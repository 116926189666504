import React from "react";
import { XIcon } from "@heroicons/react/solid";

const MAX_ATTRIBUTES = 3;
const AttributeTypes = {
  Text: "Text",
  Number: "Number",
};

/***
 * AddAttributes component
 * @param {Array} customAttributes - State to store custom attributes as array of objects
 * @param {Function} setCustomAttributes - Function to update state of custom attributes
 * @returns {JSX.Element} AddAttributes Component
 *
 * customAttribute Object:{
 * key: string,
 * value: string | number,
 * type: string
 * }
 */
function AddAttributes({ customAttributes, setCustomAttributes }) {
  // Function to handle change in attribute's key or value
  const handleAttributeChange = (index, field, updatedValue) => {
    const updatedAttributes = [...customAttributes];
    if (index < updatedAttributes.length) {
      if (
        field === "value" &&
        updatedAttributes[index].type === AttributeTypes.Number
      ) {
        updatedValue = parseInt(updatedValue, 10);
      }
      updatedAttributes[index][field] = updatedValue;
      setCustomAttributes(updatedAttributes);
    }
  };

  // Function to add new custom attribute of given type
  const addNewAttribute = (type) => {
    const attrs = [...customAttributes];
    if (attrs.length < MAX_ATTRIBUTES) {
      const newKey = `${type} Key`;
      attrs.push({
        type,
      });
      setCustomAttributes(attrs);
    } else {
      alert(`You can add a maximum of ${MAX_ATTRIBUTES} custom attributes.`);
    }
  };

  // Function to delete custom attribute
  const deleteAttribute = (index) => {
    const updatedAttributes = [...customAttributes];
    updatedAttributes.splice(index, 1);
    setCustomAttributes(updatedAttributes);
  };

  return (
    <div>
      {customAttributes &&
        customAttributes.map((attribute, index) => (
          <div
            key={index}
            className="mb-2 block flex appearance-none items-center rounded-lg border-2 border-2C7695 p-2 font-montserrat text-lg focus:outline-none focus:ring-0">
            <input
              value={attribute.key}
              onChange={(e) =>
                handleAttributeChange(index, "key", e.target.value)
              }
              placeholder="Enter attribute name"
              className="mr-2 rounded border border-blue-500 p-2"
            />
            <input
              value={attribute.value}
              onChange={(e) =>
                handleAttributeChange(index, "value", e.target.value)
              }
              placeholder="Enter attribute value"
              className="mr-2 rounded border border-blue-500 p-2"
              type={
                attribute.type === AttributeTypes.Number ? "number" : "text"
              }
            />
            <XIcon
              className="h-5 w-5 cursor-pointer text-red-600"
              onClick={() => deleteAttribute(index)}
            />
          </div>
        ))}
      <div className="mb-2 flex space-x-4">
        <button
          onClick={() => addNewAttribute(AttributeTypes.Text)}
          className="mb-2 mt-0 rounded-lg bg-224E73 p-2 hover:bg-EBEBEB">
          <span className="block text-center text-base font-normal text-white hover:text-2C7695">
            Add text attribute
          </span>
        </button>
        <button
          onClick={() => addNewAttribute(AttributeTypes.Number)}
          className="mb-2 mt-0 rounded-lg bg-224E73 p-2 hover:bg-EBEBEB">
          <span className="block text-center text-base font-normal text-white hover:text-2C7695">
            Add number attribute
          </span>
        </button>
      </div>
    </div>
  );
}

export default AddAttributes;
