import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import AddButton from "#components/utils/AddButton";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const ToteForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedTote,
  onSubmit,
  warehouses,
  customers,
  onChangeMultiSelect,
  addSubTote,
  onChangeSubTote,
  removeSubTote,
  toteTypes,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Tote Details
        </span>
      </div>

      <div>
        <Dropdown
          placeholder={"Select Tote Type"}
          list={
            toteTypes
              ? [...toteTypes, { name: "Add Custom" }]
              : [{ name: "Add Custom" }]
          }
          labelKey="name"
          valueKey="name"
          name="toteType"
          setSelected={(e) => {
            onChangeDropdown("toteType", e);
          }}
          selectedValue={selectedTote.toteType}
        />
      </div>
      <div>
        <TextField
          type="text"
          id="barcode"
          label="Barcode"
          placeholder="Barcode"
          onChange={onChange}
          value={selectedTote.barcode}
        />
      </div>
      <AddButton text="Add Sub-Tote" onClick={addSubTote} />
      {selectedTote.subTotes &&
        selectedTote.subTotes.map((item, idx) => (
          <div className="flex items-center">
            <TextField
              type="text"
              id={`barcode_${idx}`}
              label="Barcode"
              placeholder="Barcode"
              onChange={(e) => onChangeSubTote(idx, e.target.value)}
              value={item}
            />
            <span
              className="cursor-pointer pl-2 text-lg font-bold text-red-500"
              onClick={() => removeSubTote(idx)}>
              Remove
            </span>
          </div>
        ))}
      {warehouses.length > 1 && (
        <div>
          <div id="selectingStations">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Select Warehouses
              </span>
            </label>
            <MultiSelectAutoComplete
              options={warehouses}
              labelKey={"name"}
              valueKey={"id"}
              setValues={(selectedOptions) =>
                onChangeMultiSelect("warehouses", selectedOptions)
              }
              values={selectedTote.warehouses || []}
              emptyValuesAccountsForAll={true}
            />
          </div>
        </div>
      )}
      {customers.length > 1 && (
        <div>
          <div id="selectingStations">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Select Clients
              </span>
            </label>
            <MultiSelectAutoComplete
              options={getFilteredCustomerList(
                selectedTote.warehouses,
                customers,
              )}
              labelKey={"name"}
              valueKey={"id"}
              setValues={(selectedOptions) =>
                onChangeMultiSelect("customers", selectedOptions)
              }
              values={selectedTote.customers || []}
              emptyValuesAccountsForAll={true}
            />
          </div>
        </div>
      )}
    </div>
  </Modal>
);

export default ToteForm;
