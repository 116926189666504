import React, { createContext, useState, useContext } from "react";

const DropdownContext = createContext({});

export const DropdownProvider = ({ children }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  return (
    <DropdownContext.Provider value={{ activeDropdown, setActiveDropdown }}>
      {children}
    </DropdownContext.Provider>
  );
};

export const useActiveDropdown = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error("useActiveDropdown must be used within a DropdownProvider");
  }
  return context;
};
