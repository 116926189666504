import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import _ from "lodash";
import NumberField from "#components/utils/NumberField";

const DetailedView = ({
  childTask,
  selectedBundleItem,
  scanBundleItem,
  confirmBundleItem,
  onChangeScannedQuantity,
  skipBundle,
}) => {
  const [manualValue, setManualValue] = useState(null);

  if (!selectedBundleItem) {
    return (
      <>
        <div className="mt-20 flex w-full max-w-4xl items-center justify-center">
          <div className="flex-col">
            <div className="rounded-2xl border bg-white shadow-lg">
              <div className="flex items-center justify-center pb-5 pt-5">
                <div className="border-r border-gray-400 p-5">
                  <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                    <img
                      src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                      className="w-14"
                    />
                  </div>
                </div>
                <div className="flex-col">
                  <div className="px-5 py-5 pb-1 text-xl font-medium text-454A4F">
                    Please Scan the Next Item
                  </div>
                  <div className="block px-5 py-0 text-xl font-medium text-454A4F">
                    Completion: {childTask.completion}%
                  </div>
                </div>
              </div>
              <div className="items-center text-center font-montserrat text-2xl">
                OR ENTER MANUALLY
              </div>
              <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Enter Value"
                    placeholder=" "
                    onChange={(e) => setManualValue(e.target.value)}
                    value={manualValue}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        scanBundleItem(manualValue);
                        setManualValue("");
                      }
                    }}
                    autoFocus={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 flex items-center justify-center space-x-4">
          <div
            className="w-64 cursor-pointer rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
            onClick={skipBundle}>
            Skip Bundle
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="min-w-max flex-1">
      <PageTitle>Product Details</PageTitle>
      <div className="space-y-2 text-xl font-medium text-454A4F">
        <LineItemDesc title={"SKU"} value={selectedBundleItem.sku} idx={1} />
        <LineItemDesc
          title={"Product"}
          value={truncate(selectedBundleItem.productName)}
          idx={2}
        />
        <LineItemDesc
          title={"Quantity"}
          value={selectedBundleItem.quantity}
          idx={2}
        />
        {selectedBundleItem.fnSku && (
          <>
            <LineItemDesc
              title={"FN SKU"}
              value={selectedBundleItem.fnSku}
              idx={1}
            />
          </>
        )}

        {selectedBundleItem.upc && (
          <LineItemDesc
            value={selectedBundleItem?.upc?.join(", ")}
            title="UPC"
            idx={2}
          />
        )}

        {selectedBundleItem.asin && (
          <LineItemDesc
            title={"ASIN"}
            value={selectedBundleItem.asin}
            idx={1}
          />
        )}
      </div>

      <div className="mt-10 flex items-center justify-center text-center">
        <div className="pr-2 text-xl">Quantity</div>
        <NumberField
          onChange={(e) =>
            onChangeScannedQuantity(parseInt(e.target.value), "BUNDLE_ITEM")
          }
          placeholder="Quantity"
          value={selectedBundleItem.scannedQuantity}
          className="p-4 text-2xl"
          min="0"
        />
      </div>

      <div className="mt-20 flex items-center justify-center space-x-4">
        <div
          className="w-64 cursor-pointer rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
          onClick={confirmBundleItem}>
          Confirm Item
        </div>
        <div
          className="w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
          onClick={skipBundle}>
          Skip Bundle
        </div>
      </div>
    </div>
  );
};

const LineItemDesc = ({ title, value, idx }) => (
  <div className="flex w-full space-x-1 shadow-md">
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className={`flex-1 rounded-br-md rounded-tr-md border bg-white p-4`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

export default DetailedView;

const truncate = (str) => {
  return str.length > 40 ? str.substring(0, 40) + "..." : str;
};
