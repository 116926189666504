import { useState, useMemo } from "react";
import Accordian from "#components/utils/Accordian";
import Modal from "#components/utils/Modal";
import { InformationCircleIcon } from "@heroicons/react/outline";
import OrderDetailsTable from "./OrderDetailsTable";

const PartialFulfillableOrders = ({
  backOrders,
  fulfillableOrders,
  selectedOrderIdsForBatching,
  setSelectedOrderIdsForBatching,
}) => {
  if (!backOrders || backOrders.length === 0) {
    return <p>No Partial Fulfillable Orders</p>;
  }

  const fulfillableOrderIds = fulfillableOrders.map(({ order }) => order.id);
  const backOrderIds = backOrders.map(
    (backOrder) => backOrder.originalOrder.id,
  );

  const [activeIdx, setActiveIdx] = useState(0);
  const [expandedBackOrder, setExpandedBackOrder] = useState(null);
  const [selectAll, setSelectAll] = useState(
    backOrderIds.every((id) => selectedOrderIdsForBatching.includes(id)),
  );

  const handleBackOrderSelection = (id) => {
    if (selectedOrderIdsForBatching.includes(id)) {
      setSelectedOrderIdsForBatching(
        selectedOrderIdsForBatching.filter((existingId) => existingId !== id),
      );
    } else {
      setSelectedOrderIdsForBatching([...selectedOrderIdsForBatching, id]);
    }
  };

  useMemo(() => {
    if (selectAll) {
      setSelectedOrderIdsForBatching([...fulfillableOrderIds, ...backOrderIds]);
    } else {
      setSelectedOrderIdsForBatching(fulfillableOrderIds);
    }
  }, [selectAll]);

  return (
    <>
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center justify-between pb-4">
          <div className="border-gray flex h-48 w-max items-center rounded border bg-gray-100 p-3">
            <InformationCircleIcon className="text-gray h-6 w-6" />
            <ul className="ml-2 text-sm font-normal text-primaryAccent">
              <li>
                Preview and select the orders which are need to be partially
                fulfilled.
              </li>
              <li>Only selected orders will be batched as back orders.</li>
            </ul>
          </div>
          {backOrders.length > 1 && (
            <div className="flex gap-x-2 p-2">
              <input
                type="checkbox"
                checked={selectAll}
                className={`h-6 w-6 cursor-pointer border-2C7695 text-2C7695 focus:ring-0 ${"rounded"}`}
                onClick={(e) => e.stopPropagation()}
                onChange={() => setSelectAll((prev) => !prev)}
                value={false}
              />
              <p className="text-base font-semibold text-primaryAccent">
                Select All
              </p>
            </div>
          )}
        </div>

        {backOrders.map((backOrder, idx) => {
          const originalOrder = backOrder.originalOrder;
          const fulfillableChildOrder = backOrder.fulfillableChildOrder;
          const unfulfillableChildOrder = backOrder.unfulfillableChildOrder;
          const availableLineItemsQuantity =
            backOrder.availableLineItemsQuantity;

          const originalOrderTitleComponent = (
            <div className="flex gap-x-2">
              <div className="h-5">
                <input
                  type="checkbox"
                  checked={selectedOrderIdsForBatching.includes(
                    originalOrder.id,
                  )}
                  className={`h-6 w-6 cursor-pointer border-2C7695 text-2C7695 focus:ring-0 ${"rounded"}`}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => handleBackOrderSelection(originalOrder.id)}
                  value={originalOrder.id}
                />
              </div>
              <div className="flex flex-col">
                <p className="text-lg font-semibold">{originalOrder.orderId}</p>
                {activeIdx === idx && (
                  <p
                    className="font-base text-sm text-primaryAccent underline"
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpandedBackOrder(backOrder);
                    }}>
                    View Original Order Details
                  </p>
                )}
              </div>
            </div>
          );

          const getChildTitleComponent = ({ splitNumber, splitType }) => {
            return (
              <div className="flex items-center gap-x-2">
                <p className="text-base font-semibold text-primaryAccent">
                  Split {splitNumber}
                </p>
                <p className="rounded-2xl border-2 border-black p-1 text-base">
                  {splitType}
                </p>
              </div>
            );
          };

          return (
            <div key={originalOrder.id}>
              <Accordian
                key={activeIdx}
                titleComponent={originalOrderTitleComponent}
                isActive={activeIdx === idx}
                toggleCallback={(index) => {
                  if (index === activeIdx) setActiveIdx(-1);
                  else setActiveIdx(index);
                }}
                index={idx}>
                <Accordian
                  key={fulfillableChildOrder.id}
                  titleComponent={getChildTitleComponent({
                    splitNumber: originalOrder.childOrders.length - 1,
                    splitType: "Fulfillable",
                  })}
                  isActive={true}>
                  <OrderDetailsTable
                    orderDetails={fulfillableChildOrder.orderDetails}
                    availableLineItemsQuantity={availableLineItemsQuantity}
                  />
                </Accordian>
                <Accordian
                  key={unfulfillableChildOrder.id}
                  titleComponent={getChildTitleComponent({
                    splitNumber: originalOrder.childOrders.length,
                    splitType: "Non-Fulfillable",
                  })}
                  isActive={true}>
                  <OrderDetailsTable
                    orderDetails={unfulfillableChildOrder.orderDetails}
                    availableLineItemsQuantity={availableLineItemsQuantity}
                  />
                </Accordian>
              </Accordian>
            </div>
          );
        })}
      </div>

      {expandedBackOrder && (
        <Modal
          negativeAction={() => setExpandedBackOrder(null)}
          positiveAction={() => setExpandedBackOrder(null)}
          title={`Order Details (${expandedBackOrder.originalOrder.orderId})`}
          noPadding={true}
          positiveText="OK">
          <div className="space-y-4 p-4">
            <OrderDetailsTable
              orderDetails={expandedBackOrder.originalOrder.orderDetails}
              fulfillmentDetails={
                expandedBackOrder.originalOrderFulfillmentDetails
              }
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default PartialFulfillableOrders;
