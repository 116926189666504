const Checkbox = ({
  role,
  label,
  checked,
  name,
  id,
  onChange,
  htmlFor,
  value,
  filled,
  disabled,
}) => (
  <div className="flex cursor-pointer items-center">
    <div className="flex h-5 items-center">
      <input
        id={id}
        aria-describedby="offers-description"
        name={name}
        type={role || "checkbox"}
        checked={checked}
        className={`h-6 w-6 cursor-pointer border-primaryAccent text-primaryAccent focus:ring-0 ${
          role === "radio" ? "rounded-xl" : "rounded"
        } ${filled ? "bg-primaryAccent" : ""} ${disabled ? "bg-gray-400" : ""}`}
        onChange={disabled ? () => {} : onChange}
        value={value}
      />
    </div>
    {label && (
      <div className="ml-2 text-sm">
        <label
          htmlFor={htmlFor || "offers"}
          className="font-montserrat text-lg font-medium text-gray-700">
          {label}
        </label>
      </div>
    )}
  </div>
);

export default Checkbox;
