import ModalV3 from "#components/utils/ModalV3";
import {
  XIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { ALERT_TYPES } from "#components/common/ContainerWithLoaderAndAlert";
import _ from "lodash";
import { useEffect, useState } from "react";
import EnhancedCategoriesSelect from "./catalogTabs/EnhancedCategoriesSelect";
import ReactTooltip from "react-tooltip";

const CategoryModalForm = ({
  addNewCategory,
  setAddNewCategory,
  componentAlert,
  submitCategoryForm,
  setComponentAlert,
  productCategories,
}) => {
  const sortedCategories = _.sortBy(productCategories, ["name"]);
  const [categoryError, setCategoryError] = useState("");
  const [inputCategoryName, setInputCategoryName] = useState("");
  const [categoryParent, setCategoryParent] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isNewCategory, setIsNewCategory] = useState(true);

  useEffect(() => {
    if (addNewCategory.id) {
      const nameSegments = addNewCategory.name.split("/");
      const name = nameSegments.pop();
      setInputCategoryName(name);
      const currentCategory = nameSegments.join("/");
      setCategoryParent(currentCategory);
    } else {
      setInputCategoryName(addNewCategory.name.split("/").pop());
    }
  }, [addNewCategory]);

  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }
    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] flex items-center rounded-md p-4 border-[${borderColor}] mb-2 border-2`}>
        <span className="mr-2 flex flex-1 items-center space-x-2 text-lg">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="float-right flex items-center justify-center font-bold text-[#717679]"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };

  const renderCategoryOptions = (categories) => {
    const options = [
      <option key="none" value="">
        None
      </option>,
    ];
    categories.forEach((category) => {
      const level = (category.name.match(/\//g) || []).length;
      const indentedName =
        "\u00A0".repeat(level * 4) + category.name.split("/").pop().trim();

      const fontSize = 16 - level * 2;

      options.push(
        <option
          key={category.id}
          value={category.id}
          style={{ fontSize: `${fontSize}px` }}>
          {indentedName}
        </option>,
      );
    });

    return options;
  };

  const handleCategoryChange = (value) => {
    const selectedCategoryId = value?.id;
    const selectedCategoryPath =
      sortedCategories.find((c) => c.id === selectedCategoryId)?.name || "";
    if (selectedCategoryPath) {
      setSelectedCategory([
        {
          id: selectedCategoryId,
          name: selectedCategoryPath,
        },
      ]);
      setAddNewCategory({
        ...addNewCategory,
        name: `${selectedCategoryPath}/${inputCategoryName}`,
      });
    } else {
      setSelectedCategory([]);
      setAddNewCategory({
        ...addNewCategory,
        name: inputCategoryName,
      });
    }
  };

  const handleCategoryNameChange = (e) => {
    const newName = e.target.value.toUpperCase();
    if (newName.includes("/")) {
      setCategoryError('The category name cannot contain the "/" character.');
    } else {
      setCategoryError("");
      setInputCategoryName(newName);
      setAddNewCategory({
        ...addNewCategory,
        name: addNewCategory.name.replace(/[^/]*$/, newName),
      });
    }
  };

  return (
    <ModalV3 isOpen={true} onClose={() => setAddNewCategory(null)}>
      <div className="min-w-3xl modalV3 p-4">
        <div className="flex items-center justify-between pb-4 font-inter">
          <span className="font-inter text-2xl font-bold text-[#454A4F]">
            {addNewCategory.id ? "Edit Category" : "Create Category"}
          </span>
          <XIcon
            className="h-6 w-6 cursor-pointer"
            onClick={() => setAddNewCategory(null)}
          />
        </div>
        {componentAlert && renderAlert(componentAlert)}
        <div className="max-w-content flex flex-col space-y-4">
          <div>
            <span className="pb-6 text-lg font-medium text-[#717679]">
              Category Name*
            </span>
            <input
              type="text"
              className={`w-full rounded-md border-2 p-2 ${categoryError ? "border-red-500" : "border-[#DDDFE0]"}`}
              placeholder="Enter Category Name"
              value={inputCategoryName}
              onChange={handleCategoryNameChange}
            />
            {categoryError && (
              <p className="mt-1 text-sm text-red-500">{categoryError}</p>
            )}
          </div>
          <div>
            <span className="pb-6 text-lg font-medium text-[#717679]">
              Category Description*
            </span>
            <textarea
              className="w-full rounded-md border-2 border-[#DDDFE0] p-2"
              placeholder="Enter Category Description"
              value={addNewCategory.description}
              onChange={(e) =>
                setAddNewCategory({
                  ...addNewCategory,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div>
            <span className="flex items-center gap-2 text-lg font-medium text-[#717679]">
              Assign Parent Category{" "}
              <InformationCircleIcon
                data-tip="Select an existing category from the hierarchy to assign it as the parent, adding this category as its sub-node."
                className="h-5 w-5 cursor-pointer text-gray-700"
              />
            </span>
            <ReactTooltip place="right" type="dark" effect="solid" />
            {addNewCategory.id && (
              <div className="mb-3 ml-2 text-sm text-gray-500">
                Note: Editing the parent category is disabled to prevent
                disruption of existing transactions.
              </div>
            )}
            <EnhancedCategoriesSelect
              options={productCategories}
              onChangeCustomSelect={(value) => {
                handleCategoryChange(value[0]);
              }}
              disabled={!isNewCategory || addNewCategory.id}
              isSingleSelect={true}
              preValues={selectedCategory || []}
              isShowSelectedOptions={true}
              classNames="comboDropdown h-12 w-full"
            />
          </div>

          <div className="flex flex-1 items-end justify-end space-x-2">
            <button
              className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent p-2 px-6 font-semibold text-primaryAccent"
              onClick={() => setAddNewCategory(null)}>
              Cancel
            </button>
            <button
              className={`cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-2 px-6 font-semibold text-white disabled:cursor-not-allowed disabled:bg-gray-400`}
              onClick={(e) => {
                e.preventDefault();
                const errorMessage =
                  addNewCategory.name === ""
                    ? "Category name can not be empty!"
                    : addNewCategory.description === ""
                      ? "Category description can not be empty!"
                      : "";
                if (errorMessage) {
                  setComponentAlert({
                    msg: errorMessage,
                    alertType: "error",
                  });
                  setTimeout(() => {
                    setComponentAlert(null);
                  }, 2000);
                  return;
                }
                const button = e.target;
                button.disabled = true;
                submitCategoryForm(e);
              }}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ModalV3>
  );
};

export default CategoryModalForm;
