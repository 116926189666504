import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment-timezone";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
  ],
});

const getTableColumns = () => {
  return [
    { title: "No.", dataIndex: "index" },
    { title: "Item", dataIndex: "sku" },
    { title: "Description", dataIndex: "productName" },
    { title: "Qty", dataIndex: "quantity" },
  ];
};

const breakWord = (word) => {
  const wordLength = word.toString().length;

  if (!wordLength) return;

  const WORD_LIMIT = 15;
  const newWord = [];

  word = word.toString();

  if (wordLength > WORD_LIMIT) {
    for (let i = 0; i < wordLength; i++) {
      newWord.push(word[i]);
      if (i !== 0 && i % WORD_LIMIT === 0) {
        newWord.push(`\n`);
      }
    }

    return newWord.join("");
  }

  return word;
};

const columns = getTableColumns();
const colWidth = Math.ceil(100 / columns.length);
const colStyles = {
  width: colWidth.toString().concat("%"),
  border: "1px solid #ccc",
  padding: "4px",
  fontWeight: 700,
};
const rowStyles = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
};
const headerStyles = {
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#ececec",
};
const cellStyles = {
  border: "1px solid #ccc",
  padding: "4px",
  fontSize: 11,
  width: colWidth.toString().concat("%"),
};

const PackingSlip = ({ data, isProprepTenant = false }) => {
  return (
    <Document style={{ fontFamily: "Open Sans" }}>
      <Page size="A4">
        <View style={{ padding: "40px 40px 20px 40px" }}>
          <Text
            style={{
              textAlign: "center",
              textDecoration: "underline",
              fontWeight: 700,
            }}>
            PACKING SLIP SHEET
          </Text>
        </View>

        <View style={{ padding: "20px 40px", fontSize: "12px" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            {!isProprepTenant && (
              <View>
                <Image
                  src={{
                    uri: "https://hopstack-pub.s3.amazonaws.com/tym-logo.png",
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                  style={{ width: 25, height: 25, marginTop: "-20px" }}
                />
                {data.warehouse?.name?.length > 0 && (
                  <Text style={{ fontWeight: 700 }}>{data.warehouse.name}</Text>
                )}
                {data.warehouse?.line1?.length > 0 && (
                  <Text>{data.warehouse.line1}</Text>
                )}
                {data.warehouse?.line2?.length > 0 && (
                  <Text>{data.warehouse.line2}</Text>
                )}
                <Text>
                  {[
                    data.warehouse?.city,
                    data.warehouse?.state,
                    data.warehouse?.country,
                    data.warehouse?.zip,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </Text>
              </View>
            )}

            <View>
              <View style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <Text style={{ fontWeight: 700 }}>
                  {isProprepTenant ? "Order No." : "Delivery No."}
                </Text>
                <Text>
                  {data?.attributes?.humanReadableOrderIds?.map((i) => i)}
                </Text>
              </View>

              <View style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <Text style={{ fontWeight: 700 }}>Print Date</Text>
                <Text>{moment(new Date()).format("MM/DD/YYYY")}</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              margin: "20px 0",
              border: "1px solid black",
              display: "flex",
              flexDirection: "row",
            }}>
            {!isProprepTenant && (
              <>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    padding: "5px",
                    marginTop:
                      data.billingAddress?.name?.trim()?.length > 0
                        ? "15px"
                        : 0,
                  }}>
                  {["B", "I", "L", "L"].map((char) => (
                    <Text style={{ fontWeight: 700, fontSize: 10 }}>
                      {char}
                    </Text>
                  ))}
                </View>
                <View
                  style={{
                    width: "50%",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}>
                  {data.billingAddress?.name?.trim()?.length > 0 && (
                    <Text style={{ fontWeight: 700 }}>
                      {data.billingAddress.name}
                    </Text>
                  )}
                  {data.billingAddress?.line1?.trim()?.length > 0 && (
                    <Text>{data.billingAddress.line1}</Text>
                  )}
                  {data.billingAddress?.line2?.trim()?.length > 0 && (
                    <Text>{data.billingAddress.line2}</Text>
                  )}
                  <Text>
                    {[
                      data.billingAddress?.city,
                      data.billingAddress?.state,
                      data.billingAddress?.zip,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </Text>
                  {data.billingAddress?.country?.trim()?.length > 0 && (
                    <Text>{data.billingAddress.country}</Text>
                  )}
                  {data.billingAddress?.phone?.trim()?.length > 0 && (
                    <Text>Tel: {data.billingAddress.phone}</Text>
                  )}
                </View>
              </>
            )}

            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                padding: "5px",
                marginTop:
                  data.shippingAddress?.name?.trim()?.length > 0 ? "15px" : 0,
              }}>
              {["S", "H", "I", "P"].map((char) => (
                <Text style={{ fontWeight: 700, fontSize: 10 }}>{char}</Text>
              ))}
            </View>
            <View style={{ width: "50%", padding: "5px" }}>
              {data.shippingAddress?.name?.trim()?.length > 0 && (
                <Text style={{ fontWeight: 700 }}>
                  {data.shippingAddress.name}
                </Text>
              )}
              {data.shippingAddress?.line1?.trim()?.length > 0 && (
                <Text>{data.shippingAddress.line1}</Text>
              )}
              {data.shippingAddress?.line2?.trim()?.length > 0 && (
                <Text>{data.shippingAddress.line2}</Text>
              )}
              <Text>
                {[
                  data.shippingAddress?.city,
                  data.shippingAddress?.state,
                  data.shippingAddress?.zip,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </Text>
              {data.shippingAddress?.country?.trim()?.length > 0 && (
                <Text>{data.shippingAddress.country}</Text>
              )}
              {data.shippingAddress?.phone?.trim()?.length > 0 && (
                <Text>Tel: {data.shippingAddress.phone}</Text>
              )}
            </View>
          </View>

          <View
            style={{
              margin: "20px 0",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              columnGap: "36px",
              rowGap: "8px",
            }}>
            {data?.attributes?.humanReadableOrderIds?.length > 0 && (
              <View style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <Text style={{ fontWeight: 700 }}>
                  {isProprepTenant ? "Order No." : "Delivery No"}
                </Text>
                <Text>
                  {data?.attributes?.humanReadableOrderIds?.map((i) => i)}
                </Text>
              </View>
            )}

            {data?.carrier?.length > 0 && (
              <View style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <Text style={{ fontWeight: 700 }}>Ship Via</Text>
                <Text>{data.carrier}</Text>
              </View>
            )}

            {data?.createdAt?.toString()?.length > 0 && (
              <View style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <Text style={{ fontWeight: 700 }}>Order Date</Text>
                <Text>{moment(data.createdAt).format("MM/DD/YYYY")}</Text>
              </View>
            )}
          </View>

          <View style={{ margin: "20px 0" }}>
            <View style={headerStyles}>
              {columns.map((col) => (
                <Text style={{ ...colStyles }} key={col.title}>
                  {col.title}
                </Text>
              ))}
            </View>

            <View>
              {data?.items?.map((item, index) => (
                <OrderLineItems
                  item={{ ...item, index: index + 1 }}
                  columns={columns}
                />
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const OrderLineItems = ({ item, columns }) => {
  return (
    <View style={rowStyles}>
      {columns.map((col, index) => (
        <Text key={col.title} style={{ ...cellStyles }}>
          {breakWord(item[col?.dataIndex])}
        </Text>
      ))}
    </View>
  );
};

export default PackingSlip;
