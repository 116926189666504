import React, { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { AddressItem } from "./VendorAddress";
import PrimaryButton from "../commons/PrimaryButton";

const AddressSelector = ({
  addresses,
  vendorName,
  handleAddressChange,
  selectedAddressIdx,
  handleCloseAddressModal,
}) => {
  const [selectedAddressIndex, setSelectedAddressIndex] =
    useState(selectedAddressIdx);
  return (
    <>
      <RadioGroup
        value={selectedAddressIndex}
        onChange={(index) => setSelectedAddressIndex(index)}
        className="w-full">
        {addresses.map((address, index) => (
          <RadioGroup.Option
            key={index}
            value={index}
            className="relative mb-4 rounded border p-2">
            {({ checked }) => (
              <>
                <span
                  className={`absolute left-2 top-3 h-4 w-4 rounded-full border-2 ${checked ? "border-blue-600 bg-primaryAccent" : "border-gray-400"}`}
                />
                <AddressItem vendorAddress={address} vendorName={vendorName} />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <div className="mt-4 flex w-full items-center justify-end gap-4">
        <PrimaryButton
          height="3rem"
          width="6rem"
          className="mt-2 text-base font-medium"
          onClick={handleCloseAddressModal}>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          height="3rem"
          width="6rem"
          variant="primary"
          className="mt-2 text-base font-medium"
          onClick={() => {
            handleAddressChange(selectedAddressIndex);
            handleCloseAddressModal();
          }}>
          Confirm
        </PrimaryButton>
      </div>
    </>
  );
};

export default AddressSelector;
