import { createContext, useReducer } from "react";
import { uploadBulkConstants } from "../../utils/constants/bulkupload/UploadBulk";

const { errors } = uploadBulkConstants;

const BulkUploadEnumns = {
  START_UPLOADING: "START_UPLOADING",
  SET_UPLOAD_FILE: "SET_UPLOAD_FILE",
  SHOW_PREVIEW: "SHOW_PREVIEW",
  SECOND_MODAL: "SECOND_MODAL",
  THIRD_MODAL: "THIRD_MODAL",
  CHANGE_SELECTED_VALUE: "CHANGE_SELECTED_VALUE",
  START_VALIDATION: "START_VALIDATION",
  ADD_ATTRIBUTE: "ADD_ATTRIBUTE",
  STOP_MODAL: "STOP_MODAL",
  STOP_VALIDAITON: "STOP_VALIDAITON",
  READ_FILE: "READ_FILE",
  RESTART: "RESTART",
  REMOVE_LOADING: "REMOVE_LOADING",
};

const initial = {
  uploadFileStep: false,
  uploadedFile: null,
  Preview: false,
  matchColumnsStep: false,
  validation: false,
  fields: [],
  attributes: [],
  tableRows: [],
  defaultValue: [],
  tableHeaders: [],
  tableValues: [],
  finalStep: false,
  error: null,
  loading: true,
  allKeys: [],
};
const ModalContext = createContext(initial);

const reducer = (state = initial, action) => {
  const { type, payload } = action;

  switch (type) {
    case BulkUploadEnumns.START_UPLOADING: {
      return {
        ...state,
        finalStep: false,
        error: null,
        uploadedFile: null,
        tableHeaders: [],
        tableRows: [],
        tableValues: [],
        showPreview: false,
        fields: [],
        attributes: [],
        defaultValue: [],
        uploadFileStep: true,
        validation: false,
      };
    }
    case BulkUploadEnumns.SET_UPLOAD_FILE: {
      const { e } = payload;
      const file = e.target.files[0];
      return { ...state, uploadedFile: file, error: null };
    }
    case BulkUploadEnumns.SHOW_PREVIEW: {
      return { ...state, Preview: !state.Preview };
    }
    case BulkUploadEnumns.SECOND_MODAL: {
      if (state.tableHeaders.length !== 0) {
        return {
          ...state,
          matchColumnsStep: true,
          uploadFileStep: false,
          error: null,
        };
      } else {
        return { ...state, error: errors[0] };
      }
    }
    case BulkUploadEnumns.CHANGE_SELECTED_VALUE: {
      const { pos, e } = payload;
      for (let i = 0; i < state.defaultValue.length; i++) {
        if (pos === i) {
          state.defaultValue[i] = e.target.value;
          return { ...state, error: null };
        } else {
          continue;
        }
      }
    }
    case BulkUploadEnumns.THIRD_MODAL: {
      const { saveBulkUpload } = payload;

      saveBulkUpload(state.tableValues);

      return {
        ...state,
        finalStep: true,
        matchColumnsStep: false,
        validation: false,
        error: null,
      };
    }

    case BulkUploadEnumns.START_VALIDATION: {
      const { readFile, dashboardFields, validate } = payload;
      if (state.attributes.length === state.tableHeaders.length) {
        for (let i = 0; i < state.defaultValue.length - 1; i++) {
          if (
            state.defaultValue
              .slice(i + 1, state.attributes.length - (i + 1))
              .indexOf(state.defaultValue[i]) !== -1
          ) {
            return {
              ...state,
              error: errors[3](state.defaultValue[i]),
            };
          }
        }

        const headerRow = readFile[0].some((item) => item?.includes("Template"))
          ? 1
          : 0;

        for (let i = headerRow + 1; i < readFile.length; i++) {
          let values = readFile[i];
          const everyValueEmpty = (currentValue) => !!!currentValue;
          if (values.every(everyValueEmpty)) continue;
          for (let j = 0; j < values.length; j++) {
            values[j] = values[j] && values[j].toString();
          }

          if (typeof dashboardFields[state.allKeys[0]] === "object") {
            const row = state.defaultValue
              .slice(0, state.attributes.length)
              .reduce((acc, curr, i) => {
                if (
                  state.allKeys.some(
                    (item) => dashboardFields[item].display === curr,
                  )
                ) {
                  const key = Object.keys(dashboardFields).find(
                    (item) => dashboardFields[item].display === curr,
                  );
                  acc[key] = values[i] ? values[i] : "";
                } else {
                  acc["attributes"] = {
                    ...acc["attributes"],
                    [curr]: values[i] ? values[i] : "",
                  };
                }
                return acc;
              }, {});
            state.tableValues = [...state.tableValues, row];
          } else {
            const row = state.defaultValue
              .slice(0, state.attributes.length)
              .reduce((acc, curr, i) => {
                if (
                  state.allKeys.some((item) => dashboardFields[item] === curr)
                ) {
                  const key = Object.keys(dashboardFields).find(
                    (item) => dashboardFields[item] === curr,
                  );
                  acc[key] = values[i] ? values[i] : "";
                } else {
                  acc["attributes"] = {
                    ...acc["attributes"],
                    [curr]: values[i] ? values[i] : "",
                  };
                }
                return acc;
              }, {});
            state.tableValues = [...state.tableValues, row];
          }
        }

        validate(state.tableValues);

        return {
          ...state,
          finalStep: false,
          matchColumnsStep: false,
          validation: true,
          error: null,
        };
      } else {
        return { ...state, error: errors[1] };
      }
    }
    case BulkUploadEnumns.ADD_ATTRIBUTE: {
      if (state.attributes.length < state.tableHeaders.length) {
        const lastattribute = state.attributes.indexOf(
          state.attributes.length - 1,
        );
        return {
          ...state,
          attributes: [...state.attributes, lastattribute + 1],
        };
      } else {
        return { ...state, error: errors[2] };
      }
    }
    case BulkUploadEnumns.STOP_MODAL: {
      return {
        uploadFileStep: false,
        uploadedFile: null,
        Preview: false,
        matchColumnsStep: false,
        validation: false,
        fields: [],
        attributes: [],
        tableRows: [],
        defaultValue: [],
        tableHeaders: [],
        tableValues: [],
        finalStep: false,
        error: null,
        loading: true,
        allKeys: [],
      };
    }
    case BulkUploadEnumns.STOP_VALIDAITON: {
      return {
        ...state,
        validation: false,
        error: null,
        uploadedFile: null,
        tableHeaders: [],
        tableRows: [],
        tableValues: [],
        showPreview: false,
        fields: [],
        attributes: [],
        defaultValue: [],
      };
    }
    case BulkUploadEnumns.RESTART: {
      return {
        ...state,
        finalStep: false,
        error: null,
        uploadedFile: null,
        tableHeaders: [],
        tableRows: [],
        tableValues: [],
        showPreview: false,
        fields: [],
        attributes: [],
        defaultValue: [],
        uploadFileStep: true,
      };
    }
    case BulkUploadEnumns.READ_FILE: {
      const { dashboardFields, readFile } = payload;

      if (dashboardFields && readFile) {
        const firstRow = readFile[0].some((item) => item?.includes("Template"))
          ? 1
          : 0;

        state.tableHeaders = readFile[firstRow].filter((item) => item);
        if (state.tableHeaders.length === 0) {
          return { ...state, error: errors[5] };
        }
        state.tableRows = readFile
          .slice(firstRow + 1)
          .map((row) => {
            // convert non-strings to string
            return row.map((value) => {
              return value ? value.toString() : value;
            });
          })
          .filter((row) => row.some((value) => value));

        // Simplified fields and defaultValue processing
        const updatedFields = Object.keys(dashboardFields).map(
          (key) => dashboardFields[key].display || dashboardFields[key],
        );
        state.fields = [...state.fields, ...updatedFields];
        state.defaultValue = [...state.defaultValue, ...updatedFields];

        // Simplified attributes processing
        state.attributes = Object.keys(dashboardFields).slice(
          0,
          state.tableHeaders.length,
        );

        // All keys processing
        state.allKeys = Object.keys(dashboardFields);

        return { ...state };
      } else {
        return {
          uploadFileStep: false,
          uploadedFile: null,
          Preview: false,
          matchColumnsStep: false,
          validation: false,
          fields: [],
          attributes: [],
          tableRows: [],
          defaultValue: [],
          tableHeaders: [],
          tableValues: [],
          finalStep: false,
          error: null,
          loading: true,
          allKeys: [],
        };
      }
    }
    case BulkUploadEnumns.REMOVE_LOADING: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

function BulkUploadModalProvider(props) {
  const [state, dispatch] = useReducer(reducer, initial);

  function startUploading() {
    dispatch({ type: BulkUploadEnumns.START_UPLOADING });
  }
  function setUploadfile(e) {
    dispatch({ type: BulkUploadEnumns.SET_UPLOAD_FILE, payload: { e } });
  }
  function showPreview() {
    dispatch({ type: BulkUploadEnumns.SHOW_PREVIEW });
  }
  function secondModal() {
    dispatch({ type: BulkUploadEnumns.SECOND_MODAL });
  }
  function changeselectedValue(pos, e) {
    dispatch({
      type: BulkUploadEnumns.CHANGE_SELECTED_VALUE,
      payload: { pos, e },
    });
  }
  function thirdModal(saveBulkUpload) {
    dispatch({
      type: BulkUploadEnumns.THIRD_MODAL,
      payload: { saveBulkUpload },
    });
  }
  function startValidation(readFile, dashboardFields, validate) {
    dispatch({
      type: BulkUploadEnumns.START_VALIDATION,
      payload: { readFile, dashboardFields, validate },
    });
  }
  function addAttribute() {
    dispatch({ type: BulkUploadEnumns.ADD_ATTRIBUTE });
  }
  function stopModal() {
    dispatch({ type: BulkUploadEnumns.STOP_MODAL });
  }
  function stopValidation() {
    dispatch({ type: BulkUploadEnumns.STOP_VALIDAITON });
  }
  function readFile(dashboardFields, readFile) {
    dispatch({
      type: BulkUploadEnumns.READ_FILE,
      payload: { dashboardFields, readFile },
    });
  }
  function restartAgain() {
    dispatch({ type: BulkUploadEnumns.RESTART });
  }
  function removeLoading() {
    dispatch({ type: BulkUploadEnumns.REMOVE_LOADING });
  }

  return (
    <ModalContext.Provider
      value={{
        ...state,
        startUploading,
        setUploadfile,
        showPreview,
        secondModal,
        changeselectedValue,
        thirdModal,
        addAttribute,
        stopModal,
        readFile,
        restartAgain,
        removeLoading,
        startValidation,
        stopValidation,
      }}
      {...props}
    />
  );
}

export { ModalContext, BulkUploadModalProvider };
