import React, { useState } from "react";
import ModalV2 from "#components/utils/ModalV2";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import { DotsVerticalIcon, TrashIcon, EyeIcon } from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";

import ProductInfo from "./ProductInfo";
import ProductSearch from "./ProductSearch";
import BundleForm from "./BundleForm";
import CasePackInformation from "./CasePackInformation";
import COGInformation from "./CogInformation";
import UOMConfigurationV2 from "#components/products/UOMConfigurationV2";

const itemTypes = [
  { name: "Standalone", value: "STANDALONE" },
  { name: "Bundle", value: "BUNDLE" },
];

const itemSelectionTypes = Object.freeze({
  SHOW_BUNDLE_INFO: "SHOW_BUNDLE_INFO",
  SHOW_COG_DETAILS: "SHOW_COG_DETAILS",
  SHOW_UOM_CONFIGURATION: "SHOW_UOM_CONFIGURATION",
});

const AddProductStep = ({
  selectedConsignment,
  setSelectedConsignment,
  warehouses,
  multiAccountSupportEnabled,
  tenant,
  upsertSelectedConsignmentItem,
  removeSelectedConsignmentItem,
  refreshConsignmentItems,
}) => {
  const ldClient = useLDClient();
  const isBundleDefinitionEnabled = ldClient.variation(
    featureFlags.DEFINE_BUNDLE_ON_CONSIGNMENT,
    false,
  );
  const isCogEnabled = ldClient.variation(featureFlags.COG_ENABLED, false);

  const isPrepCenter = tenant?.typeOfCustomer?.includes("Prep Center");

  const [showProductSearch, setShowProductSearch] = useState(
    !selectedConsignment.items || selectedConsignment.items?.length === 0
      ? true
      : false,
  );
  const [showImage, setShowImage] = useState(null);

  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const [selectedItemDetailsPrev, setSelectedItemDetailsPrev] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [itemSelectionType, setItemSelectionType] = useState(null);

  // To handle the addition of new product to the consignment items.
  const addNewProduct = (productItem) => {
    const uom = productItem.baseUom || "Each";
    upsertSelectedConsignmentItem({
      productId: productItem.id,
      fulfillmentType:
        warehouses &&
        warehouses.find((item) => item.typeOfWarehouse?.includes("PREP CENTER"))
          ? "FBA"
          : "DEFAULT",
      asin: productItem.asin,
      sku: productItem.sku,
      sellerSku: productItem.sellerSku,
      productName: productItem.name,
      formFactor: uom,
      formFactors: [{ name: uom, quantity: 1 }],
      marketplace:
        productItem.marketplaceAttributes?.attributes?.marketplaceCountryCode,
      seller: productItem.marketplaceAttributes?.attributes?.sellerId,
      itemDetails: productItem,
      addingNew: true,
    });
    setShowProductSearch(false);
  };

  // Table headers to be displayed for the consignment items.
  const showHeaders = [
    { name: "Product Info", correspondingValue: "productInfo" },
    { name: "Channel", correspondingValue: "source" },
    {
      name: (
        <>
          Unit Type<span className="text-trashIconRed">*</span>
        </>
      ),
      correspondingValue: "formFactor",
    },
    {
      name: (
        <>
          Quantity<span className="text-trashIconRed">*</span>
        </>
      ),
      correspondingValue: "quantity",
    },
  ];
  if (isBundleDefinitionEnabled) {
    showHeaders.push({
      name: (
        <>
          Item Type<span className="text-trashIconRed">*</span>
        </>
      ),
      correspondingValue: "typeOfProduct",
    });
  }
  showHeaders.push({
    name: "Action",
    correspondingValue: "action",
  });

  // When an item is selected, it handles setting the item details, index pos in the consignment items, and the purpose for the selection.
  const handleItemSelection = (itemDetails, itemIdx, selectionType) => {
    setSelectedItemDetails({ ...itemDetails });
    setSelectedItemIndex(itemIdx);
    setItemSelectionType(selectionType);
  };

  // Used when we need to reset the selected item.
  const resetItemSelection = () => {
    setSelectedItemDetails(null);
    setSelectedItemIndex(-1);
    setItemSelectionType(null);
  };

  // This happens when we choose the product from standalone to bundle and then don't define the bundle.
  // when resetting, modify the itemDetails in the respective itemIdx for the selectedConsignment.
  const resetItemSelectionForBundleConfig = ({ operationSuccess = false }) => {
    const items = { ...selectedConsignment.items };
    const selectedItem = items[selectedItemIndex];
    if (!operationSuccess && selectedItemDetailsPrev) {
      upsertSelectedConsignmentItem({
        ...selectedItem,
        itemDetails: selectedItemDetailsPrev,
        idx: selectedItemIndex,
      });
    }
    setSelectedItemDetailsPrev(null);
    resetItemSelection();
  };

  return (
    <div className="flex h-full flex-col">
      {/* Add Products Header */}
      <div className="py-1">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold">Add Products</h1>
          <button
            onClick={() => setShowProductSearch(true)}
            className="text-hyperlinkColor underline">
            + Add More
          </button>
        </div>
        <p className="w-full text-sm font-normal">
          Easily find and add products from the Hopstack inventory. If you have
          a specific marketplace in mind, please select it for targeted results
        </p>
        <p className="my-4 text-sm font-medium text-lightGray">
          Products Added: {selectedConsignment.items?.length ?? 0}
        </p>
      </div>

      {/* Existing Consignment Products */}
      <div className="flex-1 overflow-auto">
        {/* Screen to Add a Product with Filters */}
        {showProductSearch && (
          <ProductSearch
            addProduct={addNewProduct}
            multiAccountSupportEnabled={multiAccountSupportEnabled}
            isPrepCenter={isPrepCenter}
            setShowImage={setShowImage}
            selectedCustomer={selectedConsignment.customer}
            selectedWarehouse={selectedConsignment.warehouse}
          />
        )}
        {/* Added Products in the Consignment */}
        {selectedConsignment.items?.length > 0 && (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="sticky top-0 z-10 rounded-full bg-bgGray">
              <tr className="border border-borderGray font-montserrat text-lightGray">
                {showHeaders.map((header, idx) => (
                  <th
                    scope="col"
                    className="p-3 text-left font-medium tracking-wider"
                    key={idx}>
                    {header.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {selectedConsignment.items.map((item, index) => (
                <ConsignmentProductRow
                  numberofItems={selectedConsignment.items?.length}
                  key={`${item.itemDetails?.id}+${item.formFactor}`}
                  selectedConsignment={selectedConsignment}
                  setSelectedConsignment={setSelectedConsignment}
                  item={item}
                  itemDetails={item.itemDetails}
                  itemIdx={index}
                  setShowImage={setShowImage}
                  upsertSelectedConsignmentItem={upsertSelectedConsignmentItem}
                  removeSelectedConsignmentItem={removeSelectedConsignmentItem}
                  showHeaders={showHeaders}
                  handleItemSelection={handleItemSelection}
                  setSelectedItemDetailsPrev={setSelectedItemDetailsPrev}
                  isBundleDefinitionEnabled={isBundleDefinitionEnabled}
                  isCogEnabled={isCogEnabled}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Updating UOM Configuration for a specific product */}
      {itemSelectionType === itemSelectionTypes.SHOW_UOM_CONFIGURATION &&
        selectedItemDetails && (
          <UOMConfigurationV2
            item={selectedItemDetails}
            product={selectedItemDetails}
            onUpdate={() => {
              refreshConsignmentItems();
              resetItemSelection();
            }}
            onClose={() => resetItemSelection()}
          />
        )}

      {/* COG Info View */}
      {itemSelectionType === itemSelectionTypes.SHOW_COG_DETAILS &&
        selectedItemDetails && (
          <COGInformation
            product={selectedItemDetails}
            onUpdate={() => {
              refreshConsignmentItems();
              resetItemSelection();
            }}
            onClose={() => resetItemSelection()}
          />
        )}

      {/* Bundle Form View */}
      {itemSelectionType === itemSelectionTypes.SHOW_BUNDLE_INFO &&
        selectedItemDetails && (
          <BundleForm
            selectedProduct={selectedItemDetails}
            isSelectedProductBundle={
              selectedItemDetailsPrev?.typeOfProduct === "BUNDLE"
            }
            isPrepCenter={isPrepCenter}
            negativeAction={resetItemSelectionForBundleConfig}
            setShowImage={setShowImage}
            multiAccountSupportEnabled={multiAccountSupportEnabled}
            selectedCustomer={selectedConsignment.customer}
            selectedWarehouse={selectedConsignment.warehouse}
          />
        )}

      {/* Expanded Image View */}
      {showImage && (
        <ModalV2
          onClose={() => setShowImage(null)}
          xIconClicked={() => setShowImage(null)}
          title={`Image Preview`}
          noPadding={true}>
          <div className="space-y-4 p-4">
            <div>
              <img
                className="mx-auto h-3/4 w-3/4 overflow-hidden rounded-lg object-contain"
                src={showImage}
                alt="No Image"
              />
            </div>
          </div>
        </ModalV2>
      )}
    </div>
  );
};

// Each item in the consignment will be having some state of its own. This separate component handles that.
const ConsignmentProductRow = ({
  selectedConsignment,
  setSelectedConsignment,
  item,
  itemDetails,
  itemIdx,
  setShowImage,
  upsertSelectedConsignmentItem,
  removeSelectedConsignmentItem,
  showHeaders,
  handleItemSelection,
  setSelectedItemDetailsPrev,
  isBundleDefinitionEnabled,
  isCogEnabled,
}) => {
  // Case for the consignment for which the product data doesn't exists in productvariants collection
  if (!itemDetails) {
    return null;
  }

  if (!itemDetails.typeOfProduct) {
    itemDetails.typeOfProduct = "STANDALONE";
  }

  const menuItems = (item) => {
    const arr = [
      {
        title: "Remove",
        icon: TrashIcon,
        onClick: () => removeSelectedConsignmentItem(itemIdx),
      },
    ];
    if (isBundleDefinitionEnabled && itemDetails.typeOfProduct === "BUNDLE") {
      arr.push({
        title: "View Bundle Info",
        icon: EyeIcon,
        onClick: () =>
          handleItemSelection(
            itemDetails,
            itemIdx,
            itemSelectionTypes.SHOW_BUNDLE_INFO,
          ),
      });
    }
    if (isCogEnabled) {
      arr.push({
        title: "View COG Details",
        icon: EyeIcon,
        onClick: () =>
          handleItemSelection(
            itemDetails,
            itemIdx,
            itemSelectionTypes.SHOW_COG_DETAILS,
          ),
      });
    }
    arr.push({
      title: "View UOM Details",
      icon: EyeIcon,
      onClick: () =>
        handleItemSelection(
          itemDetails,
          itemIdx,
          itemSelectionTypes.SHOW_UOM_CONFIGURATION,
        ),
    });

    return arr;
  };

  const handleItemTypeChange = (itemType) => {
    if (itemType === "BUNDLE") {
      setSelectedItemDetailsPrev({ ...itemDetails });
      const currentItemDetails = { ...itemDetails, typeOfProduct: itemType };
      upsertSelectedConsignmentItem({
        ...item,
        itemDetails: { ...currentItemDetails },
        idx: itemIdx,
      });
      handleItemSelection(
        currentItemDetails,
        itemIdx,
        itemSelectionTypes.SHOW_BUNDLE_INFO,
      );
    }
  };

  const showCasePackInfo = selectedConsignment.isCasePack;

  return (
    <>
      <tr
        key={`${itemDetails.id}+${item.formFactor}`}
        className={`bg-bgWhite ${
          showCasePackInfo
            ? "border-l border-r border-t border-l-borderGray border-r-borderGray border-t-borderGray"
            : "border border-borderGray"
        }`}>
        {showHeaders.map((header, headerIdx) => {
          const value = header.correspondingValue;
          if (value === "productInfo") {
            return (
              <td className="w-289 p-3 pr-10">
                <ProductInfo
                  item={item}
                  itemDetails={itemDetails}
                  itemIdx={itemIdx}
                  setShowImage={setShowImage}
                />
              </td>
            );
          }
          if (value === "source") {
            return <td className="pl-3">{item.itemDetails?.source}</td>;
          }
          if (value === "formFactor") {
            const baseUom = itemDetails.baseUom || "Each";
            const formFactorOptions = [{ name: baseUom, value: baseUom }];
            itemDetails.uomConfiguration
              ?.filter((i) => i.isActive)
              ?.forEach((uomConfig) => {
                formFactorOptions.push({
                  name: uomConfig.targetUom,
                  value: uomConfig.targetUom,
                });
              });

            return (
              <td className="pl-3">
                <div className="flex h-40 w-159 flex-col items-start">
                  <AutocompleteDropdownV2
                    options={formFactorOptions}
                    labelKey="name"
                    valueKey="value"
                    onChange={(uom) =>
                      upsertSelectedConsignmentItem({
                        ...item,
                        idx: itemIdx,
                        formFactor: uom,
                        formFactors: [
                          { name: uom, quantity: item.formFactors[0].quantity },
                        ],
                      })
                    }
                    value={item.formFactors[0].name ?? "Each"}
                    placeholder=""
                    searchable={false}
                  />
                  <p
                    className="mt-1 cursor-pointer text-sm font-semibold text-primaryAccent underline"
                    onClick={() =>
                      handleItemSelection(
                        itemDetails,
                        itemIdx,
                        itemSelectionTypes.SHOW_UOM_CONFIGURATION,
                      )
                    }>
                    Form Factor
                  </p>
                </div>
              </td>
            );
          }
          if (value === "quantity") {
            return (
              <td className="pl-3">
                <div className="h-40 w-103">
                  <input
                    type="number"
                    id={`item_quantity_${itemIdx}`}
                    placeholder=" "
                    onChange={(e) => {
                      upsertSelectedConsignmentItem({
                        ...item,
                        idx: itemIdx,
                        formFactors: [
                          {
                            name: item.formFactors[0].name,
                            quantity:
                              isNaN(parseInt(e.target.value)) === false
                                ? parseInt(e.target.value)
                                : null,
                          },
                        ],
                      });
                    }}
                    min={"0"}
                    value={item.formFactors[0].quantity}
                    name="itemQuantity"
                    className="h-full w-full rounded border border-borderGray"
                  />
                </div>
              </td>
            );
          }
          if (value === "typeOfProduct") {
            return (
              <td className="pl-3">
                <div className="h-40 w-159">
                  <AutocompleteDropdownV2
                    options={itemTypes}
                    labelKey="name"
                    valueKey="value"
                    onChange={(itemType) => handleItemTypeChange(itemType)}
                    value={itemDetails?.typeOfProduct ?? "STANDALONE"}
                    placeholder=""
                    searchable={false}
                    disabled={item.itemDetails?.typeOfProduct === "BUNDLE"}
                    restrictOptionsWidth={false}
                  />
                </div>
              </td>
            );
          }
          if (value === "action") {
            return (
              <td className="pl-3">
                <Menu as="div" className="relative text-left">
                  <div>
                    <Menu.Button>
                      <DotsVerticalIcon className="h-10 w-10 rounded p-2 text-base" />
                    </Menu.Button>
                  </div>
                  <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="">
                      {menuItems(item).map((menuItem, idx) => (
                        <Menu.Item key={idx}>
                          {({ active }) => (
                            <button
                              className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                              onClick={() =>
                                menuItem["onClick"](item[menuItem["vars"]])
                              }>
                              {menuItem.icon && (
                                <menuItem.icon className="h-8 w-8 pr-2" />
                              )}
                              {menuItem.title}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Menu>
              </td>
            );
          }
        })}
      </tr>
      {/* Case pack Info */}
      {showCasePackInfo && (
        <tr className="border-b border-l border-r border-b-borderGray border-l-borderGray border-r-borderGray bg-bgWhite">
          <td colSpan={"100%"}>
            <CasePackInformation
              item={item}
              itemIdx={itemIdx}
              selectedConsignment={selectedConsignment}
              setSelectedConsignment={setSelectedConsignment}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default AddProductStep;
