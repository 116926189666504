import withCycleCountPlansLogic from "#components/HOC/withCycleCountPlansLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import Tabs from "#components/utils/Tabs";
import CycleCountPlansList from "#components/cycleCountPlans/CycleCountPlansList";
import CycleCountPlanForm from "#components/cycleCountPlans/CycleCountPlanForm";
import { UserAddIcon } from "@heroicons/react/outline";
import ActivatePlanForm from "../components/cycleCountPlans/ActivatePlanForm";
import PlanExecutionsList from "../components/cycleCountPlans/PlanExecutionsList";
import DetailedPlanExecution from "../components/cycleCountPlans/DetailedPlanExecution";
import StockAdjustmentForm from "../components/cycleCountPlans/StockAdjustmentForm";

const CycleCountPlans = ({
  cycleCountPlans,
  warehouses,
  selectedCycleCountPlan,
  setSelectedCycleCountPlan,
  onChangeMultiSelect,
  fetchCycleCountPlan,
  saveCycleCountPlan,
  deleteButtonClicked,
  onChange,
  skus,
  onChangeInventorySearch,
  availableInventory,
  onChangeSKUs,
  executePlanClicked,
  planToActivate,
  setPlanToActivate,
  executePlan,
  users,
  onAddUserToPlan,
  onRemoveUserFromPlan,
  onChangePersonnelMapping,
  itemsAvailableForAssignment,
  binLocationOptions,
  specificBinLocationOptions,
  selectedPlanExecutions,
  setSelectedPlanExecutions,
  setSelectedDetailedExecution,
  selectedDetailedExecution,
  onClickExecutions,
  onClickDetailedExecutions,
  onClickAdjustStocks,
  selectedSkusForAdjustment,
  setSelectedSkusForAdjusment,
  submitStockAdjustment,
  selectExecutionSku,
  compareExecution,
  setCompareExecution,
  customers,
  onChangeMultiSelectUsers,
  debouncedBinLocationSearch,
  printReport,
}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>Cycle Count Plan Management</PageTitle>
      </div>

      <div className="mb-4 flex items-center justify-end space-x-2">
        <AddButton
          text="Add Cycle Count Plan"
          onClick={() =>
            setSelectedCycleCountPlan({
              type: "Locations",
              warehouse: warehouses?.length === 1 ? warehouses[0] : null,
            })
          }
          icon={UserAddIcon}
        />
      </div>
    </div>
    <CycleCountPlansList
      cycleCountPlans={cycleCountPlans}
      editButtonClicked={(e) => {
        fetchCycleCountPlan(e.id);
      }}
      deleteButtonClicked={(e) => deleteButtonClicked(e)}
      headers={["Name", "Frequency", "Cycle Type", "Start Date", "Action"]}
      noValuesText="No Cycle Count Plans"
      warehouses={warehouses}
      executePlan={executePlanClicked}
      viewExecutionsClicked={onClickExecutions}
    />
    {selectedCycleCountPlan && (
      <CycleCountPlanForm
        title={
          selectedCycleCountPlan.id
            ? "Edit Cycle Count Plan"
            : "Add Cycle Count Plan"
        }
        warehouses={warehouses}
        onChangeMultiSelectUsers={onChangeMultiSelectUsers}
        onChangeMultiSelect={onChangeMultiSelect}
        selectedCycleCountPlan={selectedCycleCountPlan}
        onChange={onChange}
        onClose={() => setSelectedCycleCountPlan(null)}
        onSubmit={() => saveCycleCountPlan(selectedCycleCountPlan)}
        skus={skus}
        customers={customers}
        onChangeInventorySearch={onChangeInventorySearch}
        availableInventory={availableInventory}
        onChangeSKUs={onChangeSKUs}
        binLocationOptions={binLocationOptions}
        specificBinLocationOptions={specificBinLocationOptions}
        debouncedBinLocationSearch={debouncedBinLocationSearch}
      />
    )}
    {planToActivate && (
      <ActivatePlanForm
        title="Activate Plan"
        warehouses={warehouses}
        onChangeMultiSelect={onChangeMultiSelect}
        onChange={onChange}
        onClose={() => setPlanToActivate()}
        onSubmit={() => executePlan(planToActivate)}
        skus={skus}
        onChangeInventorySearch={onChangeInventorySearch}
        availableInventory={availableInventory}
        onChangeSKUs={onChangeSKUs}
        planToActivate={planToActivate}
        users={users}
        onAddUserToPlan={onAddUserToPlan}
        onRemoveUserFromPlan={onRemoveUserFromPlan}
        onChangePersonnelMapping={onChangePersonnelMapping}
        itemsAvailableForAssignment={itemsAvailableForAssignment}
      />
    )}
    {selectedPlanExecutions && (
      <div>
        <PlanExecutionsList
          cycleCountPlanExecutions={selectedPlanExecutions}
          onClose={() => setSelectedPlanExecutions(null)}
          viewButtonClicked={onClickDetailedExecutions}
          headers={[
            "Executed At",
            "Status",
            "Latest Stock Adjustment",
            "Reason",
            "Actions",
          ]}
          noValuesText="This plan hasn't been executed yet"
          detailedPlanExecution={selectedDetailedExecution}
          compareExecution={compareExecution}
          onCloseDetailedExecutions={() => {
            setSelectedDetailedExecution(null);
            setCompareExecution({});
            setSelectedSkusForAdjusment([]);
          }}
          detailedExecutionsNoValuesText="Associates haven't started counting"
          onClickAdjustStocks={onClickAdjustStocks}
          selectedSkusForAdjustment={selectedSkusForAdjustment}
          setSelectedSkusForAdjusment={setSelectedSkusForAdjusment}
          submitStockAdjustment={submitStockAdjustment}
          selectExecutionSku={selectExecutionSku}
          printReport={printReport}
        />
      </div>
    )}
  </div>
);

export default withCycleCountPlansLogic(CycleCountPlans);
