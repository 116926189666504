import { useState, useContext, useEffect } from "react";
import CommonTable from "../common/CommonTable";
import moment from "moment-timezone";
import PopoverOnTableAction from "#components/utils/PopoverOnTableAction";
import {
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  XIcon,
  ExclamationIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  products,
} from "@heroicons/react/outline";
import {
  UPSERT_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORIES,
} from "#mutations";
import { useQuery } from "../../hooks/useQuery";
import { ALERT_TYPES } from "#components/common/ContainerWithLoaderAndAlert";
import { AppStateContext } from "#contexts/appState";
import CategoryModalForm from "#components/catalogs/CategoryModalForm";

export const initialCategoryFormValues = {
  name: "",
  description: "",
};

const ManageCategories = ({
  productCategories,
  editCategory,
  setReloadCategories,
  reloadCategories,
  products,
  loadFirstTimeData,
}) => {
  const appState = useContext(AppStateContext);
  const [addNewCategory, setAddNewCategory] = useState(null);
  const [componentAlert, setComponentAlert] = useState(null);

  const upsertCategoryQuery = useQuery(UPSERT_CATEGORY);
  const deleteCategoryQuery = useQuery(DELETE_CATEGORY);
  const updateCategoriesQuery = useQuery(UPDATE_CATEGORIES);

  useEffect(() => {
    const timer = setTimeout(() => {
      setComponentAlert(null);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [reloadCategories]);

  useEffect(() => {
    loadFirstTimeData(true);
  }, []);

  useEffect(() => {
    if (upsertCategoryQuery.loading) appState.setLoading();
    else appState.removeLoading();
    if (deleteCategory.loading) appState.setLoading();
    else appState.removeLoading();
  }, [upsertCategoryQuery.loading]);

  const getAllRelatedCategoryDetails = (baseCategoryName, categories) => {
    const basePattern = `${baseCategoryName}/`;
    const categoryIds = categories
      .filter(
        (category) =>
          category.name === baseCategoryName ||
          category.name.startsWith(basePattern),
      )
      .map((category) => {
        return category.id;
      });
    return { categoryIds };
  };

  const deleteCategory = (categoryName) => {
    const { categoryIds } = getAllRelatedCategoryDetails(
      categoryName,
      productCategories,
    );
    appState.showConfirmation(
      "Delete Category",
      `Are you sure you want to delete "${categoryName}"? This will also remove all its sub-categories and associated products.`,
      () => {
        confirmDeleteCategory(categoryIds);
      },
      appState.hideConfirmation,
    );
  };

  const confirmDeleteCategory = async (ids) => {
    const response = await deleteCategoryQuery.fetchData({ ids });
    if (response.error) {
      setComponentAlert({
        msg: response.error.message,
        alertType: "error",
      });
      appState.hideConfirmation();
    }

    if (response.data) {
      setComponentAlert({
        msg: `${response.data.deleteCategory.message}.`,
        alertType: "success",
      });
      appState.hideConfirmation();
    }
    setReloadCategories((prev) => !prev);
  };

  const flattenCategory = (category, parentName = "") => {
    let result = [];
    const currentName = parentName
      ? `${parentName}/${category.name}`
      : category.name;
    result.push({
      name: currentName,
      description: category.description,
      id: category.id,
    });

    if (category.children && category.children.length > 0) {
      category.children.forEach((child) => {
        result = result.concat(flattenCategory(child, currentName));
      });
    }

    return result;
  };

  const submitCategoryForm = async (e) => {
    e.preventDefault();

    const handleResponse = (response) => {
      if (response.error) {
        setComponentAlert({
          msg: response.error.message,
          alertType: "error",
        });
      } else if (response.data) {
        const message = !addNewCategory.id
          ? response.data.upsertCategory.message
          : response.data.updateCategories.message;
        setComponentAlert({
          msg: message,
          alertType: "success",
        });
        setAddNewCategory(null);
      }
    };

    let response;

    if (!addNewCategory.id) {
      response = await upsertCategoryQuery.fetchData(addNewCategory);
    } else {
      const flattenedCategory = flattenCategory(addNewCategory);
      response = await updateCategoriesQuery.fetchData({
        categories: flattenedCategory,
      });
    }

    handleResponse(response);
    setReloadCategories((prev) => !prev);
  };

  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }
    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] flex items-center rounded-md p-4 border-[${borderColor}] mb-2 border-2`}>
        <span className="mr-2 flex flex-1 items-center space-x-2 text-lg">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="float-right flex items-center justify-center font-bold text-[#717679]"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };

  function buildCategoryTree(categories) {
    const root = [];

    categories.forEach((category) => {
      const parts = category.name.split("/");
      let currentLevel = root;

      parts.forEach((part, index) => {
        let existingCategory = currentLevel.find((c) => c.name === part);

        if (!existingCategory) {
          existingCategory = {
            name: part,
            children: [],
            description: index === parts.length - 1 ? category.description : "",
            associatedProducts: category.associatedProducts,
            updatedAt:
              index === parts.length - 1 ? category.updatedAt : undefined,
            id: index === parts.length - 1 ? category.id : undefined,
          };
          currentLevel.push(existingCategory);
        }

        currentLevel = existingCategory.children;
      });
    });

    return root;
  }
  const transformedCategories = buildCategoryTree(productCategories);
  const headers = [
    "Name",
    "Description",
    "Associated Products",
    "Last Updated",
    "Actions",
  ];

  return (
    <div className="flex-col">
      <div className="flex items-center">
        <div className="m-4 mt-6 flex-1 font-inter text-2xl font-semibold text-gray-900">
          Manage Categories
        </div>
        <div>
          <button
            className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-2 px-6 font-semibold text-white"
            onClick={() => setAddNewCategory(initialCategoryFormValues)}>
            Add Product Category
          </button>
        </div>
      </div>
      {componentAlert && renderAlert(componentAlert)}
      <CommonTable headers={headers}>
        <tbody className="divide-y divide-gray-200 bg-white">
          {transformedCategories.map((category, index) => (
            <CategoryItem
              key={category.name + index}
              category={category}
              fullPath={""}
              setAddNewCategory={setAddNewCategory}
              deleteCategory={deleteCategory}
            />
          ))}
        </tbody>
      </CommonTable>
      {addNewCategory && (
        <CategoryModalForm
          addNewCategory={addNewCategory}
          setAddNewCategory={setAddNewCategory}
          submitCategoryForm={submitCategoryForm}
          componentAlert={componentAlert}
          productCategories={productCategories}
          setComponentAlert={setComponentAlert}
        />
      )}
    </div>
  );
};

const CategoryItem = ({
  category,
  level = 0,
  fullPath,
  setAddNewCategory,
  deleteCategory,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const hasChildren = category.children && category.children.length > 0;
  const toggleCollapse = () => setCollapsed(!collapsed);

  const currentFullPath = fullPath
    ? `${fullPath}/${category.name}`
    : category.name;

  const actionsOnCategory = (category, fullPath) => [
    {
      name: "Edit Category",
      icon: <PencilIcon className="h-6 w-6" />,
      onClick: () => setAddNewCategory({ ...category, name: fullPath }),
    },
    {
      name: "Remove Category",
      icon: <TrashIcon className="h-6 w-6" />,
      onClick: () => deleteCategory(fullPath),
    },
  ];

  return (
    <>
      <tr className="cursor-pointer hover:bg-gray-100">
        <td style={{ paddingLeft: `${20 * level}px` }} className="py-4">
          <div className="flex items-center">
            {hasChildren && (
              <button onClick={toggleCollapse} className="text-primaryAccent">
                {collapsed ? (
                  <ChevronRightIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )}
              </button>
            )}
            <span
              className={`ml-2 text-lg ${level === 0 ? "font-semibold" : "font-normal"}`}>
              {category.name.split("/").pop()}
            </span>
          </div>
        </td>
        <td className="py-4 text-lg">{category.description}</td>
        <td className="py-4 text-lg">
          {category?.children?.length ? "" : category.associatedProducts}
        </td>
        <td className="py-4 text-lg">
          {category.updatedAt
            ? moment(category.updatedAt).format("YYYY-MM-DD HH:mm")
            : ""}
        </td>
        <td className="flex space-x-2 py-4">
          {actionsOnCategory(category, currentFullPath).map((action, index) => (
            <button
              key={index}
              className="p-1 hover:bg-hoverHighlight"
              onClick={action.onClick}
              title={action.name}>
              {action.icon}
            </button>
          ))}
        </td>
      </tr>
      {!collapsed &&
        category.children.map((subcategory) => (
          <CategoryItem
            key={subcategory.id}
            category={subcategory}
            level={level + 1}
            fullPath={currentFullPath}
            setAddNewCategory={setAddNewCategory}
            deleteCategory={deleteCategory}
          />
        ))}
    </>
  );
};

export default ManageCategories;
