import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { useForm } from "#hooks/useForm";
import { MasterDataContext } from "#contexts/masterData";
import { INITIATE_RESET_PASSWORD } from "#queries";

const useLoginLogic = () => {
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const masterData = useContext(MasterDataContext);
  const { push } = useHistory();

  const login = useForm(loginUserCallback, {
    username: "",
    password: "",
    logoutAll: false,
    captcha: false,
  });

  const resetPassword = useForm(resetPasswordCallback, {
    email: "",
  });

  const loginUser = useQuery(LOGIN_USER);

  const initiateResetPasswordQuery = useQuery(INITIATE_RESET_PASSWORD);

  function loginUserCallback() {
    loginUser.fetchData(login.values);
  }

  function resetPasswordCallback() {
    if (!resetPassword.values.email) {
      appState.setAlert("Please enter email");
      return;
    }
    initiateResetPasswordQuery.fetchData(resetPassword.values);
  }

  useEffect(() => {
    if (initiateResetPasswordQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
    if (initiateResetPasswordQuery.data) {
      appState.setAlert(
        initiateResetPasswordQuery.data.initiateResetPassword.message,
      );
    }

    if (initiateResetPasswordQuery.error) {
      appState.setAlert(initiateResetPasswordQuery.error.message);
    }
  }, [
    initiateResetPasswordQuery.loading,
    initiateResetPasswordQuery.data,
    initiateResetPasswordQuery.error,
  ]);

  useEffect(() => {
    if (loginUser.data) {
      auth.login(loginUser.data.login);
      masterData.fetchMasterData();
    }
    if (loginUser.error) {
      if (
        loginUser.error?.message &&
        loginUser.error.message.indexOf("already logged in") !== -1
      ) {
        appState.showConfirmation(
          "Error logging in",
          loginUser.error.message,
          () => {
            loginUser.fetchData({ ...login.values, logoutAll: true });
            appState.hideConfirmation();
          },
          appState.hideConfirmation,
        );
      } else {
        appState.setAlert(loginUser.error.message, "error", 5000);
      }
    }
  }, [loginUser.loading, loginUser.data, loginUser.error]);

  return { login, resetPassword };
};

const LOGIN_USER = `
  mutation login($username: String!, $password: String!, $logoutAll: Boolean) {
    login(username: $username, password: $password, logoutAll: $logoutAll) {
      id
      name
      token
      role
      hopstackModules
      email
      activity
      station
      permissions {
        route
        readable
        writable
      }
      warehouses
      customers
      warehousesList {
        name
        code
        updatedAt
        id
        isDefault
      }
      customersList {
        name
        code
        updatedAt
        warehouses
        id
        isDefault
        settings
      }
      termsAndConditionsAccepted
    }
  }
`;

export default useLoginLogic;
