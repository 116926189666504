import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_RECEIVED_SKUS,
  GET_RECEIVED_SKUS_MASTER_DATA,
  GET_RECEIVED_SKU_AUDIT,
  GET_RECEIVED_SKU,
  GET_SKU,
} from "#queries";
import { SAVE_RECEIVED_SKU } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { MasterDataContext } from "#contexts/masterData";
import { EntityContext } from "#contexts/entity";

const withReceivedSkusLogic = (WrappedComponent) => {
  return (props) => {
    const entity = useContext(EntityContext);
    const masterData = useContext(MasterDataContext);
    const [fetchedSku, setFetchedSku] = useState(null);
    const [fetchedReceivedSku, setFetchedReceivedSku] = useState(null);
    const appState = useContext(AppStateContext);
    const receivedSkusQuery = useQuery(GET_RECEIVED_SKUS);
    const fetchReceivedSkuQuery = useQuery(GET_RECEIVED_SKU);
    const submitReceivedSkuQuery = useQuery(SAVE_RECEIVED_SKU);
    const getSku = useQuery(GET_SKU);
    const getAuditQuery = useQuery(GET_RECEIVED_SKU_AUDIT);
    const [selectedSkus, setSelectedSkus] = useState([]);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
      receivedSkusQuery.fetchData({
        perPage: entity.perPage,
        filters: entity.filters,
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });

      return () => {
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      if (receivedSkusQuery.data && receivedSkusQuery.data.receivedSkus) {
        entity.setEntities({
          ...receivedSkusQuery.data.receivedSkus,
          ...receivedSkusQuery.variables,
        });
      }
    }, [
      receivedSkusQuery.loading,
      receivedSkusQuery.error,
      receivedSkusQuery.data,
    ]);

    useEffect(() => {
      if (getAuditQuery.data) {
        setSelectedAudit(getAuditQuery.data.getReceivedSkuAudit);
      }

      if (getAuditQuery.error) {
        setSelectedAudit(null);
      }
    }, [getAuditQuery.loading, getAuditQuery.data, getAuditQuery.error]);

    useEffect(() => {
      if (fetchReceivedSkuQuery.data) {
        setFetchedReceivedSku(fetchReceivedSkuQuery.data.receivedSku);
      }

      if (fetchReceivedSkuQuery.error) {
        setFetchedReceivedSku(null);
        appState.setAlert("Could not fetch that received SKU");
      }

      if (fetchReceivedSkuQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      fetchReceivedSkuQuery.loading,
      fetchReceivedSkuQuery.error,
      fetchReceivedSkuQuery.data,
    ]);

    useEffect(() => {
      if (submitReceivedSkuQuery.data) {
        appState.setAlert(
          submitReceivedSkuQuery.data.saveReceivedSku.message,
          "success",
          5000,
        );
        setFetchedReceivedSku(null);
        receivedSkusQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }

      if (submitReceivedSkuQuery.error) {
        appState.setAlert(submitReceivedSkuQuery.error.message, "error", 5000);
      }

      if (submitReceivedSkuQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      submitReceivedSkuQuery.loading,
      submitReceivedSkuQuery.error,
      submitReceivedSkuQuery.data,
    ]);

    const selectSku = (id) => {
      const selectedSkusCopy = [...selectedSkus];
      const findIndex = _.findIndex(selectedSkusCopy, (e) => e === id);
      if (findIndex === -1) {
        selectedSkusCopy.push(id);
      } else {
        selectedSkusCopy[findIndex] = null;
      }
      setSelectedSkus(_.compact(_.uniq(selectedSkusCopy)));
    };

    const clearSelectedSkus = () => {
      setSelectedSkus([]);
    };

    useEffect(() => {
      if (getSku.data && getSku.data.specificInventory) {
        setFetchedSku(getSku.data.specificInventory);
      } else {
        setFetchedSku(null);
      }
    }, [getSku.loading, getSku.error, getSku.data]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return receivedSkusQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        receivedSkus={entity.entities}
        displayReceivedSkus={entity.displayEntities}
        masterData={masterData}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          receivedSkusQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          receivedSkusQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          receivedSkusQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            receivedSkusQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          receivedSkusQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={() => {
          entity.setFilters({});
          receivedSkusQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {},
            sort: entity.sort,
          });
        }}
        getAudit={(id) => getAuditQuery.fetchData({ id })}
        selectedAudit={selectedAudit}
        setSelectedAudit={setSelectedAudit}
        selectedSkus={selectedSkus}
        selectSku={selectSku}
        clearSelectedSkus={clearSelectedSkus}
        getSku={(sku) => getSku.fetchData({ sku })}
        fetchedSku={fetchedSku}
        setFetchedSku={setFetchedSku}
        fetchReceivedSku={(id) => fetchReceivedSkuQuery.fetchData({ id })}
        setFetchedReceivedSku={setFetchedReceivedSku}
        fetchedReceivedSku={fetchedReceivedSku}
        submitFetchedReceivedSku={() =>
          submitReceivedSkuQuery.fetchData({ ...fetchedReceivedSku })
        }
      />
    );
  };
};

export default withReceivedSkusLogic;
