import { useState, useEffect, useContext } from "react";
import { useTempQuery } from "#hooks/useTempQuery";
import { GET_GROUPS, GET_GROUP, GET_RULE_ENGINE_MASTER_DATA } from "#queries";
import { SAVE_GROUP, DELETE_GROUP } from "#mutations";
import _, { lte } from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { MasterDataContext } from "#contexts/masterData";
import { AuthContext } from "#contexts/auth";
import { useHistory } from "react-router-dom";

const withGroupFormLogic = (WrappedComponent) => {
  return (props) => {
    const auth = useContext(AuthContext);
    const masterData = useContext(MasterDataContext);
    const history = useHistory();
    const id =
      history.location.search &&
      history.location.search.split("=").length &&
      history.location.search.split("=")[1];
    const appState = useContext(AppStateContext);
    const masterDataQuery = useTempQuery(GET_RULE_ENGINE_MASTER_DATA);

    useEffect(() => {
      masterDataQuery.fetchData();
    }, []);

    useEffect(() => {
      if (masterDataQuery.data) {
        console.log(masterDataQuery.data.masterData);
      }
    }, [masterDataQuery.data]);

    const saveGroupQuery = useTempQuery(SAVE_GROUP);
    const [selectedGroup, setSelectedGroup] = useState({
      name: null,
      conditions: [
        {
          mainCriteria: "AND",
          conditions: [
            {
              id: "0.1",
              masterField: null,
              comparisonOperator: null,
              value: null,
            },
          ],
        },
      ],
      mainCriteria: null,
    });
    const getGroupQuery = useTempQuery(GET_GROUP);

    useEffect(() => {
      if (id) {
        getGroupQuery.fetchData({ id });
      }
    }, []);
    useEffect(() => {
      if (getGroupQuery.loading) {
        appState.setLoading();
      } else {
        if (getGroupQuery.data && getGroupQuery.data.group) {
          setSelectedGroup(
            JSON.parse(JSON.stringify(getGroupQuery.data.group)),
          );
        }
        appState.removeLoading();
      }
    }, [getGroupQuery.loading, getGroupQuery.error, getGroupQuery.data]);

    useEffect(() => {
      const onCompleted = (data) => {
        history.replace("/rules");
      };
      const onError = (error) => {
        /* magic */
        console.log(error);
      };
      if (onCompleted || onError) {
        if (
          onCompleted &&
          !saveGroupQuery.loading &&
          !saveGroupQuery.error &&
          saveGroupQuery.data
        ) {
          onCompleted(saveGroupQuery.data);
        } else if (saveGroupQuery.error && !saveGroupQuery.loading) {
          onError(saveGroupQuery.error);
        }
      }
    }, [saveGroupQuery.loading, saveGroupQuery.data, saveGroupQuery.error]);

    const onChange = (e) => {
      const group = {
        ...selectedGroup,
      };

      group[e.target.name] = e.target.value;

      setSelectedGroup({ ...group });
    };

    const removeCondition = (id) => {
      const currentSelectedGroup = JSON.parse(JSON.stringify(selectedGroup));
      // let conditions = [...currentSelectedGroup.conditions];
      // if (conditions.length === 1) {
      //   return;
      // }
      // conditions.splice(index, 1);
      // setSelectedGroup({
      //   ...selectedGroup,
      //   conditions,
      // });
      const arr = id.split(".");
      let lookupArr = currentSelectedGroup.conditions;
      let newId = "";
      arr.forEach((idx, index) => {
        if (index !== arr.length - 1) {
          lookupArr = lookupArr[idx].conditions;
          if (newId === "") {
            newId = `${idx}`;
          } else {
            newId += `.${idx}`;
          }
        }
      });
      const foundItemIndex = _.findIndex(lookupArr, { id });
      lookupArr.splice(foundItemIndex, 1);
      setSelectedGroup(currentSelectedGroup);
    };

    const onChangeCriteria = (id, tab) => {
      const currentSelectedGroup = JSON.parse(JSON.stringify(selectedGroup));
      const arr = id.split(".");
      let lookupArr = currentSelectedGroup.conditions;
      let newId = "";
      arr.forEach((idx, index) => {
        if (index !== arr.length - 1) {
          lookupArr = lookupArr[idx];
          if (newId === "") {
            newId = `${idx}`;
          } else {
            newId += `.${idx}`;
          }
        }
      });
      if (lookupArr) {
        console.log(lookupArr);
        lookupArr.mainCriteria = tab === "AND" ? "$and" : "$or";
        setSelectedGroup(currentSelectedGroup);
      }
    };

    const onChangeMainCriteria = (tab) => {
      const currentSelectedGroup = JSON.parse(JSON.stringify(selectedGroup));
      currentSelectedGroup.mainCriteria = tab === "AND" ? "$and" : "$or";
      setSelectedGroup(currentSelectedGroup);
    };

    const editFieldCondition = (id, field, value) => {
      const currentSelectedGroup = JSON.parse(JSON.stringify(selectedGroup));
      const arr = id.split(".");
      let lookupArr = currentSelectedGroup.conditions;
      let newId = "";
      arr.forEach((idx, index) => {
        if (index !== arr.length - 1) {
          lookupArr = lookupArr[idx].conditions;
          if (newId === "") {
            newId = `${idx}`;
          } else {
            newId += `.${idx}`;
          }
        }
      });
      console.log(lookupArr);
      const foundItem = _.find(lookupArr, { id });
      if (foundItem) {
        foundItem[field] = value;
      }

      setSelectedGroup(currentSelectedGroup);

      console.log(JSON.stringify(selectedGroup));
    };

    const editField = (field, value) => {
      setSelectedGroup({
        ...selectedGroup,
        [field]: value,
      });
    };

    const addBlankCondition = (id) => {
      const currentSelectedGroup = JSON.parse(JSON.stringify(selectedGroup));
      if (!id) {
        currentSelectedGroup.conditions.push({
          mainCriteria:
            currentSelectedGroup.mainCriteria === "Any" ? "OR" : "AND",
          conditions: [
            {
              id: `${currentSelectedGroup.conditions.length}.1`,
              masterField: null,
              comparisonOperator: null,
              value: null,
            },
          ],
        });
      } else {
        const arr = id.split(".");
        let lookupArr = currentSelectedGroup.conditions;
        let newId = "";
        arr.forEach((idx, index) => {
          if (index !== arr.length - 1) {
            lookupArr = lookupArr[idx].conditions;
            if (newId === "") {
              newId = `${idx}`;
            } else {
              newId += `.${idx}`;
            }
          }
        });
        console.log(lookupArr);
        lookupArr.push({
          id: `${newId}.${lookupArr.length + 1}`,
          masterField: null,
          comparisonOperator: null,
          value: null,
        });
      }
      setSelectedGroup(currentSelectedGroup);
    };

    return (
      <WrappedComponent
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        saveGroup={() => {
          console.log(selectedGroup);
          // saveGroup({ variables: { ...selectedGroup } });
          // history.replace("/groups");
        }}
        onChange={onChange}
        masterData={masterDataQuery.data ? masterDataQuery.data.masterData : []}
        addBlankCondition={addBlankCondition}
        removeCondition={removeCondition}
        editFieldCondition={editFieldCondition}
        editField={editField}
        onChangeCriteria={onChangeCriteria}
        onChangeMainCriteria={onChangeMainCriteria}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        outboundVariables={
          masterData?.outboundVariables ? masterData.outboundVariables : []
        }
      />
    );
  };
};

export default withGroupFormLogic;
