import { useState, useEffect } from "react";
import _ from "lodash";
import { GET_TOTES } from "#queries";
import { useQuery } from "#hooks/useQuery";
import Autocomplete from "#components/utils/Autocomplete";

const BarcodeForm = ({ onClose, title, onChange, onSubmit, text, value }) => {
  const [totes, setTotes] = useState([]);
  const [selectedTote, setSelectedTote] = useState(null);
  const totesQuery = useQuery(GET_TOTES);
  const onChangeToteSearch = (keyword) => {
    totesQuery.fetchData({
      perPage: 10,
      pageNumber: 1,
      filters: {
        keyword,
      },
      paginated: false,
    });
  };
  const debouncedToteSearch = _.debounce(onChangeToteSearch, 500);

  useEffect(() => {
    if (totesQuery.data) {
      setTotes(totesQuery.data.totes.entities);
    }

    if (totesQuery.error) {
      setTotes([]);
    }
  }, [totesQuery.data, totesQuery.error]);

  useQuery(() => {
    totesQuery.fetchData({
      perPage: 10,
      pageNumber: 1,
      paginated: false,
    });
  }, []);

  return (
    <div className="space-y-4">
      <div>
        <Autocomplete
          options={totes}
          labelKey="barcode"
          valueKey="barcode"
          placeholder="Search Tote"
          onChange={(e) => {
            setSelectedTote(e);
            onChange(e);
          }}
          onKeyDown={(e) =>
            e.key === "Enter" ? onSubmit() : debouncedToteSearch(e)
          }
          id="TOTE"
          value={selectedTote}
        />
      </div>
    </div>
  );
};

export default BarcodeForm;
