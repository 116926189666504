import React, { useState, useEffect } from "react";
import { PieChart, Pie, Legend, Tooltip } from "recharts";
import { useCubeQuery } from "@cubejs-client/react";
import Stats from "#components/common/Stats";
import DetailedStat from "#components/inventoryDashboard/DetailedStat";
import ReactTooltip from "react-tooltip";
import { makeDetailedStatQueries } from "#components/inventoryDashboard/queries";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import _ from "lodash";

const LabelColorShades = [
  "#B3BFCA",
  "#textWhite",
  "#2C7695",
  "#primaryAccent",
  "#1D3752",
];

const ShelfTimeCategories = [
  {
    name: "<15 Days",
    key: "Skubinmappings.shelf_time_count_less_than_15days",
    statKey: "SHELF_TIME_LT_15",
    fill: LabelColorShades[0],
  },
  {
    name: "15 - 30 Days",
    key: "Skubinmappings.shelf_time_count_15_to_30days",
    statKey: "SHELF_TIME_15_30",
    fill: LabelColorShades[1],
  },
  {
    name: "30 - 45 Days",
    key: "Skubinmappings.shelf_time_count_30_to_45days",
    statKey: "SHELF_TIME_30_45",
    fill: LabelColorShades[2],
  },
  {
    name: "45 - 60 Days",
    key: "Skubinmappings.shelf_time_count_45_to_60days",
    statKey: "SHELF_TIME_45_60",
    fill: LabelColorShades[3],
  },
  {
    name: ">60 Days",
    key: "Skubinmappings.shelf_time_count_greater_than_60days",
    statKey: "SHELF_TIME_GT_60",
    fill: LabelColorShades[4],
  },
];
const DocToStockTimeCategories = [
  {
    name: "<2 Days",
    key: "Productvariants.received_ageing_sku_count_less_than_2days",
    statKey: "RECEIVED_AGE_LT_2",
    fill: LabelColorShades[0],
  },
  {
    name: "2 - 4 Days",
    key: "Productvariants.received_ageing_sku_count_2_to_4day",
    statKey: "RECEIVED_AGE_2_4",
    fill: LabelColorShades[1],
  },
  {
    name: "4 - 7 Days",
    key: "Productvariants.received_ageing_sku_count_4_to_7day",
    statKey: "RECEIVED_AGE_4_7",
    fill: LabelColorShades[2],
  },
  {
    name: "7 - 14 Days",
    key: "Productvariants.received_ageing_sku_count_7_to_14day",
    statKey: "RECEIVED_AGE_7_14",
    fill: LabelColorShades[3],
  },
  {
    name: ">14 Days",
    key: "Productvariants.received_ageing_sku_count_greater_than_14day",
    statKey: "RECEIVED_AGE_GT_14",
    fill: LabelColorShades[4],
  },
];
const InventoryAgeTimeCategories = [
  {
    name: "<2 Days",
    key: "Productvariants.inventory_age_count_less_than_2days",
    statKey: "INVENTORY_AGE_LT_2",
    fill: LabelColorShades[0],
  },
  {
    name: "2 - 4 Days",
    key: "Productvariants.inventory_age_count_2_to_4day",
    statKey: "INVENTORY_AGE_2_4",
    fill: LabelColorShades[1],
  },
  {
    name: "4 - 7 Days",
    key: "Productvariants.inventory_age_count_4_to_7day",
    statKey: "INVENTORY_AGE_4_7",
    fill: LabelColorShades[2],
  },
  {
    name: "7 - 14 Days",
    key: "Productvariants.inventory_age_count_7_to_14day",
    statKey: "INVENTORY_AGE_7_14",
    fill: LabelColorShades[3],
  },
  {
    name: ">14 Days",
    key: "Productvariants.inventory_age_count_greater_than_14day",
    statKey: "INVENTORY_AGE_GT_14",
    fill: LabelColorShades[4],
  },
];

const InventoryStats = ({
  dateRange,
  customDate,
  tenantId,
  customers,
  warehouses,
}) => {
  const [selectedStatDetail, setSelectedStatDetail] = useState(null);
  const [selectedStatQuery, setSelectedStatQuery] = useState(null);
  const [skuSearchFilter, setSkuSearchFilter] = useState("");
  const isCustomDate = dateRange === "Custom Date";
  const appliedDateRange = isCustomDate
    ? { dateRange: [customDate.start, customDate.end] }
    : { dateRange };

  const { resultSet: shelfTimeResultSet } = useCubeQuery({
    measures: [
      "Skubinmappings.shelf_time_count_less_than_15days",
      "Skubinmappings.shelf_time_count_15_to_30days",
      "Skubinmappings.shelf_time_count_30_to_45days",
      "Skubinmappings.shelf_time_count_45_to_60days",
      "Skubinmappings.shelf_time_count_greater_than_60days",
    ],
    timeDimensions: [],
    order: {},
    limit: 5000,

    filters: [
      {
        member: "Skubinmappings.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "Skubinmappings.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "Skubinmappings.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  });

  const { resultSet: docToStockResultTime } = useCubeQuery({
    measures: [
      "Productvariants.received_ageing_sku_count_less_than_2days",
      "Productvariants.received_ageing_sku_count_2_to_4day",
      "Productvariants.received_ageing_sku_count_4_to_7day",
      "Productvariants.received_ageing_sku_count_7_to_14day",
      "Productvariants.received_ageing_sku_count_greater_than_14day",
    ],
    timeDimensions: [],
    order: {
      "Productvariants.last_received": "asc",
    },

    filters: [
      {
        member: "Productvariants.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "Productvariants.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "Productvariants.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  });

  const { resultSet: inventoryAgeResultTime } = useCubeQuery({
    measures: [
      "Productvariants.inventory_age_count_less_than_2days",
      "Productvariants.inventory_age_count_2_to_4day",
      "Productvariants.inventory_age_count_4_to_7day",
      "Productvariants.inventory_age_count_7_to_14day",
      "Productvariants.inventory_age_count_greater_than_14day",
    ],
    timeDimensions: [],
    order: {
      "Productvariants.last_received": "asc",
    },

    filters: [
      {
        member: "Productvariants.last_received_str",
        operator: "set",
      },
      {
        member: "Productvariants.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "Productvariants.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "Productvariants.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  });

  React.useEffect(() => {
    setSelectedStatQuery(
      makeDetailedStatQueries(
        dateRange,
        {
          ...customDate,
        },
        skuSearchFilter,
        tenantId,
        customers,
        warehouses,
      ).find((query) => query.statKey === selectedStatDetail),
    );
  }, [dateRange, selectedStatDetail, skuSearchFilter]);
  const CustomTooltip = (params) => {
    const { active, payload, label } = params;

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white">
          <p className="label">{`SKUs with invenory age in ${payload[0].name} : ${payload[0].value}`}</p>
          <p className="desc"></p>
        </div>
      );
    }

    return null;
  };
  const onChangeSkuSearch = (e) => setSkuSearchFilter(e);

  const debouncedSkuSearch = _.debounce(onChangeSkuSearch, 1000);

  return selectedStatDetail && selectedStatQuery ? (
    <DetailedStat
      title={selectedStatQuery?.title}
      onClose={() => {
        setSelectedStatDetail(null);
        setSkuSearchFilter("");
      }}
      stat={selectedStatQuery}
      onChangeSearchFilter={debouncedSkuSearch}
    />
  ) : (
    <div>
      <Stats
        stats={[
          {
            name: "Total Active SKUs",
            query: {
              measures: ["Productvariants.count"],
              timeDimensions: [],
              order: {
                "Productvariants.last_received": "asc",
              },
              filters: [
                {
                  member: "Productvariants.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "Productvariants.customer",
                  operator: "equals",
                  values: customers,
                },
                // {
                //   member: "Productvariants.warehouse",
                //   operator: "equals",
                //   values: warehouses,
                // },
              ],
            },
            setShowView: (view) => setSelectedStatDetail(view),
            view: "ACTIVE_SKU",
          },
          {
            name: "Stock",
            query: {
              dimensions: [],
              timeDimensions: [],
              order: {},
              measures: ["Skubinmappings.inventory_count"],
              filters: [
                {
                  member: "Skubinmappings.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "Skubinmappings.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "Skubinmappings.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
            //setShowView: (view) => setSelectedStatDetail(view),
            view: "TOTAL_STOCK",
          },
          {
            name: `Total Putaway ${
              isCustomDate ? "at ".concat(customDate) : "in ".concat(dateRange)
            }`,
            query: {
              measures: ["Productvariants.count_total_putaway"],
              timeDimensions: [
                {
                  dimension: "Productvariants.last_putaway",
                  ...appliedDateRange,
                },
              ],
              filters: [
                {
                  member: "Productvariants.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "Productvariants.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "Productvariants.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
            setShowView: (view) => setSelectedStatDetail(view),
            view: "TOTAL_PUTAWAY",
          },

          {
            name: "SKUs left to Putaway",
            query: {
              measures: ["Productvariants.count"],
              timeDimensions: [
                {
                  dimension: "Productvariants.last_received",
                  ...appliedDateRange,
                },
              ],
              segments: ["Productvariants.skus_awaiting_putaway"],
              filters: [
                {
                  member: "Productvariants.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "Productvariants.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "Productvariants.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
            setShowView: (view) => setSelectedStatDetail(view),
            view: "AWAITING_PUTAWAY",
          },
          {
            name: `Received SKUs ${
              isCustomDate ? "at ".concat(customDate) : "in ".concat(dateRange)
            }`,
            query: {
              measures: ["Productvariants.count_received_skus"],
              timeDimensions: [
                {
                  dimension: "Productvariants.last_received",
                  ...appliedDateRange,
                },
              ],
              filters: [
                {
                  member: "Productvariants.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "Productvariants.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "Productvariants.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
            setShowView: (view) => setSelectedStatDetail(view),
            view: "TOTAL_RECEIVED",
          },
          {
            name: "Avg. Doc to Stock time (Days)",
            query: {
              measures: ["Productvariants.avg_sku_putaway_time_in_days"],
              timeDimensions: [],
              filters: [
                {
                  member: "Productvariants.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "Productvariants.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "Productvariants.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
            //setShowView: (view) => setSelectedStatDetail(view),
            view: "AVG_DOC_TO_STOCK",
          },
          {
            name: "Avg. Shelf Time (Days)",
            query: {
              measures: ["Productvariants.avg_sku_fulfilled_time_in_days"],
              timeDimensions: [],
              filters: [
                {
                  member: "Productvariants.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "Productvariants.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "Productvariants.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
            //setShowView: (view) => setSelectedStatDetail(view),
            view: "AVG_SHELF",
          },
        ]}
      />
      <div className="grid w-full grid-cols-1 gap-4 xl:grid-cols-2 inventoryDashboard3Cols:grid-cols-3">
        <div className="h-max w-max rounded-lg bg-white shadow-lg">
          <div className="mb-2 mt-8 flex">
            <div className="m-auto flex w-max">
              <span className="text-center text-xl font-medium">
                Shelf Time
              </span>
              <button
                data-tip
                data-for="shelfTimeTip"
                className="ml-1 mt-1 h-5 w-5 border-none bg-white">
                <QuestionMarkCircleIcon className="h-5 w-5 cursor-pointer" />
              </button>
              <ReactTooltip
                id="shelfTimeTip"
                place="top"
                type="info"
                effect="solid">
                The duration since a SKU was put into a bin
              </ReactTooltip>
            </div>
          </div>
          <PieChart width={500} height={450}>
            <Pie
              className="cursor-pointer"
              onClick={(e) => setSelectedStatDetail(e.statKey)}
              dataKey="value"
              isAnimationActive={false}
              data={ShelfTimeCategories.map((item) => ({
                ...item,
                value: shelfTimeResultSet?.loadResponse?.results[0]?.data[0]
                  ? shelfTimeResultSet?.loadResponse?.results[0]?.data[0][
                      item.key
                    ]
                  : 0,
              })).filter((item) => item.value > 0)}
              cx="50%"
              cy="50%"
              outerRadius={180}
              innerRadius={100}
              fill="#8884d8"
              label={renderCustomizedLabel}
              labelLine
            />
            <Tooltip />
          </PieChart>
          <div className="mx-auto flex w-[400px] flex-wrap justify-evenly gap-1">
            {LabelColorShades.map((color, index) => ({
              fill: color,
              name: ShelfTimeCategories[index].name,
            })).map((label) => (
              <span className="flex gap-1">
                <span
                  className="my-auto h-4 w-4"
                  style={{ backgroundColor: label.fill }}>
                  {" "}
                </span>
                <p className="my-auto">{label.name}</p>
              </span>
            ))}
          </div>
        </div>
        <div className="h-max w-max rounded-lg bg-white shadow-lg">
          <div className="mb-2 mt-8 flex">
            <div className="m-auto flex w-max">
              <span className="text-center text-xl font-medium">
                Inventory Age
              </span>
              <button
                data-tip
                data-for="inventoryAgeTip"
                className="ml-1 mt-1 h-5 w-5 border-none bg-white">
                <QuestionMarkCircleIcon className="h-5 w-5 cursor-pointer" />
              </button>
              <ReactTooltip
                id="inventoryAgeTip"
                place="top"
                type="info"
                effect="solid">
                Interval between date the sku was last recieved and date when it
                was last shipped (if the sku has been shipped) or current date
                (if the sku has not been shipped yet)
              </ReactTooltip>
            </div>
          </div>
          <PieChart width={500} height={450}>
            <Pie
              className="cursor-pointer"
              onClick={(e) => setSelectedStatDetail(e.statKey)}
              dataKey="value"
              isAnimationActive={false}
              data={InventoryAgeTimeCategories.map((item) => ({
                ...item,
                value: inventoryAgeResultTime?.loadResponse?.results[0]?.data[0]
                  ? inventoryAgeResultTime?.loadResponse?.results[0]?.data[0][
                      item.key
                    ]
                  : 0,
              })).filter((item) => item.value > 0)}
              cx="50%"
              cy="50%"
              outerRadius={180}
              innerRadius={100}
              fill="#8884d8"
              labelLine
              label={renderCustomizedLabel}
            />
            <Tooltip />
          </PieChart>
          <div className="mx-auto flex w-[400px] flex-wrap justify-evenly gap-1">
            {LabelColorShades.map((color, index) => ({
              fill: color,
              name: InventoryAgeTimeCategories[index].name,
            })).map((label) => (
              <span className="flex gap-1">
                <span
                  className="my-auto h-4 w-4"
                  style={{ backgroundColor: label.fill }}>
                  {" "}
                </span>
                <p className="my-auto">{label.name}</p>
              </span>
            ))}
          </div>
        </div>
        <div className="h-max w-max rounded-lg bg-white shadow-lg">
          <div className="mb-2 mt-8 flex">
            <div className="m-auto flex w-max">
              <span className="text-center text-xl font-medium">
                Received Age
              </span>
              <button
                data-tip
                data-for="receivedAgeTip"
                className="ml-1 mt-1 h-5 w-5 border-none bg-white">
                <QuestionMarkCircleIcon className="h-5 w-5 cursor-pointer" />
              </button>
              <ReactTooltip
                id="receivedAgeTip"
                place="top"
                type="info"
                effect="solid">
                The time interval between when a SKU was received from
                consignment and when the SKU was put away in the warehouse
              </ReactTooltip>
            </div>
          </div>
          <PieChart width={500} height={450}>
            <Pie
              className="cursor-pointer"
              onClick={(e) => setSelectedStatDetail(e.statKey)}
              dataKey="value"
              isAnimationActive={false}
              data={DocToStockTimeCategories.map((item) => ({
                ...item,
                value: docToStockResultTime?.loadResponse?.results[0]?.data[0]
                  ? docToStockResultTime?.loadResponse?.results[0]?.data[0][
                      item.key
                    ]
                  : 0,
              })).filter((item) => item.value > 0)}
              cx="50%"
              cy="50%"
              outerRadius={180}
              innerRadius={100}
              fill="#8884d8"
              labelLine
              label={renderCustomizedLabel}
            />
            <Tooltip />
          </PieChart>
          <div className="mx-auto flex w-[400px] flex-wrap justify-evenly gap-1">
            {LabelColorShades.map((color, index) => ({
              fill: color,
              name: DocToStockTimeCategories[index].name,
            })).map((label) => (
              <span className="flex gap-1">
                <span
                  className="my-auto h-4 w-4"
                  style={{ backgroundColor: label.fill }}>
                  {" "}
                </span>
                <p className="my-auto">{label.name}</p>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryStats;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  index,
}) => {
  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  // eslint-disable-next-line
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // eslint-disable-next-line
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central">
      {value}
    </text>
  );
};
