import Barcode from "react-barcode";

const FnSkuLabel2 = ({ id, value, text1, text2 }) => (
  <div
    id={`${id}_FNSKU`}
    style={{
      backgroundColor: "#fff",
      fontFamily: "Arial",
      fontSize: 16,
      textAlign: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    }}>
    <Barcode
      value={value}
      fontSize={20}
      height={40}
      font="Arial"
      textMargin={-2}
      fontOptions="bold"
    />
    <div className="-mt-1 ml-2 items-start text-left font-bold">
      {text1 && <div>{text1}</div>}
      {text2 && <div className="-mt-2">{text2}</div>}
    </div>
  </div>
);

export default FnSkuLabel2;
