import moment from "moment-timezone";
import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";

const DevicesList = ({
  devices,
  editButtonClicked,
  deleteButtonClicked,
  passwordEditable,
  headers,
  noValuesText,
  customers,
  warehouses,
}) => {
  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="rounded-full p-4">
          <tr className="font-montserratborder-left text-primaryAccent">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {devices.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {devices.map((device, index) => (
            <tr
              key={device.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {device.name}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {device.deviceType}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {device.hardwareId}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {device.warehouses
                  ? warehouses
                      .filter((item) => device.warehouses.includes(item.id))
                      .map((item) => item.name)
                      .join(", ")
                  : ""}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {device.customers
                  ? customers
                      .filter((item) => device.customers.includes(item.id))
                      .map((item) => item.name)
                      .join(", ")
                  : ""}
              </td>
              <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                <div className="flex items-center space-x-4">
                  <TableFieldButton
                    onClick={() => editButtonClicked(device)}
                    text={<PencilIcon className="h-6 w-6" />}
                  />
                  <TableFieldButton
                    text={<TrashIcon className="h-6 w-6" />}
                    onClick={() => deleteButtonClicked(device.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DevicesList;
