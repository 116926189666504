import React, { useState } from "react";
import Modal from "#components/utils/Modal";

const ReportPreview = ({
  onClose,
  title,
  onSubmit,
  fetchedReport,
  previewData,
}) => {
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      positiveAction={onSubmit}
      xIconClicked={onClose}>
      <div className="space-y-4">
        <div>
          <div className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            {fetchedReport.reportName}
          </div>
        </div>
        <div className="pb-4 font-montserrat text-sm font-medium text-454A4F">
          This is a preview of the report. Only a maximum of the first 30 rows
          will be shown.
        </div>
        <div className="h-96 overflow-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {previewData[0] &&
                  previewData[0].map((item) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                      {item}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {previewData &&
                previewData.slice(1).map((row) => (
                  <tr>
                    {row.map((item) => (
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        {item}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default ReportPreview;
