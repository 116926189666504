import Barcode from "react-barcode";

const TwoQuarterByOneQuarterLabel = ({ id, value }) => (
  <div
    style={{
      width: 216,
      height: 120,
      textAlign: "center",
      backgroundColor: "#fff",
      paddingLeft: 2,
      paddingRight: 2,
      top: 200,
      left: 0,
      zIndex: 50,
      marginLeft: -2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    }}
    id={`${id}_2.25x1.25`}>
    <Barcode
      value={value}
      height={75}
      fontSize={30}
      font="Arial"
      textMargin={-2}
      fontOptions="bold"
    />
  </div>
);

export default TwoQuarterByOneQuarterLabel;
