const MessageDisplay = ({ children, border, customStyles }) => {
  let additionalStyles = "";
  if (border) {
    additionalStyles += " border border-primaryAccent";
  }
  if (customStyles) {
    additionalStyles += ` ${customStyles}`;
  }
  return (
    <div className={`rounded-lg bg-blue-100 ${additionalStyles}`}>
      {children}
    </div>
  );
};
export default MessageDisplay;
