import React, { useState, useEffect, useRef } from "react";

const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useRef();

  useEffect(() => {
    callback.current = effect;
  }, [effect]);

  useEffect(() => {
    const handler = () => {
      callback.current();
    };

    if (delay) {
      const id = setTimeout(handler, delay);
      return () => clearTimeout(id);
    }
    return undefined;
  }, [...(deps || []), delay]);
};

export default useDebouncedEffect;
