import { useState } from "react";
import ScanOrEnterManually from "#components/putaway/consignment/ScanOrEnterManually";
import ConsignmentsList from "#components/putaway/consignment/ConsignmentsList";
import { Tab } from "@headlessui/react";

const ConsignmentSelection = ({
  customers,
  scanBarcode,
  currentScan,
  consignmentsData,
  checkConsignmentById,
  consignmentStatusList,
  consignmentFilters,
  setConsignmentFilters,
  submitConsignmentFilters,
  checkConsignmentsPagination,
  cancelCurrentCustomerAndTracking,
  skipConsignmentSelection,
}) => {
  const [selectedIndexTop, setSelectedIndexTop] = useState(0);
  const tabs = {
    "Detail View": (
      <ScanOrEnterManually
        text="Scan a Tracking Number"
        onSubmit={scanBarcode}
        onSkip={skipConsignmentSelection}
        onCancel={() =>
          cancelCurrentCustomerAndTracking("CONSIGNMENT_SELECTION")
        }
      />
    ),
    "List View": (
      <ConsignmentsList
        consignmentsData={consignmentsData}
        checkConsignmentById={checkConsignmentById}
        filters={consignmentFilters}
        setFilters={setConsignmentFilters}
        submitFilters={submitConsignmentFilters}
        checkPagination={checkConsignmentsPagination}
        statusList={consignmentStatusList}
      />
    ),
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="left-0 w-full bg-black text-center text-2xl text-white">
        {customers.find((item) => item.id === currentScan.customer)?.name}
      </div>

      <div className="flex-col pt-36">
        <Tab.Group
          selectedIndex={selectedIndexTop}
          onChange={setSelectedIndexTop}>
          <Tab.List className="flex items-center justify-center">
            <div className="space-x-2 rounded-full bg-primaryAccent p-2">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "font-regular rounded-full px-6 py-2.5 leading-5",
                        "font-montserrat text-lg focus:outline-none focus:ring-0",
                        selected
                          ? "bg-white font-medium text-primaryAccent"
                          : "rounded-full border border-primaryAccent bg-transparent text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="flex flex-col items-center bg-transparent p-4">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default ConsignmentSelection;
