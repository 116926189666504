import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { AppStateContext } from "#contexts/appState";
import PropTypes from "prop-types";
import _ from "lodash";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";
// import RateShoppingLoader from "./RateShoppingLoader";
import { getPreferredMetricsUnit } from "../../utils/Metrics";
import RateShoppingLtlPallet from "#components/rateShopping/RateShoppingLtlPallet";
import { GET_SHIPMENT_RATES } from "#queries/index";
import { twMerge } from "tailwind-merge";
const ALERT_VISIBILITY_IN_MS = 3000;
import NewRateShoppingLtlPallets from "./NewRateShoppingLtlPallets";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import noResultsImage from "../../static/images/nofilter.png";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import RateShoppingLoader from "#components/rateShopping/RateShoppingLoader";
import dayjs from "dayjs"; // Import dayjs

/**
 * Finds the cheapest rate from an array of shipment rates.
 */
const getCheapestRate = (shipmentRates) => {
  if (shipmentRates.length === 0) return null;
  return shipmentRates.reduce((cheapestRate, currentRate) => {
    if (!currentRate.price) return cheapestRate;
    return currentRate.price < (cheapestRate?.price || Infinity)
      ? currentRate
      : cheapestRate;
  }, null);
};

/**
 * Finds the fastest rate (based on estimated delivery days).
 */
const getFastestRate = (shipmentRates) => {
  if (shipmentRates.length === 0) return null;
  return shipmentRates.reduce((fastestRate, currentRate) => {
    if (!currentRate.estimated_delivery_days) return fastestRate;
    if (!fastestRate) return currentRate;
    return currentRate.estimated_delivery_days <
      fastestRate.estimated_delivery_days
      ? currentRate
      : fastestRate;
  }, null);
};

/**
 * Determines the best value rate by combining low price and fast delivery.
 */
const getBestValueRates = (shipmentRates) => {
  const sortedRates = shipmentRates.sort(
    (a, b) =>
      a.price - b.price ||
      a.estimated_delivery_days - b.estimated_delivery_days,
  );
  return sortedRates.slice(0, 3); // Assuming top 3 for best value
};

/**
 * Format and categorize shipment rates with priority:
 * 1. Default (if provided)
 * 2. Best Value (balance between price and speed)
 * 3. Best Rate (lowest cost)
 * 4. Fastest Delivery (quickest delivery time)
 * 5. Rest of the Rates
 */
const formatRates = (shipmentRates, preSelectedRate) => {
  if (!shipmentRates.length) return {};

  // Get the best rates based on price, delivery time, and value
  const cheapestRate = getCheapestRate(shipmentRates); // Best Rate
  const fastestRate = getFastestRate(shipmentRates); // Fastest Delivery
  const bestValueRates = getBestValueRates(shipmentRates); // Best Value

  // Keep track of rates already used to prevent duplication
  const usedRateIds = new Set();

  // 1. Default Rate (if any)
  const defaultRate = preSelectedRate || null;
  if (defaultRate) {
    usedRateIds.add(defaultRate.id);
  }

  // 2. Best Value (choose a reasonable compromise between price and speed)
  const bestValue = bestValueRates.filter((rate) => !usedRateIds.has(rate.id));
  bestValue.forEach((rate) => usedRateIds.add(rate.id));

  // 3. Best Rate (lowest cost, if it's not already selected)
  const bestRate = !usedRateIds.has(cheapestRate?.id) ? cheapestRate : null;
  if (bestRate) {
    usedRateIds.add(bestRate.id);
  }

  // 4. Fastest Delivery (earliest delivery time, if not already selected)
  const fastestDelivery = !usedRateIds.has(fastestRate?.id)
    ? fastestRate
    : null;
  if (fastestDelivery) {
    usedRateIds.add(fastestDelivery.id);
  }

  // 5. Rest Rates (all others that have not been used)
  const restRates = shipmentRates.filter((rate) => !usedRateIds.has(rate.id));

  // Return the categorized rates
  return {
    defaultRate: defaultRate || null,
    bestRate: bestRate || null,
    fastestDelivery: fastestDelivery || null,
    bestValue: bestValue || [],
    restRates: restRates || [],
  };
};

const labelClass = {
  Default: "text-blue-800 bg-blue-100",
  "Best Rate": "text-green-800 bg-green-100",
  Fastest: "text-blue-800 bg-blue-100",
  "Best Value": "text-purple-800 bg-purple-100",
  Other: "text-yellow-800 bg-yellow-100",
};

const NewRateShopping = ({
  order,
  preSelectedCarrierRate,
  customer,
  warehouse,
  shippingAddress,
  boxes = [],
  pallets,
  orderSource,
  storedTransportMode,
  shipmentReference,
  onPalletSubmit,
  onRateSelect,
  rate,
  validateAddress,
  transportMode,
  carrierIntegration,
  selectedInsurance,
  setShowRateShoppingSlideOver,
}) => {
  const shipmentRatesQuery = useQuery(GET_SHIPMENT_RATES);
  //   const appState = useContext(AppStateContext);
  const [formattedRates, setFormattedRates] = useState({});
  const [palletInfo, setPalletInfo] = useState([]);
  const [errors, setErrors] = useState([]);
  const [ratesApiError, setRatesApiError] = useState(null);
  const [selectedRate, setSelectedRate] = useState(rate);
  const { preferredDimensionUnit, preferredWeightUnit } =
    getPreferredMetricsUnit();

  const segregateAndFormatRates = (rates, preSelectedCarrierRateObj) => {
    const formatted = formatRates(rates, preSelectedCarrierRateObj);
    setFormattedRates(formatted);

    const defaultSelectedRate =
      formatted?.defaultRate ||
      (formatted?.bestValue && formatted.bestValue[0]) ||
      formatted?.bestRate ||
      formatted?.fastestDelivery ||
      (formatted?.restRates && formatted?.restRates[0]) ||
      null;

    setSelectedRate({
      selectedShipmentRateId: defaultSelectedRate?.id || null,
      selectedShipmentSource: defaultSelectedRate?.source || null,
      transportMode,
      selectedCarrierRate: defaultSelectedRate,
      carrier:
        `${defaultSelectedRate?.carrier} - ${defaultSelectedRate?.service}` ||
        null,
    });
  };

  const fetchShipmentRates = async () => {
    setRatesApiError(null);
    setFormattedRates({});
    setErrors([]);

    if (transportMode === "LTL" && palletInfo.length === 0) {
      segregateAndFormatRates([], preSelectedCarrierRate);
      return;
    }

    if (boxes?.length === 0) {
      return;
    }

    const shipmentRatesQueryResponse = await shipmentRatesQuery.fetchData({
      customer,
      warehouse,
      toAddress: shippingAddress,
      boxes: boxes.map((box) => ({
        ...box,
        dimensionUnit: preferredDimensionUnit,
        weightUnit: preferredWeightUnit,
      })),
      pallets: palletInfo.map((pallet) => ({
        ...pallet,
        dimensionUnit: preferredDimensionUnit,
        weightUnit: preferredWeightUnit,
      })),
      orderSource,
      transportMode,
      shipmentReference,
      validateAddress,
      incoterms: selectedInsurance,
    });
    return shipmentRatesQueryResponse;
  };

  useEffect(() => {
    setPalletInfo(pallets || []);
    fetchShipmentRates();
  }, [pallets]);

  useEffect(() => {
    if (shipmentRatesQuery.data) setRatesApiError(null);
  }, [shipmentRatesQuery.data]);

  useEffect(() => {
    if (shipmentRatesQuery.error) {
      setRatesApiError(shipmentRatesQuery.error);
    }

    if (shipmentRatesQuery.data) {
      segregateAndFormatRates(
        shipmentRatesQuery.data.getShipmentRates.rates,
        preSelectedCarrierRate,
      );
      setErrors(shipmentRatesQuery.data.getShipmentRates.errors);
      setRatesApiError(null);
    }
  }, [
    shipmentRatesQuery.data,
    shipmentRatesQuery.loading,
    shipmentRatesQuery.error,
  ]);

  const onPalletSubmitEvent = (pallets) => {
    const validatedPallets = pallets.map((pallet) => {
      return {
        ...pallet,
        length: parseFloat(pallet.length),
        width: parseFloat(pallet.width),
        height: parseFloat(pallet.height),
        weight: parseFloat(pallet.weight),
      };
    });
    setPalletInfo(validatedPallets);
    onPalletSubmit && onPalletSubmit(validatedPallets, fetchShipmentRates);
  };

  // Helper function to check if there are any valid rates in formattedRates
  const hasValidRates = (rates) => {
    return (
      (rates.defaultRate && Object.keys(rates.defaultRate).length > 0) ||
      (rates.bestRate && Object.keys(rates.bestRate).length > 0) ||
      (rates.restRates && rates.restRates.length > 0) ||
      (rates.bestValue && rates.bestValue.length > 0) ||
      (rates.fastestDelivery && Object.keys(rates.fastestDelivery).length > 0)
    );
  };

  return (
    <div className="flex h-full flex-col px-10 font-inter">
      {/* Pallet handling for LTL */}
      {/* {shipmentRatesQuery.loading && <RateShoppingLoader />} */}
      {carrierIntegration && carrierIntegration.carrier && (
        <div>
          <h1 className="mb-4 text-xl font-bold">
            Carrier Details From {orderSource}
          </h1>
          <div className="border-1 mb-4 flex items-center space-x-3 rounded-md border-2 border-[#417492] bg-[#eff6ff] p-3">
            <span className="text-2xl text-blue-400">&#9432;</span>{" "}
            <div>
              <p>
                {carrierIntegration.carrier &&
                  carrierIntegration.carrier.length > 0 && (
                    <>
                      <span className="font-bold">Carrier:</span>{" "}
                      <span>{carrierIntegration.carrier}</span>
                    </>
                  )}
                {carrierIntegration.carrier &&
                  carrierIntegration.carrier.length > 0 &&
                  carrierIntegration.carrierService &&
                  carrierIntegration.carrierService.length > 0 &&
                  ", "}
                {carrierIntegration.carrierService &&
                  carrierIntegration.carrierService.length > 0 && (
                    <>
                      <span className="font-bold">Service:</span>{" "}
                      <span>{carrierIntegration.carrierService}</span>
                    </>
                  )}
              </p>
              {carrierIntegration?.notes && (
                <p className="text-gray-500">{carrierIntegration.notes}</p>
              )}
            </div>
            <br />
          </div>
        </div>
      )}
      <div className="flex flex-col">
        {ratesApiError && transportMode !== "LTL" && (
          <div className="flex w-full flex-col items-center justify-center py-20">
            <img
              src={noResultsImage}
              alt="No results"
              className="mb-4"
              style={{ width: "40%" }}
            />
            <p className="text-center text-base font-semibold text-gray-600">
              {ratesApiError.message}
            </p>
          </div>
        )}
        {ratesApiError && transportMode === "LTL" && (
          <CustomAlert
            id="rateShoppingLtlError"
            type="error"
            message={ratesApiError.message}
          />
        )}
        {errors.map((error, index) => (
          <CustomAlert
            key={index}
            id={"alertRateShoppingErrors" + index}
            type="error"
            message={
              <div>
                <span className="ml-3">{error.source}</span>
                <div className="block">{error?.message}</div>
                <span className="block">
                  <ul className="list-disc pl-10">
                    {error?.errors.map((errorMessage) => (
                      <li key={errorMessage}>{errorMessage}</li>
                    ))}
                  </ul>
                </span>
              </div>
            }
            options={{
              defaultColors: true,
            }}
          />
        ))}
      </div>
      {transportMode === "LTL" && (
        <div>
          <h2 className="mb-4 text-xl font-semibold">Pallet Information</h2>
          <NewRateShoppingLtlPallets
            onPalletSubmit={onPalletSubmitEvent}
            palletInfo={palletInfo}
          />
        </div>
      )}
      {/* Rates Table */}
      {hasValidRates(formattedRates) ? (
        <div>
          <h2 className="mb-4 text-xl font-semibold text-gray-900">
            Carriers & Rates
          </h2>
          <div
            style={{ height: "600px" }}
            className="overflow-x-auto overflow-y-auto">
            <table className="min-w-full table-auto">
              <thead className="sticky top-0 z-5 bg-gray-50">
                <tr>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    Carrier Option
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    Service
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    Rate
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    Delivery ETA
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    Estimated Delivery Date
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    {" "}
                    Provided By
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {/* Default Rate */}
                {formattedRates?.defaultRate && (
                  <tr className="hover:bg-gray-50">
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="rate"
                          className="mr-4 text-2C7695"
                          checked={
                            selectedRate?.selectedShipmentRateId ===
                            formattedRates.defaultRate.id
                          }
                          onChange={() =>
                            setSelectedRate({
                              selectedShipmentRateId:
                                formattedRates.defaultRate.id,
                              selectedShipmentSource:
                                formattedRates.defaultRate.source,
                              transportMode: storedTransportMode,
                              selectedCarrierRate: formattedRates.defaultRate,
                              carrier: `${formattedRates.defaultRate?.carrier} - ${formattedRates.defaultRate?.service}`,
                            })
                          }
                        />
                        <div className="text-sm font-medium text-gray-900">
                          {formattedRates.defaultRate.carrier}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm font-medium text-gray-900">
                        {formattedRates.defaultRate.service}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {getCurrencySymbol(formattedRates.defaultRate.currency)}
                        {formattedRates.defaultRate.price}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {formattedRates.defaultRate.estimated_delivery_days ||
                          "N/A"}{" "}
                        Days
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {formattedRates.defaultRate.estimated_delivery_date &&
                          dayjs(
                            formattedRates.defaultRate.estimated_delivery_date,
                          ).format("YYYY-MM-DD")}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {formattedRates.defaultRate.source || ""}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <span
                        className={`ml-4 inline-block px-3 py-1 text-sm font-light leading-none ${labelClass["Default"]} rounded-full`}>
                        Default
                      </span>
                    </td>
                  </tr>
                )}

                {/* Best Rate */}
                {formattedRates?.bestRate && (
                  <tr className="hover:bg-gray-50">
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="rate"
                          className="mr-4 text-2C7695"
                          checked={
                            selectedRate?.selectedShipmentRateId ===
                            formattedRates.bestRate.id
                          }
                          onChange={() =>
                            setSelectedRate({
                              selectedShipmentRateId:
                                formattedRates.bestRate.id,
                              selectedShipmentSource:
                                formattedRates.bestRate.source,
                              transportMode: storedTransportMode,
                              selectedCarrierRate: formattedRates.bestRate,
                              carrier: `${formattedRates.bestRate?.carrier} - ${formattedRates.bestRate?.service}`,
                            })
                          }
                        />
                        <div className="text-sm font-medium text-gray-900">
                          {formattedRates.bestRate.carrier}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm font-medium text-gray-900">
                        {formattedRates.bestRate.service}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {getCurrencySymbol(formattedRates.bestRate.currency)}
                        {formattedRates.bestRate.price}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {formattedRates.bestRate.estimated_delivery_days ||
                          "N/A"}{" "}
                        Days
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {formattedRates.bestRate.estimated_delivery_date &&
                          dayjs(
                            formattedRates.bestRate.estimated_delivery_date,
                          ).format("YYYY-MM-DD")}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {formattedRates.bestRate.source || ""}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <span
                        className={`ml-4 inline-block px-3 py-1 text-sm font-light leading-none ${labelClass["Best Rate"]} rounded-full`}>
                        Best Rate
                      </span>
                    </td>
                  </tr>
                )}

                {/* Fastest Delivery */}
                {formattedRates?.fastestDelivery && (
                  <tr className="hover:bg-gray-50">
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="rate"
                          className="mr-4 text-2C7695"
                          checked={
                            selectedRate?.selectedShipmentRateId ===
                            formattedRates.fastestDelivery.id
                          }
                          onChange={() =>
                            setSelectedRate({
                              selectedShipmentRateId:
                                formattedRates.fastestDelivery.id,
                              selectedShipmentSource:
                                formattedRates.fastestDelivery.source,
                              transportMode: storedTransportMode,
                              selectedCarrierRate:
                                formattedRates.fastestDelivery,
                              carrier: `${formattedRates.fastestDelivery?.carrier} - ${formattedRates.fastestDelivery?.service}`,
                            })
                          }
                        />
                        <div className="text-sm font-medium text-gray-900">
                          {formattedRates.fastestDelivery.carrier}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm font-medium text-gray-900">
                        {formattedRates.fastestDelivery.service}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {getCurrencySymbol(
                          formattedRates.fastestDelivery.currency,
                        )}
                        {formattedRates.fastestDelivery.price}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {formattedRates.fastestDelivery
                          .estimated_delivery_days || "N/A"}{" "}
                        Days
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {formattedRates.fastestDelivery
                          .estimated_delivery_date &&
                          dayjs(
                            formattedRates.fastestDelivery
                              .estimated_delivery_date,
                          ).format("YYYY-MM-DD")}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {formattedRates.fastestDelivery.source || ""}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <span
                        className={`ml-4 inline-block px-3 py-1 text-sm font-light leading-none ${labelClass["Fastest"]} rounded-full`}>
                        Fastest
                      </span>
                    </td>
                  </tr>
                )}

                {/* Best Value */}
                {formattedRates.bestValue?.map((rate) => (
                  <tr key={rate.id} className="hover:bg-gray-50">
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="rate"
                          className="mr-4 text-2C7695"
                          checked={
                            selectedRate?.selectedShipmentRateId === rate.id
                          }
                          onChange={() =>
                            setSelectedRate({
                              selectedShipmentRateId: rate.id,
                              selectedShipmentSource: rate.source,
                              transportMode: storedTransportMode,
                              selectedCarrierRate: rate,
                              carrier: `${rate?.carrier} - ${rate?.service}`,
                            })
                          }
                        />
                        <div className="text-sm font-medium text-gray-900">
                          {rate.carrier}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm font-medium text-gray-900">
                        {rate.service}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {getCurrencySymbol(rate.currency)}
                        {rate.price}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {rate.estimated_delivery_days || "N/A"} Days
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {rate.estimated_delivery_date &&
                          dayjs(rate.estimated_delivery_date).format(
                            "YYYY-MM-DD",
                          )}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {rate.source || ""}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <span
                        style={{ backgroundColor: "#E9D5FF" }}
                        className={twMerge(
                          `ml-4 inline-block px-3 py-1 text-sm font-light leading-none ${labelClass["Best Value"]} rounded-full`,
                        )}>
                        Best Value
                      </span>
                    </td>
                  </tr>
                ))}

                {/* Other Rates */}
                {formattedRates?.restRates?.map((rate) => (
                  <tr key={rate.id} className="hover:bg-gray-50">
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="rate"
                          className="mr-4 text-2C7695"
                          checked={
                            selectedRate?.selectedShipmentRateId === rate.id
                          }
                          onChange={() =>
                            setSelectedRate({
                              selectedShipmentRateId: rate.id,
                              selectedShipmentSource: rate.source,
                              transportMode: storedTransportMode,
                              selectedCarrierRate: rate,
                              carrier: `${rate?.carrier} - ${rate?.service}`,
                            })
                          }
                        />
                        <div className="text-sm font-medium text-gray-900">
                          {rate.carrier}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm font-medium text-gray-900">
                        {rate.service}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {getCurrencySymbol(rate.currency)}
                        {rate.price}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {rate.estimated_delivery_days || "N/A"} Days
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {dayjs(rate.estimated_delivery_date).format(
                          "YYYY-MM-DD",
                        )}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {rate.source || ""}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <span
                        className={`ml-4 inline-block px-3 py-1 text-sm font-light leading-none ${labelClass["Other"]} rounded-full`}>
                        Other
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : shipmentRatesQuery.loading ? (
        <RateShoppingLoader />
      ) : !ratesApiError ? (
        <div className="flex w-full flex-col items-center justify-center py-20">
          <img
            src={noResultsImage}
            alt="No results"
            className="mb-4"
            style={{ width: "40%" }}
          />
          <p className="text-center text-base font-semibold text-gray-600">
            No results found.
          </p>
        </div>
      ) : null}

      {hasValidRates(formattedRates) && (
        <div className="sticky bottom-0 z-30 flex h-full w-full items-end justify-end bg-white py-4">
          <PrimaryButton
            height="3rem"
            width="7rem"
            className="mt-2 text-lg font-medium"
            onClick={() => {
              setShowRateShoppingSlideOver(false);
            }}>
            Cancel
          </PrimaryButton>
          <PrimaryButton
            height="3rem"
            minWidth="7rem"
            maxWidth="15rem"
            variant="primary"
            className="ml-6 mt-2 text-lg font-medium"
            onClick={() => {
              onRateSelect(selectedRate);
              setShowRateShoppingSlideOver(false);
            }}>
            Confirm
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

NewRateShopping.propTypes = {
  customer: PropTypes.string.isRequired,
  warehouse: PropTypes.string.isRequired,
  shippingAddress: PropTypes.object.isRequired,
  boxes: PropTypes.array.isRequired,
  pallets: PropTypes.array,
  orderSource: PropTypes.string.isRequired,
  storedTransportMode: PropTypes.string.isRequired,
  shipmentReference: PropTypes.string.isRequired,
  onPalletSubmit: PropTypes.func,
  onRateSelect: PropTypes.func.isRequired,
};

export default NewRateShopping;
