import { useState } from "react";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";

const defaultButtonClassName = `outline-none focus:outline-none`;
const defaultPanelClassName = `mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-4 border border-borderGray w-full`;

const PopoverOnTableAction = ({
  title,
  children,
  buttonClassName = defaultButtonClassName,
  panelClassName = defaultPanelClassName,
  staticBackdrop = false,
  showOverlay = false,
  customClassName,
  icon,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover as="div" className="relative">
      {({}) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={[buttonClassName, customClassName]
              .filter(Boolean)
              .join(" ")}
            as="button">
            {/* This button acts as the reference for positioning the popover */}
            <div className="flex">
              {icon}
              {title}
            </div>
          </Popover.Button>

          {showOverlay && (
            <Popover.Overlay className="fixed inset-0 z-10 bg-black opacity-30" />
          )}

          <Popover.Panel
            static={staticBackdrop}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            as="div"
            className={panelClassName}>
            {/* Render the passed children inside the popover */}
            {children}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default PopoverOnTableAction;
