import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_PICKER_BATCH,
  GET_NEW_PICKER_BATCH,
  GET_TOTES,
  GET_WORKFLOW_BY_ATTRIBUTES,
  GET_SKU,
} from "#queries";
import {
  SCAN_PICKER_BARCODE,
  CONFIRM_PICK_ITEM,
  CONFIRM_PICKER_DROPOFF,
  SKIP_PICK_ITEM,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";

const withOrderPickerLogic = (WrappedComponent) => {
  return (props) => {
    // state declarations
    const [customer, setCustomer] = useState(undefined);
    const [workflow, setWorkflow] = useState(undefined);
    const [currentBatch, setCurrentBatch] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [loadingFirstTime, setLoadingFirstTime] = useState(true);
    const [displayScan, setDisplayScan] = useState(null);
    const [batchfilters, setBatchfilters] = useState({});

    // context declarations
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);

    // query declarations
    const confirmPickItemQuery = useQuery(CONFIRM_PICK_ITEM);
    const confirmDropoffQuery = useQuery(CONFIRM_PICKER_DROPOFF);
    const totesQuery = useQuery(GET_TOTES);
    const currentBatchQuery = useQuery(GET_CURRENT_PICKER_BATCH);
    const getWorkflowByAttributesQuery = useQuery(GET_WORKFLOW_BY_ATTRIBUTES);
    const skipItemQuery = useQuery(SKIP_PICK_ITEM);
    const getNewBatchQuery = useQuery(GET_NEW_PICKER_BATCH);
    const scanBarcodeQuery = useQuery(SCAN_PICKER_BARCODE);
    const getCurrentProductQuery = useQuery(GET_SKU);
    const [currentProduct, setCurrentProduct] = useState(null);

    useEffect(() => {
      if (getCurrentProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getCurrentProductQuery.data) {
        setCurrentProduct(getCurrentProductQuery.data.specificInventory);
      }

      if (getCurrentProductQuery.error) {
        setCurrentProduct(null);
      }
    }, [
      getCurrentProductQuery.loading,
      getCurrentProductQuery.data,
      getCurrentProductQuery.error,
    ]);

    useEffect(() => {
      totesQuery.fetchData();
      getWorkflowByAttributesQuery.fetchData({
        attributes: { name: "Picking" },
      });
    }, []);

    useEffect(() => {
      if (auth && auth.user && auth.user.warehousesList) {
        const warehouses = auth.user.warehousesList;
        if (warehouses.length === 1) {
          setBatchfilters({
            warehouse: { value: warehouses.map((i) => i.id)[0] },
          });
        }
      }
    }, []);

    useEffect(() => {
      if (getWorkflowByAttributesQuery.data) {
        setWorkflow(getWorkflowByAttributesQuery.data.getWorkflowByAttributes);
      }

      if (getWorkflowByAttributesQuery.error) {
        setWorkflow(null);
      }

      if (getWorkflowByAttributesQuery.loading) {
        appState.setLoading();
      }
    }, [
      getWorkflowByAttributesQuery.loading,
      getWorkflowByAttributesQuery.error,
      getWorkflowByAttributesQuery.data,
    ]);

    useEffect(() => {
      currentBatchQuery.fetchData();
    }, []);

    useEffect(() => {
      if (currentBatchQuery.loading) {
        appState.setLoading();
      }
      if (
        currentBatchQuery.data &&
        currentBatchQuery.data.getCurrentPickerBatch
      ) {
        handleSetBatchDetails(currentBatchQuery.data.getCurrentPickerBatch);
        appState.removeLoading();
      }

      if (currentBatchQuery.error) {
        setCurrentBatch(null);
        appState.removeLoading();
      }
    }, [
      currentBatchQuery.loading,
      currentBatchQuery.data,
      currentBatchQuery.error,
    ]);

    // get new batch query
    useEffect(() => {
      if (getNewBatchQuery.loading) {
        appState.setLoading();
      } else {
        if (
          getNewBatchQuery.data &&
          getNewBatchQuery.data.getNewPickerBatch.message
        ) {
          handleSetBatchDetails(getNewBatchQuery.data.getNewPickerBatch.batch);
        } else if (getNewBatchQuery.error) {
          appState.setAlert(getNewBatchQuery.error.message, "error", 5000);
        }
        appState.removeLoading();
      }
    }, [
      getNewBatchQuery.loading,
      getNewBatchQuery.data,
      getNewBatchQuery.error,
    ]);

    useEffect(() => {
      if (confirmPickItemQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmPickItemQuery.data &&
          confirmPickItemQuery.data.confirmPickItem.message
        ) {
          appState.setAlert(
            confirmPickItemQuery.data.confirmPickItem.message,
            "success",
            5000,
          );
          // currentBatchQuery.fetchData();
          handleSetBatchDetails(
            confirmPickItemQuery.data.confirmPickItem.batch,
          );
        }
        appState.removeLoading();
      }

      if (confirmPickItemQuery.error) {
        appState.setAlert(confirmPickItemQuery.error.message, "error", 5000);
        currentBatchQuery.fetchData();
      }
    }, [
      confirmPickItemQuery.loading,
      confirmPickItemQuery.data,
      confirmPickItemQuery.error,
    ]);

    useEffect(() => {
      if (confirmDropoffQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmDropoffQuery.data &&
          confirmDropoffQuery.data.confirmPickerDropoff.message
        ) {
          appState.setAlert(
            confirmDropoffQuery.data.confirmPickerDropoff.message,
            "success",
            5000,
          );
          currentBatchQuery.fetchData();
          setLoadingFirstTime(false);
        }
        appState.removeLoading();
      }
    }, [
      confirmDropoffQuery.loading,
      confirmDropoffQuery.data,
      confirmDropoffQuery.error,
    ]);

    useEffect(() => {
      if (scanBarcodeQuery.loading) {
        appState.setLoading();
      } else {
        if (
          scanBarcodeQuery.data &&
          scanBarcodeQuery.data.scanPickerBarcode &&
          scanBarcodeQuery.data.scanPickerBarcode.message
        ) {
          appState.setAlert(
            scanBarcodeQuery.data.scanPickerBarcode.message,
            "success",
            4000,
          );
          handleSetBatchDetails(scanBarcodeQuery.data.scanPickerBarcode.batch);
          // currentBatchQuery.fetchData();
        } else if (scanBarcodeQuery.error) {
          appState.setAlert(scanBarcodeQuery.error.message, "error", 5000);
          currentBatchQuery.fetchData();
        }
        appState.removeLoading();
      }
    }, [
      scanBarcodeQuery.loading,
      scanBarcodeQuery.data,
      scanBarcodeQuery.error,
    ]);

    useEffect(() => {
      if (skipItemQuery.data) {
        appState.setAlert(
          skipItemQuery.data.skipPickItem.message,
          "success",
          5000,
        );
        // currentBatchQuery.fetchData();
        handleSetBatchDetails(skipItemQuery.data.skipPickItem.batch);
      }

      if (skipItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (skipItemQuery.error) {
        appState.setAlert(skipItemQuery.error.message, "error", 5000);
        currentBatchQuery.fetchData();
      }
    }, [skipItemQuery.loading, skipItemQuery.data, skipItemQuery.error]);

    const onChangeScannedQuantity = (qty) => {
      if (qty <= currentItem.quantity && qty >= 0) {
        setCurrentItem({ ...currentItem, scannedSkus: parseInt(qty) });
      } else {
        appState.setAlert("Please enter correct quantity", "error", 5000);
      }
    };

    const simulateTote = () => {
      const totes = totesQuery.data?.totes?.entities;

      if (!totes || totes.length === 0) {
        appState.setAlert("No totes found", "error", 5000);
        return;
      }
      let activity = "PICKING";
      if (currentBatch && currentBatch.workflow === "Pick by order") {
        activity = "ORDER";
      }
      let items = totesQuery.data?.totes?.entities
        .filter((item) => item.toteType === activity)
        .map((item) => item.barcode);
      if (currentBatch.workflow === "Pick + Sort") {
        items = totesQuery.data?.totes?.entities
          .filter(
            (item) =>
              item.toteType === activity &&
              item.subTotes &&
              item.subTotes.length > 0,
          )
          .map((item) => item.barcode);
      }
      const item = items[Math.floor(Math.random() * items.length)];
      return scanBarcodeQuery.fetchData({ code: item });
    };

    const onSubmitCustomer = (selectedCustomer) => {
      setCustomer(selectedCustomer.value);
    };

    const onSkipCustomer = () => {
      setCustomer(null);
    };

    /**
     * Retrieves a new batch based on the current batch filters.
     * It checks certain conditions, that includes warehouse to be mandatory
     * and sets the appropriate alert if necessary.
     *
     * @function
     */
    const getNewBatch = () => {
      const data = {};
      if (batchfilters?.customer?.value) {
        data.customer = batchfilters.customer.value;
      }
      if (!batchfilters?.warehouse?.value) {
        appState.setAlert("Please select the Warehouse", "error", 5000);
        return;
      } else {
        data.warehouse = batchfilters.warehouse.value;
      }
      getNewBatchQuery.fetchData(data);
    };

    /**
     * Updates the batch filters with a given field and value.
     *
     * @function
     * @param {string} field - The name of the filter to change.
     * @param {string|number|boolean|Object} value - The value to set for the specified filter.
     */
    const onChangeDropdown = (field, value) => {
      const filters = {
        ...batchfilters,
      };

      filters[field] = value;
      setBatchfilters({ ...filters });
    };

    const skipItem = () => {
      skipItemQuery.fetchData({ id: currentBatch.id });
    };

    const confirmPickItem = () => {
      if (
        currentItem.skuConfirmationNeeded &&
        currentItem.scannedSkus != currentItem.pickedQuantity
      ) {
        appState.showConfirmation(
          "Are you sure?",
          "Scanned and order quantities don't match.",
          () => {
            confirmPickItemQuery.fetchData({
              id:
                currentBatchQuery.data.getCurrentPickerBatch?.id ||
                currentBatch.id,
              item: {
                id: currentItem.id,
                quantity: currentItem.quantity,
                pickedQuantity: currentItem.pickedQuantity,
                scannedSkus: currentItem.scannedSkus,
              },
              boxName:
                currentBatch.boxes &&
                currentBatch.boxes[currentBatch.boxes.length - 1]
                  ? currentBatch.boxes[currentBatch.boxes.length - 1].name
                  : null,
              weight: 1,
            });
            appState.hideConfirmation();
          },
          appState.hideConfirmation,
        );
      } else {
        confirmPickItemQuery.fetchData({
          id: currentBatchQuery.data.getCurrentPickerBatch.id,
          item: {
            id: currentItem.id,
            quantity: currentItem.quantity,
            pickedQuantity: currentItem.pickedQuantity,
            scannedSkus: currentItem.scannedSkus,
          },
          boxName:
            currentBatch.boxes &&
            currentBatch.boxes[currentBatch.boxes.length - 1]
              ? currentBatch.boxes[currentBatch.boxes.length - 1].name
              : null,
          weight: 1,
        });
      }
    };

    const confirmDropoff = () => {
      confirmDropoffQuery.fetchData({
        id: currentBatchQuery.data.getCurrentPickerBatch.id,
      });
    };

    const confirmDropoffWithConfirmation = () => {
      appState.showConfirmation(
        "Confirm",
        "Have you dropped off this batch at prep?",
        () => {
          confirmDropoffQuery.fetchData({
            id: currentBatch.id,
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const handleSetBatchDetails = (batch, error = null) => {
      if (error) {
        setCurrentBatch(null);
        return;
      }
      setCurrentBatch(batch);
      setCurrentItem(batch.currentItem);
      setDisplayScan(false);

      if (batch.customer?.length > 0) {
        setCustomer(batch.customer[0]);
      } else {
        setCustomer(batch.customer);
      }

      if (batch.currentItem?.productId) {
        getCurrentProductQuery.fetchData({ id: batch.currentItem.productId });
      }
    };

    const scanBarcode = (e) => {
      const warehouse = currentBatch
        ? currentBatch.warehouse[0]
        : batchfilters.warehouse.value;

      scanBarcodeQuery.fetchData({ code: e, warehouse });
    };

    return (
      <WrappedComponent
        currentItem={currentItem}
        currentBatch={currentBatch}
        getNewBatch={getNewBatch}
        scanBarcode={scanBarcode}
        confirmPickItem={confirmPickItem}
        confirmDropoff={confirmDropoff}
        loading={currentBatchQuery.loading}
        loadingFirstTime={loadingFirstTime}
        simulateTote={simulateTote}
        workflow={workflow}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        customer={customer}
        setCustomer={setCustomer}
        onSubmitCustomer={onSubmitCustomer}
        onSkipCustomer={onSkipCustomer}
        confirmDropoffWithConfirmation={confirmDropoffWithConfirmation}
        subdomain={appState.subdomain}
        skipItem={skipItem}
        onChangeScannedQuantity={onChangeScannedQuantity}
        displayScan={displayScan}
        setDisplayScan={setDisplayScan}
        batchfilters={batchfilters}
        onChangeDropdown={onChangeDropdown}
        currentProduct={currentProduct}
      />
    );
  };
};

export default withOrderPickerLogic;
