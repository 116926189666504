import { useState, useContext, useEffect } from "react";
import { useQuery } from "#hooks/useQuery";
import { SAVE_PRODUCT } from "#mutations";
import ModalV2 from "#components/utils/ModalV2";
import { AppStateContext } from "#contexts/appState";

import ProductInfo from "#components/consignments/ConsignmentForm/ProductInfo";

const ALERT_TIMEOUT_IN_MS = 5000;

const COGInformation = ({ product, onUpdate = null, onClose }) => {
  const appState = useContext(AppStateContext);

  const saveProductQuery = useQuery(SAVE_PRODUCT); // to save the product with modified UOM Changes.

  const [selectedProduct, setSelectedProduct] = useState({ ...product });

  // Save Product Query Effect to save the product with updated UOM Config.
  useEffect(() => {
    saveProductQuery.loading ? appState.setLoading() : appState.removeLoading();

    if (saveProductQuery.data) {
      appState.setAlert(saveProductQuery.data.saveProduct.message);
      // once the product is saved, trigger the onUpdate method call if passed.
      if (onUpdate) {
        onUpdate();
      }
    }

    if (saveProductQuery.error) {
      appState.setAlert(
        saveProductQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [saveProductQuery.data, saveProductQuery.loading, saveProductQuery.error]);

  const updateProduct = () => {
    saveProductQuery.fetchData({
      ...selectedProduct,
    });
  };

  const onChangeItemAttributes = (attributeKey, attributeValue) => {
    setSelectedProduct({
      ...selectedProduct,
      attributes: {
        ...selectedProduct.attributes,
        [attributeKey]: attributeValue,
      },
    });
  };

  return (
    <ModalV2
      title={`COG Details`}
      positiveText={"Update"}
      positiveAction={updateProduct}
      negativeText={"Cancel"}
      negativeAction={onClose}
      onClose={onClose}
      noPadding={true}>
      <div className="mx-6">
        {/* Product Info */}
        <div className="w-full">
          <label className="text-md mb-2 block font-medium">Product Info</label>
          <div className="rounded-lg border border-borderGray p-2">
            <ProductInfo
              itemDetails={selectedProduct}
              style={{ totalWidth: "w-full", width: "w-422" }}
            />
          </div>
        </div>
        {/* Base Unit of Measurement */}
        <div className="my-4 flex items-end space-x-8">
          <div className="w-171">
            <label className="text-md mb-1 block font-medium">
              Commodity Code
            </label>
            <input
              type="text"
              id="commodityCode"
              label="Commodity code"
              placeholder=" "
              onChange={(e) =>
                onChangeItemAttributes("commodityCode", e.target.value)
              }
              value={selectedProduct?.attributes?.commodityCode}
              className="h-48 w-full rounded border border-borderGray p-2"
            />{" "}
          </div>
          <div className="w-171">
            <label className="text-md mb-1 block font-medium">COG(EUR)</label>
            <input
              type="number"
              id="cog"
              label="COG(EUR)"
              placeholder=" "
              min="0"
              onChange={(e) =>
                onChangeItemAttributes("cog", parseFloat(e.target.value))
              }
              value={selectedProduct?.attributes?.cog}
              className="h-48 w-full rounded border border-borderGray p-2"
            />{" "}
          </div>
        </div>
      </div>
    </ModalV2>
  );
};

export default COGInformation;
