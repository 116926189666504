import withGroupsLogic from "#components/HOC/withGroupsLogic";
import PageTitle from "#components/utils/PageTitle";
import GroupsList from "#components/groups/GroupsList";
import AddButton from "#components/utils/AddButton";
import { useHistory } from "react-router-dom";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import QuickFilters from "#components/common/QuickFilters";

const Groups = ({
  groups,
  masterData,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  writable,
  filters,
  onChangeFilter,
}) => {
  const history = useHistory();
  return (
    <div>
      <div className="mb-4 flex items-center">
        <div className="flex-1">
          <PageTitle>Groups</PageTitle>
          {(customers.length > 1 || warehouses.length > 1) && (
            <QuickFilters
              warehouseFilterName={"warehouses"}
              customerFilterName={"customers"}
              customers={customers}
              warehouses={warehouses}
              filters={filters}
              onChangeFilter={onChangeFilter}
            />
          )}
        </div>

        <div className="items-center justify-end">
          <AddButton
            text="Add Group"
            onClick={() => history.push("/group")}
            disabled={!writable}
          />
        </div>
      </div>

      {groups.length === 0 ? (
        <h1>No Groups</h1>
      ) : (
        <GroupsList
          groups={groups}
          masterData={masterData}
          writable={writable}
        />
      )}
    </div>
  );
};

export default withGroupsLogic(Groups);
