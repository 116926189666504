import axios from "axios";
import _ from "lodash";

const fetchDataAsync = async (query, variables = {}) => {
  let { baseUrl } = localStorage;
  if (!baseUrl) {
    return;
  }

  const response = await axios.post(
    `${baseUrl}/graphql`,
    {
      query,
      variables,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  if (response.data && response.data.errors) {
    throw response.data.errors[0];
  }
  return response;
};

export default fetchDataAsync;
