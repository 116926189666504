import { useCubeQuery } from "@cubejs-client/react";

const statsComponent = (item) => {
  return (
    <div
      key={item.name}
      className={
        "shrink-0 flex-wrap overflow-hidden whitespace-nowrap rounded-lg bg-white px-4 py-5 text-center text-gray-500 shadow sm:p-6" +
        (item.setShowView ? " cursor-pointer hover:shadow-2xl" : "")
      }
      onClick={() => {
        /* setShowView is only present when the stat can be expanded to view further details */
        item.setShowView && item.setShowView(item.view);
      }}>
      <dt className="text-xl font-medium">{item.name}</dt>
      <dd className={"mt-1 text-2xl font-semibold text-gray-900"}>
        {item.stat === -1 && (
          <svg
            className="-ml-1 mr-3 h-10 w-10 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        )}
        <div className="flex items-center justify-center">
          {isNaN(item.stat) === false &&
            item.stat !== -1 &&
            (item.display
              ? item.display(Math.round(item.stat * 100) / 100)
              : Math.round(item.stat * 100) / 100)}
          {item.stringValue && item.stat}
          {isNaN(item.stat) && !item.stringValue && !item.error && (
            <>Loading...</>
          )}
          {item.error && <>0</>}
          {item.arrow ? item.arrow : null}&nbsp;
          {item.additional?.length > 1 && `(${item.additional[1]})`}
        </div>
      </dd>
    </div>
  );
};

const renderStatsComponent = (item, { resultSet, error }) => {
  if (resultSet) {
    item.additional = resultSet?.loadResponses[0]?.data[0]
      ? Object.values(resultSet?.loadResponses[0]?.data[0])
      : [];
    item.stat = resultSet.seriesNames()[0]
      ? resultSet.totalRow()[resultSet.seriesNames()[0].key]
      : 0;
    if (item.setQueryResult) item.setQueryResult(item.stat);
  } else if (error && error.toString()) {
    item.stat = error.toString();
    item.error = true;
  }
  return statsComponent(item);
};

const Stats = ({ stats, grid }) => (
  <dl
    className={`${
      grid === "order-fill-rate"
        ? "grid grid-cols-2 gap-5 py-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3"
        : grid === "statistics"
          ? "mt-5 grid grid-cols-1 gap-5 py-3 sm:grid-cols-4"
          : grid === "orders"
            ? "grid grid-cols-2 gap-5 py-3 sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6"
            : grid === "order-audit" ||
                grid === "bin-age" ||
                grid === "stock-ledger"
              ? "grid grid-cols-2 gap-5 py-3 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5"
              : grid === "custom-bin-age"
                ? "grid w-max grid-cols-2 gap-5 py-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2"
                : "grid grid-cols-2 gap-5 py-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4"
    }`}>
    {stats.map((item) => {
      if (item.renderValue) {
        return statsComponent({ ...item, stat: item.renderValue() });
      }
      const renderProps = useCubeQuery(item.query);
      return renderStatsComponent(item, renderProps);
    })}
  </dl>
);

export default Stats;
