const ExpandedAttributes = ({ order }) => {
  return (
    <div className="w-max">
      {Object.keys(order.attributes).map((attributeName, idx) => (
        <div key={idx} className="flex gap-x-4 p-3">
          <span className="font-bold">{`${attributeName}: `}</span>
          <span className="font-medium">
            {[true, false].includes(order.attributes[attributeName])
              ? order.attributes[attributeName] === true
                ? "Yes"
                : "No"
              : order.attributes[attributeName]}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ExpandedAttributes;
