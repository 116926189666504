import React from "react";
import withReportsLogic from "#components/HOC/withReportsLogic";
import ReportForm from "#components/reports/ReportsForm";
import ReportPreview from "#components/reports/ReportPreview";
import CustomEntityForm from "#components/common/CustomEntityForm";
import Popover from "#components/utils/Popover";
import ScheduledReportsForm from "#components/reports/ScheduledReportsForm";
import ReportTabsList from "#components/reports/ReportTabsList";
import ReportSearchSortFilters from "#components/reports/ReportSearchSortFilters";
import {
  GENERATED_REPORT_SORT_CHOICES,
  SCHEDULE_REPORT_SORT_CHOICES,
} from "#components/HOC/withReportsLogic";

const Reports = ({
  previewData,
  setPreviewData,
  reportsMetadata,
  reports,
  selectedReport,
  setSelectedReport,
  saveReport,
  onChangeDropdown,
  onChange,
  onChangeMultiSelect,
  customers,
  warehouses,
  addCustomEntity,
  setAddCustomEntity,
  onSubmitCustomEntity,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  showFilters,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeDropdownMulti,
  menuItems,
  fetchedReport,
  createReport,
  reportType,
  reportTypeModesEnum,
  reportModalModesEnum,
  openReportMode,
  setOpenReportMode,
  onChangeFrequency,
  parseCronExpression,
  reportsTabs,
  selectedReportTab,
  setSelectedReportTab,
  setPagination,
  setReportType,
  allUsers,
  selectedFilterReport,
  setSelectedFilterReport,
  FREQUENCY,
  copySelectedReport,
  openScheduledReportCancelConfirmation,
}) => {
  const actionsOnPopover = [
    {
      name: "Run Report Now",
      value: "ondemand",
      onClick: () => createReport("ondemand"),
    },
    {
      name: "Schedule Report for Later",
      value: "schedule",
      onClick: () => createReport("schedule"),
    },
  ];

  return (
    <div className="bg-white font-inter">
      <div className="p-8 font-inter">
        <div className="mb-8 flex items-center font-inter">
          <div className="flex-1">
            <div className="flex items-center justify-start gap-3">
              <h2 className="text-2xl font-semibold">Report Management</h2>
            </div>
            <p className="text-base font-light text-gray-400">
              Manage and Track Your Generated and Scheduled Reports
            </p>
          </div>
          <div className="mb-4 flex items-center justify-end space-x-2">
            <Popover
              title={"Add Report"}
              showChevron={true}
              disabled={!writable}
              panelClassName={
                "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray w-[16rem]"
              }
              showOverlay={true}>
              {actionsOnPopover.map((action) => {
                return (
                  <div
                    key={action.name}
                    className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                    onClick={
                      action.onClick
                        ? action.onClick
                        : () => console.log(action.value, action.name)
                    }>
                    {action.name}
                  </div>
                );
              })}
            </Popover>
          </div>
        </div>

        <div className="flex space-x-4 font-inter">
          <ReportSearchSortFilters
            onChangeSearchKeyword={onChangeSearchKeyword}
            clearKeyword={clearKeyword}
            setSort={setSort}
            sort={sort}
            choices={
              selectedReportTab === reportsTabs[0]["key"]
                ? GENERATED_REPORT_SORT_CHOICES
                : SCHEDULE_REPORT_SORT_CHOICES
            }
            reportsMetadata={reportsMetadata}
            selectedFilterReport={selectedFilterReport}
            setSelectedFilterReport={setSelectedFilterReport}
            FREQUENCY={FREQUENCY}
            onChange={onChange}
            reportsTabs={reportsTabs}
            selectedReportTab={selectedReportTab}
            clearFilters={clearFilters}
            setShowFilters={setShowFilters}
            showFilters={showFilters}
            filters={filters}
            submitFilters={submitFilters}
            onChangeFilter={onChangeFilter}
          />
        </div>

        <ReportTabsList
          menuItems={menuItems}
          reports={reports}
          setSort={setSort}
          sort={sort}
          noValuesText="No Reports"
          customers={customers}
          warehouses={warehouses}
          writable={writable}
          onChangeSearchKeyword={onChangeSearchKeyword}
          clearKeyword={clearKeyword}
          total={total}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
          perPage={perPage}
          setPerPage={setPerPage}
          selectedReportTab={selectedReportTab}
          setSelectedReportTab={setSelectedReportTab}
          reportsTabs={reportsTabs}
          setPagination={setPagination}
        />

        {selectedReport &&
          reportType === reportTypeModesEnum.ONDEMAND &&
          openReportMode !== reportModalModesEnum.DELETE && (
            <ReportForm
              title={selectedReport.id ? "Edit Report" : "Add Report"}
              selectedReport={selectedReport}
              onChange={onChange}
              onClose={() => setSelectedReport(null)}
              onSubmit={() => saveReport(selectedReport)}
              onChangeDropdown={onChangeDropdown}
              onChangeMultiSelect={onChangeMultiSelect}
              customers={customers}
              warehouses={warehouses}
              reportsMetadata={reportsMetadata}
              onChangeDropdownMulti={onChangeDropdownMulti}
            />
          )}

        {previewData && (
          <ReportPreview
            title="Report Preview"
            previewData={previewData}
            onClose={() => setPreviewData(null)}
            onSubmit={() => setPreviewData(null)}
            fetchedReport={fetchedReport}
          />
        )}

        {addCustomEntity && (
          <CustomEntityForm
            addCustomEntity={addCustomEntity}
            setAddCustomEntity={setAddCustomEntity}
            onSubmit={onSubmitCustomEntity}
          />
        )}

        {(selectedReport || openReportMode) &&
          reportType === reportTypeModesEnum.SCHEDULE &&
          openReportMode !== reportModalModesEnum.CANCEL && (
            <ScheduledReportsForm
              reportsMetadata={reportsMetadata}
              selectedReport={selectedReport}
              setSelectedReport={setSelectedReport}
              reportModalModesEnum={reportModalModesEnum}
              openReportMode={openReportMode}
              setOpenReportMode={setOpenReportMode}
              onClose={() => setSelectedReport(null)}
              onChange={onChange}
              onChangeDropdown={onChangeDropdown}
              warehouses={warehouses}
              customers={customers}
              onChangeDropdownMulti={onChangeDropdownMulti}
              onChangeFrequency={onChangeFrequency}
              parseCronExpression={parseCronExpression}
              onSubmit={() => saveReport(selectedReport)}
              setReportType={setReportType}
              allUsers={allUsers}
              copySelectedReport={copySelectedReport}
              reportTypeModesEnum={reportTypeModesEnum}
              openScheduledReportCancelConfirmation={
                openScheduledReportCancelConfirmation
              }
            />
          )}
      </div>
    </div>
  );
};

export default withReportsLogic(Reports);
