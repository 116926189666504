import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_UOM_LABELS } from "#queries";
import { SAVE_UOM_LABELS } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { MasterDataContext } from "#contexts/masterData";
import { AuthContext } from "#contexts/auth";

const withUoMLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const masterData = useContext(MasterDataContext);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const uomQuery = useQuery(GET_UOM_LABELS);
    const saveUomQuery = useQuery(SAVE_UOM_LABELS);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const ALERT_TIMER_IN_MS = 5000;

    useEffect(() => {
      uomQuery.fetchData({
        perPage: entity.perPage,
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    }, []);

    useEffect(() => {
      if (uomQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (uomQuery.data && uomQuery.data.getUomLabels) {
        const { labels, totalLabels } = uomQuery.data.getUomLabels;
        entity.setEntities({
          entities: labels,
          total: totalLabels,
        });
      }
    }, [uomQuery.loading, uomQuery.error, uomQuery.data]);

    useEffect(() => {
      if (saveUomQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveUomQuery.data) {
        setSelectedLabel(null);
        uomQuery.fetchData({
          perPage: entity.perPage,
          filters: { ...entity.filters },
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (saveUomQuery.error) {
        appState.removeLoading();
        appState.setAlert(
          saveUomQuery.error.message,
          "error",
          ALERT_TIMER_IN_MS,
        );
      }
    }, [saveUomQuery.loading, saveUomQuery.data, saveUomQuery.error]);

    const onChangeMultiSelect = (field, value) => {
      const label = {
        ...selectedLabel,
      };
      label[field] = value;
      setSelectedLabel(label);
    };

    return (
      <WrappedComponent
        uomLabels={entity.displayEntities}
        masterData={masterData}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        saveLabel={(label) => {
          const labelsObject = { ...label.labels };
          const labelsArray = Object.values(labelsObject);
          saveUomQuery.fetchData({ labels: labelsArray });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        onChangeMultiSelect={onChangeMultiSelect}
        writable={props.writable}
      />
    );
  };
};

export default withUoMLogic;
