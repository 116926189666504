import { useEffect, useState } from "react";
import { Menu } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import _ from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SortDropdown = ({
  sort,
  setSort,
  choices,
  showSortBy = true,
  showIcon = true,
}) => {
  const [outerChoices, setOuterChoices] = useState([
    {
      name: "Order ID",
      value: "orderId",
    },
    {
      name: "Invoice",
      value: "reference",
    },
    {
      name: "Price",
      value: "orderValue",
    },
    {
      name: "Order Date",
      value: "orderDate",
    },
    {
      name: "Status",
      value: "orderStatus",
    },
    {
      name: "Country",
      value: "shippingAddress.country",
    },
    {
      name: "Source",
      value: "source",
    },
  ]);
  useEffect(() => {
    if (choices) {
      setOuterChoices(choices);
    }
  }, [choices]);

  const renderSortValue = () => {
    const choice = _.find(outerChoices, { value: sort?.replace("-", "") });
    if (!choice) {
      return null;
    }
    return (
      <div className="flex items-center space-x-2">
        <div className="ml-1">{choice.name}</div>
        {sort.indexOf("-") !== -1 ? (
          <ChevronDownIcon className="h-6 w-6 text-green-400" />
        ) : (
          <ChevronUpIcon className="h-6 w-6 text-green-400" />
        )}
      </div>
    );
  };

  return (
    <Menu as="div" className="relative inline-block text-left text-lg">
      <div>
        <Menu.Button className="inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-F8F8F8 px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {showSortBy && `Sort By: `}
          {renderSortValue()}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Menu.Items className="absolute right-0 z-50 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {outerChoices
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((choice, idx) => (
              <Menu.Item key={idx}>
                {({ active }) => (
                  <div
                    onClick={() => setSort(choice.value)}
                    className={classNames(
                      choice.value === sort
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-700",
                      "block cursor-pointer px-4 py-2 text-lg hover:bg-gray-200",
                    )}>
                    {choice.name}
                  </div>
                )}
              </Menu.Item>
            ))}
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default SortDropdown;
