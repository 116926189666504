import React from "react";
import { SearchIcon, XIcon } from "@heroicons/react/solid";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  PencilIcon,
  TrashIcon,
  DocumentAddIcon,
} from "@heroicons/react/outline";

const Transfer = ({
  leftItems,
  rightItems,
  totalSearchResults,
  onChangeSearch,
  onClickAdd,
  onClickRemove,
  leftHeaders,
  rightHeaders,
  onClickEditQuantity,
}) => {
  return (
    <>
      <div className="flex-1">
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="keyword"
            id="keyword"
            className="block w-full rounded-md border-gray-300 p-2 pl-12 text-lg focus:outline-none"
            placeholder="Search"
            onChange={onChangeSearch}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <span className="h-screen w-3/5 overflow-hidden overflow-y-scroll border-2 border-gray-100">
          <table className="mb-10 min-w-full divide-y divide-gray-200">
            <thead className="rounded-full p-4">
              <tr className="font-montserratborder-left text-primaryAccent">
                {leftHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {leftItems && leftItems.length === 0 ? (
                <tr className="bg-white">
                  {leftHeaders.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                        key={headerIdx}>
                        No Results
                      </td>
                    ) : (
                      <td
                        className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                        key={headerIdx}></td>
                    ),
                  )}
                </tr>
              ) : null}
              {leftItems &&
                leftItems.map((item, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                    <td
                      className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                        index % 2 === 0
                          ? "border-F4C261"
                          : "border-primaryAccent"
                      }`}>
                      {item.sku}
                    </td>
                    <td>{item.name}</td>
                    <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      <TableFieldButton
                        text={<DocumentAddIcon className="h-6 w-6" />}
                        onClick={() => onClickAdd(item.sku)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </span>
        <span className="h-screen w-3/5 overflow-hidden overflow-y-scroll border-2 border-gray-100">
          <table className="mb-10 min-w-full divide-y divide-gray-200">
            <thead className="rounded-full p-4">
              <tr className="font-montserratborder-left text-primaryAccent">
                {rightHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {rightItems && rightItems.length === 0 ? (
                <tr className="bg-white">
                  {rightHeaders.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                        key={headerIdx}>
                        Add SKUs
                      </td>
                    ) : (
                      <td
                        className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                        key={headerIdx}></td>
                    ),
                  )}
                </tr>
              ) : null}
              {rightItems &&
                rightItems.map((item, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                    <td
                      className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                        index % 2 === 0
                          ? "border-F4C261"
                          : "border-primaryAccent"
                      }`}>
                      {item.sku}
                    </td>

                    <td className="pl-5">{item.quantity}</td>
                    <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      <div className="flex items-center space-x-4">
                        <TableFieldButton
                          onClick={() => onClickEditQuantity(item.sku)}
                          text={<PencilIcon className="h-6 w-6" />}
                        />
                        <TableFieldButton
                          text={<TrashIcon className="h-6 w-6" />}
                          onClick={() => onClickRemove(item.sku)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </span>
      </div>
    </>
  );
};

export default Transfer;
