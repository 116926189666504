export const BulkValidationQueryGenerator = ({ keyword }) => {
  return `
    query InputValidationErrors($rows: BulkUploadInput!) {
        validateBulkUpload${keyword}(rows: $rows) {
            inputValidationErrors {
            errorDetails
            errorObject {
                errors
                totalRows
                totalErrorRows
                }
            }
            message
        }
    }
    `;
};
