import React, { useState, Fragment, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Tooltip } from "antd";
import { handleGetQuantityDetailOfLineItem } from "./PoUtils";

const EnterReadyToShipDetails = ({
  products,
  setShippingPurchaseOrder,
  shippingDetails,
}) => {
  const [expandedProductId, setExpandedProductId] = useState(null);
  // Function to toggle component visibility
  const toggleComponents = (id) =>
    setExpandedProductId(expandedProductId === id ? null : id);

  // Function to update shippable quantity
  const updateShippableQuantity = (lineItemId, value) => {
    setShippingPurchaseOrder((purchaseOrder) => ({
      ...purchaseOrder,
      isReadyToShip: true,
      lineItems: purchaseOrder.lineItems.map((product) => {
        if (product.lineItemId === lineItemId) {
          const newShippableQuantity = parseFloat(value);
          return {
            ...product,
            shippableQuantity: newShippableQuantity,
          };
        }
        return product;
      }),
    }));
  };

  useEffect(() => {
    setShippingPurchaseOrder((purchaseOrder) => {
      return {
        ...purchaseOrder,
        isReadyToShip: true,
        lineItems: purchaseOrder.lineItems.map((product) => {
          const { remainingQuantity } = handleGetQuantityDetailOfLineItem(
            product.lineItemId,
            products,
            shippingDetails,
          );
          return {
            ...product,
            shippableQuantity: remainingQuantity,
          };
        }),
      };
    });
  }, []);

  return (
    <div className="mb-8 mt-8 rounded-md border border-gray-200 bg-white p-6 shadow-sm">
      <h2 className="mb-4 text-lg font-semibold">Ship Purchase Order</h2>
      <div className="overflow-auto p-4">
        <div className="min-w-full">
          <div
            style={{ gridTemplateColumns: "3fr 1fr 1fr 1fr" }}
            className="sticky top-0 z-10 grid h-14 grid-cols-4 items-center gap-4 rounded-md border border-borderGray bg-bgGray px-4 text-left text-sm font-medium tracking-wider text-gray-500">
            <div>Product Info</div>
            <div>UOM</div>
            <div>Available Quantity</div>
            <div>Shippable Quantity</div>
          </div>
          <div
            style={{ maxHeight: "40rem" }}
            className="min-w-full overflow-auto">
            {products.length > 0 &&
              products.map((product) => {
                const { remainingQuantity } = handleGetQuantityDetailOfLineItem(
                  product.lineItemId,
                  products,
                  shippingDetails,
                );
                return (
                  <div key={product.lineItemId} className="border-b py-4">
                    <div
                      style={{ gridTemplateColumns: "3fr 1fr 1fr 1fr" }}
                      className="grid grid-cols-4 items-start gap-4 p-4">
                      <div className="flex items-center">
                        <div>
                          <Tooltip title={product.name}>
                            <div className="w-48 truncate text-base font-medium">
                              {product.name}
                            </div>
                          </Tooltip>

                          <div className="text-gray-500">
                            SKU: {product.sku}
                          </div>
                          <div className="text-gray-500">
                            Variant: {product.variant}
                          </div>
                        </div>
                      </div>
                      <div className="rounded border bg-gray-100 p-2">
                        {product.uom}
                      </div>
                      <div className="rounded border bg-gray-100 p-2">
                        {remainingQuantity}
                      </div>
                      <input
                        type="number"
                        className="w-full rounded border p-2"
                        value={product.shippableQuantity}
                        min={0}
                        max={remainingQuantity}
                        onChange={(e) =>
                          updateShippableQuantity(
                            product.lineItemId,
                            e.target.value,
                          )
                        }
                      />
                    </div>
                    {product.components?.length > 0 && (
                      <button
                        onClick={() => toggleComponents(product.id)}
                        className="mt-2 flex items-center font-semibold text-primaryAccent underline">
                        {expandedProductId === product.id
                          ? "Hide Components"
                          : "View Components"}
                        {expandedProductId === product.id ? (
                          <ChevronUpIcon className="mr-1 h-5 w-5" />
                        ) : (
                          <ChevronDownIcon className="mr-1 h-5 w-5" />
                        )}
                      </button>
                    )}
                    <Transition
                      as={Fragment}
                      show={expandedProductId === product.id}
                      enter="transition ease-out duration-200 transform"
                      enterFrom="-translate-y-10 opacity-0"
                      enterTo="translate-y-0 opacity-100"
                      leave="transition ease-in duration-150 transform"
                      leaveFrom="translate-y-0 opacity-100"
                      leaveTo="-translate-y-10 opacity-0">
                      <div className="mt-2 w-full border-t p-4 pt-2">
                        <div className="mb-2 w-full text-gray-600">
                          <div
                            style={{
                              gridTemplateColumns: "3fr",
                            }}
                            className="grid h-14 w-full items-center gap-4 rounded-md border border-borderGray bg-bgGray px-4 text-left text-sm font-medium tracking-wider text-gray-500">
                            <div>Component Info</div>
                          </div>
                          {product.components?.map((component, index) => (
                            <Fragment key={index}>
                              <div
                                style={{
                                  gridTemplateColumns: "3fr",
                                }}
                                className="grid w-full items-start gap-4 p-4">
                                <div className="flex h-full flex-col items-start gap-3">
                                  {component.name}
                                </div>
                              </div>
                              <div
                                style={{ height: "1px" }}
                                className="w-full bg-gray-200"></div>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    </Transition>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterReadyToShipDetails;
