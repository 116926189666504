import Dropdown from "#components/utils/Dropdown";

const CompletedBatch = ({
  confirmDropoff,
  currentBatch,
  setCurrentBatch,
  stations,
}) => {
  return (
    <div className="-mt-20 flex h-screen w-full items-center justify-center">
      <div className="flex-col">
        <div className="w-full rounded-2xl border bg-white shadow-lg">
          <div className="flex items-center justify-center pb-5 pt-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/completed_tick.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="px-5 py-5 text-3xl font-medium text-454A4F">
              <div className="mb-2">Batch Completed Successfully</div>
              <Dropdown
                placeholder={"Station"}
                list={stations}
                labelKey="name"
                valueKey="id"
                name="station"
                setSelected={(stationId) => {
                  setCurrentBatch({ ...currentBatch, station: stationId });
                }}
                selectedValue={currentBatch.station}
              />
              <div
                className="mt-2 w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                onClick={confirmDropoff}>
                Confirm Dropoff
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedBatch;
