import _ from "lodash";
import { useQuery } from "#hooks/useQuery";
import { AppStateContext } from "#contexts/appState";
import { MasterDataContext } from "#contexts/masterData";
import {
  GET_CATALOG_PRODUCTS,
  GET_INVENTORY,
  GET_INTEGRATIONS,
} from "#queries";
import { SAVE_PRODUCT } from "#mutations";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import React, { useState, useEffect, useContext } from "react";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import ReactTooltip from "react-tooltip";

const ALERT_TIMEOUT_IN_MS = 5000;
const DEFAULT_SELECTED_CHANNEL = "All-All";

const ProductSearch = ({
  addProduct,
  multiAccountSupportEnabled,
  isPrepCenter,
  setShowImage,
  selectedCustomer,
  selectedWarehouse,
  restrictedChannels = [],
  numberofItems,
}) => {
  const ldClient = useLDClient();
  const isMarketplaceImportEnabled = ldClient.variation(
    featureFlags.IS_MARKETPLACE_IMPORT,
    false,
  );

  const masterData = useContext(MasterDataContext);
  const appState = useContext(AppStateContext);

  const importProductsQuery = useQuery(GET_CATALOG_PRODUCTS);
  const saveProductQuery = useQuery(SAVE_PRODUCT); // to save the selected imported product if any.
  const inventoryQuery = useQuery(GET_INVENTORY);
  const integrationsQuery = useQuery(GET_INTEGRATIONS);

  const [productSearchKeyword, setProductSearchKeyword] = useState("");

  const [loader, setLoader] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [importedProducts, setImportedProducts] = useState([]);
  const [showImportOption, setShowImportOption] = useState(false);
  const [showImportedProducts, setShowImportedProducts] = useState(false);

  const [clientMarketplaceIntegrations, setClientMarketplaceIntegrations] =
    useState([]);
  const [selectedChannel, setSelectedChannel] = useState(
    DEFAULT_SELECTED_CHANNEL,
  );
  const [showMarketplace, setShowMarketplace] = useState(false);
  const [marketplaces, setMarketplaces] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState("");

  // Initially fetch and populate the clientMarketplaceIntegrations
  useEffect(() => {
    if (masterData.integrationProviders) {
      integrationsQuery.fetchData({
        filters: {
          customer: selectedCustomer,
          warehouse: selectedWarehouse,
        },
      });
    }
  }, [selectedCustomer, selectedWarehouse]);

  // Integrations Query Effect to fetch clientMarketplaceIntegrations
  useEffect(() => {
    integrationsQuery.loading
      ? appState.setLoading()
      : appState.removeLoading();

    if (integrationsQuery.data) {
      const allMarketplaceIntegrations = masterData.integrationProviders.filter(
        (item) =>
          ["e-commerce marketplaces", "marketplace_fulfillment"].includes(
            item.classification?.toLowerCase(),
          ),
      );
      let activeMarketplaceIntegrations =
        integrationsQuery.data.integrations.filter((item) =>
          allMarketplaceIntegrations
            .map((i) => i.name)
            .includes(item.integrationType),
        );
      // Amazon FBA and FBM are actually 2 separate channels but uses the same integration "Amazon" as of today
      // If we see Amazon as one of the available active integrations we will replace it with FBA and Amazon FBM
      activeMarketplaceIntegrations = activeMarketplaceIntegrations
        .map((integration) => {
          let suffix = ``;
          if (integration.sellerId) {
            suffix = ` - ${integration.sellerId}`;
          }
          return integration.integrationType === "Amazon"
            ? [
                {
                  ...integration,
                  integrationType: "FBA",
                  integrationName: "FBA" + suffix,
                },
                {
                  ...integration,
                  integrationType: "FBM",
                  integrationName: "FBM" + suffix,
                },
              ]
            : {
                ...integration,
                integrationName: integration.integrationType + suffix,
              };
        })
        .flat();

      activeMarketplaceIntegrations.unshift(
        {
          integrationType: "All",
          integrationName: "All",
          id: "All",
        },
        {
          integrationType: "Hopstack",
          integrationName: "Hopstack",
          id: "Hopstack",
        },
      );
      if (restrictedChannels.length > 0) {
        activeMarketplaceIntegrations = activeMarketplaceIntegrations.filter(
          (integration) => {
            let matching = false;
            const channelRestrictionInfo = restrictedChannels.find(
              (channel) => channel.source === integration.integrationType,
            );
            if (channelRestrictionInfo) {
              matching = true;
              if (channelRestrictionInfo.marketplace) {
                matching =
                  integration.marketplaces?.includes(
                    channelRestrictionInfo.marketplace,
                  ) ?? false;
              }
              if (channelRestrictionInfo.sellerId) {
                matching =
                  integration.sellerId === channelRestrictionInfo.sellerId;
              }
            }
            return matching;
          },
        );
        setSelectedChannel(
          `${
            activeMarketplaceIntegrations[0].id +
            `-${activeMarketplaceIntegrations[0].integrationType}`
          }`,
        );
      }
      setClientMarketplaceIntegrations([...activeMarketplaceIntegrations]);
    }

    if (integrationsQuery.error) {
      appState.setAlert(
        integrationsQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      appState.removeLoading();
    }
  }, [
    integrationsQuery.data,
    integrationsQuery.loading,
    integrationsQuery.error,
  ]);

  // Inventory Query Effect to populate availableProducts
  useEffect(() => {
    inventoryQuery.loading ? setLoader(true) : setLoader(false);

    if (inventoryQuery.data) {
      setAvailableProducts(
        inventoryQuery.data.inventory.entities.filter((item) => !!item.sku),
      );
    }

    if (inventoryQuery.error) {
      setAvailableProducts([]);
    }
  }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

  // Import Products Query Effect to populate importedProducts
  useEffect(() => {
    importProductsQuery.loading ? setLoader(true) : setLoader(false);

    if (importProductsQuery.data) {
      setImportedProducts(importProductsQuery.data.getCatalogProducts.entities);
    }

    if (importProductsQuery.error) {
      setImportedProducts([]);
      setProductSearchKeyword("");
    }
  }, [
    importProductsQuery.data,
    importProductsQuery.loading,
    importProductsQuery.error,
  ]);

  // Save Product Query Effect to save the imported product.
  useEffect(() => {
    saveProductQuery.loading ? appState.setLoading() : appState.removeLoading();

    if (saveProductQuery.data) {
      appState.setAlert(saveProductQuery.data.saveProduct.message);
      // once product is successfully created, refresh the inventorySearch to show new results.
      onChangeInventorySearch(productSearchKeyword);
    }

    if (saveProductQuery.error) {
      appState.setAlert(
        saveProductQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [saveProductQuery.data, saveProductQuery.loading, saveProductQuery.error]);

  // When we have availableProducts, don't show imported products.
  useEffect(() => {
    if (availableProducts.length > 0) {
      setShowImportedProducts(false);
    }
  }, [availableProducts]);

  // Each time selectedChannel is changed, update the marketplace options accordingly.
  useEffect(() => {
    if (selectedChannel !== DEFAULT_SELECTED_CHANNEL) {
      const integration = clientMarketplaceIntegrations?.find(
        (integration) => integration.id === selectedChannel.split("-")[0],
      );
      let marketplaces =
        integration?.marketplaces?.map((m) => ({
          label: m,
          value: m,
        })) || [];
      if (restrictedChannels.length > 0) {
        const channelRestrictionInfo = restrictedChannels.find(
          (channel) => channel.source === integration.integrationType,
        );
        if (channelRestrictionInfo && channelRestrictionInfo.marketplace) {
          marketplaces = [
            {
              label: channelRestrictionInfo.marketplace,
              value: channelRestrictionInfo.marketplace,
            },
          ];
        }
      }
      setMarketplaces(marketplaces);
      setSelectedMarketplace(marketplaces[0]?.value);
      setShowImportOption(
        isMarketplaceImportEnabled &&
          ["FBA", "FBM"].includes(integration.integrationType),
      );
      setAvailableProducts([]);
      setImportedProducts([]);
      if (productSearchKeyword) {
        onChangeInventorySearch(productSearchKeyword);
      }
      setShowImportedProducts(false);
    }
  }, [selectedChannel]);

  // Debounced Inventory search logic to fetch the available products based on the channel, marketplace selected.
  const onChangeInventorySearch = (keyword) => {
    setImportedProducts([]);
    const filtersToApply = {
      keyword,
      customer: selectedCustomer,
      includeBundles: true,
    };
    const [selectedIntegrationId, selectedIntegrationType] =
      selectedChannel.split("-");

    let integration = clientMarketplaceIntegrations?.find(
      (i) =>
        i.id === selectedIntegrationId &&
        i.integrationType === selectedIntegrationType,
    );

    // If its Amazon FBA or FBM above logic of finding it from clientMarketplaceIntegrations will not work.
    // This is because both FBM, FBA objects will have same integrationId so we choose the addingChannel integrationType for source filter
    if (["FBA", "FBM"].includes(selectedIntegrationType)) {
      integration.integrationType = selectedIntegrationType;
    }

    if (selectedIntegrationType !== "All") {
      filtersToApply["source"] = [selectedIntegrationType];
      if (selectedIntegrationType !== "Hopstack") {
        filtersToApply["sellerId"] = integration?.sellerId;
        filtersToApply["marketplace"] = selectedMarketplace;
      }
    }

    inventoryQuery.fetchData({
      perPage: numberofItems ?? 25,
      pageNumber: 1,
      filters: filtersToApply,
      paginated: false,
    });
  };
  const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);

  const isAmazonIntegrated =
    clientMarketplaceIntegrations?.findIndex((integration) =>
      ["FBA", "FBM"].includes(integration.integrationType),
    ) != -1 ?? false;

  const handleAmazonSearch = () => {
    const [selectedIntegrationId, selectedIntegrationType] =
      selectedChannel.split("-");

    let integration = clientMarketplaceIntegrations?.find(
      (i) =>
        i.id === selectedIntegrationId &&
        i.integrationType === selectedIntegrationType,
    );

    const variables = {
      keyword: productSearchKeyword,
      customer: selectedCustomer,
      warehouse: selectedWarehouse,
      marketplace: selectedMarketplace,
    };
    if (integration.sellerId) {
      variables.sellerId = integration.sellerId;
    }

    importProductsQuery.fetchData(variables);
    setShowImportedProducts(true);
  };

  // The entity can be anything. It calls the callback with the product info that needs to be added. consumer component should handle the logic.
  const addProductToEntity = (item) => {
    if (addProduct) {
      addProduct(item);
    }
  };

  const saveImportedProduct = (item) => {
    const [selectedIntegrationId, selectedIntegrationType] =
      selectedChannel.split("-");

    let integration = clientMarketplaceIntegrations?.find(
      (i) =>
        i.id === selectedIntegrationId &&
        i.integrationType === selectedIntegrationType,
    );

    saveProductQuery.fetchData({
      ...item,
      sellerId: integration?.sellerId,
      marketplace: selectedMarketplace,
    });
  };

  return (
    <div className="mb-8 w-full rounded-lg border border-borderGray p-5">
      <div className="mb-2 flex space-x-6">
        <div className="w-297">
          <label className="mb-1 block text-sm font-medium text-lightGray">
            Channel
          </label>
          <AutocompleteDropdownV2
            options={clientMarketplaceIntegrations?.map((integration) => ({
              ...integration,
              id: integration.id + `-${integration.integrationType}`,
            }))}
            labelKey="integrationName"
            valueKey="id"
            value={selectedChannel}
            placeholder="Select Channel"
            searchable={clientMarketplaceIntegrations?.length > 5 ?? false}
            onChange={(id) => {
              setShowMarketplace(id.includes("FBA") || id.includes("FBM"));
              setSelectedChannel(id);
            }}
          />
        </div>
        {showMarketplace &&
          multiAccountSupportEnabled &&
          marketplaces.length > 0 && (
            <div className="w-297">
              <label className="mb-1 block text-sm font-medium text-lightGray">
                Marketplace
              </label>
              <AutocompleteDropdownV2
                options={marketplaces}
                labelKey="label"
                valueKey="value"
                placeholder="Select Marketplace"
                value={selectedMarketplace}
                searchable={marketplaces.length > 5 ?? false}
                onChange={(marketplace) => {
                  if (marketplace !== selectedMarketplace) {
                    setAvailableProducts([]);
                    setImportedProducts([]);
                    onChangeInventorySearch(productSearchKeyword);
                  }
                  setSelectedMarketplace(marketplace);
                }}
              />
            </div>
          )}
      </div>
      <div className="relative w-full">
        <label className="mb-1 block text-sm font-medium text-lightGray">
          Search Product
        </label>
        <div className="relative block w-full rounded border border-borderGray bg-white not-italic">
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
            <svg
              aria-hidden="true"
              className="h-5 w-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
          <div className="w-11/12">
            <input
              type="text"
              className="w-full border-0"
              placeholder={
                isPrepCenter || isAmazonIntegrated
                  ? "Search by ASIN/SKU/FNSKU/UPC/LPN/Name"
                  : "Search by SKU/UPC/LPN/Name"
              }
              onChange={(e) => {
                setProductSearchKeyword(e.target.value.trim());
                debouncedInventorySearch(e.target.value.trim());
                setShowImportedProducts(false);
              }}
            />
          </div>

          <SearchResults
            productSearchKeyword={productSearchKeyword}
            loader={loader}
            availableProducts={availableProducts}
            importedProducts={importedProducts}
            showImportOption={showImportOption}
            showImportedProducts={showImportedProducts}
            saveImportedProduct={saveImportedProduct}
            addProductToEntity={addProductToEntity}
            isPrepCenter={isPrepCenter}
            setShowImage={setShowImage}
            handleAmazonSearch={handleAmazonSearch}
          />
        </div>
      </div>
    </div>
  );
};

const SearchResults = ({
  productSearchKeyword,
  loader,
  availableProducts,
  importedProducts,
  showImportOption,
  showImportedProducts,
  saveImportedProduct,
  addProductToEntity,
  isPrepCenter,
  setShowImage,
  handleAmazonSearch,
}) => {
  if (!productSearchKeyword) {
    return null;
  }

  if (loader) {
    return (
      <div className="absolute z-20 mt-2 flex h-329 w-full flex-col items-center justify-center rounded border bg-white">
        <div className="h-10 w-10 animate-spin rounded-full border-t-4 border-solid border-hyperlinkColor"></div>
      </div>
    );
  }

  /* Import Product Search Results */
  if (showImportedProducts) {
    return (
      <>
        {importedProducts.length > 0 ? (
          <div className="absolute z-20 mt-2 max-h-329 w-full overflow-y-scroll rounded border bg-white p-3">
            {importedProducts.map((item, index) => (
              <div className="mb-3 flex" key={index}>
                <img
                  src={
                    item?.images?.[0]?.display_url
                      ? item.images[0].display_url
                      : FALLBACK_IMAGE_URL
                  }
                  style={{ width: "72px", height: "72px" }}
                  alt={""}
                  className="rounded object-cover"
                  onClick={() =>
                    setShowImage(
                      item?.images?.[0]?.display_url ?? FALLBACK_IMAGE_URL,
                    )
                  }
                />
                <div className="ml-4 flex-1">
                  <p
                    className="w-600 truncate text-lg font-semibold"
                    data-tip
                    data-for={`${item.id}_${index}`}>
                    {item.name}
                  </p>
                  <ReactTooltip id={`${item.id}_${index}`}>
                    {item.name}
                  </ReactTooltip>
                  <p className="text-sm font-normal text-lightGray">
                    SKU: {item.sku}
                  </p>
                  {isPrepCenter && item.asin && (
                    <p className="text-sm font-normal text-lightGray">
                      ASIN:{" "}
                      {buildMarketplaceHyperlink(
                        item.asin,
                        "ASIN",
                        item.source,
                      )}
                    </p>
                  )}
                </div>
                <button
                  className="my-auto rounded bg-hyperlinkColor text-white"
                  style={{ width: "120px", height: "32px" }}
                  onClick={() => saveImportedProduct(item)}>
                  Add Product
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="absolute z-20 mt-2 flex h-329 w-full flex-col items-center justify-center rounded border bg-white">
            <img
              style={{ width: "154px", height: "144px" }}
              alt={""}
              src="https://hopstack-pub.s3.amazonaws.com/icons/Searching.png"
            />
            <p className="mt-2 text-base font-semibold">No Results Found</p>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {/* Inventory Search Results */}
      {availableProducts.length > 0 ? (
        <div className="absolute z-20 mt-2 max-h-329 w-full overflow-y-scroll rounded border bg-white p-3">
          {availableProducts.map((item, index) => (
            <div className="mb-3 flex" key={index}>
              <img
                src={
                  item?.images?.[0]?.display_url
                    ? item.images[0].display_url
                    : FALLBACK_IMAGE_URL
                }
                style={{ width: "72px", height: "72px" }}
                alt={""}
                className="rounded object-cover"
                onClick={() =>
                  setShowImage(
                    item?.images?.[0]?.display_url ?? FALLBACK_IMAGE_URL,
                  )
                }
              />
              <div className="ml-4 flex-1">
                <p
                  className="w-600 truncate text-lg font-semibold"
                  data-tip
                  data-for={`${item.id}_${index}`}>
                  {item.name}
                </p>
                <ReactTooltip id={`${item.id}_${index}`}>
                  {item.name}
                </ReactTooltip>
                <p className="text-sm font-normal text-lightGray">
                  SKU: {item.sku}
                </p>
                {isPrepCenter && item.asin && (
                  <p className="text-sm font-normal text-lightGray">
                    ASIN:{" "}
                    {buildMarketplaceHyperlink(item.asin, "ASIN", item.source)}
                  </p>
                )}
              </div>
              <button
                className="my-auto rounded bg-hyperlinkColor text-white"
                style={{ width: "96px", height: "32px" }}
                onClick={() => addProductToEntity(item)}>
                Select
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="absolute z-20 mt-2 flex h-329 w-full flex-col items-center justify-center rounded border bg-white">
          {showImportOption ? (
            // Import From Seller's Catalogue Option
            <div className="flex flex-col items-center justify-center">
              <img
                style={{ width: "154px", height: "144px" }}
                alt={""}
                src="https://hopstack-pub.s3.amazonaws.com/icons/Searching.png"
              />
              <p className="mt-2 text-base font-semibold">No Results Found</p>
              <p className="mt-2 w-367 text-center text-xs font-normal">
                Try searching in your catalog for the product
              </p>
              <button
                className="mt-2 h-33 w-241 rounded bg-hyperlinkColor text-white"
                onClick={handleAmazonSearch}>
                Search Seller Catalogue
              </button>
            </div>
          ) : (
            // No Results Found
            <>
              <img
                style={{ width: "154px", height: "144px" }}
                alt={""}
                src="https://hopstack-pub.s3.amazonaws.com/icons/Searching.png"
              />
              <p className="mt-2 text-base font-semibold">No Results Found</p>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ProductSearch;
