import React, { useContext } from "react";
import { AppStateContext } from "#contexts/appState";

const Warning = () => {
  const appState = useContext(AppStateContext);
  const tenant = appState?.tenant;
  let disableDate = null;

  if (tenant && tenant.paymentDetails && tenant.paymentDetails.isPaymentDue) {
    disableDate = new Date(tenant.paymentDetails.earliestUnpaidInvoiceDate);
    disableDate.setDate(
      disableDate.getDate() + tenant.paymentDetails.disableThresholdInDays,
    );
  }

  return (
    <div
      className={`relative isolate items-center overflow-hidden bg-red-600 px-6 py-2.5 text-center font-montserrat text-sm text-white sm:px-3.5 sm:before:flex-1`}>
      {disableDate && (
        <div>
          You have pending dues. Please check your email to resolve this error.
          Please pay the invoice before due date {disableDate.toDateString()} to
          ensure continued operations.
        </div>
      )}
    </div>
  );
};

export default Warning;
