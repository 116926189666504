import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCBeReXWl7KC-dPQ-WeRSgZIGDjj2BzGUY",
  authDomain: "wirago-uat.firebaseapp.com",
  projectId: "wirago-uat",
  storageBucket: "wirago-uat.appspot.com",
  messagingSenderId: "874443519848",
  appId: "1:874443519848:web:6c18dbd1a69bfcd5d508dd",
  measurementId: "G-FV8FGR247P",
};

const app = initializeApp(firebaseConfig);
const firestoreDb = getFirestore(app);

export default firestoreDb;
