import React, { useState } from "react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/solid";

const FieldSelector = ({ fields, selectedFields, onSelectionChange }) => {
  const [internalSelectedFields, setInternalSelectedFields] = useState([]);

  const currentSelectedFields = selectedFields || internalSelectedFields;
  const handleSelectionChange =
    onSelectionChange ||
    ((field) => {
      if (currentSelectedFields.includes(field)) {
        const newSelectedFields = currentSelectedFields.filter(
          (f) => f !== field,
        );
        setInternalSelectedFields(newSelectedFields);
      } else {
        const newSelectedFields = [...currentSelectedFields, field];
        setInternalSelectedFields(newSelectedFields);
      }
    });

  return (
    <div className="flex">
      <div className="mr-4 w-80">
        <div className="w-full pb-4 text-center font-montserrat text-lg font-medium text-darkText">
          Available Fields
        </div>
        <div className="h-80 w-full overflow-auto">
          {fields.map(
            (field, index) =>
              !currentSelectedFields.includes(field) && (
                <div
                  className="flex w-[300px] cursor-pointer border-b-2 border-gray-200 hover:bg-gray-200"
                  key={index}
                  onClick={() => handleSelectionChange(field)}>
                  <span className="text-md pb-1 font-montserrat font-medium text-darkText">
                    {field}
                  </span>
                  <span className="ml-auto">
                    <ArrowRightIcon className="h-6 w-6 font-normal text-arrowColor" />
                  </span>
                </div>
              ),
          )}
        </div>
      </div>
      <div className="w-80">
        <div className="w-full pb-4 text-center font-montserrat text-lg font-medium text-darkText">
          Selected Fields
        </div>
        <div className="h-80 w-full overflow-auto">
          {currentSelectedFields.map((field, index) => (
            <div
              className="flex w-[300px] cursor-pointer border-b-2 border-gray-200 hover:bg-gray-200"
              key={index}
              onClick={() => handleSelectionChange(field)}>
              <span>
                <ArrowLeftIcon className="h-6 w-6 font-normal text-arrowColor" />
              </span>
              <span className="text-md ml-auto pb-1 font-montserrat font-medium text-darkText">
                {field}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FieldSelector;
