import ReCAPTCHA from "react-google-recaptcha";
import TextField from "#components/utils/TextField";

const config = require("config");

const LoginBox = ({
  onSubmit,
  onChange,
  resetPassword,
  values: { username, password, captcha },
  tenant,
}) => {
  const handleChange = (token) => {
    onChange({
      target: {
        value: token,
        name: "captcha",
      },
    });
  };
  return (
    <div className="xs:10/12 md:6/12 min-w-xl flex max-w-xl flex-col items-center justify-center space-y-6 rounded-2xl bg-white p-10 sm:w-8/12">
      <div className="text-2xl font-medium text-primaryAccent">Login</div>
      <div className="text-2xl font-medium text-454A4F">
        {tenant
          ? `Welcome back, team ${tenant.name}`
          : "Welcome to Hopstack Inc."}
      </div>
      <form
        onSubmit={(e) => {
          if (!config.CAPTCHA_ENABLED || captcha) onSubmit(e);
        }}
        className="w-full flex-col items-center justify-center space-y-6"
        noValidate>
        <TextField
          label="Username / Email"
          id="username"
          type="text"
          placeholder=" "
          onChange={onChange}
          value={username}
        />
        <TextField
          label="Password"
          placeholder=" "
          id="password"
          type="password"
          onChange={onChange}
          value={password}
        />
        {config.CAPTCHA_ENABLED && (
          <div style={{ minHeight: "74px" }}>
            <ReCAPTCHA
              name="captcha"
              sitekey={config.CAPTCHA_KEY}
              onChange={handleChange}
            />
          </div>
        )}

        <div
          onClick={resetPassword}
          className="flex cursor-pointer justify-end text-lg font-medium text-primaryAccent">
          Forgot your password?
        </div>
        <div className="flex w-full items-center justify-center">
          <input
            disabled={config.CAPTCHA_ENABLED && !captcha}
            type="submit"
            className={`${
              captcha ? "" : ""
            } inline-flex w-64 cursor-pointer justify-center rounded-md border border-transparent bg-primaryAccent p-4 font-montserrat text-2xl font-normal text-textWhite outline-none ring-0 focus:outline-none focus:ring-0 disabled:opacity-50`}
            value="Login"
          />
        </div>
      </form>
    </div>
  );
};

export default LoginBox;
