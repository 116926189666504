const CardDataTable = ({ numOfColumns, headers, values, getSku }) => (
  // <div className={`grid grid-cols-${numOfColumns} gap-1`}>
  //   {headers.map((column) => {
  //     return (
  //       <div className={`text-sm text-gray col-span-${column.space}`}>
  //         {column.title}
  //       </div>
  //     );
  //   })}
  // </div>
  <div className="bg-white p-4">
    <table className="min-w-full divide-y divide-gray-200 font-montserrat">
      {headers.length > 0 && (
        <thead>
          <tr className="border-b border-textWhite">
            {headers.map((header) => (
              <th
                scope="col"
                className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500"
                key={header}>
                {header.title}
              </th>
            ))}
          </tr>
        </thead>
      )}

      <tbody>
        {values.length > 0 &&
          values.map((value) => (
            <tr
              className={`border-${
                headers.length ? "t" : "b"
              } border-textWhite`}
              key={value}>
              {Object.values(value).map((val) => (
                <td
                  scope="col"
                  className="px-1 py-3 text-left font-medium tracking-wider text-2C7695"
                  onClick={() => getSku(val)}>
                  {val}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

export default CardDataTable;
