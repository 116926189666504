import { useState } from "react";
import SlideOverModal from "#components/common/SlideOverModal";
import { Tab } from "@headlessui/react";

import MetaInformation from "./MetaInformation";
import ExpandedChildOrders from "./ChildOrders";
import ExpandedAttributes from "./OrderAttributes";
import ExpandedShipmentPlan from "./ShipmentPlan";
import ExpandedShippingAddress from "./ShippingAddress";
import OrderLineItems from "./OrderLineItems";
import ExpandedBundleDetails from "./ExpandedBundleDetails";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ExpandedOrder = ({
  writable,
  expandedOrderInfo,
  negativeAction,
  selectedOrderLineItem,
  setSelectedOrderLineItem,
  onChangeSelectedOrderLineItem,
  updateOrderLineItem,
  multiAccountSupportEnabled,
  customers,
  warehouses,
  tenant,
  packingLabelsEnabled,
  printFnSkuLabels,
  downloadFnSkuLabels,
  printPackingLabels,
  downloadPackingLabel,
  csvPackingListEnabled,
  downloadPackingList,
  getAudit,
  downloadOrderReport,
  printPalletLabels,
  downloadPalletLabels,
  printBoxLabels,
  bolUploadEnabled,
  downloadBol,
  printBol,
  downloadBoxLabels,
  printShippingLabel,
  downloadShippingLabel,
  printOrder,
  showOnlyAudit = false,
  cancelOrders,
  markCompleted,
}) => {
  const order = expandedOrderInfo.order;
  const marketplace = order.marketplace;
  const sellerId = order.sellerId;
  const [bundleLineItemDetails, setBundleLineItemDetails] = useState(null);

  // Construct the tabs for the new UI
  const tabs = {};

  tabs["Line Items"] = (
    <OrderLineItems
      writable={writable}
      orderLineItems={
        order.orderLineItems?.length > 0
          ? order.orderLineItems
          : order.orderProducts?.length > 0
            ? order.orderProducts
            : []
      }
      setSelectedOrderLineItem={setSelectedOrderLineItem}
      order={order}
      multiAccountSupportEnabled={multiAccountSupportEnabled}
      warehouses={warehouses}
      marketplace={marketplace}
      sellerId={sellerId}
      bundleLineItemDetails={bundleLineItemDetails}
      setBundleLineItemDetails={setBundleLineItemDetails}
    />
  );

  if (
    (tenant?.settings?.backOrderEnabled && expandedOrderInfo.isBackOrder) ||
    (tenant?.settings?.splitOrderEnabled && expandedOrderInfo.isSplitOrder)
  ) {
    tabs["Suborders"] = (
      <ExpandedChildOrders
        childOrders={expandedOrderInfo.childOrders}
        packingLabelsEnabled={packingLabelsEnabled}
        printFnSkuLabels={printFnSkuLabels}
        downloadFnSkuLabels={downloadFnSkuLabels}
        printPackingLabels={printPackingLabels}
        downloadPackingLabel={downloadPackingLabel}
        csvPackingListEnabled={csvPackingListEnabled}
        downloadPackingList={downloadPackingList}
        getAudit={getAudit}
        tenant={tenant}
        downloadOrderReport={downloadOrderReport}
        printPalletLabels={printPalletLabels}
        downloadPalletLabels={downloadPalletLabels}
        printBoxLabels={printBoxLabels}
        bolUploadEnabled={bolUploadEnabled}
        downloadBol={downloadBol}
        printBol={printBol}
        downloadBoxLabels={downloadBoxLabels}
        printShippingLabel={printShippingLabel}
        downloadShippingLabel={downloadShippingLabel}
        printOrder={printOrder}
        showOnlyAudit={showOnlyAudit}
        cancelOrders={cancelOrders}
        markCompleted={markCompleted}
        writable={writable}
      />
    );
  }

  if (order.attributes && Object.keys(order.attributes).length > 0) {
    tabs["Attributes"] = <ExpandedAttributes order={order} />;
  }

  if (order.source === "FBA") {
    tabs["Shipment Plan"] = (
      <ExpandedShipmentPlan shipmentPlan={order.shipmentPlan} />
    );
  } else if (
    order.shippingAddress &&
    Object.keys(order.shippingAddress).length > 0
  ) {
    tabs["Shipping Address"] = <ExpandedShippingAddress order={order} />;
  }

  return (
    <div>
      <SlideOverModal
        open={true}
        onClose={negativeAction}
        title={`Order Details`}
        width={"w-3/4"}>
        <div className="flex h-full w-full flex-col">
          <div className="grow">
            <MetaInformation
              tenant={tenant}
              expandedOrderInfo={expandedOrderInfo}
              customers={customers}
              warehouses={warehouses}
            />
            <Tab.Group>
              <Tab.List className="mt-4 flex items-center">
                <div className="flex-1 space-x-4 rounded-xl p-1 px-0">
                  {tabs &&
                    Object.keys(tabs).map((tabName) => (
                      <Tab
                        key={tabName}
                        className={({ selected }) =>
                          classNames(
                            "font-regular rounded-full px-6 py-2.5 text-sm leading-5",
                            "bg-E1D3B8 font-montserrat font-semibold text-primaryAccent focus:outline-none focus:ring-0",
                            selected ? "border border-black" : "",
                          )
                        }>
                        {tabName}
                      </Tab>
                    ))}
                </div>
              </Tab.List>
              <Tab.Panels className="mt-2">
                {tabs &&
                  Object.values(tabs).map((tabComponent, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="textWhite-300 mt-4 flex flex-col">
                      {tabComponent}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </SlideOverModal>

      {bundleLineItemDetails && (
        <ExpandedBundleDetails
          bundleLineItemDetails={bundleLineItemDetails}
          setBundleLineItemDetails={setBundleLineItemDetails}
        />
      )}
    </div>
  );
};

export default ExpandedOrder;
