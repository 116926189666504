import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import Autocomplete from "#components/utils/Autocomplete";
import Tabs from "#components/utils/Tabs";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const CycleCountPlanForm = ({
  onClose,
  title,
  warehouses,
  onChange,
  onChangeMultiSelect,
  selectedCycleCountPlan,
  onSubmit,
  specificBinLocationOptions,
  customers,
  onChangeMultiSelectUsers,
  debouncedBinLocationSearch,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Cycle Count Plan Details
        </span>
      </div>
      {((warehouses && warehouses.length > 1) ||
        !selectedCycleCountPlan.warehouse) && (
        <>
          <Autocomplete
            options={warehouses}
            labelKey="name"
            valueKey="id"
            onChange={(selectedOption) =>
              onChange({
                target: {
                  name: "warehouse",
                  value: selectedOption,
                },
              })
            }
            value={selectedCycleCountPlan.warehouse}
            placeholder={"Select Warehouse"}
            id={`SELECT_WAREHOUSE`}
          />
        </>
      )}
      {selectedCycleCountPlan.warehouse && (
        <div>
          <div id="selectingStations">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Select Clients
              </span>
            </label>
            <MultiSelectAutoComplete
              options={getFilteredCustomerList(
                selectedCycleCountPlan.warehouse,
                customers,
              )}
              labelKey={"name"}
              valueKey={"id"}
              disabled={selectedCycleCountPlan.executions?.length >= 1}
              setValues={(e) => onChangeMultiSelectUsers("customers", e)}
              values={selectedCycleCountPlan.customers || []}
              emptyValuesAccountsForAll={true}
            />
          </div>
        </div>
      )}
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedCycleCountPlan.name}
        />
      </div>
      <>
        <Autocomplete
          options={[
            { name: "Frequency: Daily", id: "Daily" },
            { name: "Frequency: Weekly", id: "Weekly" },
            { name: "Frequency: Monthly", id: "Monthly" },
          ]}
          labelKey="name"
          valueKey="id"
          onChange={(selectedOption) =>
            onChange({
              target: {
                name: "frequency",
                value: selectedOption,
              },
            })
          }
          value={selectedCycleCountPlan.frequency}
          placeholder={"Frequency"}
          id={`SELECT_CYCLE_COUNT_FREQUENCY`}
        />
      </>
      <div>
        <TextField
          type="date"
          id="startDate"
          label="StartDate"
          placeholder=" "
          onChange={onChange}
          value={selectedCycleCountPlan.startDate}
        />
      </div>

      <>
        <Autocomplete
          options={[{ name: "Cycle Count Type: Locations", id: "Locations" }]}
          labelKey="name"
          valueKey="id"
          onChange={(selectedOption) =>
            onChange({
              target: {
                name: "type",
                value: selectedOption,
              },
            })
          }
          value={selectedCycleCountPlan.type}
          placeholder={"Cycle Count Type"}
          id={`SELECT_CYCLE_COUNT_TYPE`}
        />
      </>
      {selectedCycleCountPlan.type === "Locations" &&
        selectedCycleCountPlan.warehouse && (
          <div>
            <div className="mt-5">
              <label className="mb-2 block text-left">
                <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                  Specific Bins
                </span>
              </label>
              <MultiSelectAutoComplete
                options={specificBinLocationOptions}
                labelKey="code"
                valueKey="code"
                disabled={
                  selectedCycleCountPlan.executions?.length >= 1 ? true : false
                }
                onKeyDown={(e) => debouncedBinLocationSearch(e)}
                setValues={(e) => onChangeMultiSelect(e, "specificBins")}
                values={selectedCycleCountPlan.specificBins}
              />
            </div>
          </div>
        )}
    </div>
  </Modal>
);

export default CycleCountPlanForm;

const truncate = (str) => {
  return str.length > 40 ? str.substring(0, 40) + "..." : str;
};
