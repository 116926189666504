const STORAGE_SECTION_STATUSES = {
  ACTIVE: "active",
  UNDER_REPAIR: "under_repair",
  AVAILABLE: "available",
  UNAVAILABLE: "unavailable",
  UNDER_MAINTENANCE: "under_maintenance",
  DISABLED: "disabled",
};

export default STORAGE_SECTION_STATUSES;
