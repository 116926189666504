import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";

const headers = [
  "SKU",
  "Bin Location",
  "Qty",
  "Picked Qty",
  "Status",
  "Action",
];

const ListView = ({ currentBatch, scanBarcode, setSelectedIndex }) => {
  return (
    <div className="max-w-4xl flex-1">
      <PageTitle>Total ({currentBatch.inventory.length}) </PageTitle>
      <div className="mt-10 rounded-md bg-EBEBEB">
        <table className="-mt-4 min-w-full divide-y divide-gray-200">
          <thead className="rounded-full p-4">
            <tr className="font-montserratborder-left text-primaryAccent">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {currentBatch.inventory.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {item.sku}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.binLocation}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.quantity}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.scannedQuantity}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {renderButton(item.status)}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.status != "COMPLETE" && (
                    <div
                      className="cursor-pointer rounded-full bg-blue-500 px-2 py-1 text-center text-lg text-white"
                      onClick={() => {
                        scanBarcode(item.sku);
                        setSelectedIndex("Detail View");
                      }}>
                      Scan
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const renderButton = (status) => {
  switch (status) {
    case "COMPLETE":
      return (
        <div className="rounded-full bg-50BFC3 px-2 py-1 text-center text-lg text-454A4F">
          Completed
        </div>
      );
    case "UNPROCESSED":
      return (
        <div className="rounded-full bg-EFE9DC px-2 py-1 text-center text-lg text-454A4F">
          Incomplete
        </div>
      );
  }
};

export default ListView;
