import Barcode from "react-barcode";

const Single30UpFnSkuLabel = ({ id, value, text1, text2 }) => (
  <div
    id={`${id}_FNSKU`}
    style={{
      backgroundColor: "#fff",
      fontFamily: "Arial",
      fontSize: 12,
      display: "flex",
      flexDirection: "column",
    }}>
    <Barcode
      value={value}
      fontSize={14}
      height={30}
      width={1.6}
      font="Arial"
      textMargin={-2}
      fontOptions="bold"
    />
    <div className="-mt-1 ml-2 items-start text-left font-bold">
      {text1 && <div>{text1}</div>}
      {text2 && text2.trim() !== "" && <div className="-mt-2">{text2}</div>}
      {(!text2 || text2.trim() === "") && (
        <div className="-mt-2 text-white">PLACEHOLDER</div>
      )}
    </div>
  </div>
);

export default Single30UpFnSkuLabel;
