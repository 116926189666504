/**
 * used in places where upon selecting warehouse, we need to filter the customers specific to the selected warehouse.
 * if selectedWarehouse is array of warehouse ids, then use case is MultiSelectAutoComplete where multiple warehouse are selected.
 * if selectedWarehouse is just string, then use case is dropdown of chosing single warehouse.
 * @param {string|Array<string>} selectedWarehouse
 * @param {Array<Object>} customers
 * @returns
 */
export const getFilteredCustomerList = (selectedWarehouse, customers) => {
  if (!selectedWarehouse) {
    return customers;
  }

  if (Array.isArray(selectedWarehouse)) {
    if (selectedWarehouse.length === 0) {
      return customers;
    }

    return customers.filter(
      (customer) =>
        customer.warehouses &&
        customer.warehouses.some((warehouse) =>
          selectedWarehouse.includes(warehouse),
        ),
    );
  }

  return customers.filter(
    (customer) =>
      customer.warehouses && customer.warehouses.includes(selectedWarehouse),
  );
};
