import { useState, useEffect, useContext } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { CRM_CUSTOMERS } from "#queries";
import _ from "lodash";
import SlideOverPanel from "#components/common/SlideOverPanel";
import noSearchResultsIcon from "#static/images/noSearchResults.jpg";
import {
  EDIT_CRM_SHIPPING_ADDRESS,
  DELETE_CRM_SHIPPING_ADDRESS,
  EDIT_CRM_ACCOUNT_INFO,
  CREATE_CRM_SHIPPING_ADDRESS,
} from "#mutations/index";
import { AppStateContext } from "#contexts/appState";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { Tooltip } from "antd";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import { Country, State } from "country-state-city";
import { GET_SALES_ORDER } from "#queries/index";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import moment from "moment-timezone";

const customerSearchCriteria = [
  {
    name: "Customer ID",
    value: "Customer_ID",
    enabled: true,
  },
  {
    name: "Business Name",
    value: "Account_Name",
    enabled: false,
  },
  {
    name: "First Name",
    value: "First_Name",
    enabled: false,
  },
  {
    name: "Last Name",
    value: "Last_Name",
    enabled: false,
  },
  {
    name: "Contact Number",
    value: "Phone",
    enabled: false,
  },
  {
    name: "Email ID",
    value: "Email",
    enabled: false,
  },
];

const CUSTOMERS_PER_PAGE = 10;
const ALERT_VISIBILITY_IN_MS = 5000;

const tabsEnums = {
  SHIPPING: "shipping",
  ACCOUNT: "account",
  ORDER_HISTORY: "order_history",
};

const initialAddressInput = {
  country: "US",
};

const CustomerEnhancedSearch = (props) => {
  const {
    onChange,
    selectedCustomer,
    setSelectedCustomer,
    selectedConsignment,
  } = props;
  const appState = useContext(AppStateContext);
  const crmCustomersQuery = useQuery(CRM_CUSTOMERS);
  const createCrmShippingAddressQuery = useQuery(CREATE_CRM_SHIPPING_ADDRESS);
  const editCrmShippingAddressQuery = useQuery(EDIT_CRM_SHIPPING_ADDRESS);
  const deleteCrmCustomerQuery = useQuery(DELETE_CRM_SHIPPING_ADDRESS);
  const editCrmAccountInfo = useQuery(EDIT_CRM_ACCOUNT_INFO);
  const getSalesOrderQuery = useQuery(GET_SALES_ORDER);

  const [keyword, setKeyword] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [activeTab, setActiveTab] = useState(tabsEnums.SHIPPING);
  const [searchFields, setSearchFields] = useState(customerSearchCriteria);
  const [customersPageNo, setCustomersPageNo] = useState(1);
  const [allCustomersFetched, setAllCustomersFetched] = useState(false);

  const [addShippingAddress, setAddShippingAddress] = useState(false);
  const [editShippingAddress, setEditShippingAddress] = useState(false);
  const [editAccountAddress, setEditAccountAddress] = useState(false);
  const [addressInput, setAddressInput] = useState(initialAddressInput);
  const [shippingAddressId, setShippingAddressId] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(
    initialAddressInput.country,
  );
  const [selectedState, setSelectedState] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);

  useEffect(() => {
    if (editCrmShippingAddressQuery.error) {
      appState.setAlert(
        editCrmShippingAddressQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (editCrmShippingAddressQuery.data) {
      appState.setAlert(
        editCrmShippingAddressQuery.data.editCrmShippingAddress.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
      setEditShippingAddress(false);
    }

    if (editCrmShippingAddressQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    editCrmShippingAddressQuery.error,
    editCrmShippingAddressQuery.data,
    editCrmShippingAddressQuery.loading,
  ]);

  useEffect(() => {
    if (createCrmShippingAddressQuery.error) {
      appState.setAlert(
        createCrmShippingAddressQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (createCrmShippingAddressQuery.data) {
      appState.setAlert(
        createCrmShippingAddressQuery.data.createCrmShippingAddress.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
      setAddShippingAddress(false);
    }

    if (createCrmShippingAddressQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    createCrmShippingAddressQuery.error,
    createCrmShippingAddressQuery.data,
    createCrmShippingAddressQuery.loading,
  ]);

  useEffect(() => {
    if (editCrmAccountInfo.error) {
      appState.setAlert(
        editCrmAccountInfo.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (editCrmAccountInfo.data) {
      appState.setAlert(
        editCrmAccountInfo.data.editCrmCustomer.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
      setEditAccountAddress(false);
    }

    if (editCrmAccountInfo.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    editCrmAccountInfo.error,
    editCrmAccountInfo.data,
    editCrmAccountInfo.loading,
  ]);

  useEffect(() => {
    if (deleteCrmCustomerQuery.error) {
      appState.setAlert(
        deleteCrmCustomerQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (deleteCrmCustomerQuery.data) {
      appState.setAlert(
        deleteCrmCustomerQuery.data.deleteCrmShippingAddress.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
    }

    if (deleteCrmCustomerQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    deleteCrmCustomerQuery.error,
    deleteCrmCustomerQuery.data,
    deleteCrmCustomerQuery.loading,
  ]);

  useEffect(() => {
    if (selectedCustomer && crmCustomersQuery?.data?.crmCustomers) {
      setSelectedCustomer(crmCustomersQuery.data.crmCustomers?.entities[0]);
    }

    if (!searchResults && crmCustomersQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    crmCustomersQuery.error,
    crmCustomersQuery.data,
    crmCustomersQuery.loading,
    searchResults,
  ]);

  useEffect(() => {
    const updateStates = () => {
      if (selectedCountry) {
        const states = State.getStatesOfCountry(selectedCountry);
        setFilteredStates(states);
        const stateIsValid = states.some(
          (state) => state.isoCode === selectedState,
        );
        if (!stateIsValid) {
          setSelectedState("");
        }
      } else {
        setFilteredStates([]);
        setSelectedState("");
      }
    };

    updateStates();
  }, [selectedCountry]);

  useEffect(() => {
    if (activeTab === tabsEnums.ORDER_HISTORY) {
      (async () => {
        const response = await getSalesOrderQuery.fetchData({
          filters: {
            customerIdOnCrm: selectedCustomer.id,
          },
        });

        if (response.data?.getSalesOrders?.entities) {
          setOrderHistory(response.data.getSalesOrders.entities);
        }
      })();
    }
  }, [activeTab]);

  const onChangeCrmCustomerSearch = async (searchKeyword) => {
    if (crmCustomersQuery.loading) return;

    const response = await crmCustomersQuery.fetchData({
      perPage: CUSTOMERS_PER_PAGE,
      pageNumber: customersPageNo,
      filters: {
        keyword: searchKeyword,
        searchFields: searchFields.filter((i) => i.enabled).map((i) => i.value),
      },
      sort: "-createdAt",
    });

    if (response.data?.crmCustomers?.entities?.length > 0) {
      setSearchResults((prev) => [
        ...prev,
        ...response.data.crmCustomers?.entities,
      ]);
      setCustomersPageNo((page) => page + 1);
    } else if (response?.error?.message) {
      appState.setAlert(
        response?.error?.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    } else if (
      !response.data ||
      response.data?.crmCustomers?.entities?.length === 0
    ) {
      setAllCustomersFetched(true);
    }

    return response;
  };
  const debouncedCrmCustomerSearch = _.debounce(onChangeCrmCustomerSearch, 500);

  const handleProductScroll = (event) => {
    console.log("scroll");

    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const threshold = 1;

    if (
      scrollTop + clientHeight + threshold >= scrollHeight &&
      !crmCustomersQuery.loading &&
      !allCustomersFetched
    ) {
      onChangeCrmCustomerSearch(keyword);
    }
  };

  const validateAddress = () => {
    if (!addressInput) {
      return false;
    }

    const { firstName, lastName, line1, state, country, zip, city } =
      addressInput;

    const isValid =
      firstName && lastName && line1 && state && country && zip && city;

    if (!isValid) {
      appState.setAlert(
        "Address entered is invalid.",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setAddressInput((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(newCountry));

    setAddressInput((prev) => ({
      ...prev,
      country: newCountry,
    }));
  };

  const handleStateChange = (newState) => {
    setSelectedState(newState);

    setAddressInput((prev) => ({
      ...prev,
      state: newState,
    }));
  };

  const handleEditAddress = (address) => {
    setAddressInput(address);
    setShippingAddressId(address?.id);
    setEditShippingAddress(true);
  };

  const handleDeleteAddress = async (address) => {
    if (activeTab === tabsEnums.SHIPPING) {
      await deleteCrmCustomerQuery.fetchData({
        deleteCrmShippingAddressId: address.id,
      });
    }
  };

  const isPopupOpen =
    editShippingAddress || editAccountAddress || addShippingAddress;

  const onPopupClose = (prop) => {
    if (editShippingAddress) return setEditShippingAddress(prop);
    if (editAccountAddress) return setEditAccountAddress(prop);
    if (addShippingAddress) return setAddShippingAddress(prop);
  };

  const getPopupTitle = editShippingAddress
    ? "Edit Shipping Address"
    : editAccountAddress
      ? "Edit Account Address"
      : addShippingAddress
        ? "Add Shipping Address"
        : "";

  const popupSaveHandler = () => {
    if (!validateAddress()) {
      return;
    }

    if (editShippingAddress) {
      editCrmShippingAddressQuery.fetchData({
        editCrmShippingAddressInput: {
          ...addressInput,
          id: shippingAddressId,
        },
      });
    }

    if (editAccountAddress) {
      const { businessName, notes, ...rest } = addressInput;
      const { hrid, ...restDetails } = selectedCustomer;

      let editCrmCustomerInput = {
        ...restDetails,
        accountAddress: rest,
      };

      editCrmAccountInfo.fetchData({ editCrmCustomerInput });
    }

    if (addShippingAddress) {
      createCrmShippingAddressQuery.fetchData({
        createCrmShippingAddressInput: {
          crmCustomerId: selectedCustomer.id,
          shippingAddress: addressInput,
        },
      });
    }
  };

  return (
    <div className="relative flex-1 flex-col">
      <div className="relative flex-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            debouncedCrmCustomerSearch(keyword);
          }}>
          <input
            type="search"
            name="search"
            id="search"
            className="block w-full rounded-md border-0 py-3 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
            placeholder="Search"
            onChange={(e) => {
              setKeyword(e.target.value);
              setSearchResults([]);
              setCustomersPageNo(1);
            }}
            value={keyword}
          />
        </form>
      </div>

      {keyword && keyword.trim() !== "" && !selectedCustomer && (
        <div className="absolute z-20 mt-4 w-full border-2 border-[#DDDFE0] bg-white p-4">
          <div className="mb-4 flex gap-4 px-4">
            <span className="mt-2 text-xl font-normal text-[#717679]">
              Criteria:
            </span>

            <div className="flex flex-wrap items-center gap-4">
              {searchFields.map((criteria) => (
                <button
                  key={criteria.value}
                  className={`p-2 ${
                    criteria.enabled
                      ? "bg-primaryAccent text-white"
                      : "bg-[#F1F8FF] text-primaryAccent"
                  } rounded-md text-xl`}
                  onClick={() => {
                    if (searchFields.length === 1) {
                      return;
                    }
                    // DISABLE THE FIELD
                    const updatedFields = searchFields.map((f) => {
                      // Do not let the user deselect the current filter
                      if (criteria.enabled) return f;

                      if (f.value === criteria.value) {
                        return {
                          ...f,
                          enabled: !criteria.enabled,
                        };
                      } else {
                        return {
                          ...f,
                          enabled: false,
                        };
                      }
                    });
                    setSearchFields(updatedFields);
                  }}>
                  {criteria.name}
                </button>
              ))}
            </div>
          </div>

          <div
            className="flex flex-col overflow-x-auto overflow-y-auto"
            style={{ maxHeight: "500px" }}
            onScroll={handleProductScroll}>
            {keyword &&
            !crmCustomersQuery.loading &&
            (!searchResults || searchResults.length === 0) ? (
              <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 text-center">
                <img src={noSearchResultsIcon} className="mx-auto w-48" />
                <div className="mt-4 text-2xl text-black">
                  No Results To Display
                </div>
              </div>
            ) : (
              <div>
                <table className="w-full">
                  {searchResults.map((crmCustomer, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="flex overflow-ellipsis p-4 text-sm font-medium text-gray-900">
                        <div className="flex w-full items-center justify-between">
                          <div>
                            <div className="mt-1 text-xl font-bold text-[#111827]">
                              {`${crmCustomer?.firstName}  ${crmCustomer?.lastName}`}
                            </div>
                            <div className="text-lg font-medium text-[#717679]">
                              {crmCustomer?.contactNumber}
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedCustomer(crmCustomer);
                              onChange("customer", crmCustomer.id);
                            }}
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-224E73 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700">
                            Select
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>

                {crmCustomersQuery.loading ? (
                  <LoadingIndicator shouldShowOnPage={false} />
                ) : null}
              </div>
            )}
          </div>
        </div>
      )}

      {selectedCustomer && (
        <div className="border-1 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="flex items-center space-x-4 px-4 py-5 sm:px-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {`${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Customer ID: {selectedCustomer?.hrid}
              </p>
            </div>
            <div className="flex grow justify-end">
              <button
                type="button"
                onClick={() => {
                  setSelectedCustomer(null);
                  onChange("customer", null);
                }}
                className="cursor-pointer py-2 text-base font-semibold text-primaryAccent underline">
                Clear Selection
              </button>
            </div>
          </div>

          <div className="border-t border-gray-200">
            <nav className="flex space-x-4" aria-label="Tabs">
              <button
                onClick={() => setActiveTab(tabsEnums.SHIPPING)}
                className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                  activeTab === tabsEnums.SHIPPING
                    ? "border-224E73 text-224E73"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }`}>
                Shipping Info
              </button>
              <button
                onClick={() => setActiveTab(tabsEnums.ACCOUNT)}
                className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                  activeTab === tabsEnums.ACCOUNT
                    ? "border-224E73 text-224E73"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }`}>
                Account Info
              </button>
              <button
                onClick={() => setActiveTab(tabsEnums.ORDER_HISTORY)}
                className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                  activeTab === tabsEnums.ORDER_HISTORY
                    ? "border-224E73 text-224E73"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }`}>
                Order History
              </button>
            </nav>

            {activeTab === tabsEnums.SHIPPING &&
              selectedCustomer?.shippingAddresses?.map((address, index) => (
                <div key={index} className="border-1 relative px-4 py-5 sm:p-6">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      className="form-radio"
                      name="address"
                      id="address"
                      value={address}
                      checked={
                        selectedConsignment?.shippingAddress?.id === address.id
                      }
                      onChange={() => {
                        onChange("shippingAddress", address);
                      }}
                    />
                    <label
                      htmlFor="address"
                      className="pointer-events-none ml-3 grow">
                      <p className="text-sm font-medium text-gray-900">
                        {[address?.firstName, address?.lastName]
                          .filter(Boolean)
                          .join(" ")}
                      </p>
                      <p className="text-sm text-gray-500">
                        {[address?.line1, address?.line2]
                          .filter(Boolean)
                          .join(", ")}
                      </p>
                      <p className="text-sm text-gray-500">
                        {[
                          address?.city,
                          address?.state,
                          address?.country,
                          address?.zip,
                        ]
                          .filter(Boolean)
                          .join(", ")}
                      </p>
                    </label>
                  </div>
                  <div className="absolute right-4 top-1/2 flex -translate-y-1/2 transform space-x-2">
                    <PencilIcon
                      className="h-5 w-5 cursor-pointer"
                      style={{ color: "#224E73" }}
                      onClick={() => {
                        handleEditAddress(address);
                      }}
                    />
                    <TrashIcon
                      className="h-5 w-5 cursor-pointer"
                      style={{ color: "#C93A31" }}
                      onClick={() => {
                        appState.showConfirmation(
                          "Delete",
                          "Are you sure you want to delete this address?",
                          () => {
                            handleDeleteAddress(address);
                            appState.hideConfirmation();
                          },
                          appState.hideConfirmation,
                          "Yes, delete",
                          "No, cancel",
                        );
                      }}
                    />
                  </div>
                  <div className="absolute right-4 top-1/2 flex -translate-y-1/2 transform space-x-2">
                    <PencilIcon
                      className="h-5 w-5 cursor-pointer"
                      style={{ color: "#224E73" }}
                      onClick={() => handleEditAddress(address, index)}
                    />
                    <TrashIcon
                      className="h-5 w-5 cursor-pointer"
                      style={{ color: "#C93A31" }}
                      onClick={() => handleDeleteAddress(address)}
                    />
                  </div>
                </div>
              ))}

            {activeTab === tabsEnums.ACCOUNT && (
              <div className="border-1 px-4 py-5 sm:p-6">
                <div className="flex justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      {selectedCustomer?.businessName}
                    </p>
                    <p className="text-sm text-gray-500">
                      {[
                        selectedCustomer?.accountAddress?.firstName,
                        selectedCustomer?.accountAddress?.lastName,
                      ]
                        .filter(Boolean)
                        .join(" ")}
                    </p>
                    <p className="text-sm text-gray-500">
                      {[
                        selectedCustomer?.accountAddress?.line1,
                        selectedCustomer?.accountAddress?.line2,
                        selectedCustomer?.accountAddress?.city,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                    <p className="text-sm text-gray-500">
                      {[
                        selectedCustomer?.accountAddress?.state,
                        selectedCustomer?.accountAddress?.country,
                        selectedCustomer?.accountAddress?.zip,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                  </div>

                  <PencilIcon
                    className="h-5 w-5 cursor-pointer"
                    style={{ color: "#224E73" }}
                    onClick={() => {
                      setAddressInput(selectedCustomer?.accountAddress);
                      setEditAccountAddress(true);
                    }}
                  />
                </div>
              </div>
            )}

            {activeTab === tabsEnums.ORDER_HISTORY && (
              <div className="px-4 py-5 sm:p-6">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  {orderHistory.length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                            Order ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                            Created On
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                            Total Price
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {orderHistory.map((order) => (
                          <tr key={order?.salesOrderId}>
                            <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                              {order?.salesOrderId}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                              {order?.createdAt
                                ? moment(order.createdAt).format("YYYY-MM-DD")
                                : ""}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                              ${order?.cartDetails?.orderValue}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No orders found.</p>
                  )}
                </div>
              </div>
            )}
          </div>

          {activeTab === tabsEnums.SHIPPING && (
            <div className="bg-gray-50 px-4 py-3 text-left sm:px-6">
              <button
                type="button"
                onClick={() => {
                  setAddressInput(initialAddressInput);

                  setAddShippingAddress(true);
                  setEditShippingAddress(false);
                  setEditAccountAddress(false);
                }}
                className={`cursor-pointer py-2 text-base font-semibold text-primaryAccent underline`}>
                + Add Shipping Address
              </button>
            </div>
          )}

          {isPopupOpen && (
            <SlideOverPanel
              open={isPopupOpen}
              setOpen={onPopupClose}
              title={getPopupTitle}>
              <div className="relative h-full overflow-y-auto bg-white p-4">
                <div className="h-full space-y-1">
                  <div className="flex h-full flex-col">
                    {/* BusinessName Field */}
                    {editAccountAddress && (
                      <div className="space-y-1 py-2">
                        <label
                          htmlFor="businessName"
                          className="block text-sm font-medium text-gray-700">
                          Business Name
                        </label>
                        <input
                          type="text"
                          name="businessName"
                          id="businessName"
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          value={selectedCustomer?.businessName}
                          onChange={(e) => {
                            handleInputChange(e);
                            setSelectedCustomer((prev) => ({
                              ...prev,
                              businessName: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    )}

                    <div className="flex gap-2 py-2">
                      {/* First Name Field */}
                      <div className="w-1/2 space-y-1">
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium text-gray-700">
                          First Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="first-name"
                          required
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="Doe"
                          value={addressInput?.firstName}
                          onChange={handleInputChange}
                        />
                      </div>

                      {/* Last Name Field */}
                      <div className="w-1/2 space-y-1">
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-medium text-gray-700">
                          Last Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="last-name"
                          required
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="John"
                          value={addressInput?.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    {/* Email Field */}
                    <div className="space-y-1 py-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700">
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="XYZ123@gmail.com"
                        value={addressInput?.email}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Contact Number Field */}
                    <div className="space-y-1 py-2">
                      <label
                        htmlFor="contact-number"
                        className="block text-sm font-medium text-gray-700">
                        Contact Number
                      </label>
                      <input
                        type="tel"
                        name="contactNumber"
                        id="contact-number"
                        value={addressInput?.contactNumber}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="9876543210"
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Address Fields */}
                    <div className="space-y-1 py-2">
                      {/* Address Line 1 */}
                      <label
                        htmlFor="address-line1"
                        className="block text-sm font-medium text-gray-700">
                        Address Line 1<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="line1"
                        id="address-line1"
                        required
                        value={addressInput?.line1}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="space-y-1 py-2">
                      {/* Address Line 2 */}
                      <label
                        htmlFor="address-line2"
                        className="block text-sm font-medium text-gray-700">
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        name="line2"
                        id="address-line2"
                        value={addressInput?.line2}
                        className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4 space-y-1 py-2">
                      <div>
                        {/* City */}
                        <label
                          htmlFor="address-city"
                          className="block text-sm font-medium text-gray-700">
                          City<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="address-city"
                          value={addressInput?.city}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="zip-code"
                          className="block text-sm font-medium text-gray-700">
                          Zip Code<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="zip"
                          id="zip-code"
                          required
                          value={addressInput?.zip}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="560075"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    {/* State & Country */}
                    <div className="grid grid-cols-2 gap-4 py-2">
                      <div>
                        <Tooltip
                          title={
                            !selectedCountry
                              ? "Please select a country first"
                              : ""
                          }
                          placement="top">
                          <div
                            style={{ display: "inline-block", width: "100%" }}>
                            <label
                              htmlFor="state"
                              className="block text-sm font-medium text-gray-700">
                              State<span className="text-red-500">*</span>
                            </label>
                            <AutocompleteDropdownV2
                              options={filteredStates}
                              labelKey="name"
                              valueKey="isoCode"
                              onChange={handleStateChange}
                              value={addressInput?.state || ""}
                              placeholder="Select a State"
                              id="name"
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700">
                          Country<span className="text-red-500">*</span>
                        </label>
                        <AutocompleteDropdownV2
                          options={Country.getAllCountries()}
                          id="name"
                          labelKey="name"
                          valueKey="isoCode"
                          onChange={handleCountryChange}
                          value={addressInput?.country || ""}
                          placeholder="Select a Country"
                        />
                      </div>
                    </div>

                    {/* Planting Zone Checkbox */}
                    <div className="flex items-start py-2">
                      <div className="flex h-5 items-center">
                        <input
                          id="planting-zone"
                          name="isPlantingZoneAddress"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                          value={addressInput?.isPlantingZoneAddress}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="isPlantingZoneAddress"
                          className="font-medium text-gray-700">
                          Mark the address as a planting zone
                        </label>
                      </div>
                    </div>

                    {/* Planting Zone Code */}
                    <div className="space-y-1 py-2">
                      <label
                        htmlFor="planting-zone"
                        className="block text-sm font-medium text-gray-700">
                        Planting Zone Code
                      </label>
                      <input
                        type="text"
                        name="plantingZoneCode"
                        id="planting-zone"
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder=""
                        value={addressInput?.plantingZoneCode}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Notes Field */}
                    {editAccountAddress && (
                      <div className="space-y-1 py-2">
                        <label
                          htmlFor="notes"
                          className="block text-sm font-medium text-gray-700">
                          Notes
                        </label>
                        <input
                          type="text"
                          name="notes"
                          id="notes"
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          value={selectedCustomer?.notes}
                          onChange={(e) => {
                            handleInputChange(e);
                            setSelectedCustomer((prev) => ({
                              ...prev,
                              notes: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    )}

                    <div className="mt-auto flex items-center justify-end space-x-4 py-2">
                      <button
                        type="button"
                        onClick={() => {
                          setAddressInput(null);
                          onPopupClose(false);
                        }}
                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2">
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={popupSaveHandler}
                        disabled={
                          !addressInput?.firstName ||
                          !addressInput?.lastName ||
                          !addressInput?.line1 ||
                          !addressInput?.state ||
                          !addressInput?.country ||
                          !addressInput?.zip ||
                          !addressInput?.city
                        }
                        className="rounded-md border border-transparent bg-[#224E73] px-4 py-2 text-sm font-medium text-white hover:bg-[#224E73] focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400">
                        Save
                      </button>
                    </div>
                    {/* Form Actions */}
                  </div>
                  {/* Include additional fields for Address Line 2, City, State, ZIP, Country, etc. */}
                </div>
              </div>
            </SlideOverPanel>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerEnhancedSearch;
const truncate = (str) => {
  return str.length > 24 ? str.substring(0, 24) + "..." : str;
};
