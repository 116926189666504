import React from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";

const SuggestedChangesInfo = ({ setShowSuggestedChanges }) => {
  return (
    <div style={{ top: "5.4rem", minWidth: "40%" }} className="mt-2">
      <div className="flex items-center justify-between rounded-md border border-yellow-300 bg-yellow-50 p-1">
        <div className="flex items-center">
          <ExclamationIcon className="mr-2 h-5 w-5 text-yellow-400" />
          <span className="text-sm text-gray-700">
            You have received some changes from vendor, please take action on
            it.
          </span>
        </div>
        <PrimaryButton
          height="2rem"
          width="9rem"
          className="bg-yellow-50 px-5 text-base"
          onClick={() => {
            setShowSuggestedChanges(true);
          }}>
          View Changes
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SuggestedChangesInfo;
