import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const CustomProgressBar = ({ progress }) => {
  return (
    <div className="h-2 w-full overflow-hidden rounded-full bg-gray-200">
      <div
        className={twMerge(
          `h-full rounded-full transition-all duration-300 ${
            progress === 100 ? "bg-green-500" : ""
          }`,
        )}
        style={{
          width: `${progress}%`,
          backgroundColor: progress === 100 ? "" : "#E3A400",
        }}></div>
    </div>
  );
};

CustomProgressBar.propTypes = {
  progress: PropTypes.number.isRequired, // Progress is a required number between 0 and 100
};

export default CustomProgressBar;
