import Tabs from "#components/utils/Tabs";
import PageTitle from "#components/utils/PageTitle";
import ReceivingsList from "#components/receivings/ReceivingsList";
import ReturnsList from "#components/returns/ReturnsList";
import PutawaysList from "#components/putaways/PutawaysList";
import ExceptionsList from "#components/outbound/ExceptionsList";
import withDashboardLogic from "#components/HOC/withDashboardLogic";
import LoadingIndicator from "#components/utils/LoadingIndicator";

const Inbound = ({ writable, masterData }) => {
  const renderTabs = tabs({ masterData });
  return (
    <div className="p-5">
      <PageTitle>Inbound Overview</PageTitle>
      {renderTabs && Object.keys(renderTabs).length > 0 ? (
        <Tabs tabs={renderTabs} writable={writable} />
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

const tabs = ({ masterData }) => {
  const tabsList = {};

  const activatedModule = (name) => {
    return masterData?.hopstackModules?.find(
      (i) => i.name?.toLowerCase() === name?.toLowerCase(),
    );
  };

  if (activatedModule("receiving")) {
    tabsList["Receiving"] = <ReceivingsList />;
  }

  if (activatedModule("putaway")) {
    tabsList["Putaway"] = <PutawaysList />;
  }

  return tabsList;
};

export default withDashboardLogic(Inbound);
