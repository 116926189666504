import React from "react";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import InputBox from "#newUiComponents/commons/InputBox";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import { handleGetRequiredOptions } from "./PoUtils";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";

const TrackingDetails = ({
  customers,
  selectedCustomer,
  setSelectedCustomer,
  carrier,
  setCarrier,
  trackingNumbers,
  setTrackingNumbers,
  handleCloseTrackingDetailsModal,
  handleConfirmTrackingDetails,
}) => {
  return (
    <div className="font-inter">
      <p className="mb-2">
        Are you sure you want to proceed to ship products from the selected PO?
        Please add the tracking details if applicable.
      </p>
      <CustomAlert
        type="warning"
        message="New consignment gets created on buyer side text goes here..."
      />
      <div>
        <div>
          <h3 className="text-lg font-semibold">Tracking Details</h3>
          <p className="ml-2 text-base font-light text-gray-500">
            Tracking details context goes in here.
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-3 p-2">
          <AutoCompleteSingleSelect
            options={handleGetRequiredOptions("CUSTOMERS", customers)}
            value={selectedCustomer}
            onChange={(value) => {
              setSelectedCustomer(value);
            }}
            labelKey="label"
            valueKey="value"
            placeholder="Select an option"
            labelText="Client"
            required={true}
            infoText="Select a Client"
            showNotSelectedError={true}
            parentClasses="flex flex-col w-full"
            labelClasses="text-base text-gray-500"
          />
          <InputBox
            label="Carrier"
            infoText="Carrier name"
            placeholder="Enter carrier name"
            value={carrier}
            type="text"
            name="carrier"
            onChange={(e) => {
              setCarrier(e.target.value);
            }}
          />
          <InputBox
            label="Tracking Number(s)"
            infoText="Tracking number"
            placeholder="Enter tracking number"
            value={
              trackingNumbers && trackingNumbers.length > 0
                ? trackingNumbers.join(",")
                : ""
            }
            name="trackingNumbers"
            type="text"
            onChange={(e) => {
              setTrackingNumbers(e.target.value.split(","));
            }}
          />
        </div>
      </div>
      <div className="sticky bottom-0 z-10 mt-4 flex w-full items-center justify-end gap-4">
        <PrimaryButton
          height="3rem"
          width="6rem"
          className="mt-2 text-base font-medium"
          onClick={handleCloseTrackingDetailsModal}>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          height="3rem"
          width="6rem"
          variant="primary"
          className="mt-2 text-base font-medium"
          onClick={() => {
            handleConfirmTrackingDetails();
            handleCloseTrackingDetailsModal();
          }}>
          Confirm
        </PrimaryButton>
      </div>
    </div>
  );
};

export default TrackingDetails;
