import { InformationCircleIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";

export const ProductAliasModal = ({ aliases, customers }) => {
  return aliases?.length > 0 ? (
    <Tooltip
      title={
        <div>
          <div>
            <b>Customer IDs</b>
          </div>
          {aliases.map((alias) => (
            <div>
              {customers &&
                customers.find((item) => item.id === alias.entityId)?.name}
              : {alias.alias}
            </div>
          ))}
        </div>
      }
      overlayClassName="border border-gray-300 rounded-lg	"
      overlayInnerStyle={{
        color: "black",
        background: "#fff",
        borderRadius: "0.5rem",
      }}>
      <InformationCircleIcon
        className="inline-block text-gray-400"
        width={20}
        height={20}
      />
    </Tooltip>
  ) : (
    ""
  );
};
