import { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import { useForm } from "#hooks/useForm";
import { AppStateContext } from "#contexts/appState";
import { RESET_PASSWORD } from "#mutations";

const useResetPasswordLogc = () => {
  const { token } = useParams();
  const resetPassword = useForm(changePasswordCallback, {
    password: "",
  });

  const appState = useContext(AppStateContext);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const { push } = useHistory();

  const changePasswordQuery = useQuery(RESET_PASSWORD);

  function changePasswordCallback() {
    changePasswordQuery.fetchData({ ...resetPassword.values, token });
  }

  useEffect(() => {
    if (changePasswordQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
    if (changePasswordQuery.data) {
      appState.setAlert(
        `${changePasswordQuery.data.resetPassword.message}. Redirecting you to Login`,
      );
      setTimeout(() => push("/"), 1000);
    }
    if (changePasswordQuery.error) {
      appState.setAlert(changePasswordQuery.error.message, "error", 5000);
    }
  }, [
    changePasswordQuery.loading,
    changePasswordQuery.data,
    changePasswordQuery.error,
  ]);

  return { resetPassword, redirectToLogin };
};

export default useResetPasswordLogc;
