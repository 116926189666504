import { Menu } from "@headlessui/react";
import TableFieldButton from "#components/utils/TableFieldButton";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";

// TODO: This should be generalized to be used in other tables
export default function inventoryActionsTableKebabMenu(
  cellStyle,
  writable,
  menuItems,
  inventory,
) {
  return (
    <td className={cellStyle}>
      {writable && (
        <Menu as="div" className="inline-block text-right">
          <div>
            <Menu.Button className="w-20">
              <>
                <DotsVerticalIcon className="text-md h-8 w-8 rounded bg-E1D3B8 p-2 font-montserrat text-5F666B" />
                {inventory && inventory?.unverified && (
                  <>
                    <svg
                      data-tip
                      data-for={`unverifiedReason_${inventory.id}`}
                      className="absolute right-0 top-1"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13 9V11.6667M13 17H13.0134M3.76244 22.3333H22.2376C24.2904 22.3333 25.5735 20.1111 24.5471 18.3333L15.3094 2.33333C14.283 0.555556 11.717 0.555555 10.6906 2.33333L1.45304 18.3333C0.42664 20.1111 1.70964 22.3333 3.76244 22.3333Z"
                        stroke="#D95D03"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <ReactTooltip
                      id={`unverifiedReason_${inventory.id}`}
                      place="right"
                      type="info"
                      effect="solid">
                      <div className="max-w-xl whitespace-normal">
                        {`This product's channel has changed on your Amazon account
                          from ${inventory?.source} to ${inventory?.currentSource}. You
                          can either move all the stock to the
                          ${inventory?.currentSource} product OR leave it as is.`}
                      </div>
                    </ReactTooltip>
                  </>
                )}
              </>
            </Menu.Button>
          </div>
          <Menu.Items className="absolute z-50 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="">
              {menuItems(inventory).map((menuItem, idx) => (
                <Menu.Item key={idx}>
                  {({ active }) => (
                    <button
                      className={`${
                        menuItem.disabled ? "opacity-80" : ""
                      } relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                      onClick={() =>
                        menuItem["onClick"](inventory[menuItem["vars"]])
                      }>
                      {menuItem.icon && (
                        <menuItem.icon className="h-8 w-8 pr-2" />
                      )}
                      {menuItem.title}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Menu>
      )}
    </td>
  );
}
