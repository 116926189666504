import Modal from "#components/utils/Modal";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const OrderFilters = ({
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  warehouses,
  customers,
}) => {
  const STATUS_OPTIONS = [
    { value: "PENDING", label: "PENDING" },
    { value: "APPROVED", label: "APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
  ];
  return (
    <Modal
      title={"Filters"}
      negativeAction={negativeAction}
      positiveAction={onSubmit}
      initialFocus={null}>
      <div className="mt-4 flex justify-between space-x-6">
        <>
          <div className="flex-1">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-deepCharcoal">
                Status
              </span>
            </label>
            <MultiSelectAutoComplete
              options={STATUS_OPTIONS}
              labelKey="label"
              valueKey="value"
              setValues={(selectedOptions) => {
                onChangeFilter("status", selectedOptions);
              }}
              values={filters["status"]}
              rounded={true}
            />
          </div>
          <div className="flex-1">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-deepCharcoal">
                Warehouse
              </span>
            </label>
            <MultiSelectAutoComplete
              options={warehouses}
              labelKey="name"
              valueKey="id"
              setValues={(selectedOptions) =>
                onChangeFilter("warehouse", selectedOptions)
              }
              values={filters["warehouse"]}
              rounded={true}
            />
          </div>
          <div className="flex-1">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-deepCharcoal">
                Client
              </span>
            </label>
            <MultiSelectAutoComplete
              options={getFilteredCustomerList(filters["warehouse"], customers)}
              labelKey="name"
              valueKey="id"
              setValues={(selectedOptions) =>
                onChangeFilter("customer", selectedOptions)
              }
              values={filters["customer"]}
              rounded={true}
            />
          </div>
        </>
      </div>
    </Modal>
  );
};

export default OrderFilters;
