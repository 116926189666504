import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  ShoppingCartIcon,
  GiftIcon,
  CashIcon,
  DocumentTextIcon,
  ClipboardListIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/outline";

import TableTabs from "#components/utils/TableTabs";

import { useCubeQuery } from "@cubejs-client/react";

const query = {
  measures: ["orders.total_orders_count", "orders.completed_count"],
  timeDimensions: [
    {
      dimension: "orders.createdat",
      granularity: "day",
      dateRange: "Last 7 days",
    },
  ],
  order: {},
  filters: [
    {
      member: "orders.tenant",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
    {
      member: "orders.customer",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
    {
      member: "orders.warehouse",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
  ],
};

const data = [
  {
    name: "S",
    Completed: 4000,
    Active: 2400,
    amt: 2400,
  },
  {
    name: "M",
    Completed: 3000,
    Active: 1398,
    amt: 2210,
  },
  {
    name: "T",
    Completed: 2000,
    Active: 9800,
    amt: 2290,
  },
  {
    name: "W",
    Completed: 2780,
    Active: 3908,
    amt: 2000,
  },
  {
    name: "T",
    Completed: 1890,
    Active: 4800,
    amt: 2181,
  },
  {
    name: "F",
    Completed: 2390,
    Active: 3800,
    amt: 2500,
  },
  {
    name: "S",
    Completed: 3490,
    Active: 4300,
    amt: 2100,
  },
];
const colors = ["#224E73", "#F4C261", "#7A77FF"];
const totalOrdersChart = (resultSet) => {
  let data = resultSet.chartPivot();
  data = data.map((d) => {
    let date = new Date(d["x"]);
    d["x"] = date.toLocaleDateString();
    return d;
  });
  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" title="Days" />
        <YAxis />
        <Tooltip />
        <Legend />
        {resultSet.seriesNames().map((series, i) => (
          <Bar
            key={series.key}
            stackId="a"
            dataKey={series.key}
            name={series.title.split(" ").slice(1).join(" ")}
            fill={colors[i]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

const totalOrdersRenderer = ({ resultSet, error }) => {
  if (resultSet) {
    return totalOrdersChart(resultSet);
  } else if (error && error.toString()) {
    return <h4>{error.toString()}</h4>;
  } else {
    return <h4>Loading...</h4>;
  }
};

const TotalOrdersComponent = ({
  dateRange,
  tenantId,
  customers,
  warehouses,
}) => {
  query["timeDimensions"][0]["dateRange"] = dateRange;
  query["filters"][0].values = [tenantId];
  query["filters"][1].values = customers;
  query["filters"][2].values = warehouses;
  const renderProps = useCubeQuery(query);
  return totalOrdersRenderer(renderProps);
};

const TotalOrdersRow = ({ dateRange, tenantId, customers, warehouses }) => {
  return (
    <div className="col-span-4 rounded-xl bg-white p-2 px-4 py-4">
      <div className="text-lg">Total Incoming Orders</div>
      <div className="mt-5 w-full" style={{ height: "370px" }}>
        <TotalOrdersComponent
          dateRange={dateRange}
          key={"total_orders_" + dateRange.toString().replaceAll(" ", "_")}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
      </div>
    </div>
  );
};

const CountryWise = () => (
  <div className="bg-F8F8F8 p-2">
    <table className="min-w-full divide-y divide-gray-200 font-montserrat">
      <thead>
        <tr className="border-b border-textWhite">
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            Name
          </th>
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            Hrs
          </th>

          <th
            scope="col"
            className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            United States
          </td>{" "}
          <td className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            12 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            <div className="inline-flex items-center rounded-md border bg-F4C261 p-1 px-6 text-center text-sm font-medium text-white">
              <ArrowUpIcon className="h-4 w-4" /> 2%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            Canada
          </td>{" "}
          <td className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            12 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            <div className="inline-flex items-center rounded-md border bg-F4C261 p-1 px-6 text-center text-sm font-medium text-white">
              <ArrowUpIcon className="h-4 w-4" /> 2%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            Mexico
          </td>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            12 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            <div className="inline-flex items-center rounded-md border bg-50BFC3 p-1 px-6 text-center text-sm font-medium text-white">
              <ArrowDownIcon className="h-4 w-4" /> 2%
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default TotalOrdersRow;
