import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import SearchSortFilter from "../common/SearchSortFilter";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import Pagination from "#components/common/Pagination";
import { useEffect, useState } from "react";
import QuickFilters from "../common/QuickFilters";

const ShippersList = ({
  shippers,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  noValuesText,
  customers,
  warehouses,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeFilter,
}) => {
  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <div className="my-4">
        <QuickFilters
          warehouses={warehouses}
          warehouseFilterName={"warehouses"}
          filters={filters}
          onChangeFilter={onChangeFilter}
        />
      </div>
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={null}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Name",
            value: "name",
          },
          {
            name: "Form Factor",
            value: "formFactor",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {shippers.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {shippers &&
            shippers.map((shipper, index) => (
              <tr
                key={shipper.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {shipper.name}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {shipper.warehouses
                    ? warehouses
                        .filter((item) => shipper.warehouses.includes(item.id))
                        .map((item) => item.name)
                        .join(", ")
                    : ""}
                </td>
                <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  <div className="flex items-center space-x-4">
                    <TableFieldButton
                      onClick={() => editButtonClicked(shipper)}
                      text={<PencilIcon className="h-6 w-6" />}
                      disabled={!writable}
                    />

                    <TableFieldButton
                      text={<TrashIcon className="h-6 w-6" />}
                      onClick={() => deleteButtonClicked(shipper.id)}
                      disabled={!writable}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CustomTableWithTheme>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default ShippersList;
