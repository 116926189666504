import { useState } from "react";
import Modal from "#components/utils/Modal";

const DEFAULT_EXPAND_THRESHOLD = 2;

const ExpandedMultipleItem = ({
  items,
  emptyText = null,
  title = "Click to View",
  expandThreshold = DEFAULT_EXPAND_THRESHOLD,
}) => {
  const [listOfElements, setListOfElements] = useState(false);

  if (!items || items.length === 0) {
    return emptyText;
  }

  if (items.length < expandThreshold) {
    return items.join(", ");
  }

  return (
    <>
      <div>
        <button
          onClick={() => {
            setListOfElements(items);
          }}
          className="text-md text-2C7695 underline">
          {title}
        </button>
      </div>
      {listOfElements && (
        <Modal
          negativeAction={() => setListOfElements(null)}
          title={`Details`}
          noPadding={true}
          positiveAction={() => setListOfElements(null)}
          positiveText="OK">
          <div className="relative mx-auto max-w-xl overflow-auto bg-white p-4">
            <div className="myb-2 text-lg font-semibold">
              Total Count : {listOfElements.length}
            </div>
            <div className="my-2 w-full border-2 border-gray-700"></div>
            <div className="grid max-h-96 w-full grid-cols-2 overflow-scroll">
              {listOfElements.map((element, index) => (
                <div id={element}>
                  {index + 1}. {element}
                </div>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ExpandedMultipleItem;
