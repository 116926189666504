import { Tab } from "@headlessui/react";
import { UserAddIcon } from "@heroicons/react/outline";
// import PageButton from "#components/shared/PageButton";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ tabs, extra, selected, onChange }) => {
  console.log(tabs);
  return (
    <div className="flex items-center justify-center py-2">
      <div className="inline-flex items-center justify-center rounded-full bg-primaryAccent">
        <div className="flex items-center justify-center">
          <div className="flex rounded-xl p-1 px-1">
            {tabs &&
              tabs.map((tab, index) => (
                <div
                  key={index}
                  className={classNames(
                    "font-regular rounded-full px-6 py-1 text-sm leading-5",
                    "font-montserrat font-bold focus:outline-none focus:ring-0",
                    selected === tab
                      ? "bg-white text-primaryAccent"
                      : "rounded-full border border-transparent bg-transparent text-white",
                  )}
                  onClick={() => onChange(tab)}>
                  {tab}
                </div>
              ))}
          </div>
          <div className="items-center justify-end">{extra}</div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
