import Modal from "#components/utils/Modal";
import { getHeaderObject } from "#utils/getHeaderObject";

const ExpandedBundleDetails = ({
  bundleLineItemDetails,
  setBundleLineItemDetails,
}) => {
  const headers = [
    getHeaderObject("SKU"),
    getHeaderObject("Bundle Qty"),
    getHeaderObject("Order Qty"),
    getHeaderObject("Quantity In Stock"),
  ];

  return (
    <Modal
      negativeAction={() => setBundleLineItemDetails(null)}
      positiveAction={() => setBundleLineItemDetails(null)}
      title={`Bundle Details (${bundleLineItemDetails.sku})`}
      noPadding={true}
      positiveText="OK">
      <div className="bg-EFE9DC p-4">
        <table className="min-w-full divide-y divide-gray-200 px-2">
          <thead className="rounded-full bg-primaryAccent p-4 px-12">
            <tr className="border-left px-12 font-montserrat text-textWhite">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {bundleLineItemDetails.products?.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent`}>
                  {item.sku}
                </td>

                <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.quantityInBundle}
                </td>

                <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.quantityToFulfill}
                </td>

                <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.availableToShip}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ExpandedBundleDetails;
