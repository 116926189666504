import withBoxTypesLogic from "#components/HOC/withBoxTypesLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import BoxTypesList from "#components/boxTypes/BoxTypesList";
import BoxTypeForm from "#components/boxTypes/BoxTypeForm";
import { UserAddIcon } from "@heroicons/react/outline";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import _ from "lodash";
import { useDimensionUnitAbbreviation } from "../utils/Metrics";

const BoxTypes = ({
  masterData,
  boxTypes,
  selectedBoxType,
  setSelectedBoxType,
  fetchBoxType,
  saveBoxType,
  onChangeDropdown,
  deleteButtonClicked,
  onChange,
  devices,
  onChangeMultiSelect,
  selectedBoxTypes,
  selectBoxType,
  selectAllBoxTypes,
  allBoxTypesSelected,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  addSubBoxType,
  onChangeSubBoxType,
  removeSubBoxType,
  formFactors,
  addContents,
  removeContents,
  onChangeContents,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  showFilters,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
}) => {
  const preferredDimensionUnit = useDimensionUnitAbbreviation();
  const headers = [
    "Name",
    "Form Factor",
    `Dimensions(${preferredDimensionUnit})`,
    "Warehouses",
    "Clients",
    "Action",
  ];
  return (
    <>
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-1">
            <PageTitle>Box Types Management</PageTitle>
          </div>
          <div className="mb-4 flex items-center justify-end space-x-2">
            <AddButton
              text="Add Box Type"
              onClick={() => setSelectedBoxType({})}
              icon={UserAddIcon}
              disabled={!writable}
            />
          </div>
        </div>

        <BoxTypesList
          boxTypes={boxTypes}
          editButtonClicked={(e) => {
            fetchBoxType(e.id);
          }}
          deleteButtonClicked={(e) => deleteButtonClicked(e)}
          masterData={masterData}
          headers={headers}
          noValuesText="No Box Types"
          selectedBoxTypes={selectedBoxTypes}
          selectBoxType={selectBoxType}
          selectAllBoxTypes={selectAllBoxTypes}
          allBoxTypesSelected={allBoxTypesSelected}
          customers={customers}
          warehouses={warehouses}
          writable={writable}
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          clearFilters={clearFilters}
          onChangeFilter={onChangeFilter}
          total={total}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
          perPage={perPage}
          setPerPage={setPerPage}
        />
        {selectedBoxType && (
          <BoxTypeForm
            title={selectedBoxType.id ? "Edit Box Type" : "Add Box Type"}
            selectedBoxType={selectedBoxType}
            onChange={onChange}
            onClose={() => setSelectedBoxType(null)}
            onSubmit={() => saveBoxType(selectedBoxType)}
            hopstackModules={masterData?.hopstackModules}
            onChangeDropdown={onChangeDropdown}
            devices={devices}
            onChangeMultiSelect={onChangeMultiSelect}
            customers={customers}
            warehouses={warehouses}
            onChangeSubBoxType={onChangeSubBoxType}
            addSubBoxType={addSubBoxType}
            removeSubBoxType={removeSubBoxType}
            formFactors={formFactors}
            addContents={addContents}
            removeContents={removeContents}
            onChangeContents={onChangeContents}
            boxTypes={boxTypes}
          />
        )}
      </div>
    </>
  );
};
export default withBoxTypesLogic(BoxTypes);
