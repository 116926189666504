import withSoftwareIntegrationsLogic from "#components/HOC/withSoftwareIntegrationsLogic";
import Modal from "#components/utils/Modal";
import moment from "moment-timezone";
import IntegrationForm from "#components/integrations/IntegrationForm";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import AddButton from "#components/utils/AddButton";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import IntegrationProviders from "./IntegrationProviders";
import ManageIntegrationProvider from "./ManageIntegrationProvider";
import QuickFilters from "#components/common/QuickFilters";
const SoftwareIntegrations = ({
  integrationProviders,
  getProducts,
  getOrders,
  setOrders,
  setProducts,
  orders,
  products,
  connectIntegration,
  integrations,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  selectedIntegration,
  setSelectedIntegration,
  selectedIntegrationToManage,
  setSelectedIntegrationToManage,
  selectedIntegrationToConfigure,
  setSelectedIntegrationToConfigure,
  multiAccountSupportEnabled,
  configureIntegrationSubmit,
  onChange,
  onChangeDropdown,
  setIntegrationDropdownValue,
  saveIntegration,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  logoutIntegration,
  testIntegration,
  testResults,
  setTestResults,
  writable,
  shopifyLocations,
  shipstationCarriers,
  filters,
  onChangeFilter,
}) => (
  <>
    <div className="mb-5 flex space-x-4">
      {(customers.length > 1 || warehouses.length > 1) && (
        <QuickFilters
          warehouseFilterName={"warehouse"}
          customerFilterName={"customer"}
          customers={customers}
          warehouses={warehouses}
          filters={filters}
          onChangeFilter={onChangeFilter}
          isIntegrationPage={true}
          selectedCustomer={selectedCustomer}
          selectedWarehouse={selectedWarehouse}
        />
      )}
    </div>
    {selectedIntegrationToManage ? (
      <ManageIntegrationProvider
        integrations={integrations.filter(
          (item) =>
            item.integrationType === selectedIntegrationToManage &&
            item.customer === selectedCustomer,
        )}
        integrationProvider={integrationProviders.find(
          (item) => item.name === selectedIntegrationToManage,
        )}
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={setSelectedIntegrationToConfigure}
        writable={writable}
        connectIntegration={connectIntegration}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        getProducts={getProducts}
        configureIntegrationSubmit={configureIntegrationSubmit}
      />
    ) : (
      <IntegrationProviders
        integrations={integrations}
        integrationProviders={integrationProviders}
        connectIntegration={connectIntegration}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        getProducts={getProducts}
        selectedCustomer={selectedCustomer}
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={setSelectedIntegrationToConfigure}
        writable={writable}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        configureIntegrationSubmit={configureIntegrationSubmit}
      />
    )}

    {selectedIntegration && (
      <IntegrationForm
        title={selectedIntegration.id ? "Edit Integration" : "Add Integration"}
        isGlobal={false}
        selectedIntegration={selectedIntegration}
        integrationProviders={integrationProviders}
        setSelectedIntegration={setSelectedIntegration}
        onChange={onChange}
        onClose={() => setSelectedIntegration()}
        onSubmit={saveIntegration}
        customers={customers}
        onChangeDropdown={onChangeDropdown}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        shopifyLocations={shopifyLocations}
        shipstationCarriers={shipstationCarriers}
      />
    )}
    {testResults && (
      <Modal
        title={`Connection Result`}
        negativeAction={() => setTestResults(null)}>
        <div className="space-y-4">
          <div>
            <span className="pb-4 font-montserrat text-lg font-bold text-454A4F">
              Tested retrieval of 1 product. Success.
            </span>
          </div>
          {Object.keys(testResults).map((item, idx) => (
            <div key={idx}>
              <span className="pb-4 font-montserrat text-lg font-bold text-454A4F">
                {item} : {testResults[item]}
              </span>
            </div>
          ))}
        </div>
      </Modal>
    )}
  </>
);

export default withSoftwareIntegrationsLogic(SoftwareIntegrations);
