import { useState, useEffect, useRef } from "react";

const TextField = ({
  type,
  id,
  placeholder,
  label,
  onChange,
  value,
  rounded,
  className,
  autoFocus = false,
  error,
  disabled,
  validate,
  showAddressSuggestions,
  ...rest
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [focused, setFocused] = useState(true);

  if (type === "number") {
    rest.onWheel = (e) => e.currentTarget.blur();
  }

  const onFocus = () => setFocused(false);
  const onBlur = () => setFocused(true);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };
  useEffect(() => {
    if (window.google?.maps !== undefined && showAddressSuggestions) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options,
      );
    }
  }, []);

  useEffect(() => {
    if (showAddressSuggestions) {
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        onChange({
          target: {
            name: id,
            value: place.address_components.map((c) => c.long_name).join(", "),
          },
        });
      });
    }
  }, [value]); // update onChange closure of setSelectedProfile. Refer Stale Closures https://dmitripavlutin.com/react-hooks-stale-closures/

  if (rounded) {
    return (
      <input
        className="block w-full appearance-none rounded-full border-2 border-2C7695 p-2 font-montserrat text-lg focus:outline-none focus:ring-0"
        id={id}
        onChange={onChange}
        disabled={disabled}
        required={false}
        value={value}
        type={type}
        name={id}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...rest}
        ref={inputRef}
      />
    );
  }
  return (
    <div
      className={`outline-float relative border-2 ${
        errorMessage && errorMessage?.length > 0
          ? "border-red-500"
          : "border-2C7695"
      } w-full overflow-visible rounded-lg ${
        disabled && "cursor-not-allowed bg-gray-300"
      }`}>
      <input
        type={type}
        name={id}
        placeholder={!focused ? placeholder : " "}
        onFocus={onFocus}
        onBlur={onBlur}
        className="block w-full appearance-none border-0 bg-transparent p-4 font-montserrat text-lg placeholder-gray-400 focus:border-0 focus:outline-none focus:ring-0"
        id={id}
        onChange={(e) => {
          onChange(e);
          if (typeof error === "function") {
            setErrorMessage(
              e.target.value.length > 0 ? error(e.target.value) : null,
            );
            return;
          }
          if (typeof validate === "function") {
            const err = validate(e.target.value);
            setErrorMessage(err);
          }
        }}
        disabled={disabled}
        required={false}
        value={value}
        autoFocus={autoFocus}
        {...rest}
        ref={inputRef}
      />
      <label
        htmlFor={id}
        className={`-z-1 origin-0 absolute top-0 m-2 ml-2 ${!disabled && "bg-white"} p-2 font-montserrat text-lg font-medium text-2C7695 duration-300 ${className}`}>
        {label}
      </label>
      {errorMessage && (
        <label className="absolute -bottom-14 -left-10 !ml-0 text-lg text-red-500">
          {errorMessage}
        </label>
      )}
    </div>
  );
};

export default TextField;
