import BinLocations from "./binLocations";

const LocationManagement = (props) => {
  return (
    <div className="p-5">
      <BinLocations writable={props.writable} />
    </div>
  );
};

export default LocationManagement;
