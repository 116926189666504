export const AddUserFooter = ({
  newCustomer,
  steps,
  selectedStep,
  setPrevStep,
  setNextStep,
  showSubmit,
  onSubmit,
  onCancel,
  isShippingSelected,
}) => {
  return (
    <div className="mt-4 flex w-full items-center justify-between space-x-4 bg-white">
      {isShippingSelected}
      <button
        className="p-2 text-base font-semibold underline"
        onClick={onCancel}>
        Cancel
      </button>

      <div className="flex gap-4">
        {selectedStep.id !== steps[0].id && (
          <button
            onClick={setPrevStep}
            className={`rounded-md px-4 py-2 text-base font-semibold ${
              selectedStep?.id === steps[0]?.id
                ? "cursor-not-allowed border border-mediumGray text-mediumGray"
                : "cursor-pointer border border-primaryAccent text-primaryAccent"
            }`}>
            Previous
          </button>
        )}

        {selectedStep.id !== steps[steps.length - 1].id && (
          <button
            onClick={setNextStep}
            disabled={
              selectedStep?.id === steps[steps.length - 1]?.id ||
              !(
                newCustomer?.firstName &&
                newCustomer?.lastName &&
                newCustomer?.accountAddress?.line1 &&
                newCustomer?.accountAddress?.state &&
                newCustomer?.accountAddress?.country &&
                newCustomer?.accountAddress?.zip
              )
            }
            className={`rounded-md px-4 py-2 text-base font-semibold text-white ${
              selectedStep?.id === steps[steps.length - 1]?.id ||
              !(
                newCustomer?.firstName &&
                newCustomer?.lastName &&
                newCustomer?.accountAddress?.line1 &&
                newCustomer?.accountAddress?.state &&
                newCustomer?.accountAddress?.country &&
                newCustomer?.accountAddress?.zip
              )
                ? "cursor-not-allowed border bg-gray-400"
                : "cursor-pointer bg-primaryAccent"
            }`}>
            Next
          </button>
        )}

        {showSubmit && selectedStep.id !== steps[0].id && (
          <button
            onClick={onSubmit}
            disabled={
              !isShippingSelected || !newCustomer.shippingAddresses.length
            }
            className={`rounded-md px-4 py-2 text-base font-semibold ${
              isShippingSelected
                ? "cursor-pointer bg-primaryAccent text-white"
                : "cursor-not-allowed border border-mediumGray text-mediumGray"
            }`}>
            Add User
          </button>
        )}
      </div>
    </div>
  );
};
