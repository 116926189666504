const HeaderWithoutSidebar = ({ tenant }) => (
  <div className="absolute left-0 top-0 flex w-full justify-between">
    {/*Logo*/}
    <div className="flex items-center justify-center space-x-4 px-2 py-2">
      {tenant && tenant.logo ? (
        <img src={tenant.logo} alt="Custom Logo" className="h-20" />
      ) : (
        <div className="flex items-center justify-center space-x-4 px-2 py-2">
          <img
            src="https://hopstack-pub.s3.amazonaws.com/logo.png"
            alt="Hopstack Inc"
            className="h-12 w-12"
          />
          <span className="font-hammersmith text-2xl text-white">
            Hopstack Inc
          </span>
        </div>
      )}
    </div>
    {/*. User Details */}
  </div>
);

export default HeaderWithoutSidebar;
