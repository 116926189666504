import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_SHIPPERS, GET_SHIPPER } from "#queries";
import { SAVE_SHIPPER, DELETE_SHIPPER } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { printCanvasLabel } from "#utils/printCanvasLabel";
import readXlsxFile from "read-excel-file";

const ALERT_TIMEOUT_IN_MS = 5000;

const withShippersLogic = (WrappedComponent) => {
  return (props) => {
    const [filters, setFilters] = useState({});
    const appState = useContext(AppStateContext);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const [selectedShipper, setSelectedShipper] = useState(null);
    const shippersQuery = useQuery(GET_SHIPPERS);
    const getShipperQuery = useQuery(GET_SHIPPER);
    const saveShipperQuery = useQuery(SAVE_SHIPPER);
    const deleteShipperQuery = useQuery(DELETE_SHIPPER);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
      loadFirstTimeData();

      return () => {
        entity.resetEntities();
      };
    }, []);

    const loadFirstTimeData = () => {
      if (auth && auth.user && auth.user.warehousesList) {
        const warehouses = auth.user.warehousesList;
        if (warehouses.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000,
          );
        }

        const filtersSet = {
          warehouses: auth.user.warehousesList.map((item) => item.id),
        };

        entity.setFilters(filtersSet);
        shippersQuery.fetchData({
          perPage: entity.perPage,
          filters: filtersSet,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }
    };

    useEffect(() => {
      if (shippersQuery.data) {
        console.log(shippersQuery.data.shippers);
        entity.setEntities({
          ...shippersQuery.data.shippers,
          ...shippersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [shippersQuery.loading, shippersQuery.error, shippersQuery.data]);

    useEffect(() => {
      if (getShipperQuery.data) {
        setSelectedShipper(getShipperQuery.data.shipper);
      }

      if (getShipperQuery.error) {
        setSelectedShipper(null);
      }
    }, [getShipperQuery.loading, getShipperQuery.data, getShipperQuery.error]);

    useEffect(() => {
      if (saveShipperQuery.error) {
        appState.setAlert(
          saveShipperQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (saveShipperQuery.data && selectedShipper) {
        appState.setAlert(
          saveShipperQuery.data.saveShipper.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        setSelectedShipper(null);
        shippersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }
    }, [
      saveShipperQuery.loading,
      saveShipperQuery.error,
      saveShipperQuery.data,
    ]);

    useEffect(() => {
      if (deleteShipperQuery.data) {
        appState.setAlert(
          deleteShipperQuery.data.deleteShipper.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.hideConfirmation();
        shippersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (deleteShipperQuery.error) {
        appState.setAlert(
          deleteShipperQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.hideConfirmation();
      }
    }, [
      deleteShipperQuery.loading,
      deleteShipperQuery.data,
      deleteShipperQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this shipper?",
        () => {
          deleteShipperQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    const onChange = (e) => {
      const shipper = {
        ...selectedShipper,
      };

      shipper[e.target.name] =
        e.target.type === "number"
          ? parseFloat(e.target.value)
          : e.target.value;
      setSelectedShipper(shipper);
    };

    const onChangeMultiSelect = (field, value) => {
      const shipper = {
        ...selectedShipper,
      };
      shipper[field] = value;
      setSelectedShipper(shipper);
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return shippersQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        shippers={entity.displayEntities}
        fetchShipper={(id) => getShipperQuery.fetchData({ id })}
        selectedShipper={selectedShipper}
        setSelectedShipper={setSelectedShipper}
        saveShipper={() => {
          saveShipperQuery.fetchData({ ...selectedShipper });
        }}
        onChange={onChange}
        onChangeMultiSelect={onChangeMultiSelect}
        deleteButtonClicked={deleteButtonClicked}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        setSelectedWarehouse={(e) => {
          setFilters({
            ...filters,
            warehouses: [e],
          });
          shippersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...filters,
              warehouses: [e],
            },
            sort: entity.sort,
          });
        }}
        writable={props.writable}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          shippersQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          shippersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          shippersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            shippersQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,

                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,

            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          shippersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
      />
    );
  };
};

export default withShippersLogic;
