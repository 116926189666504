import _ from "lodash";
/**
 * getIDForName - Converts any string to a format that can be used as an ID, replacing spaces with underscores.
 * @param {string} name - Any string input.
 * @returns {string} - ID-like string with spaces replaced by underscores.
 */
export function getIDForName(name) {
  let lcName = name.toLowerCase();
  let parts = lcName.split(" ");
  let id = "";
  parts.forEach((part) => (id += part + "_"));
  return id;
}

/**
 * getHeaderObject - Constructs an object representing a table header.
 * @param {string} name - Header's display name.
 * @param {string} [correspondingValue=""] - Value associated with the header.
 * @param {boolean} [sortable=true] - Whether the table can be sorted by this header.
 * @param {*} [sortBy=null] - (optional) Value to sort by, if null uses correspondingValue.
 * @param {boolean} [enabled=true] - (optional) state for showing it on table or not, default is true
 * @returns {Object} - (optional) Detailed header object.
 */
export function getHeaderObject(
  name,
  correspondingValue = "",
  sortable = true,
  sortBy = null,
  enabled = true,
) {
  return {
    name: name,
    id: getIDForName(name),
    correspondingValue: correspondingValue,
    timeValue: false,
    arrayValue: false,
    enabled,
    editable: true,
    sortable,
    sortBy,
  };
}
/**
 * Retrieves an array of sortable columns from the provided headers array which are enabled.
 * @param {Array<Object>} headers - The array of header objects.
 * @param {string} headers[].name - The name of the header.
 * @param {boolean} headers[].sortable - Indicates if the header is sortable.
 * @param {boolean} headers[].enabled - Indicates if the header is enabled.
 * @param {*} headers[].correspondingValue - The corresponding value of the header.
 * @param {*} headers[].sortBy - The value to sort by (optional).
 * @returns {Array<Object>} - An array of sortable column objects that are enabled.
 */
export function getSortableColumns(headers) {
  return headers
    .filter((header) => header.sortable === true && header.enabled === true)
    .map((header) => {
      return {
        name: header.name,
        value: header.sortBy || header.correspondingValue,
      };
    });
}

/**
 * getUpdatedHeaders - Adjusts headers based on user's table preferences.
 * @param {Object} auth - Auth object containing user information.
 * @param {Array<Object>} headers - Headers to be updated.
 * @param {string} tableName - Table name for retrieving preferences.
 * @returns {Array<Object>} - Headers updated with user preferences.
 */
export function getUpdatedHeaders(auth, headers, tableName) {
  const customHeaders = getTablePreferences(auth, tableName);
  if (customHeaders) {
    const headerMap = _.keyBy(headers, "id");
    const customHeadersMap = _.keyBy(customHeaders, "id");
    // rearrange headers based on user's custom headers, and update enabled status
    const updatedHeaders = _.unionBy(
      _.intersectionBy(customHeaders, headers, "id"),
      headers,
      "id",
    ).map((rearrangedHeader) => {
      if (customHeadersMap[rearrangedHeader.id]) {
        return {
          ...headerMap[rearrangedHeader.id],
          enabled: customHeadersMap[rearrangedHeader.id].enabled,
        };
      } else {
        return { ...headerMap[rearrangedHeader.id] };
      }
    });
    return updatedHeaders;
  }
  // Returning Updated Header for handing custom headers
  return headers;
}

/**
 * getTablePreferences - To get the user's preferred columns for the given table.
 * @private
 * @param {Object} auth - Auth object containing user information.
 * @param {string} tableName - Table name for retrieving preferences.
 * @returns {Array<Object>} - User's preferred columns for the given table.
 */
function getTablePreferences(auth, tableName) {
  return (
    auth.user &&
    auth.user.tablePreferences &&
    auth.user.tablePreferences.find((table) => table.name == tableName)?.columns
  );
}
