import React, { useState } from "react";
import { Form, Select, Checkbox, Input, Tooltip } from "antd";
const { Option } = Select;
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import TextArea from "#newUiComponents/commons/TextArea";
import DatePicker from "#newUiComponents/commons/DatePicker";
import CheckBox from "#newUiComponents/commons/CheckBox";
import VendorClassification from "./VendorClassification";
import VendorAddress from "./VendorAddress";
import {
  handleGetBasicFormFieldDisabledStatus,
  PURCHASE_ORDER_STATUSES,
  USER_ROLE_CLASSIFICATIONS,
} from "./PoUtils";

const BasicInfoForm = ({
  data,
  basicInfoFormDetails,
  setBasicInfoFormDetails,
  handleOpenAddressModal,
  userInfo,
  poErrors,
}) => {
  return (
    <div className="max-w-5xl">
      <div className="flex w-full gap-4">
        <div className="w-3/5">
          <AutoCompleteSingleSelect
            options={data.vendorsOptions}
            value={basicInfoFormDetails.selectedVendor}
            onChange={(value) =>
              setBasicInfoFormDetails({
                ...basicInfoFormDetails,
                selectedVendor: value,
              })
            }
            labelKey="label"
            valueKey="value"
            placeholder="Select an option"
            labelText="Vendor"
            required={true}
            disabled={handleGetBasicFormFieldDisabledStatus(
              data.poStatus,
              userInfo,
            )}
            infoText="Select a Vendor"
            error={poErrors?.vendorError || ""}
            showNotSelectedError={true}
            parentClasses="flex flex-col w-full mb-4"
            labelClasses="text-base text-gray-500"
          />
          {data.selectedVendorAddress &&
            Object.keys(data.selectedVendorAddress).length > 0 && (
              <div className="mb-4 w-full">
                <VendorAddress
                  disabled={handleGetBasicFormFieldDisabledStatus(
                    data.poStatus,
                    userInfo,
                  )}
                  handleOpenAddressModal={handleOpenAddressModal}
                  vendorName={data.vendorDetails.name}
                  vendorAddress={data.selectedVendorAddress}
                />
              </div>
            )}
        </div>
        {data.vendorDetails?.classification && (
          <div className="pt-8">
            <VendorClassification
              classification={data.vendorDetails.classification}
            />
          </div>
        )}
      </div>
      <div className="w-3/5">
        <AutoCompleteSingleSelect
          options={data.warehousesOptions}
          value={basicInfoFormDetails.selectedWareshouse}
          onChange={(value) =>
            setBasicInfoFormDetails({
              ...basicInfoFormDetails,
              selectedWareshouse: value,
            })
          }
          required={false}
          disabled={handleGetBasicFormFieldDisabledStatus(
            data.poStatus,
            userInfo,
          )}
          labelKey="label"
          valueKey="value"
          placeholder="Select an option"
          labelText="Warehouse"
          error={
            basicInfoFormDetails.selectedWareshouse
              ? ""
              : poErrors?.warehouseError || ""
          }
          infoText="Select a warehouse"
          showNotSelectedError={true}
          parentClasses="flex flex-col w-full mb-4"
          labelClasses="text-base text-gray-500"
        />
        <AutoCompleteSingleSelect
          options={data.currenciesOptions}
          value={basicInfoFormDetails.selectedCurrency}
          onChange={(value) =>
            setBasicInfoFormDetails({
              ...basicInfoFormDetails,
              selectedCurrency: value,
            })
          }
          labelKey="label"
          valueKey="value"
          placeholder="Select an option"
          labelText="Currency"
          required={true}
          shouldDeselect={false}
          disabled={handleGetBasicFormFieldDisabledStatus(
            data.poStatus,
            userInfo,
          )}
          error=""
          infoText="Select a currency for the PO. Product costs will display based on the selected currency."
          showNotSelectedError={true}
          parentClasses="flex flex-col w-full mb-4"
          labelClasses="text-base text-gray-500"
        />
        <DatePicker
          label="Expected Ship Date (YYYY-MM-DD)"
          infoText="Select date supplier anticipates shipping the products"
          value={basicInfoFormDetails.expectedShipDate}
          onChange={(date) =>
            setBasicInfoFormDetails({
              ...basicInfoFormDetails,
              expectedShipDate: date,
            })
          }
          error=""
          parentClasses="mb-4"
          labelClasses="text-base text-gray-500"
        />
        <DatePicker
          label="Expected Delivery Date (YYYY-MM-DD)"
          infoText="Date when the goods are expected at the warehouse or delivery location"
          value={basicInfoFormDetails.expectedDeliveryDate}
          onChange={(date) =>
            setBasicInfoFormDetails({
              ...basicInfoFormDetails,
              expectedDeliveryDate: date,
            })
          }
          error=""
          parentClasses="mb-4"
          labelClasses="text-base text-gray-500"
        />
        <AutoCompleteSingleSelect
          options={data.priorityTagsOptions}
          value={basicInfoFormDetails.selectedPriorityTag}
          onChange={(value) => {
            setBasicInfoFormDetails({
              ...basicInfoFormDetails,
              selectedPriorityTag: value,
            });
          }}
          labelKey="labelKey"
          secondaryLabelKey="icon"
          valueKey="value"
          placeholder="Select an option"
          labelText="Priority Tag"
          infoText="Indicate the priority level for this purchase order."
          error=""
          disabled={handleGetBasicFormFieldDisabledStatus(
            data.poStatus,
            userInfo,
          )}
          showNotSelectedError={true}
          parentClasses="flex flex-col w-full mb-4"
          labelClasses="text-base text-gray-500"
          isSort={false}
        />
        <TextArea
          label="Note"
          placeholder="Enter note here ..."
          value={basicInfoFormDetails.note}
          disabled={handleGetBasicFormFieldDisabledStatus(
            data.poStatus,
            userInfo,
          )}
          onChange={(e) =>
            setBasicInfoFormDetails({
              ...basicInfoFormDetails,
              note: e.target.value,
            })
          }
          error=""
          infoText="Enter any remarks or notes related to this purchase order."
          parentClasses="mb-4"
          labelClasses="text-base text-gray-500"
          rows={4}
        />
      </div>
    </div>
  );
};

export default BasicInfoForm;
