import _ from "lodash";
import SelectCustomerCode from "#components/picker/common/SelectCustomerCode";
import ScanOrEnterManually from "#components/putaway/consignment/ScanOrEnterManually";
import DetailedView from "#components/putaway/consignment/DetailedView";
import withConsignmentPutawayLogic from "#components/HOC/withConsignmentPutawayLogic";
import ConsignmentSelection from "#components/putaway/consignment/ConsignmentSelection";
import ConsignmentItemsSelection from "#components/putaway/consignment/ConsignmentItemsSelection";

const ConsignmentPutaway = ({
  customers,
  submitCustomerCode,
  scanBarcode,
  currentScan,
  currentSku,
  loading,
  currentProduct,
  confirmItem,
  cancelItem,
  requireConsignmentForPutaway,
  consignmentsData,
  checkConsignmentById,
  specificConsignments,
  consignmentStatusList,
  consignmentFilters,
  setConsignmentFilters,
  submitConsignmentFilters,
  checkConsignmentsPagination,
  cancelCurrentCustomerAndTracking,
  nestedEntityItems,
  setNestedEntityItems,
  getNestedEntityItems,
  codeToPrint,
  skipConsignmentSelection,
  setCurrentSku,
  completedToteScan,
  setCompletedToteScan,
  currentParentProduct,
}) => {
  // STEP 1: select client
  if (!currentSku && (!currentScan || !currentScan.customer)) {
    return (
      <SelectCustomerCode customers={customers} onSubmit={submitCustomerCode} />
    );
  }

  if (completedToteScan) {
    return (
      <>
        <div className="left-0 w-full bg-black text-center text-2xl text-white">
          {customers.find((item) => item.id === currentScan.customer)?.name}
        </div>
        <div className="mt-10">
          <div className="mx-auto flex max-w-7xl flex-col items-center justify-center rounded-md border border-gray-300 bg-white p-6 text-center text-2xl">
            <div className="py-4 text-4xl font-bold">
              Tote Putaway Complete 100%
            </div>
            This tote's items have been completely putaway. It is now free to
            use for other activities.
            <div
              className="mt-4 w-64 rounded-md bg-primaryAccent py-4 text-center text-2xl text-white"
              onClick={() => setCompletedToteScan(false)}>
              OK
            </div>
          </div>
        </div>
      </>
    );
  }

  // STEP 2: select a specific consignment / provide consignment tracking number or Skip to let the backend assign the oldest consignment.
  // Note: If consignment putaway is not enabled, step 3 will be shown.
  if (
    !currentSku &&
    requireConsignmentForPutaway &&
    currentScan.trackingNumber === undefined
  ) {
    return (
      <ConsignmentSelection
        customers={customers}
        scanBarcode={scanBarcode}
        currentScan={currentScan}
        consignmentsData={consignmentsData}
        checkConsignmentById={checkConsignmentById}
        consignmentStatusList={consignmentStatusList}
        consignmentFilters={consignmentFilters}
        setConsignmentFilters={setConsignmentFilters}
        submitConsignmentFilters={submitConsignmentFilters}
        checkConsignmentsPagination={checkConsignmentsPagination}
        cancelCurrentCustomerAndTracking={cancelCurrentCustomerAndTracking}
        skipConsignmentSelection={skipConsignmentSelection}
      />
    );
  }

  // STEP 3: select item within the selected consignment(s) / provide item/tote code to create putaway batch.
  // Note: If consignment putaway is not enabled, user is asked to provide the tote/item code only
  if (!currentSku && !loading) {
    return (
      <>
        {!requireConsignmentForPutaway && (
          <div className="fixed bottom-0 right-0 mt-16 bg-green-600 p-4 text-center text-xl text-white">
            Listening for Print Commands
          </div>
        )}

        <ConsignmentItemsSelection
          customers={customers}
          currentScan={currentScan}
          scanBarcode={scanBarcode}
          specificConsignments={specificConsignments}
          cancelCurrentCustomerAndTracking={cancelCurrentCustomerAndTracking}
        />
        {/* <div className="pt-48">
            <ScanOrEnterManually
              text="Scan an Item/Tote/Pallet Code"
              onSubmit={scanBarcode}
            />
          </div> */}
      </>
    );
  }

  // STEP 4: Work with the created putaway batch.
  if (currentSku) {
    return (
      <>
        <div className="fixed bottom-0 right-0 mt-16 bg-green-600 p-4 text-center text-xl text-white">
          Listening for Print Commands
        </div>
        <div className="left-0 w-full bg-black text-center text-2xl text-white">
          {customers.find((item) => item.id === currentScan.customer)?.name}
        </div>
        <div className="mt-10">
          <DetailedView
            currentSku={currentSku}
            setCurrentSku={setCurrentSku}
            confirmItem={confirmItem}
            cancelItem={cancelItem}
            scanBarcode={scanBarcode}
            currentProduct={currentProduct}
            currentParentProduct={currentParentProduct}
            requireConsignmentForPutaway={requireConsignmentForPutaway}
            nestedEntityItems={nestedEntityItems}
            setNestedEntityItems={setNestedEntityItems}
            getNestedEntityItems={getNestedEntityItems}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="fixed bottom-0 right-0 bg-green-600 p-4 text-center text-xl text-white">
        Listening for Print Commands
      </div>
      <div className="left-0 w-full bg-black text-center text-2xl text-white">
        {customers.find((item) => item.id === currentScan.customer)?.name}
      </div>
      {codeToPrint && (
        <div className="z-50" style={{ position: "fixed" }}>
          {codeToPrint.map((code, idx) => (
            <img
              id={`label-placeholder-${code}`}
              style={{ position: "fixed", top: 10000 }}
              key={idx}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default withConsignmentPutawayLogic(ConsignmentPutaway);
