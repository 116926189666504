import Modal from "#components/utils/Modal";
import ModalButton from "#components/utils/ModalButton";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import FourBySixLabel from "#components/common/FourBySixLabel";
import FourByThreeLabel from "#components/common/FourByThreeLabel";
import FourByTwoLabel from "#components/common/FourByTwoLabel";
import FourBySixLandscapeLabel from "#components/common/FourBySixLandscapeLabel";
import ThreeByOneLabel from "#components/common/ThreeByOneLabel";
import ThreeByTwoLabel from "#components/common/ThreeByTwoLabel";
import TwoByOneLabel from "#components/common/TwoByOneLabel";
import TwoByOneLabelAdjusted from "#components/common/TwoByOneLabelAdjusted";
import TwoQuarterByOneQuarterLabel from "#components/common/TwoQuarterByOneQuarterLabel";

const PrintSettingsModal = ({
  setSelectedPrints,
  selectedPrints,
  printSelectedBarcodes,
}) => {
  return (
    <Modal negativeAction={() => setSelectedPrints(null)} title={`Print`}>
      <div className="space-y-4">
        <Dropdown
          placeholder={"Select Barcode Type"}
          list={[{ name: "1D Barcode" }]}
          labelKey="name"
          valueKey="name"
          name="barcodeType"
          setSelected={(e) => {
            setSelectedPrints({
              ...selectedPrints,
              barcodeType: e,
            });
          }}
          selectedValue={selectedPrints.barcodeType}
        />
        <Dropdown
          placeholder={"Select Dimensions"}
          list={[
            { name: "3x1" },
            { name: "3x2" },
            { name: "4x3" },
            { name: "4x2" },
            { name: "4x6" },
            { name: "4x6-Landscape" },
            { name: "2x1" },
            { name: "2.25x1.25" },
            { name: "2.25x1.57" },
          ]}
          labelKey="name"
          valueKey="name"
          name="printSize"
          setSelected={(e) => {
            setSelectedPrints({
              ...selectedPrints,
              printSize: e,
            });
          }}
          selectedValue={selectedPrints.printSize}
        />
        <TextField
          type="number"
          id="noOfCopies"
          label="Number of Copies"
          placeholder=" "
          onChange={(e) =>
            setSelectedPrints({
              ...selectedPrints,
              noOfCopies: e.target.value,
            })
          }
          value={selectedPrints.noOfCopies}
          min="0"
        />
      </div>

      <div className="mt-5 flex items-center justify-center space-x-2">
        <ModalButton onClick={printSelectedBarcodes} text={"Print"} />
        <ModalButton
          onClick={() => printSelectedBarcodes(false)}
          text={"Download"}
        />
      </div>

      {selectedPrints && selectedPrints.barcodes && (
        <div className="mx-auto mt-8 max-w-max bg-gray-500 p-1">
          <span className="text-medium my-2 text-white">Barcode Preview</span>
          {selectedPrints &&
            selectedPrints.barcodes &&
            selectedPrints.printSize === "3x1" &&
            selectedPrints.barcodes.map((item) => (
              <>
                <ThreeByOneLabel id={item} value={item} key={item} />
                <img
                  id={`label-placeholder-${item}`}
                  style={{ position: "fixed", top: 10000 }}
                />
              </>
            ))}
          {selectedPrints &&
            selectedPrints.barcodes &&
            selectedPrints.printSize === "3x2" &&
            selectedPrints.barcodes.map((item) => (
              <ThreeByTwoLabel id={item} value={item} key={item} />
            ))}
          {selectedPrints &&
            selectedPrints.barcodes &&
            selectedPrints.printSize === "4x6" &&
            selectedPrints.barcodes.map((item) => (
              <FourBySixLabel id={item} value={item} key={item} />
            ))}
          {selectedPrints &&
            selectedPrints.barcodes &&
            selectedPrints.printSize === "4x3" &&
            selectedPrints.barcodes.map((item) => (
              <FourByThreeLabel id={item} value={item} key={item} />
            ))}
          {selectedPrints &&
            selectedPrints.barcodes &&
            selectedPrints.printSize === "4x2" &&
            selectedPrints.barcodes.map((item) => (
              <FourByTwoLabel id={item} value={item} key={item} />
            ))}
          {selectedPrints &&
            selectedPrints.barcodes &&
            selectedPrints.printSize === "4x6-Landscape" &&
            selectedPrints.barcodes.map((item) => (
              <FourBySixLandscapeLabel id={item} value={item} key={item} />
            ))}
          {selectedPrints &&
            selectedPrints.barcodes &&
            selectedPrints.printSize === "2x1" &&
            selectedPrints.barcodes.map((item) => (
              <>
                <TwoByOneLabel id={item} value={item} key={item} />
                <img
                  id={`label-placeholder-${item}`}
                  style={{ position: "fixed", top: 10000 }}
                />
              </>
            ))}
          {selectedPrints &&
            selectedPrints.barcodes &&
            selectedPrints.printSize === "2.25x1.57" &&
            selectedPrints.barcodes.map((item) => (
              <>
                <TwoByOneLabelAdjusted id={item} value={item} key={item} />
                <img
                  id={`label-placeholder-${item}`}
                  style={{ position: "fixed", top: 10000 }}
                />
              </>
            ))}
          {selectedPrints &&
            selectedPrints.barcodes &&
            selectedPrints.printSize === "2.25x1.25" &&
            selectedPrints.barcodes.map((item) => (
              <>
                <TwoQuarterByOneQuarterLabel
                  id={item}
                  value={item}
                  key={item}
                />
                <img
                  id={`label-placeholder-${item}`}
                  style={{ position: "fixed", top: 10000 }}
                />
              </>
            ))}
        </div>
      )}
    </Modal>
  );
};

export default PrintSettingsModal;
