import React from "react";
import { SearchIcon, XCircleIcon } from "@heroicons/react/outline";
import SortDropdown from "#newUiComponents/commons/sortDropdown";
import Filters from "#components/reports/Filters";

const ReportSearchSortFilters = ({
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  choices,
  setSort,
  sort,
  reportsMetadata,
  FREQUENCY,
  onChange,
  selectedFilterReport,
  setSelectedFilterReport,
  selectedReportTab,
  reportsTabs,
  reportFilterModal,
  setReportFilterModal,
  clearFilters,
  setShowFilters,
  showFilters,
  onChangeFilter,
}) => {
  return (
    <div style={{ padding: "1px" }} className="flex h-8 w-full items-center">
      <div
        style={{ width: "95%", height: "100%" }}
        className="relative mr-2 flex items-center border-none">
        <input
          type="text"
          placeholder="Search..."
          value={filters["keyword"] ? filters["keyword"] : ""}
          onChange={onChangeSearchKeyword}
          onKeyDown={(e) => (e.key === "Enter" ? submitFilters() : null)}
          className="h-full w-full rounded border p-2 text-sm text-gray-500 focus:border-primaryAccent focus:ring-primaryAccent"
        />
        {filters["keyword"] ? (
          <XCircleIcon
            className="absolute right-2 h-5 w-5 cursor-pointer text-gray-400"
            onClick={clearKeyword}
          />
        ) : (
          <SearchIcon className="absolute right-2 h-5 w-5 text-gray-400" />
        )}
      </div>
      {choices && choices.length > 0 && (
        <div className="m-1">
          <SortDropdown setSort={setSort} sort={sort} choices={choices} />
        </div>
      )}
      <div className="relative mr-2 flex items-center">
        <Filters
          reportsMetadata={reportsMetadata}
          FREQUENCY={FREQUENCY}
          onChange={onChange}
          selectedFilterReport={selectedFilterReport}
          setSelectedFilterReport={setSelectedFilterReport}
          selectedReportTab={selectedReportTab}
          reportsTabs={reportsTabs}
          reportFilterModal={reportFilterModal}
          setReportFilterModal={setReportFilterModal}
          clearFilters={clearFilters}
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          onChangeFilter={onChangeFilter}
          submitFilters={submitFilters}
          filters={filters}
        />
      </div>
    </div>
  );
};
export default ReportSearchSortFilters;
