import React from "react";
import { Tooltip } from "antd";
import { handleformatNumericValue } from "./PoUtils";

function PriceBreakUpTooltip({ currency, charges, title }) {
  return (
    <Tooltip
      color="white"
      title={
        <div className="bg-white p-4">
          <div className="grid grid-cols-2 gap-x-4">
            {charges.map((charge, index) => (
              <React.Fragment key={index}>
                <span
                  className={`truncate ${charge.name.toLowerCase() === "discount" ? "text-red-500" : "text-gray-500"}`}>
                  {charge.name}:
                </span>
                <span
                  className={`text-right ${charge.name.toLowerCase() === "discount" ? "text-red-500" : "text-black"}`}>
                  {`${currency} ${charge.name === "Discount" ? -1 * handleformatNumericValue(charge.amount) : handleformatNumericValue(charge.amount)}`}
                </span>
              </React.Fragment>
            ))}
            <div className="col-span-2 mt-2 flex justify-between border-t border-gray-200 pt-2">
              <span className="text-gray-700">Order Total:</span>
              <span className="text-black">{`${currency} ${title}`}</span>
            </div>
          </div>
        </div>
      }>
      <span className="cursor-pointer">{`${currency} ${title}`}</span>
    </Tooltip>
  );
}

export default PriceBreakUpTooltip;
