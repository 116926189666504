import React from "react";
import hopstackLogo from "../static/images/hopstack-logo.png";
import {
  LOGOUT_USER,
  SAVE_USER,
  SET_ACTIVITY,
  SET_STATION,
} from "#mutations/index";
import { useQuery } from "#hooks/useQuery";
import { useEffect, useContext, useState } from "react";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";

const AccountSuspended = () => {
  const logoutQuery = useQuery(LOGOUT_USER);
  const appState = useContext(AppStateContext);
  const auth = useContext(AuthContext);
  const [showAccountSuspended, setShowAccountSuspended] = useState(true);

  useEffect(() => {
    if (logoutQuery.data) {
      setShowAccountSuspended(false);
      auth.logout();
      appState.setAlert(logoutQuery.data.logout.message);
      window.location = "/";
    }

    if (logoutQuery.error) {
      auth.logout();
    }
  }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

  const handleLogoClick = () => {
    window.location.href = "https://hopstack.io";
  };

  return (
    <div className="mx-auto my-10 w-full max-w-md rounded-lg bg-white p-5 text-center font-sans shadow-lg">
      <div className="mb-4 cursor-pointer" onClick={handleLogoClick}>
        <img
          src={hopstackLogo}
          alt="Hopstack"
          className="mx-auto"
          style={{ maxWidth: "200px" }}
        />
      </div>

      <p className="mb-4 text-center text-lg" style={{ color: "#6c757d" }}>
        Your account has been suspended. Please reach out to your administrator
        or Hopstack account manager for any concerns or queries.
      </p>

      <button
        className="mt-4 w-full rounded bg-blue-800 px-4 py-2 font-medium text-white hover:bg-blue-700"
        onClick={() => {
          logoutQuery.fetchData();
        }}>
        Logout
      </button>
    </div>
  );
};

export default AccountSuspended;
