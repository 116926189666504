import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { CameraIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";

const AddLpnToProduct = ({
  setAddLpnToProduct,
  setSearchTerm,
  setInventorySearchKeyword,
  inventorySearchKeyword,
  availableInventory,
  addLpnToProduct,
  submitLpn,
}) => {
  const productSelected =
    addLpnToProduct && addLpnToProduct.productId
      ? availableInventory.find((i) => i.id === addLpnToProduct.productId)
      : {};
  return (
    <Modal
      title={`Add LPN to Product`}
      negativeAction={() => setAddLpnToProduct(null)}
      positiveAction={submitLpn}
      id="userFormModal">
      <div className="space-y-4">
        <label
          htmlFor="default-search"
          className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          Search
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              aria-hidden="true"
              className="h-5 w-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="text-md block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="Search Products..."
            onChange={(e) => setInventorySearchKeyword(e.target.value)}
            value={inventorySearchKeyword}
          />
        </div>
        {inventorySearchKeyword && inventorySearchKeyword.trim() !== "" && (
          <>
            <table className="table-auto items-start text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th className="text-md px-6 py-2 text-gray-500">ASIN</th>
                  <th className="text-md px-6 py-2 text-gray-500">SKU</th>
                  <th className="text-md px-6 py-2 text-gray-500">Name</th>
                  <th className="text-md px-6 py-2 text-gray-500">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {availableInventory.length === 0 && (
                  <tr className="whitespace-nowrap">
                    <td className="text-md px-6 py-4 text-gray-500">
                      No Results
                    </td>
                    <td className="text-md px-6 py-4 text-gray-500"></td>
                    <td className="text-md px-6 py-4 text-gray-500"></td>
                    <td className="text-md px-6 py-4 text-gray-500"></td>
                  </tr>
                )}

                {availableInventory.length > 0 &&
                  availableInventory.map((item, idx) => (
                    <tr className="whitespace-nowrap">
                      <td className="text-md px-6 py-4 text-gray-500">
                        {item.asin}
                      </td>
                      <td className="text-md px-6 py-4 text-gray-500">
                        {item.sku}
                      </td>
                      <td
                        className="text-md px-6 py-4 text-gray-500"
                        title={item.name}>
                        {truncate(item.name)}
                      </td>
                      <td className="text-md px-6 py-4 text-gray-500">
                        <div
                          onClick={() => {
                            setAddLpnToProduct({
                              ...addLpnToProduct,
                              productId: item.id,
                            });
                            setInventorySearchKeyword(null);
                          }}
                          className="text-md cursor-pointer rounded-md bg-2C7695 p-2 text-center text-white">
                          Select
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
        {(!inventorySearchKeyword || inventorySearchKeyword.trim() === "") &&
          addLpnToProduct && (
            <>
              {addLpnToProduct.productId && (
                <>
                  <div className="max-w-2xl rounded-lg border border-gray-200 bg-white p-6 shadow-md dark:border-gray-700 dark:bg-gray-800">
                    <a href="#">
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {productSelected.name}
                      </h5>
                    </a>
                    {productSelected.asin && (
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        ASIN:
                        <span className="font-bold">
                          {buildMarketplaceHyperlink(
                            productSelected.asin,
                            "ASIN",
                            productSelected.source,
                          )}
                        </span>
                      </p>
                    )}
                    {productSelected.sku && (
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        SKU:
                        <span className="px-2 font-bold">
                          {productSelected.sku}
                        </span>
                      </p>
                    )}

                    <div
                      className="inline-flex cursor-pointer items-center rounded-lg bg-red-700 px-3 py-2 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                      onClick={() =>
                        setAddLpnToProduct({
                          ...addLpnToProduct,
                          productId: null,
                        })
                      }>
                      Remove
                    </div>
                  </div>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <CameraIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="text-md block w-full rounded-lg border border-gray-300 bg-gray-100 p-4 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      placeholder="Scan or Enter Code"
                      onChange={(e) =>
                        setAddLpnToProduct({
                          ...addLpnToProduct,
                          lpn: e.target.value,
                        })
                      }
                      value={addLpnToProduct.lpn}
                    />
                  </div>
                </>
              )}
            </>
          )}
      </div>
    </Modal>
  );
};

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};

export default AddLpnToProduct;
