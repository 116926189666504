import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import {
  EyeIcon,
  DocumentSearchIcon,
  DotsVerticalIcon,
  ClipboardCheckIcon,
  CheckCircleIcon,
  DownloadIcon,
  MinusCircleIcon,
  HomeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import {
  PrinterIcon,
  PencilAltIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import { Menu } from "@headlessui/react";
import AddButton from "#components/utils/AddButton";
import Checkbox from "#components/utils/Checkbox";
import SearchSortFilter from "#components/common/SearchSortFilter";
import ExceptionReasons from "#components/orders/ExceptionReasons";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import cellStyleForTable from "../common/CellStyleForTable";
import { getCurrentValueOfFilter } from "../../utils/getCurrentValueOfFilter";
import CustomSelectDropDown from "../common/CustomSelectDropDown";
import Toggle from "#components/utils/Toggle";
import TableFilters from "../common/TableFilters";

const noValuesText = "No Orders";
const ORDER_MANAGEMENT_TABLE_NAME = "orderManagement";
import {
  isDelmarTenant,
  isProprepTenant,
  isVanEnglenTenant,
} from "#utils/tenantCheck";
import getCarrierTrackingURL from "../../utils/getCarrierTrackingUrl";
import { OrderSource } from "../../utils/enums/orderSourceEnum";
import NotificationDisplay from "#components/common/NotificationDisplay";
import { AppStateContext } from "#contexts/appState";
import { twMerge } from "tailwind-merge";
import { useLDClient } from "launchdarkly-react-client-sdk";
import SearchPanel from "#components/common/SearchPanel";
import { Select, Space } from "antd";
import featureFlags from "#constants/feature-flags";
import CheckBox from "#newUiComponents/commons/CheckBox";
import CustomBadge from "#newUiComponents/commons/CustomBadge";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import { EntityContext } from "#contexts/entity";
import CustomPopover from "#newUiComponents/commons/CustomPopover";
import {
  getEntityStatusColor,
  getHighlightedText,
} from "#utils/searchPanelUtils";

const ALERT_VISIBILITY_IN_MS = 2000;
const ORDER_DEBOUNCE_TIME_IN_MS = 500;

const searchTabEnums = {
  orderId: "Order ID",
  sku: "SKU",
  shippingAddressName: "Shipping Name",
  reference: "Reference",
  trackingNumber: "Tracking Number",
};

const payloadField = {
  orderId: "orderId",
  sku: "orderDetails.sku",
  shippingAddressName: "shippingAddress.name",
  reference: "reference",
  trackingNumber: "trackingNumber",
};

const OrdersList = ({
  mostRecentOrderId,
  newlyAddedOrders,
  newOrders,
  newOrdersAvailable,
  setNewOrdersAvailable,
  writable,
  orders,
  filters,
  onChangeFilter,
  getAudit,
  selectOrder,
  selectedOrders,
  runAutoBatching,
  simulateAutoBatching,
  createManualBatch,
  clearSelectedOrders,
  expandOrder,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  sort,
  setSort,
  setShowFilters,
  clearFilters,
  printShippingLabel,
  downloadShippingLabel,
  customers,
  markCompleted,
  markInProcess,
  outboundExceptions,
  tryResolve,
  viewShipmentPlan,
  updateTracking,
  updateCarrier,
  viewShippingAddress,
  expandedException,
  setExpandedException,
  expandException,
  tenant,
  printBol,
  downloadBol,
  deleteOrder,
  downloadOrderReport,
  cancelOrders,
  selectAllRows,
  allRowsSelected,
  downloadFnSkuLabels,
  printFnSkuLabels,
  handleAssignWarehouse,
  editOrder,
  markShippedOnMarketplace,
  tryMassResolveExceptions,
  possibleSubStatuses,
  showOnlySelected,
  setShowOnlySelected,
  showBatchSettings,
  setShowBatchSettings,
  batchSettingsEnabled,
  warehouses,
  bolUploadEnabled,
  printBoxLabels,
  downloadBoxLabels,
  printPalletLabels,
  downloadPalletLabels,
  total,
  dataTableFilters,
  printPackingLabels,
  downloadPackingLabel,
  packingLabelsEnabled,
  downloadPackingList,
  csvPackingListEnabled,
  printOrder,
  groupedOrders,
  setSelectedOrderNotes,
  viewInvoiceLabels,
  salesOrderMode,
  setSalesOrderMode,
  bulkMarkCompleted,
  salesOrderModesEnum,
  setSalesOrderIds,
  perPage,
  searchFilters,
  fetchEnhancedSearchResults,
  searchOrdersLoading,
  searchResults,
  setSearchResults,
  currentPage,
  setCurrentPage,
  bulkOrdersToMarkComplete,
  setBulkOrdersToMarkComplete,
  markOrdersCompleted,
  bulkOrdersToMarkCancelled,
  setBulkOrdersToMarkCancelled,
  bulkMarkCancelled,
  markOrdersCancelled,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const [showEnhancedSearch, setShowEnhancedSearch] = useState(false);
  const searchTabs = Object.entries(searchTabEnums).map((val) => ({
    id: val[0],
    name: val[1],
  }));
  const [activeTab, setActiveTab] = useState(searchTabs[0].id);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [enhancedSearchText, setEnhancedSearchText] = useState("");
  const [selectedEnhancedOrders, setSelectedEnhancedOrders] = useState([]); // order id of selected orders
  const entity = useContext(EntityContext);

  const searchRef = useRef();

  const ldClient = useLDClient();
  const isEnhancedSearchEnabled = ldClient.variation(
    featureFlags.ENHANCED_SEARCH_UX,
    false,
  );

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        !searchRef ||
        !searchRef.current ||
        !searchRef.current.contains(event.target)
      ) {
        if (!event.target.closest('[role="dialog"]')) {
          setShowEnhancedSearch(false);
        }
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Close search popup on Esc key press
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setShowEnhancedSearch(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const onChangeEnhancedSearchKeyword = (e) => {
    setEnhancedSearchText(e.target.value);
    setSearchResults({});
  };

  const clearEnhancedSearchKeyword = () => {
    setEnhancedSearchText("");
    setCurrentPage(0);
  };

  const getTableRow = (order) => {
    if (!order) return null;

    return (
      <div
        className="flex items-center justify-between border-b border-gray-300 pb-2"
        key={order?.orderData?.id}>
        <div className="flex items-center gap-6">
          <CheckBox
            id={order?.orderData?.id}
            checked={selectedEnhancedOrders.includes(order?.orderData?.id)}
            value={order?.orderData?.id}
            height="18px"
            width="18px"
            onChange={(e) => {
              if (selectedEnhancedOrders.includes(e.target.value)) {
                setSelectedEnhancedOrders((prev) =>
                  prev.filter((id) => id !== e.target.value),
                );
              } else {
                setSelectedEnhancedOrders((prev) => [...prev, e.target.value]);
              }
            }}
          />

          <div>
            <p className="flex gap-2">
              <a
                className="cursor-pointer font-medium text-gray-900"
                onClick={(e) => {
                  e.stopPropagation();
                  expandOrder(order?.orderData);
                }}>
                {activeTab === payloadField.orderId
                  ? getHighlightedText({
                      text: order?.orderData?.orderId,
                      activeTab: payloadField[activeTab],
                      searchHighlights: order?.searchHighlights,
                    })
                  : order?.orderData?.orderId}
              </a>

              {activeTab !== payloadField.orderId && (
                <span className="text-gray-400">|</span>
              )}

              {activeTab !== payloadField.orderId && (
                <span className="text-gray-600">
                  {getHighlightedText({
                    text: order?.orderData[activeTab],
                    activeTab: payloadField[activeTab],
                    searchHighlights: order?.searchHighlights,
                  })}
                </span>
              )}
            </p>

            <p className="flex gap-2">
              <span className="text-gray-600">
                {customers &&
                  customers.find(
                    (customer) => customer.id === order?.orderData?.customer,
                  )?.name}
              </span>

              <span className="text-gray-400">|</span>

              <span className="text-gray-600">
                {warehouses &&
                  warehouses.find(
                    (warehouse) => warehouse.id === order?.orderData?.warehouse,
                  )?.name}
              </span>
            </p>
          </div>

          <div>
            <CustomBadge
              label={order?.orderData?.orderStatus}
              className={twMerge(
                `font-medium ${getEntityStatusColor(order?.orderData?.orderStatus).bgColor} ${getEntityStatusColor(order?.orderData?.orderStatus).color}`,
              )}
            />
          </div>
        </div>

        <div className="mr-4">
          <CustomPopover
            actions={menuItems(order.orderData).map((menuItem) => ({
              ...menuItem,
              key: menuItem.title,
              icon: null,
              name: menuItem.title,
              onClick: menuItem["onClick"],
            }))}
            styles={{ right: 0 }}
            triggerElement={
              <DotsVerticalIcon className="h-6 w-6 text-gray-500" />
            }
          />
        </div>
      </div>
    );
  };

  const searchPayload = (keyword, nextCursor) => {
    return {
      keyword: keyword?.trim(),
      field: payloadField[activeTab],
      filters: {
        warehouses: selectedWarehouses?.map((warehouse) => warehouse.id),
        customers: selectedCustomers?.map((customer) => customer.id),
        status: selectedStatuses?.map((status) => status.id),
      },
      nextCursor,
    };
  };

  useDebouncedEffect(
    () => fetchEnhancedSearchResults(searchPayload(enhancedSearchText)),
    ORDER_DEBOUNCE_TIME_IN_MS,
    [enhancedSearchText],
  );

  useEffect(() => {
    // on scroll
    fetchEnhancedSearchResults(
      searchPayload(enhancedSearchText, searchResults?.nextCursor),
    );
  }, [currentPage]);

  useEffect(() => {
    setSearchResults({});
    setCurrentPage(0);
    fetchEnhancedSearchResults(searchPayload(enhancedSearchText, null));
  }, [activeTab, selectedWarehouses, selectedCustomers, selectedStatuses]);

  useEffect(() => {
    // Reset selected orders on switching tabs
    setSelectedEnhancedOrders([]);
  }, [activeTab]);

  const validScoreResults = searchResults?.entities
    ? [...searchResults.entities]?.filter((item) => item?.score > 0)
    : [];

  const [warehouseOpen, setOpenWarehouseOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);

  const filterProps = {
    Warehouse: {
      label: "FILTER BY WAREHOUSE",
      selectedValues: selectedWarehouses,
      setSelectedValues: setSelectedWarehouses,
      placeholder: "Select Warehouse",
      id: "warehouse",
      dropdownOpen: warehouseOpen,
      setDropdownOpen: setOpenWarehouseOpen,
      optionClasses: "text-lg",
    },
    Customer: {
      label: "FILTER BY CLIENT",
      selectedValues: selectedCustomers,
      setSelectedValues: setSelectedCustomers,
      placeholder: "Select Client",
      id: "customer",
      dropdownOpen: customerOpen,
      setDropdownOpen: setCustomerOpen,
      optionClasses: "text-lg",
    },
    "Order Status": {
      label: "FILTER BY STATUS",
      selectedValues: selectedStatuses,
      setSelectedValues: setSelectedStatuses,
      placeholder: "Select Status",
      id: "status",
      dropdownOpen: statusOpen,
      setDropdownOpen: setStatusOpen,
      optionClasses: "text-lg",
    },
  };

  const handleSearchFilterChange = ({ filterName, filterValue }) => {
    switch (filterName) {
      case "warehouse":
        const warehouseExists = selectedWarehouses.find(
          (warehouse) => warehouse.id === filterValue.id,
        );

        if (warehouseExists) {
          setSelectedWarehouses((warehouses) =>
            warehouses.filter(
              (warehouse) => warehouse.id !== warehouseExists.id,
            ),
          );
        } else {
          setSelectedWarehouses((warehouses) => [...warehouses, filterValue]);
        }
        break;

      case "customer":
        const customerExists = selectedCustomers.find(
          (customer) => customer.id === filterValue.id,
        );

        if (customerExists) {
          setSelectedCustomers((customers) =>
            customers.filter((customer) => customer.id !== customerExists.id),
          );
        } else {
          setSelectedCustomers((customers) => [...customers, filterValue]);
        }
        break;

      case "status":
        const statusExists = selectedStatuses.find(
          (status) => status.id === filterValue.id,
        );

        if (statusExists) {
          setSelectedStatuses((statues) =>
            statues.filter((status) => status.id !== statusExists.id),
          );
        } else {
          setSelectedStatuses((statues) => [...statues, filterValue]);
        }
        break;
    }
  };

  useEffect(() => {
    if (showEnhancedSearch) {
      clearSelectedOrders();
    }
  }, [showEnhancedSearch]);

  const appState = useContext(AppStateContext);

  useEffect(() => {
    if (newOrdersAvailable) {
      setShowNotification(true);
    }
  }, [newOrdersAvailable]);

  const handleNotificationClose = () => {
    setShowNotification(false); // Disable showing notification again
    setNewOrdersAvailable(false);
  };

  const handleRefreshNow = () => {
    window.location.reload();
    setNewOrdersAvailable(false);
    setShowNotification(false);
  };
  const intialHeaders = () => {
    const returnHeaders = [];
    returnHeaders.push(
      getHeaderObject("Order ID", "orderId"),
      getHeaderObject("Order Created Date", "createdAt"),
      getHeaderObject("Reference", "reference"),
      getHeaderObject("Client", "customer", false),
      getHeaderObject("Warehouse", "warehouse", false),
      getHeaderObject("Price", "orderValue"),
      getHeaderObject("Order Date", "orderDate"),
      getHeaderObject("Status", "orderStatus"),
      getHeaderObject("Sub-Status", "subStatus"),
      getHeaderObject("Requested Carrier", "shippingRequests", false),
      getHeaderObject("Carrier", "carrier"),
      getHeaderObject("Tracking Number", "trackingNumber"),
      getHeaderObject("Shipping Status", "shippingStatus"),
      getHeaderObject("Pro Number", "proNumber"),
      getHeaderObject("Source", "source"),
      getHeaderObject("Order Type", "orderType"),
      getHeaderObject("Delivery Method", "deliveryMethod"),
      getHeaderObject("Tote", "tote"),
      getHeaderObject("Sub-Tote", "subTote"),
      getHeaderObject("Multi-Line Order", "multipleLineItems"),
      getHeaderObject("Number of Products", "numberOfProducts"),
      getHeaderObject("Total Quantity", "totalQuantity"),
      getHeaderObject("Action", "action", false),
      getHeaderObject("Notes", "notes", false, null),
    );

    // ONLY FOR DELMAR. UNTIL WE CAN RETRIEVE THESE FIELDS DYNAMICALLY FROM THE BACKEND.
    if (isDelmarTenant(tenant?.subdomain)) {
      returnHeaders.push(getHeaderObject("Classification", "classification"));
      returnHeaders.push(getHeaderObject("Resizing", "resizingOrder"));
    }

    if (isVanEnglenTenant(tenant?.subdomain)) {
      returnHeaders.push(getHeaderObject("Payment Status", "paymentStatus"));
    }

    return returnHeaders;
  };

  const [headers, setHeaders] = useState(intialHeaders());

  const renderGroupName = (groupName) => {
    if (groupName === null || groupName === undefined) {
      return "No Value";
    }

    if (filters?.groupBy === "warehouse") {
      return warehouses?.find((item) => item.id === groupName)?.name;
    }

    if (filters?.groupBy === "customer") {
      return customers?.find((item) => item.id === groupName)?.name;
    }
    return groupName;
  };

  const [ORDER_STATUS, SET_ORDER_STATUS] = useState(
    outboundExceptions
      ? [
          { name: "EXCEPTION", id: "EXCEPTION", frequency: 2 },
          { name: "INCOMPLETE", id: "INCOMPLETE", frequency: 1 },
        ]
      : [
          { name: "UNPROCESSED", id: "UNPROCESSED", frequency: 10 },
          { name: "BATCHED", id: "BATCHED", frequency: 9 },
          { name: "IN-PROCESS", id: "IN-PROCESS", frequency: 8 },
          { name: "INCOMPLETE", id: "INCOMPLETE", frequency: 7 },
          { name: "CANCELLED", id: "CANCELLED", frequency: 4 },
          { name: "ON_HOLD", id: "ON_HOLD", frequency: 5 },
          { name: "PARTIAL_COMPLETED", id: "PARTIAL_COMPLETED" },
          { name: "COMPLETED", id: "COMPLETED", frequency: 6 },
          { name: "BATCHED_PARTIALLY", id: "BATCHED_PARTIALLY", frequency: 3 },
        ],
  );

  const CUSTOMERS_OBJECT_LIST = [
    ...customers?.map((customer) => {
      return {
        id: customer.id,
        name: customer.name,
      };
    }),
  ];

  const WAREHOUSES_OBJECT_LIST = [
    { label: "All", value: warehouses?.map((item) => item.id) },
    ...warehouses?.map((warehouse) => {
      return {
        value: warehouse.id,
        label: warehouse.name,
      };
    }),
  ];
  const [showHeaders, setShowHeaders] = useState(headers);
  const auth = useContext(AuthContext);

  useEffect(() => {
    setHeaders(intialHeaders());
  }, [tenant]);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      ORDER_MANAGEMENT_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
    }
  }, [auth.user]);

  const menuItems = (order) => {
    const arr = [];

    const isBackOrder = order.childOrders?.length > 0;

    const isSTO = order.orderType === "STO";

    if (writable && isOrderEditable(order) && !isBackOrder) {
      arr.push({
        title: "Edit Order",
        icon: PencilAltIcon,
        onClick: () => editOrder(order),
      });
    }

    if (order.orderStatus === "INCOMPLETE" && !isBackOrder) {
      arr.push({
        title: "Mark IN-PROCESS",
        icon: ClipboardCheckIcon,
        onClick: () => markInProcess(order.id),
      });
    }

    if (outboundExceptions && !isBackOrder) {
      if (writable)
        arr.push({
          title: "Resolve Exception",
          icon: ClipboardCheckIcon,
          onClick: () => tryResolve(order.id),
        });
      arr.push({
        title: "Check Reasons",
        icon: ClipboardCheckIcon,
        onClick: () => expandException(order.id),
      });
    }

    if (order.source !== "FBA" && !isBackOrder) {
      arr.push({
        title: "Shipping Address",
        icon: EyeIcon,
        onClick: () => viewShippingAddress(order),
      });
    }

    if (order.source === "FBA" && !isBackOrder) {
      arr.push({
        title: "Edit Shipment Plan",
        icon: PencilIcon,
        onClick: () => viewShipmentPlan(order),
      });
      arr.push({
        title: "Print FNSKUs",
        icon: PrinterIcon,
        onClick: () => printFnSkuLabels(order.id),
      });
      arr.push({
        title: "Download FNSKUs",
        icon: DownloadIcon,
        onClick: () => downloadFnSkuLabels(order.id),
      });
    }

    if (
      packingLabelsEnabled &&
      order?.subStatus === "PACKING_COMPLETED" &&
      !isBackOrder &&
      !isSTO
    ) {
      arr.push({
        title: "Print Packing Label",
        icon: PrinterIcon,
        onClick: () => printPackingLabels(order.id),
      });
      arr.push({
        title: "Download Packing Label",
        icon: DownloadIcon,
        onClick: () => downloadPackingLabel(order.id),
      });
    }

    if (
      order?.subStatus === "PACKING_COMPLETED" &&
      csvPackingListEnabled &&
      !isBackOrder
    ) {
      arr.push({
        title: `Packing List (${order?.source === OrderSource.SAP_B1_TYM || isProprepTenant(appState.subdomain) ? "PDF" : "CSV"})`,
        icon: DownloadIcon,
        onClick: () => downloadPackingList(order.id),
      });
    }

    arr.push({
      title: "Audit Trail",
      icon: DocumentSearchIcon,
      onClick: () => getAudit(order),
    });

    if (writable && !isBackOrder && !isSTO) {
      if (!outboundExceptions) {
        if (order.orderStatus !== "COMPLETED") {
          arr.push({
            title: "Update Carrier",
            icon: PencilAltIcon,
            onClick: () => updateCarrier(order),
          });
        }
        arr.push({
          title: "Update Shipping Info",
          icon: PencilAltIcon,
          onClick: () => updateTracking(order),
        });
      }
      if (
        order.orderStatus === "COMPLETED" &&
        ([
          OrderSource.FBA,
          OrderSource.SHOPIFY,
          OrderSource.SALLA,
          OrderSource.NETSUITE,
          OrderSource.ZID,
          OrderSource.APPARELMAGIC,
          OrderSource.SHIP_COMPLIANT,
          OrderSource.SAP_B1_TYM,
          OrderSource.E_BAY,
        ].includes(order.source) ||
          order?.source?.includes(OrderSource.COMMERCE_HUB))
      ) {
        arr.push({
          title: "Mark Complete on Marketplace",
          icon: PencilIcon,
          onClick: () => markShippedOnMarketplace(order.id),
        });
      }
      if (tenant?.features?.multiwarehouse)
        arr.push({
          title: "Assign Warehouse",
          icon: HomeIcon,
          onClick: () => handleAssignWarehouse(order),
        });
    }
    if (!outboundExceptions && !isBackOrder && !isSTO) {
      if (tenant?.typeOfCustomer?.includes("B2B") || bolUploadEnabled) {
        arr.push({
          title: "Download BOL",
          icon: DownloadIcon,
          onClick: () => downloadBol(order.id),
        });
        arr.push({
          title: "Print BOL",
          icon: PrinterIcon,
          onClick: () => printBol(order.id),
        });
      }
      if (order?.shippingLabel) {
        arr.push({
          title: "Print Shipping Label",
          icon: PrinterIcon,
          onClick: () => printShippingLabel(order.id),
        });
        arr.push({
          title: "Download Shipping Label",
          icon: DownloadIcon,
          onClick: () => downloadShippingLabel(order.id),
        });
      }
      if (order?.invoiceUrls) {
        arr.push({
          title: "View Invoice Labels",
          icon: EyeIcon,
          onClick: () => viewInvoiceLabels(order.invoiceUrls),
        });
      }
      if (order.source === "FBA" && order?.boxLabels) {
        arr.push(
          {
            title: "Print Box Labels",
            icon: PrinterIcon,
            onClick: () => printBoxLabels(order.id),
          },
          {
            title: "Download Box Labels",
            icon: DownloadIcon,
            onClick: () => downloadBoxLabels(order.id),
          },
        );
      }
      if (order.source === "FBA" && order.typeOfShipment === "LTL") {
        arr.push(
          {
            title: "Print Pallet Labels",
            icon: PrinterIcon,
            onClick: () => printPalletLabels(order.id),
          },
          {
            title: "Download Pallet Labels",
            icon: DownloadIcon,
            onClick: () => downloadPalletLabels(order.id),
          },
        );
      }
    }

    if (downloadOrderReport && !isBackOrder && !isSTO) {
      arr.push({
        title: "Download Report",
        icon: DownloadIcon,
        onClick: () => downloadOrderReport(order.id),
      });
    }

    if (order.source === "Hopstack" && !isBackOrder) {
      arr.push({
        title: "Delete Order",
        icon: TrashIcon,
        onClick: () => deleteOrder(order.id),
      });
    }

    if (isDelmarTenant(tenant?.subdomain) && !isBackOrder) {
      arr.push({
        title: "Print Order",
        icon: PrinterIcon,
        onClick: () => printOrder(order),
      });
    }

    // ANY ORDER THAT'S NOT COMPLETED OR CANCELLED CAN BE CANCELLED.
    // WE WILL HAVE BACKEND PROTECTIONS LATER ON FOR FULL STATE DIAGRAM IMPLEMENTATION OF ORDER STATUS CHANGES
    if (
      ["CANCELLED", "COMPLETED"].includes(order.orderStatus) === false &&
      writable &&
      !isBackOrder
    ) {
      arr.push({
        title: "Mark as Cancelled",
        icon: MinusCircleIcon,
        onClick: () => cancelOrders(order.id),
      });
    }

    if (
      ["CANCELLED", "COMPLETED", "EXCEPTION"].includes(order.orderStatus) ===
        false &&
      writable &&
      !isBackOrder
    ) {
      arr.push({
        title: "Mark Complete",
        icon: CheckCircleIcon,
        onClick: () => markCompleted(order.id),
      });
    }

    return arr;
  };
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const allEligibleDataTableFilters = () => {
    const returnArr = [];
    if (warehouses?.length > 1) {
      returnArr.push({
        displayLabel: "Warehouse",
        data: warehouses,
        key: "warehouse",
      });
    }

    if (customers?.length > 1) {
      returnArr.push({
        displayLabel: "Client",
        data: CUSTOMERS_OBJECT_LIST,
        key: "customer",
      });
    }
    returnArr.push(
      {
        displayLabel: "Status",
        data: ORDER_STATUS,
        key: "orderStatus",
      },
      {
        displayLabel: "Sub-Status",
        data: possibleSubStatuses,
        key: "subStatus",
      },
    );

    if (dataTableFilters && dataTableFilters.length > 0) {
      // REASON FOR THE FILTER HERE IS TO ENSURE NULL DATA IS STILL SENT, BASICALLY THAT COVERS DATE FIELDS
      // EMPTY ARRAYS SHOULD NOT BE SENT
      returnArr.push(
        ...dataTableFilters.filter((i) => i.data === null || i.data.length > 0),
      );
    }
    return returnArr;
  };

  return (
    <>
      {showNotification && newOrdersAvailable && true && (
        <NotificationDisplay
          message={
            <span>
              You have{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {newlyAddedOrders}
              </span>{" "}
              <span style={{ fontWeight: "bold" }}>new orders</span> available.
              {""}
            </span>
          }
          onClose={handleNotificationClose}
          refreshNowLink={{
            text: "Refresh now",
            onClick: handleRefreshNow,
          }}
        />
      )}
      {expandedException && expandedException.exceptions && (
        <ExceptionReasons
          exceptionReasons={expandedException.exceptions}
          negativeAction={() => setExpandedException(null)}
        />
      )}
      <div className="w-full overflow-auto rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
        <TableFilters
          onChangeFilter={onChangeFilter}
          filters={filters}
          totalResults={total}
          dataTableFilters={allEligibleDataTableFilters()}
          clearFilters={clearFilters}
          submitFilters={submitFilters}
          autoSubmitFilters={false}
        />
        {!filters?.groupBy && (
          <div className="relative" ref={searchRef}>
            <SearchSortFilter
              onChangeSearchKeyword={
                isEnhancedSearchEnabled
                  ? onChangeEnhancedSearchKeyword
                  : onChangeSearchKeyword
              }
              value={
                isEnhancedSearchEnabled
                  ? enhancedSearchText
                  : filters["keyword"]
              }
              onFocus={() => setShowEnhancedSearch(true)}
              filters={filters}
              submitFilters={isEnhancedSearchEnabled ? null : submitFilters}
              clearKeyword={
                isEnhancedSearchEnabled
                  ? clearEnhancedSearchKeyword
                  : clearKeyword
              }
              setSort={setSort}
              sort={sort}
              headers={headers}
              setShowHeaders={setShowHeaders}
              tableName={ORDER_MANAGEMENT_TABLE_NAME}
              setShowBatchSettings={
                writable && batchSettingsEnabled && setShowBatchSettings
              }
              runAutoBatching={
                !selectedOrders ||
                (selectedOrders.length === 0 &&
                  !outboundExceptions &&
                  runAutoBatching)
              }
              clearSelectedOrdersButton={
                selectedOrders.length > 0 && (
                  <AddButton
                    text="Clear"
                    onClick={clearSelectedOrders}
                    styles={["bg-F4C261 text-black text-lg"]}
                  />
                )
              }
              manualBatchButton={
                (selectedOrders.length > 0 ||
                  selectedEnhancedOrders.length > 0) && (
                  <div className="flex space-x-2">
                    <AddButton
                      text={"Mark Completed"}
                      onClick={async () => {
                        const selectedOrderIds =
                          enhancedSearchText && showEnhancedSearch
                            ? selectedEnhancedOrders
                            : selectedOrders;

                        try {
                          await bulkMarkCompleted(selectedOrderIds);

                          if (enhancedSearchText && showEnhancedSearch) {
                            markOrdersCompleted({
                              entity: {
                                entities: searchResults?.entities?.map(
                                  (entity) => entity?.orderData,
                                ),
                              },
                              selectedOrders: selectedEnhancedOrders,
                            });
                          } else {
                            markOrdersCompleted({
                              entity,
                              selectedOrders,
                            });
                          }
                        } catch (error) {
                          console.error(
                            "An error occurred while marking orders as completed:",
                            error,
                          );
                        }
                      }}
                      styles={["text-lg"]}
                    />
                    <AddButton
                      text={"Mark as Cancelled"}
                      onClick={async () => {
                        const selectedOrderIds =
                          enhancedSearchText && showEnhancedSearch
                            ? selectedEnhancedOrders
                            : selectedOrders;

                        try {
                          await bulkMarkCancelled(selectedOrderIds);
                          if (enhancedSearchText && showEnhancedSearch) {
                            markOrdersCancelled({
                              entity: {
                                entities: searchResults?.entities?.map(
                                  (entity) => entity?.orderData,
                                ),
                              },
                              selectedOrders: selectedEnhancedOrders,
                            });
                          } else {
                            markOrdersCancelled({
                              entity,
                              selectedOrders,
                            });
                          }
                        } catch (error) {
                          console.error(
                            "An error occurred while marking orders as completed:",
                            error,
                          );
                        }
                      }}
                      styles={["text-lg"]}
                    />
                    <AddButton
                      text={
                        outboundExceptions
                          ? "Bulk Resolve"
                          : "Create Manual Batch"
                      }
                      onClick={() => {
                        if (enhancedSearchText && showEnhancedSearch) {
                          if (outboundExceptions) {
                            tryMassResolveExceptions({
                              selectedOrders: selectedEnhancedOrders,
                            });
                          } else {
                            createManualBatch({
                              entity: {
                                entities: searchResults?.entities?.map(
                                  (entity) => entity?.orderData,
                                ),
                              },
                              selectedOrders: selectedEnhancedOrders,
                            });
                          }
                        } else {
                          if (outboundExceptions) {
                            tryMassResolveExceptions({ selectedOrders });
                          } else {
                            createManualBatch({
                              entity,
                              selectedOrders,
                            });
                          }
                        }
                      }}
                      styles={["text-lg"]}
                    />
                  </div>
                )
              }
            />
            {isEnhancedSearchEnabled && showEnhancedSearch ? (
              <div className="absolute top-14 z-10 w-full rounded-md border-2 border-gray-300 bg-[#FEFEFE] p-5 pb-0">
                <SearchPanel
                  filters={[
                    ...searchFilters
                      .filter((filter) => filter?.data?.length > 0)
                      .map((filter) => ({
                        ...filter,
                        ...filterProps[filter.displayLabel],
                      })),
                  ]}
                  tabs={searchTabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  onChangeFilter={handleSearchFilterChange}
                  data={validScoreResults}
                  rowRender={(order) => getTableRow(order)}
                  isLoading={searchOrdersLoading}
                  totalResults={searchResults?.total}
                  setCurrentPage={setCurrentPage}
                  nextCursor={
                    searchResults.entities?.find((item) => item?.score === 0)
                      ? null
                      : searchResults?.nextCursor
                  }
                  emptySearchText={
                    <>
                      <p className="font-semibold">
                        {!searchOrdersLoading &&
                        enhancedSearchText &&
                        !validScoreResults?.length
                          ? "No results found"
                          : "Search Criteria Not Entered"}
                      </p>
                      <p className="text-sm text-gray-400">
                        Please type Order ID, SKU, Tracking Number, or more to
                        search
                      </p>
                    </>
                  }
                />
              </div>
            ) : null}
          </div>
        )}

        {!filters?.groupBy &&
          (selectedOrders.length > 0 || showOnlySelected) && (
            <div className="flex items-center space-x-4 px-2">
              <div>
                <Toggle
                  enabled={showOnlySelected}
                  setEnabled={(key) => setShowOnlySelected(key)}
                />
              </div>
              <div className="text-2xl">
                Show only selected orders(
                {selectedOrders.length})
              </div>
            </div>
          )}
        {!filters?.groupBy && (
          <CustomTableWithTheme>
            <thead className="sticky left-0 top-0 z-0 bg-primaryAccent p-4">
              <tr className="border-left font-montserrat text-textWhite">
                <th
                  scope="col"
                  className="px-2 py-3 pl-6 text-left font-medium tracking-wider">
                  <Checkbox
                    role="checkbox"
                    onChange={selectAllRows}
                    name="binLocations"
                    value={"Select All Bin Locations"}
                    checked={allRowsSelected}
                    disabled={showEnhancedSearch}
                  />
                </th>
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="cursor-pointer px-2 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}
                      onClick={() => {
                        if (!header.sortable) {
                          return;
                        }

                        if (sort === header.correspondingValue) {
                          setSort(`-${header.correspondingValue}`);
                        } else {
                          setSort(header.correspondingValue);
                        }
                      }}>
                      <div className="flex items-center">
                        {header.name}
                        {renderSort(header, sort)}
                      </div>
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {orders.length === 0 ? (
                <tr className="bg-white">
                  {showHeaders.map((_, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                        key={headerIdx}>
                        {noValuesText}
                      </td>
                    ) : (
                      <td
                        className="px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                        key={headerIdx}></td>
                    ),
                  )}
                  <td
                    scope="col"
                    className="px-2 py-3 pl-6 text-left font-medium tracking-wider"></td>
                </tr>
              ) : null}
              {orders.map((order, rowIndex) => (
                <tr
                  key={order.id}
                  className={`${
                    rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}>
                  <td
                    className={`rounded-bl rounded-tl border-l-8 p-5 text-left font-semibold tracking-wider text-primaryAccent ${
                      rowIndex % 2 === 0
                        ? "border-F4C261"
                        : "border-primaryAccent"
                    }`}>
                    <Checkbox
                      role="checkbox"
                      onChange={(_) => {
                        selectOrder(rowIndex, shiftKeyPressed);
                      }}
                      name="order"
                      value={order.id}
                      checked={selectedOrders.includes(order.id)}
                    />
                  </td>
                  {showHeaders.map((header, columnIndex) => {
                    let value = header.correspondingValue;
                    let cellStyle = cellStyleForTable(
                      value,
                      ["orderId"],
                      columnIndex + 1,
                      rowIndex,
                    );
                    if (value === "orderId") {
                      const isNewOrder =
                        Array.isArray(newOrders) &&
                        newOrders.length > 0 &&
                        newOrders.some((newOrder) => newOrder.id === order.id);
                      return (
                        <td className={cellStyle}>
                          {order.childOrders?.length > 0 ? (
                            <div className="mt-4 flex items-center gap-x-2">
                              <a onClick={() => expandOrder(order)}>
                                {order.orderId}
                              </a>
                              <OrderIcon type="Back" />
                            </div>
                          ) : order.splitOrders?.length > 0 ? (
                            <div className="mt-4 flex items-center gap-x-2">
                              <a onClick={() => expandOrder(order)}>
                                {order.orderId}
                              </a>
                              <OrderIcon type="Split" />
                            </div>
                          ) : (
                            <a onClick={() => expandOrder(order)}>
                              {order.orderId}
                            </a>
                          )}
                          {isNewOrder && (
                            <span className="ml-2 inline-flex select-none items-center rounded-full bg-activeTextColor/20 px-2 py-0.5 text-sm font-medium text-activeTextColor">
                              New
                            </span>
                          )}
                          <br />
                          {order.cannotFulfil && (
                            <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800">
                              Insufficient Inventory
                            </span>
                          )}
                        </td>
                      );
                    }
                    if (value === "customer") {
                      return (
                        <td className={cellStyle}>
                          {customers &&
                            customers.find((item) => item.id === order.customer)
                              ?.name}
                        </td>
                      );
                    }

                    if (value === "warehouse") {
                      return (
                        <td className={cellStyle}>
                          {order.splitOrders?.length > 0
                            ? "Multi"
                            : warehouses &&
                              warehouses.find(
                                (item) => item.id === order.warehouse,
                              )?.name}
                        </td>
                      );
                    }
                    if (value === "orderValue") {
                      return (
                        <td className={cellStyle}>
                          ${_.round(order.orderValue, 2).toFixed(2)}
                        </td>
                      );
                    }
                    if (value === "orderDate") {
                      return (
                        <td className={cellStyle}>
                          {moment(order.orderDate).format("MMM Do YYYY")}
                        </td>
                      );
                    }
                    if (value === "createdAt") {
                      return (
                        <td className={cellStyle}>
                          {moment(order.createdAt).format("MMM Do YYYY")}
                        </td>
                      );
                    }
                    if (value === "orderStatus") {
                      return <td className={cellStyle}>{order.orderStatus}</td>;
                    }
                    if (value === "subStatus") {
                      return <td className={cellStyle}>{order.subStatus}</td>;
                    }
                    if (value === "shippingStatus") {
                      return (
                        <td className={cellStyle}>{order.shippingStatus}</td>
                      );
                    }

                    if (value === "source") {
                      return <td className={cellStyle}>{order.source}</td>;
                    }
                    if (value === "orderType") {
                      return (
                        <td className={cellStyle}>
                          {order.orderType === "STO"
                            ? "Stock Transfer"
                            : order.orderType}
                        </td>
                      );
                    }
                    if (value === "carrier") {
                      return <td className={cellStyle}>{order.carrier}</td>;
                    }
                    if (value === "reference") {
                      return (
                        <td
                          className={twMerge(
                            `${cellStyle} ${
                              order?.source ===
                              OrderSource.HOPSTACK_SALES_PORTAL
                                ? "cursor-pointer text-blue-900 underline"
                                : ""
                            }`,
                          )}
                          onClick={() => {
                            if (
                              order?.source ===
                              OrderSource.HOPSTACK_SALES_PORTAL
                            ) {
                              setSalesOrderMode(salesOrderModesEnum.VIEW);

                              if (order?.salesOrder !== null) {
                                setSalesOrderIds(order.salesOrder);
                              } else {
                                setSalesOrderIds([]);

                                appState.setAlert(
                                  "Sales order ID not found.",
                                  "error",
                                  ALERT_VISIBILITY_IN_MS,
                                );
                              }
                            }
                          }}>
                          {order.reference}
                        </td>
                      );
                    }
                    if (value === "classification") {
                      return (
                        <td className={cellStyle}>{order.classification}</td>
                      );
                    }
                    if (value === "multipleLineItems") {
                      return (
                        <td className={cellStyle}>
                          {order.multipleLineItems ? "Yes" : "No"}
                        </td>
                      );
                    }
                    if (value === "paymentStatus") {
                      return (
                        <td className={cellStyle}>
                          {order.customAttributes &&
                          order.customAttributes.paymentDetails &&
                          order.customAttributes.paymentDetails.paymentStatus
                            ? order.customAttributes.paymentDetails
                                .paymentStatus
                            : ""}
                        </td>
                      );
                    }
                    if (value === "numberOfProducts") {
                      return (
                        <td className={cellStyle}>{order.numberOfProducts}</td>
                      );
                    }
                    if (value === "totalQuantity") {
                      return (
                        <td className={cellStyle}>{order.totalQuantity}</td>
                      );
                    }
                    if (value === "resizingOrder") {
                      return (
                        <td className={cellStyle}>
                          {order.customAttributes?.resizingOrder === true
                            ? "Yes"
                            : "No"}
                        </td>
                      );
                    }
                    if (value === "trackingNumber") {
                      return getCarrierTrackingURL(
                        order.trackingNumber,
                        order.carrier,
                        cellStyle,
                      );
                    }
                    if (value === "proNumber") {
                      return <td className={cellStyle}>{order.proNumber}</td>;
                    }
                    if (value === "shippingRequests") {
                      return (
                        <td className={cellStyle}>
                          {order.shippingRequests &&
                            order.shippingRequests.length &&
                            order.shippingRequests[0].title}
                        </td>
                      );
                    }
                    if (value === "deliveryMethod") {
                      return (
                        <td className={cellStyle}>
                          {order.deliveryMethod || "-"}
                        </td>
                      );
                    }
                    if (value === "tote") {
                      return <td className={cellStyle}>{order.tote}</td>;
                    }
                    if (value === "subTote") {
                      return <td className={cellStyle}>{order.subTote}</td>;
                    }
                    if (value === "notes") {
                      return (
                        <td className={cellStyle}>
                          {order?.notes?.length > 0 ? (
                            <EyeIcon
                              className="text-black-600 cursor-pointer text-xs"
                              width={35}
                              onClick={() => setSelectedOrderNotes(order.notes)}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      );
                    }
                    if (value === "action") {
                      const isLastRow = rowIndex === perPage - 1;
                      const isLastColumn =
                        columnIndex === showHeaders.length - 1;

                      return (
                        <td className={cellStyle}>
                          <Menu as="div" className="text-left">
                            <Menu.Button>
                              <DotsVerticalIcon className="text-md h-8 w-8 rounded bg-E1D3B8 p-2 font-montserrat text-5F666B" />
                            </Menu.Button>
                            <Menu.Items
                              className={`${isLastRow && "bottom-0"} ${isLastColumn && "right-0"} absolute z-10 focus:outline-none ${
                                isLastRow
                                  ? "bottom-0 mb-1 origin-bottom-left -translate-y-12"
                                  : "mt-2 origin-top-right"
                              } divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5`}>
                              <div className="max-h-96 overflow-y-scroll">
                                {menuItems(order).map((menuItem, idx) => (
                                  <Menu.Item key={idx}>
                                    {({ active }) => (
                                      <button
                                        className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                                        onClick={menuItem["onClick"]}>
                                        {menuItem.icon && (
                                          <menuItem.icon className="h-8 w-8 pr-2" />
                                        )}
                                        {menuItem.title}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Menu>
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </CustomTableWithTheme>
        )}
        {filters?.groupBy && (
          <CustomTableWithTheme>
            <thead className="sticky left-0 top-0 z-0 bg-primaryAccent p-4">
              <tr className="border-left font-montserrat text-textWhite">
                <th
                  scope="col"
                  className="px-2 py-3 pl-6 text-left font-medium tracking-wider">
                  Value
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 pl-6 text-left font-medium tracking-wider">
                  Count
                </th>
              </tr>
            </thead>
            <tbody>
              {!groupedOrders?.length ? (
                <tr className="bg-white">
                  <td>{noValuesText}</td>
                  <td></td>
                </tr>
              ) : (
                groupedOrders.map((group, idx) => (
                  <tr key={idx} className="bg-white">
                    <td className="rounded-bl rounded-tl border-l-8 border-F4C261 p-5 text-left font-semibold tracking-wider text-primaryAccent">
                      {renderGroupName(group._id)}
                    </td>
                    <td className="p-5 text-left font-semibold tracking-wider text-primaryAccent">
                      {group.total}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </CustomTableWithTheme>
        )}
      </div>
    </>
  );
};

const renderSort = (header, sort) => {
  if (!header || !header.sortable) {
    return null;
  }

  if (sort && sort.replace("-", "") === header.correspondingValue) {
    if (sort.indexOf("-") !== -1) {
      return <ChevronDownIcon className="h-6 w-6 text-green-400" />;
    }

    return <ChevronUpIcon className="h-6 w-6 text-green-400" />;
  }
  return <ChevronUpIcon className="h-6 w-6 text-gray-400" />;
};

const isOrderEditable = ({ source, orderStatus }) => {
  const orderStatuses = ["UNPROCESSED", "EXCEPTION"];
  const sources = ["Hopstack"];

  return sources.includes(source) && orderStatuses.includes(orderStatus);
};

const OrderIcon = ({ type }) => {
  return (
    <div className="rounded bg-primaryAccent px-1">
      <span className="text-sm font-bold text-white">
        {type === "Back" ? "B" : type === "Split" ? "S" : ""}
      </span>
    </div>
  );
};

export default OrdersList;
