import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import _ from "lodash";

const RoundedDropdown = ({
  list,
  selectedValue,
  setSelected,
  placeholder,
  labelKey = "name",
  valueKey = "id",
  title,
  name,
  disabled,
}) => {
  return (
    <div
      aria-disabled={disabled}
      className={
        disabled
          ? "pointer-events-none top-16 w-full opacity-50"
          : "top-16 w-full"
      }>
      <span className="pb-2 font-montserrat text-lg font-medium text-454A4F">
        {title}
      </span>
      <Listbox value={selectedValue} onChange={setSelected} name={name}>
        <div className="relative mt-1 font-montserrat text-sm font-medium text-2C7695">
          <Listbox.Button className="relative w-full cursor-pointer rounded-full border-2 border-2C7695 bg-white p-2 pr-10 text-left hover:bg-gray-50 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate text-lg font-medium text-gray-900">
              {_.find(list, (e) => e[valueKey] === selectedValue) ? (
                _.find(list, (e) => e[valueKey] === selectedValue)[labelKey]
              ) : (
                <span className="mr-4 text-primaryAccent">{placeholder}</span>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-2">
              <ChevronDownIcon
                className="h-8 w-8 font-normal text-2C7695"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute z-50 max-h-96 overflow-auto rounded-xl bg-primaryAccent text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {list && list.length > 0 ? (
                list
                  .sort(
                    (a, b) =>
                      b.frequency - a.frequency ||
                      a[labelKey].localeCompare(b[labelKey]),
                  )
                  .map((listItem, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        `relative cursor-pointer select-none border-b border-EBEBEB py-4 pl-4 pr-4 ${
                          active
                            ? "bg-EBEBEB text-primaryAccent"
                            : "bg-primaryAccent text-EBEBEB"
                        }`
                      }
                      value={listItem[valueKey]}>
                      {({ selected }) => (
                        <>
                          <span className="mr-5 block truncate text-base font-normal">
                            {listItem[labelKey]}
                          </span>
                          {selected && (
                            <span className="absolute inset-y-0 right-2 flex items-center pl-4">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))
              ) : (
                <Listbox.Option
                  className="relative cursor-default select-none border-b border-aquaBlue py-4 pl-4 pr-4 text-white"
                  value={"No results"}
                  disabled>
                  <span className="mr-5 block truncate text-base font-normal">
                    {"No results"}
                  </span>
                </Listbox.Option>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default RoundedDropdown;
