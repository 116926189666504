export const SHIPPING_PERCENTAGES = [
  {
    ID: 1,
    STATE: "ALABAMA",
    ABREV: "AL",
    NDCODE: 43,
    SHP_PCT: 10,
    FRM_ZIP: "350",
    TO_ZIP: "369",
    SHP_DATE: "10/30/1989",
  },
  {
    ID: 2,
    STATE: "ARIZONA",
    ABREV: "AZ",
    NDCODE: 9,
    SHP_PCT: 15,
    FRM_ZIP: "850",
    TO_ZIP: "869",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 3,
    STATE: "ARKANSAS",
    ABREV: "AR",
    NDCODE: 5,
    SHP_PCT: 15,
    FRM_ZIP: "716",
    TO_ZIP: "729",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 4,
    STATE: "CALIFORNIA S",
    ABREV: "CA",
    NDCODE: 6,
    SHP_PCT: 15,
    FRM_ZIP: "900",
    TO_ZIP: "939",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 5,
    STATE: "COLORADO",
    ABREV: "CO",
    NDCODE: 7,
    SHP_PCT: 15,
    FRM_ZIP: "800",
    TO_ZIP: "816",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 6,
    STATE: "CONNECTICUT",
    ABREV: "CT",
    NDCODE: 8,
    SHP_PCT: 10,
    FRM_ZIP: "060",
    TO_ZIP: "069",
    SHP_DATE: "10/12/1994",
  },
  {
    ID: 7,
    STATE: "DELAWARE",
    ABREV: "DE",
    NDCODE: 4,
    SHP_PCT: 10,
    FRM_ZIP: "197",
    TO_ZIP: "199",
    SHP_DATE: "10/26/1994",
  },
  {
    ID: 8,
    STATE: "DISTRICT OF COLUMBIA",
    ABREV: "DC",
    NDCODE: 51,
    SHP_PCT: 10,
    FRM_ZIP: "200",
    TO_ZIP: "205",
    SHP_DATE: "10/26/1994",
  },
  {
    ID: 9,
    STATE: "FLORIDA",
    ABREV: "FL",
    NDCODE: 30,
    SHP_PCT: 15,
    FRM_ZIP: "320",
    TO_ZIP: "349",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 10,
    STATE: "GEORGIA",
    ABREV: "GA",
    NDCODE: 10,
    SHP_PCT: 15,
    FRM_ZIP: "300",
    TO_ZIP: "319",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 11,
    STATE: "IDAHO",
    ABREV: "ID",
    NDCODE: 12,
    SHP_PCT: 15,
    FRM_ZIP: "832",
    TO_ZIP: "838",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 12,
    STATE: "ILLINOIS",
    ABREV: "IL",
    NDCODE: 13,
    SHP_PCT: 15,
    FRM_ZIP: "600",
    TO_ZIP: "629",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 13,
    STATE: "IOWA",
    ABREV: "IA",
    NDCODE: 15,
    SHP_PCT: 15,
    FRM_ZIP: "500",
    TO_ZIP: "528",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 14,
    STATE: "KANSAS",
    ABREV: "KS",
    NDCODE: 16,
    SHP_PCT: 15,
    FRM_ZIP: "660",
    TO_ZIP: "679",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 15,
    STATE: "KENTUCKY",
    ABREV: "KY",
    NDCODE: 17,
    SHP_PCT: 15,
    FRM_ZIP: "400",
    TO_ZIP: "427",
    SHP_DATE: "10/6/1994",
  },
  {
    ID: 16,
    STATE: "LOUISIANA",
    ABREV: "LA",
    NDCODE: 18,
    SHP_PCT: 15,
    FRM_ZIP: "700",
    TO_ZIP: "714",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 17,
    STATE: "MAINE",
    ABREV: "ME",
    NDCODE: 19,
    SHP_PCT: 10,
    FRM_ZIP: "039",
    TO_ZIP: "049",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 18,
    STATE: "MARYLAND",
    ABREV: "MD",
    NDCODE: 20,
    SHP_PCT: 10,
    FRM_ZIP: "206",
    TO_ZIP: "219",
    SHP_DATE: "10/26/1994",
  },
  {
    ID: 19,
    STATE: "MASSACHUSETTS",
    ABREV: "MA",
    NDCODE: 21,
    SHP_PCT: 10,
    FRM_ZIP: "010",
    TO_ZIP: "027",
    SHP_DATE: "10/6/1994",
  },
  {
    ID: 20,
    STATE: "MICHIGAN",
    ABREV: "MI",
    NDCODE: 22,
    SHP_PCT: 10,
    FRM_ZIP: "480",
    TO_ZIP: "499",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 21,
    STATE: "MINNESOTA",
    ABREV: "MN",
    NDCODE: 23,
    SHP_PCT: 15,
    FRM_ZIP: "550",
    TO_ZIP: "567",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 22,
    STATE: "MISSISSIPPI",
    ABREV: "MS",
    NDCODE: 24,
    SHP_PCT: 15,
    FRM_ZIP: "386",
    TO_ZIP: "397",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 23,
    STATE: "MISSOURI",
    ABREV: "MO",
    NDCODE: 25,
    SHP_PCT: 15,
    FRM_ZIP: "630",
    TO_ZIP: "658",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 24,
    STATE: "MONTANA",
    ABREV: "MT",
    NDCODE: 26,
    SHP_PCT: 15,
    FRM_ZIP: "590",
    TO_ZIP: "599",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 25,
    STATE: "NEBRASKA",
    ABREV: "NE",
    NDCODE: 27,
    SHP_PCT: 15,
    FRM_ZIP: "680",
    TO_ZIP: "693",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 26,
    STATE: "NEVADA",
    ABREV: "NV",
    NDCODE: 28,
    SHP_PCT: 15,
    FRM_ZIP: "890",
    TO_ZIP: "898",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 27,
    STATE: "NEW HAMPSHIRE",
    ABREV: "NH",
    NDCODE: 29,
    SHP_PCT: 10,
    FRM_ZIP: "030",
    TO_ZIP: "038",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 28,
    STATE: "NEW JERSEY",
    ABREV: "NJ",
    NDCODE: 2,
    SHP_PCT: 10,
    FRM_ZIP: "070",
    TO_ZIP: "089",
    SHP_DATE: "10/26/1994",
  },
  {
    ID: 29,
    STATE: "NEW MEXICO",
    ABREV: "NM",
    NDCODE: 31,
    SHP_PCT: 15,
    FRM_ZIP: "870",
    TO_ZIP: "884",
    SHP_DATE: "10/6/1994",
  },
  {
    ID: 30,
    STATE: "NEW YORK S",
    ABREV: "NY",
    NDCODE: 3,
    SHP_PCT: 10,
    FRM_ZIP: "090",
    TO_ZIP: "119",
    SHP_DATE: "10/12/1994",
  },
  {
    ID: 31,
    STATE: "NORTH CAROLINA",
    ABREV: "NC",
    NDCODE: 33,
    SHP_PCT: 10,
    FRM_ZIP: "270",
    TO_ZIP: "289",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 32,
    STATE: "NORTH DAKOTA",
    ABREV: "ND",
    NDCODE: 34,
    SHP_PCT: 15,
    FRM_ZIP: "580",
    TO_ZIP: "588",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 33,
    STATE: "OHIO",
    ABREV: "OH",
    NDCODE: 35,
    SHP_PCT: 10,
    FRM_ZIP: "430",
    TO_ZIP: "458",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 34,
    STATE: "OKLAHOMA",
    ABREV: "OK",
    NDCODE: 36,
    SHP_PCT: 15,
    FRM_ZIP: "730",
    TO_ZIP: "749",
    SHP_DATE: "10/26/1994",
  },
  {
    ID: 35,
    STATE: "OREGON",
    ABREV: "OR",
    NDCODE: 37,
    SHP_PCT: 15,
    FRM_ZIP: "970",
    TO_ZIP: "979",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 36,
    STATE: "PENNSYLVANIA",
    ABREV: "PA",
    NDCODE: 1,
    SHP_PCT: 10,
    FRM_ZIP: "150",
    TO_ZIP: "196",
    SHP_DATE: "10/5/1994",
  },
  {
    ID: 37,
    STATE: "RHODE ISLAND",
    ABREV: "RI",
    NDCODE: 39,
    SHP_PCT: 10,
    FRM_ZIP: "028",
    TO_ZIP: "029",
    SHP_DATE: "10/26/1994",
  },
  {
    ID: 38,
    STATE: "SOUTH CAROLINA",
    ABREV: "SC",
    NDCODE: 40,
    SHP_PCT: 10,
    FRM_ZIP: "290",
    TO_ZIP: "299",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 39,
    STATE: "SOUTH DAKOTA",
    ABREV: "SD",
    NDCODE: 41,
    SHP_PCT: 15,
    FRM_ZIP: "570",
    TO_ZIP: "577",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 40,
    STATE: "TENNESSEE",
    ABREV: "TN",
    NDCODE: 42,
    SHP_PCT: 15,
    FRM_ZIP: "370",
    TO_ZIP: "385",
    SHP_DATE: "10/6/1994",
  },
  {
    ID: 41,
    STATE: "TEXAS",
    ABREV: "TX",
    NDCODE: 43,
    SHP_PCT: 15,
    FRM_ZIP: "750",
    TO_ZIP: "799",
    SHP_DATE: "10/6/1994",
  },
  {
    ID: 42,
    STATE: "UTAH",
    ABREV: "UT",
    NDCODE: 44,
    SHP_PCT: 15,
    FRM_ZIP: "840",
    TO_ZIP: "847",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 43,
    STATE: "VERMONT",
    ABREV: "VT",
    NDCODE: 45,
    SHP_PCT: 10,
    FRM_ZIP: "050",
    TO_ZIP: "059",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 44,
    STATE: "VIRGINIA",
    ABREV: "VA",
    NDCODE: 46,
    SHP_PCT: 10,
    FRM_ZIP: "220",
    TO_ZIP: "246",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 45,
    STATE: "WASHINGTON",
    ABREV: "WA",
    NDCODE: 47,
    SHP_PCT: 15,
    FRM_ZIP: "980",
    TO_ZIP: "994",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 46,
    STATE: "WEST VIRGINIA",
    ABREV: "WV",
    NDCODE: 48,
    SHP_PCT: 10,
    FRM_ZIP: "247",
    TO_ZIP: "268",
    SHP_DATE: "10/26/1994",
  },
  {
    ID: 47,
    STATE: "WISCONSIN",
    ABREV: "WI",
    NDCODE: 49,
    SHP_PCT: 15,
    FRM_ZIP: "530",
    TO_ZIP: "549",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 48,
    STATE: "WYOMING",
    ABREV: "WY",
    NDCODE: 50,
    SHP_PCT: 15,
    FRM_ZIP: "820",
    TO_ZIP: "834",
    SHP_DATE: "9/21/1994",
  },
  {
    ID: 49,
    STATE: "PUERTO RICO",
    ABREV: "PR",
    NDCODE: 69,
    SHP_PCT: 0,
    FRM_ZIP: "006",
    TO_ZIP: "009",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 52,
    STATE: "INDIANA",
    ABREV: "IN",
    NDCODE: 14,
    SHP_PCT: 15,
    FRM_ZIP: "460",
    TO_ZIP: "479",
    SHP_DATE: "9/28/1994",
  },
  {
    ID: 58,
    STATE: "CALIFORNIA N",
    ABREV: "CA",
    NDCODE: 6,
    SHP_PCT: 15,
    FRM_ZIP: "940",
    TO_ZIP: "966",
    SHP_DATE: "11/2/1994",
  },
  {
    ID: 60,
    STATE: "NEW YORK N",
    ABREV: "NY",
    NDCODE: 3,
    SHP_PCT: 10,
    FRM_ZIP: "120",
    TO_ZIP: "149",
    SHP_DATE: "9/28/1994",
  },
];
