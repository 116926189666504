import withShipperLogic from "#components/HOC/withShipperLogic";
import _ from "lodash";
import { useState, useEffect, useContext } from "react";
import SelectWarehouseCode from "../components/common/SelectWarehouseCode";
import CurrentShippersList from "../components/shipper/CurrentShippersList";
import GenerateTruck from "../components/shipper/GenerateTruck";
import AddButton from "#components/utils/AddButton";
import Tabs from "#components/utils/Tabs";
import DelmarShippingsList from "#components/delmar/DelmarShippingsList";
import { MasterDataContext } from "#contexts/masterData";
import { AppStateContext } from "#contexts/appState";

const Shipper = ({
  warehouses,
  currentWarehouse,
  submitWarehouseCode,
  removeWarehouse,
  shippers,
  truckIdentifier,
  setTruckIdentifier,
  generateTruck,
  currentTruck,
  generateBarcode,
  resetAllBarcodes,
  printShipperBarcode,
}) => {
  const masterData = useContext(MasterDataContext);
  const appState = useContext(AppStateContext);
  const isDelmarTenant = (subdomain) =>
    ["delmarca", "delmartest", "uat"].includes(subdomain);

  const tabs = ({ tenant, masterData }) => {
    const tabsList = {};

    tabsList["Shipping"] = (
      <div>
        <GenerateTruck
          truckIdentifier={truckIdentifier}
          setTruckIdentifier={setTruckIdentifier}
          generateTruck={generateTruck}
          resetAllBarcodes={resetAllBarcodes}
        />
        <div className="mt-4 flex items-center p-4 text-2xl">
          Current Truck: {currentTruck?.name ? currentTruck.name : "None"}
        </div>
        <CurrentShippersList
          shippers={shippers}
          noValuesText="No shippers. Check the selected warehouse."
          generateBarcode={generateBarcode}
          printShipperBarcode={printShipperBarcode}
        />
      </div>
    );
    if (isDelmarTenant(tenant?.subdomain)) {
      tabsList["Summary"] = (
        <DelmarShippingsList writable={true} tenant={tenant} />
      );
    }

    return tabsList;
  };

  if (!currentWarehouse) {
    return (
      <>
        <SelectWarehouseCode
          warehouses={warehouses}
          onSubmit={submitWarehouseCode}
        />
      </>
    );
  }
  return (
    <>
      <div className="flex w-full items-center justify-center space-x-4 bg-black p-2 text-center text-2xl text-white">
        {warehouses.find((item) => item.id === currentWarehouse)?.name}{" "}
        <AddButton text="Change" onClick={removeWarehouse} styles={["ml-4"]} />
      </div>

      <div className="p-5">
        <Tabs tabs={tabs({ tenant: appState.tenant, masterData })} />
      </div>
    </>
  );
};

export default withShipperLogic(Shipper);
