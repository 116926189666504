// react imports
import { useState, useEffect, useRef } from "react";
// icons import
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
// other imports
import PropTypes from "prop-types";

/**
 * Accordion component that displays content that can be toggled open and closed.
 * When provided with a toggleCallback, it will execute the callback on toggle
 * instead of internally managing its active state. The index prop should be used
 * in conjunction with toggleCallback to identify the accordion instance. If using
 * toggleCallback and index props, ensure to provide a unique key prop to each
 * Accordion instance for optimal rendering performance.
 *
 * @component
 * @param {string} title - The title of the accordion.
 * @param {ReactNode} [titleComponent] - An optional component or element to be used in place of the title text. It should handle its own click events to prevent propagation when necessary.
 * @param {boolean} [isActive=false] - Specifies if the accordion is initially active.
 * @param {string} [scrollBehavior="overflow-y-auto"] - The scroll behavior for the accordion content.
 * @param {Object} [style={}] - The style object that can override the stylings of the container holding the accordion content.
 * @param {ReactNode} children - The content of the accordion.
 * @param {Function} [toggleCallback] - Optional callback to run when the accordion is toggled. Should be used with the index prop.
 * @param {number} [index] - Optional index to identify the accordion instance when using toggleCallback.
 * @returns {ReactNode} The rendered Accordion component.
 *
 * @example
 * // Accordion with callback and index. Remember to include a unique 'key' prop.
 * <Accordion
 *   title="Example"
 *   toggleCallback={handleToggle}
 *   index={0}
 *   key={uniqueKey} // uniqueKey must be unique to each instance. index can be used
 * >
 *   // ...Accordion content
 * </Accordion>
 */
const Accordian = ({
  title,
  titleComponent,
  isActive = false,
  scrollBehavior = "overflow-y-auto",
  style = {},
  children,
  toggleCallback,
  index,
}) => {
  const [active, setActive] = useState(isActive);

  const toggleActive = (e) => {
    if (toggleCallback) {
      toggleCallback(index);
    } else {
      setActive((prev) => !prev);
    }
  };

  const styleObj = {
    minHeight: style.minHeight ? style.minHeight : "100%",
    maxHeight: style.maxHeight ? style.maxHeight : "100%",
    width: style.width ? style.width : "100%",
  };

  return (
    <div
      className={`mb-4 rounded-md border-2 border-gray-300 p-2 shadow-sm duration-500`}
      style={{ width: styleObj.width }}>
      <div
        className={`flex cursor-pointer items-center justify-between ${
          active ? "border-b border-gray-300 pb-2" : ""
        }`}
        onClick={toggleActive}>
        {titleComponent ? (
          titleComponent
        ) : (
          <p className="text-lg font-semibold text-primaryAccent">{title}</p>
        )}
        <div className="cursor-pointer text-xl">
          {active ? (
            <ChevronUpIcon className="h-6 w-6" />
          ) : (
            <ChevronDownIcon className="h-6 w-6" />
          )}
        </div>
      </div>
      {active && (
        <div
          className={`pb-2 pt-2 ${scrollBehavior}`}
          style={{
            minHeight: styleObj.minHeight,
            maxHeight: styleObj.maxHeight,
          }}>
          {children}
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line no-undef
Accordian.propTypes = {
  title: PropTypes.string.isRequired,
  titleComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  isActive: PropTypes.bool,
  scrollBehavior: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  toggleCallback: PropTypes.func,
  index: PropTypes.number,
};

export default Accordian;
