import { useState, useEffect, useContext } from "react";
import { useTempQuery } from "#hooks/useTempQuery";
import { useQuery } from "#hooks/useQuery";
import {
  GET_GROUP,
  GET_RULE_ENGINE_MASTER_DATA,
  GET_STATIONS,
  GET_USERS,
} from "#queries";
import { SAVE_GROUP, DELETE_GROUP } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { MasterDataContext } from "#contexts/masterData";
import { useHistory } from "react-router-dom";

const withRuleFormLogic = (WrappedComponent) => {
  return (props) => {
    const history = useHistory();
    const id =
      history.location.search &&
      history.location.search.split("=").length &&
      history.location.search.split("=")[1];
    const appState = useContext(AppStateContext);
    const masterData = useContext(MasterDataContext);
    const masterDataQuery = useTempQuery(GET_RULE_ENGINE_MASTER_DATA);
    const stationsQuery = useQuery(GET_STATIONS);
    const usersQuery = useQuery(GET_USERS);
    const saveGroupQuery = useQuery(SAVE_GROUP);

    useEffect(() => {
      masterDataQuery.fetchData();
      stationsQuery.fetchData();
      usersQuery.fetchData();
    }, []);

    const [selectedRule, setSelectedRule] = useState({
      name: null,
      conditions: [
        {
          masterField: null,
          comparisonOperator: null,
          value: null,
        },
      ],
      actions: [
        {
          masterField: null,
          value: null,
        },
      ],
      mainCriteria: null,
    });
    const getGroupQuery = useTempQuery(GET_GROUP);

    useEffect(() => {
      if (id) {
        getGroupQuery.fetchData({ id });
      }
    }, []);
    useEffect(() => {
      if (getGroupQuery.loading) {
        appState.setLoading();
      } else {
        if (getGroupQuery.data && getGroupQuery.data.group) {
          setSelectedRule(JSON.parse(JSON.stringify(getGroupQuery.data.group)));
        }
        appState.removeLoading();
      }
    }, [getGroupQuery.loading, getGroupQuery.error, getGroupQuery.data]);

    useEffect(() => {
      const onCompleted = (data) => {
        history.replace("/rules");
      };
      const onError = (error) => {
        /* magic */
        console.log(error);
      };
      if (onCompleted || onError) {
        if (
          onCompleted &&
          !saveGroupQuery.loading &&
          !saveGroupQuery.error &&
          saveGroupQuery.data
        ) {
          onCompleted(saveGroupQuery.data);
        } else if (saveGroupQuery.error && !saveGroupQuery.loading) {
          onError(saveGroupQuery.error);
        }
      }
    }, [saveGroupQuery.loading, saveGroupQuery.data, saveGroupQuery.error]);

    const onChange = (e) => {
      const group = {
        ...selectedRule,
      };

      group[e.target.name] = e.target.value;

      setSelectedRule({ ...group });
    };

    const removeCondition = (index) => {
      let conditions = [...selectedRule.conditions];
      if (conditions.length === 1) {
        return;
      }
      conditions.splice(index, 1);
      setSelectedRule({
        ...selectedRule,
        conditions,
      });
    };

    const removeAction = (index) => {
      let actions = [...selectedRule.actions];
      if (actions.length === 1) {
        return;
      }
      actions.splice(index, 1);
      setSelectedRule({
        ...selectedRule,
        actions,
      });
    };

    const editFieldCondition = (index, field, value) => {
      let conditions = [...selectedRule.conditions];
      let condition = conditions[index];
      console.log(field);
      condition[field] = value;
      console.log(condition);
      setSelectedRule({
        ...selectedRule,
        conditions,
      });
    };

    const editFieldAction = (index, field, value) => {
      let actions = [...selectedRule.actions];
      let action = actions[index];
      console.log(field);
      action[field] = value;
      console.log(action);
      setSelectedRule({
        ...selectedRule,
        actions,
      });
    };

    const editField = (field, value) => {
      setSelectedRule({
        ...selectedRule,
        [field]: value,
      });
    };
    console.log(usersQuery.data?.users);
    return (
      <WrappedComponent
        users={
          usersQuery.data &&
          usersQuery.data.users &&
          usersQuery.data.users.length > 0
            ? usersQuery.data.users.filter(
                (item) =>
                  item.hopstackModules &&
                  item.hopstackModules.includes("Picking"),
              )
            : []
        }
        hopstackModules={
          masterData && masterData.hopstackModules
            ? masterData.hopstackModules
            : []
        }
        stations={stationsQuery.data ? stationsQuery.data.stations : []}
        packingStations={
          stationsQuery.data
            ? stationsQuery.data.stations.entities.filter(
                (item) => item.hopstackModule === "Packing",
              )
            : []
        }
        inboundSortingStations={
          stationsQuery.data
            ? stationsQuery.data.stations.entities.filter(
                (item) => item.hopstackModule === "Sorting - Inbound",
              )
            : []
        }
        outboundSortingStations={
          stationsQuery.data
            ? stationsQuery.data.stations.entities.filter(
                (item) => item.hopstackModule === "Sorting - Outbound",
              )
            : []
        }
        selectedRule={selectedRule}
        setSelectedRule={setSelectedRule}
        saveGroup={() => {
          console.log(selectedRule);
          saveGroupQuery({ ...selectedRule });
          // history.replace("/groups");
        }}
        onChange={onChange}
        masterData={masterDataQuery.data ? masterDataQuery.data.masterData : {}}
        addBlankCondition={() =>
          setSelectedRule({
            ...selectedRule,
            conditions: [
              ...selectedRule.conditions,
              { masterField: null, comparisonOperator: null, value: null },
            ],
          })
        }
        addBlankAction={() =>
          setSelectedRule({
            ...selectedRule,
            actions: [
              ...selectedRule.actions,
              { masterField: null, value: null },
            ],
          })
        }
        removeCondition={removeCondition}
        removeAction={removeAction}
        editFieldCondition={editFieldCondition}
        editField={editField}
        editFieldAction={editFieldAction}
      />
    );
  };
};

export default withRuleFormLogic;
