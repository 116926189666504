import {
  BookmarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  FilterIcon,
  SearchIcon,
  SwitchHorizontalIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useEffect, useState, useContext, useRef } from "react";
import { pluralize } from "../../../utils/helper-functions";
import noFilterIcon from "#static/images/nofilter.png";
import SectionTabs from "./SectionTabs";
import CatalogMainContent from "./CatalogMainContent";
import AutocompleteMultiSelectDropdown from "#components/utils/AutocompleteMultiSelectDropdown";
import SaveNewBookmark from "./SaveNewBookmark";
import { useQuery } from "#hooks/useQuery";
import { GET_CATALOGS, GET_PRODUCTS } from "#queries";
import { UPSERT_CATALOG } from "#mutations";
import SlideOverPanel from "#components/common/SlideOverPanel";
import ManageCatalogForm from "#components/catalogs/ManageCatalogForm";
import EnhancedCategoriesSelect from "./EnhancedCategoriesSelect";
import { AppStateContext } from "#contexts/appState";
import SelectedFilters from "./SelectedFilters";
import CatalogProductActions from "./CatalogProductActions";
import { TrashIcon } from "@heroicons/react/outline";

const ALERT_TIMEOUT_IN_MS = 5000;

const initialCatalog = {
  name: "",
  products: [],
};

const ManageCatalog = ({
  products,
  total,
  filters,
  onChangeFilter,
  submitFilters,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  productTags,
  getCatalog,
  fetchedCatalog,
  setFetchedCatalog,
  pageSavedFilters,
  applyBookmarkFilters,
  saveBookmark,
  setFilterQueryOperator,
  filterQueryOperator,
  productCategories,
  filteringCriteria,
  clearFilters,
  loadFirstTimeData,
  setExportListHeaders,
  handleScroll,
  getSpecificProduct,
  setFilters,
  customers,
  parentProducts,
}) => {
  const actionsOnBookmark = (bookmark) => {
    return [
      {
        name: "Remove",
        value: "delete",
        icon: <TrashIcon className="h-6 w-6" />,
        onClick: () => {
          setCurrentBookmark(null);
          saveBookmark(bookmark, true);
        },
      },
    ];
  };
  const upsertCatalogQuery = useQuery(UPSERT_CATALOG);
  const [componentAlert, setComponentAlert] = useState(null);
  const getCatalogsQuery = useQuery(GET_CATALOGS);
  const getProductsQuery = useQuery(GET_PRODUCTS);
  const catalogs = useRef([]);
  const [filteredCatalogs, setFilteredCatalogs] = useState([]);
  const [savingNewBookmark, setSavingNewBookmark] = useState(null);
  const [showFilterSelection, setShowFilterSelection] = useState(false);
  const [showBookmarkSelection, setShowBookmarkSelection] = useState(false);
  const [currentBookmark, setCurrentBookmark] = useState(null);
  const [showCatalogSelection, setShowCatalogSelection] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(true);
  const [addNewCatalog, setAddNewCatalog] = useState(null);
  const appState = useContext(AppStateContext);
  const filterBoxRef = useRef(null);
  const bookMarkBoxRef = useRef(null);

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (addNewCatalog?.products?.length) {
      fetchCatalogProducts(addNewCatalog.products);
    } else if (addNewCatalog?.products?.length === 0) {
      setAddNewCatalog({
        ...addNewCatalog,
        displayProducts: [],
        parentProducts: [],
      });
    }
  }, [addNewCatalog?.products]);

  const fetchCatalogProducts = async (productIds) => {
    const response = await getProductsQuery.fetchData({
      filters: {
        id: productIds,
      },
    });
    if (response.data) {
      setAddNewCatalog({
        ...addNewCatalog,
        displayProducts: response.data.products.entities,
        parentProducts: response.data.products.parentProducts,
      });
    }
  };

  const fetchInitialData = async () => {
    const catalogsResponse = await getCatalogsQuery.fetchData();

    if (catalogsResponse.data) {
      catalogs.current = catalogsResponse.data.catalogs.entities;
      setFilteredCatalogs(catalogsResponse.data.catalogs.entities);
    }
  };

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      const setFilters = [];
      Object.keys(filters).forEach((key) => {
        if (filters[key] && filters[key].length > 0) {
          const filter = filteringCriteria.find((f) => f.filterName === key);
          // || key === "catalog";
          if (filter) {
            setFilters.push(filter);
          }
        }
      });
      setSelectedFilters(setFilters);
    }
  }, [filters, filteringCriteria]);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleFilterCriteriaClick = (criteria) => {
    setShowFilterSelection(false);
    selectedFilters.push(criteria);
  };

  const handleCatalogSelection = (catalog) => {
    setShowCatalogSelection(false);
    if (catalog) {
      onChangeFilter("catalog", [catalog.name], true);
    } else {
      onChangeFilter("catalog", null, true);
    }
  };

  const handleSavedFilterClick = (savedFilter) => {
    setCurrentBookmark(savedFilter);
    setShowBookmarkSelection(false);

    if (savedFilter && Object.keys(savedFilter.filters).length > 0) {
      applyBookmarkFilters(savedFilter);
    } else {
      clearFilters();
    }
  };

  const handleCreateCatalogClick = () => {
    setAddNewCatalog(initialCatalog);
  };

  useEffect(() => {
    if (showFilterSelection) {
      setShowBookmarkSelection(false);
      setShowCatalogSelection(false);
    }
  }, [showFilterSelection]);

  useEffect(() => {
    if (showBookmarkSelection) {
      setShowFilterSelection(false);
      setShowCatalogSelection(false);
    }
  }, [showBookmarkSelection]);

  useEffect(() => {
    if (showCatalogSelection) {
      setShowBookmarkSelection(false);
      setShowFilterSelection(false);
    }
  }, [showCatalogSelection]);

  const [filterCriteriaTabs, setFilterCriteriaTabs] = useState([
    {
      name: "Any Criteria",
      current: filterQueryOperator !== "$and",
      tooltip: "Products that match any of the selected criteria",
      value: "$or",
    },
    {
      name: "All Criteria",
      current: filterQueryOperator === "$and",
      tooltip: "Products that match all of the selected criteria",
      value: "$and",
    },
  ]);

  useEffect(() => {
    if (filterQueryOperator === "$and" || filterQueryOperator === "$or") {
      onChangeFilter(null, null, true, "onChangeQueryOperator");
    }
  }, [filterQueryOperator]);

  useEffect(() => {
    if (filterCriteriaTabs) {
      const selectedTab = filterCriteriaTabs.find((tab) => tab.current);
      setFilterQueryOperator(selectedTab.value);
    }
  }, [filterCriteriaTabs]);

  useEffect(() => {
    if (currentBookmark && currentBookmark.queryOperator) {
      const selectedTab = filterCriteriaTabs.find(
        (tab) => tab.value === currentBookmark.queryOperator,
      );
      setFilterCriteriaTabs(
        filterCriteriaTabs.map((tab) => {
          tab.current = tab.name === selectedTab.name;
          return tab;
        }),
      );
    }
  }, [currentBookmark]);

  const trySaveBookmark = () => {
    if (selectedFilters.length > 0) {
      if (!currentBookmark) {
        const bookmark = {
          name: "New Bookmark",
          filters,
        };
        setCurrentBookmark(bookmark);
        setSavingNewBookmark(bookmark);
      } else {
        saveBookmark(currentBookmark);
      }
    }
  };

  const submitCatalog = async () => {
    if (addNewCatalog?.name && addNewCatalog?.name?.trim() !== "") {
      const response = await upsertCatalogQuery.fetchData({
        id: addNewCatalog?.id,
        name: addNewCatalog?.name,
        products: addNewCatalog?.products,
      });
      if (response.data) {
        setAddNewCatalog(null);
        fetchInitialData();
        appState.setDismissableAlert(
          response.data.upsertCatalog.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      if (response.error) {
        setComponentAlert({
          msg: response.error.message,
          alertType: "error",
        });
      }
    } else {
      setComponentAlert({
        msg: "Please enter a valid catalog name",
        alertType: "error",
      });
    }
  };

  const getAllSubcategories = (baseCategory, options) => {
    const basePattern = `${baseCategory}/`;
    return options.filter((option) => option.name.startsWith(basePattern));
  };

  const handleCategorySelection = (currentValues, addedValues, options) => {
    let updatedValues = [...currentValues];

    addedValues.forEach((value) => {
      if (!updatedValues.includes(value)) {
        updatedValues.push(value);
      }
      const subcategories = getAllSubcategories(value, options);
      subcategories.forEach((sub) => {
        if (!updatedValues.includes(sub.name)) {
          updatedValues.push(sub.name);
        }
      });
    });

    return updatedValues;
  };

  const handleCategoryDeselection = (currentValues, removedValues, options) => {
    return currentValues.filter(
      (value) =>
        !removedValues.some(
          (removedValue) =>
            value === removedValue || value.startsWith(`${removedValue}/`),
        ),
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterBoxRef.current && !filterBoxRef.current.contains(event.target))
        setShowFilterSelection(false);
      if (
        bookMarkBoxRef.current &&
        !bookMarkBoxRef.current.contains(event.target)
      )
        setShowBookmarkSelection(false);
    };

    if (showFilterSelection || showBookmarkSelection) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilterSelection, showBookmarkSelection]);

  return (
    <>
      {addNewCatalog && (
        <SlideOverPanel
          open={true}
          setOpen={setAddNewCatalog}
          title="Create Catalog"
          containerStyle={"max-w-7xl"}
          subTitle="Manage catalog name and associated products">
          <ManageCatalogForm
            catalog={addNewCatalog}
            setCatalog={setAddNewCatalog}
            submitCatalog={submitCatalog}
            productCategories={productCategories}
            componentAlert={componentAlert}
            setComponentAlert={setComponentAlert}
          />
        </SlideOverPanel>
      )}
      <div className="mt-4 flex space-x-4">
        <div
          className={`hs-catalog-sidebar h-80v transition-transform duration-500 ease-in-out hs-catalog-sidebar-${showLeftSidebar} ${
            showLeftSidebar ? "translate-x-0" : "-translate-x-full"
          }`}>
          <div
            className={`hs-sidebar-section-action absolute -top-3 bg-white transition-transform duration-500 ease-in-out ${
              showLeftSidebar ? "rotate-0" : "rotate-180"
            }`}
            style={{ right: "-1.15rem" }}
            onClick={() => setShowLeftSidebar((prevState) => !prevState)}>
            <SwitchHorizontalIcon className="h-5 w-5" />
          </div>
          {showLeftSidebar && (
            <div className="h-full p-4">
              <div className="text-lg text-[#8A8B8E]">
                Catalog ({catalogs.current.length})
              </div>
              <div className="relative">
                <div className="flex items-center text-xl text-[#111827]">
                  {filters?.catalog?.[0] || "No Catalog"}{" "}
                  {showCatalogSelection ? (
                    <ChevronUpIcon
                      className="h-8 w-8"
                      onClick={() =>
                        setShowCatalogSelection((prevState) => !prevState)
                      }
                    />
                  ) : (
                    <ChevronDownIcon
                      className="h-8 w-8"
                      onClick={() =>
                        setShowCatalogSelection((prevState) => !prevState)
                      }
                    />
                  )}
                </div>
                {showCatalogSelection && (
                  <div className="absolute z-40 mt-2 w-96 rounded-lg border bg-white p-4 text-lg font-semibold">
                    <div>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="search"
                          name="search"
                          id="search"
                          className="block w-full rounded-md border-0 py-1.5 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                          placeholder="Search"
                          onChange={(e) =>
                            setFilteredCatalogs((prev) => {
                              return catalogs.current.filter((catalog) =>
                                catalog.name
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase()),
                              );
                            })
                          }
                        />
                      </div>
                    </div>
                    <div>
                      {filteredCatalogs &&
                        filteredCatalogs.map((catalog, index) => (
                          <div
                            key={index}
                            className="mt-2 flex cursor-pointer p-2"
                            onClick={() => handleCatalogSelection(catalog)}>
                            <div className="flex-1 text-lg font-normal">
                              {catalog.name}
                            </div>
                            <div className="ml-2 text-lg font-light text-gray-500">
                              {pluralize(catalog.products?.length, "Product")}
                            </div>
                          </div>
                        ))}
                      <div className="flex">
                        <button
                          className="mt-4 rounded-lg py-2 pr-6 text-lg text-primaryAccent"
                          onClick={handleCreateCatalogClick}>
                          + Create Catalog
                        </button>

                        <button
                          className="mt-4 rounded-lg py-2 pr-6 text-lg text-primaryAccent"
                          onClick={() => handleCatalogSelection(null)}>
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-2 flex items-center justify-center space-x-4">
                <div className="hs-sidebar-section-title flex-1 text-[#111827]">
                  Filter
                </div>
                <div className="relative">
                  <div
                    className={`hs-sidebar-section-action relative ${
                      showBookmarkSelection &&
                      "hs-sidebar-section-action-active"
                    }`}
                    onClick={() =>
                      setShowBookmarkSelection((prevState) => !prevState)
                    }>
                    <BookmarkIcon className="h-5 w-5" />
                  </div>
                  {showBookmarkSelection && (
                    <div
                      ref={bookMarkBoxRef}
                      className="absolute z-10 mt-2 w-96 rounded-lg border bg-white p-4 text-lg font-semibold shadow-2xl">
                      <div className="flex items-center">
                        <div>Bookmarks</div>
                        <div>
                          <button
                            className="rounded-lg py-2 pr-6 text-lg text-primaryAccent"
                            onClick={() => handleSavedFilterClick(null)}>
                            (Clear)
                          </button>
                        </div>
                      </div>
                      <div>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <div>
                        {pageSavedFilters &&
                          pageSavedFilters?.map((savedFilter, index) => (
                            <div
                              key={index}
                              className="mt-2 flex cursor-pointer justify-between p-2">
                              <div className="flex flex-col text-lg font-normal">
                                <span
                                  onClick={() =>
                                    handleSavedFilterClick(savedFilter)
                                  }>
                                  {savedFilter.name}
                                </span>
                                <span className="text-lg font-light text-gray-500">
                                  {pluralize(
                                    Object.keys(savedFilter.filters).length,
                                    "Filter",
                                  )}
                                </span>
                              </div>
                              <CatalogProductActions
                                actionsOnProduct={actionsOnBookmark}
                                product={savedFilter}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <div
                    className={`hs-sidebar-section-action relative ${
                      showFilterSelection && "hs-sidebar-section-action-active"
                    }`}
                    onClick={() =>
                      setShowFilterSelection((prevState) => !prevState)
                    }>
                    <FilterIcon className="h-5 w-5" />
                  </div>
                  {showFilterSelection && (
                    <div
                      ref={filterBoxRef}
                      className="absolute z-50 mt-2 w-96 rounded-lg border bg-white p-4 text-lg font-semibold shadow-2xl">
                      <div>Add Filter</div>
                      <div>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <div>
                        {filteringCriteria
                          .filter(
                            (i) =>
                              !selectedFilters.find((f) => f.name === i.name),
                          )
                          .map((criteria, index) => (
                            <div
                              key={index}
                              className="mt-2 flex cursor-pointer p-2"
                              onClick={() =>
                                handleFilterCriteriaClick(criteria)
                              }>
                              <div className="flex-1 text-lg font-normal">
                                {criteria.name}
                              </div>
                              <div className="ml-2 text-lg font-light text-gray-500">
                                {pluralize(criteria.options.length, "Item")}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {selectedFilters.length === 0 && (
                <div className="m-auto h-full items-center justify-center pb-10 text-center">
                  <img src={noFilterIcon} />
                  <div className="mt-4 text-center font-inter text-xl font-semibold text-black">
                    No Filters selected
                  </div>
                  <div className="mt-2 text-center font-inter text-lg font-light text-gray-500">
                    Add filters from the filter icon on top and save it for
                    future usage
                  </div>
                  <button
                    className="mt-4 rounded-lg bg-primaryAccent px-6 py-2 text-white"
                    onClick={() => setShowFilterSelection(true)}>
                    Add Filter
                  </button>
                </div>
              )}
              {selectedFilters.length > 0 && (
                <div
                  style={{ height: "90%" }}
                  className="flex-1 grow overflow-y-scroll p-4">
                  <SectionTabs
                    tabs={filterCriteriaTabs}
                    setTabs={setFilterCriteriaTabs}
                  />
                  <div className="mt-4 space-y-4">
                    {selectedFilters.map((filter, index) => (
                      <div key={index}>
                        <div className="text-lg font-normal">{filter.name}</div>
                        <div className="flex items-center">
                          {filter.filterName === "category" ? (
                            <EnhancedCategoriesSelect
                              options={filter.options}
                              preValues={filters[filter.filterName]}
                              onChangeCustomSelect={(values) => {
                                onChangeFilter(filter.filterName, values, true);
                              }}
                            />
                          ) : (
                            <>
                              {" "}
                              <AutocompleteMultiSelectDropdown
                                key={index}
                                options={filter.options}
                                labelKey={"name"}
                                valueKey={"name"}
                                values={filters[filter.filterName]}
                                placeholder={`${
                                  filters[filter.filterName]?.length || 0
                                } selected`}
                                onChange={(values) => {
                                  const previousValues =
                                    filters[filter.filterName] || [];
                                  const addedValues = values.filter(
                                    (x) => !previousValues.includes(x),
                                  );
                                  const removedValues = previousValues.filter(
                                    (x) => !values.includes(x),
                                  );

                                  if (filter.filterName === "category") {
                                    let updatedValues = [...previousValues];

                                    updatedValues = handleCategoryDeselection(
                                      updatedValues,
                                      removedValues,
                                      filter.options,
                                    );

                                    updatedValues = handleCategorySelection(
                                      updatedValues,
                                      addedValues,
                                      filter.options,
                                    );

                                    onChangeFilter(
                                      filter.filterName,
                                      updatedValues,
                                      true,
                                    );
                                  } else {
                                    onChangeFilter(
                                      filter.filterName,
                                      values && values.length ? values : null,
                                      true,
                                    );
                                  }
                                }}
                              />
                            </>
                          )}
                          <XCircleIcon
                            onClick={() => {
                              if (filter.filterName === "category")
                                onChangeFilter(filter.filterName, [], true);
                              else
                                onChangeFilter(filter.filterName, null, true);
                              setSelectedFilters(
                                selectedFilters.filter(
                                  (f) => f.name !== filter.name,
                                ),
                              );
                            }}
                            className="h-12 w-12 pl-2 text-[#8C8C8C]"
                          />
                        </div>
                        <SelectedFilters
                          filters={filters}
                          filter={filter}
                          maxCount={4}
                          onChangeFilter={onChangeFilter}
                        />
                        {/* <div className="filterWrapperCatalog">
                          <div className="appliedFiltersInnerWrapper">
                            {filters[filter.filterName] &&
                              filters[filter.filterName].map(
                                (filterValue, idx) => (
                                  <div
                                    className="appliedFilterWrapper"
                                    key={idx}
                                    title={filter.name}>
                                    <span className="appliedFilterText">
                                      {filterValue || null}
                                    </span>
                                    <span
                                      className="appliedFilterClose"
                                      onClick={() => {
                                        onChangeFilter(
                                          filter.filterName,
                                          filters[filter.filterName].filter(
                                            (item) => item !== filterValue,
                                          ).length > 0
                                            ? filters[filter.filterName].filter(
                                                (item) => item !== filterValue,
                                              )
                                            : null,
                                          true,
                                        );
                                      }}>
                                      <XIcon className="h-6 w-6" />
                                    </span>
                                  </div>
                                ),
                              )}
                          </div>
                        </div> */}
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 flex justify-center">
                    <button
                      className="w-full rounded-lg bg-primaryAccent px-6 py-2 text-white"
                      onClick={trySaveBookmark}>
                      Save Filters
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <CatalogMainContent
          handleCreateCatalogClick={handleCreateCatalogClick}
          handleScroll={handleScroll}
          clearFilters={clearFilters}
          loadFirstTimeData={loadFirstTimeData}
          products={products}
          total={total}
          filters={filters}
          onChangeFilter={onChangeFilter}
          submitFilters={submitFilters}
          selectProduct={selectProduct}
          selectedProducts={selectedProducts}
          selectAllRows={selectAllRows}
          showLeftSidebar={showLeftSidebar}
          allRowsSelected={allRowsSelected}
          getCatalog={getCatalog}
          fetchedCatalog={fetchedCatalog}
          setFetchedCatalog={setFetchedCatalog}
          productCategories={productCategories}
          setExportListHeaders={setExportListHeaders}
          customers={customers}
          getSpecificProduct={getSpecificProduct}
          parentProducts={parentProducts}
        />
        {savingNewBookmark && (
          <SaveNewBookmark
            savingNewBookmark={savingNewBookmark}
            setSavingNewBookmark={setSavingNewBookmark}
            saveBookmark={() => saveBookmark(savingNewBookmark)}
          />
        )}
      </div>
    </>
  );
};

// function deselectNode(selectedData, nodeToDeselect) {
//   // Remove the node to deselect and its children from selectedData
//   selectedData = selectedData.filter(node => !node.startsWith(nodeToDeselect));

//   // Check if the parent nodes are still fully selected
//   const parentNodes = nodeToDeselect.split('/');
//   for (let i = parentNodes.length - 1; i >= 0; i--) {
//     const parentNode = parentNodes.slice(0, i).join('/');
//     if (parentNode && selectedData.some(node => node.startsWith(parentNode + '/'))) {
//       // If any sibling nodes are still selected, break the loop
//       break;
//     } else {
//       // Remove the parent node as it's no longer fully selected
//       selectedData = selectedData.filter(node => node !== parentNode);
//     }
//   }

//   return selectedData;
// }

export default ManageCatalog;
