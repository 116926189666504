import { SearchIcon, XIcon, FilterIcon } from "@heroicons/react/solid";
import { CogIcon } from "@heroicons/react/outline";
import SortDropdown from "#components/common/SortDropdown";
import EditColumns from "../common/EditColumns";
import AddButton from "#components/utils/AddButton";
import ReactTooltip from "react-tooltip";
import Tooltip from "./Tooltip";

const SearchSortFilter = ({
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  onClick,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  choices,
  exportToCsv,
  headers,
  setShowHeaders,
  tableName,
  setShowBatchSettings,
  runAutoBatching,
  clearSelectedOrdersButton: ClearSelectedOrdersButton,
  manualBatchButton: ManualBatchButton,
  searchTooltip,
  onFocus,
  value = filters["keyword"],
}) => {
  return (
    <div className="mb-2 flex items-center justify-center gap-2">
      <div className="flex-1">
        <div className="relative mt-1 rounded-md shadow-sm">
          <div
            className="absolute inset-y-0 left-0 flex items-center pl-3"
            data-tip
            data-for="search-tooltip">
            <SearchIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
          </div>

          <input
            type="text"
            name="keyword"
            id="keyword"
            className="block w-full rounded-md border-gray-300 p-2 pl-12 text-lg focus:outline-none"
            placeholder="Search"
            onChange={onChangeSearchKeyword}
            value={value ? value : ""}
            onKeyDown={(e) => (e.key === "Enter" ? submitFilters() : null)}
            onFocus={onFocus}
          />
          <div
            className="absolute inset-y-0 right-2 flex cursor-pointer items-center pl-3"
            onClick={clearKeyword}>
            <XIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>
      {setShowFilters && (
        <div className="m-1">
          <button
            type="button"
            className={`text-md textWhite-300 inline-flex items-center justify-center rounded border bg-F8F8F8 px-2 py-2 font-montserrat text-lg font-medium text-5F666B outline-none ring-0 focus:outline-none focus:ring-0`}
            onClick={() => setShowFilters(true)}>
            <FilterIcon className="mr-1 h-7 w-7" />
            Filter
          </button>
        </div>
      )}
      {clearFilters && (
        <div className="m-1">
          <button
            type="button"
            className={`text-md textWhite-300 inline-flex items-center justify-center rounded border bg-red-600 px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0`}
            onClick={clearFilters}>
            <XIcon className="mr-1 h-7 w-7" />
            Clear Filters
          </button>
        </div>
      )}

      {choices && choices.length > 0 && (
        <div className="m-1">
          <SortDropdown setSort={setSort} sort={sort} choices={choices} />
        </div>
      )}

      {headers && setShowHeaders && (
        <EditColumns
          headers={headers}
          setShowHeaders={setShowHeaders}
          tableName={tableName}
        />
      )}
      {setShowBatchSettings && (
        <CogIcon
          onClick={() => setShowBatchSettings(true)}
          className="h-10 w-10 cursor-pointer"
        />
      )}
      {runAutoBatching && (
        <AddButton
          text="Run Auto-Batching"
          onClick={runAutoBatching}
          styles={["text-lg"]}
        />
      )}
      {ManualBatchButton}
      {ClearSelectedOrdersButton}
      {searchTooltip && (
        <Tooltip placement="top" id="search-tooltip">
          {searchTooltip}
        </Tooltip>
      )}
    </div>
  );
};

export default SearchSortFilter;
