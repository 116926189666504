import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";

const DetailedView = ({ currentItem, confirmItem, completion }) => {
  return (
    <div className="min-w-max max-w-4xl flex-col items-center justify-center">
      <PageTitle>Product Details</PageTitle>
      <div className="space-y-2 text-xl font-medium text-454A4F">
        <div className="flex h-64 w-full items-center justify-center rounded-md bg-white">
          <img src={currentItem.image} className="max-w-xs" />
        </div>
        <LineItemValue value={currentItem.sku} title="SKU" idx={1} />
        <LineItemValue value={currentItem.quantity} title="Quantity" idx={2} />
        <LineItemValue value={currentItem.name} title="Product Name" idx={3} />
        <LineItemValue value={`${completion}%`} title="Completion" idx={4} />
      </div>

      <div className="mt-20 flex items-center justify-center space-x-6">
        <div
          className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
          onClick={() => confirmItem("CONFIRMED")}>
          Confirm Item
        </div>
        <div
          className="w-64 rounded-md bg-red-500 py-4 text-center text-2xl text-white"
          onClick={() => confirmItem("DAMAGED")}>
          Report Damaged
        </div>
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="flex w-full max-w-4xl space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="flex-1 rounded-br-md rounded-tr-md border bg-white p-4">
      {value}
    </div>
  </div>
);

export default DetailedView;
