import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_QUALITY_CHECKING,
  GET_NEW_QUALITY_CHECKING,
} from "#queries";
import { CONFIRM_QUALITY_CHECKING_ITEM } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";

const withQualityCheckerLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const getCurrentQualityCheckingQuery = useQuery(
      GET_CURRENT_QUALITY_CHECKING,
    );
    const getNewQualityCheckingQuery = useQuery(GET_NEW_QUALITY_CHECKING);
    const [currentQualityChecking, setCurrentQualityChecking] = useState(null);
    const confirmItemQuery = useQuery(CONFIRM_QUALITY_CHECKING_ITEM);

    useEffect(() => {
      getCurrentQualityCheckingQuery.fetchData();
    }, []);

    useEffect(() => {
      if (confirmItemQuery.data) {
        appState.setAlert(
          confirmItemQuery.data.confirmQualityCheckingItem.message,
          "success",
        );
        if (
          confirmItemQuery.data.confirmQualityCheckingItem.message ===
          "Successfully completed QC. Thank you."
        ) {
          setCurrentQualityChecking(null);
        } else {
          getCurrentQualityCheckingQuery.fetchData();
        }
      }
    }, [
      confirmItemQuery.loading,
      confirmItemQuery.error,
      confirmItemQuery.data,
    ]);

    useEffect(() => {
      if (getNewQualityCheckingQuery.data) {
        getCurrentQualityCheckingQuery.fetchData();
        if (getNewQualityCheckingQuery.data.getNewQualityChecking?.message) {
          appState.setAlert(
            getNewQualityCheckingQuery.data.getNewQualityChecking?.message,
            "success",
          );
          getCurrentQualityCheckingQuery.fetchData();
        }
      }
      if (getNewQualityCheckingQuery.error) {
        if (getNewQualityCheckingQuery.error?.message) {
          appState.setAlert(getNewQualityCheckingQuery.error.message, "error");
        }
      }
    }, [
      getNewQualityCheckingQuery.loading,
      getNewQualityCheckingQuery.error,
      getNewQualityCheckingQuery.data,
    ]);

    useEffect(() => {
      if (getCurrentQualityCheckingQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (getCurrentQualityCheckingQuery.data) {
        console.log(
          getCurrentQualityCheckingQuery.data.getCurrentQualityChecking,
        );
        setCurrentQualityChecking(
          getCurrentQualityCheckingQuery.data.getCurrentQualityChecking,
        );
      }
      if (getCurrentQualityCheckingQuery.error) {
        setCurrentQualityChecking(null);
      }
    }, [
      getCurrentQualityCheckingQuery.loading,
      getCurrentQualityCheckingQuery.error,
      getCurrentQualityCheckingQuery.data,
    ]);
    return (
      <WrappedComponent
        currentQualityChecking={currentQualityChecking}
        confirmItem={(status) =>
          confirmItemQuery.fetchData({
            id: currentQualityChecking.id,
            status,
          })
        }
        getNewQualityChecking={() => getNewQualityCheckingQuery.fetchData()}
        loading={getCurrentQualityCheckingQuery.loading}
      />
    );
  };
};

export default withQualityCheckerLogic;
