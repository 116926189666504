// import Modal from "#components/shared/Modal";
import { ExclamationIcon } from "@heroicons/react/outline";
import Modal from "#components/utils/Modal";

const ConfirmationDialog = ({
  confirmation: {
    open,
    positive,
    negative,
    mainText,
    subText,
    positiveText = "Yes, I confirm",
    negativeText = "No, Cancel",
  },
}) => {
  if (!open) return null;
  return (
    <Modal
      title={mainText}
      positiveText={positiveText}
      negativeText={negativeText}
      positiveAction={positive}
      negativeAction={negative}>
      <div className="flex flex-col items-center justify-center">
        <ExclamationIcon
          className="h-20 w-20 rounded-full bg-primaryAccent p-4 font-light text-white"
          aria-hidden="true"
        />
        <h1 className="mt-4 text-center font-montserrat text-2xl text-454A4F">
          {subText}
        </h1>
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;
