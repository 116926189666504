import React, { useState, useEffect, useCallback, useContext } from "react";
import Modal from "#components/utils/Modal";
import Dropdown from "#components/utils/Dropdown";
import { useQuery } from "../../hooks/useQuery";
import { GET_SHOPIFY_LOCATIONS } from "../../queries/index";
import _ from "lodash";
import { AppStateContext } from "../../contexts/appState";

const DEBOUNCE_DELAY = 1000;
const ALERT_DURATION = 5000;

const ConfigureIntegrationModal = ({
  selectedIntegration,
  setSelectedIntegration,
  onClose,
  onSubmit,
}) => {
  const appState = useContext(AppStateContext);
  const getShopifyLocationsQuery = useQuery(GET_SHOPIFY_LOCATIONS);
  const [activeLocations, setShopifyLocations] = useState([]);
  const [enabledLocation, setEnabledLocation] = useState(null);

  const selectedIntegrationId = selectedIntegration?.id;

  const debouncedShopifyFetchData = useCallback(
    _.debounce((integrationId) => {
      if (integrationId) {
        getShopifyLocationsQuery.fetchData({
          integrationId,
          shopifyStoreDomain: null,
          shopifyAccessToken: null,
        });
      }
    }, DEBOUNCE_DELAY),
    [],
  );

  useEffect(() => {
    if (selectedIntegrationId) {
      {
        debouncedShopifyFetchData(selectedIntegrationId);
      }
    }
  }, [selectedIntegrationId]);

  useEffect(() => {
    if (getShopifyLocationsQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (getShopifyLocationsQuery.data) {
      const activeLocations =
        getShopifyLocationsQuery.data.getShopifyLocations.activeLocations;
      const enabledLocations =
        getShopifyLocationsQuery.data.getShopifyLocations.enabledLocation;
      setEnabledLocation(enabledLocations);
      if (!enabledLocations || enabledLocations.length === 0) {
        appState.setAlert(
          `No Shopify Enabled location for this store.`,
          "error",
          ALERT_DURATION,
        );
      }
      if (!activeLocations || activeLocations.length === 0) {
        appState.setAlert(
          `No Shopify location for this store.`,
          "error",
          ALERT_DURATION,
        );
      }

      const filteredShopifyLocations = activeLocations.filter(
        (location) => location !== null,
      );
      setShopifyLocations(filteredShopifyLocations);
    }

    if (getShopifyLocationsQuery.error) {
      setShopifyLocations([]);
      appState.setAlert(
        getShopifyLocationsQuery.error.message,
        "error",
        ALERT_DURATION,
      );
    }
  }, [
    getShopifyLocationsQuery.data,
    getShopifyLocationsQuery.loading,
    getShopifyLocationsQuery.error,
  ]);

  return (
    <Modal
      title={`Configure Integration`}
      negativeAction={onClose}
      positiveAction={onSubmit}>
      <div className="space-y-4">
        {selectedIntegration.integrationType === "Shopify" ? (
          <>
            <div className="flex-1">
              <label className="mb-2 block text-left">
                <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                  Shopify Locations
                </span>
              </label>
              <Dropdown
                placeholder={enabledLocation?.name}
                list={activeLocations}
                labelKey="name"
                valueKey="locationId"
                name="configurations.enabledShopifyLocationIds"
                setSelected={(e) => {
                  setSelectedIntegration({
                    ...selectedIntegration,
                    configurations: {
                      ...selectedIntegration.configurations,
                      enabledShopifyLocationIds: e,
                    },
                  });
                }}
                selectedValue={
                  selectedIntegration?.configurations?.enabledShopifyLocationIds
                }
              />
            </div>
          </>
        ) : (
          // Add other integration types here which requires configurations on Providers page
          <>{/* Add more integrations here as needed */}</>
        )}
      </div>
    </Modal>
  );
};

export default ConfigureIntegrationModal;
