import React from "react";
import PropTypes from "prop-types";
import { Switch } from "antd";
import { twMerge } from "tailwind-merge";

const CustomSwitch = ({
  label,
  checked,
  onChange,
  disabled = false,
  loading = false,
  checkedChildren = null,
  unCheckedChildren = null,
  size = "default",
  //   customColor = "bg-blue-500",
  className = "h-6 w-12 text-primaryAccent",
  labelClasses = "text-base text-gray-500",
  ...rest
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {label && (
        <span className={twMerge("mr-2 " + labelClasses)}>{label}</span>
      )}
      <div
        className={`inline-flex items-center justify-center ${disabled ? "cursor-not-allowed opacity-50" : ""}`}>
        <Switch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          loading={loading}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
          size={size}
          className
          {...rest}
        />
      </div>
    </div>
  );
};

CustomSwitch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  checkedChildren: PropTypes.node,
  unCheckedChildren: PropTypes.node,
  size: PropTypes.oneOf(["small", "default"]),
  className: PropTypes.string,
  customSize: PropTypes.string, // Tailwind class for size of the switch container
};

export default CustomSwitch;
