import withMaterialsLogic from "#components/HOC/withMaterialsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import Tabs from "#components/utils/Tabs";
import MaterialsList from "#components/materials/MaterialsList";
import MaterialForm from "#components/materials/MaterialForm";
import { UserAddIcon } from "@heroicons/react/outline";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";

const Materials = ({
  materials,
  selectedMaterial,
  setSelectedMaterial,
  onChangeMultiSelect,
  fetchMaterial,
  saveMaterial,
  deleteButtonClicked,
  onChange,
  subdomain,
  role,
  writable,
  entity,
  fetchMaterials,
  setPerPage,
  checkPagination,
  submitFilters,
  setSort,
  clearFilter,
}) => {
  if (
    subdomain !== "uat" &&
    subdomain !== "wase" &&
    subdomain !== "ctw" &&
    subdomain !== "tosprep" &&
    subdomain !== "boxette" &&
    subdomain !== "vault" &&
    subdomain !== "weprepfba" &&
    subdomain !== "demo"
  ) {
    return (
      <div className="mt-4 flex h-full items-center justify-center text-2xl">
        Coming Soon! Keep watching this space.
      </div>
    );
  }
  if (!role || role.toLowerCase() !== "admin") {
    return (
      <div className="mt-4 flex h-full items-center justify-center text-2xl">
        Coming Soon! Keep watching this space.
      </div>
    );
  }

  return (
    <div className="p-5">
      <div className="flex items-center">
        <div className="flex-1">
          <PageTitle>Packing Management</PageTitle>
        </div>
        {writable && (
          <div className="mb-4 flex items-center justify-end space-x-2">
            <AddButton
              text="Add Material"
              onClick={() => setSelectedMaterial({})}
              icon={UserAddIcon}
            />
          </div>
        )}
      </div>
      <MaterialsList
        materials={materials}
        editButtonClicked={(e) => {
          fetchMaterial(e.id);
        }}
        deleteButtonClicked={(e) => deleteButtonClicked(e)}
        headers={[
          "Name",
          "Quantity",
          "Weight",
          "Last Updated",
          "Dimensions",
          "Threshold",
          "Action",
        ]}
        noValuesText="No Materials"
        writable={writable}
        entity={entity}
        fetchMaterials={fetchMaterials}
        setPerPage={setPerPage}
        checkPagination={checkPagination}
        submitFilters={submitFilters}
        setSort={setSort}
        clearFilter={clearFilter}
      />
      {selectedMaterial && (
        <MaterialForm
          title={selectedMaterial.id ? "Edit Material" : "Add Material"}
          onChangeMultiSelect={onChangeMultiSelect}
          selectedMaterial={selectedMaterial}
          onChange={onChange}
          onClose={() => setSelectedMaterial()}
          onSubmit={() => saveMaterial(selectedMaterial)}
        />
      )}
    </div>
  );
};

export default withMaterialsLogic(Materials);
