import withStorageManagementLogic from "../components/HOC/withStorageManagementLogic";
import { useState } from "react";
import StorageManagementHome from "../components/storageManagement/StorageManagementHome";
import CreateStorageSection from "../components/storageManagement/CreateStorageSection";

const StorageManagement = ({
  filters,
  onChangeSearchKeyword,
  clearKeyword,
  submitFilters,
  warehouses,
  selectedStorageSection,
  setSelectedStorageSection,
  onChangeStorageSection,
  storageTypes,
  INITIAL_STORAGE_SECTION_STATE,
  locationTree,
  setLocationTree,
  selectedHierarchy,
  setSelectedHierarchy,
  locationTypes,
  onChangeHierarchy,
  onChangeHierarchyLocation,
  submitSaveHierarchy,
  getCurrentHierarchyArray,
  deleteLocation,
  showAlert,
  storageSections,
  setStorageSections,
  storageSectionsSearchString,
  setStorageSectionsSearchString,
  submitCreateStorageSection,
  confirmSubmitCreateStorageSection,
  setConfirmSubmitCreateStorageSection,
  deleteStorageSection,
  storageSectionStatusUpdate,
  setStorageSectionStatusUpdate,
  submitStorageSectionStatusUpdate,
  setLoading,
  buildLocationTreeFromStorageSection,
  submitEditStorageSection,
  locationDeletions,
  setLocationDeletions,
  storageSectionToDelete,
  setStorageSectionToDelete,
  currentHierarchyArray,
  selectedLocations,
  setSelectedLocations,
  shouldSubmitEdit,
  setShouldSubmitEdit,
  submitStorageSectionStatusEdit,
  customers,
  reserveLocation,
  searchReservations,
  locationReservations,
}) => {
  return selectedStorageSection ? (
    <CreateStorageSection
      selectedStorageSection={selectedStorageSection}
      setSelectedStorageSection={setSelectedStorageSection}
      onChangeStorageSection={onChangeStorageSection}
      storageTypes={storageTypes}
      locationTree={locationTree}
      setLocationTree={setLocationTree}
      selectedHierarchy={selectedHierarchy}
      setSelectedHierarchy={setSelectedHierarchy}
      locationTypes={locationTypes}
      onChangeHierarchy={onChangeHierarchy}
      onChangeHierarchyLocation={onChangeHierarchyLocation}
      submitSaveHierarchy={submitSaveHierarchy}
      getCurrentHierarchyArray={getCurrentHierarchyArray}
      deleteLocation={deleteLocation}
      showAlert={showAlert}
      submitCreateStorageSection={submitCreateStorageSection}
      confirmSubmitCreateStorageSection={confirmSubmitCreateStorageSection}
      setConfirmSubmitCreateStorageSection={
        setConfirmSubmitCreateStorageSection
      }
      submitEditStorageSection={submitEditStorageSection}
      locationDeletions={locationDeletions}
      setLocationDeletions={setLocationDeletions}
      currentHierarchyArray={currentHierarchyArray}
      selectedLocations={selectedLocations}
      setSelectedLocations={setSelectedLocations}
      shouldSubmitEdit={shouldSubmitEdit}
      setShouldSubmitEdit={setShouldSubmitEdit}
      submitStorageSectionStatusEdit={submitStorageSectionStatusEdit}
      customers={customers}
      reserveLocation={reserveLocation}
      searchReservations={searchReservations}
      locationReservations={locationReservations}
    />
  ) : (
    <StorageManagementHome
      filters={filters}
      onChangeSearchKeyword={onChangeSearchKeyword}
      clearKeyword={clearKeyword}
      submitFilters={submitFilters}
      warehouses={warehouses}
      locationTypes={locationTypes}
      selectedStorageSection={selectedStorageSection}
      setSelectedStorageSection={setSelectedStorageSection}
      setLocationTree={setLocationTree}
      INITIAL_STORAGE_SECTION_STATE={INITIAL_STORAGE_SECTION_STATE}
      storageSections={storageSections}
      setStorageSections={setStorageSections}
      storageSectionsSearchString={storageSectionsSearchString}
      setStorageSectionsSearchString={setStorageSectionsSearchString}
      deleteStorageSection={deleteStorageSection}
      storageSectionStatusUpdate={storageSectionStatusUpdate}
      setStorageSectionStatusUpdate={setStorageSectionStatusUpdate}
      submitStorageSectionStatusUpdate={submitStorageSectionStatusUpdate}
      setLoading={setLoading}
      buildLocationTreeFromStorageSection={buildLocationTreeFromStorageSection}
      showAlert={showAlert}
      storageSectionToDelete={storageSectionToDelete}
      setStorageSectionToDelete={setStorageSectionToDelete}
      getCurrentHierarchyArray={getCurrentHierarchyArray}
    />
  );
};

export default withStorageManagementLogic(StorageManagement);
