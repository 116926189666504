import { useState, useEffect, useContext } from "react";
import { CubeProvider } from "@cubejs-client/react";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";
import cubejs from "@cubejs-client/core";

const withDashboardLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const masterData = useContext(MasterDataContext);
    const [dashboards, setDashboards] = useState([]);
    const [allGraphs, setAllGraphs] = useState([]);
    const [dashboardGraphMap, setDashboardGraphMap] = useState({});
    const [selectedDashboard, setSelectedDashboard] = useState(null);
    const [selectedGraph, setSelectedGraph] = useState(null);
    const [global, setGlobal] = useState(true);
    const [customers, setCustomers] = useState(null);
    const [warehouses, setWarehouses] = useState(null);
    const [role, setRole] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const { cubeService: API_URL } = localStorage;
    const CUBEJS_TOKEN =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDk2OTY5NDcsImV4cCI6MTY0OTc4MzM0N30.OX0BuUnGOyk0ZcrODwrryRQR8k6W3ehpeG-JGkYI1Eg";
    const cubejsApi = cubejs(CUBEJS_TOKEN, {
      apiUrl: `${API_URL}/cubejs-api/v1`,
    });
    // global.cubejsApi = cubejsApi

    useEffect(() => {
      if (auth?.user?.customers && auth?.user?.warehouses) {
        if (!customers) {
          setCustomers(auth?.user?.customers);
        }
        if (!warehouses) {
          setWarehouses(auth?.user?.warehouses);
        }
      }
      if (auth?.user?.role && auth?.user?.role !== role) {
        setRole(auth.user.role.toLowerCase());
      }
      if (appState?.tenant?.id !== tenantId) {
        setTenantId(appState?.tenant?.id);
      }
    }, [auth, appState]);

    const onChange = (e) => {
      const dashboard = {
        ...selectedDashboard,
      };

      dashboard[e.target.name] = e.target.value;
      setSelectedDashboard(dashboard);
    };

    const onGraphChange = (e) => {
      const graph = {
        ...selectedGraph,
      };

      graph[e.target.name] = e.target.value;
      setSelectedGraph(graph);
    };

    return (
      <CubeProvider cubejsApi={cubejsApi}>
        <WrappedComponent
          {...props}
          dashboards={dashboards}
          graphs={allGraphs}
          dashboardGraphMap={dashboardGraphMap}
          selectedDashboard={selectedDashboard}
          setSelectedDashboard={setSelectedDashboard}
          onChange={onChange}
          global={global}
          setGlobal={setGlobal}
          selectedGraph={selectedGraph}
          setSelectedGraph={setSelectedGraph}
          onGraphChange={onGraphChange}
          tabsKey={"tabs-key"}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
          role={role}
          tenant={appState.tenant}
          sectionPermissions={props.sectionPermissions}
          masterData={masterData}
        />
      </CubeProvider>
    );
  };
};

export default withDashboardLogic;
