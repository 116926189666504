import TextField from "#components/utils/TextField";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { useState } from "react";
import passwordStrengthValidator from "#utils/passwordStrengthValidator";

const SetPasswordBox = ({
  onSubmit,
  onChange,
  values: { password },
  tenant,
  isExpired,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const renderPasswordStrengthCriteria = () => {
    const returnArr = [];

    returnArr.push(
      <>
        <span
          className={`${passwordStrengthValidator.testPasswordLength(
            password,
          )} text-lg`}>
          Password should be a minimum of 8 characters
        </span>{" "}
        <br />
      </>,
    );
    returnArr.push(
      <>
        <span
          className={`${passwordStrengthValidator.testSequentialPassword(
            password,
          )} text-lg`}>
          Password must not contain sequential characters
        </span>{" "}
        <br />
      </>,
    );
    returnArr.push(
      <>
        <span
          className={`${passwordStrengthValidator.testPasswordMatch(
            password,
            confirmPassword,
          )} text-lg`}>
          Passwords must match
        </span>{" "}
        <br />
      </>,
    );

    return returnArr;
  };

  return (
    <div className="xs:10/12 md:6/12 min-w-xl flex max-w-xl flex-col items-center justify-center space-y-6 rounded-2xl bg-white p-10 sm:w-8/12">
      <div className="text-2xl font-medium text-primaryAccent">
        Set Password
      </div>
      <div className="text-2xl font-medium text-454A4F">
        {tenant
          ? `You've been invited to Hopstack Platform by team ${tenant.name}`
          : "You've been invited to Hopstack Platform"}
      </div>
      {isExpired ? (
        <div className="text-xl font-medium text-454A4F">
          Oops! This activation link is either invalid or expired. Please
          contact admin.
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            passwordStrengthValidator.testPassword(password, confirmPassword) &&
              onSubmit(e);
          }}
          className="w-full flex-col items-center justify-center space-y-6"
          noValidate>
          <div className="flex gap-2">
            <TextField
              label="Password"
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder=" "
              onChange={onChange}
              value={password}
            />
            <div className="flex items-center justify-center">
              {showPassword ? (
                <EyeIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="flex gap-2">
            <TextField
              label="Confirm Password"
              placeholder=" "
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                if (e.target.value === password) setDisabled(false);
                else setDisabled(true);
              }}
              value={confirmPassword}
            />
            <div className="flex items-center justify-center">
              {showConfirmPassword ? (
                <EyeIcon
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="mt-2">{renderPasswordStrengthCriteria()}</div>
          <div className="flex w-full items-center justify-center">
            <input
              type="submit"
              className={`${
                disabled ? "bg-gray-500" : "bg-primaryAccent"
              } inline-flex w-64 cursor-pointer justify-center rounded-md border border-transparent p-4 font-montserrat text-2xl font-normal text-textWhite outline-none ring-0 focus:outline-none focus:ring-0`}
              value="Submit"
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default SetPasswordBox;
