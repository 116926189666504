export const renderStatus = (status) => {
  switch (status.toLowerCase()) {
    case "partial":
    case "awaiting_label":
    case "packing-not started":
    case "picking-not started":
    case "paused":
    case "picking-paused":
    case "packing-paused":
    case "prepping-paused":
      return (
        <div className="rounded-full bg-lightBlueBackground px-2 py-1 text-center text-lg text-gray-600">
          {status?.toUpperCase()}
        </div>
      );
    case "unprocessed":
    case "picking-not started":
      return (
        <div className="rounded-full bg-gray-500 px-2 py-1 text-center text-lg text-white">
          {status?.toUpperCase()}
        </div>
      );
    case "mismatch":
      return (
        <div className="rounded-full bg-red-500 px-2 py-1 text-center text-lg text-white">
          {status?.toUpperCase()}
        </div>
      );
    case "in-process":
    case "in_progress":
    case "started":
    case "picking-started":
    case "packing-started":
    case "prepping-started":
      return (
        <div className="rounded-full bg-blue-500 px-2 py-1 text-center text-lg text-white">
          {status?.toUpperCase()}
        </div>
      );
    case "completed":
    case "confirmed_dropoff":
    case "confirmed":
    case "picked":
    case "prepped":
    case "packed":
    case "complete":
    case "packing-completed":
      return (
        <div className="rounded-full bg-green-500 px-2 py-1 text-center text-lg text-white">
          {status?.toUpperCase()}
        </div>
      );
    default:
      return (
        <div className="rounded-full bg-gray-500 px-2 py-1 text-center text-lg text-white">
          {status?.toUpperCase()}
        </div>
      );
  }
};
