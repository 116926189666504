import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import AddButton from "#components/utils/AddButton";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import _ from "lodash";
import { useDimensionUnitAbbreviation } from "../../utils/Metrics";

const BoxTypeForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedBoxType,
  onSubmit,
  warehouses,
  customers,
  onChangeMultiSelect,
  addContents,
  removeContents,
  onChangeContents,
  boxTypes,
}) => {
  const preferredDimensionUnit = useDimensionUnitAbbreviation();
  return (
    <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Box Type Details
          </span>
        </div>
        <div>
          <Dropdown
            // TODO: HOP-6031
            placeholder={"Select Form Factor"}
            list={[
              { name: "Each" },
              { name: "Case" },
              { name: "Carton" },
              { name: "Pallet" },
            ]}
            labelKey="name"
            valueKey="name"
            name="formFactor"
            setSelected={(e) => {
              onChangeDropdown("formFactor", e);
            }}
            selectedValue={selectedBoxType.formFactor}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="name"
            label="Name"
            placeholder=" "
            onChange={onChange}
            value={selectedBoxType.name}
          />
        </div>
        <div className="flex items-center space-x-2">
          <TextField
            type="number"
            id={`length`}
            label={`Length(${preferredDimensionUnit})`}
            placeholder=" "
            onChange={onChange}
            onKeyDown={(e) => lbhValidation(e)}
            value={selectedBoxType.length}
            min="0.00"
            max="9999999.99"
            step="0.01"
          />
          <TextField
            type="number"
            id={`width`}
            label={`Width(${preferredDimensionUnit})`}
            placeholder=" "
            onChange={onChange}
            onKeyDown={(e) => lbhValidation(e)}
            value={selectedBoxType.width}
            min="0.00"
            max="9999999.99"
            step="0.01"
          />
          <TextField
            type="number"
            id={`height`}
            label={`Height(${preferredDimensionUnit})`}
            placeholder=" "
            onChange={onChange}
            onKeyDown={(e) => lbhValidation(e)}
            value={selectedBoxType.height}
            min="0.00"
            max="9999999.99"
            step="0.01"
          />
        </div>
        <div>
          <div id="selectingStations">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Select Warehouses
              </span>
            </label>
            <MultiSelectAutoComplete
              options={warehouses}
              labelKey={"name"}
              valueKey={"id"}
              setValues={(selectedOptions) =>
                onChangeMultiSelect("warehouses", selectedOptions)
              }
              values={selectedBoxType.warehouses || []}
              emptyValuesAccountsForAll={true}
            />
          </div>
        </div>
        <div>
          <div id="selectingStations">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Select Clients
              </span>
            </label>
            <MultiSelectAutoComplete
              options={getFilteredCustomerList(
                selectedBoxType.warehouses,
                customers,
              )}
              labelKey={"name"}
              valueKey={"id"}
              setValues={(selectedOptions) =>
                onChangeMultiSelect("customers", selectedOptions)
              }
              values={selectedBoxType.customers || []}
              emptyValuesAccountsForAll={true}
            />
          </div>
        </div>
        {selectedBoxType.formFactor !== "Each" && (
          <>
            <div>
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Contents
              </span>
            </div>
            {selectedBoxType.contents &&
              selectedBoxType.contents.map((item, idx) => (
                <div className="flex items-center space-x-2" key={idx}>
                  <div>
                    <Dropdown
                      placeholder={"Select Form Factor"}
                      list={
                        boxTypes
                          ? boxTypes.filter(
                              (i) => i.name !== selectedBoxType.name,
                            )
                          : []
                      }
                      labelKey="name"
                      valueKey="name"
                      name="formFactor"
                      setSelected={(e) => {
                        onChangeContents(idx, {
                          target: {
                            name: "formFactor",
                            value: e,
                          },
                        });
                      }}
                      selectedValue={item.formFactor}
                    />
                  </div>
                  <div>
                    <TextField
                      type="number"
                      id="quantity"
                      label="Quantity"
                      placeholder=" "
                      onChange={(e) => onChangeContents(idx, e)}
                      value={parseInt(item.quantity)}
                      name="quantity"
                      min="0"
                    />
                  </div>
                  <span
                    className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                    onClick={() => removeContents(idx)}>
                    Remove
                  </span>
                </div>
              ))}
            <AddButton text="Add Contents" onClick={addContents} />
          </>
        )}
      </div>
    </Modal>
  );
};

const lbhValidation = (e) => {
  let regex = /[0-9]|./;
  if (regex.test(e.key)) {
    let value = parseFloat(e.target.value + e.key);
    if (value > 9999999.99) {
      e.preventDefault();
    }
  } else {
    e.preventDefault();
  }
};

export default BoxTypeForm;
