import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, PrinterIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import SearchSortFilter from "../common/SearchSortFilter";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import Pagination from "#components/common/Pagination";
import { useEffect, useState } from "react";
import QuickFilters from "../common/QuickFilters";

const CurrentShippersList = ({
  shippers,
  noValuesText,
  generateBarcode,
  printShipperBarcode,
}) => {
  const headers = ["Shipper", "Shipping Bag #", "Barcode", "Action"];
  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {shippers.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {shippers &&
            shippers.map((shipper, index) => (
              <tr
                key={shipper.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {shipper.name}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {shipper.currentBin}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {shipper.currentBarcode}
                </td>
                <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  <div className="flex items-center space-x-4">
                    <TableFieldButton
                      onClick={() => generateBarcode(shipper)}
                      text={"Generate"}
                    />

                    <TableFieldButton
                      text={<PrinterIcon className="h-6 w-6" />}
                      onClick={() => printShipperBarcode(shipper)}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default CurrentShippersList;
