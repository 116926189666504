import withVariableConfiguratorLogic from "#components/HOC/withVariableConfiguratorLogic";
import PageTitle from "#components/utils/PageTitle";
import GroupsList from "#components/groups/GroupsList";
import AddButton from "#components/utils/AddButton";
import { useHistory } from "react-router-dom";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import QuickFilters from "#components/common/QuickFilters";
import {
  ArrowRightIcon,
  SelectorIcon,
  TrashIcon,
} from "@heroicons/react/outline";

const VariableConfigurator = ({
  groups,
  masterData,
  customers,
  warehouses,
  onChangeFilter,
  filters,
  availableVariables,
  selectedVariables,
  addVariableToSelected,
  removeVariableFromSelected,
  writable,
}) => {
  const history = useHistory();

  return (
    <div>
      <div className="mb-4 flex items-center">
        <div className="flex-1">
          <PageTitle>Configure Variables</PageTitle>
          {(customers.length > 1 || warehouses.length > 1) && (
            <QuickFilters
              warehouseFilterName={"warehouse"}
              customerFilterName={"customer"}
              customers={customers}
              warehouses={warehouses}
              filters={filters}
              onChangeFilter={onChangeFilter}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
        <VariableList
          variables={availableVariables}
          title="Available Variables"
          toggleVariable={addVariableToSelected}
          writable={writable}
        />
        <VariableList
          variables={selectedVariables}
          title="Selected Variables"
          toggleVariable={removeVariableFromSelected}
          selected={true}
          writable={writable}
        />
      </div>
    </div>
  );
};

const VariableList = ({
  variables,
  title,
  selected,
  toggleVariable,
  writable,
}) => (
  <div className="border border-black bg-white text-center">
    <div className="flex items-center justify-center bg-primaryAccent px-2 py-2">
      <div className="-mr-10 flex-1 font-montserrat text-xl text-white">
        {title}
      </div>
    </div>
    {variables.map((item, idx) => (
      <div key={idx} className="flex items-center p-4 text-xl">
        <div className="flex-1 items-center justify-center text-left text-xl">
          <div>
            {idx + 1}. {item.name}
          </div>
        </div>

        <div className="flex items-center">
          {selected ? (
            <TrashIcon
              className="-mt-2 ml-1 h-8 w-8 cursor-pointer"
              onClick={() => writable && toggleVariable(item)}
            />
          ) : (
            <ArrowRightIcon
              className="-mt-2 ml-1 h-8 w-8 cursor-pointer"
              onClick={() => writable && toggleVariable(item)}
            />
          )}
        </div>
      </div>
    ))}
  </div>
);

export default withVariableConfiguratorLogic(VariableConfigurator);
