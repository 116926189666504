import React, { useContext, useEffect, useState } from "react";
import { AppStateContext } from "#contexts/appState";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import { usePresignedURL } from "#hooks/usePresignedURL";
import Autocomplete from "#components/utils/Autocomplete";

/**
 * A dropdown component for adding images.
 *
 * @component
 * @param {object} props - Component props
 * @param {function} props.onChange - Handler for when the selected image changes
 * @param {object} props.selectedProduct - The currently selected product
 *
 * @returns {React.Element}
 */
const AddImageDropDown = ({ onChange, selectedProduct }) => {
  const uploadTypesList = [{ name: "Upload Image" }, { name: "Add URL" }];
  console.log(selectedProduct);
  const [imageUploadMethod, setImageUploadMethod] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [imageError, setImageError] = useState({});
  const [awsImageKey, setAwsImageKey] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [methodCall, setMethodCall] = useState("GET");

  const appState = useContext(AppStateContext);

  const { presignedURL, setPresignedURL, getPresignedURL } = usePresignedURL();

  useEffect(() => {
    if (selectedProduct.images?.length > 0) {
      if (isValidURL(selectedProduct.images[0].url)) {
        setImageUploadMethod(uploadTypesList[1]);
        setSelectedImage(selectedProduct.images[0].url);
      } else {
        setImageUploadMethod(uploadTypesList[0]);
        setMethodCall("GET");
        getPresignedURL({ key: selectedProduct.images[0].url, method: "GET" });
      }
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (presignedURL) {
      methodCall == "GET"
        ? setSelectedImage(presignedURL)
        : uploadToS3(presignedURL);
    }
  }, [presignedURL]);

  useEffect(() => {
    if (!selectedProduct.customer) {
      appState.setAlert(`Please select Customer`);
      setImageUploadMethod("");
    } else {
      if (!selectedProduct.name && imageUploadMethod != "") {
        appState.setAlert(
          `Please enter ${!selectedProduct.name && "SKU name"}`,
        );
        setImageUploadMethod("");
      } else if (!selectedProduct.images?.length > 0) {
        setSelectedImage("");
        setImageError(null);
        setPresignedURL(null);
      }
    }
  }, [imageUploadMethod]);

  function isValidURL(string) {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  }

  const onChangeFile = (event) => {
    const imageFile = event.target.files[0];

    if (!imageFile) {
      setImageError({ invalidImage: "Please select image." });
      return false;
    }

    if (!imageFile.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
      setImageError({ invalidImage: "Please select valid image." });
      return false;
    }

    setImageError(null);
    setSelectedImage(imageFile);
    setMethodCall("PUT");
    getPresignedURL({
      key: generateKeyForImage({ key: imageFile.name.split(".")[0] }),
      method: "PUT",
    });
  };

  async function checkImage(url) {
    try {
      const res = await fetch(url);
      const buff = await res.blob();
      if (!buff.type.startsWith("image/")) {
        setImageError(true);
        setSelectedImage(null);
      } else {
        setImageError(false);
        setSelectedImage(url);
        onChange({
          target: {
            name: "images",
            value:
              selectedProduct?.images?.length > 0
                ? [{ url, source: "Hopstack" }, ...selectedProduct.images]
                : [{ url, source: "Hopstack" }],
          },
        });
      }
    } catch (error) {
      setImageError(true);
      setSelectedImage(null);
    }
  }

  const removeSpacesFromString = (str) => {
    str = str.replace(/\s/g, "");
    return str;
  };

  const generateKeyForImage = ({ key }) => {
    let hash = Math.random().toString(36).substring(2, 8);
    let keyWithNoSpaces = removeSpacesFromString(key);
    let productNameNoSpaces = removeSpacesFromString(
      selectedProduct?.name ?? "",
    );
    const generatedKey =
      selectedProduct.customer +
      "_" +
      productNameNoSpaces +
      "_" +
      keyWithNoSpaces +
      "_" +
      hash;
    setAwsImageKey(generatedKey);
    return generatedKey;
  };

  const uploadToS3 = (presignedURL) => {
    var requestOptions = {
      method: "PUT",
      body: selectedImage,
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "",
      }),
    };

    appState.setLoading();

    fetch(presignedURL, requestOptions)
      .then((_) => {
        appState.removeLoading();
        onChange({
          target: {
            name: "images",
            value:
              selectedProduct?.images?.length > 0
                ? [
                    { url: awsImageKey, source: "Hopstack" },
                    ...selectedProduct.images,
                  ]
                : [{ url: awsImageKey, source: "Hopstack" }],
          },
        });
      })
      .catch((error) => {
        appState.removeLoading();
        appState.alert("Image Upload failed. Please Try again.");
      });
  };
  return (
    <div>
      <Autocomplete
        options={uploadTypesList}
        labelKey="name"
        valueKey="name"
        onChange={(selectedValue) => {
          setImageUploadMethod(selectedValue);
        }}
        value={imageUploadMethod}
        placeholder={"Add Image"}
      />
      {imageUploadMethod == "Upload Image" ? (
        <div className="flex flex-col gap-2 pb-2">
          <div className="gap-2f flex">
            <input className="" type="file" onChange={onChangeFile} />
          </div>
          {imageError?.invalidImage && (
            <div className="text-red-600">{imageError.invalidImage}</div>
          )}
        </div>
      ) : (
        imageUploadMethod == "Add URL" && (
          <>
            <div className="mt-2 flex h-full items-center gap-2">
              <TextField
                type="text"
                id="imageUrl"
                label="Public URL"
                placeholder=" "
                onChange={(input) => checkImage(input.target.value)}
                value={selectedProduct.productImage}
                name="productImage"
              />
            </div>
            {imageError && (
              <span className="text-sm italic">
                * This doesn’t look like a valid URL for the label file. Please
                check the input.
              </span>
            )}
          </>
        )
      )}
    </div>
  );
};

export default AddImageDropDown;
