import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_BUNDLE_PUTAWAY_TASK,
  SCAN_BUNDLE_PUTAWAY_ITEM,
  COMPLETE_BUNDLE_PUTAWAY_ITEM,
  CANCEL_STANDALONE_PUTAWAY_ITEM,
} from "#mutations";
import { GET_SKU } from "#queries";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";

const withBundlePutawayLogic = (WrappedComponent) => {
  return (props) => {
    // contexts declarations
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);

    // state declarations
    const [currentTask, setCurrentTask] = useState(null);
    const [currentSku, setCurrentSku] = useState(null);
    const [warehouse, setWarehouse] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [putawayAvailableQty, setPutawayAvailableQty] = useState(null);

    // query declarations
    const currentBundlePutawayTaskQuery = useQuery(GET_BUNDLE_PUTAWAY_TASK);
    const scanBundlePutawayItemQuery = useQuery(SCAN_BUNDLE_PUTAWAY_ITEM);
    const completeBundlePutawayItemQuery = useQuery(
      COMPLETE_BUNDLE_PUTAWAY_ITEM,
    );
    const getCurrentProductQuery = useQuery(GET_SKU);
    const cancelItemQuery = useQuery(CANCEL_STANDALONE_PUTAWAY_ITEM);

    const CANCEL_TIME = 5000;

    useEffect(() => {
      if (warehouse && customer) {
      }
    }, [warehouse, customer]);

    // to scan an item (sku / bin)

    useEffect(() => {
      if (scanBundlePutawayItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (scanBundlePutawayItemQuery.data) {
        const response = scanBundlePutawayItemQuery.data.scanBundlePutawayItem;

        setPutawayAvailableQty(response.sumOfParentPutawayTasks);

        if (response?.childTask?.currentItem.productId) {
          getCurrentProductQuery.fetchData({
            id: response?.childTask?.currentItem.productId,
          });
          if (getCurrentProductQuery.data?.specificInventory) {
            setCurrentProduct(getCurrentProductQuery.data.specificInventory);
          }
        }

        if (response?.childTask?.type === "BUNDLE_PUTAWAY_CHILD") {
          // when they initially scan a sku with form factor, we receive the task in the response.
          setCurrentTask(response?.childTask);
          setCurrentSku(response?.childTask?.currentItem);
        } else {
          appState.setAlert(
            scanBundlePutawayItemQuery.data.scanBundlePutawayItem.message,
            "success",
            2000,
          );
        }
      }

      if (scanBundlePutawayItemQuery.error) {
        appState.removeLoading();
        appState.setAlert(
          scanBundlePutawayItemQuery.error.message,
          "danger",
          CANCEL_TIME,
        );
      }
    }, [
      scanBundlePutawayItemQuery.loading,
      scanBundlePutawayItemQuery.data,
      scanBundlePutawayItemQuery.error,
    ]);

    useEffect(() => {
      if (getCurrentProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getCurrentProductQuery.data) {
        setCurrentProduct(getCurrentProductQuery.data.specificInventory);
      }

      if (getCurrentProductQuery.error) {
        setCurrentProduct(null);
      }
    }, [
      getCurrentProductQuery.loading,
      getCurrentProductQuery.data,
      getCurrentProductQuery.error,
    ]);
    // to complete bundle putaway item.
    useEffect(() => {
      if (completeBundlePutawayItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (
        completeBundlePutawayItemQuery.data &&
        completeBundlePutawayItemQuery.data.completeBundlePutawayItem.message
      ) {
        appState.setAlert(
          completeBundlePutawayItemQuery.data.completeBundlePutawayItem.message,
          "success",
          2000,
        );

        setCurrentTask(null);
        setCurrentSku(null);
        setCurrentProduct(null);
      }

      if (completeBundlePutawayItemQuery.error) {
        appState.removeLoading();
        appState.setAlert(
          completeBundlePutawayItemQuery.error.message,
          "danger",
          CANCEL_TIME,
        );
      }
    }, [
      completeBundlePutawayItemQuery.loading,
      completeBundlePutawayItemQuery.data,
      completeBundlePutawayItemQuery.error,
    ]);
    // cancelItemQuery - when we want to cancel the current putaway.
    useEffect(() => {
      if (cancelItemQuery.loading) {
        appState.setLoading();
      }

      if (cancelItemQuery.error) {
        appState.setAlert(cancelItemQuery.error.message, "danger", CANCEL_TIME);
        appState.removeLoading();
      }
    }, [cancelItemQuery.error, cancelItemQuery.loading]);

    const submitWarehouseCustomerCode = ({ warehouse, customer }) => {
      if (!warehouse || !warehouse.value) {
        return appState.setAlert(
          `Please provide a warehouse`,
          "danger",
          CANCEL_TIME,
        );
      }
      if (!customer || !customer.value) {
        return appState.setAlert(
          `Please provide a customer`,
          "danger",
          CANCEL_TIME,
        );
      }
      setWarehouse(warehouse.value);
      setCustomer(customer.value);
    };

    const cancelItem = () => {
      appState.showConfirmation(
        "Confirm",
        "This action cannot be undone.",
        () => {
          appState.hideConfirmation();
          setCurrentTask(null);
          setCurrentSku(null);
          setCurrentProduct(null);
        },
        appState.hideConfirmation,
      );
    };

    const confirmItem = () => {
      completeBundlePutawayItemQuery.fetchData({
        childTask: currentTask.id,
        quantity: currentSku.scannedQuantity,
      });
    };

    const scanBarcode = ({ barcode, formFactor }) => {
      if (!barcode || barcode.trim() === "") {
        return appState.setAlert(
          `Please provide a valid barcode`,
          "danger",
          CANCEL_TIME,
        );
      }
      if (!formFactor) {
        return appState.setAlert(
          `Please provide a valid form factor`,
          "danger",
          CANCEL_TIME,
        );
      }

      return scanBundlePutawayItemQuery.fetchData({
        barcode: barcode.trim(),
        warehouse,
        customer,
        formFactor,
        childTask: currentTask?.id ?? null,
      });
    };

    const singlePutawayItem = async ({ barcode, formFactor }) => {
      const response = await scanBundlePutawayItemQuery.fetchData({
        barcode,
        customer,
        warehouse,
        formFactor,
        childTask: currentTask?.id ?? null,
      });

      if (response?.data?.scanBundlePutawayItem) {
        setCurrentSku(
          response?.data?.scanBundlePutawayItem?.childTask?.currentItem,
        );
        setCurrentTask(response?.data?.scanBundlePutawayItem?.childTask);
      }
      if (
        response?.data?.scanBundlePutawayItem.childTask.currentItem.productId
      ) {
        await getCurrentProductQuery.fetchData({
          id: response.data.scanBundlePutawayItem.childTask.currentItem
            .productId,
        });
        if (getCurrentProductQuery.data?.specificInventory) {
          setCurrentProduct(getCurrentProductQuery.data.specificInventory);
        }
      }
    };

    return (
      <WrappedComponent
        currentTask={currentTask}
        currentSku={currentSku}
        setCurrentSku={setCurrentSku}
        scanBarcode={scanBarcode}
        confirmItem={confirmItem}
        customer={customer}
        warehouse={warehouse}
        customers={
          auth.user && auth.user.customersList ? auth.user.customersList : []
        }
        warehouses={
          auth.user && auth.user.warehousesList ? auth.user.warehousesList : []
        }
        submitWarehouseCustomerCode={submitWarehouseCustomerCode}
        appState={appState}
        singlePutawayItem={singlePutawayItem}
        cancelItem={cancelItem}
        currentProduct={currentProduct}
        putawayAvailableQty={putawayAvailableQty}
        setPutawayAvailableQty={setPutawayAvailableQty}
      />
    );
  };
};

export default withBundlePutawayLogic;
