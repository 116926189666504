import Barcode from "react-barcode";

const FourBySixLandscapeLabel = ({ id, value }) => (
  <div
    style={{
      width: 384,
      height: 576,
      textAlign: "center",
      backgroundColor: "#fff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      paddingLeft: 5,
      paddingRight: 5,
      top: 200,
      left: 0,
      zIndex: 50,
      flex: 1,
    }}
    id={`${id}_4x6-Landscape`}>
    <div style={{ transform: "rotate(90deg)" }}>
      <Barcode
        value={value}
        width={2.6}
        height={60}
        fontSize={calculateFontSize(value)}
      />
    </div>
  </div>
);

//TODO: This is a hack. Need to find a better way to calculate the font size dynamically which works for all orientations and paper sizes.
//MAXIMUM LOCATION SIZE SUPPORTED IS 14 CHARACTERS. EG. "G-PP-R1-C06-01"
const calculateFontSize = (value) => {
  const DEFAULT_FONTSIZE = 42;
  if (value.length < 10) {
    return 90;
  } else if (value.length < 15) {
    return 65;
  }
  return DEFAULT_FONTSIZE;
};

export default FourBySixLandscapeLabel;
