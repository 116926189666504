import { useState, useEffect, useRef } from "react";
import { XIcon } from "@heroicons/react/outline";
import _ from "lodash";

/**
 * Renders a multiselect component with text input that filters the provided options
 * @param {Array<*>} values - An array of values representing the unique property of the option object that server code consumes.
 * @param {Array<Object>} options - An array of objects representing options provided
 * @param {string} labelKey - A string specifying what property in the option object to be shown in UI
 * @param {string} valueKey - A string specifying what unique property in the option object to be considered for value
 * @param {boolean} disabled - If set true, the whole component is disabled and acts unresponsive.
 * @param {Function} setValues - A function passed from consuming component to set values in respective higher order function.
 * @param {Function} onChange - If provided, fetches real time data when user types in to search options, This function is passed from higher order function that retrieves new data
 * @param {boolean} useLocalLabels - Recommended to send true value if onChange function is passed, Otherwise the selected values displayed will disappear when user searches something for which real time data retrieval is needed. eg: products. Utilizes the selectedLabels maintained in the state locally to display selected values in UI.
 * @param {boolean} emptyValuesAccountsForAll - If set true this component assumes empty array passed for values means All Options are selected. use case: In user form, leaving the field empty means giving access to all current values and the ones created in future. (warehouses, clients for example)
 * @returns {JSX.Element}
 */
const MultiSelectAutoComplete = ({
  values: selectedValues,
  options: providedOptions,
  labelKey,
  valueKey,
  disabled,
  setValues,
  onChange,
  onKeyDown,
  useLocalLabels = false,
  emptyValuesAccountsForAll = false,
}) => {
  selectedValues = selectedValues || [];
  providedOptions = providedOptions || [];
  labelKey = labelKey || "name";
  valueKey = valueKey || "id";
  if (!setValues) {
    setValues = () => {
      console.log("setting value");
    };
  }

  const [searchText, setSearchText] = useState("");
  const [selectedLabels, setLabels] = useState(selectedValues || []);
  const [showOptions, setShowOptions] = useState(false);
  const [allOptionsSelected, setAllOptionsSelected] = useState(() => {
    return emptyValuesAccountsForAll
      ? selectedValues.length === 0
      : selectedValues.length > 0 &&
          selectedValues.length === providedOptions.length;
  });
  const [cursor, setCursor] = useState(-1);
  const ref = useRef();

  /**
   * Calls the onChange function if provided with the updated search text when it changes to fetch real time data.
   * @param {string} searchText - The current search text value.
   * @param {Function} onChange - The function to be called when the search text changes.
   */
  useEffect(() => {
    if (typeof onChange === "function") onChange(searchText);
  }, [searchText]);

  /**
   * Adds event listeners for focusin and mousedown events to close the options and reset the cursor when the click is outside the specified ref.
   * @param {RefObject} ref - The ref object used to check if the click is outside the specified element.
   * @returns {Function} The cleanup function to remove the event listeners.
   */
  useEffect(() => {
    const listener = (e) => {
      if (!ref || !ref.current || !ref.current.contains(e.target)) {
        setShowOptions(false);
        setCursor(-1);
      }
    };

    document.addEventListener("mousedown", listener);

    // Cleanup function to remove the event listeners.
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  const unselectedOptions = providedOptions.filter(
    (option) => !selectedValues.includes(option[valueKey]),
  );
  const filteredOptions = (function () {
    if (!emptyValuesAccountsForAll && allOptionsSelected) {
      // incase emptyValluesAccountsForAll is false and all options are already selected,
      // we provide text based filter from providedOptions
      return providedOptions
        .filter((option) =>
          option[labelKey].toLowerCase().includes(searchText.toLowerCase()),
        )
        .sort(
          (a, b) =>
            b.frequency - a.frequency || a[labelKey].localeCompare(b[labelKey]),
        );
    }
    // otherwise only search within the unselected options
    return unselectedOptions
      .filter((option) =>
        option[labelKey].toLowerCase().includes(searchText.toLowerCase()),
      )
      .sort(
        (a, b) =>
          b.frequency - a.frequency || a[labelKey].localeCompare(b[labelKey]),
      );
  })();

  const select = (selectedValue) => {
    if (!allOptionsSelected) {
      setValues([...selectedValues, selectedValue]);
      setLabels([
        ...selectedLabels,
        providedOptions.find((o) => o[valueKey] === selectedValue)[labelKey],
      ]);
      setSearchText("");
    }
  };

  /**
   * Handles changes in the search text input field.
   *
   * @param {string} text - The new search text value.
   */
  const handleChange = (text) => {
    setSearchText(text);
    if (onKeyDown) {
      onKeyDown(text);
    }
    setCursor(-1);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const removeItem = (removedValue) => {
    const currentValues = [...selectedValues];
    const currentLabels = [...selectedLabels];
    const foundIndex = _.findIndex(
      selectedValues,
      (value) => value === removedValue,
    );
    if (foundIndex !== -1) {
      currentValues[foundIndex] = undefined;
      currentLabels[foundIndex] = undefined;
      setValues([..._.compact(currentValues)]);
      setLabels([..._.compact(currentLabels)]);
    }
  };

  const handleAllOptions = (type) => {
    if (type === "SELECT_ALL" && !allOptionsSelected) {
      if (!emptyValuesAccountsForAll) {
        // set all values, labels from the providedOptions
        setValues(providedOptions.map((option) => option[valueKey]));
        setLabels(providedOptions.map((option) => option[labelKey]));
      } else {
        setValues([]);
        setLabels([]);
      }
      return setAllOptionsSelected(true);
    }
    if (type === "REMOVE_ALL" || type === "CLEAR_ALL") {
      setValues([]);
      setLabels([]);
      return setAllOptionsSelected(false);
    }
  };

  const moveCursorDown = () => {
    if (cursor < filteredOptions.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e) => {
    switch (e.key) {
      case "ArrowUp":
        moveCursorUp();
        break;
      case "ArrowDown":
        moveCursorDown();
        break;
      case "Enter":
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor][valueKey]);
        }
        break;
    }
  };

  const SelectedValues = () => {
    if (allOptionsSelected) {
      return (
        <div className="m-1 inline-block rounded-full bg-primaryAccent text-white">
          <div className="flex h-8 items-center justify-between">
            <div className="flex-2 pl-3 pr-5">All Values</div>
            <XIcon
              className="mr-2 h-5 w-5 cursor-pointer"
              onClick={() => handleAllOptions("REMOVE_ALL")}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="pr-6">
        {useLocalLabels
          ? selectedLabels.map((label, i) => (
              <div
                className="m-1 inline-block rounded-full bg-primaryAccent text-white"
                key={label.toString() + i.toString()}>
                <div className="flex h-8 items-center justify-between">
                  <div className="flex-2 pl-3 pr-5">{label}</div>
                  <XIcon
                    className="mr-2 h-5 w-5 cursor-pointer"
                    onClick={() => removeItem(selectedValues[i])}
                  />
                </div>
              </div>
            ))
          : _.filter(providedOptions, (option) =>
              selectedValues.includes(option[valueKey]),
            ).map((option) => (
              <div
                className="m-1 inline-block rounded-full bg-primaryAccent text-white"
                key={option[valueKey]}>
                <div className="flex h-8 items-center justify-between">
                  <div className="flex-2 pl-3 pr-5">{option[labelKey]}</div>
                  <XIcon
                    className="mr-2 h-5 w-5 cursor-pointer"
                    onClick={() => removeItem(option[valueKey])}
                  />
                </div>
              </div>
            ))}
      </div>
    );
  };

  const FilteredOptions = () => {
    if (filteredOptions.length === 0) {
      return (
        <li className="border-dropdown-item-bottom-border relative cursor-default select-none border-b bg-primaryAccent py-4 pl-4 pr-4 text-white">
          No results
        </li>
      );
    }

    // flag to decide whether to show select all option in the dropdown or not.
    const showSelectAll =
      !emptyValuesAccountsForAll && allOptionsSelected
        ? filteredOptions.length === providedOptions.length
        : filteredOptions.length ===
          providedOptions.length - selectedValues.length;

    let classNames = `${
      allOptionsSelected
        ? "bg-disabledBackground text-disabledTextColor cursor-not-allowed"
        : "bg-primaryAccent text-white cursor-default hover:bg-2C7695"
    } select-none relative py-4 pl-4 pr-4 border-dropdown-item-bottom-border border-b`;

    return (
      <>
        {showSelectAll && (
          <li
            className={`${classNames} rounded-t-lg`}
            key={"selectAllValues"}
            onClick={() => handleAllOptions("SELECT_ALL")}>
            {"Select All"}
          </li>
        )}
        {filteredOptions.map((option, i, arr) => {
          let classNamesCopy = classNames;

          if (i === 0 && !showSelectAll) classNamesCopy += "rounded-t-lg";
          else if (i === arr.length) classNamesCopy += "rounded-b-lg";
          else if (i === 0 && arr.length === 1) classNamesCopy += "rounded-lg";
          else classNamesCopy += "";

          if (cursor === i) {
            classNamesCopy += " bg-2C7695 selected";
          }

          return (
            <li
              className={classNamesCopy}
              key={option[valueKey]}
              onClick={() => select(option[valueKey])}>
              {option[labelKey]}
            </li>
          );
        })}
      </>
    );
  };

  return (
    <div
      aria-disabled={disabled}
      className={
        disabled ? "pointer-events-none relative opacity-50" : "relative"
      }
      ref={ref}>
      {/* container which shows selectedValues */}
      <div className="relative w-full overflow-visible rounded-lg border-2 border-2C7695 outline">
        <SelectedValues />
        <input
          type="text"
          className="inline-block appearance-none border-0 bg-transparent p-4 font-montserrat text-lg focus:border-0 focus:outline-none focus:ring-0"
          onKeyDown={handleNav}
          onFocus={() => setShowOptions(true)}
          onChange={(e) => handleChange(e.target.value)}
          value={searchText}
          placeholder="Start Typing"
        />
        {selectedValues.length > 0 && (
          <div className="absolute right-0 top-0 pt-2">
            <XIcon
              className="mr-2 h-5 w-5 cursor-pointer"
              onClick={() => handleAllOptions("CLEAR_ALL")}
            />
          </div>
        )}
      </div>

      {/* dropdown to select providedOptions */}
      <ul
        className={`absolute w-full rounded-lg shadow-lg ${
          !showOptions && "hidden"
        } bg-sidebar-blue z-50 max-h-64 select-none overflow-auto`}>
        <FilteredOptions />
      </ul>
    </div>
  );
};

export default MultiSelectAutoComplete;
