import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { Country, State } from "country-state-city";

import { AppStateContext } from "#contexts/appState";
import { CREATE_CRM_CUSTOMER } from "#mutations";

import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";

import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import { Navbar } from "./NavBar";
import { AddUserFooter } from "./AddUserFooter";

const ALERT_VISIBILITY_IN_MS = 5000;

export const CreateCustomer = ({ onCancel }) => {
  const appState = useContext(AppStateContext);
  const [steps, setSteps] = useState([
    {
      tag: "accountInfo",
      name: "Account Info",
      href: "#",
      id: "01",
      status: "current",
      visible: true,
    },
    {
      tag: "shippingInfo",
      name: "Shipping Info",
      href: "#",
      id: "02",
      status: "upcoming",
      visible: true,
    },
  ]);
  const [selectedStep, setSelectedStep] = useState(steps[0]);
  const [newCustomer, setNewCustomer] = useState({
    accountAddress: { country: "US" },
  });
  const [altEmails, setAltEmails] = useState([]);
  const [altPhones, setAltPhones] = useState([]);
  const [useAccountAddress, setUseAccountAddress] = useState(true);
  const [addNewAddress, setAddNewAddress] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(
    newCustomer?.accountAddress?.country,
  );
  const [filteredStates, setFilteredStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [showSubmit, setShowSubmit] = useState(false);
  const createCRMCustomerQuery = useQuery(CREATE_CRM_CUSTOMER);

  useEffect(() => {
    const updateStates = () => {
      if (selectedCountry) {
        const states = State.getStatesOfCountry(selectedCountry);
        setFilteredStates(states);
        const stateIsValid = states.some(
          (state) => state.isoCode === selectedState,
        );
        if (!stateIsValid) {
          setSelectedState("");
        }
      } else {
        setFilteredStates([]);
        setSelectedState("");
      }
    };

    updateStates();
  }, [selectedCountry]);

  const setNextStep = () => {
    if (selectedStep.tag !== "shippingInfo") {
      if (shouldDisplayAccountAddress()) {
        setNewCustomer((prevCustomer) => {
          const accountAddress = prevCustomer?.accountAddress;
          const shippingAddresses = prevCustomer?.shippingAddresses || [];

          const accountAddressExists = shippingAddresses.some((addr) =>
            isSameAddress(addr, accountAddress),
          );

          return {
            ...prevCustomer,
            shippingAddresses: accountAddressExists
              ? [...shippingAddresses]
              : [accountAddress, ...shippingAddresses],
          };
        });
      }
    } else {
      setNewCustomer((prevCustomer) => ({ ...prevCustomer }));
    }
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const nextStep = copiedSteps.find((_, idx) => idx === foundIdx + 1);
    if (!nextStep.visible) {
      foundIdx += 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
      setShowSubmit(foundIdx === copiedSteps.length - 2);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const prevStep = copiedSteps.find((_, idx) => idx === foundIdx - 1);
    if (!prevStep.visible) {
      foundIdx -= 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setShowSubmit(false);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  const addAltEmail = () => {
    setAltEmails([...altEmails, ""]);
  };

  const removeAltEmail = (index) => {
    setAltEmails(altEmails.filter((_, i) => i !== index));
  };

  const handleAltEmailChange = (value, index) => {
    const updated = [...altEmails];
    updated[index] = value;
    setAltEmails(updated);
  };

  const addAltPhone = () => {
    setAltPhones([...altPhones, ""]);
  };

  const removeAltPhone = (index) => {
    setAltPhones(altPhones.filter((_, i) => i !== index));
  };

  const handleAltPhoneChange = (value, index) => {
    const updated = [...altPhones];
    updated[index] = value;
    setAltPhones(updated);
  };

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(newCountry));

    setNewCustomer((prev) => ({
      ...prev,
      accountAddress: {
        ...prev?.accountAddress,
        country: newCountry,
      },
    }));
  };

  const handleStateChange = (newState) => {
    setSelectedState(newState);

    setNewCustomer((prev) => ({
      ...prev,
      accountAddress: {
        ...prev?.accountAddress,
        state: newState,
      },
    }));
  };

  // Handler to add a new shipping address
  const handleAddNewAddress = () => {
    setNewCustomer((prevCustomer) => ({
      ...prevCustomer,
      shippingAddresses: [
        ...(prevCustomer?.shippingAddresses || []),
        addNewAddress,
      ],
    }));
    setAddNewAddress(null);
  };

  const shouldDisplayAccountAddress = () => {
    if (!newCustomer || !newCustomer.accountAddress) {
      return false;
    }

    const { firstName, lastName, accountAddress } = newCustomer;

    const result =
      Boolean(firstName) &&
      Boolean(lastName) &&
      Boolean(accountAddress.line1) &&
      Boolean(accountAddress.state) &&
      Boolean(accountAddress.country) &&
      Boolean(accountAddress.city) &&
      Boolean(accountAddress.zip);

    return result;
  };

  function isSameAddress(addr1, addr2) {
    if (!addr1 || !addr2) return false;
    return (
      addr1?.line1 === addr2.line1 &&
      addr1?.line2 === addr2.line2 &&
      addr1?.city === addr2.city &&
      addr1?.zip === addr2?.zip &&
      addr1?.state === addr2?.state &&
      addr1?.country === addr2?.country
    );
  }

  const validateCreateCRMCustomer = () => {
    if (!newCustomer) {
      return appState.setAlert(
        "Please enter customer details",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    if (!newCustomer.firstName) {
      return appState.setAlert(
        "Please enter first name",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    if (!newCustomer.lastName) {
      return appState.setAlert(
        "Please enter last name",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    if (!newCustomer?.accountAddress) {
      return appState.setAlert(
        "Please enter account address",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    if (
      !newCustomer?.shippingAddresses ||
      newCustomer?.shippingAddresses?.length === 0
    ) {
      return appState.setAlert(
        "Please enter shipping address",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    return true;
  };

  const createCRMCustomer = async () => {
    if (!validateCreateCRMCustomer()) {
      return;
    }
    const {
      firstName,
      lastName,
      shippingAddresses,
      accountAddress,
      ...otherDetails
    } = newCustomer;

    const updatedAccountAddress = {
      ...accountAddress,
      firstName,
      lastName,
    };
    const updatedShippingAddresses =
      shippingAddresses?.map((address) => ({
        ...address,
        firstName,
        lastName,
      })) || [];

    const crmCustomerInput = {
      ...otherDetails,
      firstName,
      lastName,
      accountAddress: updatedAccountAddress,
      shippingAddresses: updatedShippingAddresses,
    };

    createCRMCustomerQuery.fetchData({ crmCustomerInput });
  };

  useEffect(() => {
    if (createCRMCustomerQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (createCRMCustomerQuery.error) {
      appState.setAlert(
        createCRMCustomerQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    } else if (createCRMCustomerQuery.data) {
      setNewCustomer(null);
      onCancel(false);
    }
  }, [
    createCRMCustomerQuery.error,
    createCRMCustomerQuery.data,
    createCRMCustomerQuery.loading,
  ]);

  return (
    <div className="relative flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white p-4">
      <header className="space-y-2 py-4">
        <Navbar steps={steps} />
      </header>
      <main>
        {selectedStep?.tag === "accountInfo" && (
          <div className="space-y-6">
            <div className="space-y-1">
              <label
                htmlFor="businessName"
                className="block text-sm font-medium text-gray-700">
                Business Name
              </label>
              <input
                name="businessName"
                id="businessName"
                className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                placeholder="John Enterprises"
                value={newCustomer?.businessName}
                onChange={(e) => {
                  setNewCustomer((prevCustomer) => ({
                    ...prevCustomer,
                    businessName: e.target.value || null,
                  }));
                }}
              />
            </div>
            <div className="-mx-2 flex flex-wrap">
              {/* First Name Field */}
              <div className="w-1/2 space-y-1 px-2">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700">
                  First Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  required
                  className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                  placeholder="Doe"
                  value={newCustomer?.firstName}
                  onChange={(e) => {
                    setNewCustomer((prevCustomer) => ({
                      ...prevCustomer,
                      firstName: e.target.value,
                    }));
                  }}
                />
              </div>

              {/* Last Name Field */}
              <div className="w-1/2 space-y-1 px-2">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700">
                  Last Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  required
                  className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                  placeholder="John"
                  value={newCustomer?.lastName}
                  onChange={(e) => {
                    setNewCustomer((prevCustomer) => ({
                      ...prevCustomer,
                      lastName: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            {/* Email Field */}
            <div className="space-y-1">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                placeholder="XYZ123@gmail.com"
                value={newCustomer?.email}
                onChange={(e) => {
                  setNewCustomer((prevCustomer) => ({
                    ...prevCustomer,
                    email: e.target.value,
                  }));
                }}
              />
              <button
                type="button"
                onClick={addAltEmail}
                className="mt-2 py-2 text-sm text-[#224E73] underline hover:text-[#224E73]">
                + Add Alternative Email Address
              </button>

              {altEmails.map((email, index) => (
                <div key={index} className="mt-2 flex items-center">
                  <input
                    type="email"
                    className="my-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    value={email}
                    onChange={(e) =>
                      handleAltEmailChange(e.target.value, index)
                    }
                    placeholder="Alternative Email"
                  />
                  <button
                    type="button"
                    onClick={() => removeAltEmail(index)}
                    className="ml-2 rounded-full p-2 text-[#224E73] hover:bg-gray-200">
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>

            {/* Contact Number Field */}
            <div className="space-y-1">
              <label
                htmlFor="contact-number"
                className="block text-sm font-medium text-gray-700">
                Contact Number
              </label>
              <input
                type="tel"
                name="contact-number"
                id="contact-number"
                value={newCustomer?.contactNumber}
                className="block w-full rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                placeholder="9876543210"
                onChange={(e) => {
                  setNewCustomer((prevCustomer) => ({
                    ...prevCustomer,
                    contactNumber: e.target.value,
                  }));
                }}
              />
              <button
                type="button"
                onClick={addAltPhone}
                className="mt-2 py-2 text-sm text-[#224E73] underline hover:text-[#224E73]">
                + Add Additional Phone Number
              </button>
              {altPhones.map((phone, index) => (
                <div key={index} className="mt-2 flex items-center">
                  <input
                    type="tel"
                    className="my-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    value={phone}
                    onChange={(e) =>
                      handleAltPhoneChange(e.target.value, index)
                    }
                    placeholder="Alternative Phone Number"
                  />
                  <button
                    type="button"
                    onClick={() => removeAltPhone(index)}
                    className="ml-2 rounded-full p-2 text-[#224E73] hover:bg-gray-200">
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>

            {/* Info Box */}
            <div className="mt-4 flex items-center rounded-md border-l-4 border-[#224E73] bg-blue-50 p-4 text-sm text-[#224E73]">
              <InformationCircleIcon className="mr-2 h-10 w-10" />
              You can add more addresses to the user once profile has been
              created.
            </div>

            {/* Address Fields */}
            <div className="flex flex-col gap-2 space-y-1">
              {/* Address Line 1 */}
              <div>
                <label
                  htmlFor="address-line1"
                  className="block text-sm font-medium text-gray-700">
                  Address Line 1<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="address-line1"
                  id="address-line1"
                  required
                  value={newCustomer?.accountAddress?.line1}
                  className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                  onChange={(e) => {
                    setNewCustomer((prevCustomer) => ({
                      ...prevCustomer,
                      accountAddress: {
                        ...prevCustomer?.accountAddress,
                        line1: e.target.value,
                      },
                    }));
                  }}
                />
              </div>

              {/* Address Line 2 */}
              <div>
                <label
                  htmlFor="address-line2"
                  className="block text-sm font-medium text-gray-700">
                  Address Line 2
                </label>
                <input
                  type="text"
                  name="address-line2"
                  id="address-line2"
                  value={newCustomer?.accountAddress?.line2}
                  className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                  onChange={(e) => {
                    setNewCustomer((prevCustomer) => ({
                      ...prevCustomer,
                      accountAddress: {
                        ...prevCustomer?.accountAddress,
                        line2: e.target.value || null,
                      },
                    }));
                  }}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  {/* City */}
                  <label
                    htmlFor="address-city"
                    className="block text-sm font-medium text-gray-700">
                    City<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="address-city"
                    id="address-city"
                    value={newCustomer?.accountAddress?.city}
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          city: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>

                <div>
                  {/* Zipcode */}
                  <label
                    htmlFor="zip-code"
                    className="block text-sm font-medium text-gray-700">
                    Zip Code<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="zip-code"
                    id="zip-code"
                    required
                    value={newCustomer?.accountAddress?.zip}
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    placeholder="560075"
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          zip: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            {/* State & Country */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Tooltip
                  title={
                    !selectedCountry ? "Please select a country first" : ""
                  }
                  placement="top">
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="state">
                      State<span className="text-red-500">*</span>
                    </label>
                    <AutocompleteDropdownV2
                      options={filteredStates}
                      labelKey="name"
                      valueKey="isoCode"
                      onChange={handleStateChange}
                      value={newCustomer?.accountAddress?.state || ""}
                      placeholder="Select a State"
                      id="name"
                    />
                  </div>
                </Tooltip>
              </div>

              <div>
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="country">
                  Country<span className="text-red-500">*</span>
                </label>
                <AutocompleteDropdownV2
                  options={Country.getAllCountries()}
                  id="name"
                  labelKey="name"
                  valueKey="isoCode"
                  onChange={handleCountryChange}
                  value={newCustomer?.accountAddress?.country || ""}
                  placeholder="Select a Country"
                />
              </div>
            </div>

            {/* Planting Zone Checkbox */}
            <div className="mt-4 flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="planting-zone"
                  name="planting-zone"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                  value={newCustomer?.accountAddress?.isPlantingZoneAddress}
                  onChange={(e) => {
                    setNewCustomer((prevCustomer) => ({
                      ...prevCustomer,
                      accountAddress: {
                        ...prevCustomer?.accountAddress,
                        isPlantingZoneAddress: e.target.checked,
                      },
                    }));
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="planting-zone"
                  className="font-medium text-gray-700">
                  Mark the address as a planting zone
                </label>
              </div>
            </div>

            {/* Planting Zone Code */}
            <div className="space-y-1">
              <label
                htmlFor="planting-zone"
                className="block text-sm font-medium text-gray-700">
                Planting Zone Code
              </label>
              <input
                type="text"
                name="planting-zone"
                id="planting-zone"
                className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                placeholder=""
                value={newCustomer?.accountAddress?.plantingZoneCode}
                onChange={(e) => {
                  setNewCustomer((prevCustomer) => ({
                    ...prevCustomer,
                    accountAddress: {
                      ...prevCustomer?.accountAddress,
                      plantingZoneCode: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="space-y-1 py-2">
              <label
                htmlFor="notes"
                className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <textarea
                name="notes"
                id="notes"
                className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                placeholder="Enter any custom notes here"
                value={newCustomer?.notes}
                onChange={(e) => {
                  setNewCustomer((prevAddress) => ({
                    ...prevAddress,
                    notes: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        )}
        {selectedStep?.tag === "shippingInfo" && (
          <div className="space-y-6">
            {/* Checkbox to use account address as default shipping address */}
            {shouldDisplayAccountAddress() && (
              <div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="use-account-address"
                    name="use-account-address"
                    checked={useAccountAddress}
                    onChange={(e) => {
                      setUseAccountAddress(e.target.checked);
                      if (e.target.checked) {
                        setNewCustomer((prevCustomer) => ({
                          ...prevCustomer,
                          shippingAddresses: [
                            prevCustomer?.accountAddress,
                            ...(prevCustomer?.shippingAddresses || []),
                          ],
                        }));
                      } else {
                        setNewCustomer((prevCustomer) => ({
                          ...prevCustomer,
                          shippingAddresses: (
                            prevCustomer?.shippingAddresses || []
                          ).slice(1),
                        }));
                      }
                    }}
                    className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                  />
                  <label
                    htmlFor="use-account-address"
                    className="ml-2 text-sm font-medium text-gray-700">
                    Add the account address as shipping address
                  </label>
                </div>
                {useAccountAddress && (
                  <div className="rounded-md border p-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-lg font-semibold">
                          {[newCustomer?.firstName, newCustomer?.lastName]
                            .filter(Boolean)
                            .join(" ")}
                        </h3>
                        <p className="text-sm">
                          {[
                            newCustomer?.accountAddress?.line1,
                            newCustomer?.accountAddress?.line2,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                        <p className="text-sm">
                          {[
                            newCustomer?.accountAddress?.city,
                            newCustomer?.accountAddress?.state,
                            newCustomer?.accountAddress?.country,
                            newCustomer?.accountAddress?.zip,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                        <p className="text-sm">
                          {[newCustomer?.contactNumber, newCustomer?.email]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {newCustomer?.shippingAddresses?.length > 0
              ? newCustomer?.shippingAddresses
                  .filter(
                    (address) =>
                      !isSameAddress(address, newCustomer?.accountAddress),
                  )
                  .map((address, index) => (
                    <div key={index} className="rounded-md border p-4">
                      <div className="flex items-center justify-between">
                        <div>
                          <h3 className="text-lg font-semibold">
                            {[address?.firstName, address?.lastName]
                              .filter(Boolean)
                              .join(" ")}
                          </h3>
                          <p className="text-sm">
                            {[address?.line1, address?.line2]
                              .filter(Boolean)
                              .join(", ")}
                          </p>
                          <p className="text-sm">
                            {[
                              address?.city,
                              address?.state,
                              address?.country,
                              address?.zip,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </p>
                          <p className="text-sm">
                            {[address?.contactNumber, address?.email]
                              .filter(Boolean)
                              .join(", ")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
              : null}

            {!useAccountAddress &&
            newCustomer.shippingAddresses.length === 0 ? (
              <div className="rounded border border-blue-800 bg-blue-100 p-2 text-sm text-gray-600">
                Please provide a shipping address for the customer if it differs
                from the account address.
              </div>
            ) : null}

            {/* Add New Address Button */}
            {addNewAddress ? null : (
              <button
                type="button"
                onClick={() => setAddNewAddress({ country: "US" })}
                className="py-2 text-sm text-[#224E73] underline hover:text-[#224E73]">
                + Add New Address
              </button>
            )}

            {/* New Address Form */}
            {addNewAddress && (
              <div className="space-y-1">
                <div className="space-x-2 border px-2 py-4">
                  <h3 className="py-2 text-lg font-semibold">Add Address</h3>

                  <div className="grid grid-cols-2 gap-4">
                    {/* First Name Field */}
                    <div className="space-y-1">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700">
                        First Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="Doe"
                        value={addNewAddress?.firstName}
                        onChange={(e) => {
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            firstName: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    {/* Last Name Field */}
                    <div className="space-y-1">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700">
                        Last Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="John"
                        value={addNewAddress?.lastName}
                        onChange={(e) => {
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            lastName: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  {/* Email Field */}
                  <div className="space-y-1 py-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder="XYZ123@gmail.com"
                      value={addNewAddress?.email}
                      onChange={(e) => {
                        setAddNewAddress((prevAddress) => ({
                          ...prevAddress,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </div>

                  {/* Contact Number Field */}
                  <div className="space-y-1 py-2">
                    <label
                      htmlFor="contact-number"
                      className="block text-sm font-medium text-gray-700">
                      Contact Number
                    </label>
                    <input
                      type="tel"
                      name="contact-number"
                      id="contact-number"
                      value={addNewAddress?.contactNumber}
                      className="block w-full rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder="9876543210"
                      onChange={(e) => {
                        setAddNewAddress((prevAddress) => ({
                          ...prevAddress,
                          contactNumber: e.target.value,
                        }));
                      }}
                    />
                  </div>

                  {/* Address Fields */}
                  <div className="space-y-1 py-2">
                    {/* Address Line 1 */}
                    <label
                      htmlFor="address-line1"
                      className="block text-sm font-medium text-gray-700">
                      Address Line 1<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="address-line1"
                      id="address-line1"
                      required
                      value={addNewAddress?.line1}
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      onChange={(e) => {
                        setAddNewAddress((prevAddress) => ({
                          ...prevAddress,
                          line1: e.target.value,
                        }));
                      }}
                    />
                    {/* Address Line 2 */}
                    <label
                      htmlFor="address-line2"
                      className="block text-sm font-medium text-gray-700">
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      name="address-line2"
                      id="address-line2"
                      value={addNewAddress?.line2}
                      className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      onChange={(e) => {
                        setAddNewAddress((prevAddress) => ({
                          ...prevAddress,
                          line2: e.target.value || null,
                        }));
                      }}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4 py-2">
                    <div>
                      {/* City */}
                      <label
                        htmlFor="address-city"
                        className="block text-sm font-medium text-gray-700">
                        City<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="address-city"
                        id="address-city"
                        value={addNewAddress?.city}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={(e) => {
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            city: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    <div>
                      {/* Zipcode */}
                      <label
                        htmlFor="zip-code"
                        className="block text-sm font-medium text-gray-700">
                        Zip Code<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="zip-code"
                        id="zip-code"
                        required
                        value={addNewAddress?.zip}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="560075"
                        onChange={(e) => {
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            zip: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  {/* State & Country */}
                  <div className="grid grid-cols-2 gap-4 py-2">
                    <div>
                      <Tooltip
                        title={
                          !selectedCountry
                            ? "Please select a country first"
                            : ""
                        }
                        placement="top">
                        <div
                          style={{
                            display: "inline-block",
                            width: "100%",
                          }}>
                          <label
                            htmlFor="state"
                            className="block text-sm font-medium text-gray-700">
                            State<span className="text-red-500">*</span>
                          </label>
                          <AutocompleteDropdownV2
                            options={filteredStates}
                            labelKey="name"
                            valueKey="isoCode"
                            onChange={(newState) => {
                              setSelectedState(newState);

                              setAddNewAddress((prevAddress) => ({
                                ...prevAddress,
                                state: newState,
                              }));
                            }}
                            value={addNewAddress?.state || ""}
                            placeholder="Select a State"
                            id="name"
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700">
                        Country<span className="text-red-500">*</span>
                      </label>
                      <AutocompleteDropdownV2
                        options={Country.getAllCountries()}
                        id="name"
                        labelKey="name"
                        valueKey="isoCode"
                        onChange={(newCountry) => {
                          setSelectedCountry(newCountry);
                          setSelectedState("");
                          setFilteredStates(
                            State.getStatesOfCountry(newCountry),
                          );

                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            country: newCountry,
                          }));
                        }}
                        value={addNewAddress?.country || ""}
                        placeholder="Select a Country"
                      />
                    </div>
                  </div>

                  {/* Planting Zone Checkbox */}
                  <div className="mt-2 flex items-start py-2">
                    <div className="flex h-5 items-center">
                      <input
                        id="planting-zone"
                        name="planting-zone"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                        value={addNewAddress?.isPlantingZoneAddress}
                        onChange={(e) => {
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            isPlantingZoneAddress: e.target.checked,
                          }));
                        }}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="planting-zone"
                        className="font-medium text-gray-700">
                        Mark the address as a planting zone
                      </label>
                    </div>
                  </div>

                  {/* Planting Zone Code */}
                  <div className="space-y-1 py-2">
                    <label
                      htmlFor="planting-zone"
                      className="block text-sm font-medium text-gray-700">
                      Planting Zone Code
                    </label>
                    <input
                      type="text"
                      name="planting-zone"
                      id="planting-zone"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder=""
                      value={addNewAddress?.plantingZoneCode}
                      onChange={(e) => {
                        setNewCustomer((prevAddress) => ({
                          ...prevAddress,
                          plantingZoneCode: e.target.value,
                        }));
                      }}
                    />
                  </div>

                  <div className="flex items-center justify-end space-x-4 py-2">
                    <button
                      type="button"
                      onClick={() => setAddNewAddress(null)}
                      className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2">
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleAddNewAddress}
                      disabled={
                        !addNewAddress.firstName ||
                        !addNewAddress.lastName ||
                        !addNewAddress.line1 ||
                        !addNewAddress.city ||
                        !addNewAddress.zip ||
                        !addNewAddress.state ||
                        !addNewAddress.country
                      }
                      className="rounded-md border border-transparent bg-[#224E73] px-4 py-2 text-sm font-medium text-white hover:bg-[#224E73] focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-mediumGray disabled:bg-mediumGray">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </main>
      <footer className="mt-auto w-full flex-shrink-0">
        <AddUserFooter
          newCustomer={newCustomer}
          setNextStep={setNextStep}
          setPrevStep={setPrevStep}
          selectedStep={selectedStep}
          showSubmit={showSubmit}
          steps={steps}
          onSubmit={createCRMCustomer}
          onCancel={() => {
            appState.showConfirmation(
              "Cancel",
              "Are you sure you want to cancel? All progress will be lost.",
              appState.hideConfirmation,
              () => {
                setNewCustomer(null);
                onCancel(false);
                appState.hideConfirmation();
              },
              "No, continue",
              "Yes, cancel",
            );
          }}
          isShippingSelected={newCustomer?.shippingAddresses?.length > 0}
        />
      </footer>
    </div>
  );
};
