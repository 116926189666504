import { Tab } from "@headlessui/react";
import _ from "lodash";

const CompletedBatch = ({
  currentBatch,
  confirmDropoff,
  pack,
  printShippingLabel,
  restartActivity,
  pauseActivity,
  printBoxLabels,
  order,
  tenant,
  reprintShippingLabel,
  printPackingLabels,
  packingLabelsEnabled,
}) => {
  const workingListGrouped = _.groupBy(currentBatch?.workingList, "order");
  const humanizeOrderId = (orderId) => {
    if (currentBatch?.attributes?.orderIds) {
      const values = Object.values(currentBatch.attributes.orderIds);
      const idx = values.indexOf(orderId);
      if (idx > -1 && currentBatch?.attributes?.humanReadableOrderIds) {
        const humanizeOrderIds = Object.values(
          currentBatch.attributes.humanReadableOrderIds,
        );
        return humanizeOrderIds[idx];
      }
    }

    return `${orderId}`;
  };
  const tabs = {
    "Detail View": (
      <div className="w-full rounded-2xl border bg-white shadow-lg">
        <div className="flex items-center justify-center pb-5 pt-5">
          <div className="border-r border-gray-400 p-5">
            <div className="rounded-full bg-primaryAccent p-6 shadow-md">
              {" "}
              <img
                src="https://hopstack-pub.s3.amazonaws.com/icons/completed_tick.png"
                className="w-14"
              />
            </div>
          </div>
          <div className="px-5 py-5 text-3xl font-medium text-454A4F">
            <div>Order(s) Completed Successfully</div>
            <div>Drop-off at the next station</div>
            <div className="flex space-x-4">
              <>
                <div
                  className="mt-2 flex w-64 cursor-pointer items-center justify-center rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                  onClick={confirmDropoff}>
                  Confirm
                </div>
                {packingLabelsEnabled && currentBatch?.boxes?.length > 0 && (
                  <div
                    className="mt-2 flex w-64 cursor-pointer items-center justify-center rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                    onClick={printPackingLabels}>
                    Print Packing Labels
                  </div>
                )}
                {order && order?.boxLabels && printBoxLabels && (
                  <div
                    className="mt-2 flex w-64 cursor-pointer items-center justify-center rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                    onClick={printBoxLabels}>
                    Re-Print Box Labels
                  </div>
                )}
                {tenant?.features?.packerRestartOptionsEnabled !== false && (
                  <div
                    className="mt-2 flex w-64 cursor-pointer items-center justify-center rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                    onClick={() => printShippingLabel(true)}>
                    {tenant?.settings?.activities?.packing
                      ?.disableAutomaticPrinting
                      ? "Print Shipping Labels"
                      : "Re-Print Shipping Labels"}
                  </div>
                )}
                {tenant?.features?.packerRestartOptionsEnabled !== false &&
                  pauseActivity && (
                    <div
                      className="mt-2 flex w-64 cursor-pointer items-center justify-center rounded-md bg-red-600 py-4 text-center text-2xl text-white"
                      onClick={pauseActivity}>
                      Pause Packing
                    </div>
                  )}
                {tenant?.features?.packerRestartOptionsEnabled !== false &&
                  restartActivity && (
                    <div
                      className="mt-2 flex w-64 cursor-pointer items-center justify-center rounded-md bg-red-600 py-4 text-center text-2xl text-white"
                      onClick={restartActivity}>
                      Restart Packing
                    </div>
                  )}
              </>
            </div>
          </div>
        </div>
      </div>
    ),
  };

  if (
    currentBatch?.workingList?.length > 0 &&
    _.uniqBy(currentBatch?.workingList, "order").length > 1
  ) {
    tabs["List View"] = (
      <table className="mt-2 min-w-full divide-y divide-gray-200">
        <thead className="rounded-full p-4">
          <tr className="font-montserratborder-left bg-primaryAccent text-white">
            <th
              scope="col"
              className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
              Order ID
            </th>
            <th
              scope="col"
              className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
              # Unique Products
            </th>
            <th
              scope="col"
              className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
              Total Quantity
            </th>
            <th
              scope="col"
              className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
              Re-Print
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(workingListGrouped).map((order, idx) => (
            <tr
              key={idx}
              className={`${idx % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                  idx % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {humanizeOrderId(order)}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {
                  _.uniq(
                    currentBatch.workingList
                      .filter((i) => i.order === order)
                      .map((i) => i.order),
                  ).length
                }
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {_.sumBy(
                  currentBatch.workingList.filter((i) => i.order === order),
                  "quantity",
                )}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                <div
                  className="w-32 cursor-pointer rounded-md bg-2C7695 py-4 text-center text-lg text-white"
                  onClick={() => reprintShippingLabel(order)}>
                  Re-Print
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="mt-20 flex w-full items-center justify-center">
      <div className="flex-col">
        <Tab.Group>
          <Tab.List className="flex items-center justify-center">
            <div className="space-x-2 rounded-full bg-primaryAccent p-2">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "font-regular rounded-full px-6 py-2.5 leading-5",
                        "z-50 font-montserrat text-lg focus:outline-none focus:ring-0",
                        selected
                          ? "bg-white font-medium text-primaryAccent"
                          : "rounded-full border border-primaryAccent bg-transparent text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="mt-4 flex flex-col items-center bg-transparent p-4">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
};

export default CompletedBatch;
