import {
  PencilIcon,
  TrashIcon,
  EyeIcon,
  DocumentSearchIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";
import SearchSortFilter from "#components/common/SearchSortFilter";
import _ from "lodash";
import QuickFilters from "#components/common/QuickFilters";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import StatCard from "../common/StatCard";

const UsersList = ({
  users,
  allUsers,
  isStatsLoading,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  customers,
  warehouses,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  resetPasswordButtonClicked,
  toggleSuspendUserButtonClicked,
  getAudit,
  writable,
}) => {
  const getStatusChip = (activated, suspended) => {
    const chipStyle = "p-1 rounded-full m-auto text-center ";
    if (suspended)
      return (
        <div
          className={
            chipStyle +
            (suspended && "bg-suspendedTextColor/20 text-deactivatedTextColor")
          }>
          {"Suspended"}
        </div>
      );

    return (
      <div
        className={
          chipStyle +
          (activated
            ? "bg-activeTextColor/20 text-activeTextColor"
            : "bg-inActiveTextColor/20 text-inActiveTextColor")
        }>
        {activated ? "Active" : !suspended ? "Invited" : "Inactive"}
      </div>
    );
  };

  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-14">
      {(customers.length > 1 || warehouses.length > 1) && (
        <QuickFilters
          warehouseFilterName={"warehouses"}
          customerFilterName={"customers"}
          customers={customers}
          warehouses={warehouses}
          filters={filters}
          onChangeFilter={onChangeFilter}
        />
      )}

      {users && (
        <div className="grid grid-cols-4 gap-4">
          <StatCard
            title={"Total User Accounts"}
            stat={allUsers.length}
            loading={isStatsLoading}
          />
          <StatCard
            title={"Current Active Users"}
            stat={allUsers.filter((user) => user.activated).length}
            loading={isStatsLoading}
          />
          <StatCard
            title={"Suspended Users"}
            stat={allUsers.filter((user) => user.suspended).length}
            loading={isStatsLoading}
          />
          <StatCard
            title={"Invited Users"}
            stat={
              allUsers.filter((user) => !!!user.activated && !!!user.suspended)
                .length
            }
            loading={isStatsLoading}
          />
        </div>
      )}

      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Date",
            value: "createdAt",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                    key={headerIdx}>
                    No Users Found.
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : (
            users.map((user, index) => {
              const menuItems = [
                {
                  title: "Reset Password",
                  icon: EyeIcon,
                  onClick: () => resetPasswordButtonClicked(user),
                },
                {
                  title: user.suspended ? "Unsuspend User" : "Suspend User",
                  icon: DocumentSearchIcon,
                  onClick: () =>
                    toggleSuspendUserButtonClicked({
                      ...user,
                      suspended: !user.suspended,
                    }),
                },
                {
                  title: "Edit User",
                  icon: PencilIcon,
                  onClick: () => editButtonClicked(user),
                },
                {
                  title: "Delete User",
                  icon: TrashIcon,
                  onClick: () => deleteButtonClicked(user.id),
                },
                {
                  title: "View Audit Trail",
                  icon: DocumentSearchIcon,
                  onClick: () => getAudit(user.id),
                },
              ];

              return (
                <tr
                  key={user.id}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`rounded-bl rounded-tl border-l-8 p-5 text-left font-semibold tracking-wider text-primaryAccent ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {user.name}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                    {user.warehouses && user.warehouses.length > 0 ? (
                      <ExpandedMultipleItem
                        items={warehouses
                          .filter((warehouse) =>
                            user.warehouses.includes(warehouse.id),
                          )
                          .map((warehouse) => warehouse.name)}
                      />
                    ) : (
                      <ExpandedMultipleItem
                        items={warehouses.map((warehouse) => warehouse.name)}
                      />
                    )}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                    {user.customers && user.customers.length > 0 ? (
                      <ExpandedMultipleItem
                        items={customers
                          .filter((customer) =>
                            user.customers.includes(customer.id),
                          )
                          .map((customer) => customer.name)}
                      />
                    ) : (
                      <ExpandedMultipleItem
                        items={
                          user.warehouses && user.warehouses.length > 0
                            ? customers
                                .filter(
                                  (customer) =>
                                    customer.warehouses &&
                                    customer.warehouses.some((warehouse) =>
                                      user.warehouses.includes(warehouse),
                                    ),
                                )
                                .map((customer) => customer.name)
                            : customers.map((customer) => customer.name)
                        }
                      />
                    )}
                  </td>
                  <td className="overflow-ellipsis px-1 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                    {user.role}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                    <ExpandedMultipleItem items={user.hopstackModules} />
                  </td>
                  <td className="mx-auto px-1 py-1 pl-4 tracking-wider">
                    {getStatusChip(user.activated, user.suspended)}
                  </td>
                  <td className="overflow-ellipsis px-1 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="rounded-br rounded-tr px-1 py-1 text-left font-medium tracking-wider text-tableText">
                    {writable && (
                      <div className="flex min-w-max items-center justify-center space-x-2">
                        <Menu as="div">
                          <Menu.Button>
                            <DotsVerticalIcon className="h-8 w-8 rounded bg-actionMenuBG p-1 font-montserrat text-lg font-bold" />
                          </Menu.Button>
                          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="">
                              {menuItems.map((menuItem, idx) => (
                                <Menu.Item key={idx}>
                                  {() => (
                                    <button
                                      className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                                      onClick={menuItem.onClick}>
                                      {menuItem.icon && (
                                        <menuItem.icon className="h-8 w-8 pr-2" />
                                      )}
                                      {menuItem.title}
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Menu>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default UsersList;
