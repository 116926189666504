import React from "react";
import PropTypes from "prop-types";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";

function InputBox({
  label,
  required = false,
  infoText = "",
  placeholder = "",
  value,
  onChange,
  error = "",
  parentClasses = "",
  type = "text",
  disabled = false,
  labelClasses = "text-gray-700",
  shouldBeHighLighted = false,
  ...props
}) {
  return (
    <div className={`relative ${parentClasses}`}>
      {label && (
        <div className="mb-2 flex items-center">
          <div className={labelClasses}>
            {label}
            {required && <span className="text-red-500">*</span>}
            {infoText && (
              <Tooltip title={infoText}>
                <ExclamationCircleIcon className="ml-2 inline-block h-4 w-4 text-gray-400" />
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`block w-full rounded border bg-white p-2 text-gray-700 transition-colors duration-300 focus:border-primaryAccent focus:ring-primaryAccent ${
          error && shouldBeHighLighted ? "border-red-500" : "border-gray-400"
        }`}
        {...props}
      />
      {error && <div className="mt-1 text-sm text-red-600">{error}</div>}
    </div>
  );
}

// PropTypes validation
InputBox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  required: PropTypes.bool,
  infoText: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  parentClasses: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  labelClasses: PropTypes.string,
  shouldBeHighLighted: PropTypes.bool,
};

export default InputBox;
