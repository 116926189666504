import PageTitle from "#components/utils/PageTitle";
import CardTitle from "#components/utils/CardTitle";
import CardDataTable from "#components/utils/CardDataTable";
import CardDataTableReplenishments from "#components/utils/CardDataTableReplenishments";
import {
  ShoppingCartIcon,
  GiftIcon,
  CashIcon,
  DocumentTextIcon,
  ClipboardListIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/outline";
import withForecastsLogic from "#components/HOC/withForecastsLogic";
import _ from "lodash";
import Stats from "#components/common/Stats";
import ExpandedSku from "#components/inventory/ExpandedSku";

const results = [
  {
    sku: "SZS0293",
    name: "2.34 CT TGW Cubic Zirconia Bridal Ring Set Silver White",
    units: 100,
    cost: "$147.32",
    sales: "$14,732",
  },
  {
    sku: "SRZS6098",
    name: "2 1/3 CT TGW White Cubic Zirconia Bridal Set Ring Silver",
    units: 75,
    cost: "$62.99",
    sales: "$4,724.25",
  },
  {
    sku: "SRDS105103RD",
    name: "1/6 CT Diamond TW Ring Set Silver GH I2;I3",
    units: 68,
    cost: "$219.99",
    sales: "$14,960",
  },
  {
    sku: "RYKTW100265WS",
    name: "1 1/4 CT TGW Created White Sapphire Solitaire Ring 10k White Gold",
    units: 59,
    cost: "$236.25",
    sales: "$13,938.75",
  },
  {
    sku: "RYKTW100208WS-WB",
    name: "2/5 CT TGW Created White Sapphire Fashion Ring 10k White Gold",
    units: 55,
    cost: "$171.25",
    sales: "$9,418.75",
  },
  {
    sku: "RYS081WS",
    name: "1 1/10 CT TGW Created White Sapphire Fashion Ring Silver",
    units: 49,
    cost: "$43.47",
    sales: "$2,143.75",
  },
  {
    sku: "RZS02359",
    name: "Silver 6ct TGW Baguette & Rd Cubic Zirconia Ring",
    units: 44,
    cost: "$468.73",
    sales: "$20,624.12",
  },
  {
    sku: "SCDS10909B",
    name: "1/10 CT Diamond TW 9 - 9.5 MM Black Tahitian Cultured Pearl Set With Chain Silver GH I2;I3",
    units: 43,
    cost: "$241.49",
    sales: "$10,384.07",
  },
  {
    sku: "RZS7519",
    name: "Sterling Silver 1-1/5ct 1.8mm CZ Criss-Cross Ring",
    units: 40,
    cost: "$234.34",
    sales: "$9,373.60",
  },
  {
    sku: "RZS6652",
    name: "5.55 CT TGW Cubic Zirconia Fashion Ring Silver White",
    units: 38,
    cost: "$249.98",
    sales: "$9,499.24",
  },
];

const sales = [
  {
    icon: <ShoppingCartIcon className="w-6" />,
    name: "Orders",
    units: 78030,
  },
  {
    icon: <GiftIcon className="w-6" />,
    name: "Units Sold",
    units: 91588,
  },
  {
    icon: <CashIcon className="w-6" />,
    name: "Cost of Goods",
    units: "$16,697,215.76",
  },
  {
    icon: <CashIcon className="w-6" />,
    name: "Revenue",
    units: "$16,706,859.28",
  },
];

const replenishments = [
  {
    icon: <DocumentTextIcon />,
    units: "Units",
    cost: 20000,
  },
  {
    icon: <CashIcon />,
    units: "Cost",
    cost: 14000,
  },
  {
    icon: <CashIcon />,
    units: "Retail",
    cost: "$1,300,000",
  },
];

const InventoryOverview = ({
  forecasts,
  fetchedSku,
  setFetchedSku,
  getSku,
}) => {
  return (
    <div className="p-4">
      <PageTitle>Inventory Overview</PageTitle>
      <Stats
        stats={[
          {
            name: "# SKUs to replenish",
            stat: 25,
          },
          {
            name: "# SKUs to liquidate ",
            stat: 14,
          },
          {
            name: "Inventory turnover ratio",
            stat: 6,
            arrow: <ArrowUpIcon className="h-6 w-6 text-green-400" />,
          },
          {
            name: "Average days till stock out",
            stat: 8,
            arrow: <ArrowDownIcon className="h-6 w-6 text-red-400" />,
          },
        ]}
      />
      <div className="mt-4 grid grid-cols-3 gap-4">
        <div className="col-span-2 rounded-xl border bg-F8F8F8 shadow-md">
          <CardTitle value="Best Sellers" />
          <CardDataTable
            numOfColumns={7}
            headers={[
              {
                title: "SKU",
                space: 1,
              },
              {
                title: "Name",
                space: 2,
              },
              {
                title: "Units",
                space: 1,
              },
              {
                title: "Cost",
                space: 1,
              },
              {
                title: "Sales",
                space: 1,
              },
            ]}
            values={results}
            getSku={getSku}
          />
        </div>
        <div className="rounded-xl border bg-F8F8F8 shadow-md">
          <CardTitle value="Sales" />
          <CardDataTable numOfColumns={7} headers={[]} values={sales} />
        </div>
      </div>
      <div className="mt-4 grid grid-cols-4 gap-4">
        <div className="col-span-2 rounded-xl border bg-F8F8F8 shadow-md">
          <CardTitle value="Top SKUs to Replenish" />
          <CardDataTableReplenishments
            values={forecasts ? forecasts.slice(0, 10) : forecasts}
            replenish={true}
            getSku={getSku}
          />
        </div>
        <div className="col-span-2 rounded-xl border bg-F8F8F8 shadow-md">
          <CardTitle value="Top SKUs to Liquidate" />
          <CardDataTableReplenishments
            values={forecasts ? forecasts.slice(10, 20) : forecasts}
            replenish={false}
            getSku={getSku}
          />
        </div>
      </div>
      {fetchedSku && (
        <ExpandedSku
          fetchedSku={fetchedSku}
          negativeAction={() => setFetchedSku(null)}
        />
      )}
    </div>
  );
};

export default withForecastsLogic(InventoryOverview);
