import moment from "moment-timezone";
import { useState } from "react";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  PencilIcon,
  TrashIcon,
  DocumentReportIcon,
} from "@heroicons/react/outline";
import _ from "lodash";
import SearchSortFilter from "../common/SearchSortFilter";
import Pagination from "#components/common/Pagination";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";

const CustomersList = ({
  customers,
  editButtonClicked,
  deleteButtonClicked,
  passwordEditable,
  headers,
  noValuesText,
  warehouses,
  assignButtonClicked,
  writable,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  onChangeSearchKeyword,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
}) => {
  const getStatusChip = (activated) => {
    const chipStyle = "p-1 rounded-full m-auto text-center ";

    return (
      <div
        className={
          chipStyle +
          (activated
            ? "bg-activeTextColor/20 text-activeTextColor"
            : "bg-inActiveTextColor/20 text-inActiveTextColor")
        }>
        {activated ? "Active" : "Inactive"}
      </div>
    );
  };

  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={null}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Name",
            value: "name",
          },
          {
            name: "Code",
            value: "code",
          },
          {
            name: "Last Updated",
            value: "updatedAt",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {customers.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) => (
                <td
                  className="rounded-bl rounded-tl border-l-8 border-F4C261 p-5 text-left font-semibold tracking-wider text-primaryAccent"
                  key={headerIdx}>
                  {headerIdx === 0 ? noValuesText : ""}
                </td>
              ))}
            </tr>
          ) : null}
          {customers.map((customer, index) => (
            <tr
              key={customer.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`}>
              <td className="rounded-bl rounded-tl border-l-8 border-F4C261 p-5 text-left font-semibold tracking-wider text-primaryAccent">
                {customer.name}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {customer.code}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {warehouses && (
                  <ExpandedMultipleItem
                    items={warehouses
                      .filter(
                        (warehouse) =>
                          customer.warehouses &&
                          customer.warehouses.includes(warehouse.id),
                      )
                      .map((warehouse) => warehouse.name)}
                  />
                )}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {getStatusChip(customer.active)}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {customer?.currentBillingProfile?.name || "N/A"}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {moment(customer.updatedAt).format("MMM Do, YYYY")}
              </td>
              <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                <div className="flex items-center space-x-4">
                  <TableFieldButton
                    onClick={() => editButtonClicked(customer)}
                    text={<PencilIcon className="h-6 w-6" />}
                  />
                  <TableFieldButton
                    text={<TrashIcon className="h-6 w-6" />}
                    onClick={() => deleteButtonClicked(customer.id)}
                    disabled={!writable}
                  />
                  <TableFieldButton
                    text={<DocumentReportIcon className="h-6 w-6" />}
                    onClick={() => assignButtonClicked(customer)}
                    disabled={!writable}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTableWithTheme>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default CustomersList;
