const PageHeader = ({ customer, customers }) => {
  if (
    customer &&
    customers &&
    customers.findIndex((item) => item.id === customer) !== -1
  ) {
    return (
      <div className="left-0 w-full bg-black text-center text-2xl text-white">
        {customers.find((item) => item.id === customer).name}
      </div>
    );
  }

  return null;
};

export default PageHeader;
