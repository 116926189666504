import React, { useState, useContext, useEffect } from "react";
import { EXPORT_REPORT } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import { AppStateContext } from "#contexts/appState";
import { GET_EXPORT_REPORT } from "#queries/index";
import dayjs from "dayjs";

const REPORT_GET_MODES = {
  DEFAULT: "none",
  CSV: "CSV",
  PDF: "PDF",
  PREVIEW: "Preview",
};
const ConsignmentModal = ({
  show,
  setShowNow,
  onClose,
  setCustomersSelectedForReport,
  customersSelectedForReport,
  startDateForReport,
  setStartDateForReport,
  endDateForReport,
  setEndDateForReport,
  setIsYellowBanner,
  filters,
  setShowYellowBanner,
  setShowGreenBanner,
  setShowRedBanner,
  setBannerMessage,
  setReportUrl,
  startReportPolling,
  displayConsignments,
}) => {
  const [reportMode, setReportMode] = useState(REPORT_GET_MODES.DEFAULT);

  const appState = useContext(AppStateContext);
  const [downloadReport, setDownloadReport] = useState(false);
  const exportReportQuery = useQuery(EXPORT_REPORT);

  const handleDownloadCSV = async () => {
    if (displayConsignments.length === 0) {
      setShowNow(true);
      setShowYellowBanner(false);
      setShowRedBanner(true);
      setShowGreenBanner(false);
      setReportUrl("");
      setBannerMessage(
        "Your report is unable to get generated as consignments are empty",
      );

      onClose();
      return;
    }

    appState.setLoading();

    const today = dayjs().add(1, "day").format("YYYY-MM-DD");
    const twelveMonthsAgo = dayjs().subtract(12, "months").format("YYYY-MM-DD");
    const exportReportQueryResponse = await exportReportQuery.fetchData({
      exportReportInput: {
        filters: {
          customer: filters.customers || [],
          warehouse: filters.warehouses || [],
          startDate:
            filters?.startEndDate?.length > 1
              ? filters?.startEndDate[0]
              : twelveMonthsAgo,
          endDate:
            filters?.startEndDate?.length > 1
              ? filters?.startEndDate[1]
              : today,
          orderType: filters.orderType || [],
          status: filters.status || [],
        },
        module: "Export Consignments",
      },
    });
    appState.removeLoading();

    if (exportReportQueryResponse.error) {
      appState.setAlert(
        exportReportQueryResponse.error?.message ??
          exportReportQueryResponse.error,
        "error/",
        2000,
      );
    }

    if (exportReportQueryResponse.data) {
      setShowNow(true);
      setShowYellowBanner(true);
      setShowRedBanner(false);
      setShowGreenBanner(false);
      setBannerMessage(
        "Your report has been scheduled for generation. You will be notified when it is ready for download",
      );
      setReportUrl(false);
      localStorage.setItem(
        "EXPORT_CONSIGNMENTS_REPORT_ID",
        exportReportQueryResponse.data.exportReport.reportId,
      );
      localStorage.setItem("EXPORT_CONSIGNMENTS_REPORT_URL", "");
      startReportPolling(exportReportQueryResponse.data.exportReport.reportId);
    }
    onClose();
  };

  if (!show) {
    return null;
  }
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative mx-auto w-full max-w-md rounded-lg bg-white shadow-lg">
        <div className="flex justify-end p-4">
          <button className="text-2xl font-bold" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="px-6 pb-6">
          <div className="mb-4 flex justify-center">
            <svg
              className="mr-4"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.25 23L21.0833 26.8333L28.75 19.1667M40.25 23C40.25 32.5269 32.5269 40.25 23 40.25C13.4731 40.25 5.75 32.5269 5.75 23C5.75 13.4731 13.4731 5.75 23 5.75C32.5269 5.75 40.25 13.4731 40.25 23Z"
                stroke="#0FA10C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div>
              <p className="mb-2 text-xl font-semibold leading-6">
                The filtered results will be exported to a report.
              </p>

              <p className="mb-4 text-sm text-gray-600">
                You will be notified when the report has been generated!
              </p>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              className="rounded bg-gray-200 px-12 py-2 text-gray-800"
              onClick={onClose}>
              Cancel
            </button>
            <button
              className="ml-2 rounded bg-blue-500 px-6 py-2 text-white"
              onClick={handleDownloadCSV}>
              Download CSV
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsignmentModal;
