import FieldSelector from "#components/common/FieldSelector";
import withCatalogsLogic from "../components/HOC/withCatalogsLogic";
import CatalogTabs from "../components/catalogs/CatalogTabs";
import ExpandedCatalog from "#components/catalogs/ExpandedCatalog";
import Modal from "#components/utils/Modal";
import { useState } from "react";
const CatalogV2 = ({
  products,
  total,
  filters,
  onChangeFilter,
  submitFilters,
  customers,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  productTags,
  getCatalog,
  fetchedCatalog,
  setFetchedCatalog,
  pageSavedFilters,
  setFilters,
  saveBookmark,
  applyBookmarkFilters,
  filterQueryOperator,
  setFilterQueryOperator,
  filteringCriteria,
  productCategories,
  setProductCategories,
  clearFilters,
  downloadProductsList,
  exportListHeaders,
  setExportListHeaders,
  onChangeDropdownFields,
  setReloadCategories,
  reloadCategories,
  handleScroll,
  getSpecificProduct,
  loadFirstTimeData,
  parentProducts,
}) => {
  return (
    <>
      <div
        style={{ height: "100vh" }}
        className="h-100v flex-1 flex-col bg-white">
        <div className="hs-catalog-wrapper h-100v">
          <div className="hs-pageTitle">
            Catalog Management
            <div className="hs-pageSubTitle">
              Manage your catalog and products
            </div>
          </div>
          <div className="py-4">
            <CatalogTabs
              products={products}
              total={total}
              filters={filters}
              onChangeFilter={onChangeFilter}
              submitFilters={submitFilters}
              customers={customers}
              selectProduct={selectProduct}
              selectedProducts={selectedProducts}
              selectAllRows={selectAllRows}
              allRowsSelected={allRowsSelected}
              productTags={productTags}
              getCatalog={getCatalog}
              fetchedCatalog={fetchedCatalog}
              setFetchedCatalog={setFetchedCatalog}
              pageSavedFilters={pageSavedFilters}
              setFilters={setFilters}
              saveBookmark={saveBookmark}
              applyBookmarkFilters={applyBookmarkFilters}
              setFilterQueryOperator={setFilterQueryOperator}
              filterQueryOperator={filterQueryOperator}
              filteringCriteria={filteringCriteria}
              productCategories={productCategories}
              setProductCategories={setProductCategories}
              clearFilters={clearFilters}
              setExportListHeaders={setExportListHeaders}
              setReloadCategories={setReloadCategories}
              reloadCategories={reloadCategories}
              handleScroll={handleScroll}
              getSpecificProduct={getSpecificProduct}
              loadFirstTimeData={loadFirstTimeData}
              parentProducts={parentProducts}
            />
          </div>
        </div>
      </div>
      {fetchedCatalog && (
        <ExpandedCatalog
          fetchedCatalog={fetchedCatalog}
          setFetchedCatalog={setFetchedCatalog}
          customers={customers}
        />
      )}
      {exportListHeaders && (
        <Modal
          title={"Select Fields"}
          negativeAction={() => setExportListHeaders(null)}
          negativeText={"Cancel"}
          positiveText={"Download"}
          onClose={() => setExportListHeaders(null)}
          xIconClicked={() => setExportListHeaders(null)}
          positiveAction={downloadProductsList}>
          <div className="space-y-2">
            <FieldSelector
              fields={[
                "SKU",
                "Product Name",
                "Description",
                "Category",
                "Pricing",
              ]}
              selectedFields={exportListHeaders}
              onSelectionChange={onChangeDropdownFields}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

// @ts-ignore
export default withCatalogsLogic(CatalogV2);
