import React, { useState } from "react";
import NewModal from "#newUiComponents/commons/NewModal";

const PoNote = ({ note, maxLength = 20 }) => {
  const [showMoreNote, setShowMoreNote] = useState(false);
  const handleViewMore = () => {
    setShowMoreNote(true);
  };

  const displayText = note.slice(0, maxLength);

  return (
    <div
      style={{ maxWidth: "300px", whiteSpace: "nowrap", overflow: "hidden" }}>
      <span>{displayText}</span>
      {note.length > maxLength && (
        <span onClick={handleViewMore}>
          ...
          <span className="ml-3 cursor-pointer font-semibold text-primaryAccent underline">
            view more
          </span>
        </span>
      )}
      <NewModal
        isOpen={showMoreNote}
        onClose={() => {
          setShowMoreNote(false);
        }}
        maxWidth="640px"
        title="Notes"
        subtitle={``}>
        <div
          style={{ backgroundColor: "#F1F1F1" }}
          className="w-full break-words rounded-md p-4">
          {note}
        </div>
      </NewModal>
    </div>
  );
};

export default PoNote;
