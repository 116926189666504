import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import Toggle from "#components/utils/Toggle";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { isVanEnglenTenant } from "#utils/tenantCheck";

const BatchConfirmation = ({
  pickers,
  manualBatchConfig,
  setManualBatchConfig,
  stations,
  tenant,
  batchSettings,
  orders,
  fulfillableOrders,
  partiallyFulfillableOrders,
  selectedOrderIdsForBatching,
  showBatchingPreview,
}) => {
  const selectedOrders =
    orders?.filter((order) => selectedOrderIdsForBatching.includes(order.id)) ||
    [];

  // orders returned from backend won't contain child orders for a back order.
  // In case of completely fulfilling the last child of a back order which will be in fulfillableOrders, we need to display it as well in the selected orders.
  const fulfillableBackOrders = fulfillableOrders.filter(
    ({ order }) => order.originalOrder,
  );
  if (fulfillableBackOrders.length > 0) {
    selectedOrders.push(...fulfillableBackOrders.map(({ order }) => order));
  }

  const partiallyFulfillableOrderIds = partiallyFulfillableOrders.map(
    (backOrder) => backOrder.originalOrder.id,
  );

  const showBatchSize =
    batchSettings &&
    batchSettings.pickingStrategy &&
    batchSettings.pickingStrategy !== "PICK_BY_ORDER";

  const showPreppingStation =
    selectedOrders.findIndex((order) => order.source === "FBA") !== -1;

  const showCombineOrders =
    tenant?.features?.combinedOrders === true &&
    selectedOrders.findIndex(
      (i) => i.customAttributes?.combinedOrder === true,
    ) !== -1;

  // to check if there are duplicate orders with same ids
  const uniqueSelectedOrders = selectedOrders.reduce((accumulator, current) => {
    if (!accumulator.some((order) => order.id === current.id)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
  return (
    <>
      <p className="py-2 text-xl font-semibold">Batch Details</p>
      {isVanEnglenTenant(tenant?.subdomain) && (
        <div className="border-gray mb-4 flex w-full items-start rounded border bg-gray-100 p-3">
          <InformationCircleIcon className="text-gray h-10 w-10" />
          <div className="ml-2">
            <p className="text-sm font-normal text-primaryAccent">
              Note: Payments will be placed on hold (Authorized) for all orders
              being fulfilled when you click 'Create'. Customers will not be
              charged until their orders are packed.
            </p>
          </div>
        </div>
      )}

      <div className="flex gap-x-4">
        {showBatchSize && (
          <div className="w-179">
            <label className="mb-1 block text-sm font-medium text-lightGray">
              Batch Size
            </label>
            <input
              type="number"
              id="batchSize"
              label="Batch Size"
              placeholder=""
              onChange={(e) =>
                setManualBatchConfig({
                  ...manualBatchConfig,
                  maxOrdersInBatch: isNaN(parseInt(e.target.value))
                    ? null
                    : parseInt(e.target.value),
                })
              }
              value={parseInt(manualBatchConfig.maxOrdersInBatch)}
              name="Batch Size"
              min="1"
              className="w-full rounded border border-borderGray p-2"
              autoFocus={false}
            />
          </div>
        )}

        <div className="w-179">
          <label className="mb-1 block text-sm font-medium text-lightGray">
            Picker
          </label>
          <AutocompleteDropdownV2
            options={pickers}
            labelKey="name"
            valueKey="id"
            onChange={(selectedOption) => {
              setManualBatchConfig({
                ...manualBatchConfig,
                picker: selectedOption,
              });
            }}
            value={manualBatchConfig.picker}
            placeholder=""
          />
        </div>

        {showPreppingStation && (
          <div className="w-179">
            <label className="mb-1 block text-sm font-medium text-lightGray">
              Prepping Station
            </label>
            <AutocompleteDropdownV2
              options={stations.filter((i) => i.hopstackModule === "Prepping")}
              labelKey="name"
              valueKey="name"
              onChange={(selectedOption) => {
                setManualBatchConfig({
                  ...manualBatchConfig,
                  preppingStation: selectedOption,
                });
              }}
              value={manualBatchConfig.preppingStation}
              placeholder=""
            />
          </div>
        )}

        <div className="w-179">
          <label className="mb-1 block text-sm font-medium text-lightGray">
            Packing Station
          </label>
          <AutocompleteDropdownV2
            options={stations.filter((i) => i.hopstackModule === "Packing")}
            labelKey="name"
            valueKey="name"
            onChange={(selectedOption) => {
              setManualBatchConfig({
                ...manualBatchConfig,
                packingStation: selectedOption,
              });
            }}
            value={manualBatchConfig.packingStation}
            placeholder=""
          />
        </div>
      </div>

      {showCombineOrders && (
        <div className="flex items-center space-x-4 py-4">
          <Toggle
            enabled={manualBatchConfig.combineOrders}
            setEnabled={(e) =>
              setManualBatchConfig({
                ...manualBatchConfig,
                combineOrders: e,
              })
            }
          />
          <div className="block text-sm font-medium text-lightGray">
            Combine Orders?
          </div>
        </div>
      )}

      <p className="py-2 text-base font-semibold">Selected Orders</p>
      {/* table with order and status */}
      <table className="min-w-full divide-y divide-gray-200 border border-gray-300 px-2">
        <thead className="rounded-full bg-primaryAccent p-4 px-12">
          <tr className="border-left px-12 font-montserrat text-textWhite">
            <th
              scope="col"
              className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
              key={"orderId"}>
              Order ID
            </th>
            {showBatchingPreview && (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={"status"}>
                Status
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {uniqueSelectedOrders.map((order, index) => (
            <tr
              key={order.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {order.orderId}
              </td>
              {showBatchingPreview && (
                <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  <p className="w-max rounded-2xl border-2 border-black p-1 text-base">
                    {partiallyFulfillableOrderIds.includes(order.id)
                      ? "Partially Fulfillable"
                      : "Fulfillable"}
                  </p>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default BatchConfirmation;
