import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import Checkbox from "../utils/Checkbox";

const EditReceivedSkuForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  fetchedReceivedSku,
  onSubmit,
  onChangeRadio,
  stockChangeType,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          SKU Details
        </span>
      </div>

      <div className={`flex grid grid-cols-4 items-start`}>
        <div className="font-montserrat text-lg font-medium">Bin Location:</div>
        <div className="col-span-3 font-montserrat text-lg font-medium">
          {fetchedReceivedSku.binLocation}
        </div>
      </div>

      <div className={`flex grid grid-cols-4 items-start`}>
        <div className="font-montserrat text-lg font-medium">SKU:</div>
        <div className="col-span-3 font-montserrat text-lg font-medium">
          {fetchedReceivedSku.sku}
        </div>
      </div>

      <div className={`flex grid grid-cols-4 items-start`}>
        <div className="font-montserrat text-lg font-medium">Stock:</div>
        <div className="col-span-3 font-montserrat text-lg font-medium">
          {fetchedReceivedSku.unallocatedQuantity}
        </div>
      </div>
      <div>
        <TextField
          type="number"
          id="quantity"
          label="Change"
          placeholder=""
          onChange={onChange}
          name="revisedStock"
          value={fetchedReceivedSku.revisedStock}
          min="1"
        />
        <div className="flex gap-2 p-4 px-0">
          <Checkbox
            role="radio"
            label="Add"
            htmlFor="add"
            id="add"
            name="stockChangeType"
            value="add"
            onChange={onChangeRadio}
            checked={stockChangeType === "add"}
          />
          <Checkbox
            role="radio"
            label="Deduct"
            htmlFor="subtract"
            id="subtract"
            name="stockChangeType"
            value="subtract"
            onChange={onChangeRadio}
            checked={stockChangeType === "subtract"}
          />
        </div>
      </div>
      <div>
        <Dropdown
          placeholder={"Select Reason"}
          list={[{ name: "Initial Stock" }, { name: "Other" }]}
          labelKey="name"
          valueKey="name"
          name="reason"
          setSelected={(e) => {
            onChangeDropdown("reason", e);
          }}
          selectedValue={fetchedReceivedSku.reason}
        />
      </div>
      {fetchedReceivedSku.reason === "Other" && (
        <div>
          <TextField
            type="text"
            id="otherReason"
            label="Please describe"
            placeholder=" "
            onChange={onChange}
            name="otherReason"
            value={fetchedReceivedSku.otherReason}
          />
        </div>
      )}
    </div>
  </Modal>
);

export default EditReceivedSkuForm;
