import PropTypes from "prop-types";
import RateShopping from "#components/rateShopping/RateShopping";
import {
  UPDATE_ORDER_CARRIER_INFO,
  CONFIRM_PALLET_INFORMATION,
} from "#mutations";
import { useQuery } from "#hooks/useQuery";
import { useState, useEffect, useContext } from "react";
import { AppStateContext } from "#contexts/appState";
import _ from "lodash";

const ALERT_VISIBILITY_IN_MS = 3000;

const PackerRateShopping = ({
  order,
  orderBatch,
  restartActivity,
  fetchSpecificOrder,
  tenant,
}) => {
  const confirmPalletInformationQuery = useQuery(CONFIRM_PALLET_INFORMATION);
  const updateCarrierInfoQuery = useQuery(UPDATE_ORDER_CARRIER_INFO);
  const [selectedRate, setSelectedRate] = useState(null);
  const appState = useContext(AppStateContext);

  let toAddress = null;
  if (order?.shipmentPlan?.ShipToAddress) {
    const shipToAddress = order.shipmentPlan.ShipToAddress;
    toAddress = {
      line1: shipToAddress.AddressLine1,
      line2: shipToAddress.AddressLine2,
      city: shipToAddress.City,
      name: shipToAddress.Name,
      country: shipToAddress.CountryCode,
      email: "",
      phone: "",
      postalCode: shipToAddress.PostalCode,
      state: shipToAddress.StateOrProvinceCode,
    };
  } else {
    toAddress = {
      line1: order?.shippingAddress?.line1,
      line2: order?.shippingAddress?.line2,
      city: order?.shippingAddress?.city,
      name: order?.shippingAddress?.name,
      country: order?.shippingAddress?.country,
      email: order?.shippingAddress?.email,
      phone: order?.shippingAddress?.phone,
      postalCode: order?.shippingAddress?.zip,
      state: order?.shippingAddress?.state,
    };
  }

  const groupedBoxes = _.groupBy(orderBatch.workingList, "boxName");

  const boxes = orderBatch.boxes.map((box) => ({
    length: box.length,
    height: box.height,
    width: box.width,
    weight: box.weight,
    name: box.name,
    items: groupedBoxes[box.name]?.map((item) => {
      return {
        sku: item.sku,
        fnSku: item.fnSku,
        quantity: item.quantity,
        bestByDate: item.bestByDate,
      };
    }),
  }));

  useEffect(() => {
    if (updateCarrierInfoQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (
      updateCarrierInfoQuery.data &&
      updateCarrierInfoQuery.data.updateCarrierInfo
    ) {
      appState.setAlert(
        updateCarrierInfoQuery.data.updateCarrierInfo.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      fetchSpecificOrder.fetchData({
        orderId: order.id,
      });
    }

    if (updateCarrierInfoQuery.error) {
      appState.setAlert(
        updateCarrierInfoQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  }, [
    updateCarrierInfoQuery.loading,
    updateCarrierInfoQuery.error,
    updateCarrierInfoQuery.data,
  ]);

  useEffect(() => {
    if (confirmPalletInformationQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (confirmPalletInformationQuery.data) {
      appState.setAlert(
        confirmPalletInformationQuery.data.confirmPalletInformation.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (confirmPalletInformationQuery.error) {
      appState.setAlert(
        confirmPalletInformationQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  }, [
    confirmPalletInformationQuery.loading,
    confirmPalletInformationQuery.error,
    confirmPalletInformationQuery.data,
  ]);

  const onPalletSubmit = (pallets) => {
    confirmPalletInformationQuery.fetchData({
      id: orderBatch.id,
      pallets: pallets,
      generateLabels: false,
    });
  };

  const onRateSelect = (selectedRate) => {
    setSelectedRate(selectedRate);
  };

  return (
    <div className="flex w-full justify-center">
      <div className="max-w-1000 mx-auto mb-8 mt-32 rounded-lg border-solid bg-white p-10">
        <RateShopping
          customer={order.customer}
          warehouse={order.warehouse}
          pallets={order.pallets}
          shippingAddress={toAddress}
          boxes={boxes}
          orderSource={order.source}
          storedTransportMode={order.typeOfShipment}
          shipmentReference={order.orderId}
          onPalletSubmit={onPalletSubmit}
          onRateSelect={onRateSelect}
          selectedRate={selectedRate}
          preSelectedCarrierRate={order.preSelectedCarrierRate}
          tenant={tenant}
          validateAddress={order.validateAddress}
          carrierIntegration={order.carrierIntegration}
        />
        {selectedRate && (
          <div className="mt-8 flex space-x-4">
            {restartActivity && (
              <div
                className="mt-2 w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
                onClick={restartActivity}>
                Restart Packing
              </div>
            )}
            <div
              className={`mt-2 w-64 cursor-pointer rounded-md border-2 bg-white py-4 text-center text-2xl text-2C7695`}
              onClick={() => {
                const carrierSelectionData = {
                  orderInfo: {
                    id: order.id,
                    bypassCarrierSelection: true,
                  },
                };
                updateCarrierInfoQuery.fetchData(carrierSelectionData);
              }}>
              Skip
            </div>
            {selectedRate?.selectedShipmentRateId !== null && (
              <div
                className={`mt-2 w-64 cursor-pointer rounded-md bg-2C7695 py-4 text-center text-2xl text-white`}
                onClick={() => {
                  const carrierSelectionData = {
                    orderInfo: {
                      id: order.id,
                      selectedCarrierServiceId:
                        selectedRate?.selectedShipmentRateId,
                      selectedCarrierSource:
                        selectedRate?.selectedShipmentSource,
                      typeOfShipment: selectedRate?.transportMode,
                      confirmedTypeOfShipment: true,
                      carrier: selectedRate?.carrier,
                    },
                  };
                  updateCarrierInfoQuery.fetchData(carrierSelectionData);
                }}>
                Submit
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

PackerRateShopping.propTypes = {
  order: PropTypes.shape({
    warehouse: PropTypes.string.isRequired,
    customer: PropTypes.string.isRequired,
    shippingAddress: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }).isRequired,
  }),
  orderBatch: PropTypes.shape({
    boxes: PropTypes.arrayOf({
      length: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      weight: PropTypes.number.isRequired,
    }).isRequired,
  }),
  restartActivity: PropTypes.func.isRequired,
};

export default PackerRateShopping;
