import React from "react";
import { Tooltip } from "antd";

const ChargesSuggestedChangesCard = ({ charges, currencySymbol }) => {
  const filteredCharges = charges.filter(
    (charge) => charge.amount.originalAmount !== charge.amount.suggestedAmount,
  );
  if (filteredCharges.length === 0) {
    return null;
  }

  return (
    <div className="mb-4 rounded-lg border p-4">
      <h2 className="mb-4 text-lg font-semibold">Charges</h2>
      <div className="grid grid-cols-2 gap-4">
        {filteredCharges.map((charge, index) => (
          <div
            key={index}
            className="mb-4 flex flex-col gap-3 rounded-lg border p-4 last:mb-0">
            <Tooltip title={charge.name}>
              <p className="w-48 truncate text-sm">{charge.name}</p>
            </Tooltip>
            <div className="flex justify-between">
              <p className="flex flex-col text-gray-500">
                <span>Original Amount:</span>
                <span className="text-lg font-semibold text-green-500">
                  {`${currencySymbol} ${charge.amount.originalAmount}`}
                </span>
              </p>
              <p className="flex flex-col text-gray-500">
                <span>Suggested Amount:</span>{" "}
                <span className="text-lg font-semibold text-yellow-500">
                  {`${currencySymbol} ${charge.amount.suggestedAmount}`}
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChargesSuggestedChangesCard;
