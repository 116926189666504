import { useEffect, useState } from "react";
import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon } from "@heroicons/react/outline";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import truncate from "#utils/truncate";
import { getHeaderObject } from "#utils/getHeaderObject";
import cellStyleForTable from "#components/common/CellStyleForTable";
import { BundleCubeIcon } from "#components/products/ProductsList";
import ExpandedBundleDetails from "./ExpandedBundleDetails";

const OrderLineItems = ({
  writable,
  orderLineItems,
  setSelectedOrderLineItem,
  order,
  multiAccountSupportEnabled,
  marketplace,
  sellerId,
  warehouses,
  bundleLineItemDetails,
  setBundleLineItemDetails,
}) => {
  /**
   * group order line items with unique key
   * key = sku-binLocation-lotId-serialNumber-nestedFormFactorId-formFactor
   * @param {*} orderLineItems
   * @returns groupedOrderLineItems
   */
  const groupOrderLineItems = (orderLineItems) => {
    const groupedOrderLineItems = [];
    orderLineItems.forEach((orderLineItem) => {
      const key = `${orderLineItem.sku}-${orderLineItem.binLocation}-${orderLineItem.lotId}-${orderLineItem.serialNumber}-${orderLineItem.nestedFormFactorId}- ${orderLineItem.formFactor}`;
      const existingOrderLineItem = groupedOrderLineItems.find(
        (item) => item.key === key,
      );
      if (existingOrderLineItem) {
        existingOrderLineItem.quantity += orderLineItem.quantity;
      } else {
        groupedOrderLineItems.push({
          ...orderLineItem,
          key,
        });
      }
    });
    return groupedOrderLineItems;
  };

  const headers = (() => {
    const returnHeaders = [
      getHeaderObject("SKU", "sku"),
      getHeaderObject("Location", "binLocation"),
      getHeaderObject("Name", "productName"),
    ];

    if (["FBA", "Amazon FBM"].includes(order.source)) {
      returnHeaders.push(getHeaderObject("ASIN", "asin"));
    }
    if (order.source === "FBA") {
      returnHeaders.push(getHeaderObject("FNSKU", "fnSku"));
    }
    returnHeaders.push(getHeaderObject("Quantity", "quantity"));
    if (
      order.orderStatus === "UNPROCESSED" ||
      order.orderStatus === "EXCEPTION"
    ) {
      returnHeaders.push(
        getHeaderObject("Quantity In Stock", "availableToShip"),
      );
    }
    returnHeaders.push(getHeaderObject("Form Factor", "formFactor"));
    if (orderLineItems.findIndex((i) => !!i.lotId) !== -1) {
      returnHeaders.push(getHeaderObject("Lot/Batch ID", "lotId"));
    }
    if (orderLineItems.findIndex((i) => !!i.serialNumber) !== -1) {
      returnHeaders.push(getHeaderObject("Serial Number", "serialNumber"));
    }
    if (orderLineItems.findIndex((i) => !!i.nestedFormFactorId) !== -1) {
      returnHeaders.push(getHeaderObject("LPN", "nestedFormFactorId"));
    }
    if (multiAccountSupportEnabled) {
      returnHeaders.push(getHeaderObject("Marketplace", "marketplace"));
      returnHeaders.push(getHeaderObject("Seller", "sellerId"));
    }

    return returnHeaders;
  })();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const [groupedBundleLineItemDetails, setGroupedBundleLineItemDetails] =
    useState(null);
  useEffect(() => {
    if (order?.orderProducts?.length > 0 && groupedBundleLineItemDetails) {
      // This updatedLineItem will have details for the groupedOrderLineItem, with corresponding constituent products
      const updatedItem = { ...groupedBundleLineItemDetails };
      const orderProduct = order.orderProducts.find(
        (product) => product.sku === updatedItem.sku,
      );
      if (orderProduct?.products?.length > 0) {
        const constituentProducts = orderProduct.products;
        const updatedConstituentProducts = constituentProducts.map(
          (product) => {
            const updatedConstituentProduct = { ...product };
            const lineItemQuantity = updatedItem.quantity;
            updatedConstituentProduct.quantityInBundle =
              product.quantityInBundle;
            // Since this is for groupedOrderLineItems, we need to multiply the quantityToFulfill by the parentItemQuantity present in order
            updatedConstituentProduct.quantityToFulfill =
              product.quantityInBundle * lineItemQuantity;
            // TODO: Check for availableToShip qunatity, I am sending th value I am getting from product.availableToShip
            // Might need to check whether that is correct or not
            return updatedConstituentProduct;
          },
        );
        updatedItem.products = updatedConstituentProducts;
        setBundleLineItemDetails(updatedItem);
      }
    }
  }, [groupedBundleLineItemDetails]);

  return (
    <div className="relative h-fit w-full overflow-auto border border-gray-300 text-[16px] xl:text-sm">
      <table className="relative w-full divide-y divide-gray-200 whitespace-nowrap text-[16px]">
        <thead className="sticky left-0 top-0 z-10 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {groupOrderLineItems(orderLineItems).length === 0 ? (
            <tr className="bg-white">
              <td className="rounded-br rounded-tr px-1 py-2 pl-2 text-left font-medium tracking-wider text-tableText">
                {"No line items found for this order"}
              </td>
            </tr>
          ) : null}
          {groupOrderLineItems(orderLineItems).map((item, rowIndex) => (
            <tr
              key={item.id}
              className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              {headers.map((header, columnIndex) => {
                let value = header.correspondingValue;
                let cellStyle = cellStyleForTable(
                  value,
                  [],
                  columnIndex + 1,
                  rowIndex,
                  true,
                );

                if (value === "sku") {
                  return (
                    <td className={classNames(cellStyle, "select-text")}>
                      {item.typeOfProduct === "BUNDLE" ? (
                        <div
                          className="flex cursor-pointer gap-1"
                          onClick={() => {
                            setGroupedBundleLineItemDetails(item) && (
                              <ExpandedBundleDetails
                                bundleLineItemDetails={bundleLineItemDetails}
                                setBundleLineItemDetails={
                                  setBundleLineItemDetails
                                }
                              />
                            );
                          }}>
                          <BundleCubeIcon />
                          {item.sku}
                        </div>
                      ) : (
                        <div>{item.sku}</div>
                      )}
                    </td>
                  );
                }
                if (value === "binLocation") {
                  return <td className={cellStyle}>{item.binLocation}</td>;
                }
                if (value === "productName") {
                  return (
                    <td className={cellStyle}>
                      {truncate(item.productName, 40)}
                    </td>
                  );
                }
                if (value === "asin") {
                  return (
                    <td className={cellStyle}>
                      {buildMarketplaceHyperlink(
                        item.asin,
                        "ASIN",
                        item.source,
                      )}
                    </td>
                  );
                }
                if (value === "fnSku") {
                  return <td className={cellStyle}>{item.fnSku}</td>;
                }
                if (value === "quantity") {
                  return <td className={cellStyle}>{item.quantity}</td>;
                }
                if (value === "availableToShip") {
                  return <td className={cellStyle}>{item.availableToShip}</td>;
                }
                if (value === "formFactor") {
                  return <td className={cellStyle}>{item.formFactor}</td>;
                }
                if (value === "lotId") {
                  return <td className={cellStyle}>{item.lotId || "-"}</td>;
                }
                if (value === "serialNumber") {
                  return (
                    <td className={cellStyle}>{item.serialNumber || "-"}</td>
                  );
                }
                if (value === "nestedFormFactorId") {
                  return (
                    <td className={cellStyle}>
                      {item.nestedFormFactorId || "-"}
                    </td>
                  );
                }
                if (value === "marketplace") {
                  return <td className={cellStyle}>{marketplace}</td>;
                }
                if (value === "sellerId") {
                  return <td className={cellStyle}>{sellerId}</td>;
                }
                if (value === "status") {
                  return <td className={cellStyle}>{item.status}</td>;
                }
                if (value === "action") {
                  return (
                    <td className={cellStyle}>
                      <TableFieldButton
                        onClick={() => setSelectedOrderLineItem(item)}
                        text={<PencilIcon className="h-6 w-6" />}
                        disabled={!writable}
                      />
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderLineItems;
