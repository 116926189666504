import SortDropdown from "#components/common/SortDropdown";
import SalesOrderAddToCart from "./SalesOrderAddToCart";
import { useState } from "react";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { v4 as uuidv4 } from "uuid";
import truncate from "#utils/truncate";
const ListViewProducts = ({
  products,
  setProducts,
  getCatalog,
  handleAddToCart,
  handleProductScroll,
  cart,
  salesCustomers,
  selectedOrder,
}) => {
  const [showAddToCartModal, setShowAddToCartModal] = useState(false);
  const [addToCartProduct, setAddToCartProduct] = useState({});

  return (
    <>
      <div
        className="overflow-x-auto overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
        style={{ maxHeight: "500px" }}
        onScroll={handleProductScroll}>
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-lg font-normal text-[#717679] sm:pl-6">
                Product Info
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
                HZ value
              </th>
              {/* <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              Unit Type
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              Price
            </th> */}
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {!products.length && (
              <tr>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <div className="text-lg text-[#111827]">
                    No products found
                  </div>
                </td>
              </tr>
            )}
            {products?.length > 0 &&
              products.map((product, rowIndex) => (
                <tr key={uuidv4()}>
                  <td className="overflow-ellipsis py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div
                      className="flex cursor-pointer items-center"
                      onClick={() => getCatalog(product.id)}>
                      <div className="h-16 w-16 shrink-0">
                        {product.images?.[0]?.url && (
                          <img
                            className="h-16 w-16 rounded-lg"
                            src={product.images?.[0]?.url}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="ml-4">
                        <div className="mt-1 text-xl font-bold text-[#111827]">
                          {truncate(product.name, 24)}
                        </div>
                        <div className="text-lg font-medium text-[#717679]">
                          SKU: {product.sku}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-lg text-[#111827]">
                    {`${product?.attributes?.fromHZ || ""} - ${product?.attributes?.toHZ || ""}` ||
                      "-"}
                  </td>
                  {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-lg text-[#111827]">
                  <SortDropdown
                    sort={product.units}
                    showSortBy={false}
                    showIcon={false}
                    setSort={(newUnits) => {
                      const updatedProducts = products.map((p) =>
                        p.sku === product.sku ? { ...p, units: newUnits } : p,
                      );
                      setProducts(updatedProducts);
                    }}
                    choices={[
                      { name: "5 Units", value: "5" },
                      { name: "10 Units", value: "10" },
                      { name: "15 Units", value: "15" },
                    ]}
                  />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-lg text-[#111827]">
                  {product.pricing?.computedPriceFields?.totalPrice
                    ? `$${product.pricing?.computedPriceFields?.totalPrice.toFixed(
                        2,
                      )}`
                    : product.price || "-"}
                </td> */}

                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-lg text-[#111827]">
                    <span
                      className="cursor-pointer font-semibold text-primaryAccent underline"
                      onClick={() => {
                        setAddToCartProduct(product);
                        setShowAddToCartModal(true);
                      }}>
                      Select
                    </span>
                    {/* <button onClick={() => handleAddToCart(product)}>
                    <ShoppingCartIcon className="h-6 w-6" />
                  </button> */}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {showAddToCartModal && (
        <SalesOrderAddToCart
          products={products}
          product={addToCartProduct}
          setProducts={setProducts}
          handleAddToCart={handleAddToCart}
          dismiss={() => setShowAddToCartModal(false)}
          cart={cart}
          salesCustomers={salesCustomers}
          selectedConsignment={selectedOrder}
        />
      )}
    </>
  );
};

export default ListViewProducts;
