const arr = [24, 64, 96, 108];

const ProgressBar = ({ percent }) => {
  return (
    <div className="align-center absolute my-2 ml-6 h-2 w-11/12 rounded-md bg-EBEBEB">
      <div
        className={`w-${arr[percent]} h-2 rounded-md bg-primaryAccent duration-1000`}></div>
    </div>
  );
};

export default ProgressBar;
