import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { Button, Form, Input, Space, Tooltip } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { TrashIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { CREATE_PRODUCT_ALIAS, DELETE_PRODUCT_ALIAS } from "../../../mutations";
import { GET_PRODUCT_ALIASES } from "#queries/index";
import { AppStateContext } from "#contexts/appState";

const CreateProductAlias = ({ customers, product }) => {
  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isLoadingProductsAliases, setIsLoadingProductsAliases] =
    useState(false);
  const [isCreatingProductAlias, setIsCreatingProductAlias] = useState(false);
  const [alias, setAlias] = useState("");
  const [aliases, setAliases] = useState(null);
  const createProductAliasQuery = useQuery(CREATE_PRODUCT_ALIAS);
  const deleteProductAliasQuery = useQuery(DELETE_PRODUCT_ALIAS);
  const getProductAliasQuery = useQuery(GET_PRODUCT_ALIASES);
  const appState = useContext(AppStateContext);
  const indexedCustomers = useMemo(
    () =>
      customers.reduce((acc, customer) => {
        return {
          ...acc,
          [customer.id]: customer,
        };
      }, {}),
    [customers],
  );

  const addAlias = () => {
    setIsCreatingProductAlias(true);
    createProductAliasQuery
      .fetchData({
        entityName: "customers",
        entityId: selectedCustomer,
        productId: product.id,
        alias,
      })
      .then((response) => {
        if (response.error) {
          appState.setAlert(
            response.error?.message || "Error while linking products",
            "error",
            5000,
          );
        } else {
          appState.setAlert(
            "Products Alias created successfully",
            "success",
            5000,
          );
          setAlias(null);
          setSelectedCustomer(null);
          setCreateFormOpen(false);
          getAliases();
        }
      })
      .finally(() => {
        setIsCreatingProductAlias(false);
      });
  };

  const deleteAlias = (productAliasId) => {
    appState.setLoading();
    deleteProductAliasQuery
      .fetchData({
        productAliasId,
      })
      .then(() => {
        getAliases();
      })
      .finally(() => {
        appState.removeLoading();
      });
  };

  const getAliases = () => {
    setIsLoadingProductsAliases(true);
    getProductAliasQuery
      .fetchData({ id: product.id })
      .then((productAliases) => {
        setAliases(productAliases?.data?.getProductAliases || []);
      })
      .finally(() => {
        setIsLoadingProductsAliases(false);
      });
  };

  useEffect(() => {
    getAliases();
  }, []);

  return (
    <>
      <h3>Customer IDs</h3>
      <div className="grid grid-cols-2 gap-4">
        {(aliases &&
          aliases.map((alias) => {
            return (
              <div className="border-grey-400 relative border p-4">
                <div>{indexedCustomers[alias.entityId].name}</div>
                <div>{alias.alias}</div>
                <TrashIcon
                  className="absolute right-4 top-4 cursor-pointer text-red-500"
                  width={20}
                  onClick={() => deleteAlias(alias.id)}
                />
              </div>
            );
          })) ||
          "Loading..."}
      </div>
      {(!createFormOpen && (
        <div className="mt-4 flex justify-between rounded-md border border-blue-700 bg-blue-100 p-2">
          <div className="flex-1 text-gray-400">
            Enter customer-specific identifiers for this Product, enabling
            recognition across various operations within Hopstack
          </div>
          <div
            onClick={() => {
              setCreateFormOpen(true);
            }}
            className="color-blue-700 flex cursor-pointer items-center font-bold underline">
            Add Customer ID
          </div>
        </div>
      )) || (
        <div className="mt-4 rounded-sm border bg-gray-100 p-4">
          <div
            className="mb-4 mt-1 flex rounded-lg border border-yellow-400 bg-yellow-50 p-3 text-sm text-gray-600 dark:bg-gray-800"
            role="alert">
            <div className="flex items-center">
              <WarningOutlined
                height={32}
                width={32}
                className="inline-block text-yellow-400"
              />{" "}
            </div>
            <div className="grow-1 pl-2 text-gray-400">
              Ids cannot be edited or deleted once created. Multiple IDs can be
              associated for the same customer
            </div>
          </div>
          <div className="catalog-textfield-wrapper pr-0">
            <span className="text-base text-gray-500">
              Customer Name{" "}
              <Tooltip title="Select the Customer">
                <InfoCircleOutlined />
              </Tooltip>{" "}
              <label className="text-red-500">*</label>
            </span>
            <AutocompleteSingleSelectDropdown
              options={customers}
              labelKey={"name"}
              valueKey={"id"}
              onChange={(e) => {
                setSelectedCustomer(e);
              }}
              showCheckedIndicator={false}
              value={selectedCustomer}
            />
          </div>
          <div name="productName">
            <span className="text-base text-gray-500">
              Customer ID{" "}
              <Tooltip title="Enter the Alias">
                <InfoCircleOutlined />
              </Tooltip>{" "}
              <label className="text-red-500">*</label>
            </span>
            <Input
              size="large"
              className="h-14 rounded-md"
              defaultValue={""}
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
            />
          </div>
          <Space
            style={{ display: "flex", justifyContent: "flex-end" }}
            className="mt-2">
            <Form.Item>
              <Button
                className="mt-2 flex h-14 w-32 cursor-pointer items-center justify-center rounded-md border-primaryAccent p-2 text-lg font-medium text-primaryAccent"
                onClick={() => setCreateFormOpen(false)}>
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={addAlias}
                disabled={isCreatingProductAlias}
                className="mt-2 flex h-14 w-32 cursor-pointer items-center justify-center rounded-md border bg-primaryAccent p-2 text-lg font-medium text-white">
                Save
              </Button>
            </Form.Item>
          </Space>
        </div>
      )}
    </>
  );
};

export default CreateProductAlias;
