import Modal from "#components/utils/Modal";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";

const AssignWarehouseForm = ({
  onClose,
  title,
  onSubmit,
  warehouses,
  setSelectedWarehouse,
  selectedWarehouse,
  cannotChangeOrderWarehouse,
}) => (
  <Modal
    title={title}
    negativeAction={onClose}
    positiveAction={cannotChangeOrderWarehouse ? onClose : onSubmit}
    id="AssignWarehouseFormModal">
    <div>
      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
        Assign Warehouse
      </span>
    </div>
    <div className="space-y-4">
      {!cannotChangeOrderWarehouse ? (
        <>
          <div>
            <Dropdown
              placeholder={"Select Warehouse"}
              list={warehouses}
              labelKey="name"
              valueKey="id"
              name="warehouse"
              setSelected={(selectedOption) =>
                setSelectedWarehouse(selectedOption)
              }
              selectedValue={selectedWarehouse}
            />
          </div>
        </>
      ) : (
        <span className="text-lg font-semibold text-red-500">
          This order cannot be assigned to another warehouse
        </span>
      )}
    </div>
  </Modal>
);

export default AssignWarehouseForm;
