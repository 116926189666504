import _ from "lodash";
import JSPM from "jsprintmanager";
import { constructShippingLabel } from "#utils/barcodeGenerator";

export const printShippingLabel = async (
  shippingLabel,
  appState,
  printer = null,
  duplex = false,
) => {
  let printCommands = [];
  printCommands.push(constructShippingLabel());

  console.log(printCommands[0]);

  try {
    const printers = await JSPM.JSPrintManager.getPrinters();
    if (!printers || printers.length === 0) {
      appState.setAlert("No printers found", "error", 5000);
      return;
    }

    const cpj = new JSPM.ClientPrintJob();
    let myPrinter;
    if (printer) {
      if (printer.indexOf("zebra") !== -1) {
        // ADDING THIS LOGIC BECAUSE ZEBRA PRINTERS DON'T ALWAYS SHOW UP IN THE LIST OF PRINTERS WITH THE SAVED NAME
        // SOMETIMES IT FALLS BACK TO ZDESIGNER SO ACCOUNTING FOR THAT
        printer = printers.find(
          (p) =>
            p.toLowerCase().indexOf("zebra") !== -1 ||
            p.toLowerCase().indexOf("zdesigner") !== -1,
        );
      }
      myPrinter = new JSPM.InstalledPrinter(printer);
    } else {
      myPrinter = new JSPM.DefaultPrinter();
    }

    if (!myPrinter) {
      myPrinter = new JSPM.DefaultPrinter();
    }
    myPrinter.duplex =
      duplex === true
        ? JSPM.DuplexMode.DuplexLongEdge
        : JSPM.DuplexMode.Simplex;

    cpj.clientPrinter = myPrinter;
    let myFile;
    if (shippingLabel.indexOf(".png") === -1) {
      myFile = new JSPM.PrintFilePDF(
        shippingLabel,
        JSPM.FileSourceType.URL,
        "file.pdf",
      );
    } else {
      myFile = new JSPM.PrintFile(
        shippingLabel,
        JSPM.FileSourceType.URL,
        "file-PW=4-PH=6.png",
      );
    }
    myFile.printAutoCenter = true;
    myFile.pageSizing = JSPM.Sizing["None"];
    cpj.files.push(myFile);
    cpj.sendToClient();
  } catch (err) {}
  return;
};
