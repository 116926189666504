const ExpandedShipmentPlan = ({ shipmentPlan }) => {
  return (
    <div>
      <div className="mb-4">
        <p className="pb-4 font-montserrat text-xl font-bold">
          Shipment Details:
        </p>
        <div className="w-max rounded-xl border border-gray-500 p-3">
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Shipment ID: </span>
            <span className="col-span-2 ml-4 font-medium">
              {shipmentPlan.ShipmentId}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Fulfillment Center: </span>
            <span className="col-span-2 ml-4 font-medium">
              {shipmentPlan.DestinationFulfillmentCenterId}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Label Prep Type: </span>
            <span className="col-span-2 ml-4 font-medium">
              {shipmentPlan.LabelPrepType}
            </span>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <p className="pb-4 font-montserrat text-xl font-bold">
          Shipment Address:
        </p>
        <div className="w-max rounded-xl border border-gray-500 p-3">
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Name: </span>
            <span className="col-span-2 ml-4 font-medium">
              {shipmentPlan.ShipToAddress?.Name}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Address: </span>
            <span className="col-span-2 ml-4 font-medium">
              {shipmentPlan.ShipToAddress?.AddressLine1}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">City: </span>
            <span className="col-span-2 ml-4 font-medium">
              {shipmentPlan.ShipToAddress?.City}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">State: </span>
            <span className="col-span-2 ml-4 font-medium">
              {shipmentPlan.ShipToAddress?.StateOrProvinceCode}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Country: </span>
            <span className="col-span-2 ml-4 font-medium">
              {shipmentPlan.ShipToAddress?.CountryCode}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Postal Code: </span>
            <span className="col-span-2 ml-4 font-medium">
              {shipmentPlan.ShipToAddress?.PostalCode}
            </span>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <p className="pb-4 font-montserrat text-xl font-bold">Items:</p>
        <PlanOrderLineItems items={shipmentPlan.Items} />
      </div>
    </div>
  );
};

const PlanOrderLineItems = ({ items }) => {
  const headers = ["Seller SKU", "FN SKU", "Quantity"];
  return (
    <div className="relative h-fit w-max overflow-auto border border-gray-300 text-[16px] xl:text-sm">
      <table className="relative w-full divide-y divide-gray-200 whitespace-nowrap text-[16px]">
        <thead className="sticky left-0 top-0 z-10 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.length === 0 ? (
            <tr className="bg-white">
              <td className="rounded-br rounded-tr px-1 py-2 pl-2 text-left font-medium tracking-wider text-tableText">
                {"No items found in this shipment plan."}
              </td>
            </tr>
          ) : null}
          {items.map((item, index) => (
            <tr
              key={item.SellerSKU}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td className={`p-5 text-left font-semibold text-primaryAccent`}>
                {item.SellerSKU}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {item.FulfillmentNetworkSKU}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {item.Quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExpandedShipmentPlan;
