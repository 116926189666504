import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import Tabs from "#components/utils/Tabs";
import VariableConfigurator from "#components/rules/VariableConfigurator";
import Groups from "#components/rules/Groups";
import Rules from "#components/rules/Rules";

const RuleEngine = ({ writable }) => (
  <>
    {/* <div className="fixed w-screen h-screen top-0 left-100 bg-gray-50/50 z-0"></div> */}
    <div className="p-5">
      <PageTitle>Rule Engine</PageTitle>
      <Tabs
        tabs={{
          "Configure Variables": <VariableConfigurator writable={writable} />,
          Groups: <Groups writable={writable} />,
          Rules: <Rules writable={writable} />,
        }}
      />
    </div>
  </>
);

export default RuleEngine;
