import Modal from "#components/utils/Modal";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "#contexts/auth";
import { getHeaderObject } from "../../../utils/getHeaderObject";
import { renderStatus } from "../../../utils/renderStatus";
import _ from "lodash";

const ExpandedBatch = ({ batchItems, negativeAction, tableName, batch }) => {
  const [headers, setHeaders] = useState([
    getHeaderObject("SKU", "sku"),
    getHeaderObject("Bin Location", "binLocation"),
    getHeaderObject("Code Scanned", "barcodeScanned"),
    getHeaderObject("Quantity", "quantity"),
    getHeaderObject("Status", "status"),
  ]);

  const auth = useContext(AuthContext);
  useEffect(() => {
    if (!!getTablePreferences()) {
      setHeaders(getTablePreferences());
      setShowHeaders(getTablePreferences().filter((header) => header.enabled));
    }
  }, [auth.user, headers]);

  function getTablePreferences() {
    return (
      auth.user &&
      auth.user.tablePreferences &&
      auth.user.tablePreferences.find((table) => table.name == tableName)
        ?.columns
    );
  }

  const [editColumns, setEditColumns] = useState(false);
  const [showHeaders, setShowHeaders] = useState(headers);

  const activityHeaders = () => {
    const returnHeaders = [
      { name: "User", correspondingValue: "user" },
      { name: "First Activity", correspondingValue: "firstCompletedTime" },
      { name: "Last Activity", correspondingValue: "lastCompletedTime" },
      { name: "Tote", correspondingValue: "tote" },
    ];

    if (batch.typeOfBatch === "PICKING") {
      returnHeaders.push({
        name: "Requested Dropoff Packing Station",
        correspondingValue: "requestedDropoffPackingStation",
      });
      returnHeaders.push({
        name: "Dropoff Station",
        correspondingValue: "dropoffStation",
      });
    }

    returnHeaders.push({ name: "Status", correspondingValue: "status" });

    return returnHeaders;
  };
  return (
    <>
      <Modal
        onClose={() => {}}
        negativeAction={negativeAction}
        title={`Batch Details`}
        noPadding={true}
        positiveAction={negativeAction}
        positiveText="OK">
        <div className="bg-EFE9DC p-4">
          <div className="pb-2 text-xl text-black">
            Line Items ({_.sumBy(batchItems, (item) => item.skuItems.length)})
          </div>
          <table className="min-w-full divide-y divide-gray-200 px-2">
            <thead className="rounded-full bg-primaryAccent p-4 px-12">
              <tr className="border-left px-12 font-montserrat text-textWhite">
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {batchItems.map((batchItem, index) =>
                batchItem.skuItems.map((item) => (
                  <tr
                    key={item.id}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                    {showHeaders.map((header, idx) => {
                      let value = header.correspondingValue;
                      let cellStyle =
                        (value === "id"
                          ? "text-primaryAccent cursor-pointer"
                          : "text-5F666B") +
                        (idx === 0
                          ? ` p-5 text-left font-semibold tracking-wider rounded-tl rounded-bl border-l-8 ${
                              index % 2 === 0
                                ? "border-F4C261"
                                : "border-primaryAccent"
                            }`
                          : " pl-4 px-1 py-1 text-left font-medium tracking-wider rounded-br rounded-tr");

                      if (value === "binLocation") {
                        return (
                          <td key={idx} className={cellStyle}>
                            {batchItem.binLocation}
                          </td>
                        );
                      }
                      if (value === "status") {
                        return (
                          <td key={idx} className={cellStyle}>
                            {renderStatus(batchItem.status)}
                          </td>
                        );
                      }
                      if (value === "quantity") {
                        return (
                          <td key={idx} className={cellStyle}>
                            {item.scannedSkus}
                          </td>
                        );
                      }
                      if (value === "sku") {
                        return (
                          <td key={idx} className={cellStyle}>
                            {item.sku}
                          </td>
                        );
                      }
                      if (value === "barcodeScanned") {
                        return (
                          <td key={idx} className={cellStyle}>
                            {item.barcodeScanned}
                          </td>
                        );
                      }
                    })}
                  </tr>
                )),
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};
export default ExpandedBatch;
