import { useState } from "react";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

const defaultButtonClassName = `flex items-center justify-center font-medium gap-x-1 w-full bg-primaryAccent text-white rounded-lg py-2 px-3 cursor-pointer`;
const defaultPanelClassName = `mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-4 border border-borderGray w-full`;

const CustomPopover = ({
  title,
  showChevron = true,
  children,
  buttonClassName = defaultButtonClassName,
  panelClassName = defaultPanelClassName,
  staticBackdrop = false,
  showOverlay = false,
  customClassName,
}) => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const ChevronIcon = ({ isOpen }) => {
    if (isOpen) {
      return <ChevronUpIcon className="ml-1 h-6 w-6" />;
    }
    return <ChevronDownIcon className="ml-1 h-6 w-6" />;
  };

  return (
    <Popover as="div" className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={[buttonClassName, customClassName]
              .filter(Boolean)
              .join(" ")}
            as="button">
            {/* This button acts as the reference for positioning the popover */}
            {title}
            {showChevron && <ChevronIcon isOpen={open} />}
          </Popover.Button>

          {showOverlay && (
            <Popover.Overlay className="fixed inset-0 z-10 bg-black opacity-30" />
          )}

          <Popover.Panel
            static={staticBackdrop}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            as="div"
            className={panelClassName}>
            {/* Render the passed children inside the popover */}
            {children}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default CustomPopover;
