import { useState, useContext, useEffect } from "react";
import { GET_SHIPPERS, GET_CURRENT_TRUCK } from "#queries";
import {
  GENERATE_TRUCK_ID,
  GENERATE_BARCODE,
  RESET_ALL_BARCODES,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { useQuery } from "#hooks/useQuery";
import { printShipperBarcodeThermal } from "#utils/printShipperBarcode";

const ALERT_TIMEOUT_IN_MS = 5000;

const withShipperLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const [shippers, setShippers] = useState([]);
    const shippersQuery = useQuery(GET_SHIPPERS);
    const currentTruckQuery = useQuery(GET_CURRENT_TRUCK);
    const auth = useContext(AuthContext);
    const [truckIdentifier, setTruckIdentifier] = useState("A"); // TODO: THIS IS ONLY FOR DELMAR AT THE MOMENT. NEED TO FIGURE OUT A WAY TO GENERALISE THIS.
    const [currentTruck, setCurrentTruck] = useState(null);
    const generateTruckIdQuery = useQuery(GENERATE_TRUCK_ID);
    const generateBarcodeQuery = useQuery(GENERATE_BARCODE);
    const resetAllBarcodesQuery = useQuery(RESET_ALL_BARCODES);

    useEffect(() => {
      if (localStorage.getItem("currentWarehouse") && !auth.currentWarehouse) {
        auth.setCurrentWarehouse(localStorage.getItem("currentWarehouse"));
      }
    }, [localStorage.getItem("currentWarehouse")]);

    useEffect(() => {
      if (auth.currentWarehouse) {
        shippersQuery.fetchData({
          filters: { warehouses: [auth.currentWarehouse] },
          all: true,
        });
        currentTruckQuery.fetchData({
          filters: {
            warehouse: auth.currentWarehouse,
          },
        });
      } else {
        setShippers([]);
      }
    }, [auth.currentWarehouse]);

    useEffect(() => {
      if (currentTruckQuery.data) {
        setCurrentTruck(currentTruckQuery.data.getCurrentTruck);
      }

      if (currentTruckQuery.error) {
        setCurrentTruck(null);
      }
    }, [currentTruckQuery.data, currentTruckQuery.error]);

    useEffect(() => {
      if (shippersQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (shippersQuery.data) {
        setShippers(shippersQuery.data.shippers.entities);
      }
    }, [shippersQuery.data, shippersQuery.loading]);

    const submitWarehouseCode = (warehouse) => {
      if (!warehouse || !warehouse.value) {
        return appState.setAlert(
          `Please select a warehouse.`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      auth.setCurrentWarehouse(warehouse.value);
    };

    useEffect(() => {
      if (generateBarcodeQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (generateBarcodeQuery.data) {
        appState.setAlert(
          `Successfully generated a barcode with those details.`,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        shippersQuery.fetchData({
          filters: {
            warehouses: [auth.currentWarehouse],
          },
        });
      }

      if (generateBarcodeQuery.error) {
        appState.setAlert(
          generateBarcodeQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      generateBarcodeQuery.loading,
      generateBarcodeQuery.data,
      generateBarcodeQuery.error,
    ]);

    const generateBarcode = (shipper) => {
      generateBarcodeQuery.fetchData({
        id: shipper.id,
        filters: {
          warehouse: auth.currentWarehouse,
        },
      });
    };

    useEffect(() => {
      if (generateTruckIdQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (generateTruckIdQuery.data) {
        appState.setAlert(
          `Successfully generated a truck with those details.`,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        currentTruckQuery.fetchData({
          filters: {
            warehouse: auth.currentWarehouse,
          },
        });
      }

      if (generateTruckIdQuery.error) {
        appState.setAlert(
          generateTruckIdQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      generateTruckIdQuery.loading,
      generateTruckIdQuery.data,
      generateTruckIdQuery.error,
    ]);

    const generateTruck = (truckType) => {
      generateTruckIdQuery.fetchData({
        filters: {
          warehouse: auth.currentWarehouse,
          truckType,
          truckIndicator: truckIdentifier,
        },
      });
    };

    useEffect(() => {
      if (resetAllBarcodesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (resetAllBarcodesQuery.data) {
        appState.setAlert(
          resetAllBarcodesQuery.data.resetAllBarcodes.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        shippersQuery.fetchData({
          filters: {
            warehouses: [auth.currentWarehouse],
          },
        });
        currentTruckQuery.fetchData({
          filters: {
            warehouse: auth.currentWarehouse,
          },
        });
      }
    }, [
      resetAllBarcodesQuery.loading,
      resetAllBarcodesQuery.data,
      resetAllBarcodesQuery.error,
    ]);

    const resetAllBarcodes = () => {
      resetAllBarcodesQuery.fetchData({
        filters: {
          warehouse: auth.currentWarehouse,
        },
      });
    };

    const printShipperBarcode = (shipper) => {
      if (!shipper) {
        return appState.setAlert(
          `Please select a shipper.`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (!shipper.currentBarcode) {
        return appState.setAlert(
          `Generate a barcode for this shipper first before printing.`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      appState.setAlert(`Printing barcode...`, "success", ALERT_TIMEOUT_IN_MS);
      printShipperBarcodeThermal(shipper);
    };

    return (
      <WrappedComponent
        shippers={shippers}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        submitWarehouseCode={submitWarehouseCode}
        currentWarehouse={auth.currentWarehouse}
        removeWarehouse={() => auth.clearCurrentWarehouse()}
        truckIdentifier={truckIdentifier}
        setTruckIdentifier={setTruckIdentifier}
        generateTruck={generateTruck}
        currentTruck={currentTruck}
        setCurrentTruck={setCurrentTruck}
        generateBarcode={generateBarcode}
        resetAllBarcodes={resetAllBarcodes}
        printShipperBarcode={printShipperBarcode}
      />
    );
  };
};

export default withShipperLogic;
