import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_STOCK_TRANSFERS,
  GET_STOCK_TRANSFER,
  GET_WAREHOUSES,
  GET_INVENTORY,
  GET_USERS,
  GET_BIN_LOCATIONS,
  SEARCH_LOCATIONS,
  GET_SKU_BIN_MAPPINGS,
} from "#queries";
import {
  SAVE_STOCK_TRANSFER,
  DELETE_STOCK_TRANSFER,
  EXECUTE_STOCK_TRANSFER_COUNT_PLAN,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";

const withStockTransferLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const stockTransfersQuery = useQuery(GET_STOCK_TRANSFERS);
    const warehousesQuery = useQuery(GET_WAREHOUSES);
    const getStockTransferQuery = useQuery(GET_STOCK_TRANSFER);
    const saveStockTransferQuery = useQuery(SAVE_STOCK_TRANSFER);
    const getSkuBinMappingsQuery = useQuery(GET_SKU_BIN_MAPPINGS);
    const deleteStockTransferQuery = useQuery(DELETE_STOCK_TRANSFER);
    const binLocationsQuery = useQuery(GET_BIN_LOCATIONS);
    const searchLocationsQuery = useQuery(SEARCH_LOCATIONS);
    const usersQuery = useQuery(GET_USERS);
    const inventoryQuery = useQuery(GET_INVENTORY);
    const executeStockTransferQuery = useQuery(
      EXECUTE_STOCK_TRANSFER_COUNT_PLAN,
    );

    const [stockTransfers, setStockTransfers] = useState([]);
    const [selectedStockTransfer, setSelectedStockTransfer] = useState(null);
    const [planToActivate, setPlanToActivate] = useState(null);
    const [expandedStockTransfer, setExpandedStockTransfer] = useState(null);
    const [showExpand, setShowExpand] = useState(false);
    const [availableInventory, setAvailableInventory] = useState([]);
    const [binLocations, setBinLocations] = useState([]);
    const [skuBinLocations, setSkuBinLocations] = useState([]);

    useEffect(() => {
      stockTransfersQuery.fetchData();
      warehousesQuery.fetchData({
        all: true,
        sort: "name",
      });
      usersQuery.fetchData({
        perPage: 100,
        paginated: false,
        filters: { hopstackModules: ["Stock Transfer"] },
      });
    }, []);

    useEffect(() => {
      if (stockTransfersQuery.data) {
        setStockTransfers(stockTransfersQuery.data.stockTransfers);
      }

      stockTransfersQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (stockTransfersQuery.error) {
        appState.setAlert(stockTransfersQuery.error.message, "error", 5000);
      }
    }, [
      stockTransfersQuery.data,
      stockTransfersQuery.loading,
      stockTransfersQuery.error,
    ]);

    useEffect(() => {
      if (getSkuBinMappingsQuery.data) {
        let bins = [];
        getSkuBinMappingsQuery.data.skuBinMappings?.entities?.map(
          (skuBinMapping) => {
            if (
              !skuBinMapping.binLocationDeleted &&
              skuBinMapping.sku === selectedStockTransfer?.sku
            ) {
              bins.push({
                barcode: skuBinMapping.binLocation,
              });
            }
          },
        );
        setSkuBinLocations(_.sortBy(_.uniqBy(bins, "barcode"), "barcode"));
      }
      if (getSkuBinMappingsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getSkuBinMappingsQuery.error) {
        appState.setAlert(getSkuBinMappingsQuery.error.message, "error", 5000);
      }
    }, [
      getSkuBinMappingsQuery.loading,
      getSkuBinMappingsQuery.data,
      getSkuBinMappingsQuery.error,
    ]);

    useEffect(() => {
      if (searchLocationsQuery.data?.searchLocations?.data) {
        setBinLocations(
          searchLocationsQuery.data.searchLocations?.data?.locations,
        );
      }

      searchLocationsQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (searchLocationsQuery.data?.searchLocations?.error) {
        appState.setAlert(
          searchLocationsQuery.data?.searchLocations?.error,
          "error",
          5000,
        );
      } else if (searchLocationsQuery.error) {
        appState.setAlert(searchLocationsQuery.error.message, "error", 5000);
      }
    }, [
      searchLocationsQuery.data,
      searchLocationsQuery.loading,
      searchLocationsQuery.error,
    ]);

    useEffect(() => {
      if (getStockTransferQuery.data) {
        const stockTransfer = getStockTransferQuery.data.stockTransfer;
        setSelectedStockTransfer(stockTransfer);
        setExpandedStockTransfer(stockTransfer);
        // fetch the data related to that stock transfer
        inventoryQuery.fetchData({
          perPage: 25,
          pageNumber: 1,
          filters: {
            warehouse: [stockTransfer.warehouse],
            showInactive: true,
          },
        });
        searchLocationsQuery.fetchData({
          perPage: 10,
          pagination: {
            limit: 10,
            offset: 0,
          },
          filters: {
            warehouses: stockTransfer?.warehouse
              ? [stockTransfer.warehouse]
              : undefined,
          },
          warehouses: stockTransfer?.warehouse
            ? [stockTransfer.warehouse]
            : undefined,
        });
        getSkuBinMappingsQuery.fetchData({
          perPage: 10,
          pageNumber: 1,
          filters: {
            warehouse: [stockTransfer.warehouse],
          },
        });
      }

      if (getStockTransferQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getStockTransferQuery.loading,
      getStockTransferQuery.data,
      getStockTransferQuery.error,
    ]);

    useEffect(() => {
      if (executeStockTransferQuery.data) {
        setPlanToActivate(null);
        appState.setAlert(
          executeStockTransferQuery.data.executeStockTransfer.message,
          "success",
          5000,
        );
        stockTransfersQuery.fetchData();
      }
      if (executeStockTransferQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (executeStockTransferQuery.error) {
        appState.setAlert(
          executeStockTransferQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      executeStockTransferQuery.loading,
      executeStockTransferQuery.data,
      executeStockTransferQuery.error,
    ]);

    useEffect(() => {
      if (saveStockTransferQuery.data) {
        setSelectedStockTransfer(null);
        stockTransfersQuery.fetchData();
        appState.setAlert(
          saveStockTransferQuery.data.saveStockTransfer.message,
          "success",
          5000,
        );
      }
      if (saveStockTransferQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveStockTransferQuery.error) {
        appState.setAlert(saveStockTransferQuery.error.message, "error", 5000);
      }
    }, [
      saveStockTransferQuery.loading,
      saveStockTransferQuery.data,
      saveStockTransferQuery.error,
    ]);

    useEffect(() => {
      if (deleteStockTransferQuery.data) {
        appState.hideConfirmation();
        stockTransfersQuery.fetchData();
      }
      if (deleteStockTransferQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteStockTransferQuery.error) {
        appState.setAlert(
          deleteStockTransferQuery.error.message,
          "error",
          5000,
        );
        appState.hideConfirmation();
      }
    }, [
      deleteStockTransferQuery.loading,
      deleteStockTransferQuery.data,
      deleteStockTransferQuery.error,
    ]);

    useEffect(() => {
      if (inventoryQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (inventoryQuery.data) {
        setAvailableInventory(inventoryQuery.data.inventory.entities);
      }

      if (inventoryQuery.error) {
        setAvailableInventory([]);
      }
    }, [
      inventoryQuery.loading,
      inventoryQuery.error,
      inventoryQuery.data,
      getStockTransferQuery.data,
    ]);

    const validateStockTransferInputs = () => {
      if (
        selectedStockTransfer.items.every(
          (item) => item.fromBin && item.toBin && item.quantity > 0,
        )
      ) {
        return true;
      } else {
        appState.setAlert(
          "Please ensure that To location is not null and quantity is more than 0 for each transfer.",
          "error",
          5000,
        );
      }
    };

    const saveStockTransfer = () => {
      if (validateStockTransferInputs()) {
        saveStockTransferQuery.fetchData({
          stockTransferInput: { ...selectedStockTransfer },
        });
      }
    };

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this transfer job?",
        () => {
          deleteStockTransferQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    const onChangeDropdown = (field, value, id) => {
      const stockTransfer = {
        ...selectedStockTransfer,
      };

      if (field === "warehouse") {
        // get the products and bin locations for that warehouse
        inventoryQuery.fetchData({
          perPage: 25,
          pageNumber: 1,
          filters: {
            warehouse: [value],
            showInactive: true,
          },
          paginated: false,
        });
        searchLocationsQuery.fetchData({
          perPage: 10,
          pagination: {
            limit: 10,
            offset: 0,
          },
          filters: {
            warehouses: value ? [value] : undefined,
          },
          warehouses: value ? [value] : undefined,
        });
        // delete the previously selected sku, transfer items.
        delete stockTransfer.sku;
        delete stockTransfer.items;
      }
      if (field === "sku") {
        // once sku is selected, get the related sku bin mappings for showing the options
        let filters = {
          keyword: value,
          warehouse: [stockTransfer.warehouse],
        };
        if (id) {
          filters.product = [id];
        }
        getSkuBinMappingsQuery.fetchData({ filters });
        // delete the previously selected transfers
        delete stockTransfer.items;

        stockTransfer.productId = id;
      }

      stockTransfer[field] = value;
      setSelectedStockTransfer({ ...stockTransfer });
    };

    const removeItem = (idx) => {
      let currentSelectedStockTransfer = selectedStockTransfer;
      if (
        !currentSelectedStockTransfer.items ||
        currentSelectedStockTransfer.items.length === 0
      ) {
        return;
      }

      currentSelectedStockTransfer.items =
        currentSelectedStockTransfer.items.filter(
          (item, index) => index !== idx,
        );
      setSelectedStockTransfer({ ...currentSelectedStockTransfer });
    };

    const onChangeItem = (idx, e) => {
      let currentSelectedStockTransfer = selectedStockTransfer;
      const item = currentSelectedStockTransfer.items[idx];
      if (e.target.type === "number") {
        item[e.target.name] = parseInt(e.target.value);
      } else {
        item[e.target.name] = e.target.value;
      }
      currentSelectedStockTransfer.items[idx] = item;
      setSelectedStockTransfer({ ...currentSelectedStockTransfer });
    };

    const addItems = () => {
      let currentSelectedStockTransfer = selectedStockTransfer;
      if (
        !currentSelectedStockTransfer.items ||
        currentSelectedStockTransfer.items.length === 0
      ) {
        currentSelectedStockTransfer.items = [];
      }

      currentSelectedStockTransfer.items.push({
        fromBin: "",
        toBin: "",
        quantity: 0,
        personnel: "",
      });
      setSelectedStockTransfer({ ...currentSelectedStockTransfer });
    };

    const onChangeInventorySearch = (keyword) => {
      inventoryQuery.fetchData({
        perPage: 25,
        pageNumber: 1,
        filters: {
          keyword,
          showInactive: true,
          warehouse: [selectedStockTransfer.warehouse],
          customer: [selectedStockTransfer.customer],
        },
      });
    };

    /**
     * Fetches bin location data based on a keyword using binary search.
     * It retrieves a paginated result set with a limit of 10 entries per page and starts from the first page.
     *
     * @function
     * @param {string} keyword - The keyword used for filtering the bin location data.
     */
    const onChangeBinSearch = (keyword) => {
      searchLocationsQuery.fetchData({
        perPage: 10,
        pagination: {
          limit: 10,
          offset: 0,
        },
        filters: {
          warehouses: selectedStockTransfer?.warehouse
            ? [selectedStockTransfer.warehouse]
            : undefined,
          code: keyword || undefined,
        },
        warehouses: selectedStockTransfer?.warehouse
          ? [selectedStockTransfer.warehouse]
          : undefined,
        code: keyword || undefined,
      });
    };

    const onChangeSkuBinSearch = (keyword) => {
      getSkuBinMappingsQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        filters: {
          keyword,
          warehouse: [selectedStockTransfer.warehouse],
        },
      });
    };

    const executePlanClicked = (plan) => {
      setPlanToActivate(plan);
    };

    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);
    const debouncedBinLocationSearch = _.debounce(onChangeBinSearch, 1000);
    const debouncedSkuBinLocationSearch = _.debounce(
      onChangeSkuBinSearch,
      1000,
    );

    return (
      <WrappedComponent
        stockTransfers={stockTransfers}
        users={usersQuery.data?.users.entities.filter(
          (item) =>
            item.role?.toLowerCase() === "associate" &&
            item.hopstackModules.includes("Stock Transfer"),
        )}
        warehouses={
          warehousesQuery.data ? warehousesQuery.data.warehouses.entities : []
        }
        fetchStockTransfer={(id) => getStockTransferQuery.fetchData({ id })}
        selectedStockTransfer={selectedStockTransfer}
        setSelectedStockTransfer={setSelectedStockTransfer}
        saveStockTransfer={saveStockTransfer}
        setShowExpand={setShowExpand}
        showExpand={showExpand}
        removeItem={removeItem}
        onChangeItem={onChangeItem}
        onChangeDropdown={onChangeDropdown}
        addItems={addItems}
        deleteButtonClicked={deleteButtonClicked}
        expandedStockTransfer={expandedStockTransfer}
        setExpandedStockTransfer={setExpandedStockTransfer}
        availableInventory={availableInventory}
        onChangeInventorySearch={debouncedInventorySearch}
        executePlanClicked={executePlanClicked}
        setPlanToActivate={setPlanToActivate}
        planToActivate={planToActivate}
        executePlan={(stockTransfer) => {
          stockTransfer.items.map((item) => {
            executeStockTransferQuery.fetchData({
              executeTransferInput: {
                transferId: planToActivate.id,
                personnel: item.personnel,
                sku: planToActivate.sku,
                fromBin: item.fromBin,
                toBin: item.toBin,
                quantity: item.quantity,
              },
            });
          });
        }}
        binLocations={binLocations}
        onChangeBinLocationSearch={debouncedBinLocationSearch}
        skuBinLocations={skuBinLocations}
        onChangeSkuBinLocationSearch={debouncedSkuBinLocationSearch}
        customers={auth.user?.customersList ? auth.user.customersList : []}
      />
    );
  };
};

export default withStockTransferLogic;
