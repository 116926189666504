import { useState } from "react";

const CompletedBatch = ({
  currentBatch,
  confirmDropoff,
  prep,
  printShippingLabel,
  workflow,
  printAllFnSkus,
  stations,
  selectedStation,
  proceedToPacking,
  restartActivity,
}) => {
  // let message = `Go to ${currentBatch.packingStation.computerName}`;
  // if (pack) {
  //   message = `Go to Sorting Station ${currentBatch.packingStation.packingTable}`;
  // }
  const currentSelectedStation = stations.find(
    (i) => i.id === currentBatch.station,
  );
  let eligiblePacking = false;
  if (currentSelectedStation) {
    eligiblePacking =
      stations.findIndex(
        (i) =>
          i.id !== selectedStation && i.name === currentSelectedStation.name,
      ) !== -1;
  }
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <div className="-mt-20 flex h-screen w-full items-center justify-center">
      <div className="flex-col">
        <div className="w-full rounded-2xl border bg-white shadow-lg">
          <div className="flex items-center justify-center pb-5 pt-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/completed_tick.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="px-5 py-5 text-3xl font-medium text-454A4F">
              <div>Order Prepped Successfully</div>
              {currentBatch &&
                currentBatch.attributes &&
                currentBatch.attributes.dropoffStation &&
                `Go to ${currentBatch.attributes.dropoffStation}`}
              <div className="flex space-x-4">
                <div
                  className="mt-2 w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                  onClick={confirmDropoff}>
                  Confirm
                </div>
                {restartActivity && (
                  <div
                    className="mt-2 w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
                    onClick={restartActivity}>
                    Restart Prep
                  </div>
                )}

                {eligiblePacking && (
                  <div
                    className="mt-2 w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                    onClick={proceedToPacking}>
                    Proceed to Packing
                  </div>
                )}

                {workflow && (
                  <div
                    className="mt-2 w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                    onClick={printAllFnSkus}>
                    Re-Print FNSKUs
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedBatch;
