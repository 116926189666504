import React, { useContext, useEffect, useRef, useState } from "react";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import { ModalContext } from "#newUiComponents/bulkUploadV2/useReducer";
import { AppStateContext } from "#contexts/appState";
import Papa from "papaparse";
import { XCircleIcon } from "@heroicons/react/solid";
import { Tooltip } from "antd";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import CustomAlert from "#newUiComponents/commons/CustomAlert";

export const FILE_SIZE_25_MB_VALIDATION = 25600; // 25 MB(25600 KB)
export const FILE_SIZE_1_MB_VALIDATION = 1024; // 1 MB (1024 KB)
export const FILE_ROWS_VALIDATION_10000 = 10001;
export const FILE_UPLOAD_STEP_ID = "FILE_UPLOAD";
export const MAP_FIELDS_STEP_ID = "MAP_FIELDS";

const UploadBulk = ({
  dashboardFields,
  bulkUploadDuplicateValidation,
  onClose,
  bulkUploadDuplicateRowValidation,
}) => {
  const bulkUploadEntity = useContext(ModalContext);
  const [showSubmit, setShowSubmit] = useState(false);

  useEffect(() => {
    let formedSteps = bulkUploadEntity?.steps;
    if (formedSteps && formedSteps?.length !== 0) {
      formedSteps[0] = {
        ...formedSteps[0],
        status: "current",
      };

      bulkUploadEntity?.setSteps(formedSteps);
      bulkUploadEntity?.setSelectedStep(formedSteps[0]);
    }
  }, []);

  const verifyNextSteps = () => {
    if (
      bulkUploadEntity?.steps &&
      bulkUploadEntity?.selectedStep &&
      bulkUploadEntity?.steps.findIndex(
        (step) => step["status"] === "current",
      ) === 0 &&
      bulkUploadEntity?.selectedStep?.isVerify
    ) {
      if (!bulkUploadEntity?.selectedFile) return;
      setNextStep();
    }
  };

  const setNextStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(bulkUploadEntity?.steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const nextStep = copiedSteps.find((_, idx) => idx === foundIdx + 1);
    if (!nextStep?.visible) {
      foundIdx += 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      bulkUploadEntity?.setSteps(copiedSteps);
      bulkUploadEntity?.setSelectedStep(copiedSteps[foundIdx + 1]);
      setShowSubmit(foundIdx === copiedSteps.length - 2);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(bulkUploadEntity?.steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const prevStep = copiedSteps.find((_, idx) => idx === foundIdx - 1);
    if (!prevStep?.visible) {
      foundIdx -= 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== 0 && foundIdx !== -1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));
      bulkUploadEntity?.setErrors(null);
      bulkUploadEntity?.setSteps(copiedSteps);
      setShowSubmit(false);
      bulkUploadEntity?.setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  const onCancelForm = () => {
    onClose();
  };

  return (
    <div className="relative flex h-full w-full flex-col overflow-auto font-inter">
      <header className="space-y-2 py-4">
        <Navbar steps={bulkUploadEntity?.steps} />
      </header>

      <main className="my-2 h-full flex-grow overflow-auto">
        {bulkUploadEntity?.selectedStep?.id === FILE_UPLOAD_STEP_ID && (
          <FileUpload
            dashboardFields={dashboardFields}
            bulkUploadDuplicateValidation={bulkUploadDuplicateValidation}
            bulkUploadDuplicateRowValidation={bulkUploadDuplicateRowValidation}
          />
        )}
        {bulkUploadEntity?.selectedStep?.id === MAP_FIELDS_STEP_ID && (
          <MapFields />
        )}
      </main>

      <footer className="flex h-16 items-center justify-end">
        <FormActions
          steps={bulkUploadEntity?.steps}
          selectedStep={bulkUploadEntity?.selectedStep}
          setPrevStep={setPrevStep}
          setNextStep={verifyNextSteps}
          showSubmit={showSubmit}
          onSubmit={() =>
            bulkUploadEntity?.startValidation({
              dashboardFields: dashboardFields,
            })
          }
          onCancel={onCancelForm}
          onClose={onClose}
        />
      </footer>
    </div>
  );
};

const Navbar = ({ steps }) => {
  const visibleSteps = steps.filter((item) => item.visible);
  return (
    <nav className="flex w-524 space-x-2">
      {visibleSteps.map((step, index) => {
        const activeStep = step.status === "current";
        return (
          <div className="flex flex-col items-center" key={index}>
            <span
              className={`mb-1 h-2.5 w-32 rounded-md ${
                activeStep ? "bg-blueBackground" : "bg-lightBlueBackground"
              }`}></span>
            <p
              className={`text-sm decoration-solid ${
                activeStep ? "text-textDarkGray" : "text-textGray"
              }`}>
              {step.name}
            </p>
          </div>
        );
      })}
    </nav>
  );
};

const FormActions = ({
  steps,
  setPrevStep,
  setNextStep,
  showSubmit,
  onSubmit,
  onCancel,
}) => {
  const bulkUploadEntity = useContext(ModalContext);
  return (
    <>
      <div className="flex space-x-4">
        <button
          className={`mr-2 cursor-pointer py-3 text-base font-semibold text-primaryAccent underline`}
          onClick={onCancel}>
          Cancel
        </button>
        <button
          onClick={setPrevStep}
          className={`cursor-pointer rounded-md border px-6 py-3 text-base font-semibold ${
            bulkUploadEntity?.selectedStep?.id === steps[0]?.id
              ? "cursor-not-allowed border-mediumGray text-mediumGray"
              : "cursor-pointer border-primaryAccent text-primaryAccent"
          }`}>
          Previous
        </button>
        {!showSubmit && (
          <button
            onClick={setNextStep}
            disabled={
              !(
                bulkUploadEntity?.selectedStep &&
                bulkUploadEntity?.selectedStep["isVerify"]
              )
            }
            className={`rounded-md px-6 py-3 text-base font-semibold ${
              !(
                bulkUploadEntity?.selectedStep &&
                bulkUploadEntity?.selectedStep["isVerify"]
              )
                ? "cursor-not-allowed border border-mediumGray text-mediumGray"
                : "cursor-pointer bg-primaryAccent text-white"
            }`}>
            Next
          </button>
        )}
        {showSubmit && (
          <button
            onClick={
              bulkUploadEntity && !bulkUploadEntity?.error
                ? onSubmit
                : undefined
            }
            className={`rounded-md px-6 py-3 text-base font-semibold ${
              bulkUploadEntity && !bulkUploadEntity?.error
                ? "cursor-pointer bg-primaryAccent text-white"
                : "cursor-not-allowed border border-mediumGray text-mediumGray"
            }`}>
            Submit
          </button>
        )}
      </div>
    </>
  );
};

const FileUpload = ({
  dashboardFields,
  bulkUploadDuplicateValidation,
  bulkUploadDuplicateRowValidation,
}) => {
  const bulkUploadEntity = useContext(ModalContext);

  const fileInputRef = useRef(null);
  const notify = CustomNotification();
  const appState = useContext(AppStateContext);

  const handleFileChange = async (file) => {
    if (file && file?.type === bulkUploadEntity?.bulkUploadFileContentType) {
      appState.setLoading();
      const selectedFileReadResponse = await readFileDetails(file);
      appState.removeLoading();
      if (
        Math.round(file?.size / FILE_SIZE_1_MB_VALIDATION) >
        FILE_SIZE_25_MB_VALIDATION
      ) {
        notify.error("File size exceeds 25 MB or 10000 rows.");
      } else if (
        selectedFileReadResponse &&
        selectedFileReadResponse?.data &&
        selectedFileReadResponse?.data?.length > FILE_ROWS_VALIDATION_10000
      ) {
        notify.error("File size exceeds 25 MB or 10000 rows.");
      } else {
        bulkUploadEntity?.setTableDetails(selectedFileReadResponse?.data);
        bulkUploadEntity?.setSelectedFileDetails(file, dashboardFields);
      }
    } else {
      notify.error("Invalid file.");
    }
  };

  const VerifyFileDetails = async () => {
    if (!bulkUploadEntity?.selectedFile) return;
    try {
      appState.setLoading();
      if (
        bulkUploadEntity?.tableRows &&
        bulkUploadEntity?.tableHeaders &&
        bulkUploadEntity?.tableRows?.length !== 0 &&
        bulkUploadEntity?.tableHeaders?.length !== 0
      ) {
        const fileErrorList = validateFileUploadData(
          bulkUploadEntity?.tableRows,
          bulkUploadEntity?.tableHeaders,
        );
        if (fileErrorList && fileErrorList?.length !== 0) {
          bulkUploadEntity?.setErrors(fileErrorList);
        } else {
          if (!bulkUploadEntity?.selectedFile) return;
          let formedSteps = bulkUploadEntity?.steps;
          if (formedSteps && formedSteps?.length !== 0) {
            formedSteps[0] = {
              ...formedSteps[0],
              status: "current",
              isVerify: true,
            };
            bulkUploadEntity?.setSteps(formedSteps);
            bulkUploadEntity?.setSelectedStep(formedSteps[0]);
          }
        }
      }
      appState.removeLoading();
    } catch (error) {
      console.log(error);
      appState.removeLoading();
      notify.error("An error occurred while processing the file.");
    }
  };

  const validateFileUploadData = (fileDataList, fileHeaders) => {
    let errorList = [];
    if (bulkUploadEntity?.fields?.length > fileHeaders?.length) {
      errorList.push({
        type: "COLUMN_MISMATCH",
        message: `Your file has ${fileHeaders?.length} columns, but this template requires ${bulkUploadEntity?.fields?.length} columns. Download the template for reference`,
      });
    } else {
      let validateDuplicateValuesInColumn = bulkUploadDuplicateValidation;
      let validateDuplicateRow =
        bulkUploadDuplicateRowValidation &&
        bulkUploadDuplicateRowValidation?.length !== 0
          ? bulkUploadDuplicateRowValidation
          : [];
      let columnData = {};
      validateDuplicateValuesInColumn = validateDuplicateValuesInColumn
        ?.map((matchColumn) => fileHeaders.indexOf(matchColumn))
        .filter((index) => index !== -1);
      validateDuplicateValuesInColumn.forEach((column) => {
        columnData[column] = new Set();
      });
      validateDuplicateRow = validateDuplicateRow
        ?.map((matchColumn) => fileHeaders.indexOf(matchColumn))
        .filter((index) => index !== -1);
      validateDuplicateRow.forEach((column) => {
        columnData[column] = new Set();
      });
      const duplicateFileHeaders = validateDuplicateFileHeaders(fileHeaders);
      duplicateFileHeaders.forEach((columnName) => {
        if (columnName) {
          errorList.push({
            type: "Duplicate File Header Entry",
            message: `<span style="font-weight: 600">${columnName}</span> header is duplicate`,
          });
        }
      });
      const uniqueRows = new Set();
      fileDataList.forEach((row, rowIndex) => {
        Object.keys(row).forEach((column) => {
          const cellValue = row[column].trim();
          if (
            fileHeaders[parseInt(column)] &&
            fileHeaders[parseInt(column)].includes("*")
          ) {
            if (!cellValue) {
              errorList.push({
                type: "BLANK_DATA",
                message: `<span style="font-weight: 600">${fileHeaders[parseInt(column)]}</span> is missing at row ${rowIndex + 1}`,
              });
            }
          }
          if (validateDuplicateValuesInColumn.includes(parseInt(column))) {
            if (columnData[column].has(cellValue)) {
              errorList.push({
                type: "DUPLICATE_DATA",
                message: `<span style="font-weight: 600">${fileHeaders[parseInt(column)]}</span> is duplicate at row ${rowIndex + 1}`,
              });
            } else {
              columnData[column].add(cellValue);
            }
          }
        });
        if (validateDuplicateRow?.length !== 0) {
          const duplicateRowValue = validateDuplicateRow
            ?.map((index) => {
              if (index < row.length) {
                return row[index];
              }
            })
            ?.join(",");
          if (duplicateRowValue) {
            if (uniqueRows.has(duplicateRowValue)) {
              const duplicateRowHeaders = validateDuplicateRow
                ?.map((index) => fileHeaders[index])
                .join(", ");
              errorList.push({
                type: "DUPLICATE_ROW_DATA",
                message: `<span style="font-weight: 600">${duplicateRowHeaders}</span> is duplicate at row ${rowIndex + 1}`,
              });
            } else {
              uniqueRows.add(duplicateRowValue);
            }
          }
        }
      });
    }
    return errorList;
  };

  const isInvalidFloat = (value) => Number.isNaN(Number(value));

  const validateDuplicateFileHeaders = (fileHeaders) => {
    const duplicates = fileHeaders.filter(
      (item, index) => fileHeaders.indexOf(item) !== index,
    );
    return [...new Set(duplicates)];
  };

  const readFileDetails = async (file) => {
    const fileReadResponse = await new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (parseData) => {
          if (parseData?.data && parseData.data?.length !== 0) {
            parseData.data[0] = parseData.data[0].map((header) =>
              header?.replace(/\n/g, " ")?.trim(),
            );
          }
          resolve(parseData);
        },
        error: reject,
      });
    });
    return fileReadResponse;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (event) => {
    resetUploadFile();
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  const resetUploadFile = () => {
    let formedSteps = bulkUploadEntity?.steps;
    if (formedSteps && formedSteps?.length !== 0) {
      formedSteps[0] = {
        ...formedSteps[0],
        status: "current",
        isVerify: false,
      };

      bulkUploadEntity?.setSteps(formedSteps);
      bulkUploadEntity?.setSelectedStep(formedSteps[0]);
    }
    bulkUploadEntity?.setTableDetails(null);
    bulkUploadEntity?.setSelectedFileDetails(null);
    bulkUploadEntity?.setErrors(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <div className="mx-auto max-w-3xl p-4">
      <div
        className="mb-4 rounded-md border-2 border-dashed border-gray-300 bg-gray-100 p-4 text-center"
        onDragOver={handleDragOver}
        onDrop={handleDrop}>
        <label
          htmlFor="file-input"
          className="flex cursor-pointer flex-col items-center">
          <div className="mb-2 h-10 w-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#224E73"
              className="h-10 w-10">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
          </div>
          <span className="text-gray-600">
            <span className="font-semibold">
              Drag &amp; drop files or{" "}
              <span
                className="text-[#224E73] underline"
                onClick={(e) => {
                  e.preventDefault();
                  resetUploadFile();
                  fileInputRef.current.click();
                }}>
                Browse
              </span>
            </span>
            <br />
            <span className="text-gray-400">
              Supported format:{" "}
              <span>CSV (Max. 25MB OR 10000 Rows accepted)</span>
            </span>
          </span>
        </label>
        <input
          ref={fileInputRef}
          id="file-input"
          type="file"
          accept=".csv"
          onChange={(e) => handleFileChange(e.target.files[0])}
          onClick={resetUploadFile}
          className="hidden"
        />
      </div>

      {bulkUploadEntity?.selectedFile && (
        <div className="mb-4 flex items-center justify-start gap-4">
          <div className="bottom-1 flex w-2/3 items-center justify-between border-2 px-3 py-2">
            <p className="text-gray-600">
              {bulkUploadEntity?.selectedFile?.name}
            </p>
            <XCircleIcon
              onClick={resetUploadFile}
              className="bg-white-800 h-6 w-6 cursor-pointer text-gray-400"
            />
          </div>

          {!bulkUploadEntity?.error && (
            <button
              className={`mr-2 py-3 font-semibold text-primaryAccent underline ${bulkUploadEntity?.selectedStep && bulkUploadEntity?.selectedStep?.isVerify ? "cursor-default" : "cursor-pointer"}`}
              onClick={
                !(
                  bulkUploadEntity?.selectedStep &&
                  bulkUploadEntity?.selectedStep?.isVerify
                )
                  ? VerifyFileDetails
                  : undefined
              }>
              {bulkUploadEntity?.selectedStep &&
              bulkUploadEntity?.selectedStep?.isVerify
                ? "Verified!"
                : "Verify!"}
            </button>
          )}

          {bulkUploadEntity?.error && bulkUploadEntity?.error.length > 0 && (
            <button
              className="mr-2 cursor-pointer py-3 font-semibold text-primaryAccent underline"
              onClick={(e) => {
                e.preventDefault();
                resetUploadFile();
                fileInputRef.current.click();
              }}>
              Re-Upload!
            </button>
          )}
        </div>
      )}

      {bulkUploadEntity?.selectedStep &&
        bulkUploadEntity?.selectedStep?.isVerify && (
          <CustomAlert
            id="alertForFileVerifySuccess"
            type="success"
            message={`Your file ${bulkUploadEntity?.selectedFile && bulkUploadEntity?.selectedFile?.name} is verified successfully.`}
            options={{
              defaultColors: false,
              bgColor: "bg-green-100",
              textColor: "text-green-700",
              titleColor: "text-green-500",
              borderColor: "border-green-100",
            }}
          />
        )}
      {bulkUploadEntity?.error &&
        bulkUploadEntity?.error.length > 0 &&
        Array.isArray(bulkUploadEntity?.error) && (
          <ErrorNotification error={bulkUploadEntity?.error} />
        )}
    </div>
  );
};

const MapFields = ({}) => {
  const bulkUploadEntity = useContext(ModalContext);
  return (
    <div className="mx-auto ml-0 mr-0 max-w-3xl p-4">
      <div className="mb-5 w-full">
        <div className="mb-2 flex items-center">
          <div className="text-base text-gray-500">Name Of The File</div>
        </div>
        <div className="mt-1 block w-full cursor-not-allowed rounded-md border border-gray-300 bg-white px-3 py-2 font-light shadow-sm sm:text-sm">
          {bulkUploadEntity?.selectedFile?.name}
        </div>
      </div>
      <div className="mb-5 w-full">
        <div className="mb-2 flex items-center">
          <div className="text-base text-gray-500">Number Of Entries</div>
        </div>
        <div className="mt-1 block w-14 rounded-full bg-green-100 px-3 py-2 text-center font-semibold text-green-600 shadow-sm sm:text-sm">
          {bulkUploadEntity?.tableRows && bulkUploadEntity?.tableRows?.length}
        </div>
      </div>
      <div className="mb-5 w-full">
        <div className="mb-6 flex items-center">
          <div className="text-base text-gray-500">Mapped Field Headers</div>
        </div>
        <div className="mx-4 flex w-full flex-row items-start justify-between">
          <Dropdown
            title="Dashboard Columns"
            list={bulkUploadEntity?.fields}
            onChange={(value, index) => {
              bulkUploadEntity?.changeselectedValue(value, index);
            }}
            value={bulkUploadEntity?.defaultValue}
            selectlist={bulkUploadEntity?.attributes}
            tableHeaders={bulkUploadEntity?.tableHeaders}
          />
        </div>
        {bulkUploadEntity?.error && (
          <div className="mt-4">
            <CustomAlert
              id="alertForError"
              type="error"
              message={bulkUploadEntity?.error}
              options={{
                defaultColors: false,
                bgColor: "bg-red-100",
                textColor: "text-red-700",
                titleColor: "text-red-500",
                borderColor: "border-red-100",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ErrorNotification = ({ error }) => {
  return (
    <div className="flex justify-start space-x-2 rounded-md border border-red-400 bg-red-100 px-4 py-3 text-red-700">
      <div className="h-auto w-7 flex-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-6 w-6 text-red-500">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
          />
        </svg>
      </div>
      <div className="h-auto grow">
        <div className="flex flex-col">
          <p className="font-semibold">Errors</p>
          <p className="">You can make the changes and try to upload again!</p>
        </div>
        <ul className="mt-2 list-outside list-disc space-y-2 pl-5">
          {error.map((error, index) => (
            <li key={index}>
              <span dangerouslySetInnerHTML={{ __html: error?.message }} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Dropdown = ({
  title,
  list,
  onChange,
  value,
  selectlist,
  disabled,
  tableHeaders,
}) => {
  const [options, setOptions] = useState([]);
  const [headers, setHeaders] = useState([]);
  useEffect(() => {
    if (list && list?.length !== 0) {
      setOptions([
        { value: "select_option", label: "Please Select Option" },
        ...list.map((option) => {
          return {
            value: option,
            label: option,
          };
        }),
      ]);
    }
    if (selectlist && tableHeaders) {
      if (selectlist?.length === tableHeaders?.length) {
        setHeaders(tableHeaders);
      } else if (selectlist?.length <= tableHeaders?.length) {
        setHeaders(tableHeaders);
      } else if (selectlist?.length >= tableHeaders?.length) {
        setHeaders(selectlist);
      } else {
        setHeaders(selectlist);
      }
    }
  }, [list, selectlist, tableHeaders]);

  return (
    <div className="flex w-full flex-col items-start justify-center space-y-4">
      {headers.map((header, i) => (
        <React.Fragment key={i}>
          <div className="flex flex-row space-x-4">
            {tableHeaders && tableHeaders?.length !== 0 && (
              <div className="w-1/2">
                <Tooltip title={header} placement="topLeft">
                  <div className="w-56 truncate text-base text-gray-500">
                    {header}
                  </div>
                </Tooltip>
              </div>
            )}
            <div className="w-1/2">
              <AutocompleteSingleSelectDropdown
                key={i}
                options={options}
                labelKey={"label"}
                valueKey={"value"}
                onChange={(value) => onChange(value, i)}
                value={value[i] || "select_option"}
                placeholder=""
                showCheckedIndicator={false}
                sortOptions={false}
                textNormal="!text-sm !text-gray-500"
                closeDropdown={true}
              />
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default UploadBulk;
