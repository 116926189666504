import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import DateRangePicker from "#components/common/DateRangePicker";
import dayjs from "dayjs";
import { DATE_RANGES } from "#utils/dateRanges";
const BatchFilters = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  dateRangeLabel,
  statusOptions,
  customers,
  warehouses,
  showDateRangePicker,
  handleDateRangeClear,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="mt-4 flex space-x-6">
      <div className="flex-1">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Status
          </span>
        </label>
        <MultiSelectAutoComplete
          options={
            statusOptions || [
              { name: "UNPROCESSED" },
              { name: "IN-PROCESS" },
              { name: "COMPLETED" },
            ]
          }
          labelKey="name"
          valueKey="name"
          setValues={(e) => onChangeFilter("status", e)}
          values={filters["status"]}
          rounded={true}
        />
      </div>
      {showDateRangePicker && (
        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              {dateRangeLabel || "Start & End Date Range"}
            </span>
          </label>
          <DateRangePicker
            floating={true}
            pickerClasses="h-16 border-2C7695 border-2"
            format="YYYY-MM-DD"
            ranges={DATE_RANGES}
            labelClasses={{
              before: "text-lg left-1 top-1rem",
              after: "text-base -top-3 left-3",
              common: "italic text-gray-500",
              nonFloatingClasses: "text-base left-5 -top-3",
            }}
            onChangeDateRange={(dateStrings) => {
              onChangeFilter("startEndDate", dateStrings);
            }}
            handleDateRangeClear={handleDateRangeClear}
            value={
              filters["startEndDate"] && filters["startEndDate"].length
                ? [
                    dayjs(filters["startEndDate"][0]),
                    dayjs(filters["startEndDate"][1]),
                  ]
                : []
            }
            displayText={
              filters["startEndDate"] && filters["startEndDate"].length
                ? filters["startEndDate"].join(" ~ ")
                : ""
            }
          />
        </div>
      )}
      {!showDateRangePicker && (
        <>
          <div className="flex-1">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Start Date
              </span>
            </label>
            <TextField
              type="date"
              label="Select Date"
              placeholder=" "
              onChange={(e) => onChangeFilter("startDate", e.target.value)}
              value={filters["startDate"]}
            />
          </div>
          <div className="flex-1">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                End Date
              </span>
            </label>
            <TextField
              type="date"
              label="Select Date"
              placeholder=" "
              onChange={(e) => onChangeFilter("endDate", e.target.value)}
              value={filters["endDate"]}
            />
          </div>
        </>
      )}
    </div>
    <div className="mt-4 flex space-x-6">
      {warehouses && (
        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Warehouse
            </span>
          </label>
          <MultiSelectAutoComplete
            options={warehouses}
            labelKey="name"
            valueKey="id"
            setValues={(e) => onChangeFilter("warehouses", e)}
            values={filters["warehouses"]}
            rounded={true}
          />
        </div>
      )}
      {customers && (
        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Client
            </span>
          </label>
          <MultiSelectAutoComplete
            options={customers}
            labelKey="name"
            valueKey="id"
            setValues={(e) => onChangeFilter("customers", e)}
            values={filters["customers"]}
            rounded={true}
          />
        </div>
      )}
    </div>
  </Modal>
);

export default BatchFilters;
