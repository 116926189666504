import dayjs from "dayjs";
import "dayjs/locale/en";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export const DATE_RANGES = {
  Today: [dayjs().startOf("day"), dayjs().endOf("day")],
  Yesterday: [
    dayjs().subtract(1, "day").startOf("day"),
    dayjs().subtract(1, "day").endOf("day"),
  ],
  "Last 7 Days": [
    dayjs().subtract(7, "day").startOf("day"),
    dayjs().endOf("day"),
  ],
  "Last 30 Days": [
    dayjs().subtract(30, "day").startOf("day"),
    dayjs().endOf("day"),
  ],
  "Previous Month": [
    dayjs().subtract(1, "month").startOf("month"),
    dayjs().subtract(1, "month").endOf("month"),
  ],
};
