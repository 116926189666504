const isDelmarTenant = (subdomain) => {
  return ["delmar", "delmarca", "delmartest"].includes(subdomain);
};

const isProprepTenant = (subdomain) => {
  return ["proprep"].includes(subdomain);
};

const isFeatherRiverColdTenant = (subdomain) => {
  return ["featherrivercold"].includes(subdomain);
};

const isManyMoonsTenant = (subdomain) => {
  return ["manymoons"].includes(subdomain);
};

const isVanEnglenTenant = (subdomain) => {
  return ["vanengelen", "vetest"].includes(subdomain);
};
// Add More tenants in future if required.

export {
  isDelmarTenant,
  isProprepTenant,
  isFeatherRiverColdTenant,
  isManyMoonsTenant,
  isVanEnglenTenant,
};
