import { useState, useEffect } from "react";
import Dropdown from "#components/utils/Dropdown";
import TextField from "#components/utils/TextField";
import Select from "react-select";

const AwaitingPalletInformation = ({
  currentBatch,
  order,
  printBoxLabels,
  transportLoading,
  confirmDropoff,
  submitTrackingInformation,
  masterData,
  downloadBoxLabels,
  print2DBarcodes,
  download2DBarcodes,
}) => {
  const fullAddress = () => {
    if (order.shippingAddress) {
      return `${order.shippingAddress.name}, ${order.shippingAddress.line1}, ${order.shippingAddress.city}, ${order.shippingAddress.state}, ${order.shippingAddress.zip}`;
    }

    return `Unknown Address`;
  };

  const [trackingInformation, setTrackingInformation] = useState({});
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  return (
    <div className="-mt-20 flex h-screen w-full items-center justify-center">
      <div className="flex-col">
        <div className="w-full rounded-2xl border bg-white shadow-lg">
          <div className="flex items-center justify-center pb-5 pt-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/completed_tick.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="px-5 py-5 text-3xl font-medium text-454A4F">
              <div>
                Could not send this shipment via Partnered Carrier to Amazon.
              </div>
              <div>Address: {fullAddress()}</div>
              <div>
                Do you have the non-partnered tracking information for this
                shipment?
              </div>
            </div>
          </div>
          <div className="flex-col items-center justify-center space-y-2 p-10 pb-5 pt-5">
            <Select
              options={
                masterData &&
                masterData.amazonCarriers &&
                masterData.amazonCarriers.map((item) => ({
                  value: item.id,
                  label: `${item.name}`,
                }))
              }
              value={selectedCarrier}
              onChange={(e) => setSelectedCarrier(e)}
              className="mb-8 border border-black text-2xl"
              placeholder="Select Carrier"
              isClearable={true}
            />
            <TextField
              type="text"
              id="trackingNumber"
              label="Tracking Number"
              placeholder=" "
              value={trackingInformation.trackingNumber}
              onChange={(e) =>
                setTrackingInformation({
                  ...trackingInformation,
                  trackingNumber: e.target.value,
                })
              }
            />
            {/* Add a note about the information being submitted directly to Amazon */}
            <div className="text-xl italic text-454A4F">
              NOTE: This info would be updated back to Amazon. If you do not
              have have the tracking number, click on Skip. It can be updated
              later by an admin.
            </div>
            <div className="flex space-x-2">
              {order?.boxLabels && (
                <div className="flex flex-1 space-x-2">
                  <div
                    className={`mt-4 flex-1 cursor-pointer rounded-md border border-black bg-transparent py-2 text-center text-xl text-black`}
                    onClick={printBoxLabels}>
                    Print Box Labels
                  </div>
                  <div
                    className={`mt-4 flex-1 cursor-pointer rounded-md border border-black py-2 text-center text-xl text-black`}
                    onClick={downloadBoxLabels}>
                    Download Box Labels
                  </div>
                </div>
              )}

              {order?.shipmentPlan?.BoxContentsSource === "2D_BARCODE" && (
                <>
                  <div
                    className={`mt-4 flex-1 cursor-pointer rounded-md border border-black py-2 text-center text-xl text-black`}
                    onClick={print2DBarcodes}>
                    Print 2D Barcodes
                  </div>
                  <div
                    className={`mt-4 flex-1 cursor-pointer rounded-md border border-black py-2 text-center text-xl text-black`}
                    onClick={download2DBarcodes}>
                    Download 2D Barcodes
                  </div>
                </>
              )}
            </div>
            <div className="flex space-x-2">
              <div
                className={`mt-4 flex-1 rounded-md bg-2C7695 py-2 text-center text-xl text-white`}
                onClick={() =>
                  submitTrackingInformation({
                    trackingNumber: trackingInformation.trackingNumber,
                    carrier: selectedCarrier?.value,
                  })
                }>
                Submit
              </div>
              <div
                className={`mt-4 flex-1 rounded-md bg-red-600 py-2 text-center text-xl text-white`}
                onClick={confirmDropoff}>
                Skip
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwaitingPalletInformation;
