import { useState } from "react";
import { Tab } from "@headlessui/react";
import ScanOrEnterManually from "#components/putaway/bundle/ScanOrEnterManually";
import BundleItemsList from "#components/putaway/bundle/BundleItemsList";

const BundleItemsSelection = ({
  customer,
  warehouse,
  setCurrentSku,
  currentTask,
  singlePutawayItem,
  putawayAvailableQty,
  setPutawayAvailableQty,
  scanBarcode,
  appState,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = {
    "Detail View": (
      <div className="flex">
        <ScanOrEnterManually
          onSubmit={scanBarcode}
          text="Scan an Item Code"
          appState={appState}
        />
      </div>
    ),
    "List View": (
      <BundleItemsList
        customer={customer}
        warehouse={warehouse}
        setCurrentSku={setCurrentSku}
        currentTask={currentTask}
        scanBarcode={scanBarcode}
        singlePutawayItem={singlePutawayItem}
        putawayAvailableQty={putawayAvailableQty}
        setPutawayAvailableQty={setPutawayAvailableQty}
      />
    ),
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="flex-col pt-36">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex items-center justify-center">
            <div className="space-x-2 rounded-full bg-primaryAccent p-2">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "font-regular rounded-full px-6 py-2.5 leading-5",
                        "font-montserrat text-lg focus:outline-none focus:ring-0",
                        selected
                          ? "bg-white font-medium text-primaryAccent"
                          : "rounded-full border border-primaryAccent bg-transparent text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="flex flex-col items-center bg-transparent p-4">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default BundleItemsSelection;
