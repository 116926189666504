import _ from "lodash";
import { useState, useEffect, useContext } from "react";

import { useQuery } from "#hooks/useQuery";
import { CRM_CUSTOMERS } from "#queries";
import { AppStateContext } from "#contexts/appState";
// icons
import noSearchResultsIcon from "#static/images/noSearchResults.jpg";
import { SearchIcon } from "@heroicons/react/outline";

// components
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import SearchResultEntity from "#components/salesManagement/SearchResultEntity";

const CustomerSearchBar = ({ setSelectedCustomer, setShowCreateCustomer }) => {
  const appState = useContext(AppStateContext);
  const searchOptions = [
    {
      name: "Customer ID",
      value: "Customer_ID",
    },
    {
      name: "First Name",
      value: "First_Name",
    },
    {
      name: "Last Name",
      value: "Last_Name",
    },
    {
      name: "Business Name",
      value: "Account_Name",
    },
    {
      name: "Contact Number",
      value: "Phone",
    },
    {
      name: "Email ID",
      value: "Email",
    },
  ];
  const CUSTOMERS_PER_PAGE = 10;
  const ALERT_VISIBILITY_IN_MS = 5000;
  const [customersPageNo, setCustomersPageNo] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [allCustomersFetched, setAllCustomersFetched] = useState(false);
  const [isDataFetched, setisDataFetched] = useState(false);
  const [searchField, setSearchField] = useState(searchOptions[0].value);
  const onChangeCrmCustomerSearch = async (searchKeyword) => {
    if (crmCustomersQuery.loading) return;
    setisDataFetched(false);
    const response = await crmCustomersQuery.fetchData({
      perPage: CUSTOMERS_PER_PAGE,
      pageNumber: customersPageNo,
      filters: {
        keyword: searchKeyword,
        searchFields: [searchField],
      },
      sort: "-createdAt",
    });

    if (response.data?.crmCustomers?.entities?.length > 0) {
      setSearchResults((prev) => [
        ...prev,
        ...response.data.crmCustomers?.entities,
      ]);
      setCustomersPageNo((page) => page + 1);
    } else if (response?.error?.message) {
      appState.setAlert(
        response?.error?.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    } else if (
      !response.data ||
      response.data?.crmCustomers?.entities?.length === 0
    ) {
      setAllCustomersFetched(true);
    }
    setisDataFetched(true);
    return response;
  };
  const debouncedCrmCustomerSearch = _.debounce(onChangeCrmCustomerSearch, 500);
  const crmCustomersQuery = useQuery(CRM_CUSTOMERS);
  const handleProductScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const threshold = 1;

    if (
      scrollTop + clientHeight + threshold >= scrollHeight &&
      !crmCustomersQuery.loading &&
      !allCustomersFetched
    ) {
      onChangeCrmCustomerSearch(keyword);
    }
  };

  const selectCustomer = (index) => {
    if (!searchResults[index]) return;
    setSelectedCustomer(searchResults[index]);
  };

  return (
    <>
      <div className="mb-4 w-full">
        <label
          className="mb-1 block text-sm font-medium text-lightGray"
          htmlFor="client">
          Search Customer
        </label>

        <div className="flex w-full">
          <div className="relative w-1/6 flex-none rounded-md">
            <AutocompleteDropdownV2
              options={searchOptions}
              labelKey="name"
              valueKey="value"
              onChange={setSearchField}
              value={searchField}
              searchable={false}
              id="search"
            />
          </div>
          <div
            id="customer-search-input"
            className="relative flex-1 rounded-md">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                debouncedCrmCustomerSearch(keyword);
              }}>
              <input
                type="search"
                name="search"
                id="search"
                className="block w-full rounded-md border-0 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                placeholder="Search..."
                onChange={(e) => {
                  if (e.target.value === "") setisDataFetched(false);
                  setKeyword(e.target.value);
                  setSearchResults([]);
                  setCustomersPageNo(1);
                }}
                value={keyword}
              />
            </form>
          </div>
        </div>

        <div
          className="flex flex-col overflow-x-auto overflow-y-auto"
          style={{ maxHeight: "500px" }}
          onScroll={handleProductScroll}>
          {keyword &&
          !crmCustomersQuery.loading &&
          isDataFetched &&
          (!searchResults || searchResults.length === 0) ? (
            <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 pt-12 text-center">
              <img src={noSearchResultsIcon} className="mx-auto w-48" />
              <div className="mt-4 text-2xl text-black">
                No Results To Display
              </div>
              <div className="mt-4 text-center">
                <span>You can modify your search query, or </span>
                <a
                  onClick={() => setShowCreateCustomer()}
                  className="cursor-pointer text-blue-600 underline">
                  create a new customer
                </a>
              </div>
            </div>
          ) : (
            <div className="mt-4">
              {searchResults.map((crmCustomer, rowIndex) => (
                <SearchResultEntity
                  index={rowIndex}
                  lastName={crmCustomer.lastName}
                  firstName={crmCustomer.firstName}
                  hrid={crmCustomer.hrid}
                  contactNumber={crmCustomer.contactNumber}
                  email={crmCustomer.email}
                  setSelectedCustomer={selectCustomer}
                />
              ))}

              {crmCustomersQuery.loading ? (
                <LoadingIndicator shouldShowOnPage={false} />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerSearchBar;
