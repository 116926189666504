import withTotesLogic from "#components/HOC/withTotesLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import TotesList from "#components/totes/TotesList";
import ToteForm from "#components/totes/ToteForm";
import EmptyToteForm from "#components/totes/EmptyToteForm";
import { PrinterIcon, UserAddIcon } from "@heroicons/react/outline";
const config = require("config");
import CustomEntityForm from "#components/common/CustomEntityForm";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import PrintSettingsModal from "#components/common/PrintSettingsModal";

const Totes = ({
  masterData,
  totes,
  selectedTote,
  setSelectedTote,
  fetchTote,
  saveTote,
  onChangeDropdown,
  deleteButtonClicked,
  onChange,
  devices,
  onChangeMultiSelect,
  setEmptyTote,
  emptyTote,
  saveEmptyTote,
  selectedTotes,
  selectTote,
  selectedPrints,
  setSelectedPrints,
  printSelectedBarcodes,
  queueMultiplePrints,
  queueSinglePrint,
  selectAllTotes,
  allTotesSelected,
  customers,
  warehouses,
  addSubTote,
  onChangeSubTote,
  removeSubTote,
  addCustomEntity,
  setAddCustomEntity,
  onSubmitCustomEntity,
  toteTypes,
  addBlankTote,
  addBlankEmptyTote,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  showFilters,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
}) => {
  const headers = [
    "Tote Type",
    "Barcode",
    "Sub-Totes",
    "Warehouses",
    "Attributes",
    "Clients",
    "Action",
  ];
  if (config.NODE_ENV === "development") {
    headers.push("Code");
  }
  return (
    <>
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-1">
            <PageTitle>Tote Management</PageTitle>
          </div>
          {selectedTotes.length === 0 && (
            <div className="mb-4 flex items-center justify-end space-x-2">
              <AddButton
                text="Add Tote"
                onClick={addBlankTote}
                icon={UserAddIcon}
                disabled={!writable}
              />
              <AddButton
                text="Add Multiple Totes"
                onClick={addBlankEmptyTote}
                icon={UserAddIcon}
                disabled={!writable}
              />
              <AddButton
                text="Download Template"
                onClick={() =>
                  (window.location =
                    "https://templates-onboarding.s3.amazonaws.com/uat/Totes.xlsx")
                }
                disabled={!writable}
              />
              <UploadBulk
                dashboardFields={dashboardFields}
                saveBulkUpload={saveBulkUpload}
                errorMessage={errorMessage}
                successMessage={successMessage}
                validate={validate}
                validationResult={validationResult}
              />
            </div>
          )}
          {selectedTotes.length > 0 && (
            <div className="mb-4 flex items-center justify-end space-x-2">
              <AddButton
                text="Print"
                onClick={queueMultiplePrints}
                icon={PrinterIcon}
              />
            </div>
          )}
        </div>
        <TotesList
          totes={totes}
          editButtonClicked={(e) => {
            fetchTote(e.id);
          }}
          deleteButtonClicked={(e) => deleteButtonClicked(e)}
          masterData={masterData}
          headers={headers}
          noValuesText="No Totes"
          selectedTotes={selectedTotes}
          selectTote={selectTote}
          queueSinglePrint={queueSinglePrint}
          selectAllTotes={selectAllTotes}
          allTotesSelected={allTotesSelected}
          customers={customers}
          warehouses={warehouses}
          writable={writable}
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          clearFilters={clearFilters}
          onChangeFilter={onChangeFilter}
          total={total}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
          perPage={perPage}
          setPerPage={setPerPage}
        />
        {selectedTote && (
          <ToteForm
            title={selectedTote.id ? "Edit Tote" : "Add Tote"}
            selectedTote={selectedTote}
            onChange={onChange}
            onClose={() => setSelectedTote(null)}
            onSubmit={() => saveTote(selectedTote)}
            hopstackModules={masterData?.hopstackModules}
            onChangeDropdown={onChangeDropdown}
            devices={devices}
            onChangeMultiSelect={onChangeMultiSelect}
            customers={customers}
            warehouses={warehouses}
            onChangeSubTote={onChangeSubTote}
            addSubTote={addSubTote}
            removeSubTote={removeSubTote}
            toteTypes={toteTypes}
          />
        )}
        {emptyTote && (
          <EmptyToteForm
            title={"Add Tote"}
            selectedTote={emptyTote}
            onChange={(e) => onChange(e, "emptyTote")}
            onClose={() => setEmptyTote(null)}
            onSubmit={() => saveEmptyTote(emptyTote)}
            onChangeDropdown={(field, value) =>
              onChangeDropdown(field, value, "emptyTote")
            }
            onChangeMultiSelect={onChangeMultiSelect}
            customers={customers}
            warehouses={warehouses}
            toteTypes={toteTypes}
          />
        )}
        {selectedPrints && (
          <PrintSettingsModal
            setSelectedPrints={setSelectedPrints}
            selectedPrints={selectedPrints}
            printSelectedBarcodes={printSelectedBarcodes}
          />
        )}

        {addCustomEntity && (
          <CustomEntityForm
            addCustomEntity={addCustomEntity}
            setAddCustomEntity={setAddCustomEntity}
            onSubmit={onSubmitCustomEntity}
          />
        )}
      </div>
    </>
  );
};
export default withTotesLogic(Totes);
