import React from "react";
import { Timeline } from "antd";
import dayjs from "dayjs";
import { handleGetStatusColor } from "./PoUtils";
import CustomBadge from "../commons/CustomBadge";

const AuditTimeline = ({ auditsTimeline }) => {
  const { audits, status, updatedAt, poId } = auditsTimeline;
  return (
    <>
      <div className="py-4 font-inter">
        <div className="flex items-center gap-3">
          <span className="text-lg font-bold">{poId}</span>
        </div>
        <div className="mt-3 text-sm text-gray-500">
          Last updated on: {dayjs(updatedAt).format("DD MMM YYYY, hh:mm A")}
        </div>
      </div>
      <div className="p-6 font-inter">
        <Timeline>
          {audits?.map((audit, index) => {
            const { bgColor, textColor } = handleGetStatusColor(audit.status);
            return (
              <Timeline.Item key={index} color={textColor}>
                <div className="flex items-center space-x-2">
                  <div className="font-bold">{audit.user}</div>
                  <div className="text-sm text-gray-500">
                    {dayjs(audit.timestamp).format("DD MMM YYYY, hh:mm A")}
                  </div>
                  <CustomBadge
                    label={audit.status}
                    textColor={handleGetStatusColor(audit.status).textColor}
                    bgColor={handleGetStatusColor(audit.status).bgColor}
                    textSize={"text-xs"}
                  />
                </div>
                <div
                  className={`mt-2 rounded p-2 ${bgColor} ${textColor}`}
                  style={{ backgroundColor: bgColor, color: textColor }}>
                  {audit.notes.map((note, noteIndex) => (
                    <div className="my-2" key={noteIndex}>
                      {note}
                    </div>
                  ))}
                </div>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </div>
    </>
  );
};

export default AuditTimeline;
