import _ from "lodash";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const printThirtyUpLabel = async (
  codes,
  noOfCopies = 1,
  printer = null,
  appState,
  print = true,
) => {
  if (!codes) {
    return appState.setAlert("Could not print", "error", 5000);
  }

  appState.setLoading();

  try {
    const dataUrls = [];
    for (const code of codes) {
      const dataUrl = await htmlToImage.toPng(
        document.getElementById(`${code}_FNSKU`),
        { quality: 1 },
      );

      dataUrls.push(dataUrl);
    }

    if (dataUrls.length > 0) {
      const chunkedDataUrls = _.chunk(dataUrls, 30);
      for (const chunked of chunkedDataUrls) {
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "in",
          format: [8.5, 11],
        });
        // var width = pdf.internal.pageSize.getWidth();
        // var height = pdf.internal.pageSize.getHeight();
        var width = 2.625;
        var height = 1;
        let baseHorizontalCursor = 0;
        let horizontalCursor = 0;
        let verticalCursor = 0.45;
        let horizontalSpacing = 0.125;
        let verticalSpacing = 0;
        console.log(`--------`);
        console.log(width);
        console.log(height);
        const chunkedVertically = _.chunk(chunked, 3);
        console.log(`-----`);
        console.log(chunkedVertically);
        for (const verticalChunk of chunkedVertically) {
          for (const chunky of verticalChunk) {
            console.log(`---------`);
            console.log(chunky);
            pdf.addImage(
              chunky,
              "PNG",
              horizontalCursor,
              verticalCursor,
              width,
              height,
            );
            horizontalCursor += width + horizontalSpacing;
          }

          horizontalCursor = baseHorizontalCursor;
          verticalCursor += height + verticalSpacing;
        }
        // console.log(`---------`);
        // console.log(pdf);
        if (print === false) {
          pdf.output("dataurlnewwindow");
          pdf.save(`FNSKU-${Date.now()}.pdf`);
          appState.removeLoading();
          continue;
        }
        const out = pdf.output("datauristring");
        // console.log(out);
        // return;
        // pdf.output;
        // canvas.toBlob((blob) => {
        const cpj = new JSPM.ClientPrintJob();
        let myPrinter;
        if (printer) {
          myPrinter = new JSPM.InstalledPrinter(printer);
        } else {
          myPrinter = new JSPM.DefaultPrinter();
        }
        cpj.clientPrinter = myPrinter;
        for (let i = 0; i < noOfCopies; i++) {
          const myFile = new JSPM.PrintFilePDF(
            out,
            JSPM.FileSourceType.URL,
            `${Date.now()}_${i}.pdf`,
            1,
          );
          cpj.files.push(myFile);
        }
        cpj.sendToClient();
      }
    }

    appState.removeLoading();

    // htmlToImage
    //   .toPng(document.getElementById(code), { quality: 1 })
    //   .then((dataUrl) => {
    //     const pdf = new jsPDF({
    //       orientation: "portrait",
    //       unit: "in",
    //       format: [8.5, 11],
    //     });
    //     // var width = pdf.internal.pageSize.getWidth();
    //     // var height = pdf.internal.pageSize.getHeight();
    //     var width = 2.625;
    //     var height = 1;
    //     let baseHorizontalCursor = 0.1875;
    //     let horizontalCursor = 0.1875;
    //     let verticalCursor = 0.5;
    //     let horizontalSpacing = 0.125;
    //     let verticalSpacing = 0;
    //     console.log(`--------`);
    //     console.log(width);
    //     console.log(height);
    //     for (let i = 0; i < 10; i++) {
    //       for (let j = 0; j < 3; j++) {
    //         pdf.addImage(
    //           dataUrl,
    //           "PNG",
    //           horizontalCursor,
    //           verticalCursor,
    //           width,
    //           height
    //         );
    //         horizontalCursor += width + horizontalSpacing;
    //       }
    //       horizontalCursor = baseHorizontalCursor;
    //       verticalCursor += height + verticalSpacing;
    //     }

    //     // pdf.output('dataurlnewwindow');
    //     // pdf.save("download.pdf");
    //     const out = pdf.output("datauristring");
    //     // console.log(out);
    //     // return;
    //     // pdf.output;
    //     // canvas.toBlob((blob) => {
    //     const cpj = new JSPM.ClientPrintJob();
    //     let myPrinter;
    //     if (printer) {
    //       myPrinter = new JSPM.InstalledPrinter(printer);
    //     } else {
    //       myPrinter = new JSPM.DefaultPrinter();
    //     }
    //     cpj.clientPrinter = myPrinter;
    //     const myFile = new JSPM.PrintFilePDF(
    //       out,
    //       JSPM.FileSourceType.URL,
    //       `${code}.pdf`,
    //       1
    //     );
    //     for (let i = 0; i < noOfCopies; i++) {
    //       cpj.files.push(myFile);
    //     }
    //     cpj.sendToClient();
    //     // });
    //   });

    // htmlToImage
    //   .toPng(document.getElementById(code), { quality: 1 })
    //   .then((dataUrl) => {
    //     for (let i = 0; i < 10; i++) {
    //       for (let j = 0; j < 3; j++) {
    //         pdf.addImage(
    //           dataUrl,
    //           "PNG",
    //           horizontalCursor,
    //           verticalCursor,
    //           width,
    //           height
    //         );
    //         horizontalCursor += width + horizontalSpacing;
    //       }
    //       horizontalCursor = baseHorizontalCursor;
    //       verticalCursor += height + verticalSpacing;
    //     }

    //     // pdf.output('dataurlnewwindow');
    //     // pdf.save("download.pdf");
    //     const out = pdf.output("datauristring");
    //     // console.log(out);
    //     // return;
    //     // pdf.output;
    //     // canvas.toBlob((blob) => {
    //     const cpj = new JSPM.ClientPrintJob();
    //     let myPrinter;
    //     if (printer) {
    //       myPrinter = new JSPM.InstalledPrinter(printer);
    //     } else {
    //       myPrinter = new JSPM.DefaultPrinter();
    //     }
    //     cpj.clientPrinter = myPrinter;
    //     const myFile = new JSPM.PrintFilePDF(
    //       out,
    //       JSPM.FileSourceType.URL,
    //       `${code}.pdf`,
    //       1
    //     );
    //     for (let i = 0; i < noOfCopies; i++) {
    //       cpj.files.push(myFile);
    //     }
    //     cpj.sendToClient();
    //     // });
    //   });

    // htmlToImage.toBlob(document.getElementById(code)).then(function (blob) {
    //   const cpj = new JSPM.ClientPrintJob();
    //   let myPrinter;
    //   if (printer) {
    //     myPrinter = new JSPM.InstalledPrinter(printer);
    //   } else {
    //     myPrinter = new JSPM.DefaultPrinter();
    //   }

    //   if (!myPrinter) {
    //     myPrinter = new JSPM.DefaultPrinter();
    //   }
    //   myPrinter.duplex = JSPM.DuplexMode.Simplex;

    //   cpj.clientPrinter = myPrinter;
    //   const myFile = new JSPM.PrintFile(
    //     blob,
    //     JSPM.FileSourceType.BLOB,
    //     `${code}.png`,
    //     noOfCopies
    //   );
    //   cpj.files.push(myFile);
    //   cpj.sendToClient();
    // });
    // appState.setAlert(message, "success", 5000);
  } catch (err) {
    appState.removeLoading();
    throw err;
  }
};
