import React, { useContext, useEffect, useState } from "react";
import SlideOverPanel from "../common/SlideOverPanel";
import ReactTooltip from "react-tooltip";
import { XIcon } from "@heroicons/react/outline";
import { AppStateContext } from "#contexts/appState";
import _ from "lodash";

const TariffSidePanel = ({
  showSidePanel,
  setShowSidePanel,
  fulfillmentType,
  tariffType,
  subTariffType,
  savedTariff,
  billingProfileFormConfig,
  saveTariff,
  currency,
  isEditMode,
  newId,
}) => {
  const uomConfig =
    billingProfileFormConfig?.tariffsConfig[tariffType]?.uomEntityCombinations;

  const [tariffDetails, setTariffDetails] = useState({
    tariffType,
    subTariffType,
    fulfillmentType,
  });

  const appState = useContext(AppStateContext);

  let selectedUom = uomConfig && uomConfig.length > 0 && uomConfig[0].uom;
  let entityTypes = uomConfig.find(
    (uomType) => uomType.uom === selectedUom,
  )?.entityTypes;

  const [uoms, setUoms] = useState(
    savedTariff?.uoms || [
      {
        id: 1,
        uom: selectedUom,
        entity: entityTypes && entityTypes.length > 0 && entityTypes[0],
      },
    ],
  );

  const [tiers, setTiers] = useState(
    savedTariff
      ? savedTariff?.tiers
      : [
          {
            rate: null,
            ranges: uoms.map((uomData) => {
              return { id: uomData.id, start: 1 };
            }),
          },
        ],
  );

  const [tariffs, setTariffs] = useState(
    savedTariff?.length
      ? savedTariff
      : [
          {
            ...tariffDetails,
            uoms: [
              {
                id: 1,
                uom: selectedUom,
                entity: entityTypes && entityTypes.length > 0 && entityTypes[0],
              },
            ],
            tiers: [
              {
                rate: null,
                ranges: uoms.map((uomData) => {
                  return { id: uomData.id, start: 1 };
                }),
              },
            ],
            resetTime: null,
            flatRate: true,
            id: newId,
          },
        ],
  );

  const [countAddNewTariff, setCountAddNewTariff] = useState(newId + 1);

  /**
   * Retrieves unit of measure (UOM) data based on a given UOM ID.
   * @param {number} uomId - The ID of the unit of measure.
   * @returns {Object[] | Object} An array of UOM objects or a single UOM object depending on the ID.
   */
  const getUomData = (uomId) => {
    if (uomId === 1) return uomConfig;
    return uomConfig.filter((uomType) => uomType.uom !== uoms[0].uom);
  };

  /**
   * Finds the entity name associated with a given range ID.
   * @param {number} rangeId - The ID of the range.
   * @param {Object[]} uoms - An array of UOM objects.
   * @returns {string} The name of the entity or "UNIT" if no specific entity is found.
   */
  const getEntityName = (rangeId, uoms) => {
    let selectedUom = uoms.find((uom) => uom.id === rangeId);
    return selectedUom ? selectedUom.entity || selectedUom.uom : "UNIT";
  };

  /**
   * Adds a new tariff to the existing list of tariffs.
   */
  const addNewTariff = () => {
    const countAddNewTariffDuplicate = countAddNewTariff;

    const newTariff = {
      ...tariffDetails,
      uoms: [
        {
          id: 1,
          uom: selectedUom,
          entity: entityTypes && entityTypes.length > 0 && entityTypes[0],
        },
      ],
      tiers: [
        {
          rate: null,
          ranges: uoms.map((uomData) => {
            return { id: uomData.id, start: 1 };
          }),
        },
      ],

      resetTime: null,
      flatRate: true,
      id: countAddNewTariff,
    };
    setTariffs([newTariff, ...tariffs]);
    setCountAddNewTariff(countAddNewTariffDuplicate + 1);
  };

  /**
   * Updates the flat rate status of a specific tariff.
   * @param {number} index - The index of the tariff to update.
   * @param {string} newRateType - The new rate type ("FLAT_RATE" to set true).
   */
  const updateTariffRateType = (index, newRateType) => {
    setTariffs((prevTariffs) =>
      prevTariffs.map((tariff, tIndex) => {
        if (tIndex === index) {
          return { ...tariff, flatRate: newRateType === "FLAT_RATE" };
        }
        return tariff;
      }),
    );
  };

  /**
   * Updates the start value of a range within a specific tier of a tariff.
   * @param {number} tariffIndex - The index of the tariff.
   * @param {number} tierIndex - The index of the tier within the tariff.
   * @param {number} rangeIndex - The index of the range within the tier.
   * @param {number} newStart - The new start value for the range.
   */
  const updateRangeStart = (tariffIndex, tierIndex, rangeIndex, newStart) => {
    setTariffs((prevTariffs) =>
      prevTariffs.map((tariff, tIndex) => {
        if (tIndex === tariffIndex) {
          return {
            ...tariff,
            tiers: tariff.tiers.map((tier, tiIndex) => {
              if (tiIndex === tierIndex) {
                return {
                  ...tier,
                  ranges: tier.ranges.map((range, riIndex) => {
                    if (riIndex === rangeIndex) {
                      return { ...range, start: newStart };
                    }
                    return range;
                  }),
                };
              }
              return tier;
            }),
          };
        }
        return tariff;
      }),
    );
  };

  /**
   * Updates the rate of a tier within a tariff.
   * @param {number} tariffIndex - The index of the tariff.
   * @param {number} tierIndex - The index of the tier within the tariff.
   * @param {number} newRate - The new rate to be set.
   */
  const updateTierRate = (tariffIndex, tierIndex, newRate) => {
    setTariffs((prevTariffs) =>
      prevTariffs.map((tariff, tIndex) => {
        if (tIndex === tariffIndex) {
          return {
            ...tariff,
            tiers: tariff.tiers.map((tier, tiIndex) => {
              if (tiIndex === tierIndex) {
                return { ...tier, rate: newRate };
              }
              return tier;
            }),
          };
        }
        return tariff;
      }),
    );
  };

  /**
   * Updates the rate of a tier within a tariff in float.
   * @param {number} tariffIndex - The index of the tariff.
   * @param {number} tierIndex - The index of the tier within the tariff.
   * @returns {void}
   */
  const handleBlur = (tariffIndex, tierIndex) => {
    setTariffs((prevTariffs) =>
      prevTariffs.map((tariff, tIndex) => {
        if (tIndex === tariffIndex) {
          return {
            ...tariff,
            tiers: tariff.tiers.map((tier, tiIndex) => {
              if (tiIndex === tierIndex) {
                return { ...tier, rate: parseFloat(tier.rate) || 0 };
              }
              return tier;
            }),
          };
        }
        return tariff;
      }),
    );
  };

  /**
   * Updates both the end and start values of ranges within a tier of a tariff.
   * @param {number} tariffIndex - The index of the tariff.
   * @param {number} tierIndex - The index of the tier within the tariff.
   * @param {number} rangeIndex - The index of the range within the tier.
   * @param {number} newEnd - The new end value to be set.
   */
  const updateRangeEndAndStart = (
    tariffIndex,
    tierIndex,
    rangeIndex,
    newEnd,
  ) => {
    let endValuelessThanStart = false;
    setTariffs((prevTariffs) =>
      prevTariffs.map((tariff, tIndex) => {
        if (tIndex === tariffIndex) {
          return {
            ...tariff,
            tiers: tariff.tiers.map((tier, tiIndex) => {
              if (tiIndex === tierIndex) {
                return {
                  ...tier,
                  ranges: tier.ranges.map((range, riIndex) => {
                    if (riIndex === rangeIndex) {
                      const parsedNewEnd = parseInt(newEnd);
                      if (parsedNewEnd < range.start) {
                        endValuelessThanStart = true;
                        alert(`End value cannot be less than start value.`);
                        return range;
                      }
                      return { ...range, end: parsedNewEnd };
                    } else if (riIndex === rangeIndex + 1) {
                      return { ...range, start: parseInt(newEnd) + 1 };
                    }
                    return range;
                  }),
                };
              } else if (
                tiIndex === tierIndex + 1 &&
                rangeIndex === tier.ranges.length - 1 &&
                !endValuelessThanStart
              ) {
                return {
                  ...tier,
                  ranges: tier.ranges.map((range, riIndex) => {
                    if (riIndex === 0) {
                      return { ...range, start: parseInt(newEnd) + 1 };
                    }
                    return range;
                  }),
                };
              }
              return tier;
            }),
          };
        }
        return tariff;
      }),
    );
  };
  {
    isEditMode && (
      <button
        onClick={() => {
          // when a rate is added -> 1) last range gets and end value and 2) a new range is added with just start
          let lastTier = tiers[tiers?.length - 1];
          setTiers([
            ...tiers.map((tier, tIndex) => {
              if (tIndex === tiers?.length - 1)
                return {
                  ...tier,
                  ranges: tier.ranges.map((range) => {
                    return { ...range, end: range?.start + 1 };
                  }),
                };
              return tier;
            }),
            {
              rate: null,
              ranges: lastTier.ranges.map((range) => {
                return { ...range, start: range.start + 1 + 1 };
              }),
            },
          ]);
        }}
        className="flex text-[16px] font-semibold text-[#417492] underline">
        + Add More Rates
      </button>
    );
  }

  /**
   * Adds more rates to the last tier of a specific tariff.
   * @param {number} tariffIndex - The index of the tariff to be updated.
   */
  const addMoreRates = (tariffIndex) => {
    setTariffs((prevTariffs) =>
      prevTariffs.map((tariff, tIndex) => {
        if (tIndex === tariffIndex) {
          const lastTier = tariff.tiers[tariff.tiers?.length - 1];
          return {
            ...tariff,
            tiers: [
              ...tariff.tiers.map((tier, idx) => {
                if (idx === tariff.tiers?.length - 1)
                  return {
                    ...tier,
                    ranges: tier.ranges.map((range) => {
                      return { ...range, end: range?.start + 1 };
                    }),
                  };
                return tier;
              }),
              {
                rate: null,
                ranges: lastTier.ranges.map((range) => {
                  return { ...range, start: range.start + 1 + 1 };
                }),
              },
            ],
          };
        }
        return tariff;
      }),
    );
  };

  /**
   * Determines if saving a tariff is disabled based on the completeness of the rate information.
   * @param {Object} tariff - The tariff object to check.
   * @returns {boolean} True if saving is disabled, false otherwise.
   */
  const isSaveDisabled = (tariff) => {
    return tariff.tiers.some((tier) => tier.rate === null || isNaN(tier.rate));
  };

  /**
   * Handles the save operation for tariffs, checking for valid values before saving.
   * @param {Object[]} tariffs - The array of tariffs to save.
   */
  const handleSave = (tariffs) => {
    let entitiesDuringSave = [];

    const updatedTariffs = tariffs.filter((tariff) => {
      if (isSaveDisabled(tariff)) {
        appState.setAlert(
          "Please enter a valid value in the 'Fees' field to save your changes.",
          "error",
          5000,
        );
        return false;
      }

      for (const uom of tariff.uoms) {
        const entity = uom.entity;
        entitiesDuringSave.push(entity);
      }

      const duplicates = findDuplicates(entitiesDuringSave) || [];

      if (duplicates.length > 0) {
        appState.setAlert(
          `The entity '${duplicates[0]}' is already used in another tariff. Please ensure unique entities for each uom.`,
          "error",
          5000,
        );
        return false;
      }

      return true;
    });

    if (updatedTariffs.length === tariffs.length) {
      saveTariff(updatedTariffs);
    }
  };

  /**
   * Retrieves a set of all used entities across all tariffs.
   * @returns {Set} A set of used entities.
   */
  const getAllUsedEntities = () => {
    const usedEntities = new Set();
    tariffs?.forEach((tariff) => {
      tariff.uoms?.forEach((uom) => {
        if (uom?.entity) {
          usedEntities.add(uom.entity);
        }
      });
    });
    return usedEntities;
  };

  /**
   * Retrieves an array of all duplicate entities across all tariffs.
   * @returns {Array} An array of duplicate elements.
   */
  function findDuplicates(array) {
    const grouped = _.groupBy(array);
    return _.keys(_.pickBy(grouped, (x) => x.length > 1));
  }

  return (
    <SlideOverPanel open={showSidePanel} setOpen={setShowSidePanel} title={""}>
      <div className="relative h-full px-4">
        <div className="mb-16 overflow-y-auto">
          <div className="text-[24px] font-semibold">
            {`${tariffType} - ${subTariffType}`}
          </div>

          <div className="mt-4 flex items-center justify-between text-[22px] font-semibold">
            Tariff Details
            {["LABOR", "LABELS"].includes(tariffType) === false ? (
              <button
                className="rounded bg-224E73 px-3 py-1 font-inter text-sm text-white"
                onClick={addNewTariff}>
                Add New
              </button>
            ) : null}
          </div>
          {tariffs.map((tariff, index) => (
            <div className="my-8 rounded-lg bg-[#FAFAFA] p-3" key={index}>
              <h2 className="text-[20px] font-semibold">
                Tariff - {tariffs?.length - index}
              </h2>
              {/* Uom config */}
              {uomConfig && (
                <div className="my-2 flex flex-col gap-2">
                  {tariff.uoms?.map((uomData, uomIndex) => {
                    let entityTypes = uomConfig.find(
                      (uomType) => uomType.uom === uomData.uom,
                    )?.entityTypes;

                    let usedEntities = getAllUsedEntities();

                    return (
                      <div
                        className="rounded-lg bg-[#FAFAFA] p-3"
                        key={uomIndex}>
                        <div className="flex justify-between">
                          <div className="text-[16px] text-[#717679]">
                            UOM {uomData.id}
                          </div>
                          {uomData.id === 2 && (
                            <button
                              onClick={() => {
                                let updatedUoms = [tariff.uoms[0]];
                                let updatedTiers = [];

                                if (tariff.flatRate) {
                                  updatedTiers.push({
                                    rate: null,
                                    ranges: updatedUoms.map((uom) => ({
                                      id: uom.id,
                                      start: 1,
                                    })),
                                  });
                                } else {
                                  updatedTiers.push(
                                    {
                                      rate: null,
                                      ranges: updatedUoms.map((uom) => ({
                                        id: uom.id,
                                        start: 1,
                                        end: 2,
                                      })),
                                    },
                                    {
                                      rate: null,
                                      ranges: updatedUoms.map((uom) => ({
                                        id: uom.id,
                                        start: 3,
                                      })),
                                    },
                                  );
                                }
                                setTariffs(
                                  tariffs.map((t) => {
                                    if (t === tariff) {
                                      return {
                                        ...t,
                                        uoms: updatedUoms,
                                        tiers: updatedTiers,
                                      };
                                    }
                                    return t;
                                  }),
                                );
                              }}>
                              <XIcon className="h-7 w-7 p-1 text-[#cool-gray/900]" />
                            </button>
                          )}
                        </div>
                        <div className="mt-2 text-[14px] text-[#717679]">
                          Unit of Measure
                        </div>
                        <select
                          disabled={!isEditMode}
                          value={uomData.uom}
                          onChange={(e) => {
                            const selectedUom = e.target.value;
                            setTariffs(
                              tariffs.map((t) => {
                                if (t === tariff) {
                                  return {
                                    ...t,
                                    uoms: t.uoms.map((uom) => {
                                      if (uom.id === uomData.id) {
                                        let entityTypes = uomConfig?.find(
                                          (uomType) =>
                                            uomType.uom === selectedUom,
                                        )?.entityTypes;
                                        return {
                                          ...uom,
                                          uom: selectedUom,
                                          entity: entityTypes && entityTypes[0],
                                        };
                                      }
                                      return uom;
                                    }),
                                  };
                                }
                                return t;
                              }),
                            );
                          }}
                          className="w-full rounded border border-[#DDDFE0]">
                          {getUomData(uomData.id).map((uomType, index) => (
                            <option key={index}>{uomType.uom}</option>
                          ))}
                        </select>
                        <div className="mt-2 text-[14px] text-[#717679]">
                          Entity
                        </div>
                        <select
                          disabled={
                            !isEditMode ||
                            !entityTypes ||
                            entityTypes.length === 0
                          }
                          value={uomData.entity}
                          onChange={(e) => {
                            const selectedEntity = e.target.value;
                            setTariffs(
                              tariffs.map((t) => {
                                if (t === tariff) {
                                  return {
                                    ...t,
                                    uoms: t.uoms.map((uom) => {
                                      if (uom.id === uomData.id) {
                                        return {
                                          ...uom,
                                          entity: selectedEntity,
                                        };
                                      }
                                      return uom;
                                    }),
                                  };
                                }
                                return t;
                              }),
                            );
                          }}
                          className={`${!entityTypes || entityTypes.length === 0 ? "cursor-not-allowed bg-gray-100" : ""} w-full rounded border border-[#DDDFE0]`}>
                          {entityTypes &&
                            entityTypes.map((entity, index) => (
                              <option
                                key={index}
                                value={entity}
                                disabled={
                                  usedEntities.has(entity) &&
                                  uomData.entity !== entity
                                }>
                                {entity}
                              </option>
                            ))}
                        </select>
                      </div>
                    );
                  })}

                  {uomConfig.length > 1 &&
                    tariff.uoms?.length === 1 &&
                    billingProfileFormConfig?.tariffsConfig[tariff.tariffType]
                      ?.numberOfUoms > 1 &&
                    isEditMode && (
                      <button
                        onClick={() => {
                          let nextUom = uomConfig.filter(
                            (uomType) => uomType.uom !== tariff.uoms[0].uom,
                          )[0]?.uom;
                          let nextEntityTypes = uomConfig.find(
                            (uomType) => uomType.uom === nextUom,
                          )?.entityTypes;
                          let updatedUoms = [
                            ...tariff.uoms,
                            {
                              id: tariff.uoms.length + 1,
                              uom: nextUom,
                              entity:
                                nextEntityTypes && nextEntityTypes.length > 0
                                  ? nextEntityTypes[0]
                                  : null,
                            },
                          ];
                          let updatedTiers = [];
                          if (tariff.flatRate) {
                            updatedTiers = [
                              {
                                rate: null,
                                ranges: updatedUoms.map((uomData) => ({
                                  id: uomData.id,
                                  start: 1,
                                })),
                              },
                            ];
                          } else {
                            updatedTiers = [
                              {
                                rate: null,
                                ranges: updatedUoms.map((uomData) => ({
                                  id: uomData.id,
                                  start: 1,
                                  end: 2,
                                })),
                              },
                              {
                                rate: null,
                                ranges: updatedUoms.map((uomData) => ({
                                  id: uomData.id,
                                  start: 3,
                                })),
                              },
                            ];
                          }

                          setTariffs(
                            tariffs.map((t) => {
                              if (t === tariff) {
                                return {
                                  ...t,
                                  uoms: updatedUoms,
                                  tiers: updatedTiers,
                                };
                              }
                              return t;
                            }),
                          );
                        }}
                        className="flex text-[16px] font-semibold text-[#417492] underline">
                        + Add More UOM
                      </button>
                    )}
                </div>
              )}

              {/* Time Based Frequency */}
              <h2 className="mt-6 text-[20px] font-semibold">
                Time Based Frequency
              </h2>
              <input
                disabled={!isEditMode}
                className="rounded"
                checked={
                  tariff?.resetTime ||
                  billingProfileFormConfig?.tariffsConfig[tariff.tariffType]
                    ?.timeFrequency?.required
                }
                onChange={() => {
                  if (
                    !billingProfileFormConfig?.tariffsConfig[tariff.tariffType]
                      ?.timeFrequency?.required
                  ) {
                    setTariffs((tariffs) =>
                      tariffs.map((t, idx) =>
                        idx === index ? { ...t, resetTime: "DAILY" } : t,
                      ),
                    );
                  }
                }}
                type="checkbox"
              />
              <label className="ml-2">Opt for time based frequency</label>

              {(tariff?.resetTime ||
                billingProfileFormConfig?.tariffsConfig[tariff.tariffType]
                  ?.timeFrequency?.required) && (
                <select
                  disabled={!isEditMode}
                  value={tariff.resetTime}
                  onChange={(e) =>
                    setTariffs((tariffs) =>
                      tariffs.map((t, idx) =>
                        idx === index ? { ...t, resetTime: e.target.value } : t,
                      ),
                    )
                  }
                  className="mt-2 w-full rounded border border-[#DDDFE0] bg-transparent p-1">
                  {billingProfileFormConfig?.tariffsConfig[
                    tariff.tariffType
                  ]?.timeFrequency?.options.map((option, idx) => (
                    <option key={idx} value={option}>
                      {option.replace("_", "-")}
                    </option>
                  ))}
                </select>
              )}

              {/* Calculation Options */}
              {billingProfileFormConfig?.tariffsConfig[tariff.tariffType]
                ?.calculationStrategyOptions?.length > 0 && (
                <div className="my-8">
                  <h2 className="mt-6 text-[20px] font-semibold">
                    Calculation Options
                  </h2>
                  <div className="flex flex-col">
                    <div>
                      <input
                        disabled={!isEditMode}
                        checked={!tariff?.options?.endOfPeriodCalculation}
                        className="cursor-pointer"
                        type="radio"
                        id="Maximum_Occupancy"
                        name={`calculation_options_${index}`}
                        value={false}
                        onChange={() =>
                          setTariffs((tariffs) =>
                            tariffs.map((t, idx) =>
                              idx === index
                                ? {
                                    ...t,
                                    options: {
                                      ...t.options,
                                      endOfPeriodCalculation: false,
                                    },
                                  }
                                : t,
                            ),
                          )
                        }
                      />
                      <label
                        className="ml-2 text-[16px] font-semibold text-[#344054]"
                        htmlFor="Maximum_Occupancy">
                        Maximum Occupancy
                      </label>
                    </div>
                    <div>
                      <input
                        disabled={!isEditMode}
                        checked={tariff?.options?.endOfPeriodCalculation}
                        className="cursor-pointer"
                        type="radio"
                        id="End_of_Period_Occupancy"
                        name={`calculation_options_${index}`}
                        value={true}
                        onChange={() =>
                          setTariffs((tariffs) =>
                            tariffs.map((t, idx) =>
                              idx === index
                                ? {
                                    ...t,
                                    options: {
                                      ...t.options,
                                      endOfPeriodCalculation: true,
                                    },
                                  }
                                : t,
                            ),
                          )
                        }
                      />
                      <label
                        className="ml-2 text-[16px] font-semibold text-[#344054]"
                        htmlFor="End_of_Period_Occupancy">
                        End of Period Occupancy
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/* Tariff Rates  */}
              <h2 className="mt-6 text-[20px] font-semibold">Tariff Rates</h2>
              <div className="flex gap-8">
                <div>
                  <input
                    disabled={!isEditMode}
                    checked={tariff.flatRate}
                    type="radio"
                    id={`Flat_Rate_${index}`}
                    name={`tariff_rates_${index}`}
                    value="FLAT_RATE"
                    onChange={() => updateTariffRateType(index, "FLAT_RATE")}
                  />
                  <label
                    className="ml-2 text-[16px] font-semibold text-[#344054]"
                    htmlFor={`Flat_Rate_${index}`}>
                    Flat Rate
                  </label>
                </div>
                <div>
                  <input
                    disabled={!isEditMode}
                    checked={!tariff.flatRate}
                    type="radio"
                    id={`Variable_Rate_${index}`}
                    name={`tariff_rates_${index}`}
                    value="VARIABLE_RATE"
                    onChange={() =>
                      updateTariffRateType(index, "VARIABLE_RATE")
                    }
                  />
                  <label
                    className="ml-2 text-[16px] font-semibold text-[#344054]"
                    htmlFor={`Variable_Rate_${index}`}>
                    Tiered Rate
                  </label>
                </div>
              </div>
              {tariff.flatRate ? (
                tariff.tiers.map((tier, tierIndex) => {
                  return (
                    <div className="mt-3 flex gap-2 rounded-lg bg-[#FAFAFA] p-3">
                      {tier.ranges.map((range, rangeIndex) => {
                        return (
                          <div>
                            <div className="text-[14px] text-[#717679]">
                              Value{" "}
                              {`(${getEntityName(range.id, tariff.uoms)})`}
                            </div>
                            <input
                              disabled={!isEditMode}
                              value={range.start}
                              onChange={(e) =>
                                updateRangeStart(
                                  index,
                                  tierIndex,
                                  rangeIndex,
                                  parseInt(e.target.value),
                                )
                              }
                              type="number"
                              min={1}
                              className="w-[80px] rounded border border-[#DDDFE0] bg-transparent p-1"
                            />
                          </div>
                        );
                      })}
                      <div className="mx-2 mt-6">=</div>
                      <div>
                        <div className="text-[14px] text-[#717679]">
                          Fees ({currency})
                        </div>
                        <div className="flex gap-1">
                          <input
                            disabled={!isEditMode}
                            type="text"
                            key={tierIndex}
                            value={tier.rate || ""}
                            onChange={(e) =>
                              updateTierRate(index, tierIndex, e.target.value)
                            }
                            onBlur={() => handleBlur(index, tierIndex)}
                            className="w-[80px] rounded border border-[#DDDFE0] bg-transparent p-1"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  {tariff.tiers.map((tier, tierIndex) => {
                    return (
                      <div className="mt-3 flex justify-between rounded-lg bg-[#FAFAFA] p-3">
                        <div>
                          {tier.ranges.map((range, rangeIndex) => {
                            return (
                              <div className="flex gap-2">
                                <div className="w-[100px]">
                                  <div className="truncate text-[14px] text-[#717679]">
                                    Value{" "}
                                    {`(${getEntityName(range.id, tariff.uoms)})`}
                                  </div>
                                  <input
                                    disabled={!isEditMode}
                                    readOnly
                                    value={range.start}
                                    onChange={(e) =>
                                      updateRangeStart(
                                        index,
                                        tierIndex,
                                        rangeIndex,
                                        parseInt(e.target.value),
                                      )
                                    }
                                    type="number"
                                    min={1}
                                    className="w-[80px] rounded border border-[#DDDFE0] bg-transparent p-1"
                                  />
                                </div>
                                {tierIndex < tariff.tiers?.length - 1 ? (
                                  <>
                                    <div className="mx-1 mt-6">To</div>

                                    <div className="w-[100px]">
                                      <div className="truncate text-[14px] text-[#717679]">
                                        Value{" "}
                                        {`(${getEntityName(range.id, tariff.uoms)})`}
                                      </div>
                                      <input
                                        disabled={!isEditMode}
                                        value={range.end}
                                        onChange={(e) =>
                                          updateRangeEndAndStart(
                                            index,
                                            tierIndex,
                                            rangeIndex,
                                            e.target.value,
                                          )
                                        }
                                        type="number"
                                        className="w-[80px] rounded border border-[#DDDFE0] bg-transparent p-1"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="mt-6">and more</div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div className="mx-2 my-auto">=</div>
                        <div className="my-auto">
                          <div className="text-[14px] text-[#717679]">
                            Fees (per, {currency})
                          </div>
                          <div className="flex gap-1">
                            <input
                              disabled={!isEditMode}
                              type="text"
                              key={tierIndex}
                              value={tier.rate || ""}
                              onChange={(e) =>
                                updateTierRate(index, tierIndex, e.target.value)
                              }
                              onBlur={() => handleBlur(index, tierIndex)}
                              className="w-[80px] rounded border border-[#DDDFE0] bg-transparent p-1"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {isEditMode && (
                    <button
                      onClick={() => addMoreRates(index)}
                      className="flex text-[16px] font-semibold text-[#417492] underline">
                      + Add More Rates
                    </button>
                  )}
                </div>
              )}
              <div className="absolute bottom-0 right-4 w-full bg-white shadow-sm">
                <div className="my-2 flex justify-end gap-4">
                  <button
                    onClick={() => setShowSidePanel(false)}
                    className="w-32 rounded-lg border border-[#DDD] py-1 text-[16px]">
                    Cancel
                  </button>
                  <button
                    disabled={!isEditMode || isSaveDisabled(tariff)}
                    data-tip
                    data-for="saveTariff"
                    onClick={() => handleSave(tariffs)}
                    className={`${
                      isSaveDisabled(tariff) ? "opacity-60" : ""
                    } w-32 rounded-lg bg-[#417492] py-1 text-[16px] text-white`}>
                    Save
                  </button>
                  <ReactTooltip
                    id="saveTariff"
                    place="bottom"
                    type="info"
                    effect="solid">
                    Please enter a valid value in the 'Fees' field to save your
                    changes.
                  </ReactTooltip>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </SlideOverPanel>
  );
};

export default TariffSidePanel;
