import Modal from "#components/utils/Modal";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import Toggle from "#components/utils/Toggle";
import _ from "lodash";

const AddScanForm = ({
  onClose,
  title,
  selectedWorkflow,
  onSubmit,
  onChangeMultiSelect,
  eligibleScans,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Any of the following fields
        </span>
      </div>
      <div>
        <div id="selectingStations">
          <MultiSelectAutoComplete
            options={eligibleScans.map((item) => ({
              name: item.name,
              field: item,
            }))}
            labelKey={"name"}
            valueKey={"field"}
            setValues={(e) => onChangeMultiSelect("buildScans", e)}
            values={selectedWorkflow?.buildScans || []}
          />
        </div>
      </div>
      <div className="flex items-center space-x-4 px-2">
        <div>
          <Toggle
            enabled={selectedWorkflow.manualEntryEnabled}
            setEnabled={(e) => onChangeMultiSelect("manualEntryEnabled", e)}
          />
        </div>
        <div className="text-lg">Allow manual entry</div>
      </div>
    </div>
  </Modal>
);

export default AddScanForm;
