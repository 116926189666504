import Select from "react-select";
import { useState } from "react";

const SelectWarehouseCode = ({ warehouses, onSubmit, onSkip }) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  return (
    <div className="-mt-20 flex h-screen w-full items-center justify-center">
      <div className="flex-col">
        <div className="rounded-2xl border bg-white shadow-lg">
          <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
            <Select
              options={
                warehouses &&
                warehouses
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => ({
                    value: item.id,
                    label: `${item.name} - ${item.code}`,
                  }))
              }
              value={selectedWarehouse}
              onChange={(e) => setSelectedWarehouse(e)}
              className="w-96"
              placeholder="Select Warehouse"
              isClearable={true}
            />
            <div className="flex space-x-2">
              <div
                className={`mt-4 flex-1 rounded-md py-2 text-center text-xl text-white ${
                  !!selectedWarehouse ? "bg-2C7695" : "bg-gray-400"
                }`}
                onClick={() =>
                  !!selectedWarehouse
                    ? onSubmit(selectedWarehouse)
                    : console.log()
                }>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectWarehouseCode;
