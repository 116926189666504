import { useEffect, useState } from "react";
import CatalogDefaultIcon from "#static/images/catalogplaceholder.png";
import Checkbox from "#components/utils/Checkbox";
import CatalogProductActions from "./CatalogProductActions";

export default function GridViewProducts({
  products,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  getCatalog,
  actionsOnProduct,
}) {
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  return (
    <>
      <div className="flex space-x-2 p-2">
        <Checkbox
          role="checkbox"
          onChange={selectAllRows}
          name="selectAllRows"
          checked={allRowsSelected}
        />
        <div className="text-[#717679 ] text-lg">Select All</div>
      </div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {products.map((product, rowIndex) => (
          <li
            key={product.id}
            className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg border border-[#DDDFE0] bg-white text-center">
            <div className="absolute p-4">
              <Checkbox
                role="checkbox"
                onChange={(_) => {
                  selectProduct(product.id, false, products);
                }}
                name="product"
                value={product.id}
                checked={selectedProducts?.includes(product.id)}
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => getCatalog(product.id)}>
              <div className="flex flex-1 flex-col p-8">
                <img
                  className="mx-auto h-32 w-32 shrink-0 rounded-full"
                  src={product.images?.[0]?.url || CatalogDefaultIcon}
                  alt=""
                />
              </div>
              <div className="flex items-start justify-start truncate text-left capitalize">
                <div className="p-2">
                  <h3 className="text-lg text-[#717679]">SKU: {product.sku}</h3>
                  <p className="text-lg text-[#111827]">
                    {truncate(product.name)}
                  </p>
                  <p className="text-lg font-bold text-[#111827]">
                    {product.pricing?.computedPriceFields?.totalPrice > 0
                      ? `$${product.pricing?.computedPriceFields?.totalPrice.toFixed(
                          2,
                        )}`
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute -top-2 right-0 pr-4">
              <CatalogProductActions
                actionsOnProduct={actionsOnProduct}
                product={product}
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}

function truncate(str) {
  return str?.length > 20 ? str.substring(0, 20) + "..." : str;
}
