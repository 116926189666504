import _ from "lodash";
import SelectWarehouseCustomerCode from "#components/putaway/bundle/SelectWarehouseCustomerCode";
import ScanOrEnterManually from "#components/putaway/bundle/ScanOrEnterManually";
import DetailedView from "#components/putaway/bundle/DetailedView";
import withBundlePutawayLogic from "#components/HOC/withBundlePutawayLogic";
import ConsignmentItemsSelection from "#components/putaway/consignment/ConsignmentItemsSelection";
import BundleItemsSelesction from "#components/putaway/bundle/BundleItemsList";
import BundleItemsSelection from "#components/putaway/bundle/BundleItemsSelection";
import { useState } from "react";

const BundlePutaway = ({
  currentSku,
  setCurrentSku,
  scanBarcode,
  confirmItem,
  submitWarehouseCustomerCode,
  customer,
  customers,
  warehouse,
  warehouses,
  appState,
  currentTask,
  singlePutawayItem,
  cancelItem,
  currentProduct,
  putawayAvailableQty,
  setPutawayAvailableQty,
}) => {
  if (!customer || !warehouse) {
    return (
      <>
        <SelectWarehouseCustomerCode
          customers={customers}
          warehouses={warehouses}
          onSubmit={submitWarehouseCustomerCode}
        />
      </>
    );
  }

  if (currentSku) {
    return (
      <>
        <div className="fixed bottom-0 right-0 bg-green-600 p-4 text-center text-xl text-white">
          Listening for Print Commands
        </div>
        <div className="left-0 w-full bg-black text-center text-2xl text-white">
          {customers.find((item) => item.id === customer)?.name}
          {warehouses.find((item) => item.id === warehouse)?.name}
        </div>
        <div className="mt-10">
          <DetailedView
            currentSku={currentSku}
            confirmItem={confirmItem}
            scanBarcode={scanBarcode}
            setCurrentSku={setCurrentSku}
            showCancelOption={false}
            putawayAvailableQty={putawayAvailableQty}
            setPutawayAvailableQty={setPutawayAvailableQty}
            cancelItem={cancelItem}
            currentProduct={currentProduct}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="left-0 w-full bg-black text-center text-2xl text-white">
        {customers.find((item) => item.id === customer)?.name}
        {warehouses.find((item) => item.id === warehouse)?.name}
      </div>
      <div className="fixed bottom-0 right-0 bg-green-600 p-4 text-center text-xl text-white">
        Listening for Print Commands
      </div>

      <BundleItemsSelection
        customer={customer}
        warehouse={warehouse}
        scanBarcode={scanBarcode}
        appState={appState}
        setCurrentSku={setCurrentSku}
        currentTask={currentTask}
        singlePutawayItem={singlePutawayItem}
        putawayAvailableQty={putawayAvailableQty}
        setPutawayAvailableQty={setPutawayAvailableQty}
        cancelItem={cancelItem}
        currentProduct={currentProduct}
      />
    </>
  );
};

export default withBundlePutawayLogic(BundlePutaway);
