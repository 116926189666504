import { Tab } from "@headlessui/react";
import { UserAddIcon } from "@heroicons/react/outline";
// import PageButton from "#components/shared/PageButton";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ tabs, extra }) => {
  return (
    <Tab.Group>
      <Tab.List className="flex items-center">
        <div className="flex-1 space-x-4 bg-white p-1 px-0">
          {tabs &&
            Object.keys(tabs).map((tab) => (
              <Tab
                key={tab}
                className={({ selected }) =>
                  classNames(
                    "text-md px-3 py-2.5 font-medium leading-5",
                    "border-b border-b-2 font-montserrat focus:outline-none focus:ring-0",
                    selected
                      ? "border-2C7695 text-2C7695"
                      : "rounded-full border-transparent bg-transparent text-7F878E",
                  )
                }>
                {tab}
              </Tab>
            ))}
        </div>
        <div className="items-center justify-end">{extra}</div>
      </Tab.List>
      <Tab.Panels className="mt-2">
        {tabs &&
          Object.values(tabs).map((tabInner, idx) => (
            <Tab.Panel
              key={idx}
              className="textWhite-300 mt-4 flex flex-col border bg-EBEBEB">
              {tabInner}
            </Tab.Panel>
          ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Tabs;
