import Modal from "#components/utils/Modal";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";

const MultipleSelectActivityForm = ({
  onClose,
  title,
  onChangeDropdown,
  selectedActivity,
  onSubmit,
  hopstackModules,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Activity
        </span>
      </div>

      <div>
        <Dropdown
          placeholder={"Activity"}
          list={hopstackModules}
          labelKey="name"
          valueKey="name"
          name="selectedActivity"
          setSelected={onChangeDropdown}
          selectedValue={selectedActivity}
        />
      </div>
    </div>
  </Modal>
);

export default MultipleSelectActivityForm;
