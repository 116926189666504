import moment from "moment-timezone";
import _ from "lodash";
import SearchSortFilter from "#components/common/SearchSortFilter";
import { AppStateContext } from "#contexts/appState";
import { useContext } from "react";
import CustomSelectDropDown from "../common/CustomSelectDropDown";
import CustomTableWithTheme from "../common/CustomTableWithTheme";

const headers = ["ID", "User", "Client", "Date", "Type", "Status"];
const noValuesText = "No notifications";

const NotificationsList = ({
  notifications,
  filters,
  onChangeFilter,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  getNotification,
  users,
  customers,
}) => {
  const appState = useContext(AppStateContext);
  const STATUS_OPTIONS = [
    { value: "PENDING", label: "PENDING" },
    { value: "APPROVED", label: "APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
  ];

  const TYPE_OF_NOTIFICATION = [
    { value: "RECEIVING_APPROVAL", label: "RECEIVING APPROVAL" },
    { value: "SHIPMENT_PLAN_ERROR", label: "SHIPMENT PLAN ERROR" },
    { value: "REPORT_GENERATION_FAILED", label: "REPORT GENERATION FAILED" },
    { value: "REPORT_GENERATED", label: "REPORT GENERATED" },
    { value: "ORDER_NOTIFICATION", label: "ORDER NOTIFICATION" },
    { value: "BATCH_CREATION", label: "BATCH CREATION" },
  ].concat(
    ["62cdb0ac6227b7ed224d79aa", "62bc96621cbab8cc2e0a05aa"].includes(
      appState?.tenant?.id,
    )
      ? [{ value: "NEW_SHOPIFY_ORDER", label: "NEW SHOPIFY ORDER" }]
      : [],
  );

  return (
    <div className="tableWrapper">
      <div className="flex py-5">
        <div className="flex h-10 min-w-max flex-1 items-center justify-between">
          <div className="flex w-96 items-center space-x-4">
            <CustomSelectDropDown
              label={"Status"}
              options={STATUS_OPTIONS}
              value={
                filters?.status
                  ? STATUS_OPTIONS.find(
                      (option) => option.value == filters.status[0],
                    )
                  : ""
              }
              onChange={(selectedOption) =>
                onChangeFilter("status", [selectedOption.value], true)
              }
              className="min-w-max"
              placeholder="Status"
              isClearable={true}
            />
            <CustomSelectDropDown
              label={"Type Of Notification"}
              options={TYPE_OF_NOTIFICATION}
              value={
                filters?.typeOfNotification
                  ? TYPE_OF_NOTIFICATION.find(
                      (option) => option.value == filters.typeOfNotification[0],
                    )
                  : ""
              }
              onChange={(selectedOption) =>
                onChangeFilter(
                  "typeOfNotification",
                  [selectedOption.value],
                  true,
                )
              }
              className="min-w-max"
              placeholder="Type of Notification"
              isClearable={true}
            />
          </div>
        </div>
      </div>

      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Date",
            value: "createdAt",
          },
          {
            name: "Status",
            value: "status",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 rounded-full bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {notifications.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {notifications.map((notification, index) => (
            <tr
              key={notification.id}
              className={`${
                notification.status === "PENDING"
                  ? "bg-white"
                  : notification.status === "APPROVED"
                    ? "bg-green-100"
                    : "bg-red-200"
              }`}>
              <td
                className={`tracking-widerrounded-tl cursor-pointer rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}
                onClick={() => getNotification(notification.id)}>
                {notification.id}
              </td>

              <td className="cursor-pointer rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-primaryAccent">
                {notification.requestor &&
                users &&
                users.length > 0 &&
                users.findIndex((i) => i.id === notification.requestor) !== -1
                  ? users.find((i) => i.id === notification.requestor).name
                  : notification.requestor}
              </td>

              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {customers &&
                  customers.find((item) => item.id === notification.customer)
                    ?.name}
              </td>

              <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {moment(notification.createdAt).format("MMM Do YYYY, h:mm a")}
              </td>

              <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {notification.typeOfNotification}
              </td>

              <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {notification.status}
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default NotificationsList;
