import React, { useState } from "react";
import { Table } from "antd";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { useCubeQuery } from "@cubejs-client/react";
import TextField from "#components/utils/TextField";

const DetailedStat = ({ title, onClose, stat, onChangeSearchFilter }) => {
  const { resultSet, isLoading } = useCubeQuery(stat.query);
  return (
    <div>
      <div className="flex justify-start bg-white p-8 shadow-xl">
        <button onClick={onClose}>
          <ChevronLeftIcon className="h-8 w-8" />
        </button>
        <span className="ml-3 mt-1 text-xl">{title || "Detailed Report"}</span>
      </div>
      <div className="overflow-x-scroll bg-white p-4">
        <div className="mb-4 w-1/3">
          <TextField
            type="text"
            label="Search SKUs"
            placeholder="Search SKUs"
            onChange={(e) => onChangeSearchFilter(e.target.value)}
          />
        </div>
        <Table
          columns={stat.tableColumns}
          dataSource={
            resultSet?.loadResponse?.results[0]?.data
              ? resultSet?.loadResponse?.results[0]?.data
                  ?.map((d) => {
                    if (stat.statKey.includes("INVENTORY_AGE")) {
                      if (
                        !d["Productvariants.inventory_age"] ||
                        d["Productvariants.inventory_age"] < 0
                      ) {
                        d["Productvariants.inventory_age"] =
                          d["Productvariants.since_received"];
                      }
                    }
                    return d;
                  })
                  .filter((d, i) => {
                    const result =
                      resultSet?.loadResponse?.results[0]?.data?.find(
                        (d2) =>
                          d2["Skubinmappings.binlocation"] ===
                            d["Skubinmappings.binlocation"] &&
                          d2["Productvariants.sku"] ===
                            d["Productvariants.sku"] &&
                          d2["Skubinmappings.created_at"] >
                            d["Skubinmappings.created_at"],
                      );
                    return !!!result;
                  })
              : []
          }
          loading={isLoading}
          pagination={{ showSizeChanger: false }}
        />
      </div>
    </div>
  );
};

export default DetailedStat;
