import Checkbox from "#components/utils/Checkbox";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import { ExclamationCircleIcon, GiftIcon } from "@heroicons/react/outline";
import AddButton from "#components/utils/AddButton";
import ReactTooltip from "react-tooltip";

const RegularPackProducts = ({
  eligibleProducts,
  onChangeItem,
  setEligibleProducts,
  keyword,
  setKeyword,
  bundlesWithOverlap,
  setBundlesWithOverlap,
}) => {
  const HazmatIcon = () => {
    return (
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.3573 15.7195L12.6935 4.17748C12.1719 3.27417 10.8681 3.27417 10.3466 4.17748L3.68281 15.7195C3.16128 16.6228 3.81319 17.752 4.85624 17.752H18.1838C19.2269 17.752 19.8788 16.6228 19.3573 15.7195Z"
          fill="#EEB932"
          stroke="#111827"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0033 10.5466C15.2506 11.0563 15.3794 11.6178 15.3794 12.187L12.8337 12.187C12.8337 12.0206 12.7961 11.8565 12.7238 11.7074C12.6515 11.5584 12.5464 11.4286 12.4169 11.3282L13.9535 9.24927C14.3966 9.59286 14.7559 10.0369 15.0033 10.5466ZM8.03532 10.5466C7.78792 11.0563 7.65918 11.6178 7.65918 12.187L10.2048 12.187C10.2048 12.0206 10.2425 11.8565 10.3148 11.7074C10.3871 11.5584 10.4922 11.4286 10.6217 11.3282L9.08507 9.24927C8.64202 9.59286 8.28271 10.0369 8.03532 10.5466ZM9.84632 15.8744C10.3362 16.1427 10.88 16.2911 11.4353 16.3079C11.9905 16.3248 12.542 16.2096 13.0465 15.9715L11.9816 13.6043C11.8342 13.6739 11.6729 13.7076 11.5106 13.7027C11.3483 13.6977 11.1893 13.6544 11.0461 13.5759L9.84632 15.8744ZM11.4472 13.2708C11.9439 13.2708 12.3466 12.8584 12.3466 12.3496C12.3466 11.8409 11.9439 11.4285 11.4472 11.4285C10.9506 11.4285 10.5479 11.8409 10.5479 12.3496C10.5479 12.8584 10.9506 13.2708 11.4472 13.2708Z"
          fill="#111827"
        />
      </svg>
    );
  };
  return (
    <div className="p-4 pt-0">
      <div className="flex">
        <div className="p-4 pl-2 font-montserrat text-xl font-bold">
          Products:
        </div>
        <div className="p-4 pl-2 font-montserrat text-xl font-bold">
          Number of Products Selected:{" "}
          {eligibleProducts.filter((item) => item.enabled).length} <br />
          Total Quantity:{" "}
          {isNaN(
            _.sumBy(
              eligibleProducts.filter((item) => item.enabled),
              "quantities.availableToShip",
            ),
          ) === false &&
          _.sumBy(
            eligibleProducts.filter((item) => item.enabled),
            "quantities.availableToShip",
          ) > 0
            ? _.sumBy(
                eligibleProducts.filter((item) => item.enabled),
                "quantities.availableToShip",
              )
            : 0}
        </div>
      </div>
      <div className="relative p-2">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            aria-hidden="true"
            className="h-5 w-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="text-md block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          placeholder={"Search by ASIN/SKU/FNSKU/UPC/LPN/Name"}
          onChange={(e) => setKeyword(e.target.value)}
          value={keyword}
        />
      </div>
      <div className="overflow-y-auto" style={{ maxHeight: 500 }}>
        <table className="divide-y divide-gray-200 border border-gray-400 px-2">
          <thead className="rounded-full bg-primaryAccent p-4 px-12">
            <tr className="border-left px-12 font-montserrat text-textWhite">
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                <div className="flex justify-center space-x-2">
                  <Checkbox
                    role="checkbox"
                    onChange={(e) =>
                      setEligibleProducts(
                        eligibleProducts.map((i) => ({
                          ...i,
                          enabled: !!!eligibleProducts.find(
                            (item) => item.enabled === true,
                          ),
                        })),
                      )
                    }
                    name="binLocations"
                    value={"Select All Bin Locations"}
                    checked={
                      !!eligibleProducts.find((item) => item.enabled === true)
                    }
                  />
                </div>
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                ASIN
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                SKU
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                FN SKU
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                Name
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                Stock
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                Quantity to Ship
              </th>
            </tr>
          </thead>
          <tbody>
            {eligibleProducts.map((item, idx) => (
              <tr className="bg-white" key={item.id}>
                <td
                  className={`tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent`}>
                  <Checkbox
                    role="checkbox"
                    onChange={(e) =>
                      onChangeItem(item.id, "enabled", e.target.checked)
                    }
                    name="binLocations"
                    value={""}
                    checked={item.enabled}
                  />
                </td>

                <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  <div className="flex items-center">
                    {item.typeOfProduct === "BUNDLE" && (
                      <div title="This product is a bundle" className="-mt-2">
                        <GiftIcon className="h-8 w-8 text-primaryAccent" />
                      </div>
                    )}
                    {buildMarketplaceHyperlink(item.asin, "ASIN", item.source)}
                  </div>
                </td>

                <td className="rounded-br rounded-tr px-6 py-1 text-left font-medium tracking-wider text-5F666B">
                  <div className="relative">
                    {item?.marketplaceAttributes?.attributes?.hazmat && (
                      <div
                        data-tip
                        data-for={`hazmat_${idx}`}
                        className="absolute -left-8 z-10">
                        <HazmatIcon />
                        <ReactTooltip id={`hazmat_${idx}`}>
                          Hazardous Material
                        </ReactTooltip>
                      </div>
                    )}
                    <div>{item.sku}</div>
                  </div>
                </td>

                <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.fnSku}
                </td>
                <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.name}
                </td>
                <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.quantities.availableToShip}
                </td>
                <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  <input
                    type="number"
                    onChange={(e) =>
                      onChangeItem(
                        item.id,
                        "quantity",
                        parseInt(e.target.value),
                      )
                    }
                    value={item.quantity}
                    min="0"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RegularPackProducts;
