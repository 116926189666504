import { useState, useEffect, useContext } from "react";
import SlideOverModal from "#components/common/SlideOverModal";
import { getFilteredCustomerList } from "#utils/getFilteredCustomerList";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import { InformationCircleIcon, RefreshIcon } from "@heroicons/react/outline";
import { AppStateContext } from "#contexts/appState";
import AddProductStep from "./AddProductStep";
import CustomerEnhancedSearch from "./CustomerEnhancedSearch";
import SlideOverPanel from "#components/common/SlideOverPanel";
import { XIcon, PencilIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import {
  CHECK_PAYMENT_GATEWAY_CUSTOMER_EXISTS,
  CRM_CUSTOMERS,
  GET_PAYMENT_GATEWAY_CUSTOMER_DETAILS,
  GET_PAYMENT_GATEWAY_PAYMENT_METHODS,
  GET_SALES_ORDER,
} from "#queries/index";
import {
  ADD_PAYMENT_GATEWAY_CUSTOMER_PAYMENT_METHOD,
  CREATE_CRM_CUSTOMER,
  CREATE_PAYMENT_GATEWAY_CUSTOMER,
  UPDATE_PAYMENT_GATEWAY_CUSTOMER,
} from "#mutations/index";
import { v4 as uuidv4 } from "uuid";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "#components/utils/Modal";
import { Tooltip } from "antd";
import { Country, State } from "country-state-city";
import Stripe from "../../../static/images/Stripe.svg";
import { SHIPPING_PERCENTAGES } from "#constants/shipping-percentages";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AuthContext } from "#contexts/auth";
import moment from "moment-timezone";

const stripePromise = loadStripe(
  "pk_test_51OpE2WAnA4hYoxRGZEJBd0HcXS1PxR9nmcYjJjzTpx6HbF5nYvC6i21XOaE4nCNNU5CWq82avFFcpRMzcfcqHfvA00Yqu4w49i",
);

const ALERT_VISIBILITY_IN_MS = 5000;
const VALIDATION_ALERT_IN_MS = 3000;

const initialBillingInfo = {
  fullName: null,
  email: null,
  contactNumber: null,
  line1: null,
  line2: null,
  city: null,
  zip: null,
  state: null,
  country: null,
};

const mapBillingInfo = (obj) => {
  return {
    fullName: obj?.fullName || `${obj?.firstName} ${obj?.lastName}`,
    email: obj?.email,
    contactNumber: obj?.contactNumber || obj?.phone,
    line1: obj?.line1,
    line2: obj?.line2,
    city: obj?.city,
    zip: obj?.zip || obj?.postalCode,
    state: obj?.state,
    country: obj?.country,
  };
};

const paymentModesEnum = {
  stripe: "Stripe",
  payOffline: "Offline",
  payLater: "Later",
};

const defaultCompanies = [
  {
    name: "Van Engelen",
    id: "VE",
  },
  {
    name: "John Scheepers",
    id: "JS",
  },
  {
    name: "Kitchen Garden Seeds",
    id: "KGS",
  },
];

const salesCustomers = [
  {
    name: "VE",
    entity: "units",
  },
  {
    name: "JS",
    entity: "bulbs",
  },
  {
    name: "KGS",
    entity: "each",
  },
];

const OrderForm = ({
  selectedConsignment,
  setSelectedConsignment,
  onChange,
  onChangeDropdown,
  title,
  customers,
  warehouses,
  removeItem,
  submitAddedProduct,
  saveConsignmentDraft,
  tenant,
  multiAccountSupportEnabled,
  refreshConsignmentItems,
  cart,
  setCart,
  handleSalesOrderSubmission,
  newCustomer,
  setNewCustomer,
  createCRMCustomer,
  crmCustomers,
  handleAddToCart,
  getAvalaraTaxInvoice,
  avalaraTaxInvoice,
  salesOrderMode,
  setSalesOrderMode,
  salesOrderModesEnum,
  addCustomerPanel,
  setAddCustomerPanel,
  salesOrderIds,
  isFeatherRiverColdTenant = false,
}) => {
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [showSubmit, setShowSubmit] = useState(false);
  const [billingInfo, setBillingInfo] = useState(initialBillingInfo);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    type: "",
    details: {},
  });
  const [chequeDetails, setChequeDetails] = useState({
    chequeNumber: null,
    chequeValue: "",
  });
  const [paymentMode, setPaymentMode] = useState(paymentModesEnum.stripe);

  useEffect(() => {
    const formedSteps = getFormedSteps(selectedConsignment);
    setSteps(formedSteps);
    setSelectedStep(formedSteps[0]);
  }, []);

  return (
    <SlideOverModal
      open={selectedConsignment || salesOrderMode ? true : false}
      onClose={() => {
        setSelectedConsignment(null);
        setSalesOrderMode(null);
      }}
      title={title}
      staticBackdrop={true}>
      {salesOrderMode === salesOrderModesEnum.VIEW ? (
        <ViewSalesOrder salesOrderIds={salesOrderIds} warehouses={warehouses} />
      ) : salesOrderMode === salesOrderModesEnum.CREATE ? (
        <CreateSalesOrder
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          warehouses={warehouses}
          customers={customers}
          selectedConsignment={selectedConsignment}
          setSelectedConsignment={setSelectedConsignment}
          onChangeDropdown={onChangeDropdown}
          setAddCustomerPanel={setAddCustomerPanel}
          addCustomerPanel={addCustomerPanel}
          newCustomer={newCustomer}
          setNewCustomer={setNewCustomer}
          createCRMCustomer={createCRMCustomer}
          crmCustomers={crmCustomers}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          tenant={tenant}
          upsertSelectedConsignmentItem={submitAddedProduct}
          removeSelectedConsignmentItem={removeItem}
          refreshConsignmentItems={refreshConsignmentItems}
          cart={cart}
          setCart={setCart}
          handleAddToCart={handleAddToCart}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          chequeDetails={chequeDetails}
          setChequeDetails={setChequeDetails}
          billingInfo={billingInfo}
          setBillingInfo={setBillingInfo}
          paymentMode={paymentMode}
          setPaymentMode={setPaymentMode}
          onChange={onChange}
          avalaraTaxInvoice={avalaraTaxInvoice}
          getAvalaraTaxInvoice={getAvalaraTaxInvoice}
          steps={steps}
          setSteps={setSteps}
          showSubmit={showSubmit}
          setShowSubmit={setShowSubmit}
          saveConsignmentDraft={saveConsignmentDraft}
          handleSalesOrderSubmission={handleSalesOrderSubmission}
          setSalesOrderMode={setSalesOrderMode}
          salesCustomers={salesCustomers}
        />
      ) : null}
    </SlideOverModal>
  );
};

const CreateSalesOrder = ({
  selectedStep,
  setSelectedStep,
  warehouses,
  customers,
  selectedConsignment,
  setSelectedConsignment,
  onChangeDropdown,
  setAddCustomerPanel,
  addCustomerPanel,
  newCustomer,
  setNewCustomer,
  createCRMCustomer,
  crmCustomers,
  selectedCustomer,
  setSelectedCustomer,
  multiAccountSupportEnabled,
  tenant,
  upsertSelectedConsignmentItem,
  removeSelectedConsignmentItem,
  refreshConsignmentItems,
  cart,
  setCart,
  handleAddToCart,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  chequeDetails,
  setChequeDetails,
  billingInfo,
  setBillingInfo,
  paymentMode,
  setPaymentMode,
  avalaraTaxInvoice,
  getAvalaraTaxInvoice,
  onChange,
  showSubmit,
  setShowSubmit,
  steps,
  setSteps,
  saveConsignmentDraft,
  handleSalesOrderSubmission,
  setSalesOrderMode,
  salesCustomers,
}) => {
  const appState = useContext(AppStateContext);
  const [additionalOrderDetails, setAdditionalOrderDetails] = useState({
    markAsGift: false,
    giftNote: "",
    markAsFundRaiser: false,
    orderNote: "",
  });
  const [discount, setDiscount] = useState("");
  const [shippingCharge, setShippingCharge] = useState(null);

  const itemTotal =
    cart?.reduce((total, item) => total + Number(item.price), 0) || 0;

  const subTotal =
    parseFloat(itemTotal) +
    parseFloat(avalaraTaxInvoice?.totalTaxCalculated || 0) +
    parseFloat(shippingCharge || 0);

  const orderTotal =
    parseFloat(itemTotal) +
    parseFloat(avalaraTaxInvoice?.totalTaxCalculated || 0) +
    parseFloat(shippingCharge || 0) -
    parseInt(discount || 0);

  const setNextStep = () => {
    if (!selectedConsignment.warehouse) {
      return appState.setAlert(
        `Please select warehouse`,
        "error",
        VALIDATION_ALERT_IN_MS,
      );
    }
    if (!selectedConsignment.customer) {
      return appState.setAlert(
        `Please select customer`,
        "error",
        VALIDATION_ALERT_IN_MS,
      );
    }
    if (!selectedConsignment.company) {
      return appState.setAlert(
        `Please select company`,
        "error",
        VALIDATION_ALERT_IN_MS,
      );
    }
    if (!selectedConsignment.shippingAddress) {
      return appState.setAlert(
        `Please select Customer's shipping address`,
        "error",
        VALIDATION_ALERT_IN_MS,
      );
    }
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const nextStep = copiedSteps.find((_, idx) => idx === foundIdx + 1);
    if (!nextStep.visible) {
      foundIdx += 1; // skip the step which is disabled or not visible
    }

    if (nextStep.id === "03") {
      getAvalaraTaxInvoice();
    }

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
      setShowSubmit(foundIdx === copiedSteps.length - 2);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const prevStep = copiedSteps.find((_, idx) => idx === foundIdx - 1);
    if (!prevStep.visible) {
      foundIdx -= 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setShowSubmit(false);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  const onSubmit = () => {
    if (selectedConsignment.customer && selectedConsignment.warehouse) {
      const paymentDetails = {
        paymentProvider: paymentMode,
        paymentMethodId: selectedPaymentMethod.details?.id,
        chequeNumber: chequeDetails.chequeNumber,
        chequeValue: parseFloat(chequeDetails.chequeValue),
        paymentStatus: "Pending",
        billingDetails: {
          name: billingInfo.fullName,
          email: billingInfo.email,
          phone: billingInfo.contactNumber,
          address: {
            line1: billingInfo.line1,
            line2: billingInfo.line2,
            city: billingInfo.city,
            postalCode: billingInfo.zip,
            state: billingInfo.state,
            country: billingInfo.country,
          },
        },
      };

      const cartDetails = {
        currency: "USD",
        discount: parseFloat(discount),
        orderValue: orderTotal,
        salesTax: avalaraTaxInvoice?.totalTaxCalculated,
        shippingCharges: parseFloat(shippingCharge),
        subTotal: itemTotal,
      };

      const attributes = {
        isGiftOrder: additionalOrderDetails.markAsGift,
        giftMessage: additionalOrderDetails.giftNote || null,
        isFundRaiserOrder: additionalOrderDetails.markAsFundRaiser,
      };

      handleSalesOrderSubmission({ paymentDetails, cartDetails, attributes });
    }
  };

  const onSubmitDraft = () => {
    if (selectedConsignment.customer && selectedConsignment.warehouse) {
      saveConsignmentDraft();
    }
  };

  const onCancel = () => {
    setSelectedConsignment(null);
    setCart(null);
    setSalesOrderMode(null);
  };

  return (
    <div className="relative flex h-full w-full flex-col overflow-auto">
      <header className="space-y-2 py-4">
        <Navbar steps={steps} />
      </header>

      <main className="my-2 h-full grow overflow-auto">
        {selectedStep?.id === "01" && (
          <BasicInfo
            warehouses={warehouses}
            customers={customers}
            selectedConsignment={selectedConsignment}
            setSelectedConsignment={setSelectedConsignment}
            onChangeDropdown={onChangeDropdown}
            setAddCustomerPanel={setAddCustomerPanel}
            addCustomerPanel={addCustomerPanel}
            newCustomer={newCustomer}
            setNewCustomer={setNewCustomer}
            createCRMCustomer={createCRMCustomer}
            crmCustomers={crmCustomers}
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
          />
        )}
        {selectedStep?.id === "02" && (
          <AddProductStep
            selectedConsignment={selectedConsignment}
            setSelectedConsignment={setSelectedConsignment}
            warehouses={warehouses}
            multiAccountSupportEnabled={multiAccountSupportEnabled}
            tenant={tenant}
            upsertSelectedConsignmentItem={upsertSelectedConsignmentItem}
            removeSelectedConsignmentItem={removeSelectedConsignmentItem}
            refreshConsignmentItems={refreshConsignmentItems}
            cart={cart}
            setCart={setCart}
            handleAddToCart={handleAddToCart}
            zipCode={
              selectedConsignment.shippingAddress?.isPlantingZoneAddress &&
              selectedConsignment.shippingAddress?.plantingZoneCode
                ? selectedConsignment.shippingAddress.plantingZoneCode
                : selectedConsignment.shippingAddress?.zip
            }
            zipCodeTitle={
              selectedConsignment.shippingAddress?.isPlantingZoneAddress &&
              selectedConsignment.shippingAddress?.plantingZoneCode
                ? "Plantation Zipcode"
                : "Shipping Zipcode"
            }
            salesCustomers={salesCustomers}
          />
        )}
        {selectedStep?.id === "03" && (
          <PaymentInfo
            selectedConsignment={selectedConsignment}
            onChange={onChange}
            avalaraTaxInvoice={avalaraTaxInvoice}
            cart={cart}
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            chequeDetails={chequeDetails}
            setChequeDetails={setChequeDetails}
            billingInfo={billingInfo}
            setBillingInfo={setBillingInfo}
            paymentMode={paymentMode}
            setPaymentMode={setPaymentMode}
            discount={discount}
            setDiscount={setDiscount}
            shippingCharge={shippingCharge}
            setShippingCharge={setShippingCharge}
            itemTotal={itemTotal}
            subTotal={subTotal}
            orderTotal={orderTotal}
            additionalOrderDetails={additionalOrderDetails}
            setAdditionalOrderDetails={setAdditionalOrderDetails}
            salesCustomers={salesCustomers}
          />
        )}
      </main>

      <footer className="flex h-16 items-center justify-between">
        <FormActions
          selectedConsignment={selectedConsignment}
          setSelectedConsignment={setSelectedConsignment}
          steps={steps}
          selectedStep={selectedStep}
          setPrevStep={setPrevStep}
          setNextStep={setNextStep}
          onSubmitDraft={onSubmitDraft}
          showSubmit={showSubmit}
          onSubmit={onSubmit}
          onCancel={() => {
            if (
              selectedConsignment.warehouse ||
              selectedConsignment.customer ||
              selectedConsignment.company ||
              selectedConsignment.shippingAddress
            ) {
              appState.showConfirmation(
                "Cancel",
                "Are you sure you want to cancel? All progress will be lost.",
                appState.hideConfirmation,
                () => {
                  onCancel();
                  appState.hideConfirmation();
                },
                "No, continue",
                "Yes, cancel",
              );
            } else {
              onCancel();
            }
          }}
        />
      </footer>
    </div>
  );
};

const tabsEnums = {
  SHIPPING: "shipping",
  ACCOUNT: "account",
  ORDER_HISTORY: "order_history",
};

const ViewSalesOrder = ({ warehouses, salesOrderIds }) => {
  const [activeTab, setActiveTab] = useState(tabsEnums.SHIPPING);
  const [salesOrderDetails, setSalesOrderDetails] = useState(null);
  const [customerAccountDetails, setCustomerAccountDetails] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);

  const getSalesOrderQuery = useQuery(GET_SALES_ORDER);
  const crmCustomersQuery = useQuery(CRM_CUSTOMERS);

  const {
    shippingAddress,
    orderLineItems = [],
    cartDetails,
    paymentDetails,
    attributes,
    salesChannel,
    customerIdOnCrm,
  } = salesOrderDetails || {};

  const { accountAddress, businessName, firstName, lastName, hrid } =
    customerAccountDetails || {};

  const orderWarehouse = warehouses.find(
    (warehouse) => warehouse?.id === salesOrderDetails?.warehouse,
  )?.name;

  const entity = salesCustomers.find(
    (cus) => cus?.name === salesChannel,
  )?.entity;

  const getPaymentMethodDetails = (payment) => {
    if (!payment?.type) return;

    switch (payment.type) {
      case "us_bank_account":
        return {
          label: payment.bankAccount?.bankName,
          last4: payment.bankAccount?.last4,
        };
      case "card":
        return {
          label: payment.card?.brand,
          last4: payment.card?.last4,
          expiry: `${payment.card?.expiryMonth}/${payment.card?.expiryYear}`,
        };
    }
  };

  useEffect(() => {
    if (salesOrderIds.length > 0) {
      (async () => {
        const response = await getSalesOrderQuery.fetchData({
          filters: {
            ids: salesOrderIds,
          },
        });

        if (response.data?.getSalesOrders?.entities) {
          setSalesOrderDetails(response.data.getSalesOrders.entities[0]);
        }
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (customerIdOnCrm) {
        const response = await crmCustomersQuery.fetchData({
          filters: {
            keyword: null,
            searchFields: [],
            ids: [customerIdOnCrm],
          },
        });

        if (response.data?.crmCustomers?.entities) {
          setCustomerAccountDetails(response.data.crmCustomers.entities[0]);
        }
      }
    })();
  }, [customerIdOnCrm]);

  useEffect(() => {
    (async () => {
      if (activeTab === tabsEnums.ORDER_HISTORY) {
        const response = await getSalesOrderQuery.fetchData({
          filters: {
            customerIdOnCrm,
          },
        });

        if (response.data?.getSalesOrders?.entities) {
          setOrderHistory(response.data.getSalesOrders.entities);
        }
      }
    })();
  }, [activeTab]);

  if (getSalesOrderQuery.loading || crmCustomersQuery.loading)
    return (
      <div className="h-full">
        <LoadingIndicator shouldShowOnPage={false} className="h-full" />
      </div>
    );

  return (
    <div className="w-3/4">
      {/* Basic Info */}
      <div className="my-4">
        <p className="text-lg font-semibold text-gray-900">Basic Info</p>

        <div className="my-2 space-y-1">
          <p className="text-sm font-medium text-gray-500">Warehouse</p>
          <div className="rounded border p-4">{orderWarehouse}</div>
        </div>

        <div className="my-2 space-y-1">
          <p className="text-sm font-medium text-gray-500">Company</p>
          <div className="rounded border p-4">
            {
              defaultCompanies.find((company) => company.id === salesChannel)
                ?.name
            }
          </div>
        </div>

        <div className="my-2 space-y-1">
          <p className="text-sm font-medium text-gray-500">Customer</p>

          <div className="rounded border p-4">
            <div>
              <div>
                <h3 className="text-xl font-semibold leading-6 text-gray-900">
                  {firstName} {lastName}
                </h3>
                <p className="text-sm text-gray-500">Customer ID: {hrid}</p>
              </div>
            </div>

            <div className="mt-2">
              <nav className="flex space-x-4 border-b" aria-label="Tabs">
                <button
                  onClick={() => setActiveTab(tabsEnums.SHIPPING)}
                  className={`border-b-4 px-3 py-2 text-sm font-medium ${
                    activeTab === tabsEnums.SHIPPING
                      ? "border-224E73 text-224E73"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}>
                  Shipping Info
                </button>
                <button
                  onClick={() => setActiveTab(tabsEnums.ACCOUNT)}
                  className={`border-b-4 px-3 py-2 text-sm font-medium ${
                    activeTab === tabsEnums.ACCOUNT
                      ? "border-224E73 text-224E73"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}>
                  Account Info
                </button>
                <button
                  onClick={() => setActiveTab(tabsEnums.ORDER_HISTORY)}
                  className={`border-b-4 px-3 py-2 text-sm font-medium ${
                    activeTab === tabsEnums.ORDER_HISTORY
                      ? "border-224E73 text-224E73"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}>
                  Order History
                </button>
              </nav>

              <div className="mt-4 rounded border p-4">
                {activeTab === tabsEnums.SHIPPING && (
                  <div className="">
                    <p className="text-lg font-semibold text-gray-900">
                      {[shippingAddress?.firstName, shippingAddress?.lastName]
                        .filter(Boolean)
                        .join(" ")}
                    </p>
                    <p className="text-sm text-gray-500">
                      {[
                        shippingAddress?.line1,
                        shippingAddress?.line2,
                        shippingAddress?.city,
                        shippingAddress?.state,
                        salesOrderDetails?.shippingAddress?.country,
                        shippingAddress?.zip,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                    <p className="text-sm text-gray-500">
                      {[shippingAddress?.contactNumber, shippingAddress?.email]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                  </div>
                )}

                {activeTab === tabsEnums.ACCOUNT && (
                  <div className="">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-sm font-medium text-gray-900">
                          {businessName}
                        </p>
                        <p className="text-sm text-gray-500">
                          {[accountAddress?.firstName, accountAddress?.lastName]
                            .filter(Boolean)
                            .join(" ")}
                        </p>
                        <p className="text-sm text-gray-500">
                          {[
                            accountAddress?.line1,
                            accountAddress?.line2,
                            accountAddress?.city,
                            accountAddress?.state,
                            accountAddress?.country,
                            accountAddress?.zip,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                        <p className="text-sm text-gray-500">
                          {[
                            accountAddress?.contactNumber,
                            accountAddress?.email,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === tabsEnums.ORDER_HISTORY && (
                  <div className="">
                    <div className="overflow-hidden rounded-lg bg-white shadow">
                      {orderHistory.length > 0 ? (
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                Order ID
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                Created On
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                Total Price
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {orderHistory.map((order) => (
                              <tr key={order?.salesOrderId}>
                                <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                  {order?.salesOrderId}
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                  {order?.createdAt
                                    ? moment(order.createdAt).format(
                                        "YYYY-MM-DD",
                                      )
                                    : ""}
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                  ${order?.cartDetails?.orderValue}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>No orders found.</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Order & Payment Summary */}
      <div className="my-4">
        <p className="text-lg font-semibold text-gray-900">
          Order & Payment Summary
        </p>

        {/* Products */}
        <div className="my-4 space-y-4">
          {orderLineItems.length > 0
            ? orderLineItems.map((product) => {
                return (
                  <div key={product.productId} className="flex justify-between">
                    <div className="space-y-1">
                      <p className="text-sm">{product.name}</p>
                      <p className="w-max rounded border bg-gray-100 p-1 text-xs text-gray-800">
                        Qty:{" "}
                        {salesOrderDetails?.salesChannel === "VE"
                          ? `${product.units} units = ${product.quantity} bulbs`
                          : `${product.quantity} bulbs`}
                      </p>
                    </div>

                    <div className="space-y-1 text-right">
                      <p className="font-semibold">${product.price}</p>
                      <p className="text-xs text-gray-400">
                        $
                        {salesOrderDetails?.salesChannel === "VE"
                          ? `${(product.price / product.units).toFixed(2)}/unit`
                          : `${(product.price / product.quantity).toFixed(2)}/bulb`}
                      </p>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        {/* Price breakdown */}
        <div className="my-4 border-b border-t">
          <div className="space-y-2 py-2">
            {cartDetails?.subTotal > 0 && (
              <div className="flex items-center justify-between">
                <p className="text-sm text-gray-500">Item total:</p>
                <p className="">${cartDetails.subTotal}</p>
              </div>
            )}

            {cartDetails?.shippingCharges > 0 && (
              <div className="flex items-center justify-between">
                <p className="text-sm text-gray-500">Delivery Charges:</p>
                <p>${cartDetails.shippingCharges}</p>
              </div>
            )}

            {cartDetails?.discount > 0 && (
              <div className="flex items-center justify-between">
                <p className="text-sm text-gray-500">Discount:</p>
                <p className="text-red-600">-${cartDetails.discount}</p>
              </div>
            )}
          </div>

          {cartDetails?.orderValue > 0 && (
            <div className="flex items-center justify-between border-t py-3">
              <p className="text-sm text-gray-500">Order Total:</p>
              <p className="text-xl font-semibold">${cartDetails.orderValue}</p>
            </div>
          )}
        </div>

        {/* Order as gift */}
        {attributes?.isGiftOrder && (
          <div className="my-4 flex items-center gap-2">
            <input type="checkbox" readOnly disabled checked name="gift" />
            <label htmlFor="gift">Marked order as gift order</label>
          </div>
        )}
      </div>

      {/* Billing Info */}
      <div className="my-4">
        <p className="font-medium">Billing Info</p>

        <div className="my-4 rounded border p-4">
          <p className="font-semibold text-gray-900">
            {paymentDetails?.billingDetails?.name}
          </p>
          <p className="text-sm text-gray-500">
            {[
              paymentDetails?.billingDetails?.address?.line1,
              paymentDetails?.billingDetails?.address?.line2,
              paymentDetails?.billingDetails?.address?.city,
              paymentDetails?.billingDetails?.address?.state,
              salesOrderDetails?.paymentDetails?.billingDetails?.address
                ?.country,
              paymentDetails?.billingDetails?.address?.postalCode,
            ]
              .filter(Boolean)
              .join(", ")}
          </p>
          <p className="text-sm text-gray-500">
            {[
              paymentDetails?.billingDetails?.phone,
              paymentDetails?.billingDetails?.email,
            ]
              .filter(Boolean)
              .join(", ")}
          </p>
        </div>
      </div>

      {/* Payment Info */}
      <div className="my-4">
        <p className="font-medium">Payment Mode</p>

        <div className="my-4 rounded border p-4">
          {paymentDetails?.paymentProvider === "Stripe" ? (
            <p className="mb-2 text-lg">
              <img src={Stripe} alt="stripe" />
            </p>
          ) : null}

          <div className="rounded border p-4">
            <p className="font-semibold">
              {
                getPaymentMethodDetails(paymentDetails?.paymentMethodDetails)
                  ?.label
              }
            </p>
            <p className="text-sm text-gray-400">
              ****
              {
                getPaymentMethodDetails(paymentDetails?.paymentMethodDetails)
                  ?.last4
              }
            </p>
            {paymentDetails?.paymentMethodDetails?.type === "card" && (
              <p className="text-sm text-gray-400">
                Exp date:{" "}
                {
                  getPaymentMethodDetails(paymentDetails?.paymentMethodDetails)
                    ?.expiry
                }
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Navbar = ({ steps }) => {
  const visibleSteps = steps.filter((item) => item.visible);
  return (
    <nav className="flex w-524 space-x-2">
      {visibleSteps.map((step, index) => {
        const activeStep = step.status === "current";
        return (
          <div className="flex flex-col items-center" key={index}>
            <span
              className={`mb-1 h-2.5 w-32 rounded-md ${
                activeStep ? "bg-blueBackground" : "bg-lightBlueBackground"
              }`}></span>
            <p
              className={`text-sm decoration-solid ${
                activeStep ? "text-textDarkGray" : "text-textGray"
              }`}>
              {step.name}
            </p>
          </div>
        );
      })}
    </nav>
  );
};

const FormActions = ({
  selectedConsignment,
  setSelectedConsignment,
  steps,
  selectedStep,
  setPrevStep,
  setNextStep,
  onSubmitDraft,
  showSubmit,
  onSubmit,
  onCancel,
}) => {
  const auth = useContext(AuthContext);

  const showSaveAsDraft =
    (!selectedConsignment?.id || selectedConsignment?.status === "DRAFT") &&
    selectedConsignment?.customer &&
    selectedConsignment?.warehouse;

  const defaultCustomer = auth.user.customersList.find(
    (cus) => cus.code === "DEF",
  )?.id;

  return (
    <>
      <div>
        {showSaveAsDraft && (
          <button
            onClick={onSubmitDraft}
            disabled
            className={`cursor-pointer py-2 text-base font-semibold text-primaryAccent underline disabled:cursor-not-allowed disabled:text-gray-400`}>
            Save as Draft
          </button>
        )}
      </div>
      <div className="flex space-x-4">
        <button
          className={`mr-2 cursor-pointer py-3 text-base font-semibold underline`}
          onClick={onCancel}>
          Cancel
        </button>
        <button
          onClick={setPrevStep}
          className={`cursor-pointer rounded-md border px-6 py-3 text-base font-semibold ${
            selectedStep?.id === steps[0]?.id
              ? "cursor-not-allowed border-mediumGray text-mediumGray"
              : "cursor-pointer border-primaryAccent text-primaryAccent"
          }`}>
          Previous
        </button>
        {!showSubmit && (
          <button
            onClick={setNextStep}
            className={`rounded-md px-6 py-3 text-base font-semibold text-white ${
              selectedStep?.id === steps[steps.length - 1]?.id ||
              !(
                selectedConsignment.warehouse &&
                selectedConsignment.customer &&
                selectedConsignment.company &&
                selectedConsignment.shippingAddress &&
                defaultCustomer
              )
                ? "cursor-not-allowed border bg-gray-400"
                : "cursor-pointer bg-primaryAccent"
            }`}>
            Next
          </button>
        )}
        {showSubmit && (
          <button
            onClick={onSubmit}
            className={`cursor-pointer rounded-md px-6 py-3 text-base font-semibold ${
              selectedConsignment?.customer &&
              selectedConsignment?.warehouse &&
              defaultCustomer
                ? "cursor-pointer bg-primaryAccent text-white"
                : "cursor-not-allowed border border-mediumGray text-mediumGray"
            }`}>
            {selectedConsignment?.id ? "Update" : "Create"}
          </button>
        )}
      </div>
    </>
  );
};

const AddUserFooter = ({
  newCustomer,
  steps,
  selectedStep,
  setPrevStep,
  setNextStep,
  showSubmit,
  onSubmit,
  onCancel,
  isShippingSelected,
}) => {
  return (
    <div className="mt-4 flex w-full items-center justify-between space-x-4 bg-white">
      <button
        className="p-2 text-base font-semibold underline"
        onClick={onCancel}>
        Cancel
      </button>

      <div className="flex gap-4">
        {selectedStep.id !== steps[0].id && (
          <button
            onClick={setPrevStep}
            className={`rounded-md px-4 py-2 text-base font-semibold ${
              selectedStep?.id === steps[0]?.id
                ? "cursor-not-allowed border border-mediumGray text-mediumGray"
                : "cursor-pointer border border-primaryAccent text-primaryAccent"
            }`}>
            Previous
          </button>
        )}

        {selectedStep.id !== steps[steps.length - 1].id && (
          <button
            onClick={setNextStep}
            disabled={
              selectedStep?.id === steps[steps.length - 1]?.id ||
              !(
                newCustomer?.firstName &&
                newCustomer?.lastName &&
                newCustomer?.accountAddress?.line1 &&
                newCustomer?.accountAddress?.state &&
                newCustomer?.accountAddress?.country &&
                newCustomer?.accountAddress?.zip
              )
            }
            className={`rounded-md px-4 py-2 text-base font-semibold text-white ${
              selectedStep?.id === steps[steps.length - 1]?.id ||
              !(
                newCustomer?.firstName &&
                newCustomer?.lastName &&
                newCustomer?.accountAddress?.line1 &&
                newCustomer?.accountAddress?.state &&
                newCustomer?.accountAddress?.country &&
                newCustomer?.accountAddress?.zip
              )
                ? "cursor-not-allowed border bg-gray-400"
                : "cursor-pointer bg-primaryAccent"
            }`}>
            Next
          </button>
        )}

        {showSubmit && selectedStep.id !== steps[0].id && (
          <button
            onClick={onSubmit}
            disabled={
              !isShippingSelected || !newCustomer.shippingAddresses.length
            }
            className={`rounded-md px-4 py-2 text-base font-semibold ${
              isShippingSelected
                ? "cursor-pointer bg-primaryAccent text-white"
                : "cursor-not-allowed border border-mediumGray text-mediumGray"
            }`}>
            Add User
          </button>
        )}
      </div>
    </div>
  );
};

const BasicInfo = ({
  warehouses,
  customers,
  selectedConsignment,
  setSelectedConsignment,
  onChangeDropdown,
  addCustomerPanel,
  setAddCustomerPanel,
  setNewCustomer,
  createCRMCustomer,
  crmCustomers,
  newCustomer,
  selectedCustomer,
  setSelectedCustomer,
}) => {
  const appState = useContext(AppStateContext);
  const auth = useContext(AuthContext);

  const createCRMCustomerQuery = useQuery(CREATE_CRM_CUSTOMER);

  const [altEmails, setAltEmails] = useState([]);
  const [altPhones, setAltPhones] = useState([]);
  const [useAccountAddress, setUseAccountAddress] = useState(true);
  const [addNewAddress, setAddNewAddress] = useState(null);
  const [steps, setSteps] = useState([
    {
      tag: "accountInfo",
      name: "Account Info",
      href: "#",
      id: "01",
      status: "current",
      visible: true,
    },
    {
      tag: "shippingInfo",
      name: "Shipping Info",
      href: "#",
      id: "02",
      status: "upcoming",
      visible: true,
    },
  ]);
  const [selectedStep, setSelectedStep] = useState(steps[0]);
  const [showSubmit, setShowSubmit] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    newCustomer?.accountAddress?.country,
  );
  const [selectedState, setSelectedState] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);

  const defaultCustomer = auth.user.customersList.find(
    (cus) => cus.code === "DEF",
  )?.id;

  useEffect(() => {
    if (shouldDisplayAccountAddress()) {
      setShowSubmit(true);
    } else {
      console.log("conditions not met");
    }
  }, [
    newCustomer?.firstName,
    newCustomer?.lastName,
    newCustomer?.accountAddress,
    newCustomer?.shippingAddresses,
  ]);

  useEffect(() => {
    const updateStates = () => {
      if (selectedCountry) {
        const states = State.getStatesOfCountry(selectedCountry);
        setFilteredStates(states);
        const stateIsValid = states.some(
          (state) => state.isoCode === selectedState,
        );
        if (!stateIsValid) {
          setSelectedState("");
        }
      } else {
        setFilteredStates([]);
        setSelectedState("");
      }
    };

    updateStates();
  }, [selectedCountry]);

  console.log(newCustomer, "newCustomer");
  const setNextStep = () => {
    if (selectedStep.tag !== "shippingInfo") {
      if (shouldDisplayAccountAddress()) {
        setNewCustomer((prevCustomer) => {
          const accountAddress = prevCustomer?.accountAddress;
          const shippingAddresses = prevCustomer?.shippingAddresses || [];

          const accountAddressExists = shippingAddresses.some((addr) =>
            isSameAddress(addr, accountAddress),
          );

          return {
            ...prevCustomer,
            shippingAddresses: accountAddressExists
              ? [...shippingAddresses]
              : [accountAddress, ...shippingAddresses],
          };
        });
      }
    } else {
      setNewCustomer((prevCustomer) => ({ ...prevCustomer }));
    }
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const nextStep = copiedSteps.find((_, idx) => idx === foundIdx + 1);
    if (!nextStep.visible) {
      foundIdx += 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
      setShowSubmit(foundIdx === copiedSteps.length - 1);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const prevStep = copiedSteps.find((_, idx) => idx === foundIdx - 1);
    if (!prevStep.visible) {
      foundIdx -= 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setShowSubmit(false);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  const addAltEmail = () => {
    setAltEmails([...altEmails, ""]);
  };

  const removeAltEmail = (index) => {
    setAltEmails(altEmails.filter((_, i) => i !== index));
  };

  const handleAltEmailChange = (value, index) => {
    const updated = [...altEmails];
    updated[index] = value;
    setAltEmails(updated);
  };

  const addAltPhone = () => {
    setAltPhones([...altPhones, ""]);
  };

  const removeAltPhone = (index) => {
    setAltPhones(altPhones.filter((_, i) => i !== index));
  };

  const handleAltPhoneChange = (value, index) => {
    const updated = [...altPhones];
    updated[index] = value;
    setAltPhones(updated);
  };

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(newCountry));

    setNewCustomer((prev) => ({
      ...prev,
      accountAddress: {
        ...prev?.accountAddress,
        country: newCountry,
      },
    }));
  };

  const handleStateChange = (newState) => {
    setSelectedState(newState);

    setNewCustomer((prev) => ({
      ...prev,
      accountAddress: {
        ...prev?.accountAddress,
        state: newState,
      },
    }));
  };

  // Handler to add a new shipping address
  const handleAddNewAddress = () => {
    setNewCustomer((prevCustomer) => ({
      ...prevCustomer,
      shippingAddresses: [
        ...(prevCustomer?.shippingAddresses || []),
        addNewAddress,
      ],
    }));
    setAddNewAddress(null);
  };

  const shouldDisplayAccountAddress = () => {
    if (!newCustomer || !newCustomer.accountAddress) {
      return false;
    }

    const { firstName, lastName, accountAddress } = newCustomer;

    const result =
      Boolean(firstName) &&
      Boolean(lastName) &&
      Boolean(accountAddress.line1) &&
      Boolean(accountAddress.state) &&
      Boolean(accountAddress.country) &&
      Boolean(accountAddress.city) &&
      Boolean(accountAddress.zip);

    return result;
  };

  function isSameAddress(addr1, addr2) {
    if (!addr1 || !addr2) return false;
    return (
      addr1?.line1 === addr2.line1 &&
      addr1?.line2 === addr2.line2 &&
      addr1?.city === addr2.city &&
      addr1?.zip === addr2?.zip &&
      addr1?.state === addr2?.state &&
      addr1?.country === addr2?.country
    );
  }

  useEffect(() => {
    if (createCRMCustomerQuery.data) {
      setAddCustomerPanel(false);
    }
  }, [createCRMCustomerQuery.data]);

  useEffect(() => {
    if (!defaultCustomer) {
      appState.setAlert(
        "You lack the necessary permissions to create a sales order for this company.",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  }, [defaultCustomer]);

  console.log(selectedConsignment, "selectedConsignment");

  const onCancel = () => {
    setAddCustomerPanel(false);
    setNewCustomer(null);
  };

  console.log(selectedConsignment, "selectedConsignment");
  return (
    <div>
      <h1 className="mb-4 text-2xl font-semibold">Basic Info</h1>

      <div className="flex gap-4">
        {selectedConsignment.warehouseToBeSelected && (
          <div className="mb-4 w-1/2">
            <label
              className="mb-1 block text-sm font-medium text-lightGray"
              htmlFor="warehouse">
              Warehouse
            </label>
            <AutocompleteDropdownV2
              options={warehouses}
              labelKey="name"
              valueKey="id"
              onChange={(selectedOption) => {
                onChangeDropdown("warehouse", selectedOption);
              }}
              value={selectedConsignment.warehouse}
              placeholder=""
            />
          </div>
        )}
        {selectedConsignment.companyToBeSelected && (
          <div className="mb-4 w-1/2">
            <label
              className="mb-1 block text-sm font-medium text-lightGray"
              htmlFor="company">
              Select Company
            </label>
            <AutocompleteDropdownV2
              options={defaultCompanies}
              labelKey="name"
              valueKey="id"
              onChange={(selectedOption) => {
                onChangeDropdown("company", selectedOption);
              }}
              value={selectedConsignment.company}
              placeholder=""
            />
          </div>
        )}
      </div>

      {selectedConsignment.customerToBeSelected && (
        <div className="mb-4 w-full">
          <label
            className="mb-1 block text-sm font-medium text-lightGray"
            htmlFor="client">
            Search Customer
          </label>
          <div className="h-full">
            <CustomerEnhancedSearch
              products={crmCustomers}
              selectProduct={() => {}}
              selectedProducts={[]}
              allRowsSelected={false}
              selectAllRows={() => {}}
              getCatalog={() => {}}
              shiftKeyPressed={false}
              // cart={cart}
              // setCart={setCart}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              selectedConsignment={selectedConsignment}
              setSelectedConsignment={setSelectedConsignment}
              onChange={(value, selectedOption) => {
                onChangeDropdown(value, selectedOption);
              }}
            />
          </div>
        </div>
      )}

      {!selectedConsignment.customer && (
        <button
          onClick={() => setAddCustomerPanel(true)}
          className={`cursor-pointer py-2 text-base font-semibold text-primaryAccent underline`}>
          + Add Customer
        </button>
      )}

      {addCustomerPanel && (
        <SlideOverPanel
          open={addCustomerPanel}
          setOpen={setAddCustomerPanel}
          title={"Add Customer"}>
          <div className="relative flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white p-4">
            <header className="space-y-2 py-4">
              <Navbar steps={steps} />
            </header>
            <main>
              {selectedStep?.tag === "accountInfo" && (
                <div className="space-y-6">
                  <div className="space-y-1">
                    <label
                      htmlFor="businessName"
                      className="block text-sm font-medium text-gray-700">
                      Business Name
                    </label>
                    <input
                      name="businessName"
                      id="businessName"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder="John Enterprises"
                      value={newCustomer?.businessName}
                      onChange={(e) => {
                        setNewCustomer((prevCustomer) => ({
                          ...prevCustomer,
                          businessName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="-mx-2 flex flex-wrap">
                    {/* First Name Field */}
                    <div className="w-1/2 space-y-1 px-2">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700">
                        First Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="Doe"
                        value={newCustomer?.firstName}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            firstName: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    {/* Last Name Field */}
                    <div className="w-1/2 space-y-1 px-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700">
                        Last Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="John"
                        value={newCustomer?.lastName}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            lastName: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  {/* Email Field */}
                  <div className="space-y-1">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder="XYZ123@gmail.com"
                      value={newCustomer?.email}
                      onChange={(e) => {
                        setNewCustomer((prevCustomer) => ({
                          ...prevCustomer,
                          email: e.target.value,
                        }));
                      }}
                    />
                    <button
                      type="button"
                      onClick={addAltEmail}
                      className="mt-2 py-2 text-sm text-[#224E73] underline hover:text-[#224E73]">
                      + Add Alternative Email Address
                    </button>

                    {altEmails.map((email, index) => (
                      <div key={index} className="mt-2 flex items-center">
                        <input
                          type="email"
                          className="my-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          value={email}
                          onChange={(e) =>
                            handleAltEmailChange(e.target.value, index)
                          }
                          placeholder="Alternative Email"
                        />
                        <button
                          type="button"
                          onClick={() => removeAltEmail(index)}
                          className="ml-2 rounded-full p-2 text-[#224E73] hover:bg-gray-200">
                          <XIcon className="h-5 w-5" />
                        </button>
                      </div>
                    ))}
                  </div>

                  {/* Contact Number Field */}
                  <div className="space-y-1">
                    <label
                      htmlFor="contact-number"
                      className="block text-sm font-medium text-gray-700">
                      Contact Number
                    </label>
                    <input
                      type="tel"
                      name="contact-number"
                      id="contact-number"
                      value={newCustomer?.contactNumber}
                      className="block w-full rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder="9876543210"
                      onChange={(e) => {
                        setNewCustomer((prevCustomer) => ({
                          ...prevCustomer,
                          contactNumber: e.target.value,
                        }));
                      }}
                    />
                    <button
                      type="button"
                      onClick={addAltPhone}
                      className="mt-2 py-2 text-sm text-[#224E73] underline hover:text-[#224E73]">
                      + Add Additional Phone Number
                    </button>
                    {altPhones.map((phone, index) => (
                      <div key={index} className="mt-2 flex items-center">
                        <input
                          type="tel"
                          className="my-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          value={phone}
                          onChange={(e) =>
                            handleAltPhoneChange(e.target.value, index)
                          }
                          placeholder="Alternative Phone Number"
                        />
                        <button
                          type="button"
                          onClick={() => removeAltPhone(index)}
                          className="ml-2 rounded-full p-2 text-[#224E73] hover:bg-gray-200">
                          <XIcon className="h-5 w-5" />
                        </button>
                      </div>
                    ))}
                  </div>

                  {/* Info Box */}
                  <div className="mt-4 flex items-center rounded-md border-l-4 border-[#224E73] bg-blue-50 p-4 text-sm text-[#224E73]">
                    <InformationCircleIcon className="mr-2 h-10 w-10" />
                    You can add more addresses to the user once profile has been
                    created.
                  </div>

                  {/* Address Fields */}
                  <div className="flex flex-col gap-2 space-y-1">
                    {/* Address Line 1 */}
                    <div>
                      <label
                        htmlFor="address-line1"
                        className="block text-sm font-medium text-gray-700">
                        Address Line 1<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="address-line1"
                        id="address-line1"
                        required
                        value={newCustomer?.accountAddress?.line1}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            accountAddress: {
                              ...prevCustomer?.accountAddress,
                              line1: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>

                    {/* Address Line 2 */}
                    <div>
                      <label
                        htmlFor="address-line2"
                        className="block text-sm font-medium text-gray-700">
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        name="address-line2"
                        id="address-line2"
                        value={newCustomer?.accountAddress?.line2}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            accountAddress: {
                              ...prevCustomer?.accountAddress,
                              line2: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        {/* City */}
                        <label
                          htmlFor="address-city"
                          className="block text-sm font-medium text-gray-700">
                          City<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="address-city"
                          id="address-city"
                          value={newCustomer?.accountAddress?.city}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          onChange={(e) => {
                            setNewCustomer((prevCustomer) => ({
                              ...prevCustomer,
                              accountAddress: {
                                ...prevCustomer?.accountAddress,
                                city: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>

                      <div>
                        {/* Zipcode */}
                        <label
                          htmlFor="zip-code"
                          className="block text-sm font-medium text-gray-700">
                          Zip Code<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="zip-code"
                          id="zip-code"
                          required
                          value={newCustomer?.accountAddress?.zip}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="560075"
                          onChange={(e) => {
                            setNewCustomer((prevCustomer) => ({
                              ...prevCustomer,
                              accountAddress: {
                                ...prevCustomer?.accountAddress,
                                zip: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* State & Country */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <Tooltip
                        title={
                          !selectedCountry
                            ? "Please select a country first"
                            : ""
                        }
                        placement="top">
                        <div style={{ display: "inline-block", width: "100%" }}>
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="state">
                            State<span className="text-red-500">*</span>
                          </label>
                          <AutocompleteDropdownV2
                            options={filteredStates}
                            labelKey="name"
                            valueKey="isoCode"
                            onChange={handleStateChange}
                            value={newCustomer?.accountAddress?.state || ""}
                            placeholder="Select a State"
                            id="name"
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700"
                        htmlFor="country">
                        Country<span className="text-red-500">*</span>
                      </label>
                      <AutocompleteDropdownV2
                        options={Country.getAllCountries()}
                        id="name"
                        labelKey="name"
                        valueKey="isoCode"
                        onChange={handleCountryChange}
                        value={newCustomer?.accountAddress?.country || ""}
                        placeholder="Select a Country"
                      />
                    </div>
                  </div>

                  {/* Planting Zone Checkbox */}
                  <div className="mt-4 flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="planting-zone"
                        name="planting-zone"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                        value={
                          newCustomer?.accountAddress?.isPlantingZoneAddress
                        }
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            accountAddress: {
                              ...prevCustomer?.accountAddress,
                              isPlantingZoneAddress: e.target.checked,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="planting-zone"
                        className="font-medium text-gray-700">
                        Mark the address as a planting zone
                      </label>
                    </div>
                  </div>

                  {/* Planting Zone Code */}
                  <div className="space-y-1">
                    <label
                      htmlFor="planting-zone"
                      className="block text-sm font-medium text-gray-700">
                      Planting Zone Code
                    </label>
                    <input
                      type="text"
                      name="planting-zone"
                      id="planting-zone"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder=""
                      value={newCustomer?.accountAddress?.plantingZoneCode}
                      onChange={(e) => {
                        setNewCustomer((prevCustomer) => ({
                          ...prevCustomer,
                          accountAddress: {
                            ...prevCustomer?.accountAddress,
                            plantingZoneCode: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="space-y-1 py-2">
                    <label
                      htmlFor="notes"
                      className="block text-sm font-medium text-gray-700">
                      Notes
                    </label>
                    <textarea
                      name="notes"
                      id="notes"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder="Enter any custom notes here"
                      value={newCustomer?.notes}
                      onChange={(e) => {
                        setNewCustomer((prevAddress) => ({
                          ...prevAddress,
                          notes: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              )}
              {selectedStep?.tag === "shippingInfo" && (
                <div className="space-y-6">
                  {/* Checkbox to use account address as default shipping address */}
                  {shouldDisplayAccountAddress() && (
                    <div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="use-account-address"
                          name="use-account-address"
                          checked={useAccountAddress}
                          onChange={(e) => {
                            setUseAccountAddress(e.target.checked);
                            if (e.target.checked) {
                              setNewCustomer((prevCustomer) => ({
                                ...prevCustomer,
                                shippingAddresses: [
                                  prevCustomer?.accountAddress,
                                  ...(prevCustomer?.shippingAddresses || []),
                                ],
                              }));
                            } else {
                              setNewCustomer((prevCustomer) => ({
                                ...prevCustomer,
                                shippingAddresses: (
                                  prevCustomer?.shippingAddresses || []
                                ).slice(1),
                              }));
                            }
                          }}
                          className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                        />
                        <label
                          htmlFor="use-account-address"
                          className="ml-2 text-sm font-medium text-gray-700">
                          Add the account address as shipping address
                        </label>
                      </div>
                      {useAccountAddress && (
                        <div className="rounded-md border p-4">
                          <div className="flex items-center justify-between">
                            <div>
                              <h3 className="text-lg font-semibold">
                                {[newCustomer?.firstName, newCustomer?.lastName]
                                  .filter(Boolean)
                                  .join(" ")}
                              </h3>
                              <p className="text-sm">
                                {[
                                  newCustomer?.accountAddress?.line1,
                                  newCustomer?.accountAddress?.line2,
                                ]
                                  .filter(Boolean)
                                  .join(", ")}
                              </p>
                              <p className="text-sm">
                                {[
                                  newCustomer?.accountAddress?.city,
                                  newCustomer?.accountAddress?.state,
                                  newCustomer?.accountAddress?.country,
                                  newCustomer?.accountAddress?.zip,
                                ]
                                  .filter(Boolean)
                                  .join(", ")}
                              </p>
                              <p className="text-sm">
                                {[
                                  newCustomer?.contactNumber,
                                  newCustomer?.email,
                                ]
                                  .filter(Boolean)
                                  .join(", ")}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {newCustomer?.shippingAddresses?.length > 0
                    ? newCustomer?.shippingAddresses
                        .filter(
                          (address) =>
                            !isSameAddress(
                              address,
                              newCustomer?.accountAddress,
                            ),
                        )
                        .map((address, index) => (
                          <div key={index} className="rounded-md border p-4">
                            <div className="flex items-center justify-between">
                              <div>
                                <h3 className="text-lg font-semibold">
                                  {[address?.firstName, address?.lastName]
                                    .filter(Boolean)
                                    .join(" ")}
                                </h3>
                                <p className="text-sm">
                                  {[address?.line1, address?.line2]
                                    .filter(Boolean)
                                    .join(", ")}
                                </p>
                                <p className="text-sm">
                                  {[
                                    address?.city,
                                    address?.state,
                                    address?.country,
                                    address?.zip,
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}
                                </p>
                                <p className="text-sm">
                                  {[address?.contactNumber, address?.email]
                                    .filter(Boolean)
                                    .join(", ")}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                    : null}

                  {!useAccountAddress &&
                  newCustomer.shippingAddresses.length === 0 ? (
                    <div className="rounded border border-blue-800 bg-blue-100 p-2 text-sm text-gray-600">
                      Please provide a shipping address for the customer if it
                      differs from the account address.
                    </div>
                  ) : null}

                  {/* Add New Address Button */}
                  {addNewAddress ? null : (
                    <button
                      type="button"
                      onClick={() => setAddNewAddress({ country: "US" })}
                      className="py-2 text-sm text-[#224E73] underline hover:text-[#224E73]">
                      + Add New Address
                    </button>
                  )}

                  {/* New Address Form */}
                  {addNewAddress && (
                    <div className="space-y-1">
                      <div className="space-x-2 border px-2 py-4">
                        <h3 className="py-2 text-lg font-semibold">
                          Add Address
                        </h3>

                        <div className="grid grid-cols-2 gap-4">
                          {/* First Name Field */}
                          <div className="space-y-1">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700">
                              First Name<span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              required
                              className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                              placeholder="Doe"
                              value={addNewAddress?.firstName}
                              onChange={(e) => {
                                setAddNewAddress((prevAddress) => ({
                                  ...prevAddress,
                                  firstName: e.target.value,
                                }));
                              }}
                            />
                          </div>

                          {/* Last Name Field */}
                          <div className="space-y-1">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700">
                              Last Name<span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              required
                              className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                              placeholder="John"
                              value={addNewAddress?.lastName}
                              onChange={(e) => {
                                setAddNewAddress((prevAddress) => ({
                                  ...prevAddress,
                                  lastName: e.target.value,
                                }));
                              }}
                            />
                          </div>
                        </div>

                        {/* Email Field */}
                        <div className="space-y-1 py-2">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700">
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                            placeholder="XYZ123@gmail.com"
                            value={addNewAddress?.email}
                            onChange={(e) => {
                              setAddNewAddress((prevAddress) => ({
                                ...prevAddress,
                                email: e.target.value,
                              }));
                            }}
                          />
                        </div>

                        {/* Contact Number Field */}
                        <div className="space-y-1 py-2">
                          <label
                            htmlFor="contact-number"
                            className="block text-sm font-medium text-gray-700">
                            Contact Number
                          </label>
                          <input
                            type="tel"
                            name="contact-number"
                            id="contact-number"
                            value={addNewAddress?.contactNumber}
                            className="block w-full rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                            placeholder="9876543210"
                            onChange={(e) => {
                              setAddNewAddress((prevAddress) => ({
                                ...prevAddress,
                                contactNumber: e.target.value,
                              }));
                            }}
                          />
                        </div>

                        {/* Address Fields */}
                        <div className="space-y-1 py-2">
                          {/* Address Line 1 */}
                          <label
                            htmlFor="address-line1"
                            className="block text-sm font-medium text-gray-700">
                            Address Line 1
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="address-line1"
                            id="address-line1"
                            required
                            value={addNewAddress?.line1}
                            className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                            onChange={(e) => {
                              setAddNewAddress((prevAddress) => ({
                                ...prevAddress,
                                line1: e.target.value,
                              }));
                            }}
                          />
                          {/* Address Line 2 */}
                          <label
                            htmlFor="address-line2"
                            className="block text-sm font-medium text-gray-700">
                            Address Line 2
                          </label>
                          <input
                            type="text"
                            name="address-line2"
                            id="address-line2"
                            value={addNewAddress?.line2}
                            className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                            onChange={(e) => {
                              setAddNewAddress((prevAddress) => ({
                                ...prevAddress,
                                line2: e.target.value,
                              }));
                            }}
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-4 py-2">
                          <div>
                            {/* City */}
                            <label
                              htmlFor="address-city"
                              className="block text-sm font-medium text-gray-700">
                              City<span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="address-city"
                              id="address-city"
                              value={addNewAddress?.city}
                              className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                              onChange={(e) => {
                                setAddNewAddress((prevAddress) => ({
                                  ...prevAddress,
                                  city: e.target.value,
                                }));
                              }}
                            />
                          </div>

                          <div>
                            {/* Zipcode */}
                            <label
                              htmlFor="zip-code"
                              className="block text-sm font-medium text-gray-700">
                              Zip Code<span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="zip-code"
                              id="zip-code"
                              required
                              value={addNewAddress?.zip}
                              className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                              placeholder="560075"
                              onChange={(e) => {
                                setAddNewAddress((prevAddress) => ({
                                  ...prevAddress,
                                  zip: e.target.value,
                                }));
                              }}
                            />
                          </div>
                        </div>

                        {/* State & Country */}
                        <div className="grid grid-cols-2 gap-4 py-2">
                          <div>
                            <Tooltip
                              title={
                                !selectedCountry
                                  ? "Please select a country first"
                                  : ""
                              }
                              placement="top">
                              <div
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                }}>
                                <label
                                  htmlFor="state"
                                  className="block text-sm font-medium text-gray-700">
                                  State<span className="text-red-500">*</span>
                                </label>
                                <AutocompleteDropdownV2
                                  options={filteredStates}
                                  labelKey="name"
                                  valueKey="isoCode"
                                  onChange={(newState) => {
                                    setSelectedState(newState);

                                    setAddNewAddress((prevAddress) => ({
                                      ...prevAddress,
                                      state: newState,
                                    }));
                                  }}
                                  value={addNewAddress?.state || ""}
                                  placeholder="Select a State"
                                  id="name"
                                />
                              </div>
                            </Tooltip>
                          </div>

                          <div>
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium text-gray-700">
                              Country<span className="text-red-500">*</span>
                            </label>
                            <AutocompleteDropdownV2
                              options={Country.getAllCountries()}
                              id="name"
                              labelKey="name"
                              valueKey="isoCode"
                              onChange={(newCountry) => {
                                setSelectedCountry(newCountry);
                                setSelectedState("");
                                setFilteredStates(
                                  State.getStatesOfCountry(newCountry),
                                );

                                setAddNewAddress((prevAddress) => ({
                                  ...prevAddress,
                                  country: newCountry,
                                }));
                              }}
                              value={addNewAddress?.country || ""}
                              placeholder="Select a Country"
                            />
                          </div>
                        </div>

                        {/* Planting Zone Checkbox */}
                        <div className="mt-2 flex items-start py-2">
                          <div className="flex h-5 items-center">
                            <input
                              id="planting-zone"
                              name="planting-zone"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                              value={addNewAddress?.isPlantingZoneAddress}
                              onChange={(e) => {
                                setAddNewAddress((prevAddress) => ({
                                  ...prevAddress,
                                  isPlantingZoneAddress: e.target.checked,
                                }));
                              }}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="planting-zone"
                              className="font-medium text-gray-700">
                              Mark the address as a planting zone
                            </label>
                          </div>
                        </div>

                        {/* Planting Zone Code */}
                        <div className="space-y-1 py-2">
                          <label
                            htmlFor="planting-zone"
                            className="block text-sm font-medium text-gray-700">
                            Planting Zone Code
                          </label>
                          <input
                            type="text"
                            name="planting-zone"
                            id="planting-zone"
                            className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                            placeholder=""
                            value={addNewAddress?.plantingZoneCode}
                            onChange={(e) => {
                              setNewCustomer((prevAddress) => ({
                                ...prevAddress,
                                plantingZoneCode: e.target.value,
                              }));
                            }}
                          />
                        </div>

                        <div className="flex items-center justify-end space-x-4 py-2">
                          <button
                            type="button"
                            onClick={() => setAddNewAddress(null)}
                            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2">
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={handleAddNewAddress}
                            disabled={
                              !addNewAddress.firstName ||
                              !addNewAddress.lastName ||
                              !addNewAddress.line1 ||
                              !addNewAddress.city ||
                              !addNewAddress.zip ||
                              !addNewAddress.state ||
                              !addNewAddress.country
                            }
                            className="rounded-md border border-transparent bg-[#224E73] px-4 py-2 text-sm font-medium text-white hover:bg-[#224E73] focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-mediumGray disabled:bg-mediumGray">
                            Save
                          </button>
                        </div>
                        {/* Form Actions */}
                      </div>
                      {/* Include additional fields for Address Line 2, City, State, ZIP, Country, etc. */}
                    </div>
                  )}
                </div>
              )}
            </main>
            <footer className="mt-auto w-full shrink-0">
              <AddUserFooter
                newCustomer={newCustomer}
                setNextStep={setNextStep}
                setPrevStep={setPrevStep}
                selectedStep={selectedStep}
                showSubmit={showSubmit}
                steps={steps}
                onSubmit={createCRMCustomer}
                onCancel={() => {
                  appState.showConfirmation(
                    "Cancel",
                    "Are you sure you want to cancel? All progress will be lost.",
                    appState.hideConfirmation,
                    () => {
                      onCancel();
                      appState.hideConfirmation();
                    },
                    "No, continue",
                    "Yes, cancel",
                  );
                }}
                isShippingSelected={newCustomer?.shippingAddresses?.length > 0}
              />
            </footer>
          </div>
        </SlideOverPanel>
      )}
    </div>
  );
};

const PaymentInfo = ({
  avalaraTaxInvoice,
  selectedConsignment,
  selectedCustomer,
  cart,
  billingInfo,
  setBillingInfo,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  chequeDetails,
  setChequeDetails,
  paymentMode,
  setPaymentMode,
  discount,
  setDiscount,
  shippingCharge,
  setShippingCharge,
  itemTotal,
  subTotal,
  orderTotal,
  additionalOrderDetails,
  setAdditionalOrderDetails,
  salesCustomers,
}) => {
  const appState = useContext(AppStateContext);

  const salesChannel = selectedConsignment?.company;
  const entity = salesCustomers.find((cus) => cus.name === salesChannel).entity;

  const [billingSameAsAccount, setBillingSameAsAccount] = useState(false);
  const [showBillingInfoForm, setShowBillingInfoForm] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [stripeSessionDetails, setStripeSessionDetails] = useState({});
  const [isCustomerExists, setIsCustomerExists] = useState(false);
  const [paymentGatewayCustomerDetails, setPaymentGatewayCustomerDetails] =
    useState({});

  const [selectedCountry, setSelectedCountry] = useState({
    country: billingInfo?.country,
  });
  const [selectedState, setSelectedState] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);

  const [billingInfoErrors, setBillingInfoErrors] = useState(null);

  const checkPaymentGatewayCustomerExists = useQuery(
    CHECK_PAYMENT_GATEWAY_CUSTOMER_EXISTS,
  );
  const getPaymentGatewayCustomerDetails = useQuery(
    GET_PAYMENT_GATEWAY_CUSTOMER_DETAILS,
  );
  const createPaymentGatewayCustomer = useQuery(
    CREATE_PAYMENT_GATEWAY_CUSTOMER,
  );
  const updatePaymentGatewayCustomer = useQuery(
    UPDATE_PAYMENT_GATEWAY_CUSTOMER,
  );
  const getPaymentGatewayPaymentMethods = useQuery(
    GET_PAYMENT_GATEWAY_PAYMENT_METHODS,
  );
  const addPaymentGatewayPaymentMethods = useQuery(
    ADD_PAYMENT_GATEWAY_CUSTOMER_PAYMENT_METHOD,
  );

  const submitDisabled = !billingInfo?.fullName || !billingInfo?.email;

  const isBillingInfoValid = () => {
    if (!billingInfo.fullName) {
      setBillingInfoErrors((prev) => ({
        ...prev,
        fullName: "Please enter your full name.",
      }));
      return false;
    } else if (!billingInfo.email) {
      setBillingInfoErrors((prev) => ({
        ...prev,
        email: "Please enter your email.",
      }));
      return false;
    }

    return true;
  };

  const getCustomerDetails = async () => {
    if (!paymentGatewayCustomerDetails.paymentGatewayCustomerId) return;

    if (isCustomerExists) {
      const res = await getPaymentGatewayCustomerDetails.fetchData({
        provider: "Stripe",
        payload: {
          customerId: paymentGatewayCustomerDetails.paymentGatewayCustomerId,
        },
      });

      setBillingInfo(
        mapBillingInfo({
          ...res.data?.getPaymentGatewayCustomerDetails,
          ...res.data?.getPaymentGatewayCustomerDetails.address,
        }),
      );
    }
  };

  const createCustomer = async () => {
    if (!isCustomerExists) {
      const response = await createPaymentGatewayCustomer.fetchData({
        provider: "Stripe",
        uniqueRequestId: uuidv4(),
        crmCustomerId: selectedCustomer.id,
        payload: {
          fullName: billingInfo.fullName,
          email: billingInfo.email,
          phone: billingInfo.contactNumber,
          address: {
            line1: billingInfo.line1,
            line2: billingInfo.line2,
            city: billingInfo.city,
            state: billingInfo.state,
            postalCode: billingInfo.zip,
            country: billingInfo.country,
          },
          reference: selectedCustomer.id,
        },
      });

      if (
        response.data.createPaymentGatewayCustomer.data.status === "CREATED"
      ) {
        setPaymentGatewayCustomerDetails(
          response.data.createPaymentGatewayCustomer.data,
        );

        setBillingInfo(
          mapBillingInfo({
            ...response.data.createPaymentGatewayCustomer.data
              .paymentGatewayCustomer,
            ...response.data.createPaymentGatewayCustomer.data
              .paymentGatewayCustomer.address,
          }),
        );

        setShowBillingInfoForm(false);
      }

      setBillingInfoErrors(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setBillingInfo((prev) => ({ ...prev, [name]: value }));

    if (name === "fullName" || name === "email")
      setBillingInfoErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const updateBillingInfo = async () => {
    const response = await updatePaymentGatewayCustomer.fetchData({
      provider: "Stripe",
      uniqueRequestId: uuidv4(),
      id: paymentGatewayCustomerDetails.paymentGatewayCustomerId,
      payload: {
        fullName: billingInfo.fullName,
        email: billingInfo.email,
        phone: billingInfo.contactNumber,
        address: {
          line1: billingInfo.line1,
          line2: billingInfo.line2,
          city: billingInfo.city,
          state: billingInfo.state,
          postalCode: billingInfo.zip,
          country: billingInfo.country,
        },
        // ? optional
        reference: selectedCustomer.id,
      },
    });

    if (response.data.updatePaymentGatewayCustomer.id) {
      setBillingInfo(
        mapBillingInfo({
          ...response.data.updatePaymentGatewayCustomer,
          ...response.data.updatePaymentGatewayCustomer.address,
        }),
      );
      setShowBillingInfoForm(false);
    }

    setBillingInfoErrors(null);
  };

  const handleGetPaymentMethods = async () => {
    const response = await getPaymentGatewayPaymentMethods.fetchData({
      provider: "Stripe",
      payload: {
        customerId: paymentGatewayCustomerDetails.paymentGatewayCustomerId,
      },
    });

    if (
      response.data.getPaymentGatewayCustomerPaymentMethods.paymentMethods
        .length > 0
    ) {
      setPaymentMethods(
        response.data.getPaymentGatewayCustomerPaymentMethods.paymentMethods,
      );
    } else {
      addPaymentMethod();
    }
  };

  const getPaymentMethodDetails = (payment) => {
    switch (payment.type) {
      case "us_bank_account":
        return {
          label: payment.bankAccount?.bankName,
          last4: payment.bankAccount?.last4,
        };
      case "card":
        return {
          label: payment.card?.brand,
          last4: payment.card?.last4,
          expiry: `${payment.card?.expiryMonth}/${payment.card?.expiryYear}`,
        };
    }
  };

  const addPaymentMethod = async () => {
    const response = await addPaymentGatewayPaymentMethods.fetchData({
      provider: "Stripe",
      uniqueRequestId: uuidv4(),
      payload: {
        id: paymentGatewayCustomerDetails.paymentGatewayCustomerId,
        paymentCurrency: "USD",
      },
    });

    if (response.data?.addPaymentGatewayCustomerPaymentMethod) {
      setStripeSessionDetails(
        response.data.addPaymentGatewayCustomerPaymentMethod,
      );
    } else if (response.error.message) {
      appState.setAlert(
        response.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  };

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(newCountry));

    setBillingInfo((prev) => ({
      ...prev,
      country: newCountry,
    }));
  };

  const handleStateChange = (newState) => {
    setSelectedState(newState);

    setBillingInfo((prev) => ({
      ...prev,
      state: newState,
    }));
  };

  const calculateShippingCharges = () => {
    if (["VE", "JS"].includes(selectedConsignment.company)) {
      let zipcode = selectedConsignment?.shippingAddress?.zip;

      if (!zipcode) return;

      if (zipcode.length < 5) {
        zipcode = new Array(5 - zipcode.length).fill(0).join("") + zipcode;
      }

      zipcode = zipcode.slice(0, 3);

      const shippingChargePercent = SHIPPING_PERCENTAGES.find(
        (item) => zipcode >= item.FRM_ZIP && zipcode <= item.TO_ZIP,
      )?.SHP_PCT;

      const shippingCharges = (
        (itemTotal * shippingChargePercent) /
        100
      ).toFixed(2);

      setShippingCharge(shippingCharges);
    } else if (selectedConsignment.company === "KGS") {
      let country = selectedConsignment?.shippingAddress?.country;
      if (!country) {
        appState.setAlert(
          "Please add Country in the shipping address",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      const shippingRates = [
        [4.99, 3.95],
        [9.99, 5.95],
        [29.99, 8.95],
        [49.99, 11.95],
        [74.99, 12.95],
        [99.99, 13.95],
        [124.99, 14.95],
        [149.99, 15.95],
      ];

      let shippingCharges =
        shippingRates.find(([threshold]) => itemTotal <= threshold)?.[1] ||
        itemTotal * 0.1;

      if (country === "CA") {
        shippingCharges += 4.0;
      }
      setShippingCharge(shippingCharges);
    }
  };

  useEffect(() => {
    const updateStates = () => {
      if (selectedCountry) {
        const states = State.getStatesOfCountry(selectedCountry);

        setFilteredStates(states);
        const stateIsValid = states.some(
          (state) => state.isoCode === selectedState,
        );
        if (!stateIsValid) {
          setSelectedState("");
        }
      } else {
        setFilteredStates([]);
        setSelectedState("");
      }
    };

    updateStates();
  }, [billingInfo, selectedCountry]);

  useEffect(() => {
    setSelectedCountry(billingInfo.country);
    setSelectedState(billingInfo.state);
  }, [billingInfo]);

  useEffect(() => {
    if (addPaymentGatewayPaymentMethods.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [addPaymentGatewayPaymentMethods.loading]);

  useEffect(() => {
    if (billingSameAsAccount)
      setBillingInfo(mapBillingInfo(selectedCustomer.accountAddress));
    else setBillingInfo(initialBillingInfo);
  }, [billingSameAsAccount]);

  useEffect(() => {
    if (
      isCustomerExists &&
      paymentGatewayCustomerDetails.paymentGatewayCustomerId
    ) {
      getCustomerDetails();
      setShowBillingInfoForm(false);
    } else setShowBillingInfoForm(true);
  }, [
    isCustomerExists,
    paymentGatewayCustomerDetails.paymentGatewayCustomerId,
  ]);

  useEffect(async () => {
    const response = await checkPaymentGatewayCustomerExists.fetchData({
      crmCustomerId: selectedCustomer.id,
    });

    if (
      JSON.parse(
        response.data.checkPaymentGatewayCustomerExists.data.isCustomerExists,
      )
    ) {
      setIsCustomerExists(true);
      setPaymentGatewayCustomerDetails(
        response.data.checkPaymentGatewayCustomerExists.data,
      );
    } else {
      setIsCustomerExists(false);
      setShowBillingInfoForm(true);
    }
  }, []);

  useEffect(() => {
    if (checkPaymentGatewayCustomerExists.error) {
      appState.setAlert(
        checkPaymentGatewayCustomerExists.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  }, [
    checkPaymentGatewayCustomerExists.loading,
    checkPaymentGatewayCustomerExists.error,
  ]);

  useEffect(() => {
    if (updatePaymentGatewayCustomer.data) {
      appState.setAlert(
        "Billing info updated successfully.",
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (updatePaymentGatewayCustomer.error?.message) {
      appState.setAlert(
        updatePaymentGatewayCustomer.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  }, [updatePaymentGatewayCustomer.data, updatePaymentGatewayCustomer.error]);

  useEffect(() => {
    if (createPaymentGatewayCustomer.data) {
      appState.setAlert(
        "Customer created successfully.",
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (createPaymentGatewayCustomer.error?.message) {
      appState.setAlert(
        createPaymentGatewayCustomer.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  }, [createPaymentGatewayCustomer.data, createPaymentGatewayCustomer.error]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      const payMethodType = paymentMethods[0].type;

      const payMethod =
        payMethodType === "us_bank_account"
          ? paymentMethods[0].bankAccount
          : payMethodType === "card"
            ? paymentMethods[0].card
            : {};

      setSelectedPaymentMethod({
        type: payMethodType,
        details: { ...payMethod, id: paymentMethods[0].id },
      });
    }
  }, [paymentMethods]);

  useEffect(() => {
    calculateShippingCharges();
  }, [selectedConsignment?.shippingAddress]);

  return (
    <div className="container mx-auto p-4">
      <div className="flex">
        <div className="w-2/3 space-y-4 pr-4">
          <div className="mb-4 text-lg font-bold">3. Payment Info</div>

          <div className="space-y-4">
            <p className="text-sm font-medium">Billing Info</p>

            {checkPaymentGatewayCustomerExists?.loading ||
            getPaymentGatewayCustomerDetails?.loading ||
            createPaymentGatewayCustomer?.loading ||
            updatePaymentGatewayCustomer?.loading ? (
              <LoadingIndicator shouldShowOnPage={false} />
            ) : showBillingInfoForm ? (
              <div>
                {!isCustomerExists && (
                  <p className="mb-2 rounded border border-yellow-500 bg-yellow-50 p-2 text-sm text-gray-600">
                    No billing info found for this account in stripe, please
                    create one to charge now or do it later.
                  </p>
                )}

                <div className="space-y-1 rounded border p-4">
                  <p className="mb-2 text-sm font-medium">
                    {isCustomerExists ? "Edit" : "Add"} Billing Info
                  </p>

                  <div className="">
                    {!isCustomerExists && (
                      <div className="py-2">
                        <input
                          type="checkbox"
                          id="billingSameAsAccount"
                          className="mr-2"
                          checked={billingSameAsAccount}
                          onChange={(e) => {
                            setBillingSameAsAccount(e?.target.checked);
                          }}
                        />

                        <label
                          htmlFor="billingSameAsAccount"
                          className="cursor-pointer text-sm text-gray-700">
                          Billing info is same as account info
                        </label>
                      </div>
                    )}

                    {/* FullName Field */}
                    <div className="space-y-1 py-2">
                      <label
                        htmlFor="fullName"
                        className="block text-sm font-medium text-gray-700">
                        Full Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        id="full-name"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="John Doe"
                        value={billingInfo?.fullName}
                        onChange={handleInputChange}
                      />
                      {billingInfoErrors?.fullName && (
                        <span className="text-xs text-red-500">
                          {billingInfoErrors.fullName}
                        </span>
                      )}
                    </div>

                    {/* Email Field */}
                    <div className="space-y-1 py-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700">
                        Email Address<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="XYZ123@gmail.com"
                        value={billingInfo?.email}
                        onChange={handleInputChange}
                      />
                      {billingInfoErrors?.email && (
                        <span className="text-xs text-red-500">
                          {billingInfoErrors.email}
                        </span>
                      )}
                    </div>

                    {/* Contact Number Field */}
                    <div className="space-y-1 py-2">
                      <label
                        htmlFor="contact-number"
                        className="block text-sm font-medium text-gray-700">
                        Contact Number
                      </label>
                      <input
                        type="tel"
                        name="contactNumber"
                        id="contact-number"
                        value={billingInfo?.contactNumber}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="9876543210"
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Address Fields */}
                    <div>
                      <div className="space-y-1 py-2">
                        {/* Address Line 1 */}
                        <label
                          htmlFor="address-line1"
                          className="block text-sm font-medium text-gray-700">
                          Address Line 1
                        </label>
                        <input
                          type="text"
                          name="line1"
                          id="address-line1"
                          value={billingInfo?.line1}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="space-y-1 py-2">
                        {/* Address Line 2 */}
                        <label
                          htmlFor="address-line2"
                          className="block text-sm font-medium text-gray-700">
                          Address Line 2
                        </label>
                        <input
                          type="text"
                          name="line2"
                          id="address-line2"
                          value={billingInfo?.line2}
                          className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4 space-y-1 py-2">
                      <div>
                        {/* City */}
                        <label
                          htmlFor="address-city"
                          className="block text-sm font-medium text-gray-700">
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="address-city"
                          value={billingInfo?.city}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>

                      <div>
                        {/* Zipcode */}
                        <label
                          htmlFor="zip-code"
                          className="block text-sm font-medium text-gray-700">
                          Zip Code
                        </label>
                        <input
                          type="text"
                          name="zip"
                          id="zip-code"
                          value={billingInfo?.zip}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="560075"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    {/* State & Country */}
                    <div className="grid grid-cols-2 gap-4 py-2">
                      <div>
                        <Tooltip
                          title={
                            !selectedCountry
                              ? "Please select a country first"
                              : ""
                          }
                          placement="top">
                          <div
                            style={{ display: "inline-block", width: "100%" }}>
                            <label
                              htmlFor="state"
                              className="block text-sm font-medium text-gray-700">
                              State
                            </label>
                            <AutocompleteDropdownV2
                              options={filteredStates}
                              labelKey="name"
                              valueKey="isoCode"
                              onChange={handleStateChange}
                              value={billingInfo?.state || ""}
                              placeholder="Select a State"
                              id="name"
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700">
                          Country
                        </label>
                        <AutocompleteDropdownV2
                          options={Country.getAllCountries()}
                          id="name"
                          labelKey="name"
                          valueKey="isoCode"
                          onChange={handleCountryChange}
                          value={billingInfo?.country || ""}
                          placeholder="Select a Country"
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-end space-x-4 py-2">
                      <button
                        type="button"
                        onClick={() => {
                          setShowBillingInfoForm(false);
                        }}
                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2">
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          if (isBillingInfoValid()) {
                            if (isCustomerExists) updateBillingInfo();
                            else createCustomer();
                          }
                        }}
                        className="rounded-md border border-transparent bg-[#224E73] px-4 py-2 text-sm font-medium text-white hover:bg-[#224E73] focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2">
                        Save
                      </button>
                    </div>
                    {/* Form Actions */}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-between space-y-1 rounded border p-4">
                <div>
                  <p className="text-base font-semibold">
                    {billingInfo.fullName ? billingInfo.fullName : ""}
                  </p>
                  <p className="text-sm">
                    {[
                      billingInfo.line1,
                      billingInfo.line2,
                      billingInfo.city,
                      billingInfo.state,
                      billingInfo.country,
                      billingInfo.zip,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </p>
                  <p className="text-sm">
                    {[billingInfo.contactNumber, billingInfo.email]
                      .filter(Boolean)
                      .join(", ")}
                  </p>
                </div>

                <div>
                  <button onClick={() => setShowBillingInfoForm(true)}>
                    <PencilIcon className="h-5 w-5 text-primaryAccent" />
                  </button>
                </div>
              </div>
            )}
          </div>

          <div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium">Payment Mode</p>

              <button
                onClick={handleGetPaymentMethods}
                disabled={
                  submitDisabled || addPaymentGatewayPaymentMethods.loading
                }
                className={`${submitDisabled ? "cursor-not-allowed" : ""}`}>
                <RefreshIcon
                  className={`h-5 w-5 ${submitDisabled || addPaymentGatewayPaymentMethods.loading ? "text-gray-400" : ""}`}
                />
              </button>
            </div>

            <div className="mb-4">
              <div className="my-4 flex cursor-pointer gap-2 rounded border p-4">
                <input
                  type="radio"
                  id="stripe"
                  name="payment"
                  className="mt-1"
                  checked={paymentMode === paymentModesEnum.stripe}
                  onChange={() => setPaymentMode(paymentModesEnum.stripe)}
                />

                <div className="flex w-full cursor-pointer flex-col">
                  <label htmlFor="stripe">
                    <p className="font-semibold">Stripe</p>

                    <span className="text-gray-500">Pay using stripe</span>
                  </label>

                  {paymentMode === paymentModesEnum.stripe && (
                    <div className="flex flex-col gap-4">
                      {getPaymentGatewayPaymentMethods.loading ? (
                        <LoadingIndicator shouldShowOnPage={false} />
                      ) : paymentMethods.length > 0 ? (
                        <div className="">
                          {paymentMethods.map((method) => (
                            <div
                              key={method.id}
                              className="my-2 flex items-center gap-2 rounded border p-4">
                              <input
                                type="radio"
                                id={method.id}
                                name="payment-method"
                                value={method.id}
                                checked={
                                  method.id === selectedPaymentMethod.details.id
                                }
                                onChange={() => {
                                  const payMethod =
                                    method.type === "us_bank_account"
                                      ? method.bankAccount
                                      : method.type === "card"
                                        ? method.card
                                        : {};
                                  setSelectedPaymentMethod({
                                    type: method.type,
                                    details: { ...payMethod, id: method.id },
                                  });
                                }}
                              />

                              <label
                                htmlFor={method.id}
                                className="grow cursor-pointer">
                                <p className="text-base font-semibold">
                                  {getPaymentMethodDetails(method).label}
                                </p>
                                <p className="text-sm text-gray-400">
                                  ****{getPaymentMethodDetails(method).last4}
                                </p>
                                {method.type === "card" && (
                                  <p className="text-sm text-gray-400">
                                    Exp date:{" "}
                                    {getPaymentMethodDetails(method).expiry}
                                  </p>
                                )}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <button
                          className="mt-2 w-max cursor-pointer rounded-md border border-primaryAccent px-4 py-2 text-base font-semibold text-primaryAccent disabled:cursor-not-allowed disabled:border-gray-400 disabled:text-gray-400"
                          onClick={handleGetPaymentMethods}
                          disabled={
                            submitDisabled ||
                            addPaymentGatewayPaymentMethods.loading
                          }>
                          Initiate Check On Stripe
                        </button>
                      )}

                      <button
                        className="w-max cursor-pointer text-sm font-semibold text-primaryAccent underline focus:outline-none disabled:cursor-not-allowed disabled:text-gray-400"
                        disabled={
                          submitDisabled ||
                          addPaymentGatewayPaymentMethods.loading
                        }
                        onClick={addPaymentMethod}>
                        Additional Payment Methods
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="my-4 rounded border p-4">
                <div className="flex cursor-pointer gap-2">
                  <input
                    type="radio"
                    id="offline"
                    name="payment"
                    className="mt-1"
                    checked={paymentMode === paymentModesEnum.payOffline}
                    onChange={() => setPaymentMode(paymentModesEnum.payOffline)}
                  />

                  <div className="flex w-full cursor-pointer flex-col">
                    <label htmlFor="offline">
                      <p className="font-semibold">Pay Offline</p>
                    </label>

                    {paymentMode === paymentModesEnum.payOffline && (
                      <div className="mt-2 flex gap-4">
                        <div className="flex w-1/2 flex-col gap-1">
                          <label
                            htmlFor="chequeNumber"
                            className="text-sm font-medium text-gray-600">
                            Check No.
                          </label>

                          <input
                            type="text"
                            name="chequeNumber"
                            value={chequeDetails.chequeNumber}
                            className="rounded border border-gray-200 p-2 text-base"
                            onChange={(e) =>
                              setChequeDetails((prev) => ({
                                ...prev,
                                chequeNumber: e.target.value,
                              }))
                            }
                          />
                        </div>

                        <div className="flex w-1/2 flex-col gap-1">
                          <label
                            htmlFor="chequeValue"
                            className="text-sm font-medium text-gray-600">
                            Check Value ($)
                          </label>

                          <input
                            type="number"
                            name="chequeValue"
                            value={chequeDetails.chequeValue}
                            className="rounded border border-gray-200 p-2 text-base"
                            onChange={(e) =>
                              setChequeDetails((prev) => ({
                                ...prev,
                                chequeValue: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <label className="text-sm font-medium" htmlFor="orderNotes">
                  Order Notes
                </label>
                <textarea
                  id="orderNotes"
                  className="mt-2 w-full rounded border border-gray-200 p-2"
                  rows="4"
                  value={additionalOrderDetails.orderNote}
                  onChange={(e) =>
                    setAdditionalOrderDetails((prev) => ({
                      ...prev,
                      orderNote: e.target.value,
                    }))
                  }></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/3 pl-4">
          <div className="rounded border bg-gray-50 p-4">
            <div className="mb-2 font-semibold">Order Summary</div>
            <div className="mb-2">
              <span className="text-sm text-gray-600">Shipping Address</span>
            </div>
            <div className="mb-2 flex flex-col space-y-1 rounded bg-white p-2 text-xs">
              <p className="text-sm font-semibold">
                {[
                  selectedConsignment?.shippingAddress?.firstName,
                  selectedConsignment?.shippingAddress?.lastName,
                ]
                  .filter(Boolean)
                  .join(" ")}
              </p>
              <p>
                {[
                  selectedConsignment?.shippingAddress?.line1,
                  selectedConsignment?.shippingAddress?.line2,
                  selectedConsignment?.shippingAddress?.city,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </p>
              <p>
                {[
                  selectedConsignment?.shippingAddress?.state,
                  selectedConsignment?.shippingAddress?.country,
                  selectedConsignment?.shippingAddress?.zip,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </p>
            </div>

            <div className="mb-2">
              <span className="text-sm text-gray-600">Customer Cart</span>
            </div>

            <div className="mb-2 rounded bg-white p-2">
              <div className="mb-2 max-h-64 overflow-y-auto border-b">
                {cart?.map((item) => (
                  <div
                    key={item.id}
                    className="mb-4 flex justify-between text-gray-900">
                    <div className="flex flex-col space-y-1">
                      <p className="text-sm">{item.name}</p>
                      <p className="w-max rounded border bg-gray-50 p-1 text-xs">
                        Qty: {item[entity]} {entity}
                      </p>
                    </div>

                    <p className="text-base font-medium">${item.price}</p>
                  </div>
                ))}
              </div>

              <div>
                <div className="mb-2 border-b">
                  <div className="mb-2 flex justify-between">
                    <p className="text-sm text-gray-500">Item Total:</p>
                    <p className="text-base text-gray-700">
                      ${Number(itemTotal).toFixed(2)}
                    </p>
                  </div>

                  <div className="mb-2 flex justify-between">
                    <p className="text-sm text-gray-500">
                      Shipping & Handling:
                    </p>
                    <div className="flex items-center gap-2">
                      <span>$</span>
                      <input
                        type="number"
                        name="Shipping & Handling:"
                        id="ShippingHandling:"
                        value={shippingCharge}
                        className="block w-24 rounded-md border border-gray-300 px-2 py-1 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={(e) => setShippingCharge(e.target.value)}
                      />
                    </div>
                  </div>

                  {avalaraTaxInvoice?.totalTaxCalculated ? (
                    <div className="mb-2 flex justify-between">
                      <p className="text-sm text-gray-500">Sales Tax:</p>
                      <p className="text-base text-gray-700">
                        ${avalaraTaxInvoice.totalTaxCalculated}
                      </p>
                    </div>
                  ) : null}

                  <div className="mb-2 flex justify-between">
                    <p className="text-sm text-gray-500">Discount:</p>

                    <div className="flex items-center gap-2">
                      <span>$</span>
                      <input
                        type="number"
                        name="discount"
                        id="discount"
                        value={discount}
                        className="block w-24 rounded-md border border-gray-300 px-2 py-1 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-2 flex flex-col">
                  <div className="flex justify-between">
                    <p className="text-sm text-gray-500">Order Total:</p>
                    <p className="text-base text-gray-700">
                      ${subTotal.toFixed(2)}
                    </p>
                  </div>

                  {discount > 0 ? (
                    <div className="ml-auto text-right">
                      <p className="text-red-600">-${discount}</p>
                      <p className="text-xl font-semibold">
                        ${orderTotal.toFixed(2)}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <input
                type="checkbox"
                id="giftOrder"
                checked={additionalOrderDetails.markAsGift}
                onChange={(e) =>
                  setAdditionalOrderDetails((prev) => ({
                    ...prev,
                    markAsGift: e.target.checked,
                  }))
                }
                className="mr-2"
              />
              <label htmlFor="giftOrder" className="text-base text-black">
                For Gift Order
              </label>

              {additionalOrderDetails.markAsGift && (
                <div className="mt-2">
                  <label
                    className="text-sm font-medium text-gray-500"
                    htmlFor="giftNote">
                    Gift Message
                  </label>
                  <textarea
                    id="giftNote"
                    className="w-full rounded border p-2 text-sm"
                    rows="2"
                    value={additionalOrderDetails.giftNote}
                    onChange={(e) =>
                      setAdditionalOrderDetails((prev) => ({
                        ...prev,
                        giftNote: e.target.value,
                      }))
                    }></textarea>
                </div>
              )}
            </div>

            <div className="mt-2">
              <input
                type="checkbox"
                id="fundraiserEvent"
                checked={additionalOrderDetails.markAsFundRaiser}
                onChange={(e) =>
                  setAdditionalOrderDetails((prev) => ({
                    ...prev,
                    markAsFundRaiser: e.target.checked,
                  }))
                }
                className="mr-2"
              />
              <label htmlFor="fundraiserEvent" className="text-base text-black">
                For Fundraiser Event
              </label>
            </div>
          </div>
        </div>
      </div>

      {stripeSessionDetails?.checkoutSessionDetails?.clientSecret ? (
        <Modal
          maxWidth="500px"
          minWidth="500px"
          title=""
          negativeAction={() => {
            setStripeSessionDetails({});
          }}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret:
                stripeSessionDetails.checkoutSessionDetails.clientSecret,
            }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Modal>
      ) : null}
    </div>
  );
};

const getFormedSteps = (selectedConsignment) => {
  let formedSteps = [];

  formedSteps.push(
    {
      id: "01",
      name: "Basic Info",
      href: "#",
      status: "current",
      visible: true,
    },
    {
      id: "02",
      name: "Select Products",
      href: "#",
      status: "upcoming",
      visible: true,
    },
    {
      id: "03",
      name: "Payment Info",
      href: "#",
      status: "upcoming",
      visible: true,
    },
  );

  formedSteps[0] = {
    ...formedSteps[0],
    status: "current",
  };

  return formedSteps;
};

export default OrderForm;
