import { Popover } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/outline";

const InformationIcon = ({ message }) => (
  <Popover className="relative bg-white">
    <Popover.Button>
      <InformationCircleIcon className="text-xs text-yellow-600" width={30} />
    </Popover.Button>

    <Popover.Panel className="absolute z-50 bg-white">
      <div className="w-48 border border-gray-600 bg-white p-4">
        <div>{message}</div>
      </div>
    </Popover.Panel>
  </Popover>
);

export default InformationIcon;
