export const constructQrCodesForLabelPrint = (barcodes) => {
  let cmds = "^XA";
  if (barcodes[0]) {
    cmds += `^FO400,450^BQN,2,10^FDQA,${barcodes[0]}^FS`;
    cmds += `^FWR^CF0,130^FO0,0^FB1200,2,0,C,0^^FD${barcodes[0]
      .replace("CA-BIN-", "")
      .replace("CA-BUF-", "")}`;
    cmds += "\\&^FS";
  }
  // cmds += `^FO0,380^GB815,3,3^FS`;

  // if (barcodes[0]) {
  //   cmds += `^FO300,430^BQN,2,10^FDQA,${barcodes[0]}^FS`;
  //   cmds += `^CF0,40`;
  //   cmds += `^FO240,680^FD${barcodes[0]}^FS`;
  // }

  // cmds += `^FO0,780^GB815,3,3^FS`;
  // if (barcodes[1]) {
  //   cmds += `^FO300,830^BQN,2,10^FDQA,${barcodes[1]}^FS`;
  //   cmds += `^CF0,40`;
  //   cmds += `^FO240,1080^FD${barcodes[1]}^FS`;
  // }

  cmds += "^XZ";

  return cmds;
};

export const constructBarcodesFor3x1Print = (barcodes, entityType) => {
  let cmds = "^XA";
  if (barcodes[0]) {
    if (entityType === "TOTE") {
      cmds += `^BY2,3,100`;
      cmds += `^FO120,50^BC^FD${barcodes[0]}^FS`;
    }
    if (entityType === "FNSKU") {
      cmds += `^BY2,3,100`;
      cmds += `^FO85,25^BC^FD${barcodes[0]}^FS`;
    }
    if (entityType === "BIN_LOCATION") {
      cmds += `^BY3.5,2,80`;
      cmds += `^FO100,50^BC^FD${barcodes[0]}^FS`;
    }
  }

  cmds += "^XZ";

  return cmds;
};

export const constructQrCodesForRegularPrint = (barcodes) => {
  let cmds = "^XA";

  if (barcodes[0]) {
    cmds += `^FO330,30^BQN,2,8^FDQA,${barcodes[0]}^FS`;
    cmds += `^CF0,60^FO280,270^FD${barcodes[0]
      .replace("CA-BIN-", "")
      .replace("CA-BUF-", "")}^FS^`;
  }

  if (barcodes[1]) {
    cmds += `^FO330,430^BQN,2,8^FDQA,${barcodes[1]}^FS`;
    cmds += `^CF0,60^FO280,670^FD${barcodes[1]
      .replace("CA-BIN-", "")
      .replace("CA-BUF-", "")}^FS^`;
  }

  if (barcodes[2]) {
    cmds += `^FO330,830^BQN,2,8^FDQA,${barcodes[2]}^FS`;
    cmds += `^CF0,60^FO280,1070^FD${barcodes[2]
      .replace("CA-BIN-", "")
      .replace("CA-BUF-", "")}^FS^`;
  }

  cmds += "^XZ";

  return cmds;
};

export const constructBarcodesForLabelPrint = (barcodes) => {
  let cmds = "^XA";

  cmds += `^BY4,2,200`;
  if (barcodes[0]) {
    cmds += `^FO50,50^BC^FD${barcodes[0]}^FS`;
  }

  cmds += `^BY4,2,200`;
  if (barcodes[1]) {
    cmds += `^FO50,430^BC^FD${barcodes[1]}^FS`;
  }

  cmds += `^BY4,2,200`;
  if (barcodes[2]) {
    cmds += `^^FO50,800^BC^FD${barcodes[2]}^FS`;
  }

  cmds += "^XZ";
  return cmds;
};

export const constructShippingLabel = () => {
  let cmds = "^XA";

  cmds += "^CF0,60";
  cmds += "^FO50,50^GB100,100,100^FS";
  cmds += "^FO75,75^FR^GB100,100,100^FS";
  cmds += "^FO93,93^GB40,40,40^FS";
  cmds += "^FO220,50^FDHopstack, Inc.^FS";
  cmds += "^CF0,30";
  cmds += "^FO220,115^FD1000 Shipping Lane^FS";
  cmds += "^FO220,155^FDShelbyville TN 38102^FS";
  cmds += "^FO220,195^FDUnited States (USA)^FS";
  cmds += "^FO50,250^GB700,3,3^FS";

  cmds += "^CFA,30";
  cmds += "^FO50,300^FDJohn Doe^FS";
  cmds += "^FO50,340^FD100 Main Street^FS";
  cmds += "^FO50,380^FDSpringfield TN 39021^FS";
  cmds += "^FO50,420^FDUnited States (USA)^FS";
  cmds += "^CFA,15";
  cmds += "^FO600,300^GB150,150,3^FS";
  cmds += "^FO638,340^FDPermit^FS";
  cmds += "^FO638,390^FD123456^FS";
  cmds += "^FO50,500^GB700,3,3^FS";

  cmds += "^BY5,2,270";
  cmds += "^FO100,550^BC^FDSAB03030^FS";

  cmds += "^FO50,900^GB700,250,3^FS";
  cmds += "^FO400,900^GB3,250,3^FS";
  cmds += "^CF0,40";
  cmds += "^FO100,960^FDCtr. X34B-1^FS";
  cmds += "^FO100,1010^FDREF1 F00B47^FS";
  cmds += "^FO100,1060^FDREF2 BL4H8^FS";
  cmds += "^CF0,190";
  cmds += "^FO470,955^FDCA^FS";

  cmds += "^XZ";

  return cmds;
};
