import moment from "moment-timezone";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  PencilIcon,
  TrashIcon,
  QrcodeIcon,
  EyeIcon,
  DocumentIcon,
  ClipboardListIcon,
  PrinterIcon,
} from "@heroicons/react/outline";
import _ from "lodash";
import Modal from "#components/utils/Modal";
import DetailedPlanExecution from "../cycleCountPlans/DetailedPlanExecution";

const PlanExecutionsList = ({
  cycleCountPlanExecutions,
  onClose,
  viewButtonClicked,
  headers,
  noValuesText,
  detailedPlanExecution,
  onCloseDetailedExecutions,
  onClickAdjustStocks,
  selectedSkusForAdjustment,
  setSelectedSkusForAdjusment,
  submitStockAdjustment,
  selectExecutionSku,
  compareExecution,
  printReport,
}) => {
  return (
    <Modal
      title={"Executions"}
      negativeAction={onClose}
      positiveAction={onClose}>
      {/* {console.log(detailedPlanExecution, "detail")} */}
      {detailedPlanExecution && !printReport && (
        <DetailedPlanExecution
          detailedPlanExecution={detailedPlanExecution}
          onClose={onCloseDetailedExecutions}
          noValuesText="Associates haven't started counting"
          onClickAdjustStocks={onClickAdjustStocks}
          selectedSkusForAdjustment={selectedSkusForAdjustment}
          setSelectedSkusForAdjusment={setSelectedSkusForAdjusment}
          submitStockAdjustment={submitStockAdjustment}
          selectExecutionSku={selectExecutionSku}
          compareExecution={compareExecution}
        />
      )}
      <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="rounded-full p-4">
            <tr className="font-montserratborder-left text-primaryAccent">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {cycleCountPlanExecutions.executions.length === 0 ? (
              <tr className="bg-white">
                {headers.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <td
                      className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                      key={headerIdx}>
                      {noValuesText}
                    </td>
                  ) : (
                    <td
                      className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {cycleCountPlanExecutions.executions.map((planExecution, index) => (
              <tr
                key={planExecution.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td className="w-40 px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {moment(planExecution.createdAt).format(
                    "MMMM Do YYYY, h:mm:ss a",
                  )}
                </td>

                <td className="w-30 px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {planExecution.status}
                </td>
                <td className="w-40 px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {planExecution.stockAdjustments?.length
                    ? moment(
                        planExecution.stockAdjustments[
                          planExecution.stockAdjustments.length - 1
                        ].timestamp,
                      ).format("MMM Do YYYY, h:mm a")
                    : "N/A"}
                </td>
                <td className="w-40 max-w-min px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {(planExecution.stockAdjustments &&
                    planExecution.stockAdjustments[
                      planExecution.stockAdjustments.length - 1
                    ]?.reason) ||
                    "N/A"}
                </td>
                <td className="w-20 rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  <div className="flex items-center space-x-4">
                    <TableFieldButton
                      text={<ClipboardListIcon className="h-6 w-6" />}
                      onClick={() =>
                        viewButtonClicked(
                          planExecution.id,
                          planExecution.planId,
                          false,
                        )
                      }
                    />
                    <TableFieldButton
                      text={<PrinterIcon className="h-6 w-6" />}
                      onClick={() =>
                        viewButtonClicked(
                          planExecution.id,
                          planExecution.planId,
                          true,
                        )
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default PlanExecutionsList;
