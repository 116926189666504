import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";

export default function AutocompleteModern({
  options,
  value,
  onChange,
  labelKey,
  valueKey,
  placeholder,
  onKeyDown,
  secondaryLabelKey,
  disabled = false,
  searchable = true,
  restrictOptionsWidth = true,
}) {
  const [text, setText] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const dropdownRef = useRef(null);

  const select = (option) => {
    if (onChange) {
      onChange(option);
    }
    setShowOptions(false);
  };

  const handleChange = (text) => {
    setText(text);
    if (onKeyDown) {
      onKeyDown(text);
    }
    setCursor(-1);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const filteredOptions = options
    ? options
        .filter((option) =>
          option[labelKey]?.toLowerCase().includes(text?.toLowerCase()),
        )
        .sort(
          (a, b) =>
            b.frequency - a.frequency || a[labelKey].localeCompare(b[labelKey]),
        )
    : [];

  const moveCursorDown = () => {
    if (cursor < filteredOptions.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e) => {
    switch (e.key) {
      case "ArrowUp":
        moveCursorUp();
        break;
      case "ArrowDown":
        moveCursorDown();
        break;
      case "Enter":
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor][valueKey]);
        }
        break;
    }
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        !dropdownRef ||
        !dropdownRef.current ||
        !dropdownRef.current.contains(event.target)
      ) {
        setShowOptions(false);
        setCursor(-1);
        // also empty the search text
        setText("");
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative h-48 w-full" ref={dropdownRef}>
      <div
        className={`relative flex rounded border border-borderGray bg-white ${
          disabled && "pointer-events-none opacity-50"
        }`}
        onClick={() => !disabled && setShowOptions((prev) => !prev)}>
        <div
          className={`flex grow cursor-pointer items-center pl-4 text-gray-700`}>
          {(_.find(options, (e) => e[valueKey] === value) &&
            _.find(options, (e) => e[valueKey] === value)[labelKey]) ||
            placeholder}
        </div>
        <div className="flex cursor-pointer items-center justify-center p-2">
          <ChevronDownIcon className="h-6 w-6 text-gray-600" />
        </div>
      </div>

      {showOptions && (
        <>
          <div className="z-8 relative mt-2 w-full">
            <ul
              className={`absolute ${
                restrictOptionsWidth ? "w-full" : "w-max"
              } z-50 max-h-96 overflow-auto rounded-lg border border-gray-300 bg-white shadow-lg`}>
              {searchable && (
                <li className="sticky top-0 z-10 bg-white">
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full border-b border-gray-300 bg-gray-100 p-2 pl-8 text-gray-900 transition-colors duration-300 hover:bg-white focus:border-gray-500 focus:ring-gray-500"
                    placeholder={"Search"}
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyDown={handleNav}
                    autoFocus
                  />
                  <span className="absolute left-3 top-3">
                    <SearchIcon className="h-4 w-4" />
                  </span>
                </li>
              )}
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, i, arr) => {
                  let className = `text-gray-900 text-md cursor-pointer select-none relative py-4 pl-4 pr-4 border-b hover:bg-gray-300 `;

                  // if (i === 0) className += "rounded-t-lg";
                  if (i === arr.length - 1) className += "rounded-b-lg";
                  // else if (i === 0 && arr.length === 1)
                  //   className += "rounded-lg";
                  else className += "";

                  if (cursor === i) {
                    className += " bg-gray-200"; // Highlight the selected item with a slightly different shade of grey
                  }

                  return (
                    <li
                      className={className}
                      key={i}
                      onClick={() => select(option[valueKey])}>
                      {option[labelKey]}{" "}
                      {secondaryLabelKey &&
                        option[secondaryLabelKey] &&
                        `(${option[secondaryLabelKey]})`}
                    </li>
                  );
                })
              ) : (
                <li className="text-md relative cursor-default select-none border-b py-4 pl-4 pr-4 text-gray-900">
                  No results
                </li>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
