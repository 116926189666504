import ReactTooltip from "react-tooltip";

/**
 * @param {JSX.Element} children - tooltip content
 * @param {string} placement - tooltip placement => top (default) | right | bottom | left
 * @param {string} id - identifier to recognize tooltip container, same id should be passed while rendering the tooltip
 * @param {string} type - type of tooltip => dark (default) | success | warning | error | info | light
 * @param {string} effect - float (default) | solid
 * @param {string} className - className for tooltip
 * @returns {JSX.Element}
 * @description - Returns a tooltip component
 */

const Tooltip = ({ children, placement, id, type, effect, className }) => {
  return (
    <ReactTooltip
      place={placement}
      id={id}
      html={true}
      type={type}
      effect={effect}
      // Max 500px width is neither too short nor does it overflow out of the screen
      className={`max-w-[500px] text-sm ${className}`}>
      {children}
    </ReactTooltip>
  );
};

export default Tooltip;
