import PageTitle from "#components/utils/PageTitle";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import _ from "lodash";

const headers = ["ASIN", "SKU", "Name", "Qty"];

const AllListView = ({ currentBatch, confirmDropoffWithConfirmation }) => {
  return (
    <div className="max-w-4xl flex-1">
      <PageTitle>Total ({currentBatch?.workingList?.length}) </PageTitle>
      <div
        className="mt-10 overflow-auto rounded-md border border-gray-400 bg-EBEBEB p-4"
        style={{ maxHeight: 800 }}>
        <table className="-mt-4 min-w-full divide-y divide-gray-200">
          <thead className="rounded-full p-4">
            <tr className="font-montserratborder-left text-primaryAccent">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {currentBatch.workingList.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {buildMarketplaceHyperlink(item.asin, "ASIN", item.source)}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.sku}
                </td>
                <td
                  className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                  alt={item.productName}
                  title={item.productName}>
                  {truncate(item.productName)}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.quantity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-20 flex items-center justify-center space-x-4">
        <div className="w-64 py-4 text-center text-2xl text-black">
          Have you picked all these items?
        </div>
        <div
          className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
          onClick={confirmDropoffWithConfirmation}>
          Yes
        </div>
      </div>
    </div>
  );
};

const truncate = (str) => {
  return str && str.length > 50 ? str.substring(0, 50) + "..." : str;
};

export default AllListView;
