import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
// components
import SlideOverModal from "#components/common/SlideOverModal";
import AuditLogTable from "#components/salesManagement/AuditLogTable";
import { GET_CUSTOMER_DETAILS_AUDIT_LOGS, GET_USERS } from "#queries";
import { AppStateContext } from "#contexts/appState";

const ALERT_TIMEOUT_IN_MS = 5000;

const AuditHistory = () => {
  const appState = useContext(AppStateContext);
  const [auditData, setAuditData] = useState([]);
  const [modalAuditData, setmodalAuditData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const getAduitLogQuery = useQuery(GET_CUSTOMER_DETAILS_AUDIT_LOGS);
  const getAuditUsersQuery = useQuery(GET_USERS);
  const [auditUserInfo, setAuditUserInfo] = useState({});

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const now = new Date();
  const LAST_7_DAY_TIME = new Date(
    now.getTime() + 7 * 24 * 60 * 60 * 1000,
  ).getTime();
  // close modal on press of Esc key
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape" && isModalOpen) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (getAduitLogQuery.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (getAduitLogQuery.data) {
      if (getAduitLogQuery.data?.getCustomerDetailsAudit) {
        const records = getAduitLogQuery.data.getCustomerDetailsAudit;
        const userIds = records.map((record) => {
          if (!!auditUserInfo[record.userId]) {
            return;
          } else {
            return record.userId;
          }
        });
        if (userIds.length > 0) {
          getAuditUsersQuery.fetchData({
            filters: {
              id: userIds,
            },
          });
        }
        if (page === 1) setAuditData(records);
        setmodalAuditData((prevLogs) => [...prevLogs, ...records]);
        setHasMore(records?.length > 0); // If no more logs are returned, set hasMore to false
      }
      // ADD that logic here:
    }

    if (getAduitLogQuery.error) {
      appState.setAlert(
        getAduitLogQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [getAduitLogQuery.loading, getAduitLogQuery.data, getAduitLogQuery.error]);

  useEffect(() => {
    if (getAuditUsersQuery.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (getAuditUsersQuery.data?.users?.entities) {
      setAuditUserInfo((prev) => {
        let updatedUserInfo = { ...prev };
        getAuditUsersQuery.data.users.entities.forEach((user) => {
          updatedUserInfo[user.id] = user;
        });
        return updatedUserInfo;
      });
    }

    if (getAuditUsersQuery.error) {
      appState.setAlert(
        getAuditUsersQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [
    getAuditUsersQuery.loading,
    getAuditUsersQuery.data,
    getAuditUsersQuery.error,
  ]);

  // api call to fetch audit logs
  const fetchAuditLogs = (page) => {
    // Simulate an API request with pagination (replace with actual API call)
    getAduitLogQuery.fetchData({
      page,
      limit: 10,
      filter: {
        createdAfter: LAST_7_DAY_TIME,
      },
    });
  };

  useEffect(() => {
    // Fetch logs when the page changes
    fetchAuditLogs(page);
  }, [page]);

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <h1 className="mb-2 text-2xl font-bold">
          Audit History from Last 7 Days
        </h1>
        <p className="mb-4 text-sm text-gray-600">
          View a history of activity conducted on customer records from Hopstack
        </p>
        <AuditLogTable
          auditUserInfo={auditUserInfo}
          auditData={auditData}
          loading={loading}
          hasMore={hasMore}
          setPage={setPage}
          inModal={false}
        />

        <div className="mt-4 text-center">
          <a
            onClick={openModal}
            className="text-sm text-primaryAccent hover:underline">
            Click to show all audit logs
          </a>
        </div>
      </div>
      <SlideOverModal
        open={isModalOpen}
        onClose={closeModal}
        title={"Customer Details > Audit History from Last 7 Days"}>
        <div className="container mx-auto px-1">
          <p className="mb-4 text-sm text-gray-600">
            View a history of activity conducted on customer records from
            Hopstack. Press Esc to return to Search Screen.
          </p>
          <div className="px-4">
            <AuditLogTable
              auditUserInfo={auditUserInfo}
              auditData={modalAuditData}
              loading={loading}
              hasMore={hasMore}
              setPage={setPage}
              inModal={true}
            />
          </div>
        </div>
      </SlideOverModal>
    </>
  );
};

export default AuditHistory;
