import TextField from "#components/utils/TextField";
import Modal from "#components/utils/Modal";
import AddButton from "#components/utils/AddButton";
import Select from "react-select";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";

const ImportFbaShipment = ({
  shipmentPlan,
  negativeAction,
  onSubmit,
  shipmentImportSearch,
  setShipmentImportSearch,
  onImportShipmentSearch,
  warehouses,
  customers,
  importFilters,
  onChangeDropdown,
  multiAccountSupportEnabled,
  marketplaces,
  sellerIds,
}) => {
  let pickupAddress = shipmentPlan?.ShipFromAddress;

  return (
    <Modal
      title={"Import Shipment"}
      onClose={negativeAction}
      positiveText={"Import Shipment"}
      negativeText={"Cancel"}
      positiveAction={onSubmit}
      negativeAction={negativeAction}>
      <div className="mb-10 flex w-1/2 min-w-1/2 items-center gap-20 p-5">
        {warehouses && warehouses.length > 1 && (
          <div className="w-50">
            <Select
              options={
                warehouses &&
                warehouses
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => ({
                    value: item.id,
                    label: `${item.name} - ${item.code}`,
                  }))
              }
              value={importFilters?.warehouse}
              onChange={(selectedOption) => {
                onChangeDropdown("warehouse", selectedOption);
              }}
              className="w-96"
              placeholder="Select Warehouse"
              isClearable={true}
            />
          </div>
        )}
        {customers && customers.length > 1 && (
          <div className="w-50">
            <Select
              options={
                customers &&
                customers
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => ({
                    value: item.id,
                    label: `${item.name} - ${item.code}`,
                  }))
              }
              value={importFilters?.customer}
              onChange={(selectedOption) => {
                onChangeDropdown("customer", selectedOption);
              }}
              className="w-96"
              placeholder="Select Client"
              isClearable={true}
            />
          </div>
        )}
      </div>
      {multiAccountSupportEnabled &&
        marketplaces?.length > 0 &&
        sellerIds?.length > 0 && (
          <div className="mb-10 flex w-1/2 min-w-1/2 items-center gap-20 p-5">
            <div className="w-50">
              <Select
                options={marketplaces
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => ({
                    value: item,
                    label: item,
                  }))}
                value={importFilters?.marketplace}
                onChange={(selectedOption) => {
                  onChangeDropdown("marketplace", selectedOption);
                }}
                className="w-96"
                placeholder="Select Marketplace"
                isClearable={true}
              />
            </div>
            <div className="w-50">
              <Select
                options={sellerIds
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => ({
                    value: item,
                    label: item,
                  }))}
                value={importFilters?.sellerId}
                onChange={(selectedOption) => {
                  onChangeDropdown("sellerId", selectedOption);
                }}
                className="w-96"
                placeholder="Select Seller"
                isClearable={true}
              />
            </div>
          </div>
        )}
      <div className="flex items-center justify-center gap-4">
        <div className="min-w-3/4">
          <TextField
            type="text"
            id="shipmentID"
            placeholder="Enter Shipment ID"
            value={shipmentImportSearch}
            name="shipmentID"
            onChange={(item) => setShipmentImportSearch(item.target.value)}
          />
        </div>
        <div>
          <AddButton
            styles={["rounded-md"]}
            text="Search"
            onClick={onImportShipmentSearch}
          />
        </div>
      </div>
      {shipmentPlan && (
        <div>
          <div className="p-4 pl-2 font-montserrat text-xl font-bold">
            {"Shipment Details:  " + shipmentPlan.ShipmentId}
          </div>
          <div className="flex flex-col gap-4 p-4">
            <LineItemValue
              value={shipmentPlan.ShipmentName}
              title="Shipment Name"
              idx={0}
            />
            <LineItemValue
              value={shipmentPlan.DestinationFulfillmentCenterId}
              title="Fulfillment Center ID"
              idx={1}
            />
            <LineItemValue
              value={shipmentPlan.LabelPrepType}
              title="Label Prep Type"
              idx={2}
            />
            <LineItemValue
              value={shipmentPlan.ShipmentStatus}
              title="Shipment Status"
              idx={2}
            />
            {pickupAddress && (
              <LineItemValue
                value={Object.values(pickupAddress)
                  ?.filter(Boolean)
                  ?.join(", ")}
                title="Shipment Pickup Address"
                idx={3}
              />
            )}
            {shipmentPlan?.Items?.length > 0 ? (
              <div>
                <div className="p-4 pl-2 font-montserrat text-xl font-bold">
                  Products:
                </div>
                <OrderLineItems items={shipmentPlan.Items} />
              </div>
            ) : (
              "No items available."
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

const OrderLineItems = ({ items }) => {
  const headers = ["Seller SKU", "FN SKU", "Quantity"];
  return (
    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
      <table className="mb-4 divide-y divide-gray-200 border border-gray-400 px-2">
        <thead className="w-full rounded-full bg-primaryAccent p-4">
          <tr className="border-left px-12 font-montserrat text-textWhite">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="w-full px-2 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="w-full px-2 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr
              key={item.SellerSKU}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {item.SellerSKU}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {item.FulfillmentNetworkSKU}
              </td>

              <td className="w-10 rounded-br rounded-tr px-2 py-1 pl-4 text-center font-medium tracking-wider text-5F666B">
                {item.QuantityShipped}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="m-auto flex w-full max-w-4xl space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className={`flex-1 rounded-br-md rounded-tr-md border bg-white p-4`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

export default ImportFbaShipment;
