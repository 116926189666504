import React from "react";
import {
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";

const VendorClassification = ({ classification }) => {
  return (
    <div className="rounded-md bg-blue-50 p-2">
      <div className="flex items-center">
        <span className="text-sm text-gray-500">Vendor Classification</span>
      </div>
      <div className="mt-1 flex items-center">
        <span className="text-base font-semibold text-black">
          {classification}
        </span>
        <InformationCircleIcon className="ml-1 h-5 w-5 text-gray-400" />
      </div>
      <div className="mt-1 flex items-center">
        <ExclamationIcon className="mr-1 h-5 w-5 text-yellow-500" />
        <span className="text-sm text-gray-500">
          The PO will undergo additional approvals.
        </span>
      </div>
    </div>
  );
};

export default VendorClassification;
