import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import Toggle from "#components/utils/Toggle";
import { useCodeSuggestion } from "#hooks/useCodeSuggestion";
import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";

const CustomerForm = ({
  onClose,
  title,
  warehouses,
  onChange,
  onChangeMultiSelect,
  selectedCustomer,
  setSelectedCustomer,
  customers,
  onSubmit,
  fba2dBarcodeEnabled,
}) => {
  const [codesList, setCodesList] = useState([]);
  const {
    code,
    error,
    usingGeneratedCode,
    setUsingGeneratedCode,
    handleNameChange,
  } = useCodeSuggestion();

  useEffect(() => {
    setCodesList(customers.map((customer) => customer.code));
  }, [customers]);

  useEffect(() => {
    if (usingGeneratedCode) {
      setSelectedCustomer({
        ...selectedCustomer,
        code: error?.error ? "" : code,
      });
    }
  }, [code]);

  useEffect(() => {
    if (selectedCustomer.name == "") {
      setSelectedCustomer({ ...selectedCustomer, code: "" });
    }
    if (selectedCustomer.id) {
      setUsingGeneratedCode(false);
    }
  }, [selectedCustomer.name, selectedCustomer.id]);

  const handleOnChangeForName = (e) => {
    !selectedCustomer?.id && handleNameChange(e.target.value, codesList);
    onChange(e);
  };
  const handleOnChangeForCode = (e) => {
    setUsingGeneratedCode(false);
    onChange(e);
  };
  return (
    <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Client Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="name"
            label="Name"
            placeholder="Name"
            onChange={handleOnChangeForName}
            value={selectedCustomer.name}
          />
        </div>
        <div>
          <Tooltip title={"0-9 digits, A-Z letters are allowed."}>
            <TextField
              type="text"
              id="code"
              label="Code"
              placeholder=" "
              onChange={handleOnChangeForCode}
              value={
                usingGeneratedCode
                  ? error?.error
                    ? ""
                    : code
                  : selectedCustomer.code?.toUpperCase()
              }
            />
          </Tooltip>
        </div>
        {usingGeneratedCode && !(error?.error || code == "") && (
          <div className="text-sm italic">
            *This is a generated code. You can still edit it.
          </div>
        )}
        <div>
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Select Warehouses
            </span>
          </label>
          <MultiSelectAutoComplete
            options={warehouses}
            labelKey="name"
            valueKey="id"
            setValues={(e) => onChangeMultiSelect("warehouses", e)}
            values={selectedCustomer.warehouses || []}
          />
        </div>
        <div>
          <RoundedDropdown
            placeholder={"Billing Currency"}
            list={[
              { name: "USD", symbol: "$" },
              { name: "MYR", symbol: "RM" },
              { name: "INR", symbol: "₹" },
              { name: "SAR", symbol: "SAR" },
            ]}
            labelKey="name"
            valueKey="symbol"
            name="currency"
            selectedValue={selectedCustomer.currency}
            setSelected={(e) =>
              onChange({
                target: {
                  name: "currency",
                  value: e,
                },
              })
            }
          />
        </div>
        <div className="flex items-center space-x-4 px-2">
          <div>
            <Toggle
              enabled={selectedCustomer.active}
              setEnabled={(e) =>
                onChange({
                  target: {
                    name: "active",
                    value: e,
                  },
                })
              }
            />
          </div>
          <div className="text-lg">Active</div>
        </div>
        {fba2dBarcodeEnabled && (
          <div className="flex items-center space-x-4 px-2">
            <div>
              <Toggle
                enabled={
                  selectedCustomer.settings?.fba2dBarcodeEnabled === true
                }
                setEnabled={(e) =>
                  onChange({
                    target: {
                      name: "settings.fba2dBarcodeEnabled",
                      value: e,
                    },
                  })
                }
              />
            </div>
            <div className="text-lg">2D Barcodes</div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CustomerForm;
