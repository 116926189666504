import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ tabs, extra, onChange }) => {
  return (
    <div className="w-full">
      <Tab.Group onChange={onChange}>
        <Tab.List className="flex items-center">
          <div className="flex-1 space-x-4 rounded-xl p-1 px-0">
            {tabs &&
              Object.keys(tabs).map((tab) => (
                <Tab
                  key={tab}
                  className={({ selected }) =>
                    classNames(
                      "font-regular mb-2.5 rounded-full px-6 py-2.5 text-sm leading-5",
                      "font-montserrat focus:outline-none focus:ring-0",
                      selected
                        ? "bg-primaryAccent text-white"
                        : "rounded-full border border-primaryAccent bg-transparent text-primaryAccent",
                    )
                  }>
                  {tab}
                </Tab>
              ))}
          </div>
          <div className="items-center justify-end">{extra}</div>
        </Tab.List>
        <Tab.Panels className="mt-2">
          {tabs &&
            Object.values(tabs).map((tabInner, idx) => (
              <Tab.Panel
                key={idx}
                className="textWhite-300 mt-4 flex flex-col border bg-EBEBEB">
                {tabInner}
              </Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Tabs;
