import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useQuery } from "#hooks/useQuery";
import {
  GET_BILLING_PROFILES,
  GET_BILLING_PROFILE,
  GET_BILLING_PROFILE_FORM_CONFIG,
  GET_AUDIT,
} from "#queries";
import { SAVE_BILLING_PROFILE, DELETE_BILLING_PROFILE } from "#mutations";
import _, { isArray } from "lodash";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { MasterDataContext } from "#contexts/masterData";

const withBillingProfileLogic = (WrappedComponent) => {
  return (props) => {
    const masterData = useContext(MasterDataContext);
    const appState = useContext(AppStateContext);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const billingProfilesQuery = useQuery(GET_BILLING_PROFILES);
    const billingProfileFormConfigQuery = useQuery(
      GET_BILLING_PROFILE_FORM_CONFIG,
    );
    const billingProfileQuery = useQuery(GET_BILLING_PROFILE);
    const saveBillingProfileQuery = useQuery(SAVE_BILLING_PROFILE);
    const deleteBillingProfileQuery = useQuery(DELETE_BILLING_PROFILE);
    const getAuditQuery = useQuery(GET_AUDIT);
    const [selectedPaymentLineItem, setSelectPaymentLineItem] = useState(null);
    const [showProfileSummary, setShowProfileSummary] = useState(false);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [addCustomEntity, setAddCustomEntity] = useState(null);
    const [selectedProfile, setSelectedProfile] = useState(null);

    const [selectedBillingProfileCharges, setSelectedBillingProfileCharges] =
      useState(null);

    useEffect(() => {
      if (auth.user?.id) {
        billingProfilesQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
        });
        billingProfileFormConfigQuery.fetchData();
      }

      return () => {
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      if (
        billingProfilesQuery.data &&
        billingProfilesQuery.data.billingProfiles
      ) {
        entity.setEntities({
          ...billingProfilesQuery.data.billingProfiles.meta,
          ...billingProfilesQuery.variables,
          entities: billingProfilesQuery.data?.billingProfiles?.data,
        });
      }
    }, [
      billingProfilesQuery.loading,
      billingProfilesQuery.error,
      billingProfilesQuery.data,
    ]);

    useEffect(() => {
      if (saveBillingProfileQuery.data) {
        appState.setAlert(
          saveBillingProfileQuery.data.saveBillingProfile.message,
          "success",
          5000,
        );
        setSelectedProfile(null);
        billingProfilesQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
        });
      }
      if (saveBillingProfileQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveBillingProfileQuery.error) {
        if (
          saveBillingProfileQuery.error.errors &&
          saveBillingProfileQuery.error.errors.length > 0
        ) {
          appState.setAlert(
            saveBillingProfileQuery.error.errors[0].message,
            "error",
            5000,
          );
        } else {
          appState.setAlert(
            saveBillingProfileQuery.error.message,
            "error",
            5000,
          );
        }
      }
    }, [
      saveBillingProfileQuery.loading,
      saveBillingProfileQuery.data,
      saveBillingProfileQuery.error,
    ]);

    useEffect(() => {
      if (getAuditQuery.data) {
        setSelectedAudit(getAuditQuery.data?.getAudit);
      }
      if (getAuditQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteBillingProfileQuery.error) {
        appState.setAlert(
          deleteBillingProfileQuery.error.message,
          "error",
          5000,
        );
        appState.hideConfirmation();
      }
    }, [getAuditQuery.loading, getAuditQuery.data, getAuditQuery.error]);

    useEffect(() => {
      if (deleteBillingProfileQuery.data) {
        appState.hideConfirmation();
        billingProfilesQuery.fetchData();
      }
      if (deleteBillingProfileQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (deleteBillingProfileQuery.error) {
        appState.setAlert(
          deleteBillingProfileQuery.error.message,
          "error",
          5000,
        );
        appState.hideConfirmation();
      }
    }, [
      deleteBillingProfileQuery.loading,
      deleteBillingProfileQuery.data,
      deleteBillingProfileQuery.error,
    ]);

    const deleteBillingProfile = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this billing profile?",
        () => {
          deleteBillingProfileQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      console.log(entity.entities, entity.pageNumber, entity.perPage);
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return billingProfilesQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        {...props}
        masterData={masterData}
        billingProfiles={entity.displayEntities}
        selectedProfile={selectedProfile}
        setSelectedProfile={setSelectedProfile}
        selectedAudit={selectedAudit}
        setSelectedAudit={setSelectedAudit}
        fetchAudits={(id) =>
          getAuditQuery.fetchData({
            id,
          })
        }
        total={entity.total}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          billingProfilesQuery.fetchData({
            perPage,
            pageNumber: 1,
          });
        }}
        pageNumber={entity.pageNumber}
        fetchBillingProfiles={(params) => {
          entity.setFilters({ ...entity.filters, params });
          billingProfilesQuery.fetchData({
            ...params,
            perPage: entity.perPage,
            pageNumber: entity.pageNumber,
          });
        }}
        billingProfileFormConfig={
          billingProfileFormConfigQuery.data?.billingProfileFormConfig
        }
        fetchBillingProfile={(billingProfile) =>
          billingProfileQuery.fetchData({
            id: billingProfile.id,
          })
        }
        deleteBillingProfile={deleteBillingProfile}
        selectedPaymentLineItem={selectedPaymentLineItem}
        setSelectPaymentLineItem={(e) => {
          setSelectPaymentLineItem(e);
        }}
        saveBillingProfile={(billingProfile) => {
          billingProfile.tariffs = billingProfile.tariffs?.map((tariff) => {
            delete tariff.id;
            return tariff;
          });
          saveBillingProfileQuery.fetchData(billingProfile);
        }}
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          billingProfilesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showProfileSummary={showProfileSummary}
        setShowProfileSummary={setShowProfileSummary}
        subdomain={appState.subdomain}
        role={auth?.user?.role}
        selectedBillingProfileCharges={selectedBillingProfileCharges}
        setSelectedBillingProfileCharges={setSelectedBillingProfileCharges}
      />
    );
  };
};

export default withBillingProfileLogic;
