import withOutboundExceptionsLogic from "#components/HOC/withOutboundExceptionsLogic";
import PageTitle from "#components/utils/PageTitle";
import AuditLog from "#components/orders/AuditLog";
import ExpandedOrder from "#components/orders/expandedOrder/ExpandedOrder";
import _ from "lodash";
import Pagination from "#components/common/Pagination";
import OrderFilters from "#components/orders/OrderFilters";
import OrdersList from "#components/orders/OrdersList";
import AssignWarehouseForm from "#components/orders/AssignWarehouseForm";
import OrderFormWizard from "#components/orders/OrderFormWizard";
import ResolveExceptions from "#components/orders/exceptions/ResolveExceptions";

const OutboundExceptions = ({
  masterData,
  onChangeFilter,
  filters,
  loading,
  selectedAudit,
  setSelectedAudit,
  getAudit,
  selectOrder,
  selectedOrders,
  clearSelectedOrders,
  quickCreateManualBatch,
  simulateAutoBatching,
  calculateShippingRates,
  setSelectedRates,
  total,
  pageNumber,
  checkPagination,
  perPage,
  displayOrders,
  expandOrder,
  expandedOrderInfo,
  setExpandedOrderInfo,
  setShowCombinedOrders,
  setPerPage,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  sort,
  setSort,
  showFilters,
  setShowFilters,
  clearFilters,
  runAutoBatching,
  createManualBatch,
  customers,
  warehouses,
  printShippingLabel,
  downloadShippingLabel,
  markCompleted,
  markPartialCompleted,
  markUnprocessed,
  markCancelled,
  cancelOrders,
  deleteOrder,
  markInProcess,
  tryResolve,
  writable,
  selectedOrderLineItem,
  setSelectedOrderLineItem,
  updateOrderLineItem,
  onChangeSelectedOrderLineItem,
  expandedException,
  expandException,
  setExpandedException,
  selectAllRows,
  allRowsSelected,
  handleAssignWarehouse,
  assignWarehouse,
  selectedWarehouse,
  setSelectedWarehouse,
  cannotChangeOrderWarehouse,
  tenant,
  availableWarehousesForChange,
  handleOrderDataSubmission,
  selectedOrder,
  setSelectedOrder,
  editOrder,
  nonEditableFieldsOfOrder,
  isOrderBeingEdited,
  setOrderBeingEdited,
  onChange,
  onChangeDropdown,
  addItem,
  removeItem,
  onChangeItem,
  availableInventory,
  onChangeInventorySearch,
  deliveryInfo,
  setDeliveryInfo,
  saveDeliveryInfo,
  removeDelivery,
  getMarketplaceProducts,
  carrierList,
  carrierServiceList,
  shipstationList,
  eHubList,
  tryMassResolveExceptions,
  possibleSubStatuses,
  showOnlySelected,
  setShowOnlySelected,
  fetchProductSkuBinMappings,
  productSkuBinMappings,
  lotIdSelectionEnabled,
  dataTableFilters,
  lpnSelectionEnabled,
  productSkuBinMappingsWithLpn,
  isBackOrderEnabledForTenant,
  ordersFulfillmentDetails,
  setOrdersFulfillmentDetails,
  printOrder,
  searchFilters,
  fetchEnhancedSearchResults,
  searchOrdersLoading,
  searchResults,
  setSearchResults,
  totalResults,
  currentPage,
  setCurrentPage,
  bulkMarkCompleted,
  bulkOrdersToMarkCancelled,
  setBulkOrdersToMarkCancelled,
  markOrdersCompleted,
  setBulkOrdersToMarkComplete,
  bulkOrdersToMarkComplete,
  bulkMarkCancelled,
  markOrdersCancelled,
}) => {
  return (
    <div className="p-5">
      <div className="flex items-center">
        <div className="mb-20 w-full">
          <PageTitle>Outbound Exceptions</PageTitle>
          <OrdersList
            bulkOrdersToMarkCancelled={bulkOrdersToMarkCancelled}
            setBulkOrdersToMarkCancelled={setBulkOrdersToMarkCancelled}
            bulkMarkCompleted={bulkMarkCompleted}
            markOrdersCompleted={markOrdersCompleted}
            bulkOrdersToMarkComplete={bulkOrdersToMarkComplete}
            setBulkOrdersToMarkComplete={setBulkOrdersToMarkComplete}
            bulkMarkCancelled={bulkMarkCancelled}
            markOrdersCancelled={markOrdersCancelled}
            outboundExceptions={true}
            customers={customers}
            orders={displayOrders}
            masterData={masterData}
            onChangeFilter={onChangeFilter}
            filters={filters}
            loading={loading}
            getAudit={getAudit}
            selectOrder={selectOrder}
            selectedOrders={selectedOrders}
            clearSelectedOrders={clearSelectedOrders}
            quickCreateManualBatch={quickCreateManualBatch}
            createManualBatch={createManualBatch}
            simulateAutoBatching={simulateAutoBatching}
            calculateShippingRates={calculateShippingRates}
            setSelectedRates={setSelectedRates}
            runCombinedOrders={() => setShowCombinedOrders(true)}
            expandOrder={expandOrder}
            onChangeSearchKeyword={onChangeSearchKeyword}
            submitFilters={submitFilters}
            clearKeyword={clearKeyword}
            sort={sort}
            setSort={setSort}
            setShowFilters={setShowFilters}
            clearFilters={clearFilters}
            runAutoBatching={runAutoBatching}
            printShippingLabel={printShippingLabel}
            downloadShippingLabel={downloadShippingLabel}
            markInProcess={markInProcess}
            markUnprocessed={markUnprocessed}
            markCompleted={markCompleted}
            markCancelled={markCancelled}
            cancelOrders={cancelOrders}
            deleteOrder={deleteOrder}
            markPartialCompleted={markPartialCompleted}
            tryResolve={tryResolve}
            writable={writable}
            expandedException={expandedException}
            expandException={expandException}
            setExpandedException={setExpandedException}
            selectAllRows={selectAllRows}
            allRowsSelected={allRowsSelected}
            handleAssignWarehouse={handleAssignWarehouse}
            tenant={tenant}
            editOrder={editOrder}
            tryMassResolveExceptions={tryMassResolveExceptions}
            possibleSubStatuses={possibleSubStatuses}
            showOnlySelected={showOnlySelected}
            setShowOnlySelected={setShowOnlySelected}
            total={total}
            warehouses={warehouses}
            dataTableFilters={dataTableFilters}
            printOrder={printOrder}
            perPage={perPage}
            searchFilters={searchFilters}
            fetchEnhancedSearchResults={fetchEnhancedSearchResults}
            searchOrdersLoading={searchOrdersLoading}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            totalResults={totalResults}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          {selectedWarehouse && (
            <AssignWarehouseForm
              onClose={() => setSelectedWarehouse(null)}
              title={"Assign Warehouse"}
              onSubmit={assignWarehouse}
              warehouses={availableWarehousesForChange}
              setSelectedWarehouse={setSelectedWarehouse}
              selectedWarehouse={selectedWarehouse}
              cannotChangeOrderWarehouse={cannotChangeOrderWarehouse}
            />
          )}
        </div>
      </div>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
      {showFilters && (
        <OrderFilters
          negativeAction={() => setShowFilters(false)}
          masterData={masterData}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          warehouses={warehouses}
          outboundExceptions={true}
          clearFilters={clearFilters}
          possibleSubStatuses={possibleSubStatuses}
          tenant={tenant}
        />
      )}

      {isOrderBeingEdited && (
        <OrderFormWizard
          selectedOrder={selectedOrder}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title="Add Order"
          onClose={() => {
            setSelectedOrder(null);
            setOrderBeingEdited(false);
          }}
          onSubmit={handleOrderDataSubmission}
          customers={customers}
          warehouses={warehouses}
          addItem={addItem}
          removeItem={removeItem}
          onChangeItem={onChangeItem}
          availableInventory={availableInventory}
          onChangeInventorySearch={onChangeInventorySearch}
          deliveryInfo={deliveryInfo}
          setDeliveryInfo={setDeliveryInfo}
          saveDeliveryInfo={saveDeliveryInfo}
          removeDelivery={removeDelivery}
          getMarketplaceProducts={getMarketplaceProducts}
          carrierList={carrierList}
          carrierServiceList={carrierServiceList}
          tenant={tenant}
          shipstationList={shipstationList}
          eHubList={eHubList}
          setSelectedOrder={setSelectedOrder}
          nonEditableFieldsOfOrder={
            isOrderBeingEdited ? nonEditableFieldsOfOrder : []
          }
          isOrderBeingEdited={isOrderBeingEdited}
          fetchProductSkuBinMappings={fetchProductSkuBinMappings}
          productSkuBinMappings={productSkuBinMappings}
          lotIdSelectionEnabled={lotIdSelectionEnabled}
          lpnSelectionEnabled={lpnSelectionEnabled}
          productSkuBinMappingsWithLpn={productSkuBinMappingsWithLpn}
        />
      )}

      {ordersFulfillmentDetails && (
        <ResolveExceptions
          negativeAction={() => setOrdersFulfillmentDetails(null)}
          tryMassResolveExceptions={tryMassResolveExceptions}
          tryResolve={tryResolve}
          orders={displayOrders}
          selectedOrderIds={selectedOrders}
          ordersFulfillmentDetails={ordersFulfillmentDetails}
          isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
        />
      )}

      {expandedOrderInfo && (
        <ExpandedOrder
          writable={writable}
          expandedOrderInfo={expandedOrderInfo}
          negativeAction={() => setExpandedOrderInfo(null)}
          selectedOrderLineItem={selectedOrderLineItem}
          setSelectedOrderLineItem={setSelectedOrderLineItem}
          updateOrderLineItem={updateOrderLineItem}
          onChangeSelectedOrderLineItem={onChangeSelectedOrderLineItem}
          customers={customers}
          warehouses={warehouses}
          tenant={tenant}
          getAudit={getAudit}
          showOnlyAudit={true}
          cancelOrders={cancelOrders}
          markCompleted={markCompleted}
        />
      )}

      {selectedAudit && (
        <AuditLog
          selectedAudit={selectedAudit}
          negativeAction={() => setSelectedAudit(null)}
        />
      )}
    </div>
  );
};

export default withOutboundExceptionsLogic(OutboundExceptions);
