import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import React, { useState } from "react";

const TariffAccordion = ({
  title,
  subtitle,
  icon,
  isActive = false,
  scrollBehavior = "overflow-y-auto",
  style = {},
  children,
}) => {
  const [active, setActive] = useState(isActive);

  return (
    <div
      className={`mb-4 w-full rounded-md border border-[#DDDFE0] p-2 duration-500`}>
      <div
        className={`flex cursor-pointer justify-between ${
          active ? "pb-2" : ""
        }`}
        onClick={() => setActive(!active)}>
        <div>
          <div className="flex gap-2">
            <img src={icon} className="mx-1 my-auto h-10 w-10" />
            <div>
              <div className="text-lg font-semibold text-primaryAccent">
                {title}
              </div>
              <div className="text-md text-[#717679]">{subtitle}</div>
            </div>
          </div>
        </div>
        <div className="my-auto mr-2 cursor-pointer text-xl">
          {active ? (
            <ChevronUpIcon className="h-6 w-6" />
          ) : (
            <ChevronDownIcon className="h-6 w-6" />
          )}
        </div>
      </div>
      {active && (
        <div
          className={`pb-2 pt-2 ${scrollBehavior}`}
          style={{ minHeight: "100%", maxHeight: "100%", ...style }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default TariffAccordion;
