import TextField from "#components/utils/TextField";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { useState } from "react";
import passwordStrengthValidator from "#utils/passwordStrengthValidator";

const NewPasswordBox = ({ onSubmit, onChange, values, isExpired }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const renderPasswordStrengthCriteria = () => {
    const returnArr = [];

    returnArr.push(
      <>
        <span
          className={`${passwordStrengthValidator.testPasswordLength(
            newPassword,
          )} text-lg`}>
          Password should be a minimum of 8 characters
        </span>{" "}
        <br />
      </>,
    );
    returnArr.push(
      <>
        <span
          className={`${passwordStrengthValidator.testSequentialPassword(
            newPassword,
          )} text-lg`}>
          Password can't be all the same letter or number.
        </span>{" "}
        <br />
      </>,
    );
    returnArr.push(
      <>
        <span
          className={`${passwordStrengthValidator.testPasswordMatch(
            newPassword,
            values.password,
          )} text-lg`}>
          Passwords must match
        </span>{" "}
        <br />
      </>,
    );

    return returnArr;
  };

  return (
    <div className="xs:10/12 md:6/12 min-w-xl flex max-w-xl flex-col items-center justify-center space-y-6 rounded-2xl bg-white p-10 sm:w-8/12">
      <div className="text-2xl font-medium text-primaryAccent">
        Reset Password
      </div>
      {isExpired ? (
        <div className="text-xl font-medium text-454A4F">
          Oops! This link is either invalid or expired. Please contact admin.
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            passwordStrengthValidator.testPassword(
              newPassword,
              values.password,
            ) && onSubmit(e);
          }}
          className="w-full flex-col items-center justify-center space-y-6"
          noValidate>
          <div className="flex gap-2">
            <TextField
              label="Password"
              id="newPassword"
              type={showPassword ? "text" : "password"}
              placeholder=" "
              required
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <div className="flex items-center justify-center">
              {showPassword ? (
                <EyeIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="flex gap-2">
            <TextField
              label="Confirm Password"
              id="password"
              type={showConfirmPassword ? "text" : "password"}
              placeholder=" "
              required
              onChange={onChange}
            />
            <div className="flex items-center justify-center">
              {showConfirmPassword ? (
                <EyeIcon
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="mt-2">{renderPasswordStrengthCriteria()}</div>
          <div className="flex w-full items-center justify-center">
            <input
              type="submit"
              className={`inline-flex w-64 cursor-pointer justify-center rounded-md border border-transparent bg-primaryAccent p-4 font-montserrat text-2xl font-normal text-textWhite outline-none ring-0 focus:outline-none focus:ring-0`}
              value="Submit"
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default NewPasswordBox;
