const TextInput = ({
  placeholder,
  styles,
  width,
  name,
  onChange,
  value,
  type,
  min,
  max,
  step,
  disabled,
}) => {
  return (
    <div>
      <input
        name={name}
        onChange={onChange}
        value={value}
        type={type || "text"}
        placeholder={placeholder}
        className={`rounded border-gray-300 outline-none ${
          width || ""
        } ${styles} `}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
      />
    </div>
  );
};

export default TextInput;
