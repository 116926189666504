import React, { useEffect, useState } from "react";

const TIME_DELAY_FOR_ANIMATION = 300; //30 seconds
const TIME_AFTER_NOTIFICATION_IS_DISPLAYED = 4500; //This is because if an orders is sucessfully added manually, a confirmation shows up, whose durations on screen is 4 seconds//
const TIME_AFTER_NOTIFICATION_IS_CLOSED = 15000; //15 seconds after the notification is rendered, it is closed in instances where the user dosent interact with it//
const NotificationDisplay = ({ message, onClose, refreshNowLink }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let showTimer;
    let autoCloseTimer;

    showTimer = setTimeout(() => {
      setIsVisible(true);
      autoCloseTimer = setTimeout(() => {
        setIsVisible(false);
        setTimeout(onClose, TIME_DELAY_FOR_ANIMATION); // Delay calling onClose to allow for animation
      }, TIME_AFTER_NOTIFICATION_IS_CLOSED); // Auto close after 15 seconds
    }, TIME_AFTER_NOTIFICATION_IS_DISPLAYED);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(autoCloseTimer);
    };
  }, [onClose]);

  const handleClose = (event) => {
    event.stopPropagation(); // Prevent the event from bubbling up
    setIsVisible(false);
    setTimeout(onClose, TIME_DELAY_FOR_ANIMATION); // Delay calling onClose to allow for animation
  };

  return (
    <div
      className={`fixed left-1/2 top-0 z-50 flex transform items-center gap-2.5 whitespace-nowrap rounded-md border border-blue-500 bg-blue-100 px-4 py-3.5 text-gray-800 shadow-md transition-transform duration-300 ease-in-out ${
        isVisible
          ? "-translate-x-1/2 translate-y-0"
          : "-translate-x-1/2 -translate-y-full"
      }`}>
      <div className="flex-1">
        {message}
        {refreshNowLink && (
          <span
            onClick={refreshNowLink.onClick}
            className="ml-2.5 cursor-pointer text-blue-500 underline">
            {refreshNowLink.text}
          </span>
        )}
        <span> to view </span>
      </div>
      <button
        className="ml-2.5 cursor-pointer border-none bg-none text-2xl leading-none text-gray-800"
        onClick={handleClose}>
        &times;
      </button>
    </div>
  );
};

export default NotificationDisplay;
