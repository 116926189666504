import withInventoryLedgerLogic from "#components/HOC/withInventoryLedgerLogic";
import PageTitle from "#components/utils/PageTitle";
import InventoryLedgerList from "#components/inventory/InventoryLedgerList";
import ExpandedSku from "#components/inventory/ExpandedSku";
import Pagination from "#components/common/Pagination";
import EditReceivedSkuForm from "../components/receivedSkus/EditReceivedSkuForm";
import ProductForm from "#components/products/ProductForm";
import Modal from "#components/utils/Modal";
import ExpandedEligibleProducts from "#components/inventory/ExpandedEligibleProducts";
import InventoryFilters from "#components/inventory/InventoryFilters";
import VendorReturnForm from "../components/inventory/VendorReturnForm";
import InventoryExplanation from "#components/inventory/InventoryExplanation";
import Toggle from "#components/utils/Toggle";
import ChannelAllocation from "#components/inventory/ChannelAllocation";
import ExpandedEligibleBundles from "../components/inventory/ExpandedEligibleBundles";
import TransactionFilters from "../components/inventory/TransactionFilters";
import BinTransferEligibilityModal from "#components/skuBinMappings/BinTransferEligibilityModal";

// TODO: Rename the entire file and references to Stock Ledger

const Inventory = ({
  displayInventory,
  fetchedSku,
  setFetchedSku,
  unverifiedExists,
  showAllUnverifiedInventory,
  getSku,
  total,
  totalInWarehouse,
  totalAvailableToShip,
  totalDamaged,
  totalAllocated,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  sort,
  setSort,
  filters,
  onChangeSearchKeyword,
  submitFilters,
  isInventoryLoading,
  clearKeyword,
  showFilters,
  setShowFilters,
  clearFilters,
  getSpecificInventory,
  fetchedInventory,
  submitFetchedInventory,
  setFetchedInventory,
  customers,
  warehouses,
  verifyProduct,
  onChangeFilter,
  selectedProduct,
  setSelectedProduct,
  saveProduct,
  onChange,
  onChangeDropdown,
  showExportInventoryFieldSelector,
  setShowExportInventoryFieldSelector,
  inventoryExportFields,
  onSubmitInventoryExportFields,
  onChangeInventoryExportField,
  releaseInventoryForShipmentCreation,
  writable,
  eligibleProducts,
  setEligibleProducts,
  onChangeItem,
  onSubmitReleaseInventory,
  setVendorReturn,
  vendorReturn,
  inventorySearchKeyword,
  setInventorySearchKeyword,
  inventorySearchResults,
  onChangeVendorReturn,
  onChangeVendorReturnDropdown,
  submitVendorReturn,
  subdomain,
  explainInventory,
  inventoryExplanations,
  setInventoryExplanations,
  vendors,
  binLocationsOptions,
  debouncedBinLocationSearch,
  getProductForChannelAllocation,
  fetchedProductForChannelAllocation,
  setFetchedProductForChannelAllocation,
  warehousesForSelectedProductInAllocation,
  submitChannelAllocation,
  clientMarketplaceIntegrations,
  onChangeChannelProductSearch,
  channelProductSearchKeyword,
  setChannelProductSearchKeyword,
  marketplaceProducts,
  setMarketplaceProducts,
  addedChannelProducts,
  setAddedChannelProducts,
  stockChangeType,
  setStockChangeType,
  allocateBundle,
  eligibleBundles,
  setEligibleBundles,
  selectedBundleForAllocation,
  showBundleAllocationForm,
  onSubmitBundleAllocation,
  handleBundleSelection,
  shipmentConfiguration,
  setShipmentConfiguration,
  boxContentsOptions,
  packingMethodOptions,
  bundlesWithOverlap,
  setBundlesWithOverlap,
  multiAccountSupportEnabled,
  transactionFilters,
  setTransactionFilters,
  getTransactionHistoryReport,
  moveStock,
  transactionHistoryReportEnabled,
  addingChannel,
  setAddingChannel,
  checkTransferSkuBinMapping,
  binTransferEligibility,
  setBinTransferEligibility,
}) => {
  return (
    <>
      {unverifiedExists > 0 && (
        <div className="flex w-full justify-center gap-2 bg-[#FFEDD8] p-4">
          <svg
            className="my-auto"
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 9V11.6667M13 17H13.0134M3.76244 22.3333H22.2376C24.2904 22.3333 25.5735 20.1111 24.5471 18.3333L15.3094 2.33333C14.283 0.555556 11.717 0.555555 10.6906 2.33333L1.45304 18.3333C0.42664 20.1111 1.70964 22.3333 3.76244 22.3333Z"
              stroke="#D95D03"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="text-[#454748]">
            The fulfillment channel has changed for {unverifiedExists} of your
            products. These require your verification.
          </div>
          <button
            onClick={() => {
              showAllUnverifiedInventory();
            }}
            className="font-bold text-[#224E73] underline">
            Work on it
          </button>
        </div>
      )}
      <div className="w-full p-5">
        <div className="mb-4 flex items-center">
          <div className="flex-1">
            <PageTitle>Stock Ledger</PageTitle>
          </div>
        </div>

        <InventoryLedgerList
          inventory={displayInventory}
          isInventoryLoading={isInventoryLoading}
          unverifiedExists={unverifiedExists}
          noValuesText="No Inventory found"
          onChangeSearchKeyword={onChangeSearchKeyword}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setShowFilters={setShowFilters}
          clearFilters={clearFilters}
          sort={sort}
          setSort={setSort}
          verifyProduct={verifyProduct}
          filters={filters}
          getSku={getSku}
          getSpecificInventory={getSpecificInventory}
          customers={customers}
          warehouses={warehouses}
          onChangeFilter={onChangeFilter}
          clientMarketplaceIntegrations={clientMarketplaceIntegrations}
          releaseInventoryForShipmentCreation={
            releaseInventoryForShipmentCreation
          }
          allocateBundle={allocateBundle}
          initiateVendorReturn={setVendorReturn}
          writable={writable}
          subdomain={subdomain}
          explainInventory={explainInventory}
          totalInWarehouse={totalInWarehouse}
          totalAvailableToShip={totalAvailableToShip}
          totalAllocated={totalAllocated}
          totalDamaged={totalDamaged}
          total={total}
          getProductForChannelAllocation={getProductForChannelAllocation}
          moveStock={moveStock}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          setTransactionFilters={setTransactionFilters}
          transactionHistoryReportEnabled={transactionHistoryReportEnabled}
        />

        <Pagination
          showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
          showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
          showingTotal={total}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
          searchEnabled={true}
        />

        {vendorReturn && (
          <VendorReturnForm
            selectedVendorReturn={vendorReturn}
            onChange={onChangeVendorReturn}
            onChangeDropdown={onChangeVendorReturnDropdown}
            title="Initiate Vendor Return"
            onClose={() => setVendorReturn(null)}
            onSubmit={() => submitVendorReturn()}
            customers={customers}
            warehouses={warehouses}
            vendors={vendors}
            inventory={inventorySearchResults}
            inventorySearchKeyword={inventorySearchKeyword}
            setInventorySearchKeyword={setInventorySearchKeyword}
          />
        )}

        {showFilters && (
          <InventoryFilters
            negativeAction={() => setShowFilters(false)}
            onChangeFilter={onChangeFilter}
            filters={filters}
            customers={customers}
            warehouses={warehouses}
            onSubmit={submitFilters}
          />
        )}

        {transactionFilters && (
          <TransactionFilters
            transactionFilters={transactionFilters}
            setTransactionFilters={setTransactionFilters}
            onSubmit={getTransactionHistoryReport}
            negativeAction={() => setTransactionFilters(null)}
          />
        )}

        {showExportInventoryFieldSelector && (
          <Modal
            title={"Select Fields"}
            negativeAction={() => setShowExportInventoryFieldSelector(false)}
            positiveAction={onSubmitInventoryExportFields}>
            <div className="w-96">
              {inventoryExportFields.map((item) => (
                <div className="flex flex-row justify-between">
                  <label className="mt-4 w-1/2 text-left">{item.name}</label>
                  <Toggle
                    enabled={item.enabled}
                    disabled={!item.editable}
                    setEnabled={() =>
                      onChangeInventoryExportField(item.id, !item.enabled)
                    }
                  />
                </div>
              ))}
            </div>
          </Modal>
        )}

        {selectedProduct && (
          <ProductForm
            selectedProduct={selectedProduct}
            onChange={onChange}
            onChangeDropdown={onChangeDropdown}
            title="Add Product"
            onClose={() => setSelectedProduct(null)}
            onSubmit={saveProduct}
            customers={customers}
            warehouses={warehouses}
          />
        )}

        {eligibleProducts && (
          <ExpandedEligibleProducts
            eligibleProducts={eligibleProducts}
            negativeAction={() => setEligibleProducts(null)}
            onChangeItem={onChangeItem}
            setEligibleProducts={setEligibleProducts}
            onSubmitReleaseInventory={onSubmitReleaseInventory}
            subdomain={subdomain}
            shipmentConfiguration={shipmentConfiguration}
            setShipmentConfiguration={setShipmentConfiguration}
            boxContentsOptions={boxContentsOptions}
            packingMethodOptions={packingMethodOptions}
            bundlesWithOverlap={bundlesWithOverlap}
            setBundlesWithOverlap={setBundlesWithOverlap}
            selectedClient={
              customers?.find((i) => i.id === filters["customer"]?.[0])?.name ||
              "NA"
            }
            selectedWarehouse={
              warehouses?.find((i) => i.id === filters["warehouse"]?.[0])
                ?.name || "NA"
            }
          />
        )}

        {eligibleBundles && (
          <ExpandedEligibleBundles
            eligibleBundles={eligibleBundles}
            setEligibleBundles={setEligibleBundles}
            selectedBundleForAllocation={selectedBundleForAllocation}
            showBundleAllocationForm={showBundleAllocationForm}
            onSubmitBundleAllocation={onSubmitBundleAllocation}
            handleBundleSelection={handleBundleSelection}
          />
        )}

        {fetchedProductForChannelAllocation && (
          <ChannelAllocation
            sourceProduct={fetchedProductForChannelAllocation}
            warehousesForSelectedProductInAllocation={
              warehousesForSelectedProductInAllocation
            }
            negativeAction={() => setFetchedProductForChannelAllocation(null)}
            positiveAction={submitChannelAllocation}
            clientMarketplaceIntegrations={clientMarketplaceIntegrations}
            channelProductSearchKeyword={channelProductSearchKeyword}
            setChannelProductSearchKeyword={setChannelProductSearchKeyword}
            onChangeChannelProductSearch={onChangeChannelProductSearch}
            marketplaceProducts={marketplaceProducts}
            setMarketplaceProducts={setMarketplaceProducts}
            addedChannelProducts={addedChannelProducts}
            setAddedChannelProducts={setAddedChannelProducts}
            multiAccountSupportEnabled={multiAccountSupportEnabled}
            customers={customers}
            addingChannel={addingChannel}
            setAddingChannel={setAddingChannel}
          />
        )}

        {fetchedSku && (
          <ExpandedSku
            fetchedSku={fetchedSku}
            negativeAction={() => setFetchedSku(null)}
            customers={customers}
          />
        )}

        {inventoryExplanations &&
          inventoryExplanations.length > 0 &&
          inventoryExplanations.map((item, idx) => (
            <InventoryExplanation
              inventoryExplanation={item}
              idx={idx}
              index={idx}
              setInventoryExplanations={setInventoryExplanations}
              inventoryExplanations={inventoryExplanations}
              explainInventory={explainInventory}
              getSpecificInventory={getSpecificInventory}
              writable={writable}
              checkTransferSkuBinMapping={checkTransferSkuBinMapping}
              filters={filters}
            />
          ))}

        {fetchedInventory && (
          <EditReceivedSkuForm
            fetchedReceivedSku={fetchedInventory}
            onClose={() => setFetchedInventory(null)}
            onSubmit={submitFetchedInventory}
            onChange={(e) =>
              setFetchedInventory({
                ...fetchedInventory,
                [e.target.name]:
                  e.target.type === "number"
                    ? parseInt(e.target.value)
                    : e.target.value,
              })
            }
            onChangeDropdown={(field, value) => {
              setFetchedInventory({
                ...fetchedInventory,
                [field]: value,
              });
            }}
            stockChangeType={stockChangeType}
            onChangeRadio={(e) => {
              setStockChangeType(e.target.value);
            }}
            title={`Stock Adjustment for ${fetchedInventory.sku}`}
          />
        )}

        {binTransferEligibility && (
          <BinTransferEligibilityModal
            binTransferEligibility={binTransferEligibility}
            onClose={() => setBinTransferEligibility(null)}
            onSuccess={() => {
              setBinTransferEligibility(null);
              // RELOAD THE DATA
              submitFilters();
            }}
            checkTransferSkuBinMapping={checkTransferSkuBinMapping}
          />
        )}
      </div>
    </>
  );
};

export default withInventoryLedgerLogic(Inventory);
