import React, { useContext, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import AdminLayout from "#components/layout/AdminLayout";
import AssociateLayout from "#components/layout/AssociateLayout";
import NoPermissionLayout from "../components/layout/NoPermissionLayout";
import EmailNotSetLayout from "../components/layout/EmailNotSetLayout";
import UserSuspendedLayout from "../components/layout/UserSuspendedLayout";
import AcceptTermsAndConditionsLayout from "../components/layout/AcceptTermsAndConditionsLayout";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, user, loading, logout } = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const [conditionsAccepted, setConditionsAccepted] = useState(false);

  const renderRoute = (props) => {
    if (
      (!isAuthenticated && !loading) ||
      (!loading && rest.adminRoute && user?.role?.toLowerCase() === "associate")
    ) {
      return <Redirect to="/" />;
    }

    let currentRoutePermissions;

    if (user?.permissions?.length) {
      const isPageSectionReadable =
        user.permissions
          ?.filter((item) =>
            item.route.includes(props.location.pathname.concat("/")),
          )
          .filter((item) => item.readable).length > 0;

      if (props.location.pathname.includes("/dashboard")) {
        currentRoutePermissions = user.permissions.find((item) =>
          item.route.includes(props.location.pathname),
        );
      } else {
        currentRoutePermissions = user.permissions.find((item) =>
          props.location.pathname.includes(item.route),
        );
      }

      if (
        !isPageSectionReadable &&
        currentRoutePermissions &&
        !currentRoutePermissions?.readable
      ) {
        // If user doens't has read permission of current route
        // then redirect them to any other readable route

        if (user.permissions.filter((page) => page.readable).length) {
          const readableRoute = user.permissions.find((page) => page.readable);
          // If the found readable route is a section of the dashboard (identified as .route = "/dashboard/...")
          // then redirect to the dashboard root("/dashboard")
          if (readableRoute.route.includes("/dashboard/")) {
            readableRoute.route = "/dashboard";
          }
          return <Redirect to={readableRoute.route} />;
        }

        // If no readable route found then show no permission page
        return (
          <NoPermissionLayout
            onLogout={() => logout()}
            tenant={appState.tenant}
          />
        );
      }
    }

    if (user && user.role !== "ASSOCIATE") {
      return (
        <AdminLayout>
          <Component
            {...props}
            writable={
              currentRoutePermissions?.writable !== undefined
                ? currentRoutePermissions.writable
                : true
            }
            sectionPermissions={
              user.permissions?.filter((item) =>
                item.route.includes(props.location.pathname.concat("/")),
              ) || []
            }
          />
        </AdminLayout>
      );
    }
    return (
      <AssociateLayout>
        <Component {...props} writable={true} />
      </AssociateLayout>
    );
  };

  if (user && !conditionsAccepted && !user.termsAndConditionsAccepted) {
    return (
      <AcceptTermsAndConditionsLayout
        user={user}
        onLogout={() => logout()}
        dismiss={() => setConditionsAccepted(true)}
      />
    );
  }
  if (user && !user?.email) {
    return <EmailNotSetLayout user={user} onLogout={() => logout()} />;
  }
  if (user && user.suspended === true) {
    return <UserSuspendedLayout user={user} onLogout={() => logout()} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? <Redirect to="/" /> : renderRoute(props)
      }
    />
  );
  // return <Route {...rest} render={(props) => renderRoute(props)} />;
};

export default PrivateRoute;
