import Modal from "#components/utils/Modal";
import _ from "lodash";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";

const AssignBatchPickerForm = ({
  onClose,
  title,
  onSubmit,
  selectedBatch,
  setSelectedBatch,
  users,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <AutocompleteSingleSelectDropdown
          options={[{ id: null, name: "Unassigned" }, ...users]}
          labelKey={"name"}
          valueKey={"id"}
          onChange={(e) => setSelectedBatch({ ...selectedBatch, user: e })}
          value={selectedBatch.user}
          placeholder={
            selectedBatch.user
              ? `${users?.find((i) => i.id === selectedBatch.user)?.name}`
              : `Select Picker`
          }
        />
      </div>
    </div>
  </Modal>
);

export default AssignBatchPickerForm;
