import React from "react";
import PoBasicInfo from "./PoBasicInfo";
import ViewProductsMain from "./ViewProductsMain";
import currencyToSymbolMap from "currency-symbol-map/map";
import SuggestedChangesInfo from "./SuggestedChangesInfo";
import { PURCHASE_ORDER_STATUSES } from "./PoUtils";
import ViewShippedProductsMain from "./ViewShippedProductsMain";
const PurchaseOrderView = ({
  purchaseOrderDetails,
  basicInfoDetails,
  setShowSuggestedChanges,
}) => {
  return (
    <>
      {" "}
      <PoBasicInfo basicInfo={basicInfoDetails} />
      <div className="mb-8 mt-8 rounded-md border border-gray-200 bg-white p-6 shadow-sm">
        <h2 className="mb-4 text-lg font-semibold">Products & Pricing</h2>
        {purchaseOrderDetails.suggestedChangesMerged === false &&
          purchaseOrderDetails.status ===
            PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED && (
            <SuggestedChangesInfo
              setShowSuggestedChanges={setShowSuggestedChanges}
            />
          )}
        <ViewProductsMain
          currency={`${purchaseOrderDetails.currency}-${currencyToSymbolMap[purchaseOrderDetails.currency]}`}
          products={purchaseOrderDetails.lineItems}
          additionalCharges={purchaseOrderDetails.additionalCharges}
        />
      </div>
      {purchaseOrderDetails.shippingDetails &&
        purchaseOrderDetails.shippingDetails.length > 0 && (
          <div className="mb-8 mt-8 rounded-md border border-gray-200 bg-white p-6 shadow-sm">
            <h2 className="mb-4 text-lg font-semibold">Shipping Records</h2>
            <ViewShippedProductsMain
              products={purchaseOrderDetails.lineItems}
              shippingDetails={purchaseOrderDetails.shippingDetails}
            />
          </div>
        )}
    </>
  );
};

export default PurchaseOrderView;
