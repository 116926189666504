import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, TrashIcon, QrcodeIcon } from "@heroicons/react/outline";
import Modal from "#components/utils/Modal";
import { Table } from "antd";
import _ from "lodash";
import StockAdjustmentForm from "#components/cycleCountPlans/StockAdjustmentForm";
import Checkbox from "#components/utils/Checkbox";
import { uniqueAdjustmentRow } from "#components/HOC/withCycleCountPlansLogic";

const DetailedPlanExecution = ({
  onClose,
  onClickAdjustStocks,
  selectedSkusForAdjustment,
  setSelectedSkusForAdjusment,
  submitStockAdjustment,
  selectExecutionSku,
  compareExecution,
}) => {
  // console.log("dd", detailedPlanExecution); //workingList
  // console.log(compareExecution, "kkkkkk")
  const data = Object.keys(compareExecution).map((key) => {
    return {
      binLocation: compareExecution[key].binLocation,
      sku: compareExecution[key].sku,
      uom: compareExecution[key].uom,
      nestedFormFactorId: compareExecution[key].nestedFormFactorId,
      identifier: compareExecution[key].identifier,
      availableQuantity: compareExecution[key].systemCount,
      scannedSkus: compareExecution[key].scannedCount,
      userName: compareExecution[key].userName,
      status: compareExecution[key].status,
      timestamp: compareExecution[key].timestamp,
      reason: compareExecution[key].reason,
      adjustmentStatus: compareExecution[key].adjustmentStatus,
      customer: compareExecution[key].customer,
    };
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [reason, setReason] = useState(null);
  // console.log(data,"data");

  const selectAction = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setSelectedSkusForAdjusment([]);
    } else {
      setSelectedSkusForAdjusment(
        Object.values(data).filter(
          (item) =>
            item.adjustmentStatus != "NO ADJUSTMENT NEEDED" &&
            item.adjustmentStatus != "COMPLETED" &&
            item.status === "COMPLETED",
        ),
      );
    }
  };
  useEffect(() => {
    if (
      Object.values(data).filter(
        (item) =>
          item.adjustmentStatus != "NO ADJUSTMENT NEEDED" &&
          item.adjustmentStatus != "COMPLETED" &&
          item.status === "COMPLETED",
      ).length != selectedSkusForAdjustment.length
    ) {
      setSelectAll(false);
    }
    if (
      Object.values(data).filter(
        (item) =>
          item.adjustmentStatus != "NO ADJUSTMENT NEEDED" &&
          item.adjustmentStatus != "COMPLETED" &&
          item.status === "COMPLETED",
      ).length === selectedSkusForAdjustment.length
    ) {
      setSelectAll(true);
    }
  }, [selectedSkusForAdjustment]);
  return (
    <>
      <Modal
        title={"Executions"}
        negativeAction={onClose}
        positiveAction={() => {
          onClickAdjustStocks(selectedSkusForAdjustment);
          setShowConfirm(true);
        }}
        positiveText={"Adjust Stocks"}
        maxWidth="w-screen">
        <div className="w-full">
          <Table
            locale={{ emptyText: "No associates have started counting yet." }}
            dataSource={data || []}
            columns={[
              {
                title: (
                  <Checkbox
                    role="checkbox"
                    onChange={selectAction}
                    name="executions"
                    checked={selectAll}
                    value={selectedSkusForAdjustment}
                  />
                ),
                render: (text, record) => {
                  // console.log(text, record, "text, record");
                  return (
                    <Checkbox
                      role="checkbox"
                      onChange={(e) => selectExecutionSku(record)}
                      name="execution"
                      disabled={
                        record.adjustmentStatus === "NO ADJUSTMENT NEEDED" ||
                        record.adjustmentStatus === "COMPLETED" ||
                        record.status != "COMPLETED"
                          ? true
                          : false
                      }
                      checked={
                        !!selectedSkusForAdjustment?.find(
                          (item) =>
                            uniqueAdjustmentRow(item) ===
                            uniqueAdjustmentRow(record),
                        )
                      }
                      value={record}
                    />
                  );
                },
              },
              {
                title: "Location",
                dataIndex: "binLocation",
                key: "binLocation",
                render: (text) => <a>{text}</a>,
              },
              {
                title: "LPN",
                dataIndex: "nestedFormFactorId",
                key: "nestedFormFactorId",
              },
              {
                title: "UoM",
                dataIndex: "uom",
                key: "uom",
              },
              {
                title: "Product",
                dataIndex: "sku",
                key: "sku",
              },
              {
                title: "System Inventory count(latest)",
                dataIndex: "availableQuantity",
                key: "availableQuantity",
              },
              {
                title: "Physical Inventory Count(as of execution)",
                dataIndex: "scannedSkus",
                key: "scannedSkus",
              },
              {
                title: "Count Difference",
                dataIndex: "scannedSkus",
                key: "difference",
                render: (row, planExecution) => {
                  let stockDiffClassname;
                  let stockDiff =
                    planExecution.availableQuantity - planExecution.scannedSkus;
                  if (stockDiff === 0) stockDiffClassname = "text-green-500";
                  else if (stockDiff < 0) stockDiffClassname = "text-red-500";
                  else if (stockDiff > 0) stockDiffClassname = "text-red-500";

                  if (planExecution.status === "UNPROCESSED") {
                    stockDiff = "N/A";
                    stockDiffClassname = "";
                  }
                  return <div className={stockDiffClassname}>{stockDiff}</div>;
                },
              },
              {
                title: "Scanned UoM",
                dataIndex: "uom",
                key: "uom",
              },
              {
                title: "Agent",
                dataIndex: "userName",
                key: "agent",
              },
              {
                title: "Stock Cycle Status",
                dataIndex: "status",
                key: "status",
              },
              {
                title: "Inventory Adjustment Status",
                dataIndex: "adjustmentStatus",
                key: "adjustmentStatus",
              },
              {
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
                render: (text) => {
                  if (text == "N/A") {
                    return (
                      <div>
                        <a>{text}</a>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <button
                          onClick={() => {
                            setReasonModal(true);
                            setReason(text);
                          }}
                          className="text-sm text-2C7695 underline">
                          View
                        </button>
                      </div>
                    );
                  }
                },
              },
              {
                title: "Cycle Count Timestamp",
                dataIndex: "timestamp",
                key: "timestamp",
                render: (text) =>
                  (text && moment(parseInt(text)).format("llll")) || "N/A",
              },
            ]}
          />
        </div>
        {reasonModal && reason && (
          <Modal
            title={"Reason"}
            negativeAction={() => setReasonModal(false)}
            onClose={() => setReasonModal(false)}>
            <div className="space-y-4">
              <div>
                <a>{reason}</a>
              </div>
            </div>
          </Modal>
        )}
        {showConfirm && selectedSkusForAdjustment?.length > 0 && (
          <StockAdjustmentForm
            onClose={() => {
              setSelectedSkusForAdjusment([]);
              setShowConfirm(false);
            }}
            title={"Reason for stock adjustment"}
            onSubmit={submitStockAdjustment}
          />
        )}
      </Modal>
    </>
  );
};

export default DetailedPlanExecution;
