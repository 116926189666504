import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Input,
  Form,
  Button,
  Upload,
  Typography,
  Row,
  Col,
  Space,
  Tooltip,
  Progress,
} from "antd";
import {
  UploadOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { usePresignedURL } from "#hooks/usePresignedURL";
import CatalogGallery from "../CatalogGallery";
import { AppStateContext } from "#contexts/appState";
import { v4 as uuidv4 } from "uuid";
import EnhancedCategoriesSelect from "./EnhancedCategoriesSelect";
import NoMedia from "#static/images/NoMedia";
import CreateProductAlias from "./CreateProductAlias";

const { TextArea } = Input;
const { Title, Text } = Typography;

const handleConvertImagesData = (data) => {
  return (
    data?.map((item, index) => {
      return {
        ...item,
        uid: uuidv4(),
        name: "image" + index,
        status: "done",
        url: item.url,
        display_url: item.display_url,
      };
    }) || []
  );
};

const ProductOverview = ({
  product,
  setProduct,
  productCategories,
  saveProduct,
  customers,
}) => {
  const appState = useContext(AppStateContext);
  // const images = product?.images?.map((imageInfo) => imageInfo.display_url);
  const videos = product?.videos?.map((videoInfo) => videoInfo.display_url);
  // const showCarousel = product?.images?.length || product?.videos?.length;
  const [productMedia, setProductMedia] = useState([]);
  const [productImages, setProductImages] = useState(
    handleConvertImagesData(product.images),
  );

  const [selectedImage, setSelectedImage] = useState("");
  const [commonData, setCommonData] = useState({});
  const [imageUploadDone, setImageUploadDone] = useState(true);
  const { getPresignedURL } = usePresignedURL();

  const handleFileChange = ({ fileList }) => {
    setProductMedia(fileList);
  };

  useEffect(() => {
    if (product?.images?.length > 0)
      setProductImages(handleConvertImagesData(product.images));
  }, [product.images]);

  useEffect(() => {
    const fetchProductMedia = async () => {
      appState.setLoading();
      const mediaPromises = productImages?.map(async (image) => {
        if (image.display_url) {
          return {
            ...image,
            url: image.display_url,
          };
        } else {
          setCommonData((prev) => ({ ...prev, methodCall: "GET" }));
          const presignedURLResp = await getPresignedURL({
            key: image.url,
            method: "GET",
          });
          return {
            ...image,
            url:
              presignedURLResp?.data
                ?.getPresignedUrlForS3BucketContainingProductImages?.url || "",
          };
        }
      });

      const results = await Promise.all(mediaPromises);
      setImageUploadDone(true);
      setProductMedia(results);
      appState.removeLoading();
    };

    fetchProductMedia();
  }, [productImages]);

  const handleSave = () => {
    // Save logic here
    console.log("Product details saved");
  };

  const uploadFileWithProgress = (presignedURL, file, onProgress) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(Math.round(percentComplete));
        }
      });

      xhr.addEventListener("load", () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error("Upload failed: " + xhr.statusText));
        }
      });

      xhr.addEventListener("error", () => reject(new Error("Upload failed")));

      xhr.open("PUT", presignedURL);
      xhr.setRequestHeader("Content-Type", "");
      xhr.send(file);
    });
  };

  const handleRemoveImage = (file) => {
    setProductImages(productImages.filter((item) => item.uid !== file.uid));
  };

  const generateKeyForImage = ({ key }) => {
    let hash = Math.random().toString(36).substring(2, 8);
    let keyWithNoSpaces = key.replace(/\s/g, "");
    let productNameNoSpaces = (product?.name ?? "").replace(/\s/g, "");
    return `${product.customer}_${productNameNoSpaces}_${keyWithNoSpaces}_${hash}`;
  };

  const uploadToS3 = async (presignedURL, selectedImage) => {
    try {
      setImageUploadDone(false);
      await uploadFileWithProgress(presignedURL, selectedImage, (progress) => {
        setProductMedia((prevMedia) => {
          return prevMedia.map((media) => {
            if (media.url === commonData.awsImageKey) {
              media.percent = progress;
              media.status = progress === 100 ? "done" : "uploading";
            }
            return media;
          });
        });
      });
      setProductImages((prevImages) =>
        handleConvertImagesData([
          ...prevImages,
          ...[
            ...(prevImages.images || []),
            { url: commonData.awsImageKey, source: "Hopstack" },
          ],
        ]),
      );
    } catch (error) {
      setImageUploadDone(true);
      appState.setAlert("Image Upload failed. Please Try again.");
    }
  };

  useEffect(() => {
    if (commonData.presignedURL && commonData.selectedImage) {
      commonData.methodCall == "GET"
        ? setSelectedImage(commonData.presignedURL)
        : uploadToS3(commonData.presignedURL, commonData.selectedImage);
    }
  }, [commonData]);

  const onChangeFile = async ({ file }) => {
    if (!file) {
      appState.setAlert("Please select an image.");
      return;
    }

    if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
      appState.setAlert("Please select a valid image.");
      return;
    }

    const awsImageKey = generateKeyForImage({
      key: file.name.split(".")[0],
      method: "PUT",
    });

    setProductMedia((prevMedia) => [
      ...prevMedia,
      {
        uid: uuidv4(),
        name: "image" + prevMedia.length,
        status: "uploading",
        percent: 0,
        url: awsImageKey,
        source: "Hopstack",
      },
    ]);

    const response = await getPresignedURL({ key: awsImageKey, method: "PUT" });
    setCommonData({
      awsImageKey: awsImageKey,
      methodCall: "PUT",
      presignedURL:
        response?.data?.getPresignedUrlForS3BucketContainingProductImages
          ?.url || null,
      selectedImage: file,
    });
  };

  const customItemRender = (originNode, file, fileList, actions) => {
    return (
      <div className="custom-upload-item flex h-24 w-24 flex-col items-center justify-center border p-1">
        {file.status === "uploading" ? (
          <div className="flex h-4/5 w-full items-center justify-center">
            <div className="upload-progress w-full">
              <Progress percent={file.percent} />
            </div>
          </div>
        ) : (
          <div className="flex h-4/5 w-full items-center justify-center">
            <img
              src={file.url || URL.createObjectURL(file.originFileObj)}
              alt={file.name}
              className="h-full w-full object-cover"
            />
          </div>
        )}
        <div className="upload-actions flex h-1/5 w-full items-center justify-center gap-3 pt-1">
          <DeleteOutlined onClick={() => handleRemoveImage(file)} />
          <EyeOutlined />
        </div>
      </div>
    );
  };

  return (
    <>
      <Form
        layout="vertical"
        className="space-y-6"
        onFinish={() => {
          const media = productImages.map((image) => {
            return {
              url: image.url,
              source: image.source,
              display_url: image.display_url || null,
            };
          });
          saveProduct(media);
        }}
        initialValues={{
          remember: true,
        }}>
        <div className="max-w-6xl rounded-lg bg-white pt-5">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="text-base text-gray-500">
                    Product Name<span className="text-lg text-red-500">*</span>{" "}
                    <Tooltip title="Enter the product name">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="productName">
                <Input
                  size="large"
                  className="h-14 rounded-md"
                  defaultValue={product.name}
                  onChange={(e) =>
                    setProduct({ ...product, name: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="text-base text-gray-500">
                    Product Category
                    <span className="text-lg text-red-500">*</span>{" "}
                    <Tooltip title="Select the product category and click apply">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="productCategory">
                <EnhancedCategoriesSelect
                  options={productCategories}
                  onChangeCustomSelect={(values) => {
                    setProduct({ ...product, categories: values });
                  }}
                  preValues={product.categories || []}
                  isShowSelectedOptions={true}
                  classNames="comboDropdown h-12 w-full"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="text-base text-gray-500">
                    Product Description
                    <span className="text-lg text-red-500">*</span>{" "}
                    <Tooltip title="Provide a detailed product description.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="productDescription">
                <TextArea
                  rows={4}
                  defaultValue={product.description}
                  onChange={(e) =>
                    setProduct({ ...product, description: e.target.value })
                  }
                />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="text-base text-gray-500">
                        SKU{" "}
                        <Tooltip title="Stock Keeping Unit">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="sku">
                    <Input
                      size="large"
                      className="h-14 rounded-md bg-gray-100"
                      defaultValue={product.sku}
                      onChange={(e) =>
                        setProduct({ ...product, sku: e.target.value })
                      }
                      readOnly
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="text-base text-gray-500">
                        UPC{" "}
                        <Tooltip title="Universal Product Code">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="upc">
                    <Input
                      size="large"
                      className="h-14 rounded-md bg-gray-100"
                      onChange={(e) =>
                        setProduct({ ...product, name: e.target.value })
                      }
                      defaultValue={
                        product.upc ? product.upc.join(",") : product.upc
                      }
                      readOnly
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="text-base text-gray-500">
                        Source{" "}
                        <Tooltip title="Source of the product">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="source">
                    <Input
                      size="large"
                      className="h-14 rounded-md bg-gray-100"
                      defaultValue={product.source}
                      onChange={(e) =>
                        setProduct({ ...product, source: e.target.value })
                      }
                      readOnly
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="text-base text-gray-500">
                        Customer{" "}
                        <Tooltip title="Customer details">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="customer">
                    <Input
                      size="large"
                      className="h-14 rounded-md bg-gray-100"
                      defaultValue={product.customer}
                      onChange={(e) =>
                        setProduct({ ...product, customer: e.target.value })
                      }
                      readOnly
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="text-base text-gray-500">
                        Model{" "}
                        <Tooltip title="Product model number">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="model">
                    <Input
                      size="large"
                      className="h-14 rounded-md bg-gray-100"
                      defaultValue={product.model}
                      onChange={(e) =>
                        setProduct({ ...product, model: e.target.value })
                      }
                      readOnly
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="text-base text-gray-500">
                        Manufacturer{" "}
                        <Tooltip title="Manufacturer details">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="manufacturer">
                    <Input
                      size="large"
                      className="h-14 rounded-md bg-gray-100"
                      defaultValue={product.manufacturer}
                      onChange={(e) =>
                        setProduct({ ...product, manufacturer: e.target.value })
                      }
                      readOnly
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={
                  <span className="text-base text-gray-500">
                    Brand*{" "}
                    <Tooltip title="Product brand name">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="brand">
                <Input
                  size="large"
                  className="h-14 rounded-md"
                  defaultValue={product.brand}
                  onChange={(e) =>
                    setProduct({ ...product, brand: e.target.value })
                  }
                  readOnly
                  disabled
                />
              </Form.Item>
              {appState?.tenant?.settings?.isProductAliasAllowed === true ? (
                <CreateProductAlias customers={customers} product={product} />
              ) : (
                ""
              )}
            </Col>
            <Col span={12} className="h-full">
              <div className="flex flex-col items-center">
                <div className="w-full p-3" style={{ height: "36rem" }}>
                  <div
                    className={`h-full w-full p-3 ${productMedia.length === 0 ? "flex items-center justify-center border-2 border-gray-300" : ""}`}>
                    {productMedia.length ? (
                      <CatalogGallery
                        videos={videos}
                        images={productMedia.map((media) => media.url)}
                        height="30rem"
                      />
                    ) : (
                      <div>
                        <img src={NoMedia} alt="no-media-image" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mx-auto h-3/6 max-w-6xl rounded-lg bg-white p-6 shadow-md">
                  <div className="h-full">
                    <div className="flex h-full w-full flex-col items-center gap-4">
                      <div className="mb-2">
                        <span className="text-base text-gray-500">
                          Product Media{" "}
                          <Tooltip title="Upload product images and videos">
                            <InfoCircleOutlined />
                          </Tooltip>
                        </span>
                        <Text className="text-customGray block text-sm">
                          Must contain product images, 360° views, videos and
                          product zoom features
                        </Text>
                      </div>
                      <Upload
                        listType="picture-card"
                        fileList={productMedia}
                        onChange={onChangeFile}
                        itemRender={customItemRender}
                        beforeUpload={() => false}
                        className="h-4/6 w-full"
                        disabled={!imageUploadDone}>
                        {productMedia.length >= 8 ? null : (
                          <div>
                            <UploadOutlined />
                            <div style={{ marginTop: 8 }}>Upload Media</div>
                          </div>
                        )}
                      </Upload>
                      <Text className="text-customGray mt-2 block text-center">
                        Accepted formats (PNG, JPG, Mp4)
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="flex h-16 w-full items-center justify-center">
            <div className="w-full bg-gray-300" style={{ height: "1px" }}></div>
          </div>
          <Space
            style={{ display: "flex", justifyContent: "flex-end" }}
            className="mt-2">
            <Form.Item>
              <Button
                className="mt-2 flex h-14 w-32 cursor-pointer items-center justify-center rounded-md border-primaryAccent p-2 text-lg font-medium text-primaryAccent"
                onClick={() => setProduct(null)}>
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="mt-2 flex h-14 w-32 cursor-pointer items-center justify-center rounded-md border bg-primaryAccent p-2 text-lg font-medium text-white">
                Save
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default ProductOverview;
