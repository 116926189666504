import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const BinLocationFilters = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  warehouses,
  customers,
  locationTypes,
  onChangeMultiSelect,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="mt-4 flex space-x-6">
      <div className="flex-1">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Warehouse
          </span>
        </label>
        <MultiSelectAutoComplete
          options={warehouses}
          labelKey={"name"}
          valueKey={"id"}
          setValues={(selectedOptions) =>
            onChangeMultiSelect("warehouses", selectedOptions)
          }
          values={filters["warehouses"]}
        />
      </div>
      <div className="flex-1">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Location Type
          </span>
        </label>
        <Dropdown
          placeholder={"Select Location Type"}
          list={locationTypes}
          labelKey={"name"}
          valueKey={"id"}
          setSelected={(selectedOption) =>
            onChangeMultiSelect("type", selectedOption)
          }
          selectedValue={filters["type"]}
        />
      </div>

      <div className="flex-1">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Client
          </span>
        </label>
        <MultiSelectAutoComplete
          options={getFilteredCustomerList(filters["warehouses"], customers)}
          labelKey={"name"}
          valueKey={"id"}
          setValues={(selectedOptions) =>
            onChangeMultiSelect("customers", selectedOptions)
          }
          values={filters["customers"]}
        />
      </div>
    </div>
  </Modal>
);

export default BinLocationFilters;
