import { useState, useMemo } from "react";
import SlideOverModal from "#components/common/SlideOverModal";
import ResolvementConfirmation from "./ResolvementConfirmation";
import PreviewFulfillmentDetails from "../batching/PreviewFulfillmentDetails";

const ResolveExceptions = ({
  negativeAction,
  tryMassResolveExceptions,
  tryResolve,
  orders,
  selectedOrderIds = [],
  ordersFulfillmentDetails,
  isBackOrderEnabledForTenant,
}) => {
  // By default the fulfillable orders will be selected for batching.
  // We give option to user to select the partially fulfillable orders. hence we maintain this state and send it to createManualBatchFinal method
  const [selectedOrderIdsForBatching, setSelectedOrderIdsForBatching] =
    useState([]);

  // Orders that can be completely fulfilled
  const [fulfillableOrders, setFulfillableOrders] = useState([]);
  // Orders that are eligible for back orders
  const [partiallyFulfillableOrders, setPartiallyFulfillableOrders] = useState(
    [],
  );
  // Orders that went into exceptions
  const [exceptionOrders, setExceptionOrders] = useState([]);

  /**
   * Orders that might not be eligible for back order due to client, integration level setup,
   * Orders where all items have no inventory,
   * Orders which is having any exception like sku missing.
   * Orders which can only be fulfilled by alternate warehouse.
   */
  const [unfulfillableOrders, setUnfulfillableOrders] = useState([]);
  useMemo(() => {
    setFulfillableOrders(ordersFulfillmentDetails.fulfillableOrders);
    setUnfulfillableOrders(ordersFulfillmentDetails.unfulfillableOrders);
    setPartiallyFulfillableOrders(ordersFulfillmentDetails.backOrders);
    setExceptionOrders(ordersFulfillmentDetails.exceptionOrders);
    setSelectedOrderIdsForBatching(
      ordersFulfillmentDetails.fulfillableOrders.map(({ order }) => order.id),
    );
  }, [ordersFulfillmentDetails]);

  const [selectedStep, setSelectedStep] = useState("01");

  const resolveExceptions = () => {
    if (selectedOrderIdsForBatching.length === 1) {
      tryResolve(selectedOrderIds[0]);
    }
    if (selectedOrderIdsForBatching.length > 1) {
      tryMassResolveExceptions(selectedOrderIds);
    }
  };

  return (
    <SlideOverModal
      open={true}
      onClose={negativeAction}
      title={"Resolve Exceptions"}
      width={"w-1/2"}>
      <div className="flex h-full w-full select-none flex-col">
        <main className="grow">
          {selectedStep === "01" && (
            <PreviewFulfillmentDetails
              fulfillableOrders={fulfillableOrders}
              partiallyFulfillableOrders={partiallyFulfillableOrders}
              unfulfillableOrders={unfulfillableOrders}
              exceptionOrders={exceptionOrders}
              selectedOrderIds={selectedOrderIds}
              selectedOrderIdsForBatching={selectedOrderIdsForBatching}
              setSelectedOrderIdsForBatching={setSelectedOrderIdsForBatching}
              isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
              previewForExceptions={true}
            />
          )}
          {selectedStep === "02" && (
            <ResolvementConfirmation
              orders={orders}
              partiallyFulfillableOrders={partiallyFulfillableOrders}
              selectedOrderIdsForBatching={selectedOrderIdsForBatching}
            />
          )}
        </main>
        <footer>
          <FooterActions
            negativeAction={negativeAction}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
            resolveExceptions={resolveExceptions}
            selectedOrderIdsForBatching={selectedOrderIdsForBatching}
            fulfillableOrders={fulfillableOrders}
            partiallyFulfillableOrders={partiallyFulfillableOrders}
          />
        </footer>
      </div>
    </SlideOverModal>
  );
};

const FooterActions = ({
  negativeAction,
  selectedStep,
  setSelectedStep,
  resolveExceptions,
  selectedOrderIdsForBatching,
  fulfillableOrders,
  partiallyFulfillableOrders,
}) => {
  const showBatchConfirmation =
    fulfillableOrders.length > 0 || partiallyFulfillableOrders.length > 0;

  return (
    <div className="mr-2 flex h-16 items-center justify-end space-x-2">
      <button
        onClick={negativeAction}
        className={`cursor-pointer rounded-md ${
          showBatchConfirmation
            ? "bg-red-600 hover:bg-red-700"
            : "bg-primaryAccent"
        } text-md textWhite-300 inline-flex items-center justify-center border px-8 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0`}>
        {showBatchConfirmation ? "Cancel" : "OK"}
      </button>
      {showBatchConfirmation && (
        <button
          onClick={() => selectedStep === "02" && setSelectedStep("01")}
          className={`text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded-md border px-8 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0 ${
            selectedStep === "01"
              ? "cursor-not-allowed bg-gray-200 text-gray-400"
              : "bg-FC8862"
          }`}>
          Previous
        </button>
      )}
      {showBatchConfirmation && (
        <button
          onClick={() => {
            if (
              selectedStep === "01" &&
              selectedOrderIdsForBatching.length > 0
            ) {
              setSelectedStep("02");
            } else if (selectedStep === "02") {
              resolveExceptions();
            }
          }}
          className={`text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded-md border px-8 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0 ${
            selectedStep === "02"
              ? "bg-primaryAccent"
              : selectedOrderIdsForBatching.length > 0
                ? "bg-FC8862"
                : "cursor-not-allowed bg-gray-200 text-gray-400"
          }`}>
          {selectedStep === "02" ? "Resolve" : "Next"}
        </button>
      )}
    </div>
  );
};

export default ResolveExceptions;
