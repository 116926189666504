import { useState, useContext } from "react";
import { AppStateContext } from "#contexts/appState";
import { XIcon, PencilAltIcon } from "@heroicons/react/solid";
import AutocompleteMultiSelectDropdown from "#components/utils/AutocompleteMultiSelectDropdown";
import { DatePicker, Tooltip } from "antd";
import CronExpressionGenerator from "#components/common/Scheduler";
import { ALERT_VISIBILITY_IN_3000_MS } from "#components/HOC/withReportsLogic";

import dayjs from "dayjs";
import "dayjs/locale/en";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import moment from "moment-timezone";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
const DATE_FORMAT = "DD-MM-YYYY";

const ViewEditScheduledReport = ({
  selectedReport,
  setOpenReportMode,
  onClose,
  setSelectedReport,
  onChange,
  warehouses,
  customers,
  onChangeFrequency,
  parseCronExpression,
  setReportType,
  allUsers,
  copySelectedReport,
  onSubmit,
  reportTypeModesEnum,
  reportModalModesEnum,
  openScheduledReportCancelConfirmation,
}) => {
  const appState = useContext(AppStateContext);
  const [editFiltersDetails, setFiltersDetails] = useState(false);
  const [editRecipientsDetails, setEditRecipientsDetails] = useState(false);
  const [editScheduleDetails, setEditScheduleDetails] = useState(false);
  const [reportFilterValuesUpdated, setReportFilterValuesUpdated] =
    useState(false);
  const [reportRecipientValuesUpdated, setReportRecipientValuesUpdated] =
    useState(false);
  const [reportScheduleValuesUpdated, setReportScheduleValuesUpdated] =
    useState(false);

  const onSubmitForm = () => {
    if (
      reportFilterValuesUpdated ||
      reportRecipientValuesUpdated ||
      reportScheduleValuesUpdated
    ) {
      if (
        !selectedReport?.warehouses ||
        selectedReport?.warehouses === "" ||
        selectedReport?.warehouses?.length === 0
      ) {
        return appState.setAlert(
          `Please select warehouse details`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (
        !selectedReport?.customers ||
        selectedReport?.customers === "" ||
        selectedReport?.customers?.length === 0
      ) {
        return appState.setAlert(
          `Please select client details`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (
        !selectedReport?.scheduleStartDate ||
        selectedReport?.scheduleStartDate === ""
      ) {
        return appState.setAlert(
          `Please select schedule start date details`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (
        selectedReport?.frequency === "weekly" &&
        (!selectedReport?.dayOfWeek || selectedReport?.dayOfWeek === "") &&
        reportScheduleValuesUpdated
      ) {
        return appState.setAlert(
          `Please select schedule details`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (
        selectedReport?.frequency === "monthly" &&
        (!selectedReport?.dayOfMonth || selectedReport?.dayOfMonth === "") &&
        reportScheduleValuesUpdated
      ) {
        return appState.setAlert(
          `Please select schedule details`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (
        !selectedReport?.scheduleStartTime ||
        selectedReport?.scheduleStartTime === ""
      ) {
        return appState.setAlert(
          `Please select schedule start time details`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (
        !selectedReport?.localCron ||
        selectedReport?.localCron === ""
      ) {
        return appState.setAlert(
          `Please select schedule details`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (
        !selectedReport?.recipients ||
        selectedReport?.recipients === "" ||
        selectedReport?.recipients?.length === 0
      ) {
        return appState.setAlert(
          `Please select recipient details`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else {
        onSubmit();
      }
    }
  };

  const onCancelForm = () => {
    openScheduledReportCancelConfirmation(copySelectedReport);
  };

  const editFilters = (checked) => {
    setFiltersDetails(checked);
    if (!checked) {
      onChange({
        target: {
          name: "warehouses",
          value: copySelectedReport?.warehouses,
        },
      });
      onChange({
        target: {
          name: "customers",
          value: copySelectedReport?.customers,
        },
      });
      setReportFilterValuesUpdated(false);
    }
  };
  const editRecipients = (checked) => {
    setEditRecipientsDetails(checked);
    if (!checked) {
      onChange({
        target: {
          name: "recipients",
          value: copySelectedReport?.recipients,
        },
      });
      setReportRecipientValuesUpdated(false);
    }
  };
  const editSchedules = (checked) => {
    setEditScheduleDetails(checked);
    if (!checked) {
      onChange({
        target: {
          name: "frequency",
          value: copySelectedReport?.frequency,
        },
      });
      onChange({
        target: {
          name: "scheduleStartDate",
          value: copySelectedReport?.scheduleStartDate,
        },
      });
      onChange({
        target: {
          name: "scheduleStartTime",
          value: copySelectedReport?.scheduleStartTime,
        },
      });
      onChange({
        target: {
          name: "localCron",
          value: copySelectedReport?.localCron,
        },
      });
      onChange({
        target: {
          name: "scheduleRunDate",
          value: copySelectedReport?.scheduleRunDate,
        },
      });
      if (copySelectedReport?.frequency === "weekly") {
        let dayOfWeek = getDayFromCron(
          copySelectedReport?.localCron,
          copySelectedReport?.frequency,
        );
        onChange({
          target: {
            name: "dayOfWeek",
            value: dayOfWeek,
          },
        });
      }
      if (copySelectedReport?.frequency === "monthly") {
        let dayOfMonth = getDayFromCron(
          copySelectedReport?.localCron,
          copySelectedReport?.frequency,
        );
        onChange({
          target: {
            name: "dayOfMonth",
            value: dayOfMonth,
          },
        });
      }
      setReportScheduleValuesUpdated(false);
    }
  };

  return (
    <div className="relative mt-4 flex h-full w-full flex-col overflow-auto font-inter">
      <main className="my-2 h-full grow overflow-auto">
        <div className="mb-6">
          <h1 className="text-lg font-semibold">Basic Information</h1>
          <p className="mb-4 mt-1 text-base font-light text-gray-400">
            These are the main details of your report. You won't be able to
            change them here
          </p>
          <div className="grid grid-cols-1 gap-6">
            <div className="flex flex-row items-center">
              <div className="w-1/4">Report Type</div>
              <div className="flex w-3/4 gap-4">
                <input
                  type="text"
                  name="reportType"
                  placeholder={""}
                  value={selectedReport?.module}
                  onChange={() => {}}
                  disabled={true}
                  className={`block w-[400px] cursor-not-allowed rounded border border-gray-200 bg-white p-2 text-primaryAccent transition-colors duration-300`}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-1/4">Date Created</div>
              <div className="flex w-3/4 gap-4">
                <input
                  type="text"
                  name="dateCreated"
                  placeholder={""}
                  value={
                    selectedReport?.createdAt &&
                    selectedReport?.createdAt !== null &&
                    selectedReport?.createdAt !== ""
                      ? moment
                          .unix(selectedReport?.createdAt)
                          .format("DD MMM YYYY")
                      : ""
                  }
                  onChange={() => {}}
                  disabled={true}
                  className={`block w-[400px] cursor-not-allowed rounded border border-gray-200 bg-white p-2 text-primaryAccent transition-colors duration-300`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-lg font-semibold">Filter Applied</h1>
            {!editFiltersDetails && (
              <PencilAltIcon
                className="mr-2 h-5 w-5 cursor-pointer text-primaryAccent"
                onClick={() => editFilters(true)}
              />
            )}
            {editFiltersDetails && (
              <XIcon
                className="mr-2 h-5 w-5 cursor-pointer text-primaryAccent"
                onClick={() => editFilters(false)}
              />
            )}
          </div>
          <p className="mb-4 mt-1 text-base font-light text-gray-400">
            These filters, including warehouse and client selections, were used
            to create your report and determine what's included
          </p>
          <div className="grid grid-cols-1 gap-6">
            <div className="flex flex-row items-center">
              <div className="w-1/4">
                <div>Warehouse Name</div>
                {selectedReport?.warehouses && (
                  <span className="rounded-sm bg-white bg-opacity-60 pb-0 pt-0 text-xs font-bold text-primaryAccent">
                    {"[ " +
                      selectedReport?.warehouses?.length +
                      " Warehouses Selected ]"}
                  </span>
                )}
              </div>
              <div className="flex w-3/4 gap-4">
                {
                  <AutocompleteMultiSelectDropdown
                    key={"warehouseName"}
                    options={warehouses}
                    labelKey={"name"}
                    valueKey={"id"}
                    onChange={(value) => {
                      setReportFilterValuesUpdated(true);
                      onChange({
                        target: {
                          name: "warehouses",
                          value: value && value.length !== 0 ? value : null,
                        },
                      });
                    }}
                    values={selectedReport?.warehouses}
                    placeholder="Select Warehouses"
                    multiSelect={true}
                    disabled={!editFiltersDetails}
                  />
                }
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-1/4">
                <div>Client Name</div>
                {selectedReport?.customers && (
                  <span className="rounded-sm bg-white bg-opacity-60 pb-0 pt-0 text-xs font-bold text-primaryAccent">
                    {"[ " +
                      selectedReport?.customers?.length +
                      " Clients Selected ]"}
                  </span>
                )}
              </div>
              <div className="flex w-3/4 gap-4">
                {
                  <AutocompleteMultiSelectDropdown
                    key={"clientName"}
                    options={customers}
                    labelKey={"name"}
                    valueKey={"id"}
                    onChange={(value) => {
                      setReportFilterValuesUpdated(true);
                      onChange({
                        target: {
                          name: "customers",
                          value: value && value.length !== 0 ? value : null,
                        },
                      });
                    }}
                    values={selectedReport?.customers}
                    placeholder="Select Client Name"
                    multiSelect={true}
                    disabled={!editFiltersDetails}
                  />
                }
              </div>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-lg font-semibold">Schedule Details</h1>
            {!editScheduleDetails && (
              <PencilAltIcon
                className="mr-2 h-5 w-5 cursor-pointer text-primaryAccent"
                onClick={() => editSchedules(true)}
              />
            )}
            {editScheduleDetails && (
              <XIcon
                className="mr-2 h-5 w-5 cursor-pointer text-primaryAccent"
                onClick={() => editSchedules(false)}
              />
            )}
          </div>
          <p className="mb-4 mt-1 text-base font-light text-gray-400">
            This section outlines how often your report runs and when it's
            scheduled next
          </p>
          {!editScheduleDetails && (
            <div className="grid grid-cols-1 gap-6">
              <div className="flex flex-row items-center">
                <div className="w-1/4">Frequency</div>
                <div className="flex w-3/4 gap-4">
                  <input
                    type="text"
                    name="frequency"
                    placeholder={""}
                    value={selectedReport?.frequencyCap}
                    onChange={() => {}}
                    disabled={true}
                    className={`block w-[400px] rounded border border-gray-200 bg-white p-2 text-primaryAccent transition-colors duration-300`}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-1/4">Next Schedule Run</div>
                <div className="flex w-3/4 gap-4">
                  <input
                    type="text"
                    name="nextScheduleRun"
                    placeholder={""}
                    value={
                      selectedReport?.nextRun &&
                      selectedReport?.nextRun !== null
                        ? moment
                            .unix(selectedReport?.nextRun)
                            .format("DD MMM YYYY, hh:mm A")
                        : ""
                    }
                    onChange={() => {}}
                    disabled={true}
                    className={`block w-[400px] rounded border border-gray-200 bg-white p-2 text-primaryAccent transition-colors duration-300`}
                  />
                </div>
              </div>
            </div>
          )}
          {editScheduleDetails && (
            <SchedulingInfo
              selectedReport={selectedReport}
              setSelectedReport={setSelectedReport}
              onChangeFrequency={onChangeFrequency}
              onChange={onChange}
              parseCronExpression={parseCronExpression}
              isEditView={true}
              isScheduleChanged={setReportScheduleValuesUpdated}
            />
          )}
        </div>

        <div className="mb-6">
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-lg font-semibold">Recipients</h1>
            {!editRecipientsDetails && (
              <PencilAltIcon
                className="mr-2 h-5 w-5 cursor-pointer text-primaryAccent"
                onClick={() => editRecipients(true)}
              />
            )}
            {editRecipientsDetails && (
              <XIcon
                className="mr-2 h-5 w-5 cursor-pointer text-primaryAccent"
                onClick={() => editRecipients(false)}
              />
            )}
          </div>
          <p className="mb-4 mt-1 text-base font-light text-gray-400">
            For now, scheduled reports can only be sent to your own email. We'll
            soon enable adding other recipients
          </p>
          <div className="grid grid-cols-1 gap-6">
            <div className="flex flex-row items-center">
              <div className="w-1/4">
                <div>Users Selected</div>
                {selectedReport?.recipients && (
                  <span className="rounded-sm bg-white bg-opacity-60 pb-0 pt-0 text-xs font-bold text-primaryAccent">
                    {"[ " +
                      selectedReport?.recipients?.length +
                      " Users Selected ]"}
                  </span>
                )}
              </div>
              <div className="flex w-3/4 gap-4">
                {
                  <AutocompleteMultiSelectDropdown
                    key={"users"}
                    name="recipients"
                    options={allUsers}
                    labelKey={"name"}
                    valueKey={"email"}
                    onChange={(values) => {
                      const value =
                        values && values.length !== 0
                          ? values.map((email) => {
                              const findUser =
                                allUsers && allUsers.length !== 0
                                  ? allUsers.filter(
                                      (user) => user["email"] === email,
                                    )
                                  : [];
                              if (findUser.length !== 0 && email) {
                                return {
                                  email: email,
                                  name: findUser[0]["name"],
                                };
                              }
                            })
                          : [];
                      onChange({
                        target: {
                          name: "recipients",
                          value,
                        },
                      });
                      setReportRecipientValuesUpdated(true);
                    }}
                    values={
                      selectedReport?.recipients &&
                      selectedReport?.recipients?.length !== 0
                        ? selectedReport?.recipients?.map(
                            (user) => user["email"],
                          )
                        : []
                    }
                    placeholder="Select Users"
                    multiSelect={true}
                    disabled={!editRecipientsDetails}
                  />
                }
              </div>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <h1 className="text-lg font-semibold">
            Last Successful Execution Date and Time
          </h1>
          <p className="mb-4 mt-1 text-base font-light text-gray-400">
            This shows when your scheduled report last ran successfully
          </p>
          <div className="grid grid-cols-1 gap-6">
            <div className="flex flex-row items-center">
              <div className="w-1/4">Last Successful Execution</div>
              <div className="flex w-3/4 gap-4">
                <input
                  type="text"
                  name="lastSuccessfulExecution"
                  placeholder={""}
                  value={
                    selectedReport?.lastRun && selectedReport?.lastRun !== null
                      ? moment
                          .unix(selectedReport?.lastRun)
                          .format("DD MMM YYYY, hh:mm A")
                      : ""
                  }
                  onChange={() => {}}
                  disabled={true}
                  className={`block w-[400px] cursor-not-allowed rounded border border-gray-200 bg-white p-2 text-primaryAccent transition-colors duration-300`}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex h-24 items-center justify-end">
        <div className="mb-2 flex space-x-4">
          <button
            onClick={onCancelForm}
            className="cursor-pointer rounded-md border border-mediumGray px-6 py-3 text-base font-semibold text-mediumGray">
            Cancel Schedule
          </button>
          {(reportFilterValuesUpdated ||
            reportRecipientValuesUpdated ||
            reportScheduleValuesUpdated) && (
            <button
              onClick={onSubmitForm}
              className={`rounded-md px-6 py-3 text-base font-semibold ${
                true
                  ? "cursor-pointer bg-primaryAccent text-white"
                  : "cursor-not-allowed border border-mediumGray text-mediumGray"
              }`}>
              Update
            </button>
          )}
        </div>
      </footer>
    </div>
  );
};

const SchedulingInfo = ({
  selectedReport,
  onChangeFrequency,
  setSelectedReport,
  onChange,
  parseCronExpression,
  showHeader,
  isEditView,
  isScheduleChanged,
}) => {
  const [timeZoneOffset, setTimeZoneOffset] = useState(moment().format("Z"));
  const [timeZoneIdentifier, setTimeZoneIdentifier] = useState(
    moment.tz.guess(),
  );
  const [resetTime, setResetTime] = useState(false);
  const isValidFormat = moment(
    selectedReport?.scheduleStartDate,
    "DD-MM-YYYY",
    true,
  ).isValid();
  const FormattedDate = isValidFormat
    ? selectedReport?.scheduleStartDate
    : moment(selectedReport?.scheduleStartDate).format("DD-MM-YYYY");
  const scheduleStartDate = selectedReport?.scheduleStartDate
    ? [
        dayjs(convertedDateFormat(FormattedDate + "T00:00:00.000Z", "startOf")),
        dayjs(convertedDateFormat(FormattedDate + "T23:59:59.999Z", "endOf")),
      ]
    : [];
  return (
    <div className="pl-2">
      {timeZoneOffset && timeZoneIdentifier && (
        <div className="mb-4 w-538">
          <div className="flex flex-row items-center space-x-6">
            <div className="flex-1">
              <label
                htmlFor="scheduleStartDate"
                className="mb-1 text-sm text-gray-500">
                Time Zone :{" "}
                <span className="font-medium">{`${timeZoneIdentifier} (GMT${timeZoneOffset})`}</span>
              </label>
            </div>
          </div>
        </div>
      )}
      <div className="mb-4 w-538">
        <div className="flex flex-row items-center space-x-6">
          <div className="flex-1">
            <label
              htmlFor="scheduleStartDate"
              className="mb-1 text-sm text-gray-500">
              Starting From
            </label>
            <div className="mt-1 space-y-4">
              <DatePicker
                placeholder="Choose Date"
                onChange={(dates, value) => {
                  if (isEditView) {
                    isScheduleChanged(true);
                  }
                  onChange({
                    target: {
                      name: "scheduleStartDate",
                      value,
                    },
                  });
                  setResetTime(true);
                }}
                value={scheduleStartDate}
                format="DD-MM-YYYY"
                disabledDate={(current) =>
                  current.isBefore(moment().subtract(1, "day"))
                }
                className="w-[24rem] rounded border border-borderGray p-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4 w-538">
        <label className="mb-2 text-sm text-gray-500" htmlFor="frequency">
          Frequency
        </label>

        <div className="mt-1 space-y-4">
          <CronExpressionGenerator
            selectedReport={selectedReport}
            onChangeFrequency={onChangeFrequency}
            setSelectedReport={setSelectedReport}
            onChange={onChange}
            parseCronExpression={parseCronExpression}
            scheduleStartDate={selectedReport?.scheduleStartDate}
            isEditView={isEditView}
            resetTime={resetTime}
            setResetTime={setResetTime}
            isScheduleChanged={isScheduleChanged}
          />
        </div>
      </div>
    </div>
  );
};

const convertedDateFormat = (date, day) => {
  const gmtTimezone = "GMT";
  const momentDate = moment.tz(date, "DD-MM-YYYY", gmtTimezone);
  if (day === "startOf") {
    momentDate.startOf("day").toISOString();
  } else if (day === "endOf") {
    momentDate.endOf("day").toISOString();
  }
  return momentDate;
};
const getDayFromCron = (cron, frequency) => {
  let cronExpression = cron ? cron : null;
  cronExpression = cronExpression !== null ? cronExpression.split(" ") : null;
  if (frequency === "weekly") {
    return cronExpression && cronExpression.length !== 0
      ? cronExpression[4]
      : "";
  } else if (frequency === "monthly") {
    return cronExpression && cronExpression.length !== 0
      ? cronExpression[2]
      : "";
  } else {
    return "";
  }
};

export default ViewEditScheduledReport;
