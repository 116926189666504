import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ExclamationCircleIcon,
  SearchIcon,
  XCircleIcon,
  FilterIcon,
} from "@heroicons/react/outline";
import { Tooltip, Skeleton } from "antd";
import PrimaryButton from "./PrimaryButton";
import noResultsImage from "../../static/images/nofilter.png";
import CustomPagination from "./CustomPagination";
import LoadingIndicator from "../../components/utils/LoadingIndicator";

const CustomTable = ({
  columns,
  data,
  onSort,
  isSearchable = false,
  isFilters = false,
  isPagination = false,
  isInfiniteScroll = false,
  loadingData = false,
  currentPage,
  setCurrentPage,
  pageSize,
  searchTerm,
  setPageSize,
  onChangeSearchTerm = () => {},
  noResultsText = "No results found",
  isLoadMore,
  totalDataCount,
  pageSizeOptions,
  totalDataCountLable = "",
}) => {
  const [sortConfig, setSortConfig] = useState(null);
  const infiniteLoader = useRef(null);

  const handleSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    onSort(key, direction);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
  };

  const handleClearSearch = () => {
    onChangeSearchTerm("");
  };

  const attachObserver = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          totalDataCount > currentPage * pageSize
        ) {
          setCurrentPage(currentPage + 1);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (infiniteLoader.current) {
      observer.observe(infiniteLoader.current);
    }

    return observer;
  };

  useEffect(() => {
    const observer = attachObserver();

    return () => {
      if (infiniteLoader.current) {
        observer.unobserve(infiniteLoader.current);
      }
    };
  }, [data, totalDataCount]);

  return (
    <div className="h-full w-full font-inter">
      {isSearchable || isFilters ? (
        <div
          style={{ padding: "1px" }}
          className="mb-4 flex h-8 w-full items-center">
          {isSearchable && (
            <div
              style={{ width: "95%", height: "100%" }}
              className="relative mr-2 flex items-center border-none">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => onChangeSearchTerm(e.target.value)}
                className="h-full w-full rounded border p-2 text-sm text-gray-500 placeholder-transparent focus:border-primaryAccent focus:ring-primaryAccent"
              />
              {searchTerm ? (
                <XCircleIcon
                  className="absolute right-2 h-5 w-5 cursor-pointer text-gray-400"
                  onClick={handleClearSearch}
                />
              ) : (
                <SearchIcon className="absolute right-2 h-5 w-5 text-gray-400" />
              )}
            </div>
          )}
          {isFilters && (
            <div className="relative mr-2 flex items-center">
              <PrimaryButton
                icon={<FilterIcon className="h-5 w-5" />}
                className="text-sm font-normal"
                height="2rem"
                primaryColor="black"
                borderColor="border-textGray"
                textColor="">
                Filters
              </PrimaryButton>
            </div>
          )}
        </div>
      ) : null}
      {totalDataCountLable && totalDataCountLable != "" && (
        <div
          style={{ padding: "1px" }}
          className="mb-2 flex w-full items-center">
          <span className="text-base font-light text-gray-400">
            {totalDataCountLable}
            {totalDataCount}
          </span>
        </div>
      )}
      <div className="relative h-full w-full overflow-auto">
        {loadingData && !data ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="sticky top-0 z-5 bg-gray-100 shadow-md">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className={`px-6 py-6 text-left text-base font-normal tracking-wider text-[#717679] ${
                      column.stickPosition === "left"
                        ? "sticky left-0 z-5 bg-gray-100 shadow-md"
                        : ""
                    } ${
                      column.stickPosition === "right"
                        ? "sticky right-0 z-5 bg-gray-100 shadow-md"
                        : ""
                    }`}
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}>
                    <div className="flex items-center">
                      <span>{column.title}</span>
                      {column.infoText && (
                        <Tooltip title={column.infoText}>
                          <ExclamationCircleIcon className="ml-1 h-4 w-4 text-gray-500" />
                        </Tooltip>
                      )}
                      {column.isSort && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="ml-1 h-6 w-6 cursor-pointer"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8 9L12 5L16 9M16 15L12 19L8 15"
                            stroke="#717679"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {Array.from({ length: pageSize }).map((_, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className={`whitespace-nowrap px-6 py-6 text-base text-gray-900 ${
                        column.stickPosition === "left"
                          ? "sticky left-0 z-auto bg-white shadow-md"
                          : ""
                      } ${
                        column.stickPosition === "right"
                          ? "z- sticky right-0 bg-white shadow-md"
                          : ""
                      }`}>
                      <Skeleton.Input active />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : data && data?.length === 0 ? (
          <div className="flex w-full flex-col items-center justify-center py-20">
            <img
              src={noResultsImage}
              alt="No results"
              className="mb-4"
              style={{ width: "40%" }}
            />
            <p className="text-xl font-semibold text-gray-600">
              {noResultsText}
            </p>
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="sticky top-0 z-5 bg-gray-50 shadow-sm">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className={`px-6 py-6 text-left text-base font-normal tracking-wider text-[#717679] ${
                      column.stickPosition === "left"
                        ? "sticky left-0 z-5 bg-gray-100 shadow-md"
                        : ""
                    } ${
                      column.stickPosition === "right"
                        ? "sticky right-0 z-5 bg-gray-100 shadow-md"
                        : ""
                    }`}
                    onClick={() => column.isSort && handleSort(column.key)}
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}>
                    <div className="flex items-center">
                      <span>{column.title}</span>
                      {column.infoText && (
                        <Tooltip title={column.infoText}>
                          <ExclamationCircleIcon className="ml-1 h-4 w-4 text-gray-500" />
                        </Tooltip>
                      )}
                      {column.isSort && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="ml-1 h-6 w-6 cursor-pointer"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8 9L12 5L16 9M16 15L12 19L8 15"
                            stroke="#717679"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="w-full divide-y divide-gray-200 bg-white">
              {data?.map((row, rowIndex) => (
                <tr key={rowIndex} className="relative hover:bg-gray-50">
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className={`whitespace-nowrap px-6 py-6 text-base text-gray-600 ${
                        column.stickPosition === "left"
                          ? "sticky left-0 z-auto bg-white shadow-md"
                          : ""
                      } ${
                        column.stickPosition === "right"
                          ? "sticky right-0 z-auto bg-white shadow-md"
                          : ""
                      }`}>
                      {typeof row[column.key] === "function"
                        ? row[column.key]()
                        : row[column.key]}
                    </td>
                  ))}
                </tr>
              ))}
              {isInfiniteScroll &&
                data &&
                totalDataCount > currentPage * pageSize && (
                  <div
                    ref={infiniteLoader}
                    className="sticky left-1/2 flex h-14 w-full -translate-x-1/2 transform items-center justify-center border-none">
                    <LoadingIndicator shouldShowOnPage={false} />
                  </div>
                )}
            </tbody>
          </table>
        )}
      </div>
      {isPagination && !loadingData && (
        <div className="mt-4 flex justify-end">
          <CustomPagination
            showDataCountOnly={isInfiniteScroll} // to disable pagination and enabling the infinite scrolling this prop is important
            currentPage={currentPage}
            pageSize={pageSize}
            totalDataCount={totalDataCount}
            onPageChange={(page) => setCurrentPage(page)}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
            currentDataCount={data?.length || 0}
            pageSizeOptions={pageSizeOptions}
          />
        </div>
      )}
    </div>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      isSort: PropTypes.bool,
      infoText: PropTypes.string,
      stickPosition: PropTypes.oneOf(["left", "right"]),
    }),
  ).isRequired,
  data: PropTypes.array,
  onSort: PropTypes.func,
  isSearchable: PropTypes.bool,
  isFilters: PropTypes.bool,
  isPagination: PropTypes.bool,
  isInfiniteScroll: PropTypes.bool,
  loadingData: PropTypes.bool,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pageSize: PropTypes.number,
  searchTerm: PropTypes.string,
  setPageSize: PropTypes.func,
  onChangeSearchTerm: PropTypes.func,
  noResultsText: PropTypes.string,
  isLoadMore: PropTypes.bool,
  totalDataCount: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  totalDataCountLable: PropTypes.string,
};

export default CustomTable;
