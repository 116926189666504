import withDelmarShippingsLogic from "../HOC/withDelmarShippingsLogic";
import moment from "moment-timezone";
import _ from "lodash";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";
import BatchFilters from "#components/batches/outbound/BatchFilters";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { useContext, useEffect, useState } from "react";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import { renderStatus } from "../../utils/renderStatus";

const noValuesText = "No Shippings";

const SHIPPINGS_TABLE_NAME = "shippingsList";

const SEARCH_TOOL_TIP_CONTENT = `<div style="font-size: 14px; max-width:500px;">
    Within each batch, the search will look for: 
    <b>Order IDs, Tote</b><br />
    Additionally, within the items 
    contained in each batch, the search will look for:<br />
    <b>SKU, ASIN, Product Name, Name, UPC, LPN, Pallet ID, Lot ID, Bin Location, Serial Number, Tote.</b></div>
  `;

const DelmarShippingsList = ({
  displayEntities,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  sort,
  setSort,
  onChangeSearchKeyword,
  submitFilters,
  setShowFilters,
  filters,
  clearKeyword,
  clearFilters,
  showFilters,
  onChangeFilter,
  handleDateRangeClear,
  customers,
  warehouses,
}) => {
  const [headers, setHeaders] = useState([
    getHeaderObject("Order ID", "orderId", false),
    getHeaderObject("Tracking Number", "trackingNumber", false),
    getHeaderObject("Carrier", "carrier"),
    getHeaderObject("Shipping Bin", "shippingBin"),
    getHeaderObject("Bag Number", "bagNumber"),
    getHeaderObject("Truck ID", "truckId"),
    getHeaderObject("Status", "status"),
    getHeaderObject("Completion Time", "completionTime"),
    getHeaderObject("Error", "parcelError"),
  ]);
  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      SHIPPINGS_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);
  return (
    <>
      {filters?.startEndDate && (
        <div className="flex items-end justify-end text-2xl font-bold text-primaryAccent">
          Date Range: {filters.startEndDate[0] || "Beginning"} ~{" "}
          {filters.startEndDate[1] || "Now"}
        </div>
      )}
      <div className="tableWrapper">
        {(customers?.length > 1 || warehouses?.length > 1) && (
          <QuickFilters
            warehouseFilterName={"warehouses"}
            customerFilterName={"customers"}
            customers={customers}
            warehouses={warehouses}
            filters={filters}
            onChangeFilter={onChangeFilter}
          />
        )}
        <SearchSortFilter
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          clearFilters={clearFilters}
          choices={choices}
          headers={headers}
          setShowHeaders={setShowHeaders}
          tableName={SHIPPINGS_TABLE_NAME}
          searchTooltip={SEARCH_TOOL_TIP_CONTENT}
        />

        <CustomTableWithTheme>
          <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
            <tr className="border-left font-montserrat text-textWhite">
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {displayEntities.length === 0 ? (
              <tr className="bg-white">
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <td
                      className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                      key={headerIdx}>
                      {noValuesText}
                    </td>
                  ) : (
                    <td
                      className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {displayEntities.map((entity, rowIndex) => (
              <tr
                key={entity.id}
                className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                {showHeaders.map((header, columnIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    ["id"],
                    columnIndex,
                    rowIndex,
                  );
                  if (value === "orderId") {
                    return (
                      <td className={cellStyle}>
                        {renderValue(entity.orderId)}
                      </td>
                    );
                  }

                  if (value === "trackingNumber") {
                    return (
                      <td className={cellStyle}>
                        {renderValue(entity.trackingNumber)}
                      </td>
                    );
                  }
                  if (value === "carrier") {
                    return (
                      <td className={cellStyle}>
                        {renderValue(entity.carrier)}
                      </td>
                    );
                  }
                  if (value === "shippingBin") {
                    return (
                      <td className={cellStyle}>
                        {renderValue(entity.shippingBin)}
                      </td>
                    );
                  }
                  if (value === "bagNumber") {
                    return (
                      <td className={cellStyle}>
                        {renderValue(entity.bagNumber)}
                      </td>
                    );
                  }
                  if (value === "truckId") {
                    return (
                      <td className={cellStyle}>
                        {renderValue(entity.truckId)}
                      </td>
                    );
                  }
                  if (value === "parcelError") {
                    return (
                      <td className={cellStyle}>
                        {renderValue(entity.parcelError)}
                      </td>
                    );
                  }

                  if (value === "completionTime") {
                    return (
                      <td className={cellStyle}>
                        {moment(entity.createdAt).format("MMM Do YYYY, h:mm A")}
                      </td>
                    );
                  }
                  if (value === "status") {
                    return (
                      <td className={cellStyle}>
                        {renderStatus(entity.status?.toUpperCase())}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </CustomTableWithTheme>
      </div>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
      {showFilters && (
        <BatchFilters
          dateRangeLabel="Select Shipping Date Range"
          showDateRangePicker={true}
          handleDateRangeClear={handleDateRangeClear}
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
        />
      )}
    </>
  );
};

function renderValue(value) {
  return value || "-";
}

export default withDelmarShippingsLogic(DelmarShippingsList);
