import { useEffect, useState } from "react";
import { Menu } from "@headlessui/react";
import { ChevronUpIcon, AdjustmentsIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import PrimaryButton from "../../newUiComponents/commons/PrimaryButton";
import PropTypes from "prop-types";
import _ from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SortDropdown = ({
  sort,
  setSort,
  choices,
  showSortBy = true,
  showIcon = true,
}) => {
  const [outerChoices, setOuterChoices] = useState([
    {
      name: "Report Type (A-Z)",
      value: "module",
    },
    {
      name: "Report Created Date",
      value: "startDate",
    },
    {
      name: "Report Name (A-Z)",
      value: "reportName",
    },
  ]);

  useEffect(() => {
    if (choices) {
      setOuterChoices(choices);
    }
  }, [choices]);

  return (
    <Menu as="div" className="relative inline-block text-left text-lg">
      <div>
        <Menu.Button className="!hover:border-primaryAccent !hover:text-primaryAccent relative mr-2 flex items-center">
          <PrimaryButton
            icon={
              <div className="flex flex-row items-center">
                <AdjustmentsIcon className="h-5 w-5" />

                {sort && sort.indexOf("-") !== -1 ? (
                  <ChevronDownIcon className="h-6 w-6 text-primaryAccent" />
                ) : (
                  <ChevronUpIcon className="h-6 w-6 text-primaryAccent" />
                )}
              </div>
            }
            className="!hover:border-primaryAccent !hover:text-primaryAccent text-sm font-normal"
            height="2rem"
            width="4rem"
            primaryColor="black"
            borderColor="!border-primaryAccent !hover:border-primaryAccent !hover:text-primaryAccent"
            textColor="!text-primaryAccent !hover:border-primaryAccent !hover:text-primaryAccent"
          />
        </Menu.Button>
      </div>
      <Menu.Items className="absolute right-0 z-50 min-w-[240px] origin-top-right rounded-md bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {outerChoices
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((choice, idx) => (
              <Menu.Item key={idx}>
                {({ active }) => (
                  <div
                    onClick={() => setSort(choice.value)}
                    className={classNames(
                      choice.value === sort || "-" + choice.value === sort
                        ? "!border-b-[3px] border-primaryAccent text-primaryAccent"
                        : "!border-b border-gray-200 text-base font-light text-gray-400",
                      "block cursor-pointer px-4 py-2 text-base hover:bg-gray-200",
                    )}>
                    {choice.name}
                  </div>
                )}
              </Menu.Item>
            ))}
        </div>
      </Menu.Items>
    </Menu>
  );
};

SortDropdown.propTypes = {
  sort: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  showSortBy: PropTypes.bool,
  showIcon: PropTypes.bool,
};

export default SortDropdown;
