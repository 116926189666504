import React, { useEffect, useRef, useState } from "react";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import { XIcon } from "@heroicons/react/outline";
import { useActiveDropdown } from "../../contexts/dropdown";

export default function Autocomplete({
  options,
  value,
  useValueDirectly = false,
  onChange,
  labelKey,
  valueKey,
  rounded,
  placeholder,
  onKeyDown,
  boxWidth,
  disabled,
  secondaryLabelKey = null,
  id,
  noResultsText,
}) {
  const { activeDropdown, setActiveDropdown } = useActiveDropdown();
  const dropdownRef = useRef(null);
  const isOpen = activeDropdown === id;
  const [text, setText] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [cursor, setCursor] = useState(-1);

  const select = (option) => {
    onChange(option);
    setShowOptions(false);
    setActiveDropdown(null);
  };

  const handleChange = (text) => {
    setText(text);
    if (onKeyDown) {
      onKeyDown(text);
    }
    setCursor(-1);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const filteredOptions = options
    ? options
        .filter((option) =>
          option[labelKey]?.toLowerCase().includes(text?.toLowerCase()),
        )
        .sort(
          (a, b) =>
            b.frequency - a.frequency || a[labelKey].localeCompare(b[labelKey]),
        )
    : [];

  const moveCursorDown = () => {
    if (cursor < filteredOptions.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e) => {
    switch (e.key) {
      case "ArrowUp":
        moveCursorUp();
        break;
      case "ArrowDown":
        moveCursorDown();
        break;
      case "Enter":
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor][valueKey]);
        }
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Only close if this dropdown is the active one
        if (activeDropdown === id) {
          setActiveDropdown(null);
          setShowOptions(false);
          setCursor(-1);
        }
      }
    };

    // Only add the listener if the dropdown is open
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, activeDropdown, id, setActiveDropdown]);

  const handleToggle = () => {
    if (!disabled) {
      if (isOpen) {
        setActiveDropdown(null);
        setShowOptions(false);
        setCursor(-1);
      } else {
        setActiveDropdown(id);
        setShowOptions(true);
      }
    }
  };

  const renderValue = () => {
    if (useValueDirectly) {
      return value || placeholder;
    }

    return (
      (_.find(options, (e) => e[valueKey] === value) &&
        _.find(options, (e) => e[valueKey] === value)[labelKey]) ||
      placeholder
    );
  };

  return (
    <div
      className={boxWidth ? "relative" : "relative w-full"}
      ref={dropdownRef}>
      <div className="relative flex">
        <div
          className={`\ bg-white\ relative flex h-16 w-full items-center overflow-visible rounded-lg border-2 border-primaryAccent pl-4 text-lg text-gray-700 ${disabled ? "cursor-not-allowed bg-gray-300" : "cursor-pointer"}`}
          onClick={handleToggle}>
          {renderValue()}
        </div>
        <div
          className="absolute right-0 flex h-full w-10 cursor-pointer items-center justify-center text-center text-gray-600"
          onClick={() => select(null)}>
          <XIcon className="h-6 w-6 fill-current" />
        </div>
      </div>
      {showOptions && (
        <>
          {" "}
          <div className="absolute z-50 w-full">
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                placeholder={"Search"}
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={handleNav}
                autoFocus
              />
            </div>
          </div>
          <ul className="bg-sidebar-blue absolute z-50 mt-14 max-h-96 w-full min-w-96 select-none overflow-auto rounded-lg shadow-lg">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, i, arr) => {
                let className = `text-white text-lg cursor-default bg-primaryAccent select-none relative py-4 pl-4 pr-4 border-dropdown-item-bottom-border border-b hover:bg-2C7695 `;

                if (i === 0) className += "rounded-t-lg";
                else if (i === arr.length) className += "rounded-b-lg";
                else if (i === 0 && arr.length === 1) className += "rounded-lg";
                else className += "";

                if (cursor === i) {
                  className += " bg-2C7695 text-white";
                }

                return (
                  <li
                    className={className}
                    key={option[valueKey]}
                    onClick={() => select(option[valueKey])}>
                    {option[labelKey]}{" "}
                    {secondaryLabelKey !== null &&
                      option[secondaryLabelKey] !== null &&
                      `(${option[secondaryLabelKey]})`}
                  </li>
                );
              })
            ) : (
              <li className="border-dropdown-item-bottom-border relative cursor-default select-none border-b bg-primaryAccent py-4 pl-4 pr-4 text-lg text-white hover:bg-2C7695 hover:text-2C7695">
                {!text ? "Start Typing" : noResultsText || "No results"}
              </li>
            )}
          </ul>
        </>
      )}
    </div>
  );
}
