import React, { useState, useEffect, useContext, Fragment } from "react";
import { Transition } from "@headlessui/react";
import PrimaryButton from "../commons/PrimaryButton";
import CheckBox from "../commons/CheckBox";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import noResultsImage from "#static/images/nofilter.png";
import { AuthContext } from "../../contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { useQuery } from "../../hooks/useQuery";
import { GET_PRODUCTS, GET_PARENT_SKU, GET_ENTITY_TYPES } from "../../queries";
import CustomNotification from "../commons/CustomNotification";
import { DEFAULT_SEARCH_FIELDS } from "../../components/catalogs/catalogTabs/CatalogEnhancedSearch";
import NewModal from "../commons/NewModal";
import { fetchVariantAttributes } from "#components/products/ProductForm";
const SEARCH_DEBOUNCE_TIME_IN_MS = 500;
const PER_PAGE_PRODUCT_RESULTS = 25;
import _ from "lodash";

function SearchAndAddProducts({
  selectedProductIds,
  setSelectedProductIds,
  fetchCatalogProduct,
  automaticallyFetchParentProductId,
  presentProducts = [],
}) {
  const appState = useContext(AppStateContext);
  const parentProductQuery = useQuery(GET_PARENT_SKU);
  const entityTypesQuery = useQuery(GET_ENTITY_TYPES);
  const [selectedParentProduct, setSelectedParentProduct] = useState(null);
  const [variantAttributes, setVariantAttributes] = useState([]);
  const [entityAttributes, setEntityAttributes] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchFields, setSearchFields] = useState(DEFAULT_SEARCH_FIELDS);
  const [searchedProductsConfig, setSearchedProductsConfig] = useState({
    searchedProducts: [],
    pageNumber: 1,
  });
  const [productsLoading, setProductsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const auth = useContext(AuthContext);
  const notify = CustomNotification();
  const getProductsQuery = useQuery(GET_PRODUCTS);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedVariants, setSelectedVariants] = useState([]);

  useEffect(() => {
    if (automaticallyFetchParentProductId) {
      fetchParentProduct(automaticallyFetchParentProductId);
    }
  }, [automaticallyFetchParentProductId]);

  useEffect(() => {
    const attributes = variantAttributes.reduce((result, obj) => {
      const entityParent = obj["entityParent"];
      if (!result[entityParent]) {
        result[entityParent] = [];
      }
      result[entityParent].push(obj.name);
      return result;
    }, {});

    setEntityAttributes(attributes);
  }, [variantAttributes]);

  const fetchParentProduct = async (id) => {
    appState.setLoading();
    const response = await parentProductQuery.fetchData({ id });

    if (response.data) {
      const fetchedParentProduct = response.data.parentSku;
      setSelectedParentProduct(fetchedParentProduct);
      await fetchVariantAttributes(
        fetchedParentProduct,
        entityTypesQuery,
        setVariantAttributes,
      );
    }

    if (response.error) {
      setSelectedParentProduct(null);
    }

    appState.removeLoading();
  };

  const handleSearchProducts = async (searchText, pageNumber = 1) => {
    if (auth && auth.user && auth.user.customers) {
      if (pageNumber === 1) setFilteredProducts([]);
      setProductsLoading(true);
      const searchedProductsResponse = await getProductsQuery.fetchData({
        perPage: PER_PAGE_PRODUCT_RESULTS,
        pageNumber,
        filters: {
          keyword: searchText,
          customer: auth.user.customers,
          searchFields: searchFields
            .filter((i) => i.enabled)
            .map((i) => i.value),
        },
        paginated: false,
        sort: "-createdAt",
      });
      setProductsLoading(false);
      const searchedParentProducts =
        searchedProductsResponse?.data?.products?.parentProducts || [];
      const searchedProducts =
        searchedProductsResponse?.data?.products?.entities.filter(
          (i) => !i.parentProduct,
        ) || [];
      if (searchedProducts.length < PER_PAGE_PRODUCT_RESULTS) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      if (searchedProducts) {
        setSearchedProductsConfig((prevConfig) => ({
          searchedProducts:
            pageNumber === 1
              ? [
                  ...searchedParentProducts.map((i) => ({
                    ...i,
                    isParentProduct: true,
                  })),
                  ...searchedProducts,
                ]
              : [
                  ...prevConfig.searchedProducts,
                  ...searchedParentProducts.map((i) => ({
                    ...i,
                    isParentProduct: true,
                  })),
                  ...searchedProducts,
                ],
          pageNumber,
        }));
      }
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    if (value) {
      handleSearchProducts(value, 1);
    } else {
      setSearchedProductsConfig({ searchedProducts: [], pageNumber: 1 });
    }
  };

  useDebouncedEffect(
    () => handleSearch(searchTerm.trim()),
    SEARCH_DEBOUNCE_TIME_IN_MS,
    [searchTerm],
  );

  useEffect(() => {
    if (searchedProductsConfig.searchedProducts.length) {
      setFilteredProducts(
        searchedProductsConfig.searchedProducts.map((product) => ({
          id: product.id,
          name: product.name,
          sku: product.sku,
          upc: product?.upc?.join(", "),
          image:
            product.images && product.images[0]?.display_url
              ? product.images[0]?.display_url
              : FALLBACK_IMAGE_URL,
          isParentProduct: product.isParentProduct,
        })),
      );
    } else {
      setFilteredProducts([]);
    }
  }, [searchedProductsConfig]);

  useEffect(() => {
    if (searchFields.length > 0 && searchTerm)
      handleSearchProducts(searchTerm, 1);
  }, [searchFields]);

  const handleAttributeChange = (attribute, value) => {
    setSelectedAttributes((prev) => {
      const currentValues = prev[attribute] || [];
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];

      if (updatedValues.length > 0) {
        return {
          ...prev,
          [attribute]: updatedValues.length > 0 ? updatedValues : undefined,
        };
      } else {
        return {
          ..._.omit(prev, [attribute]),
        };
      }
    });
  };

  useEffect(() => {
    if (!selectedParentProduct || !selectedParentProduct.variants?.length)
      return;

    if (Object.keys(selectedAttributes).length === 0) {
      setSelectedVariants([]);
      return;
    }

    const filteredVariants = selectedParentProduct.variants.filter(
      (variant) => {
        return Object.entries(selectedAttributes).every(
          ([attribute, values]) => {
            if (!values || values.length === 0) return true; // If no values are selected for this attribute, don't filter
            const attributeKey = attribute
              .replace(/_ATTRIBUTE_VALUES/g, "")
              .toLowerCase();
            return values.includes(variant.attributes[attributeKey]);
          },
        );
      },
    );
    setSelectedVariants(filteredVariants);
  }, [selectedAttributes, selectedParentProduct]);

  const getAvailableAttributeValues = (attribute) => {
    const otherAttributes = Object.keys(selectedAttributes).filter(
      (attr) => attr !== attribute,
    );
    return selectedParentProduct.variants
      .filter((variant) =>
        otherAttributes.every((attr) => {
          const attrKey = attr.replace(/_ATTRIBUTE_VALUES/g, "").toLowerCase();
          const selectedValues = selectedAttributes[attr];
          return (
            !selectedValues ||
            selectedValues.length === 0 ||
            selectedValues.includes(variant.attributes[attrKey])
          );
        }),
      )
      .map(
        (variant) =>
          variant.attributes[
            attribute.replace(/_ATTRIBUTE_VALUES/g, "").toLowerCase()
          ],
      );
  };

  const submitSelectedVariants = () => {
    if (!selectedVariants || selectedVariants.length === 0) {
      notify.error("Please select at least one variant");
      return;
    }
    const selectedVariantsIds = selectedVariants.map((variant) => variant.id);
    setSelectedProductIds(selectedVariantsIds);
    fetchCatalogProduct(selectedVariantsIds, () => {
      notify.success(
        `Successfully added ${selectedVariants.length} product variants for ${selectedParentProduct.sku}.`,
      );
      setSelectedVariants([]);
      setSelectedAttributes({});
    });
    setSelectedParentProduct(null);
    handleSearch("");
  };

  return (
    <div className="relative w-full">
      <input
        type="search"
        placeholder="Search Products..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full rounded border bg-white p-2 text-gray-700 transition-colors duration-300 focus:border-primaryAccent focus:ring-primaryAccent"
      />
      {searchTerm && (
        <Transition
          as={Fragment}
          show={true}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-2">
          <div
            style={{ maxHeight: "28rem" }}
            className="absolute z-20 mt-2 w-full cursor-pointer overflow-auto rounded border bg-white p-6 pb-0 pt-0 shadow-lg">
            <div className="sticky top-0 z-10 flex flex-wrap items-center bg-white p-4">
              <h2 className="text-base font-semibold text-[#717679]">
                Criteria:
              </h2>
              {searchFields.map((field) => (
                <button
                  key={field.value}
                  className={`m-2 w-auto p-2 ${
                    field.enabled
                      ? "bg-primaryAccent text-white"
                      : "bg-[#F1F8FF] text-primaryAccent"
                  } rounded-md text-sm font-semibold`}
                  onClick={() => {
                    if (searchFields.length === 1) {
                      return;
                    }
                    // DISABLE THE FIELD
                    const updatedFields = searchFields.map((f) => {
                      if (f.value === field.value) {
                        return {
                          ...f,
                          enabled: !field.enabled,
                        };
                      }
                      return f;
                    });
                    setSearchFields(updatedFields);
                  }}>
                  {field.name}
                </button>
              ))}
            </div>
            {productsLoading && searchedProductsConfig.pageNumber === 1 && (
              <div className="flex items-center justify-center">
                <LoadingIndicator shouldShowOnPage={false} />
              </div>
            )}
            {!productsLoading && filteredProducts.length === 0 && (
              <div className="flex flex-col items-center justify-center">
                <img className="w-1/4" src={noResultsImage} alt="No results" />
                <div className="mt-2 text-center font-inter text-xl font-semibold text-black">
                  No Results found
                </div>
                <div className="mt-2 text-center text-gray-400">
                  We couldn't find the product you have searched for, please try
                  adjusting your search to find what you are looking for .
                </div>
              </div>
            )}
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className="flex items-center p-4 hover:bg-gray-100"
                onClick={() => {
                  if (product.isParentProduct) {
                    fetchParentProduct(product.id);
                  } else {
                    if (selectedProductIds.some((id) => id == product.id))
                      setSelectedProductIds(
                        selectedProductIds.filter((id) => id != product.id),
                      );
                    else
                      setSelectedProductIds([
                        ...selectedProductIds,
                        product.id,
                      ]);
                  }
                }}>
                {!product.isParentProduct && (
                  <CheckBox
                    checked={selectedProductIds.some((id) => id == product.id)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        setSelectedProductIds([
                          ...selectedProductIds,
                          product.id,
                        ]);
                      } else {
                        setSelectedProductIds((prev) =>
                          prev.filter((id) => id != product.id),
                        );
                      }
                    }}
                    width="1rem"
                    height="1rem"
                    className="mr-4"
                  />
                )}

                <img
                  src={product.image || "https://via.placeholder.com/64"}
                  alt={product.name}
                  className="mr-2 h-16 w-16"
                />
                <div>
                  <div className="font-medium">{product.name}</div>
                  <div className="text-sm text-gray-500">
                    SKU: {product.sku}
                  </div>
                  {product.upc && (
                    <div className="text-sm text-gray-500">
                      UPC: {product.upc}
                    </div>
                  )}
                </div>
              </div>
            ))}
            {productsLoading && searchedProductsConfig.pageNumber > 1 && (
              <div className="flex items-center justify-center">
                <LoadingIndicator shouldShowOnPage={false} />
              </div>
            )}
            {hasMore && !productsLoading && filteredProducts.length > 0 && (
              <div className="flex items-center justify-center">
                <PrimaryButton
                  height="2rem"
                  variant="primary"
                  className="mt-2 text-sm font-medium"
                  onClick={() =>
                    handleSearchProducts(
                      searchTerm,
                      searchedProductsConfig.pageNumber + 1,
                    )
                  }>
                  Load More
                </PrimaryButton>
              </div>
            )}
            <div className="sticky bottom-0 mt-4 flex w-full items-center justify-end gap-4 border-t bg-white py-4">
              <PrimaryButton
                height="2rem"
                width="6rem"
                className="mt-2 text-sm font-medium"
                onClick={() => {
                  handleSearch("");
                  setSelectedProductIds([]);
                  setSelectedVariants([]);
                  setSelectedParentProduct(null);
                }}>
                Cancel
              </PrimaryButton>
              <PrimaryButton
                height="2rem"
                variant="primary"
                className="mt-2 text-sm font-medium"
                disabled={selectedProductIds.length === 0}
                onClick={() => {
                  fetchCatalogProduct(selectedProductIds, () => {
                    notify.success(
                      selectedProductIds.length == 1
                        ? "Successfully added selected product."
                        : "Successfully added selected products.",
                    );
                  });
                  handleSearch("");
                }}>
                Add Selected ({selectedProductIds.length || 0})
              </PrimaryButton>
            </div>
          </div>
        </Transition>
      )}
      {selectedParentProduct && (
        <NewModal
          isOpen={true}
          onClose={() => setSelectedParentProduct(null)}
          title="Select Product Variant"
          subtitle="This product has multiple variants, please select the variant you want to add."
          maxHeight="100%">
          <div className="flex items-center p-4">
            <img
              src={selectedParentProduct.image || FALLBACK_IMAGE_URL}
              alt={selectedParentProduct.name}
              className="mr-2 h-16 w-16"
            />
            <div>
              <div className="font-medium">{selectedParentProduct.name}</div>
              <div className="text-sm text-gray-500">
                SKU: {selectedParentProduct.sku}
              </div>
            </div>
          </div>
          {Object.keys(entityAttributes).length > 0 && (
            <div className="mt-4 space-y-4">
              {Object.keys(entityAttributes).map((attribute) => (
                <div key={attribute}>
                  <h5 className="pb-2 font-medium">
                    {attribute.replace(/_ATTRIBUTE_VALUES/g, "")}
                  </h5>
                  <div className="flex flex-wrap gap-2">
                    {entityAttributes[attribute].map((attributeValue) => {
                      const availableValues =
                        getAvailableAttributeValues(attribute);
                      const isAvailable =
                        availableValues.includes(attributeValue);
                      return (
                        <div
                          key={attributeValue}
                          className={`flex items-center rounded border border-gray-300 p-2 ${
                            !isAvailable ? "bg-gray-200" : "bg-white"
                          }`}>
                          <CheckBox
                            id={`${attribute}-${attributeValue}`}
                            checked={(
                              selectedAttributes[attribute] || []
                            ).includes(attributeValue)}
                            onChange={() =>
                              handleAttributeChange(attribute, attributeValue)
                            }
                            disabled={!isAvailable}
                          />
                          <label
                            htmlFor={`${attribute}-${attributeValue}`}
                            className="ml-2">
                            {attributeValue}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="mt-4 flex w-full items-center justify-end gap-4">
            <div className="mt-4 flex w-full items-center justify-end gap-4 text-lg font-medium">
              Selected Variants: {selectedVariants.length}
            </div>
            <PrimaryButton
              height="3rem"
              width="6rem"
              className="mt-2 text-base font-medium"
              onClick={() => {
                setSelectedParentProduct(null);
                setSelectedVariants([]);
              }}>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              height="3rem"
              width="6rem"
              variant="primary"
              className="mt-2 text-base font-medium"
              onClick={submitSelectedVariants}
              disabled={selectedVariants.length === 0}>
              Continue
            </PrimaryButton>
          </div>
        </NewModal>
      )}
    </div>
  );
}

export default SearchAndAddProducts;
