const QualityCheckerNewBatchRequest = ({ getNewQualityChecking }) => (
  <div className="-mt-20 flex h-screen items-center justify-center space-x-4">
    <div
      className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
      onClick={getNewQualityChecking}>
      Get New Batch
    </div>
  </div>
);

export default QualityCheckerNewBatchRequest;
