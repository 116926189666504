import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import ModalButton from "#components/utils/ModalButton";

const VendorFilters = ({
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  classifications,
  clearFilters,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="mt-4 flex space-x-6">
      <ModalButton
        onClick={clearFilters}
        text={`Reset Filters`}
        className="bg-red-500"
      />
    </div>
    <div className="mt-4 flex justify-between space-x-6">
      <>
        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Vendor Classifiaction(s)
            </span>
          </label>
          <MultiSelectAutoComplete
            options={classifications.map((classification) => {
              return {
                value: classification,
                name: classification,
              };
            })}
            labelKey="name"
            valueKey="value"
            setValues={(e) => onChangeFilter("classifications", e)}
            values={filters["classifications"]}
            rounded={true}
          />
        </div>
      </>
    </div>
  </Modal>
);

export default VendorFilters;
