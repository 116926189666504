import _ from "lodash";
import CustomSelectDropDown from "./CustomSelectDropDown";
import { useEffect, useState } from "react";
import Autocomplete from "#components/utils/Autocomplete";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { XIcon } from "@heroicons/react/outline";

const QuickFilters = ({
  customers,
  warehouses,
  warehouseFilterName,
  customerFilterName,
  onChangeFilter,
  filters,
  statuses,
  isInventoryLoading,
  isIntegrationPage,
  selectedWarehouse,
  applyCustomZIndexForTopQuickFilters = false,
  width = "1/2",
  showGroupByFilter = false,
  groupByOptions,
  groupByFilterName,
  groupConsignmentOn,
  clearFilters,
}) => {
  let wareHouseObjectList = [
    { name: "All Warehouses", id: null },
    ...(
      warehouses?.map((warehouse) => {
        return {
          id: warehouse.id,
          name: `${warehouse.name}`,
        };
      }) ?? []
    ).sort((a, b) => a.name.localeCompare(b.name)),
  ];

  if (isIntegrationPage) {
    //removing All warehouses and All Clients option from list as we dont need it in integration page.
    wareHouseObjectList = wareHouseObjectList.filter((warehouse) => {
      return warehouse.name != "All Warehouses";
    });
  }

  const [customersObjectList, setCustomersObjectList] = useState(
    getFilteredCustomers(
      filters[warehouseFilterName],
      customers,
      isIntegrationPage,
    ),
  );

  const STATUS_OBJECT_LIST = [
    { name: "All", id: statuses?.map((item) => item.name) },
    ...(
      statuses?.map((status) => ({
        id: status.name,
        name: status.name,
      })) ?? []
    ).sort((a, b) => a.name.localeCompare(b.name)),
  ];

  return (
    <div className={`my-4 flex w-${width} items-center justify-between gap-4`}>
      {warehouses && warehouses.length > 1 && (
        <div className="flex-1">
          <Autocomplete
            options={wareHouseObjectList}
            labelKey="name"
            disabled={isInventoryLoading}
            valueKey="id"
            onChange={(selectedOption) => {
              if (isIntegrationPage && selectedOption == null) {
                if (selectedWarehouse) selectedOption = selectedWarehouse;
                else selectedOption = wareHouseObjectList[0].id;
              }
              const filterValue = determineValue(selectedOption);
              setCustomersObjectList(
                getFilteredCustomers(filterValue, customers, isIntegrationPage),
              );
              if (filterValue.length === 0) {
                onChangeFilter(warehouseFilterName, null, true);
              } else {
                onChangeFilter(warehouseFilterName, filterValue, true);
              }
            }}
            value={getCurrentValueOfFilter(
              filters[warehouseFilterName],
              wareHouseObjectList,
            )}
            placeholder={!isIntegrationPage ? "All Warehouses" : "Warehouses"}
            id={`SELECT_WAREHOUSE`}
          />
        </div>
      )}
      {customers && customers.length > 1 && (
        <div className="flex-1">
          <Autocomplete
            options={customersObjectList}
            labelKey="name"
            valueKey="id"
            onChange={(selectedOption) => {
              const filterValue = determineValue(selectedOption);
              if (filterValue.length === 0) {
                onChangeFilter(customerFilterName, null, true);
              } else {
                onChangeFilter(customerFilterName, filterValue, true);
              }
            }}
            disabled={isInventoryLoading}
            value={getCurrentValueOfFilter(
              filters[customerFilterName],
              customersObjectList,
            )}
            placeholder={!isIntegrationPage ? "All Clients" : "Clients"}
            id={`SELECT_CUSTOMER`}
          />
        </div>
      )}
      {statuses && statuses.length > 0 && (
        <div className="flex-1">
          <Autocomplete
            options={STATUS_OBJECT_LIST}
            labelKey="name"
            valueKey="id"
            onChange={(selectedOption) => {
              const filterValue = determineValue(selectedOption);
              if (filterValue.length === 0) {
                onChangeFilter("status", null, true);
              } else {
                if (typeof selectedOption === "string") {
                  onChangeFilter("status", [selectedOption], true);
                } else {
                  onChangeFilter("status", [...selectedOption], true);
                }
              }
            }}
            value={getCurrentValueOfFilter(
              filters["status"],
              STATUS_OBJECT_LIST,
            )}
            placeholder={"All Statuses"}
            id={`SELECT_STATUS`}
          />
        </div>
      )}

      {showGroupByFilter && (
        <div className="flex-1">
          <AutocompleteSingleSelectDropdown
            options={groupByOptions}
            labelKey="name"
            valueKey="id"
            onChange={(selectedOption) => {
              onChangeFilter(groupByFilterName, selectedOption, true);
            }}
            value={filters[groupByFilterName]}
            placeholder={"Group By"}
            id={`GROUP_BY`}
          />
        </div>
      )}
      {groupConsignmentOn && (
        <div className="flex items-center rounded-md bg-white p-2 shadow">
          <div className="text-lg text-black">Tracking Number:</div>
          <div className="ml-2 text-lg text-primaryAccent">
            {groupConsignmentOn}
          </div>
          <XIcon
            className="ml-2 h-6 w-6 cursor-pointer text-red-600"
            onClick={clearFilters}
          />
        </div>
      )}
    </div>
  );
};

/**
 * filters the customers based on the warehouse selected in the dropdown.
 * @param {Array<string>} selectedWarehouses - The value of filters[warehouseFilterName] which is array of string ids for the option all.
 * @param {Array<Object>} customers - An array of customers object
 * @returns {Array<Object>} An array of objects with customer id as value & name as label.
 */
function getFilteredCustomers(
  selectedWarehouses,
  customers,
  isIntegrationPage = false,
) {
  // incase the function being called when customers doesn't exists.
  if (!customers || customers.length < 1) {
    return [];
  }
  let filteredCustomers = [];
  if (selectedWarehouses && selectedWarehouses.length === 1) {
    // option chosen is not All.
    // filtered customers should only be related to selectedWarehouse
    filteredCustomers = customers.filter(
      (customer) =>
        customer.warehouses &&
        customer.warehouses.includes(selectedWarehouses[0]),
    );
  } else if (selectedWarehouses) {
    filteredCustomers = customers.filter(
      (customer) =>
        customer.warehouses && customer.warehouses.includes(selectedWarehouses),
    );
  } else {
    // either selectedWarehouse is undefined or null as the property warehouseFilterName is not in filters object
    // or its an array of string where the option All is chosen.
    // return all the customers including option All for CUSTOMERS_OBJECT_LIST
    filteredCustomers = customers;
  }
  const finalCustomers = filteredCustomers
    .map((customer) => {
      return {
        name: `${customer.name} - ${customer.code}`,
        id: customer.id,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  if (isIntegrationPage) {
    return finalCustomers;
  }
  return [
    { name: "All", id: filteredCustomers.map((customer) => customer.id) },
    ...finalCustomers,
  ];
}

const determineValue = (selectedOption) => {
  if (!selectedOption) return [];

  if (typeof selectedOption === "string") {
    return [selectedOption];
  }

  return [...selectedOption];
};

export const getCurrentValueOfFilter = (checkValue, checkList) => {
  if (!checkValue) return "";

  if (Array.isArray(checkValue)) {
    if (checkValue.length > 1) return "";
    else return checkList.find((option) => option.id === checkValue[0])?.id;
  } else return checkList.find((option) => option.id === checkValue)?.id;
};

export default QuickFilters;
