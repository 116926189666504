import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import {
  GET_ALL_BATCHES,
  GET_USERS,
  FETCH_SPECIFIC_BATCH,
  GET_BATCHES_FOR_STATS,
} from "#queries";
import moment from "moment-timezone";

const withPutawaysLogic = (WrappedComponent) => {
  return (props) => {
    const [expandedBatch, setExpandedBatch] = useState(null);
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);
    const fetchSpecificBatch = useQuery(FETCH_SPECIFIC_BATCH);
    const batchesQuery = useQuery(GET_ALL_BATCHES);
    const usersQuery = useQuery(GET_USERS);
    const batchesForStatsQuery = useQuery(GET_BATCHES_FOR_STATS);
    const batchesForProductivityStatsQuery = useQuery(GET_BATCHES_FOR_STATS);
    const [showFilters, setShowFilters] = useState(false);
    const [batchItems, setBatchItems] = useState(false);

    const [showView, setShowView] = useState("BATCHES");

    const [putawaysTableData, setPutawaysTableData] = useState(null);
    const [stats, setStats] = useState(null);
    const [productivityStats, setProductivityStats] = useState(null);
    const [selectedAssociate, setSelectedAssociate] = useState(null);
    const auth = useContext(AuthContext);
    const [usersMap, setUsersMap] = useState({});

    useEffect(() => {
      usersQuery.fetchData({
        perPage: 1000,
        paginated: false,
      });
    }, []);

    useEffect(() => {
      if (usersQuery.data?.users?.entities) {
        let usersData = usersQuery.data.users.entities;
        let usersDataMap = {};
        usersData.forEach((user) => {
          usersDataMap[`${user.id}`] = user;
        });
        setUsersMap(usersDataMap);
      }
    }, [usersQuery.data]);

    useEffect(() => {
      if (fetchSpecificBatch.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchSpecificBatch.data) {
        setExpandedBatch(fetchSpecificBatch.data.batch);
      }

      if (fetchSpecificBatch.error) {
        setExpandedBatch(null);
      }
    }, [
      fetchSpecificBatch.loading,
      fetchSpecificBatch.error,
      fetchSpecificBatch.data,
    ]);

    useEffect(() => {
      if (batchesForStatsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (batchesForStatsQuery.data) {
        const users = usersQuery?.data.users.entities;
        const batches = batchesForStatsQuery.data.exportBatches.filter(
          (item) =>
            ["COMPLETED", "PUTAWAY_COMPLETED"].includes(item.status) === true,
        );
        const groupedByUser = _.groupBy(batches, "user");
        const stats = {};
        for (const key in groupedByUser) {
          const usersBatches = groupedByUser[key].sort(
            (a, b) => a.assignedTime - b.assignedTime,
          );
          const itemsProcessed = _.sumBy(usersBatches, "completedItems");
          const mappedActiveMinutes = usersBatches.map((item) =>
            moment(item.assignedTime).diff(
              moment(item.attributes.lastCompletedTime),
              "minutes",
            ),
          );
          const activeMinutes = Math.abs(_.sum(mappedActiveMinutes));
          stats[key] = {
            name: users.find((item) => item.id === key)?.name,
            itemsProcessed,
            firstBatchAssignedAt: moment(usersBatches[0].assignedTime).format(
              "YYYY-MM-DD hh:mm a",
            ),
            lastBatchAssignedAt: moment(
              usersBatches[usersBatches.length - 1].assignedTime,
            ).format("YYYY-MM-DD hh:mm a"),
            activeMinutes,
            timePerItem: ((activeMinutes * 60) / itemsProcessed).toFixed(2),
            itemsPerHour: ((itemsProcessed / activeMinutes) * 60).toFixed(2),
          };
        }
        setStats(stats);
      }

      if (batchesForStatsQuery.error) {
        appState.setAlert(batchesForStatsQuery.error.message, "error", 5000);
      }
    }, [
      batchesForStatsQuery.loading,
      batchesForStatsQuery.error,
      batchesForStatsQuery.data,
    ]);

    useEffect(() => {
      if (batchesForProductivityStatsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (batchesForProductivityStatsQuery.data) {
        const users = usersQuery?.data.users.entities;
        const batches =
          batchesForProductivityStatsQuery.data.exportBatches.filter(
            (item) =>
              ["COMPLETED", "PUTAWAY_COMPLETED"].includes(item.status) === true,
          );
        const groupedByUser = _.groupBy(batches, "user");
        const stats = {};
        for (const key in groupedByUser) {
          const usersBatches = groupedByUser[key].sort(
            (a, b) => a.assignedTime - b.assignedTime,
          );
          const itemsProcessed = _.sumBy(usersBatches, "completedItems");
          const mappedActiveMinutes = usersBatches.map((item) =>
            moment(item.assignedTime).diff(
              moment(item.attributes.lastCompletedTime),
              "minutes",
            ),
          );
          const activeMinutes = Math.abs(_.sum(mappedActiveMinutes));
          stats[key] = {
            name: users.find((item) => item.id === key)?.name,
            itemsProcessed,
            firstBatchAssignedAt: moment(usersBatches[0].assignedTime).format(
              "YYYY-MM-DD hh:mm a",
            ),
            lastBatchAssignedAt: moment(
              usersBatches[usersBatches.length - 1].assignedTime,
            ).format("YYYY-MM-DD hh:mm a"),
            activeMinutes,
            timePerItem: ((activeMinutes * 60) / itemsProcessed).toFixed(2),
            itemsPerHour: ((itemsProcessed / activeMinutes) * 60).toFixed(2),
          };
        }
        setProductivityStats(stats);
      }

      if (batchesForProductivityStatsQuery.error) {
        appState.setAlert(
          batchesForProductivityStatsQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      batchesForProductivityStatsQuery.loading,
      batchesForProductivityStatsQuery.error,
      batchesForProductivityStatsQuery.data,
    ]);

    useEffect(() => {
      loadFirstTimeData();

      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    const loadFirstTimeData = () => {
      const setFilters = {
        typeOfBatch: "PUTAWAY",
        startEndDate: [
          moment().subtract(1, "w").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      };
      entity.setFilters(setFilters);

      batchesQuery.fetchData({
        perPage: entity.perPage,
        filters: setFilters,
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    };

    const handleDateRangeClear = () => {
      const setFilters = {
        typeOfBatch: "RECEIVING",
        startEndDate: [
          moment().subtract(1, "w").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      };
      entity.setFilters(setFilters);
    };

    useEffect(() => {
      if (batchesQuery.loading) appState.setLoading();
      else appState.removeLoading();

      if (batchesQuery.data && batchesQuery.data.getAllBatches) {
        entity.setEntities({
          ...batchesQuery.data.getAllBatches,
          ...batchesQuery.variables,
        });
      }
    }, [batchesQuery.loading, batchesQuery.error, batchesQuery.data]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return batchesQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        putawaysTableResult={putawaysTableData}
        batchItems={batchItems}
        setBatchItems={setBatchItems}
        allData={batchesQuery.data ? batchesQuery.data.getAllBatches : {}}
        batches={entity.entities}
        displayBatches={entity.displayEntities}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          batchesQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        handleDateRangeClear={handleDateRangeClear}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            batchesQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        showView={showView}
        setShowView={setShowView}
        users={usersQuery.data?.users ? usersQuery.data.users.entities : []}
        expandBatch={(batchId) => fetchSpecificBatch.fetchData({ batchId })}
        expandedBatch={expandedBatch}
        setExpandedBatch={setExpandedBatch}
        stats={stats}
        setStats={setStats}
        productivityStats={productivityStats}
        setProductivityStats={setProductivityStats}
        fetchBatchesForStats={() =>
          batchesForStatsQuery.fetchData({
            filters: entity.filters,
          })
        }
        fetchBatchesForProductivityStats={() =>
          batchesForProductivityStatsQuery.fetchData({
            filters: entity.filters,
          })
        }
        setSelectedAssociate={setSelectedAssociate}
        selectedAssociate={selectedAssociate}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        usersMap={usersMap}
      />
    );
  };
};

export default withPutawaysLogic;
