import { useState } from "react";
import Select from "react-select";

const SelectCustomerCode = ({ customers, onSubmit, onSkip }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  return (
    <div className="-mt-20 flex h-screen w-full items-center justify-center">
      <div className="flex-col">
        <div className="rounded-2xl border bg-white shadow-lg">
          <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
            <Select
              options={
                customers &&
                customers
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => ({
                    value: item.id,
                    label: `${item.name} - ${item.code}`,
                  }))
              }
              value={selectedCustomer}
              onChange={(e) => setSelectedCustomer(e)}
              className="w-96"
              placeholder="Select Client"
              isClearable={true}
            />
            <div className="flex space-x-2">
              {onSkip && (
                <div
                  className={`mt-4 flex-1 rounded-md bg-red-400 py-2 text-center text-xl text-white`}
                  onClick={onSkip}>
                  Skip
                </div>
              )}

              <div
                className={`mt-4 flex-1 rounded-md py-2 text-center text-xl text-white ${
                  !!selectedCustomer ? "bg-2C7695" : "bg-gray-400"
                }`}
                onClick={() =>
                  !!selectedCustomer
                    ? onSubmit(selectedCustomer)
                    : console.log()
                }>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCustomerCode;
