export default function cellStyleForTable(
  value,
  columnId,
  columnIndex,
  rowIndex,
  extraPadding = false,
) {
  const isValueInColumnId = columnId.includes(value);
  const isEvenRow = rowIndex % 2 === 0;
  const isFirstColumn = columnIndex === 0;

  const textColor = isValueInColumnId
    ? "text-primaryAccent hover:underline cursor-pointer"
    : "text-tableText";

  let borderStyle;
  if (isFirstColumn) {
    borderStyle = `p-5 text-left font-semibold tracking-wider rounded-tl rounded-bl border-l-8 ${
      isEvenRow ? "border-F4C261" : "border-primaryAccent"
    }`;
  } else if (extraPadding) {
    borderStyle = `px-2 pl-4 py-3 text-left font-medium tracking-wider rounded-br rounded-tr`;
  } else {
    borderStyle =
      "pl-4 px-1 py-1 text-left font-medium tracking-wider rounded-br rounded-tr";
  }

  return `${textColor} ${borderStyle}`;
}
