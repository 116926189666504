import React, { useState } from "react";
import { Tooltip } from "antd";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import { handleGetQuantityDetailOfLineItem } from "./PoUtils";
import NewModal from "#newUiComponents/commons/NewModal";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { useHistory } from "react-router-dom";
const ViewShippedProductsMain = ({ products, shippingDetails }) => {
  const history = useHistory();
  const [showConsignmentIdsModal, setShowConsignmentIdsModal] = useState(false);
  const [consignmentIds, setConsignmentIds] = useState([]);

  const getConsignmentDetails = (lineItemId) => {
    const consignmentList = [];

    shippingDetails.forEach((sd) => {
      const relevantLineItem = sd.shippingLineItems.find(
        (sli) => sli.lineItemId === lineItemId,
      );
      if (relevantLineItem?.consignment) {
        consignmentList.push({
          orderId: relevantLineItem.consignment.orderId,
          id: relevantLineItem.consignment.id,
          timestamp: sd.timestamp,
        });
      }
    });
    if (consignmentList.length === 0) return "";
    consignmentList.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
    );

    const latestConsignment = consignmentList[0].orderId;
    const latestConsignmentId = consignmentList[0].id;

    const additionalConsignments = consignmentList.length - 1;
    return [
      `${latestConsignment}${additionalConsignments > 0 ? ` +${additionalConsignments}` : ""}`,
      additionalConsignments,
      latestConsignmentId,
    ];
  };

  const handleGetConsignmentIds = (lineItemId) => {
    const consignmentList = [];

    shippingDetails.forEach((sd) => {
      const relevantLineItem = sd.shippingLineItems.find(
        (sli) => sli.lineItemId === lineItemId,
      );

      if (relevantLineItem?.consignment) {
        consignmentList.push({
          consignmentNo: (
            <span
              className="cursor-pointer underline"
              onClick={() => {
                history.push(
                  `/consignments/${relevantLineItem.consignment.id}`,
                );
              }}>
              {relevantLineItem.consignment.orderId}
            </span>
          ),
        });
      }
    });

    if (consignmentList.length > 0) {
      setShowConsignmentIdsModal(true);
      setConsignmentIds(consignmentList);
    }
  };

  return (
    <>
      <div className="overflow-auto p-4">
        {/* Product Table */}
        <div className="min-w-full">
          <div
            style={{ gridTemplateColumns: "2.5fr 1fr 1fr 1.5fr 1fr" }}
            className="sticky top-0 z-10 grid h-14 grid-cols-5 items-center gap-4 rounded-md border border-borderGray bg-bgGray px-4 text-left text-sm font-medium tracking-wider text-gray-500">
            <div>Product Info</div>
            <div>Total Quantity</div>
            <div>Shipped Quantity</div>
            <div>Remaining Quantity</div>
            <div>Consignment No.</div>
          </div>
          <div
            style={{ maxHeight: "40rem" }}
            className="min-w-full overflow-auto">
            {products.map((product) => {
              const { expectedQuantity, shippedQuantity, remainingQuantity } =
                handleGetQuantityDetailOfLineItem(
                  product.lineItemId,
                  products,
                  shippingDetails,
                );

              const [
                consignmentNo,
                additionalConsignments,
                latestConsignmentId,
              ] = getConsignmentDetails(product.lineItemId);

              return (
                <div key={product.productId} className="border-b py-4">
                  <div
                    style={{ gridTemplateColumns: "2.5fr 1fr 1fr 1.5fr 1fr" }}
                    className="grid grid-cols-5 items-start gap-4 p-4">
                    <div className="flex items-center">
                      <img
                        src={
                          product.images?.length > 0
                            ? product.images[0].url
                            : FALLBACK_IMAGE_URL
                        }
                        alt={product.name}
                        className="mr-4 h-16 w-16"
                      />
                      <div>
                        <Tooltip title={product.name}>
                          <div className="w-48 truncate text-base font-medium">
                            {product.name}
                          </div>
                        </Tooltip>
                        <div className="text-gray-500">SKU: {product.sku}</div>
                        <div className="text-gray-500">
                          Variant: {product.variant}
                        </div>
                      </div>
                    </div>
                    <div className="p-2">{expectedQuantity}</div>
                    <div className="p-2">{shippedQuantity || 0}</div>
                    <div className="p-2">{remainingQuantity}</div>
                    <div className="p-2 text-primaryAccent">
                      <span
                        className={`cursor-pointer ${consignmentNo ? "underline" : ""}`}
                        onClick={() => {
                          if (additionalConsignments > 0) {
                            handleGetConsignmentIds(product.lineItemId);
                          } else if (consignmentNo)
                            history.push(
                              "/consignments/" + latestConsignmentId,
                            );
                        }}>
                        {consignmentNo}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <NewModal
        isOpen={showConsignmentIdsModal}
        onClose={() => {
          setShowConsignmentIdsModal(false);
          // modal takes time to vanish if this is reset without timer then no results screen will be visible on table
          setTimeout(() => {
            setConsignmentIds([]);
          }, 500);
        }}
        maxWidth="640px"
        title="Consignment No.(s)"
        subtitle="">
        <CustomTable
          columns={[
            { title: "Consignment No.", key: "consignmentNo", isSort: false },
          ]}
          data={consignmentIds}
          isPagination={false}
          isFilters={false}
          isSearchable={false}
        />
      </NewModal>
    </>
  );
};

export default ViewShippedProductsMain;
