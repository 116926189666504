import withUoMLogic from "#components/HOC/withUoMLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import UoMList from "#components/uom/UoMList";
import UoMForm from "#components/uom/UoMForm";
import { UserAddIcon } from "@heroicons/react/outline";

const UoM = ({
  masterData,
  uomLabels,
  selectedLabel,
  setSelectedLabel,
  saveLabel,
  onChangeMultiSelect,
  writable,
}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>UoM Management</PageTitle>
      </div>

      <div className="mb-4 flex items-center justify-end space-x-2">
        <AddButton
          text="Add Custom UoM"
          onClick={() => setSelectedLabel({})}
          icon={UserAddIcon}
          disabled={!writable}
        />
      </div>
    </div>
    <UoMList
      uomLabels={uomLabels}
      masterData={masterData}
      headers={["Name", "Status", "Creation Date"]}
      noValuesText="No UoM Available"
    />
    {selectedLabel && (
      <UoMForm
        title={"Add Custom UoM"}
        selectedLabel={selectedLabel}
        onClose={() => setSelectedLabel()}
        onSubmit={() => saveLabel(selectedLabel)}
        onChangeMultiSelect={onChangeMultiSelect}
      />
    )}
  </div>
);

export default withUoMLogic(UoM);
