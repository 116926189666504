import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_ORDERS,
  GET_ORDERS_FULFILLMENT_DETAILS,
  GET_AUDIT,
  FETCH_SPECIFIC_ORDER,
  GET_USERS,
  GET_CUSTOMER,
  CHECK_IF_ORDER_WAREHOUSE_CAN_BE_CHANGED,
  GET_NON_EDITABLE_FIELDS_FOR_ORDER,
  GET_INVENTORY,
  GET_MARKETPLACE_PRODUCTS,
  GET_INTEGRATIONS,
  GET_SKU_BIN_MAPPINGS,
  GET_ALL_DATA_TABLE_FILTERS,
  GET_BACK_ORDER_DETAILS,
  GET_SPLIT_ORDER_DETAILS,
} from "#queries";
import {
  MANUALLY_MARK_ORDERS,
  TRY_RESOLVE_EXCEPTION,
  UPDATE_ORDER_LINE_ITEM,
  ASSIGN_WAREHOUSE_TO_ORDER,
  CANCEL_ORDERS,
  UPDATE_ORDER,
  DELETE_ORDER,
  TRY_MASS_RESOLVE_EXCEPTIONS,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { EntityContext } from "#contexts/entity";
import { printShippingLabel } from "#utils/printShippingLabel";
import { possibleSubStatuses } from "./withOrdersLogic";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "../../constants/feature-flags";
import moment from "moment-timezone";
import { getPrintFiles } from "../../components/HOC/withPackerLogic";
import { GET_SEARCH_FILTERS, SEARCH_ORDERS } from "#queries/index";

const ALERT_TIMEOUT_MS = 5000;
const ALERT_VISIBILITY_IN_MS = 5000; // 5 seconds

const withOutboundExceptionsLogic = (WrappedComponent) => {
  return (props) => {
    const [dataTableFilters, setDataTableFilters] = useState({});
    const auth = useContext(AuthContext);
    const entity = useContext(EntityContext);
    const ldClient = useLDClient();
    const usersQuery = useQuery(GET_USERS);
    const tryResolveQuery = useQuery(TRY_RESOLVE_EXCEPTION);
    const tryMassResolveExceptionsQuery = useQuery(TRY_MASS_RESOLVE_EXCEPTIONS);
    const fetchSpecificOrder = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchSpecificException = useQuery(FETCH_SPECIFIC_ORDER);
    const updateOrderLineItemQuery = useQuery(UPDATE_ORDER_LINE_ITEM);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [expandedOrderInfo, setExpandedOrderInfo] = useState(null);
    const [selectedOrderLineItem, setSelectedOrderLineItem] = useState(null);
    const [expandedException, setExpandedException] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const getCustomerQuery = useQuery(GET_CUSTOMER);
    const getAuditQuery = useQuery(GET_AUDIT);
    const manuallyMarkOrdersQuery = useQuery(MANUALLY_MARK_ORDERS);
    const cancelOrdersQuery = useQuery(CANCEL_ORDERS);
    const deleteOrderQuery = useQuery(DELETE_ORDER);
    const appState = useContext(AppStateContext);
    const ordersQuery = useQuery(GET_ORDERS);
    const assignWarehouseQuery = useQuery(ASSIGN_WAREHOUSE_TO_ORDER);
    const checkIfOrderWarehouseCanBeChangedQuery = useQuery(
      CHECK_IF_ORDER_WAREHOUSE_CAN_BE_CHANGED,
    );
    const [availableWarehousesForChange, setAvailableWarehousesForChange] =
      useState([]);
    const [bulkOrdersToMarkComplete, setBulkOrdersToMarkComplete] = useState(
      [],
    );
    const [bulkOrdersToMarkCancelled, setBulkOrdersToMarkCancelled] = useState(
      [],
    );
    const [showFilters, setShowFilters] = useState(false);
    const [lastSelectedOrder, setLastSelectedOrder] = useState(null);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [selectedOrderForChange, setSelectedOrderForChange] = useState(null);
    const [cannotChangeOrderWarehouse, setCannotChangeOrderWarehouse] =
      useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isOrderBeingEdited, setOrderBeingEdited] = useState(false);
    const [nonEditableFieldsOfOrder, setNonEditableFieldsOfOrder] = useState(
      [],
    );
    const [searchFilters, setSearchFilters] = useState([]);
    const [searchResults, setSearchResults] = useState({});
    const [totalResults, setTotalResults] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const updateOrderQuery = useQuery(UPDATE_ORDER);
    const getNonEditableFieldsForOrderQuery = useQuery(
      GET_NON_EDITABLE_FIELDS_FOR_ORDER,
    );
    const getOrderDetailsForEditingQuery = useQuery(FETCH_SPECIFIC_ORDER);
    const inventoryQuery = useQuery(GET_INVENTORY);
    const [availableInventory, setAvailableInventory] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const getMarketplaceProductsQuery = useQuery(GET_MARKETPLACE_PRODUCTS);
    const integrationsQuery = useQuery(GET_INTEGRATIONS);
    const [showOnlySelected, setShowOnlySelected] = useState(false);
    const [productSkuBinMappings, setProductSkuBinMappings] = useState(null);
    const [productSkuBinMappingsWithLpn, setProductSkuBinMappingsWithLpn] =
      useState(null);
    const skuBinMappingsQuery = useQuery(GET_SKU_BIN_MAPPINGS);
    const getAllDataTableFiltersQuery = useQuery(GET_ALL_DATA_TABLE_FILTERS);
    const [isBackOrderEnabledForTenant, setBackOrderEnabledForTenant] =
      useState(false);
    const [showBatchingPreview, setShowBatchingPreview] = useState(true);
    const [ordersFulfillmentDetails, setOrdersFulfillmentDetails] =
      useState(null);
    const getOrderFulfillmentDetailsQuery = useQuery(
      GET_ORDERS_FULFILLMENT_DETAILS,
    );
    const getBackOrderDetailsQuery = useQuery(GET_BACK_ORDER_DETAILS);
    const getSplitOrderDetailsQuery = useQuery(GET_SPLIT_ORDER_DETAILS);
    const getSearchFiltersQuery = useQuery(GET_SEARCH_FILTERS);
    const searchOrdersQuery = useQuery(SEARCH_ORDERS);

    useEffect(() => {
      usersQuery.fetchData();
      return () => {
        entity.resetEntities();
        entity.setFilters({});
      };
    }, []);

    useEffect(() => {
      const tenantSettings = appState?.tenant?.settings;
      if (tenantSettings?.backOrderEnabled) {
        setBackOrderEnabledForTenant(true);
      }
      if (tenantSettings?.skipBatchingPreview) {
        setShowBatchingPreview(false);
      }
    }, [appState]);

    useEffect(() => {
      if (getAllDataTableFiltersQuery.data?.getAllDataTableFilters) {
        setDataTableFilters(
          getAllDataTableFiltersQuery.data.getAllDataTableFilters,
        );
      } else {
        setDataTableFilters({});
      }
    }, [getAllDataTableFiltersQuery.data]);

    useEffect(() => {
      loadFirstTimeData();
      getAllDataTableFiltersQuery.fetchData({ entity: "ORDER" });
    }, []);

    const loadFirstTimeData = () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        const filtersSet = { orderStatus: ["INCOMPLETE", "EXCEPTION"] };
        entity.setFilters(filtersSet);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: filtersSet,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }
    };

    useEffect(() => {
      if (ordersQuery.loading) appState.setLoading();
      else appState.removeLoading();

      if (ordersQuery.data) {
        delete ordersQuery.variables.filters;
        entity.setEntities({
          ...ordersQuery.data.orders,
          ...ordersQuery.variables,
        });
      }
    }, [ordersQuery.loading, ordersQuery.error, ordersQuery.data]);

    useEffect(() => {
      if (fetchSpecificOrder.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchSpecificOrder.data) {
        setExpandedOrderInfo({
          order: fetchSpecificOrder.data.order,
        });
      }

      if (fetchSpecificOrder.error) {
        setExpandedOrderInfo(null);
      }
    }, [
      fetchSpecificOrder.loading,
      fetchSpecificOrder.error,
      fetchSpecificOrder.data,
    ]);

    useEffect(() => {
      if (checkIfOrderWarehouseCanBeChangedQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (checkIfOrderWarehouseCanBeChangedQuery.data) {
        setCannotChangeOrderWarehouse(
          checkIfOrderWarehouseCanBeChangedQuery.data
            .checkIfOrderWarehouseCanBeChanged
            ? false
            : "This order cannot be assigned to another warehouse",
        );
        return;
      }

      if (checkIfOrderWarehouseCanBeChangedQuery.error) {
        setCannotChangeOrderWarehouse(
          checkIfOrderWarehouseCanBeChangedQuery.error.message,
        );
      }
    }, [
      checkIfOrderWarehouseCanBeChangedQuery.data,
      checkIfOrderWarehouseCanBeChangedQuery.error,
      checkIfOrderWarehouseCanBeChangedQuery.loading,
    ]);

    useEffect(() => {
      if (skuBinMappingsQuery.data) {
        const groupedByLotId = _.groupBy(
          skuBinMappingsQuery.data.skuBinMappings.entities.filter(
            (item) => !!item.lotId,
          ),
          "lotId",
        );
        const toSetProductSkuBinMappings = Object.keys(groupedByLotId).map(
          (lotId) => ({
            lotId,
            unallocatedQuantity: _.sumBy(
              groupedByLotId[lotId],
              "unallocatedQuantity",
            ),
            product: groupedByLotId[lotId][0].product,
          }),
        );
        setProductSkuBinMappings(toSetProductSkuBinMappings);

        const groupedByNestedFormFactorId = _.groupBy(
          skuBinMappingsQuery.data.skuBinMappings.entities.filter(
            (item) => !!item.nestedFormFactorId,
          ),
          "nestedFormFactorId",
        );
        const toSetProductSkuBinMappingsWithLpn = Object.keys(
          groupedByNestedFormFactorId,
        ).map((nestedFormFactorId) => ({
          nestedFormFactorId,
          unallocatedQuantity: _.sumBy(
            groupedByNestedFormFactorId[nestedFormFactorId],
            "unallocatedQuantity",
          ),
          product: groupedByNestedFormFactorId[nestedFormFactorId][0].product,
        }));
        setProductSkuBinMappingsWithLpn(toSetProductSkuBinMappingsWithLpn);
      }

      if (skuBinMappingsQuery.error) {
        appState.setAlert(
          skuBinMappingsQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        setProductSkuBinMappings(null);
      }
    }, [skuBinMappingsQuery.data, skuBinMappingsQuery.error]);

    useEffect(() => {
      if (getCustomerQuery.data) {
        setAvailableWarehousesForChange([
          ...auth.user.warehousesList.filter((w) =>
            getCustomerQuery.data.customer.warehouses.join(",").includes(w.id),
          ),
        ]);
      }

      if (getCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getCustomerQuery.loading,
      getCustomerQuery.data,
      getCustomerQuery.error,
    ]);

    useEffect(() => {
      if (assignWarehouseQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (assignWarehouseQuery.data) {
        appState.setAlert(
          assignWarehouseQuery.data.assignWarehouseToOrder.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        setSelectedWarehouse(null);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (assignWarehouseQuery.error) {
        appState.setAlert(
          assignWarehouseQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      assignWarehouseQuery.data,
      assignWarehouseQuery.error,
      assignWarehouseQuery.loading,
    ]);

    useEffect(() => {
      if (fetchSpecificException.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchSpecificException.data) {
        setExpandedException(fetchSpecificException.data.order);
      }

      if (fetchSpecificException.error) {
        setExpandedException(null);
      }
    }, [
      fetchSpecificException.loading,
      fetchSpecificException.error,
      fetchSpecificException.data,
    ]);

    // This effect will fetch the fulfillment details for order before manual batching
    useEffect(() => {
      if (getOrderFulfillmentDetailsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getOrderFulfillmentDetailsQuery.data) {
        setOrdersFulfillmentDetails(
          getOrderFulfillmentDetailsQuery.data.getOrderFulfillmentDetails,
        );
      }

      if (getOrderFulfillmentDetailsQuery.error) {
        setOrdersFulfillmentDetails(null);
        appState.setAlert(
          getOrderFulfillmentDetailsQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      getOrderFulfillmentDetailsQuery.data,
      getOrderFulfillmentDetailsQuery.error,
      getOrderFulfillmentDetailsQuery.loading,
    ]);

    useEffect(() => {
      if (getBackOrderDetailsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getBackOrderDetailsQuery.data) {
        setExpandedOrderInfo({
          ...getBackOrderDetailsQuery.data.getBackOrderDetails,
          isBackOrder: true,
        });
      }

      if (getBackOrderDetailsQuery.error) {
        setExpandedOrderInfo(null);
      }
    }, [
      getBackOrderDetailsQuery.loading,
      getBackOrderDetailsQuery.error,
      getBackOrderDetailsQuery.data,
    ]);

    useEffect(() => {
      if (getSplitOrderDetailsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getSplitOrderDetailsQuery.data) {
        setExpandedOrderInfo({
          ...getSplitOrderDetailsQuery.data.getBackOrderDetails,
          isBackOrder: true,
        });
      }

      if (getSplitOrderDetailsQuery.error) {
        setExpandedOrderInfo(null);
      }
    }, [
      getSplitOrderDetailsQuery.loading,
      getSplitOrderDetailsQuery.error,
      getSplitOrderDetailsQuery.data,
    ]);

    useEffect(() => {
      if (manuallyMarkOrdersQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (manuallyMarkOrdersQuery.data) {
        ordersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });

        setSelectedOrders([]);
        appState.setAlert(
          manuallyMarkOrdersQuery.data.manuallyMarkOrders.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
      }

      if (manuallyMarkOrdersQuery.error) {
        appState.removeLoading();
        appState.setAlert(
          manuallyMarkOrdersQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      manuallyMarkOrdersQuery.error,
      manuallyMarkOrdersQuery.data,
      manuallyMarkOrdersQuery.loading,
    ]);

    useEffect(() => {
      if (ordersQuery.data) {
        entity.setEntities({
          ...ordersQuery.data.orders,
          ...ordersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [ordersQuery.loading, ordersQuery.error, ordersQuery.data]);

    useEffect(() => {
      const onCompleted = (data) => {
        /* magic */
        if (data?.getAudit) {
          setSelectedAudit(data.getAudit);
        }
      };
      const onError = (error) => {
        /* magic */
        setSelectedAudit(null);
      };
      if (onCompleted || onError) {
        if (onCompleted && !getAuditQuery.loading && !getAuditQuery.error) {
          onCompleted(getAuditQuery.data);
        } else if (onError && !getAuditQuery.loading && getAuditQuery.error) {
          onError(getAuditQuery.error);
        }
      }
    }, [getAuditQuery.loading, getAuditQuery.data, getAuditQuery.error]);

    useEffect(() => {
      getNonEditableFieldsForOrderQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (getNonEditableFieldsForOrderQuery.data) {
        setNonEditableFieldsOfOrder(
          getNonEditableFieldsForOrderQuery.data.getNonEditableFieldsOfOrder,
        );
        getOrderDetailsForEditingQuery.fetchData({ orderId: selectedOrder.id });
      }
      if (getNonEditableFieldsForOrderQuery.error) {
        appState.setAlert(
          getNonEditableFieldsForOrderQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      getNonEditableFieldsForOrderQuery.loading,
      getNonEditableFieldsForOrderQuery.data,
      getNonEditableFieldsForOrderQuery.error,
    ]);

    useEffect(() => {
      getOrderDetailsForEditingQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (getOrderDetailsForEditingQuery.data) {
        const orderDetails = getOrderDetailsForEditingQuery.data.order;
        const modifiedOrderLineItems = [];
        orderDetails.orderProducts.forEach((product) => {
          modifiedOrderLineItems.push({
            productId: product.id,
            asin: product.asin,
            sku: product.sku,
            sellerSku: product.sellerSku,
            productName: product.productName,
            availableQty: product.availableQuantity,
            baseUOM: product.baseUOM,
            formFactors: [
              {
                name: product.formFactor,
                quantity: product.quantity,
                lotId:
                  product.lotId && product.lotId.trim() !== ""
                    ? product.lotId
                    : null,
                nestedFormFactorId:
                  product.nestedFormFactorId &&
                  product.nestedFormFactorId.trim() !== ""
                    ? product.nestedFormFactorId
                    : null,
              },
            ],
          });
        });
        orderDetails.orderLineItems = modifiedOrderLineItems;

        setSelectedOrder({ ...orderDetails });
        setOrderBeingEdited(true);
      }
      if (getOrderDetailsForEditingQuery.error) {
        appState.setAlert(
          getOrderDetailsForEditingQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      getOrderDetailsForEditingQuery.loading,
      getOrderDetailsForEditingQuery.data,
      getOrderDetailsForEditingQuery.error,
    ]);

    useEffect(() => {
      updateOrderQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (updateOrderQuery.data) {
        appState.setAlert(
          updateOrderQuery.data.updateOrder.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        setSelectedOrder(null);
        setOrderBeingEdited(false);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: { ...entity.filters },
          sort: entity.sort,
        });
      }
      if (updateOrderQuery.error) {
        appState.setAlert(
          updateOrderQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      updateOrderQuery.loading,
      updateOrderQuery.data,
      updateOrderQuery.error,
    ]);

    useEffect(() => {
      if (searchOrdersQuery.error) {
        appState.setAlert(
          searchOrdersQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [searchOrdersQuery.error]);

    useEffect(() => {
      (async () => {
        const response = await getSearchFiltersQuery.fetchData();

        if (response.data?.getSearchFilters) {
          setSearchFilters(response.data.getSearchFilters);
        }
      })();
    }, []);

    const fetchEnhancedSearchResults = async (payload) => {
      if (!payload.keyword) {
        setSearchResults({});
        return;
      }

      const response = await searchOrdersQuery.fetchData(payload);

      if (response.data?.searchOrders?.entities?.length > 0) {
        setSearchResults((prev) => ({
          ...response.data.searchOrders,
          entities: [
            ...(prev?.entities || []),
            ...response.data.searchOrders.entities,
          ],
        }));

        if (currentPage === 0)
          setTotalResults(response.data.searchOrders.total);
      }
    };

    /**
     * Converts a date string to epoch time with the local time zone.
     * @param {string} dateString - The date string to convert.
     * @returns {number} The epoch time with the local time zone.
     */
    const getEpochWithLocalTimeZone = (dateString) => {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return moment.tz(dateString, userTimezone).valueOf();
    };

    const handleOrderDataSubmission = () => {
      const orderDateInEpoch = selectedOrder.orderDate
        ? getEpochWithLocalTimeZone(selectedOrder.orderDate)
        : Date.now();

      const order = {
        ...selectedOrder,
        orderDate: orderDateInEpoch,
        orderLineItems: selectedOrder.orderLineItems.map((item) => ({
          productId: item.productId,
          formFactor: item.formFactors[0].name,
          quantity: item.formFactors[0].quantity,
          lotId:
            item.formFactors[0].lotId && item.formFactors[0].lotId.trim() !== ""
              ? item.formFactors[0].lotId
              : null,
        })),
        source: "Hopstack",
      };
      if (isOrderBeingEdited) {
        const orderFields = Object.keys(order);
        const orderInput = { ...order };
        orderFields.forEach((field) => {
          if (!editOrderFields.has(field)) {
            delete orderInput[field];
          }
        });
        updateOrderQuery.fetchData({
          orderInput,
          user: auth.user?.id,
        });
      }
    };

    const editOrder = (id) => {
      const order = entity.entities.find((item) => item.id === id);
      if (order) {
        setSelectedOrder({ ...order });
        getNonEditableFieldsForOrderQuery.fetchData({
          orderId: order.id,
          userId: auth.user?.id,
        });
      }
    };

    const onChange = (e) => {
      const order = {
        ...selectedOrder,
      };

      if (e.target.type === "number") {
        order[e.target.name] = parseInt(e.target.value);
      } else {
        order[e.target.name] = e.target.value;
      }

      setSelectedOrder(order);
    };

    const onChangeDropdown = (field, value) => {
      const order = {
        ...selectedOrder,
      };

      if (field === "customer") {
        if (value !== order[field]) {
          inventoryQuery.fetchData({
            perPage: 10,
            filters: {
              customer: [value],
              includeBundles: true,
            },
            paginated: false,
            pageNumber: 1,
            sort: "asin",
          });
        }
      }

      order[field] = value;
      setSelectedOrder({ ...order });
    };

    const addItem = (addedProduct) => {
      let currentOrderInfo = selectedOrder;
      if (
        !currentOrderInfo.orderLineItems ||
        currentOrderInfo.orderLineItems.length === 0
      ) {
        currentOrderInfo.orderLineItems = [];
      }

      if (addedProduct.idx >= 0) {
        currentOrderInfo.orderLineItems[addedProduct.idx] = {
          ...addedProduct,
          idx: null,
          editing: false,
        };
      } else {
        currentOrderInfo.orderLineItems.push({ ...addedProduct });
      }

      setSelectedOrder({ ...currentOrderInfo });
    };

    const removeItem = (idx) => {
      let currentOrderInfo = selectedOrder;
      if (
        !currentOrderInfo.orderLineItems ||
        currentOrderInfo.orderLineItems.length === 0
      ) {
        return;
      }

      currentOrderInfo.orderLineItems = currentOrderInfo.orderLineItems.filter(
        (item, index) => index !== idx,
      );
      setSelectedOrder({ ...currentOrderInfo });
    };

    const onChangeItem = (idx, e) => {
      let currentOrderInfo = selectedOrder;
      const item = currentOrderInfo.orderLineItems[idx];
      item[e.target.name] =
        e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
      currentOrderInfo.orderLineItems[idx] = item;
      setSelectedOrder({ ...selectedOrder });
    };

    useEffect(() => {
      if (inventoryQuery.data) {
        setAvailableInventory(
          inventoryQuery.data.inventory.entities.filter((item) => !!item.sku),
        );
      }

      if (inventoryQuery.error) {
        setAvailableInventory([]);
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    const onChangeInventorySearch = (keyword) => {
      inventoryQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        filters: {
          keyword,
          customer: [selectedOrder.customer],
          warehouse: [selectedOrder.warehouse],
          includeBundles: true,
        },
        paginated: false,
      });
    };

    const saveDeliveryInfo = () => {
      const currentDeliveryInfo = deliveryInfo;
      const currentSelectedOrder = selectedOrder;
      if (!currentSelectedOrder.orders) {
        currentSelectedOrder.orders = [];
      }
      currentSelectedOrder.orders.push({ ...currentDeliveryInfo });
      setSelectedOrder({ ...currentSelectedOrder });
      setDeliveryInfo(null);
    };

    const removeDelivery = (idx) => {
      let currentSelectedOrder = selectedOrder;
      if (
        !currentSelectedOrder.orders ||
        currentSelectedOrder.orders.length === 0
      ) {
        return;
      }

      currentSelectedOrder.orders = currentSelectedOrder.orders.filter(
        (item, index) => index !== idx,
      );
      setSelectedOrder({ ...currentSelectedOrder });
    };

    useEffect(() => {
      if (getMarketplaceProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getMarketplaceProductsQuery.data) {
        appState.setAlert(
          getMarketplaceProductsQuery.data.marketplaceProducts.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
      }

      if (getMarketplaceProductsQuery.error) {
        appState.setAlert(
          getMarketplaceProductsQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      getMarketplaceProductsQuery.data,
      getMarketplaceProductsQuery.error,
      getMarketplaceProductsQuery.loading,
    ]);

    useEffect(() => {
      if (appState.subdomain === "boxette") {
        integrationsQuery.fetchData({
          filters: {
            integrationType: ["Easypost"],
          },
        });
      } else if (
        appState.subdomain === "prepfort" ||
        appState.subdomain === "uat"
      ) {
        integrationsQuery.fetchData({
          filters: {
            integrationType: ["eHub"],
          },
        });
      } else if (
        appState.subdomain === "tosprep" ||
        appState.subdomain === "e53"
      ) {
        integrationsQuery.fetchData({
          filters: {
            integrationType: ["Shipstation"],
          },
        });
      } else {
        integrationsQuery.fetchData({
          filters: {
            integrationType: ["Easypost"],
          },
        });
      }
    }, []);

    const selectOrder = (index, shiftKeyPressed) => {
      const selectedOrdersCopy = [...selectedOrders];
      const orders = entity.displayEntities;
      if (shiftKeyPressed) {
        if (lastSelectedOrder !== null) {
          const firstIndex = Math.min(lastSelectedOrder, index);
          const lastIndex = Math.max(lastSelectedOrder, index);
          for (let i = firstIndex; i <= lastIndex; i++) {
            if (!selectedOrdersCopy.includes(orders[i].id)) {
              selectedOrdersCopy.push(orders[i].id);
            }
          }
        } else {
          setLastSelectedOrder(index);
        }
      } else {
        setLastSelectedOrder(index);
        const idx = selectedOrdersCopy.indexOf(orders[index].id);
        if (idx !== -1) {
          selectedOrdersCopy.splice(idx, 1);
        } else {
          selectedOrdersCopy.push(orders[index].id);
        }
      }

      setSelectedOrders(selectedOrdersCopy);
    };

    const clearSelectedOrders = () => {
      setSelectedOrders([]);
      setAllRowsSelected(false);
      setShowOnlySelected(false);
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return ordersQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const markUnprocessed = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark these orders as UNPROCESSED?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: selectedOrders,
            status: "UNPROCESSED",
          });
        },
        appState.hideConfirmation,
      );
    };

    const markInProcess = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark these orders as IN-PROCESS?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: [id],
            status: "IN-PROCESS",
          });
        },
        appState.hideConfirmation,
      );
    };

    const markCancelled = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark these orders as CANCELLED?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: selectedOrders,
            status: "CANCELLED",
          });
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (cancelOrdersQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (cancelOrdersQuery.data) {
        appState.setAlert(
          cancelOrdersQuery.data.cancelOrders.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
      }

      if (cancelOrdersQuery.error) {
        appState.setAlert(
          cancelOrdersQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      cancelOrdersQuery.data,
      cancelOrdersQuery.error,
      cancelOrdersQuery.loading,
    ]);

    useEffect(() => {
      deleteOrderQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (deleteOrderQuery.data) {
        appState.setAlert(
          deleteOrderQuery.data.deleteOrder.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: { ...entity.filters },
          sort: entity.sort,
        });
      }

      if (deleteOrderQuery.error) {
        appState.setAlert(
          deleteOrderQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      deleteOrderQuery.data,
      deleteOrderQuery.error,
      deleteOrderQuery.loading,
    ]);

    const cancelOrders = (orderId) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark these orders as Cancelled?",
        () => {
          cancelOrdersQuery.fetchData({
            ids: [orderId],
            reason: `Order cancelled manually by ${auth.user.name}`,
          });
        },
        appState.hideConfirmation,
      );
    };

    const markCompleted = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark these orders as COMPLETED?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: [id],
            status: "COMPLETED",
          });
        },
        appState.hideConfirmation,
      );
    };

    const bulkMarkCompleted = async (orderIds) => {
      try {
        setExpandedOrderInfo(null);
        setBulkOrdersToMarkComplete(null);

        const eligibleStatuses = [
          "UNPROCESSED",
          "BATCHED",
          "IN-PROCESS",
          "BATCHED_PARTIALLY",
          "PARTIAL_COMPLETED",
          "INCOMPLETE",
          "EXCEPTION",
        ];

        const orderStatuses = await Promise.all(
          orderIds.map(async (id) => {
            try {
              const response = await fetchSpecificOrder.fetchData({
                orderId: id,
              });

              return {
                orderId: id,
                status: response?.data?.order?.orderStatus,
              };
            } catch (error) {
              return { orderId: id, status: null };
            }
          }),
        );

        const allEligible = orderStatuses.every((order) =>
          eligibleStatuses.includes(order.status),
        );

        if (allEligible) {
          setExpandedOrderInfo(null);
          setBulkOrdersToMarkComplete(orderIds);

          appState.showConfirmation(
            "Confirm",
            "Are you sure you want to mark these orders as COMPLETED?",
            () => {
              manuallyMarkOrdersQuery.fetchData({
                ids: orderIds,
                status: "COMPLETED",
              });
            },
            appState.hideConfirmation,
          );

          setBulkOrdersToMarkComplete(null);
          setExpandedOrderInfo(null);
          clearSelectedOrders();
        }

        setExpandedOrderInfo(null);
      } catch (error) {
        console.error(
          "An error occurred while marking orders as completed:",
          error,
        );
      }
    };

    const bulkMarkCancelled = async (orderIds) => {
      try {
        setExpandedOrderInfo(null);

        setBulkOrdersToMarkCancelled(null);

        const eligibleStatuses = [
          "UNPROCESSED",
          "BATCHED",
          "IN-PROCESS",
          "BATCHED_PARTIALLY",
          "PARTIAL_COMPLETED",
          "INCOMPLETE",
          "EXCEPTION",
        ];

        const orderStatuses = await Promise.all(
          orderIds.map(async (id) => {
            try {
              const response = await fetchSpecificOrder.fetchData({
                orderId: id,
              });

              return {
                orderId: id,
                status: response?.data?.order?.orderStatus, // Correct path for orderStatus
              };
            } catch (error) {
              return { orderId: id, status: null }; // Fallback in case of fetch error
            }
          }),
        );

        const allEligible = orderStatuses.every((order) =>
          eligibleStatuses.includes(order.status),
        );

        if (allEligible) {
          setExpandedOrderInfo(null);
          setBulkOrdersToMarkCancelled(orderIds);

          appState.showConfirmation(
            "Confirm",
            "Are you sure you want to mark these orders as CANCELLED?",
            () => {
              manuallyMarkOrdersQuery.fetchData({
                ids: orderIds,
                status: "CANCELLED",
              });
            },
            appState.hideConfirmation,
          );

          setBulkOrdersToMarkCancelled(null);
          setExpandedOrderInfo(null);
          clearSelectedOrders();
        }

        setExpandedOrderInfo(null);
      } catch (error) {
        console.error(
          "An error occurred while marking orders as completed:",
          error,
        );
      }
    };

    const markPartialCompleted = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to mark these orders as PARTIAL COMPLETED?",
        () => {
          manuallyMarkOrdersQuery.fetchData({
            ids: selectedOrders,
            status: "PARTIAL_COMPLETED",
          });
        },
        appState.hideConfirmation,
      );
    };

    const updateOrderLineItem = () => {
      const orderLineItemInfo = {
        id: selectedOrderLineItem.id,
        serialNumber: selectedOrderLineItem.serialNumber,
        overrideSerialNumber: true,
      };
      updateOrderLineItemQuery.fetchData({
        orderLineItemInput: orderLineItemInfo,
      });
    };

    const onChangeSelectedOrderLineItem = (e) => {
      const orderLineItem = { ...selectedOrderLineItem };
      orderLineItem[e.target.name] = e.target.value;
      setSelectedOrderLineItem({ ...orderLineItem });
    };

    useEffect(() => {
      if (updateOrderLineItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (
        updateOrderLineItemQuery.data &&
        updateOrderLineItemQuery.data.updateOrderLineItem
      ) {
        appState.setAlert(
          updateOrderLineItemQuery.data.updateOrderLineItem.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        const order = { ...expandedOrderInfo.order };
        const idx = order.orderLineItems.findIndex(
          (item) => item.id === selectedOrderLineItem.id,
        );
        order.orderLineItems[idx] = selectedOrderLineItem;
        setExpandedOrderInfo({ ...expandedOrderInfo, order: { ...order } });
        setSelectedOrderLineItem(null);
      }

      if (updateOrderLineItemQuery.error) {
        appState.setAlert(
          updateOrderLineItemQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      updateOrderLineItemQuery.loading,
      updateOrderLineItemQuery.error,
      updateOrderLineItemQuery.data,
    ]);

    useEffect(() => {
      if (tryResolveQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (tryResolveQuery.error) {
        setSelectedOrders([]);
        appState.setAlert(
          tryResolveQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      if (tryResolveQuery.data) {
        setSelectedOrders([]);
        appState.setAlert(
          tryResolveQuery.data.tryResolve.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        setOrdersFulfillmentDetails(null);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: { ...entity.filters },
          sort: entity.sort,
          paginated: false,
        });
      }
    }, [tryResolveQuery.data, tryResolveQuery.error, tryResolveQuery.loading]);

    useEffect(() => {
      if (tryMassResolveExceptionsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (tryMassResolveExceptionsQuery.error) {
        appState.setAlert(
          tryMassResolveExceptionsQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      if (tryMassResolveExceptionsQuery.data) {
        appState.setAlert(
          tryMassResolveExceptionsQuery.data.tryMassResolveExceptions.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        setOrdersFulfillmentDetails(null);
        ordersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: { ...entity.filters },
          sort: entity.sort,
          paginated: false,
        });
      }
    }, [
      tryMassResolveExceptionsQuery.data,
      tryMassResolveExceptionsQuery.error,
      tryMassResolveExceptionsQuery.loading,
    ]);

    const selectAllRows = () => {
      if (allRowsSelected === true) {
        setSelectedOrders([]);
        setAllRowsSelected(false);
      } else {
        setAllRowsSelected(true);
        setSelectedOrders(entity.entities.map((item) => item.id));
      }
    };

    const handleAssignWarehouse = (orderId) => {
      setSelectedWarehouse({});
      setSelectedOrderForChange(orderId);
      const order = ordersQuery.data.orders.entities.find(
        (o) => o.id === orderId,
      );
      getCustomerQuery.fetchData({ id: order.customer });
      checkIfOrderWarehouseCanBeChangedQuery.fetchData({ orderId });
    };

    const submitAssignWarehouse = () => {
      assignWarehouseQuery.fetchData({
        orderId: selectedOrderForChange,
        warehouseId: selectedWarehouse,
      });
    };

    const displayOrders = () => {
      if (showOnlySelected) {
        return entity.entities.filter((item) =>
          selectedOrders.includes(item.id),
        );
      }
      return entity.displayEntities;
    };

    const deleteOrder = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this order?",
        () => {
          deleteOrderQuery.fetchData({
            id,
          });
        },
        appState.hideConfirmation,
      );
    };

    const fetchProductSkuBinMappings = (product) => {
      if (
        ldClient?.variation(featureFlags.LOT_ID_SELECTION_ENABLED, false) ||
        ldClient?.variation(featureFlags.LPN_SELECTION_ENABLED, false)
      ) {
        if (
          selectedOrder &&
          product?.productId &&
          selectedOrder.warehouse &&
          selectedOrder.customer &&
          (!productSkuBinMappings ||
            productSkuBinMappings.findIndex(
              (i) => i.product === product?.productId,
            ) === -1)
        ) {
          skuBinMappingsQuery.fetchData({
            perPage: entity.perPage,
            filters: {
              product: [product.productId],
              warehouse: [selectedOrder.warehouse],
              customer: [selectedOrder.customer],
              all: true,
            },
            paginated: false,
            pageNumber: 1,
            sort: entity.sort,
          });
        } else if (
          !productSkuBinMappings ||
          productSkuBinMappings.findIndex(
            (i) => i.product === product?.productId,
          ) === -1
        ) {
          setProductSkuBinMappings(null);
        }
      }
    };

    const tryResolve = (id) => {
      setSelectedOrders([id]);
      if (showBatchingPreview && !ordersFulfillmentDetails) {
        getOrderFulfillmentDetailsQuery.fetchData({ ids: [id] });
      } else {
        tryResolveQuery.fetchData({ id });
      }
    };

    const tryMassResolveExceptions = ({ selectedOrders }) => {
      if (showBatchingPreview && !ordersFulfillmentDetails) {
        getOrderFulfillmentDetailsQuery.fetchData({ ids: selectedOrders });
      } else {
        tryMassResolveExceptionsQuery.fetchData({ ids: selectedOrders });
        clearSelectedOrders();
      }
    };

    /**
     * This is only for Delmar at the moment.
     * This is to print ALL labels of the order in one-shot.
     * Keeping the name generic to extend this to other tenants as well.
     * @param {string} orderId - The unique identifier for the order.
     * @returns {void}
     */
    const printOrder = (orderId) => {
      const order = entity.entities.find((item) => item.id === orderId);
      if (order) {
        const printingList = getPrintFiles(order);
        appState.setAlert(`Printing labels`, "success", ALERT_TIMEOUT_MS);
        for (const shippingLabel of printingList) {
          printShippingLabel(
            shippingLabel.file,
            appState,
            shippingLabel.printerName,
            shippingLabel.duplex,
          );
        }
      }
    };

    const markOrdersCompleted = async ({ entity, selectedOrders }) => {
      setExpandedOrderInfo(null);
      let ordersToMarkComplete = entity.entities.filter((order) =>
        selectedOrders.includes(order.id),
      );

      ordersToMarkComplete = ordersToMarkComplete.filter(
        (order) => !order.splitOrders,
      );

      const allowedOrderStatusesForBatching = [
        "UNPROCESSED",
        "BATCHED",
        "IN-PROCESS",
        "BATCHED_PARTIALLY",
        "PARTIAL_COMPLETED",
        "INCOMPLETE",
        "EXCEPTION",
      ];

      const notAllowedStatuses = _.uniq(
        ordersToMarkComplete
          .map((order) => order.orderStatus)
          .filter(
            (orderStatus) =>
              allowedOrderStatusesForBatching.includes(orderStatus) === false,
          ),
      );

      if (notAllowedStatuses.length) {
        return appState.setAlert(
          `You've selected orders that include the following statuses: '${notAllowedStatuses.join(
            ", ",
          )}'. Please select orders only with statuses ${allowedOrderStatusesForBatching.join(
            ", ",
          )}.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    };

    const markOrdersCancelled = async ({ entity, selectedOrders }) => {
      setExpandedOrderInfo(null);
      let ordersToMarkCancel = entity.entities.filter((order) =>
        selectedOrders.includes(order.id),
      );

      ordersToMarkCancel = ordersToMarkCancel.filter(
        (order) => !order.splitOrders,
      );

      const allowedOrderStatusesForBatching = [
        "UNPROCESSED",
        "BATCHED",
        "IN-PROCESS",
        "BATCHED_PARTIALLY",
        "PARTIAL_COMPLETED",
        "INCOMPLETE",
        "EXCEPTION",
      ];

      const notAllowedStatuses = _.uniq(
        ordersToMarkCancel
          .map((order) => order.orderStatus)
          .filter(
            (orderStatus) =>
              allowedOrderStatusesForBatching.includes(orderStatus) === false,
          ),
      );

      if (notAllowedStatuses.length) {
        setExpandedOrderInfo(null);
        return appState.setAlert(
          `You've selected orders that include the following statuses: '${notAllowedStatuses.join(
            ", ",
          )}'. Please select orders only with statuses ${allowedOrderStatusesForBatching.join(
            ", ",
          )}.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    };

    return (
      <WrappedComponent
        {...props}
        bulkOrdersToMarkComplete={bulkOrdersToMarkComplete}
        setBulkOrdersToMarkComplete={setBulkOrdersToMarkComplete}
        bulkOrdersToMarkCancelled={bulkOrdersToMarkCancelled}
        setBulkOrdersToMarkCancelled={setBulkOrdersToMarkCancelled}
        bulkMarkCompleted={bulkMarkCompleted}
        bulkMarkCancelled={bulkMarkCancelled}
        markOrdersCompleted={markOrdersCompleted}
        markOrdersCancelled={markOrdersCancelled}
        orders={entity.entities}
        displayOrders={displayOrders()}
        total={entity.total}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        selectedAudit={selectedAudit}
        setSelectedAudit={setSelectedAudit}
        getAudit={(id) => getAuditQuery.fetchData({ id })}
        selectOrder={selectOrder}
        selectedOrders={selectedOrders}
        clearSelectedOrders={clearSelectedOrders}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        expandOrder={(order) => {
          if (order.childOrders?.length > 0) {
            getBackOrderDetailsQuery.fetchData({ id: order.id });
          } else if (order.splitOrders?.length > 0) {
            getSplitOrderDetailsQuery.fetchData({ id: order.id });
          } else {
            fetchSpecificOrder.fetchData({ orderId: order.id });
          }
        }}
        expandedOrderInfo={expandedOrderInfo}
        selectedOrderLineItem={selectedOrderLineItem}
        setSelectedOrderLineItem={setSelectedOrderLineItem}
        updateOrderLineItem={updateOrderLineItem}
        onChangeSelectedOrderLineItem={onChangeSelectedOrderLineItem}
        setExpandedOrderInfo={setExpandedOrderInfo}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          ordersQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          ordersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          ordersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          const copiedFilters = { ...entity.filters };
          if (value === null || value === undefined) {
            delete copiedFilters[field];
            entity.setFilters({
              ...copiedFilters,
            });
          } else {
            if (field === "startEndDate" && value.length > 0) {
              // As orderDate filter needs to be converted to ISO string
              const gmtTimezone = "GMT";
              value = value.map((date, index) => {
                const momentDate = moment.tz(date, "DD-MM-YYYY", gmtTimezone);
                return index === 0
                  ? momentDate.startOf("day").toISOString()
                  : momentDate.endOf("day").toISOString();
              });
            }
            copiedFilters[field] = value;

            if (
              (field === "startEndDate" ||
                field === "completionStartEndDate") &&
              (value.length === 0 || !value[0])
            ) {
              delete copiedFilters[field];
            }

            entity.setFilters({
              ...copiedFilters,
            });
          }

          if (
            Object.keys(copiedFilters).length === 0 ||
            copiedFilters.orderStatus === null ||
            copiedFilters.orderStatus === undefined
          ) {
            copiedFilters.orderStatus = [];
          }

          if (autoSubmit) {
            ordersQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...copiedFilters,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          ordersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        printShippingLabel={(shippingLabel) => {
          if (shippingLabel) {
            printShippingLabel(shippingLabel, appState);
          } else {
            appState.setAlert(
              "Shipping label wasn't generated",
              "error",
              ALERT_TIMEOUT_MS,
            );
          }
        }}
        downloadShippingLabel={(shippingLabel) => {
          if (shippingLabel) {
            window.open(shippingLabel, "_blank");
          }
        }}
        markUnprocessed={markUnprocessed}
        markInProcess={markInProcess}
        markCompleted={markCompleted}
        markPartialCompleted={markPartialCompleted}
        markCancelled={markCancelled}
        cancelOrders={cancelOrders}
        deleteOrder={deleteOrder}
        tryResolve={tryResolve}
        tryMassResolveExceptions={tryMassResolveExceptions}
        expandedException={expandedException}
        setExpandedException={setExpandedException}
        expandException={(orderId) => {
          fetchSpecificException.fetchData({ orderId });
        }}
        selectAllRows={selectAllRows}
        allRowsSelected={allRowsSelected}
        handleAssignWarehouse={handleAssignWarehouse}
        assignWarehouse={submitAssignWarehouse}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={setSelectedWarehouse}
        cannotChangeOrderWarehouse={cannotChangeOrderWarehouse}
        tenant={appState.tenant}
        availableWarehousesForChange={availableWarehousesForChange}
        handleOrderDataSubmission={handleOrderDataSubmission}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        editOrder={editOrder}
        nonEditableFieldsOfOrder={nonEditableFieldsOfOrder}
        isOrderBeingEdited={isOrderBeingEdited}
        setOrderBeingEdited={setOrderBeingEdited}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        addItem={addItem}
        removeItem={removeItem}
        onChangeItem={onChangeItem}
        availableInventory={availableInventory}
        onChangeInventorySearch={onChangeInventorySearch}
        deliveryInfo={deliveryInfo}
        setDeliveryInfo={setDeliveryInfo}
        saveDeliveryInfo={saveDeliveryInfo}
        removeDelivery={removeDelivery}
        getMarketplaceProducts={() =>
          getMarketplaceProductsQuery.fetchData({
            customer: selectedOrder.customer,
          })
        }
        carrierList={
          integrationsQuery?.data?.integrations &&
          integrationsQuery.data.integrations[0] &&
          integrationsQuery.data.integrations[0].carrierAccounts
            ? integrationsQuery.data.integrations[0].carrierAccounts.map(
                (item) => ({
                  name: item.readable,
                  id: item.id,
                }),
              )
            : [
                { name: "UPS", id: "UPS" },
                { name: "USPS", id: "USPS" },
                { name: "FedEx", id: "FedEx" },
                { name: "DHL", id: "DHL" },
              ]
        }
        carrierServiceList={
          integrationsQuery?.data?.integrations &&
          integrationsQuery.data.integrations[0] &&
          integrationsQuery.data.integrations[0].carriers
            ? integrationsQuery.data.integrations[0].carriers
            : {}
        }
        shipstationList={
          integrationsQuery?.data?.integrations &&
          integrationsQuery.data.integrations[0] &&
          integrationsQuery.data.integrations[0].integrationType ===
            "Shipstation"
            ? integrationsQuery.data.integrations[0].carriers
            : []
        }
        eHubList={
          integrationsQuery?.data?.integrations?.[0]?.integrationType === "eHub"
            ? integrationsQuery.data.integrations[0].carriers
            : []
        }
        possibleSubStatuses={possibleSubStatuses(appState.tenant)}
        showOnlySelected={showOnlySelected}
        setShowOnlySelected={setShowOnlySelected}
        fetchProductSkuBinMappings={fetchProductSkuBinMappings}
        productSkuBinMappings={productSkuBinMappings}
        lotIdSelectionEnabled={ldClient?.variation(
          featureFlags.LOT_ID_SELECTION_ENABLED,
          false,
        )}
        dataTableFilters={dataTableFilters}
        setDataTableFilters={setDataTableFilters}
        productSkuBinMappingsWithLpn={productSkuBinMappingsWithLpn}
        setProductSkuBinMappingsWithLpn={setProductSkuBinMappingsWithLpn}
        lpnSelectionEnabled={ldClient?.variation(
          featureFlags.LPN_SELECTION_ENABLED,
          false,
        )}
        isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
        ordersFulfillmentDetails={ordersFulfillmentDetails}
        setOrdersFulfillmentDetails={setOrdersFulfillmentDetails}
        printOrder={printOrder}
        searchFilters={searchFilters}
        fetchEnhancedSearchResults={fetchEnhancedSearchResults}
        searchOrdersLoading={searchOrdersQuery.loading}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        totalResults={totalResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };
};

const editOrderFields = new Set([
  "id",
  "orderStatus",
  "carrier",
  "orderDate",
  "shippingAddress",
  "reference",
  "orderId",
  "orderValue",
  "orderLineItems",
  "subStatus",
  "shippingLabel",
  "tote",
  "trackingNumber",
  "cannotFulfil",
  "formFactor",
  "trackingUrl",
  "source",
  "email",
  "shippingLabels",
  "trackingNumbers",
  "boxInformationNeeded",
  "fbaAudit",
  "typeOfShipment",
  "confirmedTypeOfShipment",
  "boxLabels",
  "transportFailed",
  "hazmatConfirmed",
  "dropship",
  "consignmentId",
  "verifiedLabelUpload",
  "shippingRequests",
  "carrierService",
  "attributes",
  "insuranceRequired",
  "insuranceProvider",
  "insuredValue",
  "notes",
  "completedDate",
  "bolNumber",
  "bolSealNumber",
  "scacCode",
  "dateOfShipping",
  "toValidAddress",
  "warehouse",
  "customer",
]);

export default withOutboundExceptionsLogic;
