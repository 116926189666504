import { useState, useEffect, useContext, useRef } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_SALES_ORDER } from "#queries/index";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import moment from "moment-timezone";

const RECORDS_PER_PAGE = 1;
const ALERT_VISIBILITY_IN_MS = 5000;
const ROW_COUNT_WHEN_NOT_FULL = 4;

const OrderHistoryTable = ({ crmCustomerId, full, onShowFull }) => {
  const [orderHistory, setOrderHistory] = useState([]);
  const appState = useContext(AppStateContext);
  const getSalesOrderQuery = useQuery(GET_SALES_ORDER);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const observer = useRef();

  const lastLogElementRef = (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && orderHistory.length < total && full) {
        setCurrentPage((prevPage) => prevPage + 1); // Increment page and trigger data fetching
      }
    });

    if (node) observer.current.observe(node);
  };

  useEffect(() => {
    // Get first page
    getSalesOrderQuery.fetchData({
      perPage: RECORDS_PER_PAGE,
      pageNumber: 1,
      filters: {
        customerIdOnCrm: crmCustomerId,
      },
    });
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      getSalesOrderQuery.fetchData({
        perPage: RECORDS_PER_PAGE,
        pageNumber: currentPage,
        filters: {
          customerIdOnCrm: crmCustomerId,
        },
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (getSalesOrderQuery.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (getSalesOrderQuery.error) {
      appState.setAlert(
        getSalesOrderQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (getSalesOrderQuery.data) {
      setOrderHistory(getSalesOrderQuery.data.getSalesOrders?.entities);
      setTotal(getSalesOrderQuery.data.getSalesOrders?.total);
    }
  }, [
    getSalesOrderQuery.error,
    getSalesOrderQuery.data,
    getSalesOrderQuery.loading,
  ]);

  return (
    <div className="h-full flex-col items-center">
      {full && loading && <LoadingIndicator shouldShowOnPage={true} />}
      <div className="h-full px-4 py-5 sm:p-6">
        <div className="h-full overflow-hidden">
          <div className="h-full overflow-y-auto rounded-lg bg-white shadow-lg">
            <table className="min-w-full bg-white">
              <thead className="sticky top-0 z-0 bg-bgGray">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-0 px-6 py-3 text-center font-medium tracking-wider text-gray-500">
                    Order ID
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-0 px-6 py-3 text-center font-medium tracking-wider text-gray-500">
                    Created On
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-0 px-6 py-3 text-center font-medium tracking-wider text-gray-500">
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading && orderHistory.length === 0 && (
                  <tr>
                    <td colSpan={3} className="w-full py-4">
                      <div className="flex items-center justify-center">
                        <p>No Orders found</p>
                      </div>
                    </td>
                  </tr>
                )}
                {!full && loading && currentPage === 1 && (
                  <tr>
                    <td colSpan={3} className="w-full py-4">
                      <div className="flex items-center justify-center">
                        <LoadingIndicator shouldShowOnPage={false} />
                      </div>
                    </td>
                  </tr>
                )}
                {(full
                  ? orderHistory
                  : orderHistory.slice(0, ROW_COUNT_WHEN_NOT_FULL)
                ).map((order, idx) => {
                  if (orderHistory.length === idx + 1) {
                    return (
                      <tr
                        className="border-b border-gray-200"
                        ref={lastLogElementRef}
                        key={order?.salesOrderId}>
                        <td className="whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-900">
                          {order?.salesOrderId}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
                          {order?.createdAt
                            ? moment(order.createdAt).format("YYYY-MM-DD")
                            : ""}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
                          ${order?.cartDetails?.orderValue}
                        </td>
                      </tr>
                    );
                  } else {
                  }
                  return (
                    <tr
                      className="border-b border-gray-200"
                      key={order?.salesOrderId}>
                      <td className="whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-900">
                        {order?.salesOrderId}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
                        {order?.createdAt
                          ? moment(order.createdAt).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
                        ${order?.cartDetails?.orderValue}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!full && orderHistory.length > ROW_COUNT_WHEN_NOT_FULL && (
              <div className="m-8 flex justify-center">
                <p
                  className="cursor-pointer text-sm font-medium text-primaryAccent underline"
                  onClick={onShowFull}>
                  Click to show all {total} historic orders
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryTable;
