/**
 * created by - Omkar Gavade
 * use this only for selecting categories and filtering with categories in product catalog
 * we can make this generic for using everywhere, but for now usecase is limited
 **/

import React, { useState, useEffect, useRef } from "react";
import "tailwindcss/tailwind.css";
import { Select, Input } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
const { Option } = Select;
import { v4 as uuidv4 } from "uuid";
import Checkbox from "#components/utils/Checkbox";

const EnhancedCategoriesSelect = ({
  options = [],
  onChangeCustomSelect,
  preValues = [],
  disabled = false,
  isShowSelectedOptions = false, // prop to toggle showing selected options
  isSingleSelect = false, // prop to toggle single select mode
  classNames = "comboDropdown h-12",
  selectClassNames = "h-full w-full cursor-pointer rounded-md border-2 border-gray-300 bg-gray-100 text-xl caret-transparent hover:border-gray-300",
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [path, setPath] = useState([]);
  const [data, setData] = useState([]);
  const [currentNodes, setCurrentNodes] = useState([]);
  const [currentNodeId, setCurrentNodeId] = useState(null);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const originalNodesRef = useRef([]);

  useEffect(() => {
    const convertedData = convertData(options);
    setData(convertedData);
    const rootNode = convertedData[0];
    setCurrentNodes(Object.values(rootNode.children));
    setCurrentNodeId(rootNode.id);
    originalNodesRef.current = Object.values(rootNode.children);
  }, [options]);

  useEffect(() => {
    setSelectedValues(preValues);
  }, [preValues]);

  const handleExpand = (node) => {
    if (Object.values(node.children).length > 0) {
      setCurrentNodes(Object.values(node.children));
      setCurrentNodeId(node.id);
      originalNodesRef.current = Object.values(node.children);
      if (!path.some((p) => p.id === node.id)) {
        setPath((prevPath) => [...prevPath, { id: node.id, name: node.name }]);
      }
    }
  };

  const handleSelect = (value, crossPressed) => {
    if (isSingleSelect) {
      const node = crossPressed
        ? findNodeByIdRecursive(data[0], value?.split("/-")[1])
        : findNodeById(currentNodes, value);
      if (!node) return;
      setIsSaved(false);
      setSelectedValues((prev) => {
        if (
          prev?.some((item) => item?.id == value?.split("/-")[1]) &&
          crossPressed
        ) {
          onChangeCustomSelect([]);
          return [];
        } else if (!crossPressed) {
          onChangeCustomSelect([node]);
          return [node];
        }
      });
      setOpenDropDown(false);
    } else {
      const node = crossPressed
        ? findNodeByIdRecursive(data[0], value?.split("/-")[1])
        : findNodeById(currentNodes, value);
      if (!node) return;
      setIsSaved(false);

      setSelectedValues((prevValues) => {
        let newValues;
        if (prevValues.some((item) => item.id === node.id)) {
          // Deselect node and its children
          newValues = deselectAllChildren(node, prevValues);
        } else {
          // Select node and its children
          const nodesToSelect = selectAllChildren(node);
          newValues = [...prevValues, ...nodesToSelect];
        }

        // Update parent nodes
        let currentNode = node;
        while (currentNode) {
          const parentNode = findParentNodeById(data[0], currentNode.id);
          if (parentNode) {
            const allChildrenSelected = Object.values(
              parentNode.children,
            ).every((child) => newValues.some((item) => item.id === child.id));
            if (allChildrenSelected) {
              newValues = [...newValues, parentNode];
            } else {
              newValues = newValues.filter((item) => item.id !== parentNode.id);
            }
          }
          currentNode = parentNode;
        }

        return newValues;
      });
    }
  };

  const handleBack = () => {
    const newPath = path.slice(0, -1);
    if (newPath.length === 0) {
      setCurrentNodes(Object.values(data[0].children));
      setCurrentNodeId(data[0].id);
      originalNodesRef.current = Object.values(data[0].children);
    } else {
      const parentNodeId = newPath[newPath.length - 1].id;
      const parentNode = findNodeByIdRecursive(data[0], parentNodeId);
      if (parentNode) {
        setCurrentNodes(Object.values(parentNode.children));
        setCurrentNodeId(parentNodeId);
        originalNodesRef.current = Object.values(parentNode.children);
      }
    }
    setPath(newPath);
  };

  const handleSearch = (value) => {
    if (!value) {
      const node = findNodeByIdRecursive(data[0], currentNodeId);
      if (node && node.children) {
        setCurrentNodes(Object.values(node.children));
      } else {
        console.error("Node not found or has no children");
      }
    } else {
      const searchResult = originalNodesRef.current.filter((node) =>
        node.name.toLowerCase().includes(value.toLowerCase()),
      );
      setCurrentNodes(searchResult);
    }
  };

  const tagRender = () => {
    return (
      <span className="text-xl" style={{ backgroundColor: "#F1F8FF" }}>
        {getFinalSelectedValues(selectedValues, options).length} selected
      </span>
    );
  };

  const getCurrentParentName = () => {
    if (path.length === 0) return "Product Category";
    return path[path.length - 1].name;
  };

  const getSelectedChildCount = (node) => {
    let count = 0;
    const traverse = (currentNode) => {
      Object.values(currentNode.children).forEach((child) => {
        if (selectedValues.some((item) => item.id === child.id)) {
          count++;
        }
        if (Object.values(child.children).length > 0) {
          traverse(child);
        }
      });
    };
    traverse(node);
    return count;
  };

  const finalValues = getFinalSelectedValues(selectedValues, options);

  return (
    <div className={classNames}>
      <Select
        disabled={disabled}
        mode="multiple"
        open={openDropDown}
        size="large"
        className={selectClassNames}
        value={
          isShowSelectedOptions
            ? mapSelectedValuesToNames(
                selectedValues,
                options,
                false,
                true,
              )?.map((value, ind) => ({
                value: "value/-" + value.id,
                label: value.name,
              }))
            : `${finalValues.length} selected`
        }
        tagRender={!isShowSelectedOptions && tagRender}
        maxTagCount="responsive"
        style={{ width: "100%" }}
        onInputKeyDown={(e) => e.preventDefault()}
        onDropdownVisibleChange={(visible) => setOpenDropDown(visible)} // sync open state with Select dropdown visibility
        showSearch={false}
        onDeselect={(value) => handleSelect(value, true)}
        dropdownStyle={{
          width: "500px",
          height: "auto",
          overflowY: "scroll",
          padding: "10px",
        }}
        dropdownRender={(menu) => (
          <div>
            <div className="mb-2 flex items-center text-lg font-medium capitalize">
              {path.length > 0 && (
                <LeftOutlined
                  onClick={handleBack}
                  className="mr-2 cursor-pointer"
                />
              )}
              {path.length > 0
                ? path.map((p) => p.name).join("/")
                : "Product Category"}
            </div>
            <Input
              placeholder={`Search in ${getCurrentParentName()}`}
              className="mb-2 p-2"
              onChange={(e) => handleSearch(e.target.value)}
            />
            {menu}
            <>
              {!isSingleSelect && (
                <div className="flex items-center justify-center">
                  <div
                    onClick={() => {
                      setIsSaved(true);
                      setOpenDropDown(false);
                      onChangeCustomSelect(
                        selectedValues.filter((value) => !value.isRoot),
                      );
                    }}
                    className="mt-2 flex w-48 cursor-pointer items-center justify-center rounded-md border bg-primaryAccent p-2 text-lg font-medium text-white">
                    Apply
                  </div>
                </div>
              )}
            </>
          </div>
        )}
        placeholder="Please select">
        {currentNodes.map((node) => (
          <Option className="custom-option" key={uuidv4()} value={node.id}>
            <div className="relative flex items-center justify-between">
              <div
                className={`flex h-full w-full gap-2 p-3 text-base`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSelect(node.id);
                }}
                style={{ cursor: "pointer" }}>
                {!isSingleSelect && (
                  <Checkbox
                    role="checkbox"
                    className="h-16 w-16"
                    name={"isSelected" + node.id}
                    checked={selectedValues.some((item) => item.id === node.id)}
                    // onChange={() => handleSelect(node.id)}
                  />
                )}
                <div className="flex-col gap-1">
                  <span className="mx-w-80 truncate">{node.name}</span>
                  {Object.values(node.children).length > 0 &&
                    !isSingleSelect && (
                      <div className="text-sm text-gray-500">
                        {getSelectedChildCount(node)} selected
                      </div>
                    )}
                </div>
              </div>
              {Object.values(node.children).length > 0 && (
                <RightOutlined
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleExpand(node);
                  }}
                  className="absolute right-0 flex h-14 w-14 cursor-pointer items-center justify-center bg-white"
                />
              )}
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export const findNodeById = (nodes, id) => {
  for (const node of nodes) {
    if (node.id === id) return node;
  }
  return null;
};

export const findNodeByIdRecursive = (node, id) => {
  if (!node || node.id === undefined || node.id === null) {
    return null;
  }

  if (node.id === id) {
    return node;
  }

  if (node.children) {
    for (const child of Object.values(node.children)) {
      const foundNode = findNodeByIdRecursive(child, id);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return null;
};

export const findParentNodeById = (node, id) => {
  for (const key in node.children) {
    const child = node.children[key];
    if (child.id === id) {
      return node;
    }
    const parent = findParentNodeById(child, id);
    if (parent) {
      return parent;
    }
  }
  return null;
};

const getFinalSelectedValues = (selectedValues, options) => {
  const selectedIds = selectedValues?.map((value) => value.id);
  const result = [];

  for (const option of options) {
    if (selectedIds?.includes(option.id)) {
      result.push(option);
    }
  }

  return result.map((option) => option.name);
};

export const deselectAllChildren = (node, values) => {
  let nodesToDeselect = [node.id];
  const getAllChildren = (currentNode) => {
    Object.values(currentNode.children).forEach((child) => {
      nodesToDeselect.push(child.id);
      if (Object.values(child.children).length > 0) {
        getAllChildren(child);
      }
    });
  };
  getAllChildren(node);
  return values.filter((item) => !nodesToDeselect.includes(item.id));
};

export const selectAllChildren = (node) => {
  let selectedNodes = [node];
  const getAllChildren = (currentNode) => {
    Object.values(currentNode.children).forEach((child) => {
      selectedNodes.push(child);
      if (Object.values(child.children).length > 0) {
        getAllChildren(child);
      }
    });
  };
  getAllChildren(node);
  return selectedNodes;
};

export const mapSelectedValuesToNames = (
  selectedValues = [],
  options,
  isFullStringValue,
  isIds = false,
) => {
  const optionsMap = options.reduce((acc, option) => {
    acc[option.id] = option.name;
    return acc;
  }, {});

  if (isIds) {
    return selectedValues
      ?.filter((value) => !value.isRoot) // Exclude root node
      .map((value) => ({
        name: transformPathValues([optionsMap[value.id] || value.name])[0],
        id: value.id,
      }));
  }

  if (isFullStringValue) {
    return selectedValues
      ?.filter((value) => !value.isRoot) // Exclude root node
      .map((value) => optionsMap[value.id] || value.name);
  } else {
    const pathValues = selectedValues
      ?.filter((value) => !value.isRoot) // Exclude root node
      .map((value) => optionsMap[value.id] || value.name)
      .filter((value) => value !== undefined);

    return transformPathValues(pathValues);
  }
};

export const transformPathValues = (values) => {
  return values?.map((value) => {
    const parts = value?.split("/");
    if (parts?.length <= 2) {
      return value;
    } else {
      return `${parts[0]}/.../${parts[parts.length - 1]}`;
    }
  });
};

export const convertData = (inputData) => {
  const result = {};

  inputData.forEach((item) => {
    const nameParts = item.name.split("/");
    let currentLevel = result;

    nameParts.forEach((part, index) => {
      if (!currentLevel[part]) {
        currentLevel[part] = {
          id: index === nameParts.length - 1 ? item.id : null,
          name: part,
          children: {},
        };
      }

      // Only assign the id if it's the last part and not already assigned
      if (index === nameParts.length - 1) {
        currentLevel[part].id = item.id;
      }

      currentLevel = currentLevel[part].children;
    });
  });

  return [
    {
      id: Math.random().toString(36).substring(7), // Generate a new ID for the root node
      name: "Product Category",
      isRoot: true,
      children: result,
    },
  ];
};

export default EnhancedCategoriesSelect;
