import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import _ from "lodash";
import { useEffect, useState } from "react";

const GraphForm = ({
  onClose,
  title,
  onChange,
  selectedGraph,
  setSelectedGraph,
  onSubmit,
  dashboards,
  cubeMeta,
}) => {
  const [selectedCube, setSelectedCube] = useState(null);
  const [cubes, setCubes] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [dimensions, setDimensions] = useState([]);

  useEffect(() => {
    if (selectedCube) {
      setDimensions(selectedCube.dimensions);
      setMeasures(selectedCube.measures);
    }
  }, [selectedCube]);

  useEffect(() => {}, [cubes]);

  useEffect(() => {
    setCubes(cubeMeta.cubes);
  }, cubeMeta);
  return (
    <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Graph Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="name"
            label="Name"
            placeholder="Name"
            onChange={onChange}
            value={selectedGraph.name}
          />
        </div>

        <div className="w-48">
          Type
          <RoundedDropdown
            placeholder={"Graph Type"}
            list={[{ name: "Line Chart" }, { name: "Bar Graph" }]}
            labelKey="name"
            valueKey="name"
            name="type"
            selectedValue={selectedGraph.type}
            setSelected={(e) => {
              setSelectedGraph({
                ...selectedGraph,
                type: e,
              });
            }}
          />
        </div>

        <div className="w-48">
          Data Group
          <RoundedDropdown
            placeholder={"Group"}
            list={cubes}
            labelKey="name"
            valueKey="name"
            name="cube"
            selectedValue={selectedCube?.name}
            setSelected={(e) => {
              setSelectedCube(cubes.filter((cube) => cube.name === e)[0]);
            }}
          />
        </div>

        <div className="w-48">
          Measure
          <RoundedDropdown
            placeholder={"Measure"}
            list={measures}
            labelKey="shortTitle"
            valueKey="name"
            name="measure"
            selectedValue={
              selectedGraph.measures ? selectedGraph.measures[0] : null
            }
            setSelected={(e) => {
              setSelectedGraph({
                ...selectedGraph,
                measures: [e],
              });
            }}
          />
        </div>

        <div className="w-48">
          Dimension
          <RoundedDropdown
            placeholder={"Dimension"}
            list={dimensions}
            labelKey="shortTitle"
            valueKey="name"
            name="dimension"
            selectedValue={
              selectedGraph.dimensions ? selectedGraph.dimensions[0] : null
            }
            setSelected={(e) => {
              setSelectedGraph({
                ...selectedGraph,
                dimensions: [e],
                isTimeDimension:
                  dimensions.filter((dimension) => dimension.name === e)[0]
                    .type === "time",
              });
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default GraphForm;
