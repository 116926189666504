import Checkbox from "#components/utils/Checkbox";
import CatalogProductActions from "./CatalogProductActions";
import { InformationCircleIcon } from "@heroicons/react/outline";
import ArrowUpDownIcon from "#components/common/ArrowUpDownIcon";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import SelectedFilters from "./SelectedFilters";
const ListViewProducts = ({
  products,
  selectAllRows,
  allRowsSelected,
  selectProduct,
  selectedProducts,
  getCatalog,
  actionsOnProduct,
  isSearchResults,
  productCategories,
  selectAllDisabled,
}) => {
  const [sortedOrderOfProducts, setSortedOrderOfProducts] = useState([]);
  const [sortingOrders, setSortingOrders] = useState({});

  useEffect(() => {
    setSortedOrderOfProducts([...products]);
  }, [products]);

  const handleSortingOfProducts = (type, defaultOrder) => {
    let sortedProducts = [...sortedOrderOfProducts];
    let sortOrder = sortingOrders[type] || defaultOrder;

    switch (type) {
      case "productInfo":
        sortedProducts.sort((a, b) =>
          sortOrder === "asc"
            ? a.name?.localeCompare(b.name)
            : b.name?.localeCompare(a.name),
        );
        break;
      case "price":
        sortedProducts.sort((a, b) =>
          sortOrder === "asc"
            ? a.pricing?.computedPriceFields?.totalPrice -
              b.pricing?.computedPriceFields?.totalPrice
            : b.pricing?.computedPriceFields?.totalPrice -
              a.pricing?.computedPriceFields?.totalPrice,
        );
        break;
      case "customer":
        sortedProducts.sort((a, b) =>
          sortOrder === "asc"
            ? a.customer?.localeCompare(b.customer)
            : b.customer?.localeCompare(a.customer),
        );
        break;
      case "description":
        sortedProducts.sort((a, b) =>
          sortOrder === "asc"
            ? a.description?.localeCompare(b.description)
            : b.description?.localeCompare(a.description),
        );
        break;
      default:
        sortedProducts = [...products];
        break;
    }

    const nextSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortingOrders({ ...sortingOrders, [type]: nextSortOrder });

    setSortedOrderOfProducts(sortedProducts);
  };

  return (
    <div
      className={`shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg ${isSearchResults ? "h-50v w-full overflow-x-auto overflow-y-auto" : "h-full overflow-y-visible"}`}>
      <table className="min-w-full divide-y divide-gray-300 overflow-y-visible">
        <thead className="sticky top-0 z-5 bg-gray-50">
          <tr>
            {!selectAllDisabled && (
              <th
                scope="col"
                className="sticky left-0 bg-gray-50 py-3.5 pl-4 text-left text-lg font-normal text-[#717679] sm:pl-6">
                <Checkbox
                  role="checkbox"
                  onChange={selectAllRows}
                  name="selectAllRows"
                  checked={allRowsSelected}
                  disabled={!!selectAllDisabled}
                />
              </th>
            )}
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-lg font-normal text-[#717679] sm:pl-6">
              <div className="flex items-center space-x-1">
                <span>Product Info</span>
                <ArrowUpDownIcon
                  onClick={() => {
                    handleSortingOfProducts("productInfo", "asc");
                  }}
                  className="h-5 w-5 cursor-pointer text-gray-700"
                />
              </div>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              <div className="flex items-center space-x-1">
                <span>Description</span>
                <InformationCircleIcon className="h-5 w-5 cursor-pointer text-gray-700" />
                <ArrowUpDownIcon
                  onClick={() => {
                    handleSortingOfProducts("description", "asc");
                  }}
                  className="h-5 w-5 cursor-pointer text-gray-700"
                />
              </div>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              <div className="flex items-center space-x-1">
                <span>Categories</span>
                <InformationCircleIcon className="h-5 w-5 cursor-pointer text-gray-700" />
              </div>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              <div className="flex items-center space-x-1">
                <span>Price</span>
                <ArrowUpDownIcon
                  onClick={() => {
                    handleSortingOfProducts("price", "asc");
                  }}
                  className="h-5 w-5 cursor-pointer text-gray-700"
                />
              </div>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              <div className="flex items-center space-x-1">
                <span>Customer</span>
                <ArrowUpDownIcon
                  onClick={() => {
                    handleSortingOfProducts("customer", "asc");
                  }}
                  className="h-5 w-5 text-gray-700"
                />
              </div>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {!products.length && (
            <tr className="flex w-full justify-center">
              <td className="flex justify-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <div className="text-lg text-[#111827]">No products found</div>
              </td>
            </tr>
          )}
          {sortedOrderOfProducts?.length > 0 &&
            sortedOrderOfProducts.map((product, rowIndex) => (
              <tr key={uuidv4()}>
                {!selectAllDisabled && (
                  <td className="sticky left-0 bg-white py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <Checkbox
                      role="checkbox"
                      onChange={(_) => {
                        selectProduct(product.id, false, sortedOrderOfProducts);
                      }}
                      name="product"
                      value={product.id}
                      checked={selectedProducts?.includes(product.id)}
                      disabled={!!selectAllDisabled}
                    />
                  </td>
                )}
                <td className="overflow-ellipsis py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <div
                    className="flex cursor-pointer items-center"
                    onClick={() =>
                      !product.isParentProduct && getCatalog(product.id)
                    }>
                    {(product.images?.[0]?.url ||
                      product.images?.[0]?.display_url) && (
                      <div className="h-16 w-16 shrink-0">
                        <img
                          className="h-16 w-16 rounded-lg"
                          src={
                            product.images?.[0]?.display_url
                              ? product.images?.[0]?.display_url
                              : product.images?.[0]?.url
                          }
                          alt=""
                        />
                      </div>
                    )}
                    <div className="ml-4">
                      <div className="mt-1 text-lg text-[#111827]">
                        {product.name}
                      </div>
                      <div className="text-lg font-medium text-[#717679]">
                        SKU: {product.sku}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-3 py-4 text-lg text-[#111827]">
                  {product.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-lg text-[#111827]">
                  {/* {product.category
                    ? productCategories?.find((i) => i.id === product.category)
                        ?.name || "-"
                    : "-"} */}
                  <SelectedFilters
                    filters={{
                      category: product.categories,
                    }}
                    filter={{
                      filterName: "category",
                      options: productCategories,
                    }}
                    maxCount={3}
                    onChangeFilter={() => {}}
                    isClickable={false}
                  />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-lg text-[#111827]">
                  {product.pricing?.computedPriceFields?.totalPrice
                    ? `$${product.pricing?.computedPriceFields?.totalPrice.toFixed(
                        2,
                      )}`
                    : "-"}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-lg text-[#111827]">
                  All
                </td>
                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-lg text-[#111827]">
                  <CatalogProductActions
                    actionsOnProduct={actionsOnProduct}
                    product={product}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListViewProducts;
