import TextField from "#components/utils/TextField";
import { useState } from "react";

const ScanOrEnterManually = ({
  text,
  onSubmit,
  onSkip,
  onCancel,
  lpnEnabled,
  setAddLpnToProduct,
  setGenerateLpn,
}) => {
  const [manualValue, setManualValue] = useState(null);
  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex-col">
        <div className="rounded-2xl border bg-white shadow-lg">
          <div className="flex items-center justify-center pb-5 pt-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="px-5 py-5 text-xl font-medium text-454A4F">
              {text || "Scan a Tracking Number"}
            </div>
          </div>
          <div className="items-center text-center font-montserrat text-2xl">
            OR ENTER MANUALLY
          </div>
          <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
            <div>
              <TextField
                type="text"
                id="name"
                label="Enter Value"
                placeholder=" "
                onChange={(e) => setManualValue(e.target.value)}
                value={manualValue}
                onKeyDown={(e) =>
                  e.key === "Enter" ? onSubmit({ data: manualValue }) : {}
                }
                autoFocus={true}
              />
            </div>
            <div className="flex space-x-2">
              {onCancel && (
                <div
                  className={`mt-4 flex-1 cursor-pointer rounded-md bg-red-600 py-2 text-center text-xl text-white`}
                  onClick={onCancel}>
                  Cancel
                </div>
              )}

              {onSkip && (
                <div
                  className={`mt-4 flex-1 cursor-pointer rounded-md bg-red-600 py-2 text-center text-xl text-white`}
                  onClick={onSkip}>
                  Skip
                </div>
              )}

              <div
                className={`mt-4 flex-1 rounded-md py-2 text-center text-xl text-white ${
                  !!manualValue && manualValue.trim() !== ""
                    ? "cursor-pointer bg-2C7695"
                    : "cursor-not-allowed bg-gray-400"
                }`}
                onClick={() =>
                  !!manualValue && manualValue.trim() !== ""
                    ? onSubmit({ data: manualValue })
                    : console.log()
                }>
                Submit
              </div>
            </div>
          </div>
          {lpnEnabled && (
            <div className="items-center justify-center text-center">
              <div className="items-center text-center font-montserrat text-2xl">
                OR
              </div>
              <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
                <div className="flex space-x-2">
                  <div
                    className={`mt-4 rounded-md bg-2C7695 p-2 text-center text-xl text-white`}
                    onClick={() => setAddLpnToProduct({})}>
                    Add existing LPN/Code
                  </div>

                  <div
                    className="mt-4 rounded-md bg-2C7695 p-2 text-center text-xl text-white"
                    onClick={setGenerateLpn}>
                    Generate LPN
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScanOrEnterManually;
