import { useReducer, createContext } from "react";
import _ from "lodash";

const initialState = {
  items: [],
};
const DATE_RANGE_PERIOD_DAYS = 30;
const StatDashboardContext = createContext({});

function statDashboardReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_ITEMS": {
      return {
        ...state,
        items: payload,
      };
    }
    default:
      return state;
  }
}

function StatDashboardProvider(props) {
  const [state, dispatch] = useReducer(statDashboardReducer, initialState);
  function setItems() {
    const items = DashboardItems;

    dispatch({ type: "SET_ITEMS", payload: items });
  }
  // setItems()
  return (
    <StatDashboardContext.Provider
      value={{
        ...state,
        setItems,
      }}
      {...props}
    />
  );
}

export { StatDashboardContext, StatDashboardProvider };

const DashboardItems = [
  {
    id: 0,
    name: "Average Order Lead Time (Hours)",
    vizState: {
      query: {
        timeDimensions: [],
        order: [],
        filters: [
          {
            member: "auditlogs.completed_at",
            operator: "afterDate",
            values: ["01-07-1999"],
          },
        ],
        limit: 5000,
        measures: ["auditlogs.order_lead_time_hrs"],
      },
      chartType: "number",
      data: {
        value: 17.73,
      },
    },
    lg: 6,
  },
  {
    id: 4,
    name: "Total Number of Exceptional Orders",
    vizState: {
      query: {
        measures: ["orders.count"],
        timeDimensions: [],
        order: {
          "orders.createdat": "asc",
        },
        segments: ["orders.exceptional_orders"],
      },
      chartType: "number",
      data: {
        value: 1122,
      },
    },
    lg: 6,
  },
  {
    id: 9,
    name: "Average Picking Rate (Orders/Hr)",
    vizState: {
      query: {
        measures: ["pickings.averagePickRate"],
        timeDimensions: [],
        order: {},
        segments: ["pickings.more_than_one_order_per_batch"],
        filters: [
          {
            member: "pickings.stats_picksperhour",
            operator: "set",
          },
          {
            member: "pickings.stats_picksperhour",
            operator: "notEquals",
            values: ['""', '" "', " "],
          },
        ],
      },
      chartType: "number",
      data: {
        value: 287.8,
      },
    },
    lg: 6,
  },
  {
    id: 9,
    name: "Average Packing Rate (Orders/Hr)",
    vizState: {
      query: {
        measures: ["packings.averagePackRate"],
        timeDimensions: [],
        segments: ["packings.more_than_one_order_per_batch"],
        order: {},
        filters: [],
      },
      chartType: "number",
      data: {
        value: 149.02,
      },
    },
    lg: 6,
  },
  {
    id: 23,
    name: "Unprocessed (Hrs.)",
    vizState: {
      query: {
        measures: ["auditlogs.unprocessed_time_avg"],
        timeDimensions: [],
        order: {
          "auditlogs.createdat": "asc",
        },
        filters: [],
        segments: ["auditlogs.has_unprocessed_time"],
        dimensions: [],
        limit: 5000,
      },
      chartType: "number",
      data: {
        value: 20.75,
      },
    },
    lg: 4,
  },
  {
    id: 53,
    name: "Grouping time (ms)",
    vizState: {
      query: {
        measures: ["auditlogs.grouping_time_avg"],
        timeDimensions: [],
        order: {
          "auditlogs.createdat": "asc",
        },
        filters: [],
        segments: ["auditlogs.has_grouping_time"],
        dimensions: [],
        limit: 5000,
      },
      chartType: "number",
      data: {
        value: 422.47,
      },
    },
    lg: 4,
  },
  {
    id: 15,
    name: "Batching time (ms)",
    vizState: {
      query: {
        measures: ["auditlogs.batching_time_avg"],
        timeDimensions: [],
        order: {
          "auditlogs.createdat": "asc",
        },
        filters: [],
        segments: ["auditlogs.has_batching_time"],
        dimensions: [],
        limit: 5000,
      },
      chartType: "number",
      data: {
        value: 527.26,
      },
    },
    lg: 4,
  },
  {
    id: 46,
    name: "Picking time (min.)",
    vizState: {
      query: {
        measures: ["auditlogs.picking_time_avg"],
        timeDimensions: [],
        order: {
          "auditlogs.createdat": "asc",
        },
        filters: [],
        segments: ["auditlogs.has_picking_time"],
        dimensions: [],
        limit: 5000,
      },
      chartType: "number",
      data: {
        value: 6.52,
      },
    },
    lg: 4,
  },
  {
    id: 27,
    name: "Packing time (min.)",
    vizState: {
      query: {
        measures: ["auditlogs.packing_time_avg"],
        timeDimensions: [],
        order: {
          "auditlogs.createdat": "asc",
        },
        filters: [],
        segments: ["auditlogs.has_packing_time"],
        dimensions: [],
        limit: 5000,
      },
      chartType: "number",
      data: {
        value: 22.3,
      },
    },
    lg: 4,
  },
  {
    id: 8,
    name: "Average Order Lead Time by Customers",
    vizState: {
      query: {
        dimensions: ["orders.customername"],
        timeDimensions: [],
        order: [],
        filters: [
          {
            member: "auditlogs.completed_at",
            operator: "afterDate",
            values: ["01-07-1999"],
          },
        ],
        limit: 5000,
        measures: ["auditlogs.order_lead_time_hrs"],
      },
      chartType: "bar",
      data: {
        key: "auditlogs.order_lead_time_hrs",
        dataKey: "auditlogs.order_lead_time_hrs",
        cartesianChartData: [
          {
            x: "Sams Club",
            xValues: ["Sams Club"],
            "auditlogs.order_lead_time_hrs": 51.09,
          },
          {
            x: "Lord & Taylor",
            xValues: ["Lord & Taylor"],
            "auditlogs.order_lead_time_hrs": 42.64,
          },
          {
            x: "Kohls",
            xValues: ["Kohls"],
            "auditlogs.order_lead_time_hrs": 42.4,
          },
          {
            x: "Overstock.com",
            xValues: ["Overstock.com"],
            "auditlogs.order_lead_time_hrs": 32.53,
          },
          {
            x: "The Hudson's Bay",
            xValues: ["The Hudson's Bay"],
            "auditlogs.order_lead_time_hrs": 27,
          },
          {
            x: "Saks Off Fifth",
            xValues: ["Saks Off Fifth"],
            "auditlogs.order_lead_time_hrs": 26.23,
          },
          {
            x: "BELK",
            xValues: ["BELK"],
            "auditlogs.order_lead_time_hrs": 25.32,
          },
          {
            x: "Jomashop",
            xValues: ["Jomashop"],
            "auditlogs.order_lead_time_hrs": 22.28,
          },
          {
            x: "Evine.com",
            xValues: ["Evine.com"],
            "auditlogs.order_lead_time_hrs": 22.16,
          },
          {
            x: "PI Incentives",
            xValues: ["PI Incentives"],
            "auditlogs.order_lead_time_hrs": 21.17,
          },
          {
            x: "Hautelook",
            xValues: ["Hautelook"],
            "auditlogs.order_lead_time_hrs": 20.4,
          },
          {
            x: "Target",
            xValues: ["Target"],
            "auditlogs.order_lead_time_hrs": 18.28,
          },
          {
            x: "JCPenney",
            xValues: ["JCPenney"],
            "auditlogs.order_lead_time_hrs": 15.29,
          },
          {
            x: "Bluestem Fingerhut",
            xValues: ["Bluestem Fingerhut"],
            "auditlogs.order_lead_time_hrs": 15.25,
          },
          {
            x: "Bjs Whole Sale Club",
            xValues: ["Bjs Whole Sale Club"],
            "auditlogs.order_lead_time_hrs": 14.73,
          },
          {
            x: "Macy's Bloomingdales",
            xValues: ["Macy's Bloomingdales"],
            "auditlogs.order_lead_time_hrs": 14.22,
          },
          {
            x: "FredMeyer.com",
            xValues: ["FredMeyer.com"],
            "auditlogs.order_lead_time_hrs": 13.73,
          },
          {
            x: "Bluestem Gettington",
            xValues: ["Bluestem Gettington"],
            "auditlogs.order_lead_time_hrs": 13.09,
          },
          {
            x: "IC2 US",
            xValues: ["IC2 US"],
            "auditlogs.order_lead_time_hrs": 7.12,
          },
          {
            x: "GovX",
            xValues: ["GovX"],
            "auditlogs.order_lead_time_hrs": 4.99,
          },
        ],
      },
    },
  },
  {
    id: 1,
    name: `Order Volume Per Day (last ${DATE_RANGE_PERIOD_DAYS} days)`,
    vizState: {
      query: {
        measures: ["orders.count"],
        timeDimensions: [
          {
            dimension: "orders.createdat",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        order: {
          "orders.createdat": "asc",
        },
        filters: [],
        segments: [],
        dimensions: [],
      },
      chartType: "line",
      data: {
        key: "orders.count",
        dataKey: "orders.count",
        name: "Orders Count",
        cartesianChartData: [
          {
            x: "2021-07-13T00:00:00.000",
            xValues: ["2021-07-13T00:00:00.000"],
            "orders.count": 285,
          },
          {
            x: "2021-07-14T00:00:00.000",
            xValues: ["2021-07-14T00:00:00.000"],
            "orders.count": 195,
          },
          {
            x: "2021-07-15T00:00:00.000",
            xValues: ["2021-07-15T00:00:00.000"],
            "orders.count": 396,
          },
          {
            x: "2021-07-16T00:00:00.000",
            xValues: ["2021-07-16T00:00:00.000"],
            "orders.count": 594,
          },
          {
            x: "2021-07-17T00:00:00.000",
            xValues: ["2021-07-17T00:00:00.000"],
            "orders.count": 324,
          },
          {
            x: "2021-07-18T00:00:00.000",
            xValues: ["2021-07-18T00:00:00.000"],
            "orders.count": 329,
          },
          {
            x: "2021-07-19T00:00:00.000",
            xValues: ["2021-07-19T00:00:00.000"],
            "orders.count": 363,
          },
          {
            x: "2021-07-20T00:00:00.000",
            xValues: ["2021-07-20T00:00:00.000"],
            "orders.count": 293,
          },
          {
            x: "2021-07-21T00:00:00.000",
            xValues: ["2021-07-21T00:00:00.000"],
            "orders.count": 299,
          },
          {
            x: "2021-07-22T00:00:00.000",
            xValues: ["2021-07-22T00:00:00.000"],
            "orders.count": 335,
          },
          {
            x: "2021-07-23T00:00:00.000",
            xValues: ["2021-07-23T00:00:00.000"],
            "orders.count": 356,
          },
          {
            x: "2021-07-24T00:00:00.000",
            xValues: ["2021-07-24T00:00:00.000"],
            "orders.count": 274,
          },
          {
            x: "2021-07-25T00:00:00.000",
            xValues: ["2021-07-25T00:00:00.000"],
            "orders.count": 337,
          },
          {
            x: "2021-07-26T00:00:00.000",
            xValues: ["2021-07-26T00:00:00.000"],
            "orders.count": 492,
          },
          {
            x: "2021-07-27T00:00:00.000",
            xValues: ["2021-07-27T00:00:00.000"],
            "orders.count": 306,
          },
          {
            x: "2021-07-28T00:00:00.000",
            xValues: ["2021-07-28T00:00:00.000"],
            "orders.count": 218,
          },
          {
            x: "2021-07-29T00:00:00.000",
            xValues: ["2021-07-29T00:00:00.000"],
            "orders.count": 262,
          },
          {
            x: "2021-07-30T00:00:00.000",
            xValues: ["2021-07-30T00:00:00.000"],
            "orders.count": 275,
          },
          {
            x: "2021-07-31T00:00:00.000",
            xValues: ["2021-07-31T00:00:00.000"],
            "orders.count": 303,
          },
          {
            x: "2021-08-01T00:00:00.000",
            xValues: ["2021-08-01T00:00:00.000"],
            "orders.count": 340,
          },
          {
            x: "2021-08-02T00:00:00.000",
            xValues: ["2021-08-02T00:00:00.000"],
            "orders.count": 354,
          },
          {
            x: "2021-08-03T00:00:00.000",
            xValues: ["2021-08-03T00:00:00.000"],
            "orders.count": 220,
          },
          {
            x: "2021-08-04T00:00:00.000",
            xValues: ["2021-08-04T00:00:00.000"],
            "orders.count": 283,
          },
          {
            x: "2021-08-05T00:00:00.000",
            xValues: ["2021-08-05T00:00:00.000"],
            "orders.count": 254,
          },
          {
            x: "2021-08-06T00:00:00.000",
            xValues: ["2021-08-06T00:00:00.000"],
            "orders.count": 300,
          },
          {
            x: "2021-08-07T00:00:00.000",
            xValues: ["2021-08-07T00:00:00.000"],
            "orders.count": 366,
          },
          {
            x: "2021-08-08T00:00:00.000",
            xValues: ["2021-08-08T00:00:00.000"],
            "orders.count": 359,
          },
          {
            x: "2021-08-09T00:00:00.000",
            xValues: ["2021-08-09T00:00:00.000"],
            "orders.count": 377,
          },
          {
            x: "2021-08-10T00:00:00.000",
            xValues: ["2021-08-10T00:00:00.000"],
            "orders.count": 320,
          },
          {
            x: "2021-08-11T00:00:00.000",
            xValues: ["2021-08-11T00:00:00.000"],
            "orders.count": 308,
          },
          {
            x: "2021-08-12T00:00:00.000",
            xValues: ["2021-08-12T00:00:00.000"],
            "orders.count": 338,
          },
          {
            x: "2021-08-13T00:00:00.000",
            xValues: ["2021-08-13T00:00:00.000"],
            "orders.count": 146,
          },
        ],
      },
    },
  },
  {
    id: 2,
    name: "Order picked per day (last 30 days)",
    vizState: {
      query: {
        measures: ["orders.count"],
        timeDimensions: [
          {
            dimension: "auditlogs.picking_completed_at",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        order: [["auditlogs.picking_completed_at", "asc"]],
        filters: [],
        segments: [],
        dimensions: [],
      },
      chartType: "line",
      data: {
        key: "orders.count",
        dataKey: "orders.count",
        name: "Orders Count",
        cartesianChartData: [
          {
            x: "2021-07-13T00:00:00.000",
            xValues: ["2021-07-13T00:00:00.000"],
            "orders.count": 229,
          },
          {
            x: "2021-07-14T00:00:00.000",
            xValues: ["2021-07-14T00:00:00.000"],
            "orders.count": 263,
          },
          {
            x: "2021-07-15T00:00:00.000",
            xValues: ["2021-07-15T00:00:00.000"],
            "orders.count": 292,
          },
          {
            x: "2021-07-16T00:00:00.000",
            xValues: ["2021-07-16T00:00:00.000"],
            "orders.count": 585,
          },
          {
            x: "2021-07-17T00:00:00.000",
            xValues: ["2021-07-17T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-07-18T00:00:00.000",
            xValues: ["2021-07-18T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-07-19T00:00:00.000",
            xValues: ["2021-07-19T00:00:00.000"],
            "orders.count": 1017,
          },
          {
            x: "2021-07-20T00:00:00.000",
            xValues: ["2021-07-20T00:00:00.000"],
            "orders.count": 310,
          },
          {
            x: "2021-07-21T00:00:00.000",
            xValues: ["2021-07-21T00:00:00.000"],
            "orders.count": 288,
          },
          {
            x: "2021-07-22T00:00:00.000",
            xValues: ["2021-07-22T00:00:00.000"],
            "orders.count": 337,
          },
          {
            x: "2021-07-23T00:00:00.000",
            xValues: ["2021-07-23T00:00:00.000"],
            "orders.count": 345,
          },
          {
            x: "2021-07-24T00:00:00.000",
            xValues: ["2021-07-24T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-07-25T00:00:00.000",
            xValues: ["2021-07-25T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-07-26T00:00:00.000",
            xValues: ["2021-07-26T00:00:00.000"],
            "orders.count": 1109,
          },
          {
            x: "2021-07-27T00:00:00.000",
            xValues: ["2021-07-27T00:00:00.000"],
            "orders.count": 343,
          },
          {
            x: "2021-07-28T00:00:00.000",
            xValues: ["2021-07-28T00:00:00.000"],
            "orders.count": 222,
          },
          {
            x: "2021-07-29T00:00:00.000",
            xValues: ["2021-07-29T00:00:00.000"],
            "orders.count": 203,
          },
          {
            x: "2021-07-30T00:00:00.000",
            xValues: ["2021-07-30T00:00:00.000"],
            "orders.count": 289,
          },
          {
            x: "2021-07-31T00:00:00.000",
            xValues: ["2021-07-31T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-08-01T00:00:00.000",
            xValues: ["2021-08-01T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-08-02T00:00:00.000",
            xValues: ["2021-08-02T00:00:00.000"],
            "orders.count": 982,
          },
          {
            x: "2021-08-03T00:00:00.000",
            xValues: ["2021-08-03T00:00:00.000"],
            "orders.count": 238,
          },
          {
            x: "2021-08-04T00:00:00.000",
            xValues: ["2021-08-04T00:00:00.000"],
            "orders.count": 256,
          },
          {
            x: "2021-08-05T00:00:00.000",
            xValues: ["2021-08-05T00:00:00.000"],
            "orders.count": 282,
          },
          {
            x: "2021-08-06T00:00:00.000",
            xValues: ["2021-08-06T00:00:00.000"],
            "orders.count": 270,
          },
          {
            x: "2021-08-07T00:00:00.000",
            xValues: ["2021-08-07T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-08-08T00:00:00.000",
            xValues: ["2021-08-08T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-08-09T00:00:00.000",
            xValues: ["2021-08-09T00:00:00.000"],
            "orders.count": 1169,
          },
          {
            x: "2021-08-10T00:00:00.000",
            xValues: ["2021-08-10T00:00:00.000"],
            "orders.count": 248,
          },
          {
            x: "2021-08-11T00:00:00.000",
            xValues: ["2021-08-11T00:00:00.000"],
            "orders.count": 313,
          },
          {
            x: "2021-08-12T00:00:00.000",
            xValues: ["2021-08-12T00:00:00.000"],
            "orders.count": 342,
          },
          {
            x: "2021-08-13T00:00:00.000",
            xValues: ["2021-08-13T00:00:00.000"],
            "orders.count": 47,
          },
        ],
      },
    },
  },
  {
    id: 3,
    name: "Order packed per day (last 30 days)",
    vizState: {
      query: {
        measures: ["orders.count"],
        timeDimensions: [
          {
            dimension: "auditlogs.prepacking_completed_at",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        order: [["auditlogs.prepacking_completed_at", "asc"]],
        filters: [],
        segments: [],
        dimensions: [],
      },
      chartType: "line",
      data: {
        key: "orders.count",
        dataKey: "orders.count",
        name: "Orders Count",
        cartesianChartData: [
          {
            x: "2021-07-13T00:00:00.000",
            xValues: ["2021-07-13T00:00:00.000"],
            "orders.count": 312,
          },
          {
            x: "2021-07-14T00:00:00.000",
            xValues: ["2021-07-14T00:00:00.000"],
            "orders.count": 236,
          },
          {
            x: "2021-07-15T00:00:00.000",
            xValues: ["2021-07-15T00:00:00.000"],
            "orders.count": 241,
          },
          {
            x: "2021-07-16T00:00:00.000",
            xValues: ["2021-07-16T00:00:00.000"],
            "orders.count": 626,
          },
          {
            x: "2021-07-17T00:00:00.000",
            xValues: ["2021-07-17T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-07-18T00:00:00.000",
            xValues: ["2021-07-18T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-07-19T00:00:00.000",
            xValues: ["2021-07-19T00:00:00.000"],
            "orders.count": 1001,
          },
          {
            x: "2021-07-20T00:00:00.000",
            xValues: ["2021-07-20T00:00:00.000"],
            "orders.count": 306,
          },
          {
            x: "2021-07-21T00:00:00.000",
            xValues: ["2021-07-21T00:00:00.000"],
            "orders.count": 281,
          },
          {
            x: "2021-07-22T00:00:00.000",
            xValues: ["2021-07-22T00:00:00.000"],
            "orders.count": 330,
          },
          {
            x: "2021-07-23T00:00:00.000",
            xValues: ["2021-07-23T00:00:00.000"],
            "orders.count": 334,
          },
          {
            x: "2021-07-24T00:00:00.000",
            xValues: ["2021-07-24T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-07-25T00:00:00.000",
            xValues: ["2021-07-25T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-07-26T00:00:00.000",
            xValues: ["2021-07-26T00:00:00.000"],
            "orders.count": 1093,
          },
          {
            x: "2021-07-27T00:00:00.000",
            xValues: ["2021-07-27T00:00:00.000"],
            "orders.count": 334,
          },
          {
            x: "2021-07-28T00:00:00.000",
            xValues: ["2021-07-28T00:00:00.000"],
            "orders.count": 215,
          },
          {
            x: "2021-07-29T00:00:00.000",
            xValues: ["2021-07-29T00:00:00.000"],
            "orders.count": 196,
          },
          {
            x: "2021-07-30T00:00:00.000",
            xValues: ["2021-07-30T00:00:00.000"],
            "orders.count": 288,
          },
          {
            x: "2021-07-31T00:00:00.000",
            xValues: ["2021-07-31T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-08-01T00:00:00.000",
            xValues: ["2021-08-01T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-08-02T00:00:00.000",
            xValues: ["2021-08-02T00:00:00.000"],
            "orders.count": 961,
          },
          {
            x: "2021-08-03T00:00:00.000",
            xValues: ["2021-08-03T00:00:00.000"],
            "orders.count": 233,
          },
          {
            x: "2021-08-04T00:00:00.000",
            xValues: ["2021-08-04T00:00:00.000"],
            "orders.count": 254,
          },
          {
            x: "2021-08-05T00:00:00.000",
            xValues: ["2021-08-05T00:00:00.000"],
            "orders.count": 278,
          },
          {
            x: "2021-08-06T00:00:00.000",
            xValues: ["2021-08-06T00:00:00.000"],
            "orders.count": 264,
          },
          {
            x: "2021-08-07T00:00:00.000",
            xValues: ["2021-08-07T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-08-08T00:00:00.000",
            xValues: ["2021-08-08T00:00:00.000"],
            "orders.count": 0,
          },
          {
            x: "2021-08-09T00:00:00.000",
            xValues: ["2021-08-09T00:00:00.000"],
            "orders.count": 1144,
          },
          {
            x: "2021-08-10T00:00:00.000",
            xValues: ["2021-08-10T00:00:00.000"],
            "orders.count": 243,
          },
          {
            x: "2021-08-11T00:00:00.000",
            xValues: ["2021-08-11T00:00:00.000"],
            "orders.count": 307,
          },
          {
            x: "2021-08-12T00:00:00.000",
            xValues: ["2021-08-12T00:00:00.000"],
            "orders.count": 336,
          },
          {
            x: "2021-08-13T00:00:00.000",
            xValues: ["2021-08-13T00:00:00.000"],
            "orders.count": 13,
          },
        ],
      },
    },
  },
  {
    id: 5,
    name: "Exceptional Orders Distribution",
    vizState: {
      query: {
        measures: ["orders.count"],
        timeDimensions: [],
        order: {
          "orders.count": "desc",
        },
        filters: [],
        segments: ["orders.exceptional_orders"],
        dimensions: ["orders.orderstatus"],
      },
      chartType: "pie",
      data: {
        data: [
          {
            x: "RESIZING_REQUIRED",
            xValues: ["RESIZING_REQUIRED"],
            "orders.count": 1037,
          },
          { x: "CANCELLED", xValues: ["CANCELLED"], "orders.count": 85 },
        ],
        dataKey: "orders.count",
      },
    },
  },
  {
    id: 6,
    name: "Top 20 SKUs",
    vizState: {
      query: {
        measures: ["orders.count"],
        timeDimensions: [],
        order: {
          "orders.count": "desc",
        },
        dimensions: ["orderlineitems.productname"],
        limit: 20,
      },
      chartType: "bar",
      data: {
        key: "orders.count",
        dataKey: "orders.count",
        name: "Orders Count",
        cartesianChartData: [
          {
            x: "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
            xValues: [
              "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
            ],
            "orders.count": 1406,
          },
          {
            x: "Silver Round 5mm White CZ Solitaire Earrings 1ct TW",
            xValues: ["Silver Round 5mm White CZ Solitaire Earrings 1ct TW"],
            "orders.count": 1274,
          },
          {
            x: "1/10 CT Diamond TW Fashion Ring Silver I3",
            xValues: ["1/10 CT Diamond TW Fashion Ring Silver I3"],
            "orders.count": 777,
          },
          {
            x: "6 5/8 CT TGW Citrine White Topaz Fashion Ring Silver",
            xValues: ["6 5/8 CT TGW Citrine White Topaz Fashion Ring Silver"],
            "orders.count": 767,
          },
          {
            x: '2 CT TGW BRASS MULTI COLOR CZ BOLO BRACELET LENGTH (INCHES): 9" ADJUSTABLE',
            xValues: [
              '2 CT TGW BRASS MULTI COLOR CZ BOLO BRACELET LENGTH (INCHES): 9" ADJUSTABLE',
            ],
            "orders.count": 726,
          },
          {
            x: "1/8 CT Diamond TW Fashion Ring Silver I3",
            xValues: ["1/8 CT Diamond TW Fashion Ring Silver I3"],
            "orders.count": 651,
          },
          {
            x: "1.36 CT TGW Cubic Zirconia Bridal Set Ring Silver White",
            xValues: [
              "1.36 CT TGW Cubic Zirconia Bridal Set Ring Silver White",
            ],
            "orders.count": 561,
          },
          {
            x: "5 CT TGW Black Sapphire Created White Sapphire Fashion Ring Silver",
            xValues: [
              "5 CT TGW Black Sapphire Created White Sapphire Fashion Ring Silver",
            ],
            "orders.count": 496,
          },
          {
            x: "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
            xValues: [
              "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
            ],
            "orders.count": 486,
          },
          {
            x: "1/5 CT Diamond TW Anniversary Ring Silver I3",
            xValues: ["1/5 CT Diamond TW Anniversary Ring Silver I3"],
            "orders.count": 478,
          },
          {
            x: "1 CT TGW Morganite Fashion Post Earrings 14k Pink Gold",
            xValues: ["1 CT TGW Morganite Fashion Post Earrings 14k Pink Gold"],
            "orders.count": 459,
          },
          {
            x: "1/10 CT Diamond TW Mens Ring Silver GH I2;I3",
            xValues: ["1/10 CT Diamond TW Mens Ring Silver GH I2;I3"],
            "orders.count": 442,
          },
          {
            x: "3.15 CT TGW Cubic Zirconia Fashion Ring Set Silver White",
            xValues: [
              "3.15 CT TGW Cubic Zirconia Fashion Ring Set Silver White",
            ],
            "orders.count": 409,
          },
          {
            x: "1 1/2ct TGW Marqise Garnet, Citrine, Amethyst & Round Peridot stones Flower Ring set in silver",
            xValues: [
              "1 1/2ct TGW Marqise Garnet, Citrine, Amethyst & Round Peridot stones Flower Ring set in silver",
            ],
            "orders.count": 406,
          },
          {
            x: "1 CT DEW Created Moissanite-White Fashion Ring Silver",
            xValues: ["1 CT DEW Created Moissanite-White Fashion Ring Silver"],
            "orders.count": 405,
          },
          {
            x: "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
            xValues: [
              "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
            ],
            "orders.count": 404,
          },
          {
            x: "3 7/8 CT TGW MULTI COLOR CREATED SAPPHIRE FASHION RING SILVER",
            xValues: [
              "3 7/8 CT TGW MULTI COLOR CREATED SAPPHIRE FASHION RING SILVER",
            ],
            "orders.count": 403,
          },
          {
            x: "Wedding Band Ring Silver",
            xValues: ["Wedding Band Ring Silver"],
            "orders.count": 389,
          },
          {
            x: "4/5 CT TGW Created White Sapphire Fashion Ring Silver",
            xValues: ["4/5 CT TGW Created White Sapphire Fashion Ring Silver"],
            "orders.count": 388,
          },
          {
            x: "2 1/5 CT TGW Created White Sapphire Fashion Ring Silver",
            xValues: [
              "2 1/5 CT TGW Created White Sapphire Fashion Ring Silver",
            ],
            "orders.count": 387,
          },
        ],
      },
    },
  },
  {
    id: 7,
    name: "Top 20 Picking Rows",
    vizState: {
      query: {
        measures: ["orders.count"],
        timeDimensions: [],
        order: {
          "orders.count": "desc",
        },
        dimensions: ["orderlineitems.row"],
        limit: 20,
      },
      chartType: "bar",
      data: {
        key: "orders.count",
        dataKey: "orders.count",
        name: "Orders Count",
        cartesianChartData: [
          { x: "2", xValues: [2], "orders.count": 11556 },
          { x: "14", xValues: [14], "orders.count": 8980 },
          { x: "18", xValues: [18], "orders.count": 7863 },
          { x: "22", xValues: [22], "orders.count": 7700 },
          { x: "6", xValues: [6], "orders.count": 6212 },
          { x: "10", xValues: [10], "orders.count": 5205 },
          { x: "20", xValues: [20], "orders.count": 5061 },
          { x: "4", xValues: [4], "orders.count": 4968 },
          { x: "16", xValues: [16], "orders.count": 4893 },
          { x: "8", xValues: [8], "orders.count": 4821 },
          { x: "12", xValues: [12], "orders.count": 4785 },
          { x: "24", xValues: [24], "orders.count": 2189 },
          { x: "17", xValues: [17], "orders.count": 217 },
          { x: "1", xValues: [1], "orders.count": 198 },
          { x: "13", xValues: [13], "orders.count": 192 },
          { x: "3", xValues: [3], "orders.count": 20 },
          { x: "15", xValues: [15], "orders.count": 1 },
        ],
      },
    },
  },
  {
    id: 78,
    name: "Shipments By Carrier",
    vizState: {
      query: {
        measures: ["orders.count"],
        timeDimensions: [],
        order: {
          "orders.count": "desc",
        },
        filters: [],
        segments: [],
        dimensions: ["orders.carrier"],
      },
      chartType: "bar",
      data: {
        key: "orders.count",
        dataKey: "orders.count",
        name: "Orders Count",
        cartesianChartData: [
          { x: "USPS", xValues: ["USPS"], "orders.count": 29816 },
          { x: "Fedex", xValues: ["Fedex"], "orders.count": 15922 },
          { x: "UPS", xValues: ["UPS"], "orders.count": 14593 },
          { x: "Canada Post", xValues: ["Canada Post"], "orders.count": 6383 },
          { x: "Canpar", xValues: ["Canpar"], "orders.count": 50 },
          {
            x: "Loomis Express",
            xValues: ["Loomis Express"],
            "orders.count": 11,
          },
          { x: "UPS GROUND", xValues: ["UPS GROUND"], "orders.count": 2 },
          { x: "Purolator", xValues: ["Purolator"], "orders.count": 1 },
          { x: "next day air", xValues: ["next day air"], "orders.count": 1 },
          { x: "FedEx", xValues: ["FedEx"], "orders.count": 1 },
          { x: "Fedex 2nd Day", xValues: ["Fedex 2nd Day"], "orders.count": 1 },
          { x: "First Class", xValues: ["First Class"], "orders.count": 1 },
        ],
      },
    },
  },
];
