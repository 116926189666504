import UserSvg from "#components/salesManagement/user.svg";

const SearchResultEntity = (props) => {
  return (
    <div className="mb-2 flex items-center rounded-lg bg-white p-2 shadow-md">
      <img
        src={UserSvg}
        alt={props?.firstName}
        className="mr-3 h-12 w-12 rounded-full bg-gray-300"
      />
      <div className="flex-grow">
        <h3 className="mb-1 text-base font-semibold">{`${props?.firstName}  ${props?.lastName}`}</h3>
        <p className="mb-1 text-sm text-gray-600">{`#${props?.hrid}`}</p>
        <p className="text-sm text-gray-600">{`${props?.contactNumber || "N/A"}, ${props?.email || "N/A"}`}</p>
      </div>
      <button
        type="button"
        onClick={() => {
          props.setSelectedCustomer(props.index);
        }}
        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-224E73 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700">
        Select
      </button>
    </div>
  );
};

export default SearchResultEntity;
