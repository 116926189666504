import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _, { set } from "lodash";
import Toggle from "#components/utils/Toggle";
import { useEffect, useState } from "react";
import { useCodeSuggestion } from "#hooks/useCodeSuggestion";
import { Tooltip } from "antd";
import Autocomplete from "#components/utils/Autocomplete";
import { Country, State } from "country-state-city";

const WarehouseForm = ({
  onClose,
  title,
  onChange,
  onChangeAdress,
  selectedWarehouse,
  onSubmit,
  warehouses,
  setSelectedWarehouse,
}) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);
  const [codesList, setCodesList] = useState([]);
  const {
    code,
    error,
    usingGeneratedCode,
    setUsingGeneratedCode,
    handleNameChange,
  } = useCodeSuggestion();

  useEffect(() => {
    setCodesList(warehouses.map((warehouse) => warehouse.code));
  }, [warehouses]);

  useEffect(() => {
    if (usingGeneratedCode) {
      setSelectedWarehouse({
        ...selectedWarehouse,
        code: error?.error ? "" : code,
      });
    }
  }, [code]);

  useEffect(() => {
    if (selectedWarehouse.name == "") {
      setSelectedWarehouse({ ...selectedWarehouse, code: "" });
    }
    if (selectedWarehouse.id) {
      setUsingGeneratedCode(false);
    }
  }, [selectedWarehouse.name, selectedWarehouse.id]);

  const handleOnChangeForName = (e) => {
    !selectedWarehouse?.id && handleNameChange(e.target.value, codesList);
    onChange(e);
  };
  const handleOnChangeForCode = (e) => {
    setUsingGeneratedCode(false);
    onChange(e);
  };

  const { address } = selectedWarehouse;
  useEffect(() => {
    if (selectedWarehouse) {
      setSelectedCountry(address?.country || "");
      setSelectedState(address?.state || "");
    }
  }, [address]);

  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry);
      setFilteredStates(states);
      if (!states.some((state) => state.isoCode === selectedState)) {
        setSelectedState("");
      }
    } else {
      setFilteredStates([]);
      setSelectedState("");
    }
  }, [selectedCountry]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(e));
    onChangeAdress({
      target: {
        name: "country",
        value: e,
      },
    });
  };

  const handleStateChange = (e) => {
    setSelectedState(e);
    onChangeAdress({
      target: {
        name: "state",
        value: e,
      },
    });
  };

  return (
    <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Warehouse Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="name"
            label="Name"
            placeholder="Name"
            onChange={handleOnChangeForName}
            value={selectedWarehouse.name}
          />
        </div>

        <div>
          <Tooltip title={"0-9 digits, A-Z letters are allowed."}>
            <TextField
              type="text"
              id="code"
              label="Code"
              placeholder=" "
              onChange={handleOnChangeForCode}
              value={
                usingGeneratedCode
                  ? error?.error
                    ? ""
                    : code
                  : selectedWarehouse.code?.toUpperCase()
              }
            />
          </Tooltip>
        </div>
        {usingGeneratedCode && !(error?.error || code == "") && (
          <div className="text-sm italic">
            *This is a generated code. You can still edit it.
          </div>
        )}
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Warehouse Contact Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="email"
            label="Email"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.email}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="phone"
            label="Phone"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.phone}
          />
        </div>
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Warehouse Address Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="line1"
            label="Street Address 1"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.line1}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="line2"
            label="Street Address 2"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.line2}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="city"
            label="City"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.city}
          />
        </div>
        <div>
          <Tooltip
            title={!selectedCountry ? "Please select a country first" : ""}
            placement="top">
            <div style={{ display: "inline-block", width: "100%" }}>
              <Autocomplete
                options={filteredStates}
                labelKey="name"
                valueKey="isoCode"
                onChange={handleStateChange}
                value={selectedWarehouse.address?.state || ""}
                placeholder="Select a State / Province"
                id="state"
                // disabled={!selectedCountry}
              />
            </div>
          </Tooltip>
        </div>
        <div>
          <TextField
            type="text"
            id="zip"
            label="ZIP code"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.zip}
          />
        </div>
        <div>
          <Autocomplete
            options={Country.getAllCountries()}
            labelKey="name"
            valueKey="isoCode"
            onChange={handleCountryChange}
            value={selectedWarehouse.address?.country || ""}
            placeholder="Select a Country"
            id="country"
          />
        </div>
        <div>
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Type of warehouse
            </span>
          </label>
          <MultiSelectAutoComplete
            options={[
              { name: "B2B" },
              { name: "D2C" },
              { name: "PREP CENTER" },
            ]}
            labelKey="name"
            valueKey="name"
            setValues={(e) =>
              onChange({
                target: {
                  name: "typeOfWarehouse",
                  value: e,
                },
              })
            }
            values={selectedWarehouse.typeOfWarehouse || []}
          />
        </div>
        <div>
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Storage Types
            </span>
          </label>
          <MultiSelectAutoComplete
            options={[{ name: "Ambient" }, { name: "Chilled" }]}
            labelKey="name"
            valueKey="name"
            setValues={(e) =>
              onChange({
                target: {
                  name: "storageTypes",
                  value: e,
                },
              })
            }
            values={selectedWarehouse.storageTypes || []}
          />
        </div>
        <div className="flex items-center space-x-4 px-2">
          <div>
            <Toggle
              enabled={selectedWarehouse.active}
              setEnabled={(e) =>
                onChange({
                  target: {
                    name: "active",
                    value: e,
                  },
                })
              }
            />
          </div>
          <div className="text-lg">Active</div>
        </div>
      </div>
    </Modal>
  );
};

export default WarehouseForm;
