import { useContext, useEffect, useState } from "react";
import { AppStateContext } from "#contexts/appState";
const dimensionUnitMap = {
  inches: "in",
  centimeters: "cm",
  feet: "ft",
  millimeters: "mm",
  meters: "m",
  yards: "yd",
};

const weightUnitMap = {
  pounds: "lb",
  kilograms: "kg",
  grams: "g",
  ounces: "oz",
};

const TENANT_CONFIG_ERRORS = {
  PREFFERED_DIMENSION_UNIT_NOT_DEFINED:
    "The preferred dimension unit is not defined for this tenant.",
  PREFFERED_WEIGHT_UNIT_NOT_DEFINED:
    "The preferred weight unit is not defined for this tenant.",
};

// This react hook will get the preferredDimensionUnit from tenant and update it with it's abbreviation
const useDimensionUnitAbbreviation = () => {
  const appState = useContext(AppStateContext);

  // If the tenant is not set on app state context simply return
  if (!appState?.tenant) {
    return;
  }

  const preferredDimensionUnit =
    appState?.tenant?.settings?.metricsConfig?.preferredDimensionUnit?.[0];

  const [dimAlertshown, setDimAlertShown] = useState(false);

  if (!preferredDimensionUnit && !dimAlertshown) {
    setDimAlertShown(true);
    appState.setAlert(
      TENANT_CONFIG_ERRORS.PREFFERED_DIMENSION_UNIT_NOT_DEFINED,
      "error",
      3000,
    );
  }

  if (dimensionUnitMap[preferredDimensionUnit] === undefined) {
    return preferredDimensionUnit;
  }

  return dimensionUnitMap[preferredDimensionUnit];
};

// This react hook will get the preferredWeightUnit from tenant and update it with it's abbreviation
const useWeightUnitAbbreviation = () => {
  const appState = useContext(AppStateContext);

  // If the tenant is not set on app state context simply return
  if (!appState?.tenant) {
    return;
  }

  const preferredWeightUnit =
    appState?.tenant?.settings?.metricsConfig?.preferredWeightUnit?.[0];

  const [weightAlertshown, setWeightAlertShown] = useState(false);
  if (!preferredWeightUnit && !weightAlertshown) {
    setWeightAlertShown(true);
    appState.setAlert(
      TENANT_CONFIG_ERRORS.PREFFERED_WEIGHT_UNIT_NOT_DEFINED,
      "error",
      3000,
    );
  }
  if (weightUnitMap[preferredWeightUnit] === undefined) {
    return preferredWeightUnit;
  }

  return weightUnitMap[preferredWeightUnit];
};

// This react hook will get the preferredDimensionUnit and preferredWeightUnit from tenant
const getPreferredMetricsUnit = () => {
  const appState = useContext(AppStateContext);
  const preferredWeightUnit =
    appState?.tenant?.settings?.metricsConfig?.preferredWeightUnit?.[0];
  const preferredDimensionUnit =
    appState?.tenant?.settings?.metricsConfig?.preferredDimensionUnit?.[0];

  const [weightAlertshown, setWeightAlertShown] = useState(false);
  const [dimAlertshown, setDimAlertShown] = useState(false);

  if (!preferredDimensionUnit && !dimAlertshown) {
    setDimAlertShown(true);
    appState.setAlert(
      TENANT_CONFIG_ERRORS.PREFFERED_DIMENSION_UNIT_NOT_DEFINED,
      "error",
      3000,
    );
  }

  if (!preferredWeightUnit && !weightAlertshown) {
    setWeightAlertShown(true);
    appState.setAlert(
      TENANT_CONFIG_ERRORS.PREFFERED_WEIGHT_UNIT_NOT_DEFINED,
      "error",
      3000,
    );
  }

  return {
    preferredDimensionUnit,
    preferredWeightUnit,
  };
};

export {
  useDimensionUnitAbbreviation,
  useWeightUnitAbbreviation,
  getPreferredMetricsUnit,
};
