import withSkuBundleFormLogic from "#components/HOC/withSkuBundleFormLogic";
import PageTitle from "#components/utils/PageTitle";
import ModalButton from "#components/utils/ModalButton";
import SkuBundleForm from "#components/skuBundles/SkuBundleForm";

const AddUpdateSkuBundle = ({
  selectedSkuBundle,
  saveSkuBundle,
  onChange,
  showBundleItemQuantityForm,
  setShowBundleItemQuantityForm,
  skuBundleContents,
  onRemoveBundleItem,
  onAddBundleItem,
  bundleItemQuantity,
  onChangeBundleItemQuantity,
  onChangeInventorySearch,
  skuBundleSuggestionHeaders,
  skuBundleContentHeaders,
  onClickEditQuantity,
  setEnabled,
  title,
  onCancel,
}) => (
  <div className="bg-white p-5">
    <div className="flex items-center">
      <div className="flex w-full flex-row justify-between">
        <PageTitle>Bundle Management</PageTitle>
        <div>
          <ModalButton
            className="mr-5"
            onClick={() => onCancel()}
            text="Cancel"
          />
          <ModalButton
            onClick={() => saveSkuBundle(selectedSkuBundle)}
            text="Submit"
          />
        </div>
      </div>
    </div>{" "}
    {selectedSkuBundle && (
      <SkuBundleForm
        title={title}
        skuBundleSuggestionHeaders={skuBundleSuggestionHeaders}
        skuBundleContentHeaders={skuBundleContentHeaders}
        selectedSkuBundle={selectedSkuBundle}
        onChange={onChange}
        skuBundleContents={skuBundleContents}
        showBundleItemQuantityForm={showBundleItemQuantityForm}
        setShowBundleItemQuantityForm={setShowBundleItemQuantityForm}
        onAddBundleItem={onAddBundleItem}
        onRemoveBundleItem={onRemoveBundleItem}
        bundleItemQuantity={bundleItemQuantity}
        onChangeBundleItemQuantity={onChangeBundleItemQuantity}
        onChangeInventorySearch={onChangeInventorySearch}
        onClickEditQuantity={onClickEditQuantity}
        setEnabled={setEnabled}
      />
    )}
  </div>
);

export default withSkuBundleFormLogic(AddUpdateSkuBundle);
