import React, { useEffect } from "react";

const ConfirmationPopUp = ({ message, context, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  // Handle manual close
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="fixed left-0 right-0 top-0 z-50 flex items-center justify-center pr-4">
      <div className="relative mt-0.5 flex h-[70px] w-[800px] max-w-full items-center justify-between rounded-lg border border-green-500 bg-green-100 p-4 shadow-lg">
        <div className="flex items-center">
          <div
            style={{
              width: "2.0rem",
              height: "1.9rem",
              borderRadius: "50%",
              border: "2px solid #059669",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "1rem",
            }}>
            <svg
              className="h-6 w-6 text-green-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <p className="font-semibold text-black">{message}</p>
        </div>
        <div
          className="absolute right-2 top-2 cursor-pointer"
          onClick={handleClose}>
          <svg
            className="h-4 w-4 text-green-500 hover:text-green-700"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopUp;
