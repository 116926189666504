import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FORECASTS, GET_SKU } from "#queries";
import { useTempQuery } from "#hooks/useTempQuery";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";

const withForecastsLogic = (WrappedComponent) => {
  return (props) => {
    const [forecasts, setForecasts] = useState([]);
    const [showForecast, setShowForecast] = useState(false);
    const appState = useContext(AppStateContext);
    const [fetchedSku, setFetchedSku] = useState(null);
    const forecastsQuery = useTempQuery(GET_FORECASTS);
    const getSku = useTempQuery(GET_SKU);
    useEffect(() => {
      if (forecastsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (forecastsQuery.data) {
        setForecasts(forecastsQuery.data.forecasts);
        console.log("WrappedComponent");
        console.log(WrappedComponent.toString());
        console.log(WrappedComponent.toString().indexOf("InventoryOverview"));
        (WrappedComponent.toString().indexOf("InventoryOverview") !== -1 ||
          WrappedComponent.toString().indexOf("Inventory Overview") !== -1) &&
          setShowForecast(true);
      }
    }, [forecastsQuery.loading, forecastsQuery.data, forecastsQuery.error]);

    useEffect(() => {
      forecastsQuery.fetchData();
    }, []);

    useEffect(() => {
      if (getSku.data && getSku.data.sku) {
        setFetchedSku(getSku.data.sku);
      } else {
        setFetchedSku(null);
      }
    }, [getSku.loading, getSku.error, getSku.data]);

    return (
      <WrappedComponent
        forecasts={showForecast ? forecasts : []}
        runForecast={() => {
          appState.setLoading();
          appState.setAlert("Forecast run has started.", "success");
          setTimeout(() => {
            setShowForecast(true);
            appState.removeLoading();
            appState.setAlert("Forecast run has completed.", "success");
          }, 8000);
        }}
        getSku={(sku) => getSku.fetchData({ sku })}
        fetchedSku={fetchedSku}
        setFetchedSku={setFetchedSku}
      />
    );
  };
};

export default withForecastsLogic;
