import { useState, useEffect, useContext } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { GET_PRODUCTS } from "#queries";
import _ from "lodash";
import noSearchResultsIcon from "#static/images/noSearchResults.jpg";
import ListViewProducts from "./ListViewProducts";
import { AppStateContext } from "#contexts/appState";
import LoadingIndicator from "#components/utils/LoadingIndicator";

const DEFAULT_SEARCH_FIELDS = [
  {
    name: "SKU",
    value: "sku",
    enabled: false,
  },
  {
    name: "Name",
    value: "name",
    enabled: false,
  },
  {
    name: "Description",
    value: "description",
    enabled: false,
  },
  {
    name: "UPC",
    value: "upc",
    enabled: false,
  },
];
const PRODUCTS_PER_PAGE = 25;

const OrderEnhancedSearch = (props) => {
  const productsQuery = useQuery(GET_PRODUCTS);
  const [keyword, setKeyword] = useState("");
  const [searchFields, setSearchFields] = useState(DEFAULT_SEARCH_FIELDS);
  const [searchResults, setSearchResults] = useState([]);
  const [productsPageNo, setProductsPageNo] = useState(1);

  useEffect(() => {
    if (keyword && keyword.trim() !== "") {
      onChangeProductSearch(keyword);
    }
  }, [searchFields]);

  useEffect(() => {
    // Fetch products on initial render
    if (!keyword) {
      onChangeProductSearch("");
    }
  }, [keyword]);

  const onChangeProductSearch = async (searchKeyword) => {
    const response = await productsQuery.fetchData({
      perPage: PRODUCTS_PER_PAGE,
      pageNumber: productsPageNo,
      filters: {
        keyword: searchKeyword,
        salesChannels: [props.selectedOrder?.company],
        searchFields: searchFields.filter((i) => i.enabled).map((i) => i.value),
      },
      sort: "-createdAt",
    });

    if (response.data?.products?.entities) {
      setSearchResults(response.data.products.entities);
      setProductsPageNo((prev) => prev + 1);
    }
  };
  const debouncedProductSearch = _.debounce(onChangeProductSearch, 500);

  const checkProductPagination = async () => {
    if (
      productsQuery.data.products.total >
      (productsPageNo - 1) * PRODUCTS_PER_PAGE
    ) {
      const vars = {
        perPage: PRODUCTS_PER_PAGE,
        pageNumber: productsPageNo,
        filters: {
          keyword: keyword,
          salesChannels: [props.selectedOrder?.company],
          searchFields: searchFields
            .filter((i) => i.enabled)
            .map((i) => i.value),
        },
        sort: "-createdAt",
      };
      return await productsQuery.fetchData(vars);
    }
  };

  const handleProductScroll = async (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const threshold = 1;
    if (scrollTop + clientHeight + threshold >= scrollHeight) {
      const paginatedProductsResponse = await checkProductPagination();

      if (paginatedProductsResponse?.data?.products?.entities) {
        setSearchResults((prev) => [
          ...prev,
          ...paginatedProductsResponse.data.products.entities,
        ]);
        setProductsPageNo((prev) => prev + 1);
      }
    }
  };

  return (
    <div className="relative flex-1 flex-col">
      <div className="relative flex-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="search"
          name="search"
          id="search"
          className="block w-full rounded-md border-0 py-3 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
          placeholder="Search Products"
          onChange={(e) => {
            setKeyword(e.target.value);
            setProductsPageNo(1);
          }}
          onKeyUp={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
              setProductsPageNo(1);
              setSearchResults([]);

              debouncedProductSearch(e.target.value);
            }
          }}
          value={keyword}
        />
      </div>
      <div className="absolute z-20 mt-4 w-full bg-white p-4">
        <div className="flex-col">
          <div className="flex items-center p-2">
            <h2 className="text-xl font-normal text-[#717679]">Criteria:</h2>
            <div className="flex flex-wrap">
              {searchFields.map((field) => (
                <button
                  key={field.value}
                  className={`m-2 p-2 ${
                    field.enabled
                      ? "bg-primaryAccent text-white"
                      : "bg-[#F1F8FF] text-primaryAccent"
                  } rounded-md text-xl`}
                  onClick={() => {
                    if (searchFields.length === 1) {
                      return;
                    }
                    // DISABLE THE FIELD
                    const updatedFields = searchFields.map((f) => {
                      if (f.value === field.value) {
                        return {
                          ...f,
                          enabled: !field.enabled,
                        };
                      }
                      return f;
                    });
                    setSearchFields(updatedFields);
                  }}>
                  {field.name}
                </button>
              ))}
            </div>
          </div>

          {searchResults.length > 0 ? (
            <ListViewProducts
              {...props}
              products={searchResults}
              setProducts={setSearchResults}
              selectAllDisabled={true}
              handleProductScroll={handleProductScroll}
            />
          ) : null}

          {productsQuery.loading ? (
            <LoadingIndicator shouldShowOnPage={false} />
          ) : !searchResults || searchResults.length === 0 ? (
            <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 text-center">
              <img src={noSearchResultsIcon} className="mx-auto w-48" />
              <div className="mt-4 text-2xl text-black">
                No Results To Display
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OrderEnhancedSearch;
