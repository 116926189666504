import withPackingsLogic from "../HOC/withPackingsLogic";
import moment from "moment-timezone";
import _ from "lodash";
import Stats from "#components/common/Stats";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";
import BatchFilters from "#components/batches/outbound/BatchFilters";
import ExpandedBatch from "#components/batches/outbound/ExpandedBatch";
import PackingsStats from "#components/packings/CustomPackingsStats";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { useContext, useEffect, useState } from "react";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import { renderStatus } from "../../utils/renderStatus";
import Checkbox from "#components/utils/Checkbox";
import Toggle from "#components/utils/Toggle";
import AddButton from "#components/utils/AddButton";

const noValuesText = "No Packings";

const PACKINGS_TABLE_NAME = "packingsList";
const SEARCH_TOOL_TIP_CONTENT = `<div style="font-size: 14px; max-width:500px;">
    Within each batch, the search will look for: 
    <b>Order IDs, Tote</b><br />
    Additionally, within the items 
    contained in each batch, the search will look for:<br />
    <b>SKU, ASIN, Product Name, Name, UPC, LPN, Pallet ID, Lot ID, Bin Location, Serial Number, Tote.</b></div>
  `;

const PackingsList = ({
  displayBatches,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  sort,
  setSort,
  onChangeSearchKeyword,
  submitFilters,
  setShowFilters,
  filters,
  clearKeyword,
  clearFilters,
  showFilters,
  onChangeFilter,
  handleDateRangeClear,
  allData,
  users,
  expandedBatch,
  setExpandedBatch,
  expandBatch,
  stats,
  setStats,
  productivityStats,
  setProductivityStats,
  fetchBatchesForStats,
  selectedPacker,
  setSelectedPacker,
  fetchBatchesForProductivityStats,
  customers,
  warehouses,
  stations,
  selectedRows,
  selectRow,
  selectAllRows,
  allRowsSelected,
  showOnlySelected,
  setShowOnlySelected,
  clearSelectedBatches,
  downloadBatchesCsv,
  usersMap,
}) => {
  const [headers, setHeaders] = useState([
    getHeaderObject("Batch ID", "id", false),
    getHeaderObject("User", "name", false),
    getHeaderObject("Assigned Time", "assignedTime"),
    getHeaderObject(
      "First Pack",
      "firstCompletedTime",
      true,
      "attributes.firstCompletedTime",
    ),
    getHeaderObject(
      "Last Pack",
      "lastCompletedTime",
      true,
      "attributes.lastCompletedTime",
    ),
    getHeaderObject("# items", "quantity", false),
    getHeaderObject("Tote", "tote", false),
    getHeaderObject("Station", "station", false),
    getHeaderObject("Completion", "completion"),
    getHeaderObject("Status", "status"),
  ]);
  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      PACKINGS_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);
  return (
    <>
      <Stats
        stats={[
          {
            name: "Total Orders",
            stat: allData.total,
            view: "BATCHES",
            setShowView: () => {
              setStats(null);
              setProductivityStats(null);
            },
          },
          {
            name: "Completion",
            stat:
              allData.totalCompleted && allData.total
                ? allData.totalCompleted?.toString() +
                  " / " +
                  parseInt(
                    (allData.totalCompleted / allData.total) * 100,
                  ).toString() +
                  "%"
                : "",
            stringValue: true,
          },
          {
            name: "Packs per Hour",
            stat: allData.completionPerHour,
            view: "PACKINGS_STATS",
            setShowView: () => {
              fetchBatchesForStats();
              fetchBatchesForProductivityStats();
            },
          },
          {
            name: "Items in Error",
            stat: allData.itemsInError,
          },
        ]}
      />
      {filters?.startEndDate && (
        <div className="flex items-end justify-end text-2xl font-bold text-primaryAccent">
          Date Range: {filters.startEndDate[0] || "Beginning"} ~{" "}
          {filters.startEndDate[1] || "Now"}
        </div>
      )}
      {stats && productivityStats ? (
        <PackingsStats
          stats={stats}
          productivityStats={productivityStats}
          setSelectedPacker={setSelectedPacker}
          selectedPacker={selectedPacker}
          onChangeFilter={onChangeFilter}
          submitFilters={submitFilters}
          filters={filters}
          fetchBatchesForStats={fetchBatchesForStats}
          fetchBatchesForProductivityStats={fetchBatchesForProductivityStats}
        />
      ) : (
        <div className="tableWrapper">
          {(customers?.length > 1 || warehouses?.length > 1) && (
            <QuickFilters
              warehouseFilterName={"warehouses"}
              customerFilterName={"customers"}
              customers={customers}
              warehouses={warehouses}
              filters={filters}
              onChangeFilter={onChangeFilter}
            />
          )}
          <SearchSortFilter
            onChangeSearchKeyword={onChangeSearchKeyword}
            filters={filters}
            submitFilters={submitFilters}
            clearKeyword={clearKeyword}
            setSort={setSort}
            sort={sort}
            setShowFilters={setShowFilters}
            clearFilters={clearFilters}
            choices={choices}
            headers={headers}
            setShowHeaders={setShowHeaders}
            tableName={PACKINGS_TABLE_NAME}
            searchTooltip={SEARCH_TOOL_TIP_CONTENT}
          />

          {(selectedRows.length > 0 || showOnlySelected) && (
            <div className="flex items-center space-x-4 px-2">
              <div>
                <Toggle
                  enabled={showOnlySelected}
                  setEnabled={(key) => setShowOnlySelected(key)}
                />
              </div>
              <div className="text-2xl">
                Show only selected batches(
                {selectedRows.length})
              </div>
              <AddButton
                text={"Clear"}
                onClick={() => {
                  clearSelectedBatches();
                }}
              />
              <AddButton
                text={"Export to CSV"}
                onClick={() => {
                  downloadBatchesCsv();
                }}
              />
            </div>
          )}

          <CustomTableWithTheme>
            <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
              <tr className="border-left font-montserrat text-textWhite">
                <th
                  scope="col"
                  className="px-2 py-3 pl-6 text-left font-medium tracking-wider">
                  <Checkbox
                    role="checkbox"
                    onChange={selectAllRows}
                    name="select All Rows"
                    value={"Select All Rows"}
                    checked={allRowsSelected}
                  />
                </th>
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {displayBatches.length === 0 ? (
                <tr className="bg-white">
                  {showHeaders.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                        key={headerIdx}>
                        {noValuesText}
                      </td>
                    ) : (
                      <td
                        className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                        key={headerIdx}></td>
                    ),
                  )}
                </tr>
              ) : null}
              {displayBatches.map((batch, rowIndex) => (
                <tr
                  key={batch.id}
                  className={`${
                    rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}>
                  <td
                    className={`rounded-bl rounded-tl border-l-8 p-5 text-left font-semibold tracking-wider text-primaryAccent ${
                      rowIndex % 2 === 0
                        ? "border-F4C261"
                        : "border-primaryAccent"
                    }`}>
                    <Checkbox
                      role="checkbox"
                      onChange={(_) => {
                        selectRow(rowIndex);
                      }}
                      name="batch"
                      value={batch.id}
                      checked={selectedRows.includes(batch.id)}
                    />
                  </td>
                  {showHeaders.map((header, columnIndex) => {
                    let value = header.correspondingValue;
                    let cellStyle = cellStyleForTable(
                      value,
                      ["id"],
                      columnIndex + 1,
                      rowIndex,
                    );
                    if (value == "id") {
                      return (
                        <td
                          className={cellStyle}
                          onClick={() => expandBatch(batch.id)}>
                          {batch.id}
                        </td>
                      );
                    }
                    if (value == "name") {
                      return (
                        <td className={cellStyle}>
                          {batch.username ||
                            (users &&
                              users.find(
                                (item) => item.id.toString() === batch.user,
                              )?.name)}
                        </td>
                      );
                    }
                    if (value == "assignedTime") {
                      return (
                        <td className={cellStyle}>
                          {moment(
                            batch.assignedTime
                              ? batch.assignedTime
                              : batch.createdAt,
                          ).format("MMM Do YYYY, h:mm a")}
                        </td>
                      );
                    }
                    if (value == "firstCompletedTime") {
                      return (
                        <td className={cellStyle}>
                          {batch.attributes?.firstCompletedTime &&
                            moment(batch.attributes.firstCompletedTime).format(
                              "MMM Do YYYY, h:mm a",
                            )}
                        </td>
                      );
                    }
                    if (value == "lastCompletedTime") {
                      return (
                        <td className={cellStyle}>
                          {batch.attributes?.lastCompletedTime &&
                            moment(batch.attributes.lastCompletedTime).format(
                              "MMM Do YYYY, h:mm a",
                            )}
                        </td>
                      );
                    }
                    if (value == "quantity") {
                      return <td className={cellStyle}>{batch.quantity}</td>;
                    }
                    if (value === "tote") {
                      return <td className={cellStyle}>{batch.tote || `-`}</td>;
                    }
                    if (value === "station") {
                      return (
                        <td className={cellStyle}>
                          {batch.station
                            ? stations?.find((i) => i.id === batch.station)
                                ?.name || `-`
                            : `-`}
                        </td>
                      );
                    }

                    if (value == "completion") {
                      return (
                        <td className={cellStyle}>
                          {batch.completion && `${batch.completion}%`}
                        </td>
                      );
                    }
                    if (value == "status") {
                      return (
                        <td className={cellStyle}>
                          {renderStatus(batch.status)}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </CustomTableWithTheme>
        </div>
      )}
      {!stats && (
        <Pagination
          showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
          showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
          showingTotal={total}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
        />
      )}
      {showFilters && (
        <BatchFilters
          dateRangeLabel="Select Packing Date Range"
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          showDateRangePicker={true}
          handleDateRangeClear={handleDateRangeClear}
          users={
            users
              ? users.filter(
                  (item) =>
                    item.hopstackModules &&
                    item.hopstackModules.includes("Packing"),
                )
              : []
          }
          customers={customers}
          warehouses={warehouses}
        />
      )}
      {expandedBatch && (
        <ExpandedBatch
          batchItems={expandedBatch.items}
          negativeAction={() => setExpandedBatch(null)}
          tableName={"packings.orderDetails"}
          orderIds={expandedBatch.orderIds}
          batch={expandedBatch}
          usersMap={usersMap}
        />
      )}
    </>
  );
};

export default withPackingsLogic(PackingsList);
