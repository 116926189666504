// Define carriers and their URL patterns outside the function
const carriers = {
  UPS: "https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums={trackingNumber}",
  FedEx: "https://www.fedex.com/apps/fedextrack/?tracknumbers={trackingNumber}",
  USPS: "https://tools.usps.com/go/TrackConfirmAction?tLabels={trackingNumber}",
  DHL: "http://www.dhl.com/en/express/tracking.html?brand=DHL&AWB={trackingNumber}",
  OnTrac:
    "http://www.ontrac.com/trackingres.asp?tracking_number={trackingNumber}",
  DPD: "https://www.dpdgroup.com/nl/mydpd/my-parcels/track?lang=en&parcelNumber={trackingNumber}",
  PPL: "https://www.ppl.cz/en/track-a-shipment?shipmentId={trackingNumber}",
};

export default function getCarrierTrackingURL(
  trackingNumber,
  carrierName,
  cellStyle,
) {
  // Check if trackingNumber or carrierName is not provided or empty
  let defaultElement = <td className={cellStyle}>{trackingNumber}</td>;

  if (!trackingNumber || !carrierName) {
    return defaultElement;
  }

  // Convert the carrierName string to lowercase for case-insensitive comparison
  const normalizedCarrierName = carrierName.toLowerCase();

  for (const carrier in carriers) {
    // Check if the normalized carrier name includes the lowercase key
    if (normalizedCarrierName.includes(carrier.toLowerCase())) {
      return (
        <td className={cellStyle}>
          <a
            href={carriers[carrier].replace("{trackingNumber}", trackingNumber)}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer rounded-br rounded-tr text-left font-medium tracking-wider text-primaryAccent hover:underline">
            {trackingNumber}
          </a>
        </td>
      );
    }
  }

  // Return plain text if carrier is not found
  return defaultElement;
}
