import { useState } from "react";
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import ModalV3 from "#components/utils/ModalV3";
import { ALERT_TYPES } from "#components/common/ContainerWithLoaderAndAlert";

const SaveNewBookmark = ({
  setSavingNewBookmark,
  savingNewBookmark,
  saveBookmark,
}) => {
  const [componentAlert, setComponentAlert] = useState(null);

  const trySaveBookmark = () => {
    if (!savingNewBookmark.name || savingNewBookmark.name.trim() === "") {
      setComponentAlert({
        msg: "Bookmark name is required",
        alertType: ALERT_TYPES.ERROR,
      });
      return;
    }
    saveBookmark(savingNewBookmark);
  };

  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }
    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] flex items-center rounded-md p-4 border-[${borderColor}] mb-2 border-2`}>
        <span className="mr-2 flex flex-1 items-center space-x-2 text-lg">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="float-right flex items-center justify-center font-bold text-[#717679]"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };

  return (
    <ModalV3
      isOpen={true}
      onClose={() => setSavingNewBookmark(null)}
      title="Save Bookmark">
      <div className="min-w-3xl modalV3 p-4">
        <div className="flex items-center justify-between pb-4 font-inter">
          <span className="font-inter text-2xl font-semibold text-[#454A4F]">
            Save Bookmark
          </span>
          <XIcon
            className="h-6 w-6 cursor-pointer"
            onClick={() => setSavingNewBookmark(null)}
          />
        </div>
        {componentAlert && renderAlert(componentAlert)}
        <div className="max-w-content flex flex-col space-y-4 py-4">
          <div className="text-xl">
            Are you sure you want to save this bookmark?
          </div>
        </div>
        <div className="max-w-content flex flex-col space-y-4">
          <div className="space-y-2">
            <span className="text-lg font-normal text-[#717679]">
              Bookmark Name
            </span>
            <input
              type="text"
              className="w-full rounded-md border-2 border-[#DDDFE0] p-4"
              placeholder="Enter Bookmark Name"
              value={savingNewBookmark.name}
              onChange={(e) =>
                setSavingNewBookmark({
                  ...savingNewBookmark,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="flex flex-1 items-end justify-end space-x-2">
            <button
              className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent p-2 px-6 font-semibold text-primaryAccent"
              onClick={() => setSavingNewBookmark(null)}>
              Cancel
            </button>
            <button
              className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-2 px-6 font-semibold text-white"
              onClick={trySaveBookmark}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ModalV3>
  );
};

export default SaveNewBookmark;
