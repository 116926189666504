import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_BOX_TYPES, GET_BOX_TYPE } from "#queries";
import { SAVE_BOX_TYPE, DELETE_BOX_TYPE } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { printCanvasLabel } from "#utils/printCanvasLabel";
import readXlsxFile from "read-excel-file";

const withBoxTypesLogic = (WrappedComponent) => {
  return (props) => {
    const [filters, setFilters] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const appState = useContext(AppStateContext);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const [allBoxTypesSelected, setAllBoxTypesSelected] = useState(false);
    const [selectedBoxType, setSelectedBoxType] = useState(null);
    const [selectedBoxTypes, setSelectedBoxTypes] = useState([]);
    const [emptyBoxType, setEmptyBoxType] = useState(null);
    const boxTypesQuery = useQuery(GET_BOX_TYPES);
    const getBoxTypeQuery = useQuery(GET_BOX_TYPE);
    const saveBoxTypeQuery = useQuery(SAVE_BOX_TYPE);
    const saveMultipleBoxTypeQuery = useQuery(SAVE_BOX_TYPE);
    const deleteBoxTypeQuery = useQuery(DELETE_BOX_TYPE);
    const [showFilters, setShowFilters] = useState(false);
    const [lastSelectedRow, setLastSelectedRow] = useState(null);

    useEffect(() => {
      boxTypesQuery.fetchData({
        perPage: entity.perPage,
        filters: {},
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });

      return () => {
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      if (boxTypesQuery.data) {
        entity.setEntities({
          ...boxTypesQuery.data.boxTypes,
          ...boxTypesQuery.variables,
        });
        appState.removeLoading();
      }
    }, [boxTypesQuery.loading, boxTypesQuery.error, boxTypesQuery.data]);

    useEffect(() => {
      if (getBoxTypeQuery.data) {
        setSelectedBoxType(getBoxTypeQuery.data.boxType);
      }

      if (getBoxTypeQuery.error) {
        setSelectedBoxType(null);
      }
    }, [getBoxTypeQuery.loading, getBoxTypeQuery.data, getBoxTypeQuery.error]);

    useEffect(() => {
      if (saveBoxTypeQuery.error) {
        appState.setAlert(saveBoxTypeQuery.error.message, "error", 5000);
      }

      if (saveBoxTypeQuery.data && selectedBoxType) {
        appState.setAlert(
          saveBoxTypeQuery.data.saveBoxType.message,
          "success",
          5000,
        );
        setSelectedBoxType(null);
        boxTypesQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }
    }, [
      saveBoxTypeQuery.loading,
      saveBoxTypeQuery.error,
      saveBoxTypeQuery.data,
    ]);

    useEffect(() => {
      if (deleteBoxTypeQuery.data) {
        appState.hideConfirmation();
        boxTypesQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (deleteBoxTypeQuery.error) {
        appState.hideConfirmation();
      }
    }, [
      deleteBoxTypeQuery.loading,
      deleteBoxTypeQuery.data,
      deleteBoxTypeQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this box type?",
        () => {
          deleteBoxTypeQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    const onChange = (e, entity = "selectedBoxType") => {
      if (entity === "selectedBoxType") {
        const boxType = {
          ...selectedBoxType,
        };

        boxType[e.target.name] =
          e.target.type === "number"
            ? parseFloat(e.target.value)
            : e.target.value;
        setSelectedBoxType(boxType);
      } else {
        const boxType = {
          ...emptyBoxType,
        };

        boxType[e.target.name] =
          e.target.type === "number"
            ? parseFloat(e.target.value)
            : e.target.value;
        setEmptyBoxType(boxType);
      }
    };

    const onChangeDropdown = (field, value, entity = "selectedBoxType") => {
      if (entity === "selectedBoxType") {
        const boxType = {
          ...selectedBoxType,
        };

        boxType[field] = value;
        if (
          field === "boxType" &&
          (!boxType.barcode ||
            boxType.barcode.trim() === "" ||
            boxType.barcode.length === 4)
        ) {
          boxType.barcode = `${naming(boxType.boxType)}-`;
        }
        setSelectedBoxType(boxType);
      } else {
        const boxType = {
          ...emptyBoxType,
        };

        boxType[field] = value;
        setEmptyBoxType(boxType);
      }
    };

    const saveEmptyBoxType = () => {
      const boxType = {
        ...emptyBoxType,
      };

      if (!boxType.boxType) {
        appState.setAlert("Select a Box Type", "error", 5000);
        return;
      }

      if (!boxType.numberOfBoxTypes) {
        appState.setAlert("Select number of box types", "error", 5000);
        return;
      }

      if (boxType.numberOfBoxTypes <= 0) {
        appState.setAlert(
          "Number of box types should be at least 1",
          "error",
          5000,
        );
        return;
      }

      if (!boxType.namingStart) {
        appState.setAlert("Select a naming sequence start", "error", 5000);
        return;
      }

      if (!boxType.namingIncrement) {
        appState.setAlert("Select a naming sequence increment", "error", 5000);
        return;
      }

      let currentNumber = boxType.namingStart;
      let namingConvention = naming(boxType.boxType);
      const randomNumber = Math.floor(100000 + Math.random() * 900000);
      for (let i = 0; i < boxType.numberOfBoxTypes; i++) {
        let numberValue = `${currentNumber}`;
        if (currentNumber < 10) {
          numberValue = `00${currentNumber}`;
        } else if (currentNumber < 100) {
          numberValue = `0${currentNumber}`;
        }
        let barcode = `${namingConvention}-${randomNumber}-${numberValue}`;
        saveBoxTypeQuery.fetchData({
          boxType: boxType.boxType,
          barcode,
          customers: boxType.customers,
          warehouses: boxType.warehouses,
          subBoxes: boxType.subBoxTypes,
          contents: boxType.contents,
          formFactor: boxType.formFactor,
        });
        currentNumber += boxType.namingIncrement;
      }
      setTimeout(() => {
        boxTypesQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setEmptyBoxType(null);
      }, 2000);
    };
    const selectBoxType = (index, shiftKeyPressed) => {
      const selectedListCopy = [...selectedBoxTypes];
      const entities = entity.entities;
      if (shiftKeyPressed) {
        if (lastSelectedRow !== null) {
          const firstIndex = Math.min(lastSelectedRow, index);
          const lastIndex = Math.max(lastSelectedRow, index);
          for (let i = firstIndex; i <= lastIndex; i++) {
            if (!selectedListCopy.includes(entities[i].id)) {
              selectedListCopy.push(entities[i].id);
            }
          }
        } else {
          setLastSelectedRow(index);
        }
      } else {
        setLastSelectedRow(index);
        const idx = selectedListCopy.indexOf(entities[index].id);
        if (idx !== -1) {
          selectedListCopy.splice(idx, 1);
        } else {
          selectedListCopy.push(entities[index].id);
        }
      }

      setSelectedBoxTypes(selectedListCopy);
    };

    const clearSelectedBoxTypes = () => {
      setSelectedBoxTypes([]);
    };

    const addContents = () => {
      let currentSelectedBoxType = selectedBoxType;
      if (
        !currentSelectedBoxType.contents ||
        currentSelectedBoxType.contents.length === 0
      ) {
        currentSelectedBoxType.contents = [];
      }

      currentSelectedBoxType.contents.push({
        formFactor: "",
        quantity: "",
      });
      setSelectedBoxType({ ...currentSelectedBoxType });
    };

    const removeContents = (idx) => {
      let currentSelectedBoxType = selectedBoxType;
      if (
        !currentSelectedBoxType.contents ||
        currentSelectedBoxType.contents.length === 0
      ) {
        return;
      }

      currentSelectedBoxType.contents = currentSelectedBoxType.contents.filter(
        (item, index) => index !== idx,
      );
      setSelectedBoxType({ ...currentSelectedBoxType });
    };

    const selectAllBoxTypes = () => {
      if (allBoxTypesSelected === true) {
        setSelectedBoxTypes([]);
        setAllBoxTypesSelected(false);
      } else {
        setAllBoxTypesSelected(true);
        setSelectedBoxTypes(
          boxTypesQuery.data.boxTypes.entities.map((item) => item.id),
        );
      }
    };

    const uploadMultipleEntries = async (files) => {
      const rows = await readXlsxFile(files);
      for (let i = 0; i < rows.length; i++) {
        if (i === 0) {
          continue;
        }
        const row = rows[i];
        if (!row[0] || !row[1]) {
          continue;
        }

        saveMultipleBoxTypeQuery.fetchData({
          barcode: row[0].toString(),
          boxType: row[1].toString(),
        });
      }

      appState.setLoading();
      setTimeout(() => {
        boxTypesQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        appState.removeLoading();
      }, 1500);
    };

    const onChangeMultiSelect = (field, value) => {
      if (selectedBoxType) {
        const boxType = {
          ...selectedBoxType,
        };
        boxType[field] = value;
        setSelectedBoxType(boxType);
      } else {
        const boxType = {
          ...emptyBoxType,
        };
        boxType[field] = value;
        setEmptyBoxType(boxType);
      }
    };

    const addSubBoxType = () => {
      if (selectedBoxType) {
        const boxType = {
          ...selectedBoxType,
        };
        if (boxType.subBoxTypes) {
          boxType.subBoxTypes.push("");
        } else {
          boxType.subBoxTypes = [""];
        }
        setSelectedBoxType(boxType);
      }
    };

    const onChangeSubBoxType = (idx, value) => {
      if (selectedBoxType) {
        const boxType = {
          ...selectedBoxType,
        };
        if (boxType.subBoxTypes) {
          boxType.subBoxTypes[idx] = value;
        }
        setSelectedBoxType(boxType);
      }
    };

    const removeSubBoxType = (idx) => {
      if (selectedBoxType) {
        const boxType = {
          ...selectedBoxType,
        };
        boxType.subBoxTypes.splice(idx, 1);
        setSelectedBoxType(boxType);
      }
    };

    const onChangeContents = (idx, e) => {
      let currentSelectedBoxType = selectedBoxType;
      const content = currentSelectedBoxType.contents[idx];
      content[e.target.name] = e.target.value;
      currentSelectedBoxType.contents[idx] = content;
      setSelectedBoxType({ ...currentSelectedBoxType });
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return boxTypesQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        boxTypes={entity.displayEntities}
        fetchBoxType={(id) => getBoxTypeQuery.fetchData({ id })}
        selectedBoxType={selectedBoxType}
        emptyBoxType={emptyBoxType}
        setSelectedBoxType={setSelectedBoxType}
        setEmptyBoxType={setEmptyBoxType}
        saveBoxType={(boxType) => {
          saveBoxTypeQuery.fetchData({ ...boxType });
        }}
        saveEmptyBoxType={saveEmptyBoxType}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        deleteButtonClicked={deleteButtonClicked}
        selectedBoxTypes={selectedBoxTypes}
        selectBoxType={selectBoxType}
        clearSelectedBoxTypes={clearSelectedBoxTypes}
        selectAllBoxTypes={selectAllBoxTypes}
        allBoxTypesSelected={allBoxTypesSelected}
        uploadMultipleEntries={uploadMultipleEntries}
        onChangeMultiSelect={onChangeMultiSelect}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(e) => {
          setSelectedCustomer(e);
          setFilters({
            ...filters,
            customers: [e],
          });
          boxTypesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...filters,
              customers: [e],
            },
            sort: entity.sort,
          });
        }}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={(e) => {
          setFilters({
            ...filters,
            warehouses: [e],
          });
          setSelectedWarehouse(e);
          boxTypesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...filters,
              warehouses: [e],
            },
            sort: entity.sort,
          });
        }}
        addSubBoxType={addSubBoxType}
        onChangeSubBoxType={onChangeSubBoxType}
        removeSubBoxType={removeSubBoxType}
        addContents={addContents}
        removeContents={removeContents}
        onChangeContents={onChangeContents}
        writable={props.writable}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          boxTypesQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          boxTypesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          boxTypesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            boxTypesQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,

                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,

            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          boxTypesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={() => {
          entity.setFilters({});
          boxTypesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {},
            sort: entity.sort,
          });
        }}
      />
    );
  };
};

const naming = (boxType) => {
  switch (boxType) {
    case "PAPER_CARTON_BOX":
      return "PAP";
    case "PALLET":
      return "PAL";
    default:
      return null;
  }
};

export default withBoxTypesLogic;
