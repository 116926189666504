import PageTitle from "#components/utils/PageTitle";
import withConsignmentsLogic from "#components/HOC/withConsignmentsLogic";
import ConsignmentsList from "#components/consignments/ConsignmentsList";
import AuditLog from "#components/orders/AuditLog";
import Modal from "#components/utils/Modal";
import _ from "lodash";
import Pagination from "#components/common/Pagination";
import ConsignmentNotesForm from "#components/consignments/ConsignmentNotesForm";
import AddButton from "#components/utils/AddButton";
import ConsignmentFilters from "#components/consignments/ConsignmentFilters";
import UploadBulk from "#components/bulkUpload/UploadBulk";
import ReceivingConsignmentForm from "#components/consignments/ReceivingConsignmentForm";
import ChangeStatusReasonForm from "#components/consignments/ChangeStatusReasonForm";
import OrderDetailsModal from "#components/consignments/OrderDetailsModal";
import ConsignmentForm from "#components/consignments/ConsignmentForm";
import Popover from "#components/utils/Popover";
import ConsignmentModal from "#components/consignments/ConsignmentModal";
import DateRangeErrorModal from "#components/consignments/DateRangeErrorModal";
import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import ExportBanner from "#components/consignments/ExportBanner";
import { ModalContext } from "#components/bulkUpload/useReducer";
import { useQuery } from "#hooks/useQuery";
import { GET_EXPORT_REPORT } from "#queries/index";
import BulkUploadSlideOverModal from "#newUiComponents/bulkUploadV2/BulkUploadSlideOverModal";
import {
  BULK_UPLOAD_DUPLICATE_VALIDATION,
  BULK_UPLOAD_DUPLICATE_ROW_VALIDATION,
} from "#components/HOC/withConsignmentsLogic";
import {
  BULK_UPLOAD_ENTITY_TYPES,
  BULK_UPLOAD_FILE_NAMES,
} from "#newUiComponents/bulkUploadV2/useReducer";
import ExportBanners, {
  BANNER_TYPE_ENUM,
} from "#newUiComponents/commons/ExportBanner";
import { getBulkUploadTemplateURL } from "#utils/helper-functions";
import { AppStateContext } from "#contexts/appState";
import { ModalContext as ModalContexts } from "#newUiComponents/bulkUploadV2/useReducer";

const consignmentAdditionTypes = [
  { name: "Standard", value: "STANDARD" },
  { name: "Dropship", value: "DROPSHIP" },
];

const manageActionTypes = [
  { name: "Export Report", value: "EXPORT_REPORT" },
  { name: "Download Template", value: "DOWNLOAD_TEMPLATE" },
  { name: "Upload File", value: "UPLOAD_FILE" },
];

const Consignments = ({
  auditOrderId,
  setAuditOrderId,
  writable,
  displayConsignments,
  masterData,
  filters,
  onChangeFilter,
  handleDisplayTextForDateRangePicker,
  handleDateRangePickerValue,
  selectedAudit,
  selectedAuditUsersInfo,
  setSelectedAuditUsersInfo,
  showAudit,
  setShowAudit,
  getAudit,
  setSelectedAudit,
  setItems,
  items,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  showFilters,
  clearFilters,
  sort,
  setSort,
  customers,
  warehouses,
  addNewConsignment,
  selectedConsignment,
  setSelectedConsignment,
  onChange,
  onChangeNotes,
  onChangeDropdown,
  saveConsignment,
  saveConsignmentNotes,
  saveConsignmentItemNotes,
  removeItem,
  editConsignment,
  editConsignmentNotes,
  deleteConsignment,
  undoConsignmentReceive,
  selectedConsignmentNotes,
  setSelectedConsignmentNotes,
  selectedWarehouseNotes,
  setSelectedWarehouseNotes,
  selectedConsignmentItemNotes,
  setSelectedConsignmentItemNotes,
  submitAddedProduct,
  editWarehouseNotes,
  saveConsignmentDraft,
  subdomain,
  saveUserTablePreferences,
  dropshipData,
  setDropshipData,
  uploadFile,
  downloadConsignmentReport,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  receiveConsignment,
  receivingConsignment,
  setReceivingConsignment,
  submitReceivingConsignment,
  tenant,
  user,
  debouncedBinLocationSearch,
  binLocationsOptions,
  onChangeReceivingConsignment,
  closeConsignment,
  changeStatusConsignment,
  onChangeStatusConsignment,
  changeStatusConsignmentHandle,
  setChangeStatusConsignment,
  receiveConsignmentPartially,
  isPartiallyReceived,
  setIsPartiallyReceived,
  submitReceiveConsignmentPartially,
  downloadConsignmentItemList,
  validate,
  validationResult,
  casePackEnabled,
  multiAccountSupportEnabled,
  getDamagedInfo,
  showConsignmentDamageInfo,
  closeConsignmentDamageInfo,
  refreshConsignmentItems,
  getConsignmentDetails,
  showConsignmentDetails,
  closeConsignmentDetails,
  groupedConsignments,
  groupConsignmentOn,
  fetchExpandedGroupedConsignments,
  setGroupConsignmentOn,
  customersSelectedForReport,
  setCustomersSelectedForReport,
  bulkUploadV2Enabled,
  actionTypes,
  consignmentActionTypesEnum,
  setShowBulkUpload,
  showBulkUpload,
  selectedActionType,
  setSelectedActionType,
  pastUploadsList,
  showBanner,
  typeOfBanner,
  bulkuploadFileName,
  closeBanners,
  consignmentDashboardFieldsV2,
  searchFilters,
  fetchEnhancedSearchResults,
  searchConsignmentsLoading,
  searchResults,
  setSearchResults,
  currentPage,
  setCurrentPage,
}) => {
  const bulkUploadEntity = useContext(ModalContexts);
  const appState = useContext(AppStateContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [showNow, setShowNow] = useState(false);
  const [bannerMessage, setBannerMessage] = useState(
    "Your report has been scheduled for generation. You will be notified when it is ready for download",
  );
  const [showYellowBanner, setShowYellowBanner] = useState(true);
  const [showGreenBanner, setShowGreenBanner] = useState(false);
  const [showRedBanner, setShowRedBanner] = useState(false);
  const [reportUrl, setReportUrl] = useState("");
  const [showUploadBulk, setShowUploadBulk] = useState(false);
  const modals = useContext(ModalContext);

  const generatedReportUrl = localStorage.getItem(
    "EXPORT_CONSIGNMENTS_REPORT_URL",
  );
  const generatedReportId = localStorage.getItem(
    "EXPORT_CONSIGNMENTS_REPORT_ID",
  );
  const getReportPollQuery = useQuery(GET_EXPORT_REPORT);
  const POLLING_TIMER = 2000;

  useEffect(() => {
    if (generatedReportId && displayConsignments.length > 0) {
      setShowYellowBanner(true);
      setShowGreenBanner(false);
      setShowRedBanner(false);
      setShowNow(true);
      setBannerMessage(
        "Your report has been scheduled for generation. You will be notified when it is ready for download",
      );
      startReportPolling(generatedReportId);
    } else if (generatedReportUrl && displayConsignments.length > 0) {
      setReportUrl(generatedReportUrl);
      setShowYellowBanner(false);
      setShowGreenBanner(true);
      setShowNow(true);
      setBannerMessage("Your report is ready for download.");
    }
  }, [generatedReportUrl, displayConsignments]);

  const startReportPolling = (reportId) => {
    const autoRefreshInterval = setInterval(async () => {
      try {
        const getReportResponse = await getReportPollQuery.fetchData({
          reportId: reportId,
        });

        if (getReportResponse.data.getReport.status === "GENERATED") {
          setReportUrl(getReportResponse.data.getReport.csvUrl);
          localStorage.setItem("EXPORT_CONSIGNMENTS_REPORT_ID", "");
          localStorage.setItem(
            "EXPORT_CONSIGNMENTS_REPORT_URL",
            getReportResponse.data.getReport.csvUrl,
          );
          clearInterval(autoRefreshInterval);
          setShowYellowBanner(false);
          setShowGreenBanner(true);
          setShowRedBanner(false);
          setShowNow(true);
          setBannerMessage("Your report is ready for download.");
        } else if (
          getReportResponse.data.getReport.status === "GENERATION_FAILED"
        ) {
          clearInterval(autoRefreshInterval);
          setShowYellowBanner(false);
          setShowRedBanner(true);
          setShowGreenBanner(false);
          setShowNow(true);
          setBannerMessage("Download failed");
        }
      } catch (error) {
        console.error("Error fetching report:", error);
        clearInterval(autoRefreshInterval);
        setShowYellowBanner(false);
        setShowRedBanner(true);
        setShowGreenBanner(false);
        setShowNow(true);
        setBannerMessage("An error occurred while fetching the report.");
      }
    }, POLLING_TIMER);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openErrorModal = () => {
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const openBanner = () => {
    setShowNow(true);
  };

  const closeBanner = () => {
    setShowNow(false);
  };
  const handleManageActionClick = (actionType) => {
    switch (actionType) {
      case "EXPORT_REPORT":
        handleExportReportClick();
        setShowNow(false);
        break;
      case "DOWNLOAD_TEMPLATE":
        window.location =
          "https://templates-onboarding.s3.amazonaws.com/prod/Consignments.xlsx";
        break;
      case "UPLOAD_FILE":
        modals.startUploading();
        break;
      default:
        break;
    }
  };

  const handleDateRangeChange = (range) => {
    setDateRange({ startDate: range[0], endDate: range[1] });
  };

  const validateDateRange = () => {
    const { startDate, endDate } = dateRange;

    if (startDate && endDate) {
      const start = dayjs(startDate, "DD-MM-YYYY");
      const end = dayjs(endDate, "DD-MM-YYYY");

      const diffDays = end.diff(start, "day");

      if (diffDays > 90) {
        openErrorModal();
        return false;
      }
    }
    return true;
  };

  const handleExportReportClick = () => {
    if (validateDateRange()) {
      openModal();
    }
  };

  const handleManageActionClickV2 = (actionType) => {
    const selectedActionType =
      actionType && actionTypes
        ? actionTypes.filter((action) => action["value"] === actionType)
        : [];
    switch (actionType) {
      case consignmentActionTypesEnum?.STANDARD:
        addNewConsignment(consignmentActionTypesEnum?.STANDARD);
        setShowBulkUpload(false);
        break;
      case consignmentActionTypesEnum?.DROPSHIP:
        addNewConsignment(consignmentActionTypesEnum?.DROPSHIP);
        setShowBulkUpload(false);
        break;
      case consignmentActionTypesEnum?.EXPORT_REPORT:
        handleExportReportClick();
        setShowNow(false);
        setShowBulkUpload(false);
        break;
      case consignmentActionTypesEnum?.DOWNLOAD_TEMPLATE:
        const CONSIGNMENT_TEMPLATES = getBulkUploadTemplateURL(
          BULK_UPLOAD_FILE_NAMES?.CONSIGNMENT,
        );
        if (CONSIGNMENT_TEMPLATES) {
          window.location = CONSIGNMENT_TEMPLATES;
        }
        break;
      case consignmentActionTypesEnum?.UPLOAD_FILE:
        bulkUploadEntity?.setModuleContentDetails({
          bulkUploadEntityType: BULK_UPLOAD_ENTITY_TYPES?.CONSIGNMENT,
        });
        setSelectedActionType(
          selectedActionType && selectedActionType.length !== 0
            ? selectedActionType[0]
            : null,
        );
        setShowBulkUpload(true);
        break;
      case consignmentActionTypesEnum?.PAST_UPLOADS:
        bulkUploadEntity?.setModuleContentDetails({
          bulkUploadEntityType: BULK_UPLOAD_ENTITY_TYPES?.CONSIGNMENT,
        });
        setSelectedActionType(
          selectedActionType && selectedActionType.length !== 0
            ? selectedActionType[0]
            : null,
        );
        setShowBulkUpload(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="p-5">
      <div className="flex items-center pb-4">
        <div className="flex-1">
          {console.log("STEP 1")}

          {/* {showNow && <YellowBanner message={"Your report is ready for download"} istrue={false} />} */}
          <PageTitle>Consignments</PageTitle>
        </div>

        {writable && !bulkUploadV2Enabled && (
          <div className="flex items-center justify-end space-x-2">
            <Popover
              title={"Actions"}
              showChevron={true}
              panelClassName={
                "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray w-full"
              }
              showOverlay={true}>
              {manageActionTypes.map((actionType, index) => {
                return (
                  <div
                    key={index}
                    className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                    onClick={() => handleManageActionClick(actionType.value)}>
                    {actionType.name}
                  </div>
                );
              })}
            </Popover>
            {/* Add Consignment */}
            <Popover
              title={"Add Consignment"}
              showChevron={true}
              panelClassName={
                "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray w-full"
              }
              showOverlay={true}>
              {consignmentAdditionTypes.map((consignmentType, index) => {
                return (
                  <div
                    className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                    onClick={() => addNewConsignment(consignmentType.value)}>
                    {consignmentType.name}
                  </div>
                );
              })}
            </Popover>

            {/* Bulk upload consignment data */}
            <UploadBulk
              dashboardFields={dashboardFields}
              saveBulkUpload={saveBulkUpload}
              errorMessage={errorMessage}
              successMessage={successMessage}
              validate={validate}
              validationResult={validationResult}
              showAddButton={false}
            />
          </div>
        )}

        {bulkUploadV2Enabled && (
          <div className="mb-4 flex items-center justify-end space-x-2">
            {actionTypes && (
              <Popover
                title={"Manage Actions"}
                showChevron={true}
                disabled={!writable}
                panelClassName="mt-2 bg-bgWhite z-10 
                overflow-auto rounded-lg p-1 
                border border-borderGray w-[14rem]"
                showOverlay={true}>
                {actionTypes.map((action) => {
                  return (
                    <div
                      key={action.name}
                      className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                      onClick={() => handleManageActionClickV2(action.value)}>
                      {action.name}
                    </div>
                  );
                })}
              </Popover>
            )}
          </div>
        )}
      </div>
      {showNow && (
        <ExportBanner
          message={bannerMessage}
          onClose={closeBanner}
          istrue={true}
          setShowYellowBanner={showYellowBanner}
          setShowGreenBanner={showGreenBanner}
          setShowRedBanner={showRedBanner}
          reportUrl={reportUrl}
        />
      )}

      {showBanner && (
        <ExportBanners
          content={
            typeOfBanner === BANNER_TYPE_ENUM?.INITIATED ? (
              <span className="pb-2">
                Upload Scheduled! Your file {bulkuploadFileName} has been
                scheduled for upload. You will be notified when it is ready.
              </span>
            ) : typeOfBanner === BANNER_TYPE_ENUM?.COMPLETED ? (
              <span className="pb-2">
                Upload Successful! Your file {bulkuploadFileName} has been
                uploaded. You can check{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={(e) => {
                    e.preventDefault();
                    handleManageActionClickV2("PAST_UPLOADS");
                  }}>
                  Past Uploads
                </span>{" "}
                for further information.
              </span>
            ) : typeOfBanner === BANNER_TYPE_ENUM?.FAILED ? (
              <span className="pb-2">
                Upload Failed! Your file {bulkuploadFileName} couldn't be
                uploaded. You can check{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={(e) => {
                    e.preventDefault();
                    handleManageActionClickV2("PAST_UPLOADS");
                  }}>
                  Past Uploads
                </span>{" "}
                for further information.
              </span>
            ) : typeOfBanner === BANNER_TYPE_ENUM?.PARTIAL_COMPLETE ? (
              <span className="pb-2">
                Uploaded Partially! Your file {bulkuploadFileName} has been
                uploaded partially. You can check{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={(e) => {
                    e.preventDefault();
                    handleManageActionClickV2("PAST_UPLOADS");
                  }}>
                  Past Uploads
                </span>{" "}
                for further information.
              </span>
            ) : null
          }
          typeOfBanner={typeOfBanner}
          onClose={closeBanners}
        />
      )}
      <ConsignmentsList
        writable={writable}
        consignments={displayConsignments}
        masterData={masterData}
        filters={filters}
        onChangeFilter={onChangeFilter}
        getAudit={getAudit}
        setItems={setItems}
        saveUserTablePreferences={saveUserTablePreferences}
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        customers={customers}
        warehouses={warehouses}
        editConsignment={editConsignment}
        deleteConsignment={deleteConsignment}
        undoConsignmentReceive={undoConsignmentReceive}
        editConsignmentNotes={editConsignmentNotes}
        editWarehouseNotes={editWarehouseNotes}
        subdomain={subdomain}
        downloadConsignmentReport={downloadConsignmentReport}
        receiveConsignment={receiveConsignment}
        user={user}
        changeStatusConsignmentHandle={changeStatusConsignmentHandle}
        receiveConsignmentPartially={receiveConsignmentPartially}
        downloadConsignmentItemList={downloadConsignmentItemList}
        tenant={tenant}
        getDamagedInfo={getDamagedInfo}
        showConsignmentDamageInfo={showConsignmentDamageInfo}
        closeConsignmentDamageInfo={closeConsignmentDamageInfo}
        getConsignmentDetails={getConsignmentDetails}
        groupedConsignments={groupedConsignments}
        groupConsignmentOn={groupConsignmentOn}
        fetchExpandedGroupedConsignments={fetchExpandedGroupedConsignments}
        setGroupConsignmentOn={setGroupConsignmentOn}
        perPage={perPage}
        searchFilters={searchFilters}
        fetchEnhancedSearchResults={fetchEnhancedSearchResults}
        searchConsignmentsLoading={searchConsignmentsLoading}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {showFilters && (
        <ConsignmentFilters
          negativeAction={() => setShowFilters(false)}
          masterData={masterData}
          onChangeFilter={onChangeFilter}
          handleDisplayTextForDateRangePicker={
            handleDisplayTextForDateRangePicker
          }
          handleDateRange={handleDateRangeChange}
          handleDateRangePickerValue={handleDateRangePickerValue}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          warehouses={warehouses}
          clearFilters={clearFilters}
        />
      )}
      {selectedConsignment && (
        <ConsignmentForm
          selectedConsignment={selectedConsignment}
          setSelectedConsignment={setSelectedConsignment}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title={`${selectedConsignment.id ? "Edit" : "Add"} Consignment`}
          saveConsignment={saveConsignment}
          customers={customers}
          warehouses={warehouses}
          removeItem={removeItem}
          submitAddedProduct={submitAddedProduct}
          saveConsignmentDraft={saveConsignmentDraft}
          setDropshipData={setDropshipData}
          dropshipData={dropshipData}
          uploadFile={uploadFile}
          tenant={tenant}
          casePackEnabled={casePackEnabled}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          refreshConsignmentItems={refreshConsignmentItems}
        />
      )}
      {receivingConsignment && !isPartiallyReceived && (
        <ReceivingConsignmentForm
          receivingConsignment={receivingConsignment}
          setReceivingConsignment={setReceivingConsignment}
          onSubmit={submitReceivingConsignment}
          title="Receive Consignment"
          onClose={() => setReceivingConsignment(null)}
          onChangeDropdown={onChangeReceivingConsignment}
          debouncedBinLocationSearch={debouncedBinLocationSearch}
          binLocationsOptions={binLocationsOptions}
        />
      )}
      {receivingConsignment && isPartiallyReceived && (
        <Modal
          title={"Receive Consignment Current State"}
          negativeAction={() => {
            setReceivingConsignment(null);
            setIsPartiallyReceived(false);
          }}
          id="receive-consignment-partially"
          onClose={() => {
            setReceivingConsignment(null);
            setIsPartiallyReceived(false);
          }}
          positiveText="Confirm"
          positiveAction={submitReceiveConsignmentPartially}>
          <div>
            <span className="font-small pb-4 font-montserrat text-lg text-454A4F">
              Are you sure you want to receive this consignment in its current
              state?
            </span>
          </div>
        </Modal>
      )}
      {changeStatusConsignment && (
        <ChangeStatusReasonForm
          changeStatusConsignment={changeStatusConsignment}
          onSubmit={closeConsignment}
          onChange={onChangeStatusConsignment}
          title="Reason"
          onClose={() => {
            setChangeStatusConsignment(null);
          }}
        />
      )}
      {selectedConsignmentNotes && (
        <ConsignmentNotesForm
          selectedConsignmentNotes={selectedConsignmentNotes}
          onChange={onChangeNotes}
          title="Edit Notes"
          onClose={() => setSelectedConsignmentNotes(null)}
          onSubmit={saveConsignmentNotes}
        />
      )}
      {showAudit && (
        <AuditLog
          auditOrderId={auditOrderId}
          selectedAudit={selectedAudit}
          selectedAuditUsersInfo={selectedAuditUsersInfo}
          negativeAction={() => {
            setShowAudit(false);
            setSelectedAudit(null);
            setSelectedAuditUsersInfo(null);
            setAuditOrderId(null);
          }}
        />
      )}
      {selectedConsignmentItemNotes && (
        <Modal
          title={`Add Notes`}
          negativeAction={() => setSelectedConsignmentItemNotes(null)}
          positiveAction={saveConsignmentItemNotes}>
          <div className="space-y-4">
            <div>
              <label className="mb-2 block text-left">
                <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                  Notes
                </span>
              </label>
              <textarea
                id="notes"
                rows="4"
                cols="50"
                onChange={(e) =>
                  setSelectedConsignmentItemNotes({
                    ...selectedConsignmentItemNotes,
                    notes: e.target.value,
                  })
                }
                name="notes"
                value={selectedConsignmentItemNotes.notes}
              />
            </div>
          </div>
        </Modal>
      )}
      {selectedWarehouseNotes && (
        <Modal
          title={`Warehouse Notes`}
          negativeAction={() => setSelectedWarehouseNotes(null)}>
          <div className="p-4 pl-2 font-montserrat text-xl font-bold">
            Notes
          </div>
          <table className="divide-y divide-gray-200 border border-gray-400 px-2">
            <thead className="rounded-full bg-primaryAccent p-4 px-12">
              <tr className="border-left px-12 font-montserrat text-textWhite">
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                  SKU
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                  Notes
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedWarehouseNotes.workingList
                .filter((item) => !!item.warehouseNotes)
                .map((item, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                    <td
                      className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                        index % 2 === 0
                          ? "border-F4C261"
                          : "border-primaryAccent"
                      }`}>
                      {item.sku}
                    </td>

                    <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      {item.warehouseNotes.split(", ").map((i) => (
                        <>
                          <div key={i}>{i}</div>
                        </>
                      ))}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal>
      )}
      {items && items.length > 0 && showConsignmentDetails && (
        <OrderDetailsModal
          items={items}
          setItems={setItems}
          setSelectedConsignmentItemNotes={setSelectedConsignmentItemNotes}
          showConsignmentDetails={showConsignmentDetails}
          closeConsignmentDetails={closeConsignmentDetails}
        />
      )}
      {!groupedConsignments && (
        <Pagination
          showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
          showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
          showingTotal={total}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
        />
      )}
      {isModalOpen && (
        <ConsignmentModal
          show={true}
          setShowNow={setShowNow}
          onClose={closeModal}
          setCustomersSelectedForReport={setCustomersSelectedForReport}
          customersSelectedForReport={customersSelectedForReport}
          filters={filters}
          setShowYellowBanner={setShowYellowBanner}
          setShowGreenBanner={setShowGreenBanner}
          setShowRedBanner={setShowRedBanner}
          setBannerMessage={setBannerMessage}
          setReportUrl={setReportUrl}
          startReportPolling={startReportPolling}
          displayConsignments={displayConsignments}
        />
      )}
      {isErrorModalOpen && (
        <DateRangeErrorModal show={true} onClose={closeErrorModal} />
      )}

      {showBulkUpload && (
        <BulkUploadSlideOverModal
          dashboardFields={consignmentDashboardFieldsV2}
          showBulkUpload={showBulkUpload}
          setShowBulkUpload={setShowBulkUpload}
          selectedActionType={selectedActionType}
          setSelectedActionType={setSelectedActionType}
          pastUploadsList={pastUploadsList}
          bulkUploadDuplicateValidation={BULK_UPLOAD_DUPLICATE_VALIDATION}
          bulkUploadDuplicateRowValidation={
            BULK_UPLOAD_DUPLICATE_ROW_VALIDATION
          }
        />
      )}
    </div>
  );
};

export default withConsignmentsLogic(Consignments);
