import Checkbox from "#components/utils/Checkbox";

const CommonTable = ({
  includeSelectAll,
  children,
  headers,
  selectAllRows,
  allRowsSelected,
}) => {
  return (
    <div className="overflow-y-visible shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300 overflow-y-visible">
        <thead className="bg-gray-50">
          <tr>
            {includeSelectAll && (
              <th
                scope="col"
                className="py-3.5 pl-4 text-left text-lg font-normal text-[#717679] sm:pl-6">
                <Checkbox
                  role="checkbox"
                  onChange={selectAllRows}
                  name="selectAllRows"
                  checked={allRowsSelected}
                />
              </th>
            )}

            {headers.map((header) => (
              <th
                key={header}
                scope="col"
                className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        {children}
      </table>
    </div>
  );
};

export default CommonTable;
