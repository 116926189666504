import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import {
  LocationTree,
  LocationTreeNode,
} from "../../utils/storage-management/location-tree";
import {
  CREATE_STORAGE_SECTION,
  DELETE_STORAGE_SECTION,
  UPDATE_STORAGE_SECTION,
  DELETE_LOCATION,
  BULK_UPDATE_LOCATION,
  RESERVE_LOCATION,
} from "../../mutations";
import {
  SEARCH_LOCATION_TYPES,
  SEARCH_STORAGE_SECTIONS,
  SEARCH_LOCATIONS,
  SEARCH_RESERVATIONS,
} from "../../queries";
import _ from "lodash";
import { LocationCapacityUnitsEnum } from "../../constants/location-capacity";

const withStorageManagementLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const SHORT_ALERT_VISIBILITY_IN_MS = 2000;
    const ALERT_VISIBILITY_IN_MS = 5000;
    const LONG_ALERT_VISIBILITY_IN_MS = 10000;
    const [filters, setFilters] = useState({});
    const [locationTree, setLocationTree] = useState(null);
    const [selectedHierarchy, setSelectedHierarchy] = useState(null);
    const [storageSections, setStorageSections] = useState({}); // StorageSection[
    const searchStorageSectionsQuery = useQuery(SEARCH_STORAGE_SECTIONS);
    const auth = useContext(AuthContext);
    const searchLocationTypesQuery = useQuery(SEARCH_LOCATION_TYPES);
    const createStorageSectionQuery = useQuery(CREATE_STORAGE_SECTION);
    const deleteStorageSectionQuery = useQuery(DELETE_STORAGE_SECTION);
    const updateStorageSectionQuery = useQuery(UPDATE_STORAGE_SECTION);
    const searchLocationsQuery = useQuery(SEARCH_LOCATIONS);
    const deleteLocationQuery = useQuery(DELETE_LOCATION);
    const [storageSectionStatusUpdate, setStorageSectionStatusUpdate] =
      useState(null);
    const [locationDeletions, setLocationDeletions] = useState([]);

    const [
      confirmSubmitCreateStorageSection,
      setConfirmSubmitCreateStorageSection,
    ] = useState(false);
    const [storageSectionToDelete, setStorageSectionToDelete] = useState(null);
    const [storageSectionsSearchString, setStorageSectionsSearchString] =
      useState("");
    const [currentHierarchyArray, setCurrentHierarchyArray] = useState([]);
    const StorageTypes = {
      REGULAR: "Regular",
      AIR_CONDITIONED: "Air Conditioned",
      FREEZER: "Freezer",
      CHILLED: "Chilled",
    };
    const [locationTypes, setLocationTypes] = useState([]); // LocationType[]
    const [selectedLocations, setSelectedLocations] = useState(null);
    const [shouldSubmitEdit, setShouldSubmitEdit] = useState(false);
    const batchUpdateLocationsQuery = useQuery(BULK_UPDATE_LOCATION);
    const reserveLocationQuery = useQuery(RESERVE_LOCATION);
    const searchReservationsQuery = useQuery(SEARCH_RESERVATIONS);
    const [locationReservations, setLocationReservations] = useState([]);

    const showAlert = (message, type, duration) => {
      appState.setAlert(message, type, duration);
    };

    const buildLocationTreeIteratively = (
      topLevelLocations,
      locationTree,
      retainBackendId,
      allLocationsInStorageSection,
      locationTypes,
    ) => {
      // Build a map for parent ID to children locations
      const locationMap = new Map();
      for (const location of allLocationsInStorageSection) {
        const parentId = location.parentLocation || "root";
        if (!locationMap.has(parentId)) {
          locationMap.set(parentId, []);
        }
        locationMap.get(parentId).push(location);
      }

      // Initialize a queue with top-level locations (children of root)
      const queue = topLevelLocations.map((location) => ({
        location,
        parentNode: locationTree.root, // Initially, parent is root
      }));

      // Process the queue iteratively
      while (queue.length > 0) {
        const { location, parentNode } = queue.shift();

        // Create a new LocationTreeNode
        const locationNode = new LocationTreeNode(
          location.name,
          location.code,
          location.capacity,
          location.type,
          parentNode,
          location.activationStatus,
          location.hierarchicalPath,
          location.reservations,
          location.reservationsDetail,
        );

        if (retainBackendId) {
          locationNode.backendId = location.id;
        }

        locationNode.inactiveReason = location.inactiveReason;

        try {
          parentNode.addChildren([locationNode], locationTypes);
        } catch (e) {
          console.error(e);
          throw new Error(e.message);
        }

        // Enqueue child locations of the current location
        const childrenLocations = locationMap.get(location.id) || [];
        for (const childLocation of childrenLocations) {
          queue.push({
            location: childLocation,
            parentNode: locationNode, // The current node is now the parent of its children
          });
        }
      }
    };

    const buildLocationTreeFromStorageSection = async (
      storageSection,
      retainBackendId,
    ) => {
      const locationTree = new LocationTree();
      const searchLocationsResponse = await searchLocationsQuery.fetchData({
        storageSection: storageSection.id,
      });
      if (searchLocationsResponse.error) {
        showAlert(
          searchLocationsResponse.error.message ||
            "Error fetching locations. Please try again",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        throw new Error(
          searchLocationsResponse.error.message ||
            "Error fetching locations. Please try again",
        );
      } else if (searchLocationsResponse.data?.searchLocations?.error) {
        showAlert(
          searchLocationsResponse.data.searchLocations.error,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        throw new Error(searchLocationsResponse.data.searchLocations.error);
      } else if (searchLocationsResponse.data?.searchLocations?.data) {
        const allLocationsInStorageSection =
          searchLocationsResponse.data.searchLocations.data.locations;
        const topLevelLocations = allLocationsInStorageSection.filter(
          (location) => location.topLevelLocation,
        );
        buildLocationTreeIteratively(
          topLevelLocations,
          locationTree,
          retainBackendId,
          allLocationsInStorageSection,
          locationTypes,
        );
        setLocationTree(locationTree);
      }
    };

    useEffect(() => {
      if (deleteStorageSectionQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteStorageSectionQuery.error) {
        showAlert(
          deleteStorageSectionQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (deleteStorageSectionQuery.data?.deleteStorageSection?.error) {
        showAlert(
          deleteStorageSectionQuery.data.deleteStorageSection.error,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      } else if (deleteStorageSectionQuery.data?.deleteStorageSection?.data) {
        showAlert(
          "Storage section deleted successfully",
          "success",
          SHORT_ALERT_VISIBILITY_IN_MS,
        );
        searchStorageSectionsQuery.fetchData({});
        setStorageSectionsSearchString("");
        setStorageSectionToDelete(null);
      }
    }, [
      deleteStorageSectionQuery.data,
      deleteStorageSectionQuery.error,
      deleteStorageSectionQuery.loading,
    ]);

    const deleteStorageSection = (storageSection) => {
      deleteStorageSectionQuery.fetchData({
        deleteStorageSectionId: storageSection.id,
      });
    };

    useEffect(() => {
      searchLocationTypesQuery.fetchData();
    }, []);

    useEffect(() => {
      if (searchReservationsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (
        searchReservationsQuery.error ||
        searchReservationsQuery.data?.searchReservations?.error
      ) {
        showAlert(
          searchReservationsQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (searchReservationsQuery.data) {
        const reservations =
          searchReservationsQuery.data.searchReservations?.data?.reservations;
        if (reservations) {
          setLocationReservations(reservations);
        }
      }
    }, [
      searchReservationsQuery.data,
      searchReservationsQuery.loading,
      searchReservationsQuery.error,
    ]);

    useEffect(() => {
      if (searchLocationTypesQuery.data) {
        setLocationTypes(
          searchLocationTypesQuery.data.searchLocationTypes?.data,
        );
      }
      if (searchLocationTypesQuery.error) {
        showAlert(
          "Error fetching location types. Please try again",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      searchLocationTypesQuery.data,
      searchLocationTypesQuery.error,
      searchLocationTypesQuery.loading,
    ]);

    useEffect(() => {
      if (reserveLocationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (reserveLocationQuery.error) {
        showAlert(
          reserveLocationQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      if (reserveLocationQuery.data?.reserveLocation?.error) {
        showAlert(
          reserveLocationQuery.data.reserveLocation.error,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      } else if (reserveLocationQuery.data?.reserveLocation?.message) {
        submitFilters();
        showAlert(
          reserveLocationQuery.data.reserveLocation.message,
          "success",
          SHORT_ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      reserveLocationQuery.data,
      reserveLocationQuery.error,
      reserveLocationQuery.loading,
    ]);

    useEffect(() => {
      if (auth?.user) {
        searchStorageSectionsQuery.fetchData({});
      }
    }, []);

    useEffect(() => {
      if (shouldSubmitEdit) {
        submitEditStorageSection();
        setShouldSubmitEdit(false);
      }
    }, [selectedLocations, shouldSubmitEdit]);

    useEffect(() => {
      if (searchStorageSectionsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (searchStorageSectionsQuery.data) {
        const flatStorageSections =
          searchStorageSectionsQuery.data.searchStorageSections?.data
            ?.storageSections;

        if (!flatStorageSections) {
          showAlert(
            searchStorageSectionsQuery.data.searchStorageSections?.error ||
              "Error fetching storage sections. Please try again",
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
          return;
        }

        const newPerWarehouseStorageSections = { ...storageSections };

        for (const warehouse of auth.user.warehousesList) {
          if (!newPerWarehouseStorageSections[warehouse.id]) {
            newPerWarehouseStorageSections[warehouse.id] = {
              storageSections: [],
              collapsed: true,
            };
          }
        }

        const flatStorageSectionsMap = new Map(
          flatStorageSections.map((section) => [section.id, section]),
        );

        for (const warehouseId in newPerWarehouseStorageSections) {
          const existingSections =
            newPerWarehouseStorageSections[warehouseId].storageSections;

          const updatedSections = existingSections.filter((section) =>
            flatStorageSectionsMap.has(section.id),
          );

          for (const storageSection of flatStorageSections) {
            if (storageSection.warehouse === warehouseId) {
              const existingSectionIndex = updatedSections.findIndex(
                (section) => section.id === storageSection.id,
              );

              if (existingSectionIndex !== -1) {
                updatedSections[existingSectionIndex] = storageSection;
              } else {
                updatedSections.push(storageSection);
              }
            }
          }

          newPerWarehouseStorageSections[warehouseId].storageSections =
            updatedSections;
        }

        setStorageSections(newPerWarehouseStorageSections);
      } else if (searchStorageSectionsQuery.error) {
        showAlert(
          "Error fetching storage sections. Please try again",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      searchStorageSectionsQuery.data,
      searchStorageSectionsQuery.error,
      searchStorageSectionsQuery.loading,
    ]);

    const setLoading = (loading) => {
      if (loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    };

    const INITIAL_STORAGE_SECTION_STATE = {
      name: "",
      code: "",
      type: Object.values(StorageTypes)[0],
    };
    const [selectedStorageSection, setSelectedStorageSection] = useState(null);
    const onChangeSearchKeyword = (e) => {
      filters["keyword"] = e.target.value;
      setFilters({ ...filters });
    };

    const clearKeyword = () => {
      filters["keyword"] = "";
      setFilters({ ...filters });
    };

    const deleteLocation = (location) => {
      const parent = location.parent;
      const newChildren = parent.children.filter(
        (child) => child.id !== location.id,
      );
      parent.children = newChildren;
      showAlert(
        "Location deleted successfully",
        "success",
        SHORT_ALERT_VISIBILITY_IN_MS,
      );
    };

    useEffect(() => {
      const processStorageSection = async () => {
        if (createStorageSectionQuery.loading) {
          appState.setLoading();
        }
        if (createStorageSectionQuery.error) {
          showAlert(
            createStorageSectionQuery.error.message,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
          appState.removeLoading();
          return;
        }
        if (createStorageSectionQuery.data) {
          if (createStorageSectionQuery.data.createStorageSection?.error) {
            showAlert(
              createStorageSectionQuery.data.createStorageSection?.error,
              "error",
              ALERT_VISIBILITY_IN_MS,
            );
          } else {
            const createdStorageSection =
              createStorageSectionQuery.data.createStorageSection?.data;
            if (createdStorageSection) {
              try {
                showAlert(
                  `Storage section ${createdStorageSection.name} created successfully`,
                  "success",
                  ALERT_VISIBILITY_IN_MS,
                );
                setConfirmSubmitCreateStorageSection(false);
                setSelectedStorageSection(null);
                setStorageSectionsSearchString("");
                await searchStorageSectionsQuery.fetchData({});
                setLocationTree(null);
              } catch (error) {
                showAlert(error.message, "error", ALERT_VISIBILITY_IN_MS);
              }
            } else {
              showAlert(
                createStorageSectionQuery.data.createStorageSection?.error ||
                  "Error creating storage section. Please try again",
                "error",
                ALERT_VISIBILITY_IN_MS,
              );
            }
          }
          appState.removeLoading();
        }
      };

      processStorageSection();
    }, [
      createStorageSectionQuery.loading,
      createStorageSectionQuery.data,
      createStorageSectionQuery.error,
    ]);

    useEffect(() => {
      if (updateStorageSectionQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (updateStorageSectionQuery.error) {
        showAlert(
          updateStorageSectionQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      } else if (updateStorageSectionQuery.data?.updateStorageSection?.data) {
        setStorageSectionStatusUpdate(null);
        submitFilters();
      } else if (updateStorageSectionQuery.data?.updateStorageSection?.error) {
        showAlert(
          updateStorageSectionQuery.data.updateStorageSection.error,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      updateStorageSectionQuery.data,
      updateStorageSectionQuery.error,
      updateStorageSectionQuery.loading,
    ]);

    const submitStorageSectionStatusUpdate = () => {
      if (!storageSectionStatusUpdate) {
        showAlert(
          "Error: Please contact support. Error Code: 2",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      let sectionUpdates = {
        updateStorageSectionId: storageSectionStatusUpdate.id,
        status: storageSectionStatusUpdate.status,
      };
      if (storageSectionStatusUpdate.status === "unavailable") {
        if (!storageSectionStatusUpdate.sectionInactiveReason) {
          showAlert(
            "Please select a reason for making the storage section unavailable",
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
          return;
        }
        sectionUpdates.sectionInactiveReason =
          storageSectionStatusUpdate.sectionInactiveReason;
      }
      updateStorageSectionQuery.fetchData(sectionUpdates);
    };

    const submitCreateStorageSection = () => {
      const locationsToCreate = locationTree.dfsTraverse((locationNode) => {
        return {
          id: locationNode.id,
          name: locationNode.name,
          code: locationNode.code,
          capacity: locationNode.capacity,
          type: locationNode.type,
          parent: locationNode.parent ? locationNode.parent.id : null,
          hierarchicalPath: locationNode.hierarchicalPath,
        };
      });

      // Check for duplicate codes
      const duplicateCodes = _.chain(locationsToCreate)
        .groupBy("code") // Group locations by the 'code' property
        .filter((group) => group.length > 1) // Filter groups that have more than one location (duplicates)
        .map((group) => group[0].code) // Get the 'code' of the duplicate locations
        .value();

      if (duplicateCodes.length > 0) {
        showAlert(`Duplicate codes found: ${duplicateCodes}`);
        return;
      }
      createStorageSectionQuery.fetchData({
        code: selectedStorageSection.code,
        name: selectedStorageSection.name,
        warehouse: selectedStorageSection.warehouse,
        hierarchy: locationTree.getHierarchyArray(),
        storageType: selectedStorageSection.type,
        status: "available",
        locations: locationsToCreate,
      });
    };

    const submitEditStorageSection = async () => {
      appState.setLoading();

      try {
        // Update storage section
        const updateStorageSectionResponse =
          await updateStorageSectionQuery.fetchData({
            updateStorageSectionId: selectedStorageSection.id,
            name: selectedStorageSection.name,
            storageType: selectedStorageSection.type,
          });

        if (
          updateStorageSectionResponse.error ||
          updateStorageSectionResponse.data?.updateStorageSection?.error
        ) {
          throw new Error(
            updateStorageSectionResponse.error?.message ||
              updateStorageSectionResponse.data?.updateStorageSection?.error,
          );
        }

        if (selectedLocations && selectedLocations.length > 0) {
          const locationUpdates = [];
          const locationCreates = [];

          const duplicateCodes = _.chain(selectedLocations)
            .groupBy("code") // Group locations by the 'code' property
            .filter((group) => group.length > 1) // Filter groups that have more than one location (duplicates)
            .map((group) => group[0].code) // Get the 'code' of the duplicate locations
            .value();

          if (duplicateCodes.length > 0) {
            throw new Error(`Duplicate codes found: ${duplicateCodes}`);
          }
          selectedLocations.forEach((location) => {
            if (location.backendId) {
              const updateLocationVariables = {
                id: location.backendId,
                code: location.code,
                name: location.name,
                warehouse: selectedStorageSection.warehouse,
                capacity: location.capacity,
                activationStatus: location.activationStatus,
                inactiveReason: location.inactiveReason,
                parentLocation: location.parent?.backendId || undefined,
              };
              locationUpdates.push(updateLocationVariables);
            } else if (
              !location.backendId &&
              location.code != "root" &&
              location.name !== "root"
            ) {
              const createLocationVariables = {
                code: location.code,
                hierarchicalPath: location.hierarchicalPath,
                name: location.name,
                type: location.type,
                warehouse: selectedStorageSection.warehouse,
                storageType: selectedStorageSection.type,
                capacity: location.capacity,
                storageSection:
                  location.depth === 1 ? selectedStorageSection.id : undefined,
                topLevelLocation: location.depth === 1,
                parentLocation: location.parent?.backendId || undefined,
              };
              locationCreates.push(createLocationVariables);
            }
          });

          const locationPayload = [...locationUpdates, ...locationCreates];
          const batchUpdateResponse = await batchUpdateLocationsQuery.fetchData(
            {
              locations: locationPayload,
            },
          );

          if (
            batchUpdateResponse.error ||
            batchUpdateResponse.data?.bulkUpdateLocations?.error
          ) {
            throw new Error(
              batchUpdateResponse.error?.message ||
                batchUpdateResponse.data?.bulkUpdateLocations?.error,
            );
          }
        }

        // Process deletions
        for (const deletedLocation of locationDeletions) {
          try {
            const deleteLocationResponse = await deleteLocationQuery.fetchData({
              locationId: deletedLocation.backendId,
            });
            const error =
              deleteLocationResponse?.error?.message ||
              deleteLocationResponse?.data?.deleteLocation?.error;
            if (error) {
              throw new Error(
                `Cannot delete location ${deletedLocation?.code}: ${error}`,
              );
            }
          } catch (e) {
            console.error(e);
            showAlert(e.message, "error", ALERT_VISIBILITY_IN_MS);
            return;
          } finally {
            setLocationDeletions([]);
          }
        }

        // Show success alert after all promises are resolved successfully
        showAlert(
          "Storage section updated successfully",
          "success",
          SHORT_ALERT_VISIBILITY_IN_MS,
        );

        if (shouldSubmitEdit === true) {
          await buildLocationTreeFromStorageSection(
            selectedStorageSection,
            true,
          );
        } else {
          setSelectedStorageSection(null);
          setLocationTree(null);
        }
        setLocationDeletions([]);
        setConfirmSubmitCreateStorageSection(false);
        setStorageSectionsSearchString("");
        await searchStorageSectionsQuery.fetchData({});
        setSelectedLocations(null);
      } catch (error) {
        console.error(error);
        showAlert(error.message, "error", ALERT_VISIBILITY_IN_MS);
      } finally {
        appState.removeLoading();
      }
    };

    const submitStorageSectionStatusEdit = (
      id,
      status,
      inactiveReason,
      locationsUpdate,
    ) => {
      if (!id || !status) {
        showAlert(
          "Error: Please contact support. Error Code: 2",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      let sectionUpdates = {
        updateStorageSectionId: id,
        status: status,
        blockLocationsUpdate: locationsUpdate,
      };
      if (status === "unavailable") {
        sectionUpdates.sectionInactiveReason = inactiveReason;
      }

      updateStorageSectionQuery.fetchData(sectionUpdates);
    };

    const submitSaveHierarchy = async () => {
      if (selectedHierarchy.locations.length === 0) {
        showAlert("Range cannot be 0", "error", SHORT_ALERT_VISIBILITY_IN_MS);
        return;
      }
      const targetParents = [];

      if (selectedHierarchy.applyToSiblings) {
        const grandParent = selectedHierarchy.parent.parent;
        if (!grandParent) {
          showAlert(
            "Error: Please contact support. Error Code: 1",
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
          return;
        } else {
          for (const pibling of grandParent.children) {
            targetParents.push(pibling);
          }
        }
      } else {
        targetParents.push(selectedHierarchy.parent);
      }

      const locationPayload = [];

      for (const parent of targetParents) {
        let prefix = parent.hierarchicalPath;
        if (prefix === "root") {
          prefix = selectedStorageSection.code;
        }
        const locationType = locationTypes.find(
          (t) => t.id === selectedHierarchy.subType,
        );

        // Validate existing children types against new locations
        if (parent.children?.length > 0) {
          const existingChildrenType = parent.children[0].type;
          if (existingChildrenType !== locationType.id) {
            showAlert(
              `All children of ${parent.code} must be of type ${
                locationTypes?.find((t) => t.id === existingChildrenType)?.name
              }`,
              "error",
              ALERT_VISIBILITY_IN_MS,
            );
            return;
          }
        }
        if (
          !selectedHierarchy.edit ||
          selectedHierarchy.subType !== selectedHierarchy.originalSubType ||
          locationType?.threeDimRange
        ) {
          if (
            locationType?.threeDimRange &&
            selectedHierarchy.locations.length === parent.children.length
          ) {
            // three dimensional hierarchy but the number of locations haven't changed
            // update existing locations
            for (const location of selectedHierarchy.locations) {
              for (const child of parent.children) {
                const childCodeParts = child.hierarchicalPath.split("-");
                const childRelativeCode =
                  childCodeParts[childCodeParts.length - 1];
                if (childRelativeCode === location.hierarchicalPath) {
                  child.name = location.name;
                  child.capacity = location.capacity;
                  const updateLocationVariables = {
                    id: child.backendId,
                    code: child.code,
                    name: child.name,
                    warehouse: selectedStorageSection.warehouse,
                    capacity: child.capacity,
                    activationStatus: child.activationStatus,
                  };
                  locationPayload.push(updateLocationVariables);
                  break;
                }
              }
            }
            showAlert(
              "Locations updated successfully",
              "success",
              SHORT_ALERT_VISIBILITY_IN_MS,
            );
            setSelectedHierarchy(null);
            return;
          }
          const newNodes = [];
          for (const location of selectedHierarchy.locations) {
            const hierarchicalPath = prefix + "-" + location.hierarchicalPath;
            const code =
              location.hierarchicalPath == location.code
                ? hierarchicalPath
                : location.code;
            const node = new LocationTreeNode(
              location.name,
              code,
              location.capacity,
              selectedHierarchy.subType,
              parent,
              location.activationStatus,
              hierarchicalPath,
              location.reservations,
              location.reservationsDetail,
            );
            newNodes.push(node);

            const createLocationVariables = {
              code: node.code,
              hierarchicalPath: node.hierarchicalPath,
              name: node.name,
              type: node.type,
              warehouse: selectedStorageSection.warehouse,
              storageType: selectedStorageSection.type,
              capacity: node.capacity,
              storageSection:
                node.depth === 1 ? selectedStorageSection.id : undefined,
              topLevelLocation: node.depth === 1,
              parentLocation:
                node.depth > 1 ? node.parent?.backendId : undefined,
              activationStatus: node.activationStatus,
            };
            locationPayload.push(createLocationVariables);
          }
          let alertMessage = null;
          try {
            parent.setChildren(newNodes, locationTypes);
          } catch (e) {
            alertMessage = e.message;
          }
          if (alertMessage) {
            showAlert(alertMessage, "error", LONG_ALERT_VISIBILITY_IN_MS);
            return;
          } else {
            showAlert(
              "Locations added successfully",
              "success",
              SHORT_ALERT_VISIBILITY_IN_MS,
            );
          }
        } else {
          const countOfExistingChildren = parent.children.length;
          // update existing children
          for (let i = 0; i < countOfExistingChildren; i++) {
            const location = selectedHierarchy.locations[i];
            const node = parent.children[i];
            node.name = location.name;
            node.capacity = location.capacity;
            node.code = location.code;
            const updateLocationVariables = {
              id: node.backendId,
              code: node.code,
              name: node.name,
              warehouse: selectedStorageSection.warehouse,
              capacity: node.capacity,
              activationStatus: node.activationStatus,
            };
            locationPayload.push(updateLocationVariables);
          }

          // add new children
          if (countOfExistingChildren < selectedHierarchy.locations.length) {
            const newChildren = [];
            for (
              let i = countOfExistingChildren;
              i < selectedHierarchy.locations.length;
              i++
            ) {
              const location = selectedHierarchy.locations[i];
              const hierarchicalPath = prefix + "-" + location.hierarchicalPath;
              const code =
                location.hierarchicalPath == location.code
                  ? hierarchicalPath
                  : location.code;
              const node = new LocationTreeNode(
                location.name,
                code,
                location.capacity,
                selectedHierarchy.subType,
                parent,
                location.activationStatus,
                hierarchicalPath,
                location.reservations,
                location.reservationsDetail,
              );

              newChildren.push(node);

              const createLocationVariables = {
                code: node.code,
                hierarchicalPath: node.hierarchicalPath,
                name: node.name,
                type: node.type,
                warehouse: selectedStorageSection.warehouse,
                storageType: selectedStorageSection.type,
                capacity: node.capacity,
                storageSection:
                  node.depth === 1 ? selectedStorageSection.id : undefined,
                topLevelLocation: node.depth === 1,
                parentLocation:
                  node.depth > 1 ? node.parent?.backendId : undefined,
                activationStatus: node.activationStatus,
              };
              locationPayload.push(createLocationVariables);
            }
            let alertMessage = null;
            try {
              parent.addChildren(newChildren, locationTypes);
            } catch (e) {
              alertMessage = e.message;
            }
            if (alertMessage) {
              showAlert(alertMessage, "error", LONG_ALERT_VISIBILITY_IN_MS);
              return;
            } else {
              showAlert(
                "Locations updated successfully",
                "success",
                SHORT_ALERT_VISIBILITY_IN_MS,
              );
            }
          } else {
            showAlert(
              "Locations updated successfully",
              "success",
              SHORT_ALERT_VISIBILITY_IN_MS,
            );
          }
        }
      }

      if (selectedStorageSection?.edit) {
        try {
          const duplicateCodes = _.chain(locationPayload)
            .groupBy("code") // Group locations by the 'code' property
            .filter((group) => group.length > 1) // Filter groups that have more than one location (duplicates)
            .map((group) => group[0].code) // Get the 'code' of the duplicate locations
            .value();

          if (duplicateCodes.length > 0) {
            throw new Error(`Duplicate codes found: ${duplicateCodes}`);
          }
          const batchUpdateResponse = await batchUpdateLocationsQuery.fetchData(
            {
              locations: locationPayload,
            },
          );
          if (
            batchUpdateResponse.error ||
            batchUpdateResponse.data?.bulkUpdateLocations?.error
          ) {
            throw new Error(
              batchUpdateResponse.error?.message ||
                batchUpdateResponse.data?.bulkUpdateLocations?.error,
            );
          }
          await buildLocationTreeFromStorageSection(
            selectedStorageSection,
            true,
          );
          showAlert(
            "Locations updated successfully",
            "success",
            SHORT_ALERT_VISIBILITY_IN_MS,
          );
        } catch (error) {
          console.error(error);
          showAlert(error.message, "error", ALERT_VISIBILITY_IN_MS);
        }
      }
      setSelectedHierarchy(null);
    };

    const getCurrentHierarchyArray = (hierarchy = null) => {
      if (hierarchy) {
        return hierarchy.map(
          (type) => locationTypes.find((t) => t.id === type).name,
        );
      }
      if (locationTree) {
        return locationTree
          .getHierarchyArray()
          .map((type) => locationTypes.find((t) => t.id === type).name);
      }
      return [];
    };

    const submitFilters = () => {
      searchStorageSectionsQuery.fetchData({
        name: storageSectionsSearchString,
      });
    };
    const onChangeHierarchy = (e) => {
      let changes = {
        [e.target.name]: e.target.value,
      };
      if (e.target.type === "checkbox") {
        changes[e.target.name] = !selectedHierarchy[e.target.name];
      }
      if (e.target.name === "type") {
        const subType = locationTypes.find(
          (type) => type.category === e.target.value,
        );
        changes["subType"] = subType.id;
      }
      if (e.target.name === "subType") {
        changes[e.target.name] = e.target.value;
      }

      if (["type", "subType"].includes(e.target.name)) {
        changes["locations"] = [];
        changes["rangeX"] = 0;
        changes["rangeY"] = 0;
        changes["rangeZ"] = 0;
      }

      if (
        e.target.name === "rangeX" ||
        e.target.name === "rangeY" ||
        e.target.name === "rangeZ"
      ) {
        const locationType = locationTypes.find(
          (type) => type.id === selectedHierarchy.subType,
        );
        if (
          selectedHierarchy.edit &&
          !locationType?.threeDimRange &&
          selectedHierarchy.subType === selectedHierarchy.originalSubType
        ) {
          const newValue = parseInt(e.target.value);
          const currentNumberOfChildren =
            selectedHierarchy.parent.children.length;
          if (newValue < currentNumberOfChildren) {
            showAlert(
              "Cannot reduce range to less than current number of locations",
              "error",
              SHORT_ALERT_VISIBILITY_IN_MS,
            );
            return;
          } else if (
            newValue > currentNumberOfChildren &&
            selectedHierarchy.parent.code === "root"
          ) {
            showAlert(
              "Cannot increase range for the top level hierarchy",
              "error",
              SHORT_ALERT_VISIBILITY_IN_MS,
            );
            return;
          } else if (newValue > currentNumberOfChildren) {
            const currentLocations = selectedHierarchy.locations.slice(
              0,
              currentNumberOfChildren,
            );
            const countOfNewLocations = newValue - currentNumberOfChildren;
            const newLocations = [];
            const lastLocation = currentLocations[currentLocations.length - 1];
            const lastLocationCode =
              lastLocation.hierarchicalPath.split(".")[1];
            const lastLocationNumber = convertCodeToNumber(
              lastLocationCode,
              locationType.codeType,
            );
            const prefix = locationType.code;
            for (let i = 1; i <= countOfNewLocations; i++) {
              const code =
                prefix +
                "." +
                convertNumberToCode(
                  i + lastLocationNumber,
                  locationType.codeType,
                );
              newLocations.push({
                code,
                hierarchicalPath: code,
                capacity: {
                  amount: 0,
                  unit: LocationCapacityUnitsEnum.CBM,
                },
                name: locationType.name,
              });
            }
            changes["locations"] = currentLocations.concat(newLocations);
          } else {
            const currentLocations = selectedHierarchy.locations.slice(
              0,
              currentNumberOfChildren,
            );
            changes["locations"] = currentLocations;
          }
        } else {
          const locations = [];
          const range = {
            rangeX: selectedHierarchy.rangeX,
            rangeY: selectedHierarchy.rangeY,
            rangeZ: selectedHierarchy.rangeZ,
          };
          range[e.target.name] = parseInt(e.target.value);
          const prefix = locationType.code;
          for (let i = 1; i <= range.rangeX; i++) {
            const xAxisCode =
              prefix + "." + convertNumberToCode(i, locationType.codeType);
            if (range.rangeY) {
              for (let j = 1; j <= range.rangeY; j++) {
                const yAxisCode =
                  xAxisCode +
                  "." +
                  convertNumberToCode(j, locationType.codeType);
                if (range.rangeZ) {
                  for (let k = 1; k <= range.rangeZ; k++) {
                    const zAxisCode =
                      yAxisCode +
                      "." +
                      convertNumberToCode(k, locationType.codeType);
                    locations.push({
                      code: zAxisCode,
                      hierarchicalPath: zAxisCode,
                      capacity: {
                        amount: 0,
                        unit: LocationCapacityUnitsEnum.CBM,
                      },
                      name: locationType.name,
                    });
                  }
                } else {
                  locations.push({
                    code: yAxisCode,
                    hierarchicalPath: yAxisCode,
                    capacity: {
                      amount: 0,
                      unit: LocationCapacityUnitsEnum.CBM,
                    },
                    name: locationType.name,
                  });
                }
              }
            } else {
              locations.push({
                code: xAxisCode,
                hierarchicalPath: xAxisCode,
                capacity: {
                  amount: 0,
                  unit: LocationCapacityUnitsEnum.CBM,
                },
                name: locationType.name,
              });
            }
          }

          changes["locations"] = locations;
        }
        changes[e.target.name] = parseInt(e.target.value);
      }

      setSelectedHierarchy({
        ...selectedHierarchy,
        ...changes,
      });
    };

    const onChangeHierarchyLocation = (e, index) => {
      let locations = [...selectedHierarchy.locations];
      if (e.target.name === "code") {
        locations[index]["code"] = e.target.value;
      }
      if (e.target.name === "capacity-amount") {
        locations[index]["capacity"]["amount"] = parseFloat(e.target.value);
      } else if (e.target.name === "capacity-unit") {
        locations[index]["capacity"]["unit"] = e.target.value;
      } else {
        locations[index][e.target.name] =
          e.target.type === "number"
            ? parseFloat(e.target.value)
            : e.target.value;
      }
      setSelectedHierarchy({
        ...selectedHierarchy,
        locations,
      });
    };

    const reserveLocation = async (locationId, reservationsData, parentId) => {
      const reservations = reservationsData.map((reservation) => {
        return {
          locationId: reservation.locationId,
          locationCode: reservation.locationCode,
          strategy: reservation.strategy,
          customers:
            reservation.customers?.length > 0
              ? reservation.customers
              : undefined,
          products:
            reservation.products?.length > 0 ? reservation.products : undefined,
        };
      });

      await reserveLocationQuery.fetchData({
        locationId,
        parentLocation: parentId,
        reservations,
      });
      await buildLocationTreeFromStorageSection(selectedStorageSection, true);
    };

    const searchReservations = async (locationId) => {
      await searchReservationsQuery.fetchData({
        locationId,
      });
    };

    const onChangeStorageSection = (e) => {
      if (e.target.name === "code" && !locationTree.isEmpty()) {
        locationTree.dfsTraverse((location) => {
          const hierarchyParts = location.hierarchicalPath.split("-");
          hierarchyParts[0] = e.target.value;
          if (location.code === location.hierarchicalPath) {
            location.code = hierarchyParts.join("-");
          }
          location.hierarchicalPath = hierarchyParts.join("-");
        });
      }
      setSelectedStorageSection({
        ...selectedStorageSection,
        [e.target.name]: e.target.value,
      });
    };

    const addHierarchyToStorageSection = () => {};

    return (
      <WrappedComponent
        warehouses={auth?.user?.warehousesList || []}
        filters={filters}
        onChangeSearchKeyword={onChangeSearchKeyword}
        clearKeyword={clearKeyword}
        submitFilters={submitFilters}
        selectedStorageSection={selectedStorageSection}
        setSelectedStorageSection={setSelectedStorageSection}
        onChangeStorageSection={onChangeStorageSection}
        storageTypes={StorageTypes}
        INITIAL_STORAGE_SECTION_STATE={INITIAL_STORAGE_SECTION_STATE}
        locationTree={locationTree}
        setLocationTree={setLocationTree}
        selectedHierarchy={selectedHierarchy}
        setSelectedHierarchy={setSelectedHierarchy}
        locationTypes={locationTypes}
        onChangeHierarchy={onChangeHierarchy}
        onChangeHierarchyLocation={onChangeHierarchyLocation}
        submitSaveHierarchy={submitSaveHierarchy}
        getCurrentHierarchyArray={getCurrentHierarchyArray}
        deleteLocation={deleteLocation}
        showAlert={showAlert}
        storageSections={storageSections}
        setStorageSections={setStorageSections}
        storageSectionsSearchString={storageSectionsSearchString}
        setStorageSectionsSearchString={setStorageSectionsSearchString}
        submitCreateStorageSection={submitCreateStorageSection}
        confirmSubmitCreateStorageSection={confirmSubmitCreateStorageSection}
        setConfirmSubmitCreateStorageSection={
          setConfirmSubmitCreateStorageSection
        }
        deleteStorageSection={deleteStorageSection}
        storageSectionStatusUpdate={storageSectionStatusUpdate}
        setStorageSectionStatusUpdate={setStorageSectionStatusUpdate}
        submitStorageSectionStatusUpdate={submitStorageSectionStatusUpdate}
        setLoading={setLoading}
        buildLocationTreeFromStorageSection={
          buildLocationTreeFromStorageSection
        }
        locationDeletions={locationDeletions}
        setLocationDeletions={setLocationDeletions}
        submitEditStorageSection={submitEditStorageSection}
        storageSectionToDelete={storageSectionToDelete}
        setStorageSectionToDelete={setStorageSectionToDelete}
        currentHierarchyArray={currentHierarchyArray}
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        shouldSubmitEdit={shouldSubmitEdit}
        setShouldSubmitEdit={setShouldSubmitEdit}
        submitStorageSectionStatusEdit={submitStorageSectionStatusEdit}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        reserveLocation={reserveLocation}
        locationReservations={locationReservations}
        searchReservations={searchReservations}
        {...props}
      />
    );
  };
};

export default withStorageManagementLogic;

const convertNumberToCode = (number, codeType) => {
  if (codeType === CODE_TYPES.NUMERIC) {
    return number.toString();
  } else if (CODE_TYPES.ALPHA) {
    let result = "";
    while (number > 0) {
      let remainder = (number - 1) % 26;
      result = String.fromCharCode("A".charCodeAt(0) + remainder) + result;
      number = Math.floor((number - 1) / 26);
    }
    return result;
  }
};
const convertCodeToNumber = (code, codeType) => {
  if (codeType === CODE_TYPES.NUMERIC) {
    return parseInt(code);
  } else if (CODE_TYPES.ALPHA) {
    let result = 0;
    for (let i = 0; i < code.length; i++) {
      result *= 26;
      result += code.charCodeAt(i) - "A".charCodeAt(0) + 1;
    }
    return result;
  }
};

const CODE_TYPES = {
  ALPHA: "ALPHA",
  NUMERIC: "NUMERIC",
};
