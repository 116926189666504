import React from "react";
import _ from "lodash";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import JsBarcode from "jsbarcode";

// NOTE: WE HAVE CHANGED FROM CANVAS LABEL PRINTING TO GENERATING THE BARCODE USING JSBARCODE AND THEN USING JSPDF TO GENERATE THE PDF
export const SUPPORTED_SIZES_WITHOUT_CANVAS = [
  "2x1",
  "3x1",
  "2.25x1.25",
  "2.25x1.57",
];

export const printCanvasLabelPdf = async (
  code,
  noOfCopies = 1,
  message = "Printing label",
  printer = null,
  dimensions = "2x1",
  appState = null,
  print = true,
  text1,
  text2,
) => {
  if (!code) {
    return appState && appState.setAlert("Could not print", "error", 5000);
  }

  if (SUPPORTED_SIZES_WITHOUT_CANVAS.includes(dimensions)) {
    // Generate a unique ID for internal use
    const uniqueId = `barcode_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    // Create a hidden container if it doesn't exist
    let container = document.getElementById("hidden_barcode_container");
    if (!container) {
      container = document.createElement("div");
      container.id = "hidden_barcode_container";
      container.style.display = "none";
      document.body.appendChild(container);
    }

    // Create the canvas element
    let canvas = document.createElement("canvas");
    canvas.id = uniqueId;
    container.appendChild(canvas);

    try {
      // Generate the barcode
      JsBarcode(`#${uniqueId}`, code, {
        format: "code128",
        width: 2,
        height: 100,
        displayValue: false,
      });
    } catch (error) {
      container.removeChild(canvas);
      return (
        appState && appState.setAlert("Error generating barcode", "error", 5000)
      );
    }

    const {
      width,
      height,
      defaultFontSize,
      fontSizeWithAdditionalText,
      defaulltBarcodeRatio,
      barcodeRatioWithAdditionalText,
      horizontalBorder,
      verticalBorder,
      textTruncateLength,
    } = dimensionVariables[dimensions];

    const fontSize = text1 ? fontSizeWithAdditionalText : defaultFontSize;
    const barcodeRatio = text1
      ? barcodeRatioWithAdditionalText
      : defaulltBarcodeRatio;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [width, height],
    });

    let vCursor = verticalBorder;
    let hCursor = horizontalBorder;
    let barcodesizeH = width - 2 * horizontalBorder;
    let barcodesizeV = barcodesizeH / barcodeRatio;

    // Add the barcode image to the PDF
    doc.addImage(
      canvas.toDataURL(),
      "PNG",
      hCursor,
      vCursor,
      barcodesizeH,
      barcodesizeV,
    );
    doc.setFontSize(fontSize);
    const linespace = 3 / 100;
    vCursor = vCursor + barcodesizeV + fontSize / 100;
    doc.text(code, width / 2, vCursor, { align: "center" });
    if (text1) {
      vCursor = vCursor + linespace * 2 + fontSize / 100;
      doc.text(truncate(text1, textTruncateLength), horizontalBorder, vCursor);
    }
    if (text2) {
      vCursor = vCursor + linespace + fontSize / 100;
      doc.text(truncate(text2, textTruncateLength), horizontalBorder, vCursor);
    }

    // Clean up
    container.removeChild(canvas);

    // @ts-ignore
    if (print === false) {
      doc.save(`${code}.pdf`);
      return appState && appState.removeLoading();
    } else {
      const out = doc.output("datauristring");
      const cpj = new JSPM.ClientPrintJob();
      let myPrinter;
      if (printer) {
        myPrinter = new JSPM.InstalledPrinter(printer);
      } else {
        myPrinter = new JSPM.DefaultPrinter();
      }
      cpj.clientPrinter = myPrinter;
      for (let i = 0; i < noOfCopies; i++) {
        const myFile = new JSPM.PrintFilePDF(
          out,
          JSPM.FileSourceType.URL,
          `${code}_${i}.pdf`,
          1,
        );
        cpj.files.push(myFile);
      }
      cpj.sendToClient();
      return appState && appState.removeLoading();
    }
  }

  try {
    let widthOfPdf = parseInt(dimensions.split("x")[0]);
    let heightOfPdf = parseInt(dimensions.split("x")[1]);
    htmlToImage
      .toPng(document.getElementById(code), { quality: 1 })
      .then((dataUrl) => {
        const pdf = new jsPDF({
          orientation: widthOfPdf > heightOfPdf ? "landscape" : "portrait",
          unit: "in",
          format: [widthOfPdf, heightOfPdf],
        });
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.addImage(dataUrl, "PNG", 0, 0, width, height);

        if (print === false) {
          pdf.save(`${code}.pdf`);
          appState && appState.removeLoading();
          return;
        }
        const out = pdf.output("datauristring");
        const cpj = new JSPM.ClientPrintJob();
        let myPrinter;
        if (printer) {
          myPrinter = new JSPM.InstalledPrinter(printer);
        } else {
          myPrinter = new JSPM.DefaultPrinter();
        }
        cpj.clientPrinter = myPrinter;
        for (let i = 0; i < noOfCopies; i++) {
          const myFile = new JSPM.PrintFilePDF(
            out,
            JSPM.FileSourceType.URL,
            `${code}_${i}.pdf`,
            1,
          );
          cpj.files.push(myFile);
        }
        cpj.sendToClient();
      });

    appState && appState.removeLoading();
  } catch (err) {
    throw err;
  }
};

// NOTE: THESE ARE THE VARIOUS SETTINGS VARIABLES FOR THE DIFFERENT LABEL SIZES SUPPORTED BY THE JSBARCODE PRINTING METHOD INTRODUCED ABOVE
const dimensionVariables = {
  "2.25x1.25": {
    width: 2.25,
    height: 1.25,
    defaultFontSize: 14,
    fontSizeWithAdditionalText: 10,
    defaulltBarcodeRatio: 3,
    barcodeRatioWithAdditionalText: 4,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  "2x1": {
    width: 2,
    height: 1,
    defaultFontSize: 12,
    fontSizeWithAdditionalText: 10,
    defaulltBarcodeRatio: 3,
    barcodeRatioWithAdditionalText: 4,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  "3x1": {
    width: 3,
    height: 1,
    defaultFontSize: 14,
    fontSizeWithAdditionalText: 10,
    defaulltBarcodeRatio: 5,
    barcodeRatioWithAdditionalText: 6,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  "2.25x1.57": {
    width: 2.25,
    height: 1.57,
    defaultFontSize: 12,
    fontSizeWithAdditionalText: 12,
    defaulltBarcodeRatio: 2,
    barcodeRatioWithAdditionalText: 3,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
};

const truncate = (str, maxLength) => {
  return str && str.length > maxLength
    ? str.substring(0, maxLength) + "..."
    : str;
};
