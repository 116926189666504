import _, { capitalize, isEmpty } from "lodash";
import { ArrowLeftIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { useState, useEffect, useContext, Fragment } from "react";
import TariffSidePanel from "./TariffSidePanel";
import InformationIcon from "#components/utils/InformationIcon";
import TariffAccordion from "./TariffAccordion";
import { AddClientModal, StatusIcon } from "./BillingProfilesList";
import { BillingStatus } from "../../pages/billing-profiles";
import subTariffIcon1 from "../../static/images/subtariff1.png";
import subTariffIcon2 from "../../static/images/subtariff2.png";
import subTariffIcon3 from "../../static/images/subtariff3.png";
import subTariffIcon4 from "../../static/images/subtariff4.png";
import subTariffIcon5 from "../../static/images/subtariff5.png";
import ReactTooltip from "react-tooltip";
import { AppStateContext } from "../../contexts/appState";

const BillingProfileSummary = ({
  setSelectedProfile,
  billingProfileFormConfig,
  saveBillingProfile,
  billingProfile,
  setBillingProfile,
}) => {
  const [fulfillmentType, setFulfillmentType] = useState(
    billingProfileFormConfig?.enums?.fulfillmentTypes[0],
  );
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [tariffType, setTariffType] = useState(null);
  const [subTariffType, setSubTariffType] = useState(null);
  const [showSidePanel, setShowSidePanel] = useState(false);
  let isDraft = billingProfile.status === BillingStatus.DRAFT;
  // billing profile is in edit mode if there is no ID or the saved profile is in DRAFT status
  let isEditMode = !billingProfile.id || isDraft;
  const subTariffIcons = [
    subTariffIcon1,
    subTariffIcon2,
    subTariffIcon3,
    subTariffIcon4,
    subTariffIcon5,
  ];

  const appState = useContext(AppStateContext);

  const saveTariff = (updatedTariffs) => {
    updatedTariffs.forEach((updatedTariff) => {
      let existingIndex = billingProfile.tariffs.findIndex(
        (tariff) => tariff.id === updatedTariff.id,
      );

      if (existingIndex !== -1) {
        setBillingProfile((prevProfile) => ({
          ...prevProfile,
          tariffs: prevProfile.tariffs.map((tariff, index) =>
            index === existingIndex ? updatedTariff : tariff,
          ),
        }));
      } else {
        setBillingProfile((prevProfile) => ({
          ...prevProfile,
          tariffs: [...prevProfile.tariffs, updatedTariff],
        }));
      }
    });

    setShowSidePanel(false);
  };

  const deleteTariff = (selectedTariffs, subTariff) => {
    const filterTariffs = selectedTariffs.filter((selectedTariff) => {
      return selectedTariff.subTariffType === subTariff;
    });

    const lastTariff = filterTariffs.pop();

    if (lastTariff) {
      let found = false;
      setBillingProfile((prevProfile) => ({
        ...prevProfile,
        tariffs: prevProfile.tariffs.filter((tariff) => {
          if (
            !found &&
            tariff.tariffType === lastTariff.tariffType &&
            tariff.subTariffType === lastTariff.subTariffType &&
            tariff.fulfillmentType === lastTariff.fulfillmentType
          ) {
            found = true;
            return false;
          }
          return true;
        }),
      }));
    }
  };

  useEffect(() => {
    setFulfillmentType(billingProfileFormConfig?.enums?.fulfillmentTypes[0]);

    const defaultCurrency = billingProfileFormConfig?.enums?.currency[0];
    const defaultMetricSystem =
      billingProfileFormConfig?.enums?.metricSystems[0];
    const updatedTariffs =
      billingProfile?.tariffs?.map((tariff, index) => ({
        ...tariff,
        id: index,
      })) || [];

    setBillingProfile((prev) => ({
      ...prev,
      currency: billingProfile?.currency || defaultCurrency,
      metricSystem: billingProfile?.metricSystem || defaultMetricSystem,
      tariffs: updatedTariffs.length > 0 ? updatedTariffs : prev.tariffs || [],
    }));
  }, [billingProfileFormConfig]);

  const SavedTariffCard = ({ savedTariff }) => {
    const [selectedTier, setSelectedTier] = useState(0);
    if (!savedTariff) {
      return <></>;
    }
    return (
      <div>
        <div className="my-3 flex gap-2">
          {savedTariff?.tiers?.map((tier, index) => {
            return (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTier(index);
                }}
                className={`${
                  selectedTier === index
                    ? "border-b border-[#224E73] text-[#224E73]"
                    : "text-[#717679]"
                }`}>
                Rate {index + 1}
              </button>
            );
          })}
        </div>
        <div className="flex gap-4">
          <div>
            <div className="text-[14px] text-[#94999C]">Rate Type</div>
            <div className="text-[16px]">
              {savedTariff?.flatRate ? "Flat" : "Variable"}
            </div>
          </div>

          {savedTariff?.resetTime && (
            <div>
              <div className="text-[14px] text-[#94999C]">Frequency</div>
              <div className="text-[16px]">{savedTariff?.resetTime}</div>
            </div>
          )}

          {savedTariff.tiers &&
            savedTariff.tiers.length > 0 &&
            savedTariff.tiers[selectedTier].ranges.map((range) => {
              let uom = savedTariff.uoms.find((uom) => uom.id === range.id);
              return (
                <>
                  <div>
                    <div className="text-[14px] text-[#94999C]">Value</div>
                    <div className="text-[16px]">
                      {range.start}
                      {range.end && `- ${range.end}`}
                    </div>
                  </div>
                  <div>
                    <div className="text-[14px] text-[#94999C]">Entity</div>
                    <div className="text-[16px]">{uom?.entity}</div>
                  </div>
                </>
              );
            })}

          <div>
            <div className="text-[14px] text-[#94999C]">Fees</div>
            <div className="text-[16px]">
              {savedTariff.tiers && savedTariff.tiers.length > 0
                ? savedTariff.tiers[selectedTier].rate
                : "N/A"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TariffsContainer = ({ savedTariffs }) => {
    const [activeTariffIndex, setActiveTariffIndex] = useState(0);

    return (
      <div>
        <div className="scrollbar-hide my-3 flex gap-2 overflow-x-auto">
          {savedTariffs?.map((tariff, index) => (
            <button
              key={index}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setActiveTariffIndex(index);
              }}
              className={`rounded px-4 py-2 ${activeTariffIndex === index ? "bg-224E73 text-white" : "bg-gray-200 text-black"}`}>
              Tariff {index + 1}
            </button>
          ))}
        </div>
        <SavedTariffCard savedTariff={savedTariffs[activeTariffIndex]} />
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex gap-2 py-2">
          <button onClick={() => setSelectedProfile(null)}>
            <ArrowLeftIcon className="my-auto h-6 w-6" />
          </button>
          <h2 className="text-[24px] font-semibold text-black">
            {isEditMode ? "Add Billing Profile" : billingProfile.name}
          </h2>
          {billingProfile.status && (
            <StatusIcon status={billingProfile.status} />
          )}
        </div>
        {billingProfile.status === BillingStatus.ACTIVE && (
          <div className="flex gap-2">
            <button
              onClick={() => setShowAddClientModal(true)}
              className="my-2 rounded-lg bg-[#C93A31] px-8 py-1.5 text-[16px] text-white hover:opacity-80">
              Add Clients
            </button>

            <button
              onClick={() => {
                appState.showConfirmation(
                  "Deactivate Billing Profile",
                  `Are you sure you want to deactivate ${
                    billingProfile.name || ""
                  } ?`,
                  () => {
                    let updatedTariffs = billingProfile.tariffs.map(
                      (tariff) => {
                        let { id, ...rest } = tariff;
                        return rest;
                      },
                    );
                    saveBillingProfile({
                      ...billingProfile,
                      status: BillingStatus.INACTIVE,
                      tariffs: updatedTariffs,
                    });
                    appState.hideConfirmation;
                  },
                  appState.hideConfirmation,
                );
              }}
              className="my-2 rounded-lg bg-[#417492] px-8 py-1.5 text-[16px] text-white hover:opacity-80">
              Deactivate
            </button>
          </div>
        )}
      </div>
      {isEditMode && (
        <div className="text-[14px] text-[#717679]">
          Create and manage your billing profiles from here
        </div>
      )}

      {isEditMode && (
        <>
          <div className="mt-4 text-[20px] font-semibold">Basic Info</div>
          <div className="my-2 flex gap-2">
            <div className="text-[14px] text-[#717679]">
              Billing Profile Name
            </div>
            <InformationCircleIcon
              data-tip
              data-for="profileNameInfo"
              className="cursor-pointer text-xs"
              width={15}
            />
            <ReactTooltip id="profileNameInfo" place="top" effect="solid">
              Profile name is a unique identifier. Please make sure it is
              distinct and easy to recognise.
            </ReactTooltip>
          </div>
          <input
            value={billingProfile.name}
            onChange={(e) =>
              setBillingProfile({ ...billingProfile, name: e.target.value })
            }
            className="w-[500px] rounded border border-[#DDDFE0] bg-transparent p-1"
          />
        </>
      )}
      <div className="mt-4 flex w-[500px] gap-2">
        <div className="w-full">
          <div className="my-2 text-[14px] text-[#717679]">Currency</div>
          {isEditMode ? (
            <select
              value={billingProfile?.currency}
              onChange={(e) =>
                setBillingProfile({
                  ...billingProfile,
                  currency: e.target.value,
                })
              }
              className="w-full rounded border border-[#DDDFE0] bg-transparent p-1">
              {billingProfileFormConfig?.enums?.currency.map(
                (currency, index) => {
                  return <option key={index}>{currency}</option>;
                },
              )}
            </select>
          ) : (
            <div>{billingProfile.currency}</div>
          )}
        </div>
        <div className="w-full">
          <div className="my-2 text-[14px] text-[#717679]">Metric System</div>
          {isEditMode ? (
            <select
              value={billingProfile?.metricSystem}
              onChange={(e) =>
                setBillingProfile({
                  ...billingProfile,
                  metricSystem: e.target.value,
                })
              }
              className="w-full rounded border border-[#DDDFE0] bg-transparent p-1">
              {billingProfileFormConfig?.enums?.metricSystems.map(
                (metricSystem, index) => {
                  return <option key={index}>{metricSystem}</option>;
                },
              )}
            </select>
          ) : (
            <div>{billingProfile.metricSystem}</div>
          )}
        </div>
      </div>

      <div className="mt-8 text-[20px] font-semibold">Manage Tariffs</div>

      <div className="flex gap-4">
        {billingProfileFormConfig?.enums?.fulfillmentTypes.map(
          (fulfillment, index) => {
            return (
              <button
                key={index}
                onClick={() => setFulfillmentType(fulfillment)}
                className={`${
                  fulfillmentType === fulfillment
                    ? "border-[#224E73] text-[#224E73]"
                    : "text-[#717679]"
                } my-2 border-b-2 border-transparent text-[16px]`}>
                {capitalize(fulfillment.replace("_", "-"))}
              </button>
            );
          },
        )}
      </div>
      <p className="my-2 text-[14px] text-[#717679]">
        For the selected Fulfilment Service, you can add tariffs under different
        categories by clicking on them
      </p>

      {billingProfileFormConfig?.fulfillmentTariffCombinations[
        fulfillmentType
      ]?.map((fulfillment, index) => {
        let savedSubTariffs = billingProfile?.tariffs?.filter(
          (tariff) =>
            tariff.fulfillmentType === fulfillmentType &&
            tariff.tariffType === fulfillment.tariff,
        )?.length;
        return (
          <TariffAccordion
            icon={subTariffIcons[index]}
            key={index}
            title={fulfillment.tariff}
            subtitle={`${savedSubTariffs || 0} tariffs selected`}>
            <div className="flex flex-wrap gap-2">
              {fulfillment.subTariffs.map((subTariff, index) => {
                let savedTariffs = billingProfile.tariffs?.filter((tariff) => {
                  return (
                    tariff.fulfillmentType === fulfillmentType &&
                    tariff.subTariffType === subTariff &&
                    tariff.tariffType === fulfillment.tariff
                  );
                });
                return (
                  <div
                    key={index}
                    onClick={(e) => {
                      setTariffType(fulfillment.tariff);
                      setSubTariffType(subTariff);
                      setShowSidePanel(true);
                    }}
                    className={`${
                      savedTariffs && savedTariffs.length > 0
                        ? "border-[#DDDFE0] bg-white"
                        : "border-transparent bg-[#F9F9F9]"
                    } relative flex min-h-[220px] w-[500px] cursor-pointer flex-col overflow-y-hidden rounded-lg border p-4`}>
                    <div className="flex justify-between">
                      <div className="text-[18px] font-semibold text-[#111827]">
                        {subTariff.replace("_", "-")}
                      </div>
                      <div className="flex gap-2">
                        {isEditMode &&
                          savedTariffs &&
                          savedTariffs.length > 0 && (
                            <>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  appState.showConfirmation(
                                    "Delete Tariff",
                                    "Are you sure you want to delete the tariff?",
                                    () => {
                                      deleteTariff(savedTariffs, subTariff);
                                      appState.hideConfirmation();
                                    },
                                    appState.hideConfirmation,
                                  );
                                }}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M19 7L18.1327 19.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224C6.81296 21 5.94208 20.1891 5.86732 19.1425L5 7M10 11V17M14 11V17M15 7V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V7M4 7H20"
                                    stroke="#C93A31"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>

                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M10 6H6C4.89543 6 4 6.89543 4 8V18C4 19.1046 4.89543 20 6 20H16C17.1046 20 18 19.1046 18 18V14M14 4H20M20 4V10M20 4L10 14"
                                  stroke="#224E73"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </>
                          )}
                      </div>
                    </div>
                    {savedTariffs?.length > 0 ? (
                      <TariffsContainer savedTariffs={savedTariffs} />
                    ) : (
                      <div className="mt-4 text-[#94999C]">
                        No tariffs added to this sub category
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </TariffAccordion>
        );
      })}

      {showSidePanel && (
        <TariffSidePanel
          showSidePanel={showSidePanel}
          setShowSidePanel={setShowSidePanel}
          tariffType={tariffType}
          subTariffType={subTariffType}
          fulfillmentType={fulfillmentType}
          billingProfileFormConfig={billingProfileFormConfig}
          savedTariff={billingProfile.tariffs?.filter((tariff) => {
            return (
              tariff.fulfillmentType === fulfillmentType &&
              tariff.subTariffType === subTariffType &&
              tariff.tariffType === tariffType
            );
          })}
          newId={billingProfile.tariffs?.length || 0}
          currency={billingProfile.currency}
          billingProfile={billingProfile}
          saveTariff={saveTariff}
          isEditMode={isEditMode}
        />
      )}

      {isEditMode && (
        <div className="my-2 flex justify-end gap-4">
          <button
            onClick={() => setSelectedProfile(null)}
            className="rounded-lg border border-[#DDD] px-8 py-1.5 text-[16px] hover:opacity-80">
            Cancel
          </button>
          <button
            onClick={() => {
              appState.showConfirmation(
                `Create Billing Profile`,
                <>
                  <div>
                    Are you sure you want to save {billingProfile.name || ""} as
                    Draft?
                  </div>
                  <p className="text-[14px] text-gray-600">
                    Note: Only Active profiles can be assigned to clients.
                  </p>
                </>,
                () => {
                  saveBillingProfile({
                    ...billingProfile,
                    status: BillingStatus.DRAFT,
                  });
                  appState.hideConfirmation();
                },
                appState.hideConfirmation,
              );
            }}
            className="rounded-lg bg-[#C93A31] px-8 py-1.5 text-[16px] text-white hover:opacity-80">
            Save as Draft
          </button>
          <button
            onClick={() => {
              appState.showConfirmation(
                "Create Billing Profile",
                <>
                  <div>
                    Are you sure you want to submit {billingProfile.name || ""}?
                  </div>
                  <p className="m-auto w-[400px] text-[14px] leading-normal text-gray-600">
                    Note: After submission, the profile will become active,
                    allowing you to assign it to one or more clients.
                  </p>
                </>,
                () => {
                  saveBillingProfile({
                    ...billingProfile,
                    status: BillingStatus.ACTIVE,
                  });
                  appState.hideConfirmation();
                },
                appState.hideConfirmation,
              );
            }}
            className="rounded-lg bg-[#417492] px-8 py-1.5 text-[16px] text-white hover:opacity-80">
            Submit
          </button>
        </div>
      )}
      {showAddClientModal && (
        <AddClientModal dismiss={() => setShowAddClientModal(false)} />
      )}
    </div>
  );
};

export default BillingProfileSummary;
