import { useState } from "react";
import ManageCatalog from "./catalogTabs/ManageCatalog";
import SectionTabs from "./catalogTabs/SectionTabs";
import ContainerWithLoaderAndAlert from "../common/ContainerWithLoaderAndAlert";
import ManageCategories from "./CatalogManageCategories";

const CatalogTabs = ({
  products,
  total,
  filters,
  onChangeFilter,
  submitFilters,
  customers,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  productTags,
  getCatalog,
  fetchedCatalog,
  setFetchedCatalog,
  pageSavedFilters,
  setFilters,
  saveBookmark,
  applyBookmarkFilters,
  setFilterQueryOperator,
  filterQueryOperator,
  filteringCriteria,
  productCategories,
  setProductCategories,
  clearFilters,
  setExportListHeaders,
  setReloadCategories,
  reloadCategories,
  handleScroll,
  getSpecificProduct,
  loadFirstTimeData,
  parentProducts,
}) => {
  const [tabs, setTabs] = useState([
    { name: "Analytics", current: false },
    { name: "Manage Catalog", current: true },
    { name: "Manage Categories", current: false },
    { name: "Manage Products", current: false },
  ]);

  const renderTabContent = () => {
    const currentTab = tabs.find((tab) => tab.current).name;
    switch (currentTab) {
      case "Analytics":
        return (
          <div className="flex h-96 items-center justify-center text-2xl text-gray-400">
            Analytics Coming Soon
          </div>
        );
      case "Manage Catalog":
        return (
          <ContainerWithLoaderAndAlert>
            <ManageCatalog
              products={products}
              total={total}
              filters={filters}
              onChangeFilter={onChangeFilter}
              submitFilters={submitFilters}
              customers={customers}
              selectProduct={selectProduct}
              selectedProducts={selectedProducts}
              selectAllRows={selectAllRows}
              allRowsSelected={allRowsSelected}
              productTags={productTags}
              getCatalog={getCatalog}
              fetchedCatalog={fetchedCatalog}
              setFetchedCatalog={setFetchedCatalog}
              pageSavedFilters={pageSavedFilters}
              setFilters={setFilters}
              saveBookmark={saveBookmark}
              applyBookmarkFilters={applyBookmarkFilters}
              setFilterQueryOperator={setFilterQueryOperator}
              filterQueryOperator={filterQueryOperator}
              filteringCriteria={filteringCriteria}
              productCategories={productCategories}
              clearFilters={clearFilters}
              loadFirstTimeData={loadFirstTimeData}
              setExportListHeaders={setExportListHeaders}
              handleScroll={handleScroll}
              getSpecificProduct={getSpecificProduct}
              parentProducts={parentProducts}
            />
          </ContainerWithLoaderAndAlert>
        );
      case "Manage Categories":
        return (
          <ContainerWithLoaderAndAlert>
            <ManageCategories
              productCategories={productCategories}
              setProductCategories={setProductCategories}
              setReloadCategories={setReloadCategories}
              reloadCategories={reloadCategories}
              products={products}
              loadFirstTimeData={loadFirstTimeData}
            />
          </ContainerWithLoaderAndAlert>
        );
      case "Manage Products":
        return (
          <div className="flex h-96 items-center justify-center text-2xl text-gray-400">
            Products Coming Soon
          </div>
        );
      default:
        return <div>Manage Catalog</div>;
    }
  };

  return (
    <div className="flex-col">
      <SectionTabs tabs={tabs} setTabs={setTabs} />
      <div>{renderTabContent()}</div>
    </div>
  );
};

export default CatalogTabs;
