import { CogIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";
import _ from "lodash";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { TrashIcon } from "@heroicons/react/solid";

const UserRolesList = ({
  userRoles,
  users,
  headers,
  customers,
  warehouses,
  editUserRolePermissionButtonClicked,
  filters,
  onChangeFilter,
  deleteUserRole,
  writable,
}) => {
  const getUsersForRole = (role) => {
    const filteredUsers = users.filter((user) => user.role == role);
    const userNamesList = filteredUsers.map((user) => user && user.name);
    return userNamesList;
  };

  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-14">
      <div className="mt-6">
        <CustomTableWithTheme>
          <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
            <tr className="border-left font-montserrat text-textWhite">
              {headers.map((header, headerIdx) => (
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {userRoles.map((userRole, index) => {
              const menuItems = [];
              if (userRole.name != "ASSOCIATE")
                menuItems.push({
                  title: "Role Permissions",
                  icon: CogIcon,
                  onClick: () => editUserRolePermissionButtonClicked(userRole),
                });

              if (!getUsersForRole(userRole.name).length > 0)
                menuItems.push({
                  title: "Delete Role",
                  icon: TrashIcon,
                  onClick: () => deleteUserRole(userRole.id),
                });

              return (
                <tr
                  key={userRole.id}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`rounded-bl rounded-tl border-l-8 p-5 text-left font-semibold tracking-wider text-primaryAccent ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {userRole.name}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    <ExpandedMultipleItem
                      items={getUsersForRole(userRole.name)}
                    />
                  </td>
                  <td className="rounded-br rounded-tr px-1 py-1 text-left font-medium tracking-wider text-tableText">
                    {writable && (
                      <>
                        {menuItems.length > 0 && (
                          <div className="ml-4 flex min-w-max items-center justify-start space-x-2">
                            <Menu as="div">
                              <Menu.Button>
                                <DotsVerticalIcon className="h-8 w-8 rounded bg-actionMenuBG p-1 font-montserrat text-lg font-bold" />
                              </Menu.Button>
                              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="">
                                  {menuItems.map((menuItem, idx) => (
                                    <Menu.Item key={idx}>
                                      {() => (
                                        <button
                                          className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                                          onClick={menuItem.onClick}>
                                          {menuItem.icon && (
                                            <menuItem.icon className="h-8 w-8 pr-2" />
                                          )}
                                          {menuItem.title}
                                        </button>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </div>
                              </Menu.Items>
                            </Menu>
                          </div>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </CustomTableWithTheme>
      </div>
    </div>
  );
};

export default UserRolesList;
