import { useState } from "react";
import Select from "react-select";

const BundlerNewBatchRequest = ({ warehouses, setSelectedWarehouse }) => {
  const defaultWarehouse = warehouses?.length === 1 ? warehouses[0] : null;
  const [selectedWarehouse, setWarehouse] = useState(defaultWarehouse);

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="items-center justify-center space-x-4">
        <div className="mb-10 flex w-1/2 min-w-1/2 items-center gap-20 p-5">
          <div className="w-50">
            <Select
              options={
                warehouses &&
                warehouses.map((item) => ({
                  value: item.id,
                  label: `${item.name} - ${item.code}`,
                }))
              }
              value={selectedWarehouse}
              onChange={(selectedOption) => {
                setWarehouse(selectedOption);
              }}
              className="w-96"
              placeholder="Select Warehouse"
              isClearable={true}
            />
          </div>
        </div>
        <div className="flex items-center justify-center self-center py-10">
          <div
            className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
            onClick={() => setSelectedWarehouse(selectedWarehouse.value)}>
            View Tasks
          </div>
        </div>
      </div>
    </div>
  );
};

export default BundlerNewBatchRequest;
