import React, { useState } from "react";
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import { handleGetStatusColor } from "./PoUtils";
import CustomBadge from "../commons/CustomBadge";

const PoBasicInfo = ({ basicInfo }) => {
  const poData = {
    orderId: basicInfo.poId,
    vendor: basicInfo.vendorName,
    vendorClassification: basicInfo.vendorClassification,
    warehouse: basicInfo.warehouseName,
    status: (
      <CustomBadge
        textSize="text-sm"
        bgColor={handleGetStatusColor(basicInfo.status).bgColor}
        textColor={handleGetStatusColor(basicInfo.status).textColor}
        label={basicInfo.status}
      />
    ),
    priority: basicInfo.priority,
    priorityValue: basicInfo.selectedPriorityTag,
    expectedShipDate: basicInfo.expectedShipDate,
    expectedDeliveryDate: basicInfo.expectedDeliveryDate,
    notes: basicInfo.note,
  };

  const getColorForTag = (tagValue) => {
    if (tagValue >= 3) {
      return "text-red-500";
    } else if (tagValue === 2) {
      return "text-yellow-500";
    } else return "text-green-500";
  };

  return (
    <div className="rounded-md border border-gray-200 bg-white p-6 shadow-sm">
      <h2 className="mb-4 text-lg font-semibold">PO Info</h2>
      <div className="grid grid-cols-4 gap-4">
        <div className="flex flex-col gap-2">
          <p className="text-sm text-gray-500">Order ID</p>
          <p className="text-sm text-black">{poData.orderId}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="flex items-center text-sm text-gray-500">Vendor</p>
          <p className="text-sm text-black">{poData.vendor}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-gray-500">Vendor Classification</p>
          <p className="text-sm text-black">{poData.vendorClassification}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-gray-500">Warehouse</p>
          <p className="text-sm text-black">{poData.warehouse}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-gray-500">Status</p>
          <p>{poData.status}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="flex items-center text-sm text-gray-500">Priority</p>
          <p className={"text-sm " + getColorForTag(poData.priorityValue)}>
            {poData.priority}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-gray-500">Expected Ship Date</p>
          <p className="text-sm text-black">{poData.expectedShipDate}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-gray-500">Expected Delivery Date</p>
          <p className="text-sm text-black">{poData.expectedDeliveryDate}</p>
        </div>
        <div className="col-span-4 flex flex-col gap-2">
          <p className="text-sm text-gray-500">Notes</p>
          <div className={"relative max-h-12 overflow-hidden"}>
            <p className="text-sm text-black">{poData.notes}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoBasicInfo;
