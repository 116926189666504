import { useState, useEffect, useContext, useRef } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_CRM_SALES_ORDERS } from "#queries";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { GET_SO_PDF_PRESIGNED_URL } from "#mutations";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DownloadIcon,
  CloudDownloadIcon,
} from "@heroicons/react/outline";

const RECORDS_PER_PAGE = 25;
const ALERT_VISIBILITY_IN_MS = 5000;
const ROW_COUNT_WHEN_NOT_FULL = 4;

const tableHeaders = [
  {
    name: "Order ID",
    value: "id",
    sortable: true,
    sortBy: "id",
  },
  {
    name: "Company",
    value: "Company_Name",
    sortable: false,
    sortBy: "",
  },
  {
    name: "Products",
    value: "Quantity",
    sortable: false,
    sortBy: "",
  },
  {
    name: "Status",
    value: "Status",
    sortable: false,
    sortBy: "",
  },
  {
    name: "Order Total",
    value: "Grand_Total",
    sortable: false,
    sortBy: "",
  },
  {
    name: "Actions",
    value: "",
    sortable: false,
    sortBy: "",
  },
];

const CrmOrderHistoryTable = ({ crmCustomerId, full, onShowFull }) => {
  const [crmOrderHistory, setCrmOrderHistory] = useState([]);
  const appState = useContext(AppStateContext);
  const getCrmSalesOrder = useQuery(GET_CRM_SALES_ORDERS);
  const getSoPdfPresignedUrlQuery = useQuery(GET_SO_PDF_PRESIGNED_URL);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  const observer = useRef();

  const createOrDownloadPDF = (crmSalesOrderId) => {
    getSoPdfPresignedUrlQuery.fetchData({ crmSalesOrderId });
  };

  const lastLogElementRef = (node) => {
    if (loading) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && full) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    });

    if (node) observer.current.observe(node);
  };

  useEffect(() => {
    if (getSoPdfPresignedUrlQuery.error) {
      appState.setAlert(
        getSoPdfPresignedUrlQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (getSoPdfPresignedUrlQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (getSoPdfPresignedUrlQuery.data) {
      const presignedUrl =
        getSoPdfPresignedUrlQuery.data?.getCrmSalesOrderPdfPresignedUrl
          ?.presignedUrl;
      const status =
        getSoPdfPresignedUrlQuery.data?.getCrmSalesOrderPdfPresignedUrl
          ?.downloadStatus;
      if (status == "PENDING") {
        appState.setAlert(
          "PDF is being generated. Please check the Downloads tab after a few seconds.",
          "info",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      if (status == "GENERATED") {
        if (presignedUrl) {
          // Download the file
          const link = document.createElement("a");
          link.href = presignedUrl;
          link.setAttribute("download", "file.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return;
        } else {
          appState.setAlert(
            "PDF could not be generated. Please try again.",
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
        }
      }
    }
  }, [
    getSoPdfPresignedUrlQuery.data,
    getSoPdfPresignedUrlQuery.error,
    getSoPdfPresignedUrlQuery.loading,
  ]);

  const fetchCrmSalesOrder = () => {
    getCrmSalesOrder.fetchData({
      perPage: RECORDS_PER_PAGE,
      pageNumber: currentPage,
      sortBy: sortBy,
      searchParams: {
        crmCustomerId: crmCustomerId,
      },
    });
  };

  useEffect(() => {
    fetchCrmSalesOrder();
  }, [currentPage]);

  useEffect(() => {
    if (getCrmSalesOrder.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (getCrmSalesOrder.error) {
      appState.setAlert(
        getCrmSalesOrder.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (getCrmSalesOrder.data) {
      const orders = getCrmSalesOrder.data.getCrmSalesOrders?.zohoSalesOrders;
      if (orders) {
        if (orders.length < RECORDS_PER_PAGE) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setCrmOrderHistory((prevOrders) => [...prevOrders, ...orders]);
      }
    }
  }, [getCrmSalesOrder.error, getCrmSalesOrder.data, getCrmSalesOrder.loading]);

  const sortTableBy = (newSortBy) => {
    // Don't reset the table if the sortBy value is the same
    if (sortBy !== newSortBy) {
      setSortBy(newSortBy);
      setCrmOrderHistory([]);
      if (currentPage !== 1) {
        // Change page and trigger the fetch
        setCurrentPage(1);
      } else {
        fetchCrmSalesOrder();
      }
    }
  };

  return (
    <div className="h-full flex-col items-center">
      {full && loading && <LoadingIndicator shouldShowOnPage={true} />}
      <div className="h-full px-4 py-5 sm:p-6">
        <div className="h-full overflow-hidden">
          <div className="h-full overflow-y-auto rounded-lg bg-white shadow-lg">
            <table className="min-w-full bg-white">
              <thead className="sticky top-0 z-0 bg-bgGray">
                <tr>
                  {tableHeaders.map((header) => (
                    <th
                      key={header.value}
                      scope="col"
                      className="sticky top-0 z-0 px-2 py-3 pl-4 font-medium tracking-wider text-gray-500">
                      <div
                        className={`flex items-center ${header.name == "Actions" ? "justify-center" : ""} gap-2`}>
                        {header.name}
                        {header.sortable && (
                          <div className="flex-col">
                            <ChevronUpIcon
                              onClick={() => sortTableBy(header.sortBy)}
                              className={`h-5 w-5 cursor-pointer ${
                                sortBy === header.sortBy
                                  ? "text-green-400"
                                  : "text-gray-900"
                              }`}
                            />
                            <ChevronDownIcon
                              onClick={() => sortTableBy(`-${header.sortBy}`)}
                              className={`h-5 w-5 cursor-pointer ${
                                sortBy === `-${header.sortBy}`
                                  ? "text-green-400"
                                  : "text-gray-900"
                              }`}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!loading && crmOrderHistory.length === 0 && (
                  <tr>
                    <td colSpan={tableHeaders.length} className="w-full py-4">
                      <div className="flex items-center justify-center">
                        <p>No Orders found</p>
                      </div>
                    </td>
                  </tr>
                )}
                {!full && loading && currentPage === 1 && (
                  <tr>
                    <td colSpan={tableHeaders.length} className="w-full py-4">
                      <div className="flex items-center justify-center">
                        <LoadingIndicator shouldShowOnPage={false} />
                      </div>
                    </td>
                  </tr>
                )}
                {(full
                  ? crmOrderHistory
                  : crmOrderHistory.slice(0, ROW_COUNT_WHEN_NOT_FULL)
                ).map((order, idx) => (
                  <tr
                    className="border-b border-gray-200"
                    ref={
                      full && crmOrderHistory.length === idx + 1
                        ? lastLogElementRef
                        : null
                    }
                    key={order.id}>
                    {tableHeaders.map((header) => {
                      if (header.value === "id") {
                        return (
                          <td className="whitespace-nowrap px-2 py-3 pl-4 text-left font-medium text-gray-900">
                            {order.id}
                          </td>
                        );
                      }
                      if (header.value === "Company_Name") {
                        return (
                          <td className="whitespace-nowrap px-2 py-3 pl-4 text-left font-medium text-gray-900">
                            {order.Company_Name}
                          </td>
                        );
                      }
                      if (header.value === "Quantity") {
                        return (
                          <td className="whitespace-nowrap px-2 py-3 pl-4 text-left font-medium text-gray-900">
                            {order.Quantity}
                          </td>
                        );
                      }
                      if (header.value === "Status") {
                        return (
                          <td className="pl-4 text-left">
                            {order.Status === "Created" ? (
                              <span className="rounded-full bg-green-100 px-2 py-2 font-medium text-green-600">
                                {order.Status}
                              </span>
                            ) : (
                              <span className="rounded-full bg-red-100 px-2 py-3 font-medium text-red-600">
                                {order.Status}
                              </span>
                            )}
                          </td>
                        );
                      }
                      if (header.value === "Grand_Total") {
                        return (
                          <td className="whitespace-nowrap px-2 py-3 pl-4 text-left font-medium text-gray-900">
                            ${order.Grand_Total}
                          </td>
                        );
                      }
                      if (header.name === "Actions") {
                        return (
                          <td className="p-6">
                            <div className="flex justify-center">
                              <button
                                onClick={() => createOrDownloadPDF(order.id)}>
                                <CloudDownloadIcon className="h-8 w-8 pr-2" />
                              </button>
                            </div>
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            {!full && crmOrderHistory.length > ROW_COUNT_WHEN_NOT_FULL && (
              <div className="m-8 flex justify-center">
                <p
                  className="cursor-pointer text-sm font-medium text-primaryAccent underline"
                  onClick={onShowFull}>
                  Click to show all historic orders
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmOrderHistoryTable;
