import React, { useEffect, useState } from "react";
import bwipjs from "bwip-js";

export const TwoDBarcodeLabel = ({
  barcodeString,
  barcodeStringSplitByLine,
  order,
  batch,
  boxName,
}) => {
  const box = batch.boxes.find((i) => i.name === boxName);
  let canvas = document.createElement("canvas");
  bwipjs.toCanvas(canvas, {
    bcid: "pdf417", // Barcode type
    text: barcodeString, // Text to encode
    scale: 2, // 3x scaling factor
    includetext: true, // Show human-readable text
    textxalign: "center", // Always good to set this
  });
  const src = canvas.toDataURL("image/png");

  return (
    <div>
      <div
        id={`label-container-${boxName}`}
        style={{
          width: "384px",
          height: "576px",
          fontFamily: "Arial",
          margin: "0",
          padding: "0 10px",
          border: "1px solid black",
          fontSize: "14px",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "10px 0",
            borderBottom: "1px solid #000",
            fontWeight: "bold",
            fontFamily: "Arial",
          }}>
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            {order.orderId}
          </div>
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            {`Box ${batch.boxes.findIndex((i) => i.name === boxName) + 1} of ${
              batch.boxes?.length
            } - ${box.weight} lbs`}{" "}
            <br />
            {`${box?.length} x ${box?.width} x ${box?.height} in`}
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={src} alt="barcode" />
        </div>
        <div style={{ textAlign: "left", wordWrap: "break-word" }}>
          {barcodeStringSplitByLine}
        </div>
      </div>
      );
    </div>
  );
};

export default TwoDBarcodeLabel;
