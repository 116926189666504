const HollowButton = ({ text, colour, onClick, styles }) => {
  return (
    <div
      className={`rounded border px-4 py-1.5 border-${colour} cursor-pointer border-solid ${styles}`}
      onClick={onClick}>
      <div className={`text-sm text-${colour} font-semibold`}>{text}</div>
    </div>
  );
};
export default HollowButton;
