import React from "react";
import Select from "react-select";

const CustomSelectDropDown = ({
  label,
  options,
  value,
  onChange,
  className,
  placeholder,
  defaultValue,
  zIndex = 10,
  applyCustomZIndex = false,
}) => {
  const menuPortalTarget = applyCustomZIndex ? document.body : undefined;

  return (
    <div className={`z-8 relative w-full overflow-visible rounded-lg`}>
      {label && (
        <label
          className={`origin-0 px-1 font-montserrat text-sm font-medium text-primaryAccent duration-300`}>
          {label}
        </label>
      )}
      <Select
        options={options.sort((a, b) => a.label.localeCompare(b.label))}
        value={value}
        onChange={onChange}
        className={`text-sm ${className}`}
        menuPortalTarget={menuPortalTarget}
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: "1px solid #E5E7EB",
            boxShadow: state.isFocused ? "0 0 0 1px #2684FF" : "none",
            cursor: "pointer",
          }),
          option: (provided) => ({
            ...provided,
            cursor: "pointer",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: zIndex,
          }),
        }}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default CustomSelectDropDown;
