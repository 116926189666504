import _ from "lodash";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";

export const printCanvasLabel = async (
  code,
  noOfCopies = 1,
  message = "Printing label",
  printer = null,
) => {
  // if (!code) {
  //   return appState.setAlert("Could not print", "error", 5000);
  // }

  try {
    // htmlToImage
    //   .toPng(document.getElementById(code), { quality: 1 })
    //   .then(function (dataUrl) {
    //     var link = document.createElement("a");
    //     link.download = "my-image-name.png";
    //     link.href = dataUrl;
    //     link.click();
    //     // const pdf = new jsPDF();
    //     // pdf.addImage(dataUrl, 'PNG', 0, 0);
    //     // // pdf.output('dataurlnewwindow');
    //     // pdf.save("test.png");
    //   });
    // return;

    htmlToImage.toBlob(document.getElementById(code)).then(function (blob) {
      const cpj = new JSPM.ClientPrintJob();
      let myPrinter;
      if (printer) {
        myPrinter = new JSPM.InstalledPrinter(printer);
      } else {
        myPrinter = new JSPM.DefaultPrinter();
      }

      if (!myPrinter) {
        myPrinter = new JSPM.DefaultPrinter();
      }
      myPrinter.duplex = JSPM.DuplexMode.Simplex;

      cpj.clientPrinter = myPrinter;
      const myFile = new JSPM.PrintFile(
        blob,
        JSPM.FileSourceType.BLOB,
        `${code}.png`,
        1,
      );
      for (let i = 0; i < noOfCopies; i++) {
        cpj.files.push(myFile);
      }
      cpj.sendToClient();
    });
    // appState.setAlert(message, "success", 5000);
  } catch (err) {
    throw err;
  }
};
