import moment from "moment-timezone";
import _ from "lodash";
import {
  FastForwardIcon,
  PencilAltIcon,
  TrashIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";
import BatchFilters from "#components/batches/outbound/BatchFilters";
import ExpandedBatch from "#components/batches/outbound/ExpandedBatch";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { useContext, useEffect, useState } from "react";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import { Menu } from "@headlessui/react";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
const noValuesText = "No Pickings";
import { renderStatus } from "../../utils/renderStatus";
import { isDelmarTenant } from "#utils/tenantCheck";
import Checkbox from "#components/utils/Checkbox";
import Toggle from "#components/utils/Toggle";
import AddButton from "#components/utils/AddButton";
import withOutboundSummaryLogic from "../HOC/withOutboundSummaryLogic";
const CONSOLIDATED_OUTBOUND_TABLE_NAME = "consolidatedOutboundBatchesList";
import { EyeIcon } from "@heroicons/react/solid";
import AssignBatchPickerForm from "#components/pickings/AssignBatchPicker";
import Tooltip from "#components/common/Tooltip";

const statusTooltipContent = (status) => {
  console.log(`STATUS`, status);
  switch (status?.toUpperCase()) {
    case "PICKING-NOT STARTED":
      return "Picking has not started.";
    case "PICKING-STARTED":
      return "Items are being picked.";
    case "PICKING-PAUSED":
      return "Picking has been paused.";
    case "PICKING-COMPLETED":
      return "All items have been picked, but hasn't been dropped off at the next station.";
    case "PACKING-COMPLETED":
      return "All items have been packed.";
    case "PACKING-NOT STARTED":
      return "Items are at the packing station, but packing hasn't started.";
    case "PACKING-STARTED":
      return "Items are being packed.";
    case "PACKING-PAUSED":
      return "Packing has been paused.";
    case "PREPPING-COMPLETED":
      return "All items have been prepped.";
    case "PREPPING-NOT STARTED":
      return "Items are at the prepping station, but prepping hasn't started.";
    case "PREPPING-STARTED":
      return "Items are being prepped.";
    case "PREPPING-PAUSED":
      return "Prepping has been paused.";
    default:
      return status;
  }
};

const SEARCH_TOOL_TIP_CONTENT = `
  <div>
    Within each batch, the search will look for: 
    <b>Order IDs, Tote</b><br />
    Additionally, within the items 
    contained in each batch, the search will look for:<br />
    <b>SKU, ASIN, Product Name, Name, UPC, LPN, Pallet ID, Lot ID, Bin Location, Serial Number, Tote.</b>
  <div>
`;

const OutboundSummary = ({
  displayBatches,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  sort,
  setSort,
  onChangeSearchKeyword,
  submitFilters,
  setShowFilters,
  filters,
  clearKeyword,
  clearFilters,
  showFilters,
  onChangeFilter,
  handleDateRangeClear,
  users,
  usersMap,
  expandedBatch,
  setExpandedBatch,
  expandBatch,
  customers,
  warehouses,
  setSelectedBatch,
  prioritizeBatch,
  showPickerSelector,
  setShowPickerSelector,
  writable,
  deleteBatch,
  tenant,
  selectedRows,
  selectRow,
  selectAllRows,
  allRowsSelected,
  showOnlySelected,
  setShowOnlySelected,
  clearSelectedBatches,
  downloadBatchesCsv,
  onAssignBatchPicker,
  selectedBatch,
}) => {
  const menuItems = (batch) => {
    const arr = [];

    if (writable) {
      arr.push({
        title: "Re-assign Picker",
        icon: PencilAltIcon,
        onClick: () => {
          setShowPickerSelector(true);
          setSelectedBatch(batch);
        },
      });
      arr.push({
        title: "Prioritize",
        icon: FastForwardIcon,
        onClick: () => {
          prioritizeBatch(batch);
        },
      });
      arr.push({
        title: "Delete",
        icon: TrashIcon,
        onClick: () => {
          deleteBatch(batch);
        },
      });
    }

    return arr;
  };

  const getInitialHeaders = () => {
    const arr = [
      getHeaderObject("Batch ID", "id", false),
      getHeaderObject("Created Date", "createdAt", false),
      getHeaderObject("# orders", "orders", false),
      getHeaderObject("# unique products", "products", false),
      getHeaderObject("Total Qty", "quantity", false),
      getHeaderObject("Tote", "tote"),
      getHeaderObject("Picking", "picking"),
      getHeaderObject("Status", "status"),
    ];

    if (tenant?.typeOfCustomer?.includes("Prep Center")) {
      arr.push(getHeaderObject("Prepping", "prepping"));
    }
    arr.push(getHeaderObject("Packing", "packing"));

    if (isDelmarTenant(tenant?.subdomain)) {
      arr.push(
        getHeaderObject("Multiple Line Item Batch", "multipleLineItemBatch"),
      );
      arr.push(getHeaderObject("Classification", "classification"));
    }

    arr.push(
      getHeaderObject("Client(s)", "customer"),
      getHeaderObject("Warehouse", "warehouse"),
      // getHeaderObject("Status", "status"),
    );

    if (writable) {
      arr.push(getHeaderObject("Action", "action"));
    }

    return arr;
  };
  const [headers, setHeaders] = useState(getInitialHeaders());
  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      CONSOLIDATED_OUTBOUND_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  const calculateOverallStatus = (batch) => {
    console.log(`BATCHY`, batch);
    if (batch.status === "UNPROCESSED") {
      return `Picking-Not Started`;
    }

    if (batch.status === "STARTED") {
      return `Picking-Started`;
    }

    if (batch.status === "COMPLETED") {
      return `Picking-Completed`;
    }

    if (!batch.linkedBatches?.length) {
      if (
        batch.attributes?.humanReadableOrderIds?.findIndex((i) =>
          i.includes("FBA"),
        ) > -1
      ) {
        /* NOTE: If the batch has an FBA order ID, it is pending prepping. 
           This is loose logic so this is temporary until we have the data at the batch level.
        */
        return `Prepping-Not Started`;
      }

      return `Packing-Not Started`;
    }

    const inProgressBatch = batch.linkedBatches?.find(
      (i) => i.status === "STARTED",
    );

    if (inProgressBatch) {
      return `${inProgressBatch.typeOfBatch}-Started`;
    }

    const pausedBatch = batch.linkedBatches?.find((i) => i.status === "PAUSED");

    if (pausedBatch) {
      return `${pausedBatch.typeOfBatch}-Paused`;
    }

    const completedBatch = batch.linkedBatches?.find(
      (i) => i.status === "COMPLETED",
    );

    if (completedBatch) {
      return `${completedBatch.typeOfBatch}-Completed`;
    }

    const confirmedDropoffPreppingBatch = batch.linkedBatches?.find(
      (i) => i.status === "CONFIRMED_DROPOFF" && i.typeOfBatch === "PREPPING",
    );

    if (
      confirmedDropoffPreppingBatch &&
      !batch.linkedBatches?.find((i) => i.typeOfBatch === "PACKING")
    ) {
      return `Packing-Not Started`;
    }

    return `Packing-Completed`;
  };

  return (
    <>
      {filters?.startEndDate && (
        <div className="flex items-end justify-end text-2xl font-bold text-primaryAccent">
          Date Range: {filters.startEndDate[0] || "Beginning"} ~{" "}
          {filters.startEndDate[1] || "Now"}
        </div>
      )}
      <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
        {(customers?.length > 1 || warehouses?.length > 1) && (
          <QuickFilters
            warehouseFilterName={"warehouses"}
            customerFilterName={"customers"}
            customers={customers}
            warehouses={warehouses}
            filters={filters}
            onChangeFilter={onChangeFilter}
          />
        )}

        <SearchSortFilter
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          clearFilters={clearFilters}
          choices={choices}
          headers={headers}
          setShowHeaders={setShowHeaders}
          tableName={CONSOLIDATED_OUTBOUND_TABLE_NAME}
          searchTooltip={SEARCH_TOOL_TIP_CONTENT}
        />

        {(selectedRows.length > 0 || showOnlySelected) && (
          <div className="flex items-center space-x-4 px-2">
            <div>
              <Toggle
                enabled={showOnlySelected}
                setEnabled={(key) => setShowOnlySelected(key)}
              />
            </div>
            <div className="text-2xl">
              Show only selected batches(
              {selectedRows.length})
            </div>
            <AddButton
              text={"Clear"}
              onClick={() => {
                clearSelectedBatches();
              }}
            />
            <AddButton
              text={"Export to CSV"}
              onClick={() => {
                downloadBatchesCsv();
              }}
            />
          </div>
        )}
        <CustomTableWithTheme>
          <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
            <tr className="border-left font-montserrat text-textWhite">
              <th
                scope="col"
                className="px-2 py-3 pl-6 text-left font-medium tracking-wider">
                <Checkbox
                  role="checkbox"
                  onChange={selectAllRows}
                  name="select All Rows"
                  value={"Select All Rows"}
                  checked={allRowsSelected}
                />
              </th>
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {displayBatches.length === 0 ? (
              <tr className="bg-white">
                {headers.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <td
                      className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                      key={headerIdx}>
                      {noValuesText}
                    </td>
                  ) : (
                    <td
                      className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {displayBatches.map((batch, rowIndex) => (
              <tr
                key={batch.id}
                className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`rounded-bl rounded-tl border-l-8 p-5 text-left font-semibold tracking-wider text-primaryAccent ${
                    rowIndex % 2 === 0
                      ? "border-F4C261"
                      : "border-primaryAccent"
                  }`}>
                  <Checkbox
                    role="checkbox"
                    onChange={(_) => {
                      selectRow(rowIndex);
                    }}
                    name="batch"
                    value={batch.id}
                    checked={selectedRows.includes(batch.id)}
                  />
                </td>
                {showHeaders.map((header, columnIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    ["id"],
                    columnIndex + 1,
                    rowIndex,
                  );
                  if (value === "id") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() => expandBatch(batch.id)}>
                        {batch.id}
                      </td>
                    );
                  }
                  if (value === "createdAt") {
                    return (
                      <td className={cellStyle}>
                        {moment(batch.createdAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                    );
                  }
                  if (value == "user") {
                    return (
                      <td className={cellStyle}>
                        {usersMap[batch.user]?.name || "-"}
                      </td>
                    );
                  }
                  if (value == "assignedTime") {
                    return (
                      <td className={cellStyle}>
                        {moment(
                          batch.assignedTime
                            ? batch.assignedTime
                            : batch.createdAt,
                        ).format("MMM Do YYYY, h:mm a")}
                      </td>
                    );
                  }
                  if (value == "firstCompletedTime") {
                    return (
                      <td className={cellStyle}>
                        {batch.attributes?.firstCompletedTime &&
                          moment(batch.attributes.firstCompletedTime).format(
                            "MMM Do YYYY, h:mm a",
                          )}
                      </td>
                    );
                  }
                  if (value == "lastCompletedTime") {
                    return (
                      <td className={cellStyle}>
                        {batch.attributes?.lastCompletedTime &&
                          moment(batch.attributes.lastCompletedTime).format(
                            "MMM Do YYYY, h:mm a",
                          )}
                      </td>
                    );
                  }
                  if (value === "quantity") {
                    return <td className={cellStyle}>{batch.itemsLength}</td>;
                  }
                  if (value === "products") {
                    return (
                      <td className={cellStyle}>
                        {batch.numberOfUniqueProducts}
                      </td>
                    );
                  }
                  if (value === "orders") {
                    return (
                      <td className={cellStyle}>
                        {batch.attributes?.humanReadableOrderIds?.length > 0 ? (
                          <ExpandedMultipleItem
                            items={batch.attributes?.humanReadableOrderIds}
                            title={`${batch.attributes?.humanReadableOrderIds?.length}`}
                            expandThreshold={0}
                          />
                        ) : (
                          `-`
                        )}
                      </td>
                    );
                  }
                  if (value == "completion") {
                    return (
                      <td className={cellStyle}>
                        {batch.completion && `${batch.completion}%`}
                      </td>
                    );
                  }
                  if (value === "tote") {
                    return <td className={cellStyle}>{batch.tote}</td>;
                  }
                  if (value === "warehouse") {
                    return (
                      <td className={cellStyle}>
                        {warehouses?.find((i) =>
                          batch?.warehouse?.includes(i.id),
                        )?.name || `-`}
                      </td>
                    );
                  }
                  if (value === "multipleLineItemBatch") {
                    return (
                      <td className={cellStyle}>
                        {batch.attributes?.multipleLineItemBatch ? "Yes" : "No"}
                      </td>
                    );
                  }
                  if (value === "picking") {
                    return (
                      <td className={cellStyle}>
                        <EyeIcon
                          className="cursor-pointer text-xs text-gray-600"
                          width={35}
                          onClick={() => expandBatch(batch.id)}
                        />
                      </td>
                    );
                  }

                  if (value === "classification") {
                    return (
                      <td className={cellStyle}>
                        {batch.classification || "-"}
                      </td>
                    );
                  }

                  if (value === "prepping") {
                    const linkedBatch = batch.linkedBatches?.find(
                      (i) => i.typeOfBatch === "PREPPING",
                    );
                    return (
                      <td className={cellStyle}>
                        {linkedBatch ? (
                          <EyeIcon
                            className="cursor-pointer text-xs text-gray-600"
                            width={35}
                            onClick={() => expandBatch(linkedBatch.id)}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                    );
                  }
                  if (value === "packing") {
                    const linkedBatch = batch.linkedBatches?.find(
                      (i) => i.typeOfBatch === "PACKING",
                    );
                    return (
                      <td className={cellStyle}>
                        {linkedBatch ? (
                          <EyeIcon
                            className="cursor-pointer text-xs text-gray-600"
                            width={35}
                            onClick={() => expandBatch(linkedBatch.id)}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                    );
                  }
                  if (value === "customer") {
                    return (
                      <td className={cellStyle}>
                        {customers?.filter((i) =>
                          batch?.customer?.includes(i.id),
                        )?.length === 1 ? (
                          customers?.find((i) =>
                            batch?.customer?.includes(i.id),
                          )?.name
                        ) : (
                          <ExpandedMultipleItem
                            items={customers
                              ?.filter((i) => batch?.customer?.includes(i.id))
                              .map((customer) => customer.name)}
                          />
                        )}
                      </td>
                    );
                  }
                  if (value === "requestedDropoffPackingStation") {
                    return (
                      <td className={cellStyle}>
                        {batch.attributes?.requestedDropoffPackingStation ||
                          "-"}
                      </td>
                    );
                  }
                  if (value === "dropoffStation") {
                    return (
                      <td className={cellStyle}>
                        {batch.attributes?.dropoffStation || "-"}
                      </td>
                    );
                  }
                  if (value === "status") {
                    return (
                      <td className={cellStyle}>
                        <Tooltip
                          placement="top"
                          id={calculateOverallStatus(batch)}
                          className="text-wrap">
                          {statusTooltipContent(calculateOverallStatus(batch))}
                        </Tooltip>
                        <div data-for={calculateOverallStatus(batch)} data-tip>
                          {renderStatus(calculateOverallStatus(batch))}
                        </div>
                      </td>
                    );
                  }
                  if (writable && value === "action") {
                    return (
                      <td className={cellStyle}>
                        <Menu as="div" className="relative text-left">
                          <Menu.Button>
                            <DotsVerticalIcon className="text-md h-8 w-8 rounded bg-E1D3B8 p-2 font-montserrat text-5F666B" />
                          </Menu.Button>
                          <Menu.Items
                            className={`${
                              columnIndex > showHeaders.length / 2 && "right-0"
                            } absolute z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                            {menuItems(batch).map((menuItem, idx) => (
                              <Menu.Item key={idx}>
                                {({ active }) => (
                                  <button
                                    className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                                    onClick={menuItem["onClick"]}>
                                    {menuItem.icon && (
                                      <menuItem.icon className="h-8 w-8 pr-2" />
                                    )}
                                    {menuItem.title}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </CustomTableWithTheme>
      </div>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
      {expandedBatch && (
        <ExpandedBatch
          batchItems={expandedBatch.items}
          negativeAction={() => setExpandedBatch(null)}
          tableName={"picking.orderDetails"}
          orderIds={expandedBatch.orderIds}
          batch={expandedBatch}
          usersMap={usersMap}
        />
      )}
      {showFilters && (
        <BatchFilters
          dateRangeLabel="Select Summary Date Range"
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          showDateRangePicker={true}
          handleDateRangeClear={handleDateRangeClear}
          users={
            users
              ? users.filter(
                  (item) =>
                    item.hopstackModules &&
                    item.hopstackModules.includes("Picking"),
                )
              : []
          }
          customers={customers}
          warehouses={warehouses}
        />
      )}
      {selectedBatch && showPickerSelector && (
        <AssignBatchPickerForm
          onClose={() => setSelectedBatch(null)}
          title={"Assign Picker"}
          onSubmit={onAssignBatchPicker}
          selectedBatch={selectedBatch}
          setSelectedBatch={setSelectedBatch}
          users={
            users && users.length
              ? users.filter(
                  (item) =>
                    (item.warehouses &&
                      item.warehouses.includes(selectedBatch.warehouse[0])) ||
                    item.warehouses === null ||
                    item.warehouses.length === 0,
                )
              : []
          }
        />
      )}
    </>
  );
};

export default withOutboundSummaryLogic(OutboundSummary);
