import moment from "moment-timezone";
import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, TrashIcon, QrcodeIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { useState } from "react";
import Modal from "#components/utils/Modal";
import CustomTableWithTheme from "../common/CustomTableWithTheme";

const StockTransferList = ({
  stockTransfers,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  noValuesText,
  warehouses,
  executePlan,
  executePlanClicked,
  planToActivate,
  setPlanToActivate,
  expandStockTransfer,
}) => {
  const [confirm, setConfirm] = useState(false);

  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <CustomTableWithTheme>
        <thead className="rounded-full bg-primaryAccent p-4">
          <tr className="font-montserratborder-left text-textWhite">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {stockTransfers.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="rounded-bl rounded-tl border-l-8 border-F4C261 p-5 text-left font-semibold tracking-wider text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {stockTransfers.map((stockTransfer, index) => (
            <tr
              key={stockTransfer.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                onClick={() => expandStockTransfer(stockTransfer.id)}
                className={`cursor-pointer rounded-bl rounded-tl border-l-8 p-5 text-left font-semibold tracking-wider text-primaryAccent hover:underline ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {stockTransfer.sku}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {moment(stockTransfer.createdAt).format("MMM Do, YYYY")}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {stockTransfer.status}
              </td>
              <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                <div className="flex items-center space-x-8">
                  <div className="group relative">
                    {!(
                      stockTransfer.status == "ACTIVE" ||
                      stockTransfer.status === "COMPLETED"
                    ) && (
                      <span className="invisible absolute left-[-120px] min-w-max rounded-md bg-gray-900 p-2 text-center text-xs font-bold text-white shadow-md transition-all duration-100 group-hover:visible">
                        Initiate transfer
                      </span>
                    )}

                    <TableFieldButton
                      onClick={() => {
                        setConfirm(true);
                        executePlanClicked(stockTransfer);
                      }}
                      disabled={
                        stockTransfer.status === "ACTIVE" ||
                        stockTransfer.status === "COMPLETED"
                      }
                      text={<QrcodeIcon className="h-6 w-6" />}
                    />
                  </div>
                  <TableFieldButton
                    onClick={() => editButtonClicked(stockTransfer)}
                    disabled={stockTransfer.status != "UNPROCESSED"}
                    text={<PencilIcon className="h-6 w-6" />}
                  />
                  <TableFieldButton
                    text={<TrashIcon className="h-6 w-6" />}
                    disabled={stockTransfer.status != "UNPROCESSED"}
                    onClick={() => deleteButtonClicked(stockTransfer.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTableWithTheme>
      {confirm && planToActivate && (
        <Modal
          title={"Are you sure?"}
          onClose={() => {
            {
              setConfirm(false);
              setPlanToActivate();
            }
          }}
          negativeAction={() => setConfirm(false)}
          positiveAction={executePlan}
          positiveText="Transfer">
          <div className="space-y-4">
            <div>
              <a>Do you want to initiate bin to bin transfer?</a>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default StockTransferList;
