export const pluralize = (count, noun, suffix = "s") => {
  if (count === 1) return `${count} ${noun}`;
  return `${count} ${noun}${suffix}`;
};

export const handlePositiveValue = (value, type = "int") => {
  if (isNaN(value) || parseFloat(value) < 0) {
    return 0;
  }

  return type === "int" ? parseInt(value) : parseFloat(value);
};

export const getBulkUploadTemplateURL = (fileName) => {
  let templateEnvironmentBucket = "uat";
  if (process.env.NODE_ENV === "uat") {
    templateEnvironmentBucket = "uat";
  } else if (process.env.NODE_ENV === "development") {
    templateEnvironmentBucket = "uat";
  } else if (process.env.NODE_ENV === "production") {
    templateEnvironmentBucket = "prod";
  }
  return `https://templates-onboarding.s3.amazonaws.com/${templateEnvironmentBucket}/${fileName}.csv`;
};
