/**
 * created by Omkar Gavade
 * 10th July 2024
 *
 * This is a new confirmation dialog component for the application.
 */

import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { XIcon } from "@heroicons/react/outline";
import { Transition, Dialog } from "@headlessui/react";
import { AppStateContext } from "../../contexts/appState";
import PrimaryButton from "./PrimaryButton";

const NewConfirmationDialog = ({
  newConfirmation: {
    isOpen,
    title,
    content,
    positiveAction,
    negativeAction,
    negativeText = "Cancel",
    positiveText = "Confirm",
    showFooterActions = true,
  },
  minWidth = "640px",
  maxWidth = "1280px",
}) => {
  const appState = useContext(AppStateContext);

  const handleNegativeAction = () => {
    negativeAction();
    appState.hideNewConfirmation();
  };

  const handlePositiveAction = () => {
    positiveAction();
    appState.hideNewConfirmation();
  };

  if (!isOpen) return null;

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="userFormModal fixed inset-0 z-30 overflow-y-auto"
        initialFocus={null}
        onClose={handleNegativeAction}
        open={true}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          </Transition.Child>
          <div className="flex min-h-screen items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-0"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div
                className={`mx-auto inline-block transform overflow-visible rounded-3xl border-blue-500 bg-white p-8 text-left align-middle shadow-xl transition-all`}
                style={{ minWidth, maxWidth }}>
                <div className="flex items-center justify-between pb-4 font-inter">
                  <span className="font-inter text-2xl font-bold text-[#454A4F]">
                    {title || "Confirmation"}
                  </span>
                  <XIcon
                    className="h-6 w-6 cursor-pointer"
                    onClick={handleNegativeAction}
                  />
                </div>
                <div className="max-w-content flex flex-col gap-8 space-y-4">
                  {content}
                  {showFooterActions && (
                    <div className="flex flex-1 items-end justify-end space-x-2">
                      <PrimaryButton
                        height="3rem"
                        width="7rem"
                        className="mt-2 text-base font-medium"
                        onClick={handleNegativeAction}>
                        {negativeText}
                      </PrimaryButton>
                      <PrimaryButton
                        height="3rem"
                        minWidth="7rem"
                        maxWidth="15rem"
                        variant="primary"
                        className="mt-2 text-base font-medium"
                        onClick={handlePositiveAction}>
                        {positiveText}
                      </PrimaryButton>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

NewConfirmationDialog.propTypes = {
  newConfirmation: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    content: PropTypes.node,
    positiveAction: PropTypes.func.isRequired,
    negativeAction: PropTypes.func.isRequired,
    negativeText: PropTypes.string,
    positiveText: PropTypes.string,
    showFooterActions: PropTypes.bool,
  }).isRequired,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default NewConfirmationDialog;
