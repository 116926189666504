import _ from "lodash";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";
import axios from "axios";

export const printCanvasLabelRemote = async (
  payload,
  printer = null,
  appState = null,
) => {
  try {
    appState?.setLoading();
    const response = await axios.post(`http://127.0.0.1:5100/getLabel`, {
      ...payload,
    });

    const { buffer } = response.data;

    console.log(`Buffer ${buffer}`);

    const cpj = new JSPM.ClientPrintJob();
    let myPrinter;
    if (printer) {
      myPrinter = new JSPM.InstalledPrinter(printer);
    } else {
      myPrinter = new JSPM.DefaultPrinter();
    }

    if (!myPrinter) {
      myPrinter = new JSPM.DefaultPrinter();
    }
    myPrinter.duplex = JSPM.DuplexMode.Simplex;

    cpj.clientPrinter = myPrinter;
    const myFile = new JSPM.PrintFilePDF(
      buffer,
      JSPM.FileSourceType.Base64,
      `${payload.code}.pdf`,
      1,
    );
    for (let i = 0; i < payload.quantity; i++) {
      cpj.files.push(myFile);
    }
    cpj.sendToClient();

    console.log("done");
    appState?.removeLoading();

    return;
  } catch (err) {
    appState?.removeLoading();
    throw err;
  }
};
