import { useState, useEffect } from "react";
import SlideOverModal from "#components/common/SlideOverModal";
import CreateScheduledReport from "#components/reports/CreateScheduledReport";
import ViewEditScheduledReport from "#components/reports/ViewEditScheduledReport";

const ScheduledReportsForm = ({
  reportsMetadata,
  selectedReport,
  setSelectedReport,
  reportModalModesEnum,
  openReportMode,
  setOpenReportMode,
  onClose,
  onChange,
  onChangeDropdown,
  warehouses,
  customers,
  onChangeDropdownMulti,
  onChangeFrequency,
  parseCronExpression,
  onSubmit,
  setReportType,
  allUsers,
  copySelectedReport,
  reportTypeModesEnum,
  openScheduledReportCancelConfirmation,
}) => {
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [showSubmit, setShowSubmit] = useState(false);

  useEffect(() => {
    const formedSteps = getFormedSteps();
    setSteps(formedSteps);
    setSelectedStep(formedSteps[0]);
  }, []);

  const SubTitles = ({}) => {
    if (openReportMode === reportModalModesEnum.CREATE) {
      return (
        <span className="text-base font-light text-gray-400">
          Set Up and Schedule Your Report
        </span>
      );
    }
    if (openReportMode === reportModalModesEnum.VIEW) {
      return (
        <div className="flex flex-col space-y-1">
          <span className="text-base font-light text-gray-400">
            This modal gives you an overview of the selected report, showing all
            filters and scheduling details. To make any changes, click the edit
            icons
          </span>
          <span className="font-semibold text-primaryAccent">
            &nbsp;Please note that Report Type and Date Created cannot be
            edited.
          </span>
        </div>
      );
    }
    return null;
  };

  const Titles = ({}) => {
    if (openReportMode === reportModalModesEnum.CREATE) {
      return (
        <span className="text-2xl font-semibold">
          Schedule Report for Later
        </span>
      );
    }
    if (openReportMode === reportModalModesEnum.VIEW) {
      return <span className="text-2xl font-semibold">Schedule Details</span>;
    }
    if (openReportMode === reportModalModesEnum.RENAME) {
      return <span className="text-2xl font-semibold">Rename Report</span>;
    }
    if (openReportMode === reportModalModesEnum.PREVIEW) {
      return <span className="text-2xl font-semibold">Report Preview</span>;
    }
    return null;
  };

  return (
    <SlideOverModal
      open={selectedReport || reportModalModesEnum ? true : false}
      width="w-3/5"
      onClose={() => {
        setSelectedReport(null);
        setOpenReportMode(null);
      }}
      title={<Titles />}
      subtitle={<SubTitles />}
      staticBackdrop={true}
      overflow={true}>
      {/* Created Schedule Report*/}
      {openReportMode === reportModalModesEnum.CREATE ? (
        <CreateScheduledReport
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          steps={steps}
          setSteps={setSteps}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
          setOpenReportMode={setOpenReportMode}
          showSubmit={showSubmit}
          setShowSubmit={setShowSubmit}
          onClose={onClose}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          warehouses={warehouses}
          customers={customers}
          reportsMetadata={reportsMetadata}
          onChangeDropdownMulti={onChangeDropdownMulti}
          onChangeFrequency={onChangeFrequency}
          parseCronExpression={parseCronExpression}
          onSubmit={onSubmit}
          setReportType={setReportType}
          allUsers={allUsers}
        />
      ) : null}

      {/* View/Edit Schedule Report*/}
      {openReportMode === reportModalModesEnum.VIEW ? (
        <ViewEditScheduledReport
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
          setOpenReportMode={setOpenReportMode}
          onClose={onClose}
          onChange={onChange}
          warehouses={warehouses}
          customers={customers}
          onChangeFrequency={onChangeFrequency}
          parseCronExpression={parseCronExpression}
          onSubmit={onSubmit}
          setReportType={setReportType}
          allUsers={allUsers}
          copySelectedReport={copySelectedReport}
          reportTypeModesEnum={reportTypeModesEnum}
          reportModalModesEnum={reportModalModesEnum}
          openScheduledReportCancelConfirmation={
            openScheduledReportCancelConfirmation
          }
        />
      ) : null}
    </SlideOverModal>
  );
};

const getFormedSteps = () => {
  let formedSteps = [];

  formedSteps.push(
    {
      id: "01",
      name: "Report Type",
      href: "#",
      status: "current",
      visible: true,
    },
    {
      id: "02",
      name: "Filters",
      href: "#",
      status: "upcoming",
      visible: true,
    },
    {
      id: "03",
      name: "Schedule",
      href: "#",
      status: "upcoming",
      visible: true,
    },
    {
      id: "04",
      name: "Recipients",
      href: "#",
      status: "upcoming",
      visible: true,
    },
  );

  formedSteps[0] = {
    ...formedSteps[0],
    status: "current",
  };

  return formedSteps;
};

export default ScheduledReportsForm;
