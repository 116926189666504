import { useState, useEffect } from "react";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import AddButton from "#components/utils/AddButton";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import Toggle from "#components/utils/Toggle";
import { useCodeSuggestion } from "../../hooks/useCodeSuggestion";
import { Tooltip } from "antd";
import { CheckIcon } from "@heroicons/react/solid";
import Autocomplete from "#components/utils/Autocomplete";
import { Country, State } from "country-state-city";
import Dropdown from "#components/utils/Dropdown";

const VendorForm = ({
  onClose,
  title,
  onChange,
  selectedVendor,
  onSubmit,
  onChangeDropdown,
  availableInventory,
  onChangeInventorySearch,
  onChangeMultiSelect,
  addAddress,
  addNewEmail,
  onChangeAddress,
  onChangeEmails,
  removeAddress,
  removeEmails,
  vendors,
  setSelectedVendor,
}) => {
  const [selectedCountries, setSelectedCountries] = useState({});
  const [selectedStates, setSelectedStates] = useState({});
  const [filteredStatesByAddress, setFilteredStatesByAddress] = useState({});
  const [selectedStep, setSelectedStep] = useState(null);
  const [codesList, setCodesList] = useState([]);
  const {
    code,
    error,
    usingGeneratedCode,
    setUsingGeneratedCode,
    handleNameChange,
  } = useCodeSuggestion();

  useEffect(() => {
    setCodesList(vendors.map((vendor) => vendor.code));
  }, [vendors]);

  useEffect(() => {
    if (usingGeneratedCode) {
      setSelectedVendor({ ...selectedVendor, code: error?.error ? "" : code });
    }
  }, [code]);

  const { name, id, addresses } = selectedVendor;
  useEffect(() => {
    if (name === "") {
      setSelectedVendor({ ...selectedVendor, code: "" });
    }
    if (id) {
      setUsingGeneratedCode(false);
    }
  }, [name, id]);

  useEffect(() => {
    if (
      selectedVendor &&
      selectedVendor.addresses &&
      selectedVendor.addresses.length > 0
    ) {
      const newSelectedCountries = {};
      const newSelectedStates = {};
      const newFilteredStates = {};

      selectedVendor.addresses.forEach((item, index) => {
        newSelectedCountries[index] = item.country || "";
        newSelectedStates[index] = item.state || "";

        if (item.country) {
          newFilteredStates[index] = State.getStatesOfCountry(item.country);
        } else {
          newFilteredStates[index] = [];
        }
      });

      setSelectedCountries(newSelectedCountries);
      setSelectedStates(newSelectedStates);
      setFilteredStatesByAddress(newFilteredStates);
    }
  }, [addresses]);

  const handleCountryChange = (index, e) => {
    const newSelectedCountries = { ...selectedCountries, [index]: e };
    const newFilteredStates = {
      ...filteredStatesByAddress,
      [index]: State.getStatesOfCountry(e),
    };

    setSelectedCountries(newSelectedCountries);
    setSelectedStates({ ...selectedStates, [index]: "" });
    setFilteredStatesByAddress(newFilteredStates);

    onChangeAddress(
      {
        target: {
          name: "country",
          value: e,
        },
      },
      index,
    );
  };

  const handleStateChange = (index, e) => {
    setSelectedStates({ ...selectedStates, [index]: e });
    onChangeAddress(
      {
        target: {
          name: "state",
          value: e,
        },
      },
      index,
    );
  };

  const handleOnChangeForName = (e) => {
    !selectedVendor?.id && handleNameChange(e.target.value, codesList);
    onChange(e);
  };
  const handleOnChangeForCode = (e) => {
    setUsingGeneratedCode(false);
    onChange(e);
  };

  return (
    <Modal
      title={title}
      negativeAction={onClose}
      id="userFormModal"
      onClose={() => {}}
      minWidth="960px">
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Vendor Details
          </span>
        </div>
        <>
          <div>
            <TextField
              type="text"
              id="name"
              label="Name"
              placeholder=" "
              onChange={handleOnChangeForName}
              value={selectedVendor.name}
              name="name"
            />
          </div>
          <div>
            <Tooltip title={"0-9 digits, A-Z letters are allowed."}>
              <TextField
                type="text"
                id="code"
                label="Vendor Code"
                placeholder=" "
                name="code"
                onChange={handleOnChangeForCode}
                value={
                  usingGeneratedCode
                    ? error?.error
                      ? ""
                      : code
                    : selectedVendor.code?.toUpperCase()
                }
              />
            </Tooltip>
          </div>
          <div>
            <h3 className="text-base font-semibold text-gray-500">
              Vendor Classifications
            </h3>
            <Dropdown
              placeholder={"Select Classification"}
              list={[{ name: "Internal" }, { name: "Third Party" }]}
              labelKey="name"
              valueKey="name"
              name="classification"
              setSelected={(value) => {
                onChangeDropdown("classification", value);
              }}
              selectedValue={selectedVendor.classification}
            />
          </div>
          {usingGeneratedCode && !(error?.error || code == "") && (
            <div className="text-sm italic">
              *This is a generated code. You can still edit it.
            </div>
          )}

          {selectedVendor &&
            selectedVendor.emails &&
            selectedVendor.emails.map((email, index) => {
              return (
                <>
                  <div id="addEmails">
                    <label className="flex flex-row text-left">
                      <span className="font-montserrat text-lg font-medium text-454A4F">
                        Email-{index + 1}{" "}
                        {selectedVendor.emails.length === 1 && (
                          <span className="text-red-500">*</span>
                        )}
                      </span>
                      {selectedVendor.emails?.length > 1 && (
                        <span
                          className="cursor-pointer pl-2 text-right text-lg font-bold text-red-500"
                          onClick={() => removeEmails(index)}>
                          Remove
                        </span>
                      )}
                      <br />
                    </label>
                  </div>
                  <div>
                    <TextField
                      type="email"
                      id="email"
                      label="Email"
                      placeholder=" "
                      onChange={(e) => onChangeEmails(e, index)}
                      value={email}
                      name="email"
                    />
                  </div>
                </>
              );
            })}

          <AddButton text="Add Email" onClick={() => addNewEmail([""])} />

          <div>
            <TextField
              type="text"
              id="phone"
              label="Phone No."
              placeholder=" "
              onChange={onChange}
              value={
                selectedVendor.phoneNumbers
                  ? selectedVendor.phoneNumbers[0]
                  : ""
              }
              name="phoneNumbers"
            />
          </div>

          {selectedVendor &&
            selectedVendor.addresses &&
            selectedVendor?.addresses?.map((item, index) => (
              <>
                <div id="addAddresses">
                  <label className="mb-2 flex flex-row text-left">
                    <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                      Address - {index + 1}
                    </span>

                    <span
                      className="cursor-pointer pl-2 text-right text-lg font-bold text-red-500"
                      onClick={() => removeAddress(index)}>
                      Remove
                    </span>
                    <br />
                  </label>
                </div>
                <div>
                  <TextField
                    type="text"
                    id="line1"
                    label="Line 1"
                    placeholder=" "
                    onChange={(e) => onChangeAddress(e, index)}
                    value={item.line1}
                    name="line1"
                  />
                </div>

                <div>
                  <TextField
                    type="text"
                    id="line2"
                    label="Line 2"
                    placeholder=" "
                    onChange={(e) => onChangeAddress(e, index)}
                    value={item.line2}
                    name="line2"
                  />
                </div>

                <div>
                  <TextField
                    type="text"
                    id="city"
                    label="City"
                    placeholder=" "
                    onChange={(e) => onChangeAddress(e, index)}
                    value={item.city}
                    name="city"
                  />
                </div>

                <div>
                  <Tooltip
                    title={
                      !selectedCountries[index]
                        ? "Please select a country first"
                        : ""
                    }
                    placement="top">
                    <div style={{ display: "inline-block", width: "100%" }}>
                      <Autocomplete
                        options={filteredStatesByAddress[index] || []}
                        labelKey="name"
                        valueKey="isoCode"
                        onChange={(e) => handleStateChange(index, e)}
                        value={item.state}
                        placeholder="Select a State / Province"
                        id="state"
                        //disabled={!selectedCountries[index]}
                      />
                    </div>
                  </Tooltip>
                </div>

                <div>
                  <TextField
                    type="text"
                    id="zipCode"
                    label="Zip Code"
                    placeholder=" "
                    onChange={(e) => onChangeAddress(e, index)}
                    value={item.zip}
                    name="zip"
                  />
                </div>

                <div>
                  <Autocomplete
                    options={Country.getAllCountries()}
                    labelKey="name"
                    valueKey="isoCode"
                    onChange={(e) => handleCountryChange(index, e)}
                    value={item.country}
                    placeholder="Select a Country"
                    id="country"
                  />
                </div>
              </>
            ))}

          <AddButton
            text="Add Address"
            onClick={() =>
              addAddress([
                {
                  line1: "",
                  line2: "",
                  city: "",
                  state: "",
                  zip: "",
                },
              ])
            }
          />

          <div>
            <div>Active</div>
            <Toggle
              setEnabled={() =>
                onChange({
                  target: {
                    name: "active",
                    value: !selectedVendor.active,
                  },
                })
              }
              enabled={selectedVendor.active}
            />
          </div>
        </>
      </div>
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination">
        <div className="flex flex-1 justify-between sm:justify-end">
          <div className="flex flex-1 justify-between space-x-2 sm:justify-end">
            <div
              onClick={onClose}
              className="text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded-md border bg-red-600 px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 hover:bg-red-700 focus:outline-none focus:ring-0">
              Cancel
            </div>
            <div
              onClick={onSubmit}
              className="text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded-md border bg-primaryAccent px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0">
              Submit
            </div>
          </div>
        </div>
      </nav>
    </Modal>
  );
};

export default VendorForm;
