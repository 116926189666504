import cellStyleForTable from "#components/common/CellStyleForTable";
import PageTitle from "#components/utils/PageTitle";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import { getHeaderObject } from "#utils/getHeaderObject";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
} from "@heroicons/react/solid";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import Modal from "#components/utils/Modal";
import { useQuery } from "#hooks/useQuery";
import { GET_ALL_BATCHES } from "#queries/index";
import { GET_BUNDLE_PUTAWAY_LIST_VIEW } from "#mutations/index";
import { SCAN_BUNDLE_PUTAWAY_ITEM } from "#mutations";
import { GET_BUNDLE_PUTAWAY_TASK } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { EntityContext } from "#contexts/entity";
import CustomSelectDropDown from "#components/common/CustomSelectDropDown";
import { AppStateContext } from "#contexts/appState";

const ALERT_TIMEOUT_IN_MS = 5000;

const typeList = [
  {
    label: "ALL",
    value: "",
  },
  {
    label: "SKU",
    value: "sku",
  },
  {
    label: "LPN",
    value: "nestedFormFactorId",
  },
  {
    label: "PALLET",
    value: "palletId",
  },
  {
    label: "TOTE",
    value: "tote",
  },
];

const renderSort = (header, sort) => {
  if (!header || !header.sortable) {
    return null;
  }

  if (sort && sort.replace("-", "") === header.correspondingValue) {
    if (sort.indexOf("-") !== -1) {
      return <ChevronDownIcon className="h-6 w-6 text-green-400" />;
    }

    return <ChevronUpIcon className="h-6 w-6 text-green-400" />;
  }
  return <ChevronUpIcon className="h-6 w-6 text-gray-400" />;
};

const ListView = ({
  customer,
  warehouse,
  setCurrentSku,
  currentTask,
  scanBarcode,
  singlePutawayItem,
  putawayAvailableQty,
  setPutawayAvailableQty,
}) => {
  const batchesQuery = useQuery(GET_ALL_BATCHES);
  const bundlePutawayListQuery = useQuery(GET_BUNDLE_PUTAWAY_LIST_VIEW);
  const entity = useContext(EntityContext);
  const appState = useContext(AppStateContext);
  const scanBundlePutawayItemQuery = useQuery(SCAN_BUNDLE_PUTAWAY_ITEM);
  const currentBundlePutawayTaskQuery = useQuery(GET_BUNDLE_PUTAWAY_TASK);

  const [bundlePutaway, setBundlePutaway] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [bundlePutawayPageNo, setBundlePutawayPageNo] = useState(1);
  const [selectedType, setSelectedType] = useState(typeList[0]);

  const sort = entity.sort;

  const headers = (() => {
    const returnHeaders = [];

    returnHeaders.push(getHeaderObject("Sku", "sku", false));
    returnHeaders.push(getHeaderObject("Product Name", "productName", false));
    returnHeaders.push(
      getHeaderObject("Putawayed Qty", "totalConsumedQuantity", false),
    );
    returnHeaders.push(
      getHeaderObject("Total Qty", "totalScannedQuantity", false),
    );
    returnHeaders.push(getHeaderObject("Form Factor", "formFactor", false));
    returnHeaders.push(getHeaderObject("Asin", "asin", false));
    returnHeaders.push(getHeaderObject("FnSku", "fnSku", false));

    returnHeaders.push(getHeaderObject("Action", "action", false));

    return returnHeaders;
  })();

  const handleBundlePutawayScroll = async (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const threshold = 1;
    if (scrollTop + clientHeight + threshold >= scrollHeight) {
    }
  };

  useEffect(() => {
    (async () => {
      const response = await bundlePutawayListQuery.fetchData({
        warehouse,
        customer,
      });

      if (response?.data?.getBundlePutawayListView) {
        const fetchedBundlePutaway =
          response.data?.getBundlePutawayListView?.entities;

        const consolidatedBundlePutaway = fetchedBundlePutaway.reduce(
          (acc, item) => {
            const sku = item.inventory[0].sku;
            const formFactor = item.inventory[0].formFactor;
            const existingItem = acc.find(
              (i) =>
                i.inventory[0].sku === sku &&
                i.inventory[0].formFactor === formFactor,
            );

            if (existingItem) {
              // If the SKU already exists, sum the relevant fields
              existingItem.totalConsumedQuantity += item.totalConsumedQuantity;
              existingItem.totalScannedQuantity += item.totalScannedQuantity;
            } else {
              // Otherwise, add the new item
              acc.push({ ...item });
            }

            return acc;
          },
          [],
        );

        setBundlePutaway(consolidatedBundlePutaway);
      }
    })();
  }, []);

  useEffect(() => {
    if (bundlePutawayListQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (bundlePutawayListQuery.error) {
      appState.setAlert(
        bundlePutawayListQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [bundlePutawayListQuery.loading, bundlePutawayListQuery.error]);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="max-w-7xl flex-1 items-center justify-center">
      <div className="flex justify-between">
        <PageTitle>Total ({bundlePutaway?.length})</PageTitle>
      </div>

      <div
        className="m-auto mt-10 overflow-auto rounded-md bg-EBEBEB"
        style={{ maxHeight: "500px" }}
        onScroll={handleBundlePutawayScroll}>
        <table className="m-auto -mt-4 divide-y divide-gray-200">
          <thead className="rounded-full p-4">
            <tr className="border-left font-montserrat text-primaryAccent">
              {headers.map((header, headerIdx) => (
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}
                  onClick={() => {
                    if (!header.sortable) {
                      return;
                    }
                  }}>
                  <div className="flex cursor-pointer items-center">
                    {header.name}
                    {renderSort(header, sort)}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!bundlePutawayListQuery.loading && bundlePutaway.length === 0 ? (
              <tr>
                <td className="rounded-br rounded-tr px-1 py-2 pl-2 text-left font-medium tracking-wider text-tableText">
                  No items found
                </td>
              </tr>
            ) : null}

            {bundlePutaway.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"} border-l-8 ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {headers.map((header, colIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    [],
                    colIndex + 1,
                    index,
                    true,
                  );

                  if (value === "sku") {
                    return (
                      <td className={cellStyle}>{item.inventory[0].sku}</td>
                    );
                  }

                  if (value === "productName") {
                    return (
                      <td className={cellStyle}>
                        {item.inventory[0].productName}
                      </td>
                    );
                  }

                  if (value === "totalConsumedQuantity") {
                    return (
                      <td className={cellStyle}>
                        {item.totalConsumedQuantity}
                      </td>
                    );
                  }
                  if (value === "totalScannedQuantity") {
                    return (
                      <td className={cellStyle}>{item.totalScannedQuantity}</td>
                    );
                  }
                  if (value === "formFactor") {
                    return (
                      <td className={cellStyle}>
                        {item.inventory[0].formFactor}
                      </td>
                    );
                  }
                  if (value === "asin") {
                    return (
                      <td className={cellStyle}>
                        {item.inventory[0].asin !== null
                          ? item.inventory[0].asin
                          : "-"}
                      </td>
                    );
                  }

                  if (value === "fnSku") {
                    return (
                      <td className={cellStyle}>
                        {item.inventory[0].fnSku !== null
                          ? item.inventory[0].fnSku
                          : "-"}
                      </td>
                    );
                  }

                  if (value === "action") {
                    return (
                      <td className={cellStyle}>
                        <div
                          className="cursor-pointer rounded-full bg-blue-500 px-2 py-1 text-center text-lg text-white"
                          onClick={() => {
                            singlePutawayItem({
                              barcode: item.inventory[0].sku,
                              formFactor: item.inventory[0].formFactor,
                            });

                            const availableQtyForPutaway =
                              Number(item.totalScannedQuantity) -
                              Number(item.totalConsumedQuantity);

                            setPutawayAvailableQty(availableQtyForPutaway);
                          }}>
                          Work on this
                        </div>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListView;
