import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";

const BatchDateRangeForm = ({
  onSubmit,
  negativeAction,
  batchDateRange,
  onChange,
}) => (
  <Modal
    title={"Run Auto Batching"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Choose Date Range
        </span>
      </div>

      <div className="mt-4 flex space-x-6">
        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Start Date
            </span>
          </label>
          <TextField
            type="date"
            label="Select Date"
            placeholder=" "
            onChange={(e) => onChange("startDate", e.target.value)}
            value={batchDateRange.startDate}
          />
        </div>
        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              End Date
            </span>
          </label>
          <TextField
            type="date"
            label="Select Date"
            placeholder=" "
            onChange={(e) => onChange("endDate", e.target.value)}
            value={batchDateRange.endDate}
          />
        </div>
      </div>
    </div>
  </Modal>
);

export default BatchDateRangeForm;
