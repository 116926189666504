import React from "react";
import ReactDOMServer from "react-dom/server";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import PackingLabel from "../components/common/PackingLabel";

export const downloadPackingLabels = async (
  batch,
  noOfCopies = 1,
  printer = null,
  download = false,
  appState = null,
) => {
  if (!batch || !batch.workingList) {
    return appState && appState.setAlert("Could not print", "error", 5000);
  }

  const htmlString = ReactDOMServer.renderToString(
    <PackingLabel batch={batch} />,
  );

  const tempDiv = document.createElement("div");
  document.body.appendChild(tempDiv);
  tempDiv.innerHTML = htmlString;

  try {
    const rootDiv = tempDiv.querySelector("[data-reactroot]");

    const labelContainers = Array.from(rootDiv.children); // get all child divs inside rootDiv
    const labelCount = labelContainers.length;

    const date = new Date().toISOString().slice(0, 10);
    const pdfFilename = `${batch.attributes?.humanReadableOrderIds?.join(
      "_",
    )}_${date}_Packing_labels.pdf`;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: [4, 6],
    });

    for (let index = 0; index < labelCount; index++) {
      const container = labelContainers[index];
      const dataUrl = await htmlToImage.toPng(container, { quality: 1 });

      pdf.addImage(dataUrl, "PNG", 0, 0, 4, 6);

      if (index !== labelCount - 1) {
        pdf.addPage();
      }
    }

    if (download) {
      pdf.output("dataurlnewwindow");
      pdf.save(pdfFilename);
      return;
    } else {
      const out = pdf.output("datauristring");
      const cpj = new JSPM.ClientPrintJob();
      const myPrinter = printer
        ? new JSPM.InstalledPrinter(printer)
        : new JSPM.DefaultPrinter();

      cpj.clientPrinter = myPrinter;

      for (let i = 0; i < noOfCopies; i++) {
        const myFile = new JSPM.PrintFilePDF(
          out,
          JSPM.FileSourceType.URL,
          pdfFilename,
          1,
        );
        cpj.files.push(myFile);
      }

      cpj.sendToClient();
    }
  } catch (err) {
    appState.setAlert("Could not print", "error", 3000);
    console.log("Error in generating packing label:", err);
    throw err;
  } finally {
    tempDiv.remove();
  }
};
