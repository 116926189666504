import React, { useEffect, useRef } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";

const CasePackInformation = ({
  item,
  itemIdx,
  selectedConsignment,
  setSelectedConsignment,
}) => {
  const onChangeCasePackQuantity = (idx, field, value) => {
    const clonedConsignment = { ...selectedConsignment };
    const foundItem = clonedConsignment?.items.find((item, i) => i === idx);
    if (foundItem) {
      foundItem[field] = value;
      if (field === "quantityInCase") {
        foundItem.numberOfCases = Math.floor(
          getTotalQuantityAvailable(foundItem) / foundItem.quantityInCase,
        );
      }

      clonedConsignment.items[idx] = foundItem;
      setSelectedConsignment(clonedConsignment);
    }
  };

  const getTotalQuantityAvailable = (productItem) =>
    productItem.formFactors?.map((i) => i.quantity)?.reduce((a, b) => a * b, 1);

  const getCasePackItemQuantity = (productItem) =>
    isNaN(productItem.quantityInCase * productItem.numberOfCases) === false &&
    productItem.quantityInCase * productItem.numberOfCases;

  // Note:
  // This logic is made with useRef because whenever there is a change in item's quantity / form factor, we need to recompute and assign the quantityInCase for the item.
  const totalQuantityAvailable = useRef(getTotalQuantityAvailable(item));
  const casePackItemQuantity = getCasePackItemQuantity(item);
  useEffect(() => {
    if (getTotalQuantityAvailable(item) != totalQuantityAvailable.current) {
      totalQuantityAvailable.current = getTotalQuantityAvailable(item);
      onChangeCasePackQuantity(itemIdx, "quantityInCase", item.quantityInCase);
    }
  }, [item]);

  return (
    <div className="px-4 pb-2">
      <p className="text-sm font-medium">Case Pack Info</p>
      <div className="h-94 w-598">
        <div className="flex items-center">
          <div>
            <label className="mb-1 block text-sm font-medium text-lightGray">
              Per Case (Amazon)
            </label>
            <input
              type="number"
              onChange={(e) =>
                onChangeCasePackQuantity(
                  itemIdx,
                  "quantityInCase",
                  parseInt(e.target.value),
                )
              }
              value={parseInt(item.quantityInCase)}
              name="quantity"
              min="0"
              className="h-48 w-171 rounded border border-borderGray p-2"
              disabled={
                selectedConsignment.id &&
                !["UNPROCESSED", "DRAFT"].includes(selectedConsignment.status)
              }
            />
          </div>
          <p className="mt-8 p-4 text-xl">x</p>
          <div>
            <label className="mb-1 block text-sm font-medium text-lightGray">
              No of Case (Amazon)
            </label>
            <div className="h-48 w-171 rounded border border-borderGray bg-backgroundGray p-2">
              <p className="pt-0.5">{item.numberOfCases || ""}</p>
            </div>
          </div>
          <p className="mt-8 p-4 text-xl">=</p>
          <div>
            <label className="mb-1 block text-sm font-medium text-lightGray">
              Total (Amazon)
            </label>
            <div className="flex items-center">
              <div className="h-48 w-171 rounded border border-borderGray bg-backgroundGray p-2">
                <p className="pt-0.5">{casePackItemQuantity || ""}</p>
              </div>
              {isNaN(casePackItemQuantity) === false &&
                casePackItemQuantity !== totalQuantityAvailable.current && (
                  <span className="ml-2 text-red-500">
                    <ExclamationCircleIcon
                      className="inline h-8 w-8"
                      data-tip
                      data-for={`product-casepack-error-info-${itemIdx}`}
                    />
                    <ReactTooltip id={`product-casepack-error-info-${itemIdx}`}>
                      "Total should be equal to the quantity of the product."
                    </ReactTooltip>
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasePackInformation;
