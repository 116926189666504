import _ from "lodash";
import SearchSortFilter from "#components/common/SearchSortFilter";
import AddButton from "#components/utils/AddButton";
import Stats from "#components/common/Stats";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import {
  getHeaderObject,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "#contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import Toggle from "#components/utils/Toggle";
import inventoryActionsTableKebabMenu from "#components/inventory/inventoryActionsTableKebabMenu";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import ReactTooltip from "react-tooltip";
import CustomSelectDropDown from "../common/CustomSelectDropDown";
import Autocomplete from "#components/utils/Autocomplete";
import { ProductAliasModal } from "#components/products/ProductAliasModal";

const INVENTORY_LEDGER_TABLE_NAME = "InventoryLedgerTable";

const InventoryList = ({
  inventory,
  noValuesText,
  getSku,
  unverifiedExists,
  verifyProduct,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  customers,
  isInventoryLoading,
  warehouses,
  onChangeFilter,
  releaseInventoryForShipmentCreation,
  allocateBundle,
  initiateVendorReturn,
  writable,
  explainInventory,
  total,
  totalInWarehouse,
  totalAvailableToShip,
  totalDamaged,
  totalAllocated,
  getProductForChannelAllocation,
  moveStock,
  clientMarketplaceIntegrations,
  multiAccountSupportEnabled,
  setTransactionFilters,
  getTransactionHistoryReport,
  transactionHistoryReportEnabled,
}) => {
  const prepCenter =
    warehouses?.findIndex(
      (item) =>
        item.typeOfWarehouse && item.typeOfWarehouse.includes("PREP CENTER"),
    ) !== -1;
  const ldClient = useLDClient();
  const isBundlingWorkflowEnabled =
    ldClient?.variation(featureFlags.IS_BUNDLING_WORKFLOW, false) ?? false;

  const renderQuantityBasedOnWarehouses = (inventory, bucket) => {
    if (
      filters.warehouse &&
      filters.warehouse.length > 0 &&
      inventory.quantities?.warehouses
    ) {
      const allQuantitiesOfFilteredWarehouses =
        inventory.quantities.warehouses.filter((i) =>
          filters.warehouse.includes(i.warehouseId),
        );
      return _.sumBy(allQuantitiesOfFilteredWarehouses, bucket) || 0;
    } else {
      return inventory.quantities?.[bucket] || 0;
    }
  };

  const HazmatIcon = () => {
    return (
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.3573 15.7195L12.6935 4.17748C12.1719 3.27417 10.8681 3.27417 10.3466 4.17748L3.68281 15.7195C3.16128 16.6228 3.81319 17.752 4.85624 17.752H18.1838C19.2269 17.752 19.8788 16.6228 19.3573 15.7195Z"
          fill="#EEB932"
          stroke="#111827"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0033 10.5466C15.2506 11.0563 15.3794 11.6178 15.3794 12.187L12.8337 12.187C12.8337 12.0206 12.7961 11.8565 12.7238 11.7074C12.6515 11.5584 12.5464 11.4286 12.4169 11.3282L13.9535 9.24927C14.3966 9.59286 14.7559 10.0369 15.0033 10.5466ZM8.03532 10.5466C7.78792 11.0563 7.65918 11.6178 7.65918 12.187L10.2048 12.187C10.2048 12.0206 10.2425 11.8565 10.3148 11.7074C10.3871 11.5584 10.4922 11.4286 10.6217 11.3282L9.08507 9.24927C8.64202 9.59286 8.28271 10.0369 8.03532 10.5466ZM9.84632 15.8744C10.3362 16.1427 10.88 16.2911 11.4353 16.3079C11.9905 16.3248 12.542 16.2096 13.0465 15.9715L11.9816 13.6043C11.8342 13.6739 11.6729 13.7076 11.5106 13.7027C11.3483 13.6977 11.1893 13.6544 11.0461 13.5759L9.84632 15.8744ZM11.4472 13.2708C11.9439 13.2708 12.3466 12.8584 12.3466 12.3496C12.3466 11.8409 11.9439 11.4285 11.4472 11.4285C10.9506 11.4285 10.5479 11.8409 10.5479 12.3496C10.5479 12.8584 10.9506 13.2708 11.4472 13.2708Z"
          fill="#111827"
        />
      </svg>
    );
  };

  const inventoryActions = (inventory) => {
    if (inventory.unverified) {
      return [
        {
          title: `Move Stock to ${inventory?.currentSource}`,
          icon: ArrowCircleRightIcon,
          onClick: moveStock,
          vars: "id",
        },
        {
          title: "Keep it as it is",
          icon: null,
          onClick: () => verifyProduct(inventory),
          vars: "id",
          disabled: true,
        },
      ];
    }
    const actions = [
      {
        title: "Allocate Channel",
        icon: ArrowCircleRightIcon,
        onClick: getProductForChannelAllocation,
        vars: "id",
      },
    ];

    return actions;
  };
  const headersList = () => {
    const arr = [];

    if (writable) {
      arr.push({ name: "Action", id: "action" });
    }

    arr.push({
      name: "SKU",
      id: "sku",
    });

    if (prepCenter) {
      arr.push({
        name: "ASIN",
        id: "asin",
      });
      arr.push({
        name: "FnSKU",
        id: "fnsku",
      });
    }

    arr.push(
      {
        name: "Source",
        id: "source",
      },
      {
        name: "Name",
        id: "name",
      },
    );

    if (multiAccountSupportEnabled) {
      arr.push(
        {
          name: "Marketplace",
          id: "marketplace",
        },
        {
          name: "Seller",
          id: "seller",
        },
      );
    }

    arr.push(
      {
        name: "Description",
        id: "description",
      },
      {
        name: "Consignments (Qty)",
        id: "consignments_qty",
      },
      {
        name: "Receiving Buffer (Qty)",
        id: "receiving_buffer_qty",
      },
      {
        name: "Storage (Qty)",
        id: "storage_qty",
      },
      {
        name: "Storage (Base UoM Qty)",
        id: "storage_base_uom_qty",
      },
      {
        name: "Base UoM",
        id: "base_uom",
      },
      {
        name: "Outbound (Qty)",
        id: "outbound_qty",
      },
      {
        name: "Damaged (Qty)",
        id: "damaged_qty",
      },
      {
        name: "Total (Qty)",
        id: "total_qty",
      },
      {
        name: "Allocated",
        id: "allocated",
      },
      {
        name: "Available to Ship",
        id: "available_to_ship",
      },
    );

    warehouses?.findIndex(
      (item) =>
        item.typeOfWarehouse && item.typeOfWarehouse.includes("PREP CENTER"),
    ) !== -1 &&
      arr.push({
        name: "Mktplace (Qty)",
        id: "mktplace",
      });

    const headersConfig = {
      Description: {
        sortable: false,
        sortBy: null,
        enabled: false,
      },
    };
    return arr.map((header) => {
      const headerConfig = headersConfig?.[header];
      return getHeaderObject(
        header.name,
        header.id,
        headerConfig?.sortable,
        headerConfig?.sortBy,
        headerConfig?.enabled,
      );
    });
  };

  const [headers, setHeaders] = useState(headersList());

  const [showHeaders, setShowHeaders] = useState(
    headers.filter((header) => header.enabled),
  );

  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headersList(),
      INVENTORY_LEDGER_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
    }
  }, [auth.user, prepCenter, multiAccountSupportEnabled]);

  const choices = () => {
    const returnArr = [
      {
        name: "Added Date",
        value: "createdAt",
      },
      {
        name: "SKU",
        value: "sku",
      },
      {
        name: "Name",
        value: "name",
      },
      {
        name: "FnSKU",
        value: "fnsku",
      },
      {
        name: "Source",
        value: "source",
      },
      {
        name: "Consignments (Qty)",
        value: "quantities.consignmentQuantity",
      },
      {
        name: "Receiving Buffer (Qty)",
        value: "quantities.inboundQuantity",
      },
      {
        name: "Storage (Qty)",
        value: "quantities.storageQuantity",
      },
      {
        name: "Problem (Qty)",
        value: "quantities.problemQuantity",
      },
      {
        name: "Unverified (Qty)",
        value: "quantities.unverifiedQuantity",
      },
      {
        name: "Allocated (Qty)",
        value: "quantities.allocatedQuantity",
      },
      {
        name: "Total (Qty)",
        value: "quantities.totalInWarehouse",
      },
    ];
    writable &&
      warehouses?.findIndex(
        (item) =>
          item.typeOfWarehouse && item.typeOfWarehouse.includes("PREP CENTER"),
      ) !== -1 &&
      returnArr.push({
        name: "Mktplace (Qty)",
        value: "amazonQuantity",
      });

    return returnArr;
  };

  return (
    <div className="w-full rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <div className="flex w-full py-5">
        <div className="flex h-10 w-full flex-1 items-center justify-between">
          <div className="flex flex-1 items-center justify-start space-x-4">
            <QuickFilters
              warehouses={warehouses}
              customers={customers}
              isInventoryLoading={isInventoryLoading}
              warehouseFilterName={"warehouse"}
              customerFilterName={"customer"}
              onChangeFilter={onChangeFilter}
              filters={filters}
              applyCustomZIndexForTopQuickFilters={true}
            />
            <div className="my-4 flex h-10 gap-2">
              {transactionHistoryReportEnabled && (
                <>
                  <AddButton
                    text="Export Transaction History"
                    onClick={() =>
                      setTransactionFilters({
                        customer: filters.customer,
                        warehouse: filters.warehouse,
                      })
                    }
                    styles={["whitespace-nowrap"]}
                  />
                </>
              )}

              {writable && prepCenter && !multiAccountSupportEnabled && (
                <>
                  <AddButton
                    text="Release Inventory for Shipment Creation"
                    onClick={releaseInventoryForShipmentCreation}
                    styles={["whitespace-nowrap"]}
                  />
                </>
              )}
              {writable && (
                <>
                  {isBundlingWorkflowEnabled && (
                    <AddButton
                      text="Allocate Bundle"
                      onClick={allocateBundle}
                      styles={["whitespace-nowrap"]}
                    />
                  )}
                  <AddButton
                    text="Initiate Vendor Return"
                    onClick={() => initiateVendorReturn({})}
                    styles={["whitespace-nowrap"]}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {multiAccountSupportEnabled && (
        <div className="flex w-3/4 py-5">
          <div className="mb-4 flex flex-1 items-center justify-start gap-2">
            <div className="w-3/4 flex-1">
              <MarketplaceSellerFilters
                clientMarketplaceIntegrations={clientMarketplaceIntegrations}
                filters={filters}
                onChangeFilter={onChangeFilter}
              />
            </div>
            {prepCenter && (
              <div className="flex gap-2">
                {writable && (
                  <>
                    <AddButton
                      text="Release Inventory for Shipment Creation"
                      onClick={releaseInventoryForShipmentCreation}
                      styles={["whitespace-nowrap"]}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* <div className="flex items-center gap-2 px-2">
        <div>
          <Toggle
            enabled={filters["includeOutOfStock"]}
            setEnabled={(key) => onChangeFilter("includeOutOfStock", key, true)}
          />
        </div>
        <div className="my-auto text-lg">Include Out of Stock</div>

        {unverifiedExists > 0 && (
          <>
            <div>
              <Toggle
                enabled={filters["unverified"]}
                setEnabled={(key) => onChangeFilter("unverified", key, true)}
              />
            </div>
            <div className="my-auto text-lg">Show Unverified</div>
          </>
        )}
      </div> */}

      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={choices()}
        headers={headers}
        setShowHeaders={setShowHeaders}
        tableName={INVENTORY_LEDGER_TABLE_NAME}
      />

      {/* <Stats
        grid="stock-ledger"
        stats={[
          {
            name: "# products",
            renderValue: () => total,
          },
          {
            name: "Qty Available",
            renderValue: () => totalAvailableToShip,
          },
          {
            name: "Qty Damaged",
            renderValue: () => totalDamaged,
          },
          {
            name: "Qty Allocated",
            renderValue: () => totalAllocated,
          },
          {
            name: "Total Qty",
            renderValue: () => totalInWarehouse,
          },
        ]}
      /> */}

      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-white">
            {showHeaders.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header.name}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header.name}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {inventory && inventory.length === 0 ? (
            <tr className="bg-white">
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="rounded-bl rounded-tl border-l-8 border-F4C261 p-5 text-left font-semibold tracking-wider text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {inventory &&
            inventory.map((inventory, rowIndex) => (
              <tr
                key={rowIndex}
                className={`${
                  inventory.unverified
                    ? "bg-[#FFEDD8]"
                    : rowIndex % 2 === 0
                      ? "bg-white"
                      : "bg-gray-50"
                }`}>
                {showHeaders.map((header, columnIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    [
                      "sku",
                      "consignments_qty",
                      "damaged_qty",
                      "outbound_qty",
                      "storage_qty",
                      "receiving_qty",
                      "allocated",
                    ],
                    columnIndex,
                    rowIndex,
                  );

                  if (value === "action") {
                    return inventoryActionsTableKebabMenu(
                      cellStyle,
                      writable,
                      inventoryActions,
                      inventory,
                    );
                  }

                  if (value === "sku") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() => getSku(inventory.id)}>
                        <div>
                          {inventory?.marketplaceAttributes?.attributes
                            ?.hazmat && (
                            <div
                              data-tip
                              data-for={`hazmat_${rowIndex}`}
                              className="absolute -left-8">
                              <HazmatIcon />
                              <ReactTooltip id={`hazmat_${rowIndex}`}>
                                Hazardous Material
                              </ReactTooltip>
                            </div>
                          )}
                          {inventory.sku}
                          <ProductAliasModal
                            aliases={inventory?.aliases}
                            customers={customers}
                          />
                        </div>
                      </td>
                    );
                  }
                  if (value === "asin") {
                    return (
                      warehouses &&
                      warehouses?.findIndex(
                        (item) =>
                          item.typeOfWarehouse &&
                          item.typeOfWarehouse.includes("PREP CENTER"),
                      ) !== -1 && (
                        <td className={cellStyle}>
                          {buildMarketplaceHyperlink(
                            inventory.asin,
                            "ASIN",
                            inventory.source,
                            inventory?.marketplaceAttributes?.attributes
                              ?.marketplaceCountryCode,
                          )}
                        </td>
                      )
                    );
                  }
                  if (value === "name") {
                    return (
                      <td className={cellStyle}>{truncate(inventory.name)}</td>
                    );
                  }
                  if (value === "marketplace") {
                    return (
                      <td className={cellStyle}>
                        {
                          inventory.marketplaceAttributes?.attributes
                            ?.marketplaceCountryCode
                        }
                      </td>
                    );
                  }

                  if (value === "seller") {
                    return (
                      <td className={cellStyle}>
                        {inventory.marketplaceAttributes?.attributes?.sellerId}
                      </td>
                    );
                  }

                  if (value === "description") {
                    return (
                      <td
                        className={cellStyle}
                        data-tip={inventory.description}
                        data-for={inventory.id}>
                        {truncate(inventory.description)}
                        {inventory.description &&
                          inventory.description.length > 40 && (
                            <ReactTooltip
                              id={inventory.id}
                              place="top"
                              type="info"
                              effect="solid">
                              <div className="h-fit max-w-[400px] whitespace-pre-line">
                                {inventory.description}
                              </div>
                            </ReactTooltip>
                          )}
                      </td>
                    );
                  }
                  if (value === "source") {
                    return <td className={cellStyle}>{inventory?.source}</td>;
                  }
                  if (value === "fnsku") {
                    return <td className={cellStyle}>{inventory?.fnSku}</td>;
                  }
                  if (value === "consignments_qty") {
                    return (
                      <td
                        onClick={() =>
                          explainInventory(inventory.id, "consignmentQuantity")
                        }
                        className={cellStyle}>
                        {renderQuantityBasedOnWarehouses(
                          inventory,
                          "consignmentQuantity",
                        )}
                      </td>
                    );
                  }
                  if (value === "receiving_buffer_qty") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() =>
                          explainInventory(inventory.id, "inboundQuantity")
                        }>
                        {renderQuantityBasedOnWarehouses(
                          inventory,
                          "inboundQuantity",
                        )}
                      </td>
                    );
                  }
                  if (value === "storage_qty" || value === "damaged_qty") {
                    const quantityToFetch =
                      value === "storage_qty"
                        ? "storageQuantity"
                        : "problemQuantity";
                    return (
                      <td
                        className={cellStyle}
                        onClick={() =>
                          explainInventory(inventory.id, quantityToFetch)
                        }>
                        {renderQuantityBasedOnWarehouses(
                          inventory,
                          quantityToFetch,
                        )}
                      </td>
                    );
                  }
                  if (value === "storage_base_uom_qty") {
                    return (
                      <td className={cellStyle}>
                        {renderQuantityBasedOnWarehouses(
                          inventory,
                          "storageQuantityInBaseUom",
                        )}
                      </td>
                    );
                  }
                  if (value === "base_uom") {
                    return (
                      <td className={cellStyle}>
                        {inventory.baseUom ?? "Each"}
                      </td>
                    );
                  }
                  if (value === "outbound_qty") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() =>
                          explainInventory(inventory.id, "outboundQuantity")
                        }>
                        {renderQuantityBasedOnWarehouses(
                          inventory,
                          "outboundQuantity",
                        )}
                      </td>
                    );
                  }
                  if (value === "total_qty") {
                    return (
                      <td className={cellStyle}>
                        {renderQuantityBasedOnWarehouses(
                          inventory,
                          "totalInWarehouse",
                        )}
                      </td>
                    );
                  }
                  if (value === "allocated") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() =>
                          explainInventory(inventory.id, "allocatedQuantity")
                        }>
                        {renderQuantityBasedOnWarehouses(
                          inventory,
                          "allocatedQuantity",
                        )}
                      </td>
                    );
                  }
                  if (value === "available_to_ship") {
                    return (
                      <td className={cellStyle}>
                        {renderQuantityBasedOnWarehouses(
                          inventory,
                          "availableToShip",
                        )}
                      </td>
                    );
                  }
                  if (value === "mktplace") {
                    return (
                      <td className={cellStyle}>{inventory.amazonQuantity}</td>
                    );
                  }
                })}
              </tr>
            ))}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

const MarketplaceSellerFilters = ({ ...props }) => {
  const { clientMarketplaceIntegrations, filters, onChangeFilter } = props;
  const [marketplaces, setMarketplaces] = useState([
    {
      value: "All",
      label: "All",
    },
  ]);
  const [sellerIds, setSellerIds] = useState([
    {
      value: "All",
      label: "All",
    },
  ]);
  useEffect(() => {
    setMarketplaces(
      [
        {
          value: "All",
          label: "All",
        },
      ].concat(
        clientMarketplaceIntegrations
          ? _.uniq(
              clientMarketplaceIntegrations
                .map((integration) => integration.marketplaces)
                .flat()
                .filter((marketplace) => marketplace),
            )
              .map((marketplace) => {
                return {
                  value: marketplace,
                  label: marketplace,
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [],
      ),
    );
    setSellerIds(
      [
        {
          value: "All",
          label: "All",
        },
      ].concat(
        clientMarketplaceIntegrations
          ? _.uniq(
              clientMarketplaceIntegrations
                .map((integration) => integration.sellerId)
                .filter((sellerId) => sellerId),
            )
              .map((sellerId) => {
                return {
                  value: sellerId,
                  label: sellerId,
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label))
          : [],
      ),
    );
  }, [clientMarketplaceIntegrations]);

  useEffect(() => {
    if (!marketplaces.includes(filters.marketplace)) {
      onChangeFilter("marketplace", "All", false);
    }
    if (!sellerIds.includes(filters.sellerId)) {
      onChangeFilter("sellerId", "All", false);
    }
  }, [sellerIds, marketplaces]);

  return (
    <div className="flex flex-1 gap-2">
      <div className="flex-1">
        <Autocomplete
          options={marketplaces}
          labelKey="label"
          valueKey="value"
          onChange={(selectedOption) =>
            onChangeFilter("marketplace", selectedOption, true)
          }
          value={filters.marketplace}
          placeholder={"All Marketplaces"}
        />
      </div>
      <div className="flex-1">
        <Autocomplete
          options={sellerIds}
          labelKey="label"
          valueKey="value"
          onChange={(selectedOption) =>
            onChangeFilter("sellerId", selectedOption, true)
          }
          value={filters.sellerId}
          placeholder={"All Seller Accounts"}
        />
      </div>
    </div>
  );
};

const truncate = (str) => {
  return str && str.length > 35 ? str.substring(0, 35) + "..." : str;
};

export default InventoryList;
