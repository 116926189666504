import ConsignmentPutaway from "./consignmentPutaway";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import { Link } from "react-router-dom";

const Putaway = () => {
  const ldClient = useLDClient();
  const isBundlingWorkflowEnabled =
    ldClient?.variation(featureFlags.IS_BUNDLING_WORKFLOW, false) ?? false;

  if (isBundlingWorkflowEnabled) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="flex flex-col items-center justify-center py-10">
          <Link to="/putaway/order">
            <div className="w-72 rounded-md bg-2C7695 py-4 text-center text-2xl text-white">
              Putaway Consignment
            </div>
          </Link>
          <Link to="/putaway/bundle">
            <div className="mt-10 w-72 rounded-md bg-2C7695 py-4 text-center text-2xl text-white">
              Putaway Bundle
            </div>
          </Link>
        </div>
      </div>
    );
  }

  // If bundling workflow is not enable just return order picking as the default.
  return <ConsignmentPutaway />;
};

export default Putaway;
