import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const UserForm = ({
  onClose,
  title,
  masterData,
  onChange,
  onChangeDropdown,
  selectedUser,
  onSubmit,
  onChangeMultiSelect,
  customers,
  warehouses,
  vendors,
  roleTypes,
}) => (
  <Modal
    title={title}
    negativeAction={onClose}
    positiveAction={onSubmit}
    id="userFormModal">
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Role
        </span>
      </div>
      <div>
        <Dropdown
          placeholder={"Select Role"}
          list={
            roleTypes
              ? [...roleTypes, { name: "Add Custom" }]
              : [{ name: "Add Custom" }]
          }
          labelKey="name"
          valueKey="name"
          name="role"
          setSelected={(value) => {
            onChangeDropdown("role", value);
          }}
          selectedValue={selectedUser.role}
        />
      </div>

      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          User Details
        </span>
      </div>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedUser.name}
        />
      </div>
      <div>
        <TextField
          type="text"
          id="email"
          label="Email"
          placeholder="email@example.com"
          onChange={onChange}
          value={selectedUser.email}
        />
      </div>
      <div>
        {selectedUser.role === "ASSOCIATE" && (
          <div id="selectingStations">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Select Stations
              </span>
            </label>
            <MultiSelectAutoComplete
              options={
                masterData.hopstackModules
                  ? masterData.hopstackModules?.filter((i) =>
                      [
                        "Picking",
                        "Prepping",
                        "Bundling",
                        "Packing",
                        "Shipping",
                        "Receiving",
                        "Putaway",
                        "Stock Cycle Count",
                        "Stock Transfer",
                      ].includes(i.name),
                    )
                  : []
              }
              labelKey={"name"}
              valueKey={"name"}
              setValues={(e) => onChangeMultiSelect("hopstackModules", e)}
              values={selectedUser.hopstackModules || []}
            />
          </div>
        )}
      </div>
      <div>
        <div id="selectingStations">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Select Warehouses
            </span>
          </label>
          <MultiSelectAutoComplete
            options={warehouses}
            labelKey={"name"}
            valueKey={"id"}
            setValues={(selectedOptions) =>
              onChangeMultiSelect("warehouses", selectedOptions)
            }
            values={selectedUser.warehouses || []}
            emptyValuesAccountsForAll={true}
          />
        </div>
      </div>
      <div>
        <div id="selectingStations">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Select Vendors
            </span>
          </label>
          <MultiSelectAutoComplete
            options={vendors}
            labelKey={"name"}
            valueKey={"id"}
            setValues={(selectedOptions) =>
              onChangeMultiSelect("vendors", selectedOptions)
            }
            values={selectedUser.vendors || []}
            emptyValuesAccountsForAll={false}
          />
        </div>
      </div>
      <div>
        <div id="selectingStations">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Select Clients
            </span>
            <br />
            <span className="pb-4 font-montserrat text-sm font-medium italic text-454A4F">
              * Selection of all values are retricted to the warehouses selected
              above
            </span>
          </label>
          <MultiSelectAutoComplete
            options={getFilteredCustomerList(
              selectedUser.warehouses,
              customers,
            )}
            labelKey={"name"}
            valueKey={"id"}
            setValues={(selectedOptions) =>
              onChangeMultiSelect("customers", selectedOptions)
            }
            values={selectedUser.customers || []}
            emptyValuesAccountsForAll={true}
          />
        </div>
      </div>

      {!selectedUser.id && (
        <div className="text-lg font-medium">Access Control: Default</div>
      )}
    </div>
  </Modal>
);

export default UserForm;
