import { CheckIcon } from "@heroicons/react/solid";
import moment from "moment-timezone";
import Modal from "#components/utils/Modal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AuditLog = ({
  selectedAudit = { stages: [] },
  negativeAction,
  auditUserId,
}) => {
  const steps = selectedAudit.stages || [];
  const noData = steps.length === 0;

  while (steps.length < 3) {
    steps.push({});
  }
  return (
    <Modal
      negativeAction={negativeAction}
      title={`Audit Trail | ${auditUserId}`}>
      {noData && <div>No Activity so far</div>}
      {!noData && (
        <nav aria-label="Progress">
          <ol role="list" className="overflow-hidden">
            {steps.map((step, stepIdx) => (
              <li
                key={step.name}
                className={classNames(
                  stepIdx !== steps.length - 1 ? "pb-10" : "",
                  "relative",
                )}>
                {step.status ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-1 bg-F4C261"
                        aria-hidden="true"
                      />
                    ) : null}
                    <a
                      href={step.href}
                      className="group relative flex items-start">
                      <span className="flex h-9 items-center">
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primaryAccent">
                          <CheckIcon
                            className="h-5 w-5 bg-primaryAccent text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-sm text-gray-600">Status</span>
                        <span className="text-sm font-semibold uppercase tracking-wide">
                          {step.status}
                        </span>
                        {step.notes &&
                          step.notes.map((ex, id) => (
                            <div
                              key={id}
                              className="text-sm font-semibold uppercase tracking-wide text-FC8862">
                              {ex}
                            </div>
                          ))}

                        <span className="text-sm font-normal uppercase tracking-wide text-gray-500">
                          {moment(step.createdAt).format("MMM Do YYYY, h:mm a")}
                        </span>
                      </span>
                    </a>
                  </>
                ) : (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                        aria-hidden="true"
                      />
                    ) : null}
                    <a
                      href={step.href}
                      className="group relative flex items-start">
                      <span
                        className="flex h-9 items-center"
                        aria-hidden="true">
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                          <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                        </span>
                      </span>
                    </a>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      )}
    </Modal>
  );
};

export default AuditLog;
