import Tabs from "#components/utils/Tabs";
import PageTitle from "#components/utils/PageTitle";
import PickingsList from "#components/pickings/PickingsList";
import PreppingsList from "#components/preppings/PreppingsList";
import PackingsList from "#components/packings/PackingsList";
import withDashboardLogic from "#components/HOC/withDashboardLogic";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import DelmarShippingsList from "#components/delmar/DelmarShippingsList";
import OutboundSummary from "#components/outbound/OutboundSummary";

const isDelmarTenant = (subdomain) =>
  ["delmarca", "delmartest", "uat"].includes(subdomain);

const Outbound = ({ writable, tenant, masterData }) => {
  const renderTabs = tabs({ writable, tenant, masterData });
  return (
    <div className="p-5">
      <PageTitle>Outbound Overview</PageTitle>
      {renderTabs && Object.keys(renderTabs).length > 0 ? (
        <Tabs tabs={renderTabs} writable={writable} />
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

const tabs = ({ writable, tenant, masterData }) => {
  const tabsList = {};

  const activatedModule = (name) => {
    return masterData?.hopstackModules?.find(
      (i) => i.name?.toLowerCase() === name?.toLowerCase(),
    );
  };

  tabsList["Summary"] = <OutboundSummary tenant={tenant} writable={writable} />;

  if (activatedModule("picking")) {
    tabsList["Picking"] = <PickingsList writable={writable} tenant={tenant} />;
  }

  if (activatedModule("prepping")) {
    tabsList["Prepping"] = (
      <PreppingsList writable={writable} tenant={tenant} />
    );
  }

  if (activatedModule("packing")) {
    tabsList["Packing"] = <PackingsList writable={writable} tenant={tenant} />;
  }

  if (isDelmarTenant(tenant?.subdomain)) {
    tabsList["Shipping"] = (
      <DelmarShippingsList writable={writable} tenant={tenant} />
    );
  }

  return tabsList;
};

export default withDashboardLogic(Outbound);
