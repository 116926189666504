import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";

const BarcodeForm = ({ onClose, title, onChange, onSubmit, text, value }) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <TextField
          type="text"
          id="barcode"
          label="Barcode"
          placeholder=" "
          onChange={(e) => onChange(e.target.value)}
          value={value}
          onKeyDown={(e) => (e.key === "Enter" ? onSubmit() : {})}
        />
      </div>
    </div>
  </Modal>
);

export default BarcodeForm;
