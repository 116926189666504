import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import TableTabs from "#components/utils/TableTabs";
import { CubeContext } from "@cubejs-client/react";
import { useCubeQuery } from "@cubejs-client/react";
import _ from "lodash";
import { useState, useEffect, useContext } from "react";

const query = {
  measures: ["orders.cycle_time"],
  timeDimensions: [
    {
      dimension: "orders.completedDate",
      granularity: "day",
      dateRange: "Last 7 days",
    },
  ],
  order: {
    "orders.createdat": "asc",
  },
  filters: [
    {
      member: "orders.tenant",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
    {
      member: "orders.customer",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
    {
      member: "orders.warehouse",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
  ],
};

const orderCycleTimeChart = (data) => {
  // ensure that data is an array of objects
  if (
    !!!(
      data?.length &&
      data.every(
        (item) =>
          item["x"] !== undefined && item["orders.cycle_time"] !== undefined,
      )
    )
  ) {
    return <div>No data found for cycle time</div>;
  }
  data = data.map((d) => {
    let date = new Date(d["x"]);
    d["x"] = date.toLocaleDateString();
    return d;
  });
  data = data.filter((item) => item["orders.cycle_time"] !== 0);
  // ensure that data is a not empty array
  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" title="Days" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="orders.cycle_time"
          name="Order Cycle Time (seconds)"
          stroke="#2C7695"
          activeDot={{ r: 8 }}
          strokeWidth={4}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const orderCycleTimeRenderer = ({ resultSet, error }) => {
  if (resultSet) {
    return orderCycleTimeChart(resultSet.chartPivot());
  } else if (error && error.toString()) {
    return <h4>{error.toString()}</h4>;
  } else {
    return <h4>Loading...</h4>;
  }
};

const OrderCycleTimeComponent = ({
  dateRange,
  tenantId,
  customers,
  warehouses,
}) => {
  query["timeDimensions"][0]["dateRange"] = dateRange;
  const renderProps = useCubeQuery(query);
  return orderCycleTimeRenderer(renderProps);
};

const ThisWeekCycleTimeRender = ({ resultSet, error }, setThisWeekTime) => {
  if (resultSet) {
    const arr = resultSet.chartPivot();
    const non_zero_cycle_times = arr
      .map((i) => i["orders.cycle_time"])
      .filter((i) => i !== 0);
    const avg = _.mean(non_zero_cycle_times);
    setThisWeekTime(avg ? avg.toFixed(2) : "N/A");
    return avg ? avg.toFixed(2) + " sec" : "N/A";
  } else if (error && error.toString()) {
    return <h4>{error.toString()}</h4>;
  } else {
    return <h4>Loading...</h4>;
  }
};

const ThisWeekCycleTime = ({ setThisWeekTime }) => {
  const thisWeekQuery = JSON.parse(JSON.stringify(query));
  thisWeekQuery["timeDimensions"][0]["dateRange"] = "This week";
  const renderProps = useCubeQuery(thisWeekQuery);
  return ThisWeekCycleTimeRender(renderProps, setThisWeekTime);
};

const LastWeekCycleTimeRender = ({ resultSet, error }, setLastWeekTime) => {
  if (resultSet) {
    const arr = resultSet.chartPivot();
    const avg = _.mean(
      arr.map((i) => i["orders.cycle_time"]).filter((i) => i !== 0),
    );
    setLastWeekTime(avg ? avg.toFixed(2) : "N/A");
    return avg ? avg.toFixed(2) + " sec" : "N/A";
  } else if (error && error.toString()) {
    return <h4>{error.toString()}</h4>;
  } else {
    return <h4>Loading...</h4>;
  }
};

const LastWeekCycleTime = ({ setLastWeekTime }) => {
  const thisWeekQuery = JSON.parse(JSON.stringify(query));
  thisWeekQuery["timeDimensions"][0]["dateRange"] = "Last week";
  const renderProps = useCubeQuery(thisWeekQuery);
  return LastWeekCycleTimeRender(renderProps, setLastWeekTime);
};

const ThisMonthCycleTimeRender = ({ resultSet, error }) => {
  if (resultSet) {
    const arr = resultSet.chartPivot();
    const avg = _.mean(
      arr.map((i) => i["orders.cycle_time"]).filter((i) => i !== 0),
    );
    return avg ? avg.toFixed(2) + " sec" : "N/A";
  } else if (error && error.toString()) {
    return <h4>{error.toString()}</h4>;
  } else {
    return <h4>Loading...</h4>;
  }
};

const ThisMonthCycleTime = () => {
  const thisWeekQuery = JSON.parse(JSON.stringify(query));
  thisWeekQuery["timeDimensions"][0]["dateRange"] = "Last 7 days";
  const renderProps = useCubeQuery(thisWeekQuery);
  return ThisMonthCycleTimeRender(renderProps);
};

const OrderCycleTimeRow = ({ dateRange, tenantId, customers, warehouses }) => {
  const [thisWeekTime, setThisWeekTime] = useState(0);
  const [lastWeekTime, setLastWeekTime] = useState(0);
  query["filters"][0].values = [tenantId];
  query["filters"][1].values = customers;
  query["filters"][2].values = warehouses;
  return (
    <div className="grid grid-cols-8 gap-4">
      <div className="col-span-4 rounded-xl bg-white p-2 px-4 py-4 shadow-lg">
        <div className="text-lg">Order Cycle Time (Warehouse)</div>
        <div className="mt-5 w-full" style={{ height: "300px" }}>
          <OrderCycleTimeComponent
            dateRange={dateRange}
            key={"cycle_time_" + dateRange.toString().replaceAll(" ", "_")}
            tenantId={tenantId}
            customers={customers}
            warehouses={warehouses}
          />
        </div>
      </div>
      <div className="col-span-1 rounded-xl bg-white p-2 shadow-lg">
        <div className="flex-col items-center justify-center">
          <div className="flex-col items-center justify-center space-y-2 border-b border-textWhite py-2 pb-6 pt-6 text-center text-xl">
            <div className="text-3E3D3D">This Week</div>
            <div className="text-2C7695">
              <ThisWeekCycleTime
                setThisWeekTime={setThisWeekTime}></ThisWeekCycleTime>
            </div>
          </div>
          <div className="flex-col items-center justify-center space-y-2 border-b border-textWhite py-2 pb-6 pt-6 text-center text-xl">
            <div className="text-3E3D3D">Last Week</div>
            <div className="text-2C7695">
              <LastWeekCycleTime
                setLastWeekTime={setLastWeekTime}></LastWeekCycleTime>
            </div>
          </div>
          <div className="flex-col items-center justify-center space-y-2 py-2 pb-6 pt-6 text-center text-xl">
            <div className="text-3E3D3D">This Month</div>
            <div className="text-2C7695">
              <ThisMonthCycleTime></ThisMonthCycleTime>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3 rounded-xl bg-white p-2 shadow-lg">
        <div className="px-2 text-lg">Order Cycle Time (Warehouse)</div>
        <TableTabs
          tabs={{
            Customer: () => (
              <CustomerWise
                tenantId={tenantId}
                customers={customers}
                warehouses={warehouses}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};
const CustomerWise = ({ tenantId, customers, warehouses }) => {
  const [data, setData] = useState(null);
  const [thisWeekData, setThisWeekData] = useState(null);
  const [lastWeekData, setLastWeekData] = useState(null);
  const { cubejsApi } = useContext(CubeContext);
  useEffect(() => {
    cubejsApi
      .load({
        measures: ["orders.cycle_time"],
        timeDimensions: [
          {
            dimension: "orders.completedDate",
            dateRange: "This week",
          },
        ],
        order: {
          "orders.cycle_time": "desc",
        },
        dimensions: ["customers.name"],
        filters: [
          {
            member: "orders.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "orders.customer",
            operator: "equals",
            values: customers || [],
          },
          {
            member: "orders.warehouse",
            operator: "equals",
            values: warehouses || [],
          },
        ],
      })
      .then((resultSet) => {
        setThisWeekData(resultSet.chartPivot());
      })
      .catch((e) => {
        console.log(e);
      });
    cubejsApi
      .load({
        measures: ["orders.cycle_time"],
        timeDimensions: [
          {
            dimension: "orders.completedDate",
            dateRange: "Last week",
          },
        ],
        order: {
          "orders.cycle_time": "desc",
        },
        dimensions: ["customers.name"],
        filters: [
          {
            member: "orders.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "orders.customer",
            operator: "equals",
            values: customers || [],
          },
          {
            member: "orders.warehouse",
            operator: "equals",
            values: warehouses || [],
          },
        ],
      })
      .then((resultSet) => {
        setLastWeekData(resultSet.chartPivot());
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    let final = [];
    if (thisWeekData && lastWeekData) {
      for (let i = 0; i < thisWeekData.length; i++) {
        const customerName = thisWeekData[i].x;
        const seconds = thisWeekData[i]["orders.cycle_time"];
        const lastWeekSeconds = lastWeekData[i]
          ? lastWeekData[i]["orders.cycle_time"]
          : 0;
        const status = ((seconds - lastWeekSeconds) / lastWeekSeconds) * 100;
        final.push({
          customerName,
          seconds,
          status,
        });
      }
      setData(final);
    }
  }, [thisWeekData, lastWeekData]);
  return (
    <div className="h-72 overflow-y-auto bg-F8F8F8 p-2">
      <table className="min-w-full divide-y divide-gray-200 font-montserrat">
        <thead>
          <tr className="border-b border-textWhite">
            <th
              scope="col"
              className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
              Name
            </th>
            <th
              scope="col"
              className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
              Seconds
            </th>

            <th
              scope="col"
              className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((customer) => (
              <tr>
                <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695">
                  {customer.customerName}
                </td>
                <td className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
                  {customer.seconds}
                </td>
                <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695">
                  <div
                    className={
                      "inline-flex items-center rounded-md border p-1 px-6 text-center text-sm font-medium text-white " +
                      (customer.status > 0 ? "bg-F4C261" : "bg-50BFC3")
                    }>
                    {customer.status > 0 ? (
                      <ArrowUpIcon className="h-4 w-4" />
                    ) : (
                      <ArrowDownIcon className="h-4 w-4" />
                    )}{" "}
                    {customer.status &&
                    !isNaN(customer.status) &&
                    isFinite(customer.status)
                      ? `${customer.status.toFixed(2)}%`
                      : "N/A"}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const ProductWise = () => (
  <div className="bg-F8F8F8 p-2">
    <table className="min-w-full divide-y divide-gray-200 font-montserrat">
      <thead>
        <tr className="border-b border-textWhite">
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            SKU
          </th>
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            Hrs
          </th>

          <th
            scope="col"
            className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            RYKT4450WS
          </td>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            8 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            <div className="inline-flex items-center rounded-md border bg-red-600 p-1 px-6 text-center text-sm font-medium text-white">
              <ArrowUpIcon className="h-4 w-4" /> 25%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            RGS0829BT
          </td>{" "}
          <td className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            2.5 hours
          </td>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            <div className="inline-flex items-center rounded-md border bg-F4C261 p-1 px-6 text-center text-sm font-medium text-white">
              <ArrowUpIcon className="h-4 w-4" /> 8%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            RGS0212BT
          </td>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-5F666B text-gray-500">
            1.5 hours
          </td>
          <td className="px-1 py-3 text-left font-medium tracking-wider text-2C7695 text-gray-500">
            <div className="inline-flex items-center rounded-md border bg-50BFC3 p-1 px-6 text-center text-sm font-medium text-white">
              <ArrowDownIcon className="h-4 w-4" /> 10%
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default OrderCycleTimeRow;

/*
const CountryWise = () => (
  <div className="bg-F8F8F8 p-2">
    <table className="min-w-full divide-y divide-gray-200 font-montserrat">
      <thead>
        <tr className="border-b border-textWhite">
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider"
          >
            Hrs
          </th>

          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider"
          >
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            United States
          </td>{" "}
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            5.5 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-F4C261 text-white font-medium inline-flex items-center">
              <ArrowUpIcon className="w-4 h-4" /> 15%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            Canada
          </td>{" "}
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            3 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-F4C261 text-white font-medium inline-flex items-center">
              <ArrowUpIcon className="w-4 h-4" /> 4%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            Mexico
          </td>
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            2 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-50BFC3 text-white font-medium inline-flex items-center">
              <ArrowDownIcon className="w-4 h-4" /> 10%
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

*/
