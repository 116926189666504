import { useEffect, useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";
import WorkflowProductImages from "#components/products/WorkflowProductImages";
import NumberField from "#components/utils/NumberField";
import TextField from "#components/utils/TextField";
import CommonTable from "#components/common/CommonTable";
import { renderVariantDetails } from "#components/receiver/DetailedView";

const DetailedView = ({
  currentSku,
  confirmItem,
  cancelItem,
  scanBarcode,
  currentProduct,
  setCurrentSku,
  currentParentProduct,
}) => {
  return (
    <div className="mx-auto max-w-7xl flex-col items-center justify-center">
      <BatchDetails
        currentSku={currentSku}
        currentProduct={currentProduct}
        scanBarcode={scanBarcode}
        confirmItem={confirmItem}
        cancelItem={cancelItem}
        setCurrentSku={setCurrentSku}
        currentParentProduct={currentParentProduct}
      />
    </div>
  );
};

const BatchDetails = ({
  currentSku,
  currentProduct,
  scanBarcode,
  confirmItem,
  cancelItem,
  setCurrentSku,
  currentParentProduct,
}) => {
  const [barcode, setBarcode] = useState("");
  const [expandedBatch, setExpandedBatch] = useState(null);

  const renderDisplayFields = () => {
    const returnObjects = [];
    let workflow = currentSku.workflow;
    if (workflow) {
      workflow.displayFields.map((item, idx) => {
        if (currentSku.currentItem) {
          if (
            currentSku.currentItem[item.value] &&
            item.value !== "binLocation" &&
            item.value !== "putawayBinLocation" &&
            !(
              currentSku.currentItem.isStrictPutaway &&
              item.value === "suggestedBinLocation"
            )
          ) {
            returnObjects.push(
              <LineItemValue
                value={
                  !Array.isArray(currentSku.currentItem[item.value])
                    ? currentSku.currentItem[item.value]
                    : currentSku.currentItem[item.value]?.join(", ")
                }
                title={item.name}
                idx={idx}
              />,
            );
          } else if (item.value === "putawayBinLocation") {
            returnObjects.push(
              <LineItemValue
                value={currentSku.currentItem.putawayBinLocation}
                title="Putaway Bin Location"
                idx={6}
              />,
            );
          } else if (item.value === "binLocation") {
            returnObjects.push(
              <LineItemValueWithOptions
                value={currentSku.currentItem.binLocation}
                title="Bin"
                idx={6}
                setBarcode={setBarcode}
              />,
            );
          } else if (item.value === "variantDetails" && currentParentProduct) {
            returnObjects.push(
              renderVariantDetails(currentParentProduct, currentProduct),
            );
          }
        }
      });
    }
    return <>{returnObjects.filter(Boolean)}</>;
  };

  const tableHeaders = (batches, parentBatch) => {
    const headers = [];
    headers.push("SKU");
    headers.push("Name");
    headers.push("Available for Putaway");
    headers.push("UoM");
    if (parentBatch.scannedEntity !== "tote") {
      if (batches.some((i) => !!i.tote)) {
        headers.push("Tote");
      }
    }
    if (batches.some((i) => !!i.nestedFormFactorId)) {
      headers.push("LPN");
    }
    if (batches.some((i) => !!i.nestedFormFactor)) {
      headers.push("LPN Form Factor");
    }
    if (batches.some((i) => !!i.bestByDate)) {
      headers.push("Expiry Date");
    }
    if (batches.some((i) => !!i.lotId)) {
      headers.push("Lot/Batch ID");
    }
    if (batches.some((i) => !!i.serialNumber)) {
      headers.push("Serial Number");
    }

    headers.push("Action");

    return headers;
  };

  const handleRowClick = (batch) => {
    if (batch.scannedEntity) {
      setExpandedBatch(batch);
    }
  };

  const handleBackClick = () => {
    setExpandedBatch(null);
  };

  const consolidatedBatches = () => {
    const batches = currentSku.relatedReceivingBatches;
    // GROUP BY A FEW ATTRIBUTES AND SUM UP THE QUANTITIES, bestByDate, lotId, serialNumber, nestedFormFactorId, nestedFormFactor
    const groupedBatches = _.groupBy(batches, (batch) => {
      return [
        batch.bestByDate,
        batch.lotId,
        batch.serialNumber,
        batch.nestedFormFactorId,
        batch.nestedFormFactor,
        batch.productId,
      ].join("-");
    });
    return Object.values(groupedBatches).map((batch) => {
      return {
        ...batch[0],
        quantity: _.sumBy(batch, "quantity"),
        availableQuantity: _.sumBy(batch, "availableQuantity"),
      };
    });
  };

  return (
    <div className="pt-8">
      <PageTitle>Product Details</PageTitle>
      <div className="grid grid-cols-3">
        <div
          className={`w-full flex-1 bg-gray-100 ${
            currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-4"
          }`}>
          <div className="space-y-2 text-xl font-medium text-454A4F">
            {renderDisplayFields()}
          </div>
        </div>
        {currentProduct?.images?.length > 0 && (
          <div className="flex flex-1 items-center justify-center bg-gray-300">
            <WorkflowProductImages
              images={[
                currentProduct.images.find((i) => i.purpose === "OPERATIONS") ||
                  currentProduct.images[0],
              ].filter(Boolean)}
            />
          </div>
        )}
      </div>
      <div className="mx-auto mt-8 flex w-96 items-center justify-center bg-white">
        <TextField
          type="text"
          id="name"
          label="Scan/Enter a Location"
          placeholder=" "
          onChange={(e) => setBarcode(e.target.value)}
          value={barcode}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              scanBarcode({
                data: barcode,
                receivingBatchId:
                  currentSku.currentItem.receivingBatchId || null,
              });
              setBarcode("");
            }
          }}
          autoFocus={true}
        />
      </div>
      {currentSku.currentItem.productId && (
        <div className="mt-10 flex items-center justify-center text-center">
          <div>
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-2xl font-medium text-454A4F">
                Quantity
              </span>
            </label>
            <NumberField
              onChange={(e) =>
                setCurrentSku({
                  ...currentSku,
                  currentItem: {
                    ...currentSku.currentItem,
                    quantity: isNaN(parseInt(e.target.value, 10))
                      ? null
                      : parseInt(e.target.value, 10),
                  },
                })
              }
              placeholder="Qty"
              value={currentSku.currentItem.quantity}
              className="w-36 rounded-md p-4 text-lg"
              min="0"
            />
          </div>
        </div>
      )}
      {!currentSku.currentItem.sku && (
        <div
          className={`mt-4 transition-opacity duration-300 ${expandedBatch ? "h-0 overflow-hidden opacity-0" : "opacity-100"}`}>
          <CommonTable
            headers={tableHeaders(
              currentSku.relatedReceivingBatches,
              currentSku.currentItem,
            )}>
            <tbody className="divide-y divide-gray-200 bg-white">
              {consolidatedBatches().map((receivingBatch, index) => (
                <ReceivingBatchItem
                  key={index}
                  receivingBatch={receivingBatch}
                  onClick={() => handleRowClick(receivingBatch)}
                  receivingBatches={currentSku.relatedReceivingBatches}
                  currentItem={currentSku.currentItem}
                  scanBarcode={scanBarcode}
                />
              ))}
            </tbody>
          </CommonTable>
        </div>
      )}

      <div className="mt-20 flex items-center justify-center space-x-6">
        <div
          className={`w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white ${currentSku.currentItem.binLocation ? "cursor-pointer" : "cursor-not-allowed bg-gray-300 text-gray-500"}`}
          onClick={() =>
            currentSku.currentItem.binLocation &&
            confirmItem({
              id: currentSku.currentItem.id,
              quantity: currentSku.currentItem.quantity,
            })
          }>
          Confirm
        </div>
        <div
          className="w-64 rounded-md bg-red-600 py-4 text-center text-2xl text-white"
          onClick={cancelItem}>
          Cancel
        </div>
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="flex w-full max-w-4xl space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="flex-1 rounded-br-md rounded-tr-md border bg-white p-4">
      {value}
    </div>
  </div>
);

const LineItemValueWithOptions = ({ title, value, idx, setBarcode }) => (
  <div className="flex w-full max-w-4xl space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="flex-1 rounded-br-md rounded-tr-md border bg-white p-4">
      {value}
    </div>
  </div>
);

const ReceivingBatchItem = ({
  receivingBatches,
  receivingBatch,
  onClick,
  currentItem,
  scanBarcode,
}) => {
  const extraVars = [
    "nestedFormFactorId",
    "nestedFormFactor",
    "bestByDate",
    "lotId",
    "serialNumber",
  ];
  if (currentItem.scannedEntity !== "tote") {
    extraVars.push("tote");
  }
  return (
    <tr
      className={`${
        currentItem.scannedEntity === "palletId" &&
        receivingBatches.some((batch) => !!batch.nestedFormFactorId)
          ? "cursor-pointer hover:bg-gray-100"
          : ""
      }`}
      onClick={onClick}>
      <td className="px-3 py-3.5 text-left text-lg font-normal">
        {receivingBatch.sku}
      </td>
      <td className="px-3 py-3.5 text-left text-lg font-normal">
        {receivingBatch.name}
      </td>
      <td className="px-3 py-3.5 text-left text-lg font-normal">
        {receivingBatch.availableQuantity}
      </td>
      <td className="px-3 py-3.5 text-left text-lg font-normal">
        {receivingBatch.formFactor}
      </td>
      {extraVars.map(
        (key) =>
          receivingBatches.some((batch) => !!batch[key]) && (
            <td key={key} className="px-3 py-3.5 text-left text-lg font-normal">
              {receivingBatch[key] || "-"}
            </td>
          ),
      )}

      {!currentItem.scannedEntity || currentItem.scannedEntity === "tote" ? (
        <td className="px-3 py-3.5 text-left text-lg font-normal">
          <div
            className="cursor-pointer whitespace-nowrap rounded-full bg-blue-500 px-2 py-1 text-center text-lg text-white"
            onClick={() =>
              scanBarcode({
                data: receivingBatch.sku,
              })
            }>
            Scan
          </div>
        </td>
      ) : (
        <td className="px-3 py-3.5 text-left text-lg font-normal">-</td>
      )}
    </tr>
  );
};

export default DetailedView;
