import NotFound from "#components/layout/NotFound";

const Error404 = () => (
  <NotFound
    title="Page Not Found"
    description="The page you're looking for doesn't exist"
  />
);

export default Error404;
