import Tabs from "#components/utils/Tabs";
import PageTitle from "#components/utils/PageTitle";
import BinToBinTransfersList from "#components/inventoryOperations/BinToBinTransfersList";
import CycleCountExecutionsList from "#components/inventoryOperations/CycleCountExecutionsList";
import InventorySummary from "#components/inventoryOperations/InventorySummary";
import withDashboardLogic from "#components/HOC/withDashboardLogic";
import LoadingIndicator from "#components/utils/LoadingIndicator";

const InventoryOperations = ({ writable, masterData }) => {
  const renderTabs = tabs({ masterData });
  return (
    <div className="p-5">
      <PageTitle>Inventory Operations Overview</PageTitle>
      {renderTabs && Object.keys(renderTabs).length > 0 ? (
        <Tabs tabs={renderTabs} writable={writable} />
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

const tabs = ({ masterData }) => {
  const tabsList = {};

  const activatedModule = (name) => {
    return masterData?.hopstackModules?.find(
      (i) => i.name?.toLowerCase() === name?.toLowerCase(),
    );
  };

  if (activatedModule("stock cycle count")) {
    tabsList["Stock Cycle Count"] = <CycleCountExecutionsList />;
  }

  if (activatedModule("stock transfer")) {
    tabsList["Bin to Bin Transfer"] = <BinToBinTransfersList />;
  }

  if (activatedModule("bundling")) {
    tabsList["Bundling Summary"] = <InventorySummary />;
  }

  return tabsList;
};

export default withDashboardLogic(InventoryOperations);
