import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

const PaginationNavigationButtons = ({
  pageNumber,
  total,
  perPage,
  prevPage,
  nextPage,
}) => (
  <div>
    <nav
      className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
      aria-label="Pagination">
      <div
        onClick={total > 0 && pageNumber > 1 ? prevPage : undefined}
        className={`relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium hover:bg-gray-50 ${
          pageNumber === 1
            ? "cursor-not-allowed text-gray-300"
            : "cursor-pointer text-gray-600"
        }`}>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        <span>Previous</span>
      </div>
      <div
        onClick={
          total > 0 && total > pageNumber * perPage ? nextPage : undefined
        }
        className={`relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium hover:bg-gray-50 ${
          total > pageNumber * perPage
            ? "cursor-pointer text-gray-500"
            : "cursor-not-allowed text-gray-300"
        }`}>
        <span>Next</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </div>
    </nav>
  </div>
);

export default PaginationNavigationButtons;
