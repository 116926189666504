import { useState } from "react";
import SectionTabs from "./SectionTabs";
import CatalogProductOverviewForm from "./CatalogProductOverviewForm";
import CatalogProductInfoForm from "./CatalogProductInfoForm";

const CatalogEditProduct = ({
  product,
  setProduct,
  productCategories,
  saveProduct,
  customers,
}) => {
  const [tabs, setTabs] = useState([
    { name: "Product Overview", current: true },
    { name: "Product Info", current: false },
    // { name: "Pricing & Availability", current: false },
  ]);

  const renderTabContent = () => {
    const currentTab = tabs.find((tab) => tab.current).name;
    switch (currentTab) {
      case "Product Overview":
        return (
          <CatalogProductOverviewForm
            product={product}
            setProduct={setProduct}
            productCategories={productCategories}
            saveProduct={saveProduct}
            customers={customers}
          />
        );
      case "Product Info":
        return (
          <CatalogProductInfoForm
            product={product}
            setProduct={setProduct}
            saveProduct={saveProduct}
          />
        );
      case "Pricing & Availability":
        return <h1>Categories</h1>;
      default:
        return null;
    }
  };
  return (
    <>
      <div className="h-full flex-col px-6">
        <SectionTabs tabs={tabs} setTabs={setTabs} />
        <div className="h-full">{renderTabContent()}</div>
      </div>
      {/* <div className="w-full flex h-full">
      <div className="flex-1 bg-red-400 h-full">Hello</div>
      <div className="flex-1 bg-blue-400 h-full">World</div>
    </div> */}
    </>
  );
};

export default CatalogEditProduct;
