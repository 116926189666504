import moment from "moment-timezone";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const iconClass = (status) => {
  switch (status) {
    case "IN_PROGRESS":
    case "STARTED":
      return "bg-primaryAccent animate-pulse";
    case "COMPLETED":
      return "bg-green-400";
    case "FAILED":
      return "bg-red-400";
    case "SCHEDULED":
    case "WAITING":
      return "bg-suspendedTextColor animate-pulse";
    default:
      return "bg-gray-400";
  }
};

const FbaTransportStatus = ({ fbaLabelGenerationStatus }) => {
  if (
    !fbaLabelGenerationStatus ||
    !fbaLabelGenerationStatus?.stageProgress?.length
  ) {
    return null;
  }
  return (
    <div className="flow-root">
      <div className="pb-4 text-4xl font-semibold text-gray-500">
        Please do not refresh the page. This process may take a few minutes.
      </div>
      <ul role="list" className="-mb-8">
        {fbaLabelGenerationStatus.stageProgress.map((stage, eventIdx) => (
          <li key={Math.random()}>
            <div className="relative pb-8">
              {eventIdx !==
              fbaLabelGenerationStatus.stageProgress.length - 1 ? (
                <span
                  className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-center space-x-3">
                <div>
                  <span
                    className={classNames(
                      "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white",
                      iconClass(stage.status),
                    )}></span>
                  <span className="relative inline-flex h-3 w-3 rounded-full bg-sky-500"></span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-2xl text-gray-500">
                      {stage.humanReadableActivityName}{" "}
                    </p>
                    <p className="text-2xl text-gray-500">{stage.status} </p>
                    {fbaLabelGenerationStatus.status === "FAILED" &&
                      eventIdx ===
                        fbaLabelGenerationStatus.stageProgress.length - 1 && (
                        <p className="max-w-4xl text-2xl text-gray-500 text-red-600">
                          {fbaLabelGenerationStatus.errorMessage}{" "}
                        </p>
                      )}
                  </div>
                  <div className="whitespace-nowrap pl-8 text-right text-2xl text-gray-500">
                    {moment(stage.timestamp).format("YYYY-MM-DD HH:mm")}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FbaTransportStatus;
