import {
  DesktopComputerIcon,
  LogoutIcon,
  CogIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import ConfigureIntegrationModal from "./ConfigureIntegrationModal";

const IntegrationProviders = ({ children, ...props }) => {
  const {
    integrations,
    integrationProviders = [],
    connectIntegration,
    logoutIntegration,
    testIntegration,
    getProducts,
    selectedCustomer,
    setSelectedIntegrationToManage,
    selectedIntegrationToConfigure,
    setSelectedIntegrationToConfigure,
    writable,
    multiAccountSupportEnabled,
    configureIntegrationSubmit,
  } = props;
  return (
    <div className="grid grid-cols-3 gap-4">
      {integrationProviders
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .map((integrationProvider, idx) => (
          <div className="rounded-lg bg-white py-8 pt-0 shadow-lg" key={idx}>
            <div className="flex w-full justify-center rounded-t-lg bg-integrationLogoBackground py-6">
              <img
                src={integrationProvider.logo}
                className="h-20 w-32 object-contain"
              />
            </div>
            <div className="flex h-40 flex-col items-center justify-center px-4 py-6 text-center">
              <div className="text-xl font-medium text-primaryAccent">
                {integrationProvider.name}
              </div>
              <div className="text-lg font-normal text-5F666B">
                {integrationProvider.description}
              </div>
            </div>
            <div className="mx-6 mt-4 flex space-x-2">
              {integrationProvider.multipleAccountSupport &&
              multiAccountSupportEnabled ? (
                <button
                  className={`w-full px-10 py-2 text-white ${
                    writable ? "bg-2C7695" : "bg-gray-500"
                  } cursor-pointer items-center justify-center rounded-lg text-center`}
                  onClick={() =>
                    writable &&
                    setSelectedIntegrationToManage(integrationProvider.name)
                  }>
                  Manage
                </button>
              ) : (
                integrations.findIndex(
                  (item) =>
                    item.integrationType === integrationProvider.name &&
                    item.customer === selectedCustomer,
                ) === -1 && (
                  <button
                    className={`w-full px-10 py-2 text-white ${
                      writable ? "bg-2C7695" : "bg-gray-500"
                    } cursor-pointer items-center justify-center rounded-lg text-center`}
                    onClick={() =>
                      writable && connectIntegration(integrationProvider.name)
                    }>
                    Connect
                  </button>
                )
              )}
              {!(
                integrationProvider.multipleAccountSupport &&
                multiAccountSupportEnabled
              ) &&
                integrations.findIndex(
                  (item) =>
                    item.integrationType === integrationProvider.name &&
                    item.customer === selectedCustomer,
                ) !== -1 && (
                  <div className="w-full flex-col space-y-4">
                    {integrationProvider.name === "Shopify" && (
                      <div className="space-between flex w-full space-x-2">
                        <button
                          className={`text-md flex w-full px-5 py-2 text-white ${
                            writable ? "bg-2C7695" : "bg-gray-500"
                          } items-center justify-center rounded-lg text-center`}
                          onClick={() => {
                            const selectedIntegration = integrations.find(
                              (item) =>
                                item.integrationType ===
                                  integrationProvider.name &&
                                item.customer === selectedCustomer,
                            );
                            const selectedIntegrationId =
                              selectedIntegration?.id;
                            writable &&
                              setSelectedIntegrationToConfigure(
                                selectedIntegration,
                              );
                          }}>
                          <CogIcon className="mr-1 h-5 w-5" />
                          Configure Integration
                        </button>
                      </div>
                    )}
                    <div className="space-between flex w-full space-x-2">
                      <button
                        className={`text-md flex w-full px-5 py-2 text-white ${
                          writable ? "bg-2C7695" : "bg-gray-500"
                        } items-center justify-center rounded-lg text-center`}
                        onClick={() =>
                          writable &&
                          testIntegration(
                            integrations.find(
                              (item) =>
                                item.integrationType ===
                                  integrationProvider.name &&
                                item.customer === selectedCustomer,
                            ).id,
                          )
                        }>
                        <DesktopComputerIcon className="mr-1 h-5 w-5" />
                        Test
                      </button>
                      {(integrations.find(
                        (item) =>
                          item.integrationType === integrationProvider.name &&
                          item.customer === selectedCustomer,
                      )?.tags?.[0] === "E_COMMERCE_MARKETPLACE" ||
                        integrations.find(
                          (item) =>
                            item.integrationType === integrationProvider.name &&
                            item.customer === selectedCustomer,
                        )?.tags?.[0] === "MARKETPLACE_FULFILLMENT" ||
                        integrations.find(
                          (item) =>
                            item.integrationType === integrationProvider.name &&
                            item.customer === selectedCustomer,
                        )?.tags?.[0] === "ERP") && (
                        <button
                          className={`text-md flex w-full px-5 py-2 text-white ${
                            writable ? "bg-2C7695" : "bg-gray-500"
                          } items-center justify-center rounded-lg text-center`}
                          onClick={() =>
                            writable &&
                            getProducts(
                              integrations.find(
                                (item) =>
                                  item.integrationType ===
                                    integrationProvider.name &&
                                  item.customer === selectedCustomer,
                              ).id,
                            )
                          }>
                          <RefreshIcon className="mr-1 h-5 w-5" />
                          Sync Now
                        </button>
                      )}
                    </div>
                    <div className="space-between flex w-full space-x-2">
                      <button
                        className={`text-md flex w-full px-5 py-2 text-white ${
                          writable ? "bg-2C7695" : "bg-gray-500"
                        } items-center justify-center rounded-lg text-center`}
                        onClick={() =>
                          writable &&
                          logoutIntegration(
                            integrations.find(
                              (item) =>
                                item.integrationType ===
                                  integrationProvider.name &&
                                item.customer === selectedCustomer,
                            ).id,
                          )
                        }>
                        <LogoutIcon className="mr-1 h-5 w-5" />
                        Logout
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        ))}
      {selectedIntegrationToConfigure && (
        <ConfigureIntegrationModal
          selectedIntegration={selectedIntegrationToConfigure}
          setSelectedIntegration={setSelectedIntegrationToConfigure}
          onClose={() => setSelectedIntegrationToConfigure(null)}
          onSubmit={configureIntegrationSubmit}
        />
      )}
    </div>
  );
};

export default IntegrationProviders;
