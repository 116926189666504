import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import _ from "lodash";

const EmptyBinLocationForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedBinLocation,
  onSubmit,
  customers,
  warehouses,
  onChangeMultiSelect,
  locationTypes,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Location Details
        </span>
      </div>

      <div>
        <TextField
          type="text"
          id="prefix"
          label="Prefix/Floor"
          placeholder=" "
          onChange={onChange}
          value={selectedBinLocation.prefix}
        />
      </div>
      <div>
        <Dropdown
          placeholder={"Select Location Type"}
          list={
            locationTypes
              ? [...locationTypes, { name: "Add Custom", id: "addCustom" }]
              : [{ name: "Add Custom", id: "addCustom" }]
          }
          labelKey="name"
          valueKey="id"
          name="type"
          setSelected={(e) => {
            onChangeDropdown("type", e);
          }}
          selectedValue={selectedBinLocation.type}
        />
      </div>
      <div>
        <TextField
          type="number"
          id="row"
          label="Row"
          placeholder=" "
          onChange={onChange}
          value={selectedBinLocation.row}
        />
      </div>
      <div>
        <TextField
          type="text"
          id="section"
          label="Section"
          placeholder=" "
          onChange={onChange}
          value={selectedBinLocation.section}
        />
      </div>
      <div>
        <TextField
          type="number"
          id="numberOfBins"
          label="Number of Locations"
          placeholder=" "
          onChange={onChange}
          value={selectedBinLocation.numberOfBins}
          min="0"
        />
      </div>
      <div>
        <TextField
          type="number"
          id="namingStart"
          label="Naming Sequence Start"
          placeholder=" "
          onChange={onChange}
          value={selectedBinLocation.namingStart}
        />
      </div>
      <div>
        <TextField
          type="number"
          id="namingIncrement"
          label="Naming Sequence Increment"
          placeholder=" "
          onChange={onChange}
          value={selectedBinLocation.namingIncrement}
        />
      </div>
      {warehouses.length > 1 && (
        <div>
          <div id="selectingStations">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Select Warehouses
              </span>
            </label>
            <Dropdown
              placeholder={"Select Warehouse"}
              list={warehouses}
              labelKey={"name"}
              valueKey={"id"}
              setSelected={(e) => {
                onChangeDropdown("warehouse", e);
              }}
              selectedValue={selectedBinLocation.warehouse}
            />
          </div>
        </div>
      )}
    </div>
  </Modal>
);

export default EmptyBinLocationForm;
