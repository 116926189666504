import withProfilesLogic from "#components/HOC/withProfilesLogic";
import { MyProfileForm } from "../components/myProfile/MyProfileForm";

const myProfile = ({ selectedProfile, onChange, onSubmit, keyGenerator }) => {
  return (
    <div className="pb-10">
      <MyProfileForm
        selectedProfile={selectedProfile}
        onChange={onChange}
        onSubmit={onSubmit}
        keyGenerator={keyGenerator}
      />
    </div>
  );
};

export default withProfilesLogic(myProfile);
