import { useContext } from "react";
import Modal from "#components/utils/Modal";
import Dropdown from "#components/utils/Dropdown";
import FnSkuLabel2 from "#components/common/FnSkuLabel2";
import Single30UpFnSkuLabel from "#components/common/Single30UpFnSkuLabel";
import { AppStateContext } from "#contexts/appState";
import TextField from "#components/utils/TextField";

const FnSkuLabelOptions = ({
  fnSkuLabelOptions,
  setFnSkuLabelOptions,
  setForDownloadingLabel,
  setFetchedSkuAvailable,
  positiveAction,
  isSelectLabel = false,
  productIdentifiers = [],
}) => {
  const { printers } = useContext(AppStateContext);
  return (
    <Modal
      negativeAction={() => {
        setFnSkuLabelOptions(null);
        setForDownloadingLabel(false);
        setFetchedSkuAvailable(false);
      }}
      title={!isSelectLabel ? `FNSKU Labels` : `Labels`}
      positiveAction={positiveAction}>
      <div className="space-y-4">
        <Dropdown
          placeholder={"Select Dimensions"}
          list={[
            { name: "3x1" },
            { name: "2x1" },
            { name: "2.25x1.25" },
            { name: "2.25x1.57" },
            { name: "30up" },
          ]}
          labelKey="name"
          valueKey="name"
          name="printSize"
          setSelected={(e) => {
            setFnSkuLabelOptions({
              ...fnSkuLabelOptions,
              printSize: e,
            });
          }}
          selectedValue={fnSkuLabelOptions.printSize}
        />
        {fnSkuLabelOptions.print && (
          <Dropdown
            placeholder={"Select Printer"}
            list={printers}
            labelKey="name"
            valueKey="name"
            name="printerName"
            setSelected={(e) => {
              setFnSkuLabelOptions({
                ...fnSkuLabelOptions,
                printerName: e,
              });
            }}
            selectedValue={fnSkuLabelOptions.printerName}
          />
        )}
        {isSelectLabel && (
          <Dropdown
            placeholder={"Select Identifier"}
            list={productIdentifiers}
            labelKey="name"
            valueKey="value"
            name="fnSkus"
            setSelected={(e) => {
              setFnSkuLabelOptions({
                ...fnSkuLabelOptions,
                fnSkus: [{ ...fnSkuLabelOptions?.fnSkus[0], fnSku: e }],
              });
            }}
            selectedValue={fnSkuLabelOptions?.fnSkus[0]?.fnSku}
            optionsWidthFull={true}
          />
        )}

        <div>
          <TextField
            type="number"
            min="1"
            inputMode="numeric"
            id="numberOfLabels"
            label="Number of Labels"
            placeholder=" "
            onChange={(e) => {
              const newValue = e.target.value.replace(/[^0-9]/g, "");
              setFnSkuLabelOptions({
                ...fnSkuLabelOptions,
                numberOfLabels: newValue
                  ? Math.max(1, parseInt(newValue, 10))
                  : 1,
              });
            }}
            value={fnSkuLabelOptions.numberOfLabels}
          />
        </div>
      </div>
      {fnSkuLabelOptions && fnSkuLabelOptions.fnSkus && (
        <div className="fixed" style={{ marginTop: 2000 }}>
          {fnSkuLabelOptions.fnSkus && fnSkuLabelOptions.printSize === "30up"
            ? fnSkuLabelOptions.fnSkus.map((item) => (
                <Single30UpFnSkuLabel
                  id={item.fnSku}
                  value={item.fnSku}
                  text1={truncate(item?.productName) || item.fnSku}
                  text2={item.bestByDate ? `Best by: ${item.bestByDate}` : null}
                />
              ))
            : fnSkuLabelOptions.fnSkus.map((item, idx) => (
                <>
                  <FnSkuLabel2
                    id={item.fnSku}
                    value={item.fnSku}
                    text1={truncate(item?.productName) || item.fnSku}
                    text2={
                      item.bestByDate ? `Best by: ${item.bestByDate}` : null
                    }
                  />
                  <img id={`label-placeholder-${item.fnSku}`} key={idx} />
                </>
              ))}
        </div>
      )}
    </Modal>
  );
};

const truncate = (str) => {
  return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
};

export default FnSkuLabelOptions;
