import React, { useState, useEffect, useContext } from "react";
import Checkbox from "#components/utils/Checkbox";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { Space, TimePicker } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { AppStateContext } from "#contexts/appState";

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(localizedFormat);
import moment from "moment-timezone";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
const DATE_FORMAT = "DD-MM-YYYY";

const dayOfWeeks = [
  { name: "Sunday", value: "0" },
  { name: "Monday", value: "1" },
  { name: "Tuesday", value: "2" },
  { name: "Wednesday", value: "3" },
  { name: "Thursday", value: "4" },
  { name: "Friday", value: "5" },
  { name: "Saturday", value: "6" },
];

const CronExpressionGenerator = ({
  selectedReport,
  onChangeFrequency,
  setSelectedReport,
  onChange,
  parseCronExpression,
  scheduleStartDate,
  isEditView,
  resetTime,
  setResetTime,
  isScheduleChanged,
}) => {
  const appState = useContext(AppStateContext);
  const [time, setTime] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState("");
  const [dayOfMonth, setDayOfMonth] = useState("");
  const [frequency, setFrequency] = useState("daily");
  const [expression, setExpression] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (resetTime) {
      setTime("");
      setExpression("");
      onChange({
        target: {
          name: "localCron",
          value: "",
        },
      });
      onChange({
        target: {
          name: "cron",
          value: "",
        },
      });
      setResetTime(false);
    }
  }, [resetTime]);

  useEffect(() => {
    const localGeneratedExression = generateCronExpression("LOCAL");
    if (localGeneratedExression && localGeneratedExression !== "") {
      onChange({
        target: {
          name: "localCron",
          value: localGeneratedExression,
        },
      });
      const isValidFormat = moment(
        scheduleStartDate,
        "DD-MM-YYYY",
        true,
      ).isValid();
      const FormattedDate = isValidFormat
        ? scheduleStartDate
        : moment(scheduleStartDate).format("DD-MM-YYYY");
      let nextFormatedDate = calculateNewDate(
        moment(FormattedDate, "DD-MM-YYYY").format("YYYY-MM-DD") +
          " " +
          moment("00:00 AM", "hh:mm A").format("HH:mm"),
        localGeneratedExression,
        "next",
        "DD MMM, YYYY",
      );
      const utcGeneratedExression = convertCronFromLocaltoUTC(
        nextFormatedDate,
        time,
        frequency,
      );
      onChange({
        target: {
          name: "cron",
          value: utcGeneratedExression,
        },
      });

      onChange({
        target: {
          name: "scheduleRunDate",
          value: moment(nextFormatedDate, "DD MMM, YYYY").format("YYYY-MM-DD"),
        },
      });
      const parsedexpression =
        scheduleStartDate && scheduleStartDate !== ""
          ? parseCronExpression(localGeneratedExression) +
            (scheduleStartDate && " starting " + nextFormatedDate)
          : "";
      setExpression(parsedexpression);
    } else {
      setExpression("");
      onChange({
        target: {
          name: "localCron",
          value: "",
        },
      });
      onChange({
        target: {
          name: "cron",
          value: "",
        },
      });
    }
  }, [time, dayOfMonth, dayOfWeek, frequency, scheduleStartDate]);

  useEffect(() => {
    if (selectedReport?.frequency) {
      setFrequency(selectedReport?.frequency);
      if (selectedReport?.frequency === "weekly") {
        let dayOfWeek = getDayFromCron(
          selectedReport?.localCron,
          selectedReport?.frequency,
        );
        setDayOfWeek(dayOfWeek);
        onChange({
          target: {
            name: "dayOfWeek",
            value: dayOfWeek,
          },
        });
      } else if (selectedReport?.frequency === "monthly") {
        let dayOfMonth = getDayFromCron(
          selectedReport?.localCron,
          selectedReport?.frequency,
        );
        setDayOfMonth(dayOfMonth);
        onChange({
          target: {
            name: "dayOfMonth",
            value: dayOfMonth,
          },
        });
      }
      if (
        selectedReport?.scheduleStartTime !== undefined &&
        selectedReport?.scheduleStartTime !== ""
      ) {
        const isValid = moment(
          selectedReport?.scheduleStartTime,
          "hh:mm A",
          true,
        ).isValid();
        const formattedTime = isValid
          ? selectedReport?.scheduleStartTime
          : convertTo12HourFormat(selectedReport?.scheduleStartTime);
        setTime(formattedTime);
      }
    } else {
      onChangeFrequency("frequency", "daily");
    }
  }, []);

  const handleOpenChange = (visible) => {
    setOpen(visible);
  };

  useEffect(() => {
    if (open) {
      if (!time || time == "") {
        const setFormattedTime = moment().format("hh:mm A");
        setTime(setFormattedTime);
        onChange({
          target: {
            name: "scheduleStartTime",
            value: setFormattedTime,
          },
        });
      }
    }
  }, [open]);

  const generateCronExpression = (timezone) => {
    let cron = "";
    const [hours, minutes] = convertTo24HourFormat(time, timezone);
    switch (frequency) {
      case "daily":
        cron = minutes && hours ? `${minutes} ${hours} * * *` : "";
        break;
      case "weekly":
        cron =
          minutes && hours && dayOfWeek
            ? `${minutes} ${hours} * * ${dayOfWeek}`
            : "";
        break;
      case "monthly":
        cron =
          minutes && hours && dayOfMonth
            ? `${minutes} ${hours} ${dayOfMonth} * *`
            : "";
        break;
      default:
        cron = "";
    }

    return cron;
  };

  const disabledTime = () => {
    if (!scheduleStartDate) return {};

    const now = moment();

    // Check if the selected date is today
    if (moment(scheduleStartDate, "DD-MM-YYYY").isSame(now, "day")) {
      const currentHour = now.hour();
      const currentMinute = now.minute();

      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) => i).filter(
            (hour) => hour < currentHour,
          ),
        disabledMinutes: (selectedHour) => {
          if (selectedHour === currentHour) {
            return Array.from({ length: 60 }, (_, i) => i).filter(
              (minute) => minute < currentMinute,
            );
          }
          return [];
        },
      };
    }
    return {};
  };

  return (
    <div>
      <div className="mb-4 flex flex-row items-center space-x-4">
        <div className="flex flex-row items-center space-x-3">
          <Checkbox
            role="checkbox"
            onChange={(_) => {
              if (isEditView) {
                isScheduleChanged(true);
              }
              setDayOfMonth("");
              setDayOfWeek("");
              onChange({
                target: {
                  name: "dayOfWeek",
                  value: "",
                },
              });
              onChange({
                target: {
                  name: "dayOfMonth",
                  value: "",
                },
              });
              setFrequency("daily");
              onChangeFrequency("frequency", "daily");
            }}
            name="daily"
            value="daily"
            checked={frequency === "daily"}
          />
          <label
            className="block text-sm font-medium text-lightGray"
            htmlFor="Daily">
            Daily
          </label>
        </div>
        <div className="flex flex-row items-center space-x-3">
          <Checkbox
            role="checkbox"
            onChange={(_) => {
              if (isEditView) {
                isScheduleChanged(true);
              }
              setDayOfMonth("");
              setDayOfWeek("");
              onChange({
                target: {
                  name: "dayOfWeek",
                  value: "",
                },
              });
              onChange({
                target: {
                  name: "dayOfMonth",
                  value: "",
                },
              });
              setFrequency("weekly");
              onChangeFrequency("frequency", "weekly");
            }}
            name="weekly"
            value="weekly"
            checked={frequency === "weekly"}
          />
          <label
            className="block text-sm font-medium text-lightGray"
            htmlFor="Weekly">
            Weekly
          </label>
        </div>
        <div className="flex flex-row items-center space-x-3">
          <Checkbox
            role="checkbox"
            onChange={(_) => {
              if (isEditView) {
                isScheduleChanged(true);
              }
              setDayOfMonth("");
              setDayOfWeek("");
              onChange({
                target: {
                  name: "dayOfWeek",
                  value: "",
                },
              });
              onChange({
                target: {
                  name: "dayOfMonth",
                  value: "",
                },
              });
              setFrequency("monthly");
              onChangeFrequency("frequency", "monthly");
            }}
            name="monthly"
            value="monthly"
            checked={frequency === "monthly"}
          />
          <label
            className="block text-sm font-medium text-lightGray"
            htmlFor="Monthly">
            Monthly
          </label>
        </div>
      </div>
      {(frequency === "weekly" || frequency === "monthly") && (
        <div className="mb-4 flex flex-row items-center space-x-4">
          {frequency === "weekly" && (
            <div className="flex-1">
              <label
                htmlFor="DayofWeek:"
                className="mb-1 block text-sm font-medium text-lightGray">
                Day of Week
              </label>
              <AutocompleteSingleSelectDropdown
                key={"DayofWeek"}
                options={dayOfWeeks}
                labelKey={"name"}
                valueKey={"value"}
                onChange={(value) => {
                  if (isEditView) {
                    isScheduleChanged(true);
                  }
                  setDayOfMonth("");
                  setDayOfWeek("");
                  onChange({
                    target: {
                      name: "dayOfWeek",
                      value: "",
                    },
                  });
                  onChange({
                    target: {
                      name: "dayOfMonth",
                      value: "",
                    },
                  });
                  if (value && value !== "") {
                    setDayOfWeek(value);
                    onChange({
                      target: {
                        name: "dayOfWeek",
                        value,
                      },
                    });
                  }
                }}
                value={dayOfWeek}
                placeholder="Select Day"
                showCheckedIndicator={false}
                sortOptions={false}
                textNormal="!text-sm !text-gray-500"
              />
            </div>
          )}
          {frequency === "monthly" && (
            <div className="flex-1">
              <label
                htmlFor=" DayofMonth"
                className="mb-1 block text-sm font-medium text-lightGray">
                Day of Month
              </label>
              <input
                type="number"
                className="w-[24rem] !rounded !border-inherit"
                style={{
                  borderColor: "inherit",
                }}
                value={dayOfMonth}
                min="1"
                max="31"
                onChange={(e) => {
                  if (isEditView) {
                    isScheduleChanged(true);
                  }
                  setDayOfWeek("");
                  setDayOfMonth("");
                  onChange({
                    target: {
                      name: "dayOfWeek",
                      value: "",
                    },
                  });
                  onChange({
                    target: {
                      name: "dayOfMonth",
                      value: "",
                    },
                  });
                  if (e.target.value > 0 && e.target.value < 32) {
                    setDayOfMonth(e.target.value);
                    onChange({
                      target: {
                        name: "dayOfMonth",
                        value: e.target.value,
                      },
                    });
                  }
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className="mb-4 flex flex-row items-center space-x-4">
        <div className="flex-1">
          <label
            htmlFor="time"
            className="mb-1 block text-sm font-medium text-lightGray">
            Schedule Time
          </label>
          <Space>
            <TimePicker
              open={open}
              onOpenChange={handleOpenChange}
              disabled={selectedReport?.scheduleStartDate ? false : true}
              disabledTime={disabledTime}
              placeholder="Choose Time"
              onChange={(dates, value) => {
                if (isEditView) {
                  isScheduleChanged(true);
                }
                setTime(value);
                onChange({
                  target: {
                    name: "scheduleStartTime",
                    value,
                  },
                });
              }}
              value={time && time !== "" ? dayjs(time, "hh:mm A") : ""}
              showTime={{
                format: "hh:mm A",
                use12Hours: true,
                showSecond: false,
              }}
              showNow={false}
              className="w-[24rem] rounded border border-borderGray p-2"
            />
          </Space>
        </div>
      </div>
      <p className="text-sm font-medium text-lightGray">{expression}</p>
    </div>
  );
};

const convertTo24HourFormat = (time, timezon) => {
  let timeStamp = "";
  if (timezon === "UTC") {
    timeStamp =
      time && time !== null && typeof time === "object" && !Array.isArray(time)
        ? moment(dayjs(time).local().format("hh:mm A"), "hh:mm A")
            .utc()
            .format("HH:mm")
        : moment(time, "hh:mm A").utc().format("HH:mm");
  } else if (timezon === "LOCAL") {
    timeStamp =
      time && time !== null && typeof time === "object" && !Array.isArray(time)
        ? moment(dayjs(time).local().format("hh:mm A"), "hh:mm A").format(
            "HH:mm",
          )
        : moment(time, "hh:mm A").format("HH:mm");
  }
  if (timeStamp) {
    let [hours, minutes] = timeStamp.split(":");
    return [hours, minutes];
  }

  return [undefined, undefined];
};

const convertTo12HourFormat = (time24) => {
  let [hour, minute] = time24.split(":");
  hour = parseInt(hour, 10);
  const ampm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12;
  hour = hour ? hour : 12;
  return `${hour}:${minute} ${ampm}`;
};

const getDayFromCron = (cron, frequency) => {
  let cronExpression = cron ? cron : null;
  cronExpression = cronExpression !== null ? cronExpression.split(" ") : null;
  if (frequency === "weekly") {
    return cronExpression && cronExpression.length !== 0
      ? cronExpression[4]
      : "";
  } else if (frequency === "monthly") {
    return cronExpression && cronExpression.length !== 0
      ? cronExpression[2]
      : "";
  } else {
    return "";
  }
};

const calculateNewDate = (date, cron, type, format) => {
  try {
    let dateNew = moment(date, "YYYY-MM-DD HH:mm");

    const [minute, hour, dayOfMonth, month, dayOfWeek] = cron.split(" ");

    let nextDate = moment(dateNew).second(0).millisecond(0);

    // Set the next date according to cron fields
    if (minute !== "*") nextDate.minute(minute);
    if (hour !== "*") nextDate.hour(hour);
    if (dayOfMonth !== "*") nextDate.date(dayOfMonth);
    if (month !== "*") nextDate.month(month - 1); // month is 0-indexed
    if (dayOfWeek !== "*") {
      nextDate.day(
        parseInt(dayOfWeek) + (parseInt(dayOfWeek) < dateNew.day() ? 7 : 0),
      );
    }

    // Adjust if the nextDate is in the past
    if (nextDate.isBefore(dateNew)) {
      if (minute === "*") nextDate.add(1, "minute");
      else if (hour === "*") nextDate.add(1, "hour");
      else if (dayOfMonth === "*") nextDate.add(1, "day");
      else if (month === "*") nextDate.add(1, "month");
      else if (dayOfWeek === "*") nextDate.add(1, "week");
    }

    // Format date to YYYY-MM-DD
    const formattedDate = moment(nextDate, format).format(format);
    return formattedDate;
  } catch (error) {
    throw error;
  }
};

const convertCronFromLocaltoUTC = (nextFormatedDate, time, frequency) => {
  // Parse the date using moment
  const localMoment = moment(
    `${nextFormatedDate} ${time}`,
    "DD MMM, YYYY hh:mm A",
  );

  // Convert to UTC
  const utcMoment = localMoment.utc();

  const utcDayOfMonth = utcMoment.format("D");
  const utcHour = utcMoment.format("HH");
  const utcMinute = utcMoment.format("mm");
  const utcDayOfWeek = utcMoment.format("d");

  let cron = "";
  switch (frequency) {
    case "daily":
      cron = utcMinute && utcHour ? `${utcMinute} ${utcHour} * * *` : "";
      break;
    case "weekly":
      cron =
        utcMinute && utcHour && utcDayOfWeek
          ? `${utcMinute} ${utcHour} * * ${utcDayOfWeek}`
          : "";
      break;
    case "monthly":
      cron =
        utcMinute && utcHour && utcDayOfMonth
          ? `${utcMinute} ${utcHour} ${utcDayOfMonth} * *`
          : "";
      break;
    default:
      cron = "";
  }

  return cron;
};

export default CronExpressionGenerator;
