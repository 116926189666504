import React from "react";

const ForbiddenPage = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-white">
      <div className="w-2/3 rounded border border-gray-300 bg-gray-100 p-16 text-center shadow-lg">
        <h1 className="mb-8 text-6xl">Oops!</h1>
        <p className="text-2xl text-gray-600">
          You don't have permission to access this page.
        </p>
        <p className="mt-4 text-xl text-gray-500">
          Please contact your administrator or go back to the dashboard.
        </p>
      </div>
    </div>
  );
};

export default ForbiddenPage;
