import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { twMerge } from "tailwind-merge";

const CustomBadge = ({
  color,
  label,
  bgColor,
  textColor,
  textSize,
  className,
}) => {
  const BG_COLOR = color ? `bg-${color}-100` : "";
  const TEXT_COLOR = color ? `text-${color}-800` : "";

  return (
    <span
      style={{ backgroundColor: bgColor, color: textColor }}
      className={twMerge(
        `${className} rounded-full px-3 py-2 ${BG_COLOR} ${TEXT_COLOR} ${textSize || ""}`,
      )}>
      {label}
    </span>
  );
};

// Add PropTypes validation
CustomBadge.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  textSize: PropTypes.string,
  className: PropTypes.string,
};

export default CustomBadge;
