import { Tab } from "@headlessui/react";
import FulfillableOrders from "./FulfillableOrders";
import PartialFulfillableOrders from "./PartialFulfillableOrders";
import UnfulfillableOrders from "./UnfulfillableOrders";
import ExceptionOrders from "./ExceptionOrders";

const PreviewFulfillmentDetails = ({
  fulfillableOrders,
  partiallyFulfillableOrders,
  unfulfillableOrders,
  exceptionOrders,
  selectedOrderIds,
  selectedOrderIdsForBatching,
  setSelectedOrderIdsForBatching,
  isBackOrderEnabledForTenant,
  previewForExceptions = false,
}) => {
  const tabs = {};

  if (fulfillableOrders.length > 0) {
    tabs[`Fulfillable (${fulfillableOrders.length})`] = (
      <FulfillableOrders orders={fulfillableOrders} />
    );
  }

  if (isBackOrderEnabledForTenant && partiallyFulfillableOrders.length > 0) {
    tabs[`Partially Fulfillable (${partiallyFulfillableOrders.length})`] = (
      <PartialFulfillableOrders
        backOrders={partiallyFulfillableOrders}
        fulfillableOrders={fulfillableOrders}
        selectedOrderIdsForBatching={selectedOrderIdsForBatching}
        setSelectedOrderIdsForBatching={setSelectedOrderIdsForBatching}
      />
    );
  }

  if (unfulfillableOrders.length > 0) {
    tabs[`Unfulfillable (${unfulfillableOrders.length})`] = (
      <UnfulfillableOrders orders={unfulfillableOrders} />
    );
  }

  if (exceptionOrders.length > 0) {
    tabs[`Exception (${exceptionOrders.length})`] = (
      <ExceptionOrders orders={exceptionOrders} />
    );
  }

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <>
      {previewForExceptions && (
        <div className="my-4 w-full rounded-xl border border-gray-500 p-3 pl-8">
          <ul className="list-disc text-base font-normal">
            <li>
              Once resolved, the listed orders will revert to an 'unprocessed'
              status.
            </li>
            <li className="mt-4 break-words">
              The preview below shows how these orders may be processed and
              batched once they return to the 'unprocessed' category.
            </li>
          </ul>
        </div>
      )}
      <p className="py-3 text-base font-semibold">
        Total Orders: {selectedOrderIds.length}
      </p>
      <Tab.Group>
        <Tab.List className="flex items-center">
          <div className="flex-1 space-x-4 rounded-xl p-1 px-0">
            {tabs &&
              Object.keys(tabs).map((tabName) => (
                <Tab
                  key={tabName}
                  className={({ selected }) =>
                    classNames(
                      "font-regular rounded-full px-6 py-2.5 text-sm leading-5",
                      "bg-E1D3B8 font-montserrat font-semibold text-primaryAccent focus:outline-none focus:ring-0",
                      selected ? "border border-black" : "",
                    )
                  }>
                  {tabName}
                </Tab>
              ))}
          </div>
        </Tab.List>
        <Tab.Panels className="mt-2">
          {tabs &&
            Object.values(tabs).map((tabComponent, idx) => (
              <Tab.Panel key={idx} className="textWhite-300 mt-4 flex flex-col">
                {tabComponent}
              </Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

export default PreviewFulfillmentDetails;
