import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const choices = [
  {
    name: "25",
    value: 25,
  },
  {
    name: "50",
    value: 50,
  },
  {
    name: "100",
    value: 100,
  },
  {
    name: "200",
    value: 200,
  },
];

const RowsPerPageDropdown = ({ perPage, setPerPage }) => (
  <Menu as="div" className="relative inline-block text-left">
    <div>
      <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
        {perPage}
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </Menu.Button>
    </div>
    <Menu.Items className="absolute right-0 top-0 -mt-40 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
      <div className="py-1">
        {choices.map((choice, idx) => (
          <Menu.Item key={idx}>
            {({ active }) => (
              <div
                onClick={() => setPerPage(choice.value)}
                className={classNames(
                  choice.value === perPage
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-700",
                  "block cursor-pointer px-4 py-2 text-sm",
                  active && "bg-gray-200",
                )}>
                {choice.name}
              </div>
            )}
          </Menu.Item>
        ))}
      </div>
    </Menu.Items>
  </Menu>
);

export default RowsPerPageDropdown;
