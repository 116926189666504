import React, { useEffect, useState, useRef } from "react";
import { FilterIcon } from "@heroicons/react/outline";
import PrimaryButton from "../../newUiComponents/commons/PrimaryButton";
import AutocompleteMultiSelectDropdown from "#components/utils/AutocompleteMultiSelectDropdown";
import NewModal from "../../newUiComponents/commons/NewModal";
import { Space, Tooltip, DatePicker, Tag, Divider, Flex } from "antd";
import DateRangePicker from "#components/common/DateRangePicker";
import _ from "lodash";
import { STATUS } from "#components/HOC/withReportsLogic";

import dayjs from "dayjs";
import "dayjs/locale/en";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import moment from "moment-timezone";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
const DATE_FORMAT = "DD-MM-YYYY";
const DATE_RANGES = {
  Today: [dayjs().startOf("day"), dayjs().endOf("day")],
  Yesterday: [
    dayjs().subtract(1, "day").startOf("day"),
    dayjs().subtract(1, "day").endOf("day"),
  ],
  "This week": [
    dayjs().subtract(dayjs().day(), "day").startOf("day"),
    dayjs().endOf("day"),
  ],
  "Last 7 Days": [
    dayjs().subtract(7, "day").startOf("day"),
    dayjs().endOf("day"),
  ],
  "This month": [dayjs().startOf("month"), dayjs().endOf("day")],
  "Last 30 Days": [
    dayjs().subtract(30, "day").startOf("day"),
    dayjs().endOf("day"),
  ],
  "Last Month": [
    dayjs().subtract(1, "month").startOf("month"),
    dayjs().subtract(1, "month").endOf("month"),
  ],
};

const Filters = ({
  reportsMetadata,
  selectedFilterReport,
  setSelectedFilterReport,
  FREQUENCY,
  onChange,
  selectedReportTab,
  reportsTabs,
  clearFilters,
  setShowFilters,
  showFilters,
  onChangeFilter,
  submitFilters,
  filters,
}) => {
  const onRemoveFilterValue = (key, value) => {
    if (key && value) {
      if (key === "createdDateRange") {
        onChangeFilter("dateRange", null, true);
        onChange({
          target: {
            name: "createdDateRange",
            value: null,
          },
        });
      } else {
        let values = selectedFilterReport[key] || [];
        values =
          values && values.length !== 0
            ? values?.includes(value)
              ? values.filter((option) => option !== value)
              : null
            : null;
        onChangeFilter(
          key,
          values && values.length !== 0 ? values : null,
          true,
        );
        onChange({
          target: {
            name: key,
            value: values && values.length !== 0 ? values : null,
          },
        });
      }
    }
  };
  const filterValues = (key, value) => {
    if (value && Array.isArray(value)) {
      return (
        <div className="max-w-xl space-y-2" key={key}>
          <h1 className="text-lg font-semibold">
            {getFilterLables(key)?.label}
          </h1>
          <Divider />
          <Flex gap="8px 10px" wrap>
            {value.map((val) => {
              const isLongTag = val?.length > 10;
              return (
                <Tag
                  key={Math.random()}
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    onRemoveFilterValue(key, val);
                  }}
                  className="relativ m-0 ms-8 box-border flex h-auto flex-row items-center justify-between whitespace-nowrap rounded-full border border-solid bg-white p-2 ps-8 text-start text-xs leading-5 opacity-100">
                  {isLongTag ? (
                    <Tooltip title={val} key={val + "T"}>
                      <span>{`${val.slice(0, 10)}...`}</span>
                    </Tooltip>
                  ) : (
                    <span>{val}</span>
                  )}
                </Tag>
              );
            })}
          </Flex>
        </div>
      );
    }
    if (value && typeof value === "string") {
      const isLongTag = value?.length > 10;
      return (
        <div className="max-w-xl space-y-2" key={key}>
          <h1 className="text-lg font-semibold">
            {getFilterLables(key)?.label}
          </h1>
          <Divider />
          <Flex gap="8px 10px" wrap>
            <Tag
              key={Math.random()}
              closable
              onClose={(e) => {
                e.preventDefault();
                onRemoveFilterValue(key, value);
              }}
              className="relativ m-0 ms-8 box-border flex h-auto flex-row items-center justify-between whitespace-nowrap rounded-full border border-solid bg-white p-2 ps-8 text-start text-xs leading-5 opacity-100">
              {isLongTag ? (
                <Tooltip title={value} key={value + "T"}>
                  <span>{`${value.slice(0, 10)}...`}</span>
                </Tooltip>
              ) : (
                <span>{value}</span>
              )}
            </Tag>
          </Flex>
        </div>
      );
    }
    if (value && typeof value === "object") {
      return null;
    }
    return null;
  };

  return (
    <>
      <Space>
        <PrimaryButton
          onClick={() => setShowFilters(true)}
          icon={<FilterIcon className="h-5 w-5" />}
          className="!hover:border-primaryAccent !hover:text-primaryAccent text-sm font-normal"
          height="2rem"
          primaryColor="black"
          borderColor="!border-primaryAccent !hover:border-primaryAccent !hover:text-primaryAccent"
          textColor="!text-primaryAccent !hover:border-primaryAccent !hover:text-primaryAccent">
          Filters
        </PrimaryButton>
      </Space>
      <NewModal
        isOpen={showFilters}
        onClose={() => {
          setShowFilters(false);
        }}
        title="Filters"
        resetModal={
          <span
            onClick={clearFilters}
            className="mr-4 cursor-pointer font-inter font-medium text-red-500">
            Reset Filter
          </span>
        }>
        <div className="bg-white">
          <div className="w-max-content mb-6 flex flex-col gap-0 space-y-2 px-2">
            <div className="ltr grid grid-cols-1 gap-2">
              {selectedFilterReport &&
              typeof selectedFilterReport === "object" &&
              !Array.isArray(selectedFilterReport)
                ? Object.entries(selectedFilterReport).flatMap(
                    ([key, value]) => {
                      return value && value !== "" && value !== null
                        ? filterValues(key, value)
                        : null;
                    },
                  )
                : null}
            </div>
          </div>
          <div className="w-max-content mb-8 flex flex-col gap-0 space-y-2">
            <div className="w-max-content flex flex-col gap-6 px-2">
              <div className="w-full">
                <AutocompleteMultiSelectDropdown
                  className="w-full"
                  borderPropStyle={
                    "!border-r-0 !border-l-0 !border-t-0 !rounded-none"
                  }
                  key={"reportType"}
                  options={reportsMetadata?.modules}
                  labelKey={"name"}
                  valueKey={"name"}
                  onChange={(value) => {
                    if (value && value?.length !== 0) {
                      onChange({
                        target: {
                          name: "module",
                          value,
                        },
                      });
                      onChangeFilter("module", value, false);
                    } else {
                      onChange({
                        target: {
                          name: "module",
                          value: null,
                        },
                      });
                      onChangeFilter("module", null, false);
                    }
                  }}
                  values={filters["module"]}
                  placeholder="Report Type"
                  multiSelect={true}
                />
              </div>
              {selectedReportTab === reportsTabs[1]["key"] && (
                <div className="w-full">
                  <AutocompleteMultiSelectDropdown
                    className="w-full"
                    borderPropStyle={
                      "!border-r-0 !border-l-0 !border-t-0 !rounded-none"
                    }
                    key={"Frequency"}
                    options={FREQUENCY}
                    labelKey={"name"}
                    valueKey={"id"}
                    onChange={(value) => {
                      if (value && value?.length !== 0) {
                        onChange({
                          target: {
                            name: "frequency",
                            value,
                          },
                        });
                        onChangeFilter("frequency", value, false);
                      } else {
                        onChange({
                          target: {
                            name: "frequency",
                            value: null,
                          },
                        });
                        onChangeFilter("frequency", null, false);
                      }
                    }}
                    values={filters["frequency"]}
                    placeholder="Frequency"
                    multiSelect={true}
                  />
                </div>
              )}
              {selectedReportTab === reportsTabs[0]["key"] && (
                <div className="w-full">
                  <AutocompleteMultiSelectDropdown
                    className="w-full"
                    borderPropStyle={
                      "!border-r-0 !border-l-0 !border-t-0 !rounded-none"
                    }
                    key={"status"}
                    options={STATUS}
                    labelKey={"name"}
                    valueKey={"id"}
                    onChange={(value) => {
                      if (value && value?.length !== 0) {
                        onChange({
                          target: {
                            name: "status",
                            value,
                          },
                        });
                        onChangeFilter("status", value, false);
                      } else {
                        onChange({
                          target: {
                            name: "status",
                            value: null,
                          },
                        });
                        onChangeFilter("status", null, false);
                      }
                    }}
                    values={filters["status"]}
                    placeholder="Status"
                    multiSelect={true}
                  />
                </div>
              )}
              {selectedReportTab === reportsTabs[0]["key"] && (
                <div className="w-full">
                  <div className="w-max-content">
                    <DateRangePicker
                      label={"Created Date Range"}
                      floating={true}
                      pickerClasses="h-16 border-2"
                      format={DATE_FORMAT}
                      ranges={DATE_RANGES}
                      labelClasses={{
                        before: "text-lg left-1 top-1rem",
                        after: "text-base -top-3 left-3",
                        common: "italic text-gray-500",
                        nonFloatingClasses: "text-base left-5 -top-3",
                      }}
                      onChangeDateRange={(dateStrings) => {
                        if (
                          dateStrings &&
                          dateStrings.length !== 0 &&
                          dateStrings[0] !== "" &&
                          dateStrings[1] !== ""
                        ) {
                          let filterDateRange = [];
                          filterDateRange.push(
                            moment(dateStrings[0], "DD-MM-YYYY").format(
                              "YYYY-MM-DD",
                            ),
                          );
                          filterDateRange.push(
                            moment(dateStrings[1], "DD-MM-YYYY").format(
                              "YYYY-MM-DD",
                            ),
                          );
                          onChangeFilter("dateRange", filterDateRange, false);
                          let dateRange = [];
                          dateRange.push(
                            moment(dateStrings[0], "DD-MM-YYYY").format(
                              "DD MMM, YYYY",
                            ),
                          );
                          dateRange.push(
                            moment(dateStrings[1], "DD-MM-YYYY").format(
                              "DD MMM, YYYY",
                            ),
                          );
                          onChange({
                            target: {
                              name: "createdDateRange",
                              value: dateRange,
                            },
                          });
                        } else {
                          onChangeFilter("dateRange", null, false);
                          onChange({
                            target: {
                              name: "createdDateRange",
                              value: null,
                            },
                          });
                        }
                      }}
                      handleDateRangeClear={() => {
                        onChangeFilter("dateRange", null, false);
                        onChange({
                          target: {
                            name: "createdDateRange",
                            value: null,
                          },
                        });
                      }}
                      value={
                        filters["startDateRange"] && filters["endDateRange"]
                          ? [
                              dayjs(filters["startDateRange"]),
                              dayjs(filters["endDateRange"]),
                            ]
                          : []
                      }
                      displayText={
                        filters["startDateRange"] && filters["endDateRange"]
                          ? moment(
                              filters["startDateRange"],
                              "YYYY-MM-DD",
                            ).format("DD-MM-YYYY") +
                            " ~ " +
                            moment(
                              filters["endDateRange"],
                              "YYYY-MM-DD",
                            ).format("DD-MM-YYYY")
                          : ""
                      }
                      disabledDate={(current) => {
                        return current && current > moment().endOf("day");
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-max-content flex flex-col gap-8 space-y-4">
            <div className="flex flex-1 items-end justify-end space-x-2">
              <button
                className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent p-2 px-6 font-semibold text-primaryAccent"
                onClick={() => setShowFilters(false)}>
                Cancel
              </button>
              <button
                className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-2 px-6 font-semibold text-white"
                onClick={() => submitFilters()}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </NewModal>
    </>
  );
};

export const getFilterLables = (status) => {
  switch (status) {
    case "module":
      return { label: "Report Type" };
    case "status":
      return { label: "Status" };
    case "createdDateRange":
      return { label: "Created Date Range" };
    case "frequency":
      return { label: "Frequency" };
  }
};

export default Filters;
