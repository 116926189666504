import React from "react";

const CustomTableWithTheme = ({ children }) => {
  return (
    <div className="relative h-[70vh] w-full overflow-auto text-[16px] xl:text-sm">
      <table className="relative w-full divide-y divide-gray-200 whitespace-nowrap text-[16px]">
        {children}
      </table>
    </div>
  );
};

export default CustomTableWithTheme;
