import withForecastsLogic from "#components/HOC/withForecastsLogic";
import AddButton from "#components/utils/AddButton";
import PageTitle from "#components/utils/PageTitle";
import ForecastsList from "#components/forecasts/ForecastsList";
import ExpandedSku from "#components/inventory/ExpandedSku";

const Forecasts = ({
  forecasts,
  runForecast,
  fetchedSku,
  setFetchedSku,
  getSku,
}) => (
  <div className="p-5">
    <div className="mb-2 flex items-center">
      <div className="flex-1">
        <PageTitle>Forecast</PageTitle>
      </div>

      <div className="items-center justify-end">
        <AddButton text="Run Forecast" onClick={runForecast} />
      </div>
    </div>
    <ForecastsList
      forecasts={forecasts}
      headers={[
        "SKU",
        "Image",
        "Description",
        "Price",
        "Last Sold",
        "Forecast Ran",
        "In Stock",
        "2 week forecast",
        "1 month forecast",
        "Safety stock",
      ]}
      noValuesText="No Forecast"
      getSku={getSku}
    />
    {fetchedSku && (
      <ExpandedSku
        fetchedSku={fetchedSku}
        negativeAction={() => setFetchedSku(null)}
      />
    )}
  </div>
);

export default withForecastsLogic(Forecasts);
