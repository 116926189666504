import Modal from "#components/utils/Modal";
import _ from "lodash";
import PermissionsForm from "./PermissionsForm";

const RolePermissionForm = ({
  onClose,
  title,
  onSubmit,
  permissionOptions,
  onChangePermission,
}) => {
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      positiveAction={onSubmit}
      id="RolePermissionFormModal">
      <PermissionsForm
        onChangePermission={onChangePermission}
        permissionOptions={permissionOptions}
      />
    </Modal>
  );
};

export default RolePermissionForm;
