import React from "react";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { Dropdown } from "antd";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import CustomBadge from "#newUiComponents/commons/CustomBadge";
import { STATUS_ENUM } from "#newUiComponents/bulkUploadV2/useReducer";

export const PAST_BULK_UPLOAD_COLUMNS = [
  { title: "File Name", key: "fileName", isSort: false },
  { title: "Date of Upload", key: "dateOfUpload", isSort: false },
  { title: "Upload Status", key: "fileUploadStatus", isSort: false },
  { title: "Processing Status", key: "processingStatus", isSort: false },
  { title: "Action", key: "action", stickPosition: "right" },
];

const PastUploads = ({ pastUploadsList, menuItems }) => {
  const handleIncomingData = (data) => {
    return data.map((pastUploadRow) => {
      return {
        ...pastUploadRow,
        processingStatus: getStatus(pastUploadRow?.processingStatus),
        fileUploadStatus: getStatus(pastUploadRow?.fileUploadStatus),
        action: getActions(pastUploadRow, menuItems),
      };
    });
  };

  return (
    <div className="relative flex h-full w-full flex-col overflow-auto font-inter">
      <main className="my-2 mt-4 h-full flex-grow overflow-auto">
        <CustomTable
          columns={PAST_BULK_UPLOAD_COLUMNS}
          data={handleIncomingData(pastUploadsList)}
        />
      </main>
    </div>
  );
};

export const getStatusColor = (status) => {
  switch (status) {
    case STATUS_ENUM.IN_PROGRESS:
      return { bgColor: "#BFDBFE", textColor: "#1E40AF" }; // light blue background, dark blue text
    case STATUS_ENUM.COMPLETED:
      return { bgColor: "#D1FAE5", textColor: "#065F46" }; // light green background, dark green text
    case STATUS_ENUM.UPLOADED:
      return { bgColor: "#D1FAE5", textColor: "#065F46" }; // light green background, dark green text
    case STATUS_ENUM.FAILED:
      return { bgColor: "#FECACA", textColor: "#991B1B" }; // light red background, dark red text
    case STATUS_ENUM.QUEUED:
      return { bgColor: "#F1F1F1", textColor: "#6E7173" }; // light gray background, gray text
    case STATUS_ENUM.NOT_UPLOADED:
      return { bgColor: "#F1F1F1", textColor: "#6E7173" }; // light gray background, gray text
    case STATUS_ENUM.PARTIAL_COMPLETE:
      return { bgColor: "#FED7AA", textColor: "#FB923C" }; // light orange background, dark orange text
    default:
      return { bgColor: "#FFFFFF", textColor: "#000000" }; // white background, black text
  }
};

export const getActions = (bulkUpload, menuItems) => {
  if (bulkUpload?.fileUploadStatus === STATUS_ENUM.UPLOADED) {
    return (
      <Dropdown
        trigger={["hover"]}
        menu={{
          items: menuItems(bulkUpload),
        }}
        overlayClassName="z-50"
        className="z-50"
        dropdownRender={({ props }) => {
          return (
            <div className="w-auto rounded-md border bg-white p-2 text-sm text-gray-600 shadow-md">
              {props.items.map((item) => (
                <div
                  onClick={() => item["onClick"](bulkUpload)}
                  key={item.title}
                  className="cursor-pointer p-2 hover:bg-hoverHighlight hover:text-primaryAccent">
                  {item.title}
                </div>
              ))}
            </div>
          );
        }}
        placement="topRight"
        arrow>
        <DotsVerticalIcon className="h-6 w-6 cursor-pointer text-gray-400" />
      </Dropdown>
    );
  }
  return <DotsVerticalIcon className="h-6 w-6 text-gray-400" />;
};

export const getStatus = (status) => {
  if (status) {
    return (
      <CustomBadge
        textSize="text-sm"
        label={status
          ?.toLowerCase()
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
        textColor={getStatusColor(status)?.textColor}
        bgColor={getStatusColor(status)?.bgColor}
      />
    );
  }
  return null;
};

export default PastUploads;
