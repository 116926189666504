import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const ToteForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedTote,
  onSubmit,
  customers,
  warehouses,
  onChangeMultiSelect,
  toteTypes,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Tote Details
        </span>
      </div>

      <div>
        <Dropdown
          placeholder={"Select Tote Type"}
          list={
            toteTypes
              ? [...toteTypes, { name: "Add Custom" }]
              : [{ name: "Add Custom" }]
          }
          labelKey="name"
          valueKey="name"
          name="toteType"
          setSelected={(e) => {
            onChangeDropdown("toteType", e);
          }}
          selectedValue={selectedTote.toteType}
        />
      </div>
      <div>
        <TextField
          type="number"
          id="numberOfTotes"
          label="Number of Totes"
          placeholder=" "
          onChange={onChange}
          value={selectedTote.numberOfTotes}
          min="0"
        />
      </div>
      <div>
        <TextField
          type="number"
          id="namingStart"
          label="Naming Sequence Start"
          placeholder=" "
          onChange={onChange}
          value={selectedTote.namingStart}
        />
      </div>
      <div>
        <TextField
          type="number"
          id="namingIncrement"
          label="Naming Sequence Increment"
          placeholder=" "
          onChange={onChange}
          value={selectedTote.namingIncrement}
        />
      </div>
    </div>
    {warehouses.length > 1 && (
      <div className="mt-4">
        <div id="selectingStations">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Select Warehouses
            </span>
          </label>
          <MultiSelectAutoComplete
            options={warehouses}
            labelKey={"name"}
            valueKey={"id"}
            setValues={(selectedOptions) =>
              onChangeMultiSelect("warehouses", selectedOptions)
            }
            values={selectedTote.warehouses || []}
            emptyValuesAccountsForAll={true}
          />
        </div>
      </div>
    )}
    {customers.length > 1 && (
      <div className="mt-4">
        <div id="selectingStations">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Select Clients
            </span>
          </label>
          <MultiSelectAutoComplete
            options={getFilteredCustomerList(
              selectedTote.warehouses,
              customers,
            )}
            labelKey={"name"}
            valueKey={"id"}
            setValues={(selectedOptions) =>
              onChangeMultiSelect("customers", selectedOptions)
            }
            values={selectedTote.customers || []}
            emptyValuesAccountsForAll={true}
          />
        </div>
      </div>
    )}
  </Modal>
);

export default ToteForm;
