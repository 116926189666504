const ResolvementConfirmation = ({
  orders,
  partiallyFulfillableOrders,
  selectedOrderIdsForBatching,
}) => {
  const selectedOrders =
    orders?.filter((order) => selectedOrderIdsForBatching.includes(order.id)) ||
    [];

  const partiallyFulfillableOrderIds = partiallyFulfillableOrders.map(
    (backOrder) => backOrder.originalOrder.id,
  );

  return (
    <>
      <p className="py-2 text-base font-semibold">Selected Orders</p>
      {/* table with order and status */}
      <table className="min-w-full divide-y divide-gray-200 border border-gray-300 px-2">
        <thead className="rounded-full bg-primaryAccent p-4 px-12">
          <tr className="border-left px-12 font-montserrat text-textWhite">
            <th
              scope="col"
              className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
              key={"orderId"}>
              Order ID
            </th>
            <th
              scope="col"
              className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
              key={"status"}>
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedOrders.map((order, index) => (
            <tr
              key={order.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {order.orderId}
              </td>
              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                <p className="w-max rounded-2xl border-2 border-black p-1 text-base">
                  {partiallyFulfillableOrderIds.includes(order.id)
                    ? "Partially Fulfillable"
                    : "Fulfillable"}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ResolvementConfirmation;
