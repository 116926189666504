/**
 * String.prototype.replaceAll() polyfill
 *
 * This is to ensure if the replaceAll method is not available in the browser, it will be polyfilled.
 * This happens in the case of IE11.
 */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (search, replacement) {
    const target = this;

    if (!(search instanceof RegExp)) {
      // Escape special characters if 'search' is a string
      search = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      search = new RegExp(search, "g");
    }

    return target.replace(search, replacement);
  };
}

import "#styles/globals.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Sentry from "@sentry/react";
import { useEffect, useContext, useState } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "#config/routes";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";
import Layout from "#components/common/Layout";
import { useQuery } from "#hooks/useQuery";
import {
  GET_LOGGED_IN_USER,
  GET_CURRENT_TENANT,
  GET_INTEGRATIONS,
  GET_UOM_LABELS,
} from "#queries";
import { StatDashboardProvider } from "#contexts/statsDashboard";

import ErrorBoundary from "#components/common/ErrorBoundary";
import { StatProvider } from "#contexts/stat";
import { EntityProvider } from "#contexts/entity";
import { CubeProvider } from "@cubejs-client/react";
const { parse } = require("tldts");
const config = require("config");
import JSPM from "jsprintmanager";
import axios from "axios";
import { BulkUploadModalProvider } from "../components/bulkUpload/useReducer";
import { BulkUploadV2ModalProvider } from "#newUiComponents/bulkUploadV2/useReducer";
import AccountSuspended from "./AccountSuspended";
import Login from "./login";
import { FeatureFlagsProvider } from "#contexts/featureFlags";

const App = () => {
  const appState = useContext(AppStateContext);
  const {
    removeLoading,
    setAlert,
    setIntegrations,
    setLoading,
    setSubdomain,
    setTenant,
    tenant,
  } = useContext(AppStateContext);
  const auth = useContext(AuthContext);
  const masterData = useContext(MasterDataContext);
  const { loading, error, data, fetchData } = useQuery(GET_LOGGED_IN_USER);
  const integrationsQuery = useQuery(GET_INTEGRATIONS);
  const getUomLabelsQuery = useQuery(GET_UOM_LABELS);
  const ldClient = useLDClient();

  useEffect(() => {
    const tenantId = appState?.tenant?.id?.toString() ?? "";
    if (tenantId) {
      ldClient
        .identify({
          kind: "user",
          key: tenantId,
        })
        .catch((err) => {
          console.log(
            "error occured when interacting with launch darkly. ",
            err.message,
          );
        });

      // fetch the uom labels for this tenant
      getUomLabelsQuery.fetchData({
        includeInactives: true,
      });
    }
  }, [tenant]);

  // setting the uom labels in the app state context when the query is successful
  useEffect(() => {
    if (getUomLabelsQuery.data) {
      appState.setUomLabels(getUomLabelsQuery.data?.getUomLabels?.labels);
    }

    // fallback uom labels in case of error - Each, Case, Carton, Pallet
    if (getUomLabelsQuery.error) {
      console.error("Error fetching uom labels", getUomLabelsQuery.error);
      appState.setUomLabels([
        {
          name: "Each",
          isActive: true,
        },
        {
          name: "Case",
          isActive: true,
        },
        {
          name: "Carton",
          isActive: true,
        },
        {
          name: "Pallet",
          isActive: true,
        },
      ]);
    }
  }, [getUomLabelsQuery.data, getUomLabelsQuery.error]);

  useEffect(() => {
    JSPM.JSPrintManager.license_url = `https://api.delmar.hopstack.io/jspm`;
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = function () {
      if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        JSPM.JSPrintManager.getPrinters()
          .then((res) => appState.setPrinters(res))
          .catch((error) => appState.setPrinters([]));
      }
    };
  }, []);

  // useEffect(() => {
  //   if (currentTenantQuery.loading) {
  //     appState.setLoading();
  //   } else {
  //     appState.removeLoading();
  //   }

  //   if (currentTenantQuery.error) {
  //     appState.setAlert(currentTenantQuery.error.message, "error", 5000);
  //     appState.setTenant(null);
  //   }

  //   if (currentTenantQuery.data) {
  //     appState.setTenant(currentTenantQuery.data.currentTenant);
  //     fetchData();
  //   }
  // }, [
  //   currentTenantQuery.data,
  //   currentTenantQuery.error,
  //   currentTenantQuery.loading,
  // ]);

  useEffect(() => {
    fetchData();
    appState.setAdminPageBgColor("bg-EBEBEB");
  }, [window.location.pathname]);

  useEffect(() => {
    appState.setLoading();
    const parsed = parse(window.location.href);

    if (!!!parsed.subdomain) {
      if (process.env.NODE_ENV !== "development") {
        appState.setAlert(`Access Denied.`, "error", 5000);
      } else {
        // currentTenantQuery.fetchData();
        Sentry.setTag("tenant", config.DEFAULT_SUBDOMAIN);
        setSubdomain(config.DEFAULT_SUBDOMAIN);
        axios
          .get(
            `${config.TENANT_SERVICE}/api/tenants/findTenantBySubdomain?subdomain=${config.DEFAULT_SUBDOMAIN}`,
          )
          .then((response) => {
            appState.setTenant(response.data);
            fetchData();
          })
          .catch((error) => {
            appState.setSubdomain(null);
            appState.setTenant(null);
            appState.setAlert(`Invalid Tenant`, "error", 5000);
          });
      }
    } else {
      setSubdomain(parsed.subdomain);
      Sentry.setTag("tenant", parsed.subdomain);
      axios
        .get(
          `${config.TENANT_SERVICE}/api/tenants/findTenantBySubdomain?subdomain=${parsed.subdomain}`,
        )
        .then((response) => {
          appState.setTenant(response.data);
          fetchData();
        })
        .catch((error) => appState.setAlert(`Invalid Tenant`, "error", 5000));
    }
  }, []);

  useEffect(async () => {
    if (!loading && error) {
      appState.removeLoading();
      auth.logout();
    }
    // if (loading) {
    //   appState.setLoading();
    // }
    if (data && data.me) {
      appState.removeLoading();
      auth.login({
        ...data.me,
        token: localStorage.getItem("token"),
      });
      Sentry.setUser({
        id: `${tenant?.name} - ${data.me?.id} - ${data.me?.email}`,
      });
      Sentry.setTag("email", data.me?.email || "");
      Sentry.setTag("name", data.me?.name || "");
      Sentry.setTag("tenant", tenant?.name || "");
      masterData.fetchMasterData();
      if (data.me.customers && data.me.customers.length === 1) {
        integrationsQuery.fetchData({
          filters: {
            customer: data.me.customers[0],
            warehouse: data.me.warehouses[0],
            integrationType: ["Amazon"],
          },
        });
      }
    }
  }, [loading, error, data]);

  useEffect(() => {
    if (integrationsQuery.data) {
      appState.setIntegrations(integrationsQuery.data.integrations);
    }

    if (integrationsQuery.error) {
      appState.setIntegrations([]);
    }
  }, [integrationsQuery.data, integrationsQuery.error]);

  useEffect(() => {
    if (!!auth.isAuthenticated && !!auth.token) {
      if (
        tenant &&
        tenant.paymentDetails &&
        tenant.paymentDetails.isPaymentDue === true &&
        auth &&
        auth.user &&
        auth.user.role &&
        (auth.user.role.toLowerCase() === "admin" ||
          auth.user.role.toLowerCase() === "associate" ||
          (auth.user.permissions &&
            auth.user.permissions.findIndex(
              (item) => item.route === "/warehouses" && item.writable,
            ) !== -1 &&
            auth.user.permissions.findIndex(
              (item) => item.route === "/users" && item.writable,
            ) !== -1 &&
            auth.user.permissions.findIndex(
              (item) => item.route === "/customers" && item.writable,
            ) !== -1))
      ) {
        const disableDate = new Date(
          tenant.paymentDetails.earliestUnpaidInvoiceDate,
        );
        disableDate.setDate(
          disableDate.getDate() + tenant.paymentDetails.disableThresholdInDays,
        );
        const currentDate = new Date();

        if (currentDate > disableDate) {
          appState.removeLoading();
          window.location = "/account-suspended";
        }
      }
    }
  }, [auth.isAuthenticated, auth.user, appState.tenant]);

  return (
    <ErrorBoundary>
      <StatProvider>
        <FeatureFlagsProvider>
          <EntityProvider>
            <StatDashboardProvider>
              <BulkUploadV2ModalProvider>
                <BulkUploadModalProvider>
                  <Layout>
                    <Router>
                      <Switch>
                        <Route component={Routes} />
                      </Switch>
                    </Router>
                  </Layout>
                </BulkUploadModalProvider>
              </BulkUploadV2ModalProvider>
            </StatDashboardProvider>
          </EntityProvider>
        </FeatureFlagsProvider>
      </StatProvider>
    </ErrorBoundary>
  );
};

export default Sentry.withErrorBoundary(App, {
  fallback: "An error has occurred",
});
