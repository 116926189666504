import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CustomTable from "#newUiComponents/commons/CustomTable";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { DuplicateIcon, TrashIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";

const NewRateShoppingLtlPallets = ({ onPalletSubmit, palletInfo }) => {
  const [pallets, setPallets] = useState([]);

  // Handle change in input fields
  const handleInputChange = (index, field, value) => {
    const updatedPallets = pallets.map((pallet, idx) =>
      idx === index ? { ...pallet, [field]: value } : pallet,
    );
    setPallets(updatedPallets);
  };

  // Add a new pallet
  const addPallet = () => {
    setPallets([
      ...pallets,
      { length: null, width: null, height: null, weight: null },
    ]);
  };

  // Duplicate a pallet
  const duplicatePallet = (index) => {
    const newPallet = { ...pallets[index] };
    setPallets([...pallets, newPallet]);
  };

  // Remove a pallet
  const removePallet = (index) => {
    const updatedPallets = pallets.filter((_, idx) => idx !== index);
    setPallets(updatedPallets);
  };

  // Save pallets by submitting the current state to parent
  const savePallets = () => {
    onPalletSubmit && onPalletSubmit(pallets);
  };

  // Function to handle table data
  const handlePalletsForTable = () => {
    return pallets.map((pallet, index) => ({
      index: index + 1,
      palletDimensions: (
        <div className="flex items-center gap-2">
          <input
            type="text"
            value={pallet.length || ""}
            placeholder="L"
            className="h-10 w-10 rounded-md border-gray-300 p-2 text-center shadow-md"
            onChange={(e) =>
              handleInputChange(index, "length", parseFloat(e.target.value))
            }
          />
          <input
            type="text"
            value={pallet.width || ""}
            placeholder="W"
            className="h-10 w-10 rounded-md border-gray-300 p-2 text-center shadow-md"
            onChange={(e) =>
              handleInputChange(index, "width", parseFloat(e.target.value))
            }
          />
          <input
            type="text"
            value={pallet.height || ""}
            placeholder="H"
            className="h-10 w-10 rounded-md border-gray-300 p-2 text-center shadow-md"
            onChange={(e) =>
              handleInputChange(index, "height", parseFloat(e.target.value))
            }
          />
        </div>
      ),
      weight: (
        <input
          type="number"
          value={pallet.weight || ""}
          className="h-10 w-36 rounded-md border-gray-300 p-2 text-center shadow-md"
          placeholder="Enter Weight"
          onChange={(e) =>
            handleInputChange(index, "weight", parseFloat(e.target.value))
          }
        />
      ),
      action: (
        <div className="flex items-center space-x-2">
          <Tooltip title="Duplicate Pallet">
            <DuplicateIcon
              className="h-6 w-6 cursor-pointer text-primaryAccent"
              onClick={() => duplicatePallet(index)}
            />
          </Tooltip>

          <Tooltip title="Delete Pallet">
            <TrashIcon
              className="h-6 w-6 cursor-pointer text-red-500"
              onClick={() => removePallet(index)}
            />
          </Tooltip>
        </div>
      ),
    }));
  };

  const columns = [
    { title: "#", key: "index", isSort: false },
    {
      title: "Pallet Dimensions (in) (l*b*h)",
      key: "palletDimensions",
      isSort: false,
    },
    { title: "Weight (lb)", key: "weight", isSort: false },
    { title: "Action", key: "action", isSort: false },
  ];

  useEffect(() => {
    setPallets(
      palletInfo.length > 0
        ? palletInfo
        : [
            {
              length: null,
              width: null,
              height: null,
              weight: null,
            },
          ],
    );
  }, [palletInfo]);

  return (
    <div className="mb-5 rounded-lg border-2 p-4">
      <CustomTable
        columns={columns}
        data={handlePalletsForTable()}
        isPagination={false}
        isFilters={false}
        isSearchable={false}
      />
      <div className="mt-4">
        <PrimaryButton
          height="3rem"
          width="6rem"
          variant="secondary"
          className="mt-2 border-none text-base font-medium"
          onClick={addPallet}>
          + Add Pallet
        </PrimaryButton>
      </div>
      <div className="mt-4 flex justify-end">
        <PrimaryButton
          height="3rem"
          width="6rem"
          variant="primary"
          className="mt-2 text-base font-medium"
          onClick={savePallets} // Save data on button click
        >
          Save
        </PrimaryButton>
      </div>
    </div>
  );
};

NewRateShoppingLtlPallets.propTypes = {
  onPalletSubmit: PropTypes.func,
  palletInfo: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      weight: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default NewRateShoppingLtlPallets;
