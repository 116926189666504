import cellStyleForTable from "#components/common/CellStyleForTable";
import PageTitle from "#components/utils/PageTitle";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import { getHeaderObject } from "#utils/getHeaderObject";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
} from "@heroicons/react/solid";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import Modal from "#components/utils/Modal";
import { useQuery } from "#hooks/useQuery";
import { GET_ALL_BATCHES } from "#queries/index";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { EntityContext } from "#contexts/entity";
import CustomSelectDropDown from "#components/common/CustomSelectDropDown";
import { AppStateContext } from "#contexts/appState";

const CONSIGNMENTS_PER_PAGE = 25;
const ALERT_TIMEOUT_IN_MS = 5000;

const typeList = [
  {
    label: "ALL",
    value: "",
  },
  {
    label: "SKU",
    value: "sku",
  },
  {
    label: "LPN",
    value: "nestedFormFactorId",
  },
  {
    label: "PALLET",
    value: "palletId",
  },
  {
    label: "TOTE",
    value: "tote",
  },
];

const renderSort = (header, sort) => {
  if (!header || !header.sortable) {
    return null;
  }

  if (sort && sort.replace("-", "") === header.correspondingValue) {
    if (sort.indexOf("-") !== -1) {
      return <ChevronDownIcon className="h-6 w-6 text-green-400" />;
    }

    return <ChevronUpIcon className="h-6 w-6 text-green-400" />;
  }
  return <ChevronUpIcon className="h-6 w-6 text-gray-400" />;
};

const ListView = ({ scanBarcode, currentScan }) => {
  const batchesQuery = useQuery(GET_ALL_BATCHES);
  const entity = useContext(EntityContext);
  const appState = useContext(AppStateContext);

  const [consignments, setConsignments] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [consignmentsPageNo, setConsignmentsPageNo] = useState(1);
  const [selectedType, setSelectedType] = useState(typeList[0]);
  const sort = entity.sort;

  if (!consignments) {
    return null;
  }

  const headers = (() => {
    const returnHeaders = [];

    if (consignments.some((item) => !!item.asin)) {
      returnHeaders.push(getHeaderObject("ASIN", "asin", false));
    }
    returnHeaders.push(getHeaderObject("Unique ID", "sku", false));
    returnHeaders.push(getHeaderObject("Type", "type", false));
    returnHeaders.push(
      getHeaderObject("Total Product Qty", "availableQuantity", false),
    );
    returnHeaders.push(getHeaderObject("Product Info", "productInfo", false));
    returnHeaders.push(
      getHeaderObject("Received Date", "receivedDate", true, "createdAt"),
    );
    returnHeaders.push(getHeaderObject("Action", "action", false));

    return returnHeaders;
  })();

  const onChangeSearchKeyword = async (keyword) => {
    const response = await batchesQuery.fetchData({
      filters: {
        typeOfBatch: "RECEIVING",
        status: ["COMPLETED", "PUTAWAY_STARTED"],
        keyword: keyword.trim(),
        fieldName: selectedType.value,
        customers: [currentScan?.customer],
      },
      strictTimeRange: false,
      perPage: CONSIGNMENTS_PER_PAGE,
      pageNumber: 1,
      sort: entity.sort,
    });

    if (response.data?.getAllBatches) {
      setConsignments(response.data.getAllBatches.entities);
    }
  };

  const debouncedProductSearch = _.debounce(onChangeSearchKeyword, 500);

  const checkConsignmentPagination = async () => {
    if (
      batchesQuery.data?.getAllBatches?.total >
      (consignmentsPageNo - 1) * CONSIGNMENTS_PER_PAGE
    ) {
      const vars = {
        filters: {
          typeOfBatch: "RECEIVING",
          status: ["COMPLETED", "PUTAWAY_STARTED"],
          keyword: searchKeyword.trim(),
          fieldName: selectedType.value,
          customers: [currentScan?.customer],
        },
        strictTimeRange: false,
        perPage: CONSIGNMENTS_PER_PAGE,
        pageNumber: consignmentsPageNo,
        sort: entity.sort,
      };
      return await batchesQuery.fetchData(vars);
    }
  };

  const handleConsigmentsScroll = async (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const threshold = 1;
    if (scrollTop + clientHeight + threshold >= scrollHeight) {
      const response = await checkConsignmentPagination();

      if (response?.data?.getAllBatches) {
        setConsignments((prev) => [
          ...prev,
          ...response.data.getAllBatches.entities,
        ]);
        setConsignmentsPageNo((prev) => prev + 1);
      }
    }
  };

  const getProductIdentifier = (product) => {
    if (product?.palletId) return { type: "Pallet", id: product.palletId };
    else if (product?.nestedFormFactorId)
      return {
        type: `LPN-${product.nestedFormFactor}`,
        id: product.nestedFormFactorId,
      };
    else if (product?.tote) return { type: "Tote", id: product.tote };
    else return { type: "SKU", id: product?.items[0].sku };
  };

  const getProductFormFactor = (productId) => {
    return consignments.find((item) => item.id === productId)?.baseUom;
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const setSort = async (key) => {
    const sort = entity.sort === key ? `-${key}` : key;

    entity.setSort({ sort });

    const response = await batchesQuery.fetchData({
      filters: {
        typeOfBatch: "RECEIVING",
        status: ["COMPLETED", "PUTAWAY_STARTED"],
        keyword: searchKeyword.trim(),
        fieldName: selectedType.value,
        customers: [currentScan?.customer],
      },
      strictTimeRange: false,
      perPage: CONSIGNMENTS_PER_PAGE,
      pageNumber: 1,
      sort,
    });

    if (response.data?.getAllBatches) {
      setConsignments(response.data.getAllBatches.entities);
    }
  };

  useEffect(() => {
    (async () => {
      entity.setSort({ sort: "createdAt" });

      const response = await batchesQuery.fetchData({
        filters: {
          typeOfBatch: "RECEIVING",
          status: ["COMPLETED", "PUTAWAY_STARTED"],
          keyword: searchKeyword.trim(),
          fieldName: selectedType.value,
          customers: [currentScan?.customer],
        },
        strictTimeRange: false,
        perPage: CONSIGNMENTS_PER_PAGE,
        pageNumber: 1,
        sort: "createdAt",
      });

      if (response.data?.getAllBatches) {
        setConsignments(response.data.getAllBatches.entities);
        setConsignmentsPageNo((prev) => prev + 1);
      }
    })();
  }, [selectedType.value]);

  useEffect(() => {
    if (batchesQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (batchesQuery.error) {
      appState.setAlert(
        batchesQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [batchesQuery.loading, batchesQuery.error]);

  return (
    <div className="max-w-7xl flex-1 items-center justify-center">
      <div className="flex justify-between">
        <PageTitle>Total ({consignments?.length})</PageTitle>

        <div className="items-right flex">
          <CustomSelectDropDown
            options={typeList}
            placeholder="Select Type"
            defaultValue={typeList[0]}
            onChange={(e) => {
              setSelectedType(e);
            }}
            className="min-w-1/4"
          />
        </div>
      </div>

      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            aria-hidden="true"
            className="h-5 w-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="text-md block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          placeholder="Search"
          onChange={(e) => {
            setSearchKeyword(e.target.value);

            if (e.target.value === "") {
              debouncedProductSearch(e.target.value?.trim());
            }
          }}
          value={searchKeyword}
          onKeyDown={(e) =>
            e.key === "Enter"
              ? debouncedProductSearch(e.target.value?.trim())
              : {}
          }
        />
      </div>

      <div
        className="m-auto mt-10 overflow-auto rounded-md bg-EBEBEB"
        style={{ maxHeight: "500px" }}
        onScroll={handleConsigmentsScroll}>
        <table className="m-auto -mt-4 divide-y divide-gray-200">
          <thead className="rounded-full p-4">
            <tr className="border-left font-montserrat text-primaryAccent">
              {headers.map((header, headerIdx) => (
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}
                  onClick={() => {
                    if (!header.sortable) {
                      return;
                    }
                    setSort(header.sortBy);
                  }}>
                  <div className="flex cursor-pointer items-center">
                    {header.name}
                    {renderSort(header, sort)}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!batchesQuery.loading && consignments.length === 0 ? (
              <tr>
                <td className="rounded-br rounded-tr px-1 py-2 pl-2 text-left font-medium tracking-wider text-tableText">
                  No items found
                </td>
              </tr>
            ) : null}
            {consignments.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"} border-l-8 ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {headers.map((header, colIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    [],
                    colIndex + 1,
                    index,
                    true,
                  );

                  if (value === "asin" && item?.asin) {
                    return (
                      <td className={classNames(cellStyle, "select-text")}>
                        {buildMarketplaceHyperlink(
                          item.asin,
                          "ASIN",
                          item.source,
                        )}
                      </td>
                    );
                  }

                  if (value === "sku") {
                    return (
                      <td className={cellStyle}>
                        {getProductIdentifier(item).id}
                      </td>
                    );
                  }

                  if (value === "type") {
                    return (
                      <td className={cellStyle}>
                        {getProductIdentifier(item).type}
                      </td>
                    );
                  }

                  if (value === "availableQuantity") {
                    return (
                      <td className={cellStyle}>{item.availableQuantity}</td>
                    );
                  }

                  if (value === "productInfo") {
                    return (
                      <td className={cellStyle}>
                        <EyeIcon
                          className="h-7 w-14 cursor-pointer text-primaryAccent"
                          onClick={() => setProductsData(item?.items)}
                        />
                      </td>
                    );
                  }

                  if (value === "receivedDate") {
                    return (
                      <td className={cellStyle}>
                        {item?.attributes?.lastCompletedTime
                          ? moment(item.attributes.lastCompletedTime).format(
                              "YYYY-MM-DD",
                            )
                          : moment(item?.createdAt).format("YYYY-MM-DD")}
                      </td>
                    );
                  }

                  if (value === "action") {
                    return (
                      <td className={cellStyle}>
                        <div
                          className="cursor-pointer rounded-full bg-blue-500 px-2 py-1 text-center text-lg text-white"
                          onClick={() =>
                            scanBarcode({
                              data: getProductIdentifier(item).id,
                            })
                          }>
                          Work on this
                        </div>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {productsData && productsData.length > 0 && (
        <Modal
          negativeAction={() => setProductsData(null)}
          title={`Items (${productsData.length})`}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="rounded-full p-4">
              <tr className="font-montserratborder-left bg-primaryAccent text-white">
                {productsData.some((item) => item.asin).length > 0 && (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    ASIN
                  </th>
                )}

                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  SKU
                </th>

                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Name
                </th>
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Quantity
                </th>
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Form Factor
                </th>
              </tr>
            </thead>
            <tbody>
              {productsData.map((item, index) => (
                <tr
                  key={item.sku}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  {item.asin && (
                    <td
                      className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                        index % 2 === 0
                          ? "border-F4C261"
                          : "border-primaryAccent"
                      }`}>
                      {buildMarketplaceHyperlink(
                        item.asin,
                        "ASIN",
                        item.source,
                      )}
                    </td>
                  )}
                  <td className="max-w-2xl px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.sku}
                  </td>
                  <td className="max-w-2xl px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.name}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.quantity}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {getProductFormFactor(item.id)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default ListView;
