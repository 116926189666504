import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useQuery } from "#hooks/useQuery";
import moment from "moment-timezone";
import {
  GET_BILLING_PROFILES,
  GET_CUSTOMERS,
  GET_BILLING_PROFILE,
  GET_CUSTOMER_USAGE,
  GET_CUSTOMER,
  GET_INVOICES,
  GET_INVOICE,
  GENERATE_CUSTOMER_INVOICE,
} from "#queries";
import {
  SAVE_BILLING_PROFILE,
  DELETE_BILLING_PROFILE,
  ASSIGN_BILLING_PROFILE,
  SAVE_INVOICE,
  DELETE_INVOICE,
  STRIPE_PAY,
} from "#mutations";
import _, { filter } from "lodash";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";

const withInvoiceLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const customerQuery = useQuery(GET_CUSTOMER);
    const billingProfilesQuery = useQuery(GET_BILLING_PROFILES);
    const assignBillingProfileQuery = useQuery(ASSIGN_BILLING_PROFILE);
    const generateCustomerInvoiceQuery = useQuery(GENERATE_CUSTOMER_INVOICE);
    const getCustomerBillUsageQuery = useQuery(GET_CUSTOMER_USAGE);
    const stripePayQuery = useQuery(STRIPE_PAY);

    const customerInvoicesQuery = useQuery(GET_INVOICES);
    const invoiceQuery = useQuery(GET_INVOICE);
    const saveInvoiceQuery = useQuery(SAVE_INVOICE);
    const deleteInvoiceQuery = useQuery(DELETE_INVOICE);

    const [showCustomerInvoice, setShowCustomerInvoice] = useState(null);
    const [selectedBillingProfile, setSelectedBillingProfile] = useState(null);
    const [showAssignBillingProfileForm, setShowAssignBillingProfileForm] =
      useState(false);

    let startMonth = moment().month() + 1;
    startMonth = startMonth < 10 ? `0${startMonth}` : `${startMonth}`;
    let endMonth = moment().month() + 2;
    endMonth = endMonth < 10 ? `0${endMonth}` : `${endMonth}`;
    const [filters, setFilters] = useState({
      startDate: `${moment().year()}-${startMonth}-01`,
      endDate:
        moment().format("YYYY-MM-DD") /*`${moment().year()}-${endMonth}-01`*/,
      type: "inbound",
    });

    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [showDetailedInvoice, setShowDetailedInvoice] = useState(null);
    const [showCustomChargeForm, setShowCustomChargeForm] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [detailedUsage, setDetailedUsage] = useState(null);

    useEffect(() => {
      billingProfilesQuery.fetchData({});
      customerInvoicesQuery.fetchData({
        perPage: 100,
        filters: entity.filters,
        paginated: false,
        pageNumber: 1,
      });

      return () => {
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      if (customersQuery.data) {
        entity.setEntities({
          ...customersQuery.data.customers,
          ...customersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [customersQuery.loading, customersQuery.error, customersQuery.data]);

    useEffect(() => {
      if (stripePayQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (stripePayQuery.error) {
        alert(stripePayQuery.error.message);
      }

      if (stripePayQuery.data) {
        window.open(stripePayQuery.data.pay.url);
      }
    }, [stripePayQuery.error, stripePayQuery.data, stripePayQuery.loading]);

    useEffect(() => {
      if (getCustomerBillUsageQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (
        getCustomerBillUsageQuery.data &&
        getCustomerBillUsageQuery.data.usage
      ) {
        setDetailedUsage(getCustomerBillUsageQuery.data.usage);
      }
    }, [
      getCustomerBillUsageQuery.loading,
      getCustomerBillUsageQuery.error,
      getCustomerBillUsageQuery.data,
    ]);

    useEffect(() => {
      if (generateCustomerInvoiceQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (generateCustomerInvoiceQuery.data) {
        setSelectedInvoice({
          ...selectedInvoice,
          customerId: selectedCustomer.id,
          usage: generateCustomerInvoiceQuery.data.generateInvoice,
        });
      }
    }, [
      generateCustomerInvoiceQuery.loading,
      generateCustomerInvoiceQuery.error,
      generateCustomerInvoiceQuery.data,
    ]);

    useEffect(() => {
      if (customerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (customerQuery.data?.customer) {
        setSelectedCustomer(customerQuery.data?.customer);
      }
    }, [customerQuery.loading, customerQuery.error, customerQuery.data]);

    useEffect(() => {
      if (customerInvoicesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (customerInvoicesQuery.data?.invoices) {
        setShowCustomerInvoice(customerInvoicesQuery.data?.invoices?.data);
      }
    }, [
      customerInvoicesQuery.loading,
      customerInvoicesQuery.error,
      customerInvoicesQuery.data,
    ]);

    useEffect(() => {
      if (saveInvoiceQuery.data) {
        appState.setAlert(
          saveInvoiceQuery.data.saveInvoice.message,
          "success",
          5000,
        );
        customerInvoicesQuery.fetchData({
          customerId: customerQuery.data?.customer.id,
        });
      }

      if (saveInvoiceQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveInvoiceQuery.error) {
        appState.setAlert(saveInvoiceQuery.error.message, "error", 5000);
      }
    }, [
      saveInvoiceQuery.loading,
      saveInvoiceQuery.error,
      saveInvoiceQuery.data,
    ]);

    useEffect(() => {
      if (deleteInvoiceQuery.data) {
        appState.setAlert(
          deleteInvoiceQuery.data.deleteInvoice.message,
          "success",
          5000,
        );
        customerInvoicesQuery.fetchData({
          customerId: customerQuery.data?.customer.id,
        });
      }

      if (deleteInvoiceQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteInvoiceQuery.error) {
        appState.setAlert(deleteInvoiceQuery.error.message, "error", 5000);
      }
    }, [
      deleteInvoiceQuery.loading,
      deleteInvoiceQuery.error,
      deleteInvoiceQuery.data,
    ]);

    useEffect(() => {
      if (billingProfilesQuery.data && billingProfilesQuery.data.batches) {
      }
    }, [
      billingProfilesQuery.loading,
      billingProfilesQuery.error,
      billingProfilesQuery.data,
    ]);

    const onChangeFilter = (key, value) => {
      setFilters({ ...filters, [key]: value });
    };

    const onChangeInvoice = (key, value) => {
      const lineItems = selectedInvoice[key] ? [...selectedInvoice[key]] : [];

      const newCustomCharges = _.uniqBy(
        [...lineItems, value].map((lineItem) => {
          if (lineItem.chargeType === value.chargeType) {
            return value;
          }
          return lineItem;
        }),
        "chargeType",
      );
      setSelectedInvoice({ ...selectedInvoice, [key]: newCustomCharges });
    };

    const deleteCustomCharge = (key, chargeType) => {
      setSelectedInvoice({
        ...selectedInvoice,
        [key]: (selectedInvoice[key] ? selectedInvoice[key] : []).filter(
          (item) => item.chargeType !== chargeType,
        ),
      });
    };
    return (
      <WrappedComponent
        customers={entity.entities}
        showCustomerInvoice={showCustomerInvoice}
        setShowCustomerInvoice={(e) => {
          setShowCustomerInvoice(e);
          setSelectedCustomer(e);
        }}
        onClickCustomerInvoice={
          (customer) => {
            customerInvoicesQuery.fetchData({ customerId: customer.id });
            customerQuery.fetchData({ id: customer.id });
          } // Invoice Query
        }
        currentBillingProfile={
          customerQuery.data?.customer?.currentBillingProfile
        }
        currency={customerQuery.data?.customer.currency || "$"}
        filters={filters}
        onChangeFilter={onChangeFilter}
        submitInvoiceDateRange={() => {
          generateCustomerInvoiceQuery.fetchData({
            customerId: selectedCustomer?.id,
            ...filters,
          });
          getCustomerBillUsageQuery.fetchData({
            customerId: selectedCustomer?.id,
            ...filters,
            type: "all",
          });
        }}
        usage={selectedInvoice?.usage}
        detailedUsage={detailedUsage}
        setShowAssignBillingProfileForm={setShowAssignBillingProfileForm}
        showAssignBillingProfileForm={showAssignBillingProfileForm}
        onChangeBillingProfile={(billingProfile) =>
          setSelectedBillingProfile(billingProfile)
        }
        selectedBillingProfile={selectedBillingProfile}
        invoices={customerInvoicesQuery.data?.invoices || []}
        setShowDetailedInvoice={setShowDetailedInvoice}
        handleDeleteInvoice={(invoice) =>
          deleteInvoiceQuery.fetchData({ id: invoice.id })
        }
        selectedInvoice={selectedInvoice}
        setSelectedInvoice={setSelectedInvoice}
        handleEditInvoice={(invoice) => {
          setSelectedInvoice(invoice);
          generateCustomerInvoiceQuery.fetchData({
            customerId: selectedCustomer?.id,
            ...invoice.usage,
          });
        }}
        handleCreateInvoice={() => {
          setSelectedInvoice({});
          generateCustomerInvoiceQuery.fetchData({
            customerId: selectedCustomer?.id,
            ...filters,
          });
        }}
        showCustomChargeForm={showCustomChargeForm}
        setShowCustomChargeForm={setShowCustomChargeForm}
        onChangeInvoice={onChangeInvoice}
        onSubmitInvoice={() => {
          saveInvoiceQuery.fetchData({
            invoiceInput: {
              ...selectedInvoice,
              usage: {
                ...generateCustomerInvoiceQuery.data?.generateInvoice,
                inbound:
                  generateCustomerInvoiceQuery.data?.generateInvoice.inbound ||
                  [],
                outbound:
                  generateCustomerInvoiceQuery.data?.generateInvoice.outbound ||
                  [],
                storage:
                  generateCustomerInvoiceQuery.data?.generateInvoice.storage ||
                  [],
                activity:
                  generateCustomerInvoiceQuery.data?.generateInvoice.activity ||
                  [],
                label:
                  generateCustomerInvoiceQuery.data?.generateInvoice.label ||
                  [],
                shipping:
                  generateCustomerInvoiceQuery.data?.generateInvoice.shipping ||
                  [],
                manHour:
                  generateCustomerInvoiceQuery.data?.generateInvoice.manHour ||
                  [],
              },
              startDate:
                generateCustomerInvoiceQuery.data?.generateInvoice.startDate,
              endDate:
                generateCustomerInvoiceQuery.data?.generateInvoice.endDate,
              total: computeTotal(
                generateCustomerInvoiceQuery.data?.generateInvoice,
                selectedInvoice,
              ), //TODO,
            },
          });
          setSelectedInvoice(null);
        }}
        handleDownloadDetailedBill={(startDate, endDate) => {
          // generateCustomerInvoiceQuery.fetchData({
          //   ...filters,
          //   customerId: showCustomerInvoice?.id,
          //   startDate,
          //   endDate,
          // });
          getCustomerBillUsageQuery.fetchData({
            customerId: selectedCustomer?.id,
            ...filters,
            startDate,
            endDate,
            type: "all",
          });
        }}
        deleteCustomCharge={deleteCustomCharge}
        payWithStripeClicked={(invoice) =>
          stripePayQuery.fetchData({
            name: "Invoice 2022",
            amount: (invoice.total * 100).toString(),
          })
        }
        subdomain={appState.subdomain}
        role={auth?.user?.role}
        writable={props.writable}
      />
    );
  };
};

export default withInvoiceLogic;

const computeTotal = (usage, selectedInvoice) => {
  const customCharges = [
    (selectedInvoice["customInboundCharges"] || [])
      .map((charge) => ({ isCustom: true, ...charge }))
      .reduce((prev, curr) => prev + curr.numUnits * curr.rate, 0),
    (selectedInvoice["customOutboundCharges"] || [])
      .map((charge) => ({ isCustom: true, ...charge }))
      .reduce((prev, curr) => prev + curr.numUnits * curr.rate, 0),
    (selectedInvoice["customMaterialCharges"] || [])
      .map((charge) => ({ isCustom: true, ...charge }))
      .reduce((prev, curr) => prev + curr.numUnits * curr.rate, 0),
    (selectedInvoice["customShippingCharges"] || [])
      .map((charge) => ({ isCustom: true, ...charge }))
      .reduce((prev, curr) => prev + curr.numUnits * curr.rate, 0),
    (selectedInvoice["customStorageCharges"] || [])
      .map((charge) => ({ isCustom: true, ...charge }))
      .reduce((prev, curr) => prev + curr.numUnits * curr.rate, 0),
    (selectedInvoice["customRecurringCharges"] || [])
      .map((charge) => ({ isCustom: true, ...charge }))
      .reduce((prev, curr) => prev + curr.numUnits * curr.rate, 0),
    (selectedInvoice["customManhourCharges"] || [])
      .map((charge) => ({ isCustom: true, ...charge }))
      .reduce((prev, curr) => prev + curr.numUnits * curr.rate, 0),
  ].reduce((prev, curr) => prev + curr, 0);

  const _usage = usage.usage || [];
  const bill = [
    _usage
      ?.filter((i) => i.chargeType === "By Activity")
      ?.reduce((p, c) => p + c.rate * c.event.payload.quantity, 1),
    ,
    _usage
      ?.filter((i) => i.chargeType === "By Storage")
      ?.reduce(
        (p, c) =>
          p +
          c.uomList.reduce((p2, c2) => p2 * parseFloat(c2.numUnits), 1) *
            c.rate,
        1,
      ),
  ];

  return Math.floor(
    bill.reduce((prev, curr) => prev + curr, 0) + customCharges,
  );
};
