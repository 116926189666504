import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_VENDORS,
  GET_PRODUCTS,
  GET_VENDOR,
  GET_VENDOR_FIELDS,
} from "#queries";
import {
  SAVE_VENDOR,
  DELETE_VENDOR,
  BULK_UPLOAD_VENDOR_FIELDS,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import readXlsxFile from "read-excel-file";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";
import { validate } from "numeral";

const withVendorLogic = (WrappedComponent) => {
  return (props) => {
    const [availableInventory, setAvailableInventory] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const [items, setItems] = useState(null);
    const appState = useContext(AppStateContext);
    const vendorsQuery = useQuery(GET_VENDORS);
    const getVendorQuery = useQuery(GET_VENDOR);
    const saveVendorQuery = useQuery(SAVE_VENDOR);
    const deleteVendorQuery = useQuery(DELETE_VENDOR);
    const inventoryQuery = useQuery(GET_PRODUCTS);
    const [showFilters, setShowFilters] = useState(false);
    const getVendorFields = useQuery(GET_VENDOR_FIELDS);
    const uploadBulk = useQuery(BULK_UPLOAD_VENDOR_FIELDS);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setfinalError] = useState(null);
    const [successMessage, setsuccessMessage] = useState(null);

    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "Vendors" }),
    );
    const [validationResult, setValidationResult] = useState(null);

    useEffect(() => {
      if (getVendorFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (getVendorFields.data) {
        setDashboardFields(getVendorFields.data.vendorFields);
      }
    }, [getVendorFields.loading, getVendorFields.data, getVendorFields.error]);

    useEffect(() => {
      if (uploadBulk.data) {
        vendorsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
        setsuccessMessage(uploadBulk.data.bulkUploadVendors.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setfinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadVendors
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      if (getVendorQuery.data) {
        setSelectedVendor(getVendorQuery.data.vendor);
      }
      inventoryQuery.fetchData({
        perPage: 50,
        paginated: false,
        pageNumber: 1,
        sort: "asin",
      });
      if (getVendorQuery.error) {
        appState.setAlert(getVendorQuery.error.message, "error", 5000);
      }

      if (getVendorQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [getVendorQuery.data, getVendorQuery.loading, getVendorQuery.error]);

    useEffect(() => {
      if (inventoryQuery.loading) {
      } else {
        appState.removeLoading();
      }
      if (inventoryQuery.data) {
        setAvailableInventory(
          inventoryQuery.data.products.entities.filter((item) => !!item.sku),
        );
      }

      if (inventoryQuery.error) {
        setAvailableInventory([]);
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    useEffect(() => {
      loadFirstTimeData();
      getVendorFields.fetchData();
    }, []);

    const loadFirstTimeData = () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        // const warehouses = auth.user.warehousesList;
        // const customers = auth.user.customersList;
        // if (warehouses.length === 0 || customers.length === 0) {
        //   return appState.setAlert(
        //     `You don't have necessary permission to execute this action.`,
        //     "error",
        //     5000,
        //   );
        // }
        const filtersSet = {
          classifications: ["Internal", "Third Party"],
        };
        entity.setFilters(filtersSet);
        vendorsQuery.fetchData({
          perPage: entity.perPage,
          filters: filtersSet,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }
    };

    useEffect(() => {
      if (saveVendorQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveVendorQuery.error) {
        appState.setAlert(saveVendorQuery.error.message, "error", 5000);
      }

      if (saveVendorQuery.data) {
        appState.setAlert(saveVendorQuery.data.saveVendor.message);
        setSelectedVendor(null);
        setAvailableInventory([]);
        vendorsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }
    }, [saveVendorQuery.data, saveVendorQuery.loading, saveVendorQuery.error]);

    useEffect(() => {
      if (vendorsQuery.data && vendorsQuery.data.vendors) {
        entity.setEntities({
          ...vendorsQuery.data.vendors,
          ...vendorsQuery.variables,
        });
      }
    }, [vendorsQuery.loading, vendorsQuery.error, vendorsQuery.data]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return vendorsQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const addNewVendor = () => {
      setSelectedVendor({
        active: true,
        classification: "Internal",
        emails: [""],
      });
    };

    const addItem = () => {
      let currentSelectedVendor = selectedVendor;
      if (
        !currentSelectedVendor.items ||
        currentSelectedVendor.items.length === 0
      ) {
        currentSelectedVendor.items = [];
      }

      setSelectedVendor({ ...currentSelectedVendor });
    };

    const submitAddedProduct = (addedProduct) => {
      let currentSelectedVendor = selectedVendor;
      if (
        !currentSelectedVendor.items ||
        currentSelectedVendor.items.length === 0
      ) {
        currentSelectedVendor.items = [];
      }

      if (addedProduct.idx >= 0) {
        currentSelectedVendor.items[addedProduct.idx] = {
          ...addedProduct,
          idx: null,
          editing: false,
        };
      } else {
        currentSelectedVendor.items.push({ ...addedProduct });
      }
      setSelectedVendor({ ...currentSelectedVendor });
    };

    const addAddress = (addedAddresses) => {
      let currentSelectedVendor = selectedVendor;
      if (
        !currentSelectedVendor.addresses ||
        currentSelectedVendor.addresses.length === 0
      ) {
        currentSelectedVendor.addresses = [];
      }
      if (
        !currentSelectedVendor.addresses ||
        currentSelectedVendor.addresses.length === 0
      ) {
        currentSelectedVendor.addresses = addedAddresses;
      } else {
        currentSelectedVendor.addresses.push(...addedAddresses);
      }
      setSelectedVendor({ ...currentSelectedVendor });
    };

    const addNewEmail = (addedEmails) => {
      let currentSelectedVendor = selectedVendor;
      if (
        !currentSelectedVendor.emails ||
        currentSelectedVendor.emails.length === 0
      ) {
        currentSelectedVendor.emails = [];
      }
      if (
        !currentSelectedVendor.emails ||
        currentSelectedVendor.emails.length === 0
      ) {
        currentSelectedVendor.emails = addedEmails;
      } else {
        currentSelectedVendor.emails.push(...addedEmails);
      }
      setSelectedVendor({ ...currentSelectedVendor });
    };
    const onChangeAddress = (e, idx) => {
      let currentSelectedVendor = selectedVendor;
      const item = currentSelectedVendor.addresses[idx];
      item[e.target.name] = e.target.value;
      currentSelectedVendor.addresses[idx] = item;
      setSelectedVendor({ ...currentSelectedVendor });
    };

    const onChangeEmails = (e, idx) => {
      let currentSelectedVendor = selectedVendor;
      currentSelectedVendor.emails[idx] = e.target.value;
      setSelectedVendor({ ...currentSelectedVendor });
    };
    const removeItem = (idx) => {
      let currentSelectedVendor = selectedVendor;
      if (
        !currentSelectedVendor.items ||
        currentSelectedVendor.items.length === 0
      ) {
        return;
      }

      currentSelectedVendor.items = currentSelectedVendor.items.filter(
        (item, index) => index !== idx,
      );
      setSelectedVendor({ ...currentSelectedVendor });
    };

    const onChangeItem = (idx, e) => {
      let currentSelectedVendor = selectedVendor;
      const item = currentSelectedVendor.items[idx];
      item[e.target.name] = e.target.value;
      currentSelectedVendor.items[idx] = item;
      setSelectedVendor({ ...currentSelectedVendor });
    };

    const onChange = (e) => {
      const vendor = {
        ...selectedVendor,
      };
      let name = e.target.name;
      if (name === "phoneNumbers") vendor["phoneNumbers"] = [e.target.value];
      else if (e.target.type === "number") {
        vendor[e.target.name] = parseInt(e.target.value);
      } else {
        vendor[e.target.name] = e.target.value;
      }

      setSelectedVendor(vendor);
    };

    const onChangeMultiSelect = (field, value) => {
      const vendor = {
        ...selectedVendor,
      };
      vendor[field] = value;
      setSelectedVendor(vendor);
    };

    const onAddProduct = (e) => {
      const vendor = {
        ...selectedVendor,
      };
      if (!vendor.supplyDetails) {
        vendor.supplyDetails = [];
      }
      vendor.supplyDetails.push(e);
      setSelectedVendor({ ...vendor });
    };

    const onChangeDropdown = (field, value) => {
      const vendor = {
        ...selectedVendor,
      };

      vendor[field] = value;
      setSelectedVendor({ ...vendor });
    };

    const onChangeInventorySearch = (keyword) => {
      const filtersToApply = {
        keyword,
        warehouse: selectedVendor.warehouses,
      };

      inventoryQuery.fetchData({
        perPage: 50,
        pageNumber: 1,
        filters: filtersToApply,
        paginated: false,
      });
    };

    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);

    useEffect(() => {
      if (deleteVendorQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteVendorQuery.error) {
        appState.setAlert(deleteVendorQuery.error.message, "error", 5000);
        appState.hideConfirmation();
      }

      if (deleteVendorQuery.data) {
        appState.setAlert(
          deleteVendorQuery.data.deleteVendor.message,
          "success",
          5000,
        );
        appState.hideConfirmation();
        vendorsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: { ...entity.filters },
          sort: entity.sort,
        });
      }
    }, [
      deleteVendorQuery.data,
      deleteVendorQuery.error,
      deleteVendorQuery.loading,
    ]);

    const deleteVendor = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this vendor?",
        () => {
          deleteVendorQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    const removeAddress = (index) => {
      const vendor = {
        ...selectedVendor,
      };
      vendor.addresses.splice(index, 1);
      setSelectedVendor(vendor);
    };
    const removeEmails = (idx) => {
      const vendor = {
        ...selectedVendor,
      };
      vendor.emails.splice(idx, 1);
      setSelectedVendor(vendor);
    };

    const uploadVendors = async (files) => {
      appState.setLoading();
      const vendors = {};
      const rows = await readXlsxFile(files);
      for (let i = 0; i < rows.length; i++) {
        if (i === 0) {
          continue;
        }
        const row = rows[i];
        if (row.length < 8) {
          appState.setAlert(`Please provide all details`, "error", 5000);
          appState.removeLoading();
          return;
        }

        const customer = auth.user.customersList.find(
          (item) =>
            item.name.toLowerCase() === row[0]?.toLowerCase() ||
            item.code.toLowerCase() === row[0]?.toLowerCase(),
        );
        if (!customer) {
          appState.setAlert(
            `One or more of the records belong to invalid tenants.`,
            "error",
            5000,
          );
          appState.removeLoading();
          return;
        }

        if (!row[1] || row[1].toString().trim() === "") {
          appState.setAlert(
            `Vendor number is mandatory for all rows`,
            "error",
            5000,
          );
          appState.removeLoading();
          return;
        }

        if (!row[2] || row[2].toString().trim() === "") {
          appState.setAlert(
            `Order ID is mandatory for all rows`,
            "error",
            5000,
          );
          appState.removeLoading();
          return;
        }
        if (!vendors[row[1]]) {
          vendors[row[1]] = {
            customer: customer.id,
            vendorNumber: row[1]?.toString(),
            orderId: row[2]?.toString(),
            trackingNumber: row[3]
              ? row[3]
                  .toString()
                  .split(",")
                  .map((item) => item.trim())
              : [],
            items: [],
            warehouse: customer.warehouses && customer.warehouses[0],
            carrier: row[8]?.toString(),
            supplier: row[9]?.toString(),
          };
        }

        vendors[row[1]].items.push({
          asin: row[4]?.toString(),
          sellerSku: row[5]?.toString(),
          formFactors: [
            {
              name: "Each",
              quantity: parseInt(row[6]),
            },
          ],
          fulfillmentType: row[7]?.toString(),
        });
      }

      for (const vendor of Object.values(vendors)) {
        saveVendorQuery.fetchData({ ...vendor });
      }

      setTimeout(() => {
        appState.removeLoading();
      }, 2500);
    };

    const saveVendor = () => {
      if (selectedVendor.emails.length > 0 && !selectedVendor.emails[0]) {
        appState.setAlert("Email can not be empty !");
        return;
      }
      const vendor = { ...selectedVendor };
      delete vendor.customerToBeSelected;
      delete vendor.warehouseToBeSelected;
      delete vendor.warehouse;
      saveVendorQuery.fetchData({
        vendorInput: { ...vendor },
      });
    };
    return (
      <WrappedComponent
        {...props}
        vendors={entity.entities}
        displayVendors={entity.displayEntities}
        total={entity.total}
        pageNumber={entity.pageNumber}
        onChangeMultiSelect={onChangeMultiSelect}
        checkPagination={checkPagination}
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          vendorsQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        removeAddress={removeAddress}
        removeEmails={removeEmails}
        addAddress={addAddress}
        addNewEmail={addNewEmail}
        submitFilters={() => {
          setShowFilters(false);
          vendorsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        onAddProduct={onAddProduct}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          vendorsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = true) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            vendorsQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          vendorsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        onChangeAddress={onChangeAddress}
        onChangeEmails={onChangeEmails}
        setItems={setItems}
        items={items}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        addNewVendor={addNewVendor}
        selectedVendor={selectedVendor}
        setSelectedVendor={setSelectedVendor}
        addItem={addItem}
        removeItem={removeItem}
        onChangeItem={onChangeItem}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        availableInventory={availableInventory}
        saveVendor={saveVendor}
        editVendor={(id) => {
          return getVendorQuery.fetchData({ vendorId: id });
        }}
        deleteVendor={deleteVendor}
        onChangeInventorySearch={debouncedInventorySearch}
        uploadVendors={uploadVendors}
        submitAddedProduct={submitAddedProduct}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
      />
    );
  };
};

export default withVendorLogic;
