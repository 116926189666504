import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useQuery } from "#hooks/useQuery";
import {
  GET_BILLING_PROFILES,
  GET_CUSTOMERS,
  GET_BILLING_PROFILE,
  GET_CUSTOMER_USAGE,
  GET_CUSTOMER,
} from "#queries";
import {
  SAVE_BILLING_PROFILE,
  DELETE_BILLING_PROFILE,
  ASSIGN_BILLING_PROFILE,
} from "#mutations";
import _, { filter } from "lodash";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import moment from "moment-timezone";

const withUsageLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const customerQuery = useQuery(GET_CUSTOMER);
    const billingProfilesQuery = useQuery(GET_BILLING_PROFILES);
    const assignBillingProfileQuery = useQuery(ASSIGN_BILLING_PROFILE);
    const getCustomerBillUsageQuery = useQuery(GET_CUSTOMER_USAGE);
    const [showCustomerUsage, setShowCustomerUsage] = useState(null);
    const [selectedBillingProfile, setSelectedBillingProfile] = useState(null);
    const [showAssignBillingProfileForm, setShowAssignBillingProfileForm] =
      useState(false);

    let startMonth = moment().month() + 1;
    startMonth = startMonth < 10 ? `0${startMonth}` : `${startMonth}`;
    let endMonth = moment().month() + 2;
    endMonth = endMonth < 10 ? `0${endMonth}` : `${endMonth}`;
    const [filters, setFilters] = useState({
      startDate: `${moment().year()}-${startMonth}-01`,
      endDate: `${moment().year()}-${endMonth}-01`,
      type: "inbound",
    });

    useEffect(() => {
      billingProfilesQuery.fetchData({});
      customersQuery.fetchData({
        perPage: 100,
        filters: {},
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
      getCustomerBillUsageQuery.fetchData({});

      return () => {
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      if (customersQuery.data) {
        entity.setEntities({
          ...customersQuery.data.customers,
          ...customersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [customersQuery.loading, customersQuery.error, customersQuery.data]);

    useEffect(() => {
      if (getCustomerBillUsageQuery.loading) {
        appState.setPageLoading();
      } else {
        appState.removePageLoading();
      }
    }, [
      getCustomerBillUsageQuery.loading,
      getCustomerBillUsageQuery.error,
      getCustomerBillUsageQuery.data,
    ]);

    useEffect(() => {
      if (customerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (customerQuery.data?.customer) {
        setShowCustomerUsage(customerQuery.data?.customer);
      }
    }, [customerQuery.loading, customerQuery.error, customerQuery.data]);

    useEffect(() => {
      if (assignBillingProfileQuery.data) {
        setShowAssignBillingProfileForm(null);
        customersQuery.fetchData({
          perPage: 100,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }

      if (assignBillingProfileQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (assignBillingProfileQuery.error) {
        appState.setAlert(
          assignBillingProfileQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      assignBillingProfileQuery.loading,
      assignBillingProfileQuery.error,
      assignBillingProfileQuery.data,
    ]);

    useEffect(() => {
      if (billingProfilesQuery.data && billingProfilesQuery.data.batches) {
      }
    }, [
      billingProfilesQuery.loading,
      billingProfilesQuery.error,
      billingProfilesQuery.data,
    ]);

    const onChangeFilter = (key, value) => {
      setFilters({ ...filters, [key]: value });
    };

    return (
      <WrappedComponent
        {...props}
        customers={customersQuery?.data?.customers?.entities || []}
        billingProfiles={billingProfilesQuery.data?.billingProfiles || []}
        assignBillingProfile={() => {
          assignBillingProfileQuery.fetchData({
            billingProfileId: selectedBillingProfile,
            customerId: showAssignBillingProfileForm,
          });
        }}
        showCustomerUsage={showCustomerUsage}
        setShowCustomerUsage={setShowCustomerUsage}
        onClickCustomerUsage={(customer) =>
          customerQuery.fetchData({ id: customer.id })
        }
        filters={filters}
        onChangeFilter={onChangeFilter}
        submitUsageDateRange={(typeFilters) => {
          if (typeFilters) {
            getCustomerBillUsageQuery.fetchData({
              customerId: showCustomerUsage?.id,
              ...filters,
              ...typeFilters,
            });
            return;
          }
          getCustomerBillUsageQuery.fetchData({
            customerId: showCustomerUsage?.id,
            ...filters,
          });
        }}
        usages={getCustomerBillUsageQuery.data?.usages?.data}
        setShowAssignBillingProfileForm={setShowAssignBillingProfileForm}
        showAssignBillingProfileForm={showAssignBillingProfileForm}
        onChangeBillingProfile={(billingProfile) =>
          setSelectedBillingProfile(billingProfile)
        }
        selectedBillingProfile={selectedBillingProfile}
        subdomain={appState.subdomain}
        role={auth?.user?.role}
        writable={props.writable}
      />
    );
  };
};

export default withUsageLogic;
