import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { handlePositiveValue } from "#utils/helper-functions";

const CatalogProductInfoForm = ({
  product,
  setProduct,
  productCategories,
  saveProduct,
}) => {
  return (
    <div className="flex-1 flex-col">
      <div className="flex flex-1">
        <div className="flex-1 bg-white">
          <div className="flex w-full">
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">Length</label>
              <input
                className="catalog-textfield"
                type="number"
                value={product.attributes?.length}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    attributes: {
                      ...(product?.attributes || {}),
                      length: handlePositiveValue(e.target.value, "float"),
                    },
                  })
                }
              />
            </div>
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">Width</label>
              <input
                className="catalog-textfield"
                type="number"
                value={product.attributes?.width}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    attributes: {
                      ...(product?.attributes || {}),
                      width: handlePositiveValue(e.target.value, "float"),
                    },
                  })
                }
              />
            </div>
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">Height</label>
              <input
                className="catalog-textfield"
                type="number"
                value={product.attributes?.height}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    attributes: {
                      ...(product?.attributes || {}),
                      height: handlePositiveValue(e.target.value, "float"),
                    },
                  })
                }
              />
            </div>
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">Unit</label>
              <AutocompleteSingleSelectDropdown
                options={[
                  {
                    name: "Inches",
                  },
                  {
                    name: "Centimeters",
                  },
                  {
                    name: "Meters",
                  },
                  {
                    name: "Feet",
                  },
                ]}
                labelKey={"name"}
                valueKey={"name"}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    attributes: {
                      ...(product?.attributes || {}),
                      dimensionsUnit: e,
                    },
                  })
                }
                value={product.attributes?.dimensionsUnit}
                showCheckedIndicator={false}
              />
            </div>
          </div>
          <div className="flex w-full">
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">Weight</label>
              <input
                className="catalog-textfield"
                type="number"
                value={product.attributes?.weight}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    attributes: {
                      ...(product?.attributes || {}),
                      weight: handlePositiveValue(e.target.value, "float"),
                    },
                  })
                }
              />
            </div>
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">Unit</label>
              <AutocompleteSingleSelectDropdown
                options={[
                  { name: "Pounds" },
                  { name: "Kilograms" },
                  { name: "Ounces" },
                  { name: "Litres" },
                ]}
                labelKey={"name"}
                valueKey={"name"}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    attributes: {
                      ...(product?.attributes || {}),
                      weightMeasure: e,
                    },
                  })
                }
                value={product.attributes?.weightMeasure}
                showCheckedIndicator={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-none items-end justify-end space-x-2">
        <button
          className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent p-2 px-6 font-semibold text-primaryAccent"
          onClick={() => setProduct(null)}>
          Cancel
        </button>
        <button
          className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-2 px-6 font-semibold text-white"
          onClick={saveProduct}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default CatalogProductInfoForm;
