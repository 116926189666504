import ReactTooltip from "react-tooltip";

const renderWithTooltip = ({
  onClick,
  text,
  IconText,
  disabled,
  tooltipText,
}) => {
  const id = `table-button-tooltip-${Math.random()
    .toString(36)
    .substring(2, 8)}`;
  return (
    <>
      <button
        type="button"
        data-tip
        data-for={id}
        disabled={disabled}
        className={
          disabled
            ? `text-md textWhite-300 pointer-events-none inline-flex justify-center rounded border bg-E1D3B8 px-2 py-2 font-montserrat font-medium text-5F666B opacity-50 outline-none ring-0 focus:outline-none focus:ring-0`
            : `text-md textWhite-300 inline-flex justify-center rounded border bg-E1D3B8 px-2 py-2 font-montserrat font-medium text-5F666B outline-none ring-0 focus:outline-none focus:ring-0`
        }
        onClick={onClick}
        title={tooltipText}>
        {IconText ? IconText : text || "Button"}
      </button>
      <ReactTooltip id={id} place="top" type="light" effect="float">
        {tooltipText}
      </ReactTooltip>
    </>
  );
};
const renderWithoutTooltip = ({ onClick, text, IconText, disabled }) => (
  <button
    type="button"
    disabled={disabled}
    className={
      disabled
        ? `text-md textWhite-300 pointer-events-none inline-flex justify-center rounded border bg-E1D3B8 px-2 py-2 font-montserrat font-medium text-5F666B opacity-50 outline-none ring-0 focus:outline-none focus:ring-0`
        : `text-md textWhite-300 inline-flex justify-center rounded border bg-E1D3B8 px-2 py-2 font-montserrat font-medium text-5F666B outline-none ring-0 focus:outline-none focus:ring-0`
    }
    onClick={onClick}>
    {IconText ? IconText : text || "Button"}
  </button>
);

const TableFieldButton = ({
  onClick,
  text,
  IconText,
  disabled,
  tooltipText,
}) =>
  tooltipText
    ? renderWithTooltip({ onClick, text, IconText, disabled, tooltipText })
    : renderWithoutTooltip({ onClick, text, IconText, disabled });

export default TableFieldButton;
