import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import Toggle from "#components/utils/Toggle";
import _ from "lodash";
import Autocomplete from "#components/utils/Autocomplete";
import { Country, State } from "country-state-city";
import { useState, useEffect } from "react";
import { Tooltip } from "antd";

const OrderForm = ({
  onClose,
  title,
  carrierUpdate,
  setCarrierUpdate,
  onSubmit,
  carrierList,
  carrierServiceList,
  tenant,
  shipstationList,
  eHubList,
}) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);

  const { shippingAddress } = carrierUpdate;

  useEffect(() => {
    if (shippingAddress) {
      setSelectedCountry(shippingAddress.country || "");
      setSelectedState(shippingAddress.state || "");
    }
  }, [shippingAddress]);

  useEffect(() => {
    const updateStates = () => {
      if (selectedCountry) {
        const states = State.getStatesOfCountry(selectedCountry);
        setFilteredStates(states);
        const stateIsValid = states.some(
          (state) => state.isoCode === selectedState,
        );
        if (!stateIsValid) {
          setSelectedState("");
        }
      } else {
        setFilteredStates([]);
        setSelectedState("");
      }
    };

    updateStates();
  }, [selectedCountry]);

  const handleCountryChange = (e) => {
    const newCountry = e;
    setSelectedCountry(newCountry);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(newCountry));
    setCarrierUpdate({
      ...carrierUpdate,
      shippingAddress: carrierUpdate.shippingAddress
        ? {
            ...carrierUpdate.shippingAddress,
            country: newCountry,
            state: "",
          }
        : {
            country: newCountry,
          },
    });
  };

  const handleStateChange = (e) => {
    const newState = e;
    setSelectedState(newState);
    setCarrierUpdate({
      ...carrierUpdate,
      shippingAddress: carrierUpdate.shippingAddress
        ? {
            ...carrierUpdate.shippingAddress,
            state: newState,
          }
        : {
            state: newState,
          },
    });
  };

  let selectedCarrierList = carrierList;
  let carrierKeyName = "carrier";
  let onCarrierChangeHandler = (updatedCarrier) => {
    setCarrierUpdate({
      ...carrierUpdate,
      carrier: updatedCarrier,
      carrierService:
        carrierUpdate.carrier !== updatedCarrier
          ? null
          : carrierUpdate.carrierService,
    });
  };

  if (shipstationList && Object.keys(shipstationList).length > 0) {
    selectedCarrierList = Object.keys(shipstationList).map((item) => ({
      name: item.toUpperCase(),
      id: item,
    }));
  } else if (eHubList && eHubList.length > 0) {
    selectedCarrierList = eHubList.map((item) => ({
      name: `${item.carrier_name} ${item.service}`,
      id: `${item.service_id}`,
    }));

    onCarrierChangeHandler = (updatedCarrier) => {
      const selectedCarrier = eHubList.find(
        (carrier) => `${carrier.service_id}` === updatedCarrier,
      );
      setCarrierUpdate({
        ...carrierUpdate,
        carrier: selectedCarrier.carrier_code,
        carrierService: `${updatedCarrier}`,
      });
    };

    carrierKeyName = "carrierService";
  }
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      positiveAction={onSubmit}
      id="orderFormModal">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Carrier and Address Details
        </span>
      </div>
      <div className="mt-4 space-y-4">
        <div className="mb-3 grid grid-cols-3 gap-4">
          <div>
            <TextField
              type="text"
              id="name"
              label="Name"
              placeholder=" "
              onChange={(e) =>
                setCarrierUpdate({
                  ...carrierUpdate,
                  shippingAddress: carrierUpdate.shippingAddress
                    ? {
                        ...carrierUpdate.shippingAddress,
                        name: e.target.value,
                      }
                    : {
                        name: e.target.value,
                      },
                })
              }
              value={carrierUpdate?.shippingAddress?.name}
              name="shippingAddress.name"
            />
          </div>
          <div>
            <TextField
              type="text"
              id="name"
              label="Email"
              placeholder=" "
              onChange={(e) =>
                setCarrierUpdate({
                  ...carrierUpdate,
                  shippingAddress: carrierUpdate.shippingAddress
                    ? {
                        ...carrierUpdate.shippingAddress,
                        email: e.target.value,
                      }
                    : {
                        email: e.target.value,
                      },
                })
              }
              value={carrierUpdate?.shippingAddress?.email}
              name="shippingAddress.email"
            />
          </div>
          <div>
            <TextField
              type="text"
              id="name"
              label="Phone"
              placeholder=" "
              onChange={(e) =>
                setCarrierUpdate({
                  ...carrierUpdate,
                  shippingAddress: carrierUpdate.shippingAddress
                    ? {
                        ...carrierUpdate.shippingAddress,
                        phone: e.target.value,
                      }
                    : {
                        phone: e.target.value,
                      },
                })
              }
              value={carrierUpdate?.shippingAddress?.phone}
              name="shippingAddress.phone"
            />
          </div>
        </div>
        <div className="mb-3 grid grid-cols-3 gap-4">
          <div>
            <TextField
              type="text"
              id="name"
              label="Address Line 1"
              placeholder=" "
              onChange={(e) =>
                setCarrierUpdate({
                  ...carrierUpdate,
                  shippingAddress: carrierUpdate.shippingAddress
                    ? {
                        ...carrierUpdate.shippingAddress,
                        line1: e.target.value,
                      }
                    : {
                        lin1: e.target.value,
                      },
                })
              }
              value={carrierUpdate?.shippingAddress?.line1}
              name="shippingAddress.line1"
            />
          </div>
          <div>
            <TextField
              type="text"
              id="name"
              label="Address Line 2"
              placeholder=" "
              onChange={(e) =>
                setCarrierUpdate({
                  ...carrierUpdate,
                  shippingAddress: carrierUpdate.shippingAddress
                    ? {
                        ...carrierUpdate.shippingAddress,
                        line2: e.target.value,
                      }
                    : {
                        line2: e.target.value,
                      },
                })
              }
              value={carrierUpdate?.shippingAddress?.line2}
              name="shippingAddress.line2"
            />
          </div>
          <div>
            <TextField
              type="text"
              id="name"
              label="City"
              placeholder=" "
              onChange={(e) =>
                setCarrierUpdate({
                  ...carrierUpdate,
                  shippingAddress: carrierUpdate.shippingAddress
                    ? {
                        ...carrierUpdate.shippingAddress,
                        city: e.target.value,
                      }
                    : {
                        city: e.target.value,
                      },
                })
              }
              value={carrierUpdate?.shippingAddress?.city}
              name="shippingAddress.city"
            />
          </div>
        </div>
        <div className="mb-3 grid grid-cols-3 gap-4">
          <div>
            <Tooltip
              title={!selectedCountry ? "Please select a country first" : ""}
              placement="top">
              <div style={{ display: "inline-block", width: "100%" }}>
                <Autocomplete
                  options={filteredStates}
                  labelKey="name"
                  valueKey="isoCode"
                  onChange={handleStateChange}
                  value={carrierUpdate?.shippingAddress?.state || ""}
                  placeholder="Select a State"
                  id="name"
                />
              </div>
            </Tooltip>
          </div>

          <div>
            <Autocomplete
              options={Country.getAllCountries()}
              id="name"
              labelKey="name"
              valueKey="isoCode"
              onChange={handleCountryChange}
              value={carrierUpdate?.shippingAddress?.country || ""}
              placeholder="Select a Country"
            />
          </div>
          <div>
            <TextField
              type="text"
              id="name"
              label="Pincode"
              placeholder=" "
              onChange={(e) =>
                setCarrierUpdate({
                  ...carrierUpdate,
                  shippingAddress: carrierUpdate.shippingAddress
                    ? {
                        ...carrierUpdate.shippingAddress,
                        zip: e.target.value,
                      }
                    : {
                        zip: e.target.value,
                      },
                })
              }
              value={carrierUpdate?.shippingAddress?.zip}
              name="shippingAddress.zip"
            />
          </div>
        </div>
      </div>

      <div className="iustify-center flex flex-row py-2">
        <div className="self-center pr-4 text-lg">Validate address</div>
        <div>
          <Toggle
            setEnabled={(e) =>
              setCarrierUpdate({
                ...carrierUpdate,
                toValidAddress: carrierUpdate.toValidAddress ? false : true,
              })
            }
            enabled={carrierUpdate.toValidAddress}
          />
        </div>
      </div>
      <div className="mt-4 space-y-4">
        {carrierUpdate.shippingRequests &&
          carrierUpdate.shippingRequests.length > 0 && (
            <div className="text-xl">
              Carrier requested: {carrierUpdate.shippingRequests[0].title}
            </div>
          )}

        {tenant?.typeOfCustomer?.includes("B2B") === false && (
          <>
            <div>
              <Dropdown
                placeholder={"Select Carrier"}
                list={selectedCarrierList}
                labelKey="name"
                valueKey="id"
                name="carrier"
                setSelected={onCarrierChangeHandler}
                selectedValue={carrierUpdate[carrierKeyName]}
              />
            </div>
          </>
        )}
        {carrierUpdate.carrier &&
          carrierServiceList[carrierUpdate.carrier] &&
          carrierServiceList[carrierUpdate.carrier].length > 0 && (
            <>
              <div>
                <Dropdown
                  placeholder={"Select Service"}
                  list={carrierServiceList[carrierUpdate.carrier]?.map(
                    (item) => ({
                      name: item.toUpperCase(),
                      id: item,
                    }),
                  )}
                  labelKey="name"
                  valueKey="id"
                  name="carrierService"
                  setSelected={(e) => {
                    setCarrierUpdate({
                      ...carrierUpdate,
                      carrierService: e,
                    });
                  }}
                  selectedValue={carrierUpdate.carrierService}
                />
              </div>
            </>
          )}
        {tenant?.typeOfCustomer?.includes("B2B") && (
          <>
            <div>
              <TextField
                type="text"
                id="name"
                label="Carrier"
                placeholder=" "
                onChange={(e) =>
                  setCarrierUpdate({
                    ...carrierUpdate,
                    carrier: e.target.value,
                  })
                }
                value={carrierUpdate?.carrier}
                name="carrier"
              />
            </div>
            <div>
              <TextField
                type="text"
                id="bolNumber"
                label="BOL Number"
                placeholder=" "
                onChange={(e) =>
                  setCarrierUpdate({
                    ...carrierUpdate,
                    bolNumber: e.target.value,
                  })
                }
                value={carrierUpdate?.bolNumber}
                name="bolNumber"
              />
            </div>
            <div>
              <TextField
                type="text"
                id="bolSealNumber"
                label="Seal #"
                placeholder=" "
                onChange={(e) =>
                  setCarrierUpdate({
                    ...carrierUpdate,
                    bolData: {
                      ...carrierUpdate.bolData,
                      bolSealNumber: e.target.value,
                    },
                  })
                }
                value={carrierUpdate?.bolData?.bolSealNumber}
                name="bolSealNumber"
              />
            </div>
            <div>
              <TextField
                type="text"
                id="scacCode"
                label="SCAC Code"
                placeholder=" "
                onChange={(e) =>
                  setCarrierUpdate({
                    ...carrierUpdate,
                    scacCode: e.target.value,
                  })
                }
                value={carrierUpdate?.scacCode}
                name="scacCode"
              />
            </div>
            <div>
              <TextField
                type="date"
                id="dateOfShipping"
                label="Date of Shipping"
                placeholder=" "
                onChange={(e) =>
                  setCarrierUpdate({
                    ...carrierUpdate,
                    dateOfShipping: e.target.value,
                  })
                }
                value={carrierUpdate?.dateOfShipping}
                name="dateOfShipping"
              />
            </div>
          </>
        )}
        {tenant?.features?.orderInsurance && (
          <>
            <div>
              <Dropdown
                placeholder={"Is Insurance Required?"}
                list={[
                  { name: "Yes", id: true },
                  { name: "No", id: false },
                ]}
                labelKey="name"
                valueKey="id"
                name="insuranceRequired"
                setSelected={(e) => {
                  setCarrierUpdate({
                    ...carrierUpdate,
                    insuranceRequired: e,
                  });
                }}
                selectedValue={carrierUpdate.insuranceRequired}
              />
            </div>
            {carrierUpdate.insuranceRequired && (
              <>
                <div>
                  <Dropdown
                    placeholder={"Insurance Provider"}
                    list={[
                      { name: "Shipsurance", id: "shipsurance" },
                      { name: "Carrier", id: "carrier" },
                      { name: "Provider", id: "provider" },
                    ]}
                    labelKey="name"
                    valueKey="id"
                    name="insuranceProvider"
                    setSelected={(e) => {
                      setCarrierUpdate({
                        ...carrierUpdate,
                        insuranceProvider: e,
                      });
                    }}
                    selectedValue={carrierUpdate.insuranceProvider}
                  />
                </div>
                <div>
                  <TextField
                    type="number"
                    id="insuredValue"
                    label="Insured Value"
                    placeholder=" "
                    onChange={(e) =>
                      setCarrierUpdate({
                        ...carrierUpdate,
                        insuredValue:
                          isNaN(parseFloat(e.target.value)) === false &&
                          parseFloat(e.target.value) >= 0
                            ? parseFloat(e.target.value)
                            : null,
                      })
                    }
                    value={carrierUpdate.insuredValue}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default OrderForm;
