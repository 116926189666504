import React from "react";
import LineItemsSuggestedChangesCard from "./LineItemsSuggestedChangesCard";
import ChargesSuggestedChangesCard from "./ChargesSuggestedChangesCard";
import currencyToSymbolMap from "currency-symbol-map/map";
import { handleCheckForDateAndFormat } from "./PoUtils";

const SuggestedChangesCard = ({ po, generalInfo, products, charges }) => {
  const CURRENCY_SYMBOL = currencyToSymbolMap[po.currency] || "";

  return (
    <div className="rounded-lg bg-white p-6 font-inter shadow-lg">
      {/* PO Total Price */}
      {generalInfo.suggestedPoTotalPrice &&
        generalInfo.originalPoTotalPrice !==
          generalInfo.suggestedPoTotalPrice && (
          <div className="mb-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">Original PO Total Price</p>
                <p className="text-lg font-semibold text-green-500">
                  {`${CURRENCY_SYMBOL} ${generalInfo.originalPoTotalPrice}`}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">
                  Suggested PO Total Price
                </p>
                <p className="text-lg font-semibold text-yellow-500">
                  {`${CURRENCY_SYMBOL} ${generalInfo.suggestedPoTotalPrice}`}
                </p>
              </div>
            </div>
          </div>
        )}

      {/* Expected Ship Date */}
      {generalInfo.suggestedExpectedShipDate &&
        generalInfo.originalExpectedShipDate !==
          generalInfo.suggestedExpectedShipDate && (
          <div className="mb-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">
                  Original Expected Ship Date
                </p>
                <p className="text-lg font-semibold text-green-500">
                  {generalInfo.originalExpectedShipDate}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">
                  Suggested Expected Ship Date
                </p>
                <p className="text-lg font-semibold text-yellow-500">
                  {generalInfo.suggestedExpectedShipDate}
                </p>
              </div>
            </div>
          </div>
        )}

      {/* Expected Delivery Date */}
      {generalInfo.suggestedExpectedDeliveryDate &&
        generalInfo.originalExpectedDeliveryDate !==
          generalInfo.suggestedExpectedDeliveryDate && (
          <div className="mb-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">
                  Original Expected Delivery Date
                </p>
                <p className="text-lg font-semibold text-green-500">
                  {generalInfo.originalExpectedDeliveryDate}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">
                  Suggested Expected Delivery Date
                </p>
                <p className="text-lg font-semibold text-yellow-500">
                  {generalInfo.suggestedExpectedDeliveryDate}
                </p>
              </div>
            </div>
          </div>
        )}

      {/* Vendor Notes */}
      {generalInfo.vendorNotes && (
        <div className="mb-6">
          <div>
            <p className="text-sm text-gray-500">Vendor Notes</p>
            <p className="text-lg">{generalInfo.vendorNotes}</p>
          </div>
        </div>
      )}

      {/* Line Items and Charges */}
      {products.length > 0 &&
        products.map((product, index) => (
          <LineItemsSuggestedChangesCard
            key={index}
            product={product}
            currencySymbol={CURRENCY_SYMBOL}
          />
        ))}
      {charges.length > 0 && (
        <ChargesSuggestedChangesCard
          charges={charges}
          currencySymbol={CURRENCY_SYMBOL}
        />
      )}
    </div>
  );
};

export default SuggestedChangesCard;
