import Modal from "#components/utils/Modal";
import _ from "lodash";

const SetOrderNotesForm = ({
  onClose,
  title,
  onSubmit,
  negativeAction,
  orderNote,
  setOrderNote,
}) => (
  <Modal
    title={title}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    onClose={onClose}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Add Remarks
        </span>
      </div>

      <div>
        <textarea
          id="remarks"
          rows="4"
          cols="50"
          onChange={(e) => setOrderNote(e.target.value)}
          name="orderNote"
          value={orderNote}
        />
      </div>
    </div>
  </Modal>
);

export default SetOrderNotesForm;
