import { useState, useEffect, useContext } from "react";
import {
  AdjustmentsIcon,
  ViewGridIcon,
  ViewListIcon,
  PlusIcon,
  TagIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import GridViewProducts from "./GridViewProducts";
import ListViewProducts from "./ListViewProducts";
import Popover from "#components/utils/Popover";
import SlideOverPanel from "../../common/SlideOverPanel";
import CatalogManageTags from "../CatalogManageTags";
import { useQuery } from "../../../hooks/useQuery";
import { GET_SKU } from "#queries";
import { SAVE_PRODUCT, DELETE_PRODUCT } from "#mutations";
import CatalogEditProduct from "./CatalogEditProduct";
import { AppStateContext } from "#contexts/appState";
import CatalogEnhancedSearch from "./CatalogEnhancedSearch";
import PurchaseOrderMain from "#newUiComponents/purchaseOrder/PurchaseOrderMain";
import { GET_PRODUCTS } from "../../../queries";
import { GET_WAREHOUSES, GET_VENDORS } from "#queries/index";

const ALERT_TIMEOUT_IN_MS = 5000;

//temp function to add flag for po creation
export const checkURL = () => {
  const currentURL = window.location.href;
  const valuesToCheck = ["delmartest", "localhost", "uat"];
  return valuesToCheck.some((value) => currentURL.includes(value));
};

function CatalogMainContent({
  products,
  total,
  filters,
  submitFilters,
  onChangeFilter,
  clearFilters,
  loadFirstTimeData,
  selectProduct,
  showLeftSidebar,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  getCatalog,
  productCategories,
  selectAllDisabled,
  setExportListHeaders,
  handleCreateCatalogClick,
  handleScroll,
  getSpecificProduct,
  customers,
  parentProducts,
}) {
  const appState = useContext(AppStateContext);
  const [selectedProductForTags, setSelectedProductForTags] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [productForPurchase, setProductForPurchase] = useState(null);
  const [parentProductForPurchase, setParentProductForPurchase] =
    useState(null);
  const [showPurchaseOrderSlide, setShowPurchaseOrderSlide] = useState(false);
  const [showListView, setShowListView] = useState(true);
  const [showAddTags, setShowAddTags] = useState(false);
  const [showManageTags, setShowManageTags] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [vendors, setVendors] = useState([]);
  const getSkuQuery = useQuery(GET_SKU);
  const saveProductQuery = useQuery(SAVE_PRODUCT);
  const deleteProductQuery = useQuery(DELETE_PRODUCT);
  const getProductsQuery = useQuery(GET_PRODUCTS);
  const warehousesQuery = useQuery(GET_WAREHOUSES);
  const vendorsQuery = useQuery(GET_VENDORS);

  if (parentProducts?.length > 0) {
    products = [
      ...parentProducts.map((i) => ({ ...i, isParentProduct: true })),
      ...products,
    ];
  }

  useEffect(() => {
    if (getSkuQuery.loading || deleteProductQuery.loading)
      appState.setLoading();
    else appState.removeLoading();
  }, [getSkuQuery.loading, deleteProductQuery.loading]);

  useEffect(() => {
    if (!showPurchaseOrderSlide) {
      setParentProductForPurchase(null);
      setProductForPurchase([]);
    }
  }, [showPurchaseOrderSlide]);

  const fetchCatalogProduct = async (
    ids,
    successCallBack = () => {},
    errorCallBack = () => {},
  ) => {
    appState.setLoading();
    const productsQueryResponse = await getProductsQuery.fetchData({
      filters: { id: [...ids] },
    });
    appState.removeLoading();
    if (productsQueryResponse?.data?.products?.entities.length) {
      const parentProducts =
        productsQueryResponse?.data?.products?.parentProducts || [];
      setProductForPurchase(
        productsQueryResponse?.data?.products.entities.map((product) => {
          if (parentProducts.some((i) => i.id === product.parentProduct)) {
            return {
              ...product,
              parentProductDetails: parentProducts.find(
                (i) => i.id === product.parentProduct,
              ),
            };
          } else return product;
        }),
      );
      successCallBack(productsQueryResponse?.data?.products.entities);
      setShowPurchaseOrderSlide(true);
      setParentProductForPurchase(null);
    } else if (productsQueryResponse?.error) {
      errorCallBack(
        productsQueryResponse?.error?.message || "Error fetching products!",
      );
    }
  };

  const deleteProduct = (id) => {
    // ASK FOR CONFIRMATION
    appState.showConfirmation(
      "Remove Product",
      "Are you sure you want to remove this product?",
      () => confirmDeleteProduct(id),
      appState.hideConfirmation,
    );
  };

  const confirmDeleteProduct = async (id) => {
    const response = await deleteProductQuery.fetchData({ id });
    if (response.error) {
      appState.setDismissableAlert(
        response.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      appState.hideConfirmation();
    }

    if (response.data) {
      appState.setDismissableAlert(
        response.data.deleteProduct.message,
        "success",
        ALERT_TIMEOUT_IN_MS,
      );
      appState.hideConfirmation();
      submitFilters();
    }
  };

  const fetchProductForTags = async (product) => {
    const response = await getSkuQuery.fetchData({ id: product.id });

    if (response?.data?.specificInventory) {
      setSelectedProductForTags(response.data.specificInventory);
      setShowAddTags(true);
    }
  };

  const fetchProductForEdit = async (product) => {
    const response = await getSkuQuery.fetchData({ id: product.id });

    if (response?.data?.specificInventory) {
      setEditingProduct(response.data.specificInventory);
    }
  };

  const actionsOnPopover = [
    {
      name: "Add Catalog",
      value: "add_catalog",
      icon: <PlusIcon className="h-6 w-6" />,
      onClick: () => handleCreateCatalogClick(),
    },
    {
      name: "Manage Tags",
      value: "add_tags",
      icon: <TagIcon className="h-6 w-6" />,
      // disabled: !selectedProducts?.length,
      // disabledMessage: "Please select a product to add tags",
      onClick: () => setShowManageTags(true),
    },
    {
      name: "Export Catalog",
      value: "export_catalog",
      icon: <PlusIcon className="h-6 w-6" />,
      onClick: () => setExportListHeaders(["SKU", "Product Name"]),
    },
  ];

  const actionsOnProduct = (product) => {
    if (product.isParentProduct) {
      return [
        {
          name: "Create Purchase Order",
          value: "purchaseOrder",
          icon: <PlusIcon className="h-6 w-6" />,
          onClick: () => {
            setShowPurchaseOrderSlide(true);
            setParentProductForPurchase(product);
          },
        },
      ];
    }

    return checkURL()
      ? [
          {
            name: "Edit Product Details",
            value: "edit",
            icon: <PencilIcon className="h-6 w-6" />,
            onClick: () => fetchProductForEdit(product),
          },
          {
            name: "Create Purchase Order",
            value: "purchaseOrder",
            icon: <PlusIcon className="h-6 w-6" />,
            onClick: () => {
              fetchCatalogProduct([product.id]);
            },
          },
          {
            name: "Add Tags",
            value: "tags",
            icon: <TagIcon className="h-6 w-6" />,
            onClick: () => fetchProductForTags(product),
          },
          {
            name: "Remove",
            value: "delete",
            icon: <TrashIcon className="h-6 w-6" />,
            onClick: () => deleteProduct(product.id),
          },
        ]
      : [
          {
            name: "Edit Product Details",
            value: "edit",
            icon: <PencilIcon className="h-6 w-6" />,
            onClick: () => fetchProductForEdit(product),
          },
          {
            name: "Add Tags",
            value: "tags",
            icon: <TagIcon className="h-6 w-6" />,
            onClick: () => fetchProductForTags(product),
          },
          {
            name: "Remove",
            value: "delete",
            icon: <TrashIcon className="h-6 w-6" />,
            onClick: () => deleteProduct(product.id),
          },
        ];
  };

  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  const getFinalSelectedValues = (selectedValues, options) => {
    const selectedIds = selectedValues.map((value) => value.id);
    const result = [];

    for (const option of options) {
      if (selectedIds.includes(option.id)) {
        result.push({
          id: option.id,
          name: option.name,
          description: option.description,
        });
      }
    }

    return result;
  };

  const saveProduct = async (media) => {
    const response = await saveProductQuery.fetchData({
      ...editingProduct,
      images: media ? media : editingProduct.images,
      categories:
        editingProduct.categories?.length > 0
          ? getFinalSelectedValues(editingProduct.categories, productCategories)
          : [],
    });
    if (response?.data?.saveProduct) {
      setEditingProduct(null);
      appState.setDismissableAlert(
        response.data.saveProduct.message,
        "success",
        ALERT_TIMEOUT_IN_MS,
      );
      submitFilters();
    }

    if (response?.error) {
      setEditingProduct(null);
      appState.setDismissableAlert(
        response.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  };

  useEffect(() => {
    if (saveProductQuery.loading) appState.setLoading();
    else appState.removeLoading();
  }, [saveProductQuery.loading]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const warehousesData = await warehousesQuery.fetchData();
        const vendorsData = await vendorsQuery.fetchData({
          filters: {
            classifications: ["Internal", "Third Party"],
          },
        });
        if (warehousesData.data?.warehouses?.entities?.length > 0)
          setWarehouses(warehousesData.data.warehouses.entities);
        if (vendorsData.data?.vendors?.entities?.length > 0)
          setVendors(vendorsData.data.vendors.entities);
      } catch (e) {
        console.log("Error fetching warehouses", e);
      }
    })();
  }, []);

  return (
    <>
      {showAddTags && (
        <SlideOverPanel
          open={showAddTags}
          setOpen={setShowAddTags}
          title="Add/Remove Tags"
          containerStyle={"max-w-3xl"}
          subTitle="Manage or add tags to products">
          <CatalogManageTags
            setShowAddTags={setShowAddTags}
            product={selectedProductForTags}
            loadFirstTimeData={loadFirstTimeData}
            fetchProductForTags={fetchProductForTags}
          />
        </SlideOverPanel>
      )}
      {showManageTags && (
        <SlideOverPanel
          open={showManageTags}
          setOpen={setShowManageTags}
          title="Manage Tags"
          containerStyle={"max-w-3xl"}
          subTitle="Manage or create new tags.">
          <CatalogManageTags
            loadFirstTimeData={loadFirstTimeData}
            showManageTags={showManageTags}
            setShowManageTags={setShowManageTags}
          />
        </SlideOverPanel>
      )}
      <SlideOverPanel
        open={showPurchaseOrderSlide}
        setOpen={setShowPurchaseOrderSlide}
        title="Add Purchase Order"
        containerStyle={"max-w-7xl"}
        subTitle="In here we will give context on creating new Purchase Order, Learn More">
        <PurchaseOrderMain
          parentProductForPurchase={parentProductForPurchase}
          productForPurchase={productForPurchase}
          fetchCatalogProduct={fetchCatalogProduct}
          toView={false}
          toCreate={true}
          setShowPurchaseOrderSlide={setShowPurchaseOrderSlide}
          warehouses={warehouses}
          vendors={vendors}
        />
      </SlideOverPanel>
      {editingProduct && (
        <SlideOverPanel
          open={true}
          setOpen={setEditingProduct}
          title="Edit Product Details"
          containerStyle={"max-w-7xl"}
          subTitle="Manage product attributes, identifiers, images etc">
          <CatalogEditProduct
            product={editingProduct}
            setProduct={setEditingProduct}
            productCategories={productCategories}
            saveProduct={saveProduct}
            customers={customers}
          />
        </SlideOverPanel>
      )}
      <div
        className={`hs-catalog-main-content-${showLeftSidebar} h-70v w-full px-4 sm:px-6 lg:px-8`}>
        <div className="item-center mt-2 flex justify-center space-x-4">
          <CatalogEnhancedSearch
            products={products}
            selectProduct={selectProduct}
            selectedProducts={selectedProducts}
            allRowsSelected={allRowsSelected}
            selectAllRows={selectAllRows}
            getCatalog={getCatalog}
            actionsOnProduct={actionsOnProduct}
            productCategories={productCategories}
            shiftKeyPressed={shiftKeyPressed}
            parentProducts={parentProducts}
          />

          {/* 
          for now this should be hidden as feature is yet to be implemented.
          <button className="hs-catalog-topbar-action">
            <AdjustmentsIcon className="h-6 w-6" />
          </button> 
          */}
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className={`hs-catalog-topbar-action-group-left ${
                showListView && "hs-catalog-topbar-action-group-active"
              }`}
              onClick={() => setShowListView(true)}>
              <ViewListIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={`hs-catalog-topbar-action-group-right ${
                !showListView && "hs-catalog-topbar-action-group-active"
              }`}
              onClick={() => setShowListView(false)}>
              <ViewGridIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </span>
          <Popover
            title={"Actions"}
            showChevron={true}
            panelClassName={
              "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray"
            }
            showOverlay={true}
            customClassName={"py-4"}>
            {actionsOnPopover.map((action, idx) => {
              return (
                <div
                  className={`cursor-pointer whitespace-nowrap rounded-lg p-3 font-medium ${
                    action.disabled
                      ? "cursor-not-allowed text-gray-200"
                      : "text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                  }`}
                  onClick={
                    action.onClick && !action.disabled
                      ? action.onClick
                      : () =>
                          action.disabledMessage
                            ? alert(action.disabledMessage)
                            : console.log(action.value, action.name)
                  }
                  key={idx}>
                  {action.name}
                </div>
              );
            })}
          </Popover>
        </div>

        <div className="mt-2 sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg leading-6 text-[#8A8B8E]">
              Products: {total}
            </h1>
          </div>
        </div>
        <div
          onScroll={handleScroll}
          className="mt-2 flow-root h-full overflow-x-auto">
          <div className="-mx-4 -my-2 h-full overflow-visible sm:-mx-6 lg:-mx-8">
            <div className="inline-block h-full min-w-full overflow-y-visible py-2 align-middle sm:px-6 lg:px-8">
              {showListView ? (
                <ListViewProducts
                  products={products}
                  selectProduct={selectProduct}
                  selectedProducts={selectedProducts}
                  allRowsSelected={allRowsSelected}
                  selectAllRows={selectAllRows}
                  getCatalog={getCatalog}
                  actionsOnProduct={actionsOnProduct}
                  productCategories={productCategories}
                  shiftKeyPressed={shiftKeyPressed}
                  selectAllDisabled={selectAllDisabled}
                  parentProducts={parentProducts}
                />
              ) : (
                <GridViewProducts
                  products={products}
                  selectProduct={selectProduct}
                  selectedProducts={selectedProducts}
                  allRowsSelected={allRowsSelected}
                  selectAllRows={selectAllRows}
                  getCatalog={getCatalog}
                  actionsOnProduct={actionsOnProduct}
                  parentProducts={parentProducts}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CatalogMainContent;
