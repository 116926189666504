import { CheckIcon } from "@heroicons/react/solid";
import moment from "moment-timezone";
import Modal from "#components/utils/Modal";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ExpandedStockTransfer = ({
  stockTransfer,
  negativeAction,
  warehouses,
  users,
}) => {
  let tabs = {};
  tabs = {
    ...tabs,
    Items: (
      <OrderLineItems
        item={stockTransfer}
        warehouses={warehouses}
        users={users}
      />
    ),
  };

  return (
    <Modal
      negativeAction={negativeAction}
      title={`Bin to Bin Transfer Details`}
      noPadding={true}
      positiveAction={negativeAction}
      positiveText="OK">
      <div className="overflow-auto bg-EFE9DC p-4">
        <Tab.Group>
          <Tab.List className="flex items-center">
            <div className="flex-1 space-x-4 rounded-xl p-1 px-0">
              {/* {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "px-6 py-2.5 text-sm leading-5 font-regular rounded-full",
                        "focus:outline-none focus:ring-0 font-montserrat",
                        selected
                          ? "bg-white text-5F7174"
                          : "border-transparent bg-E1D3B8 border rounded-full text-primaryAccent"
                      )
                    }
                  >
                    {tab}
                  </Tab>
                ))} */}
            </div>
          </Tab.List>
          <Tab.Panels className="mt-2">
            {tabs &&
              Object.values(tabs).map((tabInner, idx) => (
                <Tab.Panel
                  key={idx}
                  className="textWhite-300 mt-4 flex flex-col border bg-EBEBEB">
                  {tabInner}
                </Tab.Panel>
              ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Modal>
  );
};

const OrderLineItems = ({ item, warehouses, users }) => {
  const headers = [
    "SKU",
    "Warehouse",
    "From Location",
    "To Location",
    "Quantity",
    "Personnel",
    "Status",
  ];
  return (
    <table className="min-w-full divide-y divide-gray-200 px-2">
      <thead className="rounded-full bg-primaryAccent p-4 px-12">
        <tr className="border-left px-12 font-montserrat text-textWhite">
          {headers.map((header, headerIdx) =>
            headerIdx === 0 ? (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ) : (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {item.items?.map((i, index) => (
          <tr
            key={item.id}
            className={`${item.index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
            <td
              className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${"border-F4C261"}`}>
              {item.sku}
            </td>
            <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
              {warehouses.find((a) => a.id === item.warehouse)?.name}
            </td>

            <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
              {i.fromBin}
            </td>

            <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
              {i.toBin}
            </td>

            <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
              {i.quantity}
            </td>

            <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
              {users.find((a) => a.id === i.personnel)?.name}
            </td>

            <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
              {item.status}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ExpandedStockTransfer;

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};
