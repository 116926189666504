import { Tab } from "@headlessui/react";
import DetailedView from "#components/bundler/DetailedView";
import ListView from "#components/bundler/ListView";
import CompletedBatch from "#components/bundler/CompletedBatch";
import { useState } from "react";

const BundlerBatch = ({
  childTask,
  selectedBundle,
  selectedBundleItem,
  scanBundleItem,
  confirmBundleItem,
  printBundlingLabels,
  confirmBundle,
  onChangeScannedQuantity,
  skipBundle,
}) => {
  const [selectedIndex, setSelectedIndex] = useState("List View");
  const tabs = {
    "Detail View": (
      <DetailedView
        childTask={childTask}
        selectedBundleItem={selectedBundleItem}
        scanBundleItem={scanBundleItem}
        confirmBundleItem={confirmBundleItem}
        onChangeScannedQuantity={onChangeScannedQuantity}
        skipBundle={skipBundle}
      />
    ),
    "List View": (
      <ListView
        childTask={childTask}
        scanBundleItem={scanBundleItem}
        setSelectedIndex={setSelectedIndex}
      />
    ),
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const childTaskCompleted =
    childTask.inventory?.filter((item) => item.status === "COMPLETE")
      ?.length === childTask.inventory.length;

  if (childTaskCompleted) {
    return (
      <CompletedBatch
        childTask={childTask}
        selectedBundle={selectedBundle}
        onChangeScannedQuantity={onChangeScannedQuantity}
        printBundlingLabels={printBundlingLabels}
        confirmBundle={confirmBundle}
        skipBundle={skipBundle}
      />
    );
  }

  return (
    <>
      <div className="flex-col pt-32">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex items-center justify-center">
            <div className="space-x-2 rounded-full bg-primaryAccent p-2">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "font-regular rounded-full px-6 py-2.5 leading-5",
                        "z-50 font-montserrat text-lg focus:outline-none focus:ring-0",
                        selected
                          ? "bg-white font-medium text-primaryAccent"
                          : "rounded-full border border-primaryAccent bg-transparent text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="mt-4 flex flex-col items-center bg-transparent p-4">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default BundlerBatch;
