const SlateTable = ({ children }) => {
  return (
    <div className="w-full rounded-t">
      <table className="w-full rounded-t">{children}</table>
    </div>
  );
};

const SlateTableHeaderCell = ({ children, first, stickyHeader }) => {
  return (
    <th
      className={`rounded-t text-left text-sm font-medium text-lightGray ${
        first ? "pl-12" : "p-6"
      }`}>
      {children}
    </th>
  );
};

const SlateTableHeader = ({ children, sticky }) => {
  return (
    <thead className="h-14 w-full rounded-t">
      <tr className={`h-14 w-full bg-gray-50 ${sticky ? "sticky top-0" : ""}`}>
        {children}
      </tr>
    </thead>
  );
};

const SlateTableBody = ({ children, styles }) => {
  return <tbody className={`w-full ${styles}`}>{children}</tbody>;
};

const SlateTableBodyRow = ({ children, style, className, noBottomBorder }) => {
  return (
    <tr
      className={`${
        noBottomBorder ? "" : "border-b border-gray-100"
      } h-20 w-full ${className || ""}`}
      style={style}>
      {children}
    </tr>
  );
};

const SlateTableBodyRowCell = ({
  children,
  first,
  colspan,
  className,
  style,
}) => {
  return (
    <td
      className={`table-cell text-base text-black ${first ? "pl-12" : "pl-6"} ${
        className || ""
      } `}
      colSpan={colspan}
      style={style}>
      {children}
    </td>
  );
};

export {
  SlateTable,
  SlateTableHeader,
  SlateTableBody,
  SlateTableBodyRow,
  SlateTableBodyRowCell,
  SlateTableHeaderCell,
};
