import {
  Document,
  Page,
  Text,
  View,
  Image,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment-timezone";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";

Font.register({
  family: "Cairo",
  src: "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hOA-W1Q.ttf",
});

const styles = StyleSheet.create({
  arabicText: {
    fontFamily: "Cairo",
  },
});

const GoodsShippedNote = ({ order, tenant, warehouse, customer }) => {
  const shippingAddressExists = order?.shippingAddress
    ? Object.values(order.shippingAddress).filter(Boolean).length > 0
    : false;

  const orderAdditionalDetailsExists =
    Object.values([
      order?.carrier,
      order?.carrierService,
      order?.trackingNumber,
    ]).filter(Boolean).length > 0;

  return (
    <Document style={styles.arabicText}>
      <Page size="A4">
        <View
          style={{
            backgroundColor: "#224E73",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 10,
          }}>
          {
            <Image
              src={{
                uri: tenant && tenant.logo ? tenant.logo : "",
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
              style={{ width: 100 }}
              fixed={true}
            />
          }
        </View>

        <View
          style={{
            padding: "20px 20px 0 20px",
            fontSize: "15px",
          }}>
          <View>
            <Text
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                fontSize: 24,
                fontWeight: "bold",
              }}>
              Order Report:
            </Text>
          </View>

          <View>
            {order.orderId && (
              <Text style={{ marginBottom: "10px", fontWeight: "bold" }}>
                Order ID: {order.orderId}
              </Text>
            )}
            {order.orderDate && (
              <Text style={{ marginBottom: "10px", fontWeight: "bold" }}>
                Order Date: {moment(order.orderDate).format("YYYY-MM-DD")}
              </Text>
            )}
            {order.orderStatus === "COMPLETED" && (
              <Text style={{ marginBottom: "10px", fontWeight: "bold" }}>
                Completed on: {moment(order.completedDate).format("YYYY-MM-DD")}
              </Text>
            )}
            {customer && (
              <Text style={{ marginBottom: "10px", fontWeight: "bold" }}>
                Client Name: {customer}
              </Text>
            )}
            {warehouse && (
              <Text style={{ marginBottom: "10px", fontWeight: "bold" }}>
                Warehouse: {warehouse}
              </Text>
            )}

            {shippingAddressExists && (
              <Text
                style={{
                  width: "100%",
                  margin: "15px 0",
                  borderTop: "1px solid #ccc",
                }}></Text>
            )}

            {shippingAddressExists && (
              <View>
                <Text style={{ fontWeight: "extrabold" }}>
                  Shipping Address:
                </Text>
                <Text>
                  {order.shippingAddress.line1
                    ? order.shippingAddress.line1 + ","
                    : ""}
                </Text>
                <Text>
                  {order.shippingAddress.line2
                    ? order.shippingAddress.line2 + ","
                    : ""}
                </Text>
                <Text>
                  {[
                    order.shippingAddress.city,
                    order.shippingAddress.state,
                    order.shippingAddress.zip,
                    order.shippingAddress.country,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </Text>
              </View>
            )}

            {orderAdditionalDetailsExists && (
              <Text
                style={{
                  width: "100%",
                  margin: "15px 0",
                  borderTop: "1px solid #ccc",
                }}></Text>
            )}

            {orderAdditionalDetailsExists && (
              <View>
                {order.carrier && (
                  <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                    Carrier: {order.carrier}
                  </Text>
                )}
                {order.carrierService && (
                  <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                    Service: {order.carrierService}
                  </Text>
                )}
                {order.trackingNumber && (
                  <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                    Tracking Number: {order.trackingNumber}
                  </Text>
                )}
              </View>
            )}
          </View>
        </View>

        <View style={{ padding: "0 20px" }}>
          <Text style={{ fontWeight: "bold" }}>
            Items({order.orderLineItems ? order.orderLineItems.length : 0}
            ):
          </Text>
        </View>

        {order.orderLineItems.length > 0
          ? order.orderLineItems.map((orderItem) => (
              <ReactPdfTable
                columns={tableColumns(order)}
                data={orderItem}
                additionalDetails={additionalTableData()}
                key={orderItem.id}
              />
            ))
          : null}

        <View style={{ marginTop: 10, padding: 20, flexDirection: "row" }}>
          <Text
            style={{
              fontWeight: "medium",
              fontSize: 16,
            }}>
            Driver's Signature:
          </Text>
        </View>
        <View style={{ padding: "0 20px", flexDirection: "row" }}>
          <Text
            style={{
              fontWeight: "medium",
              fontSize: 16,
            }}>
            Warehouse Representative's Signature:
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            marginTop: 40,
            marginRight: 30,
            marginLeft: "auto",
          }}
          wrap={false}>
          <Text style={{ fontSize: 7, padding: "0 5px" }}>Fulfilled on</Text>
          <Image
            src={{
              uri: "https://hopstack-pub.s3.amazonaws.com/hopstack-logo.jpg",
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
            style={{ width: 60, height: 20, padding: 5 }}
            fixed={true}
          />
        </View>
      </Page>
    </Document>
  );
};

const tableColumns = (order, tenantType) => {
  const returnArr = [];
  returnArr.push({ title: "SKU", dataIndex: "sku" });
  returnArr.push({
    title: "UPC",
    dataIndex: "upc",
    render: (_, item) => item.upc?.join(", "),
  });
  if (tenantType === "Prep Center") {
    returnArr.push({
      title: "ASIN",
      dataIndex: "asin",
      render: (lineItemData, lineItem) => {
        return buildMarketplaceHyperlink(lineItemData, "ASIN", lineItem.source);
      },
    });
  }

  returnArr.push(
    { title: "Lot/Batch ID", dataIndex: "lotId" },
    {
      title: "Expiry Date",
      dataIndex: "bestByDate",
      render: (_, item) =>
        item?.bestByDate ? moment(item.bestByDate).format("YYYY-MM-DD") : "",
    },
    {
      title: "Shipped Qty",
      dataIndex: "quantity",
      render: (_, item) => (isNaN(item.quantity) === false ? item.quantity : 0),
    },
    {
      title: "UoM",
      dataIndex: "formFactor",
      render: (_, item) => (item.formFactor ? item.formFactor : "Each"),
    },
  );

  return returnArr;
};

const additionalTableData = () => {
  const returnArr = [
    { title: "Bin Location", dataIndex: "binLocation" },
    { title: "Name", dataIndex: "productName" },
    { title: "LPN", dataIndex: "nestedFormFactorId" },
    { title: "Pallet ID", dataIndex: "palletId" },
  ];

  return returnArr;
};

const ReactPdfTable = ({ columns, data, additionalDetails }) => {
  const colWidth = Math.ceil(100 / columns.length);
  const colStyle = {
    width: colWidth.toString().concat("%"),
  };
  const rowStyles = {
    display: "flex",
    flexDirection: "row",
  };
  const headerStyles = {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ececec",
  };
  const cellStyles = {
    border: "1px solid #ccc",
    padding: "4px",
  };

  const breakWord = (word) => {
    if (!word) return;

    const wordLength = word.toString().length;
    const WORD_LIMIT = 10;
    const newWord = [];

    word = word.toString();

    if (wordLength > WORD_LIMIT) {
      for (let i = 0; i < wordLength; i++) {
        newWord.push(word[i]);
        if (i !== 0 && i % WORD_LIMIT === 0) {
          newWord.push(`\n`);
        }
      }

      return newWord.join("");
    }

    return word;
  };

  return (
    <View
      style={{ width: "100%", padding: 20, borderBottom: "1px solid #ccc" }}
      wrap={false}>
      <View style={{ ...headerStyles }}>
        {columns.map((col) => (
          <Text
            style={{ ...colStyle, ...cellStyles, fontSize: 12 }}
            key={col.title}>
            {col.title}
          </Text>
        ))}
      </View>

      <View style={rowStyles}>
        {columns.map((col) => (
          <Text
            style={{
              ...colStyle,
              ...cellStyles,
              fontSize: 12,
            }}
            key={col.dataIndex}>
            {breakWord(
              col?.render
                ? col.render(data[col.dataIndex], data)
                : data[col.dataIndex],
            )}
          </Text>
        ))}
      </View>

      <View style={{ marginTop: 12 }}>
        {additionalDetails.map((detail) => (
          <Text style={{ fontSize: 12 }} key={detail.dataIndex}>
            {detail.title}: {data[detail.dataIndex]}
          </Text>
        ))}
      </View>
    </View>
  );
};

const Logo = ({ tenant = {} }) => (
  <div className="flex items-center justify-center space-x-2 py-2">
    {tenant && tenant.logo ? (
      <img src={tenant.logo} alt="Custom Logo" className="h-20" />
    ) : (
      <div className="flex items-center justify-center space-x-2 py-2">
        <img
          src="https://hopstack-pub.s3.amazonaws.com/logo.png"
          alt="Custom Logo"
          className="h-10 w-10"
        />
        <span className="font-hammersmith text-xl text-white">
          Hopstack Inc
        </span>
      </div>
    )}
  </div>
);

export default GoodsShippedNote;
