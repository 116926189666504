import { SearchIcon, XIcon, FilterIcon } from "@heroicons/react/solid";
import HollowButton from "../../utils/hollowButton";
import WarehouseStorageSections from "../../components/storageManagement/WarehouseStorageSections";
import { useState } from "react";

const StorageManagementHome = ({
  filters,
  onChangeSearchKeyword,
  clearKeyword,
  submitFilters,
  warehouses,
  selectedStorageSection,
  setSelectedStorageSection,
  INITIAL_STORAGE_SECTION_STATE,
  setLocationTree,
  storageSections,
  setStorageSections,
  storageSectionsSearchString,
  locationTypes,
  setStorageSectionsSearchString,
  deleteStorageSection,
  storageSectionStatusUpdate,
  setStorageSectionStatusUpdate,
  submitStorageSectionStatusUpdate,
  setLoading,
  buildLocationTreeFromStorageSection,
  showAlert,
  storageSectionToDelete,
  setStorageSectionToDelete,
  getCurrentHierarchyArray,
}) => {
  return (
    <>
      <div className="min-h-screen bg-white px-3 py-5">
        <div className="text-xl font-semibold">Storage Management</div>
        <div className="mt-1 text-sm font-normal text-gray-500">
          Effortlessly replicate your physical space, creating diverse storage
          types and detailed hierarchies for optimal space utilization and
          streamlined operations.
        </div>
        <div className="mt-5 flex w-full">
          <div className="relative mt-1 w-full rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="keyword"
              id="keyword"
              className="block w-full rounded-md border-gray-300 p-1 pl-12 text-base focus:outline-none"
              placeholder="Search"
              onChange={(e) => setStorageSectionsSearchString(e.target.value)}
              value={storageSectionsSearchString}
              onKeyDown={(e) => (e.key === "Enter" ? submitFilters() : null)}
            />
            <div
              className="absolute inset-y-0 right-2 flex cursor-pointer items-center pl-3"
              onClick={clearKeyword}>
              <XIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
          </div>
          <div className="ml-2 pt-1">
            <HollowButton
              text="Filter"
              colour="black"
              onClick={submitFilters}
            />
          </div>
        </div>

        <div className="mt-2">
          <WarehouseStorageSections
            storageSections={storageSections}
            setStorageSections={setStorageSections}
            warehouses={warehouses}
            selectedStorageSection={selectedStorageSection}
            setSelectedStorageSection={setSelectedStorageSection}
            INITIAL_STORAGE_SECTION_STATE={INITIAL_STORAGE_SECTION_STATE}
            setLocationTree={setLocationTree}
            locationTypes={locationTypes}
            deleteStorageSection={deleteStorageSection}
            storageSectionStatusUpdate={storageSectionStatusUpdate}
            setStorageSectionStatusUpdate={setStorageSectionStatusUpdate}
            submitStorageSectionStatusUpdate={submitStorageSectionStatusUpdate}
            setLoading={setLoading}
            buildLocationTreeFromStorageSection={
              buildLocationTreeFromStorageSection
            }
            showAlert={showAlert}
            storageSectionToDelete={storageSectionToDelete}
            setStorageSectionToDelete={setStorageSectionToDelete}
            getCurrentHierarchyArray={getCurrentHierarchyArray}
          />
        </div>
      </div>
    </>
  );
};

export default StorageManagementHome;
