import React, { useContext } from "react";
import Tabs from "#components/utils/Tabs";
import PageTitle from "#components/utils/PageTitle";
import SoftwareIntegrations from "#components/integrations/SoftwareIntegrations";
import HardwareIntegrations from "#components/integrations/HardwareIntegrations";
import GlobalIntegrations from "#components/integrations/GlobalIntegrations";
import { AuthContext } from "#contexts/auth";

const Integrations = ({ writable, location }) => {
  const { user } = useContext(AuthContext);
  const isAdmin = user?.role?.toLowerCase() === "admin";

  const tabs = {
    Client: <SoftwareIntegrations writable={writable} location={location} />,
    // Hardware: (
    //   <HardwareIntegrations writable={writable} location={location} />
    // ),
  };

  if (isAdmin) {
    tabs.Global = (
      <GlobalIntegrations writable={writable} location={location} />
    );
  }

  return (
    <div className="p-5">
      <PageTitle>Integration Management</PageTitle>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default Integrations;
