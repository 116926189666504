import TableFieldButton from "#components/utils/TableFieldButton";
import Checkbox from "#components/utils/Checkbox";
import { PencilIcon, TrashIcon, PrinterIcon } from "@heroicons/react/outline";
import SearchSortFilter from "#components/common/SearchSortFilter";
import _ from "lodash";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
import { useEffect, useState } from "react";
import QuickFilters from "../common/QuickFilters";

const BinLocationsList = ({
  binLocations,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  selectedBinLocations,
  selectBinLocation,
  queueSinglePrint,
  selectAllBinLocations,
  allBinLocationsSelected,
  warehouses,
  customers,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  onChangeFilter,
}) => {
  const noValuesText = "No Bin Locations Found.";

  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div className="tableWrapper">
      {warehouses.length > 1 && (
        <QuickFilters
          warehouseFilterName={"warehouses"}
          customerFilterName={"customers"}
          // customers array is not being passed here since latest locations model does not have an inherent client linkage.
          warehouses={warehouses}
          filters={filters}
          onChangeFilter={onChangeFilter}
          width={"1/3"}
        />
      )}
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Location Type",
            value: "type",
          },
          {
            name: "Barcode",
            value: "code",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            <th
              scope="col"
              className="border-l-8 border-transparent px-2 py-3 pl-5 text-left font-medium tracking-wider">
              <Checkbox
                role="checkbox"
                onChange={selectAllBinLocations}
                name="binLocations"
                value={"Select All Bin Locations"}
                checked={allBinLocationsSelected}
              />
            </th>
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {!binLocations ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"></td>
            </tr>
          ) : (
            binLocations.map((binLocation, index) => (
              <tr
                key={binLocation.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  <Checkbox
                    role="checkbox"
                    onChange={(_) => selectBinLocation(index, shiftKeyPressed)}
                    name="binLocation"
                    value={binLocation.id}
                    checked={selectedBinLocations.includes(binLocation.id)}
                  />
                </td>

                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {binLocation.typeName}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {binLocation.code}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {binLocation.warehouse &&
                    warehouses &&
                    warehouses.filter(
                      (item) => binLocation.warehouse === item.id,
                    )[0]?.name}
                </td>
                <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  <div className="flex items-center space-x-4">
                    {!(
                      binLocation.isVirtualLocation ||
                      binLocation.isSystemLocation
                    ) && (
                      <>
                        <TableFieldButton
                          onClick={() => editButtonClicked(binLocation)}
                          text={<PencilIcon className="h-6 w-6" />}
                        />

                        <TableFieldButton
                          text={<TrashIcon className="h-6 w-6" />}
                          onClick={() => deleteButtonClicked(binLocation.id)}
                        />
                      </>
                    )}
                    <TableFieldButton
                      text={<PrinterIcon className="h-6 w-6" />}
                      onClick={() => queueSinglePrint(binLocation.id)}
                    />
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default BinLocationsList;
