import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_TOTES,
  GET_TOTE,
  GET_ENTITY_TYPES,
  GET_TOTES_FIELDS,
} from "#queries";
import {
  SAVE_TOTE,
  DELETE_TOTE,
  SAVE_ENTITY_TYPE,
  BULK_UPLOAD_TOTES,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import {
  printCanvasLabelPdf,
  SUPPORTED_SIZES_WITHOUT_CANVAS,
} from "#utils/printCanvasLabelPdf";
import readXlsxFile from "read-excel-file";
import { EntityContext } from "#contexts/entity";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";

const withTotesLogic = (WrappedComponent) => {
  return (props) => {
    const [addCustomEntity, setAddCustomEntity] = useState(null);
    const [entityTypes, setEntityTypes] = useState([]);
    const [customerFilter, setCustomerFilter] = useState({
      customer: null,
      warehouse: null,
    });
    const auth = useContext(AuthContext);
    const [filters, setFilters] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const appState = useContext(AppStateContext);
    const entity = useContext(EntityContext);
    const [allTotesSelected, setAllTotesSelected] = useState(false);
    const [selectedPrint, setSelectedPrint] = useState(null);
    const [selectedPrints, setSelectedPrints] = useState(null);
    const [selectedTote, setSelectedTote] = useState(null);
    const [selectedTotes, setSelectedTotes] = useState([]);
    const [emptyTote, setEmptyTote] = useState(null);
    const totesQuery = useQuery(GET_TOTES);
    const getToteQuery = useQuery(GET_TOTE);
    const saveToteQuery = useQuery(SAVE_TOTE);
    const saveMultipleToteQuery = useQuery(SAVE_TOTE);
    const deleteToteQuery = useQuery(DELETE_TOTE);
    const entityTypesQuery = useQuery(GET_ENTITY_TYPES);
    const saveEntityTypeQuery = useQuery(SAVE_ENTITY_TYPE);
    const [showFilters, setShowFilters] = useState(false);
    const getTotesFields = useQuery(GET_TOTES_FIELDS);
    const uploadBulk = useQuery(BULK_UPLOAD_TOTES);

    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [lastSelectedRow, setLastSelectedRow] = useState(null);
    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "Totes" }),
    );
    const [validationResult, setValidationResult] = useState(null);

    useEffect(() => {
      if (getTotesFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getTotesFields.data) {
        setDashboardFields(getTotesFields.data.toteFields);
      }
    }, [getTotesFields.loading, getTotesFields.data, getTotesFields.error]);

    useEffect(() => {
      if (uploadBulk.data) {
        totesQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadTotes.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadTotes
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      fetchEntities();
      fetchEntityTypes();
      getTotesFields.fetchData();
    }, []);

    const fetchEntityTypes = () => {
      const filtersSet = { entityParent: ["TOTE"] };
      entityTypesQuery.fetchData({ filters: filtersSet });
    };

    useEffect(() => {
      if (entityTypesQuery.data) {
        setEntityTypes(entityTypesQuery.data.entityTypes);
      }

      if (entityTypesQuery.error) {
        setEntityTypes([]);
      }
    }, [entityTypesQuery.data, entityTypesQuery.error]);

    const fetchEntities = () => {
      totesQuery.fetchData({
        perPage: entity.perPage,
        filters: {},
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    };

    useEffect(() => {
      if (totesQuery.data) {
        entity.setEntities({
          ...totesQuery.data.totes,
          ...totesQuery.variables,
        });
        appState.removeLoading();
      }
    }, [totesQuery.loading, totesQuery.error, totesQuery.data]);

    useEffect(() => {
      if (saveEntityTypeQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveEntityTypeQuery.data) {
        const filtersSet = { entityParent: ["TOTE"] };
        entityTypesQuery.fetchData({ filters: filtersSet });
        setAddCustomEntity(null);
      }

      if (saveEntityTypeQuery.error) {
        appState.setAlert(saveEntityTypeQuery.error.message, "error", 5000);
      }
    }, [
      saveEntityTypeQuery.loading,
      saveEntityTypeQuery.data,
      saveEntityTypeQuery.error,
    ]);

    useEffect(() => {
      if (getToteQuery.data) {
        setSelectedTote(getToteQuery.data.tote);
      }

      if (getToteQuery.error) {
        setSelectedTote(null);
      }
    }, [getToteQuery.loading, getToteQuery.data, getToteQuery.error]);

    useEffect(() => {
      if (saveToteQuery.error) {
        appState.setAlert(saveToteQuery.error.message, "error", 5000);
      }

      if (saveToteQuery.data && selectedTote) {
        appState.setAlert(saveToteQuery.data.saveTote.message, "success", 5000);
        setSelectedTote(null);
        totesQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }
    }, [saveToteQuery.loading, saveToteQuery.error, saveToteQuery.data]);

    useEffect(() => {
      if (deleteToteQuery.data) {
        appState.hideConfirmation();
        totesQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (deleteToteQuery.error) {
        appState.hideConfirmation();
      }
    }, [deleteToteQuery.loading, deleteToteQuery.data, deleteToteQuery.error]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this tote?",
        () => {
          deleteToteQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    const onChange = (e, entity = "selectedTote") => {
      if (entity === "selectedTote") {
        const tote = {
          ...selectedTote,
        };

        tote[e.target.name] =
          e.target.type === "number"
            ? parseInt(e.target.value)
            : e.target.value;
        setSelectedTote(tote);
      } else {
        const tote = {
          ...emptyTote,
        };

        tote[e.target.name] =
          e.target.type === "number"
            ? parseInt(e.target.value)
            : e.target.value;
        setEmptyTote(tote);
      }
    };

    const onChangeDropdown = (field, value, entity = "selectedTote") => {
      if (entity === "selectedTote") {
        const tote = {
          ...selectedTote,
        };

        if (field === "toteType") {
          if (value === "Add Custom") {
            return setAddCustomEntity({
              entityParent: "TOTE",
              name: "",
            });
          }
        }

        tote[field] = value;
        if (
          field === "toteType" &&
          (!tote.barcode ||
            tote.barcode.trim() === "" ||
            tote.barcode.length === 4)
        ) {
          const toteType = entityTypes.find((i) => i.name === tote.toteType);
          tote.barcode = `${naming(toteType)}-`;
        }
        setSelectedTote(tote);
      } else {
        if (field === "toteType") {
          if (value === "Add Custom") {
            return setAddCustomEntity({
              entityParent: "TOTE",
              name: "",
            });
          }
        }
        const tote = {
          ...emptyTote,
        };

        tote[field] = value;
        setEmptyTote(tote);
      }
    };

    const saveEmptyTote = () => {
      const tote = {
        ...emptyTote,
      };

      if (!tote.toteType) {
        appState.setAlert("Select a Tote Type", "error", 5000);
        return;
      }

      if (!tote.numberOfTotes) {
        appState.setAlert("Select number of totes", "error", 5000);
        return;
      }

      if (tote.numberOfTotes <= 0) {
        appState.setAlert(
          "Number of totes should be at least 1",
          "error",
          5000,
        );
        return;
      }

      if (!tote.namingStart) {
        appState.setAlert("Select a naming sequence start", "error", 5000);
        return;
      }

      if (!tote.namingIncrement) {
        appState.setAlert("Select a naming sequence increment", "error", 5000);
        return;
      }

      let currentNumber = tote.namingStart;

      const toteType = entityTypes.find((i) => i.name === tote.toteType);
      let namingConvention = naming(toteType);
      const randomNumber = Math.floor(100000 + Math.random() * 900000);
      for (let i = 0; i < tote.numberOfTotes; i++) {
        let numberValue = `${currentNumber}`;
        if (currentNumber < 10) {
          numberValue = `00${currentNumber}`;
        } else if (currentNumber < 100) {
          numberValue = `0${currentNumber}`;
        }
        let barcode = `${namingConvention}-${randomNumber}-${numberValue}`;
        saveToteQuery.fetchData({
          toteType: tote.toteType,
          barcode,
          customers: tote.customers,
          warehouses: tote.warehouses,
          subTotes: tote.subTotes,
        });
        currentNumber += tote.namingIncrement;
      }
      setTimeout(() => {
        totesQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setEmptyTote(null);
      }, 2000);
    };

    const selectTote = (index, shiftKeyPressed) => {
      const selectedListCopy = [...selectedTotes];
      const entities = entity.entities;
      if (shiftKeyPressed) {
        if (lastSelectedRow !== null) {
          const firstIndex = Math.min(lastSelectedRow, index);
          const lastIndex = Math.max(lastSelectedRow, index);
          for (let i = firstIndex; i <= lastIndex; i++) {
            if (!selectedListCopy.includes(entities[i].id)) {
              selectedListCopy.push(entities[i].id);
            }
          }
        } else {
          setLastSelectedRow(index);
        }
      } else {
        setLastSelectedRow(index);
        const idx = selectedListCopy.indexOf(entities[index].id);
        if (idx !== -1) {
          selectedListCopy.splice(idx, 1);
        } else {
          selectedListCopy.push(entities[index].id);
        }
      }

      setSelectedTotes(selectedListCopy);
    };

    const clearSelectedTotes = () => {
      setSelectedTotes([]);
    };

    const queueSinglePrint = (id) => {
      const tote = totesQuery.data.totes.entities.find(
        (item) => item.id === id,
      );
      if (!tote) {
        return;
      }
      setSelectedPrints({
        barcodes: [tote.barcode],
        barcodeType: "1D Barcode",
        noOfCopies: 1,
        dpi: 8,
        printer: "Zebra",
        printSize: "3x1",
      });
    };

    const queueMultiplePrints = () => {
      const toteBarcodes = totesQuery.data.totes.entities
        .filter((item) => selectedTotes.includes(item.id))
        .map((item) => item.barcode);
      if (!toteBarcodes || toteBarcodes.length === 0) {
        return;
      }

      setSelectedPrints({
        barcodes: toteBarcodes,
        barcodeType: "1D Barcode",
        noOfCopies: 1,
        dpi: 8,
        printer: "Zebra",
        printSize: "3x1",
      });
    };

    const selectAllTotes = () => {
      if (allTotesSelected === true) {
        setSelectedTotes([]);
        setAllTotesSelected(false);
      } else {
        setAllTotesSelected(true);
        setSelectedTotes(totesQuery.data.totes.entities.map((item) => item.id));
      }
    };

    const uploadMultipleEntries = async (files) => {
      const rows = await readXlsxFile(files);
      for (let i = 0; i < rows.length; i++) {
        if (i === 0) {
          continue;
        }
        const row = rows[i];
        if (!row[0] || !row[1]) {
          continue;
        }

        saveMultipleToteQuery.fetchData({
          barcode: row[0].toString(),
          toteType: row[1].toString(),
        });
      }

      appState.setLoading();
      setTimeout(() => {
        totesQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        appState.removeLoading();
      }, 1500);
    };

    const onChangeMultiSelect = (field, value) => {
      if (selectedTote) {
        const tote = {
          ...selectedTote,
        };
        tote[field] = value;
        setSelectedTote(tote);
      } else {
        const tote = {
          ...emptyTote,
        };
        tote[field] = value;
        setEmptyTote(tote);
      }
    };

    const addSubTote = () => {
      if (selectedTote) {
        const tote = {
          ...selectedTote,
        };
        if (tote.subTotes) {
          tote.subTotes.push("");
        } else {
          tote.subTotes = [""];
        }
        setSelectedTote(tote);
      }
    };

    const onChangeSubTote = (idx, value) => {
      if (selectedTote) {
        const tote = {
          ...selectedTote,
        };
        if (tote.subTotes) {
          tote.subTotes[idx] = value;
        }
        setSelectedTote(tote);
      }
    };

    const removeSubTote = (idx) => {
      if (selectedTote) {
        const tote = {
          ...selectedTote,
        };
        tote.subTotes.splice(idx, 1);
        setSelectedTote(tote);
      }
    };

    const addBlankTote = () => {
      const blank = {};
      if (filters) {
        if (filters["customers"]) {
          blank["customers"] = filters["customers"];
        }
        if (filters["warehouses"]) {
          blank["warehouses"] = filters["warehouses"];
        }
      }

      setSelectedTote({
        ...blank,
      });
    };

    const addBlankEmptyTote = () => {
      const blank = {};
      if (filters) {
        if (filters["customers"]) {
          blank["customers"] = filters["customers"];
        }
        if (filters["warehouses"]) {
          blank["warehouses"] = filters["warehouses"];
        }
      }

      setEmptyTote({
        ...blank,
      });
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return totesQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const printSelectedBarcodes = (print = true) => {
      if (selectedPrints.printSize === "4x6-Landscape") {
        selectedPrints.barcodes.map((item) => {
          printCanvasLabelPdf(
            `${item}_${selectedPrints.printSize}`,
            selectedPrints.noOfCopies,
            null,
            null,
            "4x6",
            appState,
            print,
          );
        });
      } else {
        selectedPrints.barcodes.map((item) => {
          printCanvasLabelPdf(
            SUPPORTED_SIZES_WITHOUT_CANVAS.includes(selectedPrints.printSize)
              ? `${item}`
              : `${item}_${selectedPrints.printSize}`,
            selectedPrints.noOfCopies,
            null,
            null,
            selectedPrints.printSize,
            appState,
            print,
          );
        });
      }
    };

    return (
      <WrappedComponent
        totes={entity.displayEntities}
        addBlankTote={addBlankTote}
        addBlankEmptyTote={addBlankEmptyTote}
        toteTypes={entityTypes}
        fetchTote={(id) => getToteQuery.fetchData({ id })}
        selectedTote={selectedTote}
        emptyTote={emptyTote}
        setSelectedTote={setSelectedTote}
        setEmptyTote={setEmptyTote}
        saveTote={(tote) => {
          saveToteQuery.fetchData({ ...tote });
        }}
        saveEmptyTote={saveEmptyTote}
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        deleteButtonClicked={deleteButtonClicked}
        selectedTotes={selectedTotes}
        selectTote={selectTote}
        clearSelectedTotes={clearSelectedTotes}
        selectedPrint={selectedPrint}
        setSelectedPrint={setSelectedPrint}
        printSelectedBarcodes={printSelectedBarcodes}
        queueMultiplePrints={queueMultiplePrints}
        queueSinglePrint={queueSinglePrint}
        selectedPrints={selectedPrints}
        setSelectedPrints={setSelectedPrints}
        selectAllTotes={selectAllTotes}
        allTotesSelected={allTotesSelected}
        uploadMultipleEntries={uploadMultipleEntries}
        onChangeMultiSelect={onChangeMultiSelect}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(e) => {
          setSelectedCustomer(e);
          setFilters({
            ...filters,
            customers: [e],
          });
          totesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...filters,
              customers: [e],
            },
            sort: entity.sort,
          });
        }}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={(e) => {
          setFilters({
            ...filters,
            warehouses: [e],
          });
          setSelectedWarehouse(e);
          totesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...filters,
              warehouses: [e],
            },
            sort: entity.sort,
          });
        }}
        addSubTote={addSubTote}
        onChangeSubTote={onChangeSubTote}
        removeSubTote={removeSubTote}
        addCustomEntity={addCustomEntity}
        setAddCustomEntity={setAddCustomEntity}
        onSubmitCustomEntity={() =>
          saveEntityTypeQuery.fetchData({
            entityTypeInput: {
              ...addCustomEntity,
            },
          })
        }
        writable={props.writable}
        submitFilters={() => {
          setShowFilters(false);
          totesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          totesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            totesQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,

                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,

            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          totesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={() => {
          entity.setFilters({});
          totesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {},
            sort: entity.sort,
          });
        }}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          totesQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
      />
    );
  };
};

const naming = (toteType) => {
  return toteType && toteType.code
    ? toteType.code.toUpperCase()
    : toteType.name.toUpperCase();
};

export default withTotesLogic;
