import withUsersLogic from "#components/HOC/withUsersLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import Pagination from "#components/common/Pagination";
import UserFilters from "#components/users/UserFilters";
import UsersList from "#components/users/UsersList";
import UserForm from "#components/users/UserForm";
import RolePermissionForm from "#components/users/RolePermissionForm";
import { UserAddIcon } from "@heroicons/react/outline";
import CustomEntityForm from "#components/common/CustomEntityForm";
import AuditLog from "#components/users/AuditLog";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import Tabs from "#components/utils/Tabs";
import UserRolesList from "../components/users/UserRolesList";
import { useState } from "react";

const Users = ({
  masterData,
  users,
  allUsers,
  isStatsLoading,
  selectedUser,
  setSelectedUser,
  fetchUser,
  resetUserPassword,
  saveUser,
  stations,
  onChangeMultiSelect,
  deleteButtonClicked,
  onChange,
  customers,
  warehouses,
  vendors,
  permissionOptions,
  onChangePermission,
  writable,
  fetchUserRolePermission,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  showFilters,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeDropdown,
  roleTypes,
  addBlankUser,
  addCustomEntity,
  setAddCustomEntity,
  onSubmitCustomEntity,
  resetPasswordButtonClicked,
  toggleSuspendUserButtonClicked,
  selectedAudit,
  setSelectedAudit,
  auditUserId,
  setAuditUserId,
  getAudit,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  selectedRole,
  setSelectedRole,
  submitUserRole,
  deleteUserRole,
  updateCustomEntityForNewRole,
  validate,
  validationResult,
}) => {
  const USERS_TAB_NO = 0;
  const [selectedTab, setSelectedTab] = useState(USERS_TAB_NO);
  const tabs = () => {
    let tabsList = {
      Users: (
        <div>
          <UsersList
            users={users}
            editButtonClicked={(user) => {
              fetchUser(user.id);
            }}
            deleteButtonClicked={(user) => deleteButtonClicked(user)}
            resetUserPassword={(email) => resetUserPassword(email)}
            headers={[
              "Name",
              "Warehouses",
              "Clients",
              "User Role",
              "Stations",
              "Status",
              "Created Date",
              "Action",
            ]}
            noValuesText="No Users"
            customers={customers}
            warehouses={warehouses}
            writable={writable}
            onChangeSearchKeyword={onChangeSearchKeyword}
            filters={filters}
            submitFilters={submitFilters}
            clearKeyword={clearKeyword}
            setSort={setSort}
            sort={sort}
            setShowFilters={setShowFilters}
            showFilters={showFilters}
            clearFilters={clearFilters}
            onChangeFilter={onChangeFilter}
            resetPasswordButtonClicked={resetPasswordButtonClicked}
            toggleSuspendUserButtonClicked={toggleSuspendUserButtonClicked}
            getAudit={getAudit}
            allUsers={allUsers}
            isStatsLoading={isStatsLoading}
          />
        </div>
      ),
      Roles: (
        <div>
          <UserRolesList
            userRoles={roleTypes}
            users={allUsers}
            headers={["Name", "Associated User Accounts", "Action"]}
            customers={customers}
            warehouses={warehouses}
            editUserRolePermissionButtonClicked={(userRole) =>
              fetchUserRolePermission(userRole)
            }
            filters={filters}
            onChangeFilter={onChangeFilter}
            deleteUserRole={deleteUserRole}
            writable={writable}
          />
        </div>
      ),
    };
    return tabsList;
  };
  return (
    <div className="p-5">
      <div className="mb-2 flex flex-row justify-between">
        <PageTitle>User Management</PageTitle>
        <div className="mb-4 flex items-center justify-end space-x-2">
          <AddButton
            disabled={!writable}
            text={selectedTab == USERS_TAB_NO ? "Add User" : "Add Role"}
            onClick={
              selectedTab == USERS_TAB_NO
                ? addBlankUser
                : updateCustomEntityForNewRole
            }
            icon={UserAddIcon}
          />
          {selectedTab == USERS_TAB_NO && (
            <>
              <AddButton
                text="Download Template"
                onClick={() =>
                  (window.location =
                    "https://templates-onboarding.s3.amazonaws.com/prod/Users.xlsx")
                }
              />
              <UploadBulk
                dashboardFields={dashboardFields}
                saveBulkUpload={(rows) => saveBulkUpload(rows)}
                errorMessage={errorMessage}
                successMessage={successMessage}
                validate={validate}
                validationResult={validationResult}
              />
            </>
          )}
        </div>
      </div>

      <Tabs tabs={tabs()} onChange={(tab) => setSelectedTab(tab)} />

      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
      {selectedUser && (
        <UserForm
          title={selectedUser.id ? "Edit User" : "Add User"}
          selectedUser={selectedUser}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          onClose={() => setSelectedUser(null)}
          onSubmit={saveUser}
          masterData={masterData}
          stations={stations}
          onChangeMultiSelect={onChangeMultiSelect}
          customers={customers}
          warehouses={warehouses}
          vendors={vendors}
          roleTypes={roleTypes}
        />
      )}
      {showFilters && (
        <UserFilters
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          warehouses={warehouses}
          customers={customers}
          roleTypes={roleTypes}
        />
      )}
      {selectedRole && (
        <RolePermissionForm
          onChangePermission={onChangePermission}
          permissionOptions={permissionOptions}
          onClose={() => setSelectedRole(null)}
          title="Access Control"
          onSubmit={() => submitUserRole()}
        />
      )}
      {addCustomEntity && (
        <CustomEntityForm
          showCode={false}
          addCustomEntity={addCustomEntity}
          setAddCustomEntity={setAddCustomEntity}
          onSubmit={onSubmitCustomEntity}
          permissionOptions={permissionOptions}
          onChangePermission={onChangePermission}
        />
      )}
      {selectedAudit && (
        <AuditLog
          auditUserId={auditUserId}
          selectedAudit={selectedAudit}
          negativeAction={() => {
            setSelectedAudit(null), setAuditUserId(null);
          }}
        />
      )}
    </div>
  );
};

export default withUsersLogic(Users);
