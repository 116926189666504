import Modal from "#components/utils/Modal";
import BundleProductsDetail from "#components/bundles/BundleProductsDetail";
import { useState } from "react";
import { ArrowsExpandIcon } from "@heroicons/react/outline";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import { ProductAliasModal } from "#components/products/ProductAliasModal";

const ExpandedSku = ({ negativeAction, fetchedSku, customers }) => {
  const headers = [
    "Image",
    "SKU",
    "Client",
    "Name",
    "ASIN",
    "Fn SKU",
    "Attributes",
    "Base UOM",
    "UOM Configuration",
    "SKU Type",
  ];
  if (!fetchedSku.typeOfProduct) {
    fetchedSku.typeOfProduct = "STANDALONE";
  }
  if (
    fetchedSku.typeOfProduct === "BUNDLE" &&
    fetchedSku.products?.length > 0
  ) {
    headers.push("Bundled Products");
  }
  const [attributes, setAttributes] = useState(null);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [uom, setUom] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [showBundleProductsDetail, setShowBundleProductsDetail] =
    useState(false);

  const image =
    fetchedSku.images && fetchedSku.images[0]?.display_url
      ? fetchedSku.images[0]?.display_url
      : FALLBACK_IMAGE_URL;

  const handleImageLoad = (event) => {
    event.target.style.display = "block";
    event.target.previousSibling.style.display = "none"; // Hide the spinner
  };

  const handleImageError = (event) => {
    event.target.src = FALLBACK_IMAGE_URL; // Incase loading fails
  };

  // NOTE : only added for Amazon as of now
  const isMarketplaceProduct = (source) => ["FBA", "FBM"].includes(source);

  const checkAttributesPresent = (fetchedSku) => {
    const attrs = isMarketplaceProduct(fetchedSku?.source)
      ? fetchedSku?.marketplaceAttributes?.attributes
      : fetchedSku?.attributes;

    const customAttrs = fetchedSku?.customAttributes;
    if (
      (!attrs || Object.keys(attrs).length === 0) &&
      (!customAttrs || customAttrs.length === 0)
    ) {
      return false;
    }
    return true;
  };

  const handleAttributeClick = () => {
    let attrs = isMarketplaceProduct(fetchedSku.source)
      ? fetchedSku?.marketplaceAttributes?.attributes
      : fetchedSku?.attributes;
    setAttributes(attrs);
    setCustomAttributes(fetchedSku?.customAttributes);
  };

  if (attributes || customAttributes.length > 0) {
    return (
      <Modal
        negativeAction={() => {
          setAttributes(null);
          setCustomAttributes([]);
        }}
        title={`Item Attributes`}
        noPadding={true}
        positiveAction={() => {
          setAttributes(null);
          setCustomAttributes([]);
        }}
        positiveText="OK">
        <div className="space-y-4 p-4">
          {attributes && Object.keys(attributes).length > 0 && (
            <>
              <div>
                <span className="pb-4 font-montserrat text-lg font-bold text-454A4F">
                  Attributes
                </span>
              </div>
              {Object.keys(attributes).map((item, idx) => {
                if (
                  typeof attributes[item] !== "object" &&
                  attributes[item] !== null &&
                  !Array.isArray(attributes[item])
                ) {
                  return (
                    <div key={idx}>
                      <span className="text-md pb-4 font-montserrat text-454A4F">
                        {item.toUpperCase()}:{" "}
                        {[true, false].includes(attributes[item])
                          ? attributes[item] === true
                            ? "Yes"
                            : "No"
                          : attributes[item]}
                      </span>
                    </div>
                  );
                }
              })}
            </>
          )}
          {customAttributes && customAttributes.length > 0 && (
            <>
              <div>
                <span className="pb-4 font-montserrat text-lg font-bold text-454A4F">
                  Custom Attributes
                </span>
              </div>
              {customAttributes.map((item, idx) => {
                if (
                  typeof item.value !== "object" &&
                  item.value !== null &&
                  !Array.isArray(item.value)
                ) {
                  return (
                    <div key={idx}>
                      <span className="text-md pb-4 font-montserrat text-454A4F">
                        {item.key}: {item.value}
                      </span>
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
      </Modal>
    );
  }

  if (uom) {
    return (
      <Modal
        negativeAction={() => setUom(null)}
        title={`Unit of Measurement`}
        noPadding={true}
        positiveAction={() => setUom(null)}
        positiveText="OK">
        <div className="space-y-4 p-4">
          <table className="min-w-full divide-y divide-gray-200 px-2">
            <thead className="rounded-full bg-primaryAccent p-4 px-12">
              <tr className="border-left border-l-8 border-primaryAccent px-12 font-montserrat text-textWhite">
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                  Quantity
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                  Base UOM
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                  Target UOM
                </th>
              </tr>
            </thead>
            <tbody>
              {uom.map((item, idx) => (
                <tr key={idx} className="bg-white">
                  <td
                    className={`tracking-widerrounded-tl rounded-bl border-l-8 border-primaryAccent p-5 text-left font-semibold text-primaryAccent`}>
                    {item.baseUomQuantity}
                  </td>

                  <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                    {item.baseUom}
                  </td>

                  <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                    {item.targetUom}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }

  if (showBundleProductsDetail) {
    return (
      <BundleProductsDetail
        products={fetchedSku.products}
        onClose={() => setShowBundleProductsDetail(false)}
      />
    );
  }

  return (
    <Modal
      negativeAction={negativeAction}
      title={`SKU Details`}
      noPadding={true}
      positiveAction={negativeAction}
      positiveText="OK">
      <div className="overflow-auto bg-EFE9DC p-4">
        {showImage && (
          <Modal
            negativeAction={() => setShowImage(false)}
            title={`Image Preview`}
            noPadding={true}
            positiveAction={() => setShowImage(false)}
            positiveText="OK">
            <div className="space-y-4 p-4">
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                <div className="h-10 w-10 animate-spin rounded-full border-t-4 border-solid border-zinc-300"></div>
              </div>
              <img
                src={image}
                alt="No Image"
                className="mx-auto hidden h-1/2 w-1/2 overflow-hidden rounded-lg object-contain"
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            </div>
          </Modal>
        )}
        <table className="min-w-full divide-y divide-gray-200 px-2">
          <thead className="rounded-full bg-primaryAccent p-4 px-12">
            <tr className="border-left border-l-8 border-primaryAccent px-12 font-montserrat text-textWhite">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            <tr key={fetchedSku.id} className="bg-white">
              <td
                className={`rounded-bl rounded-tl border-l-8 border-primaryAccent p-2 text-left font-semibold tracking-wider text-primaryAccent`}>
                <div
                  onClick={() => setShowImage(true)}
                  className="relative h-20 w-40">
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                    <div className="h-10 w-10 animate-spin rounded-full border-t-4 border-solid border-zinc-300"></div>
                  </div>
                  <img
                    className="mt-2 hidden h-20 w-40 overflow-hidden rounded-lg bg-gray-300 object-contain"
                    src={image}
                    alt="No Image"
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                  />
                  <ArrowsExpandIcon className="absolute bottom-0 right-0 h-10 w-10 p-2 text-white" />
                </div>
              </td>
              <td
                className={`border-primaryAccent p-5 text-left font-semibold tracking-wider text-primaryAccent`}>
                <div className="flex items-center">
                  {fetchedSku.sku}
                  <ProductAliasModal
                    aliases={fetchedSku?.aliases}
                    customers={customers}
                  />
                </div>
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                {customers &&
                  customers.find((item) => item.id === fetchedSku.customer)
                    ?.name}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                <div className="w-96">{fetchedSku.name}</div>
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                {buildMarketplaceHyperlink(
                  fetchedSku.asin,
                  "ASIN",
                  fetchedSku.source,
                  fetchedSku?.marketplaceAttributes?.attributes
                    ?.marketplaceCountryCode,
                )}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                {fetchedSku.fnSku}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                {checkAttributesPresent(fetchedSku) ? (
                  <div
                    className="cursor-pointer text-primaryAccent"
                    onClick={() => handleAttributeClick(fetchedSku)}>
                    View
                  </div>
                ) : (
                  "No Attributes"
                )}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                {fetchedSku.baseUom}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                {fetchedSku.uomConfiguration &&
                Object.keys(fetchedSku.uomConfiguration).length > 0 &&
                Object.values(fetchedSku.uomConfiguration).filter(
                  (i) => i !== null,
                ) ? (
                  <div
                    className="cursor-pointer text-primaryAccent"
                    onClick={() => setUom(fetchedSku.uomConfiguration)}>
                    View
                  </div>
                ) : (
                  "No UOM Configuration"
                )}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                {fetchedSku.typeOfProduct.toLowerCase()}
              </td>

              {fetchedSku.typeOfProduct === "BUNDLE" && (
                <>
                  {fetchedSku.products && fetchedSku.products.length > 0 && (
                    <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-tableText">
                      <div
                        className="cursor-pointer text-primaryAccent"
                        onClick={() => setShowBundleProductsDetail(true)}>
                        View
                      </div>
                    </td>
                  )}
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ExpandedSku;
