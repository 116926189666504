import React, { useState, Fragment } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import {
  handleformatNumericValue,
  handleMetalLossCost,
  METAL_LOSS_PERCENTAGE_OPTIONS,
  PO_STRING_VALUES,
  UOM_VALUES_FOR_COMPONENTS,
} from "./PoUtils";
import { Tooltip } from "antd";
import { v4 as uuidv4 } from "uuid";

const ViewProductsMain = ({
  currency,
  products = [],
  additionalCharges = [],
}) => {
  const [expandedProductId, setExpandedProductId] = useState(null);

  const toggleComponents = (id) =>
    setExpandedProductId(expandedProductId === id ? null : id);

  const subTotal = products?.reduce(
    (acc, product) => acc + (product.unitPrice * product.quantity || 0),
    0,
  );

  const totalPO = additionalCharges?.reduce(
    (acc, charge) => acc + parseFloat(charge.amount),
    subTotal,
  );

  return (
    <div className="overflow-auto">
      <div className="mt-4 min-w-full">
        <div
          style={{ gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr" }}
          className="sticky top-0 z-10 grid h-14 grid-cols-6 items-center gap-4 rounded-md border border-borderGray bg-bgGray px-4 text-left text-sm font-medium tracking-wider text-gray-500">
          <div>Product Info</div>
          <div>UOM</div>
          <div>Quantity</div>
          <div>Item Cost/ Item</div>
          <div>Total Cost</div>
        </div>
        <div
          style={{ maxHeight: "40rem" }}
          className="min-w-full overflow-auto">
          {products.map((product) => (
            <div key={product.productId} className="border-b py-4">
              <div
                style={{ gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr" }}
                className="grid grid-cols-6 items-start gap-4 p-4">
                <div className="flex items-center">
                  <img
                    src={
                      product.images?.length > 0
                        ? product.images[0].url
                        : FALLBACK_IMAGE_URL
                    }
                    alt={product.name}
                    className="mr-4 h-16 w-16"
                  />
                  <div>
                    <Tooltip title={product.name}>
                      {" "}
                      <div className="w-48 truncate text-base font-medium">
                        {product.name}
                      </div>
                    </Tooltip>
                    <div className="text-gray-500">SKU: {product.sku}</div>
                    <div className="text-gray-500">
                      Variant:{product?.variant}
                    </div>
                  </div>
                </div>
                <div className="rounded border bg-gray-100 p-2">
                  {product.uom ? product.uom : ""}
                </div>
                <div className="p-2">{product.quantity}</div>
                <div>
                  <span>
                    {currency.split("-")[1]}
                    {handleformatNumericValue(product.unitPrice)}
                  </span>
                </div>
                <div>
                  {`${currency.split("-")[1]}${handleformatNumericValue(product.unitPrice) * product.quantity}`}
                </div>
              </div>
              {product?.components && product?.components.length > 0 && (
                <button
                  onClick={() => toggleComponents(product.productId)}
                  className="mt-2 flex items-center font-semibold text-primaryAccent underline">
                  {expandedProductId === product.productId
                    ? "Hide Components"
                    : "View Components"}
                  {expandedProductId === product.productId ? (
                    <ChevronUpIcon className="mr-1 h-5 w-5" />
                  ) : (
                    <ChevronDownIcon className="mr-1 h-5 w-5" />
                  )}
                </button>
              )}
              <Transition
                as={Fragment}
                show={expandedProductId === product.productId}
                enter="transition ease-out duration-200 transform"
                enterFrom="-translate-y-10 opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-150 transform"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="-translate-y-10 opacity-0">
                <div className="mt-2 w-full border-t p-4 pt-2">
                  <div className="mb-2 w-full text-gray-600">
                    <div
                      style={{ gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr" }}
                      className="grid h-14 w-full items-center gap-4 rounded-md border border-borderGray bg-bgGray px-4 text-left text-sm font-medium tracking-wider text-gray-500">
                      <div>Component Info</div>
                      <div>Quantity</div>
                      <div>Recipe Unit</div>
                      <div>Used Unit</div>
                      <div>Price/Unit</div>
                      <div>Cost</div>
                    </div>
                    {product.components?.map((component, index) => (
                      <Fragment key={index}>
                        <div
                          style={{
                            gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr",
                          }}
                          className="grid w-full items-start gap-4 p-4">
                          <div
                            className={`flex h-full flex-col items-start gap-3`}>
                            <Tooltip title={component.name}>
                              <span className="w-48 truncate">
                                {" "}
                                {component.name}
                              </span>
                            </Tooltip>
                            {component.masterData?.componentType ===
                              PO_STRING_VALUES.METAL_STRING && (
                              <div>
                                <p className="mt-3 flex flex-wrap items-center gap-2">
                                  <span className="text-xs">
                                    Metal Loss %:{" "}
                                  </span>
                                  <span>{component.metalLossPercentage}</span>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className={`flex h-full items-start`}>
                            {" "}
                            <span>
                              {component.masterData.quantity
                                ? handleformatNumericValue(
                                    component.masterData.quantity,
                                  )
                                : 0}
                            </span>
                          </div>
                          <div className={`flex h-full w-full items-start`}>
                            <span>
                              {(["gm", "ct"].includes(component.masterData?.uom)
                                ? handleformatNumericValue(
                                    component.masterData?.weight,
                                  )
                                : handleformatNumericValue(
                                    component.masterData.quantity,
                                  )) || 0}
                            </span>
                            <span className="ml-2">
                              {
                                UOM_VALUES_FOR_COMPONENTS[
                                  component.masterData?.uom
                                ]
                              }
                            </span>
                          </div>
                          <div
                            className={`flex h-full w-full flex-col items-start gap-3`}>
                            <div>
                              <span>{component.quantity}</span>
                              <span className="ml-2">
                                {UOM_VALUES_FOR_COMPONENTS[component.uom]}
                              </span>
                            </div>
                            {component.masterData.componentType ===
                              PO_STRING_VALUES.METAL_STRING && (
                              <div>
                                <div className="flex h-full flex-wrap items-center gap-2">
                                  <p className="text-xs">
                                    Metal loss used unit:
                                  </p>
                                  <p>
                                    {handleformatNumericValue(
                                      component.quantity *
                                        (component.metalLossPercentage / 100),
                                    )}{" "}
                                    {UOM_VALUES_FOR_COMPONENTS[component.uom]}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={`flex h-full items-start`}>
                            <span>{currency.split("-")[1]}</span>
                            <span>
                              {handleformatNumericValue(component.unitPrice)}
                            </span>
                          </div>
                          <div
                            className={`flex h-full w-full flex-col items-center gap-3`}>
                            <span className="flex w-full justify-start">
                              {currency.split("-")[1]}
                              {(() => {
                                if (
                                  ["gm", "ct"].includes(
                                    component.masterData?.uom,
                                  )
                                ) {
                                  return handleformatNumericValue(
                                    component.masterData.quantity *
                                      component.unitPrice *
                                      component.quantity,
                                  );
                                } else
                                  return handleformatNumericValue(
                                    component.unitPrice * component.quantity,
                                  );
                              })()}
                            </span>
                            {component.masterData.componentType ===
                              PO_STRING_VALUES.METAL_STRING && (
                              <div className="text- flex w-full flex-col gap-2">
                                <p className="text-xs">Metal loss cost:</p>
                                <span>
                                  {currency.split("-")[1]}
                                  {handleformatNumericValue(
                                    handleMetalLossCost(component),
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          style={{ height: "1px" }}
                          className="w-full bg-gray-200"></div>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </Transition>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 border-t pr-4 pt-4">
        <div className="flex justify-end">
          <div className="w-full">
            <div className="mb-2 flex justify-between gap-4">
              <div className="flex w-3/4 items-center justify-end">
                Purchase Order Sub-Total:
              </div>
              <div className="flex w-1/4 items-end justify-end font-medium">
                {currency.split("-")[1]}
                {handleformatNumericValue(subTotal)}
              </div>
            </div>
            {additionalCharges.map((charge, index) => (
              <div className="mb-2 flex justify-between gap-4" key={index}>
                <div
                  className={`flex w-3/4 items-center justify-end ${charge.name.toLowerCase() === "discount" ? "text-red-500" : ""}`}>
                  {charge.name}:
                </div>
                <div
                  className={`flex w-1/4 items-center justify-end ${charge.name.toLowerCase() === "discount" ? "text-red-500" : ""}`}>
                  {currency.split("-")[1]}
                  {charge.name === "Discount"
                    ? -1 * handleformatNumericValue(charge.amount)
                    : handleformatNumericValue(charge.amount)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-4 border-b border-t py-4 pr-4">
        <div className="flex justify-end">
          <div className="mt-2 flex w-full justify-between gap-4">
            <div className="flex w-3/4 justify-end text-right">
              Purchase Order Total:
            </div>
            <div className="flex w-1/4 justify-end text-right font-semibold">
              {currency.split("-")[1]}
              {handleformatNumericValue(totalPO)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProductsMain;
