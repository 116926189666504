import TextField from "#components/utils/TextField";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import Autocomplete from "#components/utils/Autocomplete";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const GroupForm = ({
  masterData,
  selectedRule,
  editField,
  editFieldCondition,
  addBlankCondition,
  removeCondition,
  saveGroup,
  title,
  stations,
  editFieldAction,
  packingStations,
  inboundSortingStations,
  outboundSortingStations,
  addBlankAction,
  removeAction,
  users,
  hopstackModules,
}) => {
  const history = useHistory();
  const dataFields = (masterField) => {
    switch (masterField) {
      case "shippingAddress.country":
        return masterData.allCountries;
      case "customerName":
        return masterData.allCustomers;
      case "warehouseName":
        return masterData.allWarehouses;
      case "group":
        return masterData.allGroups;
      case "resizingOrder":
        return masterData.allBooleanChoices;
      case "combinedOrder":
        return masterData.allBooleanChoices;
      default:
        return [];
    }
  };

  const renderValueField = (condition, index) => {
    if (
      condition.masterField &&
      [
        "shippingAddress.country",
        "customerName",
        "warehouseName",
        "group",
        "resizingOrder",
        "combinedOrder",
      ].includes(condition.masterField)
    ) {
      if (condition.comparisonOperator === "$eq") {
        return (
          <Autocomplete
            options={dataFields(condition.masterField)}
            labelKey="name"
            valueKey="name"
            onChange={(e) => editFieldCondition(index, "value", e)}
            value={condition.value}
            rounded={true}
            id={`${condition.masterField}_${index}`}
          />
        );
      }
      return (
        <MultiSelectAutoComplete
          options={dataFields(condition.masterField)}
          labelKey="name"
          valueKey="name"
          setValues={(e) => editFieldCondition(index, "value", e)}
          values={condition.value}
          rounded={true}
        />
      );
    }

    return (
      <div className="w-96">
        <TextField
          type="text"
          label="Value"
          placeholder="Value"
          onChange={(e) => editFieldCondition(index, "value", e.target.value)}
          value={condition.value}
          rounded={true}
        />
      </div>
    );
  };

  const renderActionValueField = (condition, index) => {
    if (
      condition.masterField &&
      condition.masterField.toLowerCase() === "packing stations"
    ) {
      return (
        <Autocomplete
          options={packingStations}
          labelKey="name"
          valueKey="name"
          onChange={(e) => editFieldAction(index, "value", e)}
          value={condition.value}
          rounded={true}
          id={`${condition.masterField}_${index}`}
        />
      );
    }
    if (
      condition.masterField &&
      condition.masterField.toLowerCase() === "sorting - inbound stations"
    ) {
      return (
        <Autocomplete
          options={inboundSortingStations}
          labelKey="name"
          valueKey="name"
          onChange={(e) => editFieldAction(index, "value", e)}
          value={condition.value}
          rounded={true}
          id={`${condition.masterField}_${index}`}
        />
      );
    }
    if (
      condition.masterField &&
      condition.masterField.toLowerCase() === "sorting - outbound stations"
    ) {
      return (
        <Autocomplete
          options={outboundSortingStations}
          labelKey="name"
          valueKey="name"
          onChange={(e) => editFieldAction(index, "value", e)}
          value={condition.value}
          rounded={true}
          id={`${condition.masterField}_${index}`}
        />
      );
    }

    if (
      condition.masterField &&
      condition.masterField.toLowerCase().indexOf("pickers") !== -1
    ) {
      return (
        <Autocomplete
          options={users}
          labelKey="name"
          valueKey="name"
          onChange={(e) => editFieldAction(index, "value", e)}
          value={condition.value}
          rounded={true}
          id={`${condition.masterField}_${index}`}
        />
      );
    }

    if (
      condition.masterField &&
      condition.masterField.toLowerCase().indexOf("station") === -1 &&
      condition.masterField.toLowerCase().indexOf("picker") === -1
    ) {
      return (
        <Autocomplete
          options={[
            { name: "Yes", value: "true" },
            { name: "No", value: "false" },
          ]}
          labelKey="name"
          valueKey="value"
          onChange={(e) => editFieldAction(index, "value", e)}
          value={condition.value}
          rounded={true}
          id={`${condition.masterField}_${index}`}
        />
      );
    }

    return (
      <div className="w-96">
        <TextField
          type="text"
          label="Value"
          placeholder="Value"
          onChange={(e) => editFieldCondition(index, "value", e.target.value)}
          value={condition.value}
          rounded={true}
        />
      </div>
    );
  };

  const renderConditions = () => {
    const returnDivs = [];
    selectedRule.conditions.map((condition, index) => {
      let filteredComparisonOperators = masterData.comparisonOperators;
      if (condition.masterField) {
        const fieldMaster = _.find(masterData.masterFields, {
          value: condition.masterField,
        });
        filteredComparisonOperators = _.filter(
          filteredComparisonOperators,
          (e) => {
            return e.scope === fieldMaster.fieldType || e.scope === "ALL";
          },
        );
      }
      returnDivs.push(
        <div className="mt-2 flex items-center justify-between bg-white p-2">
          <div className="flex items-center space-x-2">
            <div className="w-48">
              <RoundedDropdown
                placeholder={"Field"}
                list={_.filter(masterData.masterFields, { value: "group" })}
                labelKey="name"
                valueKey="value"
                name="field"
                selectedValue={condition.masterField}
                setSelected={(e) => editFieldCondition(index, "masterField", e)}
              />
            </div>
            <div className="w-48">
              <RoundedDropdown
                placeholder={"Operator"}
                list={masterData.comparisonOperators}
                labelKey="name"
                valueKey="value"
                name="comparisionOperator"
                selectedValue={condition.comparisonOperator}
                setSelected={(e) =>
                  editFieldCondition(index, "comparisonOperator", e)
                }
              />
            </div>
            <div className="mt-1">{renderValueField(condition, index)}</div>
          </div>
          <div className="flex items-center space-x-2">
            <TableFieldButton
              text={
                <div className="flex items-center space-x-1">
                  <PlusCircleIcon className="h-6 w-6" />
                  <span>Add</span>
                </div>
              }
              onClick={addBlankCondition}
            />
            <TableFieldButton
              text={<TrashIcon className="h-7 w-7" />}
              onClick={() => removeCondition(index)}
            />
          </div>
        </div>,
      );
    });

    return returnDivs;
  };

  const stationNames = () => {
    const returnArr = [];
    if (stations) {
      for (const station of hopstackModules.filter(
        (item) =>
          (item.moduleType === "INBOUND" || item.moduleType === "OUTBOUND") &&
          ["Picking", "Receiving", "Putaway"].includes(item.name) === false,
      )) {
        returnArr.push({
          name: `${station.name} Stations`,
        });
      }
    }

    return returnArr;
  };

  const pickers = () => {
    const returnArr = [];
    returnArr.push({
      name: `Pickers`,
    });

    return returnArr;
  };

  const renderExceptions = () => {
    const returnDivs = [];
    selectedRule.actions.map((condition, index) => {
      returnDivs.push(
        <div className="mt-2 flex items-center justify-between bg-white p-2">
          <div className="flex items-center space-x-2">
            <div className="w-48">
              <RoundedDropdown
                placeholder={"Select"}
                list={[
                  ...hopstackModules.filter(
                    (item) =>
                      item.moduleType === "INBOUND" ||
                      item.moduleType === "OUTBOUND",
                  ),
                  ...pickers(),
                  ...stationNames(),
                ]}
                labelKey="name"
                valueKey="name"
                name="field"
                selectedValue={condition.masterField}
                setSelected={(e) => editFieldAction(index, "masterField", e)}
              />
            </div>

            <div className="mt-1">
              {renderActionValueField(condition, index)}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <TableFieldButton
              text={
                <div className="flex items-center space-x-1">
                  <PlusCircleIcon className="h-6 w-6" />
                  <span>Add</span>
                </div>
              }
              onClick={addBlankAction}
            />
            <TableFieldButton
              text={<TrashIcon className="h-7 w-7" />}
              onClick={() => removeAction(index)}
            />
          </div>
        </div>,
      );
    });

    return returnDivs;
  };

  return (
    <div className="m-4 bg-white p-5">
      <div className="text-2xl font-medium text-primaryAccent">
        {title ? title : "Create Group"}
      </div>
      <div className="mt-4 w-96">
        <TextField
          type="text"
          id="name"
          label="Rule Name"
          placeholder="Rule Name"
          onChange={editField}
          value={selectedRule.name}
        />
      </div>
      <div className="mt-10 text-xl font-medium text-454A4F">
        Define Condition
      </div>
      <div className="mt-6 flex items-center">
        <div className="mr-4">When</div>
        <div className="w-48">
          <RoundedDropdown
            placeholder={"Condition"}
            list={masterData.logicalOperators}
            labelKey="name"
            valueKey="value"
            name="mainCriteria"
            selectedValue={selectedRule.mainCriteria}
            setSelected={(e) => editField("mainCriteria", e)}
          />
        </div>
        <div className="mx-4 text-xl font-medium">
          all of the conditions are met
        </div>
      </div>
      <div className="mt-10 rounded-md bg-EBEBEB p-2">{renderConditions()}</div>
      <div className="mt-6 flex items-center">
        <div className="mr-4 text-xl font-medium">
          Then perform the following actions
        </div>
      </div>
      <div className="mt-4 rounded-md bg-EBEBEB p-2">{renderExceptions()}</div>
      <div className="mt-10">
        <div className="flex w-full items-center justify-center space-x-4">
          <div
            className="w-32 rounded-md border border-2C7695 py-2 text-center text-2C7695"
            onClick={() => history.replace("/rules")}>
            Cancel
          </div>
          <div
            className="w-32 rounded-md border border-1D3752 bg-1D3752 py-2 text-center text-white"
            onClick={saveGroup}>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

const TableFieldButton = ({ onClick, text, IconText }) => (
  <button
    type="button"
    className={`inline-flex justify-center rounded-full border-textWhite bg-E1D3B8 px-2 py-2 font-montserrat text-lg font-medium text-5F666B outline-none ring-0 focus:outline-none focus:ring-0`}
    onClick={onClick}>
    {IconText ? IconText : text || "Button"}
  </button>
);

export default GroupForm;
