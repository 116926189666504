import { twMerge } from "tailwind-merge";

const ModalButton = ({
  onClick,
  transparent,
  text,
  className,
  specifybg,
  style,
  disabled,
}) => (
  <button
    type="button"
    style={style}
    className={twMerge(
      `inline-flex justify-center px-6 py-2 text-lg font-medium ${
        transparent
          ? `border border-F4C261 bg-transparent ${!specifybg && "text-B3BFCA"}`
          : `${!specifybg && "bg-textWhite"}`
      } rounded-md border font-montserrat font-medium outline-none ring-0 focus:outline-none focus:ring-0 ${className} ${
        disabled ? "cursor-not-allowed opacity-50" : ""
      }`,
    )}
    onClick={!disabled ? onClick : null}
    disabled={disabled}>
    {text || "Button"}
  </button>
);

export default ModalButton;
