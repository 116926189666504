import PageTitle from "#components/utils/PageTitle";
import withNotificationsLogic from "#components/HOC/withNotificationsLogic";
import NotificationsList from "#components/notifications/NotificationsList";
import Pagination from "#components/common/Pagination";
import EditNotificationForm from "../components/notifications/EditNotificationForm";
import NotificationFilters from "#components/notifications/NotificationFilters";

const Notifications = ({
  displayNotifications,
  masterData,
  filters,
  onChangeFilter,
  getAudit,
  selectedNotifications,
  setSelectedNotifications,
  selectSku,
  selectedSkus,
  clearSelectedSkus,
  getSku,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  showFilters,
  clearFilters,
  sort,
  setSort,
  getNotification,
  fetchedNotification,
  setFetchedNotification,
  submitFetchedNotification,
  rejectNotification,
  approveNotification,
  users,
  removeItem,
  customers,
  warehouses,
  auth,
}) => {
  if (auth?.user?.role?.toLowerCase() !== "admin") {
    return (
      <div className="mt-4 flex h-full items-center justify-center text-2xl">
        No Notifications at this time.
      </div>
    );
  }
  return (
    <div className="p-5">
      <PageTitle>Notifications</PageTitle>
      <NotificationsList
        notifications={displayNotifications}
        masterData={masterData}
        filters={filters}
        onChangeFilter={onChangeFilter}
        getAudit={getAudit}
        selectedNotifications={selectedNotifications}
        setSelectedNotifications={setSelectedNotifications}
        selectSku={selectSku}
        selectedSkus={selectedSkus}
        clearSelectedSkus={clearSelectedSkus}
        getSku={getSku}
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        getNotification={getNotification}
        users={users}
        removeItem={removeItem}
        customers={customers}
        warehouses={warehouses}
      />
      {fetchedNotification && (
        <EditNotificationForm
          fetchedNotification={fetchedNotification}
          onClose={() => setFetchedNotification(null)}
          onSubmit={submitFetchedNotification}
          onChange={(e) =>
            e.target.name === "quantity"
              ? setFetchedNotification({
                  ...fetchedNotification,
                  mainEntity: {
                    ...fetchedNotification.mainEntity,
                    [e.target.name]:
                      e.target.name === "quantity"
                        ? parseInt(e.target.value)
                        : e.target.value,
                  },
                })
              : setFetchedNotification({
                  ...fetchedNotification,
                  [e.target.name]: e.target.value,
                })
          }
          title={`View Notification`}
          rejectNotification={rejectNotification}
          approveNotification={approveNotification}
          removeItem={removeItem}
          xIconClicked={() => setFetchedNotification(null)}
        />
      )}
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
      {showFilters && (
        <NotificationFilters
          negativeAction={() => setShowFilters(false)}
          masterData={masterData}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          users={users}
          customers={customers}
          warehouses={warehouses}
        />
      )}
    </div>
  );
};

export default withNotificationsLogic(Notifications);
