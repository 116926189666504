const Button = ({
  onClick,
  transparent,
  text,
  icon: Icon,
  disabled,
  styles,
}) => (
  <button
    type="button"
    disabled={disabled}
    className={`flex items-center justify-center border-transparent bg-2C7695 font-montserrat font-medium text-white ${
      Icon ? "rounded-full" : "rounded-md"
    } p-0 outline-none ring-0 focus:outline-none focus:ring-0 ${
      styles ? styles.join(" ") : ""
    } ${disabled ? "bg-gray-500 hover:cursor-default" : ""}`}
    onClick={onClick}>
    {Icon && (
      <>
        <Icon className="h-10 w-10 rounded-full border border-primaryAccent bg-primaryAccent py-1 text-white md:py-2" />
        <span className="px-2 pr-2 md:pr-4">{text || "Button"}</span>
      </>
    )}
    {!Icon && (
      <>
        <span className="w-full px-2 py-1 md:py-2">{text || "Button"}</span>
      </>
    )}
  </button>
);

export default Button;
