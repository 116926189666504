import React from "react";

export const PackingLabel = ({ batch }) => {
  const groupedByBox = batch.workingList.reduce((acc, item) => {
    if (!acc[item.boxName]) {
      acc[item.boxName] = [];
    }

    // Check if the SKU already exists in the box
    const existingItem = acc[item.boxName].find((i) => i.sku === item.sku);

    if (existingItem) {
      // If SKU exists, update the quantity
      existingItem.quantity += item.quantity;
    } else {
      // Otherwise, add the item to the box
      acc[item.boxName].push({ ...item });
    }

    return acc;
  }, {});

  return (
    <div>
      {Object.keys(groupedByBox).map((boxName, index) => {
        const box = batch.boxes.find((b) => b.name === boxName);
        return (
          <div
            id={`label-container-${index}`}
            key={index}
            style={{
              width: "384px",
              height: "576px",
              fontFamily: "Arial",
              margin: "0",
              padding: "0 10px",
              border: "1px solid black",
              fontSize: "14px",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "10px 0",
                borderBottom: "1px solid #000",
                fontWeight: "bold",
                fontFamily: "Arial",
              }}>
              <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                {batch.attributes?.humanReadableOrderIds?.map((i) => i)}
              </div>
              <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                {`Box ${index + 1} of ${batch.boxes?.length} - ${
                  box.weight
                } lbs`}{" "}
                <br />
                {`${box?.length} x ${box?.width} x ${box?.height} in`}
              </div>
            </div>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                fontFamily: "Arial",
                fontSize: "14px",
                border: "1px solid #000",
                padding: "20px",
              }}>
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid #000",
                      padding: "5px",
                      textAlign: "left",
                    }}>
                    SKU
                  </th>
                  {batch.source === "FBA" && (
                    <th
                      style={{
                        border: "1px solid #000",
                        padding: "5px",
                        textAlign: "left",
                      }}>
                      ASIN
                    </th>
                  )}
                  <th
                    style={{
                      border: "1px solid #000",
                      padding: "5px",
                      textAlign: "left",
                    }}>
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                {groupedByBox[boxName].map((item) => (
                  <tr key={item.sku}>
                    <td style={{ border: "1px solid #000", padding: "5px" }}>
                      {item.sku}
                    </td>
                    {batch.source === "FBA" && (
                      <td style={{ border: "1px solid #000", padding: "5px" }}>
                        {item.asin}
                      </td>
                    )}
                    <td style={{ border: "1px solid #000", padding: "5px" }}>
                      {item.quantity}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default PackingLabel;
