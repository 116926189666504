import { useState, useEffect, useContext } from "react";
import { GET_NOTIFICATIONS, GET_NOTIFICATION, GET_USERS } from "#queries";
import { APPROVE_NOTIFICATION, REJECT_NOTIFICATION } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";
import { EntityContext } from "../../contexts/entity";

const withNotificationsLogic = (WrappedComponent) => {
  return (props) => {
    const auth = useContext(AuthContext);
    const usersQuery = useQuery(GET_USERS);
    const entity = useContext(EntityContext);
    const masterData = useContext(MasterDataContext);
    const [fetchedNotification, setFetchedNotification] = useState(null);
    const appState = useContext(AppStateContext);
    const notificationsQuery = useQuery(GET_NOTIFICATIONS);
    const fetchNotificationQuery = useQuery(GET_NOTIFICATION);
    const approveNotificationQuery = useQuery(APPROVE_NOTIFICATION);
    const rejectNotificationQuery = useQuery(REJECT_NOTIFICATION);
    const getNotificationQuery = useQuery(GET_NOTIFICATION);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
      entity.setFilters({ status: ["PENDING"], typeOfNotification: [""] });
      notificationsQuery.fetchData({
        perPage: entity.perPage,
        filters: { status: ["PENDING"] },
        paginated: false,
        pageNumber: 1,
        sort: "-createdAt",
      });

      return () => {
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      usersQuery.fetchData({
        perPage: 100,
        paginated: false,
      });
    }, []);

    useEffect(() => {
      if (notificationsQuery.data && notificationsQuery.data.notifications) {
        entity.setEntities({
          ...notificationsQuery.data.notifications,
          ...notificationsQuery.variables,
        });
      }
    }, [
      notificationsQuery.loading,
      notificationsQuery.error,
      notificationsQuery.data,
    ]);

    useEffect(() => {
      if (fetchNotificationQuery.data) {
        setFetchedNotification(fetchNotificationQuery.data.notification); // Fixed typo
      }

      if (fetchNotificationQuery.error) {
        setFetchedNotification(null);
        appState.setAlert("Could not fetch that notification");
      }

      if (fetchNotificationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      fetchNotificationQuery.loading,
      fetchNotificationQuery.error,
      fetchNotificationQuery.data,
    ]);

    useEffect(() => {
      if (approveNotificationQuery.data) {
        appState.setAlert(
          approveNotificationQuery.data.approveNotification.message,
          "success",
          5000,
        );
        setFetchedNotification(null);
        appState.hideConfirmation();
        notificationsQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }

      if (approveNotificationQuery.error) {
        appState.hideConfirmation();
        appState.setAlert(
          approveNotificationQuery.error.message,
          "error",
          5000,
        );
      }

      if (approveNotificationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      approveNotificationQuery.loading,
      approveNotificationQuery.error,
      approveNotificationQuery.data,
    ]);

    useEffect(() => {
      if (rejectNotificationQuery.data) {
        appState.setAlert(
          rejectNotificationQuery.data.rejectNotification.message,
          "success",
          5000,
        );
        appState.hideConfirmation();
        setFetchedNotification(null);
        notificationsQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }

      if (rejectNotificationQuery.error) {
        appState.setAlert(rejectNotificationQuery.error.message, "error", 5000);
      }

      if (rejectNotificationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      rejectNotificationQuery.loading,
      rejectNotificationQuery.error,
      rejectNotificationQuery.data,
    ]);

    useEffect(() => {
      if (getNotificationQuery.data && getNotificationQuery.data.notification) {
        setFetchedNotification(getNotificationQuery.data.notification);
      } else {
        setFetchedNotification(null);
      }
    }, [
      getNotificationQuery.loading,
      getNotificationQuery.error,
      getNotificationQuery.data,
    ]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return notificationsQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const approveFetchedNotification = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to approve? This action cannot be undone.",
        () => {
          approveNotificationQuery.fetchData({
            id: fetchedNotification.id,
            body: fetchedNotification.mainEntity,
            remarks: fetchedNotification.remarks,
          });
        },
        appState.hideConfirmation,
      );
    };

    const rejectFetchedNotification = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to reject? This action cannot be undone.",
        () => {
          rejectNotificationQuery.fetchData({
            id: fetchedNotification.id,
            remarks: fetchedNotification.remarks,
          });
        },
        appState.hideConfirmation,
      );
    };

    const removeItem = (idx) => {
      if (fetchedNotification) {
        fetchedNotification.mainEntity.shipmentPlanProducts.splice(idx, 1);
        setFetchedNotification({ ...fetchedNotification });
      }
    };

    return (
      <WrappedComponent
        notifications={entity.entities}
        displayNotifications={entity.displayEntities}
        masterData={masterData}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          notificationsQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          notificationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          notificationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            notificationsQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          notificationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={() => {
          entity.setFilters({});
          notificationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {},
            sort: entity.sort,
          });
        }}
        getNotification={(id) => getNotificationQuery.fetchData({ id })}
        fetchedNotification={fetchedNotification}
        setFetchedNotification={setFetchedNotification}
        approveNotification={approveFetchedNotification}
        rejectNotification={rejectFetchedNotification}
        users={usersQuery.data ? usersQuery.data.users.entities : []}
        removeItem={removeItem}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        auth={auth}
      />
    );
  };
};

export default withNotificationsLogic;
