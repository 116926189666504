import React, { Fragment, useState } from "react";
import { Tooltip } from "antd";
import CustomPopconfirm from "#newUiComponents/commons/CustomPopConfirm";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import InputWithSelect from "#newUiComponents/commons/InputWithSelect";
import {
  TrashIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import {
  PO_STRING_VALUES,
  METAL_LOSS_PERCENTAGE_OPTIONS,
  UOM_VALUES_FOR_COMPONENTS,
} from "./PoUtils";
import { Transition } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";

const AddProductsForParentProducts = ({
  product,
  numberInputOnWheelPreventChange,
  updateQuantity,
  showCosts,
  currency,
  handleProductCostValue,
  updateProductCost,
  handleDelete,
  toggleComponents,
  expandedProductId,
  updateComponent,
  handleMetalLossCost,
}) => {
  return (
    <div>
      {" "}
      <div key={product.id} className="border-b py-4">
        <div
          style={{ gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr 1fr" }}
          className="grid grid-cols-6 items-start gap-4 p-4">
          <div className="flex items-center">
            <div>
              <Tooltip title={product.name}>
                <div className="w-48 truncate text-base font-medium">
                  {product.name}
                </div>
              </Tooltip>
              <div className="text-gray-500">SKU: {product.sku}</div>
              {/* <div className="text-gray-500">Variant: {product.variant}</div> */}
            </div>
          </div>
          <div className="rounded border bg-gray-100 p-2">
            {product.childProducts[0]["baseUom"]
              ? product.childProducts[0]["baseUom"]
              : ""}
          </div>
          <div>
            {product.childProducts.reduce((acc, childProduct) => {
              return acc + childProduct.quantity;
            }, 0)}
          </div>
          <div>
            {showCosts(product.childProducts[0]) ? (
              product.childProducts[0].components?.length > 0 ? (
                <span>
                  {currency.split("-")[1]}
                  {Number(product.childProducts[0]["cost"]).toFixed(2)}
                </span>
              ) : (
                <div className="flex w-full">
                  <div className="w-1/4 rounded-l border p-2">
                    {currency.split("-")[1]}
                  </div>
                  <input
                    type="number"
                    className="w-3/4 rounded-r border p-2"
                    onWheel={numberInputOnWheelPreventChange}
                    value={
                      product.cost === null
                        ? null
                        : handleProductCostValue(
                            product.childProducts[0].cost || 0,
                          )
                    }
                    min="0"
                    onChange={(e) =>
                      updateProductCost(
                        product.childProducts[0].id,
                        e.target.value === "" ? null : e.target.value,
                        product.id,
                      )
                    }
                  />
                </div>
              )
            ) : (
              "-"
            )}
          </div>
          <div>
            {showCosts(product.childProducts[0])
              ? `${currency.split("-")[1]}${
                  !product.childProducts[0].totalCost
                    ? "0"
                    : Number(
                        product.childProducts.reduce((acc, childProduct) => {
                          return acc + childProduct.quantity;
                        }, 0) * product.childProducts[0].cost,
                      ).toFixed(2)
                }`
              : "-"}
          </div>

          <div>
            <CustomPopconfirm
              title="Are you sure to delete this product?"
              onConfirm={() => handleDelete(product.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                className: "bg-primaryAccent text-white",
              }}
              cancelButtonProps={{
                className:
                  "bg-white border rounded border-primaryAccent text-primaryAccent",
              }}>
              <TrashIcon className="h-6 w-6 cursor-pointer text-red-500" />
            </CustomPopconfirm>
          </div>
        </div>

        {product.childProducts?.length > 0 && (
          <button
            onClick={() => toggleComponents(product.id)}
            className="mt-2 flex items-center font-semibold text-primaryAccent underline">
            {expandedProductId === product.id
              ? "Hide Variants"
              : "View Variants"}
            {expandedProductId === product.id ? (
              <ChevronUpIcon className="mr-1 h-5 w-5" />
            ) : (
              <ChevronDownIcon className="mr-1 h-5 w-5" />
            )}
          </button>
        )}
        <Transition
          as="div" // Change Fragment to an actual div
          show={expandedProductId === product.id}
          enter="transition ease-out duration-200 transform"
          enterFrom="-translate-y-10 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in duration-150 transform"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="-translate-y-10 opacity-0">
          <div
            style={{ gridTemplateColumns: "2fr 1fr 1fr" }}
            className="mt-3 grid items-start gap-4 rounded-md border border-borderGray bg-bgGray p-3 text-sm text-gray-500">
            <div>Variant Info</div>
            <div>
              Quantity <span className="text-red-500">*</span>
            </div>
            <div>Action</div>
          </div>
          {product.childProducts.map((childProduct) => {
            return (
              <div
                key={childProduct.id}
                style={{
                  gridTemplateColumns: "2fr 1fr 1fr",
                }}
                className="grid w-full items-start gap-4 p-4">
                <div className="flex items-center">
                  <img
                    src={childProduct.image}
                    alt={childProduct.name}
                    className="mr-4 h-16 w-16"
                  />
                  <div>
                    <Tooltip title={childProduct.name}>
                      <div className="w-48 truncate text-base font-medium">
                        {childProduct.name}
                      </div>
                    </Tooltip>
                    <div className="text-gray-500">SKU: {childProduct.sku}</div>
                    <div className="text-gray-500">
                      Variant:{childProduct?.variant}
                    </div>
                  </div>
                </div>
                <input
                  type="number"
                  className="w-full rounded border p-2"
                  value={childProduct.quantity}
                  onWheel={numberInputOnWheelPreventChange}
                  min={1}
                  onChange={(e) =>
                    updateQuantity(childProduct.id, e.target.value)
                  }
                />
                <div>
                  <CustomPopconfirm
                    title="Are you sure to delete this product?"
                    onConfirm={() => handleDelete(childProduct.id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{
                      className: "bg-primaryAccent text-white",
                    }}
                    cancelButtonProps={{
                      className:
                        "bg-white border rounded border-primaryAccent text-primaryAccent",
                    }}>
                    <TrashIcon className="h-6 w-6 cursor-pointer text-red-500" />
                  </CustomPopconfirm>
                </div>
              </div>
            );
          })}
          {product.childProducts &&
            product.childProducts[0]?.components?.length > 0 && (
              <div className="mt-2 w-full border-t p-4 pt-2">
                <div className="mb-2 w-full text-gray-600">
                  <CustomAlert
                    id="alertForComponent"
                    type="info"
                    message="Please note that any update made to the component pricing will only be applicable to the current PO."
                    options={{
                      defaultColors: true,
                    }}
                  />
                  <div
                    style={{
                      gridTemplateColumns: "2fr 1fr 1fr 1.5fr 1fr 1fr",
                    }}
                    className="grid h-14 w-full items-center gap-4 rounded-md border border-borderGray bg-bgGray px-4 text-left text-sm font-medium tracking-wider text-gray-500">
                    <div>Component Info</div>
                    <div>Quantity</div>
                    <div>Recipe Unit</div>
                    <div>Used Unit</div>
                    <div>Price/Unit</div>
                    <div>Cost</div>
                  </div>
                  {product.childProducts[0].components?.map(
                    (component, index) => (
                      <Fragment key={index}>
                        <div
                          style={{
                            gridTemplateColumns: "2fr 1fr 1fr 1.5fr 1fr 1fr",
                          }}
                          className="grid w-full items-start gap-4 p-4">
                          <div
                            className={`flex h-full flex-col items-start gap-3`}>
                            <Tooltip title={component.name}>
                              <span className="w-48 truncate">
                                {" "}
                                {component.name}
                              </span>
                            </Tooltip>
                            {component.masterData?.componentType ===
                              PO_STRING_VALUES.METAL_STRING && (
                              <div>
                                <p className="text-sm">Metal loss Percentage</p>
                                <p className="mt-3 flex flex-wrap items-center gap-2 text-xs">
                                  <span>Select Metal Loss %: </span>
                                  <select
                                    value={component.metalLossPercentage}
                                    onChange={(e) => {
                                      updateComponent(
                                        product.childProducts[0].id,
                                        index,
                                        "metalLossPercentage",
                                        e.target.value === ""
                                          ? null
                                          : e.target.value,
                                        product.id,
                                      );
                                    }}
                                    className={`w-20 cursor-pointer rounded border-gray-300 bg-white p-2 focus:outline-none`}
                                    id="">
                                    {METAL_LOSS_PERCENTAGE_OPTIONS.map(
                                      (option) => (
                                        <option
                                          key={uuidv4()}
                                          value={option.value}>
                                          {option.label}
                                        </option>
                                      ),
                                    )}
                                  </select>
                                </p>
                              </div>
                            )}
                          </div>
                          <div
                            className={`flex h-full w-full ${component.masterData.componentType === PO_STRING_VALUES.METAL_STRING ? "items-start" : "items-center"}`}>
                            <span>
                              {component.masterData.quantity
                                ? component.masterData.quantity
                                : 0}
                            </span>
                          </div>
                          <div
                            className={`flex h-full w-full ${component.masterData.componentType === PO_STRING_VALUES.METAL_STRING ? "items-start" : "items-center"}`}>
                            <span>
                              {(["gm", "ct"].includes(component.masterData?.uom)
                                ? component.masterData?.weight
                                : component.masterData.quantity) || 0}
                            </span>
                            <span className="ml-2">
                              {UOM_VALUES_FOR_COMPONENTS[
                                component.masterData?.uom
                              ] || "Pcs"}
                            </span>
                          </div>
                          <div
                            className={`flex h-full w-full flex-col gap-3 ${component.masterData.componentType === PO_STRING_VALUES.METAL_STRING ? "items-start" : "items-center"}`}>
                            <InputWithSelect
                              inputProps={{
                                type: "number",
                                value: component.quantity
                                  ? component.quantity
                                  : 0,
                                onChange: (e) => {
                                  updateComponent(
                                    product.childProducts[0].id,
                                    index,
                                    "quantity",
                                    e.target.value === ""
                                      ? null
                                      : e.target.value,
                                    product.id,
                                  );
                                },
                                disabled: false,
                              }}
                              selectProps={{
                                value: component.uom ? component.uom : "",
                                onChange: (e) => {
                                  updateComponent(
                                    product.childProducts[0].id,
                                    index,
                                    "uom",
                                    e.target.value === ""
                                      ? null
                                      : e.target.value,
                                    product.id,
                                  );
                                },
                                disabled: false,
                                options: [
                                  { value: "pc", label: "Pcs" },
                                  { value: "gm", label: "Gms" },
                                  { value: "ct", label: "Cts" },
                                ],
                              }}
                            />
                            {component.masterData?.componentType ===
                              PO_STRING_VALUES.METAL_STRING && (
                              <div className="flex h-full flex-wrap items-center gap-2">
                                <p className="text-xs">Metal loss used unit:</p>
                                <p>
                                  {Number(
                                    component.quantity *
                                      (component.metalLossPercentage / 100),
                                  ).toFixed(2)}{" "}
                                  {UOM_VALUES_FOR_COMPONENTS[component.uom]}
                                </p>
                              </div>
                            )}
                          </div>
                          <div
                            className={`flex h-full w-full ${component.masterData.componentType === PO_STRING_VALUES.METAL_STRING ? "items-start" : "items-center"}`}>
                            <div className="w-1/4 rounded-l border p-2">
                              {currency.split("-")[1]}
                            </div>
                            <input
                              type="number"
                              className="w-3/4 rounded-r border p-2"
                              onWheel={numberInputOnWheelPreventChange}
                              value={
                                component.unitPrice === null
                                  ? null
                                  : handleProductCostValue(
                                      component.unitPrice || 0,
                                    )
                              }
                              min="0"
                              onChange={(e) =>
                                updateComponent(
                                  product.childProducts[0].id,
                                  index,
                                  "unitPrice",
                                  e.target.value === "" ? null : e.target.value,
                                  product.id,
                                )
                              }
                            />
                          </div>
                          <div
                            className={`flex h-full flex-col items-center gap-3`}>
                            <div className="flex h-1/2 w-full justify-start">
                              {" "}
                              <span>{currency.split("-")[1]}</span>
                              <span>
                                {component.cost === null
                                  ? null
                                  : handleProductCostValue(component.cost || 0)}
                              </span>
                            </div>
                            {component.masterData.componentType ===
                              PO_STRING_VALUES.METAL_STRING && (
                              <div className="flex w-full flex-wrap items-center">
                                <p className="text-xs">Metal loss cost:</p>
                                <span className="ml-1">
                                  {currency.split("-")[1]}
                                  {Number(
                                    handleMetalLossCost(component),
                                  ).toFixed(2)}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          style={{ height: "1px" }}
                          className="w-full bg-gray-200"></div>
                      </Fragment>
                    ),
                  )}
                </div>
              </div>
            )}
        </Transition>
      </div>
    </div>
  );
};

export default AddProductsForParentProducts;
