import moment from "moment-timezone";
import _ from "lodash";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";
import BatchFilters from "#components/batches/outbound/BatchFilters";
import ExpandedCycleCountBatch from "#components/batches/inventory/ExpandedCycleCountBatch";
import { useContext, useEffect, useState } from "react";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import withCycleCountExecutionsLogic from "#components/HOC/withCycleCountExecutionsLogic";
const noValuesText = "No Cycle Count Executions";

const CYCLE_COUNT_EXECUTIONS_TABLE_NAME = "cycleCountExecutionsTable";

const CycleCountExecutionsList = ({
  total,
  perPage,
  setPerPage,
  pageNumber,
  checkPagination,
  filters,
  sort,
  setSort,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  users,
  displayBatches,
  showFilters,
  onChangeFilter,
  handleDateRangeClear,
  customers,
  warehouses,
  usersMap,
}) => {
  const [expandedBatch, setExpandedBatch] = useState(null);
  const [headers, setHeaders] = useState([
    getHeaderObject("Batch ID", "id", false),
    getHeaderObject("User", "name", false),
    getHeaderObject("Plan", "planName", false),
    getHeaderObject("Assigned Time", "assignedTime"),
    getHeaderObject("Status", "status"),
  ]);

  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      CYCLE_COUNT_EXECUTIONS_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  return (
    <>
      {filters?.startEndDate && (
        <div className="flex items-end justify-end text-2xl font-bold text-primaryAccent">
          Date Range: {filters.startEndDate[0] || "Beginning"} ~{" "}
          {filters.startEndDate[1] || "Now"}
        </div>
      )}

      <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
        {warehouses?.length > 1 && (
          <QuickFilters
            warehouseFilterName={"warehouses"}
            customerFilterName={"customers"}
            warehouses={warehouses}
            customers={customers}
            filters={filters}
            onChangeFilter={onChangeFilter}
          />
        )}
        <SearchSortFilter
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          clearFilters={clearFilters}
          choices={choices}
          headers={headers}
          setShowHeaders={setShowHeaders}
          tableName={CYCLE_COUNT_EXECUTIONS_TABLE_NAME}
        />

        <CustomTableWithTheme>
          <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
            <tr className="border-left font-montserrat text-textWhite">
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {displayBatches?.length === 0 ? (
              <tr className="bg-white">
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <td
                      className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                      key={headerIdx}>
                      {noValuesText}
                    </td>
                  ) : (
                    <td
                      className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {displayBatches &&
              displayBatches.map((cycleCountExecution, rowIndex) => (
                <tr
                  key={cycleCountExecution.id}
                  className={`${
                    rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}>
                  {showHeaders.map((header, columnIndex) => {
                    let value = header.correspondingValue;
                    let cellStyle = cellStyleForTable(
                      value,
                      ["id"],
                      columnIndex,
                      rowIndex,
                    );
                    if (value === "id") {
                      return (
                        <td
                          className={cellStyle}
                          onClick={() => setExpandedBatch(cycleCountExecution)}>
                          {cycleCountExecution.id}
                        </td>
                      );
                    }
                    if (value === "assignedTime") {
                      return (
                        <td className={cellStyle}>
                          {moment(
                            cycleCountExecution.assignedTime
                              ? cycleCountExecution.assignedTime
                              : cycleCountExecution.createdAt,
                          ).format("MMM Do YYYY, h:mm a")}
                        </td>
                      );
                    }
                    if (value === "planName") {
                      return (
                        <td className={cellStyle}>
                          {cycleCountExecution.planName}
                        </td>
                      );
                    }
                    if (value === "name") {
                      return (
                        <td className={cellStyle}>
                          {users &&
                            users.find(
                              (item) =>
                                item.id.toString() ===
                                cycleCountExecution.assignedUserId?.toString(),
                            )?.name}
                        </td>
                      );
                    }
                    if (value === "quantity") {
                      return (
                        <td className={cellStyle}>
                          {cycleCountExecution.quantity}
                        </td>
                      );
                    }
                    if (value === "status") {
                      return (
                        <td className={cellStyle}>
                          {cycleCountExecution.status}
                        </td>
                      );
                    }
                    if (value === "sku") {
                      return (
                        <td className={cellStyle}>{cycleCountExecution.sku}</td>
                      );
                    }

                    if (value === "fromBin") {
                      return (
                        <td className={cellStyle}>
                          {cycleCountExecution.fromBin}
                        </td>
                      );
                    }
                    if (value === "toBin") {
                      return (
                        <td className={cellStyle}>
                          {cycleCountExecution.toBin}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
          </tbody>
        </CustomTableWithTheme>
      </div>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
      {expandedBatch && (
        <ExpandedCycleCountBatch
          batchItems={expandedBatch.workingList || []}
          negativeAction={() => setExpandedBatch(null)}
          tableName={"cycleCountBatch.workingList"}
          batch={expandedBatch}
          usersMap={usersMap}
        />
      )}
      {showFilters && (
        <BatchFilters
          dateRangeLabel="Select Date Range"
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          showDateRangePicker={true}
          filters={filters}
          onSubmit={submitFilters}
          handleDateRangeClear={handleDateRangeClear}
          users={
            users
              ? users.filter(
                  (item) =>
                    item.hopstackModules &&
                    item.hopstackModules.includes("Stock Transfer"),
                )
              : []
          }
          statusOptions={[
            { name: "UNPROCESSED" },
            { name: "ACTIVE" },
            { name: "COMPLETED" },
          ]}
          customers={customers}
          warehouses={warehouses}
        />
      )}
    </>
  );
};

export default withCycleCountExecutionsLogic(CycleCountExecutionsList);
