import { useEffect, useRef, useState, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { DownloadIcon } from "@heroicons/react/outline";

import { GET_SO_PDF_DOWNLOAD_HISTORY, GET_USERS } from "#queries";
import { GET_SO_PDF_PRESIGNED_URL } from "#mutations";

import { AppStateContext } from "#contexts/appState";

const ALERT_TIMEOUT_IN_MS = 5000;

const DownloadHistory = () => {
  const appState = useContext(AppStateContext);
  const [page, setPage] = useState(1);
  const [downloadHistoryData, setDownloadHistoryData] = useState([]);
  const [hasMore, setHasMore] = useState(true); // To manage whether there is more data to load
  const getDownloadHistoryQuery = useQuery(GET_SO_PDF_DOWNLOAD_HISTORY);
  const getUsersQuery = useQuery(GET_USERS);
  const getSoPdfPresignedUrlQuery = useQuery(GET_SO_PDF_PRESIGNED_URL);
  const [userInfo, setUserInfo] = useState({});

  const fetchDownloadHistory = (page) => {
    // Simulate an API request with pagination (replace with actual API call)
    getDownloadHistoryQuery.fetchData({
      perPage: 10,
      pageNumber: page,
    });
  };
  const observer = useRef();
  const fetchPresignedUrl = (crmSalesOrderId) => {
    getSoPdfPresignedUrlQuery.fetchData({ crmSalesOrderId });
  };

  useEffect(() => {
    fetchDownloadHistory(page);
  }, [page]);

  useEffect(() => {
    if (getDownloadHistoryQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (getDownloadHistoryQuery.data) {
      if (getDownloadHistoryQuery.data?.getCrmSalesOrderPdfDownloadHistory) {
        const records =
          getDownloadHistoryQuery.data.getCrmSalesOrderPdfDownloadHistory;
        const userIds = records
          .filter((record) => {
            if (!userInfo[record.user]) {
              return record.user;
            }
          })
          .map((val) => val.user);
        if (userIds.length > 0) {
          getUsersQuery.fetchData({
            filters: {
              id: userIds,
            },
          });
        }
        setDownloadHistoryData((prevData) => [...prevData, ...records]);
        setHasMore(records?.length > 0); // If no more logs are returned, set hasMore to false
      }
    }

    if (getDownloadHistoryQuery.error) {
      appState.setAlert(
        getDownloadHistoryQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [
    getDownloadHistoryQuery.loading,
    getDownloadHistoryQuery.data,
    getDownloadHistoryQuery.error,
  ]);

  useEffect(() => {
    if (getUsersQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (getUsersQuery.data?.users?.entities) {
      setUserInfo((prev) => {
        let updatedUserInfo = { ...prev };
        getUsersQuery.data.users.entities.forEach((user) => {
          updatedUserInfo[user.id] = user;
        });
        return updatedUserInfo;
      });
    }

    if (getUsersQuery.error) {
      appState.setAlert(
        getUsersQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [getUsersQuery.loading, getUsersQuery.data, getUsersQuery.error]);

  useEffect(() => {
    if (getSoPdfPresignedUrlQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    const presignedUrl =
      getSoPdfPresignedUrlQuery.data?.getCrmSalesOrderPdfPresignedUrl
        ?.presignedUrl;
    if (presignedUrl) {
      const link = document.createElement("a");
      link.href = presignedUrl;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("Presigned URL not found", getSoPdfPresignedUrlQuery.data);
    }
  }, [
    getSoPdfPresignedUrlQuery.data,
    getSoPdfPresignedUrlQuery.error,
    getSoPdfPresignedUrlQuery.loading,
  ]);

  // Use IntersectionObserver to detect when the last row comes into view
  const lastLogElementRef = (node) => {
    if (getDownloadHistoryQuery.loading) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // Increment page and trigger data fetching
      }
    });

    if (node) observer.current.observe(node);
  };

  return (
    <div className="">
      <p className="mb-4 text-sm text-gray-600">
        Download all historic order PDFs as requested through the system
      </p>
      <div className="mx-auto w-full overflow-x-auto rounded-lg bg-white p-6 shadow-lg">
        <table className="min-w-full border bg-white">
          <thead className="bg-E2E2E2">
            <tr>
              <th className="border-b-2 border-gray-200 p-6 text-left font-semibold text-gray-500">
                Name of User
              </th>
              <th className="border-b-2 border-gray-200 p-6 text-left font-semibold text-gray-500">
                Date & Time of Request
              </th>
              <th className="border-b-2 border-gray-200 p-6 text-left font-semibold text-gray-500">
                Customer ID
              </th>
              <th className="border-b-2 border-gray-200 p-6 text-left font-semibold text-gray-500">
                Order ID
              </th>
              <th className="border-b-2 border-gray-200 p-6 text-left font-semibold text-gray-500">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {downloadHistoryData.map((record, index) => {
              if (downloadHistoryData.length === index + 1)
                return (
                  <tr
                    ref={lastLogElementRef}
                    key={index}
                    className="hover:bg-gray-100">
                    <td className="border-b border-gray-200 p-6">
                      {userInfo[record.user]?.name}
                    </td>
                    <td className="border-b border-gray-200 p-6">
                      {new Date(record.createdAt).toISOString()}
                    </td>
                    <td className="border-b border-gray-200 p-6">
                      {record.crmCustomerId}
                    </td>
                    <td className="border-b border-gray-200 p-6">
                      {record.crmSalesOrderId}
                    </td>
                    <td className="border-b border-gray-200 p-6">
                      <button
                        onClick={() =>
                          fetchPresignedUrl(record.crmSalesOrderId)
                        }
                        className={`relative flex w-full cursor-pointer select-none items-center py-4 pl-4 pr-4 hover:text-2C7695`}>
                        <DownloadIcon className="h-8 w-8 pr-2" />
                      </button>
                    </td>
                  </tr>
                );
              else {
                return (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="border-b border-gray-200 p-6">
                      {userInfo[record.user]?.name}
                    </td>
                    <td className="border-b border-gray-200 p-6">
                      {new Date(record.createdAt).toISOString()}
                    </td>
                    <td className="border-b border-gray-200 p-6">
                      {record.crmCustomerId}
                    </td>
                    <td className="border-b border-gray-200 p-6">
                      {record.crmSalesOrderId}
                    </td>
                    <td className="border-b border-gray-200 p-6">
                      <button
                        onClick={() =>
                          fetchPresignedUrl(record.crmSalesOrderId)
                        }
                        className={`relative flex w-full cursor-pointer select-none items-center py-4 pl-4 pr-4 hover:text-2C7695`}>
                        <DownloadIcon className="h-8 w-8 pr-2" />
                      </button>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
        <div className="h-10"></div>
        {hasMore ? (
          <div className="p-4 text-center">
            <span>Loading more data...</span>
          </div>
        ) : (
          <div className="p-4 text-center">
            <span>No more data to load</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadHistory;
