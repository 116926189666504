import Barcode from "react-barcode";

const FourBySixLabel = ({ id, value }) => (
  <div
    style={{
      width: 384,
      height: 576,
      textAlign: "center",
      backgroundColor: "#fff",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      paddingLeft: 5,
      paddingRight: 5,
      top: 200,
      left: 0,
      zIndex: 50,
      flex: 1,
    }}
    id={`${id}_4x6`}>
    <Barcode value={value} width={1.8} height={50} fontSize={18} />
  </div>
);

export default FourBySixLabel;
