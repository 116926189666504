import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Button } from "antd";
import { twMerge } from "tailwind-merge";

export default function PrimaryButton({
  children,
  onClick,
  variant,
  primaryColor,
  textColor,
  disabled,
  loading,
  className,
  filledClasses,
  outlinedClasses,
  width,
  height,
  maxWidth,
  minWidth,
  borderColor,
  ...props
}) {
  const filled = `${className} bg-${primaryColor} text-${textColor} ${filledClasses} flex cursor-pointer items-center justify-center rounded-md p-2`;
  const outlined = `${className} border-2 border-${borderColor} text-${primaryColor} ${outlinedClasses} flex cursor-pointer items-center justify-center rounded-md p-2`;

  return (
    <Button
      type={variant}
      onClick={onClick}
      style={{
        width,
        height,
        maxWidth,
        minWidth,
      }}
      disabled={disabled}
      loading={loading}
      className={twMerge(`${variant === "primary" ? filled : outlined}`)}
      {...props}>
      {children}
    </Button>
  );
}

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  primaryColor: PropTypes.string,
  textColor: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  filledClasses: PropTypes.string,
  outlinedClasses: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  borderColor: PropTypes.string,
};

PrimaryButton.defaultProps = {
  onClick: () => {},
  variant: "",
  primaryColor: "primaryAccent",
  textColor: "white",
  disabled: false,
  loading: false,
  className: "mt-2 text-sm font-medium",
  filledClasses: "border-none border text-white",
  outlinedClasses: "",
  width: "",
  height: "",
  maxWidth: "",
  minWidth: "",
  borderColor: "primaryAccent",
};
