import Modal from "#components/utils/Modal";
import { useState } from "react";
import { ExclamationCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import Dropdown from "#components/utils/Dropdown";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";

const AutocompleteDropdownWrapper = ({
  label,
  options,
  labelKey,
  valueKey,
  onChange,
  value,
  showCheckedIndicator = false,
}) => {
  return (
    <div className="catalog-textfield-wrapper">
      <label className="catalog-textfield-label">{label}</label>
      <AutocompleteSingleSelectDropdown
        options={options}
        labelKey={labelKey ?? "name"}
        valueKey={valueKey ?? "id"}
        onChange={onChange}
        value={value}
        showCheckedIndicator={showCheckedIndicator}
      />
    </div>
  );
};

const ChannelAllocation = ({
  negativeAction,
  sourceProduct,
  warehousesForSelectedProductInAllocation,
  positiveAction,
  clientMarketplaceIntegrations,
  channelProductSearchKeyword,
  setChannelProductSearchKeyword,
  onChangeChannelProductSearch,
  marketplaceProducts,
  addedChannelProducts,
  setAddedChannelProducts,
  multiAccountSupportEnabled,
  customers,
  addingChannel,
  setAddingChannel,
}) => {
  let quantity = sourceProduct?.quantities?.availableToShip;
  if (
    warehousesForSelectedProductInAllocation &&
    warehousesForSelectedProductInAllocation.length > 0
  ) {
    /* If quantities.warehouses has warehouses, 
    Calculate the total quantity available to ship from active warehouses */
    quantity = 0;
    for (const warehouse of sourceProduct?.quantities?.warehouses) {
      // Check if the warehouse is active, through the warehousesForSelectedProductInAllocation array
      const warehouseFound = warehousesForSelectedProductInAllocation.find(
        (ele) => ele.id.toString() === warehouse.warehouseId,
      );
      if (warehouseFound && warehouseFound?.active === true) {
        quantity += warehouse.availableToShip;
      }
    }
  }
  const hasQuantity = quantity > 0;
  const hasMarketplaceIntegrations = clientMarketplaceIntegrations.length > 0;
  const canAllocate = hasQuantity && hasMarketplaceIntegrations;
  if (addingChannel) {
    // If we are adding a channel, we should disable the modal from being submitted. Positive action is the "Submit" button on the modal.
    positiveAction = null;
  }
  return (
    <Modal
      negativeAction={negativeAction}
      title={`Allocate Channel`}
      noPadding={true}
      positiveAction={canAllocate ? positiveAction : negativeAction}
      positiveText={canAllocate ? "Submit" : "OK"}>
      <div className="space-y-4 px-2 pb-2">
        <div className="max-w-2xl rounded-lg border border-gray-200 bg-white p-6 shadow-md dark:border-gray-700 dark:bg-gray-800">
          <a href="#">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {sourceProduct.name}
            </h5>
          </a>
          {sourceProduct.asin && (
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              ASIN:
              <span className="font-bold">
                {buildMarketplaceHyperlink(
                  sourceProduct.asin,
                  "ASIN",
                  sourceProduct.source,
                )}
              </span>
            </p>
          )}
          {sourceProduct.sku && (
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              SKU:
              <span className="px-2 font-bold">{sourceProduct.sku}</span>
            </p>
          )}
          {multiAccountSupportEnabled &&
            sourceProduct.marketplaceAttributes?.attributes
              ?.marketplaceCountryCode && (
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                Marketplace:
                <span className="px-2 font-bold">
                  {
                    sourceProduct.marketplaceAttributes?.attributes
                      ?.marketplaceCountryCode
                  }
                </span>
              </p>
            )}
          {multiAccountSupportEnabled &&
            sourceProduct.marketplaceAttributes?.attributes?.sellerId && (
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                Seller:
                <span className="px-2 font-bold">
                  {sourceProduct.marketplaceAttributes?.attributes?.sellerId}
                </span>
              </p>
            )}
          {sourceProduct.quantities && (
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Unallocated Quantity:
              <span className="px-2 font-bold">{quantity}</span>
            </p>
          )}
          {sourceProduct.customer && (
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Client:
              <span className="px-2 font-bold">
                {customers?.find((i) => i.id === sourceProduct.customer)
                  ?.name || "-"}
              </span>
            </p>
          )}
        </div>

        {canAllocate ? (
          <>
            <div className="text-xl font-bold">
              {addingChannel ? (
                <>
                  <>
                    <AutocompleteDropdownWrapper
                      label="Target Client"
                      options={customers}
                      value={addingChannel.customer}
                      onChange={(e) =>
                        setAddingChannel({ ...addingChannel, customer: e })
                      }
                    />
                    <AutocompleteDropdownWrapper
                      label="Channel"
                      options={clientMarketplaceIntegrations.map((i) => ({
                        ...i,
                        id: i.id + `-${i.integrationType}`,
                      }))}
                      value={addingChannel.integrationId}
                      onChange={(e) =>
                        setAddingChannel({ ...addingChannel, integrationId: e })
                      }
                      labelKey="integrationName"
                      valueKey="id"
                    />

                    {addingChannel.integrationId &&
                      multiAccountSupportEnabled &&
                      clientMarketplaceIntegrations.find(
                        (i) =>
                          i.id === addingChannel.integrationId.split("-")[0],
                      )?.marketplaces && (
                        <Dropdown
                          label="Marketplace"
                          list={
                            clientMarketplaceIntegrations
                              .find(
                                (i) =>
                                  i.id ===
                                  addingChannel.integrationId.split("-")[0],
                              )
                              ?.marketplaces?.map((m) => ({
                                label: m,
                                value: m,
                              })) || []
                          }
                          labelKey="label"
                          valueKey="value"
                          placeholder="Select Marketplace"
                          selectedValue={addingChannel.marketplace}
                          setSelected={(e) =>
                            setAddingChannel({
                              ...addingChannel,
                              marketplace: e,
                              customer: sourceProduct.customer,
                            })
                          }
                        />
                      )}

                    <div className="mt-4">
                      <TextField
                        type="number"
                        id="quantity"
                        label="Quantity"
                        placeholder=" "
                        onChange={(e) =>
                          setAddingChannel({
                            ...addingChannel,
                            quantity:
                              isNaN(parseInt(e.target.value)) === false
                                ? parseInt(e.target.value)
                                : null,
                          })
                        }
                        value={addingChannel.quantity}
                        name="quantity"
                        min="0"
                      />
                    </div>
                    {addingChannel.integrationId && (
                      <>
                        <div className="space-y-4">
                          <label
                            htmlFor="default-search"
                            className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Search
                          </label>
                          <div className="relative">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <svg
                                aria-hidden="true"
                                className="h-5 w-5 text-gray-500 dark:text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                              </svg>
                            </div>
                            <input
                              type="search"
                              id="default-search"
                              className="text-md block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                              placeholder={
                                ["FBA", "FBM"].includes(
                                  clientMarketplaceIntegrations.find(
                                    (i) =>
                                      i.id ===
                                      addingChannel.integrationId.split("-")[0],
                                  ).integrationType,
                                )
                                  ? "Search by ASIN/SKU/FNSKU/UPC/LPN/Name"
                                  : "Search by SKU/UPC/LPN/Name"
                              }
                              onChange={(e) => {
                                setChannelProductSearchKeyword(e.target.value);
                                onChangeChannelProductSearch(
                                  e.target.value,
                                  addingChannel,
                                );
                              }}
                            />
                          </div>
                        </div>
                        {channelProductSearchKeyword &&
                          channelProductSearchKeyword.trim() !== "" && (
                            <div className="max-h-96 overflow-scroll">
                              <table className="table-auto items-start text-left">
                                <thead className="bg-gray-50">
                                  <tr>
                                    {["FBA", "FBM"].includes(
                                      clientMarketplaceIntegrations.find(
                                        (i) =>
                                          i.id ===
                                          addingChannel.integrationId.split(
                                            "-",
                                          )[0],
                                      ).integrationType,
                                    ) && (
                                      <th className="text-md px-6 py-2 text-gray-500">
                                        ASIN
                                      </th>
                                    )}
                                    <th className="text-md px-6 py-2 text-gray-500">
                                      SKU
                                    </th>
                                    <th className="text-md px-6 py-2 text-gray-500">
                                      Name
                                    </th>
                                    <th className="text-md px-6 py-2 text-gray-500">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white">
                                  {marketplaceProducts.length === 0 && (
                                    <tr className="whitespace-nowrap">
                                      <td className="text-md px-6 py-4 text-gray-500">
                                        No Results
                                      </td>
                                      <td className="text-md px-6 py-4 text-gray-500"></td>
                                      <td className="text-md px-6 py-4 text-gray-500"></td>
                                      <td className="text-md px-6 py-4 text-gray-500"></td>
                                    </tr>
                                  )}

                                  {marketplaceProducts.length > 0 &&
                                    marketplaceProducts.map((item, idx) => (
                                      <tr className="whitespace-nowrap">
                                        {["FBA", "FBM"].includes(
                                          clientMarketplaceIntegrations.find(
                                            (i) =>
                                              i.id ===
                                              addingChannel.integrationId.split(
                                                "-",
                                              )[0],
                                          ).integrationType,
                                        ) && (
                                          <td className="text-md px-6 py-4 text-gray-500">
                                            {buildMarketplaceHyperlink(
                                              item.asin,
                                              "ASIN",
                                              item.source,
                                            )}
                                          </td>
                                        )}
                                        <td className="text-md px-6 py-4 text-gray-500">
                                          {item.sku}
                                        </td>
                                        <td
                                          className="text-md px-6 py-4 text-gray-500"
                                          title={item.name}>
                                          {truncate(item.name)}
                                        </td>
                                        <td className="text-md px-6 py-4 text-gray-500">
                                          <div
                                            onClick={() => {
                                              addingChannel.quantity &&
                                                setAddedChannelProducts([
                                                  ...addedChannelProducts,
                                                  {
                                                    ...item,
                                                    quantity:
                                                      addingChannel.quantity,
                                                  },
                                                ]);
                                              setAddingChannel(null);
                                              setChannelProductSearchKeyword(
                                                null,
                                              );
                                            }}
                                            className="text-md cursor-pointer rounded-md bg-2C7695 p-2 text-center text-white">
                                            Select
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                      </>
                    )}
                  </>
                  <div
                    onClick={() => setAddingChannel(null)}
                    className="text-md textWhite-300 my-2 w-24 cursor-pointer items-center justify-center rounded rounded-md border bg-red-600 px-2 py-2 text-center font-montserrat text-lg font-medium text-white outline-none ring-0 hover:bg-red-700 focus:outline-none focus:ring-0">
                    Cancel
                  </div>
                </>
              ) : (
                <div className="p-4 pt-0">
                  <div className="text-xl text-black">Allocated Channels</div>
                  <table className="my-1 table-auto items-start text-left">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="text-md px-6 py-2 text-gray-500">SKU</th>
                        {["FBA", "FBM"].includes(sourceProduct.source) && (
                          <th className="text-md px-6 py-2 text-gray-500">
                            ASIN
                          </th>
                        )}
                        <th className="text-md px-6 py-2 text-gray-500">
                          Name
                        </th>
                        <th className="text-md px-6 py-2 text-gray-500">
                          Channel
                        </th>
                        <th className="text-md px-6 py-2 text-gray-500">
                          Quantity
                        </th>
                        <th className="text-md px-6 py-2 text-gray-500">
                          Client
                        </th>
                        <th className="text-md px-6 py-2 text-gray-500">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {addedChannelProducts.map((item, idx) => {
                        return (
                          <tr className="whitespace-nowrap">
                            <td className="text-md px-6 py-4 text-gray-500">
                              {item.sku}
                            </td>
                            {["FBA", "FBM"].includes(sourceProduct.source) && (
                              <td className="text-md px-6 py-4 text-gray-500">
                                {buildMarketplaceHyperlink(
                                  item.asin,
                                  "ASIN",
                                  item.source,
                                )}
                              </td>
                            )}
                            <td className="text-md px-6 py-4 text-gray-500">
                              {truncate(item.name)}
                            </td>
                            <td className="text-md px-6 py-4 text-gray-500">
                              {item.source}
                            </td>
                            <td className="text-md px-6 py-4 text-gray-500">
                              {item.quantity}
                            </td>

                            <td className="text-md px-6 py-4 text-gray-500">
                              {customers?.find((i) => i.id === item.customer)
                                ?.name || "-"}
                            </td>

                            <td className="text-md px-6 py-4 text-gray-500">
                              <div className="flex space-x-2">
                                <span
                                  className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                                  onClick={() => {
                                    const temp = [...addedChannelProducts];
                                    temp.splice(idx, 1);
                                    setAddedChannelProducts(temp);
                                  }}>
                                  Remove
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    className="mb-2 mt-2 flex w-48 cursor-pointer text-ellipsis rounded-full border-2 border-dashed border-2C7695 p-2"
                    onClick={() =>
                      setAddingChannel({
                        customer: sourceProduct.customer,
                      })
                    }>
                    <PlusCircleIcon className="mr-2 h-6 w-6" />
                    Add Channel
                  </div>
                </div>
              )}

              <ExclamationCircleIcon className="inline-block h-6 w-6 text-yellow-500" />
              <span className="ml-2">
                Warning: LPNs and other identifiers will not be carried over to
                the destination product.
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="flex text-xl font-bold">
              <ExclamationCircleIcon className="inline-block h-6 w-6 text-red-500" />
              <span className="ml-2">
                {hasQuantity
                  ? `You need to have at least 1 active marketplace integration to allocate a channel.`
                  : `You need a minimum of 1 unit to allocate a channel.`}
              </span>
            </div>
          </>
        )}
        {/* TODO: VALIDATION OF CHANNEL ALLOCATION SHOULD GO HERE */}
      </div>
    </Modal>
  );
};

const truncate = (str) => {
  return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
};

export default ChannelAllocation;
