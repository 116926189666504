import React, { useState } from "react";
import { Table } from "antd";
import TextField from "#components/utils/TextField";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { useCubeQuery } from "@cubejs-client/react";

const DetailedStat = ({
  title,
  onClose,
  stat,
  selectedBinLocation,
  setSelectedBinLocation,
  data,
  dataIsLoading,
  onChangeSearchFilter,
  selectedStatDetail,
}) => {
  const { resultSet, isLoading } = useCubeQuery(stat.query);
  return (
    <div>
      <div className="flex justify-start bg-white p-8 shadow-xl">
        <button onClick={onClose}>
          <ChevronLeftIcon className="h-8 w-8" />
        </button>
        <span className="ml-3 mt-1 text-xl">{title || "Detailed Report"}</span>
      </div>
      <div className="bg-white p-4">
        <div className="mb-4 w-1/3">
          {selectedStatDetail !== "BIN_AGE_CUSTOM" && (
            <TextField
              type="text"
              label="Search Bins or SKUs"
              placeholder="Search Bins or SKUs"
              onChange={(e) => onChangeSearchFilter(e.target.value)}
            />
          )}
        </div>
        <Table
          columns={stat.tableColumns}
          dataSource={data || resultSet?.loadResponse.results[0].data || []}
          loading={isLoading || dataIsLoading}
          pagination={{ showSizeChanger: false }}
          onRow={(record) => {
            return {
              onClick: () => setSelectedBinLocation(record["binLocation"]),
            };
          }}
        />
      </div>
    </div>
  );
};

export default DetailedStat;
