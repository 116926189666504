import moment from "moment-timezone";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  PencilIcon,
  TrashIcon,
  QrcodeIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import _ from "lodash";
import CustomTableWithTheme from "../common/CustomTableWithTheme";

const CycleCountPlansList = ({
  cycleCountPlans,
  editButtonClicked,
  deleteButtonClicked,
  viewExecutionsClicked,
  passwordEditable,
  headers,
  noValuesText,
  warehouses,
  executePlan,
}) => {
  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <CustomTableWithTheme>
        <thead className="rounded-full bg-primaryAccent p-4">
          <tr className="font-montserratborder-left text-textWhite">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {cycleCountPlans.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {cycleCountPlans.map((cycleCountPlan, index) => (
            <tr
              key={cycleCountPlan.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {cycleCountPlan.name}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {cycleCountPlan.frequency}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {cycleCountPlan.type}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {moment(cycleCountPlan.startDate).format("MMM Do, YYYY")}
              </td>
              <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                <div className="flex items-center space-x-4">
                  <TableFieldButton
                    onClick={() => executePlan(cycleCountPlan)}
                    text={<QrcodeIcon className="h-6 w-6" />}
                  />
                  <TableFieldButton
                    onClick={() => editButtonClicked(cycleCountPlan)}
                    text={<PencilIcon className="h-6 w-6" />}
                  />
                  <TableFieldButton
                    text={<TrashIcon className="h-6 w-6" />}
                    disabled={
                      cycleCountPlan.executions?.length >= 1 ? true : false
                    }
                    onClick={() => deleteButtonClicked(cycleCountPlan.id)}
                  />

                  <TableFieldButton
                    text={<EyeIcon className="h-6 w-6" />}
                    onClick={() => viewExecutionsClicked(cycleCountPlan)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default CycleCountPlansList;
