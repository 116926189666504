import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

import { useCubeQuery } from "@cubejs-client/react";

const colors = ["#primaryAccent", "#F4C261", "#7A77FF"];

const query = {
  measures: ["Batches.receiving_cycle_time", "Batches.putaway_cycle_time"],
  timeDimensions: [
    {
      dimension: "Batches.createdat",
    },
  ],
  order: {
    "Batches.createdat": "asc",
  },
  dimensions: ["customers.name"],
  filters: [
    {
      member: "Batches.tenant",
      operator: "equals",
      values: [],
    },
    {
      member: "BatchesCustomer.customer",
      operator: "equals",
      values: [],
    },
    {
      member: "BatchesWarehouse.warehouse",
      operator: "equals",
      values: [],
    },
  ],
};

function getFormatedTime(timeInHours) {
  if (timeInHours > 0) {
    let timeString = `${Math.floor(timeInHours)} hr ${Math.round(
      (timeInHours - Math.floor(timeInHours)) * 60,
    )} min`;
    return timeString;
  } else return "0 hr";
}

function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div className="bg-slate-300 px-5 py-3">
        <p className="text-normal text-[16px]">{label}</p>
        <p className="text-normal text-blue text-[14px]">{`Avg. Inbound Time : ${getFormatedTime(
          payload[0]?.value ?? 0,
        )}`}</p>
      </div>
    );
  }

  return null;
}

const trendsOfAvgIBTimeMOMChart = (resultSet) => {
  let data = resultSet.chartPivot();
  data = data.map((d) => {
    let date = new Date(d["x"]);
    d["x"] = date.toLocaleDateString();
    return d;
  });
  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" title="Days" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          activeDot={{ r: 8 }}
          strokeWidth={4}
          stroke="#2C7695"
          key={"ibTimeMerchant"}
          stackId="a"
          dataKey={"Batches.receiving_cycle_time"}
          name={"Avg Inbound Time (hrs)"}
          fill={colors[0]}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const trendsOfAvgIBTimeRender = ({ resultSet, error }) => {
  if (resultSet) {
    let receivingDataSet = {};
    resultSet.loadResponse.results[0].data.forEach((item) => {
      receivingDataSet[item["Batches.createdat"]] = {
        ...item,
        "Batches.receiving_cycle_time":
          (item["Batches.createdat"] in receivingDataSet
            ? Number(
                receivingDataSet[item["Batches.createdat"]][
                  "Batches.receiving_cycle_time"
                ] ?? 0,
              )
            : 0) + Number(item["Batches.receiving_cycle_time"] ?? 0),
        countRE:
          (item["Batches.createdat"] in receivingDataSet
            ? Number(receivingDataSet[item["Batches.createdat"]]["countRE"])
            : 0) + 1,
        "Batches.putaway_cycle_time":
          (item["Batches.createdat"] in receivingDataSet
            ? Number(
                receivingDataSet[item["Batches.createdat"]][
                  "Batches.putaway_cycle_time"
                ] ?? 0,
              )
            : 0) + Number(item["Batches.putaway_cycle_time"] ?? 0),
        countPA:
          (item["Batches.createdat"] in receivingDataSet
            ? Number(receivingDataSet[item["Batches.createdat"]]["countPA"])
            : 0) + 1,
      };
    });
    resultSet.loadResponse.results[0].data =
      resultSet.loadResponse.results[0].data?.map((item) => {
        delete item["customers.name"];
        delete item["Batches.putaway_cycle_time"];
        item["Batches.receiving_cycle_time"] = (
          receivingDataSet[item["Batches.createdat"]][
            "Batches.receiving_cycle_time"
          ] /
            (3600 * receivingDataSet[item["Batches.createdat"]].countRE) +
          receivingDataSet[item["Batches.createdat"]][
            "Batches.putaway_cycle_time"
          ] /
            (3600 * receivingDataSet[item["Batches.createdat"]].countPA)
        ).toFixed(2);
        return item;
      }) ?? [];

    return trendsOfAvgIBTimeMOMChart(resultSet);
  } else if (error && error.toString()) {
    return <h4>{error.toString()}</h4>;
  } else {
    return <h4>Loading...</h4>;
  }
};

const TrendsOfAvgIBTimeChart = ({
  dateRange,
  tenantId,
  customers,
  warehouses,
}) => {
  query["timeDimensions"][0]["granularity"] = "day";
  query["timeDimensions"][0]["dateRange"] = dateRange;
  query["filters"][0].values = [tenantId];
  query["filters"][1].values = customers;
  query["filters"][2].values = warehouses;
  console.log("QUERY:" + JSON.stringify(query));
  const renderProps = useCubeQuery(query);
  return trendsOfAvgIBTimeRender(renderProps);
};

const TrendsOfAvgIBTime = ({ dateRange, tenantId, customers, warehouses }) => {
  return (
    <div className="col-span-4 w-full rounded-xl bg-white p-2 px-4 py-4 shadow-lg">
      <div className="text-lg">By Merchant</div>
      <div className="mt-5 w-full" style={{ height: "370px" }}>
        <TrendsOfAvgIBTimeChart
          dateRange={dateRange}
          key={"total_orders_" + dateRange.toString().replaceAll(" ", "_")}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
      </div>
    </div>
  );
};

export default TrendsOfAvgIBTime;
