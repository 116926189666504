import React, { useState } from "react";
import CustomBadge from "#newUiComponents/commons/CustomBadge";
import InputBox from "#newUiComponents/commons/InputBox";
import { PlusIcon, MinusIcon } from "@heroicons/react/outline";

const BoxTable = ({
  boxDetails,
  currentBatch,
  setWeightOfBox,
  setViewItems,
  setBoxName,
  setBoxDetails,
}) => {
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (index) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((i) => i !== index)
        : [...prevExpandedRows, index],
    );
  };

  const noWrapStyle = {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };

  return (
    <div
      style={{ maxHeight: "600px" }}
      className="box-list h-fit w-full overflow-y-auto rounded-lg border border-gray-100 font-inter">
      {/* Common Box Header */}
      <div
        className="sticky top-0 grid grid-cols-4 items-center gap-4 bg-gray-50 px-6 py-6 text-left text-sm font-semibold tracking-wider text-[#717679]"
        style={{ gridTemplateColumns: "2fr 1fr 1fr 1fr 50px", zIndex: "8" }}>
        <div>Name</div>
        <div>No. of Products</div>
        <div>Dimensions</div>
        <div>Weight (lb)</div>
        <div></div> {/* Empty space for the expand/collapse button */}
      </div>

      {/* Box Rows */}
      {boxDetails.map((item, index) => (
        <div key={index} className="box-wrapper border-t border-gray-100">
          {/* Box Data Row */}
          <div
            className="grid grid-cols-4 items-center gap-4 bg-white px-6 py-6 text-sm font-normal tracking-wider"
            style={{
              gridTemplateColumns: "2fr 1fr 1fr 1fr 50px",
            }}>
            <div className="font-semibold" style={{ ...noWrapStyle }}>
              <span
                className="cursor-pointer text-base font-semibold text-primaryAccent"
                onClick={() => {
                  setViewItems(item.name);
                  setBoxName(item.name);
                }}>
                {item.name}
              </span>
            </div>
            <div className="flex" style={{ ...noWrapStyle }}>
              <CustomBadge
                label={currentBatch.workingList
                  .filter((i) => i.boxName === item.name)
                  ?.length.toString()}
                bgColor="#DEE0FF"
                textColor="#555656"
                textSize="text-sm"
              />
            </div>
            <div
              style={{
                ...noWrapStyle,
              }}>{`${item.length} x ${item.width} x ${item.height}`}</div>
            <div>
              <InputBox
                type="number"
                value={item.weight || ""}
                onChange={(e) => setWeightOfBox(index, e.target.value)}
                placeholder="Enter Weight"
                min="0"
                parentClasses="w-3/4"
                labelClasses="text-gray-700"
              />
            </div>
            <div className="text-right">
              <button onClick={() => toggleRowExpansion(index)}>
                {expandedRows.includes(index) ? (
                  <MinusIcon
                    className="h-4 w-4 text-primaryAccent"
                    strokeWidth={3}
                  />
                ) : (
                  <PlusIcon
                    className="h-4 w-4 text-primaryAccent"
                    strokeWidth={3}
                  />
                )}
              </button>
            </div>
          </div>

          {/* Expanded Content for Product Details */}
          {expandedRows.includes(index) && (
            <div className="expanded-content gap-4 p-4">
              {/* Common Product Header */}
              <div className="rounded-md border border-gray-200">
                <div
                  style={{
                    backgroundColor: "#E5ECF2",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    gridColumn: "span 6 / span 6",
                  }}
                  className="sticky top-0 z-5 col-span-6 grid grid-cols-6 p-4 text-left text-sm font-semibold text-[#717679]">
                  <div>Product Name</div>
                  <div>SKU</div>
                  <div>Quantity</div>
                  <div>Unit Cost</div>
                  <div>Total Cost</div>
                  <div>Commodity Code</div>
                </div>

                {/* Product Rows */}
                {item.items.map((product, idx) => (
                  <div
                    key={idx}
                    style={{
                      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                      gridColumn: "span 6 / span 6",
                    }}
                    className="col-span-6 grid grid-cols-6 items-center border-b p-4">
                    <div style={{ ...noWrapStyle }}>{product.productName}</div>
                    <div style={{ ...noWrapStyle }}>{product.sku}</div>
                    <div className="flex" style={{ ...noWrapStyle }}>
                      <CustomBadge
                        label={product.quantity.toString()}
                        bgColor="#E1FCEF"
                        textColor="#14804A"
                        textSize="text-sm"
                      />
                    </div>
                    <div style={{ ...noWrapStyle }}>${product.unitCost}</div>
                    <div style={{ ...noWrapStyle }}>
                      $
                      {Number(
                        parseFloat(product.unitCost * product.quantity),
                      ).toFixed(2)}
                    </div>
                    <div style={{ ...noWrapStyle }}>
                      <InputBox
                        type="text"
                        value={product.commodityCode || ""}
                        onChange={(e) => {
                          setBoxDetails((prevDetails) => {
                            const updatedDetails = prevDetails.map((box) => {
                              const updatedItems = box.items.map((item) => {
                                // If the product is the same as the one being updated, update its commodityCode
                                if (
                                  item.productId ===
                                  prevDetails[index].items[idx].productId
                                ) {
                                  return {
                                    ...item,
                                    commodityCode: e.target.value,
                                  };
                                }
                                return item;
                              });
                              return { ...box, items: updatedItems };
                            });
                            return updatedDetails;
                          });
                        }}
                        placeholder="Enter Commodity Code"
                        min="0"
                        parentClasses="w-full"
                        labelClasses="text-gray-700"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default BoxTable;
