import TextField from "#components/utils/TextField";
import validator from "validator";
import { ArrowLeftIcon } from "@heroicons/react/outline";

const ResetPasswordBox = ({
  onSubmit,
  onChange,
  resetPassword,
  tenant,
  values,
}) => {
  const notValid = !validator.isEmail(values.email);
  return (
    <div className="xs:10/12 md:6/12 min-w-xl flex max-w-xl flex-col items-center justify-center space-y-6 rounded-2xl bg-white p-10 sm:w-8/12">
      <div className="text-2xl font-medium text-primaryAccent">
        Reset Password
      </div>
      <div className="text-2xl font-medium text-454A4F">
        {tenant
          ? `Welcome back, team ${tenant.name}`
          : "Welcome to Hopstack Inc."}
      </div>
      <form
        onSubmit={onSubmit}
        className="w-full flex-col items-center justify-center space-y-8"
        noValidate>
        <TextField
          label="Email"
          id="email"
          type="email"
          placeholder=" "
          onChange={onChange}
          value={values.email}
          error={(value) => {
            if (!validator.isEmail(value)) {
              return "Please enter a valid email";
            }
            return "";
          }}
        />
        <div className="flex w-full items-center justify-center">
          <input
            type="submit"
            disabled={notValid}
            className={`${
              notValid ? "opacity-60" : ""
            } inline-flex w-64 cursor-pointer justify-center rounded-md border border-transparent bg-primaryAccent p-4 font-montserrat text-2xl font-normal text-textWhite outline-none ring-0 focus:outline-none focus:ring-0`}
            value="Reset Password"
          />
        </div>
        <div
          onClick={resetPassword}
          className="cursor-pointe flex items-center justify-center gap-2 text-lg font-medium text-primaryAccent">
          <ArrowLeftIcon className="h-6 w-6" />
          Back to log in
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordBox;
