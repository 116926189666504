import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import HeaderWithoutSidebar from "#components/common/HeaderWithoutSidebar";
import LoginBox from "#components/login/LoginBox";
import useLoginLogic from "#hooks/login/useLoginLogic";
import { GET_LOGGED_IN_USER } from "#queries";
import { LOGOUT_USER, SET_ACTIVITY } from "#mutations";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import MultipleSelectActivityForm from "#components/common/MultipleSelectActivityForm";
import { Link } from "react-router-dom";
import SetPasswordBox from "../components/setPassword/SetPasswordBox";
import withSetPasswordLogic from "../components/HOC/withSetPasswordLogic";

const SetPassword = ({ values, onChange, onSubmit, isExpired }) => {
  const logoutQuery = useQuery(LOGOUT_USER);
  const setActivityQuery = useQuery(SET_ACTIVITY);
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);

  if (appState.loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <div className="flex h-screen w-full items-center justify-center bg-primaryAccent">
        {/* Header */}
        <HeaderWithoutSidebar />
        {/*Center Box*/}
        <SetPasswordBox
          values={values}
          onSubmit={onSubmit}
          onChange={onChange}
          tenant={appState.tenant}
          isExpired={isExpired}
        />
      </div>
      <footer className="-mt-8 flex w-full items-center justify-center space-x-5 text-white">
        <div>
          Copyright ©
          <a
            href="http://hopstack.io"
            className="px-2"
            target="_blank"
            rel="noreferrer">
            Hopstack Inc.
          </a>
          {new Date().getFullYear()}
        </div>
        <div></div>
        <div>
          <Link target="_blank" to="/privacyPolicy">
            Privacy Policy
          </Link>
        </div>
        <div>
          <Link target="_blank" to="/termsOfUse">
            Terms of Use
          </Link>
        </div>
        <div>
          <Link target="_blank" to="/disclaimer">
            Disclaimer
          </Link>
        </div>
      </footer>
    </>
  );
};

export default withSetPasswordLogic(SetPassword);
