import { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import HeaderWithoutSidebar from "#components/common/HeaderWithoutSidebar";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { Link, useParams } from "react-router-dom";
import { VERIFY_RESET_PASSWORD_TOKEN } from "#queries";
import useResetPasswordLogc from "#hooks/resetPassword/useResetPasswordLogic";
import NewPasswordBox from "#components/resetPassword/NewPasswordBox";
import { useState } from "react";

const ResetPassword = () => {
  const verifyResetPasswordToken = useQuery(VERIFY_RESET_PASSWORD_TOKEN);
  const { token } = useParams();
  const appState = useContext(AppStateContext);
  const { resetPassword, redirectToLogin } = useResetPasswordLogc();
  const [isExpired, setExpired] = useState(false);

  useEffect(() => {
    if (verifyResetPasswordToken.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (verifyResetPasswordToken.error) {
      setExpired(true);
    }
  }, [
    verifyResetPasswordToken.data,
    verifyResetPasswordToken.error,
    verifyResetPasswordToken.loading,
  ]);

  useEffect(() => {
    verifyResetPasswordToken.fetchData({ token });
  }, []);

  if (appState.loading) {
    return <LoadingIndicator />;
  }

  if (redirectToLogin) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="flex h-screen w-full items-center justify-center bg-primaryAccent">
        {/* Header */}
        <HeaderWithoutSidebar />
        {/*Center Box*/}
        <NewPasswordBox
          values={resetPassword.values}
          onSubmit={resetPassword.onSubmit}
          onChange={resetPassword.onChange}
          isExpired={isExpired}
        />
      </div>
      <footer className="-mt-8 flex w-full items-center justify-center space-x-5 text-white">
        <div>
          Copyright ©
          <a
            href="http://hopstack.io"
            className="px-2"
            target="_blank"
            rel="noreferrer">
            Hopstack Inc.
          </a>
          {new Date().getFullYear()}
        </div>
        <div></div>
        <div>
          <Link target="_blank" to="/privacyPolicy">
            Privacy Policy
          </Link>
        </div>
        <div>
          <Link target="_blank" to="/termsOfUse">
            Terms of Use
          </Link>
        </div>
        <div>
          <Link target="_blank" to="/disclaimer">
            Disclaimer
          </Link>
        </div>
      </footer>
    </>
  );
};

export default ResetPassword;
