import React from "react";
import { AppStateContext } from "#contexts/appState";
import { usePresignedURL } from "#hooks/usePresignedURL";
import { useState, useEffect, useContext } from "react";

const SupportedTypes = {
  image: ["image/jpeg", "image/png"],
};

/**
 *
 * @param {string}    props.id id of the component
 * @param {function}  props.onError error handler callback
 * @param {string}    props.type this components handles file selection based on this prop
 * @param {boolean}   props.alerts a flag to render alerts based on errors thrown from this component
 * @param {function}  props.customKeyGenerator a custom implementation to generate filenames
 * @param {function}  props.onSuccess success handler callback
 * @param {React.PureComponent} props.children A react component passed by parent to render input with custom styles
 * @returns {React.PureComponent}
 */

const ALERT_TIMEOUT_MS = 5000;

export const FileUpload = ({
  id,
  onError,
  type,
  alerts,
  customKeyGenerator,
  onSuccess,
  children,
}) => {
  const appState = useContext(AppStateContext);
  const { presignedURL, getPresignedURL } = usePresignedURL();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileKey, setFileKey] = useState(null);
  const [filename, setFilename] = useState(null);
  const [success, setSuccess] = useState(null);
  const [failed, setFailed] = useState(null);

  useEffect(() => {
    if (presignedURL) {
      fetch(presignedURL, {
        method: "PUT",
        body: selectedFile,
        redirect: "follow",
        headers: new Headers({
          "Content-Type": "",
        }),
      })
        .then((_) => {
          alerts &&
            appState.setAlert(
              "Uploaded successfully",
              "success",
              ALERT_TIMEOUT_MS,
            );
          onSuccess({
            target: { name: id, value: fileKey },
          });
          setSuccess(true);
          setFailed(false);
        })
        .catch((error) => {
          appState.setAlert("Image Upload failed. Please Try again.");

          setSuccess(false);
          setFailed(true);
        });
    }
  }, [presignedURL]);

  const handleFileUpload = (e) => {
    if (e.target.files.length !== 1) {
      alerts &&
        appState.setAlert("Please select 1 image", "error", ALERT_TIMEOUT_MS);
      onError("Please select 1 image");
      return;
    }

    const file = e.target.files[0];

    if (!SupportedTypes[type]) {
      throw new Error("Unsupported file type");
    }
    const isValid =
      SupportedTypes[type].filter((allowedType) => allowedType === file.type)
        .length > 0;
    if (!isValid) {
      alerts &&
        appState.setAlert(
          "Invalid image type. Supported image types JPEG, PNG",
          "error",
          ALERT_TIMEOUT_MS,
        );
      return;
    }

    const key = customKeyGenerator(file);
    setFileKey(key);
    getPresignedURL({ key, method: "PUT" });
    setSelectedFile(file);
    setFilename(file.name);
  };

  return children ? (
    children(handleFileUpload, filename, success, failed)
  ) : (
    <input type={"file"} onChange={handleFileUpload} />
  );
};
