import { useState, useEffect, useContext } from "react";
import { AppStateContext } from "#contexts/appState";
import { ArrowRightIcon, XIcon } from "@heroicons/react/solid";
import AutocompleteMultiSelectDropdown from "#components/utils/AutocompleteMultiSelectDropdown";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { DatePicker } from "antd";
import CronExpressionGenerator from "#components/common/Scheduler";
import { ALERT_VISIBILITY_IN_3000_MS } from "#components/HOC/withReportsLogic";

import dayjs from "dayjs";
import "dayjs/locale/en";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import moment from "moment-timezone";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

const CreateScheduledReport = ({
  selectedStep,
  setSelectedStep,
  steps,
  setSteps,
  selectedReport,
  setOpenReportMode,
  showSubmit,
  setShowSubmit,
  onClose,
  setSelectedReport,
  onChange,
  onChangeDropdown,
  warehouses,
  customers,
  reportsMetadata,
  onChangeDropdownMulti,
  onChangeFrequency,
  parseCronExpression,
  onSubmit,
  setReportType,
  allUsers,
}) => {
  const appState = useContext(AppStateContext);

  const setNextStep = () => {
    // Step 01 Validation
    if (
      steps &&
      steps.findIndex((step) => step["status"] === "current") === 0
    ) {
      if (
        !selectedReport.module ||
        !selectedReport.selectedVariables ||
        selectedReport.selectedVariables?.length === 0
      ) {
        return appState.setAlert(
          `Please select report type`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (
        !selectedReport.selectedVariables ||
        selectedReport.selectedVariables?.length === 0
      ) {
        return appState.setAlert(
          `Please select fields`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      }
    }

    // Step 02 Validation
    if (
      steps &&
      steps.findIndex((step) => step["status"] === "current") === 1
    ) {
      if (!selectedReport.warehouses) {
        return appState.setAlert(
          `Please select warehouse`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (!selectedReport.warehouses || !selectedReport.customers) {
        return appState.setAlert(
          `Please select client name`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      }
    }

    // Step 02 Validation
    if (
      steps &&
      steps.findIndex((step) => step["status"] === "current") === 2
    ) {
      if (
        !selectedReport?.scheduleStartDate ||
        selectedReport?.scheduleStartDate === ""
      ) {
        return appState.setAlert(
          `Please select starting from date`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else if (!selectedReport.localCron || selectedReport.localCron === "") {
        return appState.setAlert(
          `Please set scheduling details`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      }
    }

    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const nextStep = copiedSteps.find((_, idx) => idx === foundIdx + 1);
    if (!nextStep.visible) {
      foundIdx += 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
      setShowSubmit(foundIdx === copiedSteps.length - 2);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const prevStep = copiedSteps.find((_, idx) => idx === foundIdx - 1);
    if (!prevStep.visible) {
      foundIdx -= 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setShowSubmit(false);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  const onSubmitForm = () => {
    // Step 03 Validation
    if (
      steps &&
      steps.findIndex((step) => step["status"] === "current") === 3
    ) {
      if (
        !selectedReport?.recipients ||
        selectedReport?.recipients === "" ||
        selectedReport?.recipients?.length === 0
      ) {
        return appState.setAlert(
          `Please select recipient users`,
          "error",
          ALERT_VISIBILITY_IN_3000_MS,
        );
      } else {
        onSubmit();
      }
    } else {
      onSubmit();
    }
  };

  const onCancelForm = () => {
    setOpenReportMode(null);
    setReportType(null);
    onClose();
  };

  return (
    <div className="relative flex h-full w-full flex-col overflow-auto font-inter">
      <header className="space-y-2 py-4">
        <Navbar steps={steps} />
      </header>

      <main className="my-2 h-full grow overflow-auto">
        {selectedStep?.id === "01" && (
          <BasicInfo
            onChange={onChange}
            selectedReport={selectedReport}
            reportsMetadata={reportsMetadata}
            onChangeDropdown={onChangeDropdown}
            onChangeDropdownMulti={onChangeDropdownMulti}
          />
        )}
        {selectedStep?.id === "02" && (
          <FilterInfo
            onChange={onChange}
            selectedReport={selectedReport}
            reportsMetadata={reportsMetadata}
            onChangeDropdown={onChangeDropdown}
            onChangeDropdownMulti={onChangeDropdownMulti}
            warehouses={warehouses}
            customers={customers}
          />
        )}
        {selectedStep?.id === "03" && (
          <SchedulingInfo
            selectedReport={selectedReport}
            onChangeFrequency={onChangeFrequency}
            onChange={onChange}
            setSelectedReport={setSelectedReport}
            parseCronExpression={parseCronExpression}
            isEditView={false}
          />
        )}
        {selectedStep?.id === "04" && (
          <RecipientsInfo
            onChange={onChange}
            selectedReport={selectedReport}
            customers={customers}
            allUsers={allUsers}
          />
        )}
      </main>

      <footer className="flex h-16 items-center justify-end">
        <FormActions
          steps={steps}
          selectedStep={selectedStep}
          setPrevStep={setPrevStep}
          setNextStep={setNextStep}
          selectedReport={selectedReport}
          showSubmit={showSubmit}
          onSubmit={onSubmitForm}
          onCancel={() => {
            onCancelForm();
          }}
        />
      </footer>
    </div>
  );
};

const Navbar = ({ steps }) => {
  const visibleSteps = steps.filter((item) => item.visible);
  return (
    <nav className="flex w-524 space-x-2">
      {visibleSteps.map((step, index) => {
        const activeStep = step.status === "current";
        return (
          <div className="flex flex-col items-center" key={index}>
            <span
              className={`mb-1 h-2.5 w-32 rounded-md ${
                activeStep ? "bg-blueBackground" : "bg-lightBlueBackground"
              }`}></span>
            <p
              className={`text-sm decoration-solid ${
                activeStep ? "text-textDarkGray" : "text-textGray"
              }`}>
              {step.name}
            </p>
          </div>
        );
      })}
    </nav>
  );
};

const FormActions = ({
  selectedReport,
  steps,
  selectedStep,
  setPrevStep,
  setNextStep,
  showSubmit,
  onSubmit,
}) => {
  return (
    <>
      <div className="flex space-x-4">
        <button
          onClick={setPrevStep}
          className={`cursor-pointer rounded-md border px-6 py-3 text-base font-semibold ${
            selectedStep?.id === steps[0]?.id
              ? "cursor-not-allowed border-mediumGray text-mediumGray"
              : "cursor-pointer border-primaryAccent text-primaryAccent"
          }`}>
          Previous
        </button>
        {!showSubmit && (
          <button
            onClick={setNextStep}
            className={`rounded-md px-6 py-3 text-base font-semibold ${
              selectedStep?.id === steps[steps.length - 1]?.id
                ? "cursor-not-allowed border border-mediumGray text-mediumGray"
                : "cursor-pointer bg-primaryAccent text-white"
            }`}>
            Next
          </button>
        )}
        {showSubmit && (
          <button
            onClick={onSubmit}
            className={`cursor-pointer rounded-md px-6 py-3 text-base font-semibold ${
              true
                ? "cursor-pointer bg-primaryAccent text-white"
                : "cursor-not-allowed border border-mediumGray text-mediumGray"
            }`}>
            {selectedReport?.id ? "Update" : "Create"}
          </button>
        )}
      </div>
    </>
  );
};

const BasicInfo = ({
  onChange,
  selectedReport,
  reportsMetadata,
  onChangeDropdown,
  onChangeDropdownMulti,
}) => {
  useEffect(() => {
    if (
      selectedReport &&
      selectedReport?.module &&
      selectedReport?.selectedVariables
    ) {
      const defaultVariable = reportsMetadata?.modules
        ?.find((module) => module.name === selectedReport?.module)
        .variables.find((variable) => variable.default);
      const containsDefault = selectedReport?.selectedVariables?.includes(
        defaultVariable?.name,
      );
      if (!containsDefault) {
        onChangeDropdownMulti("selectedVariables", defaultVariable?.name);
      }
    }
  }, [selectedReport]);

  return (
    <div>
      <div className="mb-4">
        <h1 className="text-lg font-semibold">Report Type Selection</h1>
        <p className="mt-1 text-base font-light text-gray-400">
          Choose the type of report you want to schedule
        </p>
      </div>

      <div className="mb-8 w-538">
        <label className="mb-1 text-sm text-gray-500" htmlFor="reportType">
          Report Type
        </label>
        <AutocompleteSingleSelectDropdown
          key={"name"}
          options={reportsMetadata?.modules}
          labelKey={"name"}
          valueKey={"name"}
          onChange={(value) => {
            onChangeDropdown("module", value);
          }}
          value={selectedReport?.module}
          placeholder="Select Report Type"
          showCheckedIndicator={false}
        />
      </div>

      {selectedReport?.module && (
        <div className="mb-4 px-2">
          <div className="grid grid-cols-2 gap-2">
            <div className="">
              <div className="w-full pb-4 text-center font-montserrat text-lg font-medium text-darkText">
                AVAILABLE FIELDS
              </div>
              <div className="h-80 w-full overflow-auto p-2">
                {reportsMetadata.modules
                  .find((module) => module.name === selectedReport.module)
                  .variables.filter(
                    (variable) =>
                      !selectedReport.selectedVariables.includes(
                        variable.name,
                      ) && !variable.default,
                  )
                  .map((item, index) => (
                    <div
                      className="mb-3 flex cursor-pointer items-center rounded-md border border-gray-200 p-2 hover:bg-gray-200"
                      key={`available-${index}`}
                      onClick={() => {
                        onChangeDropdownMulti("selectedVariables", item.name);
                      }}>
                      <span className="text-md pb-1 font-montserrat font-medium text-darkText">
                        {item.name}
                      </span>
                      <span className="ml-auto">
                        <ArrowRightIcon className="h-5 w-5 font-normal text-arrowColor" />
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="">
              <div className="w-full pb-4 text-center font-montserrat text-lg font-medium text-darkText">
                SELECTED FIELDS
              </div>
              <div className="h-80 w-full overflow-auto p-2">
                {reportsMetadata.modules
                  .find((module) => module.name === selectedReport.module)
                  .variables.sort((a, b) => (a.default ? -1 : 1))
                  .filter(
                    (variable) =>
                      selectedReport.selectedVariables.includes(
                        variable.name,
                      ) || variable.default,
                  )
                  .map((item, index) => (
                    <div
                      className={`mb-3 flex items-center rounded-md border border-gray-200 p-2 ${item.default ? "bg-gray-200" : "hover:bg-gray-200"}`}
                      key={`selected-${index}`}>
                      <span className="text-md pb-1 font-montserrat font-medium text-darkText">
                        {item.name} {item.default ? " *" : ""}
                      </span>
                      {!item.default && (
                        <span className="ml-auto">
                          <XIcon
                            className={`h-5 w-5 font-normal ${!item.default ? "cursor-pointer" : ""}`}
                            onClick={() => {
                              if (!item.default) {
                                onChangeDropdownMulti(
                                  "selectedVariables",
                                  item.name,
                                );
                              }
                            }}
                          />
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 flex pb-4 font-montserrat text-sm font-medium italic text-darkText">
            <span className="ml-auto">* mandatory selection</span>
          </div>
        </div>
      )}
    </div>
  );
};

const FilterInfo = ({
  onChange,
  selectedReport,
  reportsMetadata,
  warehouses,
  customers,
}) => {
  return (
    <div>
      <div className="mb-4">
        <h1 className="text-lg font-semibold">Filter</h1>
        <p className="mt-1 text-base font-light text-gray-400">
          Select specific warehouses and clients to filter your report criteria
        </p>
      </div>

      <div className="mb-4 w-538">
        <label className="mb-2 text-sm text-gray-500" htmlFor="warehouseName">
          Warehouse Name
          {selectedReport?.warehouses && (
            <span className="ml-1 rounded-sm bg-white bg-opacity-60 pb-0 pl-1 pr-1 pt-0 text-xs font-bold text-primaryAccent">
              {"[ " +
                selectedReport?.warehouses?.length +
                " Warehouses Selected ]"}
            </span>
          )}
        </label>
        <AutocompleteMultiSelectDropdown
          key={"warehouseName"}
          options={warehouses}
          labelKey={"name"}
          valueKey={"id"}
          onChange={(value) => {
            onChange({
              target: {
                name: "warehouses",
                value: value && value.length !== 0 ? value : null,
              },
            });
          }}
          values={selectedReport?.warehouses}
          placeholder="Select Warehouses"
          multiSelect={true}
        />
      </div>
      <div className="mb-4 w-538">
        <label className="mb-2 text-sm text-gray-500" htmlFor="clientName">
          Client Name
          {selectedReport?.customers && (
            <span className="ml-1 rounded-sm bg-white bg-opacity-60 pb-0 pl-1 pr-1 pt-0 text-xs font-bold text-primaryAccent">
              {"[ " + selectedReport?.customers?.length + " Clients Selected ]"}
            </span>
          )}
        </label>
        <AutocompleteMultiSelectDropdown
          key={"clientName"}
          options={customers}
          labelKey={"name"}
          valueKey={"id"}
          onChange={(value) => {
            onChange({
              target: {
                name: "customers",
                value: value && value.length !== 0 ? value : null,
              },
            });
          }}
          values={selectedReport?.customers}
          placeholder="Select Client Name"
          multiSelect={true}
        />
      </div>
    </div>
  );
};

const SchedulingInfo = ({
  selectedReport,
  onChangeFrequency,
  setSelectedReport,
  onChange,
  parseCronExpression,
  showHeader,
  isEditView,
  isScheduleChanged,
}) => {
  const [timeZoneOffset, setTimeZoneOffset] = useState(moment().format("Z"));
  const [timeZoneIdentifier, setTimeZoneIdentifier] = useState(
    moment.tz.guess(),
  );
  const [resetTime, setResetTime] = useState(false);
  const isValidFormat = moment(
    selectedReport?.scheduleStartDate,
    "DD-MM-YYYY",
    true,
  ).isValid();
  const FormattedDate = isValidFormat
    ? selectedReport?.scheduleStartDate
    : moment(selectedReport?.scheduleStartDate).format("DD-MM-YYYY");
  const scheduleStartDate = selectedReport?.scheduleStartDate
    ? [
        dayjs(convertedDateFormat(FormattedDate + "T00:00:00.000Z", "startOf")),
        dayjs(convertedDateFormat(FormattedDate + "T23:59:59.999Z", "endOf")),
      ]
    : [];
  return (
    <div className="pl-2">
      <div className="mb-4">
        <h1 className="text-lg font-semibold">Scheduling Details</h1>
        <p className="mt-1 text-base font-light text-gray-400">
          This section outlines how often your report runs and when it's
          scheduled next
        </p>
      </div>
      {timeZoneOffset && timeZoneIdentifier && (
        <div className="mb-4 w-538">
          <div className="flex flex-row items-center space-x-6">
            <div className="flex-1">
              <label
                htmlFor="scheduleStartDate"
                className="mb-1 text-sm text-gray-500">
                Time Zone :{" "}
                <span className="font-medium">{`${timeZoneIdentifier} (GMT${timeZoneOffset})`}</span>
              </label>
            </div>
          </div>
        </div>
      )}
      <div className="mb-4 w-538">
        <div className="flex flex-row items-center space-x-6">
          <div className="flex-1">
            <label
              htmlFor="scheduleStartDate"
              className="mb-1 text-sm text-gray-500">
              Starting From
            </label>
            <div className="mt-1 space-y-4">
              <DatePicker
                placeholder="Choose Date"
                onChange={(dates, value) => {
                  if (isEditView) {
                    isScheduleChanged(true);
                  }
                  onChange({
                    target: {
                      name: "scheduleStartDate",
                      value,
                    },
                  });
                  setResetTime(true);
                }}
                value={scheduleStartDate}
                format="DD-MM-YYYY"
                disabledDate={(current) =>
                  current.isBefore(moment().subtract(1, "day"))
                }
                className="w-[24rem] rounded border border-borderGray p-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4 w-538">
        <label className="mb-2 text-sm text-gray-500" htmlFor="frequency">
          Frequency
        </label>

        <div className="mt-1 space-y-4">
          <CronExpressionGenerator
            selectedReport={selectedReport}
            onChangeFrequency={onChangeFrequency}
            setSelectedReport={setSelectedReport}
            onChange={onChange}
            parseCronExpression={parseCronExpression}
            scheduleStartDate={selectedReport?.scheduleStartDate}
            isEditView={isEditView}
            resetTime={resetTime}
            setResetTime={setResetTime}
            isScheduleChanged={isScheduleChanged}
          />
        </div>
      </div>
    </div>
  );
};

const RecipientsInfo = ({ onChange, selectedReport, allUsers }) => {
  return (
    <div>
      <div className="mb-4">
        <h1 className="font-inter text-lg font-semibold">Recipients</h1>
        <p className="mt-1 text-base font-light text-gray-400">
          For now, scheduled reports can only be sent to your own email. We'll
          soon enable adding other recipients
        </p>
      </div>

      <div className="mb-4 w-538">
        <label className="mb-2 text-sm text-gray-500" htmlFor="Users">
          Users
          {selectedReport?.recipients && (
            <span className="ml-1 rounded-sm bg-white bg-opacity-60 pb-0 pl-1 pr-1 pt-0 text-xs font-bold text-primaryAccent">
              {"[ " + selectedReport?.recipients?.length + " Users Selected ]"}
            </span>
          )}
        </label>
        <AutocompleteMultiSelectDropdown
          key={"users"}
          name="recipients"
          options={allUsers}
          labelKey={"name"}
          valueKey={"email"}
          onChange={(values) => {
            const value =
              values && values.length !== 0
                ? values.map((email) => {
                    const findUser =
                      allUsers && allUsers.length !== 0
                        ? allUsers.filter((user) => user["email"] === email)
                        : [];
                    if (findUser.length !== 0 && email) {
                      return {
                        email: email,
                        name: findUser[0]["name"],
                      };
                    }
                  })
                : [];
            onChange({
              target: {
                name: "recipients",
                value,
              },
            });
          }}
          values={
            selectedReport?.recipients &&
            selectedReport?.recipients?.length !== 0
              ? selectedReport?.recipients?.map((user) => user["email"])
              : []
          }
          placeholder="Select Users"
          multiSelect={true}
        />
      </div>
    </div>
  );
};

const convertedDateFormat = (date, day) => {
  const gmtTimezone = "GMT";
  const momentDate = moment.tz(date, "DD-MM-YYYY", gmtTimezone);
  if (day === "startOf") {
    momentDate.startOf("day").toISOString();
  } else if (day === "endOf") {
    momentDate.endOf("day").toISOString();
  }
  return momentDate;
};

export default CreateScheduledReport;
