// react imports
import { useState, useEffect, useContext } from "react";
// icons import
import { SearchIcon, XIcon, PlusIcon } from "@heroicons/react/solid";
// context import
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
// components import
import Modal from "#components/utils/Modal";
import SortDropdown from "#components/common/SortDropdown";
import Accordian from "#components/utils/Accordian";
import AddButton from "#components/utils/AddButton";
import TagsList from "#components/catalogs/TagsList";
import TagForm from "#components/catalogs/TagForm";
// hooks import
import { useQuery } from "#hooks/useQuery";
// queries, mutations import
import { GET_TAGS, GET_PRODUCTS } from "../../queries";
import { SAVE_TAG, DELETE_TAG } from "../../mutations";
// other imports
import _ from "lodash";
import PropTypes from "prop-types";

/**
 * ManageTags component for handling tags.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.writable - Specifies if the tags are writable.
 * @param {boolean} [props.editable=false] - Specifies if the tags are editable.
 * @param {boolean} [props.deletable=false] - Specifies if the tags are deletable.
 * @param {boolean} [props.selectable=false] - Specifies if the tags are selectable.
 * @param {Function} props.negativeAction - The action to perform when a negative action is taken.
 * @param {string} props.positiveText - The text to display for the positive action.
 * @param {Function} props.positiveAction - The action to perform when a positive action is taken. It is called with the selectedTags as an argument.
 * @param {Array} [props.selectedTags] - The selected tags (optional).
 * @param {string} [props.selectedCustomers] - The selected customer ids (optional). If passed, tags related to this client only be shown.
 * @returns {ReactNode} The rendered ManageTags component.
 */
const ManageTags = ({
  writable,
  editable = true,
  deletable = true,
  selectable = false,
  negativeAction,
  positiveText,
  positiveAction,
  selectedTags: providedSelectedTags,
  selectedCustomers,
}) => {
  if (!writable) {
    editable = false;
    deletable = false;
  }

  const appState = useContext(AppStateContext);
  const auth = useContext(AuthContext);

  const tagsQuery = useQuery(GET_TAGS);
  const productsQuery = useQuery(GET_PRODUCTS);
  const saveTagQuery = useQuery(SAVE_TAG);
  const deleteTagQuery = useQuery(DELETE_TAG);
  const authorizedCustomers = auth.user?.customersList ?? [];

  const [tagsFilter, setTagsFilter] = useState(() => {
    const filter = {};
    filter.customers =
      selectedCustomers?.length > 0
        ? selectedCustomers
        : authorizedCustomers.map((customer) => customer.id);
    return filter;
  });
  const [tags, setTags] = useState([]);
  const [totalTags, setTotalTags] = useState(0);
  const [selectedTags, setSelectedTags] = useState(
    providedSelectedTags ? [...providedSelectedTags] : [],
  );
  const [showAddTag, setShowAddTag] = useState(false);
  const [tagFormData, setTagFormData] = useState(null);
  const [tagBeingEdited, setTagBeingEdited] = useState(false);
  const [deletingTag, setDeletingTag] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortProperty, setSortProperty] = useState("-createdAt");

  /**
   * Perform side effects based on the state of `tagsQuery`.
   * @param {Object} tagsQuery - The object containing the query state.
   */
  useEffect(() => {
    if (tagsQuery.data && tagsQuery.data.tags) {
      setTags([...tagsQuery.data.tags.entities]);
      setTotalTags(tagsQuery.data.tags.total);
      if (searchKeyword) {
        const matchingTag = tagsQuery.data.tags.entities.find(
          (tag) => tag.name.toLowerCase() === searchKeyword.toLowerCase(),
        );
        setShowAddTag(!matchingTag);
      } else {
        setShowAddTag(false);
      }
    }

    if (tagsQuery.error) {
      appState.setAlert(tagsQuery.error.message, "error", 5000);
    }
  }, [tagsQuery.loading, tagsQuery.data, tagsQuery.error]);

  /**
   * Perform side effects based on the state of `productsQuery`. Used to fetch total associated products for a tag
   * @param {Object} productsQuery - The object containing the query state.
   */
  useEffect(() => {
    if (productsQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (productsQuery.data) {
      const totalAssociatedProducts = productsQuery.data.products.total;
      if (tagBeingEdited) {
        updateTag(totalAssociatedProducts);
      } else if (deletingTag) {
        deleteTag(deletingTag, totalAssociatedProducts);
      }
    }

    if (productsQuery.error) {
      appState.setAlert(productsQuery.error.message, "error", 5000);
    }
  }, [productsQuery.loading, productsQuery.data, productsQuery.error]);

  /**
   * Perform side effects based on the state of `saveTagQuery`.
   * @param {Object} saveTagQuery - The object containing the query state.
   */
  useEffect(() => {
    if (saveTagQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (saveTagQuery.data) {
      appState.setAlert(saveTagQuery.data.saveTag.message, "success", 5000);
      appState.hideConfirmation();
      setTagBeingEdited(false);
      setTagFormData(null);
      fetchTags();
    }

    if (saveTagQuery.error) {
      appState.setAlert(saveTagQuery.error.message, "error", 5000);
      appState.hideConfirmation();
    }
  }, [saveTagQuery.loading, saveTagQuery.data, saveTagQuery.error]);

  /**
   * Perform side effects based on the state of `deleteTagQuery`.
   * @param {Object} deleteTagQuery - The object containing the query state.
   */
  useEffect(() => {
    if (deleteTagQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (deleteTagQuery.data) {
      appState.setAlert(deleteTagQuery.data.deleteTag.message, "success", 5000);
      appState.hideConfirmation();
      setDeletingTag(null);
      fetchTags();
    }

    if (deleteTagQuery.error) {
      appState.setAlert(deleteTagQuery.error.message, "error", 5000);
      appState.hideConfirmation();
      setDeletingTag(null);
    }
  }, [deleteTagQuery.loading, deleteTagQuery.data, deleteTagQuery.error]);

  /**
   * Perform side effects based on the state of `searchKeyword` and `sortProperty`.
   * @param {string} searchKeyword
   * @param {string} sortProperty
   */
  useEffect(() => {
    if (searchKeyword) {
      return debouncedTagSearch(searchKeyword);
    }
    fetchTags();
  }, [searchKeyword, sortProperty]);

  const fetchTags = () => {
    tagsQuery.fetchData({
      perPage: 50,
      pageNumber: 1,
      filters: {
        ...tagsFilter,
        keyword: searchKeyword,
      },
      sort: sortProperty,
    });
  };

  const isPresentInSelectedTags = (tag) => {
    return selectedTags.some((selectedTag) => selectedTag.id === tag.id);
  };

  const handleTagSelection = (tag, remove = false) => {
    if (!remove && !isPresentInSelectedTags(tag)) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(
        selectedTags.filter((selectedTag) => selectedTag.id !== tag.id),
      );
    }
  };

  const saveTag = () => {
    saveTagQuery.fetchData(tagFormData);
  };
  const updateTag = (totalAssociatedProducts = null) => {
    if (totalAssociatedProducts === null) {
      return productsQuery.fetchData({
        filters: {
          tags: [tagFormData],
        },
      });
    }
    let alertMsg = "Are you sure you want to update this tag?";
    if (totalAssociatedProducts > 0) {
      alertMsg += ` this tag will be modified for ${totalAssociatedProducts} associated products`;
    }
    appState.showConfirmation(
      "Confirm",
      alertMsg,
      () => {
        saveTagQuery.fetchData(tagFormData);
      },
      appState.hideConfirmation,
    );
  };
  const deleteTag = (tag, totalAssociatedProducts = null) => {
    setDeletingTag(tag);
    if (totalAssociatedProducts === null) {
      return productsQuery.fetchData({
        filters: {
          tags: [tag],
        },
      });
    }
    let alertMsg = "Are you sure you want to delete this tag?";
    if (totalAssociatedProducts > 0) {
      alertMsg += ` this tag will be removed from ${totalAssociatedProducts} associated products`;
    }
    appState.showConfirmation(
      "Confirm",
      alertMsg,
      () => {
        deleteTagQuery.fetchData({ id: tag.id });
      },
      appState.hideConfirmation,
    );
  };

  const onChangeTagSearch = (searchKeyword) => {
    tagsQuery.fetchData({
      perPage: 50,
      pageNumber: 1,
      filters: {
        ...tagsFilter,
        keyword: searchKeyword,
      },
      sort: sortProperty,
    });
  };
  const debouncedTagSearch = _.debounce(onChangeTagSearch, 500);

  const clearTagSearch = () => {
    setSearchKeyword("");
    setShowAddTag(false);
  };

  return (
    <div>
      <Modal
        title={`Manage Tags`}
        negativeAction={negativeAction}
        positiveAction={() => positiveAction(selectedTags)}
        positiveText={positiveText}>
        <div>
          {/* Search & Sorting */}
          <div className="flex">
            <div className="flex-1">
              <div className="relative mb-4 mt-1 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-7 w-7 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="keyword"
                  id="keyword"
                  className="block w-full rounded-md border-gray-300 p-2 pl-12 text-lg focus:outline-none"
                  placeholder="Search"
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  value={searchKeyword}
                />
                {searchKeyword && (
                  <div
                    className="absolute inset-y-0 right-2 flex cursor-pointer items-center pl-3"
                    onClick={clearTagSearch}>
                    <XIcon
                      className="h-7 w-7 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="m-1 ml-2">
              <SortDropdown
                setSort={(key) => {
                  setSortProperty(sortProperty === key ? `-${key}` : key);
                }}
                sort={sortProperty}
                choices={[
                  {
                    name: "Added Date",
                    value: "createdAt",
                  },
                  {
                    name: "Name",
                    value: "name",
                  },
                ]}
                showSortBy={false}
              />
            </div>
          </div>

          {/* Show add tag when search keyword is not having exact match in the tags result. */}
          {writable && showAddTag && (
            <div className="mb-5">
              <AddButton
                disabled={false}
                text={`Add "${searchKeyword}"`}
                onClick={() =>
                  setTagFormData({ name: searchKeyword, customers: [] })
                }
                icon={PlusIcon}
              />
            </div>
          )}

          {/* Selected Tags */}
          {selectedTags.length > 0 && (
            <Accordian
              title={`Selected (${selectedTags.length})`}
              style={{ maxHeight: "13rem" }}>
              <TagsList
                tags={selectedTags}
                editable={false}
                deletable={false}
                selectable={selectable}
                handleTagSelection={handleTagSelection}
                isPresentInSelectedTags={isPresentInSelectedTags}
              />
            </Accordian>
          )}

          {/* Available Tags */}
          <Accordian
            title={`Available (${totalTags})`}
            isActive={true}
            style={{ maxHeight: "13rem" }}>
            {tags.length > 0 ? (
              <TagsList
                tags={tags}
                editable={editable}
                deletable={deletable}
                selectable={selectable}
                setTagFormData={setTagFormData}
                setTagBeingEdited={setTagBeingEdited}
                updateTag={() => updateTag()}
                deleteTag={deleteTag}
                handleTagSelection={handleTagSelection}
                isPresentInSelectedTags={isPresentInSelectedTags}
              />
            ) : (
              <div className="text-md py-4 text-center font-semibold text-gray-500">
                No Tags Found
              </div>
            )}
          </Accordian>
        </div>
      </Modal>

      {tagFormData && (
        <TagForm
          tagFormData={tagFormData}
          tagBeingEdited={tagBeingEdited}
          customers={authorizedCustomers}
          setTagFormData={setTagFormData}
          saveTag={saveTag}
          updateTag={updateTag}
        />
      )}
    </div>
  );
};

ManageTags.propTypes = {
  writable: PropTypes.bool.isRequired,
  selectable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  negativeAction: PropTypes.func,
  positiveText: PropTypes.string,
  positiveAction: PropTypes.func,
  selectedTags: PropTypes.array,
  selectedCustomers: PropTypes.array,
};

export default ManageTags;
