import { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { GET_TAGS } from "#queries";
import { SAVE_TAG, DELETE_TAG, SAVE_PRODUCT } from "#mutations";
import { useQuery } from "../../hooks/useQuery";
import ModalV3 from "#components/utils/ModalV3";
import PopoverOnTableAction from "#components/utils/PopoverOnTableAction";
import ReactTooltip from "react-tooltip";
import noFilterIcon from "#static/images/nofilter.png";
import {
  XIcon,
  ExclamationIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  ChevronDownIcon,
  DotsVerticalIcon,
  TrashIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import { ALERT_TYPES } from "../common/ContainerWithLoaderAndAlert";
import Checkbox from "#components/utils/Checkbox";
import { pluralize } from "../../utils/helper-functions";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";

const SEARCH_DEBOUNCE_TIME_IN_MS = 500;

const CatalogManageTags = ({
  product: incomingProduct,
  setShowAddTags,
  showManageTags,
  setShowManageTags,
  loadFirstTimeData,
  fetchProductForTags,
}) => {
  const [product, setProduct] = useState(incomingProduct);
  const [componentAlert, setComponentAlert] = useState(null);
  const [addNewTag, setAddNewTag] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [page, setPage] = useState(1);
  const [showAssignedTags, setShowAssignedTags] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalTags, setTotalTags] = useState(0);
  const tagsQuery = useQuery(GET_TAGS);
  const saveTagQuery = useQuery(SAVE_TAG);
  const deleteTagQuery = useQuery(DELETE_TAG);
  const saveProductQuery = useQuery(SAVE_PRODUCT);
  const appState = useContext(AppStateContext);

  useEffect(() => {
    setProduct(incomingProduct);
  }, [incomingProduct]);

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    let timeOut;
    if (componentAlert) {
      timeOut = setTimeout(() => {
        setComponentAlert(null);
      }, 5000);
    }
    return () => clearTimeout(timeOut);
  }, [componentAlert]);

  const fetchTags = async (isNewSearch = false, keyword = searchKeyword) => {
    if (isNewSearch) {
      setPage(1);
      setTags([]);
      setHasMore(true);
      setIsLoading(true);
    } else {
      setIsLoadMoreLoading(true);
    }

    const response = await tagsQuery.fetchData({
      perPage: 25,
      pageNumber: isNewSearch ? 1 : page,
      filters: {
        keyword: keyword,
      },
    });

    if (isNewSearch) {
      setIsLoading(false);
    } else {
      setIsLoadMoreLoading(false);
    }

    if (response.data) {
      setTags((prevTags) =>
        isNewSearch
          ? response.data.tags.entities
          : [...prevTags, ...response.data.tags.entities],
      );
      setTotalTags(response.data.tags?.total || 0);
      setPage((prevPage) => prevPage + 1);
      if (response.data.tags.entities.length < 25) {
        setHasMore(false);
      }
    }
  };

  const handleToggle = () => {
    setShowAssignedTags(!showAssignedTags);
  };

  useEffect(() => {
    if (saveProductQuery.loading) appState.setLoading();
    else appState.removeLoading();
  }, [saveProductQuery.loading]);

  const submitTags = async () => {
    const response = await saveProductQuery.fetchData({
      tags: product.tags,
      ...product,
    });
    if (response.error) {
      setComponentAlert({
        msg: response.error.message,
        alertType: "error",
      });
    }

    if (response.data) {
      setComponentAlert({
        msg: response.data.saveProduct.message,
        alertType: "success",
      });

      if (showManageTags) setShowManageTags(false);
      else setShowAddTags(false);
    }
  };

  const handleSubmitTagsWithConfirmation = () => {
    appState.showNewConfirmation(
      "Confirm",
      "Are you sure you want to assign the selected tags to the product ?",
      submitTags,
      appState.hideConfirmation,
    );
  };

  const submitTagForm = async (e) => {
    e.preventDefault();
    if (!addNewTag.name) {
      setComponentAlert({
        msg: "Please enter a valid tag name",
        alertType: "error",
      });
      setAddNewTag(null);
      return;
    }
    if (!showManageTags) addNewTag.productId = product.id;
    appState.setLoading();
    const response = await saveTagQuery.fetchData(addNewTag);

    appState.removeLoading();
    setAddNewTag(null);
    if (response.error) {
      setComponentAlert({
        msg: response.error.message,
        alertType: "error",
      });
    }

    if (response.data) {
      setComponentAlert({
        msg: response.data.saveTag.message || "Success",
        alertType: "success",
      });
      if (!showManageTags) fetchProductForTags(product);
      fetchTags(true);
      loadFirstTimeData(false, true);
    }
  };

  const setKeywordValue = async (e) => {
    const keyword = e.target.value;
    setSearching(true);
    setSearchKeyword(keyword);
    fetchTags(true, keyword); // new search
  };

  const debouncedInventorySearch = _.debounce(
    setKeywordValue,
    SEARCH_DEBOUNCE_TIME_IN_MS,
  );

  const actionsOnTag = (tag) => {
    return [
      {
        name: "Delete Tag",
        value: "delete",
        icon: <TrashIcon className="h-6 w-6" />,
        onClick: () => handleDeleteTag(tag),
      },
    ];
  };

  const handleDeleteTag = (tag) => {
    appState.showNewConfirmation(
      "Delete Tag",
      <div className="py-6">
        <p className="mb-4 text-lg text-gray-700">
          Are you sure you want to delete the selected tag?
        </p>
        <div className="rounded-lg border border-gray-200 bg-gray-50 p-4">
          <h3 className="mb-2 text-gray-600">Products Associated</h3>
          <p className="mb-2 text-xl font-bold text-gray-900">
            {tag.products?.length || 0} Products
          </p>
          <div className="flex items-center text-red-500">
            <ExclamationIcon className="mr-2 h-6 w-6" />
            <span>
              The selected tag will be removed from all those products
            </span>
          </div>
        </div>
      </div>,
      () => deleteTag(tag.id),
      appState.hideConfirmation,
    );
  };
  const deleteTag = async (id) => {
    const tag = tags.find((tag) => tag.id === id);
    if (tag) {
      const response = await deleteTagQuery.fetchData({ id });
      if (response.error) {
        setComponentAlert({
          msg: response.error.message,
          alertType: "error",
        });
      }

      if (response.data) {
        setComponentAlert({
          msg: response.data.deleteTag.message,
          alertType: "success",
        });
        setTags(tags.filter((tag) => tag.id !== id));
        fetchTags(true);
        loadFirstTimeData(false, true);
        setProduct({
          ...product,
          tags: product.tags.filter((tag) => tag.id !== id),
        });
      }
    }
  };

  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "rgba(20, 128, 74, 0.1)"; // Transparent green
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "rgba(202, 58, 49, 0.1)"; // Transparent red
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "rgba(29, 78, 216, 0.1)";
        borderColor = "#1D4ED8";
        textColor = "#1D4ED8";
        break;
      default:
        return null;
    }

    return (
      <div
        style={{
          backgroundColor: bgColor,
          borderColor: borderColor,
          color: textColor,
        }}
        className={`mb-2 flex items-center rounded-md border-2 p-4`}
        role="alert">
        <div className="shrink-0">{icon}</div>
        <div className="ml-3">
          <p>{showAlert.msg}</p>
        </div>
        <button
          className="ml-auto text-gray-500 hover:text-gray-700"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6" />
        </button>
      </div>
    );
  };

  const toggleTagSelection = (tagId) => {
    const updatedProduct = { ...product };
    updatedProduct.tags = updatedProduct.tags || [];
    const tag = updatedProduct.tags.find((t) => t.id === tagId);
    if (tag) {
      updatedProduct.tags = updatedProduct.tags.filter((t) => t.id !== tagId);
    } else {
      const tag = tags.find((t) => t.id === tagId);
      updatedProduct.tags.push(tag);
    }
    setProduct(updatedProduct);
  };

  return (
    <>
      {addNewTag && (
        <ModalV3
          isOpen={addNewTag != null}
          onClose={() => setAddNewTag(null)}
          title="Create Tag">
          <div className="min-w-3xl modalV3 p-4">
            <div className="flex items-center justify-between pb-4 font-inter">
              <span className="font-inter text-2xl font-bold text-[#454A4F]">
                Create Tag
              </span>
              <XIcon
                className="h-6 w-6 cursor-pointer"
                onClick={() => setAddNewTag(null)}
              />
            </div>
            <div className="max-w-content flex flex-col space-y-4">
              <div>
                <span className="pb-6 text-lg font-medium text-[#717679]">
                  Tag Name<span className="text-red-500">*</span>
                </span>
                <input
                  type="text"
                  className="w-full rounded-md border-2 border-[#DDDFE0] p-2"
                  placeholder="Enter Tag Name"
                  value={addNewTag.name}
                  onChange={(e) =>
                    setAddNewTag({ ...addNewTag, name: e.target.value })
                  }
                />
              </div>
              <div>
                <span className="pb-6 text-lg font-medium text-[#717679]">
                  Tag Description
                </span>
                <textarea
                  className="w-full rounded-md border-2 border-[#DDDFE0] p-2"
                  placeholder="Enter Tag Description"
                  value={addNewTag.description}
                  onChange={(e) =>
                    setAddNewTag({
                      ...addNewTag,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-1 items-end justify-end space-x-2">
                <button
                  className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent p-2 px-6 font-semibold text-primaryAccent"
                  onClick={() => setAddNewTag(null)}>
                  Cancel
                </button>
                <button
                  className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-2 px-6 font-semibold text-white"
                  onClick={submitTagForm}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ModalV3>
      )}
      {componentAlert && (
        <div className="w-full p-2">{renderAlert(componentAlert)}</div>
      )}
      <div className="flex h-full flex-col p-6">
        {!showManageTags && (
          <div className="shrink-0 grow-0">
            <div className="mb-4">
              <h3 className="mb-2 text-lg font-semibold">
                Assigned Tags ({product.tags.length})
              </h3>
              <div className="flex flex-wrap gap-2">
                {product.tags.slice(0, 5).map((tag) => (
                  <div
                    key={tag.id}
                    className="flex items-center truncate rounded-full border border-gray-400 bg-white p-2">
                    {tag.name}
                    <button
                      onClick={() => toggleTagSelection(tag.id)}
                      className="ml-2 text-black">
                      <XIcon className="h-4 w-4" />
                    </button>
                  </div>
                ))}
                {showAssignedTags &&
                  product.tags.slice(5).map((tag) => (
                    <div
                      key={tag.id}
                      className="flex items-center truncate rounded-full border border-gray-400 bg-white p-2">
                      {tag.name}
                      <button
                        onClick={() => toggleTagSelection(tag.id)}
                        className="ml-2 text-black">
                        <XIcon className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                {showAssignedTags && (
                  <button
                    onClick={handleToggle}
                    className="flex items-center truncate rounded-full border border-gray-400 bg-white p-2">
                    less
                  </button>
                )}
                {!showAssignedTags && product.tags.length > 5 && (
                  <button
                    onClick={handleToggle}
                    className="items-centertruncate flex rounded-full border border-gray-400 bg-white p-2">
                    +{product.tags.length - 5}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="flex grow flex-col overflow-hidden">
          <div className="mb-2 flex items-center justify-between">
            <h3 className="text-lg font-semibold">
              Available Tags ({totalTags})
            </h3>
            <button
              className="cursor-pointer whitespace-nowrap rounded-md border-2 border-primaryAccent p-2 px-10 font-semibold text-primaryAccent"
              onClick={() => setAddNewTag({ name: "", description: "" })}>
              Create Tag
            </button>
          </div>
          <div className="mb-2">
            <input
              type="text"
              className="w-full rounded-md border-2 border-gray-300 p-2"
              placeholder="Search Tags..."
              onChange={(e) => debouncedInventorySearch(e)}
            />
          </div>
          <div className="grow overflow-y-scroll p-4">
            {isLoading && (
              <div className="flex items-center justify-center">
                <LoadingIndicator shouldShowOnPage={false} />
              </div>
            )}
            {!isLoading &&
              tags.map((tag) => (
                <div key={tag.id} className="flex items-center p-2">
                  {!showManageTags && (
                    <Checkbox
                      role="checkbox"
                      onChange={() => toggleTagSelection(tag.id)}
                      name="selectAllRows"
                      checked={
                        product?.tags?.findIndex((t) => t.id === tag.id) > -1
                      }
                    />
                  )}
                  <div className="ml-4 flex-1">
                    <div className="flex items-center gap-2">
                      <div className="text-lg text-black">{tag.name}</div>
                      {tag.description && (
                        <>
                          <InformationCircleIcon
                            className="h-5 w-5 cursor-pointer text-gray-400"
                            data-tip={tag.description}
                          />
                          <ReactTooltip />
                        </>
                      )}
                    </div>
                    <div className="text-lg text-[#717679]">
                      {pluralize(tag.products?.length || 0, "Product")}
                    </div>
                  </div>
                  {showManageTags && (
                    <div className="relative">
                      <PopoverOnTableAction
                        icon={
                          <DotsVerticalIcon className="h-6 w-6 text-[#737579]" />
                        }
                        panelClassName={
                          "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray"
                        }
                        customClassName={"py-4 mr-2"}>
                        {actionsOnTag(tag).map((action) => {
                          return (
                            <div
                              className={`cursor-pointer whitespace-nowrap rounded-lg p-3 font-medium ${
                                action.disabled
                                  ? "cursor-not-allowed text-gray-200"
                                  : "text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                              }`}
                              onClick={
                                action.onClick && !action.disabled
                                  ? action.onClick
                                  : () =>
                                      action.disabledMessage
                                        ? alert(action.disabledMessage)
                                        : console.log(action.value, action.name)
                              }>
                              <div className="flex space-x-2">
                                <span>{action.icon ? action.icon : null}</span>
                                <span>{action.name}</span>
                              </div>
                            </div>
                          );
                        })}
                      </PopoverOnTableAction>
                    </div>
                  )}
                </div>
              ))}
            {isLoadMoreLoading && (
              <div className="flex items-center justify-center">
                <LoadingIndicator shouldShowOnPage={false} />
              </div>
            )}
            {!isLoading && !isLoadMoreLoading && tags.length === 0 && (
              <div className="m-auto flex h-full flex-col items-center justify-center pb-10 text-center">
                <img className="w-1/2" src={noFilterIcon} />
                <div className="mt-4 text-center font-inter text-xl font-semibold text-black">
                  Tags not found
                </div>
                <div className="mt-2 text-center font-inter text-lg font-light text-gray-500">
                  Tag you have searched for looks like doesn’t exit, we
                  recommend you to create a new one.
                </div>
                <button
                  className="mt-4 rounded-lg bg-primaryAccent px-6 py-2 text-white"
                  onClick={() => setAddNewTag({ name: "", description: "" })}>
                  Create Tag
                </button>
              </div>
            )}
            {!isLoading && !isLoadMoreLoading && hasMore && (
              <div className="flex justify-center p-4">
                <button
                  className="cursor-pointer whitespace-nowrap rounded-md border-2 border-primaryAccent p-2 px-10 font-semibold text-primaryAccent"
                  onClick={() => fetchTags(false)}>
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
        {!showManageTags && (
          <div className="sticky bottom-0 right-0 flex w-full items-end justify-end space-x-2 bg-white p-6">
            <button
              className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent p-2 px-6 font-semibold text-primaryAccent"
              onClick={() => {
                setShowAddTags(false);
              }}>
              Cancel
            </button>
            <button
              className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-2 px-6 font-semibold text-white"
              onClick={handleSubmitTagsWithConfirmation}>
              Save
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CatalogManageTags;
