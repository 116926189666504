const NotFound = ({ title, description, tenant }) => {
  return (
    <div className="min-w-screen flex min-h-screen flex-col justify-center bg-gray-100 py-6 sm:py-12">
      <div className="relative py-3 sm:mx-auto sm:max-w-xl">
        <div className="absolute inset-0 -skew-y-6 transform bg-gradient-to-r from-primaryAccent to-1D3752 shadow-lg sm:-rotate-6 sm:skew-y-0 sm:rounded-3xl"></div>
        <div className="relative bg-white px-4 py-10 shadow-lg sm:rounded-3xl sm:p-20">
          <div className="mx-auto max-w-md">
            {/* <div className="flex">
              <RenderImage tenant={tenant} />
            </div> */}
            <div className="divide-y divide-gray-200">
              <div className="space-y-4 py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7">
                <p className="text-3xl text-red-500">
                  {title ? title : "Page Not Found"}
                </p>

                <p>
                  {description
                    ? description
                    : "Looks like the page you're looking for doesn't exist."}
                </p>
              </div>
              <div className="pt-6 text-base font-bold leading-6 sm:text-lg sm:leading-7">
                <p>
                  <a href="/" className="text-green-600 hover:text-green-700">
                    &larr; Go Back
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderImage = ({ tenant }) => (
  <div className="flex items-center justify-center">
    {tenant && tenant.logo ? (
      <img className="h-20 w-auto" src={tenant.logo} alt="Custom Logo" />
    ) : (
      <div className="flex items-center justify-center">
        <img
          className="h-12 w-auto"
          src="https://hopstack-pub.s3.amazonaws.com/logo.png"
          alt="Hopstack"
        />
        <h1 className="px-2 font-hammersmith text-2xl font-normal text-gray-800">
          Hopstack Inc
        </h1>
      </div>
    )}
  </div>
);

export default NotFound;
