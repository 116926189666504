/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { InformationCircleIcon } from "@heroicons/react/solid";

const Notification = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map((alert) => (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed left-0 top-0 z-50 flex w-full items-end px-4 py-6 sm:items-end sm:p-6"
      key={alert.id}>
      <div className="flex w-full flex-col items-center space-y-4">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={true}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div
            className={`pointer-events-auto w-full max-w-2xl overflow-hidden rounded-xl bg-white shadow-xl ring-1 ring-black ring-opacity-5`}>
            <div className="p-4">
              <div className="flex items-center">
                <div className="shrink-0">
                  {determineColor(alert.alertType)[0]}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sidebar-blue font-montserrat text-3xl font-normal">
                    {alert.msg}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  ));

const determineColor = (alertType) => {
  switch (alertType) {
    case "error":
      return [
        <XIcon
          className="h-14 w-14 rounded-full bg-primaryAccent font-light text-white"
          aria-hidden="true"
        />,
      ];
    case "success":
      return [
        <CheckIcon
          className="h-14 w-14 rounded-full bg-primaryAccent font-light text-white"
          aria-hidden="true"
        />,
      ];
    default:
      return [
        <InformationCircleIcon
          className="h-14 w-14 rounded-full bg-primaryAccent font-light text-white"
          aria-hidden="true"
        />,
      ];
  }
};

export default Notification;
