import { useState, useEffect } from "react";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import Autocomplete from "#components/utils/Autocomplete";
import { TrashIcon } from "@heroicons/react/outline";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { GET_USERS } from "#queries";
import { useQuery } from "#hooks/useQuery";

const ActivatePlanForm = ({
  onClose,
  title,
  onChange,
  planToActivate,
  onSubmit,
  onAddUserToPlan,
  onRemoveUserFromPlan,
  onChangePersonnelMapping,
  itemsAvailableForAssignment,
}) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const usersQuery = useQuery(GET_USERS);
  const onChangeUserSearch = (keyword) => {
    usersQuery.fetchData({
      perPage: 10,
      pageNumber: 1,
      filters: {
        keyword,
        warehouses: [planToActivate.warehouse],
      },
      paginated: false,
    });
  };
  const debouncedUserSearch = _.debounce(onChangeUserSearch, 500);

  useEffect(() => {
    if (usersQuery.data) {
      setUsers(
        usersQuery.data.users.entities.filter(
          (item) =>
            (item.role?.toLowerCase() === "associate" &&
              item.hopstackModules.includes("Stock Cycle Count")) ||
            item.role?.toLowerCase() !== "associate",
        ),
      );
    }

    if (usersQuery.error) {
      setUsers([]);
    }
  }, [usersQuery.data, usersQuery.error]);

  useQuery(() => {
    usersQuery.fetchData({
      perPage: 10,
      pageNumber: 1,
      paginated: false,
    });
  }, []);
  return (
    <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Name
            </span>
          </label>
          <div>{planToActivate.name}</div>
        </div>
        <div>
          <Autocomplete
            options={users}
            labelKey="name"
            valueKey="id"
            placeholder="Search to Assign Personnel"
            onChange={(e) => {
              setSelectedUser(e);
              onAddUserToPlan(users?.find((i) => i.id === e));
            }}
            onKeyDown={(e) =>
              e.key === "Enter" ? setSelectedUser(null) : debouncedUserSearch(e)
            }
            id="USER"
            value={selectedUser}
          />
        </div>
        <div>
          {planToActivate.personnel?.map((item, idx) => {
            const alreadyAssignedItems = planToActivate.personnel
              .reduce((prev, curr) => {
                if (curr.id === item.id) return prev;
                return [...prev, ...curr.assignedItems];
              }, [])
              .join(",");
            return (
              <div className="flex w-full flex-row">
                <div className="mt-3 w-1/3 text-xl font-medium">
                  {item.name}
                </div>
                <div className="w-2/3">
                  <MultiSelectAutoComplete
                    options={[
                      ...itemsAvailableForAssignment.skus.map(
                        (item) => `Product: ${item}`,
                      ),
                      ...itemsAvailableForAssignment.binRanges.rows.map(
                        (item) => `Row: ${item}`,
                      ),
                      ...itemsAvailableForAssignment.binRanges.sections.map(
                        (item) => `Section: ${item}`,
                      ),
                      ...itemsAvailableForAssignment.binRanges.bins.map(
                        (item) => `Bin: ${item}`,
                      ),
                      ...itemsAvailableForAssignment.specificBins.map(
                        (item) => `${item}`,
                      ),
                    ]
                      .filter((item) => !alreadyAssignedItems.includes(item))
                      .map((item) => ({
                        name: item,
                      }))}
                    labelKey="name"
                    valueKey="name"
                    setValues={(e) => onChangePersonnelMapping(idx, e)}
                    values={planToActivate.personnel[idx].assignedItems}
                  />
                </div>
                <div
                  onClick={() => onRemoveUserFromPlan(item.id)}
                  className="cursor-pointer">
                  <TrashIcon className="ml-3 mt-3 h-8 w-8" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ActivatePlanForm;
