import SlideOverPanel from "../common/SlideOverPanel";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";

const PICKING_STRATEGIES = [
  {
    name: "Pick by Order",
    value: "PICK_BY_ORDER",
  },
  {
    name: "Pick + Sort",
    value: "PICK_PLUS_SORT",
  },
  {
    name: "Pick then Sort",
    value: "PICK_THEN_SORT",
  },
];

const BatchSettings = ({
  showBatchSettings,
  setShowBatchSettings,
  batchSettings,
  setBatchSettings,
  saveBatchSettings,
}) => {
  return (
    <SlideOverPanel
      open={showBatchSettings}
      setOpen={setShowBatchSettings}
      title="Batch Settings">
      <div className="relative h-full">
        <div className="px-2">
          <div className="space-y-4">
            <div>
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Picking Strategy
              </span>
            </div>
            <div>
              <Dropdown
                placeholder={"Select Picking Strategy"}
                list={PICKING_STRATEGIES}
                labelKey="name"
                valueKey="value"
                name="pickingStrategy"
                setSelected={(e) =>
                  setBatchSettings({
                    ...batchSettings,
                    pickingStrategy: e,
                  })
                }
                selectedValue={batchSettings.pickingStrategy}
              />
            </div>
            <div>
              <TextField
                type="number"
                id="minBatchSize"
                label="Min Batch-Size"
                placeholder="Min Batch-Size"
                onChange={(e) =>
                  setBatchSettings({
                    ...batchSettings,
                    minAutoBatchSize:
                      isNaN(parseInt(e.target.value)) === false
                        ? parseInt(e.target.value)
                        : null,
                  })
                }
                value={batchSettings.minAutoBatchSize}
              />
            </div>
            <div>
              <TextField
                type="number"
                id="minBatchSize"
                label="Max Batch-Size(Single-line)"
                placeholder="Min Batch-Size(Single-line)"
                onChange={(e) =>
                  setBatchSettings({
                    ...batchSettings,
                    maxOrdersPerBatchForSingleLineItems:
                      isNaN(parseInt(e.target.value)) === false
                        ? parseInt(e.target.value)
                        : null,
                  })
                }
                value={batchSettings.maxOrdersPerBatchForSingleLineItems}
              />
            </div>
            <div>
              <TextField
                type="number"
                id="maxOrdersPerBatchForMultipleLineItems"
                label="Max Batch-Size(Multi-line)"
                placeholder="Max Batch-Size(Multi-line)"
                onChange={(e) =>
                  setBatchSettings({
                    ...batchSettings,
                    maxOrdersPerBatchForMultipleLineItems:
                      isNaN(parseInt(e.target.value)) === false
                        ? parseInt(e.target.value)
                        : null,
                  })
                }
                value={batchSettings.maxOrdersPerBatchForMultipleLineItems}
              />
            </div>
            <div>
              <TextField
                type="number"
                id="rowIncrements"
                label="Row Increments"
                placeholder="Row Increments"
                onChange={(e) =>
                  setBatchSettings({
                    ...batchSettings,
                    rowIncrements:
                      isNaN(parseInt(e.target.value)) === false
                        ? parseInt(e.target.value)
                        : null,
                  })
                }
                value={batchSettings.rowIncrements}
              />
            </div>
            <div>
              <TextField
                type="number"
                id="numberOfAdjacentRows"
                label="Number of Adjacent Rows"
                placeholder="Number of adjacent rows"
                onChange={(e) =>
                  setBatchSettings({
                    ...batchSettings,
                    numberOfAdjacentRows:
                      isNaN(parseInt(e.target.value)) === false
                        ? parseInt(e.target.value)
                        : null,
                  })
                }
                value={batchSettings.numberOfAdjacentRows}
              />
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 w-full shrink-0 border-t border-gray-400 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setShowBatchSettings(false)}>
              Cancel
            </button>
            <button
              type="submit"
              className="focus-visible:outline-primaryAcc inline-flex justify-center rounded-md bg-primaryAccent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primaryAccent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              onClick={saveBatchSettings}>
              Save
            </button>
          </div>
        </div>
      </div>
    </SlideOverPanel>
  );
};

export default BatchSettings;
