import React from "react";
import CustomTable from "../../newUiComponents/commons/CustomTable";
import { Tabs, Dropdown } from "antd";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import CustomBadge from "../../newUiComponents/commons/CustomBadge";
import { CUSTOM_TABLE_PAGE_SIZE_OPTIONS } from "#components/HOC/withReportsLogic";

const ReportTabsList = ({
  reports,
  menuItems,
  reportsTabs,
  selectedReportTab,
  setSelectedReportTab,
  total,
  pageNumber,
  perPage,
  setPerPage,
  setPagination,
}) => {
  const handleIncomingData = (data) => {
    return data.map((report) => {
      return {
        ...report,
        status: (
          <CustomBadge
            label={report?.status}
            textColor={getStatusColor(report?.status)?.textColor}
            bgColor={getStatusColor(report?.status)?.bgColor}
          />
        ),
        reportTrigger: (
          <span>{getReportTriggerLabel(report?.jobId)?.label}</span>
        ),
        action: (
          <Dropdown
            trigger={["hover"]}
            menu={{
              items: menuItems(report),
            }}
            overlayClassName="z-5"
            dropdownRender={({ props }) => {
              return (
                <div className="w-auto rounded-md border bg-white p-2 text-sm text-gray-600 shadow-md">
                  {props.items.map((item) => (
                    <div
                      onClick={() => item["onClick"](report)}
                      key={item.title}
                      className="cursor-pointer p-2 hover:bg-hoverHighlight hover:text-primaryAccent">
                      {item.title}
                    </div>
                  ))}
                </div>
              );
            }}
            placement="topRight"
            arrow>
            <DotsVerticalIcon className="h-6 w-6 cursor-pointer text-gray-400" />
          </Dropdown>
        ),
      };
    });
  };

  return (
    <Tabs
      defaultActiveKey={reportsTabs[0].key}
      activeKey={selectedReportTab}
      tabBarStyle={{
        borderBottom: "2px solid #ccc",
      }}
      onChange={(key) => setSelectedReportTab(key)}
      size="large">
      <Tabs.TabPane
        className="hover:text-black"
        key={reportsTabs[0].key}
        tab={
          <span
            className={`text-base font-medium hover:text-black ${selectedReportTab === reportsTabs[0].key ? "text-black" : "text-gray-600"}`}>
            {reportsTabs[0].label}
          </span>
        }>
        <CustomTable
          key={reportsTabs[0].key}
          columns={reportsTabs[0].columns}
          data={handleIncomingData(reports)}
          isFilters={false}
          isSearchable={false}
          isPagination={true}
          pageSize={perPage}
          setPageSize={setPerPage}
          currentPage={pageNumber}
          totalDataCount={total}
          loadingData={false}
          onSort={(key, direction) => {}}
          setCurrentPage={setPagination}
          pageSizeOptions={CUSTOM_TABLE_PAGE_SIZE_OPTIONS}
          totalDataCountLable="Reports: "
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        className="hover:text-black"
        key={reportsTabs[1].key}
        tab={
          <span
            className={`text-base font-medium hover:text-black ${selectedReportTab === reportsTabs[1].key ? "text-black" : "text-gray-600"}`}>
            {reportsTabs[1].label}
          </span>
        }>
        <CustomTable
          key={reportsTabs[1].key}
          columns={reportsTabs[1].columns}
          data={handleIncomingData(reports)}
          isFilters={false}
          isSearchable={false}
          isPagination={true}
          pageSize={perPage}
          setPageSize={setPerPage}
          currentPage={pageNumber}
          totalDataCount={total}
          loadingData={false}
          onSort={(key, direction) => {}}
          setCurrentPage={setPagination}
          pageSizeOptions={CUSTOM_TABLE_PAGE_SIZE_OPTIONS}
          totalDataCountLable="Reports: "
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ReportTabsList;

export const getStatusColor = (status) => {
  switch (status) {
    case "CREATED":
      return { bgColor: "#BFDBFE", textColor: "#1E40AF" }; // light blue background, dark blue text
    case "GENERATING":
      return { bgColor: "#FFF9E9", textColor: "#E3A400" }; // light yellow background, dark yellow text
    case "GENERATED":
      return { bgColor: "#D1FAE5", textColor: "#065F46" }; // light green background, dark green text
    case "GENERATION_FAILED":
      return { bgColor: "#FECACA", textColor: "#991B1B" }; // light red background, dark red text
    default:
      return { bgColor: "#FFFFFF", textColor: "#000000" }; // white background, black text
  }
};
export const getReportTriggerLabel = (jobId) => {
  if (jobId && jobId !== null) {
    return {
      label: "Scheduled",
    };
  } else {
    return {
      label: "User Requested",
    };
  }
};
