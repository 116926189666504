import Barcode from "react-barcode";

const ThreeByOneLabel = ({ id, value }) => (
  <div id={`${id}_3x1`} style={{ backgroundColor: "#fff" }}>
    <Barcode
      value={value}
      fontSize={26}
      font="Arial"
      textMargin={-2}
      fontOptions="bold"
    />
  </div>
);

export default ThreeByOneLabel;
