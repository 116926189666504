import _ from "lodash";
import TableFieldButton from "#components/utils/TableFieldButton";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import SearchSortFilter from "#components/common/SearchSortFilter";
import QuickFilters from "#components/common/QuickFilters";
import Modal from "#components/utils/Modal";
import { useState } from "react";
import { Tab } from "@headlessui/react";
import CustomTableWithTheme from "../common/CustomTableWithTheme";

const headers = [
  "Code",
  "Name",
  "Email",
  "Vendor Classification",
  "Phone",
  "Addresses",
  "Status",
  "Actions",
];
const noValuesText = "No Vendors";

const VendorList = ({
  vendors,
  filters,
  onChangeFilter,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  customers,
  warehouses,
  editVendor,
  deleteVendor,
  availableInventory,
}) => {
  const [viewEmailsModal, setViewEmailsModal] = useState(false);
  const [viewEmails, setViewEmails] = useState(null);
  const [viewConsig, setViewConsig] = useState(null);
  const [viewAddressModal, setViewAddressModal] = useState(false);
  const [viewAddress, setViewAddress] = useState(null);
  let tabs = {};
  let addressTabs = {};

  const emailTabs = {
    Email: <EmailsDetails emails={viewEmails} />,
  };
  addressTabs = {
    ...addressTabs,
    Address: <AddressItem item={viewAddress} />,
  };

  tabs = {
    ...tabs,
    Items: <OrderLineItems item={viewConsig} />,
  };

  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Code",
            value: "code",
          },
          {
            name: "Name",
            value: "name",
          },
          {
            name: "Email",
            value: "email",
          },
          {
            name: "Status",
            value: "active",
          },
        ]}
      />
      <div className="overflow-x-auto">
        <CustomTableWithTheme>
          <thead className="rounded-full bg-primaryAccent p-4">
            <tr className="border-left font-montserrat text-textWhite">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {vendors.length === 0 ? (
              <tr className="bg-white">
                {headers.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <td
                      className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                      key={headerIdx}>
                      {noValuesText}
                    </td>
                  ) : (
                    <td
                      className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {vendors &&
              vendors.length > 0 &&
              vendors.map((vendor, index) => (
                <tr
                  key={vendor.id}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`rounded-bl rounded-tl border-l-8 p-5 text-left font-semibold tracking-wider ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {vendor.code}
                  </td>

                  <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {vendor.name}
                  </td>

                  <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    <div>
                      <button
                        onClick={() => {
                          setViewEmailsModal(true);
                          if (vendor.emails) setViewEmails(vendor?.emails);
                          else setViewEmails([""]);
                        }}
                        className="text-sm text-2C7695 underline">
                        Click to View
                      </button>
                    </div>
                  </td>
                  <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {vendor.classification}
                  </td>
                  <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {vendor?.phoneNumbers ? vendor.phoneNumbers[0] : "-"}
                  </td>
                  <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    <div>
                      <button
                        onClick={() => {
                          setViewAddressModal(true);
                          setViewAddress(vendor?.addresses);
                        }}
                        className="text-sm text-2C7695 underline">
                        Click to View
                      </button>
                    </div>
                  </td>

                  <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {vendor?.active === true ? "Active" : "Inactive"}
                  </td>

                  <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    <div className="flex items-center space-x-4">
                      <TableFieldButton
                        onClick={() => editVendor(vendor.id)}
                        text={<PencilIcon className="h-6 w-6" />}
                      />
                      <TableFieldButton
                        text={<TrashIcon className="h-6 w-6" />}
                        // disabled={cycleCountPlan.executions?.length >= 1 ? true : false}
                        onClick={() => deleteVendor(vendor.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </CustomTableWithTheme>
      </div>

      {viewEmailsModal && (
        <Modal
          negativeAction={() => setViewEmailsModal(false)}
          title={`Vendor Emails`}
          noPadding={true}
          positiveAction={() => setViewEmailsModal(false)}
          positiveText="OK">
          <div className="overflow-auto bg-EFE9DC p-4">
            <Tab.Group>
              <Tab.List className="flex items-center">
                <div className="flex-1 space-x-4 rounded-xl p-1 px-0"></div>
              </Tab.List>
              <Tab.Panels className="mt-2">
                {emailTabs &&
                  Object.values(emailTabs).map((tabInner, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="textWhite-300 mt-4 flex flex-col border bg-EBEBEB">
                      {tabInner}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </Modal>
      )}

      {viewAddressModal && (
        <Modal
          negativeAction={() => setViewAddressModal(false)}
          title={`Vendor Addresses`}
          noPadding={true}
          positiveAction={() => setViewAddressModal(false)}
          positiveText="OK">
          <div className="overflow-auto bg-EFE9DC p-4">
            <Tab.Group>
              <Tab.List className="flex items-center">
                <div className="flex-1 space-x-4 rounded-xl p-1 px-0"></div>
              </Tab.List>
              <Tab.Panels className="mt-2">
                {addressTabs &&
                  Object.values(addressTabs).map((tabInner, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="textWhite-300 mt-4 flex flex-col border bg-EBEBEB">
                      {tabInner}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </Modal>
      )}
    </div>
  );
};

const EmailsDetails = ({ emails }) => {
  return (
    <div>
      {emails && emails.length > 0 && (
        <div>
          <h3 className="mb-4 text-lg font-semibold text-gray-500">Emails</h3>
          <ul className="list-none">
            {emails.map((email, idx) => (
              <li key={idx} className="mb-2">
                <a
                  href={`mailto:${email}`}
                  className="text-sm text-2C7695 underline hover:text-primaryAccent">
                  {email}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
const OrderLineItems = ({ item }) => {
  const headers = ["SKUs", "Name"];
  // {console.log(item, "itemssssggg")}
  if (item && item?.length === 0) {
    return (
      <div className="flex items-center justify-center">
        <p className="font-montserrat text-lg font-semibold text-2C7695">
          No Products
        </p>
      </div>
    );
  }
  return (
    <table className="min-w-full divide-y divide-gray-200 px-2">
      <thead className="rounded-full bg-primaryAccent p-4 px-12">
        <tr className="border-left px-12 font-montserrat text-textWhite">
          {headers.map((header, headerIdx) =>
            headerIdx === 0 ? (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ) : (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {item?.map((i, index) => (
          <tr
            key={i.id}
            className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
            <td
              className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
              }`}>
              {i?.sku}
            </td>

            <td
              className={`tracking-widerrounded-tl rounded-bl p-5 text-left font-medium text-5F666B`}>
              {i?.name}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const AddressItem = ({ item }) => {
  const headers = ["Line 1", "Line 2", "City", "State", "Zip Code", "Country"];
  return (
    <table className="min-w-full divide-y divide-gray-200 px-2">
      <thead className="rounded-full bg-primaryAccent p-4 px-12">
        <tr className="border-left px-12 font-montserrat text-textWhite">
          {headers.map((header, headerIdx) =>
            headerIdx === 0 ? (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ) : (
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                key={headerIdx}>
                {header}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {item.map((i, index) => (
          <tr
            key={i.id}
            className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
            <td
              className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
              }`}>
              {i?.line1}
            </td>

            <td
              className={`tracking-widerrounded-tl rounded-bl p-5 text-left font-medium text-5F666B`}>
              {i?.line2}
            </td>

            <td
              className={`tracking-widerrounded-tl rounded-bl p-5 text-left font-medium text-5F666B`}>
              {i?.city}
            </td>

            <td
              className={`tracking-widerrounded-tl rounded-bl p-5 text-left font-medium text-5F666B`}>
              {i?.state}
            </td>

            <td
              className={`tracking-widerrounded-tl rounded-bl p-5 text-left font-medium text-5F666B`}>
              {i?.zip}
            </td>

            <td
              className={`tracking-widerrounded-tl rounded-bl p-5 text-left font-medium text-5F666B`}>
              {i?.country}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VendorList;
