import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import ReactTooltip from "react-tooltip";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";

const ProductInfo = ({
  item,
  itemDetails,
  itemIdx = 0,
  setShowImage,
  style = { totalWidth: "w-289", width: "w-198" },
}) => {
  if (!setShowImage) {
    setShowImage = () => {};
  }

  const productName = itemDetails ? itemDetails.name : null;
  const productSKU = itemDetails ? itemDetails.sku : item.sku;
  const productASIN = itemDetails ? itemDetails.asin : item.asin;
  const titleData = productName ? productName : productSKU;

  return (
    <div className={`${style.totalWidth} rounded-lg bg-bgWhite`}>
      {/* Product Info */}
      <div className="flex">
        <img
          src={
            itemDetails?.images?.[0]?.display_url
              ? itemDetails.images[0].display_url
              : FALLBACK_IMAGE_URL
          }
          style={{ width: "83px", height: "83px" }}
          alt={""}
          className="rounded object-cover"
          onClick={() =>
            setShowImage(
              itemDetails?.images?.[0]?.display_url ?? FALLBACK_IMAGE_URL,
            )
          }
        />
        <div className="ml-1">
          <p
            data-tip
            data-for={`title_${item.id}_${itemIdx}`}
            className={`truncate text-base ${style.width} font-medium`}>
            {titleData}
          </p>
          <ReactTooltip id={`title_${item.id}_${itemIdx}`}>
            {titleData}
          </ReactTooltip>
          {productName && (
            <>
              <p
                data-tip
                data-for={`sku_${item.id}_${itemIdx}`}
                className={`truncate text-sm ${style.width} font-normal text-lightGray`}>
                SKU: {productSKU}
              </p>
              <ReactTooltip id={`sku_${item.id}_${itemIdx}`}>
                {productSKU}
              </ReactTooltip>
            </>
          )}
          {productASIN && (
            <>
              <p
                data-tip
                data-for={`asin_${item.id}_${itemIdx}`}
                className={`truncate text-sm ${style.width} font-normal text-lightGray`}>
                ASIN:{" "}
                {buildMarketplaceHyperlink(
                  productASIN,
                  "ASIN",
                  itemDetails.source,
                )}
              </p>
              <ReactTooltip id={`asin_${item.id}_${itemIdx}`}>
                {productASIN}
              </ReactTooltip>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
