import React from "react";

const DateRangeErrorModal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-96 rounded-lg bg-white shadow-lg">
        <div className="flex justify-end p-4">
          <button className="text-xl font-bold" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="flex items-start justify-between p-6">
          {/* <div className="text-yellow-500 text-5xl mb-4">&#9888;</div> */}
          <svg
            className="mr-2"
            width="92"
            height="92"
            viewBox="0 0 92 92"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22.9999 17.25V21.0833M22.9999 28.75H23.0191M9.72089 36.4167H36.279C39.2299 36.4167 41.0742 33.2222 39.5988 30.6667L26.3197 7.66667C24.8443 5.11111 21.1556 5.11111 19.6802 7.66667L6.40112 30.6667C4.92567 33.2222 6.76999 36.4167 9.72089 36.4167Z"
              stroke="#F3B130"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <div className="-mt-3">
            <p className="mb-1 font-sans text-lg font-medium leading-6 text-black">
              Invalid Date Range
            </p>
            <p className="text-sm text-gray-600">
              The selected date range exceeds three months. Please choose a date
              range within three months to proceed with the export.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeErrorModal;
