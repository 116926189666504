/**
 * Renders a number input field with optional properties including a minimum value,
 * custom class name, and placeholders. This component also incorporates a hook to prevent
 * the input's value from changing when the mouse wheel is scrolled over it.
 *
 * @param {Object} props - The properties passed to the number field component.
 * @param {Function} props.onChange - Callback function that is called when the input's value changes.
 * @param {string} [props.placeholder=""] - The placeholder text for the input field when it is empty.
 * @param {number|string} props.value - The current value of the input field.
 * @param {string} [props.className] - An optional CSS class to apply to the input element.
 * @param {number} [props.min] - An optional minimum value for the input field.
 * @returns {React.Element} The React element for the input field.
 */
const NumberField = ({ onChange, placeholder = "", value, className, min }) => {
  // Initialize input props with common properties
  const props = {
    type: "number",
    onChange,
    placeholder,
    value,
    className,
    onWheel: (e) => e.currentTarget.blur(),
  };

  if (min) {
    props.min = min;
  }

  return <input {...props} />;
};

export default NumberField;
