import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import { PencilIcon, TrashIcon, SelectorIcon } from "@heroicons/react/outline";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import Switch from "#components/utils/Switch";
import { Container, Draggable } from "react-smooth-dnd";
import _ from "lodash";

const WorkflowForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedWorkflow,
  onSubmit,
  onChangeMultiSelect,
  customers,
  onDrop,
  setEnabled,
  warehouses,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Workflow Details
        </span>
      </div>

      {selectedWorkflow.workflowType === "OUTBOUND" && (
        <>
          {/* <div>
            <Dropdown
              placeholder={"Select Picking Strategy"}
              list={[
                { name: "Pick + Sort" },
                { name: "Pick by order" },
                { name: "Pick then Sort" },
              ]}
              labelKey="name"
              valueKey="name"
              name="pickingStrategy"
              setSelected={(e) => {
                onChangeDropdown("pickingStrategy", e);
              }}
              selectedValue={selectedWorkflow.pickingStrategy}
            />
          </div> */}
          <div>
            <Dropdown
              placeholder={"Select Compliance"}
              list={[{ name: "Default" }, { name: "FIFO" }, { name: "FEFO" }]}
              labelKey="name"
              valueKey="name"
              name="compliance"
              setSelected={(e) => {
                onChangeDropdown("compliance", e);
              }}
              selectedValue={selectedWorkflow.compliance}
            />
          </div>
          {selectedWorkflow.pickingStrategy !== "Pick by order" && (
            <>
              <div>
                <TextField
                  type="number"
                  id="minBatchSize"
                  label="Min Batch Size"
                  placeholder=" "
                  onChange={onChange}
                  value={selectedWorkflow.minBatchSize}
                  min="0"
                />
              </div>
              <div>
                <TextField
                  type="number"
                  id="maxBatchSize"
                  label="Max Batch Size"
                  placeholder=" "
                  onChange={onChange}
                  value={selectedWorkflow.maxBatchSize}
                  min="0"
                />
              </div>
              <div>
                <TextField
                  type="number"
                  id="waveSize"
                  label="Wave Size"
                  placeholder=" "
                  onChange={onChange}
                  value={selectedWorkflow.waveSize}
                  min="0"
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  </Modal>
);

export default WorkflowForm;
