import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import SwitchComponent from "#components/utils/Switch";
import _ from "lodash";

const DashboardForm = ({
  onClose,
  title,
  onChange,
  selectedDashboard,
  onSubmit,
  global,
  setGlobal,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
          Dashboard Details
        </span>
      </div>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedDashboard.name}
        />
      </div>
      <div>
        Global Dashboard
        <SwitchComponent enabled={global} setEnabled={setGlobal} />
      </div>
    </div>
  </Modal>
);

export default DashboardForm;
