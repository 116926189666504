import { useState, useEffect, useRef } from "react";
import { XIcon } from "@heroicons/react/outline";

/**
 * Renders a multiselect component with text input for adding custom labels.
 * @param {Array<string>} values - An array of selected values (strings).
 * @param {boolean} disabled - If true, the component is disabled and unresponsive.
 * @param {Function} setValues - Function to set values in the parent component.
 * @param {Object} inputStyle - Custom styles for the input field.
 * @param {string} buttonText - Text to display on the "Add" button.
 * @param {Object} buttonStyle - Custom styles for the "Add" button.
 * @param {string} placeholder - Placeholder text for the input field.
 * @param {Object} labelStyle - Custom styles for the selected labels.
 * @param {Object} labelContainerStyle - Custom styles for the label container.
 * @param {JSX.Element} labelRemoveIcon - Custom icon for removing labels.
 * @param {Object} disabledStyle - Custom styles for the disabled state.
 * @returns {JSX.Element}
 */
const MultiSelectAutoCompleteV2 = ({
  values: selectedValues = [],
  disabled = false,
  setValues,
  inputStyle = {},
  buttonText = "Add",
  buttonStyle = {},
  placeholder = "Start Typing",
  labelStyle = {},
  labelContainerStyle = {},
  labelRemoveIcon = <XIcon className="mr-2 h-5 w-5 cursor-pointer" />,
  disabledStyle = { opacity: 0.5, pointerEvents: "none" },
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedLabels, setLabels] = useState(selectedValues);
  const [cursor, setCursor] = useState(-1);
  const ref = useRef();

  useEffect(() => {
    const listener = (e) => {
      if (!ref.current || !ref.current.contains(e.target)) {
        setCursor(-1);
      }
    };

    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  const select = (selectedValue) => {
    if (selectedValue && !selectedLabels.includes(selectedValue)) {
      setValues([...selectedLabels, selectedValue]);
      setLabels([...selectedLabels, selectedValue]);
      setSearchText("");
    }
  };

  const handleChange = (text) => {
    setSearchText(text);
  };

  const removeItem = (removedValue) => {
    const updatedValues = selectedLabels.filter(
      (value) => value !== removedValue,
    );
    setValues(updatedValues);
    setLabels(updatedValues);
  };

  const SelectedValues = () => (
    <div className="pr-6" style={labelContainerStyle}>
      {selectedLabels.map((label, i) => (
        <div
          className="m-1 inline-block rounded-full bg-primaryAccent text-white"
          key={label + i}
          style={labelStyle}>
          <div className="flex h-8 items-center justify-between">
            <div className="flex-2 pl-3 pr-5">{label}</div>
            <div onClick={() => removeItem(label)}>{labelRemoveIcon}</div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div
      aria-disabled={disabled}
      className="relative"
      style={disabled ? disabledStyle : {}}
      ref={ref}>
      <div className="relative w-full overflow-visible rounded-lg border-2 border-2C7695 outline">
        <SelectedValues />
        <input
          type="text"
          className="inline-block appearance-none border-0 bg-transparent p-4 font-montserrat text-lg focus:border-0 focus:outline-none focus:ring-0"
          style={inputStyle}
          onKeyDown={(e) => e.key === "Enter" && select(searchText)}
          onChange={(e) => handleChange(e.target.value)}
          value={searchText}
          placeholder={placeholder}
        />
      </div>
      <div className="mt-2 flex justify-center">
        <button
          className="rounded bg-primaryAccent px-4 py-2 text-white"
          style={buttonStyle}
          onClick={() => select(searchText)}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default MultiSelectAutoCompleteV2;
