import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";
import QRCode from "react-qr-code";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";

const DetailedView = ({
  currentItem,
  scanBarcode,
  confirmPickItem,
  onChangeScannedQuantity,
}) => {
  const [manualValue, setManualValue] = useState(null);
  const isBinScanned = currentItem.isBinScanned === true;

  return (
    <div className="min-w-max max-w-4xl flex-col items-center justify-center">
      <PageTitle>Product Details</PageTitle>

      <div className="space-y-2 text-xl font-medium text-454A4F">
        <LineItemValue
          value={currentItem.binLocation}
          title="Bin Location"
          idx={2}
        />
        {currentItem.tote && (
          <LineItemValue value={currentItem.tote} title="Tote" idx={3} />
        )}
        {currentItem.bestByDate && (
          <LineItemValue
            value={currentItem.bestByDate}
            title="Expiry Date"
            idx={3}
          />
        )}
        <LineItemValue value={currentItem.quantity} title="Quantity" idx={5} />
        <LineItemValue value={currentItem.sku} title="SKU" idx={3} />
        {currentItem.productAlias && (
          <LineItemValue
            value={currentItem.productAlias}
            title="Customer Id"
            idx={3}
          />
        )}
        {currentItem.fnSku && (
          <LineItemValue value={currentItem.fnSku} title="FN SKU" idx={4} />
        )}
        {currentItem.asin && (
          <LineItemValue value={currentItem.asin} title="ASIN" idx={5} />
        )}
        {currentItem.upc && (
          <LineItemValue
            value={currentItem?.upc?.join(", ")}
            title="UPC"
            idx={5}
          />
        )}
        <LineItemValue
          value={currentItem.productName}
          title="Product Name"
          idx={4}
        />
      </div>

      <div
        onClick={() => {
          if (isBinScanned) {
            scanBarcode(currentItem.sku);
          } else {
            scanBarcode(currentItem.binLocation);
          }
        }}>
        <div className="mt-4 flex items-center justify-center">
          <div
            className={`mt-4 rounded-full border-4 border-FC8862 p-5 shadow-md ${
              isBinScanned && "border-gray-600 bg-gray-400"
            }`}>
            <QRCode
              value={qrcodeValue(isBinScanned, currentItem.binLocation)}
              size="100"
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          {scanItemMessage(isBinScanned)}
        </div>
      </div>

      <div className="mt-2 flex-col items-center justify-center bg-white p-10 pb-5 pt-5">
        <div>
          <TextField
            type="text"
            id="name"
            label="Scan Bin/Tote/Item"
            placeholder=" "
            onChange={(e) => setManualValue(e.target.value)}
            value={manualValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                scanBarcode(manualValue);
                setManualValue("");
              }
            }}
            autoFocus={true}
          />
        </div>
      </div>

      {!isBinScanned && (
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          Please Scan Bin Location
        </div>
      )}

      {isBinScanned ? (
        <div className="mt-10 grid grid-cols-2 self-center">
          <div className="justify-cente flex items-center bg-white p-6 py-2 text-3xl">
            <div className="py-2 text-3xl text-5F666B">Picked Quantity</div>
          </div>
          <div className="flex items-center justify-center bg-white p-6 py-2 text-3xl text-5F666B">
            <TextField
              type="number"
              id="scannedQuantity"
              label="Picked Quantity"
              placeholder=" "
              onChange={(e) => onChangeScannedQuantity(e.target.value)}
              value={currentItem.scannedQuantity}
            />
          </div>
        </div>
      ) : null}

      <div className="mt-20 flex items-center justify-center">
        {(isBinScanned || currentItem.scannedQuantity === 0) && (
          <div
            className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
            onClick={confirmPickItem}>
            Confirm Item
          </div>
        )}
        {!isBinScanned && currentItem.scannedQuantity > 0 && (
          <div className="w-64 rounded-md bg-gray-400 py-4 text-center text-2xl text-white">
            Confirm Item
          </div>
        )}
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="flex w-full max-w-4xl space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div
      className={`flex flex-1 items-center rounded-br-md rounded-tr-md border bg-white p-4`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

const qrcodeValue = (isBinScanned, binLocation) => {
  if (isBinScanned) {
    return `NOVALUE`;
  }

  return binLocation;
};

const scanItemMessage = (isBinScanned) => {
  if (isBinScanned) {
    return `Scanned`;
  }

  if (!isBinScanned) {
    return "Scan Bin";
  }
};

export default DetailedView;
