import Modal from "#components/utils/Modal";

const BundleProductsDetail = ({ products, onClose }) => {
  return (
    <Modal
      negativeAction={() => onClose()}
      title={`Products in Bundle (${products.length})`}>
      <div className="overflow-y-auto" style={{ maxHeight: 500 }}>
        <table className="mr-8 min-w-full divide-y divide-gray-200 px-2">
          <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
            <tr className="font-montserratborder-left bg-primaryAccent text-white">
              <th
                scope="col"
                className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                SKU
              </th>
              <th
                scope="col"
                className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                Name
              </th>
              <th
                scope="col"
                className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr
                key={product.sku}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-medium text-5F666B ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {product.sku}
                </td>
                <td className="max-w-2xl px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {product.name}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {product.quantity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default BundleProductsDetail;
