import JSPM from "jsprintmanager";

// NOTE: THIS IS ONLY FOR DELMAR. WE WILL MOVE THIS INTO A GENERIC PRINT USING OUR LAYOUT.
// THIS IS TEMPORARY UNTIL WE GET THAT IN PLACE.
// THE DIFFERENCE HERE IS DELMAR USING ZEBRA AND WE ORIGINALLY BUILT THIS FUNCTIONALITY BY GENERATING THE ZPL COMMANDS INLINE.
export const printShipperBarcodeThermal = async (shipper) => {
  const printers = await JSPM.JSPrintManager.getPrinters();
  const findZebra = printers.find(
    (e) =>
      e.toLowerCase().includes("zdesign") || e.toLowerCase().includes("zebra"),
  );
  if (findZebra) {
    const cpj = new JSPM.ClientPrintJob();
    const myPrinter = new JSPM.InstalledPrinter(findZebra);
    if (myPrinter) {
      cpj.clientPrinter = myPrinter;

      let cmds = "^XA";
      cmds += "^CF0,80";
      cmds += "^FO50,50^FDDelmar^FS"; // HARDCODED TO DELMAR
      cmds += "^FO50,130^GB700,1,3^FS";
      cmds += "^CFA,70";
      cmds += `^FO50,300^FD${shipper.name}^FS`;
      cmds += `^FO50,400^FD#${shipper.currentBin}^FS`;
      cmds += "^FO50,500^GB700,1,3^FS";
      cmds += "^BY5,2,270";
      cmds += `^FO100,650^BC^FD${shipper.currentBarcode}^FS`;
      cmds += "^XZ";
      cpj.printerCommands = cmds;
      console.log(cmds);
      cpj.sendToClient();
    }
  }

  return;
};
