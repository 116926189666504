import {
  GET_REPORTS,
  GET_REPORTS_METADATA,
  GET_REPORT,
  GET_SCHEDULE_REPORTS,
} from "../../queries";
import {
  SAVE_REPORT,
  START_REPORT_GENERATION,
  DELETE_REPORT,
  UPDATE_REPORT,
  SAVE_SCHEDULE_REPORT,
  DELETE_SCHEDULE_REPORT,
  UPDATE_SCHEDULE_REPORT,
} from "../../mutations";
import { useState, useEffect, useContext, useRef } from "react";
import { useQuery } from "#hooks/useQuery";
import { EntityContext } from "#contexts/entity";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { PrinterIcon, EyeIcon, TrashIcon } from "@heroicons/react/solid";
import Papa from "papaparse";
import moment from "moment-timezone";
import { GET_USERS } from "#queries";
const ALERT_VISIBILITY_IN_5000_MS = 5000;
export const ALERT_VISIBILITY_IN_3000_MS = 3000;

const CREATE_REPORT_MODES = {
  VIEW: "view",
  CREATE: "schedule",
  PREVIEW: "preview",
  RENAME: "rename",
  CANCEL: "cancel",
  DELETE: "delete",
};
const REPORT_TYPES_MODES = {
  SCHEDULE: "schedule",
  ONDEMAND: "ondemand",
};
const reportsTabs = [
  {
    key: "GENERATED_REPORTS",
    label: "Generated Reports",
    columns: [
      { title: "Report Name", key: "reportName", isSort: false },
      { title: "Created Date", key: "createdAtDate", isSort: false },
      { title: "Report Type", key: "module", isSort: false },
      { title: "Report Trigger", key: "reportTrigger", isSort: false },
      { title: "Status", key: "status", isSort: false },
      { title: "Action", key: "action", stickPosition: "right" },
    ],
  },
  {
    key: "SCHEDULED_REPORT",
    label: "Scheduled Reports",
    columns: [
      { title: "Report Type", key: "module", isSort: false },
      { title: "Scheduled Frequency", key: "frequencyCap", isSort: false },
      { title: "Next Scheduled Run", key: "nextRunDate", isSort: false },
      { title: "Last Successful Execution", key: "lastRunDate", isSort: false },
      { title: "Action", key: "action", stickPosition: "right" },
    ],
  },
];
export const GENERATED_REPORT_SORT_CHOICES = [
  {
    name: "Report Type (A-Z)",
    value: "module",
  },
  {
    name: "Report Created Date",
    value: "createdAt",
  },
  {
    name: "Report Name (A-Z)",
    value: "reportName",
  },
];
export const SCHEDULE_REPORT_SORT_CHOICES = [
  {
    name: "Report Type (A-Z)",
    value: "module",
  },
  {
    name: "Scheduled Frequency",
    value: "frequency",
  },
];
export const STATUS = [
  { id: "GENERATED", name: "GENERATED" },
  { id: "CREATED", name: "CREATED" },
  { id: "GENERATION_FAILED", name: "GENERATION_FAILED" },
  { id: "GENERATING", name: "GENERATING" },
];
const FREQUENCY = [
  { id: "daily", name: "Daily" },
  { id: "weekly", name: "Weekly" },
  { id: "monthly", name: "Monthly" },
];
export const CUSTOM_TABLE_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

const withReportsLogic = (WrappedComponent) => {
  return (props) => {
    const autoRefreshInterval = useRef(null);
    const REPORT_GET_MODES = {
      DEFAULT: "none",
      CSV: "CSV",
      PDF: "PDF",
      PREVIEW: "Preview",
    };
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const [selectedReport, setSelectedReport] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const reportsQuery = useQuery(GET_REPORTS);
    const scheduleReportsQuery = useQuery(GET_SCHEDULE_REPORTS);
    const reportsMetadataQuery = useQuery(GET_REPORTS_METADATA);
    const saveReportQuery = useQuery(SAVE_REPORT);
    const saveScheduleReportQuery = useQuery(SAVE_SCHEDULE_REPORT);
    const [reportsMetadata, setReportsMetadata] = useState(null);
    const getReportQuery = useQuery(GET_REPORT);
    const getReportPollQuery = useQuery(GET_REPORT);
    const startReportGenerationQuery = useQuery(START_REPORT_GENERATION);
    const [reportMode, setReportMode] = useState(REPORT_GET_MODES.DEFAULT);
    const [previewData, setPreviewData] = useState(null);
    const deleteReportQuery = useQuery(DELETE_REPORT);
    const deleteJobQuery = useQuery(DELETE_SCHEDULE_REPORT);
    const updateReportQuery = useQuery(UPDATE_REPORT);
    const updateScheduleReportQuery = useQuery(UPDATE_SCHEDULE_REPORT);
    const allUsersQuery = useQuery(GET_USERS);
    const [openReportMode, setOpenReportMode] = useState(null);
    const [reportType, setReportType] = useState(null);
    const [selectedReportTab, setSelectedReportTab] = useState(
      reportsTabs[0]["key"],
    );
    const [users, setUsers] = useState(null);
    const [selectedFilterReport, setSelectedFilterReport] = useState(null);
    const [copySelectedReport, setCopyupSelectedReport] = useState(null);

    useEffect(() => {
      if (getReportQuery.loading && reportMode !== REPORT_GET_MODES.DEFAULT) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (getReportQuery.data) {
        if (getReportQuery?.data?.getReport?.id) {
          // report is ready
          let url = null;
          if (reportMode === REPORT_GET_MODES.CSV) {
            url = getReportQuery.data.getReport.csvUrl;
            window.open(url, "_blank");
            setReportMode(REPORT_GET_MODES.DEFAULT);
          } else if (reportMode === REPORT_GET_MODES.PDF) {
            url = getReportQuery.data.getReport.pdfUrl;
            window.open(url, "_blank");
            setReportMode(REPORT_GET_MODES.DEFAULT);
          } else if (reportMode === REPORT_GET_MODES.PREVIEW) {
            url = getReportQuery.data.getReport.previewUrl;
            fetch(url)
              .then((response) => {
                response.text().then((csvString) => {
                  // Parse the CSV string
                  const result = Papa.parse(csvString, {
                    dynamicTyping: true,
                  });
                  const csvData = result.data;
                  setPreviewData(csvData);
                });
              })
              .catch((err) => {
                appState.setAlert(
                  err.message,
                  "error",
                  ALERT_VISIBILITY_IN_5000_MS,
                );
              });
            setReportMode(REPORT_GET_MODES.DEFAULT);
          }
        } else {
          appState.setAlert(
            getReportQuery?.data?.getReport?.error || "Failed to get report.",
            "error",
            ALERT_VISIBILITY_IN_5000_MS,
          );
        }
      } else if (
        getReportQuery.error &&
        reportMode !== REPORT_GET_MODES.DEFAULT
      ) {
        appState.setAlert(
          getReportQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_5000_MS,
        );
      }
    }, [getReportQuery.loading, getReportQuery.error, getReportQuery.data]);

    useEffect(() => {
      if (getReportPollQuery?.data?.getReport?.status === "GENERATED") {
        appState.setAlert(
          `Report "${getReportPollQuery.data.getReport.reportName}" is ready for download.`,
          "success",
          ALERT_VISIBILITY_IN_5000_MS,
        );
        entity.setEntities({
          ...entity,
          entities: entity.entities.map((report) => {
            if (report.id === getReportPollQuery.data.getReport.id) {
              return {
                ...report,
                id: getReportPollQuery.data.getReport.id,
                status: getReportPollQuery.data.getReport.status,
                reportName: getReportPollQuery.data.getReport.reportName,
                csvUrl: getReportPollQuery.data.getReport.csvUrl,
                previewUrl: getReportPollQuery.data.getReport.previewUrl,
              };
            }
            return report;
          }),
        });
      } else if (getReportPollQuery?.data?.getReport?.error) {
        appState.setAlert(
          getReportPollQuery?.data?.getReport?.error,
          "error",
          ALERT_VISIBILITY_IN_5000_MS,
        );
      }

      if (getReportPollQuery.error) {
        appState.setAlert(
          getReportQuery?.error?.message || "Failed to get report.",
          "error",
          ALERT_VISIBILITY_IN_5000_MS,
        );
      }
    }, [
      getReportPollQuery.data,
      getReportPollQuery.loading,
      getReportPollQuery.error,
    ]);

    const downloadCSV = (report) => {
      setReportMode(REPORT_GET_MODES.CSV);
      getReportQuery.fetchData({
        reportId: report.id,
      });
    };
    const downloadPDF = (id, module) => {
      setReportMode(REPORT_GET_MODES.PDF);
      getReportQuery.fetchData({
        reportId: id,
      });
    };

    const viewGeneratedReport = (report) => {
      setReportMode(REPORT_GET_MODES.PREVIEW);
      getReportQuery.fetchData({
        reportId: report.id,
      });
    };
    const editGeneratedReport = (report) => {
      setReportType(REPORT_TYPES_MODES.ONDEMAND);
      setSelectedReport(report);
    };

    const editScheduleViewReport = (report) => {
      setReportType(REPORT_TYPES_MODES.SCHEDULE);
      setOpenReportMode(CREATE_REPORT_MODES.VIEW);
      setSelectedReport(report);
      setCopyupSelectedReport(report);
    };

    const renameScheduleReport = (report) => {
      setReportType(REPORT_TYPES_MODES.SCHEDULE);
      setOpenReportMode(CREATE_REPORT_MODES.RENAME);
    };

    const menuItems = (report) => {
      const arr = [];
      if (selectedReportTab === reportsTabs[0]["key"]) {
        if (report.status === "GENERATED") {
          arr.push({
            title: "Report Preview",
            icon: <EyeIcon className="mr-2 h-5 w-5" />,
            onClick: viewGeneratedReport,
            disabled: false,
          });
        }
        if (
          report.status === "CREATED" ||
          report.status === "GENERATED" ||
          report.status === "GENERATION_FAILED"
        ) {
          arr.push({
            title: "Edit Report",
            icon: <EyeIcon className="mr-2 h-5 w-5" />,
            onClick: editGeneratedReport,
            disabled:
              !props.writable ||
              !["CREATED", "GENERATED", "GENERATION_FAILED"].includes(
                report.status,
              ),
          });
          arr.push({
            title: "Delete Report",
            icon: <TrashIcon className="mr-2 h-5 w-5" />,
            onClick: openGeneratedReportDeleteConfirmation,
            disabled:
              !props.writable ||
              !["CREATED", "GENERATED", "GENERATION_FAILED"].includes(
                report.status,
              ),
          });
        }
        if (report.status === "GENERATED") {
          arr.push({
            title: "Download CSV",
            icon: <PrinterIcon className="mr-2 h-5 w-5" />,
            onClick: downloadCSV,
            disabled: false,
          });
        }
      } else if (selectedReportTab === reportsTabs[1]["key"]) {
        if (report.status === "SCHEDULED") {
          arr.push({
            title: "View/Edit Schedule Details",
            icon: <EyeIcon className="mr-2 h-5 w-5" />,
            onClick: editScheduleViewReport,
            disabled: !props.writable || !["SCHEDULED"].includes(report.status),
          });
          arr.push({
            title: "Cancel Schedule",
            icon: <TrashIcon className="mr-2 h-5 w-5" />,
            onClick: openScheduledReportCancelConfirmation,
            disabled: !props.writable || !["SCHEDULED"].includes(report.status),
          });
        }
      }
      return arr;
    };

    useEffect(() => {
      if (auth && auth.user) {
        let filterSet = {
          keyword: null,
          module: null,
          role: "ADMIN",
          status: null,
          startDateRange: null,
          endDateRange: null,
        };
        if (auth.user?.role?.toLowerCase() !== "admin") {
          filterSet.customers = auth.user?.customers;
          filterSet.role = auth.user?.role;
        }
        entity.setFilters(filterSet);
        setTimeout(() => {
          getReportList(
            {
              perPage: entity.perPage,
              paginated: false,
              pageNumber: entity.pageNumber,
              sort: entity.sort,
              filters: filterSet,
            },
            selectedReportTab,
          );
        }, 500);
      }
      return () => {
        entity.resetEntities();
      };
    }, [auth]);

    useEffect(() => {
      reportsMetadataQuery.fetchData();
    }, []);

    useEffect(() => {
      if (reportsMetadataQuery.data) {
        setReportsMetadata(reportsMetadataQuery.data.reportsMetadata);
      }
    }, [
      reportsMetadataQuery.loading,
      reportsMetadataQuery.error,
      reportsMetadataQuery.data,
    ]);

    useEffect(() => {
      if (saveReportQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (saveReportQuery.data) {
        if (saveReportQuery?.data?.createReport?.message) {
          appState.setAlert(
            saveReportQuery.data.createReport.message,
            "success",
            ALERT_VISIBILITY_IN_5000_MS,
          );
          setSelectedReport(null);
          if (selectedReportTab === reportsTabs[1]["key"]) {
            setSelectedFilterReport(null);
            setSelectedReportTab(reportsTabs[0]["key"]);
            entity.setFilters({
              status: null,
              startDateRange: null,
              endDateRange: null,
              module: null,
              keyword: null,
              role: entity?.filters?.role,
            });
            entity.setSort({ sort: "-createdAt" });
            getReportList(
              {
                perPage: entity.perPage,
                pageNumber: 1,
                filters: {
                  status: null,
                  startDateRange: null,
                  endDateRange: null,
                  module: null,
                  keyword: null,
                  role: entity?.filters?.role,
                },
                sort: entity.sort,
              },
              reportsTabs[0]["key"],
            );
          } else {
            getReportList(
              {
                perPage: entity.perPage,
                pageNumber: 1,
                filters: { ...entity.filters },
                sort: entity.sort,
              },
              selectedReportTab,
            );
          }
        } else {
          appState.setAlert(
            saveReportQuery.data.createReport.error || "Failed to save report.",
            "error",
            ALERT_VISIBILITY_IN_5000_MS,
          );
        }
      } else if (saveReportQuery.error) {
        appState.setAlert(
          saveReportQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_5000_MS,
        );
      }
    }, [saveReportQuery.loading, saveReportQuery.error, saveReportQuery.data]);

    useEffect(() => {
      if (reportType === REPORT_TYPES_MODES.ONDEMAND) {
        autoRefreshInterval.current = setInterval(() => {
          const reports = entity.entities;
          for (const report of reports) {
            if (report.status === "GENERATING" || report.status === "CREATED") {
              setReportMode(REPORT_GET_MODES.DEFAULT);
              getReportPollQuery.fetchData({
                reportId: report.id,
              });
            }
          }
        }, 2000);
      }

      return () => {
        clearInterval(autoRefreshInterval.current);
      };
    }, [entity.entities]);

    const selectReport = (id) => {
      setSelectedReport({ id });
    };

    useEffect(() => {
      if (startReportGenerationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (startReportGenerationQuery.data) {
        if (startReportGenerationQuery?.data?.generateReport?.message) {
          appState.setAlert(
            startReportGenerationQuery.data.generateReport.message,
            "success",
            ALERT_VISIBILITY_IN_5000_MS,
          );
          getReportList(
            {
              perPage: entity.perPage,
              pageNumber: 1,
              filters: { ...entity.filters },
              sort: entity.sort,
            },
            selectedReportTab,
          );
        } else {
          appState.setAlert(
            startReportGenerationQuery?.data?.generateReport?.error ||
              "Failed to generate report.",
            "error",
            ALERT_VISIBILITY_IN_5000_MS,
          );
        }
      } else if (startReportGenerationQuery.error) {
        appState.setAlert(
          startReportGenerationQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_5000_MS,
        );
      }
    }, [
      startReportGenerationQuery.loading,
      startReportGenerationQuery.error,
      startReportGenerationQuery.data,
    ]);

    const queueReportGeneration = (report) => {
      startReportGenerationQuery.fetchData({
        generateReportId: report.id,
        reportType: report.module,
      });
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };

        return getReportList(vars, selectedReportTab);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const setPagination = (page) => {
      entity.paginate({ pageNumber: page });
      const vars = {
        perPage: entity.perPage,
        pageNumber: page,
        filters: entity.filters,
        paginated: true,
        sort: entity.sort,
      };

      return getReportList(vars, selectedReportTab);
    };

    const onChange = (event) => {
      let { name, value, type } = event.target;
      if (type === "number") {
        value = parseInt(value);
      } else if (name === "reportName") {
        /**
         * ensure that the report name value only contains:
         * 1. alphanumeric characters
         * 2. spaces
         * 3. underscores
         * 4. dashes
         */
        value = value.replace(/[^a-zA-Z0-9 _-]/g, "");
      }

      if (showFilters) {
        setSelectedFilterReport((prevReport) => ({
          ...prevReport,
          [name]: value,
        }));
      } else {
        setSelectedReport((prevReport) => ({
          ...prevReport,
          [name]: value,
        }));
      }
    };

    const onChangeDropdown = (field, value) => {
      const report = {
        ...selectedReport,
      };
      report[field] = value;
      if (field === "module") {
        report.selectedVariables = [];
      }
      setSelectedReport(report);
    };

    const onChangeDropdownMulti = (field, value) => {
      const report = {
        ...selectedReport,
      };
      if (!report[field]) {
        report[field] = [];
      }
      if (report[field].includes(value)) {
        report[field] = report[field].filter((item) => item !== value);
      } else {
        report[field] = [...report[field], value];
      }

      setSelectedReport(report);
    };

    useEffect(() => {
      if (updateReportQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (updateReportQuery.data) {
        if (updateReportQuery?.data?.updateReport?.message) {
          appState.setAlert(
            updateReportQuery.data.updateReport.message,
            "success",
            ALERT_VISIBILITY_IN_5000_MS,
          );
          setSelectedReport(null);
          getReportList(
            {
              perPage: entity.perPage,
              pageNumber: 1,
              filters: { ...entity.filters },
              sort: entity.sort,
            },
            selectedReportTab,
          );
        } else {
          appState.setAlert(
            updateReportQuery.data.updateReport.error ||
              "Failed to update report.",
            "error",
            ALERT_VISIBILITY_IN_5000_MS,
          );
        }
      } else if (updateReportQuery.error) {
        appState.setAlert(
          updateReportQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_5000_MS,
        );
      }
    }, [
      updateReportQuery.loading,
      updateReportQuery.error,
      updateReportQuery.data,
    ]);

    const saveReport = async (report) => {
      if (reportType === REPORT_TYPES_MODES.ONDEMAND) {
        appState.setLoading();
        if (!report.customers || report.customers?.length === 0) {
          if (auth.user?.role?.toLowerCase() === "admin") {
            report.customers = [];
          }
          report.customers = auth.user?.customers;
        }
        if (!report.warehouses) {
          report.warehouses = auth.user?.warehouses;
        }
        const generatedReportInput = {
          customers: report?.customers,
          endDate: report?.endDate,
          module: report?.module,
          reportName: report?.reportName,
          selectedVariables: report?.selectedVariables,
          startDate: report?.startDate,
          warehouses: report?.warehouses,
        };
        if (report.id) {
          generatedReportInput["id"] = report?.id;
          updateReportQuery.fetchData({
            updateReportInput: generatedReportInput,
          });
        } else {
          saveReportQuery.fetchData({
            createReportInput: generatedReportInput,
          });
        }
      } else if (reportType === REPORT_TYPES_MODES.SCHEDULE) {
        appState.setLoading();
        if (!report.customers || report.customers?.length === 0) {
          if (auth.user?.role?.toLowerCase() === "admin") {
            report.customers = [];
          }
          report.customers = auth.user?.customers;
        }
        if (!report.warehouses) {
          report.warehouses = auth.user?.warehouses;
        }

        const reportInput = {
          ...report,
        };
        if (
          reportInput?.cron !== copySelectedReport?.cron ||
          reportInput?.scheduleRunDate !== copySelectedReport?.scheduleRunDate
        ) {
          const isValidFormat = moment(
            selectedReport?.scheduleRunDate,
            "DD-MM-YYYY",
            true,
          ).isValid();
          const isValid = moment(
            selectedReport?.scheduleStartTime,
            "hh:mm A",
            true,
          ).isValid();
          if (isValidFormat) {
            reportInput.scheduleRunDate = moment(
              reportInput.scheduleRunDate,
              "DD-MM-YYYY",
            ).format("YYYY-MM-DD");
          }
          if (isValid) {
            reportInput.scheduleStartTime = moment(
              reportInput.scheduleStartTime,
              "hh:mm A",
            ).format("HH:mm");
          }
          reportInput.nextRun = moment(
            reportInput.scheduleRunDate + " " + reportInput.scheduleStartTime,
            "YYYY-MM-DD HH:mm",
          )
            .utc()
            .unix();
        }

        const scheduleReportInput = {
          cron: reportInput?.cron,
          customers: reportInput?.customers,
          frequency: reportInput?.frequency,
          localCron: reportInput?.localCron,
          module: reportInput?.module,
          nextRun: reportInput?.nextRun,
          recipients: reportInput?.recipients,
          scheduleRunDate: reportInput?.scheduleRunDate,
          scheduleStartDate: reportInput?.scheduleStartDate,
          scheduleStartTime: reportInput?.scheduleStartTime,
          selectedVariables: reportInput?.selectedVariables,
          warehouses: reportInput?.warehouses,
        };

        if (report.id) {
          scheduleReportInput["id"] = report?.id;
          const updateScheduleReportResponse =
            await updateScheduleReportQuery.fetchData({
              updateJobInput: scheduleReportInput,
            });
          appState.removeLoading();
          if (updateScheduleReportResponse.error) {
            appState.setAlert(
              updateScheduleReportResponse.error.message,
              "error",
              ALERT_VISIBILITY_IN_5000_MS,
            );
          } else if (updateScheduleReportResponse.data) {
            if (updateScheduleReportResponse?.data?.updateJob?.message) {
              appState.setAlert(
                updateScheduleReportResponse.data.updateJob.message,
                "success",
                ALERT_VISIBILITY_IN_5000_MS,
              );
              setSelectedReport(null);
              setOpenReportMode(null);
              setReportType(null);
              getReportList(
                {
                  perPage: entity.perPage,
                  pageNumber: 1,
                  filters: { ...entity.filters },
                  sort: entity.sort,
                },
                selectedReportTab,
              );
            } else {
              appState.setAlert(
                updateScheduleReportResponse.data.updateJob.error ||
                  "Failed to update job.",
                "error",
                ALERT_VISIBILITY_IN_5000_MS,
              );
            }
          }
        } else {
          const saveScheduleReportResponse =
            await saveScheduleReportQuery.fetchData({
              createJobInput: scheduleReportInput,
            });
          appState.removeLoading();
          if (saveScheduleReportResponse.error) {
            appState.setAlert(
              saveScheduleReportResponse.error.message,
              "error",
              ALERT_VISIBILITY_IN_5000_MS,
            );
          } else if (saveScheduleReportResponse.data) {
            if (saveScheduleReportResponse?.data?.createJob?.message) {
              appState.setAlert(
                saveScheduleReportResponse.data.createJob.message,
                "success",
                ALERT_VISIBILITY_IN_5000_MS,
              );
              setSelectedReport(null);
              setOpenReportMode(null);
              setReportType(null);
              setCopyupSelectedReport(null);
              if (selectedReportTab === reportsTabs[0]["key"]) {
                setSelectedFilterReport(null);
                setSelectedReportTab(reportsTabs[1]["key"]);
                entity.setFilters({
                  module: null,
                  frequency: null,
                  keyword: null,
                  role: entity?.filters?.role,
                });
                entity.setSort({ sort: "-createdAt" });
                getReportList(
                  {
                    perPage: entity.perPage,
                    pageNumber: 1,
                    filters: {
                      module: null,
                      frequency: null,
                      keyword: null,
                      role: entity?.filters?.role,
                    },
                    sort: entity.sort,
                  },
                  reportsTabs[1]["key"],
                );
              } else {
                getReportList(
                  {
                    perPage: entity.perPage,
                    pageNumber: 1,
                    filters: { ...entity.filters },
                    sort: entity.sort,
                  },
                  selectedReportTab,
                );
              }
            } else {
              appState.setAlert(
                saveScheduleReportResponse.data.createJob.error ||
                  "Failed to save job.",
                "error",
                ALERT_VISIBILITY_IN_5000_MS,
              );
            }
          }
        }
      }
    };

    const openScheduledReportCancelConfirmation = (report) => {
      setReportType(REPORT_TYPES_MODES.SCHEDULE);
      setOpenReportMode(CREATE_REPORT_MODES.CANCEL);
      setSelectedReport(report);
      if (report) {
        appState.showNewConfirmation(
          "Scheduled Report",
          <span>
            Are you sure you want to delete the scheduled report for{" "}
            <span className="font-semibold text-primaryAccent">
              {report?.module}
            </span>
            ? This action cannot be undone.
          </span>,
          () => confirmCancelReport(report),
          () => {
            setReportMode(null);
            setReportType(null);
            setSelectedReport(null);
            setOpenReportMode(null);
          },
        );
      }
    };

    const confirmCancelReport = async (report) => {
      if (report && report?.id) {
        appState.setLoading();
        const cancelScheduleReportResponse = await deleteJobQuery.fetchData({
          deleteJobId: report.id,
        });
        appState.removeLoading();
        if (cancelScheduleReportResponse.error) {
          appState.setAlert(
            cancelScheduleReportResponse.error.message,
            "error",
            ALERT_VISIBILITY_IN_5000_MS,
          );
          setReportMode(null);
          setReportType(null);
          setSelectedReport(null);
          setOpenReportMode(null);
        } else if (cancelScheduleReportResponse.data) {
          if (cancelScheduleReportResponse?.data?.deleteJob?.message) {
            appState.setAlert(
              cancelScheduleReportResponse.data.deleteJob.message,
              "success",
              ALERT_VISIBILITY_IN_5000_MS,
            );
            setReportMode(null);
            setReportType(null);
            setSelectedReport(null);
            setOpenReportMode(null);

            getReportList(
              {
                perPage: entity.perPage,
                pageNumber: 1,
                filters: { ...entity.filters },
                sort: entity.sort,
              },
              selectedReportTab,
            );
          } else {
            setReportMode(null);
            setReportType(null);
            setSelectedReport(null);
            setOpenReportMode(null);
            appState.setAlert(
              cancelScheduleReportResponse.data.createJob.error ||
                "Failed to delete schedule report.",
              "error",
              ALERT_VISIBILITY_IN_5000_MS,
            );
          }
        }
      }
    };

    useEffect(() => {
      if (deleteReportQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (deleteReportQuery.data) {
        if (deleteReportQuery?.data?.deleteReport?.message) {
          appState.setAlert(
            deleteReportQuery.data.deleteReport.message,
            "success",
            ALERT_VISIBILITY_IN_5000_MS,
          );
          setReportMode(null);
          setReportType(null);
          setSelectedReport(null);
          setOpenReportMode(null);
          getReportList(
            {
              perPage: entity.perPage,
              pageNumber: 1,
              filters: { ...entity.filters },
              sort: entity.sort,
            },
            selectedReportTab,
          );
        } else {
          setReportMode(null);
          setReportType(null);
          setSelectedReport(null);
          setOpenReportMode(null);
          appState.setAlert(
            deleteReportQuery?.data?.deleteReport?.error ||
              "Failed to delete report.",
            "error",
            ALERT_VISIBILITY_IN_5000_MS,
          );
        }
      } else if (deleteReportQuery.error) {
        setReportMode(null);
        setReportType(null);
        setSelectedReport(null);
        setOpenReportMode(null);
        appState.setAlert(
          deleteReportQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_5000_MS,
        );
      }
    }, [
      deleteReportQuery.loading,
      deleteReportQuery.error,
      deleteReportQuery.data,
    ]);

    const openGeneratedReportDeleteConfirmation = (report) => {
      setReportType(REPORT_TYPES_MODES.ONDEMAND);
      setOpenReportMode(CREATE_REPORT_MODES.DELETE);
      setSelectedReport(report);
      if (report) {
        appState.showNewConfirmation(
          "Generated Report",
          <span>
            Are you sure you want to delete{" "}
            <span className="font-semibold text-primaryAccent">
              {report?.reportName}
            </span>
            ? This action cannot be undone.
          </span>,
          () => confirmDeleteGeneratedReport(report),
          () => {
            setReportMode(null);
            setReportType(null);
            setSelectedReport(null);
            setOpenReportMode(null);
          },
        );
      }
    };

    const confirmDeleteGeneratedReport = (report) => {
      if (report && report?.id && report?.module) {
        deleteReportQuery.fetchData({
          deleteReportId: report.id,
          module: report.module,
        });
      }
    };

    const createReport = (reportType) => {
      setReportType(reportType);
      setOpenReportMode(reportType);
      setSelectedReport({
        selectedVariables: [],
      });
    };

    const onChangeFrequency = (field, value) => {
      const report = {
        ...selectedReport,
      };
      report[field] = value;
      setSelectedReport(report);
    };

    const parseCronExpression = (cronExpression) => {
      const cronParts = cronExpression.split(" ");

      if (cronParts.length !== 5) {
        throw new Error("Invalid cron expression");
      }
      const minute = cronParts[0];
      const hour = cronParts[1];
      const time = moment(hour + " " + minute, "HH:mm A").format("hh:mm A");
      const dayOfMonth = cronParts[2];
      const month = cronParts[3];
      const dayOfWeek = cronParts[4];
      let humanReadable = `Occurs At ${time} `;

      if (dayOfMonth !== "*" && month === "*" && dayOfWeek === "*") {
        humanReadable += ` on day ${dayOfMonth} of every month`;
      } else if (dayOfMonth === "*" && month === "*" && dayOfWeek !== "*") {
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        humanReadable += ` every ${days[dayOfWeek]}`;
      } else if (dayOfMonth === "*" && month === "*" && dayOfWeek === "*") {
        humanReadable += ` every day`;
      }

      return humanReadable;
    };

    const getReportList = async (requestData, selectedTab) => {
      if (!requestData) {
        return;
      }
      if (selectedTab === reportsTabs[0]["key"]) {
        appState.setLoading();
        requestData["filters"]["startDateRange"] = requestData["filters"][
          "startDateRange"
        ]
          ? moment(
              requestData["filters"]["startDateRange"] + " 00:00:00",
              "YYYY-MM-DD HH:mm:ss",
            )
              .utc()
              .unix()
          : null;
        requestData["filters"]["endDateRange"] = requestData["filters"][
          "endDateRange"
        ]
          ? moment(
              requestData["filters"]["endDateRange"] + " 23:59:59",
              "YYYY-MM-DD HH:mm:ss",
            )
              .utc()
              .unix()
          : null;
        const getGeneratedReportResponse =
          await reportsQuery.fetchData(requestData);
        appState.removeLoading();
        if (getGeneratedReportResponse.error) {
          console.log(getGeneratedReportResponse.error);
          const unixToLocalStartDateRange =
            requestData?.filters["startDateRange"] &&
            requestData?.filters["startDateRange"] !== null
              ? moment
                  .unix(requestData?.filters["startDateRange"])
                  .format("YYYY-MM-DD")
              : null;
          const unixToLocalEndDateRange =
            requestData?.filters["endDateRange"] &&
            requestData?.filters["endDateRange"] !== null
              ? moment
                  .unix(requestData?.filters["endDateRange"])
                  .format("YYYY-MM-DD")
              : null;
          entity.setFilters({
            ...requestData.filters,
            startDateRange: unixToLocalStartDateRange,
            endDateRange: unixToLocalEndDateRange,
          });
        } else if (getGeneratedReportResponse.data) {
          let responseGeneratedReports = getGeneratedReportResponse?.data
            ?.getReports?.entities
            ? getGeneratedReportResponse?.data?.getReports?.entities
            : [];
          responseGeneratedReports = responseGeneratedReports.map((report) => {
            return {
              ...report,
              createdAtDate:
                report?.createdAt !== null
                  ? moment.unix(report?.createdAt).format("DD MMM YYYY")
                  : null,
            };
          });
          let generatedReportEntitity =
            getGeneratedReportResponse?.data?.getReports;
          generatedReportEntitity["entities"] = responseGeneratedReports;
          entity.setEntities({
            ...generatedReportEntitity,
            ...getGeneratedReportResponse.variables,
            paginated: false,
          });

          const unixToLocalStartDateRange =
            requestData?.filters["startDateRange"] &&
            requestData?.filters["startDateRange"] !== null
              ? moment
                  .unix(requestData?.filters["startDateRange"])
                  .format("YYYY-MM-DD")
              : null;
          const unixToLocalEndDateRange =
            requestData?.filters["endDateRange"] &&
            requestData?.filters["endDateRange"] !== null
              ? moment
                  .unix(requestData?.filters["endDateRange"])
                  .format("YYYY-MM-DD")
              : null;
          entity.setFilters({
            ...requestData.filters,
            startDateRange: unixToLocalStartDateRange,
            endDateRange: unixToLocalEndDateRange,
          });
        }
      } else if (selectedTab === reportsTabs[1]["key"]) {
        appState.setLoading();
        const getScheduleReportResponse =
          await scheduleReportsQuery.fetchData(requestData);
        appState.removeLoading();
        if (getScheduleReportResponse.error) {
          console.log(getScheduleReportResponse.error);
        } else if (getScheduleReportResponse.data) {
          let sheduledReportsEntity = getScheduleReportResponse?.data?.jobs
            ?.entities
            ? getScheduleReportResponse?.data?.jobs?.entities
            : [];
          if (
            sheduledReportsEntity &&
            sheduledReportsEntity !== null &&
            sheduledReportsEntity?.length !== 0
          ) {
            sheduledReportsEntity = sheduledReportsEntity.map((job) => {
              return {
                ...job,
                nextRunDate:
                  job?.nextRun !== null
                    ? moment.unix(job?.nextRun).format("DD MMM YYYY hh:mm A")
                    : null,
                lastRunDate:
                  job?.lastRun !== null
                    ? moment.unix(job?.lastRun).format("DD MMM YYYY hh:mm A")
                    : null,
                frequencyCap:
                  job?.frequency !== null
                    ? job?.frequency.charAt(0).toUpperCase() +
                      job?.frequency.slice(1).toLowerCase()
                    : null,
              };
            });
            let reponseScheduleJobsData = getScheduleReportResponse?.data?.jobs;
            reponseScheduleJobsData["entities"] = sheduledReportsEntity;
            entity.setEntities({
              ...reponseScheduleJobsData,
              ...getScheduleReportResponse.variables,
              paginated: false,
            });
            entity.paginate({ pageNumber: requestData.pageNumber });
          } else {
            entity.setEntities({
              ...getScheduleReportResponse.data.jobs,
              ...getScheduleReportResponse.variables,
              paginated: false,
            });
          }
        }
      }
    };

    useEffect(() => {
      loadFirstTimeData();
    }, [auth]);

    const loadFirstTimeData = async () => {
      if (auth && auth.user) {
        const getAllUsersResponse = await allUsersQuery.fetchData({
          filters: {
            all: true,
            role: ["ADMIN"],
          },
          sort: "-createdAt",
        });
        if (getAllUsersResponse.error) {
          console.log(getAllUsersResponse.error);
          setUsers([]);
        } else if (getAllUsersResponse.data) {
          setUsers(
            getAllUsersResponse?.data?.users.entities.filter(
              (user) =>
                user.name !== null &&
                user.email !== null &&
                user.email === auth?.user?.email,
            ) ?? [],
          );
        }
      }
    };

    useEffect(() => {
      setSelectedReportTab(selectedReportTab);
    }, [selectedReportTab]);

    return (
      <WrappedComponent
        {...props}
        allUsers={users}
        fetchedReport={getReportQuery?.data?.getReport}
        previewData={previewData}
        menuItems={menuItems}
        setPreviewData={setPreviewData}
        reports={entity.entities}
        total={entity.total}
        selectReport={selectReport}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        writable={props.writable}
        perPage={entity.perPage}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        onChangeDropdownMulti={onChangeDropdownMulti}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          getReportList(
            {
              perPage,
              pageNumber: 1,
              filters: { ...entity.filters },
              sort: entity.sort,
            },
            selectedReportTab,
          );
        }}
        submitFilters={() => {
          setShowFilters(false);
          getReportList(
            {
              perPage: entity.perPage,
              pageNumber: 1,
              filters: { ...entity.filters },
              sort: entity.sort,
            },
            selectedReportTab,
          );
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          getReportList(
            {
              perPage: entity.perPage,
              pageNumber: 1,
              filters: { ...entity.filters, keyword: null },
              sort: entity.sort,
            },
            selectedReportTab,
          );
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          let filterSet = {};
          if (
            field &&
            field === "dateRange" &&
            value !== null &&
            value.length !== 0
          ) {
            entity.setFilters({
              ...entity.filters,
              startDateRange: value[0],
              endDateRange: value[1],
            });
            filterSet = {
              ...entity.filters,
              startDateRange: value[0],
              endDateRange: value[1],
            };
          } else if (field && field === "dateRange" && value === null) {
            entity.setFilters({
              ...entity.filters,
              startDateRange: null,
              endDateRange: null,
            });
            filterSet = {
              ...entity.filters,
              startDateRange: null,
              endDateRange: null,
            };
          } else {
            entity.setFilters({
              ...entity.filters,
              [field]: value,
            });
            filterSet = {
              ...entity.filters,
              [field]: value,
            };
          }
          if (autoSubmit) {
            getReportList(
              {
                perPage: entity.perPage,
                pageNumber: 1,
                filters: {
                  ...filterSet,
                },
                sort: entity.sort,
              },
              selectedReportTab,
            );
          }
        }}
        onChangeSearchKeyword={(event) =>
          entity.setFilters({
            ...entity.filters,
            keyword: event.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          getReportList(
            {
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
              },
              sort,
            },
            selectedReportTab,
          );
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        setSelectedReport={setSelectedReport}
        selectedReport={selectedReport}
        clearFilters={() => {
          let filterSet = {};
          if (selectedReportTab === reportsTabs[0]["key"]) {
            filterSet = {
              status: null,
              startDateRange: null,
              endDateRange: null,
              module: null,
              keyword: null,
              role: "ADMIN",
            };
            if (auth.user?.role?.toLowerCase() !== "admin") {
              filterSet.role = auth.user?.role;
            }
            entity.setFilters(filterSet);
          } else if (selectedReportTab === reportsTabs[1]["key"]) {
            filterSet = {
              module: null,
              frequency: null,
              keyword: null,
              role: "ADMIN",
            };
            if (auth.user?.role?.toLowerCase() !== "admin") {
              filterSet.role = auth.user?.role;
            }
            entity.setFilters(filterSet);
          }
          setSelectedFilterReport(null);
          getReportList(
            {
              perPage: entity.perPage,
              pageNumber: 1,
              filters: filterSet,
              sort: entity.sort,
            },
            selectedReportTab,
          );
        }}
        saveReport={saveReport}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        reportsMetadata={reportsMetadata}
        createReport={createReport}
        reportModalModesEnum={CREATE_REPORT_MODES}
        reportTypeModesEnum={REPORT_TYPES_MODES}
        openReportMode={openReportMode}
        setOpenReportMode={setOpenReportMode}
        reportType={reportType}
        setReportType={setReportType}
        onChangeFrequency={onChangeFrequency}
        parseCronExpression={parseCronExpression}
        selectedReportTab={selectedReportTab}
        setSelectedReportTab={(tab) => {
          setSelectedFilterReport(null);
          if (tab === reportsTabs[0]["key"]) {
            entity.setEntities({
              ...entity,
              entities: [],
              total: 0,
            });
            setSelectedReportTab(tab);
            entity.setSort({ sort: "-createdAt" });
            entity.setFilters({
              status: null,
              startDateRange: null,
              endDateRange: null,
              module: null,
              keyword: null,
              role: entity?.filters?.role,
            });
            getReportList(
              {
                perPage: entity.perPage,
                pageNumber: 1,
                filters: {
                  status: null,
                  startDateRange: null,
                  endDateRange: null,
                  module: null,
                  keyword: null,
                  role: entity?.filters?.role,
                },
                sort: "-createdAt",
              },
              tab,
            );
          } else if (tab === reportsTabs[1]["key"]) {
            setSelectedReportTab(tab);
            entity.setEntities({
              ...entity,
              entities: [],
              total: 0,
            }),
              entity.setFilters({
                module: null,
                frequency: null,
                keyword: null,
                role: entity?.filters?.role,
              });
            entity.setSort({ sort: "nextRun" });
            getReportList(
              {
                perPage: entity.perPage,
                pageNumber: 1,
                filters: {
                  module: null,
                  frequency: null,
                  keyword: null,
                  role: entity?.filters?.role,
                },
                sort: "nextRun",
              },
              tab,
            );
          }
        }}
        reportsTabs={reportsTabs}
        setPagination={setPagination}
        selectedFilterReport={selectedFilterReport}
        FREQUENCY={FREQUENCY}
        copySelectedReport={copySelectedReport}
        setSelectedFilterReport={setSelectedFilterReport}
        setReportMode={setReportMode}
        openScheduledReportCancelConfirmation={
          openScheduledReportCancelConfirmation
        }
      />
    );
  };
};

export default withReportsLogic;
