const AmazonPrepInstructions = ({
  prepInstructions,
  title = "Amazon Prep Instructions",
}) => (
  <div className="flex w-full space-x-1 shadow-md">
    <div className="flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 border-primaryAccent bg-white p-4 text-2C7695">
      {title}
    </div>
    <div
      className={`flex-1 space-y-2 rounded-br-md rounded-tr-md border bg-white p-4`}>
      {prepInstructions?.map((item, idx) => (
        <div key={idx} className="flex">
          Instruction: {item.PrepInstruction} | Owner: {item.PrepOwner}
        </div>
      ))}
    </div>
  </div>
);

export default AmazonPrepInstructions;
