const FilledButton = ({ text, colour, onClick, className }) => {
  return (
    <div
      className={`rounded px-4 py-1.5 bg-${colour} cursor-pointer ${className} `}
      onClick={onClick}>
      <div className={`text-sm font-semibold text-white`}>{text}</div>
    </div>
  );
};
export default FilledButton;
