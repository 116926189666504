import Select from "react-select";

const PickerNewBatchRequest = ({
  getNewBatch,
  warehouses,
  batchfilters,
  onChangeDropdown,
}) => (
  <div className="flex h-screen items-center justify-center">
    <div className="items-center justify-center space-x-4">
      <div className="mb-10 flex w-1/2 min-w-1/2 items-center gap-20 p-5">
        {warehouses && warehouses.length > 1 && (
          <div className="w-50">
            <Select
              options={
                warehouses &&
                warehouses.map((item) => ({
                  value: item.id,
                  label: `${item.name} - ${item.code}`,
                }))
              }
              value={batchfilters?.warehouse}
              onChange={(selectedOption) => {
                onChangeDropdown("warehouse", selectedOption);
              }}
              className="w-96"
              placeholder="Select Warehouse"
              isClearable={true}
            />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center self-center py-10">
        <div
          className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
          onClick={getNewBatch}>
          Get New Bundle
        </div>
      </div>
    </div>
  </div>
);

export default PickerNewBatchRequest;
