import React from "react";
import PropTypes from "prop-types";

const InputWithSelect = ({ inputProps = {}, selectProps = {} }) => {
  return (
    <div className="inline-flex w-full">
      <input
        {...inputProps}
        className={`w-20 rounded-l border-r border-gray-300 p-2 text-center focus:outline-none ${
          inputProps.disabled ? "bg-gray-200" : "bg-white"
        }`}
      />
      <select
        {...selectProps}
        className={`w-20 cursor-pointer rounded-r border-gray-300 p-2 focus:outline-none ${
          selectProps.disabled ? "bg-gray-200" : "bg-white"
        }`}>
        {selectProps.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

// PropTypes validation
InputWithSelect.propTypes = {
  inputProps: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  selectProps: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    disabled: PropTypes.bool,
  }),
};

export default InputWithSelect;
