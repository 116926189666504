import { useState } from "react";
import Modal from "#components/utils/Modal";
import Toggle from "#components/utils/Toggle";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import RegularPackProducts from "./RegularPackProducts";
import CasePackProducts from "./CasePackProducts";

const ExpandedEligibleProducts = ({
  eligibleProducts,
  negativeAction,
  onChangeItem,
  setEligibleProducts,
  onSubmitReleaseInventory,
  shipmentConfiguration,
  setShipmentConfiguration,
  boxContentsOptions,
  packingMethodOptions,
  bundlesWithOverlap,
  setBundlesWithOverlap,
  selectedClient,
  selectedWarehouse,
}) => {
  const [keyword, setKeyword] = useState("");
  const filteredEligibleProducts = () => {
    if (!keyword || keyword.trim() === "") {
      return eligibleProducts;
    } else {
      return (
        eligibleProducts?.filter((item) => {
          return (
            item.asin?.toLowerCase().includes(keyword.toLowerCase()) ||
            item.sku?.toLowerCase().includes(keyword.toLowerCase()) ||
            item.fnSku?.toLowerCase().includes(keyword.toLowerCase()) ||
            item.name?.toLowerCase().includes(keyword.toLowerCase()) ||
            item.upc?.includes(keyword) ||
            item.lpn?.includes(keyword)
          );
        }) || []
      );
    }
  };
  return (
    <Modal
      negativeAction={negativeAction}
      title={`Eligible Products for ${selectedClient} from ${selectedWarehouse}`}
      noPadding={true}
      positiveAction={onSubmitReleaseInventory}
      positiveText="Release"
      minWidth={1280}>
      <div className="w-96 p-4">
        <div>
          <div className="pl-2 font-montserrat text-xl font-bold">
            Box Contents Source
          </div>
        </div>
        <div className="p-2">
          <Dropdown
            placeholder={"Select Role"}
            list={boxContentsOptions}
            labelKey="name"
            valueKey="value"
            name="intendedBoxContentsSource"
            setSelected={(e) =>
              setShipmentConfiguration({
                ...shipmentConfiguration,
                intendedBoxContentsSource: e,
              })
            }
            selectedValue={shipmentConfiguration.intendedBoxContentsSource}
          />
        </div>
      </div>

      <div className="w-96 p-4 pt-0">
        <div>
          <div className="pl-2 font-montserrat text-xl font-bold">
            Packing Method
          </div>
        </div>
        <div className="p-2">
          <Dropdown
            placeholder={"Packing Method"}
            list={packingMethodOptions}
            labelKey="name"
            valueKey="value"
            name="isCasePack"
            setSelected={(e) =>
              setShipmentConfiguration({
                ...shipmentConfiguration,
                isCasePack: e,
              })
            }
            selectedValue={shipmentConfiguration.isCasePack}
          />
        </div>
      </div>
      <div className="ml-2 flex items-center space-x-4 px-2">
        <div>
          <Toggle
            enabled={shipmentConfiguration.retry === true}
            setEnabled={(e) =>
              setShipmentConfiguration({ ...shipmentConfiguration, retry: e })
            }
          />
        </div>
        <div className="text-lg">Automatic Retry upon failure</div>
        <button
          data-tip
          data-for="retryShipmentTips"
          className="-mt-2 h-5 w-5 border-none bg-transparent pl-2">
          <ExclamationCircleIcon className="h-8 w-8 cursor-pointer" />
        </button>
        <ReactTooltip
          id="retryShipmentTips"
          place="right"
          type="info"
          effect="solid">
          <div className="max-w-xl whitespace-normal text-xl">
            When items get rejected by Amazon, enabling this option would drop
            those flagged items and retry the shipment creation automatically.
          </div>
        </ReactTooltip>
      </div>
      {shipmentConfiguration.retry && (
        <div className="w-96 p-4 pt-0">
          <div>
            <div className="pl-2 font-montserrat text-xl font-bold">
              Min Shipment Size
            </div>
          </div>
          <div className="p-2">
            <input
              type="number"
              onChange={(e) =>
                setShipmentConfiguration({
                  ...shipmentConfiguration,
                  minItemLen:
                    isNaN(parseInt(e.target.value)) == false
                      ? parseInt(e.target.value)
                      : null,
                })
              }
              value={shipmentConfiguration.minItemLen}
              min="0"
            />
          </div>
        </div>
      )}

      {shipmentConfiguration.isCasePack === false && (
        <RegularPackProducts
          eligibleProducts={filteredEligibleProducts()}
          onChangeItem={onChangeItem}
          setEligibleProducts={setEligibleProducts}
          setKeyword={setKeyword}
          keyword={keyword}
          bundlesWithOverlap={bundlesWithOverlap}
          setBundlesWithOverlap={setBundlesWithOverlap}
        />
      )}

      {shipmentConfiguration.isCasePack === true && (
        <CasePackProducts
          eligibleProducts={filteredEligibleProducts()}
          onChangeItem={onChangeItem}
          setEligibleProducts={setEligibleProducts}
          setKeyword={setKeyword}
          keyword={keyword}
          bundlesWithOverlap={bundlesWithOverlap}
          setBundlesWithOverlap={setBundlesWithOverlap}
        />
      )}
    </Modal>
  );
};

export default ExpandedEligibleProducts;

const truncate = (str) => {
  return str.length > 40 ? str.substring(0, 40) + "..." : str;
};
