import moment from "moment-timezone";
import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import SearchSortFilter from "../common/SearchSortFilter";
import Pagination from "#components/common/Pagination";

const getStatusChip = (activated) => {
  const chipStyle = "p-1 rounded-full m-auto text-center";

  return (
    <div
      className={
        chipStyle +
        (activated
          ? " bg-activeTextColor/20 text-activeTextColor"
          : " bg-inActiveTextColor/20 text-inActiveTextColor")
      }>
      {activated ? "Active" : "Inactive"}
    </div>
  );
};

const WarehousesList = ({
  warehouses,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  noValuesText,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearFilters,
  setSort,
  sort,
  setShowFilters,
  clearKeyword,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
}) => {
  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={null}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Name",
            value: "name",
          },
          {
            name: "Code",
            value: "code",
          },
          {
            name: "Last Updated",
            value: "updatedAt",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
          <tr className="border-left font-montserrat text-textWhite">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {warehouses.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="rounded-bl rounded-tl border-l-8 border-F4C261 p-5 text-left font-semibold tracking-wider text-primaryAccent"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {warehouses.map((warehouse, index) => (
            <tr
              key={warehouse.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`}>
              <td
                className={`rounded-bl rounded-tl border-l-8 p-5 text-left font-semibold tracking-wider text-primaryAccent ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {warehouse.name}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {warehouse.code}
              </td>
              {/* <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {warehouse.location}
              </td> */}
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {warehouse.typeOfWarehouse?.join(", ")}
              </td>
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {getStatusChip(warehouse.active)}
              </td>
              {/* <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {warehouse.splitOrdersEnabled ? "Yes" : "No"}
              </td> */}
              <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {moment(warehouse.updatedAt).format("MMM Do, YYYY")}
              </td>
              <td className="rounded-br rounded-tr px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                <div className="flex items-center space-x-4">
                  <TableFieldButton
                    onClick={() => editButtonClicked(warehouse)}
                    text={<PencilIcon className="h-6 w-6" />}
                    disabled={!writable}
                  />
                  <TableFieldButton
                    text={<TrashIcon className="h-6 w-6" />}
                    onClick={() => deleteButtonClicked(warehouse.id)}
                    disabled={!writable}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTableWithTheme>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default WarehousesList;
