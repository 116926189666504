import PageTitle from "#components/utils/PageTitle";
import withVendorLogic from "#components/HOC/withVendorLogic";
import VendorList from "#components/vendor/VendorList";
import { UserAddIcon } from "@heroicons/react/outline";
import _ from "lodash";
import Pagination from "#components/common/Pagination";
import VendorForm from "#components/vendor/VendorForm";
import AddButton from "#components/utils/AddButton";
import VendorFilters from "#components/vendor/VendorFilters";
import BulkUpload from "../components/bulkUpload/UploadBulk";

const VendorManagement = ({
  writable,
  vendors,
  displayVendors,
  masterData,
  filters,
  onChangeFilter,
  getAudit,
  setItems,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  showFilters,
  clearFilters,
  sort,
  setSort,
  customers,
  warehouses,
  addNewVendor,
  selectedVendor,
  setSelectedVendor,
  onChange,
  saveVendor,
  onChangeDropdown,
  availableInventory,
  onChangeInventorySearch,
  editVendor,
  editVendorNotes,
  deleteVendor,
  submitAddedProduct,
  editWarehouseNotes,
  consignments,
  onChangeMultiSelect,
  addAddress,
  addNewEmail,
  onChangeAddress,
  onChangeEmails,
  removeAddress,
  removeEmails,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  selectAllRows,
  allRowsSelected,
  validate,
  validationResult,
}) => {
  return (
    <div className="p-5">
      <div className="flex items-center pb-4">
        <div className="flex-1">
          <PageTitle>Vendor Management</PageTitle>
        </div>

        {writable && (
          <div className="flex items-center justify-end space-x-2">
            <AddButton
              text="Add Vendor"
              onClick={addNewVendor}
              icon={UserAddIcon}
            />
            <AddButton
              text="Download Template"
              onClick={() =>
                (window.location =
                  "https://templates-onboarding.s3.amazonaws.com/uat/Vendors.xlsx")
              }
              disabled={!writable}
            />
            <BulkUpload
              dashboardFields={dashboardFields}
              saveBulkUpload={(rows) => saveBulkUpload(rows)}
              errorMessage={errorMessage}
              successMessage={successMessage}
              validate={validate}
              validationResult={validationResult}
            />
          </div>
        )}
      </div>
      <VendorList
        writable={writable}
        vendors={displayVendors}
        masterData={masterData}
        filters={filters}
        onChangeFilter={onChangeFilter}
        getAudit={getAudit}
        setItems={setItems}
        consignments={consignments}
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        availableInventory={availableInventory}
        setSort={setSort}
        customers={customers}
        warehouses={warehouses}
        editVendor={(id) => {
          editVendor(id);
        }}
        deleteVendor={(id) => {
          deleteVendor(id);
        }}
        editVendorNotes={editVendorNotes}
        editWarehouseNotes={editWarehouseNotes}
      />
      {showFilters && (
        <VendorFilters
          negativeAction={() => setShowFilters(false)}
          masterData={masterData}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          classifications={["Internal", "Third Party"]}
          clearFilters={clearFilters}
        />
      )}
      {selectedVendor && (
        <VendorForm
          selectedVendor={selectedVendor}
          onChange={onChange}
          removeAddress={removeAddress}
          removeEmails={removeEmails}
          onChangeMultiSelect={onChangeMultiSelect}
          title="Add Vendor"
          onClose={() => setSelectedVendor(null)}
          onSubmit={saveVendor}
          setSelectedVendor={setSelectedVendor}
          addAddress={addAddress}
          addNewEmail={addNewEmail}
          onChangeAddress={onChangeAddress}
          onChangeEmails={onChangeEmails}
          warehouses={warehouses}
          availableInventory={availableInventory}
          onChangeInventorySearch={onChangeInventorySearch}
          submitAddedProduct={submitAddedProduct}
          vendors={vendors}
          onChangeDropdown={onChangeDropdown}
        />
      )}

      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default withVendorLogic(VendorManagement);
