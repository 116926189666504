import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const GroupsList = ({ masterData, writable }) => {
  const history = useHistory();
  if (!masterData || !masterData.comparisonOperators) {
    return null;
  }
  const { masterFields, comparisonOperators, logicalOperators } = masterData;
  const formString = (condition) => {
    const masterField = _.find(masterFields, { value: condition.masterField });
    const comparisonOperator = _.find(comparisonOperators, {
      value: condition.comparisonOperator,
    });
    return `${masterField.name} ${comparisonOperator.name} ${condition.value}`;
  };
  const renderMainCriteria = (mainCriteria) => {
    const criteria = _.find(
      masterData.logicalOperators,
      (e) => e.value === mainCriteria,
    );
    return criteria.name;
  };
  return (
    <div className="mb-4 flex items-center justify-center">
      <div className="text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true">
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No Groups</h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started by creating a new group.
        </p>
        <div className="mt-6">
          <button
            type="button"
            className={`inline-flex items-center text-sm font-medium text-white ${
              writable ? "bg-2C7695" : "bg-gray-500"
            } rounded-full border-transparent px-4 py-2 font-montserrat font-medium outline-none ring-0 focus:outline-none focus:ring-0`}
            onClick={() => history.push("/group")}
            disabled={!writable}>
            Add Group
          </button>
        </div>
      </div>

      {/* <div className="border rounded-md shadow-md bg-F8F8F8">
        <div className="flex justify-between p-4 border-b bg-white rounded-tl-md rounded-tr-md">
          <div className="text-xl text-2C7695 font-medium">Test Group</div>
          <div className="flex space-x-2">
            <PencilIcon className="w-6 h-6 text-primaryAccent" />
            <TrashIcon className="w-6 h-6 text-primaryAccent" />
          </div>
        </div>
        <div className="text-2xl p-4">WHEN ANY of the conditions are met</div>
        <div className="flex-col space-y-4 pb-4">
          <div className="flex items-center justify-center">
            <div className="w-96 bg-EBEBEB rounded-xl p-2 items-center justify-center flex-col space-y-4 relative border border-primaryAccent px-10 pl-2">
              <div className="text-xl font-medium">IF ANY</div>
              <div className="ml-4 bg-primaryAccent rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Destination is US
              </div>
              <div className="ml-4 bg-primaryAccent rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                {`Order Value is >= 1000`}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-96 bg-EBEBEB rounded-xl p-2 items-center justify-center flex-col space-y-4 relative border border-primaryAccent px-10 pl-2">
              <div className="text-xl font-medium">IF ALL</div>
              <div className="ml-4 bg-primaryAccent rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Destination is US
              </div>
              <div className="ml-4 bg-primaryAccent rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Customer is JCPenney
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-96 bg-EBEBEB rounded-xl p-2 items-center justify-center flex-col space-y-4 relative border border-primaryAccent px-10 pl-2">
              <div className="text-xl font-medium">IF NONE</div>
              <div className="ml-4 bg-primaryAccent rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Customer is Target
              </div>
              <div className="ml-4 bg-primaryAccent rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Customer is Costco
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default GroupsList;

// {
//   <div className="flex justify-start items-center w-64 bg-white border border-primaryAccent rounded-full relative p-2 h-20">
// <div className="text-primaryAccent text-lg">OR</div>
// <div className="absolute mt-20 ml-4 bg-primaryAccent rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 w-48 text-center z-50">
//   Destination is US
// </div>
// </div>
// <div className="flex justify-start items-center w-64 bg-white border border-primaryAccent rounded-full relative p-2 h-20">
// <div className="text-primaryAccent text-lg">OR</div>
// <div className="absolute mt-20 ml-4 bg-primaryAccent rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 w-48 text-center">
//   Destination is US
// </div>
// </div>
// </div>
// }
