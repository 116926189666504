import React from "react";
import { PencilIcon } from "@heroicons/react/solid";

export const AddressItem = ({ vendorAddress, vendorName }) => {
  return (
    <div className="ml-6">
      <div className="font-semibold text-black">{vendorName}</div>
      <div className="text-gray-600">{vendorAddress.line1}</div>
      <div className="text-gray-600">{vendorAddress.line2}</div>
      <div className="text-gray-600">{vendorAddress.city}</div>
      <div className="text-gray-600">{vendorAddress.zip}</div>
      <div className="text-gray-600">{vendorAddress.state}</div>
      <div className="text-gray-600">{vendorAddress.country}</div>
      {vendorAddress.email && (
        <div className="mt-2 text-gray-600">Email: {vendorAddress.email}</div>
      )}
      {vendorAddress.phone && (
        <div className="mt-2 text-gray-600">Phone: {vendorAddress.phone}</div>
      )}
    </div>
  );
};

const VendorAddress = ({
  vendorAddress,
  vendorName,
  handleOpenAddressModal,
  disabled,
}) => {
  return (
    <div
      className={`rounded-md border p-4 ${disabled ? "pointer-events-none bg-gray-200 opacity-50" : ""}`}>
      <div className="flex items-center justify-between">
        <span className="text-sm text-gray-500">Vendor Address</span>
        <PencilIcon
          onClick={disabled ? () => {} : handleOpenAddressModal}
          className={`h-5 w-5 cursor-pointer text-gray-400 ${
            disabled ? "cursor-not-allowed text-gray-300" : ""
          }`}
        />
      </div>
      <AddressItem vendorAddress={vendorAddress} vendorName={vendorName} />
    </div>
  );
};

export default VendorAddress;
