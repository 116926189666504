import Modal from "#components/utils/Modal";
import _ from "lodash";

const OrderFilters = ({ negativeAction, exceptionReasons }) => {
  const headers = ["Type Of Exception", "Description"];
  return (
    <Modal
      title={"Exception Reasons"}
      negativeAction={negativeAction}
      positiveAction={negativeAction}
      initialFocus={null}>
      <table className="min-w-full divide-y divide-gray-200 px-2">
        <thead className="rounded-full bg-primaryAccent p-4 px-12">
          <tr className="border-left px-12 font-montserrat text-textWhite">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {exceptionReasons.map((item, index) => (
            <tr
              key={item.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {item.typeOfException}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {item.description}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
};

export default OrderFilters;
