import { countries } from "countries-list";

/**
 * Generates a list of options from the `countries` import where each option is an object
 * containing the country's ISO code as `value` and the country's name as `label`.
 * @returns {Array<{value: string, label: string}>} An array of objects each representing a country option.
 */
export const countryOptions = Object.keys(countries).map((countryCode) => ({
  value: countryCode,
  label: countries[countryCode].name,
}));

/**
 * Transforms `countryOptions` into a list of objects with `name` and `value` properties,
 * where `name` is the country's name and `value` is the country's ISO code.
 * This is used for creating a list that might be easier to manage in some UI contexts
 * where `name` and `value` properties are preferred.
 * @returns {Array<{name: string, value: string}>} An array of objects, each representing a country with its name and ISO code.
 */
export const countriesList = countryOptions.map((option) => ({
  name: option.label,
  value: option.value,
}));
