import RowsPerPageDropdown from "./RowsPerPageDropdown";
import PaginationNavigationButtons from "./PaginationNavigationButtons";
import { useContext } from "react";
import { AppStateContext } from "#contexts/appState";

const Pagination = ({
  showingLhs,
  showingRhs,
  showingTotal,
  perPage,
  setPerPage,
  pageNumber,
  checkPagination,
  searchEnabled = false,
}) => {
  const appState = useContext(AppStateContext);

  return (
    <div
      className={`fixed bottom-0 left-0 h-20 w-screen bg-transparent ${appState?.sidebarOpen ? "md:pl-72" : "md:pl-20"}`}>
      <div className="flex h-full w-full flex-wrap items-center whitespace-nowrap bg-E5E5E5 py-4 text-primaryAccent">
        <div className="flex-1 px-5 font-bold">
          <p className="font-medium text-gray-700">
            Showing <span className="font-medium">{showingLhs}</span> to
            <span className="font-medium"> {showingRhs}</span> of{" "}
            <span className="font-medium">
              {searchEnabled === true && showingTotal === 1000
                ? "many"
                : showingTotal}
            </span>{" "}
            results
          </p>
        </div>
        <div className="ml-4 mr-10 flex-none items-end justify-end text-right">
          <div className="flex flex-wrap items-center space-x-4">
            <div>Results per page</div>
            <RowsPerPageDropdown perPage={perPage} setPerPage={setPerPage} />
            <PaginationNavigationButtons
              pageNumber={pageNumber}
              total={showingTotal}
              perPage={perPage}
              prevPage={() => checkPagination("backward")}
              nextPage={() => checkPagination("forward")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
