import React, { useContext, useState } from "react";
import ModalV2 from "#components/utils/ModalV2";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import {
  DotsVerticalIcon,
  TrashIcon,
  EyeIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";

import ProductInfo from "./ProductInfo";
import ProductSearch from "./ProductSearch";
import BundleForm from "./BundleForm";
import CasePackInformation from "./CasePackInformation";
import COGInformation from "./CogInformation";
import UOMConfigurationV2 from "#components/products/UOMConfigurationV2";
import OrderEnhancedSearch from "./OrderEnhancedSearch";
import EmptyCart from "#static/images/EmptyCart.svg";
import { AuthContext } from "#contexts/auth";
import truncate from "#utils/truncate";

const itemTypes = [
  { name: "Standalone", value: "STANDALONE" },
  { name: "Bundle", value: "BUNDLE" },
];

const itemSelectionTypes = Object.freeze({
  SHOW_BUNDLE_INFO: "SHOW_BUNDLE_INFO",
  SHOW_COG_DETAILS: "SHOW_COG_DETAILS",
  SHOW_UOM_CONFIGURATION: "SHOW_UOM_CONFIGURATION",
});

const AddProductStep = ({
  selectedConsignment,
  setSelectedConsignment,
  warehouses,
  multiAccountSupportEnabled,
  tenant,
  upsertSelectedConsignmentItem,
  removeSelectedConsignmentItem,
  refreshConsignmentItems,
  cart,
  setCart,
  handleAddToCart,
  zipCode,
  zipCodeTitle,
  salesCustomers,
}) => {
  const ldClient = useLDClient();
  const isBundleDefinitionEnabled = ldClient.variation(
    featureFlags.DEFINE_BUNDLE_ON_CONSIGNMENT,
    false,
  );
  const isCogEnabled = ldClient.variation(featureFlags.COG_ENABLED, false);

  const isPrepCenter = tenant?.typeOfCustomer?.includes("Prep Center");

  const [showProductSearch, setShowProductSearch] = useState(
    !selectedConsignment.items || selectedConsignment.items?.length === 0
      ? true
      : false,
  );
  const [showImage, setShowImage] = useState(null);

  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const [selectedItemDetailsPrev, setSelectedItemDetailsPrev] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [itemSelectionType, setItemSelectionType] = useState(null);

  // To handle the addition of new product to the consignment items.
  const addNewProduct = (productItem) => {
    const uom = productItem.baseUom || "Each";
    upsertSelectedConsignmentItem({
      productId: productItem.id,
      fulfillmentType:
        warehouses &&
        warehouses.find((item) => item.typeOfWarehouse?.includes("PREP CENTER"))
          ? "FBA"
          : "DEFAULT",
      asin: productItem.asin,
      sku: productItem.sku,
      sellerSku: productItem.sellerSku,
      productName: productItem.name,
      formFactor: uom,
      formFactors: [{ name: uom, quantity: 1 }],
      marketplace:
        productItem.marketplaceAttributes?.attributes?.marketplaceCountryCode,
      seller: productItem.marketplaceAttributes?.attributes?.sellerId,
      itemDetails: productItem,
      addingNew: true,
    });
    setShowProductSearch(false);
  };

  // Table headers to be displayed for the consignment items.
  const showHeaders = [
    { name: "Product Info", correspondingValue: "productInfo" },
    { name: "Channel", correspondingValue: "source" },
    {
      name: (
        <>
          Unit Type<span className="text-trashIconRed">*</span>
        </>
      ),
      correspondingValue: "formFactor",
    },
    {
      name: (
        <>
          Quantity<span className="text-trashIconRed">*</span>
        </>
      ),
      correspondingValue: "quantity",
    },
  ];
  if (isBundleDefinitionEnabled) {
    showHeaders.push({
      name: (
        <>
          Item Type<span className="text-trashIconRed">*</span>
        </>
      ),
      correspondingValue: "typeOfProduct",
    });
  }
  showHeaders.push({
    name: "Action",
    correspondingValue: "action",
  });

  // When an item is selected, it handles setting the item details, index pos in the consignment items, and the purpose for the selection.
  const handleItemSelection = (itemDetails, itemIdx, selectionType) => {
    setSelectedItemDetails({ ...itemDetails });
    setSelectedItemIndex(itemIdx);
    setItemSelectionType(selectionType);
  };

  // Used when we need to reset the selected item.
  const resetItemSelection = () => {
    setSelectedItemDetails(null);
    setSelectedItemIndex(-1);
    setItemSelectionType(null);
  };

  // This happens when we choose the product from standalone to bundle and then don't define the bundle.
  // when resetting, modify the itemDetails in the respective itemIdx for the selectedConsignment.
  const resetItemSelectionForBundleConfig = ({ operationSuccess = false }) => {
    const items = { ...selectedConsignment.items };
    const selectedItem = items[selectedItemIndex];
    if (!operationSuccess && selectedItemDetailsPrev) {
      upsertSelectedConsignmentItem({
        ...selectedItem,
        itemDetails: selectedItemDetailsPrev,
        idx: selectedItemIndex,
      });
    }
    setSelectedItemDetailsPrev(null);
    resetItemSelection();
  };

  return (
    <div className="flex h-full flex-col">
      {/* Add Products Header */}
      <div className="py-1">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold">Add Products</h1>
        </div>
        <p className="w-full text-sm font-normal"></p>
      </div>

      <div className="w-full sm:px-1 lg:px-1">
        <div className="mt-2 flex items-start justify-between">
          {/* Search div with 2/3 width and full height */}
          <div className="mr-4 h-full w-2/3">
            <OrderEnhancedSearch
              products={[]}
              selectProduct={() => {}}
              selectedProducts={[]}
              allRowsSelected={false}
              selectAllRows={() => {}}
              getCatalog={() => {}}
              shiftKeyPressed={false}
              cart={cart}
              setCart={setCart}
              selectedOrder={selectedConsignment}
              handleAddToCart={handleAddToCart}
              salesCustomers={salesCustomers}
            />
          </div>

          {/* Cart display div with 1/3 width */}
          <div className="h-full w-1/3 overflow-x-hidden sm:px-1 lg:px-1">
            <Cart
              items={cart || []}
              setCart={setCart}
              zipCode={zipCode}
              zipCodeTitle={zipCodeTitle}
              salesCustomers={salesCustomers}
              selectedConsignment={selectedConsignment}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Cart = ({
  items,
  setCart,
  zipCode,
  zipCodeTitle,
  salesCustomers,
  selectedConsignment,
}) => {
  const auth = useContext(AuthContext);

  const totalPrice = items.reduce(
    (acc, item) => acc + parseFloat(item?.price || 0),
    0,
  );

  const salesChannel = selectedConsignment?.company;
  const entity = salesCustomers.find((cus) => cus.name === salesChannel).entity;

  return (
    <div className="rounded-lg bg-[#FAFAFA] p-4 shadow">
      <h2 className="bg mb-4 border-b pb-2 text-lg font-semibold">
        Customer Cart
      </h2>
      <div className="mb-4">
        <div className="mb-2 text-sm">
          {zipCodeTitle}
          <span className="font-semibold">{zipCode}</span>
        </div>
      </div>
      <div className="flex items-center justify-between border-b border-t py-2">
        <span>Sub Total - {items?.length} items</span>
        <span className="font-inter text-xl font-bold">
          ${isNaN(totalPrice) ? 0 : totalPrice.toFixed(2)}
        </span>
      </div>
      <div className="flex flex-col items-center justify-center">
        {items.length === 0 ? (
          <div className="my-4">
            <img
              src={EmptyCart}
              alt="No Items Added"
              className="mb-4 h-52 w-52 object-contain"
            />
            <p className="text-center text-sm text-gray-600">No Items Added</p>
          </div>
        ) : (
          // Render list of items in cart
          items.map((item) => (
            <div
              key={item.id}
              className="mb-2 flex w-full items-center justify-between">
              <div className="flex flex-col">
                <span className="text-sm">{truncate(item?.name, 40)}</span>
                <span className="text-xs text-gray-500">
                  {item[entity]} {entity}
                </span>
              </div>
              <div className="my-2 flex items-center">
                <span>$</span>

                {/* Only ADMIN has access to edit the price */}
                {auth?.user?.role?.toLowerCase() === "admin" ? (
                  <input
                    type="number"
                    value={item.price}
                    onChange={(e) =>
                      setCart((prev) =>
                        prev.map((cart) =>
                          cart.sku === item.sku
                            ? {
                                ...cart,
                                price: parseFloat(e.target.value || 0),
                              }
                            : cart,
                        ),
                      )
                    }
                    className="ml-2 w-24 rounded px-2 py-1"
                  />
                ) : (
                  <span>{item?.price}</span>
                )}

                <button
                  onClick={() =>
                    setCart((prev) =>
                      prev.filter((subItem) => subItem.id !== item.id),
                    )
                  }
                  className="rounded-full p-1 text-red-500 hover:bg-red-100">
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

// Each item in the consignment will be having some state of its own. This separate component handles that.
const ConsignmentProductRow = ({
  selectedConsignment,
  setSelectedConsignment,
  item,
  itemDetails,
  itemIdx,
  setShowImage,
  upsertSelectedConsignmentItem,
  removeSelectedConsignmentItem,
  showHeaders,
  handleItemSelection,
  setSelectedItemDetailsPrev,
  isBundleDefinitionEnabled,
  isCogEnabled,
}) => {
  // Case for the consignment for which the product data doesn't exists in productvariants collection
  if (!itemDetails) {
    return null;
  }

  if (!itemDetails.typeOfProduct) {
    itemDetails.typeOfProduct = "STANDALONE";
  }

  const menuItems = (item) => {
    const arr = [
      {
        title: "Remove",
        icon: TrashIcon,
        onClick: () => removeSelectedConsignmentItem(itemIdx),
      },
    ];
    if (isBundleDefinitionEnabled && itemDetails.typeOfProduct === "BUNDLE") {
      arr.push({
        title: "View Bundle Info",
        icon: EyeIcon,
        onClick: () =>
          handleItemSelection(
            itemDetails,
            itemIdx,
            itemSelectionTypes.SHOW_BUNDLE_INFO,
          ),
      });
    }
    if (isCogEnabled) {
      arr.push({
        title: "View COG Details",
        icon: EyeIcon,
        onClick: () =>
          handleItemSelection(
            itemDetails,
            itemIdx,
            itemSelectionTypes.SHOW_COG_DETAILS,
          ),
      });
    }
    arr.push({
      title: "View UOM Details",
      icon: EyeIcon,
      onClick: () =>
        handleItemSelection(
          itemDetails,
          itemIdx,
          itemSelectionTypes.SHOW_UOM_CONFIGURATION,
        ),
    });

    return arr;
  };

  const handleItemTypeChange = (itemType) => {
    if (itemType === "BUNDLE") {
      setSelectedItemDetailsPrev({ ...itemDetails });
      const currentItemDetails = { ...itemDetails, typeOfProduct: itemType };
      upsertSelectedConsignmentItem({
        ...item,
        itemDetails: { ...currentItemDetails },
        idx: itemIdx,
      });
      handleItemSelection(
        currentItemDetails,
        itemIdx,
        itemSelectionTypes.SHOW_BUNDLE_INFO,
      );
    }
  };

  const showCasePackInfo = selectedConsignment.isCasePack;

  return (
    <>
      <tr
        key={`${itemDetails.id}+${item.formFactor}`}
        className={`bg-bgWhite ${
          showCasePackInfo
            ? "border-l border-r border-t border-l-borderGray border-r-borderGray border-t-borderGray"
            : "border border-borderGray"
        }`}>
        {showHeaders.map((header, headerIdx) => {
          const value = header.correspondingValue;
          if (value === "productInfo") {
            return (
              <td className="w-289 p-3 pr-10">
                <ProductInfo
                  item={item}
                  itemDetails={itemDetails}
                  itemIdx={itemIdx}
                  setShowImage={setShowImage}
                />
              </td>
            );
          }
          if (value === "source") {
            return <td className="pl-3">{item.itemDetails?.source}</td>;
          }
          if (value === "formFactor") {
            const baseUom = itemDetails.baseUom || "Each";
            const formFactorOptions = [{ name: baseUom, value: baseUom }];
            itemDetails.uomConfiguration
              ?.filter((i) => i.isActive)
              ?.forEach((uomConfig) => {
                formFactorOptions.push({
                  name: uomConfig.targetUom,
                  value: uomConfig.targetUom,
                });
              });

            return (
              <td className="pl-3">
                <div className="flex h-40 w-159 flex-col items-start">
                  <AutocompleteDropdownV2
                    options={formFactorOptions}
                    labelKey="name"
                    valueKey="value"
                    onChange={(uom) =>
                      upsertSelectedConsignmentItem({
                        ...item,
                        idx: itemIdx,
                        formFactor: uom,
                        formFactors: [
                          { name: uom, quantity: item.formFactors[0].quantity },
                        ],
                      })
                    }
                    value={item.formFactors[0].name ?? "Each"}
                    placeholder=""
                    searchable={false}
                  />
                  <p
                    className="mt-1 cursor-pointer text-sm font-semibold text-primaryAccent underline"
                    onClick={() =>
                      handleItemSelection(
                        itemDetails,
                        itemIdx,
                        itemSelectionTypes.SHOW_UOM_CONFIGURATION,
                      )
                    }>
                    Form Factor
                  </p>
                </div>
              </td>
            );
          }
          if (value === "quantity") {
            return (
              <td className="pl-3">
                <div className="h-40 w-103">
                  <input
                    type="number"
                    id={`item_quantity_${itemIdx}`}
                    placeholder=" "
                    onChange={(e) => {
                      upsertSelectedConsignmentItem({
                        ...item,
                        idx: itemIdx,
                        formFactors: [
                          {
                            name: item.formFactors[0].name,
                            quantity:
                              isNaN(parseInt(e.target.value)) === false
                                ? parseInt(e.target.value)
                                : null,
                          },
                        ],
                      });
                    }}
                    min={"0"}
                    value={item.formFactors[0].quantity}
                    name="itemQuantity"
                    className="h-full w-full rounded border border-borderGray"
                  />
                </div>
              </td>
            );
          }
          if (value === "typeOfProduct") {
            return (
              <td className="pl-3">
                <div className="h-40 w-159">
                  <AutocompleteDropdownV2
                    options={itemTypes}
                    labelKey="name"
                    valueKey="value"
                    onChange={(itemType) => handleItemTypeChange(itemType)}
                    value={itemDetails?.typeOfProduct ?? "STANDALONE"}
                    placeholder=""
                    searchable={false}
                    disabled={item.itemDetails?.typeOfProduct === "BUNDLE"}
                    restrictOptionsWidth={false}
                  />
                </div>
              </td>
            );
          }
          if (value === "action") {
            return (
              <td className="pl-3">
                <Menu as="div" className="text-left">
                  <div>
                    <Menu.Button>
                      <DotsVerticalIcon className="h-10 w-10 rounded p-2 text-base" />
                    </Menu.Button>
                  </div>
                  <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="">
                      {menuItems(item).map((menuItem, idx) => (
                        <Menu.Item key={idx}>
                          {({ active }) => (
                            <button
                              className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                              onClick={() =>
                                menuItem["onClick"](item[menuItem["vars"]])
                              }>
                              {menuItem.icon && (
                                <menuItem.icon className="h-8 w-8 pr-2" />
                              )}
                              {menuItem.title}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Menu>
              </td>
            );
          }
        })}
      </tr>
      {/* Case pack Info */}
      {showCasePackInfo && (
        <tr className="border-b border-l border-r border-b-borderGray border-l-borderGray border-r-borderGray bg-bgWhite">
          <td colSpan={"100%"}>
            <CasePackInformation
              item={item}
              itemIdx={itemIdx}
              selectedConsignment={selectedConsignment}
              setSelectedConsignment={setSelectedConsignment}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default AddProductStep;
