import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import Autocomplete from "#components/utils/Autocomplete";
import { TrashIcon } from "@heroicons/react/outline";
import { useState } from "react";

const StockAdjustmentForm = ({ onClose, title, onSubmit }) => {
  const [reason, setReason] = useState("");
  const [confirm, setConfirm] = useState(false);
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      positiveAction={() => setConfirm(true)}>
      <div className="space-y-4">
        <div>
          <TextField
            type="text"
            id="stockAdjustmentReason"
            label="Reason for stock adjustment"
            placeholder=" "
            onChange={(e) => setReason(e.target.value)}
            value={reason}
          />
        </div>
      </div>

      {confirm && reason && (
        <Modal
          title={"Are you sure?"}
          onClose={() => setConfirm(false)}
          negativeAction={() => setConfirm(false)}
          positiveAction={() => onSubmit(reason)}
          positiveActionText="Confirm">
          <div className="space-y-4">
            <div>
              <a>Do you want to make adjustments to your inventory?</a>
            </div>
          </div>
        </Modal>
      )}
    </Modal>
  );
};

export default StockAdjustmentForm;
