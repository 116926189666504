import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import ModalButton from "#components/utils/ModalButton";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import DateRangePicker from "#components/common/DateRangePicker";
import { DATE_RANGES } from "#utils/dateRanges";

dayjs.extend(utc);
dayjs.extend(timezone);
const ConsignmentFilters = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  onSubmit,
  negativeAction,
  selectedCombinedOrder,
  masterData,
  onChangeFilter,
  handleDateRangePickerValue,
  handleDisplayTextForDateRangePicker,
  filters,
  outboundExceptions,
  handleDateRange,
  customers,
  warehouses,
  clearFilters,
}) => {
  return (
    <Modal
      title={"Filters"}
      negativeAction={negativeAction}
      positiveAction={onSubmit}
      initialFocus={null}>
      <div className="mt-4 flex space-x-6">
        <ModalButton
          onClick={clearFilters}
          text={`Reset Filters`}
          className="bg-red-500"
        />
      </div>
      <div className="mt-4 flex space-x-6">
        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Consignment Creation Date Range
            </span>
          </label>
          <DateRangePicker
            floating={true}
            label={"Select Date Range"}
            pickerClasses="h-16 border-2C7695 border-2"
            format="DD-MM-YYYY"
            ranges={DATE_RANGES}
            labelClasses={{
              before: "text-lg left-1 top-1rem",
              after: "text-base -top-3 left-3",
              common: "italic text-gray-500",
              nonFloatingClasses: "text-base left-5 -top-3",
            }}
            onChangeDateRange={(dateStrings) => {
              onChangeFilter("startEndDate", dateStrings);
              handleDateRange(dateStrings);
            }}
            value={handleDateRangePickerValue(filters["startEndDate"])}
            displayText={handleDisplayTextForDateRangePicker(
              filters["startEndDate"],
            )}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-between space-x-6">
        <>
          <div className="flex-1">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Status
              </span>
            </label>
            <MultiSelectAutoComplete
              options={[
                { name: "DRAFT" },
                { name: "UNPROCESSED" },
                { name: "RECEIVING_STARTED" },
                { name: "COMPLETED" },
                { name: "CLOSED" },
              ]}
              labelKey="name"
              valueKey="name"
              setValues={(e) => onChangeFilter("status", e)}
              values={filters["status"]}
              rounded={true}
            />
          </div>
        </>
        <>
          <div className="flex-1">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Client(s)
              </span>
            </label>
            <MultiSelectAutoComplete
              options={customers}
              labelKey="name"
              valueKey="id"
              setValues={(e) => onChangeFilter("customers", e)}
              values={filters["customers"]}
              rounded={true}
            />
          </div>
        </>
        <>
          <div className="flex-1">
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Order Type
              </span>
            </label>
            <MultiSelectAutoComplete
              options={[
                { name: "Regular", key: "Regular" },
                { name: "Stock Transfer", key: "STO" },
              ]}
              labelKey="name"
              valueKey="key"
              setValues={(e) => onChangeFilter("orderType", e)}
              values={filters["orderType"]}
              rounded={true}
            />
          </div>
        </>
      </div>
      <div className="mt-4 flex justify-between space-x-6"></div>
    </Modal>
  );
};

export default ConsignmentFilters;
