import React, { createContext, useContext, useState, useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";

const FeatureFlagsContext = createContext();

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const FeatureFlagsProvider = ({ children }) => {
  const [ldFlags, setLdFlags] = useState({
    isBranded: undefined,
    storageManagementEnabled: undefined,
    salesPortalEnabled: undefined,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const ldClient = useLDClient();

  useEffect(() => {
    const fetchFlags = async () => {
      if (ldClient) {
        const isBranded = await ldClient.variation(
          featureFlags.IS_BRANDED,
          false,
        );

        const storageManagementEnabled = await ldClient.variation(
          featureFlags.HIERARCHICAL_STORAGE_MANAGEMENT_ENABLED,
          false,
        );

        const salesPortalEnabled = await ldClient.variation(
          featureFlags.SALES_PORTAL_ENABLED,
          false,
        );

        setLdFlags({
          isBranded,
          storageManagementEnabled,
          salesPortalEnabled,
        });
        setIsLoaded(true);
      }
    };

    fetchFlags();
  }, [ldClient]);

  useEffect(() => {
    const handleFlagChange = () => {
      const isBranded = ldClient.variation(featureFlags.IS_BRANDED, false);

      const storageManagementEnabled = ldClient.variation(
        featureFlags.HIERARCHICAL_STORAGE_MANAGEMENT_ENABLED,
        false,
      );

      const salesPortalEnabled = ldClient.variation(
        featureFlags.SALES_PORTAL_ENABLED,
        false,
      );

      setLdFlags({
        isBranded,
        storageManagementEnabled,
        salesPortalEnabled,
      });
    };

    if (ldClient) {
      ldClient.on("change", handleFlagChange);
    }

    return () => {
      if (ldClient) {
        ldClient.off("change", handleFlagChange);
      }
    };
  }, [ldClient]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <FeatureFlagsContext.Provider value={ldFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
