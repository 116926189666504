import moment from "moment-timezone";
import _, { set } from "lodash";
import {
  FastForwardIcon,
  PencilAltIcon,
  TrashIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";
import BatchFilters from "#components/batches/outbound/BatchFilters";
import ExpandedBatch from "#components/batches/outbound/ExpandedBatch";
import ExpandInventoryOperationsSummaryBatch from "#components/batches/inventory/ExpandInventoryOperationsSummaryBatch";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { useContext, useEffect, useState } from "react";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import { Menu } from "@headlessui/react";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
const noValuesText = "No Bundle Operations";
import { renderStatus } from "../../utils/renderStatus";
import { isDelmarTenant } from "#utils/tenantCheck";
import Checkbox from "#components/utils/Checkbox";
import Toggle from "#components/utils/Toggle";
import AddButton from "#components/utils/AddButton";
import withOutboundSummaryLogic from "../HOC/withOutboundSummaryLogic";
import withInventorySummaryLogic from "#components/HOC/withInventorySummaryLogic";
const INVENTORY_SUMMARY_TABLE_NAME = "consolidatedInventorySummaryTable";
import { EyeIcon } from "@heroicons/react/solid";
import AssignBatchPickerForm from "#components/pickings/AssignBatchPicker";

const InventorySummary = ({
  displayBatches,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  sort,
  setSort,
  onChangeSearchKeyword,
  submitFilters,
  setShowFilters,
  filters,
  clearKeyword,
  clearFilters,
  showFilters,
  onChangeFilter,
  handleDateRangeClear,
  users,
  usersMap,
  expandedBatch,
  setExpandedBatch,
  expandBatch,
  customers,
  warehouses,
  setSelectedBatch,
  prioritizeBatch,
  showPickerSelector,
  setShowPickerSelector,
  writable,
  deleteBatch,
  tenant,
  selectedRows,
  selectRow,
  selectAllRows,
  allRowsSelected,
  showOnlySelected,
  setShowOnlySelected,
  clearSelectedBatches,
  downloadBatchesCsv,
  onAssignBatchPicker,
  selectedBatch,
  toShowActivity,
}) => {
  const [bundleDetails, setBundleDetails] = useState({
    isBundlePicking: false,
    isBundlePutaway: false,
    isBundling: false,
    bundleData: [],
  });

  const menuItems = (batch) => {
    const arr = [];

    if (writable) {
      arr.push({
        title: "Re-assign Picker",
        icon: PencilAltIcon,
        onClick: () => {
          setShowPickerSelector(true);
          setSelectedBatch(batch);
        },
      });
      arr.push({
        title: "Prioritize",
        icon: FastForwardIcon,
        onClick: () => {
          prioritizeBatch(batch);
        },
      });
      arr.push({
        title: "Delete",
        icon: TrashIcon,
        onClick: () => {
          deleteBatch(batch);
        },
      });
    }

    return arr;
  };

  const getInitialHeaders = () => {
    const arr = [
      getHeaderObject("Batch ID", "id", false),

      getHeaderObject("User", "user", false),
      getHeaderObject("Created at", "createdAt", false),
      getHeaderObject("Tote", "tote", false),
    ];

    arr.push(getHeaderObject("Bundle Picking", "bundlePicking", false)),
      arr.push(getHeaderObject("Bundling", "bundling", false)),
      arr.push(getHeaderObject("Bundle Putaway", "bundlePutaway", false)),
      arr.push(getHeaderObject("Status", "status", false)),
      arr.push(
        getHeaderObject("Client(s)", "customer"),
        getHeaderObject("Warehouse", "warehouse"),
      );

    return arr;
  };
  const [headers, setHeaders] = useState(getInitialHeaders());
  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      INVENTORY_SUMMARY_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    if (bundleDetails.isBundlePicking) {
      setBundleDetails((prev) => ({
        ...prev,
        bundleData: expandedBatch,
      }));
    } else if (bundleDetails.isBundling) {
      setBundleDetails((prev) => ({
        ...prev,
        bundleData: expandedBatch,
      }));
    } else if (bundleDetails.isBundlePutaway) {
      setBundleDetails((prev) => ({
        ...prev,
        bundleData: expandedBatch,
      }));
    }
  }, [expandedBatch]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  const renderOverallStatus = (batch) => {
    if (batch.overallStatus === "UNPROCESSED") {
      return renderStatus("UNPROCESSED");
    }

    if (batch.overallStatus !== "IN-PROCESS") {
      return renderStatus("IN-PROCESS");
    }

    if (batch.overallStatus !== "COMPLETED") {
      return renderStatus("COMPLETED");
    }

    return renderStatus(batch.overallStatus);
  };

  return (
    <>
      {filters?.startEndDate && (
        <div className="flex items-end justify-end text-2xl font-bold text-primaryAccent">
          Date Range: {filters.startEndDate[0] || "Beginning"} ~{" "}
          {filters.startEndDate[1] || "Now"}
        </div>
      )}
      <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
        {(customers?.length > 1 || warehouses?.length > 1) && (
          <QuickFilters
            warehouseFilterName={"warehouses"}
            customerFilterName={"customers"}
            customers={customers}
            warehouses={warehouses}
            filters={filters}
            onChangeFilter={onChangeFilter}
          />
        )}
        <SearchSortFilter
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          clearFilters={clearFilters}
          choices={choices}
          headers={headers}
          setShowHeaders={setShowHeaders}
          tableName={INVENTORY_SUMMARY_TABLE_NAME}
        />

        {(selectedRows.length > 0 || showOnlySelected) && (
          <div className="flex items-center space-x-4 px-2">
            <div>
              <Toggle
                enabled={showOnlySelected}
                setEnabled={(key) => setShowOnlySelected(key)}
              />
            </div>
            <div className="text-2xl">
              Show only selected batches(
              {selectedRows.length})
            </div>
            <AddButton
              text={"Clear"}
              onClick={() => {
                clearSelectedBatches();
              }}
            />
            {/* <AddButton
              text={"Export to CSV"}
              onClick={() => {
                downloadBatchesCsv();
              }}
            /> */}
          </div>
        )}
        <CustomTableWithTheme>
          <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
            <tr className="border-left font-montserrat text-textWhite">
              <th
                scope="col"
                className="px-2 py-3 pl-6 text-left font-medium tracking-wider">
                <Checkbox
                  role="checkbox"
                  onChange={selectAllRows}
                  name="select All Rows"
                  value={"Select All Rows"}
                  checked={allRowsSelected}
                />
              </th>
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {displayBatches.length === 0 ? (
              <tr className="bg-white">
                {headers.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <>
                      <td
                        scope="col"
                        className="rounded-bl rounded-tl border-l-8 border-F4C261 p-5 text-left font-semibold tracking-wider text-primaryAccent"
                        key={headerIdx}>
                        {noValuesText}
                      </td>
                      <td></td>
                    </>
                  ) : (
                    <td
                      scope="col"
                      className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {displayBatches.map((batch, rowIndex) => (
              <tr
                key={batch.id}
                className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`rounded-bl rounded-tl border-l-8 p-5 text-left font-semibold tracking-wider text-primaryAccent ${
                    rowIndex % 2 === 0
                      ? "border-F4C261"
                      : "border-primaryAccent"
                  }`}>
                  <Checkbox
                    role="checkbox"
                    onChange={(_) => {
                      selectRow(rowIndex);
                    }}
                    name="batch"
                    value={batch.id}
                    checked={selectedRows.includes(batch.id)}
                  />
                </td>
                {showHeaders.map((header, columnIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    ["id"],
                    columnIndex + 1,
                    rowIndex,
                  );
                  if (value === "id") {
                    return <td className={cellStyle}>{batch.id}</td>;
                  }
                  if (value === "createdAt") {
                    return (
                      <td className={cellStyle}>
                        {moment(batch.createdAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                    );
                  }

                  if (value == "user") {
                    return (
                      <td className={cellStyle}>
                        {usersMap[batch.allocatedUser]?.name || "-"}
                      </td>
                    );
                  }

                  if (value === "quantity") {
                    return <td className={cellStyle}>{batch.itemsLength}</td>;
                  }

                  if (value == "completion") {
                    return (
                      <td className={cellStyle}>
                        {batch.completion && `${batch.completion}%`}
                      </td>
                    );
                  }
                  if (value === "tote") {
                    return <td className={cellStyle}>{batch.tote}</td>;
                  }
                  if (value === "warehouse") {
                    return (
                      <td className={cellStyle}>
                        {warehouses?.find((i) =>
                          batch?.warehouse?.includes(i.id),
                        )?.name || `-`}
                      </td>
                    );
                  }

                  if (value === "bundlePicking") {
                    console.log("batch.id", batch.id);
                    return (
                      <td className={cellStyle}>
                        <EyeIcon
                          className="cursor-pointer text-xs text-gray-600"
                          width={35}
                          onClick={() => {
                            setBundleDetails((prev) => ({
                              isBundlePutaway: false,
                              isBundling: false,
                              isBundlePicking: true,
                              bundleData: [],
                            }));
                            expandBatch(batch.id, "BundlePicking");
                          }}
                        />
                      </td>
                    );
                  }

                  if (value === "bundling") {
                    return (
                      <td className={cellStyle}>
                        <EyeIcon
                          className="cursor-pointer text-xs text-gray-600"
                          width={35}
                          onClick={() => {
                            setBundleDetails((prev) => ({
                              isBundlePutaway: false,
                              isBundling: true,
                              isBundlePicking: false,
                              bundleData: [],
                            }));
                            expandBatch(batch.id, "Bundling");
                          }}
                        />
                      </td>
                    );
                  }

                  if (value === "bundlePutaway") {
                    return (
                      <td className={cellStyle}>
                        <EyeIcon
                          className="cursor-pointer text-xs text-gray-600"
                          width={35}
                          onClick={() => {
                            setBundleDetails((prev) => ({
                              isBundlePutaway: true,
                              isBundling: false,
                              isBundlePicking: false,
                              bundleData: [],
                            }));
                            expandBatch(batch.id, "BundlePutaway");
                          }}
                        />
                      </td>
                    );
                  }

                  if (value === "status") {
                    return (
                      <td className={cellStyle}>
                        {batch?.overallStatus || "-"}
                      </td>
                    );
                  }

                  if (value === "customer") {
                    return (
                      <td className={cellStyle}>
                        {customers?.filter((i) =>
                          batch?.customer?.includes(i.id),
                        )?.length === 1 ? (
                          customers?.find((i) =>
                            batch?.customer?.includes(i.id),
                          )?.name
                        ) : (
                          <ExpandedMultipleItem
                            items={customers
                              ?.filter((i) => batch?.customer?.includes(i.id))
                              .map((customer) => customer.name)}
                          />
                        )}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </CustomTableWithTheme>
      </div>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
      {expandedBatch && (
        <ExpandInventoryOperationsSummaryBatch
          batchItems={bundleDetails.bundleData}
          negativeAction={() => {
            setExpandedBatch(null);
            setBundleDetails({
              isBundlePicking: false,
              isBundling: false,
              isBundlePutaway: false,
              bundleData: [],
            });
          }}
          tableName={"picking.orderDetails"}
          toShowActivity={false}
          isBundlePicking={bundleDetails.isBundlePicking}
          isBundling={bundleDetails.isBundling}
          isBundlePutaway={bundleDetails.isBundlePutaway}
        />
      )}
      {showFilters && (
        <BatchFilters
          dateRangeLabel="Select Summary Date Range"
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          showDateRangePicker={true}
          handleDateRangeClear={handleDateRangeClear}
          users={
            users
              ? users.filter(
                  (item) =>
                    item.hopstackModules &&
                    item.hopstackModules.includes("Picking"),
                )
              : []
          }
          customers={customers}
          warehouses={warehouses}
        />
      )}
      {selectedBatch && showPickerSelector && (
        <AssignBatchPickerForm
          onClose={() => setSelectedBatch(null)}
          title={"Assign Picker"}
          onSubmit={onAssignBatchPicker}
          selectedBatch={selectedBatch}
          setSelectedBatch={setSelectedBatch}
          users={
            users && users.length
              ? users.filter(
                  (item) =>
                    (item.warehouses &&
                      item.warehouses.includes(selectedBatch.warehouse[0])) ||
                    item.warehouses === null ||
                    item.warehouses.length === 0,
                )
              : []
          }
        />
      )}
    </>
  );
};

export default withInventorySummaryLogic(InventorySummary);
