import { useState } from "react";
import CryptoJS from "crypto-js";
import { profaneWordsHash } from "../utils/constants/profaneWordsHashList";

function removeVowelsAndSpecialCharacters(str) {
  // Remove special characters using a regular expression
  const cleanedStr = str.replace(/[^\w\s]/gi, "");

  // Remove vowels using another regular expression
  const removedVowelsStr = cleanedStr.replace(/[aeiou]/gi, "");
  return removedVowelsStr;
}

export const useCodeSuggestion = () => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [usingGeneratedCode, setUsingGeneratedCode] = useState(false);

  const generateCode = (codeFor, codeVersion = 0) => {
    /*
    Counter is used to keep a track of same generated codes.
    This condition will check for same code and replace the last letter with counter value
    The counter goes till 3, which is chosen at random, as I don't expect more than 2 similar codes.
    */
    if (codeVersion > 0 && codeVersion < 4) {
      codeFor = codeFor.substring(0, codeFor.length - 1);
      return codeFor + `${codeVersion}`;
    }
    let tempGeneratedCode = "";
    let wordsInCodeFor = codeFor
      .trim()
      .split(" ")
      .map((word) => removeVowelsAndSpecialCharacters(word))
      .filter((word) => word.length > 0);
    if (wordsInCodeFor.length > 1) {
      if (wordsInCodeFor.length == 2) {
        for (let index = 0; index < 2; index++) {
          console.log(wordsInCodeFor[index]);
          tempGeneratedCode += wordsInCodeFor[index].substring(
            0,
            Math.min(wordsInCodeFor[index].length, 2),
          );
        }
      } else {
        for (
          let index = 0;
          index < Math.min(wordsInCodeFor.length, 4);
          index++
        ) {
          tempGeneratedCode += wordsInCodeFor[index][0];
        }
      }
      return tempGeneratedCode.toUpperCase();
    } else {
      let generatedCode = wordsInCodeFor[0].substring(
        0,
        Math.min(wordsInCodeFor[0].length, 4),
      );
      return generatedCode.toUpperCase();
    }
  };

  const checkDuplicate = (checkWord, codesList) => {
    return codesList.includes(checkWord);
  };

  const checkProfanity = (checkWord) => {
    const checkWordHex = CryptoJS.SHA256(checkWord.toLowerCase()).toString(
      CryptoJS.enc.Hex,
    );

    return profaneWordsHash.includes(checkWordHex);
  };

  const handleNameChange = (name, codesList) => {
    let codeVersion = 0;
    setName(name);
    setError();
    if (name.length >= 3) {
      let newCode = generateCode(name, codeVersion);
      while (checkDuplicate(newCode, codesList) && codeVersion < 3) {
        newCode = generateCode(newCode, ++codeVersion);
      }
      if (checkProfanity(newCode)) {
        setError({ error: "Inappropriate code generated." });
      } else {
        setError({ error: null });
      }
      if (codeVersion == 3) {
        setError({ error: "To many duplicates" });
      }
      setCode(newCode);
    } else {
      setUsingGeneratedCode(false);
      setCode("");
    }
    if (error?.error) setUsingGeneratedCode(false);
    else setUsingGeneratedCode(true);
  };

  return {
    name,
    code,
    error,
    handleNameChange,
    usingGeneratedCode,
    setUsingGeneratedCode,
  };
};
