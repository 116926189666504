import PageTitle from "#components/utils/PageTitle";
import withReceivedSkusLogic from "#components/HOC/withReceivedSkusLogic";
import ReceivedSkusList from "#components/receivedSkus/ReceivedSkusList";
import AuditLog from "#components/orders/AuditLog";
import ExpandedSku from "#components/inventory/ExpandedSku";
import Pagination from "#components/common/Pagination";
import EditReceivedSkuForm from "../components/receivedSkus/EditReceivedSkuForm";

const ReceivedSkus = ({
  receivedSkus,
  displayReceivedSkus,
  masterData,
  filters,
  onChangeFilter,
  selectedAudit,
  getAudit,
  setSelectedAudit,
  selectedReceivedSkus,
  setSelectedReceivedSkus,
  selectSku,
  selectedSkus,
  clearSelectedSkus,
  fetchedSku,
  setFetchedSku,
  getSku,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  fetchReceivedSku,
  fetchedReceivedSku,
  setFetchedReceivedSku,
  submitFetchedReceivedSku,
}) => {
  return (
    <div className="p-5">
      <PageTitle>Received SKUs</PageTitle>
      <ReceivedSkusList
        receivedSkus={displayReceivedSkus}
        masterData={masterData}
        filters={filters}
        onChangeFilter={onChangeFilter}
        getAudit={getAudit}
        selectedReceivedSkus={selectedReceivedSkus}
        setSelectedReceivedSkus={setSelectedReceivedSkus}
        selectSku={selectSku}
        selectedSkus={selectedSkus}
        clearSelectedSkus={clearSelectedSkus}
        getSku={getSku}
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        fetchReceivedSku={fetchReceivedSku}
      />
      {selectedAudit && (
        <AuditLog
          selectedAudit={selectedAudit}
          negativeAction={() => setSelectedAudit(null)}
        />
      )}
      {fetchedSku && (
        <ExpandedSku
          fetchedSku={fetchedSku}
          negativeAction={() => setFetchedSku(null)}
        />
      )}
      {fetchedReceivedSku && (
        <EditReceivedSkuForm
          fetchedReceivedSku={fetchedReceivedSku}
          onClose={() => setFetchedReceivedSku(null)}
          onSubmit={submitFetchedReceivedSku}
          onChange={(e) =>
            setFetchedReceivedSku({
              ...fetchedReceivedSku,
              quantity: parseInt(e.target.value),
            })
          }
          quantity={fetchedReceivedSku.quantity}
          title={`Edit quantity of ${fetchedReceivedSku.sku}`}
        />
      )}
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default withReceivedSkusLogic(ReceivedSkus);
