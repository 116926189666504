import { useQuery } from "#hooks/useQuery";
import { useContext, useEffect, useRef, useState } from "react";
import { GET_PRODUCTS, GET_LINKED_PRODUCTS } from "../../queries";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import ReactTooltip from "react-tooltip";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import {
  XIcon,
  TrashIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { CREATE_PRODUCT_LINKAGES } from "#mutations/index";
import { AppStateContext } from "#contexts/appState";
import noFilterIcon from "#static/images/nofilter.png";
import Modal from "#components/utils/Modal";

const LinkProduct = ({ product, customers, onClose }) => {
  const productsQuery = useQuery(GET_PRODUCTS);
  const [searchString, setSearchString] = useState("");
  const [displaySearchResults, setDisplaySearchResults] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoadingLinkedProducts, setIsLoadingLinkedProducts] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const appState = useContext(AppStateContext);
  const tableRef = useRef(null);

  const selectedProductIds = selectedProducts.map(
    (selectedProduct) => selectedProduct.id,
  );
  const productLinkageQuery = useQuery(CREATE_PRODUCT_LINKAGES);
  const getLinkedProductsQuery = useQuery(GET_LINKED_PRODUCTS);

  useEffect(() => {
    setIsLoadingLinkedProducts(true);
    getLinkedProductsQuery
      .fetchData({ id: product.id })
      .then((linkedProducts) => {
        setSelectedProducts(linkedProducts?.data?.getLinkedProducts || []);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingLinkedProducts(false);
      });
  }, []);

  useEffect(() => {
    productsQuery.fetchData({
      filters: {
        keyword: searchString,
        customer: [product.customer],
      },
    });
  }, [searchString]);

  const createProductLinkage = () => {
    productLinkageQuery
      .fetchData({
        productId: product.id,
        linkedProducts: selectedProductIds.map((selectedProductId) => ({
          productId: selectedProductId,
        })),
      })
      .then((response) => {
        if (response.error) {
          appState.setAlert(
            response.error?.message || "Error while linking products",
            "error",
            5000,
          );
        } else {
          appState.setAlert("Products Linked successfully", "success", 5000);
          onClose();
        }
        setShowConfirmationModal(false);
      });
  };

  const removeSelectedProduct = (productIndex) => {
    const updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts.splice(productIndex, 1);
    setSelectedProducts(updatedSelectedProducts);
  };

  if (isLoadingLinkedProducts) {
    return (
      <div className="mt-2 flex h-[300px] w-full flex-col items-center justify-center rounded border">
        <div className="h-10 w-10 animate-spin rounded-full border-t-4 border-solid border-hyperlinkColor"></div>
      </div>
    );
  }

  const filteredProducts =
    productsQuery.data?.products?.entities.filter((entity) => {
      return (
        (entity.baseUom || "EACH") === (product?.baseUom || "EACH") &&
        entity.id !== product?.id
      );
    }) || [];

  const onBeforeDragStart = () => {
    if (tableRef.current) {
      const table = tableRef.current;
      const colWidths = [];
      for (let col of table.rows[0].cells) {
        colWidths.push(col.clientWidth);
      }
      for (let row of table.rows) {
        Array.from(row.cells).forEach((cell, i) => {
          cell.style.width = `${colWidths[i]}px`;
        });
      }
    }
  };

  const onDragEnd = (result) => {
    if (result.destination.index === -1) return;
    const selectedProductsCopy = [...selectedProducts];
    const [reOrderedProducts] = selectedProductsCopy.splice(
      result.source.index,
      1,
    );
    selectedProductsCopy.splice(result.destination.index, 0, reOrderedProducts);
    setSelectedProducts(selectedProductsCopy);
  };

  return (
    <div>
      <div className="text-sm text-gray-600">
        Optimize order processing by linking and prioritizing products based on
        available quantities, especially for low-stock SKUs
      </div>
      <div className="mt-2 flex gap-2">
        <div className="">
          {
            <img
              src={
                (product?.images?.[0] && product?.images[0].display_url) ||
                FALLBACK_IMAGE_URL
              }
              style={{ width: "70px", height: "70px" }}
              className="border-grey-6000 border"
            />
          }
        </div>
        <div>
          <div className="font-semibold">{product?.name}</div>
          <div>
            <span className="text-gray-600">SKU:</span> {product?.sku}
          </div>
          {product?.asin && (
            <div>
              <span className="text-gray-600">ASIN:</span> {product?.asin}
            </div>
          )}
          <div>
            <span className="text-gray-600">Base UOM:</span>{" "}
            {product?.baseUom || "Each"}
          </div>
          <div>
            <span className="text-gray-600">Client:</span>{" "}
            {customers &&
              customers.find((item) => item.id === product?.customer)?.name}
          </div>
        </div>
      </div>
      <div className="mt-4 text-xl font-semibold">Select Products</div>
      <div
        className="mb-4 mt-1 flex rounded-lg border border-blue-800 bg-blue-50 p-3 text-sm text-gray-600 dark:bg-gray-800"
        role="alert">
        <div className="flex items-center">
          <InformationCircleIcon
            height={24}
            className="inline-block text-blue-800"
          />{" "}
        </div>
        <div className="grow-1 pl-2">
          {selectedProducts.length > 0
            ? "Easily manage product associations: Assign multiple products, set priorities by dragging, and unlink using the delete button."
            : "Users can link products that share the same warehouse, client, and base unit of measure (UOM) within the platform."}
        </div>
      </div>
      <input
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        placeholder="Search for products"
        className="border-grey-500 mr-2 w-full rounded border p-3"
        onFocus={() => {
          setDisplaySearchResults(true);
        }}
      />
      <div className="relative pt-2">
        {selectedProducts.length > 0 ? (
          <>
            <DragDropContext
              onDragEnd={onDragEnd}
              onBeforeDragStart={onBeforeDragStart}>
              <table ref={tableRef} className="w-full">
                <thead>
                  <tr>
                    <th className="bg-[#FAFAFA] p-4 text-center"></th>
                    <th className="bg-[#FAFAFA] p-4 text-left">Product Info</th>
                    <th className="bg-[#FAFAFA] p-4 text-center">Order</th>
                    <th className="bg-[#FAFAFA] p-4 text-center">Action</th>
                  </tr>
                </thead>
                <Droppable droppableId="Column">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedProducts.map((selectedProduct, index) => {
                        return (
                          <Draggable
                            draggableId={selectedProduct.id}
                            index={index}
                            className=""
                            key={selectedProduct.id}>
                            {(provided) => (
                              <tr
                                className="border-grey-6000 link-product-draggable border-b bg-white"
                                {...provided.draggableProps}
                                ref={provided.innerRef}>
                                <td {...provided.dragHandleProps}>
                                  <svg
                                    className="m-auto"
                                    width="12"
                                    height="20"
                                    viewBox="0 0 12 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle
                                      cx="2"
                                      cy="2"
                                      r="2"
                                      fill="#B7B9BB"
                                    />
                                    <circle
                                      cx="2"
                                      cy="10"
                                      r="2"
                                      fill="#B7B9BB"
                                    />
                                    <circle
                                      cx="2"
                                      cy="18"
                                      r="2"
                                      fill="#B7B9BB"
                                    />
                                    <circle
                                      cx="10"
                                      cy="2"
                                      r="2"
                                      fill="#B7B9BB"
                                    />
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="2"
                                      fill="#B7B9BB"
                                    />
                                    <circle
                                      cx="10"
                                      cy="18"
                                      r="2"
                                      fill="#B7B9BB"
                                    />
                                  </svg>
                                </td>
                                <td>
                                  <div className="flex gap-2 p-2">
                                    <div className="flex items-center">
                                      <img
                                        src={
                                          selectedProduct?.images?.[0]
                                            ?.display_url || FALLBACK_IMAGE_URL
                                        }
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                        }}
                                        className="border-grey-6000 border"
                                      />
                                    </div>
                                    <div>
                                      <div>{selectedProduct?.name}</div>
                                      <div>
                                        <span className="text-gray-600">
                                          SKU:
                                        </span>{" "}
                                        {selectedProduct?.sku}
                                      </div>
                                      {selectedProduct?.asin && (
                                        <div>
                                          <span className="text-gray-600">
                                            ASIN:
                                          </span>{" "}
                                          {selectedProduct?.asin}
                                        </div>
                                      )}
                                      <div>
                                        <span className="text-gray-600">
                                          Base UOM:
                                        </span>{" "}
                                        {selectedProduct?.baseUom || "Each"}
                                      </div>
                                      <div>
                                        <span className="text-gray-600">
                                          Client:
                                        </span>{" "}
                                        {customers &&
                                          customers.find(
                                            (item) =>
                                              item.id === product?.customer,
                                          )?.name}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="p-2 text-center">{index + 1}</td>
                                <td className="p-2 text-center">
                                  <TrashIcon
                                    className="m-auto h-6 w-6 cursor-pointer text-red-600"
                                    onClick={() => {
                                      removeSelectedProduct(index);
                                    }}
                                  />
                                </td>
                                {provided.placeholder}
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
            <footer className="mt-2 flex h-16 flex-row-reverse items-center justify-between">
              <div className="flex space-x-4">
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className={`cursor-pointer rounded-md border border-mediumGray px-6 py-3 text-base font-semibold text-mediumGray`}>
                  Cancel
                </button>
                <button
                  disabled={productLinkageQuery?.loading}
                  className={`cursor-pointer rounded-md bg-primaryAccent px-6 py-3 text-base font-semibold text-white disabled:bg-gray-600 disabled:text-white`}
                  onClick={() => setShowConfirmationModal(true)}>
                  Save
                </button>
              </div>
            </footer>
          </>
        ) : (
          <>
            <div className="absolute z-20 w-full">
              <div className="h-300 mt-2 flex w-full flex-col items-center justify-center rounded bg-white">
                <img
                  style={{ width: "154px", height: "144px" }}
                  alt={""}
                  src={noFilterIcon}
                />
                <p className="mt-2 text-xl font-semibold">No Linked Products</p>
                <p className="mt-2 text-center font-light">
                  Search and select product which needs to be linked to the
                  selected product
                </p>
              </div>
              <footer className="mt-8 flex h-16 flex-row-reverse items-center justify-between">
                <div className="flex space-x-4">
                  <button
                    onClick={() => {
                      onClose();
                    }}
                    className={`cursor-pointer rounded-md border border-mediumGray px-6 py-3 text-base font-semibold text-mediumGray`}>
                    Cancel
                  </button>
                  <button
                    disabled={
                      !(
                        getLinkedProductsQuery?.data &&
                        getLinkedProductsQuery?.data?.getLinkedProducts
                          ?.length > 0
                      ) || productLinkageQuery?.loading
                    }
                    className={`cursor-pointer rounded-md bg-primaryAccent px-6 py-3 text-base font-semibold text-white disabled:bg-gray-600 disabled:text-white`}
                    onClick={() => setShowConfirmationModal(true)}>
                    Save
                  </button>
                </div>
              </footer>
            </div>
          </>
        )}

        {displaySearchResults && searchString.length > 0 && (
          <>
            <div className="absolute right-0 top-0 z-30 -ml-8 flex pt-4 sm:-ml-10">
              <button
                type="button"
                className="relative rounded-md text-gray-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => {
                  setDisplaySearchResults(false);
                }}>
                <span className="absolute -inset-2" />
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6 bg-transparent" aria-hidden="true" />
              </button>
            </div>
            <div className="absolute top-0 z-20 mt-4 h-[355px] w-full overflow-y-scroll rounded border bg-white p-3 pt-4">
              {filteredProducts.length > 0 ? (
                filteredProducts.map((item, index) => (
                  <div className="mb-3 flex" key={index}>
                    <div className="flex items-start">
                      <img
                        src={
                          item?.images?.[0]?.display_url
                            ? item.images[0].display_url
                            : FALLBACK_IMAGE_URL
                        }
                        style={{ width: "70px", height: "70px" }}
                        alt={""}
                        className="border-grey-6000 rounded border object-cover"
                      />
                    </div>
                    <div className="ml-4 flex-1" style={{ maxWidth: "220px" }}>
                      <p
                        className="w-full truncate text-lg font-semibold"
                        data-tip
                        data-for={`${item.id}_${index}`}>
                        {item.name}
                      </p>
                      <ReactTooltip id={`${item.id}_${index}`}>
                        {item.name}
                      </ReactTooltip>
                      <p className="text-sm font-normal text-lightGray">
                        SKU: {item.sku}
                      </p>
                      {item.asin && (
                        <p className="text-sm font-normal text-lightGray">
                          ASIN:{" "}
                          {buildMarketplaceHyperlink(
                            item.asin,
                            "ASIN",
                            item.source,
                          )}
                        </p>
                      )}
                      <p className="text-sm font-normal text-lightGray">
                        Base UOM: {item.baseUOM || "Each"}
                      </p>
                      <p className="text-sm font-normal text-lightGray">
                        Client:{" "}
                        {customers &&
                          customers.find(
                            (item) => item.id === product?.customer,
                          )?.name}
                      </p>
                    </div>
                    <div className="ml-auto flex items-end">
                      {selectedProductIds.includes(item.id) ? (
                        <button
                          className="disabled my-auto rounded bg-white text-hyperlinkColor"
                          style={{ width: "120px", height: "32px" }}
                          disabled>
                          Selected
                        </button>
                      ) : (
                        <button
                          className="my-auto rounded bg-hyperlinkColor text-white"
                          style={{ width: "120px", height: "32px" }}
                          onClick={() => {
                            setSelectedProducts([...selectedProducts, item]);
                            setDisplaySearchResults(false);
                          }}>
                          Select
                        </button>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex h-329 flex-col items-center justify-center rounded bg-white">
                  <img
                    style={{ width: "154px", height: "144px" }}
                    alt={""}
                    src="https://hopstack-pub.s3.amazonaws.com/icons/Searching.png"
                  />
                  <p className="mt-2 text-base font-semibold">
                    {productsQuery.loading
                      ? "Searching for products"
                      : "No Products Found"}
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showConfirmationModal && (
        <Modal
          title={"Are you sure?"}
          onClose={() => setShowConfirmationModal(false)}
          negativeAction={() => setShowConfirmationModal(false)}
          positiveAction={() => createProductLinkage()}
          disabled={productLinkageQuery?.loading}
          positiveActionText="Confirm">
          <div className="space-y-4">
            <div>
              <a>
                Are you sure you want to link the products to the selected
                products?
              </a>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default LinkProduct;
