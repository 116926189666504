import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";

const LinteItemsSuggestedChangesCard = ({ product, currencySymbol }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasQuantityChange =
    product.quantity &&
    product.quantity.originalQuantity !== product.quantity.suggestedQuantity;

  const hasPriceChange =
    product.price &&
    product.price.originalPrice !== product.price.suggestedPrice;

  const hasComponentChanges = (componentData) =>
    componentData &&
    (componentData.price.originalPrice !== componentData.price.suggestedPrice ||
      componentData.quantity.originalQuantity !==
        componentData.quantity.suggestedQuantity ||
      componentData.uom.originalUom !== componentData.uom.suggestedUom);

  return (
    (hasQuantityChange || hasPriceChange) && (
      <div className="mb-4 rounded-lg border">
        <div
          className="flex w-full cursor-pointer items-center justify-between p-4"
          onClick={() => setIsOpen(!isOpen)}>
          <div className="flex items-center">
            <img
              src={product.image}
              alt={product.name}
              className="mr-4 h-16 w-16 object-cover"
            />
            <div>
              <Tooltip title={product.name}>
                <p className="w-48 truncate font-semibold">{product.name}</p>
              </Tooltip>
              <p className="truncate text-sm text-gray-500">
                SKU: {product.sku}
              </p>
              <p className="truncate text-sm text-gray-500">
                Variant: {product.variant}
              </p>
            </div>
          </div>
          {isOpen ? (
            <ChevronUpIcon className="h-5 w-5 text-black" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-black" />
          )}
        </div>
        {isOpen && (
          <div className="p-4">
            <div className="mb-4 grid grid-cols-2 gap-4">
              {hasQuantityChange && (
                <div className="flex flex-col gap-3 rounded-lg border p-4">
                  <p className="text-sm">Quantity Change</p>
                  <div className="flex justify-between">
                    <p className="flex flex-col text-gray-500">
                      <span>Original Qty:</span>
                      <span className="text-lg font-semibold text-green-500">
                        {product.quantity.originalQuantity}
                      </span>
                    </p>
                    <p className="flex flex-col text-gray-500">
                      <span>Suggested Qty:</span>{" "}
                      <span className="text-lg font-semibold text-yellow-500">
                        {product.quantity.suggestedQuantity}
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {hasPriceChange && (
                <div className="flex flex-col gap-3 rounded-lg border p-4">
                  <p className="text-sm">Price Change</p>
                  <div className="flex justify-between">
                    <p className="flex flex-col text-gray-500">
                      <span>Original Price:</span>
                      <span className="text-lg font-semibold text-green-500">
                        {`${currencySymbol} ${product.price.originalPrice}`}
                      </span>
                    </p>
                    <p className="flex flex-col text-gray-500">
                      <span>Suggested Price:</span>{" "}
                      <span className="text-lg font-semibold text-yellow-500">
                        {`${currencySymbol} ${product.price.suggestedPrice}`}
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {product.components.map((componentData, index) => {
                if (hasComponentChanges(componentData))
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-3 rounded-lg border p-4">
                      <Tooltip title={componentData.name}>
                        <p className="w-48 truncate text-sm">
                          {componentData.name}
                        </p>
                      </Tooltip>

                      {componentData.price.originalPrice !==
                        componentData.price.suggestedPrice && (
                        <div className="flex justify-between">
                          <p className="flex flex-col text-gray-500">
                            <span>Original Price:</span>
                            <span className="text-lg font-semibold text-green-500">
                              {`${currencySymbol} ${componentData.price.originalPrice}`}
                            </span>
                          </p>
                          <p className="flex flex-col text-gray-500">
                            <span>Suggested Price:</span>{" "}
                            <span className="text-lg font-semibold text-yellow-500">
                              {`${currencySymbol} ${componentData.price.suggestedPrice}`}
                            </span>
                          </p>
                        </div>
                      )}

                      {componentData.quantity.originalQuantity !==
                        componentData.quantity.suggestedQuantity && (
                        <div className="flex justify-between">
                          <p className="flex flex-col text-gray-500">
                            <span>Original Quantity:</span>
                            <span className="text-lg font-semibold text-green-500">
                              {componentData.quantity.originalQuantity}
                            </span>
                          </p>
                          <p className="flex flex-col text-gray-500">
                            <span>Suggested Quantity:</span>{" "}
                            <span className="text-lg font-semibold text-yellow-500">
                              {componentData.quantity.suggestedQuantity}
                            </span>
                          </p>
                        </div>
                      )}

                      {/* Conditional rendering for UOM */}
                      {componentData.uom.originalUom !==
                        componentData.uom.suggestedUom && (
                        <div className="flex justify-between">
                          <p className="flex flex-col text-gray-500">
                            <span>Original UOM:</span>
                            <span className="text-lg font-semibold text-green-500">
                              {componentData.uom.originalUom}
                            </span>
                          </p>
                          <p className="flex flex-col text-gray-500">
                            <span>Suggested UOM:</span>{" "}
                            <span className="text-lg font-semibold text-yellow-500">
                              {componentData.uom.suggestedUom}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  );
              })}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default LinteItemsSuggestedChangesCard;
