import { InformationCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SectionTabs = ({ tabs, setTabs }) => {
  return (
    <div>
      <div className="pr-4 sm:hidden">
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primaryAccent focus:outline-none focus:ring-primaryAccent sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}>
          {tabs.map((tab) => (
            <option
              key={tab.name}
              onChange={() =>
                setTabs(
                  tabs.map((t) => {
                    t.current = t.name === tab.name;
                    return t;
                  }),
                )
              }>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-300">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-b-4 border-primaryAccent text-primaryAccent"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "flex cursor-pointer whitespace-nowrap border-b-2 px-1 py-2 text-sm font-medium",
                )}
                onClick={() =>
                  setTabs(
                    tabs.map((t) => {
                      t.current = t.name === tab.name;
                      return t;
                    }),
                  )
                }>
                {tab.name}
                {tab.tooltip && (
                  <>
                    <InformationCircleIcon
                      className="h-5 w-5 pl-1 text-gray-400"
                      data-tip
                      data-for={`${tab.name}`}
                    />
                    <ReactTooltip id={`${tab.name}`}>
                      {tab.tooltip}
                    </ReactTooltip>
                  </>
                )}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SectionTabs;
