import { useState, useContext, useEffect } from "react";
import { useQuery } from "#hooks/useQuery";
import { SAVE_PRODUCT } from "#mutations";
import ModalV2 from "#components/utils/ModalV2";
import { AppStateContext } from "#contexts/appState";
import { TrashIcon } from "@heroicons/react/outline";

import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import ProductInfo from "#components/consignments/ConsignmentForm/ProductInfo";

const ALERT_TIMEOUT_IN_MS = 5000;

const UOMConfigurationV2 = ({ item, product, onUpdate = null, onClose }) => {
  const appState = useContext(AppStateContext);
  const saveProductQuery = useQuery(SAVE_PRODUCT); // to save the product with modified UOM Changes.

  const [activeUomLabels] = useState(
    appState.masterData?.uomLabels?.filter((label) => label.isActive),
  );
  const [selectedProduct, setSelectedProduct] = useState({ ...product });
  const [baseUOM, setBaseUOM] = useState(
    activeUomLabels.find((i) => i.name === selectedProduct.baseUom),
  );
  const [possibleTargetUOMs, setPossibleTargetUOMs] = useState(
    activeUomLabels.filter((label) => label.name !== baseUOM.name),
  );
  const [showAddConfiguration, setShowAddConfiguration] = useState(true);

  // Save Product Query Effect to save the product with updated UOM Config.
  useEffect(() => {
    saveProductQuery.loading ? appState.setLoading() : appState.removeLoading();

    if (saveProductQuery.data) {
      appState.setAlert(saveProductQuery.data.saveProduct.message);
      // once the product is saved, trigger the onUpdate method call if passed.
      if (onUpdate) {
        onUpdate();
      }
    }

    if (saveProductQuery.error) {
      appState.setAlert(
        saveProductQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [saveProductQuery.data, saveProductQuery.loading, saveProductQuery.error]);

  // Called when you change the base uom of the product
  const updateBaseUOM = (uom) => {
    setSelectedProduct({
      ...selectedProduct,
      baseUom: uom,
      uomConfiguration: [],
    });
    const baseUOMFormFactor = activeUomLabels.find(
      (formFactor) => formFactor.name == uom,
    );
    setBaseUOM(baseUOMFormFactor);
    const targetUoms = activeUomLabels.filter(
      (label) => label.name !== baseUOMFormFactor.name,
    );
    setPossibleTargetUOMs(targetUoms);
    setShowAddConfiguration(targetUoms.length > 0);
  };

  // Called when you change the source uom / from uom in the uom configuration
  const changeBaseUom = (idx, name) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].baseUom = name;
    setSelectedProduct({ ...clonedProduct });
  };

  // Called when you change the source uom / from uom quantity in the uom configuration
  const changeBaseUomQuantity = (idx, e) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].baseUomQuantity =
      isNaN(parseInt(e.target.value)) === false
        ? parseInt(e.target.value)
        : null;
    setSelectedProduct({ ...clonedProduct });
  };

  // Called when you change the target uom / to uom in the uom configuration
  const changeTargetUom = (idx, name) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].targetUom = name;
    setSelectedProduct({ ...clonedProduct });
  };

  const addConfiguration = () => {
    const existingUomConfigurations = selectedProduct.uomConfiguration;
    if (existingUomConfigurations.length > 0) {
      const previousUOMConfig =
        existingUomConfigurations[existingUomConfigurations.length - 1];
      if (
        !previousUOMConfig.baseUomQuantity ||
        isNaN(previousUOMConfig.baseUomQuantity) ||
        !previousUOMConfig.baseUom ||
        !previousUOMConfig.targetUom
      ) {
        appState.setAlert("Provide a valid UOM Configuration", "error", 5000);
        return;
      }
    }

    setSelectedProduct({
      ...selectedProduct,
      uomConfiguration: [
        ...existingUomConfigurations,
        {
          baseUom: "",
          baseUomQuantity: 1,
          targetUom: "",
        },
      ],
    });
    setShowAddConfiguration(
      existingUomConfigurations.length + 1 < possibleTargetUOMs.length,
    );
  };

  const removeUomConfiguration = (idx) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration = clonedProduct.uomConfiguration.filter(
      (_, index) => idx !== index,
    );
    setSelectedProduct({ ...clonedProduct });
    setShowAddConfiguration(
      clonedProduct.uomConfiguration.length < possibleTargetUOMs.length,
    );
  };

  const updateProduct = () => {
    saveProductQuery.fetchData({
      ...selectedProduct,
    });
  };

  return (
    <ModalV2
      title={`UOM Configuration`}
      positiveText={"Update"}
      positiveAction={updateProduct}
      negativeText={"Cancel"}
      negativeAction={onClose}
      onClose={onClose}
      noPadding={true}>
      <div className="mx-6">
        {/* Product Info */}
        <div className="w-full">
          <label className="text-md mb-2 block font-medium">Product Info</label>
          <div className="rounded-lg border border-borderGray p-2">
            <ProductInfo
              item={item}
              itemDetails={selectedProduct}
              style={{ totalWidth: "w-full", width: "w-422" }}
            />
          </div>
        </div>
        {/* Base Unit of Measurement */}
        <div className="my-4">
          <label className="text-md mb-2 block font-medium">
            Base Unit of Measurement
          </label>
          <div className="w-204">
            <AutocompleteDropdownV2
              options={activeUomLabels}
              labelKey="name"
              valueKey="name"
              onChange={(uom) => updateBaseUOM(uom)}
              value={selectedProduct.baseUom}
              placeholder="Select Base UOM"
              searchable={false}
            />
          </div>
          {selectedProduct.baseUOM && (
            <div className="text-xs">
              <span className="text-trashIconRed">*</span> Changing Base form
              factor will reset the configuration
            </div>
          )}
        </div>
        {/* UOM Configuration */}
        {selectedProduct.baseUom && (
          <div className="mb-2">
            <label className="text-md mb-2 block font-medium">
              Configuration
            </label>
            <div className="space-y-4">
              {selectedProduct?.uomConfiguration?.map(
                (uomConfiguration, idx) => {
                  let baseUOMFormFactorList = [];

                  if (idx === 0) {
                    baseUOMFormFactorList = [baseUOM];
                  } else {
                    const previousTargetUOM = activeUomLabels.find(
                      (label) =>
                        label.name ===
                        selectedProduct.uomConfiguration[idx - 1].targetUom,
                    );
                    baseUOMFormFactorList = [previousTargetUOM];
                  }

                  const uomLabelsToExcludeInTargetList = [
                    selectedProduct.baseUom,
                    ...selectedProduct.uomConfiguration
                      .filter((_, cIdx) => cIdx < idx)
                      .map((config) => config.targetUom),
                  ];

                  const targetUOMFormFactorList = activeUomLabels.filter(
                    (label) =>
                      uomLabelsToExcludeInTargetList.includes(label.name) ===
                      false,
                  );

                  return (
                    <UOMConfig
                      uomConfiguration={uomConfiguration}
                      idx={idx}
                      changeBaseUomQuantity={changeBaseUomQuantity}
                      baseUOMFormFactorList={baseUOMFormFactorList}
                      targetUOMFormFactorList={targetUOMFormFactorList}
                      changeBaseUom={changeBaseUom}
                      changeTargetUom={changeTargetUom}
                      removeUomConfiguration={removeUomConfiguration}
                      disableInteraction={
                        selectedProduct.uomConfiguration.length - 1 !== idx
                      }
                    />
                  );
                },
              )}
            </div>
          </div>
        )}
        {/* Add UOM Config */}
        {showAddConfiguration && selectedProduct.baseUom && (
          <button
            className="mr-4 h-33 w-171 cursor-pointer rounded border bg-primaryAccent p-1 text-sm font-medium text-white"
            onClick={addConfiguration}>
            + Add Configuration
          </button>
        )}
      </div>
    </ModalV2>
  );
};

const UOMConfig = ({
  uomConfiguration,
  idx,
  changeBaseUomQuantity,
  baseUOMFormFactorList,
  changeBaseUom,
  targetUOMFormFactorList,
  changeTargetUom,
  removeUomConfiguration,
  disableInteraction,
}) => {
  return (
    <div className="flex items-center space-x-4">
      <div>
        <input
          type="number"
          id="quantity"
          label="Quantity"
          placeholder=" "
          onChange={(qty) => changeBaseUomQuantity(idx, qty)}
          value={parseInt(uomConfiguration.baseUomQuantity)}
          name="quantity"
          min="1"
          className="w-111 rounded border border-borderGray p-2"
        />
      </div>
      <div className="w-204">
        <AutocompleteDropdownV2
          options={baseUOMFormFactorList}
          labelKey="name"
          valueKey="name"
          onChange={(uom) => changeBaseUom(idx, uom)}
          value={uomConfiguration.baseUom}
          placeholder="UOM"
          disabled={disableInteraction}
          searchable={false}
        />
      </div>
      <div className="text-2xl">=</div>
      <div className="w-204">
        <AutocompleteDropdownV2
          options={targetUOMFormFactorList}
          labelKey="name"
          valueKey="name"
          onChange={(uom) => changeTargetUom(idx, uom)}
          value={uomConfiguration.targetUom}
          placeholder="Target UOM"
          disabled={disableInteraction}
          searchable={false}
        />
      </div>
      {!disableInteraction && (
        <div
          className="cursor-pointer text-lg font-bold text-red-500"
          onClick={() => removeUomConfiguration(idx)}>
          <TrashIcon className="h-8 w-8" />
        </div>
      )}
    </div>
  );
};

export default UOMConfigurationV2;
