import { useState } from "react";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";

const ScanOrEnterManually = ({ text, onSubmit, appState }) => {
  const [barcode, setBarcode] = useState(null);
  const [formFactor, setFormFactor] = useState(null);

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex-col">
        <div className="rounded-2xl border bg-white shadow-lg">
          <div className="flex items-center justify-center pb-5 pt-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="px-5 py-5 text-xl font-medium text-454A4F">
              {text || "Scan a Tracking Number"}
            </div>
          </div>
          <div className="items-center text-center font-montserrat text-2xl">
            OR ENTER MANUALLY
          </div>
          <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
            <div>
              <TextField
                type="text"
                id="name"
                label="Enter Value"
                placeholder=" "
                onChange={(e) => setBarcode(e.target.value)}
                value={barcode}
                onKeyDown={(e) =>
                  e.key === "Enter" ? onSubmit({ barcode, formFactor }) : {}
                }
                autoFocus={true}
              />
            </div>
            <div className="mt-4">
              <Dropdown
                placeholder={"Form Factor"}
                list={appState.masterData.uomLabels.filter(
                  (label) => label.isActive,
                )}
                labelKey="name"
                valueKey="name"
                name="formFactor"
                setSelected={(selectedValue) => setFormFactor(selectedValue)}
                selectedValue={formFactor}
              />
            </div>
            <div className="flex space-x-2">
              <div
                className={`mt-4 flex-1 rounded-md py-2 text-center text-xl text-white ${
                  barcode && barcode.trim() !== "" && formFactor
                    ? "bg-2C7695"
                    : "bg-gray-400"
                }`}
                onClick={() => onSubmit({ barcode, formFactor })}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanOrEnterManually;
