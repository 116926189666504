export const uploadBulkConstants = {
  // Add Button
  AddBttn: {
    text: "Upload File",
  },
  //  1st Modal
  fileuploadModal: {
    title: "Upload File",
    positiveText: "Map Columns",
    label: "File Uploaded",
    inputType: "file",
    inputAccept: ".xlsx, .xls, .csv",
    modalbuttonText: "Preview Spreadsheet",
  },
  //  2nd Modal
  matchcolumnsModal: {
    title: "Match-Columns",
    positiveText: "Confirm",
    columns: ["Spreadsheet Columns", "Dashboard Columns"],
    placeholder: "Columns",
    modalbuttonText: "+ Add another attribute",
    specifiedFields: [
      "name",
      "code",
      "location",
      "typeOfWarehouse",
      "storageTypes",
    ],
    validateText: "Validate",
  },
  //  3rd Modal
  successModal: {
    title: "UPLOADED",
    content: "You've successfully uploaded the file!",
    text: "Upload Another File",
  },

  // 4th Modal
  validation: {
    title: "Validation",
    positiveText: ["Proceed to Upload", "Download"],
  },

  // finalError
  finalError: {
    title: "Oops!",
    text: "Upload Another File",
  },

  // errors
  errors: [
    "Please select the file before proceeding",
    "Please match the columns before proceeding",
    "Cannot exceed number of spreadsheet columns",
    (item) => {
      return `You can't have multiple ${item}`;
    },
    "Please select the appropriate values",
  ],
};

export const uploadBulkStyles = {
  //   Dropdown
  dropDown: {
    outerDiv: "mx-4 flex flex-col items-center justify-center ",
    titleDiv: "flex flex-row justify-center mb-2 font-bold",
    innerDiv: " flex flex-col items-center justify-center",
    select: "w-full mb-4 border-slate-300 rounded",
  },
  //  1st Modal
  fileuploadModalstyles: {
    ButtonStyles:
      "pt-4 w-full  p-4 flex items-center justify-center space-x-4 rounded-bl-3xl rounded-br-3xl",
    positiveClassName: "bg-E5E5E5 font-normal text-white",
    negativeClassname:
      "bg-red-600  font-normal text-white ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-red-700",
    inputouterDiv: "flex flex-col justify-center items-start",
    inputinnerDiv:
      "flex  justify-center items-center border-transparent rounded-full ring-0 focus:ring-0 outline-none focus:outline-none p-0",
    label:
      "text-base font-medium text-white text-2C7695 font-montserrat font-semibold",
    span: "w-full p-3 px-6",
    input: "border-black p-1.5 text-black",
    inputColor: "primary",
    modalbutton: "border-white text-base text-blue-600 mt-2",
    tableDiv:
      "ml-6  flex justify-start overflow-x-scroll overflow-y-scroll h-36 scroll scrollbar scrollbar-thumb-black whitespace-nowrap scroll-smooth  ",
    table: " border-2 border-black rounded-sm ",
    thead: "border border-black text-xs",
    tbody: "",
    td: "border border-black text-xs",
  },

  //  2nd Modal
  checkboxModalstyles: {
    ButtonStyles:
      "pt-4 w-full  p-4 flex items-center justify-center space-x-4 rounded-bl-3xl rounded-br-3xl",
    positiveClassName: "bg-E5E5E5 font-normal text-white",
    negativeClassname: "text-black font-normal",
  },
  //  3rd Modal
  matchcolumnsModalstyles: {
    ButtonStyles:
      "pt-4 w-full  p-4 flex items-center justify-center space-x-4 rounded-bl-3xl rounded-br-3xl",
    positiveClassName: "bg-E5E5E5 font-normal text-white",
    negativeClassname: "text-black font-normal",
    validateClassname:
      "bg-red-600  font-normal text-white ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-red-700",
    outerDiv: "flex flex-col items-end justify-center",
    innerDiv: "flex flex-row items-start justify-between w-full",

    modalbuttonDiv: "flex flex-row justify-end",
    modalbutton: "border-white text-base text-blue-600",
  },
  //  4th Modal
  successModalstyles: {
    modalbtnDiv: "flex flex-col items-center justify-center",
    modalbutton: "border-white text-base text-white bg-E5E5E5",
  },
  // ErrorModal
  errorModal: {
    modalbtnDiv: "flex flex-col items-center justify-center",
    modalbutton: "border-white text-base text-white bg-E5E5E5",
    content: "p-4 text-base font-normal text-red-500 ml-px",
  },
  uploadAgain: {
    modalbutton: "border-white text-base text-white",
  },
};
