import React from "react";
import PurchaseOrderList from "../../newUiComponents/purchaseOrder/PurchaseOrderList";
import { checkURL } from "#components/catalogs/catalogTabs/CatalogMainContent";
function PurchaseOrderListPage() {
  if (checkURL())
    return (
      <div className="h-100v bg-white font-inter">
        <PurchaseOrderList />
      </div>
    );
  else
    return (
      <div className="flex h-full w-full justify-center">
        <h2>Coming sooon....</h2>
      </div>
    );
}

export default PurchaseOrderListPage;
