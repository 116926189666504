import Modal from "#components/utils/Modal";

const ExpandedShippingAddress = ({ shippingAddress, negativeAction }) => {
  let address = shippingAddress.shippingAddress || {};
  return (
    <Modal
      negativeAction={negativeAction}
      title={`Shipping Address`}
      noPadding={true}
      positiveAction={negativeAction}
      positiveText="OK">
      <div className="p-4">
        <div className="p-4 pl-2 font-montserrat text-xl font-bold">
          Name: {address.name}
        </div>
        <div className="p-4 pl-2 font-montserrat text-xl font-bold">
          Email: {address.email || shippingAddress.email}
        </div>
        <div className="p-4 pl-2 font-montserrat text-xl font-bold">
          Phone: {address.phone}
        </div>
        <table className="divide-y divide-gray-200 border border-gray-400 px-2">
          <thead className="rounded-full bg-primaryAccent p-4 px-12">
            <tr className="border-left px-12 font-montserrat text-textWhite">
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                Line 1
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                Line 2
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                City
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                State
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                Zip
              </th>
              <th
                scope="col"
                className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                Country
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white">
              <td
                className={`tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent`}>
                {address.line1}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {address.line2}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {address.city}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {address.state}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {address.zip}
              </td>

              <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                {address.country}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ExpandedShippingAddress;

const truncate = (str) => {
  return str.length > 40 ? str.substring(0, 40) + "..." : str;
};
