const passwordStrengthValidator = Object.freeze({
  testPasswordLength: (password) =>
    password && password.length >= 8
      ? "text-green-400"
      : password && password.trim() !== ""
        ? "text-red-400"
        : "text-black",
  testPasswordMatch: (password, confirmPassword) =>
    password && confirmPassword && password === confirmPassword
      ? "text-green-400"
      : password && confirmPassword
        ? "text-red-400"
        : "text-black",
  testSequentialPassword: (password) => {
    if (!password || password.trim().length === 0) {
      return "text-black";
    }
    const repeatedCharRegex = /^(.)\1*$/;

    if (repeatedCharRegex.test(password)) {
      return "text-red-400";
    }
    return "text-green-400";
  },
  testPassword: (password, confirmPassword) =>
    password && confirmPassword && password === confirmPassword,
});

export default passwordStrengthValidator;
