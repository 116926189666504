import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import AddButton from "#components/utils/AddButton";
import Autocomplete from "#components/utils/Autocomplete";

const StockTransferForm = ({
  onClose,
  title,
  warehouses,
  selectedStockTransfer,
  onSubmit,
  onChangeDropdown,
  addItems,
  onChangeItem,
  removeItem,
  users,
  availableInventory,
  onChangeInventorySearch,
  skuBinLocations,
  onChangeSkuBinLocationSearch,
  binLocations,
  onChangeBinLocationSearch,
  customers,
}) => {
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      positiveAction={onSubmit}
      positiveText={"Submit"}>
      <div className="space-y-4">
        <div>
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Select Warehouse
            </span>
          </label>
          <Autocomplete
            options={warehouses}
            boxWidth="w-full"
            labelKey="name"
            valueKey="id"
            onChange={(e) => {
              onChangeDropdown("warehouse", e);
            }}
            value={selectedStockTransfer.warehouse}
            placeholder="Start typing warehouse"
            name="warehouse"
            id="WAREHOUSE_ID"
          />
        </div>
        {selectedStockTransfer.warehouse && (
          <div>
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                Select Client
              </span>
            </label>
            <Autocomplete
              options={customers?.filter((customer) =>
                customer.warehouses?.includes(selectedStockTransfer.warehouse),
              )}
              boxWidth="w-full"
              labelKey="name"
              valueKey="id"
              onChange={(e) => {
                onChangeDropdown("customer", e);
              }}
              value={selectedStockTransfer.customer}
              placeholder="Start typing client"
              name="customer"
              id="CUSTOMER_ID"
            />
          </div>
        )}

        {selectedStockTransfer.warehouse && selectedStockTransfer.customer && (
          <div>
            <label className="mb-2 block text-left">
              <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                SKU
              </span>
            </label>
            <Autocomplete
              options={
                availableInventory &&
                availableInventory.map((item) => ({
                  name: `${item.sku}`,
                  id: item.id,
                }))
              }
              boxWidth="w-full"
              labelKey="name"
              valueKey="id"
              onChange={(selectedId) => {
                if (!selectedId) {
                  onChangeDropdown("sku", "", null);
                  return;
                }

                const selectedOption = availableInventory.find(
                  (item) => item.id === selectedId,
                );

                if (selectedOption) {
                  onChangeDropdown(
                    "sku",
                    selectedOption.sku,
                    selectedOption.id,
                  );
                }
              }}
              value={selectedStockTransfer.sku}
              useValueDirectly={true}
              placeholder="Start typing SKU"
              name="sku"
              onKeyDown={onChangeInventorySearch}
              id="SKU_ID"
            />
          </div>
        )}

        {selectedStockTransfer.warehouse &&
          selectedStockTransfer.customer &&
          selectedStockTransfer.sku && (
            <>
              <div>
                <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                  Bin to Bin Transfer Details
                </span>
              </div>

              {selectedStockTransfer.items &&
                selectedStockTransfer.items.length > 0 &&
                selectedStockTransfer.items.map((item, idx) => (
                  <div className="flex items-center space-x-2" key={idx}>
                    <div className="w-64">
                      <Autocomplete
                        options={skuBinLocations}
                        labelKey="barcode"
                        valueKey="barcode"
                        onChange={(e) => {
                          onChangeItem(idx, {
                            target: {
                              name: "fromBin",
                              value: e,
                            },
                          });
                        }}
                        value={item.fromBin}
                        useValueDirectly={true}
                        onKeyDown={(e) => onChangeSkuBinLocationSearch(e)}
                        placeholder="From"
                        name="fromBin"
                        id="FROM_BIN"
                      />
                    </div>{" "}
                    <div className="w-64">
                      <Autocomplete
                        options={binLocations}
                        labelKey="code"
                        valueKey="code"
                        onChange={(e) => {
                          onChangeItem(idx, {
                            target: {
                              name: "toBin",
                              value: e,
                            },
                          });
                        }}
                        value={item.toBin}
                        useValueDirectly={true}
                        onKeyDown={(e) => onChangeBinLocationSearch(e)}
                        placeholder="To"
                        name="toBin"
                        id="TO_BIN"
                      />
                    </div>{" "}
                    <div>
                      <TextField
                        type="number"
                        id="quantity"
                        label="Quantity"
                        min="0"
                        placeholder=" "
                        onChange={(e) => {
                          onChangeItem(idx, e);
                        }}
                        value={parseInt(item.quantity)}
                        name="quantity"
                      />
                    </div>
                    <div className="w-96">
                      <Autocomplete
                        options={users}
                        labelKey="name"
                        valueKey="id"
                        onChange={(e) => {
                          onChangeItem(idx, {
                            target: {
                              name: "personnel",
                              value: e,
                            },
                          });
                        }}
                        value={
                          users?.find((u) => u.id === item.personnel)?.name
                        }
                        useValueDirectly={true}
                        placeholder="Assign Personnel"
                        name="personnel"
                        id="PERSONNEL"
                      />
                    </div>
                    <span
                      className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                      onClick={() => removeItem(idx)}>
                      Remove
                    </span>
                  </div>
                ))}

              {skuBinLocations.length > 0 ? (
                <>
                  <AddButton text="Add Transfer" onClick={addItems} />
                </>
              ) : (
                <div>
                  <a className="cursor-pointer pl-2 text-center text-lg font-medium text-red-500">
                    No SKU Bin Mappings found for the selected warehouse and
                    product
                  </a>
                </div>
              )}
            </>
          )}
      </div>
    </Modal>
  );
};

export default StockTransferForm;
